import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Layout from "../Layout/Layout";
import { useAuthStore } from "../../Store/AuthStore";
import Loader from "../../Components-ui/Loader";
import Datetime from "react-datetime";
import { makeQuery } from "../../Common/util.helper";
import moment from "moment";
import { Pagination } from "../../Components-ui/Pagination";
import { toast } from "react-toastify";
import PushNotificationService from "./PushNotification.service";
import PushNotiModal from "./PushNotiModal";

const PushNotification: React.FC = () => {
  const history = useHistory();

  const [Loading, setLoading] = useState<any>(false);

  //-----Get bulk users
  const [MetaData, setMetaData] = useState<any>({});
  const [CurrentPage, setCurrentPage] = useState<number>(0);
  const [messages, setMessages] = useState([]);
  const [OpenModal, setOpenModal] = useState(false);
  const [StartDate, setStrtDate] = useState<Date>();
  const [duplicate, setDuplicate] = useState({
    title: "",
    message: "",
    imageUrl: "",
  })

  const [Filter, setFilter] = useState<any>({
    startDate: "",
    endDate: "",
    title: "",
    message: "",
    user_id: "",
    imageUrl: "",
  });

  const { logout, permissions, userData, tokens } = useAuthStore();

  const getAllMessages = async (number: any) => {
    try {
      const query = "";
      const resp = await PushNotificationService.getAllMessages(query, number);
      console.log(resp.data, "resppp");
      if (resp) {
        setMessages(resp?.data?.data?.result);
        setMetaData({
          current: resp?.data?.meta?.page,
          pages: resp?.data?.meta?.totalPages,
        });
        setCurrentPage(number);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (page: number, isReset: boolean = false) => {
    let cloneFilterData: any;
    setLoading(true);

    cloneFilterData = !isReset
      ? { ...Filter }
      : {
        companyCode: "",
        metal: "",
        metalType: "",
        startDate: "",
        endDate: "",
      };

    // if (cloneFilterData.orderBy !== "") {
    //   let orderByValues = cloneFilterData.orderBy.split("/");
    //   cloneFilterData.order = orderByValues[1];
    //   cloneFilterData.orderBy = orderByValues[0];
    // }
    try {
      const query = makeQuery(cloneFilterData);

      const data: any = await PushNotificationService.getAllMessages(
        query,
        page
      );
      if (data.status === 200 && data.data.data) {
        setMetaData({
          current: data.data.meta.page,
          pages: data.data.meta.totalPages,
        });
        setCurrentPage(page);
        setMessages(data ? data.data.data.result : []);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Something Went Wrong");
      }
    } catch (err) {
      setLoading(false);
      toast.error("Something Went Wrong");
    }
    // console.log(data ? data.data.data.result : [])
  };

  const closeModal = () => {
    setOpenModal(false);
    setDuplicate({
      title: "",
      message: "",
      imageUrl: "",
    })
  };

  const handleChangeFilter = (e: any) => {
    const { name, value } = e.target;
    setFilter({
      ...Filter,
      [name]: value,
    });
  };

  const today = new Date();
  const disableFutureStartDt = (current: any) => {
    return current.isBefore(today);
  };
  const disableFutureEndDt = (current: any) => {
    return current.isAfter(StartDate) && current.isBefore(today);
  };

  const resetData = async (e: any) => {
    e.preventDefault();
    setFilter({
      startDate: "",
      endDate: "",
      title: "",
      message: "",
      user_id: "",
      imageUrl: "",
    });

    setCurrentPage(0);
    await handleSubmit(0, true);
  };
  useEffect(() => {
    // getAllMessages(0);
    handleSubmit(0);
  }, [OpenModal]);

  return (
    <Layout type={"PaymentModule"}>
      {Loading ? (
        <Loader />
      ) : (
        <>
          <div className=" ">
            <div className="flex justify-between items-center mb-5">
              <h1 className="text-2xl font-bold tracking-tight">
                {"Push Notification"}
              </h1>

              <div>
                <button
                  className="text-white  bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                  type="button"
                  onClick={() => setOpenModal(true)}
                >
                  New Notification
                </button>
                {/* </div> */}
              </div>
            </div>

            <div className="border bg-white rounded border-gray-200 shadow">
              <div className="flex-1 p-5">
                <div className="col-span-12 sm:col-span-12">
                  <form
                    onSubmit={(e) => {
                      handleSubmit(0);
                      e.preventDefault();
                    }}
                  >
                    <div className="flex flex-wrap -mx-3 mb-2">
                      <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <div className="relative">
                          <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="description"
                          >
                            Notification Title
                          </label>
                          <input
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            id="title"
                            name="title"
                            type="text"
                            placeholder="Notification Title"
                            maxLength={30}
                            onChange={(e: any) => {
                              handleChangeFilter(e);
                            }}
                            value={Filter.title}
                          // onChange={(event) =>
                          //   setSelectedRecord({
                          //     ...selectedRecord,
                          //     description: event.target.value,
                          //   })
                          // }
                          // value={selectedRecord.description}
                          />
                        </div>
                      </div>

                      <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label
                          className="block text-sm font-medium text-gray-700"
                          htmlFor="grid-state"
                        >
                          From date
                        </label>

                        <Datetime
                          timeFormat={""}
                          dateFormat={"DD/MM/YYYY"}
                          inputProps={{
                            readOnly: true,
                            placeholder: "pick date",
                            className: "focus:outline-none",
                          }}
                          className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4`}
                          onChange={(event: any) => {
                            setFilter({
                              ...Filter,
                              startDate: moment(
                                new Date(event).toISOString()
                              ).format("DD/MM/YYYY"),
                              endDate: moment(
                                new Date(event).toISOString()
                              ).format("DD/MM/YYYY"),
                            });
                            setStrtDate(new Date(event));
                          }}
                          isValidDate={disableFutureStartDt}
                          value={moment(Filter.startDate)}
                          renderInput={(props) => {
                            return (
                              <input
                                {...props}
                                value={Filter.startDate ? Filter.startDate : ""}
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label
                          className="block text-sm font-medium text-gray-700"
                          htmlFor="grid-state"
                        >
                          To date
                        </label>
                        <Datetime
                          timeFormat={""}
                          dateFormat={"DD/MM/YYYY"}
                          inputProps={{
                            readOnly: true,
                            placeholder: "pick date",
                            className: "focus:outline-none",
                          }}
                          isValidDate={disableFutureEndDt}
                          className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4`}
                          onChange={(event: any) =>
                            setFilter({
                              ...Filter,
                              endDate: moment(
                                new Date(event).toISOString()
                              ).format("DD/MM/YYYY"),
                            })
                          }
                          value={moment(Filter.endDate)}
                          renderInput={(props) => {
                            return (
                              <input
                                {...props}
                                value={Filter.endDate ? Filter.endDate : ""}
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="flex w-full justify-end px-3 mb-6 md:mb-0">
                        <div className="my-3">
                          <input
                            className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                            type="submit"
                            value="Filter"
                            onClick={() => handleSubmit(CurrentPage)}
                          />

                          <button
                            className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                            type="button"
                            onClick={(e) => resetData(e)}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-span-12 sm:col-span-12">
                  {" "}
                  <hr />
                </div>
                <br />
                <div className="col-span-12 sm:col-span-12">
                  {Loading ? (
                    <Loader />
                  ) : (
                    <div className="border bg-white rounded border-gray-200 shadow overflow-auto">
                      <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                        <thead>
                          <tr className="text-left border-b border-gray-300">
                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Message ID</th>
                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Title</th>
                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Message</th>
                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Created At</th>
                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Partner Name</th>
                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Status</th>
                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Duplicate</th>
                            {/* <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Message Type</th> */}

                            {/* <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Mail
                      </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {messages.length > 0 ? (
                            messages.map((msg: any, index: number) => {
                              return (
                                <tr
                                  key={index}
                                  className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                                >
                                  <td className="py-3 px-3 text-left relative">
                                    {msg.messageId}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {msg.title}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {/* {msg.message} */}
                                    {msg.message.length > 50 ? `${msg.message.substring(0, 50)}...` : msg.message}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {moment(msg.createdAt).format(
                                      "DD-MM-YYYY HH:mm"
                                    )}
                                  </td>

                                  <td className="py-3 px-3 text-left relative">
                                    {msg.partnerName}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">

                                    <span
                                      className={`text-xs font-semibold inline-block py-1 px-2  rounded-full  last:mr-0 mr-1 ${msg.isSent == true
                                        ? "text-green-600 bg-green-200"
                                        : "text-black-600 bg-red-400"
                                        }`}
                                    >
                                      {msg.isSent == true ? "Success" : "Failed"}
                                    </span>
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    <button
                                   className="px-2 py-1 mr-3 text-xs font-bold text-white uppercase bg-purple-950 rounded cursor-pointer"
                                      onClick={() => {
                                        setDuplicate({
                                          title: msg.title,
                                          message: msg.message,
                                          imageUrl: msg.imageUrl
                                        })
                                        setOpenModal(true);
                                      }

                                      }>
                                      Duplicate
                                    </button>
                                  </td>
                                  {/* ' <td className="py-3 px-3 text-left relative">
                                    {msg.messageType == "ONE" ? "ONE" : msg.messageType == "MANY" ? "ALL" : "CUSTOMER GROUP"}
                                  </td>' */}


                                  {/* //new */}
                                  {/* <td className="py-3 px-3 text-left relative">
                                {(STATUS.filter(
                                  (x: any) => x.value === order.status
                                ).length > 0
                                  ? STATUS.filter(
                                      (x: any) => x.value === order.status
                                    )[0].label
                                  : order.status) === "COMPLETED" ? (
                                  <button
                                    className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                    type="button"
                                    // onClick={() =>
                                    //   history.push(`/order/${order.ebOrderRef}`)
                                    // }
                                  >
                                    Send mail
                                  </button>
                                ) : (
                                  ""
                                )}
                              </td> */}
                                </tr>
                              );
                            })
                          ) : (
                            <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                              <td
                                colSpan={10}
                                className="text-center py-3 px-3 text-left relative"
                              >
                                No data to display
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>

              {/* Pagination Bar */}
              <Pagination
                meta={MetaData}
                handlePageChange={(number: number) => getAllMessages(number)}
              />
            </div>

            {OpenModal ? <PushNotiModal onCloseModal={closeModal} data={duplicate} /> : null}
          </div>
        </>
      )}
    </Layout>
  );
};

export default PushNotification;
