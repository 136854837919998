import {makeQuery} from "../../Common/util.helper";
import Http from "../../library/http.library";
import {httpMethods} from "../../library/enum";

export const getRefundList = async (tokens: any, currentPage: any,data:any) => {
    var query = {
        isPaginated: true,
        ...(data.ebOrderRef ? {orderRef: data.ebOrderRef} : {}),
        ...(data.type ? {qType: data.type} : {}),
        ...(data.accountId ? {accountId: data.accountId} : {}),
        ...(data.customerId ? {customerId: data.customerId} : {}),
        ...(data.currency ? {currency: data.currency} : {}),
        // ...(data.fromDate ? {fromDate: data.fromDate} : {}),
        // ...(data.toDate ? {toDate: data.toDate} : {}),
        ...(data.status ? {status: data.status} : {}),
        // ...(data.orderBy ? {orderBy: data.orderBy} : {}),
    }
    const params = makeQuery(query);
    return await Http.setMethod(httpMethods.get).setUrl(`/refund-list?page=${currentPage}${params}`).setToken(tokens.idToken.jwtToken).request();

};

export const postSelectRefundList = async (tokens: any, data: any) => {
    return await Http.setMethod(httpMethods.post).setUrl(`/refund-list`).setData(data).setToken(tokens.idToken.jwtToken).request();

};

export const getRefundApprove = async (currentPage: number, tokens: any,data:any) => {
    var status = ''
    data?.status?.map((value: any) => {
        status = status + '&status=' + value.status
    })
    var query = {
        isPaginated: true,
        ...(data.orderRef ? {orderRef: data.orderRef} : {}),
        ...(data.orderItemRef ? {orderItemRef: data.orderItemRef} : {}),
        // ...(data.status ? {status: data.status} : {}),
        ...(data.fromDate ? {createdAtFrom: data.fromDate} : {}),
        ...(data.toDate ? {createdAtTo: data.toDate} : {}),
    }
    const params = makeQuery(query) + status;
    return await Http.setMethod(httpMethods.get).setUrl(`/refund-approve?page=${currentPage}${params}`).setToken(tokens.idToken.jwtToken).request();

};

export const refundApprove = async (tokens: any,data:any) => {
    return Http.setMethod(httpMethods.patch).setUrl(`/refund-approve`).setData(data).setToken(tokens.idToken.jwtToken).request();

};


const ManualRefundService = {
    getRefundList,
    postSelectRefundList,
    getRefundApprove,
    refundApprove,
};

export default ManualRefundService;
