import { useEffect, useState } from "react";
import moment from "moment";
import Datetime from "react-datetime";
import Loader from "../../../Components-ui/Loader";
import { useAuthStore } from "../../../Store/AuthStore";
import Layout from "../../Layout/Layout";
import { getDashboardData } from "./VsoDashboard.service";
import { filter } from "mathjs";
import { toast, ToastContainer } from "react-toastify";

type Props = {};

type Filters = {
  date: string;
};

const VsoDashboard = (props: Props) => {
  const { tokens, permissions } = useAuthStore();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filters>({
    date: new Date().toISOString(),
  });
  useEffect(() => {
    fetchVsoData();
  }, [filters]);

  const fetchVsoData = async () => {
    try {
      setLoading(true);
      let response = await getDashboardData(filters, tokens);
      if (response.status == 200 && response.data) {
        setData(response.data.data);
      }
    } catch (error) {
      console.error("Something went wrong!");
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    fetchVsoData();
  };

  const resetFilter = () => {
    setFilters({ date: new Date().toISOString() });
  };

  const addDecimal = (num: any) => {
    return (parseInt(String(num * Math.pow(10, 4))) / Math.pow(10, 4)).toFixed(
      4
    );
  };

  return (
    <>
      {loading && <Loader />}
      <ToastContainer />
      <Layout type={"VSOModule"}>
        <div className="flex justify-between items-center mb-5">
          <h1 className="text-2xl font-bold tracking-tight">
            {"VSO Dashboard"}
          </h1>
        </div>
        <div className="border bg-white rounded border-gray-200 shadow">
          <form onSubmit={handleSubmit}>
            <div className="flex-1 p-3 pb-0">
              <div className="flex flex-wrap -mx-3 mb-2">
                <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-state"
                  >
                    Date
                  </label>
                  <div className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border shadow-sm">
                    <Datetime
                      className="efect-to"
                      inputProps={{
                        readOnly: true,
                        className: "focus:outline-none",
                      }}
                      value={moment(filters.date)}
                      timeFormat={"HH:mm:ss"}
                      dateFormat={"DD/MM/YYYY"}
                      onChange={(e: any) => {
                        setFilters({
                          ...filters,
                          date: new Date(e).toISOString(),
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                  <div className="my-6">
                    <button
                      className="float-right ml-4 px-4 py-2.5 font-bold text-white text-sm uppercase bg-red-600 rounded"
                      type="button"
                      onClick={resetFilter}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="p-3 overflow-x-auto">
            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
              <thead className="text-left border-b border-gray-600 text-xs">
                <tr className="text-white sticky top-0 px-6 py-3 text-gray-600 bg-purple-300 font-semibold">
                  <th className="text-white sticky top-0 px-3 py-2 bg-gray-800 z-10 px-3 py-2 w-1/4 text-left">
                    Vault
                  </th>
                  <th className="text-white sticky top-0 px-3 py-2 bg-gray-800 z-10 px-3 py-2 w-1/4 text-left">
                    Detail
                  </th>
                  <th className="text-white sticky top-0 px-3 py-2 bg-gray-800 z-10 px-3 py-2 text-right">
                    Silver
                  </th>
                  <th className="text-white sticky top-0 px-3 py-2 bg-gray-800 z-10 px-3 py-2 text-right">
                    Platinum
                  </th>
                  <th className="text-white sticky top-0 px-3 py-2 bg-gray-800 z-10 px-3 py-2 text-right">
                    Palladium
                  </th>
                  <th className="text-white sticky top-0 px-3 py-2 bg-gray-800 z-10 px-3 py-2 text-right">
                    Gold
                  </th>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-xs ">
                {data?.map((vault: any, vIndex: number) => {
                  return vault?.summary?.map((item: any, index: number) => (
                    <tr
                      className={`border-b border-gray-200 ${
                        vIndex % 2 === 0 ? "bg-white" : "bg-gray-100"
                      }`}
                    >
                      {index === 0 && (
                        <td
                          className="px-2 py-2 text-left"
                          rowSpan={vault?.summary?.length}
                        >
                          <span className="font-semibold">
                            {vault.vaultName
                              ? vault.vaultName
                              : `Vault ${vault.vaultId}`}
                          </span>
                        </td>
                      )}

                      <td className="px-3 py-2 text-left">
                        <span className="font-bold">{item.value}</span>
                      </td>
                      <td className="px-3 py-2 text-right">
                        <span className="">
                          {addDecimal(item.summary.agTotal)} g
                        </span>
                      </td>
                      <td className="px-3 py-2 text-right">
                        <span className="">
                          {addDecimal(item.summary.ptTotal)} g
                        </span>
                      </td>
                      <td className="px-3 py-2 text-right">
                        <span className="">
                          {addDecimal(item.summary.pdTotal)} g
                        </span>
                      </td>
                      <td className="px-3 py-2 text-right">
                        <span className="">
                          {addDecimal(item.summary.auTotal)} g
                        </span>
                      </td>
                    </tr>
                  ));
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default VsoDashboard;
