import Layout from "../Layout/Layout";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "./react-confirm-alert.css";
import {
  GetAllReferenceMaster,
  CreateReferenceMaster,
  GetErpTableData,
  GetErpSyncDetail,
  syncMasterTables
} from "./EbMasterServices";
import FeederPopUp from "./FeederPopup";
import UpdateReferencePopup from "./UpdateReferencePopup";
import ReferenceDetails from "./ViewReferencePopup";
import Datetime from "react-datetime";
import moment from "moment";
import { e } from "mathjs";
import axios from "axios";
import { useAuthStore } from "../../Store/AuthStore";
import Loader from "../../Components-ui/Loader";
const EbMaster: React.FC = () => {
  const { tokens } = useAuthStore();
  const [selectedTable, setSelectedTable] = useState<any>("item_master");
  const [erpData, setErpData] = useState<any>([]);
  const [tableHeaders, setTableHeaders] = useState<any>([]);
  const [syncDetails, setSyncDetails] = useState<any>();
  const [customUpdatetDate, setCustomUpdateDate] = useState<any>(null);
  const [selectedToSync, setSelectedToSync] = useState<any>([]);
  const [Loading, setLoading] = useState<any>(false);
  const tablesList = [{
    id: "item_master", name: "Item master"
  },
  {
    id: "warehouse_master", name: "Warehouse master"
  },
  { id: "hsn_master", name: "HSN master" }];

  useEffect(() => {
    // console.log("tokenstokenstokenstokens ", tokens.accessToken.jwtToken);

    axios.defaults.headers.common['Authorization'] = `Bearer ${tokens.accessToken.jwtToken}`;
    // date - new Date(startDate).toISOString();
    setSelectedToSync([])
    setCustomUpdateDate(null);
    setTableHeaders([]);
    loadData();
  }, [selectedTable]);

  useEffect(() => {
    // date - new Date(startDate).toISOString();
    if (customUpdatetDate) {
      setTableHeaders([]);
      loadData(true);
    }

  }, [customUpdatetDate]);

  const loadData = async (iscustomDate?: any) => {
    setLoading(true);
    try {
      let syncDetail: any = await GetErpSyncDetail(selectedTable,tokens);
      setSyncDetails(syncDetail.data.data[0]);
      let erpData: any;
      console.log("customDatecustomDate ", iscustomDate, customUpdatetDate);

      if (iscustomDate) {
        erpData = await GetErpTableData(selectedTable, customUpdatetDate);
      } else {
        erpData = await GetErpTableData(selectedTable, new Date(syncDetail.data.data[0].updatedAt).toISOString());
      }


      setErpData(erpData.data.data);
      if (erpData.data.data.length > 0) {
        setLoading(false);
        let header: any = Object.keys(erpData.data.data[0]);
        var filteredHeader = header.filter((e: any) => e !== 'createdAt');
        filteredHeader = filteredHeader.filter((e: any) => e !== 'updatedAt');
        setTableHeaders(filteredHeader);
      } else {
        setLoading(false);
        throw ("No data found!");

      }

    } catch (error: any) {
      setLoading(false);
      console.log(error);
      toast.error(error.toString())
    }
  };

  const camelCaseSplitter = (text: string) => {
    return text.toString().replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  const onItemSelected = (data: any) => {
    if (selectedToSync.includes(data)) {
      setSelectedToSync(selectedToSync.filter((item: any) => item !== data));
    } else {
      setSelectedToSync((oldArray: any) => [...oldArray, data]);
    }
  }

  const isChecked = (data: any) => {
    var retVal = false;
    switch (selectedTable) {
      case "item_master":
        retVal = selectedToSync.includes(data.itemCode)
        break;
      case "warehouse_master":
        retVal = selectedToSync.includes(data.warehouseId)
        break;
      case "hsn_master":
        retVal = selectedToSync.includes(data.hsnCode)
        break;
      default:
      // code block
    }

    return retVal;
  }

  const syncTables = async () => {
    console.log("Sync Tables ", selectedToSync);
    setLoading(true);
    if (selectedToSync.length > 0) {
      
      let updatedMasters = await syncMasterTables(selectedTable, selectedToSync);
      console.log("updatedMasters ", updatedMasters);
      let syncDetail: any = await GetErpSyncDetail(selectedTable,tokens);
      setSyncDetails(syncDetail.data.data[0]);
      // setLoading(false);
      window.location.reload();
    } else {
      setLoading(false);
      toast.error("Nothing is selected to be synced!")
    }

  }


  return (

    <Layout type={"MasterModule"}>
      {
        Loading ? (<Loader />) :
          <div className="overflow-x-auto">
            <div className="w-full">

              <div className=" shadow-md rounded-lg mt-6">

                <div className="flex justify-between items-center mb-5">
                  <h1 className="text-2xl font-bold tracking-tight">
                    {"Data Sync"}
                  </h1>
                  <button
                    className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
                    onClick={() => { syncTables() }}
                    type="button"
                  >
                    Sync
                  </button>
                </div>
                <div className="flex items-center mb-5">
                  <div className="px-3">
                    <label
                      className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                      // for="grid-state"
                    >
                      Table
                    </label>
                    <div className="relative">
                      <select
                        onChange={e => setSelectedTable(e.target.value)}
                        className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                        id="grid-state"
                        value={selectedTable}
                      >
                        {tablesList.map((data: any,index:number) => {
                          return (
                            <option key={index} value={data.id}>
                              {data.name}
                            </option>
                          );
                        })}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                  </div>
                  <div className="px-3">
                    <label
                      className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                      // for="grid-state"
                    >
                      Last Updated
                    </label>
                    <div className="relative">
                      {syncDetails?.updatedAt ? moment(syncDetails?.updatedAt).format("YYYY-MM-DD HH:mm:ss") : ""}
                    </div>
                  </div>

                  <div className="px-3">
                    <label
                      className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                      // for="grid-state"
                    >
                      Custom Update Date
                    </label>
                    <div id="startPicker" className="">
                      <Datetime
                        inputProps={{ readOnly: true,className: "border text-sm px-3 border-gray-300 rounded py-2", placeholder: "Custom Update Date" }}
                        value={moment(customUpdatetDate)}
                        timeFormat={true}
                        dateFormat={"DD/MM/YYYY"}
                        onChange={(e: any) => {
                          setCustomUpdateDate(new Date(e).toISOString());
                        }}
                        renderInput={(props) => {
                          return <input {...props} value={(customUpdatetDate) ? props.value : ''} />
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="min-h-screen">
                  <table className="w-full table-auto rounded-lg shadow-lg">
                    <thead>
                      <tr className="sticky top-0 px-2 py-3 text-gray-900 bg-purple-200">

                        {tableHeaders?.map((data: any,index:number) => {
                          // console.log("Dataaaa ", data);
                          return (<th key={index} className=" py-3 px-2 text-center text-xs">{camelCaseSplitter(data).toUpperCase()}</th>)
                        })}
                        {tableHeaders.length > 0 &&
                          <th className=" py-3 px-2 text-center text-xs">{"action".toUpperCase()}</th>}

                      </tr>
                    </thead>
                    <tbody className="text-gray-800 text-sm font-light">

                      {erpData.length > 0 &&
                        erpData.map((data: any,index:number) => {
                          return (
                            <tr key={index} className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100">
                              {tableHeaders &&
                                tableHeaders.map((key: any,index:number) => {
                                  // console.log("key ", key, data[key]);
                                  return (<td key={index} className=" py-3 px-2 text-center text-xs">
                                    <span className="font-medium whitespace-normal break-all">
                                      {typeof data[key] == "boolean" ? data[key] ? "YES" : "NO" : data[key]}
                                    </span>
                                  </td>)
                                })}

                              {tableHeaders.length > 0 &&
                                <td className=" py-3 px-2 text-center text-xs">
                                  <input
                                    type="checkbox"
                                    name="defaultRule"
                                    checked={isChecked(data)}
                                    onChange={() => {
                                      switch (selectedTable) {
                                        case "item_master":
                                          onItemSelected(data.itemCode)
                                          break;
                                        case "warehouse_master":
                                          onItemSelected(data.warehouseId)
                                          break;
                                        case "hsn_master":
                                          onItemSelected(data.hsnCode)
                                          break;
                                        default:
                                        // code block
                                      }

                                    }}
                                  />
                                </td>}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
      }
      <ToastContainer />


    </Layout>
  );
};

export default EbMaster;
