import React, { useState, useEffect } from "react";
import { useAuthStore } from "../../../Store/AuthStore";
import Layout from "../../Layout/Layout";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import moment from "moment";
import { Pagination } from "../../../Components-ui/Pagination";
import "./BarReconciliation.css";
import BarReconciliationReportService from "./BarReconciliationReportService";
import { getValuts } from "../../Metal/vsoCalendar/VSO.Service";
import Datetime from "react-datetime";
import customerService from "../../Customer/Services/Customer.service";
import XLSX from "xlsx";

const BarSegregationList: React.FC = () => {
  const { tokens, warehouses, permissions } = useAuthStore();
  const width = 100;
  const [date, setDate] = useState<any>(new Date().toISOString());

  const [metaCustomerTotalStock, setMetaCustomerTotalStock] = useState<any>({});
  const [customerTotalStock, setCustomerTotalStock] = useState<any>([]);
  const [partnerList, setPartnerList] = useState<any>([]);
  const [Loading, setLoading] = useState<any>(false);
  const [metalList, setMetalList] = useState<any>([]);
  const [vaultList, setVaultList] = useState<any>([]);
  const [reportDate, setReportDate] = useState<any>({});
  const [filters, setFilters] = useState<any>({
    metalCode: "",
    vaultId: "",
    partner: "",
    partnerId: "",
    customerId: "",
    reference: "",
    accountId: "",
    allocationStatus: "",
    isExchange: "",
    fromDate: "",
    toDate: "",
  });

  const getPartners = () => {
    customerService
      .getPartnerList(tokens)
      .then((response: any) => {
        setPartnerList(response.data.data);
      })
      .catch((err) => {
        toast.error("Something Went Wrong!");
        setLoading(false);
      });
  };

  const loadMetals = async (): Promise<void> => {
    return new Promise(async (resolve) => {
      BarReconciliationReportService.getMetals(tokens)
        .then((response: any) => {
          setMetalList(response.data.data);
          resolve();
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
          resolve();
        });
    });
  };

  const loadVaults = async (): Promise<void> => {
    return new Promise(async (resolve) => {
      getValuts(tokens, warehouses, permissions)
        .then((response: any) => {
          setVaultList(response.data.data);
          resolve();
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
          resolve();
        });
    });
  };

  useEffect(() => {
    // setLoading(true);
    loadMetals();
    loadVaults();
    getPartners();
  }, []);

  useEffect(() => {
    CustomerTotalStockGet(0);
  }, [setFilters]);
  const CustomerTotalStockGet: any = async (page: number,data?:any) => {

    const filterData:any= data ? data : filters;
    filterData.isPaginated = true;
    await BarReconciliationReportService.getCustomerTotalStock(
      tokens,
      page,
      filterData
    )
      .then((response: any) => {
        setCustomerTotalStock(response.data.data.result);
        setMetaCustomerTotalStock({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.errors[0].title);
      });
  };

  const downloadReport = async (type: string, filters: any, segNo?: string) => {
    filters.isPaginated = false;
    if (type === "seg-details") {
        console.log(filters);
        filters.isDownload=true
        filters.isPaginated=false
        await BarReconciliationReportService.getCustomerTotalStock(
          tokens,
          0,
          filters
        ).then((response: any) => {
          console.log(response.data.data);
  
          const worksheet = XLSX.utils.json_to_sheet(response.data.data);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, type);
          XLSX.writeFile(workbook, `Customer_Total_Stock.xlsx`);
        });
      }
  };

  const ResetForm = async () => {
    setFilters({
      metalCode: "",
      vaultId: "",
      partner: "",
      partnerId: "",
      customerId: "",
      allocationStatus: "",
      fromDate: "",
      toDate: "",
    });

    // CustomerTotalStockGet(0);
    setFilters((prevFilter: any) => {
        CustomerTotalStockGet(0, prevFilter);
      return prevFilter;
    });
  };

  return (
    <Layout type={"Reports"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">
          {"Customer Total Stock List"}
        </h1>
      </div>
      <div className="border bg-white rounded border-gray-200 shadow">
        <div className="flex-1 p-2 pb-0">
          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="pb-3 w-full md:w-2/4 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="vault-id"
              >
                Vault Id
              </label>
              <select
                className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                name="vault-id"
                id="vault-id"
                placeholder="Vault ID"
                onChange={(event) =>
                  setFilters({
                    ...filters,
                    vaultId: event.target.value,
                  })
                }
                value={filters.vaultId}
              >
                <option value="">Select a Vault</option>
                {vaultList.map((vault: any) => (
                  <option value={vault.warehouseId}>{vault.warehouseId}</option>
                ))}
              </select>
            </div>
            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="user-id"
              >
                Metal Code
              </label>
              <select
                className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                name="user-id"
                id="user-id"
                placeholder="Metal Code"
                onChange={(event) =>
                  setFilters({
                    ...filters,
                    metalCode: event.target.value,
                  })
                }
                value={filters.metalCode}
              >
                <option value="">Select a Metal</option>
                {metalList.map((metal: any) => (
                  <option value={metal.metalCode}>{metal.metalCode}</option>
                ))}
              </select>
            </div>

            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="partner"
              >
                Partner
              </label>
              <div className="relative">
                <select
                  className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                  id="partner"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      partnerId: event.target.value,
                    })
                  }
                  value={filters.partnerId}
                >
                  <option value="">Select a Partner</option>
                  {partnerList.map((partner: any) => (
                    <option value={partner.partnerId}>{partner.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="user-type"
              >
                Customer
              </label>
              <div className="relative">
                <input
                  className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                  id="description"
                  type="number"
                  placeholder="Customer ID"
                  autoComplete="none"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      customerId: event.target.value,
                    })
                  }
                  value={filters.customerId}
                />
              </div>
            </div>
            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="user-type"
              >
                Allocation Status
              </label>
              <div className="relative">
                <select
                  className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                  id="user-type"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      allocationStatus: event.target.value,
                    })
                  }
                  value={filters.allocationStatus}
                >
                  <option value="">Select a Status</option>
                  <option value="PERMANENT">PERMANENT</option>
                  <option value="TEMPORARY">TEMPORARY</option>
                  <option value="REVERSED">REVERSED</option>
                </select>
              </div>
            </div>
            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                for="grid-state"
              >
                From Date
              </label>
              <div className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border shadow-sm">
                <Datetime
                  className="efect-to"
                  inputProps={{
                    readOnly: true,
                    placeholder: "pick date",
                    className: "focus:outline-none",
                  }}
                  // value={
                  //   modalFilters.fromDate ? moment(modalFilters.fromDate) : ""
                  // }
                  value={moment(filters.fromDate)}
                  renderInput={(props) => {
                    return (
                      <input
                        {...props}
                        value={filters.fromDate ? filters.fromDate : ""}
                      />
                    );
                  }}
                  timeFormat={""}
                  dateFormat={"DD/MM/YYYY"}
                  onChange={(e: any) => {
                    setFilters({
                      ...filters,
                      fromDate: moment(new Date(e).toISOString()).format(
                        "MM/DD/YYYY"
                      ),
                    });
                  }}
                />
              </div>
            </div>
            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                for="grid-state"
              >
                To Date
              </label>
              <div className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border shadow-sm">
                <Datetime
                  className="efect-to"
                  inputProps={{
                    readOnly: true,
                    placeholder: "pick date",
                    className: "focus:outline-none",
                  }}
                  // value={
                  //   modalFilters.toDate ? moment(modalFilters.toDate) : ""
                  // }
                  value={moment(filters.toDate)}
                  renderInput={(props) => {
                    return (
                      <input
                        {...props}
                        value={filters.toDate ? filters.toDate : ""}
                      />
                    );
                  }}
                  timeFormat={""}
                  dateFormat={"DD/MM/YYYY"}
                  onChange={(e: any) => {
                    setFilters({
                      ...filters,
                      toDate: moment(new Date(e).toISOString()).format(
                        "MM/DD/YYYY"
                      ),
                    });
                  }}
                />
              </div>
            </div>

            <div className="pb-3 w-full mb-6 md:mb-0 ">
              <div className="my-6 d-flex">
                <button
                  className=" ml-4 px-4 py-2.5 font-bold text-white text-sm uppercase bg-purple-950 rounded"
                  type="button"
                  onClick={(e) => CustomerTotalStockGet(0)}
                >
                  Filter
                </button>
                <button
                  className=" ml-4 px-4 py-2.5 font-bold text-white text-sm uppercase bg-red-600 rounded"
                  type="button"
                  onClick={(e) => ResetForm()}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white rounded border-gray-200 shadow m-2">
          {Loading ? (
            <Loader />
          ) : (
            <div>
              <fieldset className="bg-gray-100 border border-gray-300 flex min-w-0 mt-3 p-3 p-1 pb-4 rounded w-full">
                <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
                  {"Customer Total Stock List"}
                </legend>
                <div className="w-full">
                  <div
                    className="font-bold mb-1 ml-3 pb-2"
                    style={{ fontSize: "13px" }}
                  >
                    Report as at{" "}
                    <span className="bg-green-500  py-1 px-2 rounded text-white text-xs">
                      {moment(reportDate).format("DD-MM-YYYY HH:mm")}
                    </span>
                    <button
                      className={`border rounded-md background-transparent  
                        uppercase px-3 py-1 text-sm outline-none 
                        focus:outline-none m-2  ease-linear transition-all 
                        duration-150  `}
                      type="button"
                      onClick={() => downloadReport("seg-details", filters)}
                    >
                      Download Report
                    </button>
                  </div>
                  <div className="overflow-x-auto">
                    <table className="whitespace-nowrap  rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs ">
                      <thead>
                        <tr className="text-left border-b border-gray-300">
                          {/*<th style={{minWidth: width + "px", left: 0}}*/}
                          {/*    className="sticky-col sticky top-0 px-3 py-2 bg-gray-800 z-10">Vault*/}
                          {/*    ID*/}
                          {/*</th>*/}
                          {/* <th
                            style={{ minWidth: width + "px", left: 0 + "px" }}
                            className="sticky-col  sticky top-0 px-3 py-2 bg-gray-800 z-10"
                          >
                            Vault
                          </th> */}
                          <th
                            style={{
                              minWidth: width + "px",
                              left: width * 0 + "px",
                            }}
                            className="sticky-col  sticky top-0 px-3 py-2 bg-gray-800 z-10"
                          >
                            Metal Code
                          </th>
                          {/* <th
                            style={{
                              minWidth: width + "px",
                              left: width * 2 + "px",
                            }}
                            className="sticky-col  sticky top-0 px-3 py-2 bg-gray-800 z-10"
                          >
                            Item Code
                          </th> */}
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Customer ID
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Partner
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                           Total Weight
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Allocation Status
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        {0 !== customerTotalStock.length ? (
                          customerTotalStock.map((data: any, index: number) => {
                            return (
                              <tr
                                key={index}
                                className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                              >
                                <td className="py-3 px-3 text-left relative">
                                  {data.metalCode}
                                </td>
                                <td className="py-3 px-3 text-left relative">
                                  {data.customerId || "N/A"}
                                </td>
                                <td className="py-3 px-3 text-left relative">
                                  <span>
                                    {
                                      partnerList?.find(
                                        (i: any) =>
                                          i.partnerId ===
                                          parseInt(data.partnerId)
                                      )?.name // partnerList.map((x: any) =>  x.partnerId).includes(parseInt(data.partnerId)).name
                                    }
                                  </span>
                                </td>
                                <td className="py-3 px-3 text-left relative">
                                  {data.totalWeight}
                                </td>
                                <td className="py-3 px-3 text-left relative">
                                  {data.allocationStatus}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                            <td
                              colSpan={22}
                              className="text-center py-3 px-3 text-left relative"
                            >
                              No data to display
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    meta={metaCustomerTotalStock}
                    handlePageChange={(number: number) => {
                        CustomerTotalStockGet(number);
                    }}
                  />
                </div>
              </fieldset>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};
export default BarSegregationList;
