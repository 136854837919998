import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import downArrow from '../../../Images/chevron-down.svg';
import upArrow from '../../../Images/chevron-up.svg';

interface GainLossSummeryTableProps {
    realised: any;
    unRealised: any;
}

const metalsWithCode = [
    { code: 'Au', name: 'Gold' },
    { code: 'Ag', name: 'Silver' },
    { code: 'Pt', name: 'Platinum' },
    { code: 'Pd', name: 'Palladium' },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        borderRight: `1px solid ${theme.palette.divider}`, // Add vertical border
        padding: '8px 12px', // Reduce padding
        fontSize: 14,       // Smaller font size
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,       // Smaller font size for body cells
        borderRight: `1px solid ${theme.palette.divider}`, // Add vertical border
        padding: '8px 12px', // Reduce padding
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    height: '42px', // Reduce row height
}));


const GainLossSummeryTable: React.FC<GainLossSummeryTableProps> = (props) => {
    const { realised, unRealised } = props;

    const investedMetalSum = realised &&
        Object.keys(realised).reduce(
            (acc, metal) => acc + realised[metal]?.sell_price,
            0
        );
    const markToMarkSum = unRealised &&
        Object.keys(unRealised).reduce(
            (acc, metal) => acc + unRealised[metal].totalSellAmount,
            0
        );
    const unInvestedSum = unRealised &&
        Object.keys(unRealised).reduce(
            (acc, metal) => acc + unRealised[metal].totalBuybackAmount,
            0
        );

    const realisedMetalSum = realised &&
        Object.keys(realised).reduce(
            (acc, metal) => acc + realised[metal]?.buyback_price,
            0
        );

    const realisedPLSum = realisedMetalSum - investedMetalSum;
    const unrealisedPLSum = markToMarkSum - unInvestedSum;
    const realisedPLSumPer =
        investedMetalSum > 0
            ? ((realisedMetalSum - investedMetalSum) / investedMetalSum) * 100
            : realisedMetalSum > 0
                ? 100
                : 0;
    const unrealisedPLSumPer =
        unInvestedSum > 0
            ? ((markToMarkSum - unInvestedSum) / unInvestedSum) * 100
            : markToMarkSum > 0
                ? 100
                : 0;

    return realised ? (
        <>
            <TableContainer component={Paper} style={{ maxHeight: 350, overflow: 'hidden' }}>
                <Typography variant="subtitle1" fontWeight='bold' className='p-1' gutterBottom>
                    BOOKED GAIN / LOSS
                </Typography>
                <Table stickyHeader>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell align="center">Metal</StyledTableCell>
                            {realised &&
                                Object.keys(realised).map((metalCode) => {
                                    const metalObject = metalsWithCode?.find(
                                        (metal) => metal?.code === metalCode
                                    );
                                    const metalName = metalObject ? metalObject?.name : metalCode;
                                    return (
                                        <StyledTableCell key={metalCode} align="center">
                                            {metalName}
                                        </StyledTableCell>
                                    );
                                })}
                            <StyledTableCell align="center">Total</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <StyledTableCell><b>Invested Metal Value</b></StyledTableCell>
                            {Object.keys(realised).map((metal) => (
                                <StyledTableCell key={`${metal}-sold`} align="right">
                                    ₹{realised[metal].sell_price.toFixed(2)}
                                </StyledTableCell>
                            ))}
                            <StyledTableCell align="right">₹{investedMetalSum.toFixed(2)}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell><b>Realised Metal Value</b></StyledTableCell>
                            {realised &&
                                Object.keys(realised).map((metal) => (
                                    <StyledTableCell key={`${metal}-investment`} align="right">
                                        ₹{realised[metal].buyback_price.toFixed(2)}
                                    </StyledTableCell>
                                ))}
                            <StyledTableCell align="right">₹{realisedMetalSum.toFixed(2)}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell><b>Metal Transacted</b></StyledTableCell>
                            {realised &&
                                Object.keys(realised).map((metal) => (
                                    <StyledTableCell key={`${metal}-invst`} align="right">
                                        {realised[metal].quantity.toFixed(4)} g
                                    </StyledTableCell>
                                ))}
                            <StyledTableCell align="center">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell>
                                <b>Gain / Loss (INR)</b>
                            </StyledTableCell>
                            {realised &&
                                Object.keys(realised).map((metal) => (
                                    <StyledTableCell key={`${metal}-pp`} align="right">
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            {realised[metal].buyback_price.toFixed(2) -
                                                realised[metal].sell_price.toFixed(2) !== 0 ? (
                                                realised[metal].buyback_price - realised[metal].sell_price > 0 ? (
                                                    <img
                                                        src={upArrow}
                                                        className="arrow-icon"
                                                        style={{ marginRight: '5px', marginBottom: '-4px' }}
                                                    />
                                                ) : (
                                                    <img
                                                        src={downArrow}
                                                        className="arrow-icon"
                                                        style={{ marginRight: '5px', marginBottom: '-4px' }}
                                                    />
                                                )
                                            ) : (
                                                ''
                                            )}
                                            <span>
                                                ₹
                                                {Math.abs(realised[metal].buyback_price - realised[metal].sell_price).toFixed(2)}
                                            </span>
                                        </div>
                                    </StyledTableCell>
                                ))}
                            <StyledTableCell align="right">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    {realisedPLSum !== 0 ? (
                                        realisedPLSum > 0 ? (
                                            <img
                                                src={upArrow}
                                                className="arrow-icon"
                                                style={{ marginRight: '5px', marginBottom: '-4px' }}
                                            />
                                        ) : (
                                            <img
                                                src={downArrow}
                                                className="arrow-icon"
                                                style={{ marginRight: '5px', marginBottom: '-4px' }}
                                            />
                                        )
                                    ) : (
                                        ''
                                    )}
                                    <span>
                                        ₹{Math.abs(realisedPLSum).toFixed(2)}
                                    </span>
                                </div>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell>
                                <b>Gain / Loss (%)</b>
                            </StyledTableCell>
                            {realised &&
                                Object.keys(realised).map((metal) => (
                                    <StyledTableCell key={`${metal}-pp`} align="right" style={{ padding: '5px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            {realised[metal].buyback_price.toFixed(2) - realised[metal].sell_price.toFixed(2) !== 0 ? (
                                                realised[metal].buyback_price - realised[metal].sell_price > 0 ? (
                                                    <img
                                                        src={upArrow}
                                                        className='arrow-icon'
                                                        style={{ marginRight: '5px', marginBottom: '-4px' }}
                                                    />
                                                ) : (
                                                    <img
                                                        src={downArrow}
                                                        className='arrow-icon'
                                                        style={{ marginRight: '5px', marginBottom: '-4px' }}
                                                    />
                                                )
                                            ) : (
                                                ''
                                            )}
                                            <span>
                                                {realised[metal].sell_price > 0
                                                    ? Math.abs(
                                                        ((realised[metal].buyback_price - realised[metal].sell_price) /
                                                            realised[metal].sell_price) *
                                                        100
                                                    ).toFixed(2)
                                                    : realised[metal].buyback_price > 0
                                                        ? 100
                                                        : 0}
                                                %
                                            </span>
                                        </div>
                                    </StyledTableCell>
                                ))}

                            <StyledTableCell align="right" style={{ padding: '5px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    {realisedPLSumPer !== 0 ? (
                                        realisedPLSumPer > 0 ? (
                                            <img
                                                src={upArrow}
                                                className='arrow-icon'
                                                style={{ marginRight: '5px', marginBottom: '-4px' }}
                                            />
                                        ) : (
                                            <img
                                                src={downArrow}
                                                className='arrow-icon'
                                                style={{ marginRight: '5px', marginBottom: '-4px' }}
                                            />
                                        )
                                    ) : (
                                        ''
                                    )}
                                    <span>{realisedPLSumPer !== 0 ? Math.abs(realisedPLSumPer).toFixed(2) : 0}%</span>
                                </div>
                            </StyledTableCell>
                        </StyledTableRow>

                    </TableBody>
                </Table>
            </TableContainer>


            <TableContainer component={Paper} className='table-real-container' style={{ maxHeight: 350, overflow: 'hidden', marginTop: '10px' }}>
                <Typography variant="subtitle1" fontWeight='bold' className='p-1' gutterBottom>
                    POTENTIAL GAIN / LOSS
                </Typography>
                <Table stickyHeader>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell align="center">Metal</StyledTableCell>
                            {realised &&
                                Object.keys(realised).map((metalCode) => {
                                    const metalObject = metalsWithCode.find(
                                        (metal) => metal.code === metalCode
                                    );
                                    const metalName = metalObject ? metalObject.name : metalCode;
                                    return (
                                        <StyledTableCell key={metalCode} align="center">
                                            {metalName}
                                        </StyledTableCell>
                                    );
                                })}
                            <StyledTableCell align="center">Total</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <StyledTableCell><b>Investment value</b></StyledTableCell>
                            {unRealised &&
                                Object.keys(unRealised).map((metal) => (
                                    <StyledTableCell key={`${metal}-invst`} align="right">
                                        ₹{unRealised[metal].totalBuybackAmount.toFixed(2)}
                                    </StyledTableCell>
                                ))}
                            <StyledTableCell align="right">₹{Math.abs(unInvestedSum).toFixed(2)}</StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell><b>Mark to Market value</b></StyledTableCell>
                            {unRealised &&
                                Object.keys(unRealised).map((metal) => (
                                    <StyledTableCell key={`${metal}-invst`} align="right">
                                        ₹{unRealised[metal].totalSellAmount.toFixed(2)}
                                    </StyledTableCell>
                                ))}
                            <StyledTableCell align="right">₹{Math.abs(markToMarkSum).toFixed(2)}</StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell><b>Metal Holdings</b></StyledTableCell>
                            {unRealised &&
                                Object.keys(unRealised).map((metal) => (
                                    <StyledTableCell key={`${metal}-invst`} align="right">
                                        {unRealised[metal].totalSellQuantity.toFixed(4)} g
                                    </StyledTableCell>
                                ))}
                            <StyledTableCell align="center">-</StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell>
                                <b>Gain / Loss (INR)</b>
                            </StyledTableCell>
                            {unRealised &&
                                Object.keys(unRealised).map((metal) => (
                                    <StyledTableCell key={`${metal}-pp`} align="right" style={{ padding: '5px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            {unRealised[metal].totalSellAmount -
                                                unRealised[metal].totalBuybackAmount !== 0 ? (
                                                unRealised[metal].totalSellAmount -
                                                    unRealised[metal].totalBuybackAmount > 0 ? (
                                                    <img
                                                        src={upArrow}
                                                        className="arrow-icon"
                                                        style={{ marginRight: '5px', marginBottom: '-4px' }}
                                                    />
                                                ) : (
                                                    <img
                                                        src={downArrow}
                                                        className="arrow-icon"
                                                        style={{ marginRight: '5px', marginBottom: '-4px' }}
                                                    />
                                                )
                                            ) : (
                                                ''
                                            )}
                                            <span>
                                                ₹
                                                {Math.abs(
                                                    unRealised[metal].totalSellAmount -
                                                    unRealised[metal].totalBuybackAmount
                                                ).toFixed(2)}
                                            </span>
                                        </div>
                                    </StyledTableCell>
                                ))}
                            <StyledTableCell align="right" style={{ padding: '5px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    {unrealisedPLSum !== 0 ? (
                                        unrealisedPLSum > 0 ? (
                                            <img
                                                src={upArrow}
                                                className="arrow-icon"
                                                style={{ marginRight: '5px', marginBottom: '-4px' }}
                                            />
                                        ) : (
                                            <img
                                                src={downArrow}
                                                className="arrow-icon"
                                                style={{ marginRight: '5px', marginBottom: '-4px' }}
                                            />
                                        )
                                    ) : (
                                        ''
                                    )}
                                    <span>₹{Math.abs(unrealisedPLSum).toFixed(2)}</span>
                                </div>
                            </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell>
                                <b>Gain / Loss (%)</b>
                            </StyledTableCell>
                            {unRealised &&
                                Object.keys(unRealised).map((metal) => (
                                    <StyledTableCell key={`${metal}-pp`} align="right" style={{ padding: '5px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            {unRealised[metal].totalSellAmount.toFixed(2) -
                                                unRealised[metal].totalBuybackAmount.toFixed(2) !== 0 ? (
                                                unRealised[metal].totalSellAmount.toFixed(2) -
                                                    unRealised[metal].totalBuybackAmount.toFixed(2) > 0 ? (
                                                    <img
                                                        src={upArrow}
                                                        className="arrow-icon"
                                                        style={{ marginRight: '5px', marginBottom: '-4px' }}
                                                    />
                                                ) : (
                                                    <img
                                                        src={downArrow}
                                                        className="arrow-icon"
                                                        style={{ marginRight: '5px', marginBottom: '-4px' }}
                                                    />
                                                )
                                            ) : (
                                                ''
                                            )}
                                            <span className="right-align">
                                                {unRealised[metal].totalBuybackAmount > 0
                                                    ? Math.abs(
                                                        ((unRealised[metal].totalSellAmount -
                                                            unRealised[metal].totalBuybackAmount) /
                                                            unRealised[metal].totalBuybackAmount) *
                                                        100
                                                    ).toFixed(2)
                                                    : unRealised[metal].totalSellAmount > 0
                                                        ? 100
                                                        : 0}
                                                %
                                            </span>
                                        </div>
                                    </StyledTableCell>
                                ))}
                            <StyledTableCell align="right" style={{ padding: '5px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    {unrealisedPLSumPer !== 0 ? (
                                        <>
                                            {unrealisedPLSumPer > 0 ? (
                                                <img
                                                    src={upArrow}
                                                    className="arrow-icon"
                                                    style={{ marginRight: '5px', marginBottom: '-4px' }}
                                                />
                                            ) : (
                                                <img
                                                    src={downArrow}
                                                    className="arrow-icon"
                                                    style={{ marginRight: '5px', marginBottom: '-4px' }}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    <span>
                                        {unrealisedPLSumPer !== 0 ? Math.abs(unrealisedPLSumPer).toFixed(2) : 0}%
                                    </span>
                                </div>
                            </StyledTableCell>
                        </StyledTableRow>


                    </TableBody>
                </Table>
            </TableContainer>
        </>
    ) : null;
};

export default GainLossSummeryTable;
