import moment from 'moment';
import React from 'react'
import { HEAD_CELLS  as tableChildHeadCells } from "./utils";

interface PropsType {
    displayData:any;
    viewClick: any;
  }


const FundDataTable : React.FC<PropsType> = ({
    displayData,viewClick
  }) => {
    
    return (
        <div>
           
           <table className="text-left border-collapse w-full overflow-y-auto overflow-x-scroll mt-4 table-fixed">
                <thead className="">
                    <tr>
                        <th className="font-bold py-1 text-sm bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell px-2 w-1/6">Customer ID</th>
                        <th className="font-bold py-1 text-sm bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell px-2">Fund Transaction ID</th>
                        <th className="font-bold py-1 text-sm bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell px-2">Order ID</th>
                        <th className="font-bold py-1 text-sm bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell px-2">Amount</th>
                        <th className="font-bold py-1 text-sm bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell px-2">Currency</th>
                        <th className="font-bold py-1 text-sm bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell px-2">Request Time</th>
                        <th className="font-bold py-1 text-sm bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell px-2">Response Time</th>
                        <th className="font-bold py-1 text-sm bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell px-2">Response ID</th>
                        <th className="font-bold py-1 text-sm bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell px-2">View More</th>
                    </tr>
                </thead>
                <tbody className='text-left'>
                    {displayData &&
                    displayData.map((ebl: any, index: any) => {
                    return (<tr key={index} className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0 text-sm">
                        <td style={{overflowWrap: "break-word", wordWrap: "break-word", hyphens: "auto"}} className="px-3 py-2 text-gray-800  border border-b block lg:table-cell relative lg:static">
                            {ebl.fund_trn_id}
                        </td>
                        <td style={{overflowWrap: "break-word", wordWrap: "break-word", hyphens: "auto"}} className="px-3 py-2 text-gray-800  border border-b block lg:table-cell relative lg:static">
                            {ebl.customer_id}
                        </td>
                        <td style={{overflowWrap: "break-word", wordWrap: "break-word", hyphens: "auto"}} className="px-3 py-2 text-gray-800  border border-b block lg:table-cell relative lg:static">
                            {ebl.order_id}
                        </td>
                        <td style={{overflowWrap: "break-word", wordWrap: "break-word", hyphens: "auto"}} className="px-3 py-2 text-gray-800  border border-b block lg:table-cell relative lg:static">
                            {ebl.Amount}
                        </td>
                        <td style={{overflowWrap: "break-word", wordWrap: "break-word", hyphens: "auto"}} className="px-3 py-2 text-gray-800  border border-b block lg:table-cell relative lg:static">
                            {ebl.currency}
                        </td>
                        <td style={{overflowWrap: "break-word", wordWrap: "break-word", hyphens: "auto"}} className="px-3 py-2 text-gray-800  border border-b block lg:table-cell relative lg:static">
                            {moment(ebl?.Request_time).format("YYYY-MM-DD hh:mm:ss")}
                        </td>
                        <td style={{overflowWrap: "break-word", wordWrap: "break-word", hyphens: "auto"}} className="px-3 py-2 text-gray-800  border border-b block lg:table-cell relative lg:static">
                            {ebl.Response_time ? moment(ebl?.Response_time).format("YYYY-MM-DD hh:mm:ss") : ""}
                        </td>
                        <td style={{overflowWrap: "break-word", wordWrap: "break-word", hyphens: "auto"}} className="px-3 py-2 text-gray-800  border border-b block lg:table-cell relative lg:static">
                            {ebl.Response_Id}
                        </td>
                        <td className="px-3 py-2 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                            <button
                                className="bg-indigo-400 text-white rounded px-3 py-1 font-bold"
                                onClick={() => {
                                    viewClick(ebl?.id);
                                }}
                            >
                            View
                        </button>
                        </td>
                    </tr>)
                    })}
                </tbody>
            </table> 
        </div>
    )
}

export default FundDataTable
