import React from "react";
import './Modal.css'
// @ts-ignore
const Modal = ({handleClose, show, children,size = ''}) => {
    const showHideClassName = show ? "fixed hidden inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full modal display-block" : "modal display-none";
    return ('large' !== size)?
     (
        <div className={showHideClassName}>
            <div id="modal">
                <div className="relative top-20 mx-auto p-5 pb-0 border w-96 shadow-lg rounded-md bg-white" >
                    <button
                        className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50"  onClick={handleClose}>
                        <svg className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18"
                             height="18" viewBox="0 0 18 18">
                            <path
                                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                        </svg>
                    </button>
                    <div className="mt-3">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    ):(<div className={showHideClassName}>
            <div id="modal">
                <div className="relative top-20 mx-auto p-5 pb-0 border w-2/3 shadow-lg rounded-md bg-white" >
                    <button
                        className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50"  onClick={handleClose}>
                        <svg className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18"
                             height="18" viewBox="0 0 18 18">
                            <path
                                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                        </svg>
                    </button>
                    <div className="mt-3">
                        {children}
                    </div>
                </div>
            </div>
        </div>)
}

export default Modal;