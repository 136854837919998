export const HEAD_CELLS = [
  {
    label: "fund_trn_id",
  },
  {
    label: "pge",
  },
  {
    label: "Entity",
  },
  {
    label: "Method",
  },
  {
    label: "Partner",
  },
  {
    label: "Customer_ID",
  },
  {
    label: "Sourcemodule",
  },
  {
    label: "order_id",
  },
  {
    label: "Amount",
  },
  {
    label: "currency",
  },
  {
    label: "request_time",
  },
  {
    label: "response_time",
  },
  {
    label: "response_id",
  },
  {
    label: "View More",
  }
];


export const DATA_TYPE = Object.freeze({
  NORMAL: "NORMAL",
  START_DATE: "START_DATE",
  END_DATE: "END_DATE",
  SETTLE_START_DATE: "SETTLE_START_DATE",
  SETTLE_END_DATE: "SETTLE_SETTLE_END_DATE",
});