import axios from "axios";
// axios.defaults.headers.common['Authorization'] = "BEARER IKXyRR6kWGBmJDPvVcY2tTOsfrT1bttJxukXJXp@qpmVYf9jTRipPvR5vFWWE";

export const GetAllCurrencyMaster = async () => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/currency_master/get`;
    resolve(await axios.get(apiUrl));
  });
};

export const GetAllMetalMaster = async () => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/metal_master/get`;
    resolve(await axios.get(apiUrl));
  });
};

export const GetAllMetalUomConvMaster = async () => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/metal_uom_conv_master/get`;
    resolve(await axios.get(apiUrl));
  });
};

export const GetAllItemMaster = async () => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/item_master/get`;
    resolve(await axios.get(apiUrl));
  });
};

export const GetAllTaxGstMaster = async () => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/tax_gst_master/get`;
    resolve(await axios.get(apiUrl));
  });
};

export const GetAllWarehouseMaster = async () => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/warehouse_master/get`;
    resolve(await axios.get(apiUrl));
  });
};
