import Layout from "../../Layout/Layout";
import {useHistory} from "react-router-dom";
import TradeExecutionService from "../Services/trade-execution.service";
import React, {useEffect, useState} from "react";
import Datetime from "react-datetime";
import moment from "moment";
import Loader from "../../../Components-ui/Loader"
import {toast, ToastContainer} from "react-toastify";
import {useAuthStore} from "../../../Store/AuthStore";
import {Pagination} from "../../../Components-ui/Pagination";
import { makeQuery } from "../../../Common/util.helper";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";

interface PropsType {
}

const TradeExecutionTable: React.FC<PropsType> = () => {

    const history = useHistory();

    const { logout, permissions, userData , tokens } = useAuthStore();


    const [TradeExecutions, setTradeExecutions] = useState<any>([]);
    const [partners, setPartners] = useState<any>([]);
    const [Loading, setLoading] = useState<any>(true);
    const [MetaData, setMetaData] = useState<any>({});
    const [CurrentPage, setCurrentPage] = useState<number>(0);

    const [FilterData, setFilterData] = useState<any>({
        description: "",
        ruleType: "",
        mainItemCode: "",
        effectDate: "",
        active: "",
        status: "",
        partnerId: ""
    });
    useEffect((): any => {
        getAllTradeExecutions(CurrentPage);
        getPartners();
    }, []);

    /**
     * Get all trade executions
     * @param page
     * @param isReset
     */
    const getAllTradeExecutions = async (page: number, isReset: boolean = false) => {
        setLoading(true)
        let cloneFilterData = !isReset ? {...FilterData} : {
            description: "",
            ruleType: "",
            mainItemCode: "",
            effectDate: "",
            active: "",
            partnerId: ""
        }
        const query = makeQuery(cloneFilterData);
        let data: any = await TradeExecutionService.getAllTradeExecutions(page,query,tokens);
        if (data.status === 200 && data.data.data) {
            let responseData = data.data.data;
            setMetaData({
                current: data.data.meta.page,
                pages: data.data.meta.totalPages,
            })
            setCurrentPage(page)
            setTradeExecutions(responseData);
            setLoading(false)
        }
    };

     /**
     * Handle filters change event
     * @param e
     */
      const handleChangeFilter = (e: any) => {
        const {name, value} = e.target
        setFilterData({
            ...FilterData,
            [name]: value
        });
    }

    /**
     * Enable or disable trade execution
     * @param e
     * @param data
     */
    const enableTradeExecution = async (e: any, data: any) => {
        setLoading(true)
        let cloneData = {...data}

        cloneData.active = e.target.checked ? 'ENABLED' : 'DISABLED';
        await TradeExecutionService.updateTradeExecutionRule(cloneData, tokens);
        await getAllTradeExecutions(CurrentPage);
        setLoading(false)
        return toast.success("Trade execution was successfully updated");
    }

      /**
     * Reset filters to initial state
     * @param e
     */
       const resetFilterData = async (e: any) => {
        e.preventDefault()
        setFilterData({
            description: "",
            ruleType: "",
            mainItemCode: "",
            effectDate: "",
            active: "",
            partnerId: ""
        })

        setCurrentPage(0)
        await getAllTradeExecutions(0, true)
    }

    const getPartners = async () => {
        let data: any = await TradeExecutionService.getPartners(tokens);
        if (data.status === 200 && data.data.data) {
            let responseData = data.data.data;
            setPartners(responseData);
        }
    };


    return (
        <Layout type={"TradeExecution"}>
            <ToastContainer/>
            <div className="flex justify-between items-center mb-5">
                <h1 className="text-2xl font-bold tracking-tight">
                    {"Trade Execution Rules"}
                </h1>
                {
                    HasAnyAccess(['trading.rule.master.create']) && (
                <button
                    className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
                    type="button"
                    onClick={() => history.push('/trade-execution/add')}
                >
                    + Add Trading Rules
                </button>)}
            </div>
            {Loading ? (<Loader/>) : (
                /* Trade Execution Master Table */
                <div className="w-full rounded border-gray-200 overflow-hidden shadow-lg dark:border-gray-700 bg-white">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Filter Rules</h3>
                </div>
                <hr/>
                <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
                        <div className="col-span-12 sm:col-span-3">
                            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                                Rule Description
                            </label>
                            <input
                                type="text"
                                name="description"
                                id="description"
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                onChange={(e: any) => {
                                    handleChangeFilter(e)
                                }}
                                value={FilterData.description}
                            />
                        </div>
                        <div className="col-span-12 sm:col-span-3">
                            <label htmlFor="ruleType" className="block text-sm font-medium text-gray-700">
                                Type
                            </label>
                            <select
                                id="ruleType"
                                name="ruleType"
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                onChange={(e: any) => {
                                    handleChangeFilter(e)
                                }}
                                value={FilterData.ruleType}
                            >
                                <option value="">All</option>
                                <option value="SELL">SELL</option>
                                <option value="BUYBACK">BUYBACK</option>

                            </select>
                        </div>
                        
                        <div className="col-span-12 sm:col-span-3">
                            <label htmlFor="mainItemCode" className="block text-sm font-medium text-gray-700">
                            Item Code
                            </label>
                            <input
                                id="mainItemCode"
                                name="mainItemCode"
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                onChange={(e: any) => {
                                    handleChangeFilter(e)
                                }}
                                value={FilterData.mainItemCode}
                            >
                            </input>
                        </div>

                        <div className="col-span-12 sm:col-span-3">
                            <label htmlFor="effectDate" className="block text-sm font-medium text-gray-700">
                            Effective Date
                            </label>
                            <div>
                                <Datetime
                                    timeFormat={""}
                                    dateFormat={"DD/MM/YYYY"}
                                     inputProps={{placeholder: 'pick date', className: "focus:outline-none"}}
                                    className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4
             `}
                                    onChange={(event: any) => setFilterData({
                                        ...FilterData,
                                        effectDate: moment(new Date(event).toISOString()).format("MM/DD/YYYY"),
                                    })} value={moment(FilterData.effectDate)}
                                    renderInput={(props) => {
                                        return <input {...props} value={(FilterData.effectDate) ? (FilterData.effectDate) : ''}/>
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-span-12 sm:col-span-3">
                            <label htmlFor="partnerId" className="block text-sm font-medium text-gray-700">
                                Partner
                            </label>
                            <select
                                id="partnerId"
                                name="partnerId"
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                onChange={(e: any) => {
                                    handleChangeFilter(e)
                                }}
                                value={FilterData.partnerId}
                            >
                                <option value="">All</option>
                                {
                                    partners.map((partner: any, index: number) => {
                                        return (
                                            <option key={index} value={parseInt(partner.partnerId)}>{partner.name}</option>
                                        )

                                    })
                                }

                            </select>
                        </div>

                        <div className="col-span-12 sm:col-span-3">
                            <label htmlFor="active" className="block text-sm font-medium text-gray-700">
                                Active
                            </label>
                            <select
                                id="active"
                                name="active"
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                onChange={(e: any) => {
                                    handleChangeFilter(e)
                                }}
                                value={FilterData.active}
                            >
                                <option value="">All</option>
                                <option value="ENABLED">ENABLED</option>
                                <option value="DISABLED">DISABLED</option>

                            </select>
                        </div>

                        <div className="col-span-12 sm:col-span-3">
                            <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                                Status
                            </label>
                            <select
                                id="status"
                                name="status"
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                onChange={(e: any) => {
                                    handleChangeFilter(e)
                                }}
                                value={FilterData.status}
                            >
                                <option value="">All</option>
                                <option value="LOGGED">LOGGED</option>
                                <option value="EXPIRED">EXPIRED</option>
                                <option value="APPROVED">APPROVED</option>
                            </select>
                        </div>
                        <div className="col-span-12 sm:col-span-12 flex justify-end">
                            <button
                                className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                                type="button"
                                onClick={() => getAllTradeExecutions(0)}
                            >
                                Filter
                            </button>
                            <button
                                className="text-white bg-red-600 border border-red-300 rounded-md background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                                type="button"
                                onClick={(e) => resetFilterData(e)}
                            >
                                Reset
                            </button>

                        </div>
                        <div className="col-span-12 sm:col-span-12"> <hr/></div>
                        
                    </div>
                    <div className="border bg-white rounded border-gray-200 shadow">
                        <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                            <thead>
                            <tr className="text-left border-b border-gray-300">
                                <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Rule Description</th>
                                <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Type</th>
                                <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Item Code</th>
                                <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Partner</th>
                                <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Effective From</th>
                                <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Effective To</th>
                                <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Priority</th>
                                <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Active</th>
                                {
                                    HasAnyAccess(['trading.rule.master.edit']) && (
                                <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Status</th>)}
                                {
                                    HasAnyAccess(['trading.rule.master.view']) && (
                                <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Actions</th>)}
                            </tr>
                            </thead>
                            <tbody>
                            { TradeExecutions.length > 0 ?
                                TradeExecutions.map((tradeExecution: any, index: number) => {
                                    return (
                                        <tr key={index}
                                            className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                            <td className="py-3 px-3 text-left relative">
                                                {tradeExecution.description}
                                            </td>
                                            <td className="py-3 px-3 text-left relative">
                                                {tradeExecution.type}
                                            </td>
                                            <td className="py-3 px-3 text-left relative">
                                                {tradeExecution.itemCode}
                                            </td>
                                            <td className="py-3 px-3 text-left relative">
                                                {partners.map((partner: any) => {
                                                    if (tradeExecution.partnerId === partner.partnerId) {
                                                        return partner.name
                                                    } else {
                                                        return ''
                                                    }
                                                })}
                                            </td>
                                            <td className="py-3 px-3 text-left relative">
                                                {moment(tradeExecution.effectFromDate).format("DD-MM-YYYY HH:mm")}
                                            </td>
                                            <td className="py-3 px-3 text-left relative">
                                                {moment(tradeExecution.effectToDate).format("DD-MM-YYYY HH:mm")}
                                            </td>
                                            <td className="py-3 px-3 text-left relative">
                                                {tradeExecution.priority}
                                            </td>
                                                {
                                                    HasAnyAccess(['trading.rule.master.edit']) && (
                                                        <td className="py-3 px-3 text-left relative">
                                                        <div
                                                    className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                    <input
                                                        type="checkbox"
                                                        name="toggle"
                                                        id={`${tradeExecution.id}toggle`}
                                                        className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                                                        checked={tradeExecution.active === 'ENABLED'}
                                                        onChange={(e) => enableTradeExecution(e, tradeExecution)}
                                                    />
                                                    <label htmlFor={`${tradeExecution.id}toggle`}
                                                           className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                                                </div>
                                            </td>
                                                    )}
                                            <td className="py-3 px-3 text-left relative">
                                                {tradeExecution.status}
                                            </td>

                                                {
                                                    HasAnyAccess(['trading.rule.master.view']) && (
                                                        <td className="py-3 px-3 text-left relative">
                                                <button
                                                    className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                    type="button"
                                                    onClick={() => history.push(`/trade-execution/update/${tradeExecution.id}`)}
                                                >
                                                    View
                                                </button>
                                            </td>)}
                                        </tr>
                                    )
                                }) :
                                <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                    <td colSpan={10} className="text-center py-3 px-3 text-left relative">No data to
                                        display
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="col-span-12 sm:col-span-12">
                        {/*Pagination Bar*/}
                        <Pagination
                            meta={MetaData}
                            handlePageChange={(number: number) => getAllTradeExecutions(number)}
                        />
                    </div>
                </div>

            )}
        </Layout>
    );
};

export default TradeExecutionTable;
