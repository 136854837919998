import { forwardRef, useEffect, useState } from "react";
import { useAuthStore } from "../../../Store/AuthStore";
import { useHistory } from "react-router-dom";
import usersIcon from "./icons/users.svg";
import sell from "./icons/sell.svg";
import errorIcon from "./icons/errorIcon.svg";
import verification from "./icons/verification.svg";
import wallet from "./icons/wallet.svg";
import currency from "./icons/currency.svg";
import transaction from "./icons/transaction.svg";
import metalBar from "./icons/metalBar.svg";
import buyback from "./icons/buyback.svg";
import completed from "./icons/completed.svg";
import activeUsers from "./icons/activeUsers.svg";

type Props = {
  label: string;
  value: string;
  icon?: any;
  iconkey?: string;
  color?: string;
  amount?: string;
  percentage?: any;
  count?: any;
  redirectFunc?: (e: any, label: any, iconKey: any) => void;
};

const DashboardCardComponent = ({
  label,
  value,
  icon,
  amount,
  iconkey,
  percentage,
  count,
  redirectFunc,
}: Props) => {
  const { tokens, permissions } = useAuthStore();
  //   const [data, setData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [textColor, setTextColor] = useState<string>("green-500");
  const [textDarkColor, setTextDarkColor] = useState<string>("green-100");
  const [bgColor, setBgColor] = useState<string>("green-100");
  const [bgDarkColor, setBgDarkColor] = useState<string>("green-500");
  const history = useHistory();


  const StatusIcon = () => {
    switch (iconkey) {
      case "pendingOrders":
        // setTextColor("yellow-500");
        // setTextDarkColor("yellow-100");
        // setBgColor("yellow-100");
        // setBgDarkColor("yellow-500");
        return (
          <div
            className={`p-3 rounded-full text-yellow-500 dark:text-yellow-100 bg-yellow-100 dark:bg-yellow-500 mr-4`}
          >
            <img src={errorIcon} />
          </div>
        );
      case "totalUserSignupInADay":
        // setTextColor("blue-500");
        // setTextDarkColor("blue-100");
        // setBgColor("blue-100");
        // setBgDarkColor("blue-500");
        return (
          <div
            className={`p-3 rounded-full text-blue-500 dark:text-blue-100 bg-blue-100 dark:bg-blue-500 mr-4`}
          >
            <img src={usersIcon} />
          </div>
        );

      case "totalCustomersSignupInADay":
        // setTextColor("blue-500");
        // setTextDarkColor("blue-100");
        // setBgColor("blue-100");
        // setBgDarkColor("blue-500");
        return (
          <div
            className={`p-3 rounded-full text-green-500 dark:text-green-100 bg-green-100 dark:bg-green-500 mr-4`}
          >
            <img src={usersIcon} />
          </div>
        );

      case "pennyVerificationTransactions":
        // setTextColor("green-500");
        // setTextDarkColor("green-100");
        // setBgColor("green-100");
        // setBgDarkColor("green-500");
        return (
          <div
            className={`p-3 rounded-full text-green-500 dark:text-green-100 bg-green-100 dark:bg-green-500 mr-4`}
          >
            <img src={verification} />
          </div>
        );

      case "activeUsers":
        // setTextColor("blue-500");
        // setTextDarkColor("blue-100");
        // setBgColor("blue-100");
        // setBgDarkColor("blue-500");
        return (
          <div
            className={`p-3 rounded-full text-blue-500 dark:text-blue-100 bg-blue-100 dark:bg-blue-500 mr-4`}
          >
            <img src={activeUsers} />
          </div>
        );

      case "b2bUsers":
        // setTextColor("blue-500");
        // setTextDarkColor("blue-100");
        // setBgColor("blue-100");
        // setBgDarkColor("blue-500");
        return (
          <div
            className={`p-3 rounded-full text-blue-500 dark:text-blue-100 bg-blue-100 dark:bg-blue-500 mr-4`}
          >
            <img src={usersIcon} />
          </div>
        );

      case "b2bCustomers":
        // setTextColor("blue-500");
        // setTextDarkColor("blue-100");
        // setBgColor("blue-100");
        // setBgDarkColor("blue-500");
        return (
          <div
            className={`p-3 rounded-full text-green-500 dark:text-green-100 bg-green-100 dark:bg-green-500 mr-4`}
          >
            <img src={usersIcon} />
          </div>
        );


      case "b2cUsers":
        // setTextColor("green-500");
        // setTextDarkColor("blue-100");
        // setBgColor("blue-100");
        // setBgDarkColor("blue-500");
        return (
          <div
            className={`p-3 rounded-full text-blue-500 dark:text-blue-100 bg-blue-100 dark:bg-blue-500 mr-4`}
          >
            <img src={usersIcon} />
          </div>
        );

      case "b2cCustomers":
        // setTextColor("blue-500");
        // setTextDarkColor("blue-100");
        // setBgColor("blue-100");
        // setBgDarkColor("blue-500");
        return (
          <div
            className={`p-3 rounded-full text-green-500 dark:text-green-100 bg-green-100 dark:bg-green-500 mr-4`}
          >
            <img src={usersIcon} />
          </div>
        );

      case "activeCustomers":
        // setTextColor("blue-500");
        // setTextDarkColor("blue-100");
        // setBgColor("blue-100");
        // setBgDarkColor("blue-500");
        return (
          <div
            className={`p-3 rounded-full text-green-500 dark:text-green-100 bg-green-100 dark:bg-green-500 mr-4`}
          >
            <img src={activeUsers} />
          </div>
        );

      case "sellTransactions":
        // setTextColor("purple-500");
        // setTextDarkColor("purple-100");
        // setBgColor("purple-100");
        // setBgDarkColor("purple-500");
        return (
          <div
            className={`p-3 rounded-full text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4`}
          >
            <img src={sell} />
          </div>
        );

      case "paymentPendingOrders":
        // setTextColor("yellow-500");
        // setTextDarkColor("yellow-100");
        // setBgColor("yellow-100");
        // setBgDarkColor("yellow-500");
        return (
          <div
            className={`p-3 rounded-full text-yellow-500 dark:text-yellow-100 bg-yellow-100 dark:bg-yellow-500 mr-4`}
          >
            <img src={errorIcon} />
          </div>
        );

      case "invoiceUploadPendingOrders":
        // setTextColor("yellow-500");
        // setTextDarkColor("yellow-100");
        // setBgColor("yellow-100");
        // setBgDarkColor("yellow-500");
        return (
          <div
            className={`p-3 rounded-full text-yellow-500 dark:text-yellow-100 bg-yellow-100 dark:bg-yellow-500 mr-4`}
          >
            <img src={errorIcon} />
          </div>
        );

      case "cancelledOrders":
        // setTextColor("yellow-500");
        // setTextDarkColor("yellow-100");
        // setBgColor("yellow-100");
        // setBgDarkColor("yellow-500");
        return (
          <div
            className={`p-3 rounded-full text-yellow-500 dark:text-yellow-100 bg-yellow-100 dark:bg-yellow-500 mr-4`}
          >
            <img src={errorIcon} />
          </div>
        );

      case "paymentFailedOrders":
        // setTextColor("yellow-500");
        // setTextDarkColor("yellow-100");
        // setBgColor("yellow-100");
        // setBgDarkColor("yellow-500");
        return (
          <div
            className={`p-3 rounded-full text-yellow-500 dark:text-yellow-100 bg-yellow-100 dark:bg-yellow-500 mr-4`}
          >
            <img src={errorIcon} />
          </div>
        );

      case "invoiceApprovalPendingOrders":
        // setTextColor("yellow-500");
        // setTextDarkColor("yellow-100");
        // setBgColor("yellow-100");
        // setBgDarkColor("yellow-500");
        return (
          <div
            className={`p-3 rounded-full text-yellow-500 dark:text-yellow-100 bg-yellow-100 dark:bg-yellow-500 mr-4`}
          >
            <img src={errorIcon} />
          </div>
        );

      case "paymentPendingOrders":
        // setTextColor("yellow-500");
        // setTextDarkColor("yellow-100");
        // setBgColor("yellow-100");
        // setBgDarkColor("yellow-500");
        return (
          <div
            className={`p-3 rounded-full text-yellow-500 dark:text-yellow-100 bg-yellow-100 dark:bg-yellow-500 mr-4`}
          >
            <img src={errorIcon} />
          </div>
        );

      case "walletTransactions":
        // setTextColor("purple-500");
        // setTextDarkColor("purple-100");
        // setBgColor("purple-100");
        // setBgDarkColor("purple-500");
        return (
          <div
            className={`p-3 rounded-full text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4`}
          >
            <img src={wallet} />
          </div>
        );

      case "refundTransactions":
        // setTextColor("purple-500");
        // setTextDarkColor("purple-100");
        // setBgColor("purple-100");
        // setBgDarkColor("purple-500");
        return (
          <div
            className={`p-3 rounded-full text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4`}
          >
            <img src={currency} />
          </div>
        );

      case "totalTransactions":
        // setTextColor("purple-500");
        // setTextDarkColor("purple-100");
        // setBgColor("purple-100");
        // setBgDarkColor("purple-500");
        return (
          <div
            className={`p-3 rounded-full text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4`}
          >
            <img src={transaction} />
          </div>
        );

      case "Ag":
        // setTextColor("gray-400");
        // setTextDarkColor("gray-100");
        // setBgColor("gray-200");
        // setBgDarkColor("gray-500");
        return (
          <div
            className={`p-3 rounded-full text-gray-400 dark:text-gray-100 bg-gray-200 dark:bg-gray-500 mr-4`}
          >
            <img src={metalBar} />
          </div>
        );

      case "Au":
        // setTextColor("yellow-500");
        // setTextDarkColor("yellow-100");
        // setBgColor("yellow-100");
        // setBgDarkColor("yellow-500");
        return (
          <div
            className={`p-3 rounded-full text-yellow-500 dark:text-yellow-100 bg-yellow-100 dark:bg-yellow-500 mr-4`}
          >
            <img src={metalBar} />
          </div>
        );

      case "Pd":
        // setTextColor("gray-700");
        // setTextDarkColor("gray-100");
        // setBgColor("gray-200");
        // setBgDarkColor("gray-500");
        return (
          <div
            className={`p-3 rounded-full text-gray-700 dark:text-gray-100 bg-gray-200 dark:bg-gray-500 mr-4`}
          >
            <img src={metalBar} />
          </div>
        );

      case "Pt":
        // setTextColor("gray-500");
        // setTextDarkColor("gray-100");
        // setBgColor("gray-200");
        // setBgDarkColor("gray-500");
        return (
          <div
            className={`p-3 rounded-full text-gray-500 dark:text-gray-100 bg-gray-200 dark:bg-gray-500 mr-4`}
          >
            <img src={metalBar} />
          </div>
        );

      case "buyBackTransactions":
        // setTextColor("purple-500");
        // setTextDarkColor("purple-100");
        // setBgColor("purple-100");
        // setBgDarkColor("purple-500");
        return (
          <div
            className={`p-3 rounded-full text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4`}
          >
            <img src={buyback} />
          </div>
        );

      default:
        return (
          <div
            className={`p-3 rounded-full text-green-500 dark:text-green-100 bg-green-100 dark:bg-green-500 mr-4`}
          >
            <img src={completed} />
          </div>
        );
        break;
    }
  };

  return (
    <div
      className="min-w-0 rounded-lg shadow-xs overflow-hidden bg-gray-100 dark:bg-gray-800"
      // onClick={(e) => redirectFunc?.(e, label, iconkey)}
      onClick={
        iconkey == "totalUserSignupInADay"
          ? (e) => { }
          : (e) => redirectFunc?.(e, label, iconkey)
      }
      style={{ cursor: "pointer" }}
    >
      <div className="p-4 flex items-center">
        {/* <div
          className={`p-3 rounded-full text-${textColor} dark:text-${textDarkColor} bg-${bgColor} dark:bg-${bgDarkColor} mr-4`}
        > */}

        {StatusIcon()}
        {/* </div> */}
        <div>
          <p className="mb-2 text-sm font-medium text-gray-500 dark:text-gray-300">
            {label}
            {percentage ?
              (percentage !== 'NaN' && percentage !== 'Infinity' ?
                <span className="text-blue-600 dark:text-blue-400"> ({percentage}%)</span>
                :
                <span className="text-red-500 dark:text-red-400"> (0.00%)</span>
              )
              :
              ""
            }
          </p>

          {count >= 0 && (
            <p className="mb-2 text-sm font-medium text-purple-600 dark:text-purple-400">
              Count: {count}
            </p>
          )}

          <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
            {value}
          </p>

          {amount && (
            <p className="text-sm font-semibold text-green-600 dark:text-green-400">
              ₹ {amount}
            </p>
          )}
        </div>

      </div>
    </div>
  );
};

export default DashboardCardComponent;
