import React from "react";
import { Route } from "react-router-dom";

import CustomDutyTable from "../Components/CustomDutyTable";


export const CustomsDutyRoute: React.FC = () => {
    return (
        <>
            <Route path="/customs-duty" exact component={CustomDutyTable} />
        </>
    );
};
