import { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { BarlistSummery } from "./Metal.Service";
import { Transition } from "@headlessui/react";
import { jsPDF, jsPDFOptions } from "jspdf";
import autoTable from "jspdf-autotable";
import Datetime from "react-datetime";
import { ExportToExcel } from "../Util/ExcelExport";
import moment from "moment";
import { generateDailyBarlistSummaryPdf } from "../Reports/PdfGenarate/pdf-generate";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useAuthStore } from "../../Store/AuthStore";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const SummeryReport: React.FC = () => {
  const { tokens } = useAuthStore();

  const [filteredData, setfilteredData] = useState<any>([]);
  const [vaultList, setVaultList] = useState<any>([]);
  const [metalList, setMetalList] = useState<any>([]);
  const [reportsList, setReportsList] = useState<any>([]);
  const [isFirstTime, setIsFirstTime] = useState<any>(true);
  const [reportModalOpen, setReportModalOpen] = useState<any>(false);

  const [selectedVault, setSelectedVault] = useState<any>("ALL");
  const [selectedMetal, setSelectedMetal] = useState<any>("ALL");
  const [selectedDate, setSelectedDate] = useState<any>("");
  const [selectedReports, setSelectedReports] = useState<any>([]);
  const [grouped, setGrouped] = useState<any>(true);
  const [fineness, setFineness] = useState<any>(true);

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = (params: any = {}) => {
    BarlistSummery(params)
      .then((data: any) => {
        setfilteredData(data.data.data);
        setReportsList(data.data.reports);
        if (isFirstTime) {
          setVaultList(data.data.vaultList);
          setMetalList(data.data.metalList);
          setIsFirstTime(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeFilters = (type: string, value: any) => {
    let params: any = {};
    if (type == "VAULT") {
      setSelectedVault(value);
      if (value != "ALL") {
        params["vaultId"] = value;
      }
      if (selectedMetal != "ALL") {
        params["metalCode"] = selectedMetal;
      }
      if (selectedDate) {
        params["date"] = new Date(selectedDate).toISOString();
      }
    } else if (type == "METAL") {
      setSelectedMetal(value);
      if (value != "ALL") {
        params["metalCode"] = value;
      }
      if (selectedVault != "ALL") {
        params["vaultId"] = selectedVault;
      }
      if (selectedDate) {
        params["date"] = new Date(selectedDate).toISOString();
      }
    } else if (type == "DATE") {
      setSelectedDate(value);
      if (value) {
        params["date"] = new Date(value).toISOString();
      }
      if (selectedMetal != "ALL") {
        params["metalCode"] = selectedMetal;
      }
      if (selectedVault != "ALL") {
        params["vaultId"] = selectedVault;
      }
    }
    fetchdata(params);
  };

  const changeSelectedReport = (e: any) => {
    console.log(e.target.value, "@target values...");

    const value = Number(e.target.value);
    const index = selectedReports.findIndex((r: any) => r.id == value);
    if (index >= 0) {
      setSelectedReports(selectedReports.filter((r: any) => r.id != value));
    } else {
      setSelectedReports([
        ...selectedReports,
        reportsList.find((r: any) => r.id == value),
      ]);
    }
  };

  console.log(selectedReports, "@selectedReports");

  const calculateTotal = (data: any, column: string) => {
    let value = data.reduce((a: any, c: any) => {
      if (!(c.isSubTotal || c.isTotal)) a += Number.parseFloat(c[column]);
      return a;
    }, 0);
    return roundNo(value, column == "Weight" ? 1 : 4);
  };

  // const generateReports = async () => {
  //   let doc: any = new jsPDF();

  //   for (let i = 0; i < selectedReports.length; i++) {
  //     let report = selectedReports[i];
  //     //filter selected report data
  //     const dataset = filteredData.reduce((a: any, c: any) => {
  //       if (c.vaultId == report.vaultId && c.metalCode == report.metalCode) {
  //         if (c.isTotal) {
  //           a.push([
  //             {
  //               text: "Grand Total",
  //               bold: true,
  //             },
  //             "",
  //             "",
  //             {
  //               text: c.weightSum,
  //               bold: true,
  //             },
  //             {
  //               text: c.equivalentWeightSum,
  //               bold: true,
  //             },
  //             "",
  //           ]);
  //         }
  //         if (c.isSubTotal && grouped) {
  //           a.push([
  //             {
  //               text: c.subTotalTitle,
  //               bold: true,
  //             },
  //             "",
  //             "",
  //             {
  //               text: c.weightSum,
  //               bold: true,
  //             },
  //             {
  //               text: c.equivalentWeightSum,
  //               bold: true,
  //             },
  //             "",
  //           ]);
  //         }

  //         if (!c.isTotal && !c.isSubTotal) {
  //           a.push([
  //             c.itemName,
  //             c.barNo,
  //             c.brandCode,
  //             c.weight,
  //             c.equivalentWeight,
  //             c.fineness,
  //           ]);
  //         }
  //       }
  //       return a;
  //     }, []);

  //     const docDefinition: any = await generateDailyBarlistSummaryPdf(
  //       tokens,
  //       dataset,
  //       report
  //     );
  //     pdfMake.createPdf(docDefinition).download("Daily Barlist Summary Report");
  //   }
  // };

  const generateReports = async () => {
    let combinedDataset: any[] = [];

    for (let i = 0; i < selectedReports.length; i++) {
      let report = selectedReports[i];
      // filter selected report data
      const dataset = filteredData.reduce((a: any, c: any) => {
        if (c.vaultId == report.vaultId && c.metalCode == report.metalCode) {
          if (c.isTotal) {
            a.push([
              {
                text: "Grand Total",
                bold: true,
              },
              "",
              "",
              {
                text: c.weightSum,
                bold: true,
              },
              {
                text: c.equivalentWeightSum,
                bold: true,
              },
              "",
            ]);
          }
          if (c.isSubTotal && grouped) {
            a.push([
              {
                text: c.subTotalTitle,
                bold: true,
              },
              "",
              "",
              {
                text: c.weightSum,
                bold: true,
              },
              {
                text: c.equivalentWeightSum,
                bold: true,
              },
              "",
            ]);
          }

          if (!c.isTotal && !c.isSubTotal) {
            a.push([
              c.itemName,
              c.barNo,
              c.brandCode,
              c.weight,
              c.equivalentWeight,
              c.fineness,
            ]);
          }
        }
        return a;
      }, []);

      // accumulate data for all selected reports
      combinedDataset = [...combinedDataset, ...dataset];
    }

    // generate a single report for all selected options
    const docDefinition: any = await generateDailyBarlistSummaryPdf(
      tokens,
      combinedDataset,
      selectedReports[0] // You can pass any one of the selected reports for metadata
    );
    pdfMake.createPdf(docDefinition).download("Daily Barlist Summary Report");
  };

  // const generateExcelReports = () => {
  //   for (let i = 0; i < selectedReports.length; i++) {
  //     let report = selectedReports[i];
  //     //filter selected report data
  //     const dataset = filteredData.reduce((a: any, c: any) => {
  //       if (c.vaultId == report.vaultId && c.metalCode == report.metalCode) {
  //         let item: any = {};
  //         if ((c.isSubTotal && grouped) || c.isTotal) {
  //           item["Item name"] = c.isTotal ? "Grand Total" : c.subTotalTitle;
  //           item["Bar reference"] = "";
  //           item["Brand"] = "";
  //           item["Weight in grams"] = c.weightSum;
  //           item["Equivalent metal weight in grams"] = c.equivalentWeightSum;
  //           if (fineness) {
  //             item["Fineness"] = "";
  //           }
  //           a.push(item);
  //         } else {
  //           item["Item name"] = c.itemName;
  //           item["Bar reference"] = c.barNo;
  //           item["Brand"] = c.brandCode;
  //           item["Weight in grams"] = c.weight;
  //           item["Equivalent metal weight in grams"] = c.equivalentWeight;
  //           if (fineness) {
  //             item["Fineness"] = c.fineness;
  //           }
  //           a.push(item);
  //         }
  //       }
  //       return a;
  //     }, []);

  //     ExportToExcel(dataset, report.title, ColTypes);
  //   }
  // };

  const generateExcelReports = () => {
    let combinedDataset: any[] = [];

    for (let i = 0; i < selectedReports.length; i++) {
      let report = selectedReports[i];
      // filter selected report data
      const dataset = filteredData.reduce((a: any, c: any) => {
        if (c.vaultId == report.vaultId && c.metalCode == report.metalCode) {
          let item: any = {};
          if ((c.isSubTotal && grouped) || c.isTotal) {
            item["Item name"] = c.isTotal ? "Grand Total" : c.subTotalTitle;
            item["Bar reference"] = "";
            item["Brand"] = "";
            item["Weight in grams"] = c.weightSum;
            item["Equivalent metal weight in grams"] = c.equivalentWeightSum;
            if (fineness) {
              item["Fineness"] = "";
            }
            a.push(item);
          } else {
            item["Item name"] = c.itemName;
            item["Bar reference"] = c.barNo;
            item["Brand"] = c.brandCode;
            item["Weight in grams"] = c.weight;
            item["Equivalent metal weight in grams"] = c.equivalentWeight;
            if (fineness) {
              item["Fineness"] = c.fineness;
            }
            a.push(item);
          }
        }
        return a;
      }, []);

      // accumulate data for all selected reports
      combinedDataset = [...combinedDataset, ...dataset];
    }

    // export the combined dataset to Excel
    ExportToExcel(combinedDataset, "Bar Summary Report", ColTypes);
  };

  const savePDF = (doc: any, report: any) => {
    let count = 0;
    let initCols = ["Item Name", "Bar No", "Brand", "Weight", "Eq Weight"];
    if (fineness) {
      initCols.push("Fineness");
    }
    const columns = [initCols];

    doc.setFontSize(10);
    doc.text(report.info, 8, 19);

    autoTable(doc, {
      head: columns,
      body: report.dataset,
      startY: 38,
      styles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 9,
        cellPadding: { horizontal: 2, vertical: 2.5 },
      },
      tableLineColor: [189, 195, 199],
      tableLineWidth: 0.2,
      columnStyles: {
        2: { cellWidth: 50 },
      },
      didDrawPage: (dataArg: any) => {
        doc.setFontSize(13);
        doc
          .text(report.title, dataArg.settings.margin.left, 10)
          .setFont("Roboto", "bold");
        doc.line(8, 13, doc.internal.pageSize.getWidth() - 8, 13);
      },
      didParseCell: (data: any) => {
        data.cell.styles.lineColor = [189, 195, 199];
        data.cell.styles.lineWidth = 0.2;
        alignCol(data);

        if (data.row.section == "head" || data.row.raw[0].includes("Total")) {
          data.cell.styles.fillColor = [235, 235, 235];
          data.cell.styles.fontStyle = "bold";
        } else {
          data.cell.styles.fillColor = [255, 255, 255];
        }
      },
      margin: { top: 20, bottom: 15, horizontal: 8 },
    });

    const pageCount = doc.internal.pages.length;
    return doc;
  };

  const alignCol = (data: any) => {
    if ([3, 4, 5].includes(data.column.index)) {
      data.cell.styles.halign = "right";
    }
  };

  const roundNo = (amount: number, size: number = 4) => {
    let calc = Math.round(amount * 10000) / 10000;
    return calc.toFixed(size);
  };

  return (
    <Layout type={"VaultingModule"}>
      <div className="flex justify-between content-bottom">
        <div className="py-1  flex mb-3 items-center w-full">
          <div>
            <h5 className=" font-bold tracking-tight text-gray-600 sm:text-2xl">
              Daily Bar List Summary
            </h5>
            <span className="text-sm text-left font-bold text-gray-800">{`Date to : ${moment(
              selectedDate ? selectedDate : new Date()
            ).format("Do MMMM YYYY, h:mm:ss a")}`}</span>
          </div>
        </div>
      </div>
      <div className="flex justify-between bg-white border-gray-300 border px-2 rounded mb-2">
        <div className="flex">
          <div className="py-2 text-left flex">
            <select
              className={`appearance-none rounded  block w-full px-2 
              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
            focus:outline-none text-sm`}
              value={selectedVault}
              onChange={(e) => changeFilters("VAULT", e.target.value)}
            >
              <option value="ALL" selected={selectedVault == "ALL"}>
                Vault : All
              </option>
              {vaultList.map((vault: any) => (
                <option
                  value={vault}
                  selected={selectedVault == vault}
                >{`Vault : ${vault}`}</option>
              ))}
            </select>
          </div>
          <div className="py-2 text-left flex ml-2">
            <select
              className={`appearance-none rounded  block w-full px-2 
              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
            focus:outline-none text-sm`}
              value={selectedMetal}
              onChange={(e) => changeFilters("METAL", e.target.value)}
            >
              <option value="ALL" selected={selectedMetal == "ALL"}>
                Metal : All
              </option>
              {metalList.map((metal: any) => (
                <option
                  value={metal}
                  selected={selectedMetal == metal}
                >{`Metal : ${metal}`}</option>
              ))}
            </select>
          </div>
          <div className="py-2 text-left flex ml-2">
            <select
              className={`appearance-none rounded  block w-full px-2 
              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
            focus:outline-none text-sm`}
              value={grouped ? "1" : "0"}
              onChange={(e) => {
                setGrouped(e.target.value == "1");
              }}
            >
              <option value="1">Group by Items</option>
              <option value="0">Group by None</option>
            </select>
          </div>
          <div className="py-2 text-left flex ml-2">
            <select
              className={`appearance-none rounded  block w-full px-2 
              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
            focus:outline-none text-sm`}
              value={fineness ? "1" : "0"}
              onChange={(e) => {
                setFineness(e.target.value == "1");
              }}
            >
              <option value="1">Include Fineness</option>
              <option value="0">Without Fineness</option>
            </select>
          </div>
          <div className="py-2 text-left flex ml-2">
            <div id="endPicker" className="">
              <Datetime
                inputProps={{
                  readOnly: true,
                  className:
                    "border text-sm px-3 border-gray-300 rounded py-2 focus:outline-none",
                  placeholder: "Select Date",
                }}
                value={selectedDate}
                timeFormat={true}
                dateFormat={"DD/MM/YYYY"}
                onChange={(e: any) => {
                  changeFilters("DATE", e);
                }}
                renderInput={(props: any) => {
                  return (
                    <input {...props} value={selectedDate ? props.value : ""} />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="py-2 text-right flex">
          <button
            type="button"
            onClick={() => setReportModalOpen(reportsList.length > 0)}
            className="ml-2 inline-flex justify-center px-3 py-2 border border-transparent text-sm font-semibold rounded text-purple-800 
          bg-purple-200 hover:bg-purple-300 focus:outline-none"
          >
            Download Reports
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <div className="w-full">
          <div className=" mt-1 mb-6">
            <table
              className="w-full table-auto rounded text-xs border border-gray-300"
              id="tabledata"
            >
              <thead>
                {/* first row */}
                <tr className="sticky top-1 px-2 py-2 text-gray-900 bg-gray-300">
                  <th className="align-top py-2 px-2 text-center">
                    Vault Name
                  </th>
                  <th className="align-top py-2 px-2 text-center">Item name</th>
                  <th className="align-top py-2 px-2 text-center">Item Code</th>
                  <th className="align-top py-2 px-2 text-center">
                    Metal Code
                  </th>
                  <th className="align-top py-2 px-2 text-center">
                    Bar reference
                  </th>
                  <th className="align-top py-2 px-2 text-center">Brand</th>
                  <th className="align-top py-2 px-2 text-right">
                    Weight in grams
                  </th>
                  <th className="align-top py-2 px-2 text-right">
                    Equivalent metal weight in grams
                  </th>
                  {fineness && (
                    <th className="align-top py-2 px-2 text-right">Fineness</th>
                  )}
                  {/* <th className="align-top py-2 px-2 text-left">Actions</th> */}
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm ">
                <tr>
                  <td
                    className="px-2 pt-1 text-right bg-gray-400"
                    colSpan={fineness ? 9 : 8}
                  ></td>
                </tr>
                {filteredData.map((row: any, index: number) => {
                  if (!(row.isSubTotal || row.isTotal)) {
                    return (
                      <tr>
                        <td className="py-3 px-2 text-center bg-white ">
                          <span className="font-medium text-xs">
                            {row.vaultId}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-center bg-white ">
                          <span className="font-medium text-xs">
                            {row.itemName}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-center bg-white ">
                          <span className="font-medium text-xs">
                            {row.itemCode}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-center bg-white ">
                          <span className="font-medium text-xs">
                            {row.metalCode}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-center bg-white ">
                          <span className="font-medium text-xs">
                            {row.barNo}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-center bg-white">
                          <span className="font-medium text-xs">
                            {row.brandCode}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-right bg-white ">
                          <span className="font-medium text-xs">
                            {row.weight}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-right bg-white ">
                          <span className="font-medium text-xs">
                            {row.equivalentWeight}
                          </span>
                        </td>
                        {fineness && (
                          <td className="py-3 px-2 text-right bg-white ">
                            <span className="font-medium text-xs">
                              {row.fineness}
                            </span>
                          </td>
                        )}
                      </tr>
                    );
                  } else if (row.isSubTotal && grouped) {
                    return (
                      <tr>
                        <td
                          className="py-3 px-2 text-left bg-gray-100"
                          colSpan={6}
                        >
                          <span className="font-semibold text-xs">{`${row.info}  ${row.subTotalTitle}`}</span>
                        </td>
                        <td className="py-3 px-2 text-right bg-gray-100">
                          <span className="font-semibold text-xs">
                            {row.weightSum}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-right bg-gray-100">
                          <span className="font-semibold text-xs">
                            {row.equivalentWeightSum}
                          </span>
                        </td>
                        {fineness && (
                          <td className="py-3 px-2 text-right bg-gray-100"></td>
                        )}
                      </tr>
                    );
                  } else if (row.isTotal) {
                    return (
                      <>
                        <tr>
                          <td
                            className="py-3 px-2 text-left bg-gray-200"
                            colSpan={6}
                          >
                            <span className="font-bold text-xs">{`${row.info}  Grand Total`}</span>
                          </td>
                          <td className="py-3 px-2 text-right bg-gray-200">
                            <span className="font-bold text-xs">
                              {row.weightSum}
                            </span>
                          </td>
                          <td className="py-3 px-2 text-right bg-gray-200">
                            <span className="font-bold text-xs">
                              {row.equivalentWeightSum}
                            </span>
                          </td>
                          {fineness && (
                            <td className="py-3 px-2 text-right bg-gray-200"></td>
                          )}
                        </tr>

                        <tr>
                          <td
                            className="px-2 pt-1 text-right bg-gray-400"
                            colSpan={fineness ? 9 : 8}
                          ></td>
                        </tr>
                      </>
                    );
                  }
                })}

                {filteredData.length == 0 ? (
                  <tr>
                    <td
                      className="py-5 px-1 text-center bg-white font-semibold"
                      colSpan={fineness ? 9 : 8}
                    >
                      {isFirstTime ? "Loading..." : "No Records Found !"}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td
                      className="py-4 px-2 bg-white text-xs font-bold"
                      colSpan={6}
                    >
                      Grand Total
                    </td>
                    <td className="py-4 px-2 bg-white text-xs font-bold text-right">
                      {calculateTotal(filteredData, "weight")}
                    </td>
                    <td className="py-4 px-2 bg-white text-xs font-bold text-right">
                      {calculateTotal(filteredData, "equivalentWeight")}
                    </td>
                    {fineness && (
                      <td className="py-4 px-2 bg-white text-xs font-bold"></td>
                    )}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Transition
        show={reportModalOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="w-full">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <div className="flex">
                      <div className="mt-0">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Barlist Report Options
                        </h3>
                        <p className="text-sm text-gray-500 mb-2">
                          Please fill the list of reports you want to download.
                        </p>
                        <hr className=""></hr>
                      </div>
                      <div className="items-ends">
                        <button
                          onClick={() => {
                            setReportModalOpen(false);
                            setSelectedReports([]);
                          }}
                          type="button"
                          className="mt-3 w-full inline-flex justify-center top-5 right-5 absolute rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                          X
                        </button>
                      </div>
                    </div>
                    <div className="w-full">
                      {reportsList.map((report: any) => (
                        <div className="flex cursor-pointer my-1 hover:bg-blue-lightest rounded">
                          <div className="py-1 px-0 flex">
                            <label className="inline-flex items-center mr-2">
                              <input
                                type="checkbox"
                                value={report.id}
                                className="form-checkbox h-3 w-3 text-gray-600"
                                onChange={changeSelectedReport}
                                checked={selectedReports.some(
                                  (r: any) => r.id == report.id
                                )}
                              />
                            </label>
                            <p className="text-xs text-gray-800 font-semibold">
                              {report.title}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="flex my-3">
                      <button
                        type="button"
                        onClick={() => setSelectedReports(reportsList)}
                        className="justify-center rounded-md border border-transparent shadow-sm
                         px-4 py-1 bg-blue-400  font-medium text-white  focus:outline-none text-xs"
                      >
                        Select All
                      </button>
                      <button
                        onClick={() => generateReports()}
                        type="button"
                        className="justify-center rounded-md border border-transparent shadow-sm ml-2
                         px-4 py-1 bg-green-400  font-medium text-white  focus:outline-none text-xs"
                      >
                        Generate PDF
                      </button>
                      <button
                        onClick={() => generateExcelReports()}
                        type="button"
                        className="justify-center rounded-md border border-transparent shadow-sm ml-2
                         px-4 py-1 bg-blue-400  font-medium text-white  focus:outline-none text-xs"
                      >
                        Generate Excel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </Layout>
  );
};

const ColTypes = [
  { index: 3, type: "n" },
  { index: 4, type: "n" },
  { index: 5, type: "n" },
];

export default SummeryReport;
