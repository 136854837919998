import Http from "../../../library/http.library";
import {httpMethods} from "../../../library/enum";

class TaxCategoryService {

    /**
     * Get all tax categories
     * @param page
     * @param tokens
     * @param query
     * @param isPaginated
     */
    async getAllTaxCategories(page:number, tokens: any, query: any, isPaginated: boolean = true) {
        return await Http.setMethod(httpMethods.get).setUrl(`/tax-cat-master?page=${page}${query}${!isPaginated ? `&isPaginated=false` : ``}`).setToken(tokens.idToken.jwtToken).request();
    }

    /**
     * Add new tax category
     * @param data
     * @param tokens
     */
    async addTaxCategory(data: any, tokens: any) {
        return await Http.setMethod(httpMethods.post).setUrl(`/tax-cat-master`).setData([data]).setToken(tokens.idToken.jwtToken).request();

    }

    /**
     * Update tax category details
     * @param data
     * @param tokens
     */
    async updateTaxCategory(data: any, tokens: any) {
        return Http.setMethod(httpMethods.patch).setUrl(`/tax-cat-master/${data.id}`).setData(data).setToken(tokens.idToken.jwtToken).request();
    }

}

export default new TaxCategoryService();
