import Layout from "../Layout/Layout";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Components-ui/Loader";
import BarSummaryService from "./BarSummaryService";
import moment from "moment";
import { useAuthStore } from "../../Store/AuthStore";
import { Pagination } from "../../Components-ui/Pagination";
import { SetConfirmAlert, SetPopupAlert } from "../Util/util.component";
import HasAnyAccess from "../../Components-ui/hasAnyAccess";
import XLSX from "xlsx";
const HsnMaster: React.FC = () => {
  const { logout, permissions, userData, tokens } = useAuthStore();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [barList, setBarList] = useState<any>([]);
  const [metal, setMetal] = useState<any>([]);
  const [meta, setMetaData] = useState<any>({});
  const [Loading, setLoading] = useState<any>(true);
  const [filters, setFilters] = useState<any>({
    warehouseId: "",
    metalCode: "",
    itemCode: "",
    barNo: "",
    weight: "",
    status: "",
    trxLock: "",
  });

  useEffect(() => {
    loadData({}, 0);
    fetchMetalList();
  }, []);

  const handleChangeFilter = (e: any) => {
    const { name, value } = e.target;

    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const fetchMetalList = async () => {
    let params = {
      isPaginated: false,
    };

    await BarSummaryService.getMetalMasterData(tokens, params)
      .then((res: any) => {
        setMetal(res.data.data);
      })
      .catch((err) => {
        toast.error("Metal data load error!");
      });
  };

  const downloadMetalList = async (params:any,page:any) => {
    params.isPaginated=false;
    await BarSummaryService.getBarSummaryData(tokens, params,page)
      .then((res: any) => {
          console.log("res",res.data.data);
          const worksheet = XLSX.utils.json_to_sheet(res.data.data);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Bar Summary");
          XLSX.writeFile(workbook, `Bar-Summary.xlsx`);
      })
      .catch((err) => {
        toast.error("Metal data load error!");
      });
  };


  const barLockUnlock = async (bar: any) => {
    SetConfirmAlert(
      "Confirm Alert",
      `Are you sure you want to ${bar.trxLock ? "Unlock" : "Lock"} ${
        bar.barNo
      } bar?`,
      async () => {
        await BarSummaryService.lockUnlockBar(tokens, bar.id, {
          trxLock: !bar.trxLock,
        });
        loadData(filters, currentPage);
      },
      () => {}
    );
  };

  const loadData = async (params: any, page: number) => {
    setLoading(true);
    params.isPaginated=true;
    await BarSummaryService.getBarSummaryData(tokens, params, page)
      .then((response: any) => {
        setBarList(response.data.data.result);
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPage(response.data.meta.page);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Bar list load failed!");
      });
  };

  const ResetForm = async (e: any) => {
    e.preventDefault();
    setCurrentPage(0);
    setFilters({
      warehouseId: "",
      metalCode: "",
      itemCode: "",
      barNo: "",
      weight: "",
      status: "",
      trxLock: "",
    });

    loadData({}, 0);
  };
  const paginationFilter = async (page: number) => {
    setCurrentPage(page);
    loadData(filters, page);
  };

  return (
    <Layout type={"Reports"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">
          {"Bar List Summary"}
        </h1>
      </div>

      {/* filter rules */}
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Filter Records
        </h3>
      </div>
      <hr />
      <form
        onSubmit={(e) => {
          loadData(filters, 0);
          e.preventDefault();
        }}
      >
        <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="metal"
              className="block text-sm font-medium text-gray-700"
            >
              Metal
            </label>

            <select
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              id="metalCode"
              name="metalCode"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={filters.metalCode}
            >
              <option value="">All</option>
              {metal.map((metal: any, index: number) => {
                return (
                  <option key={index} value={metal.metalCode}>
                    {metal.metalCode}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="warehouseId"
              className="block text-sm font-medium text-gray-700"
            >
              Warehouse
            </label>
            <input
              type="text"
              name="warehouseId"
              id="warehouseId"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={filters.warehouseId}
            />
          </div>
          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="itemCode"
              className="block text-sm font-medium text-gray-700"
            >
              Item Code
            </label>
            <input
              type="text"
              name="itemCode"
              id="itemCode"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={filters.itemCode}
            />
          </div>
          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="barNo"
              className="block text-sm font-medium text-gray-700"
            >
              Bar Number
            </label>
            <input
              type="text"
              name="barNo"
              id="barNo"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={filters.barNo}
            />
          </div>
          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="status"
              className="block text-sm font-medium text-gray-700"
            >
              status
            </label>

            <select
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              id="status"
              name="status"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={filters.status}
            >
              <option value="">All</option>
              <option key="INWAREHOUSE" value="INWAREHOUSE">
                INWAREHOUSE
              </option>
              <option key="INTRANSIT" value="INTRANSIT">
                INTRANSIT
              </option>
            </select>
          </div>
          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="trxLock"
              className="block text-sm font-medium text-gray-700"
            >
              Transcation Lock
            </label>

            <select
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              id="trxLock"
              name="trxLock"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={filters.trxLock}
            >
              <option value="">All</option>
              <option value="true">Locked</option>
              <option value="false">Unlock</option>
            </select>
          </div>
          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="weight"
              className="block text-sm font-medium text-gray-700"
            >
              Weight
            </label>
            <input
              type="text"
              name="weight"
              id="weight"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={filters.weight}
            />
          </div>

          <div className="col-span-12 sm:col-span-12 flex justify-end">
            {/* <button
            className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
            type="button"
            onClick={() => loadData(filters, 0)}
          >
            Filter
          </button> */}
            <input
              className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
              type="submit"
              value="Filter"
            />
            <button
              className="text-white bg-red-600 border border-red-300 rounded-md background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150 mr-2"
              type="button"
              onClick={(e) => ResetForm(e)}
            >
              Reset
            </button>
            <button
              className="text-white bg-purple-950 border rounded-md background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
              type="button"
              onClick={(e) => downloadMetalList(filters,0)}
            >
              Download
            </button>
          </div>
        </div>
      </form>
      <div className="col-span-12 sm:col-span-12">
        {" "}
        <hr />
      </div>
      {/* end filter */}
      <div className="border bg-white rounded border-gray-200 shadow">
        {Loading ? (
          <Loader />
        ) : (
          <div>
            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
              <thead>
                <tr className="text-left border-b border-gray-300">
                  <th className="sticky top-0 px-3 py-2 bg-gray-800">Metal</th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Item Code
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Warehouse
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Bar Number
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Weight
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Fineness
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Equivalent Weight
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Lock Weight
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Allocated Weight
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Status
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Added At
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Bar Lock
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Transaction Lock
                  </th>
                </tr>
              </thead>
              <tbody>
                {0 !== barList.length ? (
                  barList.map((bar: any, index: number) => {
                    return (
                      <tr
                        key={index}
                        className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                      >
                        <td className="py-3 px-3 text-left relative">
                          {bar.metalCode}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.itemCode}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.warehouseId}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.barNo}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.weight}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.fineness}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.equivalentWeight}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.lockWeight}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.allocatedWeight}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.status}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {moment(bar.ceratedAt).format("DD-MM-YYYY HH:mm")}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          <label>{bar.isLocked ? "Lock" : "Unlock"}</label>
                        </td>
                        {HasAnyAccess(["bar.summary.edit"]) ? (
                          <td className="py-3 px-3 text-left relative">
                            <button
                              className={`text-white background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2 ${
                                bar.trxLock === true
                                  ? "bg-red-400"
                                  : "bg-green-400"
                              }`}
                              type="button"
                              onClick={() => barLockUnlock(bar)}
                            >
                              {bar.trxLock ? "Lock" : "Unlock"}
                            </button>
                          </td>
                        ) : (
                          <td className="py-3 px-3 text-left relative">
                            <button
                              className={`text-white background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2 ${
                                bar.trxLock === true
                                  ? "bg-red-400"
                                  : "bg-green-400"
                              }`}
                              type="button"
                              onClick={() =>
                                SetPopupAlert(
                                  "Permission Issue",
                                  "You don't have permission for this"
                                )
                              }
                            >
                              {bar.trxLock ? "Lock" : "Unlock"}
                            </button>
                          </td>
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                    <td
                      colSpan={13}
                      className="text-center py-3 px-3 text-left relative"
                    >
                      No data to display
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        <Pagination
          meta={meta}
          handlePageChange={(number: number) => {
            paginationFilter(number);
          }}
        />
      </div>
    </Layout>
  );
};

export default HsnMaster;
