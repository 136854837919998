import React, {useEffect, useState} from "react";
import Loader from "../../../Components-ui/Loader"
import {toast} from "react-toastify";
import RoleService from "../../Role/Services/role.service";
import {useAuthStore} from "../../../Store/AuthStore";
import MultipleSelect from "../../../Components-ui/MultipleSelect";

interface PropsType {
    onCloseModal: any,
    user: any,
    getAllUsers: any,
    page: number,
    roles: any
}

const AssignRoleForm: React.FC<PropsType> = ({
                                           onCloseModal,
                                           user,
                                           getAllUsers,
                                           page,
                                           roles
                                       }) => {

    const { tokens } = useAuthStore();

    const [Loading, setLoading] = useState<any>(false);
    const [SelectedRoles, setSelectedRoles] = useState<any>([]);


    useEffect(() => {
        setSelectedRoles(user.roles)
    }, [user]);


    /**
     * Assign role to user
     * @param e
     */
    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (SelectedRoles.length === 0) return toast.error("You have to add at least one role.");

        try {
            setLoading(true)
            await addRoleUser()
            await getAllUsers(page);
            setLoading(false);
            onCloseModal()
            return toast.success("Role was successfully updated");

        } catch (error: any) {
            setLoading(false);
            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                console.log(error.response.data.errors[0]);
                toast.error(error.response.data.errors[0].title);
            }
        }
    }

    /**
     * Assign role to user
     */
    const addRoleUser = async () => {
        if (SelectedRoles.length > 0) {
            let payload = SelectedRoles.map((role: any) => {
                return {
                    userId: user.userId,
                    roleId: role.id,
                    active: "ENABLED"
                }
            })
            await RoleService.addUserRole(payload, tokens)
        }
    }

    /**
     * Handle multiple select change event
     * @param selected
     */
    const handleChangeItem = (selected: any) => {
        setSelectedRoles(selected)
        console.log(selected, "selected")
    };

    return (
        <>
            {
                Loading ? <Loader/> : (
                    <div
                        className=" w-full bg-gray-800 bg-opacity-50 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-4xl">
                            <div
                                className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
                                <div
                                    className="items-start justify-between px-6 py-4 border-b border-solid border-blue bg-gray-100 rounded-t">
                                    <h3 className="text-xl font-semibold">Assign Role</h3>
                                </div>

                                <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
                                    <div className="col-span-12 sm:col-span-12">
                                        <label htmlFor="role"
                                               className="block text-sm font-medium text-gray-700">
                                            Roles
                                        </label>
                                        <div className="mt-1">
                                            <MultipleSelect
                                                optionList={roles}
                                                onChangeItem={handleChangeItem}
                                                selectedValue={SelectedRoles}
                                                closeMenuOnSelect={false}
                                                getOptionLabel={(option:any) => `${option.name}`}
                                                getOptionValue={(option:any) => `${option.id}`}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="flex items-center justify-end sm:py-3  sm:px-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <div>
                                        <button
                                            id="modal"
                                            className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={onCloseModal}
                                        >
                                            Close
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                                            type="button"
                                            onClick={(e) => handleSubmit(e)}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default AssignRoleForm;
