import { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import {
  GetAllReferenceMaster,
  GetAllReferenceDetails,
  GetAllRoleDetails,
  GetAllRoleAction,
  GetAllUserMaster,
  GetAllUserRoleMapping,
  GetAllModuleMaster,
  GetAllModuleAction,
  GetAllLogDetails,
  GetAllPartnerMaster,
  GetAllPartnerDetails,
} from "./NFR.Service";
import { ToastContainer, toast } from "react-toastify";
import "../Metal/react-confirm-alert.css";

const MasterTable: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [referenceMaster, setReferenceMaster] = useState<any>();
  const [referenceDetails, setReferenceDetails] = useState<any>();
  const [roleMaster, setRoleMaster] = useState<any>();
  const [roleAction, setRoleAction] = useState<any>();
  const [userMaster, setUserMaster] = useState<any>();
  const [userRoleMapping, setUserRoleMapping] = useState<any>();
  const [moduleMaster, setModuleMaster] = useState<any>();
  const [moduleAction, setModuleAction] = useState<any>();
  const [logDetails, setLogDetails] = useState<any>();
  const [partnerDetails, setPartnerDetails] = useState<any>();
  const [partnerMaster, setPartnerMaster] = useState<any>();

  useEffect(() => {
    loadData();
  }, [loading]);

  const loadData = async () => {
    let result: any;
    try {
      result = await GetAllReferenceMaster();
      setReferenceMaster(result.data.data);

      result = await GetAllReferenceDetails();
      setReferenceDetails(result.data.data);

      result = await GetAllRoleDetails();
      setRoleMaster(result.data.data);

      result = await GetAllRoleAction();
      setRoleAction(result.data.data);

      result = await GetAllUserMaster();
      setUserMaster(result.data.data);

      result = await GetAllUserRoleMapping();
      setUserRoleMapping(result.data.data);

      result = await GetAllModuleMaster();
      setModuleMaster(result.data.data);

      result = await GetAllModuleAction();
      setModuleAction(result.data.data);

      result = await GetAllLogDetails();
      setLogDetails(result.data.data);

      result = await GetAllPartnerMaster();
      setPartnerMaster(result.data.data);

      result = await GetAllPartnerDetails();
      setPartnerDetails(result.data.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong!");
    }
  };

  return (
    <Layout>
      <ToastContainer />

      <div className="flex">
        <h5 className="font-bold tracking-tight sm:text-xl">
          Non Functional Requirements Tables
        </h5>
      </div>
      <hr />
      <br />

      <div className="flex">
        <h5 className="font-bold tracking-tight text-gray-600 sm:text-xl">
          Reference Master Tables
        </h5>
      </div>
      <div className="overflow-x-auto">
        <div className="w-full">
          <div className="shadow-md rounded-lg my-6">
            <table className="min-w-max w-full table-fixed rounded-lg">
              <thead>
                <tr className="sticky top-0 px-6 py-3 text-green-900 bg-purple-300">
                  <th className="py-3 px-6 text-left text-xs">Code Name</th>
                  <th className="py-3 px-6 text-left text-xs">
                    Code Description
                  </th>
                  <th className="py-3 px-6 text-left text-xs">Created User</th>
                  <th className="py-3 px-6 text-left text-xs">Updated User</th>
                  <th className="py-3 px-6 text-left text-xs">Active Ind</th>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-light">
                {referenceMaster &&
                  referenceMaster.map((item: any) => {
                    return (
                      <tr
                        key={item.id}
                        className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
                      >
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Code_Name}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Code_Description}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Created_User}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Updated_User}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Active_Ind === true ? "Active" : "Inactive"}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="flex">
        <h5 className="font-bold tracking-tight text-gray-600 sm:text-xl">
          Reference Details Tables
        </h5>
      </div>
      <div className="overflow-x-auto">
        <div className="w-full">
          <div className="shadow-md rounded-lg my-6">
            <table className="min-w-max w-full table-fixed rounded-lg">
              <thead>
                <tr className="sticky top-0 px-6 py-3 text-green-900 bg-purple-300">
                  <th className="py-3 px-6 text-left text-xs">Code Name</th>
                  <th className="py-3 px-6 text-left text-xs">Code Value</th>
                  <th className="py-3 px-6 text-left text-xs">Created User</th>
                  <th className="py-3 px-6 text-left text-xs">Updated User</th>
                  <th className="py-3 px-6 text-left text-xs">
                    Value Description
                  </th>
                  <th className="py-3 px-6 text-left text-xs">Value Remark</th>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-light">
                {referenceDetails &&
                  referenceDetails.map((item: any) => {
                    return (
                      <tr
                        key={item.id}
                        className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
                      >
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Code_Name}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Code_Value}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Created_User}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Updated_User}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Value_Description}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Value_Remark}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="flex">
        <h5 className="font-bold tracking-tight text-gray-600 sm:text-xl">
          Role Master Table
        </h5>
      </div>
      <div className="overflow-x-auto">
        <div className="w-full">
          <div className="shadow-md rounded-lg my-6">
            <table className="min-w-max w-full table-fixed rounded-lg">
              <thead>
                <tr className="sticky top-0 px-6 py-3 text-green-900 bg-purple-300">
                  <th className="py-3 px-6 text-left text-xs">Role Code</th>
                  <th className="py-3 px-6 text-left text-xs">Role Name</th>
                  <th className="py-3 px-6 text-left text-xs">Active</th>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-light">
                {roleMaster &&
                  roleMaster.map((item: any) => {
                    return (
                      <tr
                        key={item.id}
                        className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
                      >
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Role_code}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Role_name}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Active_Ind === true ? "Active" : "Inactive"}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="flex">
        <h5 className="font-bold tracking-tight text-gray-600 sm:text-xl">
          Role Action Table
        </h5>
      </div>
      <div className="overflow-x-auto">
        <div className="w-full">
          <div className="shadow-md rounded-lg my-6">
            <table className="min-w-max w-full table-fixed rounded-lg">
              <thead>
                <tr className="sticky top-0 px-6 py-3 text-green-900 bg-purple-300">
                  <th className="py-3 px-6 text-left text-xs">Previlage ID</th>
                  <th className="py-3 px-6 text-left text-xs">Role Code</th>
                  <th className="py-3 px-6 text-left text-xs">Module Code</th>
                  <th className="py-3 px-6 text-left text-xs">Action Code</th>
                  <th className="py-3 px-6 text-left text-xs">Allowed</th>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-light">
                {roleAction &&
                  roleAction.map((item: any) => {
                    return (
                      <tr
                        key={item.id}
                        className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
                      >
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.previlage_id}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.role_code}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.module_code}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.action_code}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.allowed === true ? "True" : "False"}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="flex">
        <h5 className="font-bold tracking-tight text-gray-600 sm:text-xl">
          User Master Tables
        </h5>
      </div>
      <div className="overflow-x-auto">
        <div className="w-full">
          <div className="shadow-md rounded-lg my-6">
            <table className="min-w-max w-full table-fixed rounded-lg">
              <thead>
                <tr className="sticky top-0 px-6 py-3 text-green-900 bg-purple-300">
                  <th className="py-3 px-6 text-left text-xs">User code</th>
                  <th className="py-3 px-6 text-left text-xs">User name</th>
                  <th className="py-3 px-6 text-left text-xs">Email</th>
                  <th className="py-3 px-6 text-left text-xs">Phone</th>
                  <th className="py-3 px-6 text-left text-xs">Partner code</th>
                  <th className="py-3 px-6 text-left text-xs">User Type</th>
                  <th className="py-3 px-6 text-left text-xs">Vaults</th>
                  <th className="py-3 px-6 text-left text-xs">Active</th>
                  <th className="py-3 px-6 text-left text-xs">
                    Lost Sucess Login
                  </th>
                  <th className="py-3 px-6 text-left text-xs">
                    Lost Failed Login
                  </th>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-light">
                {userMaster &&
                  userMaster.map((item: any) => {
                    return (
                      <tr
                        key={item.id}
                        className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
                      >
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.user_code}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.user_name}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.email}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.phone}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.partner_code}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.user_Type}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.vaults}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.active === true ? "True" : "False"}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.lost_Sucess_Login}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.lost_Failed_Login}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="flex">
        <h5 className="font-bold tracking-tight text-gray-600 sm:text-xl">
          User Role mapping Tables
        </h5>
      </div>
      <div className="overflow-x-auto">
        <div className="w-full">
          <div className="shadow-md rounded-lg my-6">
            <table className="min-w-max w-full table-fixed rounded-lg">
              <thead>
                <tr className="sticky top-0 px-6 py-3 text-green-900 bg-purple-300">
                  <th className="py-3 px-6 text-left text-xs">User Code</th>
                  <th className="py-3 px-6 text-left text-xs">Role Code</th>
                  <th className="py-3 px-6 text-left text-xs">Active</th>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-light">
                {userRoleMapping &&
                  userRoleMapping.map((item: any) => {
                    return (
                      <tr
                        key={item.id}
                        className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
                      >
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.usercode}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.role_code}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.active === true ? "True" : "False"}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="flex">
        <h5 className="font-bold tracking-tight text-gray-600 sm:text-xl">
          Module master table
        </h5>
      </div>
      <div className="overflow-x-auto">
        <div className="w-full">
          <div className="shadow-md rounded-lg my-6">
            <table className="min-w-max w-full table-fixed rounded-lg">
              <thead>
                <tr className="sticky top-0 px-6 py-3 text-green-900 bg-purple-300">
                  <th className="py-3 px-6 text-left text-xs">Module_Code</th>
                  <th className="py-3 px-6 text-left text-xs">Module_Name</th>
                  <th className="py-3 px-6 text-left text-xs">Context</th>
                  <th className="py-3 px-6 text-left text-xs">Type</th>
                  <th className="py-3 px-6 text-left text-xs">Active</th>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-light">
                {moduleMaster &&
                  moduleMaster.map((item: any) => {
                    return (
                      <tr
                        key={item.id}
                        className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
                      >
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Module_Code}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Module_Name}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Context}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Type}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Active === true ? "True" : "False"}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="flex">
        <h5 className="font-bold tracking-tight text-gray-600 sm:text-xl">
          Module Action Table
        </h5>
      </div>
      <div className="overflow-x-auto">
        <div className="w-full">
          <div className="shadow-md rounded-lg my-6">
            <table className="min-w-max w-full table-fixed rounded-lg">
              <thead>
                <tr className="sticky top-0 px-6 py-3 text-green-900 bg-purple-300">
                  <th className="py-3 px-6 text-left text-xs">Module code</th>
                  <th className="py-3 px-6 text-left text-xs">Action code</th>
                  <th className="py-3 px-6 text-left text-xs">Action name</th>
                  <th className="py-3 px-6 text-left text-xs">Type</th>
                  <th className="py-3 px-6 text-left text-xs">Context</th>
                  <th className="py-3 px-6 text-left text-xs">Active</th>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-light">
                {moduleAction &&
                  moduleAction.map((item: any) => {
                    return (
                      <tr
                        key={item.id}
                        className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
                      >
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Module_code}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Action_code}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Action_name}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Type}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Context}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Active === true ? "True" : "False"}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="flex">
        <h5 className="font-bold tracking-tight text-gray-600 sm:text-xl">
          Log details table
        </h5>
      </div>
      <div className="overflow-x-auto">
        <div className="w-full">
          <div className="shadow-md rounded-lg my-6">
            <table className="min-w-max w-full table-fixed rounded-lg">
              <thead>
                <tr className="sticky top-0 px-6 py-3 text-green-900 bg-purple-300">
                  <th className="py-3 px-6 text-left text-xs">Module code</th>
                  <th className="py-3 px-6 text-left text-xs">
                    Sub module code
                  </th>
                  <th className="py-3 px-6 text-left text-xs">Type</th>
                  <th className="py-3 px-6 text-left text-xs">Text</th>
                  <th className="py-3 px-6 text-left text-xs">Payload</th>
                  <th className="py-3 px-6 text-left text-xs">Created on</th>
                  <th className="py-3 px-6 text-left text-xs">Created by</th>
                  <th className="py-3 px-6 text-left text-xs">Status</th>
                  <th className="py-3 px-6 text-left text-xs">Resolved on</th>
                  <th className="py-3 px-6 text-left text-xs">Resolved by</th>
                  <th className="py-3 px-6 text-left text-xs">Remarks</th>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-light">
                {logDetails &&
                  logDetails.map((item: any) => {
                    return (
                      <tr
                        key={item.id}
                        className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
                      >
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.module_code}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.sub_module_code}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.type}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.text}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.payload}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.created_on}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.created_by}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.status}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.resolved_on}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.resolved_by}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.remarks}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="flex">
        <h5 className="font-bold tracking-tight text-gray-600 sm:text-xl">
          Partner Details table
        </h5>
      </div>
      <div className="overflow-x-auto">
        <div className="w-full">
          <div className="shadow-md rounded-lg my-6">
            <table className="min-w-max w-full table-fixed rounded-lg">
              <thead>
                <tr className="sticky top-0 px-6 py-3 text-green-900 bg-purple-300">
                  <th className="py-3 px-6 text-left text-xs">Partner Code</th>
                  <th className="py-3 px-6 text-left text-xs">Key</th>
                  <th className="py-3 px-6 text-left text-xs">Value</th>
                  <th className="py-3 px-6 text-left text-xs">Active</th>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-light">
                {partnerDetails &&
                  partnerDetails.map((item: any) => {
                    return (
                      <tr
                        key={item.id}
                        className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
                      >
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.partner_code}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.key}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.value}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.active === true ? "True" : "False"}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="flex">
        <h5 className="font-bold tracking-tight text-gray-600 sm:text-xl">
          Partner Master table
        </h5>
      </div>
      <div className="overflow-x-auto">
        <div className="w-full">
          <div className="shadow-md rounded-lg my-6">
            <table className="min-w-max w-full table-fixed rounded-lg">
              <thead>
                <tr className="sticky top-0 px-6 py-3 text-green-900 bg-purple-300">
                  <th className="py-3 px-6 text-left text-xs">Partner code</th>
                  <th className="py-3 px-6 text-left text-xs">Partner name</th>
                  <th className="py-3 px-6 text-left text-xs">
                    Integration type
                  </th>
                  <th className="py-3 px-6 text-left text-xs">Ebl user Code</th>
                  <th className="py-3 px-6 text-left text-xs">Partner logo</th>
                  <th className="py-3 px-6 text-left text-xs">
                    Partner address
                  </th>
                  <th className="py-3 px-6 text-left text-xs">Domain url</th>
                  <th className="py-3 px-6 text-left text-xs">Theme ref</th>
                  <th className="py-3 px-6 text-left text-xs">
                    Service authentication
                  </th>
                  <th className="py-3 px-6 text-left text-xs">Active</th>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-light">
                {partnerMaster &&
                  partnerMaster.map((item: any) => {
                    return (
                      <tr
                        key={item.id}
                        className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
                      >
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.partner_code}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.partner_name}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.integration_type}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.ebl_user_Code}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.partner_logo}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.partner_address}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.domain_url}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.theme_ref}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.service_authentication}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.active === true ? "True" : "False"}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MasterTable;
