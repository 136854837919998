import Layout from "../Layout/Layout";
import React, {useEffect, useState} from "react";
import {ToastContainer, toast} from "react-toastify";
import "./react-confirm-alert.css";
import Loader from "../../Components-ui/Loader";
import HsnMasterService, {filterHsnData} from "./Hsn.Service";
import moment from "moment";
import {useAuthStore} from "../../Store/AuthStore";
import {Pagination} from "../../Components-ui/Pagination";
const HsnMaster: React.FC = () => {
    const {tokens} = useAuthStore();
    const [Loading, setLoading] = useState<any>(true);
    const [hsnList, setHsnList] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [meta, setMetaData] = useState<any>({});
    const [filters, setFilters] = useState<any>({
        hsnCode: '',
        description: '',
        active: '',
    });

    useEffect(() => {
        loadHsnData();
    }, []);

    const loadHsnData = async () => {
        await HsnMasterService.getHsnData(tokens).then((response: any) => {
            setHsnList(response.data.data.result);
            setMetaData({
                current: response.data.meta.page,
                pages: response.data.meta.totalPages,
            });
            setCurrentPage(response.data.meta.page);
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            toast.error("Something Went Wrong!");
        });
    };

    const filter = async (page:any) => {
        setLoading(true);
        await HsnMasterService.filterHsnData(filters, page, tokens).then((response: any) => {
            setHsnList(response.data.data.result);
            setMetaData({
                current: response.data.meta.page,
                pages: response.data.meta.totalPages,
            });
            setCurrentPage(response.data.meta.page);
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            toast.error("Something Went Wrong!");
        });
    }

    const ResetForm = async (e: any) => {
        e.preventDefault();
        setCurrentPage(0);
        setFilters({
            hsnCode: '',
            description: '',
            active: '',
        });
        await loadHsnData();
    }
    const paginationFilter = async (page: number) => {
        setCurrentPage(page);
        filter(page);
    }


    return (
        <Layout type={"MasterModule"}>
            <ToastContainer/>
            <div className="flex justify-between items-center mb-5">
                <h1 className="text-2xl font-bold tracking-tight">
                    {"HSN Master"}
                </h1>
            </div>
            <div className="border bg-white rounded border-gray-200 shadow">
                <div className="flex-1 p-2">
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="user-id">
                                HSN Code
                            </label>
                            <input
                                className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                                onChange={event => setFilters({
                                    ...filters,
                                    hsnCode: event.target.value
                                })}
                                name="user-id" id="user-id" type="text" placeholder="HSN Code" value={filters.hsnCode}
                                autoComplete="none"/>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="email">
                               Description
                            </label>
                            <input
                                className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                                id="email" onChange={event => setFilters({
                                ...filters,
                                description: event.target.value
                            })} type="text" placeholder="Description" value={filters.description} autoComplete="none"/>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="user-type">
                               Status
                            </label>
                            <div className="relative">
                                <select
                                    className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                                    id="status" onChange={event => setFilters({
                                    ...filters,
                                    active: event.target.value
                                })} value={filters.active}>
                                    <option value="">Select a Status</option>
                                    <option value="ENABLED">ENABLED</option>
                                    <option value="DISABLED">DISABLED</option>
                                </select>
                            </div>
                        </div>
                        <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <div className="my-6">
                                <button
                                    className="px-4 py-2.5 font-bold text-white text-sm uppercase bg-purple-950 rounded"
                                    type="button"
                                    onClick={() => filter(0)}
                                >
                                    Filter
                                </button>
                                <button
                                    className="ml-4 px-4 py-2.5 font-bold text-white text-sm uppercase bg-red-600 rounded"
                                    type="button"
                                    onClick={(e) => ResetForm(e)}>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border bg-white rounded border-gray-200 shadow">
                <div className="border bg-white rounded border-gray-200 shadow m-2">
                    {Loading ? (<Loader/>) : (
                        <div>
                            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                                <thead>
                                <tr className="text-left border-b border-gray-300">
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10 text-truncate">HSN Code</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Description</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Created By</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Updated Date</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {(0 !== hsnList.length) ?
                                    hsnList.map((hsn: any, index: number) => {
                                        return (
                                            <tr key={index}
                                                className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                <td className="py-3 px-3 text-left relative">
                                                    {hsn.hsnCode}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {hsn.description}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {hsn.createdBy}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {moment(hsn.updatedAt).format("DD-MM-YYYY HH:mm")}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    <span
                                                        className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${"ENABLED" === hsn.active ? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>
                                                        {hsn.active}
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    : (<tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                        <td colSpan={7} className="text-center py-3 px-3 text-left relative">No data to
                                            display
                                        </td>
                                    </tr>)
                                }
                                </tbody>
                            </table>
                        </div>
                    )}
                    <Pagination
                        meta={meta}
                        handlePageChange={(number: number) => {
                            paginationFilter(number)
                        }}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default HsnMaster;
