import React,{useEffect} from "react";

interface PropsType {
    itemList: any,
    columns: any,
    type: string,
    isShowTotalSection?: any,
    totalValue?: any,
    totalLabel?: any
}

const ItemTable: React.FC<PropsType> = ({
        itemList,
        columns,
        type,
        isShowTotalSection = false,
        totalValue = null,
        totalLabel = null
    }) => {
        
    return (
        <table
            className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
            <thead>
            <tr className="text-center border-b border-gray-300">
                {
                    columns.map((column:any, index:number) => {
                        return (
                            <th key={index} className="sticky top-0 px-3 py-2">{column.label}</th>
                        )
                    })
                }
            </tr>
            </thead>
            <tbody
                className="text-gray-800 text-sm font-light">
            {
                itemList.map((tax: any, key:number) => {
                    return (
                        <tr key={key} className="border-b bg-white text-black border-gray-300">
                            {
                                columns.map((column:any, index:number) => {
                                    return (
                                        <>
                                        { type === 'taxes' ? <td key={index} className="py-3 px-3 text-center relative">{index === 0 ? `${tax[column.value]} (${tax.rate}%)` : tax[column.value]}</td> : ''}
                                        { type === 'invoice_taxes' ? <td key={index} className="py-3 px-3 text-center relative">{index === 0 ? `${tax.taxSubType} (${tax.value}%)` : tax.amount}</td> : ''}
                                        </>
                                    )
                                })
                            }
                        </tr>
                    )
                })
            }

            {
                isShowTotalSection && (
                    <tr key="totalSection" className="border-b bg-gray-300 text-black border-gray-200">
                        <td className="py-3 px-3 text-center relative">
                            <h4 className="text-lg leading-6 font-bold font-medium text-black">{totalLabel}</h4>
                        </td>
                        <td className="py-3 px-3 text-center relative">
                            <h4 className="text-lg leading-6 font-bold font-medium text-black underline underline-offset-2">
                                {totalValue}
                            </h4>
                        </td>
                    </tr>
                )
            }
            </tbody>
        </table>
    )
}

export default ItemTable;
