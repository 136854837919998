import "cross-fetch/polyfill";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { UserPool } from "../../../Services/UserPool";
import { httpMethods } from "../../../library/enum";
import Http from "../../../library/http.library";
import { makeQuery } from "../../../Common/util.helper";
import { cognito } from "../../../Config/consts";

/**
 * create investor
 * @param tokens
 * @param data
 */
export const createInvestor = async (tokens: any, data: any) => {
  return await Http.setMethod(httpMethods.post)
    .setUrl(`/create-investor`)
    .setData(data)
    .setToken(tokens.idToken.jwtToken)
    .request();
};
export const updateInvestor = async (
  tokens: any,
  data: any,
  investorId: any
) => {
  return await Http.setMethod(httpMethods.patch)
    .setUrl(`/investor-update/${investorId}`)
    .setData(data)
    .setToken(tokens.idToken.jwtToken)
    .request();
};
export const updateInvestorStatus = async (tokens: any, data: any) => {
  return await Http.setMethod(httpMethods.patch)
    .setUrl(`/investor/status/change`)
    .setData(data)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getAllInvestors = async (
  tokens: any,
  currentPage: any,
  isPaginated: boolean
) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/investor/list?page=${currentPage}&isPaginated=${isPaginated}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getGstinList = async (tokens: any, data: any) => {
  return await Http.setMethod(httpMethods.post)
    .unsetData()
    .setData(data)
    .setUrl(`/customer/b2b/gstn/search`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getInvestorDetails = async (tokens: any, id: any) => {
  return await Http.setMethod(httpMethods.get)
    .unsetData()
    .setUrl(`/investor/getData/${id}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};
export const getInvestorAddressDetails = async (tokens: any, data: any) => {
  return await Http.setMethod(httpMethods.post)
    .unsetData()
    .setData(data)
    .setUrl(`/customer/b2b/gstn/details`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const filterInvestors = async (
  data: any,
  currentPage: number,
  tokens: any
) => {
  var query = {
    ...(data.mobile ? { mobile: data.mobile } : {}),
    ...(data.investorName ? { investorName: data.investorName } : {}),
    ...(data.investorId ? { investorId: data.investorId } : {}),
    ...(data.referralCode ? { referralCode: data.referralCode } : {}),
    ...(data.partner ? { partnerId: data.partner } : {}),
    ...(data.pan ? { pan: data.pan } : {}),
  };
  const params = makeQuery(query);
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/investor/list?page=${currentPage}${params}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const sendOTP = async (tokens: any, data: any, key: any) => {
  return await Http.setMethod(httpMethods.post)
    .unsetData()
    .setData(data)
    .setUrl(
      `/investor/sendotp/${
        key === "mobile_no" ? "CustomerMobile" : "Customeremail"
      }`
    )
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getAllInvCustomers = async (
  data: any,
  tokens: any,
  currentPage: any
) => {
  var query = {
    isPaginated: true,
    page: currentPage,
    ...(data.customer_id ? { id: data.customer_id } : {}),
    ...(data.user_id ? { userId: data.user_id } : {}),
    ...(data.mobile ? { mobile: data.mobile } : {}),
    ...(data.partner ? { partnerId: data.partner } : {}),
    ...(data.gst ? { customerType: data.gst } : {}),
    ...(data.status ? { status: data.status } : {}),
    ...(data.verifyStatus ? { verifyStatus: data.verifyStatus } : {}),
    ...(data.customerCategory
      ? { customerCategory: data.customerCategory }
      : {}),
    ...(data.source ? { source: data.source } : {}),
    ...(data.name ? { name: data.name } : {}),
    ...(data.fromDate ? { fromDate: data.fromDate } : {}),
    ...(data.toDate ? { toDate: data.toDate } : {}),
    ...(data.investorId ? { investorId: data.investorId } : {}),
  };
  const params = makeQuery(query);
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/investor-customers-list?${params}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getAllInvOrders = async (
  data: any,
  tokens: any,
  currentPage: any
) => {
  var query = {
    isPaginated: true,
    page: currentPage,
    ...(data.customerId ? { customerId: data.customerId } : {}),
    ...(data.orderRef ? { orderRef: data.orderRef } : {}),
    ...(data.metalCode ? { metalCode: data.metalCode } : {}),
    ...(data.status ? { status: data.status } : {}),
    ...(data.fromDate ? { fromDate: data.fromDate } : {}),
    ...(data.toDate ? { toDate: data.toDate } : {}),
    ...(data.investorId ? { investorId: data.investorId } : {}),
  };
  const params = makeQuery(query);
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/order-investor?${params}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const verifyOTP = async (tokens: any, data: any) => {
  return await Http.setMethod(httpMethods.patch)
    .unsetData()
    .setData(data)
    .setUrl(`/investor/verifyOtp-CustomerMobile`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const resendOTP = async (tokens: any, data: any, key: any) => {
  return await Http.setMethod(httpMethods.patch)
    .unsetData()
    .setData(data)
    .setUrl(`/investor/resendOtp/${key === "mobile_no" ? "Mobile" : "email"}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getpanDetails = async (tokens: any, data: any) => {
  return await Http.setMethod(httpMethods.post)
    .unsetData()
    .setData(data)
    .setUrl(`/customer/pan/verify`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const checkNameSimilarity = async (tokens: any, data: any) => {
  return await Http.setMethod(httpMethods.post)
    .unsetData()
    .setData(data)
    .setUrl(`/pan/name-simlarity`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const downloadInvCustReport = async (data: any, tokens: any) => {
  return Http.setMethod(httpMethods.get)
    .unsetData()
    .setUrl(`/investor-customer/log/download?${data}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const downloadInvOrderReport = async (data: any, tokens: any) => {
  return Http.setMethod(httpMethods.get)
    .unsetData()
    .setUrl(`/order-investor/download?${data}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const sendEmailInvCustReport = async (data: any, tokens: any) => {
  return Http.setMethod(httpMethods.get)
    .unsetData()
    .setUrl(`/investor-customers-list-email?${data}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const sendEmailInvOrderReport = async (data: any, tokens: any) => {
  return Http.setMethod(httpMethods.get)
    .unsetData()
    .setUrl(`/investor-email?${data}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const sendAadhaarOtp = async (tokens: any, data: any) => {
  return Http.setMethod(httpMethods.post)
    .setData(data)
    .setUrl("/user/kyc/aadhar/investor/sendotp")
    .setToken(tokens.idToken.jwtToken)
    .request();
};
export const verifyAadhaarOtp = async (tokens: any, data: any) => {
  return Http.setMethod(httpMethods.post)
    .setData(data)
    .setUrl("/user/kyc/aadhar/investor/verifyfotp")
    .setToken(tokens.idToken.jwtToken)
    .request();
};

const UserListService = {
  createInvestor,
  getAllInvestors,
  filterInvestors,
  getGstinList,
  getInvestorDetails,
  updateInvestor,
  updateInvestorStatus,
  getInvestorAddressDetails,
  sendOTP,
  verifyOTP,
  resendOTP,
  getAllInvCustomers,
  getAllInvOrders,
  downloadInvCustReport,
  sendEmailInvCustReport,
  downloadInvOrderReport,
  sendEmailInvOrderReport,
  sendAadhaarOtp,
  verifyAadhaarOtp,
  getpanDetails,
  checkNameSimilarity,
};

export default UserListService;
