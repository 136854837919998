import React from "react";
import { Route } from "react-router-dom";
import FundMgtHead from "../FundMgtHead";
import FundManagement from "../FundManagement";
import SingleFundManagementView from "../FundManagementSingleView";
import Sip from "../../Sip/sip";

export const FundMgtRoutes: React.FC = () => {
  return (
    <>
      <Route path="/fund" exact component={FundMgtHead} />
      {/* <Route path="/all" exact component={ViewAllFund} /> */}
      <Route path="/fund/:id" exact component={SingleFundManagementView} />
      <Route path="/fund-management" exact component={FundManagement} />
      <Route path="/fund-management/:status" exact component={FundManagement} />
      <Route path="/sip-list" exact component={Sip} />

    </>
  );
};
