import axios from "axios";
import Http from "../../../library/http.library";
import {httpMethods} from "../../../library/enum";

export const SendForReceipting = async (data: any) => {
    return new Promise(async (resolve, reject) => {
        let apiUrl = process.env.REACT_APP_URL + `/api/v1/vsoreceipt/sendreceipts`;
        try {
          let result = await axios.post(apiUrl, data);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      });
}

export const GetVSODetails = async (date: string, vaultId: string) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/vsoreceipt/getdate/${date}/${vaultId}`;
    try {
      let result = await axios.get(apiUrl);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
}

//New Endpoint
export const GetVSOReceiptDetailsByLastReceipted = async (date: string, vaultId: string) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/vso/receipt/bylastres?vaultId=${vaultId}&date=${date}`;
    try {
      let result = await axios.get(apiUrl);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
}

//New Endpoint
export const GetVSOReceiptDetailsByDate = async (date: string, vaultId: string) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/vso/receipt/bydate?vaultId=${vaultId}&date=${date}`;
    try {
      let result = await axios.get(apiUrl);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
}

//New Endpoint
export const InitiateVSOReceiptingProcess = async (date: string, vaultId: string, vso: string) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/vso/receipt/process`;
    try {
      let result = await axios.post(apiUrl, {
        vaultId: vaultId,
        date: date,
        vso: vso
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
}

export const TestGet = async (partnerId: any, quantity: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `https://qa-api.ebullion.in/api/v1/customer_ledger/metalprices?partnerId=${partnerId}&quantity=${quantity}`;
    try {
      let result = await axios.get(apiUrl);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
}

export const UpdateVSOStatus = async (data: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/vsoreceipt/updatestatus`;
    try {
      let result = await axios.post(apiUrl, data);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
}

/**
     * Get all valuts
     * @param tokens
     * @param warehouses
     * @param permissions
     */
 export const getValuts = async (tokens:any, warehouses: any = [], permissions: any = []) => {
    let ids: any = []
    if (permissions.map((x: any) =>  x.permissionCode).includes('vso.receipt') && warehouses.length > 0) {
        ids = warehouses.map((x: any) => x.warehouseId).join(',');
    }
    // return await Http.setMethod(httpMethods.get).setUrl(`/warehouse-master?isPaginated=false${permissions.map((x: any) =>  x.permissionCode).includes('vso.receipt') ? `&warehouseIds=${ids}` : ''}`).request();
  //
    return new Promise(async (resolve, reject) => {
    let apiUrl = `${process.env.REACT_APP_URL}/warehouse-master?isPaginated=false${permissions.map((x: any) =>  x.permissionCode).includes('vso.receipt') ? `&warehouseIds=${ids}` : ''}`;
    try{
        resolve(await axios.get(apiUrl , {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                Authorization: `Bearer ${tokens.idToken.jwtToken}`,
            },
        }));
    }catch (e) {
        reject(e)
    }
  });
}

export const createCalendar = async (data: any, tokens:any) => {
    return await Http.setMethod(httpMethods.post).setUrl(`/vso/calendar`).setData(data).setToken(tokens.idToken.jwtToken).request();

  //   return new Promise(async (resolve, reject) => {
  //   try {
  //     let apiUrl = process.env.REACT_APP_URL + `/vso/calendar`;
  //
  //     resolve(await axios.post(apiUrl, data,{
  //       headers: {
  //           "Content-Type": "application/json",
  //           "Access-Control-Allow-Origin": "*",
  //           "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
  //           Authorization: `Bearer ${tokens.idToken.jwtToken}`,
  //       },
  //   }));
  //   } catch (error) {
  //     reject(error);
  //   }
  // });
};

export const getCalenderDetails = async (
  startDate: string,
  endDate: string,
  vaultId: string,
  tokens:any,
  page:number
) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/vso/calendar?startDate=${startDate}&endDate=${endDate}&vaultId=${vaultId}&orderBy=date&page=${page}&pageSize=10`).request();

  //   return new Promise(async (resolve, reject) => {
  //   let apiUrl = `${process.env.REACT_APP_URL}/vso/calendar?startDate=${startDate}&endDate=${endDate}&vaultId=${vaultId}&orderBy=date&page=${page}&pageSize=10`;
  //   try{
  //       resolve(await axios.get(apiUrl , {
  //           headers: {
  //               "Content-Type": "application/json",
  //               "Access-Control-Allow-Origin": "*",
  //               "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
  //               Authorization: `Bearer ${tokens.idToken.jwtToken}`,
  //           },
  //       }));
  //   }catch (e) {
  //       reject(e)
  //   }
  // });
};

// Update time
export const EditTime = async (id:number, data: any, tokens:any) => {
    return Http.setMethod(httpMethods.patch).setUrl(`/vso/calendar/${id}`).setData(data).setToken(tokens.idToken.jwtToken).request();
  //
  //   return new Promise(async (resolve, reject) => {
  //   let apiUrl = process.env.REACT_APP_URL + `/vso/calendar/${id}`;
  //   try {
  //     let result = await axios.patch(apiUrl, data,{
  //       headers: {
  //           "Content-Type": "application/json",
  //           "Access-Control-Allow-Origin": "*",
  //           "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
  //           Authorization: `Bearer ${tokens.idToken.jwtToken}`,
  //       },
  //   });
  //     resolve(result);
  //   } catch (error) {
  //     reject(error);
  //   }
  // });
};
