import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../Layout/Layout";
import { ToastContainer, toast } from "react-toastify";
import "../Metal/react-confirm-alert.css";
import Loader from "../../Components-ui/Loader";
import { useAuthStore } from "../../Store/AuthStore";
import DocumentTypeService from "./DocumentType.Service";
import moment from "moment";
import Datetime from "react-datetime";
import SimpleReactValidator from "simple-react-validator";
import { delteUploadedFiles, getUploadedFiles, updateUploadedFiles, uploadSampleDoc } from "./ConfigDocument.Service";
import { DownloadReportNew } from "../AWS/s3Bucket.Service";


const ConfigDoc: React.FC = () => {
    let { id } = useParams<any>();
    const ref: any = useRef();
    let history = useHistory();
    const { tokens } = useAuthStore();
    const [Loading, setLoading] = useState<any>(false);
    const [checked, setChecked] = useState<any>(false);
    const [selectedRecord, setSelectedRecord] = useState<any>({
        active: "",
        defaultVerified: "",
        customerType: "",
        description: "",
        fileType: "",
        index: 0,
        optional: "",
        title: "",
        validDuration: 1,
        fromDate: moment(new Date().toISOString()).format("MM/DD/YYYY"),
        toDate: moment(new Date().toISOString()).format("MM/DD/YYYY"),
    });
    const [selectedFiles, setSelectedFiles] = useState<any>([]);
    const [validatorStatus, setValidator] = useState<boolean>(false);

    const simpleValidator = new SimpleReactValidator({
        locale: "en",
        validators: {},
    });
    const validationRef = React.useRef(simpleValidator);
    const validator = validationRef.current;

    var valid = function (current: any) {
        return current.isAfter(moment(selectedRecord.fromDate).subtract(1, 'day'));
    };

    var validFrom = function (current: any) {
        const yesterday = moment().subtract(1, 'day');
        return current.isAfter(yesterday);
    };


    useEffect(() => {
        if (id) {
            setLoading(true);
            getDataRecord();
            getSampleFiles(id);
        }
    }, []);


    const getDataRecord = async () => {
        await DocumentTypeService.getSingleDoc(id, tokens).then((response: any) => {
            setLoading(false);
            setChecked(-1 === response.data.data.validDuration)
            setSelectedRecord(response.data.data);
            setSelectedRecord({
                validDuration: response.data.data.validDuration,
                defaultVerified: response.data.data.defaultVerified,
                active: response.data.data.active,
                customerType: response.data.data.customerType,
                description: response.data.data.description,
                fileType: response.data.data.fileType,
                index: response.data.data.index,
                optional: response.data.data.optional,
                title: response.data.data.title,
                fromDate: moment(new Date(response.data.data.fromDate).toISOString()).format("MM/DD/YYYY"),
                toDate: moment(new Date(response.data.data.toDate).toISOString()).format("MM/DD/YYYY"),
            });
        }).catch(err => {
            setLoading(false);
            toast.error(err.response.data.errors[0].title);
        });
    };

    const getSampleFiles = async (id: any) => {
        await getUploadedFiles(tokens, id).then((response: any) => {
            setLoading(false);
            if (response.data.data.length > 0) {
                response.data.data.forEach((element: any, index: any) => {
                    let obj = { ...element, fileName: element.resource.split(".")[1] };

                    response.data.data[index] = obj;
                });
            }


            setSelectedFiles(response.data.data);
        }).catch(err => {
            setLoading(false);
            toast.error(err.response.data.errors[0].title);
        });
    };

    const viewSampleFiles = async (resource: any) => {
        try {
            let rawData: any = await DownloadReportNew(resource);
            const data = await rawData.blob();

            let url = window.URL.createObjectURL(data)
            window.open(url, '_blank');
        } catch (error) {
            toast.error("Something Went Wrong!");
        }
    }

    const save = async () => {
        setValidator(true);
        if (validator.allValid()) {
            if (checked) {
                selectedRecord.validDuration = -1
            }
            setLoading(true);
            if (id) { // Edit
                selectedRecord.docTypeId = id
                await DocumentTypeService.updateDoc(selectedRecord, tokens).then(async (response: any) => {

                    try {
                        await updateAndUploadFiles(response.data.data.docTypeId);
                    } catch (error) {
                        toast.error("File Upload Issue");
                    }
                    setLoading(false);
                    getDataRecord();
                    getSampleFiles(id);
                    toast.success("Record Updated Successfully");
                    // setTimeout(() => {
                    //     history.push('/document-config');
                    // }, 4900);
                }).catch(err => {
                    setLoading(false);
                    toast.error(err.response.data.errors[0].title);
                });
            } else { // New record
                await DocumentTypeService.createDoc(selectedRecord, tokens).then(async (response: any) => {
                    try {
                        await updateAndUploadFiles(response.data.data.docTypeId);
                    } catch (error) {
                        toast.error("File Upload Issue");
                    }

                    setLoading(false);
                    clear();
                    toast.success("Record Added Successfully");
                    // setTimeout(() => {
                    //     history.push('/document-config');
                    // }, 4900);
                }).catch(err => {
                    setLoading(false);
                    toast.error(err.response.data.errors[0].title);
                });
            }
            setValidator(false);
        }
    }

    const updateAndUploadFiles = async (docTypeId: any) => {
        if (selectedFiles.length > 0) {

            for await (const file of selectedFiles) {
                if (file.resource == "") {
                    var formData = new FormData();
                    formData.append("file", file.file);
                    formData.append("docTypeId", docTypeId);
                    if (file.name != "") formData.append("name", file.name);
                    if (file.description != "") formData.append("description", file.description);

                    try {
                        const fileUploadResult = await uploadSampleDoc(tokens, formData);

                    } catch (error) {
                        throw new Error("File Upload issue");

                    }

                } else {

                    try {
                        const newFile = file;
                        delete newFile.fileName
                        const fileUploadUpdateResult = await updateUploadedFiles(tokens, [newFile]);

                    } catch (error) {
                        throw new Error("File Upload Update failed");

                    }
                }
            }
        }
    }

    const clear = () => {
        setChecked(false)
        setSelectedRecord({
            active: "",
            defaultVerified: "",
            customerType: "",
            description: "",
            fileType: "",
            index: 0,
            optional: "",
            title: "",
            fromDate: "",
            toDate: "",
            validDuration: 0,
        });
        setSelectedFiles([]);
    }

    const getValidatorError = (nameOfStateProp: any) => {
        const allErrorMessages = validator.getErrorMessages();
        return allErrorMessages[nameOfStateProp];
    };

    const changeStatus = async (status: any, key: any, id: any) => {

    }

    const changeStatusDocTypeSample = async (status: any, index: any) => {
        let newArr = [...selectedFiles];
        newArr[index].active = ('ENABLED' !== status) ? 'ENABLED' : 'DISABLED';
        setSelectedFiles(newArr);
    }

    const changeHandler = (event: any) => {
        let selectedFile = event.target.files[0];
        const newFile = {
            id: "",
            docTypeId: "",
            resource: "",
            active: "ENABLED",
            name: "",
            description: "",
            fileName: selectedFile.name,
            file: selectedFile
        };
        setSelectedFiles((oldArray: any) => [...oldArray, newFile]);
        ref.current.value = "";
    };

    const removeFile = async (index: any) => {
        setLoading(true);
        if (selectedFiles[index].resource != "") {
            await delteUploadedFiles(tokens, selectedFiles[index].id).then((response: any) => {
                setLoading(false);

                if (response.data.data.id) {
                    let newList = [...selectedFiles];

                    let filteredList = newList.filter((data: any) => data.id != response.data.data.id);

                    setSelectedFiles(filteredList);
                }

            }).catch(err => {
                setLoading(false);
                toast.error(err.response.data.errors[0].title);
            });
        } else {

            // assigning the list to temp variable
            const temp = [...selectedFiles];

            // removing the element using splice
            temp.splice(index, 1);

            // updating the list
            setSelectedFiles(temp);
            setLoading(false)
        }

    };

    const onFileDataChange = (index: any, val: any, key: any) => {
        let prevArray = [...selectedFiles];
        prevArray[index][key] = val;
        setSelectedFiles(prevArray);
    };

    return (
        <Layout type={"MasterModule"}>
            <ToastContainer />
            <button
                onClick={() => history.push(`/document-config`)}
                className="-mr-2 flex items-center rounded text-white font-bold text-xs uppercase"
            >
                <svg className="w-7" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd"
                    clip-rule="evenodd">
                    <path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" />
                </svg>
                {" "}

            </button>
            <div className="flex justi0fy-between items-center mb-5">
                <h5 className="text-2xl font-bold tracking-tight">
                    {id ? "Edit Document types" : "Create New Document types"}
                </h5>
            </div>
            {Loading && (<Loader />)}
            <div
                className="w-full rounded border-gray-200 overflow-hidden shadow-lg dark:border-gray-700 bg-white">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Document Type Details</h3>
                </div>
                <hr />
                {/* Form */}
                <form className="w-full">
                    <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
                        <div className="col-span-12 sm:col-span-4">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="user-type">
                                Customer Type
                            </label>
                            <select
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="user-type"
                                name="customer-type"
                                required
                                onChange={(event) => setSelectedRecord({
                                    ...selectedRecord,
                                    customerType: event.target.value
                                })} value={selectedRecord.customerType}>
                                <option value="">Select a Customer Type</option>
                                <option value="CO">CO</option>
                                <option value="FIRM">FIRM</option>
                                <option value="HUF">HUF</option>
                                <option value="LLP">LLP</option>
                                <option value="SOLE">SOLE</option>
                                <option value="TRUST">TRUST</option>
                            </select>
                            {validator.message(
                                "customer-type",
                                selectedRecord.customerType,
                                "required"
                            )}
                            {
                                validatorStatus ? (<span className="text-xs text-red-700"
                                    id="name">{getValidatorError("customer-type")}</span>) : ''
                            }
                        </div>
                        <div className="col-span-12 sm:col-span-4">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="user-type">
                                Required Status
                            </label>
                            <select
                                className={'mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'}
                                id="required-state"
                                required
                                name="optional"
                                onChange={(event) => setSelectedRecord({
                                    ...selectedRecord,
                                    optional: event.target.value
                                })} value={selectedRecord.optional}
                            >
                                <option value="">Select</option>
                                <option value="false">Required</option>
                                <option value="true">Optional</option>
                            </select>
                            {validator.message(
                                "optional",
                                selectedRecord.optional,
                                "required"
                            )}
                            {
                                validatorStatus ? (<span className="text-xs text-red-700"
                                    id="name">{getValidatorError("optional")}</span>) : ''
                            }
                        </div>
                        <div className="col-span-12 sm:col-span-4">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="user-type">
                                Default Verified
                            </label>
                            <select
                                className={'mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'}
                                id="required-state"
                                required
                                name="defaultVerified"
                                onChange={(event) => setSelectedRecord({
                                    ...selectedRecord,
                                    defaultVerified: event.target.value
                                })} value={selectedRecord.defaultVerified}
                            >
                                <option value="">Select</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                            {validator.message(
                                "defaultVerified",
                                selectedRecord.defaultVerified,
                                "required"
                            )}
                            {
                                validatorStatus ? (<span className="text-xs text-red-700"
                                    id="name">{getValidatorError("defaultVerified")}</span>) : ''
                            }
                        </div>
                    </div>
                    <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
                        <div className="col-span-12 sm:col-span-4">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="user-type">
                                file Type
                            </label>
                            <select
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="file-type"
                                required
                                name="file_type"
                                onChange={(event) => setSelectedRecord({
                                    ...selectedRecord,
                                    fileType: event.target.value
                                })} value={selectedRecord.fileType}
                            >
                                <option value="">Select</option>
                                <option value="PDF">PDF</option>
                                <option value="IMG">IMAGE</option>
                                <option value="IMG_OR_PDF">IMAGE OR PDF</option>
                            </select>
                            {validator.message(
                                "file_type",
                                selectedRecord.fileType,
                                "required"
                            )}
                            {
                                validatorStatus ? (<span className="text-xs text-red-700"
                                    id="name">{getValidatorError("file_type")}</span>) : ''
                            }
                        </div>
                        <div className="col-span-12 sm:col-span-4">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="user-type">
                                Status
                            </label>
                            <select
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="file-type"
                                required
                                name="status"
                                onChange={(event) => setSelectedRecord({
                                    ...selectedRecord,
                                    active: event.target.value
                                })} value={selectedRecord.active}
                            >
                                <option value="">Select</option>
                                <option value="ENABLED">ENABLED</option>
                                <option value="DISABLED">DISABLED</option>
                            </select>
                            {validator.message(
                                "status",
                                selectedRecord.active,
                                "required"
                            )}
                            {
                                validatorStatus ? (<span className="text-xs text-red-700"
                                    id="name">{getValidatorError("status")}</span>) : ''
                            }
                        </div>
                        <div className="col-span-12 sm:col-span-4">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="user-type">
                                index
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="index"
                                type="number"
                                min="1"
                                placeholder="Index"
                                required
                                name="index"
                                onChange={(event) => setSelectedRecord({
                                    ...selectedRecord,
                                    index: event.target.value
                                })} value={selectedRecord.index}
                            />
                            {validator.message(
                                "index",
                                selectedRecord.index,
                                "required|integer"
                            )}
                            {
                                validatorStatus ? (<span className="text-xs text-red-700"
                                    id="name">{getValidatorError("index")}</span>) : ''
                            }
                        </div>
                    </div>
                    <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
                        <div className="col-span-12 sm:col-span-4">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="user-type">
                                Title
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="index"
                                type="text"
                                min="1"
                                name="title"
                                placeholder="Title"
                                required
                                onChange={(event) => setSelectedRecord({
                                    ...selectedRecord,
                                    title: event.target.value
                                })} value={selectedRecord.title}
                            />
                            {validator.message(
                                "title",
                                selectedRecord.title,
                                "required"
                            )}
                            {
                                validatorStatus ? (<span className="text-xs text-red-700"
                                    id="name">{getValidatorError("title")}</span>) : ''
                            }
                        </div>
                        <div className="col-span-12 sm:col-span-4">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="user-type">
                                valid from
                            </label>
                            <Datetime
                                isValidDate={validFrom}
                                timeFormat={""}
                                dateFormat={"DD/MM/YYYY"}
                                inputProps={{
                                    readOnly: true,
                                    disabled: (!!id),
                                    placeholder: 'pick date',
                                    className: "focus:outline-none"
                                }}
                                className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4 ${id ? "bg-gray-200" : ""}`}
                                onChange={(event: any) => {

                                    setSelectedRecord({

                                        ...selectedRecord,
                                        fromDate: moment(new Date(event).toISOString()).format("MM/DD/YYYY"),
                                        toDate: ((event.isAfter(new Date(selectedRecord.toDate)) ? moment(new Date(event).toISOString()).format("MM/DD/YYYY") : moment(selectedRecord.toDate).format("MM/DD/YYYY"))),
                                    });
                                }} value={moment(selectedRecord.fromDate)}

                                renderInput={(props) => {
                                    return <input {...props}
                                    style={{width:'100%'}}
                                        value={(selectedRecord.fromDate) ? (selectedRecord.fromDate) : ''} />
                                }}
                            />

                        </div>
                        <div className="col-span-12 sm:col-span-4">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="user-type">
                                valid to
                            </label>
                            <Datetime
                                isValidDate={valid}
                                timeFormat={""}
                                dateFormat={"DD/MM/YYYY"}
                                inputProps={{readOnly: true, placeholder: 'pick date', className: "focus:outline-none" }}
                                className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4 
             `}
                                onChange={(event: any) => setSelectedRecord({
                                    ...selectedRecord,
                                    toDate: moment(new Date(event).toISOString()).format("MM/DD/YYYY"),
                                })} value={moment(selectedRecord.toDate)}

                                renderInput={(props) => {
                                    return <input {...props}
                                    style={{width:'100%'}}
                                        value={(selectedRecord.toDate) ? (selectedRecord.toDate) : ''} />
                                }}
                            />
                        </div>
                    </div>
                    <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
                        <div className="col-span-12 sm:col-span-4">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="user-type">
                                Valid Duration (Days)
                            </label>
                            <div className="justify-between flex">
                                <div className="col-span-12 sm:col-span-2">
                                    <input type="checkbox" onChange={(event) => {
                                        setChecked(!checked);
                                        setSelectedRecord({
                                            ...selectedRecord,
                                            validDuration: 1
                                        });
                                        // if(!checked){
                                        //     setSelectedRecord({
                                        //         ...selectedRecord,
                                        //         validDuration: 1
                                        //     });
                                        // }
                                    }} value={1} checked={checked} id="not-expired" name="valid-duration" />
                                    <label htmlFor="not-expired"> Not Expired</label>
                                </div>
                                <div className="col-span-12 sm:col-span-2">
                                    <input disabled={checked}
                                        className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4 ${checked ? "bg-gray-200" : ""}`}
                                        placeholder="Valid Duration"
                                        required
                                        id="number"
                                        min={1}
                                        type="number"
                                        name="valid-duration"
                                        onKeyPress={(e: any) => {
                                            if (e.code === 'Minus') {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(event) => {
                                            setSelectedRecord({
                                                ...selectedRecord,
                                                validDuration: event.target.value
                                            });
                                        }} value={(!checked) ? selectedRecord.validDuration : ''}
                                    />
                                </div>
                            </div>
                            {validator.message(
                                "valid-duration",
                                selectedRecord.validDuration,
                                !checked ? 'required|numeric|min:1,num' : 'required'
                            )}
                            {
                                validatorStatus ? (<span className="text-xs text-red-700"
                                    id="name">{getValidatorError("valid-duration")}</span>) : ''
                            }
                        </div>
                    </div>

                    <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
                        <div className="col-span-12 sm:col-span-auto">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="user-type">
                                Description
                            </label>
                            <textarea
                                id="description"
                                name="description"
                                rows={3}
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                onChange={(event) => setSelectedRecord({
                                    ...selectedRecord,
                                    description: event.target.value
                                })} value={selectedRecord.description}
                            />
                            {validator.message(
                                "description",
                                selectedRecord.description,
                                "required"
                            )}
                            {
                                validatorStatus ? (<span className="text-xs text-red-700"
                                    id="name">{getValidatorError("description")}</span>) : ''
                            }
                        </div>
                    </div>
                    {/* add sample docs */}
                    <div>
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Sample Documents</h3>
                        </div>
                        <div className="flex items-center justify-end border-t border-solid border-blueGray-200">
                            <div className="relative">
                                <button
                                    className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm cursor-pointer px-6 py-2 mr-6 m-2"
                                    type="button"
                                >
                                    Select File
                                </button>
                                <input type="file"
                                    id="avatar" name="avatar"
                                    accept=".pdf"
                                    className="cursor-pointer"
                                    onChange={(event) => { changeHandler(event) }}
                                    ref={ref}
                                    style={{ opacity: 0.0, backgroundColor: 'red', position: "absolute", top: 0, left: 0, bottom: 0, right: 0, width: "100%", height: "100%" }}
                                />
                            </div>
                        </div>
                        {/* <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5"> */}
                        {selectedFiles.map((data: any, index: any) => (
                            <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5" key={index}>
                                <div className="col-span-12 sm:col-span-2">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="user-type">
                                        File
                                    </label>
                                    <div>
                                        <span className="mt-1 block w-full py-2 px-3 bg-white font-bold uppercase">
                                            {data.fileName}</span>
                                    </div>
                                </div>
                                <div className="col-span-12 sm:col-span-3">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="user-type">
                                        Name
                                    </label>
                                    <input
                                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        id="index"
                                        type="text"
                                        min="1"
                                        name="name"
                                        placeholder="name"
                                        onChange={(event) => onFileDataChange(index, event.target.value, "name")}
                                        value={data.name}
                                    />
                                </div>
                                <div className="col-span-12 sm:col-span-3">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="user-type">
                                        Description
                                    </label>
                                    <input
                                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        id="index"
                                        type="text"
                                        min="1"
                                        name="description"
                                        placeholder="Description"
                                        onChange={(event) => onFileDataChange(index, event.target.value, "description")}
                                        value={data.description}
                                    />
                                </div>
                                {data.resource != "" &&
                                    <div className="col-span-12 sm:col-span-1">
                                        <label
                                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="user-type">
                                            ACTIVE
                                        </label>
                                        <div className="flex justify-between items-center" onClick={() => {
                                            changeStatusDocTypeSample(data.active, index)
                                        }}>
                                            <div
                                                className={`w-14 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out ${("ENABLED" === data.active) ? "bg-green-400" : ""}`}>
                                                <div
                                                    className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${("ENABLED" === data.active) ? "translate-x-6" : ""}`}>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                }
                                <div className="col-span-12 sm:col-span-3">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="user-type">
                                        Action
                                    </label>
                                    <button
                                        id="modal"
                                        className="text-red-900 border border-red-300 rounded-md bg-red-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => removeFile(index)}
                                    >
                                        Remove
                                    </button>
                                    {data.resource != "" &&
                                        <button
                                            id="modal"
                                            className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => viewSampleFiles(data.resource)
                                            }
                                        >
                                            View
                                        </button>
                                    }

                                </div>
                            </div>
                        ))
                        }

                        {/* </div> */}
                    </div>

                    {/* end */}
                    <div
                        className="flex items-center justify-end sm:py-3  sm:px-6 border-t border-solid border-blueGray-200 rounded-b">
                        <div>
                            <button
                                id="modal"
                                className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => history.goBack()}
                            >
                                Close
                            </button>
                        </div>
                        <div>
                            <button
                                className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                                type="button" onClick={() => save()}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Layout>
    );
};

export default ConfigDoc;
