import React from "react";
import { Route } from "react-router-dom";

import CountryTable from "../Components/CountryTable";


export const CountryRoutes: React.FC = () => {
    return (
        <>
            <Route path="/master/country" exact component={CountryTable} />
        </>
    );
};
