import Http from "../../library/http.library";
import { httpMethods } from "../../library/enum";
import { makeQuery } from "../../Common/util.helper";
import axios from "axios";

export const getPartnerList = async (tokens: any, currentPage: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/partners?isPaginated=true&page=${currentPage}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const UpdatePartner = async (data: any, tokens: any) => {
  return Http.setMethod(httpMethods.patch)
    .setUrl(`/partners/${data.partnerId}`)
    .setData(data)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const filterPartner = async (
  data: any,
  currentPage: number,
  tokens: any
) => {
  var query = {
    ...(data.name ? { name: data.name } : {}),
    ...(data.active ? { active: data.active } : {}),
    page: currentPage,
  };
  const params = makeQuery(query);
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/partners?isPaginated=true${params}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const addNewPartner = async (data: any, tokens: any) => {
  return await Http.setMethod(httpMethods.post)
    .setUrl(`/partners`)
    .setData({
      name: data.name,
      email: data.email,
      active: data.active,
    })
    .setToken(tokens.idToken.jwtToken)
    .request();
};

//credit limit
export const addCreditLimit = async (data: any, tokens: any) => {
  return await Http.setMethod(httpMethods.post)
    .setUrl(`/partner-credit`)
    .setData({
      partnerId: data.id,
      dailySellLimit: data.dailySellLimit,
    })
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const updateCreditLimit = async (data: any, tokens: any) => {
  return Http.setMethod(httpMethods.patch)
    .setUrl(`/partner-credit/${data.id}`)
    .setData({
      partnerId: data.partnerId,
      dailySellLimit: data.dailySellLimit,
    })
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getCreditLimit = async (id: any, tokens: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/partner-credit/${id}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getApiKeysById = async (id: any, tokens: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/partner/api-key?partnerId=${id}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getFtpAccountsByPartner = async (id: any, tokens: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/partner/ftp?partnerId=${id}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const generateApiKey = async (id: any, tokens: any) => {
  return await Http.setMethod(httpMethods.post)
    .setUrl(`/partner/api-key`)
    .setData({
      partnerId: id,
      active: "ENABLED",
    })
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const generateFtpAccoount = async (id: any, tokens: any) => {
  return await Http.setMethod(httpMethods.post)
    .setUrl(`/partner/ftp`)
    .setData({
      partnerId: id,
      type: "SETTLEMENT_FTP",
    })
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const updateApiKey = async (data: any, tokens: any) => {
  return await Http.setMethod(httpMethods.patch)
    .setUrl(`/partner/api-key/${data.id}`)
    .setData({
      active: data.active,
    })
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const updateFtpAccount = async (data: any, tokens: any) => {
  return await Http.setMethod(httpMethods.patch)
    .setUrl(`/partner/ftp/${data.id}`)
    .setData({
      active: data.active,
    })
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getReportSummary = async (tokens: any, filters: any) => {
  let status =
    filters?.status?.length > 0
      ? "&status=" +
        filters?.status?.map((item: any) => item?.status)?.join(",")
      : "";

  return await Http.setMethod(httpMethods.get)
    .setUrl(
      `/order-trade-summary/eb?page=${filters?.page}&pageSize=${
        filters?.pagesize
      }&fromDate=${filters?.fromDate}&toDate=${filters?.toDate}${
        filters?.partnerId ? "&partnerId=" + filters?.partnerId : ""
      }&metalCode=${filters?.metalCode}&itemCode=${filters?.itemCode}&vault=${
        filters?.vault
      }${
        filters?.isMetalCovered !== "All"
          ? filters?.isMetalCovered === "Covered"
            ? "&isMetalCovered=true"
            : "&isMetalCovered=false"
          : ""
      }${
        filters?.isFxCovered !== "All"
          ? filters?.isFxCovered === "Covered"
            ? "&isFxCovered=true"
            : "&isFxCovered=false"
          : ""
      }&orderBy=${filters?.orderBy}&order=${
        filters?.order
      }${status}&isPaginated=true`
    )
    .setResponseType("application/json")
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getTradeRecords = async (tokens: any, filters: any) => {
  let status =
    filters?.status?.length > 0
      ? "&status=" +
        filters?.status?.map((item: any) => item?.status)?.join(",")
      : "";

  return await Http.setMethod(httpMethods.get)
    .setUrl(
      `/order-trade-record?page=${filters?.page}&pageSize=${
        filters?.pagesize
      }&fromDate=${filters?.fromDate}&toDate=${filters?.toDate}${
        filters?.partnerId ? "&partnerId=" + filters?.partnerId : ""
      }&metalCode=${filters?.metalCode}&itemCode=${filters?.itemCode}&vault=${
        filters?.vault
      }${
        filters?.isMetalCovered !== "All"
          ? filters?.isMetalCovered === "Covered"
            ? "&isMetalCovered=true"
            : "&isMetalCovered=false"
          : ""
      }${
        filters?.isFxCovered !== "All"
          ? filters?.isFxCovered === "Covered"
            ? "&isFxCovered=true"
            : "&isFxCovered=false"
          : ""
      }&orderBy=${filters?.orderBy}&order=${
        filters?.order
      }${status}&isPaginated=${!!filters?.isPaginated}`
    )
    .setResponseType("application/json")
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const reportDownload = async (tokens: any, filters: any) => {
  let status = "";
  if (filters?.status?.length > 0)
    status = `&status=${filters?.status
      ?.map((item: any) => item.status)
      .join(",")}`;
  return await Http.setMethod(httpMethods.get)
    .setResponseType("application/json")
    .setUrl(
      `/trade-summary-report-download/eb?page=${filters?.page}&pageSize=${
        filters?.pagesize
      }&fromDate=${filters?.fromDate}&toDate=${filters?.toDate}${
        filters?.partnerId ? "&partnerId=" + filters?.partnerId : ""
      }${
        filters?.partnerName ? "&partnerName=" + filters?.partnerName : ""
      }&metalCode=${filters?.metalCode}&itemCode=${filters?.itemCode}&vault=${
        filters?.vault
      }${
        filters?.isFxCovered !== "All"
          ? filters?.isFxCovered === "Covered"
            ? "&isFxCovered=true"
            : "&isFxCovered=false"
          : ""
      }${
        filters?.isMetalCovered !== "All"
          ? filters?.isMetalCovered === "Covered"
            ? "&isMetalCovered=true"
            : "&isMetalCovered=false"
          : ""
      }&orderBy=${filters?.orderBy}&order=${filters?.order}${status}`
    )
    .setToken(tokens.idToken.jwtToken)
    .request();
};




const DashboardReportService = {
  getPartnerList,
  UpdatePartner,
  filterPartner,
  addNewPartner,
  addCreditLimit,
  updateCreditLimit,
  getCreditLimit,
  getApiKeysById,
  getFtpAccountsByPartner,
  generateApiKey,
  updateApiKey,
  updateFtpAccount,
  generateFtpAccoount,
  getReportSummary,
  reportDownload,
  getTradeRecords
};

export default DashboardReportService;
