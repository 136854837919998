import React,{useState} from "react";
import Layout from "../../Layout/Layout";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import QueryTable from "../Components/shared/QueryTable";
import QueryTax from "./shared/QueryTax";


const TransQuery: React.FC = () => {

  const [openDialog, setOpenDialog] = useState(false);

  const dialogBoxFunction = () => setOpenDialog(true);
  return (
    <Layout>
      <h1 className="text-3xl font-bold mb-4">{"Transaction Query"}</h1>
      <div className="flex flex-row">
        <div className="flex-1 border bg-white shadow-sm sm:rounded-md ">
          <div className="flex bg-gray-200 p-4 py-6">
            

            <div className="flex-initial">
              <div className="px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  htmlFor="grid-state"
                >
                  From Date
                </label>
                <div className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border shadow-sm">
                  <Datetime
                    inputProps={{ className: "focus:outline-none" }}
                    // value={moment(date)}
                    timeFormat={false}
                    dateFormat={"YYYY-MM-DD"}
                    // onChange={(e: any) => {
                    //     // setDisable(false);
                    //     setDate(new Date(e).toISOString())
                    //     ifTheSameDate(e);
                    // }
                    // }
                  />
                </div>
              </div>
            </div>

            <div className="flex-initial">
              <div className="px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  htmlFor="grid-state"
                >
                  To Date
                </label>
                <div className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border shadow-sm">
                  <Datetime
                    inputProps={{ className: "focus:outline-none" }}
                    // value={moment(date)}
                    timeFormat={false}
                    dateFormat={"YYYY-MM-DD"}
                    // onChange={(e: any) => {
                    //     // setDisable(false);
                    //     setDate(new Date(e).toISOString())
                    //     ifTheSameDate(e);
                    // }
                    // }
                  />
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="md:w-full px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  htmlFor="grid-state"
                >
                  Partner
                </label>
                <div className="relative">
                  <select
                    // onChange={(e) => { setSelectedVault(e.target.value) }}
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    // value={selectedVault}
                    required={true}
                  >
                    <option value="ebl">EBL</option>
                    <option value="bajaj">BAJAJ</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="md:w-full px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  htmlFor="grid-state"
                >
                  Metal
                </label>
                <div className="relative">
                  <select
                    // onChange={(e) => setSelectedMetal(e.target.value)}
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    // value={selectedMetal}
                  >
                    <option value="">All</option>
                    <option value="Ag">Silver</option>
                    <option value="Pt">Platinum</option>
                    <option value="Au">Gold</option>
                    <option value="Pd">Palladium</option>
                  </select>
                </div>
              </div>
            </div>


            <div className="flex-1">
              <div className="md:w-full px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  htmlFor="grid-state"
                >
                  VSO Receipt
                </label>
                <div className="relative">
                  <select
                    // onChange={(e) => setSelectedMetal(e.target.value)}
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    // value={selectedMetal}
                  >
                    <option value="R1">R1</option>
                    <option value="R2">R2</option>
                    <option value="R3">R3</option>
                    <option value="R4">R4</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="md:w-full px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  htmlFor="grid-state"
                >
                  Inv Ref
                </label>
                <div className="relative">
                  <select
                    // onChange={(e) => setSelectedMetal(e.target.value)}
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    // value={selectedMetal}
                  >
                    <option value="R1">R1</option>
                    <option value="R2">R2</option>
                    <option value="R3">R3</option>
                    <option value="R4">R4</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="md:w-full px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  htmlFor="grid-state"
                >
                  FOP
                </label>
                <div className="relative">
                  <select
                    // onChange={(e) => setSelectedMetal(e.target.value)}
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    // value={selectedMetal}
                  >
                    <option value="R1">R1</option>
                    <option value="R2">R2</option>
                    <option value="R3">R3</option>
                    <option value="R4">R4</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="md:w-full px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  htmlFor="grid-state"
                >
                  CustId
                </label>
                <div className="relative">
                  <select
                    // onChange={(e) => setSelectedMetal(e.target.value)}
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    // value={selectedMetal}
                  >
                    <option value="R1">R1</option>
                    <option value="R2">R2</option>
                    <option value="R3">R3</option>
                    <option value="R4">R4</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="md:w-full px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  htmlFor="grid-state"
                >
                  Sale/Buy Back
                </label>
                <div className="relative">
                  <select
                    // onChange={(e) => setSelectedMetal(e.target.value)}
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    // value={selectedMetal}
                  >
                    <option value="sale">Sale</option>
                    <option value="buyback">Buy Back</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="md:w-full px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  htmlFor="grid-state"
                >
                  Inv Status
                </label>
                <div className="relative">
                  <select
                    // onChange={(e) => setSelectedMetal(e.target.value)}
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    // value={selectedMetal}
                  >
                    <option value="R1">Enable</option>
                    <option value="R2">Disable</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="flex py-5 justify-end">
              <button
                className="bg-indigo-400  text-white px-4 py-2 rounded font-bold"
                // onClick={onQueryClick}
              >
                Query
              </button>
            </div>
            <div className="flex py-5 justify-end">
              <button
                className="bg-indigo-400  text-white px-4 py-2 rounded font-bold"
                // onClick={onQueryClick}
              >
                Export
              </button>
            </div>
          </div>
        </div>
      </div>

<QueryTable
openingDialog={dialogBoxFunction}
openDialog={openDialog}
/>

<QueryTax/>


      

    </Layout>
  );
};

export default TransQuery;
