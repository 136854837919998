import Http from "../../library/http.library";
import { httpMethods } from "../../library/enum";

class PushNotificationService {
  /**
   * orderSalesInvoicesDownload
   * @param key Invoice Key
   * @param tokens Auth tokens from Store
   * @returns Response
   */
  async sendToOne(data: any, tokens: any) {
    return (
      Http.setMethod(httpMethods.post)
        .setUrl(`/sendtoOne-notification`)
        .setData(data)
        //   .setToken(tokens.idToken.jwtToken)
        .request()
    );
  }
  async sendToMany(data: any, tokens: any) {
    return (
      Http.setMethod(httpMethods.post)
        .setUrl(`/sendtoMany-notification`)
        .setData(data)
        //   .setToken(tokens.idToken.jwtToken)
        .request()
    );
  }
  async getAllMessages(query: any, page: number) {
    return (
      Http.setMethod(httpMethods.get)
        .setUrl(`/getAllNotificationDetails?page=${page}${query}`)
        //   .setToken(tokens.idToken.jwtToken)
        .request()
    );
  }
}

export default new PushNotificationService();
