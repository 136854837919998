import create from "zustand";
import produce from 'immer';

type State = { open: boolean; defOpen: () => void, nsvDefaultAsk: any, nsvDefaultBid: any, spreadDefaultBid: any, uom: any, currency: any, uomConversions: any,weightOrAmount:any,isruleExpired:boolean,ruleStatus:string};

export const useStore = create<State>((set) => ({
  open: true,
  nsvDefaultAsk: -1,
  nsvDefaultBid: -1,
  spreadDefaultBid: -1,
  uom: "g",
  currency: "INR",
  uomConversions: [],
  weightOrAmount: "weight",
  isruleExpired: false,
  ruleStatus :"LOGGED",
  setNsvDefaultAsk: (nsvDefaultAsk: any) => set({ nsvDefaultAsk }),
  setNsvDefaultBid: (nsvDefaultBid: any) => set({ nsvDefaultBid }),
  setSpreadDefaultBid: (spreadDefaultBid: any) => set({ spreadDefaultBid }),
  setUOM: (uom: any) => set({ uom }),
  setIsRuleExpired: (isruleExpired: any) => set({ isruleExpired }),
  setIsRuleStatus: (ruleStatus: any) => set({ ruleStatus }),
  setCurrency: (currency: any) => set({ currency }),
  setUOMConversions: (uomConversions: any) => set({ uomConversions }),
  setWeightOrAmount: (weightOrAmount: any) => set({ weightOrAmount }),
  defOpen: () => set(produce((draft) => ({ open: !draft.open }))),
}));