import React, { useEffect, useState } from "react";
import moment from "moment";
import Loader from "../../Components-ui/Loader";
import Layout from "../Layout/Layout";

interface PropsType {
  userData: any;
  Loading: any;
  isCompleted: any;
}
const UserTable: React.FC<PropsType> = ({ userData, Loading, isCompleted }) => {
  return (
    <>
      {Loading ? (
        <Loader />
      ) : (
        <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
          <thead>
            <tr className="text-left border-b border-gray-300">
              <th className="sticky top-0 px-3 py-2 bg-gray-800">Date</th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800">First Name</th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800">Last Name</th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800">Pan</th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800">Phone</th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800">IsValid</th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800">
                Iscompleted
              </th>
            </tr>
          </thead>
          <tbody>
            {userData.length > 0 ? (
              userData.map((row: any, index: number) => {
                return (
                  <tr
                    key={index}
                    className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                  >
                    <td className="py-3 px-3 text-left relative">
                      {moment(row?.createdAt).format("DD-MM-YYYY")}
                    </td>
                    <td className="py-3 px-3 text-left relative">
                      {row?.firstName}
                    </td>
                    <td className="py-3 px-3 text-left relative">
                      {row?.lastName}
                    </td>
                    <td className="py-3 px-3 text-left relative">{row?.pan}</td>
                    <td className="py-3 px-3 text-left relative">
                      {row?.mobile}
                    </td>

                    <td className="py-3 px-3 text-left relative">
                      {row?.isValid ? "Yes" : "No"}
                    </td>
                    <td className="py-3 px-3 text-left relative">
                      {row?.isCompleted ? "Yes" : "No"}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                <td
                  colSpan={8}
                  className="text-center py-3 px-3 text-left relative"
                >
                  No data to display
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </>
  );
};

export default UserTable;
