import React from "react";
import Layout from "../../Layout/Layout";
import {ToastContainer} from "react-toastify";

import TradeExecutionForm from "./TradeExecutionForm";

interface PropsType {
}

const AddNewTradeExecutionRule: React.FC<PropsType> = () => {

    return (
        <>
            <Layout type={"TradeExecution"}>
                <ToastContainer />
                <div className="flex justify-between items-center mb-5">
                    <TradeExecutionForm
                        isEdit={false}
                        data={null}
                    />
                </div>
            </Layout>
        </>
    );
};

export default AddNewTradeExecutionRule;
