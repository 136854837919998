import React, { useState, useEffect } from "react";
import { useAuthStore } from "../../../Store/AuthStore";
import Layout from "../../Layout/Layout";
import { useHistory, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import UserListService from "../Services/UserList.Service";
import moment from "moment";
import CountryService from "../../Country/Services/country.service";

export const SingleUSer: React.FC = () => {
  const [Loading, setLoading] = useState<any>(true);
  const [userDetails, setUserDetails] = useState<any>({});
  const [show, setShow] = useState<boolean>(false);
  const [status, setStatus] = useState<any>("");
  const [reason, setReason] = useState<any>("");
  const [UserActivityLogs, setUserActivityLogs] = useState<any>([]);

  const { tokens } = useAuthStore();
  let history = useHistory();
  let user_id: any = useParams();

  const Get_user_list = async () => {
    await UserListService.getSingleUser(user_id.id, tokens)
      .then((response: any) => {
        setUserDetails(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const Get_activity_logs = async () => {
    await UserListService.enableDisableUserActivityLogs(tokens, user_id.id)
      .then((response: any) => {
       
        setUserActivityLogs(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  useEffect(() => {
    Get_user_list();
    Get_activity_logs();
  }, []);

  const enableDisableUser = async (e: any) => {
    setShow(true);
    setStatus(e.target.checked);
  };

  /**
   * Enable or disable user
   * @param e
   */
  const enableDisableUserFunc = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    let payload = {
      enable: status,
      reason: reason,
      //   enable: e.target.checked,
      users: [user_id.id],
    };
    try {
      await UserListService.enableDisableUser(tokens, payload);
      await Get_user_list();
      await Get_activity_logs();
      setLoading(false);
      setShow(false);
      return toast.success("User was successfully updated");
    } catch (e: any) {
      setLoading(false);
      console.log(e.data.errors);
      if (e.data.errors.length > 0) {
        setShow(false);
        return toast.error(e.data.errors[0].title);
      }
      return toast.error("Something went wrong");
    }
  };

  const handleChange = (e: any) => {
    setReason(e.target.value);
  };

  const hideModal = () => {
    setShow(false);
  };

  return (
    <Layout type={"UserModule"}>
      <ToastContainer />
      <div>
        <button
          onClick={() => {
                    history.goBack()
          }}
          className="-mr-2 flex items-center rounded text-white font-bold text-xs uppercase"
        >
                <svg className="w-7" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd"
                     clip-rule="evenodd">
                    <path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z"/>
                </svg>
                {" "}

        </button>
      </div>
      <div className="flex justi0fy-between items-center mb-5">
            <h1 className="text-2xl font-bold tracking-tight">
                {"User Details"}
            </h1>
      </div>
        {Loading ? (<Loader/>) : (
        <div className="flex flex-row">
          <div className="border bg-white rounded border-gray-200 shadow">
            <div className="flex-1 p-2">
              <fieldset className="border rounded p-1 border-gray-300 pb-4 flex mt-3">
                <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
                  {"Details"}
                </legend>
                <div className="w-full">
                  <div className="flex flex-wrap -mx-3 mb-2">
                    <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        className="block text-sm font-medium text-gray-700"
                                            htmlFor="user-id">
                        First Name
                      </label>
                      <input
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"

                                            name="user-id" id="user-id" type="text" placeholder="User ID"
                        autoComplete="none"
                                            value={userDetails.firstName + ' ' + userDetails.lastName} disabled/>
                    </div>
                    <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        className="block text-sm font-medium text-gray-700"
                                            htmlFor="email">
                        Email
                      </label>
                      <input
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            id="email" type="text" placeholder="Email" autoComplete="none"
                                            value={userDetails.email?.value} disabled/>
                    </div>
                    <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        className="block text-sm font-medium text-gray-700"
                                            htmlFor="mobile">
                        Mobile
                      </label>
                      <input
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            id="mobile" type="text" placeholder="Mobile" autoComplete="none"
                                            value={userDetails.mobile?.value} disabled/>
                    </div>
                    <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        className="block text-sm font-medium text-gray-700"
                                            htmlFor="user-type">
                        User Type
                      </label>
                      <div className="relative">
                        <input
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                id="mobile" type="text" autoComplete="none"
                                                value={userDetails.userType} disabled/>
                      </div>
                    </div>
                    <div className="pb-3 w-full px-3 mb-6 md:mb-0">
                      <label
                        className="block text-sm font-medium text-gray-700"
                                            htmlFor="partner">
                        Partner
                      </label>
                      <div className="relative">
                        <input
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                id="mobile" type="text" autoComplete="none"
                                                value={userDetails.partner?.name} disabled/>
                      </div>
                    </div>
                    <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        className="block text-sm font-medium text-gray-700"
                                            htmlFor="partner">
                        Created By
                      </label>
                      <div className="relative">
                        <input
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                id="mobile" type="text" autoComplete="none"
                                                value={userDetails.createdBy} disabled/>
                      </div>
                    </div>
                    <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        className="block text-sm font-medium text-gray-700"
                                            htmlFor="partner">
                        Updated By
                      </label>
                      <div className="relative">
                        <input
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                id="mobile" type="text" autoComplete="none"
                                                value={userDetails.updatedBy} disabled/>
                      </div>
                    </div>
                    <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        className="block text-sm font-medium text-gray-700"
                                            htmlFor="partner">
                        Created Date
                      </label>
                      <div className="relative">
                        <input
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                id="mobile" type="text" autoComplete="none"
                                                value= {moment(userDetails.createdAt).format("DD-MM-YYYY HH:mm")} disabled/>
                      </div>
                    </div>
                    <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        className="block text-sm font-medium text-gray-700"
                                            htmlFor="partner">
                        Updated Date
                      </label>
                      <div className="relative">
                        <input
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                id="mobile" type="text" autoComplete="none"
                                                value= {moment(userDetails.updatedAt).format("DD-MM-YYYY HH:mm")} disabled/>
                      </div>
                    </div>
                    <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        className="block text-sm font-medium text-gray-700"
                                            htmlFor="partner">
                        Active
                      </label>
                      <div className="relative">
                        {/*<div className="flex justify-between items-center">
                                                <div
                                                    className={`w-14 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out ${("ENABLED" === userDetails.active) ? "bg-green-400" : ""}`}>
                                                    <div
                                                        className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${("ENABLED" === userDetails.active) ? "translate-x-6" : ""}`}>
                                                    </div>
                                                </div>
                                            </div>*/}
                                            <div
                                                className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                          <input
                            type="checkbox"
                            name="toggle"
                            id={`toggle-user-${user_id.id}`}
                                                    checked={userDetails.active === 'ENABLED'}
                            className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                            onChange={(e) => enableDisableUser(e)}
                          />
                                                <label htmlFor={`toggle-user-${user_id.id}`}
                                                       className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"/>
                        </div>
                      </div>
                    </div>
                    {show && (
                      <>
                        <div className="">
                          <h1 className="font-bold">{"Reason"}</h1>
                          <form onSubmit={enableDisableUserFunc}>
                            <textarea
                              className="border p-3 mb-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                              // onChange={(e) => handleChange(e)}
                              // value={reason}
                              onChange={handleChange}
                            ></textarea>
                            <button
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mb-2 rounded focus:outline-none focus:shadow-outline"
                              // onClick={enableDisableUser1}
                              type="submit"
                            >
                              Save
                            </button>
                          </form>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div className="flex-none h-full w-2/4 bg-white shadow-sm border ml-2 p-2 sm:rounded-md">
            <fieldset className="border bg-gray-200 rounded p-1 border-gray-300 pb-4 flex mt-3">
              <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
                {"KYC"}
              </legend>
              <div className="w-full">
                <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                  <thead>
                    <tr className="text-left border-b border-gray-300">
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Name
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Value
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        IsVerified
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {JSON.stringify(userDetails.kyc) !== "{}" ? (
                      Object.keys(userDetails.kyc || {}).map((value, index) => {
                        return (
                          <tr
                            key={index}
                            className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                          >
                            <td className="py-3 px-3 text-left relative">
                              {value}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {userDetails.kyc[value].value}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {userDetails.kyc[value].isVerified
                                ? "TRUE"
                                : "FALSE"}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                        <td
                          colSpan={3}
                          className="text-center py-3 px-3 text-left relative"
                        >
                          No data to display
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </fieldset>
            <br></br>
            <fieldset className="border bg-gray-200 rounded p-1 border-gray-300 pb-4 flex mt-3">
              <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
                {"Activity logs"}
              </legend>
              <div className="w-full">
                <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                  <thead>
                    <tr className="text-left border-b border-gray-300">
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Date
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Reason
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Status
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        User
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {UserActivityLogs?.length > 0 ? (
                      UserActivityLogs.map((value: any, index: any) => {
                        return (
                          <tr
                            key={index}
                            className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                          >
                            <td className="py-3 px-3 text-left relative">
                              {moment(value?.updatedAt).format("DD-MM-YYYY HH:mm")}
                            </td>
                            <td className="py-3 px-3 text-left relative ">
                              {value?.reason}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {value?.status ? "Active" : "Inactive"}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {value?.displayName}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                        <td
                          colSpan={4}
                          className="text-center py-3 px-3 text-left relative"
                        >
                          No data to display
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </fieldset>
          </div>
        </div>
      )}
    </Layout>
  );
};
export default SingleUSer;
