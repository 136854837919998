import Http from "../../library/http.library";
import {httpMethods} from "../../library/enum";
import {makeQuery} from "../../Common/util.helper";


export const getCurrencyData = async (tokens: any,currentPage:any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/currency-master?isPaginated=true&page=${currentPage}`).setToken(tokens.idToken.jwtToken).request();

};

export const updateCurrencyData = async (data: any, tokens: any) => {
    return Http.setMethod(httpMethods.patch).setUrl(`/currency-master/${data.id}`).setData(data).setToken(tokens.idToken.jwtToken).request();
};

export const createCurrencyData = async (data: any, tokens: any) => {
    return await Http.setMethod(httpMethods.post).setUrl(`/currency-master`).setData(data).setToken(tokens.idToken.jwtToken).request();
};

export const filterCurrencyData = async (tokens: any, data: any, currentPage: any) => {
    var query = {
        isPaginated: true,
        ...(data.numeric ? {numeric: data.numeric} : {}),
        ...(data.name ? {name: data.name} : {}),
        ...(data.currencyCode ? {currencyCode: data.currencyCode} : {}),
        ...(data.active ? {active: data.active} : {}),
        page: currentPage,
    }
    const params = makeQuery(query);
    return await Http.setMethod(httpMethods.get).setUrl(`/currency-master?${params}`).setToken(tokens.idToken.jwtToken).request();

};

const CurrencyMasterService = {
    getCurrencyData,
    updateCurrencyData,
    createCurrencyData,
    filterCurrencyData
};

export default CurrencyMasterService;
