// import MasterRuleSettings from "./MasterRuleSettings";
import { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { useHistory, useParams } from "react-router-dom";
import MetalBasisTable from './MetalBasis'
import CustomesDutyTable from './CustomesDuty'
import MetalBasis from './MetalMaster'
import { GetMetalByID } from './Metal.Service';
import { toast, ToastContainer } from "react-toastify";

const MetalTable: React.FC = () => {
  let { id } = useParams<any>();
  const [metal, setMetal] = useState<any>();
  const [inCreateMode, setInCreateMode] = useState<boolean>(false);
  let history = useHistory();

  useEffect(() => {
    console.log("Metal id: ", id);
    fetchMetal();
  }, []);

  function refreshPage() {
    window.location.reload(false);
  }

  const fetchMetal = () => {
    GetMetalByID(id).then((data: any) => {
      console.log("Metal Data: ", data)
      let a = [];
      a.push(data.data.data)
      console.log("Metal Array: ", a)
      setMetal(a);
    }).catch(err => {
      // toast.error("Metal id is not valid");
      console.log("Metal table error", err);
    })
  }

  const showToast = (res: boolean) => {
    res ? toast.success("Success!") : toast.error("Something went wrong!")
  }

  return (
  <Layout type={"VaultingModule"}>
    <ToastContainer/>
    <div className="flex" >
      <button onClick={() =>  history.push(`/metal-master`)}
      className="inline-flex justify-center py-2 px-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
        </svg>
      </button>
    {/* <button onClick={() => {setMetal(metal)}} */}
    <button onClick={refreshPage}
      className="inline-flex justify-center py-2 px-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
       <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
     </svg>
      </button>
    </div>
   
      <div className="w-full">

       <MetalBasis metal={metal} setMetalFunction={setMetal} isEditable={true}></MetalBasis>
       <MetalBasisTable metal={metal} inCreateMode={inCreateMode} isEditable={true}></MetalBasisTable>
       <CustomesDutyTable metal={metal} isEditable={true}></CustomesDutyTable>
        

      </div>
    
  </Layout>
  );
  };

  export default MetalTable;