import React from "react";

const HasAnyAccess = (data: any) => {
  var permissions = JSON.parse(localStorage.getItem("auth-storage")!);
  if (
    permissions?.state?.permissions?.filter(
      (permission: any) => permission.permissionCode === "super.admin"
    ).length === 1
  ) {
    return true;
  } else {
    return (
      permissions?.state?.permissions?.filter((permission: any) =>
        data?.includes(permission.permissionCode)
      ).length > 0
    );
  }
};

export const hasAccess = (data: any) => {
  var permissions = JSON.parse(localStorage.getItem("auth-storage")!);

  return (
    permissions?.state?.permissions?.filter((permission: any) =>
      data?.includes(permission.permissionCode)
    ).length > 0
  );
};

export default HasAnyAccess;
