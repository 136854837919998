import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import ErrorToolTip from "./ErrorToolTip";
import {
  CreateReferenceMaster, CreateReferenceDetails
} from "./EbMasterServices";

interface PropsType {
  show: any;
  setOpen: any;
  masterData?: any;
  masterId?: number;
  window?: string;
}
const FeederPopup: React.FC<PropsType> = ({ show, setOpen, masterData, masterId, window }) => {
  useEffect(() => {
    fetchDetails();
  });

  const [code, setcode] = useState<string>("");

  const [CreateRowErrors, setCreateRowErrors] = useState<any>({});
  const [CreateRowSubmitCount, setCreateRowSubmitCount] = useState<number>(0);
  const [inCreateMode, setInCreateMode] = useState<boolean>(false);
  const [ActiveColCreate, setActiveColCreate] = useState<string>("");

  const [codeValue, setCodeValue] = useState<string>("");
  const [valueDesc, setValueDesc] = useState<string>("");
  const [remark, setRemark] = useState<string>("");
  const [active, setActive] = useState<string>("");

  const fetchDetails = async () => {};

  const onClickCreate = (e: any) => {
    e.preventDefault();
    setCreateRowSubmitCount(0);

    setInCreateMode(!inCreateMode);
  };

  const latest_state = (data: any) => {
    return {
      code,
      ...data,
    };
  };

  const validateReferenceMaster = (data: any) => {
    let result = true;
    let errors: any = {};
    let state = latest_state(data);

    if (state.hsn.length == 0) {
      result = false;
      errors.code = "CODE can't be empty!";
    } else {
      errors.code = "";
    }

    setCreateRowErrors(errors);
    return result;
  };

  return (
    <Transition
      show={show}
      enter="transition ease-out duration-100 transform"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="transition ease-in duration-75 transform"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div className="fixed z-20 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div
            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <button
                  onClick={setOpen()}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center top-0 right-0 absolute rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  X
                </button>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <div className="w-full">
                <h5 className=" font-bold tracking-tight text-center text-gray-600 sm:text-xl">
                  Reference Details
                </h5>
                <table className="min-w-max w-full table-auto rounded-lg">
                  <thead>
                    <tr className="sticky top-0 px-6 py-3 text-green-900 bg-purple-300">
                      <th className="py-3 px-6 text-left">Code Value</th>
                      <th className="py-3 px-6 text-left">Value Description</th>
                      <th className="py-3 px-6 text-left">Value Remark</th>
                      <th className="py-3 px-6 text-left">Active</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-800 text-sm font-light">
                    <tr className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100">
                      <td className="py-3 px-3 text-left relative">
                        <input
                          type="text"
                          className="border p-1"
                          placeholder="code value"
                          onChange={(e) => {
                            setCodeValue(e.target.value);
                          }}
                        />
                      </td>

                      <td className="py-3 px-3 text-left relative">
                        <input
                          type="text"
                          className="border p-1"
                          placeholder="value description"
                          onChange={(e) => {
                            setValueDesc(e.target.value);
                          }}
                        />
                      </td>
                      <td className="py-3 px-3 text-left relative">
                        <input
                          type="text"
                          className="border p-1"
                          placeholder="value remark"
                          onChange={(e) => {
                            setRemark(e.target.value);
                          }}
                        />
                      </td>

                      <td className="py-3 px-3 text-left relative">
                        <input
                          type="radio"
                          id="true"
                          name="radio"
                          value="true"
                          onChange={(e) => {
                            setActive(e.target.value);
                          }}
                        />
                        <label for="true"> Yes </label>
                        <input
                          type="radio"
                          id="false"
                          name="radio"
                          value="false"
                          onChange={(e) => {
                            setActive(e.target.value);
                          }}
                        />
          <label for="false"> No</label>
        </td>
                    </tr>
                  </tbody>
                </table>

                <div className="flex justify-end mt-2">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      if(window==="MASTER_INSERT"){
                        CreateReferenceMaster(
                          {value:
                            [{
                             codeValue:codeValue,
                             valueDescription:valueDesc,
                             valueRemark:remark
                            }], ...masterData }
                            );
                      }else if(window==="DETAILS_INSERT"){
                        CreateReferenceDetails(
                          [{
                            codeValue:codeValue, 
                            valueDescription:valueDesc,
                            valueRemark:remark,
                            active:active,
                            createdBy: masterData.createdBy,
                            updatedBy: masterData.updatedBy,
                            masterId: masterId,
                        }])
                      }
                      
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default FeederPopup;
