import React, { useState, useEffect } from "react";
import { useAuthStore } from "../../../Store/AuthStore";
import Layout from "../../Layout/Layout";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import SimpleReactValidator from "simple-react-validator";
import InvestorListService from "../Services/InvestorList.Service";
import OTPComponent from "./OTPComponent";

export const CreateInvestor: React.FC = () => {
  const [Loading, setLoading] = useState<any>(true);

  const { tokens } = useAuthStore();
  const [countryCode, setCountryCode] = useState("+91");
  const [gstInsList, setGstInsList] = useState([]);
  const [aadhaarAddress, setAadharAddress] = useState("");
  let history = useHistory();
  const [user, setUser] = useState<any>({
    name: "",
    pan: "",
    gstin: "",
    address: "",
    aadhar: "",
    mobile: "",
    commissionRate: "",
    businessName: "",
    aadhaarAddress: aadhaarAddress,
  });
  const [validatorStatus, setValidator] = useState<boolean>(false);
  const [addressData, setAddressData] = useState<any>([]);
  const [busName, setBusName] = useState<any>("");
  const [showOTP, setShowOTP] = useState(false);
  const [mobileVerified, setMobileVerified] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [aadharVerified, setAadharVerified] = useState(false);
  const [isEmailVerifyDisabled, setIsEmailVerifyDisabled] = useState(true);
  const [key, setKey] = useState("");
  const [panName, setPanName] = useState("");

  const [otpId, setOtpId] = useState<any>();

  const simpleValidator = new SimpleReactValidator({
    locale: "en",
    validators: {},
  });
  const validationRef = React.useRef(simpleValidator);
  const validator = validationRef.current;

  let isMobVerifyDisabled = user.mobile.length !== 10;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  let isAadharVerifyDisabled = user.aadhar.length !== 12;

  useEffect(() => {
    setLoading(false);
  }, []);

  const addNew = async () => {
    setValidator(true);
    var data = {
      name: user.name || "",
      email: user.email || "",
      mobile: countryCode + user.mobile || "",
      pan: user.pan || "",
      gstin: user.gstin || "",
      address: addressData || "",
      aadhar: user.aadhar || "",
      commissionRate: user.commissionRate || "",
      businessName: busName || "",
      aadhaarAddress: aadhaarAddress,
    };

    if (busName && !user.gstin) {
      const match = await checkOwnerPanNameSimilarity(panName, user.name);
      if (match) {
        toast.success("Owner Name and Pan Name matched");
        setBusName(busName);
      } else {
        return toast.error("Owner Name and Pan Name do not match");
      }
    }
    if (validator.allValid()) {
      setLoading(true);
      // if (user?.gstin == "") {
      //   setLoading(false);
      //   return toast.error("GSTIN number is required");
      // }
      if (!mobileVerified) {
        setLoading(false);
        return toast.error("Mobile verification is required");
      }
      if (!emailVerified) {
        setLoading(false);
        return toast.error("Email verification is required");
      }
      if (!aadharVerified) {
        setLoading(false);
        return toast.error("Aadhar verification is required");
      }
      await InvestorListService.createInvestor(tokens, data)
        .then(() => {
          toast.success("Investor Added Successfully");
          setUser({
            name: "",
            pan: "",
            gstin: "",
            address: "",
            aadhar: "",
            mobile: "",
            commissionRate: "",
            email: "",
            businessName: "",
          });
          setAddressData([]);
          setAadharAddress("");
          setBusName("");
          setLoading(false);
          history.push("/investors");
        })
        .catch((err) => {
          setLoading(false);

          setUser({
            name: "",
            pan: "",
            gstin: "",
            address: "",
            aadhar: "",
            mobile: "",
            commissionRate: "",
            email: "",
            businessName: "",
          });
          setAadharAddress("");
          //setModalStatus(false);
          console.log(err);
          toast.error(err.data.errors[0].title);
        });

      setValidator(false);
      setEmailVerified(false);
      setAadharVerified(false);
      setMobileVerified(false);
      setIsEmailVerifyDisabled(true);
      setBusName("");
      setAddressData([]);
    }
  };

  const getValidatorError = (nameOfStateProp: any) => {
    const allErrorMessages = validator.getErrorMessages();
    return allErrorMessages[nameOfStateProp];
  };

  const getGstIn = async (data: any) => {
    try {
      const payload = { pan: data };
      const res = await InvestorListService.getGstinList(tokens, payload);
      if (res) {
        setGstInsList(res?.data?.data?.gstins);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    }
  };

  const getPanDetails = async (panNo: any) => {
    try {
      const payload = { pan: panNo };
      const res = await InvestorListService.getpanDetails(tokens, payload);
      const busName = res?.data?.data?.name;
      setPanName(res?.data?.data?.name);
      setBusName(res?.data?.data?.name);
    } catch (err) {
      console.error("Error verifying PAN number:", err);
      toast.error("An error occurred while verifying the PAN number");
    }
  };

  const checkOwnerPanNameSimilarity = async (panName: any, ownwerName: any) => {
    const payload: any = {
      panName: panName,
      ownerName: ownwerName,
    };

    try {
      const response = await InvestorListService.checkNameSimilarity(
        tokens,
        payload
      );
      return response; // Assuming response indicates whether names match
    } catch (err) {
      console.error("Error matching PAN and owner names:", err);
      // toast.error("An error occurred while matching PAN and owner names");
      return false; // Return false if there was an error
    }
  };

  const getInvestorAddressDetails = async (gstinNum: any) => {
    try {
      const payload = { gstin: gstinNum };
      const res = await InvestorListService.getInvestorAddressDetails(
        tokens,
        payload
      );
      const address =
        res?.data?.data?.address?.address1 +
        " " +
        res?.data?.data?.address?.pinCode +
        " " +
        res?.data?.data?.address?.city +
        " " +
        res?.data?.data?.address?.state;
      setAddressData(address);
      setBusName(res?.data?.data?.gst_metadata?.name);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEmailChange = (event: any) => {
    const email = event.target.value;
    setUser({
      ...user,
      email,
    });
    if (emailRegex.test(email)) {
      setIsEmailVerifyDisabled(false);
    } else {
      setIsEmailVerifyDisabled(true);
    }
  };

  const handleSendOtp = (key: string) => {
    sendOtp(key);
    setShowOTP(true);
    setKey(key);
  };

  const sendOtp = async (key: any) => {
    try {
      if (key == "aadhaarNo") {
        const payload = { aadhaarNo: user.aadhar, consent: true };
        const res = await InvestorListService.sendAadhaarOtp(tokens, payload);
        if (res?.data?.data?.success == true) {
          // setLoading(true);
          setShowOTP(true);
        } else {
          toast.error("Something went wrong!!");
        }
      } else {
        const payload =
          key === "mobile_no"
            ? { mobile_no: countryCode + user.mobile }
            : { email: user.email };
        const res = await InvestorListService.sendOTP(tokens, payload, key);
        if (res?.data?.data) {
          // setLoading(true);
          setOtpId(res.data.data.otpId);
        }
      }
    } catch (err: any) {
      console.log(err);
      toast.error(err?.data?.errors[0]?.title);
    }
  };

  const verifyOtp = async (otp: string) => {
    try {
      const payload = {
        otp: `${otp}`,
        otpId: otpId,
      };
      const res = await InvestorListService.verifyOTP(tokens, payload);
      if (res?.data?.data) {
        if (key === "email") {
          setEmailVerified(true);
          setShowOTP(false);
          setKey("");
          toast.success("Email verified successfully!");
        } else if (key == "mobile_no") {
          setMobileVerified(true);
          setShowOTP(false);
          toast.success("Mobile verified successfully!");
          setKey("");
        } else if (key == "aadhar") {
          setAadharVerified(true);
          setShowOTP(false);
          toast.success("Aadhaar verified successfully!");
          setKey("");
        }
      }
    } catch (err: any) {
      console.log(err);
      toast.error(err?.data?.errors[0]?.title);
    }
  };

  const verifyAadhaarOtp = async (otpData: any) => {
    try {
      const data = {
        aadhaarNo: user.aadhar,
        otp: otpData,
      };

      const res = await InvestorListService.verifyAadhaarOtp(tokens, data);
      if (res.status == 200) {
        const res2: any =
          res.data.data.aadharData.address.address1 +
          "," +
          res.data.data.aadharData.address.address2 +
          "," +
          res.data.data.aadharData.address.city +
          "," +
          res.data.data.aadharData.address.pinCode +
          "," +
          res.data.data.aadharData.address.state;

        setAadharAddress(res2);
        setAadharVerified(true);
        setShowOTP(false);
        toast.success("Aadhar verified successfully!");
        setKey("");
      }
    } catch (err: any) {
      console.log(err);
      toast.error(err?.data?.errors[0]?.title);
      setShowOTP(false);
    }
  };

  const resendOtp = async () => {
    try {
      const payload = {
        otpId: otpId,
      };

      const res = await InvestorListService.resendOTP(tokens, payload, key);
      if (res.data.data) {
        toast.success("OTP Sent successfully!");
      }
    } catch (err: any) {
      console.log(err);
      toast.error(err?.data?.errors[0]?.title);
    }
  };
  return (
    <Layout type={"InvestorModule"}>
      <ToastContainer />
      {showOTP ? (
        key == "aadhaarNo" ? (
          <OTPComponent
            data={{ aadhaarNo: user.aadhar }}
            onClose={() => setShowOTP(false)}
            onVerify={(otp: any) => verifyAadhaarOtp(otp)}
            onResend={() => sendOtp("aadhaarNo")}
          />
        ) : (
          <OTPComponent
            data={
              key === "email"
                ? { email: user.email }
                : { mobile_no: countryCode + user.mobile }
            }
            onClose={() => setShowOTP(false)}
            onVerify={(otp: any) => verifyOtp(otp)}
            onResend={() => resendOtp()}
          />
        )
      ) : (
        ""
      )}
      <div>
        <button
          onClick={() => {
            history.goBack();
          }}
          className="-mr-2 flex items-center rounded text-white font-bold text-xs uppercase"
        >
          <svg
            className="w-7"
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fill-rule="evenodd"
            clip-rule="evenodd"
          >
            <path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" />
          </svg>{" "}
        </button>
      </div>
      <div className="flex justi0fy-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">
          {"Create Business Partner"}
        </h1>
      </div>
      {Loading ? (
        <Loader />
      ) : (
        <div className="flex flex-row">
          <div className="border bg-white rounded border-gray-200 shadow">
            <div className="flex-1 p-5">
              <div className="w-full">
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="user-id"
                    >
                      Name
                    </label>
                    <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                      name="user-id"
                      id="user-id"
                      type="text"
                      placeholder="Name"
                      autoComplete="none"
                      onChange={(event) =>
                        setUser({
                          ...user,
                          name: event.target.value,
                        })
                      }
                      value={user.name}
                    />

                    {validator.message("name", user.name, "required")}
                    {validatorStatus ? (
                      <span className="text-xs text-red-700" id="name">
                        {getValidatorError("name")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Email
                    </label>

                    <div className="flex items-center">
                      <input
                        className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                        id="email"
                        type="text"
                        placeholder="Email"
                        autoComplete="none"
                        // onChange={(event) =>
                        //   setUser({
                        //     ...user,
                        //     email: event.target.value,
                        //   })
                        // }
                        onChange={handleEmailChange}
                        value={user.email}
                        disabled={emailVerified}
                      />

                      {emailVerified && !isEmailVerifyDisabled ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="green"
                          style={{ marginLeft: "8px" }}
                        >
                          <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 17.93l-5.477-5.48 1.41-1.41 4.066 4.067 8.044-8.047 1.41 1.41-9.453 9.46z" />
                        </svg>
                      ) : (
                        <button
                          className="ml-2 text-white px-3 py-1 rounded"
                          style={{
                            backgroundColor: isEmailVerifyDisabled
                              ? "gray"
                              : "rgb(34, 11, 59)",
                            fontWeight: "bold",
                            padding: "8px 12px",
                            borderRadius: "4px",
                            color: "white",
                            cursor: isEmailVerifyDisabled
                              ? "not-allowed"
                              : "pointer",
                            opacity: isEmailVerifyDisabled ? 0.6 : 1,
                          }}
                          disabled={isEmailVerifyDisabled}
                          onClick={() => handleSendOtp("email")}
                        >
                          Verify
                        </button>
                      )}
                    </div>

                    {validator.message("email", user.email, "required|email")}
                    {validatorStatus ? (
                      <span className="text-xs text-red-700" id="email">
                        {getValidatorError("email")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="mobile_number"
                    >
                      Mobile No
                    </label>

                    <div className="flex flex-wrap items-stretch w-full relative">
                      <div className="flex -mr-px">
                        <select
                          className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark form-control country-select"
                          onChange={(event) =>
                            setCountryCode(event.target.value)
                          }
                          value={countryCode}
                        >
                          <option value={"+91"}>+91</option>
                          <option value={"+94"}>+94</option>
                        </select>
                      </div>
                      <input
                        className="flex-shrink flex-grow flex-auto  w-100 h-10  leading-normal w-px flex-1 bbg-grey-lighter text-grey-darker border border-grey-lighter rounded rounded-l-none px-3 relative focus:border-blue focus:shadow"
                        id="mobile"
                        name="mobile"
                        type="number"
                        placeholder="Mobile Number"
                        autoComplete="none"
                        onChange={(event) =>
                          setUser({
                            ...user,
                            mobile: event.target.value,
                          })
                        }
                        value={user.mobile}
                        disabled={mobileVerified}
                      />
                      {mobileVerified && !isMobVerifyDisabled ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="green"
                          style={{ margin: "8px", justifyContent: "center" }}
                        >
                          <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 17.93l-5.477-5.48 1.41-1.41 4.066 4.067 8.044-8.047 1.41 1.41-9.453 9.46z" />
                        </svg>
                      ) : (
                        <button
                          className={`ml-2 text-white px-3 py-1 rounded`}
                          style={{
                            backgroundColor: isMobVerifyDisabled
                              ? "gray"
                              : "rgb(34, 11, 59)",
                            fontWeight: "bold",
                            marginLeft: "8px",
                            padding: "8px 12px",
                            borderRadius: "4px",
                            color: "white",
                            cursor: isMobVerifyDisabled
                              ? "not-allowed"
                              : "pointer",
                            opacity: isMobVerifyDisabled ? 0.6 : 1,
                          }}
                          // onClick={() => setShowOTP(true)}
                          disabled={user.mobile.length !== 10}
                          onClick={() => handleSendOtp("mobile_no")}
                        >
                          Verify
                        </button>
                      )}
                    </div>

                    {validator.message(
                      "mobile",
                      user.mobile,
                      "required|number"
                    )}
                    {validatorStatus ? (
                      <span className="text-xs text-red-700" id="mobile">
                        {getValidatorError("mobile")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="aadhar"
                    >
                      Aadhaar
                    </label>

                    <div className="flex items-center">
                      <input
                        className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                        id="aadhar"
                        type="text"
                        placeholder="Aadhaar"
                        autoComplete="none"
                        // onChange={(event) =>
                        //   setUser({
                        //     ...user,
                        //     email: event.target.value,
                        //   })
                        // }
                        onChange={(e) =>
                          setUser({
                            ...user,
                            aadhar: e.target.value,
                          })
                        }
                        value={user.aadhar}
                        disabled={aadharVerified}
                      />

                      {aadharVerified && !isAadharVerifyDisabled ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="green"
                          style={{ marginLeft: "8px" }}
                        >
                          <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 17.93l-5.477-5.48 1.41-1.41 4.066 4.067 8.044-8.047 1.41 1.41-9.453 9.46z" />
                        </svg>
                      ) : (
                        <button
                          className="ml-2 text-white px-3 py-1 rounded"
                          style={{
                            backgroundColor: isAadharVerifyDisabled
                              ? "gray"
                              : "rgb(34, 11, 59)",
                            fontWeight: "bold",
                            padding: "8px 12px",
                            borderRadius: "4px",
                            color: "white",
                            cursor: isAadharVerifyDisabled
                              ? "not-allowed"
                              : "pointer",
                            opacity: isAadharVerifyDisabled ? 0.6 : 1,
                          }}
                          disabled={isAadharVerifyDisabled}
                          onClick={() => handleSendOtp("aadhaarNo")}
                        >
                          Verify
                        </button>
                      )}
                    </div>

                    {validator.message("email", user.email, "required|email")}
                    {validatorStatus ? (
                      <span className="text-xs text-red-700" id="email">
                        {getValidatorError("email")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="user-id"
                    >
                      Aadhaar Address
                    </label>
                    <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                      name="aadharAddress"
                      id="aadharAddress"
                      type="text"
                      placeholder="Aadhaar Address"
                      autoComplete="none"
                      disabled
                      value={aadhaarAddress}
                    />
                  </div>

                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="user-id"
                    >
                      PAN
                    </label>
                    <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                      name="pan"
                      id="pan"
                      type="text"
                      placeholder="PAN"
                      autoComplete="none"
                      onChange={(event) => {
                        setUser({
                          ...user,
                          pan: event.target.value.toUpperCase(),
                          gstin:""
                        });
                        setBusName("");
                        setAddressData("");
                        getGstIn(event.target.value.toUpperCase());
                        if (event.target.value.length == 10) {
                          getPanDetails(event.target.value.toUpperCase());
                        }
                      }}
                      value={user.pan}
                    />
                    {validator.message("pan", user.pan, "required")}
                    {validatorStatus ? (
                      <span className="text-xs text-red-700" id="pan">
                        {getValidatorError("pan")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="gstin"
                    >
                      GSTIN number
                    </label>
                    <select
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="gstin"
                      onChange={(event) => {
                        setUser({
                          ...user,
                          gstin: event.target.value,
                        });
                        getInvestorAddressDetails(event.target.value);
                      }}
                      value={user.gstin}
                    >
                      <option value="">Select GSTIN</option>

                      {gstInsList?.map((el: any, index) => (
                        <option key={index} value={el?.gstin}>
                          {el?.gstin}
                        </option>
                      ))}
                    </select>
                    {validator.message("gstin", user.email, "required|gstin")}
                    {validatorStatus ? (
                      <span className="text-xs text-red-700" id="gstin">
                        {getValidatorError("gstin")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="businessName"
                    >
                      Business Name
                    </label>
                    <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                      name="businessName"
                      id="businessName"
                      type="text"
                      placeholder="Business Name"
                      autoComplete="none"
                      value={busName}
                      disabled
                      // disabled = {user.gstin !=="" ? true : false}
                      onChange={(e) => setBusName(e.target.value)}
                    />
                    {validator.message(
                      "businessName",
                      busName,
                      "required|businessName"
                    )}
                    {validatorStatus ? (
                      <span className="text-xs text-red-700" id="businessName">
                        {getValidatorError("businessName")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="aadhar"
                    >
                      Aadhar (Optional)
                    </label>
                    <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                      name="aadhar"
                      id="aadhar"
                      type="text"
                      placeholder="Aadhar"
                      autoComplete="none"
                      onChange={(event) =>
                        setUser({
                          ...user,
                          aadhar: event.target.value,
                        })
                      }
                      value={user.aadhar}
                    />
                  </div> */}
                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="address"
                    >
                      Business Address
                    </label>
                    <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                      name="address"
                      id="address"
                      type="text"
                      placeholder="Business Address"
                      autoComplete="none"
                      disabled
                      value={addressData}
                    />
                  </div>
                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="CommissionRate"
                    >
                      Revenue Share (%)
                    </label>
                    <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                      name="CommissionRate"
                      id="CommissionRate"
                      type="text"
                      placeholder="Revenue Share"
                      autoComplete="none"
                      onChange={(event) =>
                        setUser({
                          ...user,
                          commissionRate: event.target.value,
                        })
                      }
                      value={user.commissionRate}
                    />
                    {validator.message(
                      "commissionRate",
                      user.commissionRate,
                      "required"
                    )}
                    {validatorStatus ? (
                      <span
                        className="text-xs text-red-700"
                        id="CommissionRate"
                      >
                        {getValidatorError("commissionRate")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0"></div>
                  <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0"></div> */}
                  <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <div className="flex items-center justify-end sm:py-3  sm:px-6 border-solid border-blueGray-200 rounded-b">
                      <div>
                        <button
                          id="modal"
                          className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => history.goBack()}
                        >
                          Close
                        </button>
                      </div>
                      <div>
                        <button
                          // className={`text-white background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md bg-purple-950`}
                          className={`ml-2 text-white px-3 py-1 rounded`}
                          type="button"
                          style={{
                            backgroundColor:
                              mobileVerified && emailVerified && aadharVerified
                                ? "rgb(34, 11, 59)"
                                : "gray",
                            fontWeight: "bold",
                            marginLeft: "8px",
                            padding: "8px 12px",
                            borderRadius: "4px",
                            color: "white",
                            cursor:
                              mobileVerified && emailVerified && aadharVerified
                                ? "pointer"
                                : "not-allowed",
                            opacity:
                              mobileVerified && emailVerified && aadharVerified
                                ? 1
                                : 0.6,
                          }}
                          disabled={
                            !mobileVerified || !emailVerified || !aadharVerified
                          }
                          onClick={() => {
                            addNew();
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};
export default CreateInvestor;
