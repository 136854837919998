import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import DashboardCard from "../Statistics/Components/DashboardCard";
import Dashboard from "./Components/Metal";

const DashboardRoutes: React.FC = () => {
  return (
    <Fragment>
      <Route path="/dashboard" exact component={Dashboard} />
    </Fragment>
    )
};

export default DashboardRoutes;
