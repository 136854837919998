import React from "react";
import ItemTable from "./ItemTable";

interface PropsType {
    subItem: any,
    mainItemKey: number,
    subItemKey: number,
    parentId: string
}

const SubItemList: React.FC<PropsType> = ({
                                              subItem,
                                              mainItemKey,
                                              subItemKey,
                                              parentId
                                           }) => {

    return (
        <tr key={subItemKey} className="bg-gray-100">
            <td colSpan={7}>
                <div id={`flush-collapseOne-${mainItemKey}`} className="accordion-collapse border-0 collapse show"
                     aria-labelledby={`flush-headingOne-${mainItemKey}`} data-bs-parent={`#${parentId}`}>
                    <div className="pt-2 px-3 text-md leading-6 font-medium text-gray-500">
                        SUB ITEMS
                    </div>
                    <div className="accordion-body py-3 px-3">
                        <table
                            className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                            <thead>
                            <tr className="text-center border-b border-gray-300">
                                <th className="sticky top-0 px-3 py-2 bg-purple-950">Name</th>
                                <th className="sticky top-0 px-3 py-2 bg-purple-950">Description</th>
                                <th className="sticky top-0 px-3 py-2 bg-purple-950">Amount</th>
                                <th className="sticky top-0 px-3 py-2 bg-purple-950">Calculated
                                    Quantity
                                </th>
                                <th className="sticky top-0 px-3 py-2 bg-purple-950">Unit Price</th>
                            </tr>
                            </thead>
                            <tbody
                                className="text-gray-800 text-sm font-light accordion accordion-flush"
                                id={`subItemAccordion-${subItemKey}`}
                            >
                            <tr className="accordion-item
                            accordion-header
                            accordion-button
                            w-full
                            py-4 px-4
                            text-gray-800 text-left
                            bg-gray-100
                            rounded-none
                            transition
                            cursor-pointer
                            focus:outline-none"
                                data-bs-toggle="collapse"
                                data-bs-target={`#flush-collapseOneSub-${subItemKey}`}
                                aria-expanded="false"
                                aria-controls={`flush-collapseOneSub-${subItemKey}`}
                                id={`flush-headingOneSub-${subItemKey}`}>
                                <td className="py-3 px-3 text-center relative">{subItem.name}</td>
                                <td className="py-3 px-3 text-center relative">{subItem.description}</td>
                                <td className="py-3 px-3 text-center relative">{subItem.summary.lineAmount}</td>
                                <td className="py-3 px-3 text-center relative">{subItem.calculatedQty}</td>
                                <td className="py-3 px-3 text-center relative">{subItem.summary.unitPrice}</td>
                            </tr>
                            {
                                subItem.taxes && subItem.taxes.length > 0 && (
                                    <tr className="bg-gray-100">
                                        <td colSpan={6}>
                                            <div id={`flush-collapseOneSub-${subItemKey}`}
                                                 className="accordion-collapse border-0 collapse show"
                                                 aria-labelledby={`flush-collapseOneSub-${subItemKey}`}
                                                 data-bs-parent={`subItemAccordion-${subItemKey}`}>

                                                {
                                                    subItem.taxes.length > 0 && (
                                                        <div className="accordion-body py-3 px-3">
                                                            <div
                                                                className="text-md leading-6 pb-2 font-medium text-gray-500">
                                                                TAXES
                                                            </div>
                                                            <ItemTable
                                                                columns={[{label: 'Sub Type', value: 'subTaxType'}, {label: 'Value', value: 'value'}]}
                                                                itemList={subItem.taxes}
                                                                type='taxes'
                                                                isShowTotalSection={true}
                                                                totalLabel="Total Tax Amount"
                                                                totalValue={subItem.summary.lineTaxAmount}
                                                            />
                                                        </div>
                                                    )
                                                }

                        {subItem.discount.length > 0 && (
                          <div className="accordion-body py-3 px-3">
                            <div className="text-md pb-2 leading-6 font-medium text-gray-500">
                              DISCOUNTS
                            </div>
                            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                              <thead>
                                <tr className="text-center  border-b border-gray-300">
                                  <th className="sticky top-0 px-3 py-2">
                                    Coupon Name
                                  </th>
                                  <th className="sticky top-0 px-3 py-2">
                                    Metal Name
                                  </th>
                                  <th className="sticky top-0 px-3 py-2">
                                    Requested Type
                                  </th>
                                  <th className="sticky top-0 px-3 py-2">
                                    Requested Value
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="text-gray-800 text-sm font-light">
                                <tr className="border-b bg-white text-black border-gray-300">
                                  <td className="py-3 px-3 text-center relative">
                                    {subItem?.discount[0].name}
                                  </td>
                                  <td className="py-3 px-3 text-center relative">
                                    {subItem?.discount[0].DiscountMetal}
                                  </td>
                                  <td className="py-3 px-3 text-center relative">
                                    {subItem?.discount[0].issueDiscountType}
                                  </td>
                                  <td className="py-3 px-3 text-center relative">
                                    {subItem?.discount[0].issueDiscountType ==
                                    "PRODUCT_QTY"
                                      ? (subItem?.discount[0]?.value).toFixed(4)
                                      : (subItem?.discount[0]?.value).toFixed(2)
                                      }
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default SubItemList;
