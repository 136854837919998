import axios from "axios";
import Http from "../../library/http.library";
import {httpMethods} from "../../library/enum";
import {makeQuery} from "../../Common/util.helper";


export const getWarehouseMasterData = async (tokens: any,currentPage:any, isPaginated: boolean = true) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/warehouse-master?isPaginated=${isPaginated}`).setToken(tokens.idToken.jwtToken).request();
};

export const filterWarehouseMasterData = async (data:any,currentPage:any,tokens:any) => {
    var query = {
        isPaginated: true,
        ...(data.warehouseId ? {warehouseId: data.warehouseId} : {}),
        ...(data.warehouseName ? {warehouseName: data.warehouseName} : {}),
        ...(data.addressLine1 ? {addressLine1: data.addressLine1} : {}),
        ...(data.city ? {city: data.city} : {}),
        ...(data.gstState ? {gstState: data.gstState} : {}),
        ...(data.state ? {state: data.state} : {}),
        ...(data.type ? {type: data.type} : {}),
        ...(data.active ? {active: data.active} : {}),
        page: currentPage,
    }
    const params = makeQuery(query);
    return await Http.setMethod(httpMethods.get).setUrl(`/warehouse-master?${params}`).setToken(tokens.idToken.jwtToken).request();
};


const WarehouseMasterService = {
    getWarehouseMasterData,
    filterWarehouseMasterData
};

export default WarehouseMasterService;
