import React, {useEffect, useState} from "react";
import Layout from "../Layout/Layout";
import {CreateMetal, filterMetals, getAllMetals, updateMetalsDetails} from "./Metal.Service";
import {ToastContainer, toast} from "react-toastify";
import "./react-confirm-alert.css";
import Loader from "../../Components-ui/Loader";
import {Pagination} from "../../Components-ui/Pagination";
import Modal from "../../Components-ui/Modal/Modal";
import SimpleReactValidator from "simple-react-validator";
import {useAuthStore} from "../../Store/AuthStore";
import HasAnyAccess from "../../Components-ui/hasAnyAccess";

const MetalTable: React.FC = () => {
    const { logout, permissions, userData , tokens } = useAuthStore();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [meta, setMetaData] = useState<any>({});
    const [Loading, setLoading] = useState<any>(true);
    const [metalList, setMetalList] = useState<any>([]);
    const [show, setModalStatus] = useState<any>(false);
    const [status, setStatus] = useState<any>('TOGGLE');
    const [children, setChildren] = useState<any>();
    const [selectedMetal, setSelectedMetal] = useState<any>({
        metalCode: "",
        rounding: "",
        minimumQuantity: 0,
        uom: "",
        description: "",
    });
    const [validatorStatus, setValidator] = useState<boolean>(false);
    const [filters, setFilters] = useState<any>({
        metalCode: '',
        description: '',
        active: '',
    });
    const simpleValidator = new SimpleReactValidator({
        locale: "en",
        validators: {},
    });
    const validationRef = React.useRef(simpleValidator);
    const validator = validationRef.current;

    useEffect(() => {
        loadMetals(0);
    }, []);

    useEffect(() => {
        childrenElements();
    }, [selectedMetal, status]);

    useEffect(() => {
        childrenElements();
    }, [validatorStatus]);

    const loadMetals = async (page:any) => {
        setLoading(true);
        await getAllMetals(tokens,page).then((response: any) => {
            setMetalList(response.data.data.result);
            setLoading(false);
            setMetaData({
                current: response.data.meta.page,
                pages: response.data.meta.totalPages,
            });
            setCurrentPage(page);
        }).catch(err => {
            setLoading(false)
            toast.error("Something Went Wrong!");
        });
    };

    const changeStatus = async (status: any, key: any, id: any) => {
        setStatus('TOGGLE');
        let changedData;
        let newArr = [...metalList];
        newArr[key].active = ('ENABLED' !== status) ? 'ENABLED' : 'DISABLED'
        newArr[key].updatedAt = new Date().toISOString();
        changedData = newArr[key];
        setMetalList(newArr)
       await updateMedalRecord(id, changedData);
    }

    const getValidatorError = (nameOfStateProp: any) => {
        const allErrorMessages = validator.getErrorMessages();
        return allErrorMessages[nameOfStateProp];
    };

    const childrenElements = () => {
        setChildren(<div className="w-full max-w-xs">
            <h1 className="font-bold">
                {('ADD' === status) ? 'Add Metals Master Record' : 'Edit Metals Master Record'}
            </h1>
            <form className="bg-white rounded pt-6 mb-4" onSubmit={handleFormSubmit}>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="metalCode">
                        Metal Code
                    </label>
                    <input
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        id="metalCode" type="text" placeholder="Metal Code" name="metalCode"
                        onChange={(event) => setSelectedMetal({
                            ...selectedMetal,
                            metalCode: event.target.value
                        })} value={selectedMetal.metalCode}
                        autoComplete="off"/>
                    {validator.message(
                        "metalCode",
                        selectedMetal.metalCode,
                        "required"
                    )}
                    {
                        validatorStatus ? (<span className="text-xs text-red-700"
                                                 id="metalCode">{getValidatorError("metalCode")}</span>) : ''
                    }
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="description">
                        Description
                    </label>
                    <input
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        id="description" type="text" name="description" placeholder="Description"
                        onChange={(event) => setSelectedMetal({
                            ...selectedMetal,
                            description: event.target.value
                        })} value={selectedMetal.description || ''} autoComplete="off"/>
                    {validator.message(
                        "description",
                        selectedMetal?.description,
                        "required"
                    )}
                    {
                        validatorStatus ? (<span className="text-xs text-red-700"
                                                 id="metalCode">{getValidatorError("description")}</span>) : ''
                    }
                </div>
                {/*<div className="mb-4">*/}
                {/*    <label className="block text-sm font-medium text-gray-700" htmlFor="uom">*/}
                {/*        UOM*/}
                {/*    </label>*/}
                {/*    <input*/}
                {/*        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"*/}
                {/*        id="uom" type="text" name="uom" placeholder="UOM" onChange={(event) => setSelectedMetal({*/}
                {/*        ...selectedMetal,*/}
                {/*        uom: event.target.value*/}
                {/*    })} value={selectedMetal.uom || ''} autoComplete="off"/>*/}
                {/*    {validator.message(*/}
                {/*        "uom",*/}
                {/*        selectedMetal?.uom,*/}
                {/*        "required"*/}
                {/*    )}*/}
                {/*    {*/}
                {/*        validatorStatus ? (<span className="text-xs text-red-700"*/}
                {/*                                 id="metalCode">{getValidatorError("uom")}</span>) : ''*/}
                {/*    }*/}
                {/*</div>*/}
                {/*<div className="mb-4">*/}
                {/*    <label className="block text-sm font-medium text-gray-700" htmlFor="minimumQuantity">*/}
                {/*        Minimum Quantity*/}
                {/*    </label>*/}
                {/*    <input*/}
                {/*        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"*/}
                {/*        id="minimumQuantity" type="number" placeholder="Minimum Quantity" name="minimumQuantity"*/}
                {/*        min="0" onChange={(event) => setSelectedMetal({*/}
                {/*        ...selectedMetal,*/}
                {/*        minimumQuantity: event.target.value*/}
                {/*    })} value={(selectedMetal.minimumQuantity) && Math.max(0, selectedMetal.minimumQuantity)}*/}
                {/*        autoComplete="off"/>*/}
                {/*    {validator.message(*/}
                {/*        "minimumQuantity",*/}
                {/*        selectedMetal?.minimumQuantity,*/}
                {/*        "required"*/}
                {/*    )}*/}
                {/*    {*/}
                {/*        validatorStatus ? (<span className="text-xs text-red-700"*/}
                {/*                                 id="metalCode">{getValidatorError("minimumQuantity")}</span>) : ''*/}
                {/*    }*/}
                {/*</div>*/}
                {/*<div className="mb-4">*/}
                {/*    <label className="block text-sm font-medium text-gray-700" htmlFor="rounding">*/}
                {/*        Rounding*/}
                {/*    </label>*/}
                {/*    <input*/}
                {/*        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"*/}
                {/*        id="rounding" type="number" placeholder="Rounding" onChange={(event) => setSelectedMetal({*/}
                {/*        ...selectedMetal,*/}
                {/*        rounding: event.target.value*/}
                {/*    })} value={(selectedMetal.rounding || 0) && Math.max(0, selectedMetal.rounding)}*/}
                {/*        autoComplete="off"/>*/}
                {/*</div>*/}
                <div className="">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="username">
                        Status
                    </label>
                    <div className="flex justify-between items-center" onClick={(event) => setSelectedMetal({
                        ...selectedMetal,
                        active: ('ENABLED' !== selectedMetal.active) ? 'ENABLED' : 'DISABLED'
                    })}>
                        <div
                            className={`w-14 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out ${("ENABLED" === selectedMetal.active) ? "bg-green-400" : ""}`}>
                            <div
                                className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${("ENABLED" === selectedMetal.active) ? "translate-x-6" : ""}`}>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-between flex-row-reverse">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button" onClick={() => {
                        if ('ADD' === status) {
                            addNew()
                        } else {
                            updateRecord(selectedMetal.id)
                        }
                    }}>
                        {('ADD' === status) ? 'SAVE' : 'UPDATE'}
                    </button>
                </div>
            </form>
        </div>);
    }

    const handleFormSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (validator.allValid()) {
        } else {
            setValidator(true)
            validator.showMessages();
        }
    };

    const addNew = async () => {
        setValidator(true);
        var data = [
            {
                active: selectedMetal.active || 'DISABLED',
                description: selectedMetal.description || '',
                metalCode: selectedMetal.metalCode || '',
                minimumQuantity: selectedMetal.minimumQuantity || 0,
                rounding: selectedMetal.rounding || 0,
                uom: selectedMetal.uom || '',
                createdBy: selectedMetal.createdBy || '',
            }
        ]
        if (validator.allValid()) {
            setLoading(true);
            await CreateMetal(data,tokens).then(() => {
                loadMetals(currentPage);
                setLoading(false);
                toast.success("Record Added Successfully");
                setModalStatus(false);
            }).catch(err => {
                setLoading(false);
                setModalStatus(false);
                toast.error(err.data.errors[0].title);
            });
            setValidator(false);
        }
    }

    const updateMedalRecord = async (id: any, changedData: any) => {
        console.log(validator.allValid());
        console.log(status);
        setValidator(true);
        if('TOGGLE' !== status){
            if (validator.allValid()) {
                await updateMetalsDetails(id, changedData,tokens).then(() => {
                    loadMetals(currentPage)
                    toast.success("Updated Successfully");
                    setModalStatus(false);
                }).catch(err => {
                    console.log(err);
                    setModalStatus(false);
                    toast.error("Something Went Wrong");
                });
                setValidator(false);
            }
        }else{
            await updateMetalsDetails(id, changedData,tokens).then(() => {
                loadMetals(currentPage)
                toast.success("Updated Successfully");
                setModalStatus(false);
            }).catch(err => {
                console.log(err);
                setModalStatus(false);
                toast.error("Something Went Wrong");
            });
        }

    }

    const updateRecord = async (id: number) => {
        await updateMedalRecord(id, selectedMetal);
    }

    const hideModal = () => {
        setModalStatus(false);
    };

    const editModalElements = (data: any) => {
        setStatus('EDIT');
        childrenElements();
        setSelectedMetal(data);
        setModalStatus(true);
    }

    const addModalElements = () => {
        setSelectedMetal({
            metalCode: "",
            rounding: "",
            minimumQuantity: 0,
            uom: "",
            description: "",
        });
        setStatus('ADD');
        childrenElements();
        setModalStatus(true);
    }

    const filter = async (page:any) => {
        setLoading(true);
        filterMetals(filters, page, tokens).then((response: any) => {

            setMetalList(response.data.data.result);
            setMetaData({
                current: response.data.meta.page,
                pages: response.data.meta.totalPages,
            });
            setCurrentPage(response.data.meta.page);
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            toast.error(err);
        });
    }

    const ResetForm = async () => {
        setCurrentPage(0);
        setFilters({
            metalCode: '',
            description: '',
            active: '',
        });
        loadMetals(0);
    }

    const paginationFilter = async (page: number) => {
        setCurrentPage(page);
        filter(page);
    }

    return (
        <Layout type={"MasterModule"}>
            <ToastContainer/>
            <div className="flex justify-between items-center mb-5">
                <h1 className="text-2xl font-bold tracking-tight">
                    {"Metals"}
                </h1>
                {
                    HasAnyAccess(['metal.master.create']) && (
                        <button
                            className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
                            type="button"
                            onClick={() => {
                                addModalElements()
                            }}
                        >
                            + Create New
                        </button>
                    )
                }
            </div>
            <div className="border bg-white rounded border-gray-200 shadow">
                <div className="flex-1 p-2">
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="code">
                                Code
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                name="code" id="code" type="text" placeholder="Code"
                                autoComplete="none" onChange={event => setFilters({
                                ...filters,
                                metalCode: event.target.value
                            })} value={filters.metalCode}/>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="description">
                                Description
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="description" type="text" placeholder="Description" autoComplete="none"
                                onChange={event => setFilters({
                                    ...filters,
                                    description: event.target.value
                                })} value={filters.description}/>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="status">
                                Status
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="status" onChange={event => setFilters({
                                    ...filters,
                                    active: event.target.value
                                })} value={filters.active}>
                                    <option value="">Select a Status</option>
                                    <option value="ENABLED">ENABLED</option>
                                    <option value="DISABLED">DISABLED</option>
                                </select>
                            </div>
                        </div>
                        <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <div className="my-6">
                                <button
                                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                                    type="button" onClick={() => filter(0)}>
                                    Filter
                                </button>
                                <button
                                    className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                                    type="button" onClick={() => ResetForm()}>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border bg-white rounded border-gray-200 shadow m-2">
                    {Loading ? (<Loader/>) : (
                        <div>
                            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                                <thead>
                                <tr className="text-left border-b border-gray-300">
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Metal Code</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Description</th>
                                    {/*<th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">UOM</th>*/}
                                    {/*<th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Minimum Qty</th>*/}
                                    {/*<th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Rounding</th>*/}
                                    {
                                        HasAnyAccess(['metal.master.edit']) && (
                              <>
                                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Status</th>
                                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Actions</th>
                              </>
                                        )}
                                </tr>
                                </thead>
                                <tbody>
                                {(0 !== metalList.length) ?
                                    metalList.map((metal: any, index: number) => {
                                        return (
                                            <tr key={index}
                                                className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                <td className="py-3 px-3 text-left relative">
                                                    {metal.metalCode}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {metal.description}
                                                </td>
                                                {/*<td className="py-3 px-3 text-left relative">*/}
                                                {/*    {metal.uom}*/}
                                                {/*</td>*/}
                                                {/*<td className="py-3 px-3 text-left relative">*/}
                                                {/*    {metal.minimumQuantity}*/}
                                                {/*</td>*/}
                                                {/*<td className="py-3 px-3 text-left relative">*/}
                                                {/*    {metal.rounding}*/}
                                                {/*</td>*/}
                                                    {
                                                        HasAnyAccess(['metal.master.edit']) && (
                                                            <td className="py-3 px-3 text-left relative">

                                                            <div className="flex justify-between items-center"
                                                                 onClick={() => {
                                                                     changeStatus(metal.active, index, metal.id)
                                                                 }}>
                                                                <div
                                                                    className={`w-14 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out ${("ENABLED" === metal.active) ? "bg-green-400" : ""}`}>
                                                                    <div
                                                                        className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${("ENABLED" === metal.active) ? "translate-x-6" : ""}`}>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                </td> )
                                                    }
                                                    {
                                                        HasAnyAccess(['metal.master.edit']) && (
                                                            <td className="py-3 px-3 text-left relative">

                                                            <button
                                                        className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                        type="button"
                                                        onClick={() => {
                                                            editModalElements(metal)
                                                        }}
                                                    >
                                                        Edit
                                                    </button>

                                                </td> )
                                                    }
                                            </tr>
                                        )
                                    })
                                    : (<tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                        <td colSpan={7} className="text-center py-3 px-3 text-left relative">No data to
                                            display
                                        </td>
                                    </tr>)
                                }
                                </tbody>
                            </table>
                        </div>
                    )}
                    <Pagination
                        meta={meta}
                        handlePageChange={(number: number) => {
                            paginationFilter(number)
                        }}
                    />
                </div>
                <Modal size={''} show={show} handleClose={hideModal} children={children}/>
            </div>
        </Layout>
    );
};

export default MetalTable;
