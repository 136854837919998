import React, {useState, useEffect} from "react";
import {useAuthStore} from "../../../Store/AuthStore";
import Layout from "../../Layout/Layout";
import {toast, ToastContainer} from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import moment from "moment";
import {Pagination} from "../../../Components-ui/Pagination";
import SettlementReportService from "../PartnerSettlement/PartnerSettlementReportService";
import customerService from "../../Customer/Services/Customer.service";
import Modal from "../../../Components-ui/Modal/Modal";
import Datetime from "react-datetime";
import MultipleSelect from "../../../Components-ui/MultipleSelect";
import {Tooltip} from "@mui/material";

const PartnerSettlementReport: React.FC = () => {
    const status = [{
        id:'CREATED',
        status:'CREATED',
    },{
        id:'PENDING',
        status:'PENDING',
    },{
        id:'SUCCESS',
        status:'SUCCESS',
    },{
        id:'FAILED',
        status:'FAILED',
    }]
    const {tokens} = useAuthStore();
    const [children, setChildren] = useState<any>();
    const [show, setModalStatus] = useState<any>(false);
    const [Loading, setLoading] = useState<any>(false);
    const [settlements, setSettlements] = useState<any>([]);
    const [meta, setMetaData] = useState<any>({});
    const [settlementsDetails, setSettlementsDetails] = useState<any>([]);
    const [metaSettlementsDetails, setMetaDataSettlementsDetails] = useState<any>({});
    const [selectedSettlementId, setSelectedSettlementId] = useState<any>();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [partnerList, setPartnerList] = useState<any>([]);
    const [SelectedStatus, setSelectedStatus] = useState<any>([]);
    const [SelectedMainStatus, setSelectedMainStatus] = useState<any>([]);
    const [filters, setFilters] = useState<any>({
        date: null,
        partner: '',
        status: '',
        settlementStatus: [],
        fromDate:null,
        toDate:null
    });

    const [modalFilters, setModalFilters] = useState<any>({
        partnerId: '',
        customerId: '',
        transactionId: '',
        status: '',
        isValidAmount: '',
        isRecordMatched: '',
        date: null
    });

    const getPartners = () => {
        customerService.getPartnerList(tokens).then((response: any) => {
            setPartnerList(response.data.data);
        }).catch(err => {
            toast.error("Something Went Wrong!");
            setLoading(false);
        });

    }

    const loadSettlements = async (page: number, filter: any): Promise<void> => {
        setLoading(true);
        return new Promise(async (resolve) => {
            SettlementReportService.getPartnerSettlements(tokens, page, filter).then((response: any) => {
                setSettlements(response.data.data.result);
                setMetaData({
                    current: response.data.meta.page,
                    pages: response.data.meta.totalPages,
                });
                setCurrentPage(response.data.meta.page);
                setLoading(false);
            }).catch(err => {
                toast.error("Something Went Wrong!");
                setLoading(false);
            });
        })
    };

    const loadSettlementsDetails = async (page: number, data: any,id:any) => {

        SettlementReportService.getPartnerSettlementsDetails(tokens, page, data,id).then((response: any) => {
            setSettlementsDetails(response.data.data.result);
            setMetaDataSettlementsDetails({
                current: response.data.meta.page,
                pages: response.data.meta.totalPages,
            });
            setLoading(false);
            setModalStatus(true);
        }).catch(err => {
            toast.error("Something Went Wrong!");
            setLoading(false);
        });
    };

    useEffect(() => {
        getPartners();
        setLoading(true);
        loadSettlements(currentPage, {});
    }, []);

    const ResetForm = async () => {
        setFilters({
            date: null,
            partner: '',
            status: '',
            settlementStatus: [],
            fromDate:null,
            toDate:null
        });
        setCurrentPage(0);
        setSelectedMainStatus([]);
        loadSettlements(0, {})
    }

    useEffect(() => {
        childrenElements();
    }, [settlementsDetails, show, modalFilters, metaSettlementsDetails]);

    useEffect(() => {
        childrenElements();
    }, [SelectedStatus]);

    useEffect(() => {
        childrenElements();
    }, [settlementsDetails]);

    useEffect(() => {
        setModalFilters({
            selectedStatus: [],
            fileLocation: '',
            fileUploaded: '',
        });
        setSelectedStatus([])
    }, [show]);

    const downloadReport=(data:any)=>{
        SettlementReportService.reportDownload(tokens, data).then((response: any) => {
            const href = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download',  'partner_settlements_details_report.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        }).catch(err => {
            toast.error("Something Went Wrong!");
            setLoading(false);
        });
    }

    const handleChangeItem = (selected: any) => {
        if(show){
            setModalFilters({
                ...modalFilters,
                selectedStatus: selected
            });
            setSelectedStatus(selected);
        }else{
            setFilters({
                ...filters,
                settlementStatus: selected
            });
            setSelectedMainStatus(selected)
        }


    };

    const childrenElements = () => {
        setChildren(<div className="w-full">
            <h1 className="font-bold">
                {'Partner Settlement Record Detail'}
            </h1>
            <div className="mb-3 bg-white p-2 pb-1 pr-3.5 pt-4 rounded shadow-2xl">
                <div className="flex-1 p-2">
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-type">
                                Status
                            </label>
                            <MultipleSelect
                                optionList={status}
                                onChangeItem={handleChangeItem}
                                selectedValue={SelectedStatus}
                                closeMenuOnSelect={false}
                                getOptionLabel={(option:any) => `${option.status}`}
                                getOptionValue={(option:any) => `${option.id}`}
                            />
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-type">
                                File Uploaded
                            </label>
                            <div className="relative">
                                <select
                                    className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                                    id="user-type" onChange={event => setModalFilters({
                                    ...modalFilters,
                                    fileUploaded: event.target.value
                                })} value={modalFilters.fileUploaded}>
                                    <option value="">Select a Status</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-type">
                                File Location
                            </label>
                            <div className="relative">
                                <input
                                    className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                                    id="description" type="text" placeholder="File Location" autoComplete="none"
                                    onChange={event => setModalFilters({
                                        ...modalFilters,
                                        fileLocation: event.target.value
                                    })} value={modalFilters.fileLocation}/>
                            </div>
                        </div>
                        <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <div className="my-6">
                                <button
                                    className="px-4 py-2.5 font-bold text-white text-sm uppercase bg-purple-950 rounded"
                                    type="button"
                                    onClick={() => loadSettlementsDetails(0, modalFilters,selectedSettlementId)}
                                >
                                    Filter
                                </button>
                                <button
                                    className="ml-4 px-4 py-2.5 font-bold text-white text-sm uppercase bg-red-600 rounded"
                                    type="button"
                                    onClick={(e) => ResetModalForm()}>
                                    Reset
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-wrap mb-2">
                    {Loading ? (<Loader/>) : (
                        <div>
                            <div className="overflow-x-auto">
                                <table
                                    className="whitespace-nowrap  rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs ">
                                    <thead>
                                    <tr className="text-left border-b border-gray-300">
                                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">File Uploaded
                                        </th>
                                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">File Location
                                        </th>
                                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Status
                                        </th>
                                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(0 !== settlementsDetails.length) ?
                                        settlementsDetails.map((data: any, index: number) => {
                                            return (
                                                <tr key={index}
                                                    className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                    <td className="py-3 px-3 text-left relative">
                                                        <span
                                                            className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${data.fileUploaded ? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>
                                                        {data.fileUploaded ? 'True': 'False'}
                                                               </span>
                                                    </td>
                                                    <td className="py-3 px-3 text-left relative">
                                                        {data.fileLocation || 'N/A'}
                                                    </td>
                                                    <td className="py-3 px-3 text-left relative">
                                                         <span
                                                             className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${data.status === 'SUCCESS'? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>
                                                        {data.status}
                                                               </span>
                                                    </td>
                                                    <td className="py-3 px-3 text-left relative">  {
                                                        // <Tooltip title={!data.fileUploaded ? "Report does not uploaded!":""} followCursor>
                                                        <Tooltip title={"Click download button to download partner settlement report!"} followCursor>
                                                            <button  style={{pointerEvents:"unset"}}
                                                                    //  className={`border border-purple-300 rounded-md background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150 ${!data.fileUploaded ? "cursor-not-allowed bg-gray-300" : "bg-purple-100 hover:bg-purple-950 hover:text-white"}`}
                                                                     className={`border border-purple-300 rounded-md background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150 bg-purple-100 hover:bg-purple-950 hover:text-white`}
                                                                     type="button"
                                                                    //  onClick={() => data.fileUploaded && downloadReport(data.id)}
                                                                     onClick={() => downloadReport(data.id)}
                                                            >
                                                                Download
                                                            </button>
                                                        </Tooltip>
                                                    }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        : (
                                            <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                                <td colSpan={30}
                                                    className="text-center py-3 px-3 text-left relative">No
                                                    data to
                                                    display
                                                </td>
                                            </tr>)
                                    }
                                    </tbody>
                                </table>

                            </div>
                            <Pagination
                                meta={metaSettlementsDetails}
                                handlePageChange={(number: number) => {
                                    modalPaginationFilter(number)
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>);
    }

    const editModalElements = async (item: any) => {
        setModalFilters({
            partnerId: item.partnerId,
            id: item.id
        })
        setSelectedSettlementId(item.id);
        loadSettlementsDetails(0, item,item.id);
    }

    const paginationFilter = async (page: number) => {
        setCurrentPage(page);
        loadSettlements(page, filters);
    }

    const modalPaginationFilter = async (page: number) => {
        loadSettlementsDetails(page, modalFilters,modalFilters.id);
    }

    const hideModal = () => {
        setModalStatus(false);
    };

    const ResetModalForm = async () => {
        setSelectedStatus([]);
        setModalFilters({
            selectedStatus: [],
            fileLocation: '',
            fileUploaded: '',
        });
        setCurrentPage(0);
        loadSettlementsDetails(0, {},selectedSettlementId)
    }

    var valid = function (current: any) {
        return current.isAfter(moment(filters.fromDate).subtract(1, 'day'));
    };

    return (
        <Layout type={"PaymentModule"}>
            <ToastContainer/>
            <div className="flex justify-between items-center mb-5">
                <h1 className="text-2xl font-bold tracking-tight">
                    {"Partner Settlement"}
                </h1>
            </div>
            <div className="border bg-white rounded border-gray-200 shadow">
                <div className="flex-1 p-2">
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-type">
                                Partner
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="user-type" onChange={event => setFilters({
                                    ...filters,
                                    partner: event.target.value
                                })} value={filters.partner}>
                                    <option value="">Select a Partner</option>
                                    {partnerList.map((partner: any) => (
                                        <option value={partner.partnerId}>{partner.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-type">
                                Status
                            </label>
                            <div className="mt-1">
                                <MultipleSelect
                                    optionList={status}
                                    onChangeItem={handleChangeItem}
                                    selectedValue={SelectedMainStatus}
                                    closeMenuOnSelect={false}
                                    getOptionLabel={(option:any) => `${option.status}`}
                                    getOptionValue={(option:any) => `${option.id}`}
                                />
                            </div>

                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="grid-state"
                            >
                                From Date
                            </label>
                            <div
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <Datetime
                                    timeFormat={""}
                                    dateFormat={"YYYY-MM-DD"}
                                    inputProps={{
                                        readOnly: true,
                                        placeholder: 'From Date',
                                        className: "focus:outline-none"
                                    }}
                                    onChange={(event: any) => {
                                        setFilters({

                                            ...filters,
                                            fromDate: moment(new Date(event).toISOString()).format("YYYY-MM-DD"),
                                            toDate: ((event.isAfter(new Date(filters.fromDate)) ? moment(new Date(event).toISOString()).format("YYYY-MM-DD") : moment(filters.toDate).format("YYYY-MM-DD"))),
                                        });
                                    }} value={moment(filters.fromDate)}

                                    renderInput={(props) => {
                                        return <input {...props}
                                                      style={{width:'100%'}}
                                                      value={(filters.fromDate) ? (filters.fromDate) : ''} />
                                    }}
                                />
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="grid-state"
                            >
                                To Date
                            </label>
                            <div
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <Datetime
                                    isValidDate={valid}
                                    timeFormat={""}
                                    dateFormat={"YYYY-MM-DD"}
                                    inputProps={{ readOnly: true,placeholder: 'To Date', className: "focus:outline-none" }}
                                    onChange={(event: any) => setFilters({
                                        ...filters,
                                        toDate: moment(new Date(event).toISOString()).format("YYYY-MM-DD"),
                                    })} value={moment(filters.toDate)}

                                    renderInput={(props) => {
                                        return <input {...props} style={{width:'100%'}}
                                                      value={(filters.toDate) ? (filters.toDate) : ''} />
                                    }}
                                />

                            </div>
                        </div>
                        <div className="flex w-full justify-end px-3 mb-6 md:mb-0">
                            <div className="my-3">
                                <button
                                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                                    type="button"
                                    onClick={() => loadSettlements(0, filters)}
                                >
                                    Filter
                                </button>
                                <button
                                    className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={(e) => ResetForm()}>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded border-gray-200 shadow m-2">
                    {Loading ? (<Loader/>) : (
                        <div>
                            <div className="w-full">
                                <div className="overflow-x-auto">
                                    <table
                                        className="whitespace-nowrap  rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs ">
                                        <thead>
                                        <tr className="text-left border-b border-gray-300">
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Partner
                                            </th>
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Date</th>
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Status</th>
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {(0 !== settlements.length) ?
                                            settlements.map((data: any, index: number) => {
                                                return (
                                                    <tr key={index}
                                                        className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                        <td className="py-3 px-3 text-left relative">
                                                            <span>
                                                        { data.partnerId ?
                                                            partnerList?.find((i: any) => i.partnerId === parseInt(data.partnerId)).name || '' :''                                                            // partnerList.map((x: any) =>  x.partnerId).includes(parseInt(data.partnerId)).name
                                                        }
                                                        </span>
                                                        </td>
                                                        <td className="py-3 px-3 text-left relative">
                                                            {moment(data.date).format("DD-MM-YYYY HH:mm:ss") || 'N/A'}
                                                        </td>
                                                        <td className="py-3 px-3 text-left relative">
                                                               <span
                                                                   className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${data.status === 'SUCCESS'? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>
                                                        {data.status}
                                                               </span>

                                                        </td>
                                                        <td className="py-3 px-3 text-left relative">
                                                            <button
                                                                className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                                type="button"
                                                                onClick={() => {
                                                                    editModalElements(data)
                                                                }}
                                                            >
                                                                View
                                                            </button>
                                                            {
                                                                // <Tooltip title={!data.fileUploaded ? "Report does not uploaded!":""} followCursor>
                                                                <Tooltip title={"Click download button to download partner settlement report!"} followCursor>
                                                                    <button  style={{pointerEvents:"unset"}}
                                                                            // className={`border border-purple-300 rounded-md background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150 ${!data.fileUploaded ? "cursor-not-allowed bg-gray-300" : "bg-purple-100 hover:bg-purple-950 hover:text-white"}`}
                                                                            className={`border border-purple-300 rounded-md background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150 bg-purple-100 hover:bg-purple-950 hover:text-white`}
                                                                            type="button"
                                                                            // onClick={() => data.fileUploaded && downloadReport(data.activePartnerSettlementProcessId)}
                                                                            onClick={() => downloadReport(data.activePartnerSettlementProcessId)}
                                                                    >
                                                                        Download
                                                                    </button>
                                                                </Tooltip>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            : (
                                                <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                                    <td colSpan={4}
                                                        className="text-center py-3 px-3 text-left relative">No
                                                        data to
                                                        display
                                                    </td>
                                                </tr>)
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination
                                    meta={meta}
                                    handlePageChange={(number: number) => {
                                        paginationFilter(number)
                                    }}
                                />
                            </div>
                            <Modal size={'large'} show={show} handleClose={hideModal} children={children}/>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};
export default PartnerSettlementReport;
