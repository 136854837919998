import { useEffect, useState } from "react";
import { GetAllCustomesDuty, CreateCustomesDuty, DeleteCusomeDuty, EditCusomeDuty } from './Metal.Service'
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { SetDeleteConfirmAlert } from '../Util/util.component'
import './react-confirm-alert.css'
import ErrorToolTip from './ErrorToolTip'
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";


interface PropsType {
  metal: any;
  isEditable: boolean;
}

const CustomesDutyTable: React.FC<PropsType> = ({ metal, isEditable }) => {

  let history = useHistory();
  let { id } = useParams<any>();
  const [custome, setcustome] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [inCreateMode, setInCreateMode] = useState<boolean>(false);

  const [hsn, sethsn] = useState<string>("");
  const [dutyOnNotifiedRates, setdutyOnNotifiedRates] = useState<string>("true");
  const [customsRate, setcustomsRate] = useState<string>("");
  const [startDate, setstartDate] = useState<string>("");
  const [endDate, setendDate] = useState<string>("");
  const [codeDuty, setcodeDuty] = useState<string>("");

  //new params
  const [Metal_code, setMetalCode] = useState<string>("");
  const [dutyFCT, setdutyFCT] = useState<string>("");
  const [active, setactive] = useState<string>("true");

  // let { id } = useParams<any>();
  const [editRow, setEditRow] = useState<any>({});
  const [data, setData] = useState<any>([]);
  // const [metalCode, setMetalCode] = useState<string>(""); 
  const [inEditMode, setInEditMode] = useState({
    status: false,
    row: -1
  })

  const [CreateRowErrors, setCreateRowErrors] = useState<any>({});
  const [EditRowErrors, setEditRowErrors] = useState<any>({});
  const [CreateRowSubmitCount, setCreateRowSubmitCount] = useState<number>(0);
  const [ActiveColCreate, setActiveColCreate] = useState<string>("");
  const [ActiveColEdit, setActiveColEdit] = useState<string>("");
  const [InitLoading, setInitLoading] = useState<boolean>(true);

  const onClickEdit = (i: number) => {
    setInEditMode({ status: true, row: i })
    setEditRow(data[i]);
  }

  const onClickSave = (index: number) => {
    if (validateCustomDutyEdit(editRow)) {
      setInEditMode({ status: false, row: -1 })
      // console.log(editRow)
      SaveEditCustomDuty({
        ...editRow,
        startDate: moment(editRow.startDate).format("YYYY-MM-DD h:mm:ss"),
        endDate: moment(editRow.endDate).format("YYYY-MM-DD h:mm:ss"),
        hsn: editRow.hsn.trim(),
        codeDuty: editRow.codeDuty.trim()
      });
    }
  }


  const validateCustomDutyEdit = (state: any) => {
    let result = true;
    let errors: any = {};

    if (state.hsn.length == 0) {
      result = false;
      errors.hsn = "HSN can't be empty!"
    } else {
      errors.hsn = ""
    }

    // if(.codeDuty.length == 0){
    //    = false;
    //   .codeDuty = "code Duty can't be empty!"
    // }else{
    //   .codeDuty = ""
    // }

    if (state.startDate.length == 0) {
      result = false;
      errors.startDate = "start Date can't be empty!"
    } else if (moment(state.endDate).isBefore(moment(state.startDate))) {
      result = false;
      errors.startDate = "start Date must before end date!"
    }
    else {
      errors.startDate = ""
    }

    if (state.endDate.length == 0) {
      result = false;
      errors.endDate = "end Date can't be empty!"
    } else {
      errors.endDate = ""
    }

    if (state.customsRate.length == 0) {
      result = false;
      errors.customsRate = "customs rate can't be empty!"
    } else if (isNaN(state.customsRate)) {
      result = false;
      errors.customsRate = "customs rate must be a number!"
    } else if (state.customsRate <= 0) {
      result = false;
      errors.customsRate = "customs rate can't be less than zero!"
    }
    else {
      errors.customsRate = ""
    }

    if (state.dutyFCT.length == 0) {
      result = false;
      errors.dutyFCT = "dutyFCT can't be empty!"
    } else if (isNaN(state.dutyFCT)) {
      result = false;
      errors.dutyFCT = "dutyFCT must be a number!"
    } else if (state.dutyFCT < 0 || state.dutyFCT > 100) {
      result = false;
      errors.dutyFCT = "dutyFCT must in 0-100!"
    }
    else {
      errors.dutyFCT = ""
    }

    setEditRowErrors(errors);
    return result;
  }


  const onClickDelete = (item: any) => {
    console.log("Delete item: ", item);

    SetDeleteConfirmAlert(
      "Confirm Delete",
      "Are you sure you want to delete this?",
      () => { DeleteCutom(item) },
      () => { }
    )
  }

  const DeleteCutom = (item: any) => {
    DeleteCusomeDuty(item.id).then(data => {
      FetchCustomDuty();
      toast.success("Deleted!");
    }).catch(err => {
      toast.error("Something went wrong!");
    })
  }

  const SaveEditCustomDuty = (data: any) => {
    EditCusomeDuty(data.id, data).then(data => {
      FetchCustomDuty();
      toast.success("Edit Successful!");
    }).catch(err => {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
      FetchCustomDuty();
    })
  }


  useEffect(() => {
    console.log("Metal basis metal: ", metal)
    setMetalCode(metal ? metal[0].Metal_code : "");
    //Get metal basis
    // fetchMetalBasis();
    GetAllCustomesDuty(metal ? metal[0].Metal_code : "").then((data: any) => {
      console.log("Metal basis data: ", data);
      setData(data ? data.data.data : []);
      setInitLoading(false);
    }).catch(err => {
      setData([]);
      setInitLoading(false);
      toast.error("Metal basis data not found");
    })

  }, [metal])

  const onAddCustomeDuty = () => {
    setInitLoading(true);
    setInCreateMode(!inCreateMode);
    // clearDataInForm();
  }
  const onCreateCustomeDutyClicked = (e: any) => {
    e.preventDefault();
    setCreateRowSubmitCount(1);

    if (validateCustomDutyCreate({})) {
      setInCreateMode(false);
      CreateCustomesDuty({
        metalCode: Metal_code,
        dutyOnNotifiedRates: dutyOnNotifiedRates == "true",
        active: active == "true",
        dutyFCT: dutyFCT,
        hsn: hsn.trim(),
        customsRate: customsRate,
        startDate: moment(startDate).format("YYYY-MM-DD h:mm:ss"),
        endDate: moment(endDate).format("YYYY-MM-DD h:mm:ss"),
        codeDuty: codeDuty.trim()
      }).then(data => {
        toast.success("Successfully Added!");
        setCreateRowSubmitCount(0);
        clearDataInForm();
        FetchCustomDuty();

      }).catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Something went wrong");
        }
        setInCreateMode(true);
      })
    }
  }

  const latest_state = (data: any) => {
    return {
      hsn, dutyOnNotifiedRates, codeDuty, startDate, endDate,
      customsRate, dutyFCT,
      ...data
    }
  }

  const validateCustomDutyCreate = (data: any) => {
    let result = true;
    let errors: any = {};
    let state = latest_state(data);

    if (state.hsn.length == 0) {
      result = false;
      errors.hsn = "HSN can't be empty!"
    } else {
      errors.hsn = ""
    }

    // if(.codeDuty.length == 0){
    //   = false;
    //   .codeDuty = "code Duty can't be empty!"
    // }else{
    //   .codeDuty = ""
    // }

    if (state.startDate.length == 0) {
      result = false;
      errors.startDate = "start Date can't be empty!"
    } else if (moment(state.endDate).isBefore(moment(state.startDate))) {
      result = false;
      errors.startDate = "start Date must before end date!"
    }
    else {
      errors.startDate = ""
    }

    if (state.endDate.length == 0) {
      result = false;
      errors.endDate = "end Date can't be empty!"
    } else {
      errors.endDate = ""
    }

    if (state.customsRate.length == 0) {
      result = false;
      errors.customsRate = "customs rate can't be empty!"
    } else if (isNaN(state.customsRate)) {
      result = false;
      errors.customsRate = "customs rate must be a number!"
    } else if (state.customsRate <= 0) {
      result = false;
      errors.customsRate = "customs rate can't be less than zero!"
    }
    else {
      errors.customsRate = ""
    }

    if (state.dutyFCT.length == 0) {
      result = false;
      errors.dutyFCT = "dutyFCT can't be empty!"
    } else if (isNaN(state.dutyFCT)) {
      result = false;
      errors.dutyFCT = "dutyFCT must be a number!"
    } else if (state.dutyFCT < 0 || state.dutyFCT > 100) {
      result = false;
      errors.dutyFCT = "dutyFCT must in 0-100!"
    }
    else {
      errors.dutyFCT = ""
    }

    setCreateRowErrors(errors);
    return result;
  }

  const clearDataInForm = () => {
    sethsn("");
    setcustomsRate("");
    setstartDate("");
    setendDate("");
    setcodeDuty("");
    setdutyFCT("");
    setdutyOnNotifiedRates("true");
    setactive("true");
  }


  const FetchCustomDuty = () => {
    GetAllCustomesDuty(metal ? metal[0].Metal_code : "").then((custome: any) => {
      console.log("Custom duty data xx: ", custome);
      setData(custome ? custome.data.data : []);
      setInitLoading(false);
    }).catch(err => {
      setData([]);
      toast.error("Custon Duty data not found");
      setInitLoading(false);
    })
  }


  //create New record

  const onClickCreate = (e: any) => {
    e.preventDefault();
    setCreateRowSubmitCount(0);
    // sethsn("");
    // setcustomsRate("");
    // setstartDate("");
    // setendDate("");
    // setcodeDuty("");
    setInCreateMode(!(inCreateMode));
  }


  // Create new row

  const createNewRow = () => {
    return (
      <tr key={0} className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100">

        {/* HSN */}
        <td className="py-3 px-2 text-left relative">
          <input
            onBlur={(e) => setActiveColCreate("")}
            onFocus={(e) => setActiveColCreate("hsn")}
            className={`appearance-none rounded  block w-full px-2
              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
             focus:outline-none text-sm
             ${CreateRowSubmitCount > 0 && CreateRowErrors.hsn && "border-red-600 focus:border-red-600"}`}
            value={hsn}
            placeholder="HSN"
            onChange={(e) => {
              validateCustomDutyCreate({ hsn: e.target.value });
              sethsn(e.target.value)
            }}
          />
          <ErrorToolTip
            error={CreateRowErrors.hsn}
            count={CreateRowSubmitCount}
            type={"hsn"}
            active={ActiveColCreate}
          />
        </td>

        {/* Duty On Notified Rates  */}
        {/* <td className="py-3 px-2 text-left relative">
          <select 
          className={`appearance-none rounded  block w-full px-2
          py-1 border border-gray-300 placeholder-gray-500 text-gray-900
         focus:outline-none text-sm`}
            value={dutyOnNotifiedRates} 
            onChange={(e) => setdutyOnNotifiedRates(e.target.value)}>
            <option value="true">Enabled</option>
            <option value="false">Disabled</option>
          </select>
        </td> */}

        {/* Exe Rate */}
        {/* <td className="py-3 px-2 text-center relative">
            <input 
             onBlur={(e) => setActiveColCreate("")}
             onFocus={(e) => setActiveColCreate("codeDuty")} 
               className={`appearance-none rounded  block w-full px-2
              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
             focus:outline-none text-sm
             ${ CreateRowSubmitCount > 0 && CreateRowErrors.codeDuty && "border-red-600 focus:border-red-600"}`}
              value={codeDuty}  
              placeholder="Code Duty"
              onChange={(e) => { 
                validateCustomDutyCreate({codeDuty:e.target.value});
                setcodeDuty(e.target.value) }}
            />
            <ErrorToolTip 
              error={CreateRowErrors.codeDuty} 
              count={CreateRowSubmitCount}
              type={"codeDuty"}
              active={ActiveColCreate}
              />
          </td> */}
        {/* Start Date */}
        <td className="py-3 px-2 text-left relative">
          <input
            onBlur={(e) => setActiveColCreate("")}
            onFocus={(e) => setActiveColCreate("startDate")}
            className={`appearance-none rounded  block w-full px-2
              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
             focus:outline-none text-sm
             ${CreateRowSubmitCount > 0 && CreateRowErrors.startDate && "border-red-600 focus:border-red-600"}`}
            value={startDate}
            type="date"
            onChange={(e) => {
              validateCustomDutyCreate({ startDate: e.target.value });
              setstartDate(e.target.value)
            }}
          />
          <ErrorToolTip
            error={CreateRowErrors.startDate}
            count={CreateRowSubmitCount}
            type={"startDate"}
            active={ActiveColCreate}
          />
        </td>
        {/* End Date */}
        <td className="py-3 px-2 text-left relative">
          <input
            onBlur={(e) => setActiveColCreate("")}
            onFocus={(e) => setActiveColCreate("endDate")}
            className={`appearance-none rounded  block w-full px-2
              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
             focus:outline-none text-sm
             ${CreateRowSubmitCount > 0 && CreateRowErrors.endDate && "border-red-600 focus:border-red-600"}`}
            value={endDate}
            type="date"
            onChange={(e) => {
              validateCustomDutyCreate({ endDate: e.target.value });
              setendDate(e.target.value)
            }}
          />
          <ErrorToolTip
            error={CreateRowErrors.endDate}
            count={CreateRowSubmitCount}
            type={"endDate"}
            active={ActiveColCreate}
          />
        </td>

        {/* Advalorem */}
        <td className="py-3 px-2 text-left relative">
          <input
            onBlur={(e) => setActiveColCreate("")}
            onFocus={(e) => setActiveColCreate("customsRate")}
            className={`appearance-none rounded  block w-full px-2
              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
             focus:outline-none text-sm
             ${CreateRowSubmitCount > 0 && CreateRowErrors.customsRate && "border-red-600 focus:border-red-600"}`}
            value={customsRate}
            // value={}
            placeholder="Customs Rate"
            onChange={(e) => {
              validateCustomDutyCreate({ customsRate: e.target.value });
              setcustomsRate(e.target.value)
            }}
          />
          <ErrorToolTip
            error={CreateRowErrors.customsRate}
            count={CreateRowSubmitCount}
            type={"customsRate"}
            active={ActiveColCreate}
          />
        </td>

        {/* Duties(flat / %) */}
        <td className="py-3 px-2 text-left relative">
          <input
            onBlur={(e) => setActiveColCreate("")}
            onFocus={(e) => setActiveColCreate("dutyFCT")}
            className={`appearance-none rounded  block w-full px-2
              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
             focus:outline-none text-sm
             ${CreateRowSubmitCount > 0 && CreateRowErrors.dutyFCT && "border-red-600 focus:border-red-600"}`}
            value={dutyFCT}
            placeholder="Duty FCT"
            onChange={(e) => {
              validateCustomDutyCreate({ dutyFCT: e.target.value });
              setdutyFCT(e.target.value)
            }}
          />
          <ErrorToolTip
            error={CreateRowErrors.dutyFCT}
            count={CreateRowSubmitCount}
            type={"dutyFCT"}
            active={ActiveColCreate}
          />
        </td>
        {/* Active */}
        <td className="py-3 px-2 text-left relative">
          <select className="appearance-none rounded  block w-full px-2
              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
             focus:outline-none text-sm"
            value={active}
            onChange={(e) => setactive(e.target.value)}>
            <option value="true">Enabled</option>
            <option value="false">Disabled</option>
          </select>
        </td>
        <td className="py-3 px-2 relative text-center">
          <div className="flex item-center justify-center">
            <div
              onClick={(e) => onCreateCustomeDutyClicked(e)}
              className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
              </svg>
            </div>
            <div className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
              onClick={(e) => {
                clearDataInForm();
                onAddCustomeDuty()
              }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d=" M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
          </div>
        </td>
      </tr>
    )
  }

  return (
    <div className="my-6">

      <h6 className="text-xl font-bold text-left  font-normal leading-normal mt-0 mb-4 text-gray-800 ">
        Customs Duty &nbsp;&nbsp;
      {isEditable && id != -1 && <span onClick={(e) => onClickCreate(e)}
          className="inline-flex justify-center py-2 px-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
        </span>}
      </h6>
      <table className="text-sm table-auto rounded-lg w-full">
        <thead>
          <tr className="sticky top-0 px-6 py-3 text-green-900 bg-purple-300">
            <th className="py-3 px-2 text-left">HSN</th>
            {/* <th className="py-3 px-2 text-left text-xs">Duty On <br/>Notified Rates</th>
                <th className="py-3 px-2 text-left">Code Duty</th> */}
            <th className="py-3 px-2 text-left">Start Date</th>
            <th className="py-3 px-2 text-left">End Date</th>
            <th className="py-3 px-2 text-left">Customs Rate</th>
            <th className="py-3 px-2 text-left">Duties <br />(flat / %)</th>
            <th className="py-3 px-2 text-left">Active</th>
            {isEditable && <th className="py-3 px-2 text-left">Action</th>}

          </tr>
        </thead>
        <tbody className="text-gray-800 text-sm font-light">
          {inCreateMode ? createNewRow() : ""}
          {data.map((items: any, i: number) => (

            <tr className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100">
              {/* HSN */}
              <td className="py-3 px-6 text-left relative">
                {(inEditMode.status && inEditMode.row == i)
                  ?
                  <div>
                    <input
                      onChange={(e: any) => {
                        validateCustomDutyEdit({ ...editRow, hsn: e.target.value });
                        setEditRow({ ...editRow, hsn: e.target.value })
                      }}
                      onBlur={(e) => setActiveColEdit("")}
                      onFocus={(e) => setActiveColEdit("hsn")}
                      className={`appearance-none rounded  block w-full px-2
                              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm
                            ${EditRowErrors.hsn && "border-red-600 focus:border-red-600"}`}
                      value={editRow.hsn} >
                    </input>
                    <ErrorToolTip
                      error={EditRowErrors.hsn}
                      count={1}
                      type={"hsn"}
                      active={ActiveColEdit}
                    />
                  </div>
                  : <span className="font-semibold">{items.hsn}</span>
                }
              </td>

              {/* Duty On Notified Rates  */}
              {/* <td className="py-3 px-2 text-left relative">
                    {(inEditMode.status && inEditMode.row == i) ? 
                    <select 
                    className={`appearance-none rounded  block w-full px-2
                    py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                   focus:outline-none text-sm`}
                      value={editRow.dutyOnNotifiedRates} 
                      onChange={(e) => setEditRow({...editRow , dutyOnNotifiedRates : e.target.value == 'true'}) }>
                      <option value="true" selected={editRow.dutyOnNotifiedRates}>Enabled</option>
                      <option value="false" selected={!editRow.dutyOnNotifiedRates}>Disabled</option>
                    </select>
                    : (items.dutyOnNotifiedRates)
                    ? <span className="font-semibold bg-green-200 text-green-600 py-1 px-4 rounded-full text-xs">Enabled</span>
                    : <span className="font-semibold bg-red-200 text-red-600 py-1 px-4 rounded-full text-xs">Disabled</span>
                    }
                  </td> */}

              {/* Exe Rate */}
              {/* <td className="py-3 px-6 text-left relative">
                    { (inEditMode.status && inEditMode.row == i) 
                       ? 
                       <div>
                        <input 
                            onChange={(e:any) => {
                              validateCustomDutyEdit({...editRow , codeDuty : e.target.value});
                              setEditRow({...editRow , codeDuty : e.target.value}) }}
                            onBlur={(e) => setActiveColEdit("")}
                            onFocus={(e) => setActiveColEdit("codeDuty")} 
                            className={`appearance-none rounded  block w-full px-2
                              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm
                            ${ EditRowErrors.codeDuty && "border-red-600 focus:border-red-600"}`}
                            value={editRow.codeDuty} >
                        </input>
                        <ErrorToolTip 
                         error={EditRowErrors.codeDuty} 
                         count={1}
                         type={"codeDuty"}
                         active={ActiveColEdit}
                       />
                        </div>
                       : <span className="font-semibold">{items.codeDuty}</span>
                    }
                </td> */}

              {/* Start Date */}
              <td className="py-3 px-6 text-left relative">
                {(inEditMode.status && inEditMode.row == i)
                  ?
                  <div>
                    {/* <input
                      onChange={(e: any) => {
                        validateCustomDutyEdit({ ...editRow, startDate: e.target.value });
                        setEditRow({ ...editRow, startDate: e.target.value })
                      }}
                      type="date"
                      onBlur={(e) => setActiveColEdit("")}
                      onFocus={(e) => setActiveColEdit("startDate")}
                      className={`appearance-none rounded  block w-full px-2
                              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm
                            ${EditRowErrors.startDate && "border-red-600 focus:border-red-600"}`}
                      value={moment(editRow.startDate).format('YYYY-MM-DD')} >
                    </input> */}

                    <Datetime
                      inputProps={{ className: "focus:outline-none" }}
                      value={moment(editRow.startDate).format('YYYY-MM-DD')}
                      timeFormat={false}
                      dateFormat={"DD/MM/YYYY"}
                      className={`appearance-none rounded  block w-full px-2
                              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm
                            ${EditRowErrors.startDate && "border-red-600 focus:border-red-600"}`}
                      onChange={(e: any) => {
                        validateCustomDutyEdit({ ...editRow, startDate: new Date(e).toISOString() });
                        setEditRow({ ...editRow, startDate: new Date(e).toISOString() })
                      }}
                    />
                    <ErrorToolTip
                      error={EditRowErrors.startDate}
                      count={1}
                      type={"startDate"}
                      active={ActiveColEdit}
                    />
                  </div>
                  : <span className="font-semibold">{moment(items.startDate).format('DD-MM-YYYY')}</span>
                }
              </td>
              {/* End Date */}
              <td className="py-3 px-6 text-left relative">
                {(inEditMode.status && inEditMode.row == i)
                  ?
                  <div>
                    {/* <input
                      onChange={(e: any) => {
                        validateCustomDutyEdit({ ...editRow, endDate: e.target.value });
                        setEditRow({ ...editRow, endDate: e.target.value })
                      }}
                      type="date"
                      onBlur={(e) => setActiveColEdit("")}
                      onFocus={(e) => setActiveColEdit("endDate")}
                      className={`appearance-none rounded  block w-full px-2
                              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm
                            ${EditRowErrors.endDate && "border-red-600 focus:border-red-600"}`}
                      value={moment(editRow.endDate).format('YYYY-MM-DD')} >
                    </input> */}
                    <Datetime
                      inputProps={{ className: "focus:outline-none" }}
                      value={moment(editRow.endDate).format('YYYY-MM-DD')}
                      timeFormat={false}
                      dateFormat={"DD/MM/YYYY"}
                      className={`appearance-none rounded  block w-full px-2
                              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm
                            ${EditRowErrors.endDate && "border-red-600 focus:border-red-600"}`}
                      onChange={(e: any) => {
                        validateCustomDutyEdit({ ...editRow, endDate: new Date(e).toISOString() });
                        setEditRow({ ...editRow, endDate: new Date(e).toISOString() })
                      }}
                    />
                    <ErrorToolTip
                      error={EditRowErrors.endDate}
                      count={1}
                      type={"endDate"}
                      active={ActiveColEdit}
                    />
                  </div>
                  : <span className="font-semibold">{moment(items.endDate).format('DD-MM-YYYY')}</span>
                }
              </td>
              {/* Advalorem */}
              <td className="py-3 px-6 text-left relative">
                {(inEditMode.status && inEditMode.row == i)
                  ?
                  <div>
                    <input
                      onChange={(e: any) => {
                        validateCustomDutyEdit({ ...editRow, customsRate: e.target.value });
                        setEditRow({ ...editRow, customsRate: e.target.value })
                      }}
                      onBlur={(e) => setActiveColEdit("")}
                      onFocus={(e) => setActiveColEdit("customsRate")}
                      className={`appearance-none rounded  block w-full px-2
                              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm
                            ${EditRowErrors.customsRate && "border-red-600 focus:border-red-600"}`}
                      value={editRow.customsRate} >
                    </input>
                    <ErrorToolTip
                      error={EditRowErrors.customsRate}
                      count={1}
                      type={"customsRate"}
                      active={ActiveColEdit}
                    />
                  </div>
                  : <span className="font-semibold">{items.customsRate}</span>
                }
              </td>
              {/* Duties(flat / %) */}
              <td className="py-3 px-6 text-left relative">
                {(inEditMode.status && inEditMode.row == i)
                  ?
                  <div>
                    <input
                      onChange={(e: any) => {
                        validateCustomDutyEdit({ ...editRow, dutyFCT: e.target.value });
                        setEditRow({ ...editRow, dutyFCT: e.target.value })
                      }}
                      onBlur={(e) => setActiveColEdit("")}
                      onFocus={(e) => setActiveColEdit("dutyFCT")}
                      className={`appearance-none rounded  block w-full px-2
                              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm
                            ${EditRowErrors.dutyFCT && "border-red-600 focus:border-red-600"}`}
                      value={editRow.dutyFCT} >
                    </input>
                    <ErrorToolTip
                      error={EditRowErrors.dutyFCT}
                      count={1}
                      type={"dutyFCT"}
                      active={ActiveColEdit}
                    />
                  </div>
                  : <span className="font-semibold">{items.dutyFCT}</span>
                }
              </td>
              {/* Active */}
              <td className="py-3 px-2 text-left relative">
                {(inEditMode.status && inEditMode.row == i) ?
                  <select
                    className={`appearance-none rounded  block w-full px-2
                     py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                    focus:outline-none text-sm`}
                    value={editRow.active}
                    onChange={(e) => setEditRow({ ...editRow, active: e.target.value == 'true' })}>
                    <option value="true" selected={editRow.active}>Enabled</option>
                    <option value="false" selected={!editRow.active}>Disabled</option>
                  </select>
                  : (items.active)
                    ? <span className="font-semibold bg-green-200 text-green-600 py-1 px-4 rounded-full text-xs">Enabled</span>
                    : <span className="font-semibold bg-red-200 text-red-600 py-1 px-4 rounded-full text-xs">Disabled</span>
                }
              </td>
              {/* Action */}
              {isEditable && <td className="py-3 px-2 text-left relative">
                <div className="flex item-center justify-center">
                  {inEditMode.status && inEditMode.row == i &&
                    <div
                      onClick={() => onClickSave(i)}
                      className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                      </svg>
                    </div>}
                  {inEditMode.row != i &&
                    <div
                      onClick={() => onClickEdit(i)}
                      className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110 ">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                      </svg>
                    </div>}
                  {inEditMode.row != i &&
                    <div className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110" onClick={() => onClickDelete(items)}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </div>}
                  {inEditMode.status && inEditMode.row == i &&
                    <div
                      onClick={
                        () => {
                          clearDataInForm();
                          setEditRowErrors({});
                          setActiveColEdit("");
                          setInEditMode({ status: false, row: -1 });
                        }}
                      className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d=" M6 18L18 6M6 6l12 12" />
                      </svg>
                    </div>}


                </div>
              </td>}
            </tr>
          ))}

          {!InitLoading && data.length == 0 &&
            <tr className="border-b border-gray-200 bg-gray-50 py-3">
              <td className="py-3 px-2 text-center relative" colSpan={isEditable ? 11 : 10} >
                <span className="text-sm font-medium" >No Custom Duty Records Found !</span>
              </td>
            </tr>}
        </tbody>
      </table>
    </div>


  );


  const sampleData = [{
    HSN: "7106",
    Duty_On_Notified_Rates: "",
    Notified_Rates: "Agcust",
    Exe_Rate: "76.01",
    Start_Date: "7/5/19 0:00",
    End_Date: "7/5/21 0:00",
    Advalorem: "Advalorem",
    Duties: "12.875",
  }, {
    HSN: "7106",
    Duty_On_Notified_Rates: "",
    Notified_Rates: "Agcust",
    Exe_Rate: "76.01",
    Start_Date: "7/5/19 0:00",
    End_Date: "7/5/21 0:00",
    Advalorem: "Advalorem",
    Duties: "12.875",
  }
  ]
}

export default CustomesDutyTable;