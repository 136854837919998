import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Layout from '../../Layout/Layout';

/* eslint-disable react/prop-types */
const ChartComponent = ({ selectedMetal, selectedDuration }:any) => {
  // const [fromDate, setFromDate] = useState('');
  const [res, setRes] = useState<any>([]);

  useEffect(() => {
    const calculateFromDate = (duration:any) => {
      const today = new Date();
      if (duration === '1D') {
        today.setDate(today.getDate() - 1);
      } else if (duration === '1W') {
        today.setDate(today.getDate() - 7);
      } else if (duration === '1M') {
        today.setMonth(today.getMonth() - 1);
      } else {
        throw new Error('Invalid duration');
      }
      return today.toISOString().slice(0, 10);
    };

    const calculatedFromDate = calculateFromDate(selectedDuration);

    const data = JSON.stringify({
      metalCode: selectedMetal,
      fromDate: calculatedFromDate,
    });

    const config:any = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.ebullion.in/price/chart/new',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // const apiFromDate = response?.data?.fromDate || calculatedFromDate; // Use API's fromDate if available
        // setFromDate(apiFromDate);
        setRes(response?.data?.data || []);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [selectedMetal, selectedDuration]);

  const options:any = {
    chart: {
      id: 'area-datetime',
      type: 'area',
      height: 350,
      width: 500,
      zoom: {
        autoScaleYaxis: true,
      },
    },
    title: {
      text: 'Pricing Chart',
      align: 'left',
    },
    yaxis: {
      title: {
        text: 'Price (INR)',
      },
    },
    colors: ['#99834d'],
    stroke: {
      curve: 'straight',
      width: 1,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      x: {
        format: 'dd MMMM yyyy hh:mm TT',
      },
    },
    xaxis: {
      type: 'datetime',
      min: res ? new Date(res[0]?.x).getTime() : undefined, // Set the starting point of the graph
      tickAmount: 6,
    },
  };

  const series = [
    {
      name: 'INR / g',
      data: res,
    },
  ];

  return (
    <div className='area-line-chart'>
      <ReactApexChart
        options={options}
        series={series}
        type='area'
        height={350}
      />
    </div>
  );
};

export default ChartComponent;



