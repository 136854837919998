import axios from "axios";
import Http from "../../library/http.library";
import {httpMethods} from "../../library/enum";
import {makeQuery} from "../../Common/util.helper";

export const getAllMetals = async (tokens: any,currentPage:any) => {
  return await Http.setMethod(httpMethods.get).setUrl(`/metal-master`).setToken(tokens.idToken.jwtToken).request();

};

export const filterMetals = async (data: any, currentPage: any, tokens: any) => {
  var query = {
    isPaginated: true,
    ...(data.metalCode ? { metalCode: data.metalCode } : {}),
    ...(data.description ? { description: data.description } : {}),
    ...(data.active ? { active: data.active } : {}),
    page: currentPage,
  }
  const params = makeQuery(query);
  return await Http.setMethod(httpMethods.get).setUrl(`/metal-master?${params}`).setToken(tokens.idToken.jwtToken).request();

  // return new Promise(async (resolve, reject) => {
  //   let apiUrl = process.env.REACT_APP_URL + `/api/v1/metalcodemaster/all/with-sync`;
  //   resolve(await axios.get(apiUrl));
  // });
};

export const updateMetalsDetails = async (id: any , data: any,tokens:any) => {
  return Http.setMethod(httpMethods.patch).setUrl(`/metal-master/${id}`).setData(data).setToken(tokens.idToken.jwtToken).request();

};


export const CreateMetal = async (data: any,tokens:any) => {
  return await Http.setMethod(httpMethods.post).setUrl(`/metal-master`).setData(data).setToken(tokens.idToken.jwtToken).request();
};

export const GetMetalByID = async (id: number) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/metalcodemaster/${id}`;
    try {
      let result = await axios.get(apiUrl);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const EditMetalMaster = async (id: number, data: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/metalcodemaster/${id}`;
    try {
      let result = await axios.put(apiUrl, data);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteMetalMaster = async (id: number) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/metalcodemaster/${id}`;
    try {
      let result = await axios.delete(apiUrl);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const GetMetalBasisByMetalCode = async (code: string) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/metalbasis/type/${code}`;
    try {
      let result = await axios.get(apiUrl);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const InsertMetalBasis = async (data: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/metalbasis/insert`;
    try {
      let result = await axios.post(apiUrl, data);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const EditMetalBasis = async (id: number, data: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/metalbasis/${id}`;
    try {
      let result = await axios.put(apiUrl, data);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteMetalBasis = async (id: number) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/metalbasis/${id}`;

    try {
      let result = await axios.delete(apiUrl);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};
//------------------API For Customer Duty---------------------

// Get all custome duty

export const GetAllCustomesDuty = async (code: string) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/customDuty/type/${code}`;
    resolve(await axios.get(apiUrl));
  });
};

// Create new custome duty record
export const CreateCustomesDuty = async (data: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apiUrl = process.env.REACT_APP_URL + `/api/v1/customDuty/insert`;
      //let apiUrl = "http://localhost:3030" + `/api/v1/customDuty/insert`;
      resolve(await axios.post(apiUrl, data));
    } catch (error) {
      reject(error);
    }
  });
};
// Delete custome duty record

export const DeleteCusomeDuty = async (id: number) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/customDuty/${id}`;
    try {
      let result = await axios.delete(apiUrl);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

// Update custome duty record
export const EditCusomeDuty = async (id: number, data: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/customDuty/${id}`;
    try {
      let result = await axios.put(apiUrl, data);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

// Get daily-bar list summary by location
export const BarListSummaryByLocation = async (tokens:any, warehouses: any = [], permissions: any = []) => {
  let ids: any = []
  if (permissions.map((x: any) =>  x.permissionCode).includes('vso.receipt') && warehouses.length > 0) {
    ids = warehouses.map((x: any) => x.warehouseId).join(',');
  }
  return await Http.setMethod(httpMethods.get).setUrl(`/summary-by-location${permissions.map((x: any) =>  x.permissionCode).includes('vso.receipt') ? `?warehouseIds=${ids}` : ''}`).setToken(tokens.idToken.jwtToken).request();

};
// Get daily-bar list summary by pool
export const BarListSummaryByPool = async (tokens:any, warehouses: any = [], permissions: any = []) => {
  let ids: any = []
  if (permissions.map((x: any) =>  x.permissionCode).includes('vso.receipt') && warehouses.length > 0) {
    ids = warehouses.map((x: any) => x.warehouseId).join(',');
  }
  return await Http.setMethod(httpMethods.get).setUrl(`/summary-by-pool${permissions.map((x: any) =>  x.permissionCode).includes('vso.receipt') ? `?warehouseIds=${ids}` : ''}`).setToken(tokens.idToken.jwtToken).request();

  // return new Promise(async (resolve, reject) => {
  //   let apiUrl = process.env.REACT_APP_URL + `/summary-by-pool${permissions.map((x: any) =>  x.permissionCode).includes('vso.receipt') ? `?warehouseIds=${ids}` : ''}`;
  //   // let apiUrl = process.env.REACT_APP_URL + `/vault/inventory/bypool`;
  //   try {
  //     let result = await axios.get(apiUrl,{
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Origin": "*",
  //         "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
  //         Authorization: `Bearer ${tokens.idToken.jwtToken}`,
  //       }
  //     });
  //     resolve(result.data);
  //   } catch (error) {
  //     reject(error);
  //   }
  // });
};
// Get daily-bar list

export const BarList = async (page = 0, params: any, pageSize = 10, permissions: any = [], warehouses: any = []) => {
  let warehouseIds: any = []
  if (permissions.map((x: any) =>  x.permissionCode).includes('vso.receipt') && warehouses.length > 0) {
    warehouseIds = warehouses.map((x: any) => x.warehouseId).join(',');
  }
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/vault/inventory`;
    try {
      let result = await axios.get(apiUrl, { params: { page, pageSize, ...params, warehouseIds } });
      resolve(result.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Get daily-bar list
export const BarlistSummery = async (params: any = {}) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/vault/inventory/summary/report`;
    try {
      let result = await axios.get(apiUrl, { params });
      resolve(result.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const CreateCalendar = async (data: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apiUrl = process.env.REACT_APP_URL + `/vsocalendar/create`;

      resolve(await axios.post(apiUrl, data));
    } catch (error) {
      reject(error);
    }
  });
};

//----------------- Get vault refs --------------------

export const GetAllReferences = async (
  token: any,
  isPaginated: boolean = true
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await Http.setMethod(httpMethods.get)
        .setUrl(`/ref-details?isPaginated=${isPaginated}`)
        .setToken(token)
        .request();
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

// Get warehouse stock movement
export const WarehouseStockMovement = async (page = 0, params: any, pageSize = 10) => {

  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/warehouse/movement/register`;
    try {
      console.log("warehouse");
      let result = await axios.get(apiUrl, { params: { page, pageSize, ...params } });

      resolve(result.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Insert new record to daily bar list
export const InsertDailyBarList = async (data: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/vault/inventory/single`;
    try {
      let result = await axios.post(apiUrl, data);
      resolve(result);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const GetMetalAllocationByID = async (id: number) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl =
      process.env.REACT_APP_URL + `/api/v1/stock/details/single/${id}`;
    try {
      let result = await axios.get(apiUrl);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

// Update custome duty record
export const UpdateMetalLockUnLock = async (barNo: number, data: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl =
      process.env.REACT_APP_URL + `/vault/inventory/transaction-lock/${barNo}`;
    try {
      let result = await axios.patch(apiUrl, data);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const CalenderDetailsByDetails = async (
  startDate: string,
  endDate: string,
  vaultId: string
) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl =
      process.env.REACT_APP_URL +
      `/vsocalendar/byrange?startDate=${startDate}&endDate=${endDate}&vaultId=${vaultId}`;
    try {
      let result = await axios.get(apiUrl);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

// Update time
export const EditTime = async (id: number, data: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/vsocalendar/update`;
    try {
      let result = await axios.patch(apiUrl, data);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

// Get All date
export const GetVSOReferencesByData = async (date: string, valultId: string) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/vsoreceipt/pending/${date}/${valultId}`;
    try {
      let result = await axios.get(apiUrl);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};
