import Layout from "../../Layout/Layout";
import React, { useEffect, useState } from "react";
import moment from "moment";
import TaxDetailService from "../Services/tax-details.service";
import TaxService from "../../Tax/Services/tax.service";
import TaxCategoryService from "../../TaxCategory/Services/tax-category.service";
import Loader from "../../../Components-ui/Loader";
import { toast, ToastContainer } from "react-toastify";
import TaxDetailsForm from "./TaxDetailsForm";
import { DATA_TYPE } from "../Utils/enum";
import { useAuthStore } from "../../../Store/AuthStore";
import { Pagination } from "../../../Components-ui/Pagination";
import { makeQuery } from "../../../Common/util.helper";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";

interface PropsType {}

const TaxCategoryTable: React.FC<PropsType> = () => {
  const { logout, permissions, userData, tokens } = useAuthStore();

  const [MetaData, setMetaData] = useState<any>({});
  const [CurrentPage, setCurrentPage] = useState<number>(0);
  const [Loading, setLoading] = useState<any>(false);
  const [TaxDetails, setTaxDetails] = useState<any>([]);
  const [OpenModal, setOpenModal] = useState(false);
  const [Validator, setValidator] = useState(false);
  const [HSN, setHSN] = useState<any>([]);
  const [Tax, setTax] = useState<any>([]);
  const [TaxCategories, setTaxCategories] = useState<any>([]);
  const [TaxDetailModel, setTaxDetailModel] = useState<any>({
    masterId: "",
    subTaxType: "",
    hsnId: "",
    value: "",
    from: moment().format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
    categoryId: "",
    active: "ENABLED",
    createdBy: "string",
  });

  const [FilterData, setFilterData] = useState<any>({
    masterId: "",
    subTaxType: "",
    hsnId: "",
    active: "",
  });

  useEffect((): any => {
    getAllHSN();
    getAllTaxDetails(CurrentPage);
    getAllTax();
    getAllTaxCategories();
    // eslint-disable-next-line
  }, []);

  /**
   * Validate tax model
   * @param e
   * @param type
   */
  const onChangeTax = (e: any, type: any) => {
    switch (type) {
      case DATA_TYPE.START_DATE:
        if (
          moment(TaxDetailModel?.to).valueOf() >= moment(new Date(e)).valueOf()
        ) {
          setValidator(true);
          return setTaxDetailModel({
            ...TaxDetailModel,
            from: moment(e).format("YYYY-MM-DD"),
          });
        }
        if (
          moment(TaxDetailModel?.to).valueOf() < moment(new Date(e)).valueOf()
        ) {
          setValidator(true);
          return setTaxDetailModel({
            ...TaxDetailModel,
            to: moment(e).format("YYYY-MM-DD"),
            from: moment(e).format("YYYY-MM-DD"),
          });
        }

        return setValidator(false);
      case DATA_TYPE.END_DATE:
        if (
          moment(TaxDetailModel?.from).valueOf() <=
          moment(new Date(e)).valueOf()
        ) {
          setValidator(true);
          return setTaxDetailModel({
            ...TaxDetailModel,
            to: moment(e).format("YYYY-MM-DD"),
          });
        }

        return setValidator(false);
      case DATA_TYPE.NORMAL:
        setTaxDetailModel({
          ...TaxDetailModel,
          [e.target.name]: e.target.value,
        });
        console.log("Working");

        return setValidator(true);
      default:
        return "";
    }
  };

  /**
   * Get all tac categories
   * @param page
   * @param isReset
   */
  const getAllTaxDetails = async (page: number, isReset: boolean = false) => {
    setLoading(true);
    let cloneFilterData = !isReset
      ? { ...FilterData }
      : {
          masterId: "",
          subTaxType: "",
          hsnId: "",
          active: "",
        };
    const query = makeQuery(cloneFilterData);
    let data: any = await TaxDetailService.getAllTaxDetails(
      page,
      tokens,
      query
    );
    if (data.status === 200 && data.data.data) {
      setCurrentPage(page);
      setMetaData({
        current: data.data.meta.page,
        pages: data.data.meta.totalPages,
      });
      setTaxDetails(data ? data.data.data.result : []);
    }
    setLoading(false);
  };

  /**
   * Handle filters change event
   * @param e
   */
  const handleChangeFilter = (e: any) => {
    const { name, value } = e.target;
    setFilterData({
      ...FilterData,
      [name]: value,
    });
  };

  /**
   * Reset filters to initial state
   * @param e
   */
  const resetFilterData = async (e: any) => {
    e.preventDefault();
    setFilterData({
      masterId: "",
      subTaxType: "",
      hsnId: "",
      active: "",
    });

    setCurrentPage(0);
    await getAllTaxDetails(0, true);
  };

  /**
   * Get all hsn
   */
  const getAllHSN = async () => {
    let data: any = await TaxDetailService.getAllHSN(tokens, false);
    if (data.status === 200 && data.data.data) {
      let responseData = data.data.data;
      setHSN(responseData);
      setLoading(false);
    }
  };

  /**
   * Get all tax categories
   */
  const getAllTaxCategories = async () => {
    let data: any = await TaxCategoryService.getAllTaxCategories(
      0,
      tokens,
      "",
      false
    );
    if (data.status === 200 && data.data.data) {
      let responseData = data.data.data;
      setTaxCategories(responseData);
      setLoading(false);
    }
  };

  /**
   * Get all taxes
   */
  const getAllTax = async () => {
    let data: any = await TaxService.getAllTaxes(0, tokens, "", false);
    if (data.status === 200 && data.data.data) {
      let responseData = data.data.data;
      setTax(responseData);
      setLoading(false);
    }
  };

  /**
   * Close modal
   */
  const closeModal = () => {
    setOpenModal(false);
  };

  /**
   * Open tax category modal
   */
  const onAddTaxDetails = () => {
    setInitialState();
    setOpenModal(true);
  };

  /**
   * Get tax details
   * @param id
   */
  const getTaxDetailsModel = (id: any) => {
    let taxDetail: any = TaxDetails.filter((tax: any) => tax.id === id);
    if (taxDetail.length > 0) {
      setTaxDetailModel(taxDetail[0]);
      setValidator(true);
      setOpenModal(true);
    }
  };

  /**
   * Enable or disable tax details
   * @param e
   * @param data
   */
  const enableDisableTaxDetails = async (e: any, data: any) => {
    setLoading(true);
    let cloneData = { ...data };

    cloneData.active = e.target.checked ? "ENABLED" : "DISABLED";
    await TaxDetailService.updateTaxDetails(cloneData, tokens);
    await getAllTaxDetails(CurrentPage);
    setLoading(false);
    return toast.success("Tax Details was successfully updated");
  };

  /**
   * Set category details to initial state
   */
  const setInitialState = () => {
    setTaxDetailModel({
      masterId: "",
      subTaxType: "",
      hsnId: "",
      value: "",
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      categoryId: "",
      active: "ENABLED",
      createdBy: "string",
    });
  };

  return (
    <Layout type={"TaxModule"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">{"Tax Details"}</h1>
        {HasAnyAccess(["tax.details.master.create"]) && (
          <button
            className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
            type="button"
            onClick={onAddTaxDetails}
          >
            + Add Tax Details
          </button>
        )}
      </div>

      <div className="w-full rounded border-gray-200 overflow-hidden shadow-lg dark:border-gray-700 bg-white">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Filter Tax Details
          </h3>
        </div>
        <hr />
        <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="masterId"
              className="block text-sm font-medium text-gray-700"
            >
              Master Record
            </label>

            <select
              id="masterId"
              name="masterId"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={FilterData.masterId}
            >
              <option value="">All</option>
              {Tax.map((t: any, index: number) => {
                return (
                  <option key={index} value={t.id}>
                    {t.taxCode}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="subTaxType"
              className="block text-sm font-medium text-gray-700"
            >
              Sub Tax Type
            </label>
            <input
              type="text"
              name="subTaxType"
              id="subTaxType"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={FilterData.subTaxType}
            />
          </div>
          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="hsnId"
              className="block text-sm font-medium text-gray-700"
            >
              HSN
            </label>
            <select
              id="hsnId"
              name="hsnId"
              autoComplete="off"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={FilterData.hsnId}
            >
              <option value="">All</option>
              {HSN.map((hsn: any, index: number) => {
                return (
                  <option key={index} value={hsn.id}>
                    {hsn.hsnCode}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="active"
              className="block text-sm font-medium text-gray-700"
            >
              Status
            </label>
            <select
              id="active"
              name="active"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={FilterData.active}
            >
              <option value="">All</option>
              <option value="ENABLED">ENABLED</option>
              <option value="DISABLED">DISABLED</option>
            </select>
          </div>
          <div className="col-span-12 sm:col-span-12 flex justify-end">
            <button
              className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
              type="button"
              onClick={() => getAllTaxDetails(0)}
            >
              Filter
            </button>
            <button
              className="text-white bg-red-600 border border-red-300 rounded-md background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
              type="button"
              onClick={(e) => resetFilterData(e)}
            >
              Reset
            </button>
          </div>
          <div className="col-span-12 sm:col-span-12">
            {" "}
            <hr />
          </div>
          <div className="col-span-12 sm:col-span-12">
            {Loading ? (
              <Loader />
            ) : (
              <div className="border bg-white rounded border-gray-200 shadow">
                <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                  <thead>
                    <tr className="text-left border-b border-gray-300">
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Sub Tax Type
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        HSN Id
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Value
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        From
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">To</th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Date
                      </th>
                      {HasAnyAccess(["tax.details.master.edit"]) && (
                        <>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800">
                            Status
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800">
                            Actions
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {0 !== TaxDetails.length ? (
                      TaxDetails.map((tax: any, index: number) => {
                        return (
                          <tr
                            key={index}
                            className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                          >
                            <td className="py-3 px-3 text-left relative">
                              {tax.subTaxType}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {
                                HSN.find((i: any) => i.id === tax.hsnId)
                                  ?.hsnCode
                              }
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {tax.value}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {moment(tax.from).format("DD-MM-YYYY HH:mm")}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {moment(tax.to).format("DD-MM-YYYY HH:mm")}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {moment(tax.createdAt).format("DD-MM-YYYY HH:mm")}
                            </td>
                            {HasAnyAccess(["tax.details.master.edit"]) && (
                              <td className="py-3 px-3 text-left relative">
                                <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                  <input
                                    type="checkbox"
                                    name="toggle"
                                    id={`${tax.id}toggle`}
                                    checked={tax.active === "ENABLED"}
                                    className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                                    onChange={(e) =>
                                      enableDisableTaxDetails(e, tax)
                                    }
                                  />
                                  <label
                                    htmlFor={`${tax.id}toggle`}
                                    className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                                  />
                                </div>
                              </td>
                            )}
                            {HasAnyAccess(["tax.details.master.edit"]) && (
                              <td className="py-3 px-3 text-left relative">
                                <button
                                  className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                  type="button"
                                  onClick={() => getTaxDetailsModel(tax.id)}
                                >
                                  Edit
                                </button>
                              </td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                        <td
                          colSpan={8}
                          className="text-center py-3 px-3 text-left relative"
                        >
                          No data to display
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        <div className="col-span-12 sm:col-span-12 px-4 pt-2">
          {/*Pagination Bar*/}
          {!Loading && (
            <Pagination
              meta={MetaData}
              handlePageChange={(number: number) => getAllTaxDetails(number)}
            />
          )}
        </div>
      </div>
      {OpenModal && (
        <TaxDetailsForm
          onCloseModal={closeModal}
          taxDetailsModel={TaxDetailModel}
          onChangeTax={onChangeTax}
          getAllTaxDetails={getAllTaxDetails}
          validatorRef={Validator}
          hsn={HSN}
          taxes={Tax}
          taxCategories={TaxCategories}
          page={CurrentPage}
        />
      )}
    </Layout>
  );
};

export default TaxCategoryTable;
