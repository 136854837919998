import * as math from 'mathjs'
const maths: any = math.create(math.all);
// maths.config({
//     number: "BigNumber",
//   });
const parser = maths.parser();
parser.set('customDuty', (metalCode:string , date:string, hsn:string , round:number) => {
    return {metalCode,date,hsn,round}
})

parser.set("convertUOM", (from:string , to:string) => {
    return {from,to}
});

export const isCustomDutyFunction = (ex:string) => {
   
    const regex = /customDuty.*?\(.*?\).*?/g;
    const found = ex.match(regex);
    if(found && found.length > 0 ){

        const values = found.map( rule => {
            try{
                const result = parser.evaluate(rule);
            
                return{
                    valid : true,
                    rule,
                   ...result
                }
               
            }catch(err){
                    return{
                        rule,
                        valid : false,
                        metalCode : "" ,
                        date : "" ,
                        hsn : "" ,
                        round : ""
                    }
            }
        })

        return { success : true , values};
    }else{
        return { success : false , values : []};
    }
}

export const isConvertUOMFunction = (ex:string) => {
   
    const regex = /convertUOM.*?\(.*?\).*?/g;
    const found = ex.match(regex);
    if(found && found.length > 0 ){

        const values = found.map( rule => {
            try{
                const result = parser.evaluate(rule);
            
                return{
                    valid : true,
                    rule,
                   ...result
                }
               
            }catch(err){
                    return{
                        rule,
                        valid : false,
                        from : "" ,
                        to : ""
                    }
            }
        })

        return { success : true , values};
    }else{
        return { success : false , values : []};
    }
}
