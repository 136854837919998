import React from "react";
import { Route } from "react-router-dom";
import PushNotification from "../../PushNotification/PushNotification";

import DashboardCard from "../Components/DashboardCard";

export const DashboardCardRoutes: React.FC = () => {
  return (
    <>
      <Route path="/statistics" exact component={DashboardCard} />
      <Route path="/push-noti" exact component={PushNotification} />
    </>
  );
};