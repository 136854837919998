import React from "react";
import SubItemList from "./SubItemList";

interface PropsType {
    item: any,
    parentId: string,
    mainItemKey: number
}

const MainItemList: React.FC<PropsType> = ({
                                               item,
                                               parentId,
                                               mainItemKey
                                           }) => {

    return (
        <>

            <tr key={mainItemKey}
                className="accordion-item
                accordion-header
                accordion-button
                w-full
                py-4 px-4
                text-gray-800 text-left
                bg-gray-100
                rounded-none
                transition
                cursor-pointer
                focus:outline-none"
                data-bs-toggle="collapse"
                data-bs-target={`#flush-collapseOne-${mainItemKey}`}
                aria-expanded="false"
                aria-controls={`flush-collapseOne-${mainItemKey}`}
                id={`flush-headingOne-${mainItemKey}`}
            >
                <td className="py-4 px-4 text-left relative">{item.itemCode}</td>
                {/*<td className="py-4 px-4 text-left relative">{item.quantity}</td>*/}
                <td className="py-4 px-4 text-left relative">{item.uom}</td>
                <td className="py-4 px-4 text-left relative">{item.requestedType}</td>
                <td className="py-4 px-4 text-left relative">{item.requestedType}</td>
                <td className="py-4 px-4 text-left relative ">{item.summary.totalLineAmount}</td>


            </tr>

            {
                item.itemBreakDown.length > 0 && (
                    item.itemBreakDown.map((subItem: any, key: number) => {
                        return (
                            <SubItemList
                                key={key}
                                subItem={subItem}
                                mainItemKey={mainItemKey}
                                subItemKey={key}
                                parentId={parentId}/>
                        )
                    })
                )
            }

        </>
    )
}

export default MainItemList;
