import axios from "axios";
import { makeQuery } from "../../../Common/util.helper";
import { httpMethods } from "../../../library/enum";
import Http from "../../../library/http.library";
class PricingService {
  async duplicatePricingMaster(id: string) {
    return await Http.setMethod(httpMethods.put)
      .setUrl(`/price/duplicate/${id}`)
      .request();
    //
    // return new Promise(async (resolve, reject) => {
    //   let apiUrl =
    //     process.env.REACT_APP_URL +
    //     `/price/duplicate/${id}`;
    //   resolve(await axios.put(apiUrl));
    // });
  }

  async updatePriceMasterStatus(id: string, status: string) {
    return new Promise(async (resolve, reject) => {
      let apiUrl =
        process.env.REACT_APP_URL +
        `/api/v1/price/rule/master/${id}/status/${status}`;
      resolve(await axios.put(apiUrl));
    });
  }

  async deleteSubRule(id: string) {
    return new Promise(async (resolve, reject) => {
      let apiUrl =
        process.env.REACT_APP_URL + `/api/v1/price/rule/subRule/` + id;
      resolve(await axios.delete(apiUrl));
    });
  }

  // ============================================ Custom Duty ======================================

  async getCustomDuty(data: any) {
    return new Promise(async (resolve, reject) => {
      let apiUrl = process.env.REACT_APP_URL + `/price/custom_duty`;
      resolve(await axios.post(apiUrl, data));
    });
  }

  async getVariableData() {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/api/v1/price/rule/variables/value`)
      .request();

    // return new Promise(async (resolve, reject) => {
    //   let apiUrl =
    //     process.env.REACT_APP_URL + `/api/v1/price/rule/variables/value`;
    //   resolve(await axios.get(apiUrl));
    // });
  }

  // =========================================  Weight Break  =============================================

  async getWeightBreakDataByID(id: string) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/price/weight/${id}`)
      .request();

    // return new Promise(async (resolve, reject) => {
    //   let apiUrl =
    //     process.env.REACT_APP_URL + `/price/weight/${id}`;
    //   resolve(await axios.get(apiUrl));
    // });
  }

  async getWeightBreakData() {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/price/weight`)
      .request();

    // return new Promise(async (resolve, reject) => {
    //   let apiUrl =
    //     process.env.REACT_APP_URL + `/price/weight`;
    //   resolve(await axios.get(apiUrl));
    // });
  }

  async createWeightBreak(data: any) {
    return await Http.setMethod(httpMethods.post)
      .setUrl(`/price/weight`)
      .setData(data)
      .request();

    // return new Promise(async (resolve, reject) => {
    //   let apiUrl = process.env.REACT_APP_URL + `/price/weight`;
    //   resolve(await axios.post(apiUrl, data));
    // });
  }

  async deleteWeightBreak(id: string) {
    return await Http.setMethod(httpMethods.delete)
      .setUrl(`/price/weight/${id}`)
      .request();

    // return new Promise(async (resolve, reject) => {
    //   let apiUrl = process.env.REACT_APP_URL + `/price/weight/${id}`;
    //   resolve(await axios.delete(apiUrl));
    // });
  }

  async updateWeightBreak(data: any) {
    return await Http.setMethod(httpMethods.patch)
      .setUrl(`/price/weight/${data.id}`)
      .setData(data)
      .request();

    // return new Promise(async (resolve, reject) => {
    //   let apiUrl = process.env.REACT_APP_URL + `/price/weight/${data.id}`;
    //   resolve(await axios.patch(apiUrl, data));
    // });
  }

  async createPricingMaster(data: any, tokens: any) {
    // return new Promise(async (resolve, reject) => {
    //   let apiUrl = process.env.REACT_APP_URL + `/price/master`;
    //   resolve(await axios.post(apiUrl, data));
    // });

    return await Http.setMethod(httpMethods.post)
      .setUrl(`/price/master`)
      .setData(data)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }

  // =========================================== Pricing Sub Rules ==============================================

  async createPricingSubRules(data: any) {
    return new Promise(async (resolve, reject) => {
      let apiUrl = process.env.REACT_APP_URL + `/price/subrule`;
      resolve(await axios.post(apiUrl, data));
    });
  }

  async getPricingSubRulesByMasterId(id: string) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/price/subrule/${id}`)
      .request();

    // return new Promise(async (resolve, reject) => {
    //   let apiUrl =
    //     process.env.REACT_APP_URL + `/price/subrule/${id}`;
    //   resolve(await axios.get(apiUrl));
    // });
  }

  async updatePricingSubRulesByMasterId(id: string, data: any, token: any) {
    return Http.setMethod(httpMethods.patch)
      .setUrl(`/price/subrule/${id}`)
      .setToken(token)
      .setData(data)
      .request();

    // return new Promise(async (resolve, reject) => {
    //   let apiUrl =
    //     process.env.REACT_APP_URL + `/price/subrule/${id}`;
    //   resolve(await axios.patch(apiUrl, data));
    // });
  }

  async updatePricingMaster(id: string, data: any) {
    // return Http.setMethod(httpMethods.patch).setUrl(`/price/master/${id}`).setData(data).request();

    return new Promise(async (resolve, reject) => {
      let apiUrl = process.env.REACT_APP_URL + `/price/master/${id}`;
      resolve(await axios.patch(apiUrl, data));
    });
  }

  async getPricingMasterById(id: string) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/price/master/${id}`)
      .request();

    // return new Promise(async (resolve, reject) => {
    //   let apiUrl =
    //     process.env.REACT_APP_URL + `/price/master/${id}`;
    //   resolve(await axios.get(apiUrl));
    // });
  }

  async getPricingRuleByRuleId(
    metalCode: string,
    partnerId: string,
    uom: string,
    type: string,
    value: string,
    currency: string,
    ruleId: string
  ) {
    // return new Promise(async (resolve, reject) => {
    //   let apiUrl =
    //     process.env.REACT_APP_URL + `/price/getprice/byruleid?metalCode=${metalCode}&partnerId=${partnerId}&uom=${uom}&type=${type}&value=${value}&currency=${currency}&ruleId=${ruleId}`;
    //   resolve(await axios.get(apiUrl));
    // });

    return await Http.setMethod(httpMethods.get)
      .setUrl(
        `/price/getprice/byruleid?metalCode=${metalCode}&partnerId=${partnerId}&uom=${uom}&type=${type}&value=${value}&currency=${currency}&ruleId=${ruleId}`
      )
      .request();
  }

  async getAuxilaryData(feed: any) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/price/pricefeed/all?feed=${feed}`)
      .request();

    // return new Promise(async (resolve, reject) => {
    //   let apiUrl = process.env.REACT_APP_URL + `/price/pricefeed/all?feed=${feed}`;
    //   resolve(await axios.get(apiUrl));
    // });
  }

  /**
   *
   * @param tokens
   * @param currentPage
   * @param isPaginated
   * @returns
   */
  async getBenchData(
    tokens: any,
    currentPage: any,
    isPaginated: boolean,
    filters?: any
  ) {
    if (filters) {
      var query = {
        isPaginated: isPaginated,
        ...(filters.metalCode ? { metalCode: filters.metalCode } : {}),
        ...(filters.basisCode ? { basisCode: filters.basisCode } : {}),
        ...(filters.description ? { description: filters.description } : {}),
        ...(filters.feed ? { feed: filters.feed } : {}),
        page: currentPage,
      };

      const params = makeQuery(query);
      return await Http.setMethod(httpMethods.get)
        .setUrl(`/price/benchmark?${params}`)
        .setToken(tokens.idToken.jwtToken)
        .request();
    } else {
      return await Http.setMethod(httpMethods.get)
        .setUrl(`/price/benchmark?isPaginated=${isPaginated}`)
        .request();
    }
  }

  async updateBenchData(data: any, tokens: any) {
    return await Http.setMethod(httpMethods.patch)
      .setUrl(`/price/benchmark/${data.id}`)
      .setData(data)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }

  async createBenchData(data: any, tokens: any) {
    return await Http.setMethod(httpMethods.post)
      .setUrl(`/price/benchmark`)
      .setData(data)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }

  async getBenchmarkFeedData() {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/price/benchmark?isPaginated=false`)
      .request();

    // return new Promise(async (resolve, reject) => {
    //   let apiUrl = process.env.REACT_APP_URL + "/price/benchmark-feed";
    //   resolve(await axios.get(apiUrl));
    // });
  }

  // async getMetalMasterData() {
  //   return new Promise(async (resolve, reject) => {
  //     let apiUrl = process.env.REACT_APP_URL + "/metal-master";
  //     resolve(await axios.get(apiUrl));
  //   });
  // }

  async getMetalMasterData(tokens: any, params: any) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/metal-master?${params}`)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }

  // async getPartnerMasterData() {
  //   return new Promise(async (resolve, reject) => {
  //     let apiUrl = process.env.REACT_APP_URL + "/partners";
  //     resolve(await axios.get(apiUrl));
  //   });
  // }

  /**
   *
   * @param tokens
   * @param query
   * @returns
   */
  async getPartnerMasterData(tokens: any, query: any) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/partners?${query}`)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }

  async getUOMMasterData() {
    return new Promise(async (resolve, reject) => {
      let apiUrl = process.env.REACT_APP_URL + "/uom-master?isPaginated=false";
      resolve(await axios.get(apiUrl));
    });
  }

  async getPricingMaster(tokens: any, data: any, page: number = 0) {
    data.page = page;
    const query = makeQuery(data);
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/price/master?${query}`)
      .setToken(tokens.idToken.jwtToken)
      .request();

    // return new Promise(async (resolve, reject) => {
    //   let apiUrl = process.env.REACT_APP_URL + `/price/master?isPaginated=false${query??""}`;
    //   resolve(await axios.get(apiUrl));
    // });
  }

  async getFeedMaster() {
    return new Promise(async (resolve, reject) => {
      let apiUrl = process.env.REACT_APP_URL + "/feed/master";
      resolve(
        await axios.get(apiUrl, {
          params: {
            isPaginated: false,
          },
        })
      );
    });
  }

  async changePriceStatus(data: any, tokens: any) {
    return await Http.setMethod(httpMethods.put)
      .setUrl(`/pricing/rule/status-change`)
      .setData(data)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }

  async updateLocoPremium(data: any, tokens: any) {
    return await Http.setMethod(httpMethods.post)
      .unsetData()
      .setUrl(`/price/master/update/loco/premium`)
      .setData(data)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }
}

export default new PricingService();
