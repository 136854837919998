import HeadTE from "./shared/HeadTE";
import TradeExeRuleInput from "./shared/TradeExeRuleInput";
import Layout from "../../Layout/Layout";
import Collapsible from "react-collapsible";
import React, { useState, useEffect } from "react";

import {
  CreateTradeSub,
  DeleteSubTradeRule,
  EditTradeSubRule,
  GetAllById,
  GetAllSubRules,
  GetTradeRuleById,
} from "../Services/Trade.Service";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface PropsType {}


const TradeExeRuleMain: React.FC<PropsType> = () => {
  const [rules, setRules] = useState<any>([]);
  const [subRules, setSubRules] = useState<any>();
  const [loadingDetails, setLoadingDetails] = useState<any>({});
  const [index, setIndex] = useState<any>();
  const [edit, setEdit] = useState(false);
  const [update, setUpdate] = useState(false);

  const selctedItemDetails =
    !!loadingDetails?.item?.length &&
    subRules?.item_code &&
    loadingDetails?.item.find(
      (data: any) => data?.Item_code === subRules?.item_code
    );

  useEffect(() => {

    const id: any = localStorage.getItem("editId");
    if (!!id && !subRules?.id) {
      const subTrading = async () => {
        try {
          const allRef: any = await Promise.allSettled([
            GetTradeRuleById(id), // create 
            GetAllById(id),
           GetAllSubRules(),
          ]);
          setSubRules({
            ...subRules,
            id,
            Rule_ref: allRef?.[0]?.value?.data?.data?.Rule_ref,
            metal_code: allRef?.[0]?.value?.data?.data?.metal,
            item_code: allRef?.[1]?.value?.data?.data?.item?.[0]?.Item_code,
            line_No: 0,
            Item_name: allRef?.[1]?.value?.data?.data?.item?.[0]?.Item_name,
            pricing_rule: allRef?.[1]?.value?.data?.data?.pricing?.[0]?.id,
            currency:
              allRef?.[1]?.value?.data?.data?.currencies?.[0]?.Curr_Code,
              pricing_enabled: false,
              is_Maintain_Stock: false,
              allocate_Vault: false,
              apply_rate_on: "1",
              amount_rate:"AMOUNT",
              metal_discount: false,
              add_ded: false
          
          });
           fetchSubRule(allRef?.[2]?.value?.data?.data, id);
            setLoadingDetails(allRef?.[1]?.value?.data?.data);
        } catch (error) {
          console.error(error);
          toast.error("Something Went Wrong!");
        }
      };
      subTrading();
    }
  }, [subRules]);
  

  useEffect(() => {
    if (update ) {   
      const fetch = async()=>{
        try{
      const cloneData:any= await GetAllSubRules()
          fetchSubRule( cloneData?.data?.data,subRules.id);
      
        }catch(error){
console.error(error);
toast.error(error);
        }
       finally{
        setUpdate(false)
       }

      }
      fetch();

    }
    
  }, [update, subRules?.id]);


  
  const fetchSubRule = async (data: any, id: any) => {
    try {
      const selectedSubRule = Array.isArray(data)
        ? data.filter((v: any) => v?.Rule_ref === parseInt(id))
        : [];
        
      setRules(selectedSubRule);
    } catch (error) {
      toast.error(error);
    }
  };



  const onChange = (e: any) => {
    if (
      e.target.name === "value" ||
      e.target.name ==="discountRate"
    ) {
      return setSubRules({
        ...subRules,
        [e.target.name]: parseFloat(e.target.value),
      });
    }
    if (
      e.target.name === "pricing_rule"
    ) {
      return setSubRules({
        ...subRules,
        [e.target.name]: parseInt(e.target.value),
      });
    }
    setSubRules({
      ...subRules,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddNewRule = async () => {
    const cloneData = {
      ...subRules,
      line_No: rules.length + 1,
    };

    delete cloneData.Item_name;
    try {
      if (!!edit) {
    
        let dataTemp = [...rules];
        dataTemp[index] =cloneData;
        let dataClone = { data: [cloneData] };
        await EditTradeSubRule(parseInt(cloneData.id), {
          ...dataClone,
          data:{
            ...dataClone.data,
            id: parseInt(cloneData?.id),
          }
       
        });
        toast.success("Successfully Updated!")
        setRules(dataTemp);
       
        return setEdit(false);

      }

      delete cloneData.id;

      let cloneRules = [...rules];
      cloneRules.push(cloneData);
      // setRules(cloneRules);
      let data = { records: [cloneData] };
      await CreateTradeSub(data);
      toast.success("Successfully Added!");
    //  setSubRules({defaultValue})
       setUpdate(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelRule = async (index: any) => {
    
    try {
      let delData = [...rules];
      const id = parseInt(delData[index].id);
      await DeleteSubTradeRule(id);
      delData.splice(index, 1);
      setRules(delData);
      toast.success("Successfully Deleted!");
      // setSubRules({defaultValue})
      setUpdate(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (data: any, index: any) => {
    setSubRules(data);
    setIndex(index);
    setEdit(true);
  };

  console.log(subRules);
  

  return (
    <Layout>
          <ToastContainer />
      <div className="">

        <div className="title flex items-center mb-5">
          <div className="text">
            <h1 className="text-2xl font-bold flex items-center tracking-tight">
              Trade Execution Engine
            </h1>
            <p className="">Define your rules for trade execution</p>
          </div>
        </div>

        <TradeExeRuleInput
          handleChange={onChange}
          data={subRules}
          addNewRule={handleAddNewRule}
          edit={edit}
          selctedItemDetails={selctedItemDetails?.Item_name}
          subAllDetail={loadingDetails}
        />
        <Collapsible trigger="Trade execution rules" className="mt-4 shadow-sm">
          <div className="p-3 pb-0 border font-bold uppercase bg-white">
            <HeadTE
              rules={rules}
              delRule={handleDelRule}
              editRule={handleEdit}
              subAllDetail={loadingDetails}
            />
          </div>
        </Collapsible>
      </div>
    </Layout>
  );
};

export default TradeExeRuleMain;