import React from "react";
import { Route } from "react-router-dom";

import StateTable from "../Components/StateTable";


export const StateRoutes: React.FC = () => {
    return (
        <>
            <Route path="/master/state" exact component={StateTable} />
        </>
    );
};
