import React, { useState, useEffect } from "react";
import { useAuthStore } from "../../../Store/AuthStore";
import Layout from "../../Layout/Layout";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import moment from "moment";
import UserListService from "../Services/UserList.Service";
import { Pagination } from "../../../Components-ui/Pagination";
import { useHistory } from "react-router-dom";
import RoleService from "../../Role/Services/role.service";
import AssignRoleForm from "./AssignRoleForm";
import AssignWarehouseForm from "./AssignWarehouseForm";
import WarehouseMasterService from "../../Metal/WearHouseMaster.Service";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";

const ListOfUsers: React.FC = () => {
  const history = useHistory();
  const [Loading, setLoading] = useState<any>(true);
  const [userList, setUserList] = useState<any>([]);
  const [meta, setMetaData] = useState<any>({});
  const [partnerList, setPartnerList] = useState<any>([]);
  const [selectedPartner, setSelectedPartner] = useState<any>({});
  const [updateStatus, setUpdateStatus] = useState<any>("");
  const [toggle, setToggle] = useState<any>("ENABLED");
  const [Roles, setRoles] = useState<any>([]);
  const [OpenModal, setOpenModal] = useState(false);
  const [OpenModalAssignWarehouse, setOpenModalAssignWarehouse] = useState(
    false
  );
  const [User, setUser] = useState({});
  const [UserWarehouse, setUserWarehouse] = useState({});
  const [filters, setFilters] = useState<any>({
    partner: 0,
    user_id: "",
    name: "",
    email: "",
    mobile: "",
    user_type: "",
  });
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [Warehouses, setWarehouses] = useState<any>([]);

  const { logout, permissions, userData, tokens } = useAuthStore();

  const changeStatus = async (status: any, key: any, id: any) => {
    setUpdateStatus("STATUS");
    let newArr = [...userList];
    newArr[key].active = "ENABLED" !== status ? "ENABLED" : "DISABLED";
    setSelectedPartner(newArr[key]);
    setUserList(newArr);
  };

  const updateUser = async () => {
    await UserListService.updateUser(selectedPartner, tokens)
      .then((response: any) => {
        get_user_list(currentPage);
        setLoading(false);
        toast.success("Updated Successfully");
      })
      .catch((err) => {
        setLoading(false);
        get_user_list(currentPage);
        toast.error(err.response.data.errors[0].title);
      });
    setUpdateStatus("");
  };

  useEffect(() => {
    if ("STATUS" === updateStatus) {
      updateUser();
    }
  }, [updateStatus, selectedPartner]);

  const get_partner_list = async () => {
    await UserListService.getPartnerList(tokens)
      .then((response: any) => {
        setPartnerList(response.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.errors[0].title);
      });
  };

  const get_user_list = async (page: number) => {
    setLoading(true);
    await UserListService.getUserList(tokens, page)
      .then((response: any) => {
        setUserList(response.data.data);
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPage(page);
        userList.map((user: any, index: number) => {
          setToggle({
            ...toggle,
            key: index,
            active: user.active,
          });
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.errors[0].title);
      });
  };

  const filter = async (page: number) => {
    setLoading(true);
    await UserListService.filterUsers(filters, page, tokens)
      .then((response: any) => {
        setUserList(response.data.data);
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPage(response.data.meta.page);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.errors[0].title);
      });
  };

  const ResetForm = async (e: any) => {
    e.preventDefault();
    setCurrentPage(0);
    setFilters({
      partner: 0,
      user_id: "",
      name: "",
      email: "",
      mobile: "",
      user_type: "",
    });
    await get_user_list(0);
  };

  const paginationFilter = async (page: number) => {
    await filter(page);
  };

  useEffect(() => {
    get_user_list(0);
    get_partner_list();
    getAllRoles();
    getAllWarehouses();
  }, []);

  /**
   * Get all roles
   */
  const getAllRoles = async () => {
    let data: any = await RoleService.getAllRoles(0, tokens, false);
    if (data.status === 200 && data.data.data) {
      setRoles(data.data.data);
    }
  };

  /**
   * Get all warehouses
   */
  const getAllWarehouses = async () => {
    let data: any = await WarehouseMasterService.getWarehouseMasterData(
      tokens,
      0,
      false
    );
    if (data.status === 200 && data.data.data) {
      setWarehouses(data.data.data);
    }
  };

  /**
   * Close modal
   */
  const closeModal = () => {
    setOpenModal(false);
  };

  /**
   * Close warehouse modal
   */
  const onCloseWarehouseModal = () => {
    setOpenModalAssignWarehouse(false);
  };

  /**
   * Get user details
   * @param id
   * @param isWarehouse
   */
  const getUser = (id: any, isWarehouse: boolean) => {
    let user: any = userList.filter((user: any) => user.userId === id);
    if (user.length > 0) {
      if (isWarehouse) {
        setUserWarehouse(user[0]);
        setOpenModalAssignWarehouse(true);
      } else {
        setUser(user[0]);
        setOpenModal(true);
      }
    }
  };

  /**
   * Enable or disable user
   * @param e
   * @param userId
   */
  const enableDisableUser = async (e: any, userId: string) => {
    setLoading(true);
    let payload = {
      enable: e.target.checked,
      users: [userId],
    };
    try {
      await UserListService.enableDisableUser(tokens, payload);
      await get_user_list(currentPage);
      setLoading(false);
      return toast.success("User was successfully updated");
    } catch (e: any) {
      setLoading(false);
      console.log(e.data.errors);
      if (e.data.errors.length > 0) {
        return toast.error(e.data.errors[0].title);
      }
      return toast.error("Something went wrong");
    }
  };

  return (
    <Layout type={"UserModule"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">{"Users"}</h1>
        {HasAnyAccess(["user.create"]) && (
          <button
            className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
            type="button"
            onClick={() => {
              history.push(`/user/create`);
            }}
          >
            + Create New
          </button>
        )}
      </div>
      <div className="border bg-white rounded border-gray-200 shadow">
        <div className="flex-1 p-2">
          <form
            onSubmit={(e) => {
              filter(0);
              e.preventDefault();
            }}
          >
            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="user-id"
                >
                  User ID
                </label>
                <input
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      user_id: event.target.value,
                    })
                  }
                  name="user-id"
                  id="user-id"
                  type="text"
                  placeholder="User ID"
                  value={filters.user_id}
                  autoComplete="none"
                />
              </div>
              <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="user-id"
                >
                  User Name
                </label>
                <input
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      name: event.target.value,
                    })
                  }
                  name="user-name"
                  id="user-name"
                  type="text"
                  placeholder="User Name"
                  value={filters.name}
                  autoComplete="none"
                />
              </div>
              <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="email"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      email: event.target.value,
                    })
                  }
                  type="text"
                  placeholder="Email"
                  value={filters.email}
                  autoComplete="none"
                />
              </div>
              <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="mobile"
                >
                  Mobile
                </label>
                <input
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="mobile"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      mobile: event.target.value,
                    })
                  }
                  type="text"
                  placeholder="Mobile"
                  value={filters.mobile}
                  autoComplete="none"
                />
              </div>
              <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="user-type"
                >
                  User Type
                </label>
                <div className="relative">
                  <select
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    id="user-type"
                    onChange={(event) =>
                      setFilters({
                        ...filters,
                        user_type: event.target.value,
                      })
                    }
                    value={filters.user_type}
                  >
                    <option value="">Select an User Type</option>
                    <option value="STAFF">STAFF</option>
                    <option value="CUSTOMER">CUSTOMER</option>
                    <option value="PARTNER">PARTNER</option>
                  </select>
                </div>
              </div>
              <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="partner"
                >
                  Partner
                </label>
                <div className="relative">
                  <select
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    id="partner"
                    onChange={(event) =>
                      setFilters({
                        ...filters,
                        partner: event.target.value,
                      })
                    }
                    value={filters.partner}
                  >
                    <option value="">Select a Partner</option>
                    {partnerList.map((partner: any) => (
                      <option value={partner.partnerId}>{partner.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="w-full px-3 mb-6 md:mb-0 justify-end">
                <div className="flex my-2 justify-end">
                  {/* <button
                  className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                  type="button"
                  onClick={() => filter(0)}
                >
                  Filter
                </button> */}
                  <input
                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                    type="submit"
                    value="Filter"
                  />
                  <button
                    className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                    type="button"
                    onClick={(e) => ResetForm(e)}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="border bg-white rounded border-gray-200 shadow m-2">
          {Loading ? (
            <Loader />
          ) : (
            <div>
              <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                <thead>
                  <tr className="text-left border-b border-gray-300">
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      User id
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Name
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Email
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Mobile
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      User Type
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Partner
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Created Date
                    </th>
                    {/*{*/}
                    {/*    permissions.filter((permission: any) => permission.permissionCode === 'user.edit').length > 0 && (*/}
                    {/*<th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Status</th>)}*/}
                    {HasAnyAccess([
                      "user.view",
                      "assign.role",
                      "assign.warehouse",
                    ]) && (
                      <th
                        className="sticky top-0 px-3 py-2 bg-gray-800 z-10"
                        colSpan={2}
                      >
                        Status
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {0 !== userList.length ? (
                    userList.map((user: any, index: number) => {
                      return (
                        <tr
                          key={index}
                          className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                        >
                          <td className="py-3 px-3 text-left relative">
                            {user.userId}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {user.firstName + " " + user.lastName}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {user.email.value}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {user.mobile.value}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {user.userType}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {user.partner.name}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {moment(user.createdAt).format("DD-MM-YYYY HH:mm")}
                          </td>
                          {HasAnyAccess(["user.edit"]) && (
                            <td className="py-3 px-3 text-left relative">
                              <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                <input
                                  type="checkbox"
                                  name="toggle"
                                  id={`${user.userId}toggle`}
                                  checked={user.active === "ENABLED"}
                                  className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                                  // onChange={(e) =>
                                  //   enableDisableUser(e, user.userId)
                                  // }
                                />
                                <label
                                  htmlFor={`${user.userId}toggle`}
                                  className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                                />
                              </div>
                            </td>
                          )}
                          {HasAnyAccess([
                            "user.view",
                            "assign.warehouse",
                            "assign.role",
                          ]) && (
                            <td className="py-3 px-3 text-left">
                              <div className="flex justify-center">
                                <div className="relative">
                                  <button
                                    className="whitespace-nowrap items-center flex ease-in-out duration-150 transition active:bg-purple-800 active:shadow-lg active:text-white focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 hover:bg-purple-700 hover:shadow-lg shadow-md rounded dropdown-toggle px-0.5 py-0.5 bg-purple-950 text-white font-medium text-xs text-xs leading-tight"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="20px"
                                      viewBox="0 0 24 24"
                                      width="20px"
                                      fill="#FFFFFF"
                                    >
                                      <path d="M0 0h24v24H0z" fill="none" />
                                      <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                                    </svg>
                                  </button>
                                  <ul
                                    className="border-none bg-clip-padding m-0 hidden mt-1 shadow-lg rounded-lg text-left list-none py-2 float-left z-50 text-base bg-white hidden absolute dropdown-menu min-w-max"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    {" "}
                                    {HasAnyAccess(["user.view"]) && (
                                      <li>
                                        <button
                                          className="hover:bg-gray-100 text-gray-700 bg-transparent whitespace-nowrap w-full block font-normal px-4 py-2 text-sm dropdown-item"
                                          onClick={() =>
                                            history.push(
                                              `/users/${user.userId}`
                                            )
                                          }
                                        >
                                          View
                                        </button>
                                      </li>
                                    )}
                                    {user.userType !== "CUSTOMER" ? (
                                      <>
                                        {HasAnyAccess(["assign.role"]) && (
                                          <li>
                                            <button
                                              className="hover:bg-gray-100 text-gray-700 bg-transparent whitespace-nowrap w-full block font-normal px-4 py-2 text-sm dropdown-item"
                                              onClick={() =>
                                                getUser(user.userId, false)
                                              }
                                            >
                                              Assign Role
                                            </button>
                                          </li>
                                        )}
                                        {HasAnyAccess(["assign.warehouse"]) && (
                                          <li>
                                            <button
                                              className="hover:bg-gray-100 text-gray-700 bg-transparent whitespace-nowrap w-full block font-normal px-4 py-2 text-sm dropdown-item"
                                              onClick={() =>
                                                getUser(user.userId, true)
                                              }
                                            >
                                              Assign Warehouse
                                            </button>
                                          </li>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                      <td
                        colSpan={9}
                        className="text-center py-3 px-3 text-left relative"
                      >
                        No data to display
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          <Pagination
            meta={meta}
            handlePageChange={(number: number) => {
              paginationFilter(number);
            }}
          />
        </div>
      </div>

      {OpenModal && (
        <AssignRoleForm
          onCloseModal={closeModal}
          user={User}
          getAllUsers={get_user_list}
          page={currentPage}
          roles={Roles}
        />
      )}

      {OpenModalAssignWarehouse && (
        <AssignWarehouseForm
          onCloseModal={onCloseWarehouseModal}
          user={UserWarehouse}
          getAllUsers={get_user_list}
          page={currentPage}
          warehouses={Warehouses}
        />
      )}
    </Layout>
  );
};

export default ListOfUsers;
