import Http from "../../library/http.library";
import {httpMethods} from "../../library/enum";
import {makeQuery} from "../../Common/util.helper";


export const getHsnData = async (tokens:any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/hsn-master`).setToken(tokens.idToken.jwtToken).request();
};

export const filterHsnData = async (data:any, currentPage:any, tokens:any) => {
    var query = {
        isPaginated: true,
        ...(data.hsnCode ? {hsnCode: data.hsnCode} : {}),
        ...(data.description ? {description: data.description} : {}),
        ...(data.active ? {active: data.active} : {}),
        page: currentPage,
    }
    const params = makeQuery(query);
    return await Http.setMethod(httpMethods.get).setUrl(`/hsn-master?${params}`).setToken(tokens.idToken.jwtToken).request();
};

const HsnMasterService = {
    getHsnData,
    filterHsnData
};

export default HsnMasterService;
