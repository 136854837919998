import Http from "../../../library/http.library";
import { httpMethods } from "../../../library/enum";

class SipPromoCodeService {
  /**
   * Get all discounts
   * @param page
   * @param query
   * @param isPaginated
   */
  async getAllSipPromoCodes(
    tokens: any,
    page: number,
    query: any,
    isPaginated: boolean = true,
    isSip: boolean = true
  ) {
    return await Http.setMethod(httpMethods.get)
      .setToken(tokens.idToken.jwtToken)
      .setUrl(
        `/discount?isSip=${isSip}&orderBy=createdAt&order=desc&page=${page}${query}${
          !isPaginated ? `&isPaginated=false` : ``
        }`
      )
      .request();
  }

  /**
   * Get discount details
   * @param id
   */
  async getSipPromoCodeDetails(id: any, tokens: any) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/discount/${id}`)
      .setToken(tokens.idToken.jwtToken)
      // .setUrl(`/discount?id=${id}`)
      .request();
  }

  /**
   * Add new discount
   * @param data
   * @param tokens
   */
  async addSipPromoCode(data: any, tokens: any) {
    return await Http.setMethod(httpMethods.post)
      .setUrl(`/discount`)
      .setData(data)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }

  /**
   * Update discount details
   * @param data
   * @param tokens
   */
  async updateSipPromoCode(data: any, tokens: any) {
    return await Http.setMethod(httpMethods.patch)
      .setUrl(`/discount/${data.id}`)
      .setData(data)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }

  async enableSipPromoCodeCouponStatus(data: any, tokens: any) {
    return await Http.setMethod(httpMethods.patch)
      .setUrl(`/discount/status/${data.id}`)
      .setData(data)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }
}

export default new SipPromoCodeService();
