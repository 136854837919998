import React, { useState } from "react";
import Main from "./Main";
import Layout from "../../Layout/Layout";

interface PropsType {
  // askPrice: number;
  // bitPrice: number;
}

const Branch: React.FC<PropsType> = () => {
  const [counterArr, setCounterArr] = useState<any>([0,0,0,0]);

  const onPlusClicked = () => {
    setCounterArr([...counterArr, []]);
  };

  return (
    <Layout type={"Other"}>
    <div>
      <div className='flex justify-between mb-5'>
        <h1 className='text-3xl font-bold'>API Testing</h1>
        <button
        type="button"
        className="bg-indigo-600 uppercase text-white px-4 py-2 rounded font-bold"
        onClick={() => onPlusClicked()}
      >
        + Metal
      </button>
      </div>
      
      <div className='flex'>
      {counterArr.map((i: any, index: number) => {
        return (
         
         <div className='flex flex-1 flex-shrink m-1'>
        <Main key={index} />
       
        </div> 
        );
      })}
    </div>
    </div>
    </Layout>
  );
};

export default Branch;
