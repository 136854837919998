import axios from "axios";

class CustomsDutyService {

    /**
     * Get all custom duties
     * @param page
     * @param tokens
     * @param query
     * @param isPaginated
     */
    async getAllCustomDuties(page:number, tokens: any, query: any, isPaginated: boolean = true) {
        return new Promise(async (resolve, reject) => {
            let apiUrl = `${process.env.REACT_APP_URL}/price/custom-duty?page=${page}${query}${!isPaginated ? `&isPaginated=false` : ``}`;
            try{
                resolve(await axios.get(apiUrl , {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                        Authorization: `Bearer ${tokens.idToken.jwtToken}`,
                    },
                }));
            }catch (e) {
                reject(e)
            }
        });
    }

    /**
     * Get all metals
     * @param page
     * @param tokens
     * @param query
     * @param isPaginated
     */
    async getAllMetals(page:number, tokens: any, query: any, isPaginated: boolean = true) {
        return new Promise(async (resolve, reject) => {
            let apiUrl = `${process.env.REACT_APP_URL}/metal-master?page=${page}${query}${!isPaginated ? `&isPaginated=false` : ``}`;
            try{
                resolve(await axios.get(apiUrl , {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                        Authorization: `Bearer ${tokens.idToken.jwtToken}`,
                    },
                }));
            }catch (e) {
                reject(e)
            }
        });
    }

    /**
     * Add new customs duty
     * @param data
     * @param tokens
     */
    async addCustomsDuty(data: any, tokens: any) {
        return new Promise(async (resolve, reject) => {
            let apiUrl = `${process.env.REACT_APP_URL}/price/custom-duty`;
            try{
                resolve(await axios.post(apiUrl, [data], {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                        Authorization: `Bearer ${tokens.idToken.jwtToken}`,
                    },
                }));
            }catch (e) {
                reject(e)
            }
        })

    }

    /**
     * Update custom duty details
     * @param data
     * @param tokens
     */
    async updateCustomsDuty(data: any, tokens: any) {
        return new Promise(async (resolve, reject) => {
            let apiUrl = `${process.env.REACT_APP_URL}/price/custom-duty/${data.id}`;
            try {
                resolve(await axios.patch(apiUrl, data, {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                        Authorization: `Bearer ${tokens.idToken.jwtToken}`,
                    },
                }));
            } catch (e) {
                reject(e)
            }
        })
    }

}

export default new CustomsDutyService();
