import React from 'react'

const QueryExpand = () => {
    return (
        <>
      <>
        <div className=" overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-lg">
            <div className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
              <div className="items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 className="text-3xl font-semibold">More</h3>
              </div>

              <div className="flex justify-items-center">
                <div className="relative p-6 flex-auto">
                  <div className="items-center justify-center flex-row p-2">
                    <label className="text-base font-medium text-gray-800 pr-20">
                      Total amount:
                    </label>
                  </div>
                  <div className="items-center justify-center flex-col p-2">
                    <label className="text-base font-medium text-gray-800 pr-20">
                      hsn code:
                    </label>
                  </div>
                  <div className="items-center justify-center flex-col p-2">
                    <label className="text-base font-medium text-gray-800 pr-20">
                      gst master ref:
                    </label>
                  </div>
                  <div className="items-center justify-center flex-col p-2">
                    <label className="text-base font-medium text-gray-800 pr-20">
                      pricing ref:
                    </label>
                  </div>
                  <div className="items-center justify-center flex-col p-2">
                    <label className="text-base font-medium text-gray-800 pr-20">
                      invoice generated:
                    </label>
                 
                  </div>
                  <div className="items-center justify-center flex-col p-2">
                    <label className="text-base font-medium text-gray-800 pr-20">
                      Invnum:
                    </label>
                  </div>
                  <div className="items-center justify-center flex-col p-2">
                    <label className="text-base font-medium text-gray-800 pr-20">
                      metal allocated:
                    </label>
                    
                  </div>
                  <div className="items-center justify-center flex-col p-2">
                    <label className="text-base font-medium text-gray-800 pr-20">
                      allocation ref:
                    </label>
                   
                  </div>

                  <div className="items-center justify-center flex-col p-2">
                    <label className="text-base font-medium text-gray-800 pr-20">
                      dsr reference:
                    </label>
                  </div>

                  <div className="items-center justify-center flex-col p-2">
                    <label className="text-base font-medium text-gray-800 pr-20">
                      vso userid:
                    </label>
                  </div>
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  
                    <div>
                      <button
                        id="modal"
                        className="text-white bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        // onClick={onClose}
                      >
                        Close
                      </button>
                    </div>
                  </div>
             
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
    )
}

export default QueryExpand
