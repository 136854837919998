import { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import {
  GetAllCurrencyMaster,
  GetAllMetalMaster,
  GetAllMetalUomConvMaster,
  GetAllItemMaster,
  GetAllTaxGstMaster,
  GetAllWarehouseMaster,
} from "./Master.Service";
import { ToastContainer, toast } from "react-toastify";
import "./react-confirm-alert.css";
import moment from 'moment'

const MasterTable: React.FC = () => {
  const [currency, setCurrency] = useState<any>();
  const [metal, setMetal] = useState<any>();
  const [metalUomCon, setMetalUomCon] = useState<any>();
  const [item, setItem] = useState<any>();
  const [taxGst, setTaxGst] = useState<any>();
  const [warehouse, setWarehouse] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    loadData();
  }, [loading]);

  const loadData = async () => {
    try {
      let allCurrency: any = await GetAllCurrencyMaster();
      setCurrency(allCurrency.data.data);

      let allMetal: any = await GetAllMetalMaster();
      setMetal(allMetal.data.data);

      let allMetalUomConv: any = await GetAllMetalUomConvMaster();
      setMetalUomCon(allMetalUomConv.data.data);

      let allItem: any = await GetAllItemMaster();
      setItem(allItem.data.data);

      let allTaxGst: any = await GetAllTaxGstMaster();
      setTaxGst(allTaxGst.data.data);

      let allWarehouse: any = await GetAllWarehouseMaster();
      setWarehouse(allWarehouse.data.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong!");
    }
  };

  return (
    <Layout type={"MasterModule"}>
      <ToastContainer />
      <div className="overflow-x-auto">
        <div className="w-full">
          <div className=" shadow-md rounded-lg mt-6">
          <h6 className="mb-2 font-bold tracking-tight text-gray-600 sm:text-md">
          Item Master 
        </h6>
        <table className="w-full table-auto rounded-lg text-xs">
              <thead>
                <tr className="sticky top-0 px-2 py-3 text-gray-900 bg-purple-200">
                  {/* <th className="py-3 px-2 text-left text-xs ">Service ID</th> */}
                  <th className="py-3 px-2 text-left text-xs ">Code</th>
                  <th className="py-3 px-2 text-left text-xs ">Name</th>
                  <th className="py-3 px-2 text-left text-xs ">Description</th>
                  <th className="py-3 px-2 text-left text-xs ">Group</th>
                  <th className="py-3 px-2 text-left text-xs ">Metal Code</th>
                  <th className="py-3 px-2 text-left text-xs ">HSN</th>
                  <th className="py-3 px-2 text-left text-xs ">Not GST</th>
                  <th className="py-3 px-2 text-left text-xs ">Stock UOM</th>
                  <th className="py-3 px-2 text-left text-xs ">Is Stock</th>
                
                  <th className="py-3 px-2 text-left text-xs ">UOM</th>
                  <th className="py-3 px-2 text-left text-xs ">Has Batch</th>
                  <th className="py-3 px-2 text-left text-xs ">Has Atomic Batch</th>
                  <th className="py-3 px-2 text-left text-xs ">
                  Assay for CMC
                  </th>
                  <th className="py-3 px-2 text-left text-xs ">Item Tax</th>
                  <th className="py-3 px-2 text-left text-xs ">Default Metal Content</th>
                  <th className="py-3 px-2 text-left text-xs ">Timestamp</th>
                  <th className="py-3 px-2 text-left text-xs ">Disabled</th>
                  {/* <th className="py-3 px-2 text-left text-xs ">User</th> */}
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-light">
                {item &&
                  item.map((item: any) => {
                    return (
                      <tr
                        key={item.id}
                        className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
                      >
                        {/* <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Service_ID}
                          </span>
                        </td> */}
                        <td className="py-3 pl-2 text-left text-xs w-1/12">
                          <span className="font-bold whitespace-normal break-all">
                            {item.Item_code}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-left w-2/12" style={{fontSize : "0.65rem"}}>
                          <span className="font-medium whitespace-normal break-all">{item.Item_name}</span>
                        </td>
                        <td className="py-3 pl-2 text-left w-1/12" style={{fontSize : "0.65rem"}}>
                          <span className="font-medium whitespace-normal break-all">
                            {item.Description.replace(/<\/?[^>]+(>|$)/g, "")}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs w-1/12">
                          <span className="font-medium whitespace-normal break-all">{item.Item_group}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs w-1/12">
                          <span className="font-medium whitespace-normal break-all">{item.Metal_code}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs w-1/12">
                          <span className="font-medium whitespace-normal break-all">
                            {item.Gst_hsn_code}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs w-1/12 ">
                        {showBadge(item.Is_not_gst === true ? "True" : "False")}
                        </td>
                        <td className="py-3 px-2 text-left text-xs w-1/12 ">
                          <span className="font-medium whitespace-normal break-all">{item.Stock_uom}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs w-1/12 ">
                          
                          {showBadge(item.Is_stock_item === true ? "True" : "False")}
                        
                        </td>
                       
                        <td className="py-3 px-2 text-left text-xs w-1/12">
                          <span className="font-medium whitespace-normal break-all">{item.Uoms}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs w-1/12">
                         
                          {showBadge(item.Has_batch_no === true ? "True" : "False")}
                        
                        </td>
                       
                        <td className="py-3 px-2 text-left text-xs w-1/12">
                         
                          {showBadge(item.Has_atomic_batches === true ? "True" : "False")}
                         
                        </td>
                        <td className="py-3 px-2 text-left text-xs w-2/12">
                          <span className="font-medium whitespace-normal break-all">
                            {showBadge(item.Use_assay_for_calculating_metal_conent === true ? "True" : "False")}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs w-1/12">
                          <span className="font-medium whitespace-normal break-all">{item.Item_tax}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs w-1/12">
                          <span className="font-medium whitespace-normal break-all">{item.Default_metal_Content}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs w-1/12">
                          <span className="font-medium whitespace-normal break-all">
                          {
                          moment(item.Timestamp).isValid() ? moment(item.Timestamp).format('DD-MM-YYYY') : ""}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs w-1/12">
                          <span className="font-medium whitespace-normal break-all">
                          {showBadge(item.Disabled === true ? "True" : "False")}
                          </span>
                        </td>
                        {/* <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">{item.user}</span>
                        </td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <div className="w-full">
          <div className=" shadow-md rounded-lg mt-6">
         <h6 className="mb-2 font-bold tracking-tight text-gray-600 sm:text-md">
          Warehouse Master 
        </h6>
            <table className="min-w-max w-full table-auto rounded-lg">
              <thead>
                <tr className="sticky top-0 px-2 py-3 text-gray-900 bg-purple-200">
                  {/* <th className="py-3 px-2 text-left text-xs ">Service ID</th> */}
                  <th className="py-3 px-2 text-left text-xs ">Warehouse Name</th>
                  <th className="py-3 px-2 text-left text-xs ">Address Line 01</th>
                  <th className="py-3 px-2 text-left text-xs ">Address Line 02</th>
                  <th className="py-3 px-2 text-left text-xs ">City</th>
                  <th className="py-3 px-2 text-left text-xs ">State</th>
                  <th className="py-3 px-2 text-left text-xs ">Pincode</th>
                  <th className="py-3 px-2 text-left text-xs ">GST State Code</th>
                  <th className="py-3 px-2 text-left text-xs ">Type</th>
                  <th className="py-3 px-2 text-left text-xs ">Active</th>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-light">
                {warehouse &&
                  warehouse.map((item: any) => {
                    return (
                      <tr
                        key={item.id}
                        className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
                      >
                        {/* <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Service_ID}
                          </span>
                        </td> */}
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Warehouse_Name}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">{item.Address_line_1}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">{item.Address_line_2}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">{item.City}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">{item.State}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">{item.Pincode}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">{item.GST_State_Code}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">{item.Type}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">
                          {showBadge(item.Active === true ? "True" : "False")}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <div className="lg:w-4/6">
          <div className=" shadow-md rounded-lg mt-6">
          <h6 className="mb-2 font-bold tracking-tight text-gray-600 sm:text-md">
          Currency Master 
        </h6>
            <table className="min-w-max w-full table-auto rounded-lg">
              <thead>
                <tr className="sticky top-0 px-2 py-3 text-gray-900 bg-purple-200">
                  {/* <th className="py-3 px-2 text-left text-xs ">Service ID</th> */}
                  <th className="py-3 px-2 text-left text-xs ">Curr Code</th>
                  <th className="py-3 px-2 text-left text-xs ">Currency Name</th>
                  <th className="py-3 px-2 text-left text-xs ">Decimal</th>
                  <th className="py-3 px-2 text-left text-xs ">Minimum Value</th>
                  <th className="py-3 px-2 text-left text-xs ">Active</th>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-light">
                {currency &&
                  currency.map((item: any) => {
                    return (
                      <tr
                        key={item.id}
                        className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
                      >
                        {/* <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Service_ID}
                          </span>
                        </td> */}
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Curr_Code}
                          </span>
                        </td>
                        <td className=" py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">{item.Curr_Name}</span>
                        </td>
                        <td className=" py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">{item.Decimal}</span>
                        </td>
                        <td className=" py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">
                            {item.Minimum_Value}
                          </span>
                        </td>
                        <td className=" py-3 px-2 text-left text-xs ">
                          {showBadge(item.Active)}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
      <div className="lg:w-4/6">
          <div className=" shadow-md rounded-lg mt-6">
          <h6 className="mb-2 font-bold tracking-tight text-gray-600 sm:text-md">
          Metal Master 
        </h6>
            <table className="w-full table-auto rounded-lg shadow-lg">
              <thead>
                <tr className="sticky top-0 px-2 py-3 text-gray-900 bg-purple-200">
                  {/* <th className="py-3 px-2 text-left text-xs ">Service ID</th> */}
                  <th className="py-3 px-2 text-left text-xs ">Metal code</th>
                  <th className="py-3 px-2 text-left text-xs ">Description</th>
                  <th className="py-3 px-2 text-left text-xs ">UOM</th>
                  <th className="py-3 px-2 text-left text-xs ">Minimum Quantity</th>
                  <th className="py-3 px-2 text-left text-xs ">Enabled</th>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-light">
                {metal &&
                  metal.map((item: any) => {
                    return (
                      <tr
                        key={item.id}
                        className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
                      >
                        {/* <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Service_ID}
                          </span>
                        </td> */}
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Metal_code}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">
                            {item.Description}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">{item.UOM}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">
                            {item.Minimum_Quantity}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">
                          {showBadge(item.Enabled === true ? "True" : "False")}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
      <div className="lg:w-4/6">
          <div className=" shadow-md rounded-lg mt-6">
          <h6 className="mb-2 font-bold text-gray-600 sm:text-md">
          Metal UOM Conversion Master 
        </h6>
            <table className="min-w-max w-full table-auto rounded-lg">
              <thead>
                <tr className="sticky top-0 px-2 py-3 text-gray-900 bg-purple-200">
                  {/* <th className="py-3 px-2 text-left text-xs ">Service ID</th> */}
                  <th className="py-3 px-2 text-left text-xs ">Conversion ID</th>
                  <th className="py-3 px-2 text-left text-xs ">UOM Category</th>
                  <th className="py-3 px-2 text-left text-xs ">UOM From</th>
                  <th className="py-3 px-2 text-left text-xs ">UOM To</th>
                  <th className="py-3 px-2 text-left text-xs ">Value</th>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-light">
                {metalUomCon &&
                  metalUomCon.map((item: any) => {
                    return (
                      <tr
                        key={item.id}
                        className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
                      >
                        {/* <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Service_ID}
                          </span>
                        </td> */}
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Conversion_ID}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">
                            {item.UOM_Category}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">{item.UOM_From}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">{item.UOM_To}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">{item.Value}</span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className="pb-10" />
          </div>
        </div>
      </div>

    
     
      {/* <div className="flex">
        <h6 className=" font-bold tracking-tight text-gray-600 sm:text-md">
          TAX/GST Master 
        </h6>
      </div>
      <div className="overflow-x-auto">
        <div className="w-full">
          <div className=" shadow-md rounded-lg my-6">
            <table className="min-w-max w-full table-auto rounded-lg">
              <thead>
                <tr className="sticky top-0 px-2 py-3 text-gray-900 bg-purple-200">
                  <th className="py-3 px-2 text-left text-xs ">Service ID</th>
                  <th className="py-3 px-2 text-left text-xs ">Item Tax</th>
                  <th className="py-3 px-2 text-left text-xs ">description</th>
                  <th className="py-3 px-2 text-left text-xs ">gst hsn code</th>
                  <th className="py-3 px-2 text-left text-xs ">Description</th>
                  <th className="py-3 px-2 text-left text-xs ">Rate</th>
                  <th className="py-3 px-2 text-left text-xs ">From</th>
                  <th className="py-3 px-2 text-left text-xs ">To</th>
                  <th className="py-3 px-2 text-left text-xs ">Type of Supply</th>
                  <th className="py-3 px-2 text-left text-xs ">Timestamp</th>
                  <th className="py-3 px-2 text-left text-xs ">user</th>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-light">
                {taxGst &&
                  taxGst.map((item: any) => {
                    return (
                      <tr
                        key={item.id}
                        className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
                      >
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Service_ID}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-semibold whitespace-normal break-all">{item.item_tax}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">
                            {item.description}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">
                            {item.gst_hsn_code}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">
                            {item.Description}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">{item.Rate}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">{item.From}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">{item.To}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">
                            {item.Type_of_Supply}
                          </span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">{item.Timestamp}</span>
                        </td>
                        <td className="py-3 px-2 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">{item.user}</span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}

    </Layout>
  );
};

const showBadge = (detail:string , type:string="true" ) => {
  return ( detail.toLowerCase() == type)
  ? <input type="checkbox" className="form-checkbox" checked readOnly/>
  : <input type="checkbox" className="form-checkbox" disabled/>
  }


export default MasterTable;
