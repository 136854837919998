import React, {useEffect, useState} from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { getName } from "./utils"
import "react-toastify/dist/ReactToastify.css";
import {useAuthStore} from "../../Store/AuthStore";
import HasAnyAccess from "../../Components-ui/hasAnyAccess";

interface PropsType {
    details: any;
    selectMetal: any;
    changeStatus: any;
}

const VsoReceipt: React.FC<PropsType> = ({ details, selectMetal, changeStatus }) => {
    const {tokens, warehouses, permissions} = useAuthStore();
    const [select, setSelect] = useState<boolean>(true);
    const [selectedRecords, setSelectRecords] = useState<any>([]);
    const [dataList, setDataList] = useState<any>([]);
    const [List, setList] = useState<any>([]);
    // const detailsData = (!selectMetal ? details : details?.filter((fil: any) => fil.metalCode === selectMetal));
    const roundDecimals = (amount: string, num?: number) => {
        return Number(amount).toFixed(num ? num : 4);
    }

    useEffect(() => {
        if(!selectMetal){
            setDataList(details);
            setList(details);
        }else{
            setDataList(!!details.length && details?.filter((fil: any) => fil.metalCode === selectMetal));
            setList(!!details.length && details?.filter((fil: any) => fil.metalCode === selectMetal));
        }
    }, [selectMetal,details]);

    const selectAll =(select:any)=>{
        if(dataList.length > 0) {
            if (select) {
                var arr: any[] = []
                setSelectRecords('')
                dataList.map((val: any, index: number) => {
                    val.selected = true
                    arr.push(val.id);
                });
                setSelectRecords(arr);
                changeStatus(arr)
            } else {
                dataList.map((val: any, index: number) => {
                    if (!selectedRecords.includes(val.id)) {
                        selectedRecords.push(val.id);
                        val.selected = true
                    } else {
                        selectedRecords.splice(selectedRecords.indexOf(val.id), 1);
                        val.selected = false
                    }
                });
                setSelectRecords(selectedRecords);
                changeStatus(selectedRecords)
            }
            setList(dataList)
        }
    }

    useEffect(() => {
        setSelect(true);
        setSelectRecords([]);
    }, [details]);

    useEffect(() => {
        setSelect(List.length > 0 ? List.length === selectedRecords.length : false);
    }, [List,selectedRecords]);

    useEffect(() => {
        selectAll(select);
    }, [dataList]);

    const changeData = (data:any,i:any)=>{
        let newArr = [...List];
        newArr[i].selected = !data[0].selected;
        setList(newArr);
        data.map((val: any, index: number) => {
            if(!selectedRecords.includes(val.id)){
                selectedRecords.push(val.id);
                val.selected = true
            }else{
                selectedRecords.splice(selectedRecords.indexOf(val.id), 1);
                val.selected = false
            }
        });
        setSelectRecords(selectedRecords);
        changeStatus(selectedRecords);
        setSelect(List.length === selectedRecords.length);
    }

    return (<div className="flex flex-col">
            <fieldset className="bg-gray-100 rounded mt-3">

                <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mt-1">Receipts</legend>
                <div className="bg-gray-100 rounded mb-5 p-5">


                    <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                        <thead>
                        <tr className="text-left border-b border-gray-300">
                            {
                                HasAnyAccess(['vso.calendar.receipting.process'])&& (
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                                        <input type="checkbox"  checked={select}
                                               onClick={() => {
                                                   selectAll(!select);
                                                   setSelect(!select);

                                               }}/>
                                    </th>)}
                            <th className="sticky top-0 z-10 bg-gray-800 py-3 px-6 text-center" style={{overflow: "hidden"}}>Customer ID</th>
                            <th className="sticky top-0 z-10 bg-gray-800 py-3 px-6 text-center" style={{overflow: "hidden"}}>GST Tax Invoice No.</th>
                            <th className="sticky top-0 z-10 bg-gray-800 py-3 px-6 text-center" style={{overflow: "hidden"}}>Invoice Date</th>
                            <th className="sticky top-0 z-10 bg-gray-800 py-3 px-6 text-center" style={{overflow: "hidden"}}>Metal</th>
                            <th className="sticky top-0 z-10 bg-gray-800 py-3 px-6 text-center" style={{overflow: "hidden"}}>Quantity</th>
                            <th className="sticky top-0 z-10 bg-gray-800 py-3 px-6 text-center" style={{overflow: "hidden"}}>Pay Out Ref</th>
                        </tr>
                        </thead>
                        <tbody className="text-gray-800 text-m font-dark">
                        {List.length > 0 ?
                            List
                                .map((val: any, index: any) => {


                                    return (
                                        <tr key={index} className="border-b border-gray-200 bg-white text-black">
                                            {
                                                HasAnyAccess(['vso.calendar.receipting.process']) && (
                                                    <td className="py-3 px-3 text-left relative">
                                                        <input type="checkbox" value={val.selected} checked={val.selected}
                                                               onClick={() => {
                                                                   changeData([val],index)
                                                               }}/>
                                                    </td>)}
                                            <td className="py-3 px-6 text-center" style={{overflow: "hidden"}}>{val.customerId}</td>
                                            <td className="py-3 px-6 text-center" style={{overflow: "hidden"}}> {val.gstTaxInvoice}</td>
                                            <td className="py-3 px-6 text-center" style={{overflow: "hidden"}}> {moment(val.invoiceDate).format("DD-MM-YYYY")}</td>
                                            <td className="py-3 px-6 text-center" style={{overflow: "hidden"}}> {getName(val.metalCode)}</td>
                                            <td className="py-3 px-6 text-center" style={{overflow: "hidden"}}> {roundDecimals(val.quantity)}</td>
                                            <td className="py-3 px-6 text-center" style={{overflow: "hidden"}}> {val.payoutReference}</td>
                                        </tr>
                                    );
                                }): (<tr
                                className="border-b border-gray-200 bg-white text-black">
                                <td className="py-3 px-3 text-center relative" colSpan={7}>
                                    No Data. Use the filter for get data.
                                </td>
                            </tr>)}

                        </tbody>
                    </table>
                </div>
            </fieldset>
        </div>
    );

}

export default VsoReceipt;
