import React, {useEffect, useState} from "react";
import Loader from "../../../Components-ui/Loader"
import {toast} from "react-toastify";
import TaxCategoryService from "../Services/tax-category.service";
import SimpleReactValidator from "simple-react-validator";
import {TYPES} from "../Utils/enum";
import {useAuthStore} from "../../../Store/AuthStore";

interface PropsType {
    onCloseModal: any,
    taxCategory: any,
    onChangeTaxCategory: any,
    validatorRef: any,
    getAllTaxCategories: any,
    page: number
}

const TaxCategoryForm: React.FC<PropsType> = ({
      onCloseModal,
      taxCategory,
      onChangeTaxCategory,
      validatorRef,
      getAllTaxCategories,
      page
  }) => {

    const { tokens } = useAuthStore();

    const [Loading, setLoading] = useState<any>(false);
    const [ValidatorStatus, setValidatorStatus] = useState<boolean>(false);

    const simpleValidator = new SimpleReactValidator({
        locale: "en",
        validators: {},
    });

    const validationRef = React.useRef(simpleValidator);
    const validator = validationRef.current;

    /**
     * Get validation error messages
     * @param nameOfStateProp
     */
    const getValidatorError = (nameOfStateProp: any) => {
        const allErrorMessages = validator.getErrorMessages();
        return allErrorMessages[nameOfStateProp];
    };

    useEffect(() => {
        if (taxCategory.hasOwnProperty('id')) {
            setValidatorStatus(true)
        }
    }, [taxCategory]);


    /**
     * Add or update tax category details
     * @param e
     */
    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (!validatorRef || !validator.allValid()) {
            setValidatorStatus(true)
            return toast.error("Please make sure all the inputs are valid.");
        }

        try {
            setLoading(true)
            if (taxCategory.hasOwnProperty('id')) {
                await TaxCategoryService.updateTaxCategory(taxCategory, tokens);
                await getAllTaxCategories(page);
                setLoading(false);
                onCloseModal()
                return toast.success("Tax Category was successfully updated");
            } else {
                await TaxCategoryService.addTaxCategory(taxCategory, tokens);
                await getAllTaxCategories(page);
                setLoading(false);
                onCloseModal()
                return toast.success("Tax Category was successfully added");
            }

        } catch (error: any) {
            console.log(error);
            setLoading(false);
            if (error && error.data && error.data.errors && error.data.errors.length > 0) {
                console.log(error.data.errors[0]);
                toast.error(error.data.errors[0].title);
            }
        }
    }

    return (
        <>
            {
                Loading ? <Loader/> : (
                    <div
                        className=" w-full bg-gray-800 bg-opacity-50 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-4xl">
                            <div
                                className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
                                <div
                                    className="items-start justify-between px-6 py-4 border-b border-solid border-blue bg-gray-100 rounded-t">
                                    <h3 className="text-xl font-semibold">{taxCategory.hasOwnProperty('id') ? 'Tax Category Details' : 'Add New Tax Category'}</h3>
                                </div>

                                <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="name"
                                               className="block text-sm font-medium text-gray-700">
                                            Name
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="name"
                                                name="name"
                                                autoComplete="off"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                value={taxCategory?.name ?? ""}
                                                onChange={(e: any) => {
                                                    onChangeTaxCategory(e)
                                                }}
                                            />
                                            {validator.message(
                                                "name",
                                                taxCategory?.name,
                                                "required"
                                            )}
                                            {
                                                ValidatorStatus ?
                                                    <span className="text-xs text-red-700"
                                                          id="name">{getValidatorError("name")}</span> : ""
                                            }

                                        </div>
                                    </div>
                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="categoryCode"
                                               className="block text-sm font-medium text-gray-700">
                                            Code
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                disabled={taxCategory.hasOwnProperty('id')}
                                                id="categoryCode"
                                                name="categoryCode"
                                                autoComplete="off"
                                                className={`${taxCategory.hasOwnProperty('id') ? "bg-gray-200" : "bg-white"}  mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                                                value={taxCategory?.categoryCode ?? ""}
                                                onChange={(e: any) => {
                                                    onChangeTaxCategory(e)
                                                }}
                                            />
                                            {validator.message(
                                                "categoryCode",
                                                taxCategory?.categoryCode,
                                                "required"
                                            )}
                                            {
                                                ValidatorStatus ?
                                                    <span className="text-xs text-red-700"
                                                          id="categoryCode">{getValidatorError("categoryCode")}</span> : ""
                                            }

                                        </div>
                                    </div>
                                    {/*<div className="col-span-12 sm:col-span-6">*/}
                                    {/*    <label htmlFor="type"*/}
                                    {/*           className="block text-sm font-medium text-gray-700">*/}
                                    {/*        Type*/}
                                    {/*    </label>*/}
                                    {/*    <div className="mt-1">*/}
                                    {/*        <select*/}
                                    {/*            id="type"*/}
                                    {/*            name="type"*/}
                                    {/*            autoComplete="off"*/}
                                    {/*            value={taxCategory?.type ?? ""}*/}
                                    {/*            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"*/}
                                    {/*            onChange={(e: any) => {*/}
                                    {/*                onChangeTaxCategory(e)*/}
                                    {/*            }}*/}
                                    {/*        >*/}
                                    {/*            <option value="" disabled>Select a type</option>*/}
                                    {/*            {*/}
                                    {/*                TYPES.map((type: any, index: number) => {*/}
                                    {/*                    return (*/}
                                    {/*                        <option key={index} value={type.value}>{type.label}</option>*/}
                                    {/*                    )*/}

                                    {/*                })*/}
                                    {/*            }*/}
                                    {/*        </select>*/}
                                    {/*        {validator.message(*/}
                                    {/*            "type",*/}
                                    {/*            taxCategory?.type,*/}
                                    {/*            "required"*/}
                                    {/*        )}*/}
                                    {/*        {*/}
                                    {/*            ValidatorStatus ?*/}
                                    {/*                <span className="text-xs text-red-700"*/}
                                    {/*                                    id="type">{getValidatorError("type")}</span> : ""*/}
                                    {/*        }*/}

                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-span-12 sm:col-span-6">*/}
                                    {/*    <label htmlFor="options"*/}
                                    {/*           className="block text-sm font-medium text-gray-700">*/}
                                    {/*        Options*/}
                                    {/*    </label>*/}
                                    {/*    <div className="mt-1">*/}
                                    {/*        <input*/}
                                    {/*            id="options"*/}
                                    {/*            name="options"*/}
                                    {/*            autoComplete="off"*/}
                                    {/*            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"*/}
                                    {/*            value={taxCategory?.options ?? ""}*/}
                                    {/*            onChange={(e: any) => {*/}
                                    {/*                onChangeTaxCategory(e)*/}
                                    {/*            }}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>

                                <div
                                    className="flex items-center justify-end sm:py-3  sm:px-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <div>
                                        <button
                                            id="modal"
                                            className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={onCloseModal}
                                        >
                                            Close
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                                            type="button"
                                            onClick={(e) => handleSubmit(e)}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default TaxCategoryForm;
