import React, {useState, useEffect} from "react";
import {useAuthStore} from "../../../Store/AuthStore";
import Layout from "../../Layout/Layout";
import {useHistory, useParams} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import UserListService from "../Services/UserList.Service";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import MultipleSelect from "../../../Components-ui/MultipleSelect";

export const CreateUser: React.FC = () => {
    const [Loading, setLoading] = useState<any>(true);
    const [rolesList, setRolesList] = useState<any>([]);
    const [selectedRole, setSelectedRoles] = useState<any>([]);
    const [array, setArray] = useState<any>([]);
    const {tokens} = useAuthStore();
    const [countryCode, setCountryCode] = useState('+91');
    let history = useHistory();
    const [user, setUser] = useState<any>({
        name: "",
        email: "",
        mobileNo: "",
        role: [],
    });
    const [validatorStatus, setValidator] = useState<boolean>(false);
    const simpleValidator = new SimpleReactValidator({
        locale: "en",
        validators: {},
    });
    const validationRef = React.useRef(simpleValidator);
    const validator = validationRef.current;
    

    useEffect(() => {
        setLoading(false);
        getUserRoles()
    }, []);

    const getUserRoles = async () => {
        await UserListService.getAllRoles(tokens).then((response: any) => {
            setRolesList(response.data.data);
        }).catch(err => {
            // toast.error(err.response.data.errors[0].title);
        });
    }

    const addNew = async () => {

       setValidator(true);
        var data =
            {
                name: user.name || '',
                email: user.email || '',
                mobile: countryCode+user.mobile || '',
                role: array || []
            }

        if (validator.allValid()) {
            setLoading(true);
            await UserListService.createUser(tokens, data).then(() => {
                toast.success("User Added Successfully");
                setUser({
                    name: "",
                    email: "",
                    mobile: "",
                    role: [],
                });
                setSelectedRoles([]);
                setArray([]);
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                //setModalStatus(false);
                console.log(err);
                
                toast.error(err.data.errors[0].detail);
            });
            setValidator(false);
        }
    }

    const getValidatorError = (nameOfStateProp: any) => {
        const allErrorMessages = validator.getErrorMessages();
        return allErrorMessages[nameOfStateProp];
    };

    const handleChangeItem = (selected: any) => {
        var arr: any[] = []
        setSelectedRoles(selected)
        selected.map((role:any) =>{
            arr.push(role.id);
            return setArray(arr)
        });
    };

    return <Layout type={"UserModule"}>
        <ToastContainer/>
        <div>
            <button
                onClick={() => {
                    history.goBack()
                }}
                className="-mr-2 flex items-center rounded text-white font-bold text-xs uppercase"
            >
                <svg className="w-7" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd"
                     clip-rule="evenodd">
                    <path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z"/>
                </svg>
                {" "}

            </button>
        </div>
        <div className="flex justi0fy-between items-center mb-5">
            <h1 className="text-2xl font-bold tracking-tight">
                {"Create New User"}
            </h1>
        </div>
        {Loading ? (<Loader/>) : (
            <div className="flex flex-row">
                <div className="border bg-white rounded border-gray-200 shadow">
                    <div className="flex-1 p-2">
                            <div className="w-full">
                                <div className="flex flex-wrap -mx-3 mb-2">
                                    <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                        <label
                                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="user-id">
                                            Name
                                        </label>
                                        <input
                                            className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"

                                            name="user-id" id="user-id" type="text" placeholder="Name"
                                            autoComplete="none"
                                            onChange={(event) => setUser({
                                                ...user,
                                                name: event.target.value
                                            })} value={user.name} />
                                            {validator.message(
                                                "name",
                                                user.name,
                                                "required"
                                            )}
                                            {
                                                validatorStatus ? (<span className="text-xs text-red-700"
                                                                        id="name">{getValidatorError("name")}</span>) : ''
                                            }
                                    </div>
                                    <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                        <label
                                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="email">
                                            Email
                                        </label>
                                        <input
                                            className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                                            id="email" type="text" placeholder="Email" autoComplete="none"
                                            onChange={(event) => setUser({
                                                ...user,
                                                email: event.target.value
                                            })} value={user.email} />

                                            {validator.message(
                                                "email",
                                                user.email,
                                                "required|email"
                                            )}
                                            {
                                                validatorStatus ? (<span className="text-xs text-red-700"
                                                                        id="email">{getValidatorError("email")}</span>) : ''
                                            }
                                    </div>
                                    <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                        <label
                                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="email">
                                            Mobile No
                                        </label>
                                        {/* <div className="input-group mb-3">
                                            <div className="input-group-text" id="basic-addon1">
                                            <select className="form-control country-select" 
                                            onChange={(event) => setCountryCode(event.target.value
                                            )} value={countryCode}
                                            >
                                                <option value={'+91'}>+91</option>
                                                <option value={'+94'}>+94</option>
                                            </select>
                                            </div>
                                            <input
                                                className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                                                id="mobile" name="mobile" type="number" placeholder="Mobile Number" autoComplete="none"
                                                onChange={(event) => setUser({
                                                    ...user,
                                                    mobile: event.target.value
                                                })} value={user.mobile} />
                                            </div> */}
                                            <div className="flex flex-wrap items-stretch w-full relative">
                                                <div className="flex -mr-px">
                                                <select className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark form-control country-select" 
                                                    onChange={(event) => setCountryCode(event.target.value
                                                    )} value={countryCode}
                                                    >
                                                        <option value={'+91'}>+91</option>
                                                        <option value={'+94'}>+94</option>
                                                    </select>
                                                </div>	
                                                <input
                                                    className="flex-shrink flex-grow flex-auto  w-100 h-10  leading-normal w-px flex-1 bbg-grey-lighter text-grey-darker border border-grey-lighter rounded rounded-l-none px-3 relative focus:border-blue focus:shadow"
                                                    id="mobile" name="mobile" type="number" placeholder="Mobile Number" autoComplete="none"
                                                    onChange={(event) => setUser({
                                                        ...user,
                                                        mobile: event.target.value
                                                    })} value={user.mobile} />
                                            </div>
                                       

                                            {validator.message(
                                                "mobile",
                                                user.mobile,
                                                "required|number"
                                            )}
                                            {
                                                validatorStatus ? (<span className="text-xs text-red-700"
                                                                        id="mobile">{getValidatorError("mobile")}</span>) : ''
                                            }
                                    </div>
                                    <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                        <label
                                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="email">
                                            User Role
                                        </label>
                                        <div className="mt-1">
                                            <MultipleSelect
                                                optionList={rolesList}
                                                isSearchable={false}
                                                onChangeItem={handleChangeItem}
                                                selectedValue={selectedRole}
                                                closeMenuOnSelect={false}
                                                getOptionLabel={(option:any) => `${option.name}`}
                                                getOptionValue={(option:any) => `${option.id}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0"></div>
                                    <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0"></div>
                                    <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                        <div
                            className="flex items-center justify-end sm:py-3  sm:px-6 border-solid border-blueGray-200 rounded-b">
                                            <div>
                                                <button
                                                    id="modal"
                                                    className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                    type="button"
                                                    onClick={() => history.goBack()}
                                                >
                                                    Close
                                                </button>
                                            </div>
                                            <div>
                                                <button
                                                className={`text-white background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md bg-purple-950`}
                                                    type="button"
                                                    onClick={() => {
                                                        addNew();
                                                    }}>
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
        )}
    </Layout>
}
export default CreateUser;
