import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import React from "react";
import Layout from "../../Layout/Layout";
import SipPromoCodeForm from "./SipPromoCodeForm";

interface PropsType {}

const EditSipPromoCode: React.FC<PropsType> = () => {
  let { id } = useParams<any>();

  return (
    <>
      <Layout type={"SIP"}>
        <ToastContainer />
        <div className="flex justify-between items-center mb-5">
          <SipPromoCodeForm isEdit={true} id={id} />
        </div>
      </Layout>
    </>
  );
};

export default EditSipPromoCode;
