import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import BulkUploadService from "./BulUploadService";
import { useAuthStore } from "../../Store/AuthStore";
import Loader from "../../Components-ui/Loader";
import SimpleReactValidator from "simple-react-validator";
import Service from "../CustomerGroup/CustomerGroup.Service";
import { ToastContainer, toast } from "react-toastify";

interface PropsType {
  onCloseModal: any;
}

const BulkUploadModal: React.FC<PropsType> = ({ onCloseModal }) => {
  const [Loading, setLoading] = useState<any>(false);

  const [comName, setComName] = useState("");
  const [value, setValue] = useState("");
  const [typeOfValue, setTypeOfValue] = useState("");
  const [customerGroup, setCustomerGroup] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [typeOfMetal, setTypeOfMetal] = useState("");
  const [file, setFile] = useState("");

  const [validatorStatus, setValidator] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState("");

  const { logout, permissions, userData, tokens } = useAuthStore();

  const simpleValidator = new SimpleReactValidator({
    locale: "en",
    validators: {},
  });
  const validationRef = React.useRef(simpleValidator);
  const validator = validationRef.current;

  // const downloadSample = async () => {
  //   setLoading(true);
  //   await BulkUploadService.downloadBulkSample(tokens)
  //     .then((response: any) => {
  //       const href = window.URL.createObjectURL(
  //         new Blob([response.data.data.payload.data])
  //       );
  //       const link = document.createElement("a");
  //       link.href = href;
  //       link.setAttribute("download", "sample-data.xlsx"); //or any other extension
  //       document.body.appendChild(link);
  //       link.click();
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //     });
  // };

  const changeHandler = (event: any) => {
    let selectedFile = event.target.files[0];
    setFileToUpload(selectedFile);
    let reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onloadend = (e: any) => {
      setFile(e.target.result);
    };
  };
  const fetchData = async () => {
    setLoading(true);
    await Service.getAllGroupData(tokens)
      .then((response: any) => {
        console.log("groups", response.data);
        setGroupList(response.data.data);
        // setMetaData({
        //   current: response.data.meta.page,
        //   pages: response.data.meta.totalPages,
        // });
        // setCurrentPage(response.data.meta.page);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const submitForm = async () => {
    setValidator(true);

    if (validator.allValid()) {
      let formData = new FormData();

      formData.append("metal", typeOfMetal);
      formData.append("metalType", typeOfValue);
      formData.append("customerGroup", customerGroup);
      formData.append("metalVal", value);
      formData.append("companyCode", comName);
      formData.append("document", fileToUpload);

      setValidator(true);

      await BulkUploadService.uploadData(tokens, formData)
        .then((response: any) => {
          setLoading(false);
          toast.success(response.data.data.payload.data.msg);
          onCloseModal();
          window.location.href = window.location.href;
        })
        .catch((err) => {
          toast.error("something went wrong while uploading this file");
          setLoading(false);
          onCloseModal();
        });
    } else {
      validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      // forceUpdate();
    }
  };

  const getValidatorError = (nameOfStateProp: any) => {
    const allErrorMessages = validator.getErrorMessages();
    return allErrorMessages[nameOfStateProp];
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {Loading ? (
        <Loader />
      ) : (
        <div className=" w-full bg-gray-800 bg-opacity-50 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className=" w-full bg-gray-800 bg-opacity-50 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-4xl">
              <div className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
                <div className="items-start justify-between px-6 py-4 border-b border-solid border-blue bg-gray-100 rounded-t">
                  <div className="flex justify-between items-center mb-5">
                    <h1 className="text-2xl font-bold tracking-tight">
                      {"Bulk Upload"}
                    </h1>
                    {/* <button
              onClick={() => downloadSample()}
              className="text-white  bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
              type="button"
            >
              Download sample
            </button> */}
                  </div>

                  <div className="flex flex-row">
                    <div className="border bg-white rounded border-gray-200 shadow">
                      <div className="flex-1 p-2">
                        <fieldset className=" rounded p-1 border-gray-300 pb-4 flex mt-3">
                          {/* <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
                                {"Details"}
                            </legend> */}
                          <div className="w-full">
                            <div className="flex flex-wrap -mx-3 mb-2">
                              <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label
                                  className="block text-sm font-medium text-gray-700"
                                  htmlFor="user-id"
                                >
                                  Name of the company
                                </label>
                                <input
                                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  name="user-id"
                                  id="user-id"
                                  type="text"
                                  placeholder="Name of the company"
                                  autoComplete="none"
                                  value={comName}
                                  onChange={(e) => setComName(e.target.value)}
                                  maxLength={30}
                                />
                                <span
                                  className="text-xs text-red-700"
                                  id="name"
                                >
                                  {validator.message(
                                    "Value",
                                    comName,
                                    "required"
                                  )}
                                </span>
                                {/* {validatorStatus ? (
                          <span className="text-xs text-red-700" id="name">
                            {getValidatorError("title")}
                          </span>
                        ) : (
                          ""
                        )} */}
                              </div>

                              <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label
                                  className="block text-sm font-medium text-gray-700"
                                  htmlFor="email"
                                >
                                  Select type
                                </label>
                                <select
                                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  id="grid-state"
                                  value={typeOfValue}
                                  onChange={(e) =>
                                    setTypeOfValue(e.target.value)
                                  }
                                >
                                  <option disabled value="">
                                    Select type
                                  </option>
                                  {/* <option value="quantity">Quantity</option> */}
                                  <option value="amount">Amount</option>
                                </select>
                                <span
                                  className="text-xs text-red-700"
                                  id="name"
                                >
                                  {validator.message(
                                    "type",
                                    typeOfValue,
                                    "required"
                                  )}
                                </span>
                              </div>
                              <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label
                                  className="block text-sm font-medium text-gray-700"
                                  htmlFor="email"
                                >
                                  Select Customer Group
                                </label>
                                <select
                                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  id="grid-state"
                                  value={customerGroup}
                                  onChange={(e) =>
                                    setCustomerGroup(e.target.value)
                                  }
                                >
                                  <option disabled value="">
                                    Select Customer Group
                                  </option>
                                  {/* <option value="quantity">Quantity</option> */}
                                  {groupList.length > 0 &&
                                    groupList?.map((customer: any) => (
                                      <option
                                        key={customer?.id}
                                        value={customer?.id}
                                      >
                                        {customer?.name}
                                      </option>
                                    ))}
                                </select>
                                <span
                                  className="text-xs text-red-700"
                                  id="name"
                                >
                                  {validator.message(
                                    "type",
                                    typeOfValue,
                                    "required"
                                  )}
                                </span>
                              </div>
                              <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label
                                  className="block text-sm font-medium text-gray-700"
                                  htmlFor="mobile"
                                >
                                  Value
                                </label>
                                <input
                                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  id="mobile"
                                  type="text"
                                  placeholder="Value"
                                  autoComplete="none"
                                  value={value}
                                  onChange={(e) => setValue(e.target.value)}
                                />
                                <span
                                  className="text-xs text-red-700"
                                  id="name"
                                >
                                  {validator.message(
                                    "value",
                                    value,
                                    "required|numeric|min:0,num|numeric|max:5000000,num"
                                  )}
                                </span>
                              </div>
                              <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label
                                  className="block text-sm font-medium text-gray-700"
                                  htmlFor="user-type"
                                >
                                  Metal type
                                </label>
                                <div className="relative">
                                  <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="grid-state"
                                    value={typeOfMetal}
                                    onChange={(e) =>
                                      setTypeOfMetal(e.target.value)
                                    }
                                  >
                                    <option disabled value="">
                                      Select Metal
                                    </option>
                                    <option value="Silver">Silver</option>
                                    <option value="Gold">Gold</option>
                                    <option value="Palladium">Palladium</option>
                                    <option value="Platinum">Platinum</option>
                                  </select>
                                  <span
                                    className="text-xs text-red-700"
                                    id="name"
                                  >
                                    {validator.message(
                                      "Metal type",
                                      typeOfMetal,
                                      "required"
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label
                                  className="block text-sm font-medium text-gray-700"
                                  htmlFor="user-type"
                                >
                                  Upload file
                                </label>
                                <div className="relative">
                                  <input
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="mobile"
                                    type="file"
                                    accept=".xls,.xlsx"
                                    // value={file}
                                    onChange={(event) => {
                                      changeHandler(event);
                                    }}
                                    // autoComplete="none"
                                    // value={"userDetails.userType"}
                                    // disabled
                                  />

                                  <span
                                    className="text-xs text-red-700"
                                    id="name"
                                  >
                                    {validator.message(
                                      "Document",
                                      file,
                                      "required"
                                    )}
                                  </span>
                                  <li
                                    style={{ color: "#4fb645" }}
                                    className="text-xs"
                                  >
                                    File size should not be greater than 15 MB
                                  </li>
                                  <li
                                    style={{ color: "#4fb645" }}
                                    className="text-xs"
                                  >
                                    Only xlsx file is allowed to upload
                                  </li>
                                  {/* <li
                                    style={{ color: "#0000EE" }}
                                    className="text-xs"
                                  >
                                    {" "}
                                    <a
                                      href="#"
                                      onClick={() => downloadSample()}
                                    >
                                      Download Sample
                                    </a>
                                  </li> */}
                                </div>
                              </div>
                              {/* <div className="pb-3 w-full px-3 mb-6 md:mb-0">
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="partner"
                    >
                      Upload file
                    </label>
                    <div className="relative">
                      <input
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        id="mobile"
                        type="file"
                        // autoComplete="none"
                        // value={"userDetails.partner?.name"}
                        // disabled
                      />
                    </div>
                  </div> */}
                            </div>
                          </div>
                        </fieldset>

                        {/* <div className="flex justify-center  items-center mb-5">
                          <button
                            onClick={() => submitForm()}
                            className="text-white  bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                            type="button"
                          >
                            Submit
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-end sm:py-3  sm:px-6 border-t border-solid border-blueGray-200 rounded-b">
                    <div>
                      <button
                        id="modal"
                        className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                        type="button"
                        onClick={onCloseModal}
                      >
                        Close
                      </button>
                    </div>
                    <div>
                      <button
                        className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                        type="button"
                        onClick={(e) => submitForm()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BulkUploadModal;
