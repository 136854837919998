import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Layout from "../Layout/Layout";
import BulkUploadService from "./BulUploadService";
import { useAuthStore } from "../../Store/AuthStore";
import Loader from "../../Components-ui/Loader";
import SimpleReactValidator from "simple-react-validator";
import BulkUploadModal from "./BulkUploadModal";
import Datetime from "react-datetime";
import { makeQuery } from "../../Common/util.helper";
import moment from "moment";
import { Pagination } from "../../Components-ui/Pagination";
import HasAnyAccess from "../../Components-ui/hasAnyAccess";
import { toast } from "react-toastify";

const BulkUpload: React.FC = () => {
  const history = useHistory();

  const [Loading, setLoading] = useState<any>(false);
  const [comName, setComName] = useState("");
  const [value, setValue] = useState("");
  const [typeOfValue, setTypeOfValue] = useState("");
  const [typeOfMetal, setTypeOfMetal] = useState("");
  const [file, setFile] = useState("");
  const [validatorStatus, setValidator] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState("");
  const [OpenModal, setOpenModal] = useState(false);
  const [StartDate, setStrtDate] = useState<Date>();

  //-----Get bulk users
  const [users, setUsers] = useState<any>({});
  const [MetaData, setMetaData] = useState<any>({});
  const [CurrentPage, setCurrentPage] = useState<number>(0);
  const [Filter, setFilter] = useState<any>({
    companyCode: "",
    metal: "",
    metalType: "",
    startDate: "",
    endDate: "",
  });

  const { logout, permissions, userData, tokens } = useAuthStore();

  const simpleValidator = new SimpleReactValidator({
    locale: "en",
    validators: {},
  });
  const validationRef = React.useRef(simpleValidator);
  const validator = validationRef.current;

  const closeModal = () => {
    setOpenModal(false);
  };
  const handleSubmit = async (page: number, isReset: boolean = false) => {
    let cloneFilterData: any;
    setLoading(true);

    cloneFilterData = !isReset
      ? { ...Filter }
      : {
          companyCode: "",
          metal: "",
          metalType: "",
          startDate: "",
          endDate: "",
        };

    // if (cloneFilterData.orderBy !== "") {
    //   let orderByValues = cloneFilterData.orderBy.split("/");
    //   cloneFilterData.order = orderByValues[1];
    //   cloneFilterData.orderBy = orderByValues[0];
    // }
    try {
      const query = makeQuery(cloneFilterData);

      const data: any = await BulkUploadService.getBulkData(
        query,
        page,
        tokens
      );
      if (data.status === 200 && data.data.data) {
        setMetaData({
          current: data.data.meta.page,
          pages: data.data.meta.totalPages,
        });
        setCurrentPage(page);
        setUsers(data ? data.data.data.result : []);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Something Went Wrong");
      }
    } catch (err) {
      setLoading(false);
      toast.error("Something Went Wrong");
    }
    // console.log(data ? data.data.data.result : [])
  };
  /**
   * Handle filters change event
   * @param e
   */
  const handleChangeFilter = (e: any) => {
    const { name, value } = e.target;
    setFilter({
      ...Filter,
      [name]: value,
    });
  };
  /**
   * Reset filters to initial state
   * @param e
   */
  const resetData = async (e: any) => {
    e.preventDefault();
    setFilter({
      companyCode: "",
      metal: "",
      metalType: "",
      startDate: "",
      endDate: "",
    });

    setCurrentPage(0);
    await handleSubmit(0, true);
  };

  const downloadSample = async () => {
    setLoading(true);
    await BulkUploadService.downloadBulkSample(tokens)
      .then((response: any) => {
        // const href = window.URL.createObjectURL(
        //   new Blob([response.data.data.payload.data])
        // );
        // const link = document.createElement("a");
        // link.href = href;
        // link.setAttribute("download", "sample-data.xlsx"); //or any other extension
        // document.body.appendChild(link);
        // link.click();
        window.open(response.data.data.payload.data);
        setLoading(false);
        toast.success("Sample Downloaded Successfully");
      })
      .catch((err) => {
        setLoading(false);
        toast.success("Something Went Wrong");
      });
  };

  const viewFile = async (id: any) => {
    history.push(`/bulk-download/${id}`);
  };
  const downloadFile = async () => {
    console.log("download");
  };
  const today = new Date();
  const disableFutureStartDt = (current: any) => {
    return current.isBefore(today);
  };
  const disableFutureEndDt = (current: any) => {
    return current.isAfter(StartDate) && current.isBefore(today);
  };

  useEffect(() => {
    handleSubmit(0);
  }, []);

  return (
    <Layout type={"PaymentModule"}>
      {Loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-between items-center mb-5">
            <h1 className="text-2xl font-bold tracking-tight">
              {"User Upload"}
            </h1>

            <div>
              <button
                className="text-white  bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                type="button"
                onClick={() => downloadSample()}
              >
                Download Sample
              </button>
              <button
                className="text-white  bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                type="button"
                onClick={() => setOpenModal(true)}
              >
                upload user
              </button>
            </div>
          </div>
          <div className="border bg-white rounded border-gray-200 shadow">
            <div className="flex-1 p-2">
              <form
                onSubmit={(e) => {
                  handleSubmit(0);
                  e.preventDefault();
                }}
              >
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <div className="relative">
                      <label
                        className="block text-sm font-medium text-gray-700"
                        htmlFor="description"
                      >
                        Company code
                      </label>
                      <input
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        id="companyCode"
                        name="companyCode"
                        type="text"
                        placeholder="Company code"
                        maxLength={30}
                        onChange={(e: any) => {
                          handleChangeFilter(e);
                        }}
                        value={Filter.companyCode}
                        // onChange={(event) =>
                        //   setSelectedRecord({
                        //     ...selectedRecord,
                        //     description: event.target.value,
                        //   })
                        // }
                        // value={selectedRecord.description}
                      />
                    </div>
                  </div>
                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="description"
                    >
                      Metal type
                    </label>

                    <select
                      id="metalType"
                      name="metalType"
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      onChange={(e: any) => {
                        handleChangeFilter(e);
                      }}
                      value={Filter.metalType}
                    >
                      <option value="">All</option>
                      <option value="amount">Amount</option>
                      <option value="quantity">Quantity</option>
                    </select>
                  </div>
                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="grid-state"
                    >
                      Metal
                    </label>

                    <select
                      id="metal"
                      name="metal"
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      onChange={(e: any) => {
                        handleChangeFilter(e);
                      }}
                      value={Filter.metal}
                    >
                      <option value="">All</option>
                      <option value="Silver">Silver</option>
                      <option value="Platinum">Platinum</option>
                      <option value="Palladium">Palladium</option>
                      <option value="Gold">Gold</option>
                    </select>
                  </div>
                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="grid-state"
                    >
                      From date
                    </label>

                    <Datetime
                      timeFormat={""}
                      dateFormat={"DD/MM/YYYY"}
                      inputProps={{
                        readOnly: true,
                        placeholder: "pick date",
                        className: "focus:outline-none",
                      }}
                      className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4`}
                      onChange={(event: any) => {
                        setFilter({
                          ...Filter,
                          startDate: moment(
                            new Date(event).toISOString()
                          ).format("DD/MM/YYYY"),
                          endDate: moment(new Date(event).toISOString()).format(
                            "DD/MM/YYYY"
                          ),
                        });
                        setStrtDate(new Date(event));
                      }}
                      isValidDate={disableFutureStartDt}
                      value={moment(Filter.startDate)}
                      renderInput={(props) => {
                        return (
                          <input
                            {...props}
                            value={Filter.startDate ? Filter.startDate : ""}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="grid-state"
                    >
                      To date
                    </label>
                    <Datetime
                      timeFormat={""}
                      dateFormat={"DD/MM/YYYY"}
                      inputProps={{
                        readOnly: true,
                        placeholder: "pick date",
                        className: "focus:outline-none",
                      }}
                      isValidDate={disableFutureEndDt}
                      className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4`}
                      onChange={(event: any) =>
                        setFilter({
                          ...Filter,
                          endDate: moment(new Date(event).toISOString()).format(
                            "DD/MM/YYYY"
                          ),
                        })
                      }
                      value={moment(Filter.endDate)}
                      renderInput={(props) => {
                        return (
                          <input
                            {...props}
                            value={Filter.endDate ? Filter.endDate : ""}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="flex w-full justify-end px-3 mb-6 md:mb-0">
                    <div className="my-3">
                      <input
                        className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                        type="submit"
                        value="Filter"
                        onClick={() => handleSubmit(CurrentPage)}
                      />

                      <button
                        className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                        type="button"
                        onClick={(e) => resetData(e)}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="bg-white rounded border-gray-200 shadow m-2">
              {Loading ? (
                <Loader />
              ) : (
                <div>
                  <div className="overflow-x-auto">
                    <table className="whitespace-nowrap  rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs ">
                      <thead>
                        <tr className="text-left border-b border-gray-300">
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Created At (Date)
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Company code
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Total users
                          </th>
                          {/* <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Success users
                          </th> */}
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Metal
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Metal type
                          </th>
                          {HasAnyAccess([
                            "dashboard.statistics.view",
                            "user.upload.view",
                          ]) && (
                            <th className="sticky top-0 px-3 py-2 bg-gray-800">
                              Actions
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {users.length > 0 ? (
                          users.map((order: any, index: number) => {
                            return (
                              <tr
                                key={index}
                                className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                              >
                                <td className="py-3 px-3 text-left relative">
                                  {moment(order.createdAt).format("DD-MM-YYYY")}
                                </td>
                                <td className="py-3 px-3 text-left relative">
                                  {order.companyCode}
                                </td>

                                <td className="py-3 px-3 text-left relative">
                                  {order.userCount}
                                </td>
                                {/* <td className="py-3 px-3 text-left relative">
                                  {order.validUserCount}
                                </td> */}
                                <td className="py-3 px-3 text-left relative">
                                  {order.metal}
                                </td>
                                <td className="py-3 px-3 text-left relative">
                                  {order.metalType}
                                </td>
                                {HasAnyAccess([
                                  "dashboard.statistics.view",
                                  "user.upload.view",
                                ]) && (
                                  <td className="py-3 px-3 text-left relative">
                                    <button
                                      className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                      type="button"
                                      onClick={() =>
                                        viewFile(order.bulkUploadId)
                                      }
                                      // disabled={!order.isCompleted}
                                    >
                                      {"View"}
                                    </button>
                                  </td>
                                )}
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                            <td
                              colSpan={10}
                              className="text-center py-3 px-3 text-left relative"
                            >
                              No data to display
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    meta={MetaData}
                    handlePageChange={(number: number) => handleSubmit(number)}
                  />
                </div>
              )}
              {OpenModal ? <BulkUploadModal onCloseModal={closeModal} /> : null}
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default BulkUpload;
