import Http from "../../../library/http.library";
import { httpMethods } from "../../../library/enum";
import { makeQuery } from "../../../Common/util.helper";

export const getFundData = async (tokens: any) => {
  return await Http.setMethod(httpMethods.get)
    .setResponseType("application/json")
    .setUrl(`/admin/transaction/log?page=0&pageSize=10&isPaginated=true`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getApiLogsData = async (
  tokens: any,
  currentPage: any,
  transactionId: any
) => {
  return await Http.setMethod(httpMethods.get)
    .setResponseType("application/json")
    .setUrl(
      `/transaction/api-log/${
        transactionId ? transactionId.id : ""
      }?pageSize=10&page=${currentPage}`
    )
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getTransactionSingleRecord = async (
  tokens: any,
  transactionId: any
) => {
  return await Http.setMethod(httpMethods.get)
    .setResponseType("application/json")
    .setUrl(
      `/admin/transaction/log?id=${transactionId ? transactionId.id : ""}`
    )
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const filterData = async (tokens: any, data: any, currentPage: any) => {
  var query = {
    ...(data.settlementToTime
      ? { settlementToTime: data.settlementToTime }
      : {}),
    ...(data.settlementFromTime
      ? { settlementFromTime: data.settlementFromTime }
      : {}),
    ...(data.transactionToTime
      ? { transactionToTime: data.transactionToTime }
      : {}),
    ...(data.transactionFromTime
      ? { transactionFromTime: data.transactionFromTime }
      : {}),
    ...(data.settlementStatus ? { isSettled: data.settlementStatus } : {}),
    ...(data.sourceModule ? { sourceModule: data.sourceModule } : {}),
    ...(data.paymentStatus ? { paymentStatus: data.paymentStatus } : {}),
    ...(data.customerID ? { customerId: data.customerID } : {}),
    ...(data.transactionRefId
      ? { transactionRefId: data.transactionRefId }
      : {}),
    ...(data.providerTransactionId
      ? { providerTransactionId: data.providerTransactionId }
      : {}),
    ...(data.partner ? { partnerId: data.partner } : {}),
    ...(data.pge ? { provider: data.pge } : {}), //PGE
    // ...(data.entity ? {method: data.entity} : {}),//ENTITY
    ...(data.amount ? { amount: data.amount } : {}),
    ...(data.debitCredit ? { transactionType: data.debitCredit } : {}),
    ...(data.paymentMethod ? { method: data.paymentMethod } : {}),
    ...(data.status ? { status: data.status } : {}),
    ...(data.utr ? { utr: data.utr } : {}),
    ...(data.id ? { id: data.id } : {}),
    ...(data.transactionMasterId
      ? { transactionMasterId: data.transactionMasterId }
      : {}),
    ...(data.method ? { method: data.method } : {}),
    page: currentPage,
  };
  const params = makeQuery(query);
  return await Http.setMethod(httpMethods.get)
    .setResponseType("application/json")
    .setUrl(`/admin/transaction/log?&isPaginated=true&pageSize=10${params}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const reportDownload = async (tokens: any, data: any) => {
  var query = {
    ...(data.settlementToTime
      ? { settlementToTime: data.settlementToTime }
      : {}),
    ...(data.settlementFromTime
      ? { settlementFromTime: data.settlementFromTime }
      : {}),
    ...(data.transactionToTime
      ? { transactionToTime: data.transactionToTime }
      : {}),
    ...(data.transactionFromTime
      ? { transactionFromTime: data.transactionFromTime }
      : {}),
    ...(data.settlementStatus ? { isSettled: data.settlementStatus } : {}),
    ...(data.sourceModule ? { sourceModule: data.sourceModule } : {}),
    ...(data.paymentStatus ? { paymentStatus: data.paymentStatus } : {}),
    ...(data.customerID ? { customerId: data.customerID } : {}),
    ...(data.transactionRefId
      ? { transactionRefId: data.transactionRefId }
      : {}),
    ...(data.providerTransactionId
      ? { providerTransactionId: data.providerTransactionId }
      : {}),
    ...(data.partner ? { partnerId: data.partner } : {}),
    ...(data.pge ? { provider: data.pge } : {}), //PGE
    // ...(data.entity ? {method: data.entity} : {}),//ENTITY
    ...(data.amount ? { amount: data.amount } : {}),
    ...(data.debitCredit ? { transactionType: data.debitCredit } : {}),
    ...(data.paymentMethod ? { method: data.paymentMethod } : {}),
    ...(data.status ? { status: data.status } : {}),
    ...(data.utr ? { utr: data.utr } : {}),
    ...(data.id ? { id: data.id } : {}),
    ...(data.transactionMasterId
      ? { transactionMasterId: data.transactionMasterId }
      : {}),
    ...(data.method ? { method: data.method } : {}),
  };
  const params = makeQuery(query);
  return await Http.setMethod(httpMethods.get)
    .setResponseType("blob")
    .setUrl(`/transaction/log/download?${params}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const updateTxnStatus = async (tokens: any, data: any) => {
  return await Http.setMethod(httpMethods.post)
    .setData(data)
    .setUrl(`/customer/transaction/status`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

const FundManagementService = {
  getFundData,
  filterData,
  getApiLogsData,
  getTransactionSingleRecord,
  reportDownload,
  updateTxnStatus,
};

export default FundManagementService;
