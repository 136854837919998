import Layout from "../Layout/Layout";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { STATUS } from "../Order/Utils/enum";
import RefundService from "../ManualRefund/ManualRefundService";
import Loader from "../../Components-ui/Loader";
import { Pagination } from "../../Components-ui/Pagination";
import { useAuthStore } from "../../Store/AuthStore";
import { toast, ToastContainer } from "react-toastify";
import Modal from "../../Components-ui/Modal/Modal";
import HasAnyAccess from "../../Components-ui/hasAnyAccess";
import Datetime from "react-datetime";
import MultipleSelect from "../../Components-ui/MultipleSelect";

interface PropsType {}

const ManualRefundApprove: React.FC<PropsType> = () => {
  const status = [{
    id:'CREATED',
    status:'CREATED',
  },{
    id:'REJECTED',
    status:'REJECTED',
  },{
    id:'APPROVED',
    status:'APPROVED',
  },{
    id:'REFUND_REQUESTED',
    status:'REFUND_REQUESTED',
  }]
  const { logout, permissions, userData, tokens } = useAuthStore();
  const [Loading, setLoading] = useState<any>(false);
  const [Orders, setOrders] = useState<any>([]);
  const [selectedRecords, setSelectedRecords] = useState<any>([]);
  const [CurrentPage, setCurrentPage] = useState<number>(0);
  const [show, setModalStatus] = useState<any>(false);
  const [children, setChildren] = useState<any>();
  const [itemList, setItemList] = useState<any>([]);
  const [meta, setMetaData] = useState<any>({});
  const [Filter, setFilter] = useState<any>({
    orderRef: "",
    orderItemRef: "",
    status: [],
    fromDate: null,
    toDate: null

  });
  const [SelectedStatus, setSelectedStatus] = useState<any>([]);
  useEffect((): any => {
    fetchData(CurrentPage, {});
  }, []);

  useEffect(() => {
    childrenElements();
  }, [show]);

  useEffect(() => {
    childrenElements();
  }, [itemList]);

  const childrenElements = () => {
    setChildren(
      <div className="w-full">
        <h1 className="font-bold">{"Selected Items"}</h1>
        <form className="bg-white rounded pt-6 pb-2 mb-2">
          <div className="mb-4">
            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
              <thead>
                <tr className="text-left border-b border-gray-300">
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Item Code
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Requested Type
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Requested Value
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    UOM
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Order Ref
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {0 !== itemList.length ? (
                  itemList.map((partner: any, index: number) => {
                    return (
                      <tr
                        key={index}
                        className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                      >
                        <td className="py-3 px-3 text-left relative">
                          {partner.itemCode}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {partner.requestedType}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {partner.requestedValue}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {partner.uom}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {partner.ebOrderItemRef}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {partner.status}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                    <td
                      colSpan={6}
                      className="text-center py-3 px-3 text-left relative"
                    >
                      No data to display
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </form>
      </div>
    );
  };

  const fetchData = async (page: number, data: any): Promise<void> => {
    setLoading(true);
    return new Promise(async (resolve) => {
      RefundService.getRefundApprove(page, tokens, data)
        .then((response: any) => {
          setOrders(response.data.data.result);
          setMetaData({
            current: response.data.meta.page,
            pages: response.data.meta.totalPages,
          });
          resolve();
          setLoading(false);
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
          resolve();
          setLoading(false);
        });
    });
  };

  const changeStatus = async (data: any, key: any) => {
    let newArr = [...Orders];
    newArr[key].selected = !data.selected;
    setOrders(newArr);
    if (0 !== selectedRecords.length) {
      if (selectedRecords.some((item: any) => item.id === data.id)) {
        selectedRecords.forEach((element: any, index: number) => {
          if (data.id === element.id) {
            selectedRecords.splice(index, 1);
          }
          setSelectedRecords(selectedRecords);
        });
      } else {
        setSelectedRecords(selectedRecords.concat(data));
      }
    } else {
      setSelectedRecords(selectedRecords.concat(data));
    }
  };

  const approve = async (): Promise<void> => {
    if (0 !== selectedRecords.length) {
      setLoading(true)
      var arr: any[] = [];
      selectedRecords.map((data: any, index: number) => {
        arr.push(data.id);
      });
      return new Promise(async (resolve) => {
        var data = {
          approved: arr,
          rejected: [],
        };
        RefundService.refundApprove(tokens, data)
          .then((response: any) => {
            toast.success("Record will be approve");
            setSelectedRecords([]);
            fetchData(CurrentPage, Filter);
            setLoading(false);
            resolve();
          })
          .catch((err) => {
            console.log(err.data);
            setLoading(false);
            toast.error(err.data.errors[0].title);
            resolve();
          });
      });
    }
  };

  const cancelRefund = async (): Promise<void> => {
    if (0 !== selectedRecords.length) {
      setLoading(true)
      var arr: any[] = [];
      selectedRecords.map((data: any, index: number) => {
        arr.push(data.id);
      });
      return new Promise(async (resolve) => {
        var data = {
          approved: [],
          rejected: arr,
        };
        RefundService.refundApprove(tokens, data)
          .then((response: any) => {
            toast.success("Record has been rejected");
            setSelectedRecords([]);
            fetchData(CurrentPage, Filter);
            setLoading(false);
            resolve();
          })
          .catch((err) => {
            setLoading(false);
            toast.error("Something Went Wrong!");
            resolve();
          });
      });
    }
  };

  const hideModal = () => {
    setModalStatus(false);
  };

  const paginationFilter = async (page: number) => {
    setCurrentPage(page);
    await fetchData(page, Filter);
  };

  const handleChangeFilter = (e: any) => {
    const { name, value } = e.target;
    setFilter({
      ...Filter,
      [name]: value,
    });
  };

  const resetData = async (e: any) => {
    e.preventDefault();
    setFilter({
      orderRef: "",
      orderItemRef: "",
      status: [],
      fromDate: null,
      toDate: null
    });
    setCurrentPage(0);
    setSelectedStatus([]);
    fetchData(0, {});
  };

  const handleSubmit = async (page: number, isReset: boolean = false) => {
    setLoading(true);
    fetchData(0, Filter);
    // setLoading(false);
  };

  const handleChangeItem = (selected: any) => {
    setFilter({
      ...Filter,
      status: selected
    });
    setSelectedStatus(selected);

  };

  var valid = function (current: any) {
    return current.isAfter(moment(Filter.fromDate).subtract(1, 'day'));
  };

  return (
    <Layout type={"Refund"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">
          {"Approve"}
        </h1>
      </div>
      <div className="w-full rounded border-gray-200 overflow-hidden shadow-lg dark:border-gray-700 bg-white">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Filter Refund Orders
          </h3>
        </div>
        <hr />
        <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="ebOrderRef"
              className="block text-sm font-medium text-gray-700"
            >
              Order Ref
            </label>
            <input
              type="text"
              name="orderRef"
              id="ebOrderRef"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={Filter.orderRef}
            />
          </div>
          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="ebOrderRef"
              className="block text-sm font-medium text-gray-700"
            >
              Order Item Ref
            </label>
            <input
              type="text"
              name="orderItemRef"
              id="ebOrderRef"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={Filter.orderItemRef}
            />
          </div>

          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="qType"
              className="block text-sm font-medium text-gray-700"
            >
              Status
            </label>
            {/*<select*/}
            {/*  id="qType"*/}
            {/*  name="status"*/}
            {/*  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"*/}
            {/*  onChange={(e: any) => {*/}
            {/*    handleChangeFilter(e);*/}
            {/*  }}*/}
            {/*  value={Filter.status}*/}
            {/*>*/}
            {/*  <option value="">All</option>*/}
            {/*  <option value="CREATED">CREATED</option>*/}
            {/*  <option value="REFUND_REQUESTED">REFUND_REQUESTED</option>*/}
            {/*  <option value="REJECTED">REJECTED</option>*/}
            {/*  <option value="APPROVED">APPROVED</option>*/}
            {/*</select>*/}
            <MultipleSelect
                optionList={status}
                onChangeItem={handleChangeItem}
                selectedValue={SelectedStatus}
                closeMenuOnSelect={false}
                getOptionLabel={(option:any) => `${option.status}`}
                getOptionValue={(option:any) => `${option.id}`}
            />
          </div>
          <div className="col-span-12 sm:col-span-4">
            <label htmlFor="fromDate" className="block text-sm font-medium text-gray-700">
              From Date
            </label>

            <div>
              <Datetime
                  timeFormat={""}
                  dateFormat={"DD/MM/YYYY"}
                  inputProps={{readOnly: true,placeholder: 'pick date', className: "focus:outline-none"}}
                  className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4`}
                  onChange={(event: any) => setFilter({
                    ...Filter,
                    fromDate: moment(new Date(event).toISOString()).format("YYYY-MM-DD"),
                    toDate: ((event.isAfter(new Date(Filter.toDate)) ? moment(new Date(event).toISOString()).format("YYYY-MM-DD") : moment(Filter.toDate).format("YYYY-MM-DD"))),

                  })}
                  value={moment(Filter.fromDate)}
                  renderInput={(props) => {
                    return <input {...props} value={(Filter.fromDate) ? (Filter.fromDate) : ''}/>
                  }}
              />
            </div>
          </div>

          <div className="col-span-12 sm:col-span-4">
            <label htmlFor="toDate" className="block text-sm font-medium text-gray-700">
              To Date
            </label>

            <div>
              <Datetime
                  isValidDate={valid}
                  timeFormat={""}
                  dateFormat={"DD/MM/YYYY"}
                  inputProps={{readOnly: true,placeholder: 'pick date', className: "focus:outline-none"}}
                  className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4`}
                  onChange={(event: any) => setFilter({
                    ...Filter,
                    toDate: moment(new Date(event).toISOString()).format("YYYY-MM-DD"),
                  })}
                  value={moment(Filter.toDate)}
                  renderInput={(props) => {
                    return <input {...props} value={(Filter.toDate) ? (Filter.toDate) : ''}/>
                  }}
              />
            </div>
          </div>
          <div className="col-span-12 sm:col-span-12 flex justify-end">
            <button
                className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                type="button"
                onClick={() => handleSubmit(CurrentPage)}
            >
              Filter
            </button>
            <button
              className="ml-2 text-white border border-red-300 rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
              type="button"
              onClick={(e) => resetData(e)}
            >
              Reset
            </button>

          </div>
        </div>
        <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
          <div className="col-span-12 sm:col-span-12">
            {Loading ? (
              <Loader />
            ) : (
              <div className="border bg-white rounded border-gray-200 shadow">
                <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                  <thead>
                    <tr className="text-left border-b border-gray-300">
                      {HasAnyAccess([
                        "manual.refund.approve",
                        "manual.refund.cancel",
                      ]) && (
                        <th className="sticky top-0 px-3 py-2 bg-gray-800"></th>
                      )}
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Order Ref
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Order Item Ref
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Created At
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Main Item Code
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Status
                      </th>
                      {/*<th className="sticky top-0 px-3 py-2 bg-gray-800">Actions</th>*/}
                    </tr>
                  </thead>
                  <tbody>
                    {0 !== Orders.length ? (
                      Orders.map((order: any, index: number) => {
                        return (
                          <tr
                            key={index}
                            className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                          >
                            {HasAnyAccess([
                              "manual.refund.approve",
                              "manual.refund.cancel",
                            ]) && (
                              <td className="py-3 px-3 text-left relative">
                                <input
                                 disabled={order.status !== "CREATED"}
                                  type="checkbox"
                                  value={order.selected}
                                  checked={order.selected}
                                  onClick={() => {
                                    changeStatus(order, index);
                                  }}
                                />
                              </td>
                            )}
                            <td className="py-3 px-3 text-left relative">
                              {order.ebOrderRef}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {order.ebOrderItemRef}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {moment(order.createdAt).format(
                                "YYYY-MM-DD HH:mm"
                              )}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {order.mainItemCode}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {order?.status?.replaceAll('_',' ')}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                        <td
                          colSpan={11}
                          className="text-center py-3 px-3 text-left relative"
                        >
                          No data to display
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Pagination
                  meta={meta}
                  handlePageChange={(number: number) => {
                    paginationFilter(number);
                  }}
                />
              </div>
            )}
          </div>
          <div className="col-span-12 sm:col-span-12 flex justify-end">
            {HasAnyAccess(["manual.refund.approve"]) && (
              <button
                className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                type="button"
                onClick={approve}
              >
                Approve
              </button>
            )}
            {HasAnyAccess(["manual.refund.cancel"]) && (
              <button
                className="text-white border border-purple-300 rounded-md bg-red-600 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                type="button"
                onClick={cancelRefund}
              >
                Reject
              </button>
            )}
          </div>
        </div>
        <Modal
          size={"large"}
          show={show}
          handleClose={hideModal}
          children={children}
        />
      </div>
    </Layout>
  );
};

export default ManualRefundApprove;
