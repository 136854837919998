import Layout from "../../Layout/Layout";
import React, { useEffect, useState } from "react";
import moment from "moment";
import RoleService from "../Services/role.service";
import Loader from "../../../Components-ui/Loader";
import { toast, ToastContainer } from "react-toastify";
import RoleForm from "./RoleForm";
import { useAuthStore } from "../../../Store/AuthStore";
import { Pagination } from "../../../Components-ui/Pagination";
import PermissionService from "../../Permission/Services/permission.service";
import ConfirmationModal from "../../../Components-ui/ConfirmationModal";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";
import { makeQuery } from "../../../Common/util.helper";

interface PropsType {}

const RoleTable: React.FC<PropsType> = () => {
  const { logout, permissions, userData, tokens } = useAuthStore();

  const [Loading, setLoading] = useState<any>(false);
  const [Roles, setRoles] = useState<any>([]);
  const [MetaData, setMetaData] = useState<any>({});
  const [CurrentPage, setCurrentPage] = useState<number>(0);
  const [OpenModal, setOpenModal] = useState(false);
  const [ConfirmationOpenModal, setConfirmationOpenModal] = useState(false);
  const [SelectedId, setSelectedId] = useState<number>(0);
  const [Validator, setValidator] = useState(false);
  const [Permissions, setPermissions] = useState<any>([]);
  const [Role, setRole] = useState<any>({
    name: "",
    description: "",
    isSystem: false,
    active: "ENABLED",
  });

  const [RoleFilterData, setRoleFilterData] = useState<any>({
    name: "",
    description: "",
    isSystem: "",
    active: "",
  });

  useEffect((): any => {
    getAllRoles(CurrentPage);
    getAllPermissions(0);
    // eslint-disable-next-line
  }, []);

  /**
   * Validate permission data
   * @param e
   */
  const onChangeRole = (e: any) => {
    setRole({
      ...Role,
      [e.target.name]: e.target.value,
    });

    return setValidator(true);
  };

  /**
   * Get all permissions
   * @param page
   * @param isReset
   */
  const getAllRoles = async (page: number, isReset: boolean = false) => {
    setLoading(true);
    let cloneFilterData = !isReset
      ? { ...RoleFilterData }
      : {
          name: "",
          description: "",
          isSystem: "",
          active: "",
        };
    if (cloneFilterData.isSystem !== "") {
      cloneFilterData.isSystem = cloneFilterData.isSystem === "yes";
    }
    const query = makeQuery(cloneFilterData);
    let data: any = await RoleService.getAllRoles(page, tokens, true, query);
    if (data.status === 200 && data.data.data) {
      setCurrentPage(page);
      setMetaData({
        current: data.data.meta.page,
        pages: data.data.meta.totalPages,
      });
      setRoles(data ? data.data.data.result : []);
    }
    setLoading(false);
  };

  /**
   * Get all permissions
   * @param page
   */
  const getAllPermissions = async (page: number) => {
    let data: any = await PermissionService.getAllPermissions(
      page,
      tokens,
      false,
      "&active=ENABLED"
    );
    if (data.status === 200 && data.data.data) {
      console.log(data.data.data, "data");
      setPermissions(data.data.data);
    }
  };

  /**
   * Close modal
   */
  const closeModal = () => {
    setOpenModal(false);
  };

  /**
   * Open permission modal
   */
  const onAddRole = () => {
    setInitialState();
    setOpenModal(true);
  };

  /**
   * Get permission details
   * @param id
   */
  const getRole = (id: any) => {
    let permission: any = Roles.filter(
      (permission: any) => permission.id === id
    );
    if (permission.length > 0) {
      setRole(permission[0]);
      setValidator(true);
      setOpenModal(true);
    }
  };

  /**
   * Enable or disable permission
   * @param e
   * @param data
   */
  const enableDisableRole = async (e: any, data: any) => {
    setLoading(true);
    let cloneData = { ...data };

    cloneData.active = e.target.checked ? "ENABLED" : "DISABLED";
    await RoleService.updateRole(cloneData, tokens);
    await getAllRoles(CurrentPage);
    setLoading(false);
    return toast.success("Role was successfully updated");
  };

  /**
   * Set selected record id
   * @param id
   */
  const onRemoveRole = async (id: number) => {
    setSelectedId(id);
    setConfirmationOpenModal(true);
  };

  /**
   * Open or close confirmation modal
   * @param value
   */
  const setConfirmModal = (value: boolean) => {
    setConfirmationOpenModal(value);
  };

  /**
   * Handle filters change event
   * @param e
   */
  const handleChangeFilter = (e: any) => {
    const { name, value } = e.target;
    setRoleFilterData({
      ...RoleFilterData,
      [name]: value,
    });
  };

  /**
   * Remove role
   */
  const removeRole = async () => {
    try {
      setLoading(true);
      await RoleService.removeRole(SelectedId, tokens);
      await getAllRoles(CurrentPage);
      setLoading(false);
      return toast.success("Role was successfully removed");
    } catch (error: any) {
      setLoading(false);
      if (
        error &&
        error.data &&
        error.data.errors &&
        error.data.errors.length > 0
      ) {
        toast.error(error.data.errors[0].title);
      }
    }
  };

  /**
   * Reset filters to initial state
   * @param e
   */
  const resetFilterData = async (e: any) => {
    e.preventDefault();
    setRoleFilterData({
      name: "",
      description: "",
      isSystem: "",
      active: "",
    });

    setCurrentPage(0);
    await getAllRoles(0, true);
  };

  /**
   * Set permission details to initial state
   */
  const setInitialState = () => {
    setRole({
      name: "",
      description: "",
      isSystem: false,
      active: "ENABLED",
    });
  };

  return (
    <Layout type={"AuthorizationModule"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">{"Roles"}</h1>
        {HasAnyAccess(["role.add"]) && (
          <button
            className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
            type="button"
            onClick={onAddRole}
          >
            + Add Role
          </button>
        )}
      </div>

      <div className="w-full rounded border-gray-200 overflow-hidden shadow-lg dark:border-gray-700 bg-white">
        <form
          onSubmit={(e) => {
            getAllRoles(0);
            e.preventDefault();
          }}
        >
          <div className="px-4 pt-4 pb-4 sm:px-6 grid grid-cols-12 gap-5">
            <div className="col-span-12 sm:col-span-3">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={(e: any) => {
                  handleChangeFilter(e);
                }}
                value={RoleFilterData.name}
              />
            </div>
            <div className="col-span-12 sm:col-span-3">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Description
              </label>
              <input
                type="text"
                name="description"
                id="description"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={(e: any) => {
                  handleChangeFilter(e);
                }}
                value={RoleFilterData.description}
              />
            </div>
            <div className="col-span-12 sm:col-span-3">
              <label
                htmlFor="active"
                className="block text-sm font-medium text-gray-700"
              >
                Status
              </label>
              <select
                id="active"
                name="active"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={(e: any) => {
                  handleChangeFilter(e);
                }}
                value={RoleFilterData.active}
              >
                <option value="">All</option>
                <option value="ENABLED">ENABLED</option>
                <option value="DISABLED">DISABLED</option>
              </select>
            </div>
            <div className="col-span-12 sm:col-span-3">
              <label
                htmlFor="isSystem"
                className="block text-sm font-medium text-gray-700"
              >
                System Role
              </label>
              <select
                id="isSystem"
                name="isSystem"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={(e: any) => {
                  handleChangeFilter(e);
                }}
                value={RoleFilterData.isSystem}
              >
                <option value="">All</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="col-span-12 sm:col-span-12 flex justify-end">
              {/* <button
                            className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                            type="button"
                            onClick={() => getAllRoles(0)}
                        >
                            Filter
                        </button> */}
              <input
                className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                type="submit"
                value="Filter"
              />
              <button
                className="text-white bg-red-600 border border-red-300 rounded-md background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                type="button"
                onClick={(e) => resetFilterData(e)}
              >
                Reset
              </button>
            </div>
            <div className="col-span-12 sm:col-span-12">
              {" "}
              <hr />
            </div>
            <div className="col-span-12 sm:col-span-12">
              {Loading ? (
                <Loader />
              ) : (
                <div className="border bg-white rounded border-gray-200 shadow">
                  <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                    <thead>
                      <tr className="text-left border-b border-gray-300">
                        <th className="sticky top-0 px-3 py-2 bg-gray-800">
                          Name
                        </th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800">
                          Description
                        </th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800">
                          System Role
                        </th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800">
                          Date
                        </th>
                        {HasAnyAccess(["role.edit"]) && (
                          <th className="sticky top-0 px-3 py-2 bg-gray-800">
                            Status
                          </th>
                        )}
                        {HasAnyAccess(["role.edit", "role.remove"]) && (
                          <th className="sticky top-0 px-3 py-2 bg-gray-800">
                            Actions
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {Roles.length > 0 ? (
                        Roles.map((role: any, index: number) => {
                          return (
                            <tr
                              key={index}
                              className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                            >
                              <td className="py-3 px-3 text-left relative">
                                {role.name}
                              </td>
                              <td className="py-3 px-3 text-left relative">
                                {role.description}
                              </td>
                              <td className="py-3 px-3 text-left relative">
                                {role.isSystem ? "True" : "False"}
                              </td>
                              <td className="py-3 px-3 text-left relative">
                                {moment(role.createdAt).format(
                                  "DD-MM-YYYY HH:mm"
                                )}
                              </td>
                              <td className="py-3 px-3 text-left relative">
                                {HasAnyAccess(["role.edit"]) && (
                                  <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                    <input
                                      type="checkbox"
                                      name="toggle"
                                      id={`${role.id}table-toggle`}
                                      checked={role.active === "ENABLED"}
                                      className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                                      onChange={(e) =>
                                        enableDisableRole(e, role)
                                      }
                                    />
                                    <label
                                      htmlFor={`${role.id}table-toggle`}
                                      className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                                    />
                                  </div>
                                )}
                              </td>
                              {HasAnyAccess(["role.edit", "role.remove"]) && (
                                <td className="py-3 px-3 text-left relative">
                                  {HasAnyAccess(["role.edit"]) && (
                                    <button
                                      className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                      type="button"
                                      onClick={() => getRole(role.id)}
                                    >
                                      Edit
                                    </button>
                                  )}
                                  {HasAnyAccess(["role.remove"]) &&
                                    !role.isSystem && (
                                      <button
                                        className="text-white bg-red-600 border border-red-300 rounded-md background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => onRemoveRole(role.id)}
                                      >
                                        Remove
                                      </button>
                                    )}
                                </td>
                              )}
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                          <td
                            colSpan={6}
                            className="py-3 px-3 text-left relative text-center"
                          >
                            No data available.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </form>

        <div className="col-span-12 sm:col-span-12 px-4 pt-2">
          {/*Pagination Bar*/}
          {!Loading && (
            <Pagination
              meta={MetaData}
              handlePageChange={(number: number) => getAllRoles(number)}
            />
          )}
        </div>
      </div>
      {OpenModal && (
        <RoleForm
          onCloseModal={closeModal}
          role={Role}
          onChangeRole={onChangeRole}
          getAllRoles={getAllRoles}
          validatorRef={Validator}
          page={CurrentPage}
          permissions={Permissions}
        />
      )}

      <ConfirmationModal
        isOpen={ConfirmationOpenModal}
        onSubmit={removeRole}
        setOpenModel={(value: boolean) => setConfirmModal(value)}
      />
    </Layout>
  );
};

export default RoleTable;
