/**
 * DATA TYPES
 */
export const DATA_TYPE = Object.freeze({
    NORMAL: "NORMAL",
    START_DATE: "START_DATE",
    END_DATE: "END_DATE",
});

/**
 * APPLY RATES
 */
export const APPLY_RATES = [
    "ACTUAL",
    "PREVIOUS_AMOUNT",
    "NET_TOTAL",
    "CUM_PREVIOUS_AMOUNT",
    "CUM_NET_TOTAL"
]

/**
 * APPLY TYPES
 */
export const APPLY_TYPES = [
    {value: "AMOUNT", label: "AMOUNT"},
    {value: "RULE", label: "RULE"},
    {value: "RATE", label: "RATE"},
    {value: "UNIT_AMOUNT", label: "UNIT AMOUNT"},
]

/**
 * MAIN RULE TYPES
 */
export const MAIN_RULE_TYPES = [
    "SELL",
    "BUYBACK"
]

/**
 * CURRENCY
 */
export const CURRENCY = [
    "INR",
    "USD"
]

