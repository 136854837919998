import Layout from "../../Layout/Layout";
import { useHistory } from "react-router-dom";
import DiscountService from "../Services/sip.service";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Loader from "../../../Components-ui/Loader";
import { toast, ToastContainer } from "react-toastify";
import { useAuthStore } from "../../../Store/AuthStore";
import { Pagination } from "../../../Components-ui/Pagination";
import { makeQuery } from "../../../Common/util.helper";
// import { ISSUE_TYPES } from "../Utils/enum";
import TradeExecutionService from "../../TradeExecution/Services/trade-execution.service";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";
import Datetime from "react-datetime";
import { SIP_ISSUE_TYPES } from "../../Discounts/Utils/enum";

interface PropsType {}

const SipTable: React.FC<PropsType> = () => {
  const history = useHistory();

  const { logout, permissions, userData, tokens } = useAuthStore();

  const [Discounts, setDiscounts] = useState<any>([]);
  const [Loading, setLoading] = useState<any>(false);
  const [MetaData, setMetaData] = useState<any>({});
  const [CurrentPage, setCurrentPage] = useState<number>(0);

  const [FilterData, setFilterData] = useState<any>({
    name: "",
    iType: "",
    active: "",
    effectiveFrom: "",
    effectiveTo: "",
  });

  useEffect((): any => {
    getAllDiscounts(CurrentPage);
  }, []);

  /**
   * Get all trade executions
   * @param page
   * @param isReset
   */
  const getAllDiscounts = async (page: number, isReset: boolean = false) => {
    setLoading(true);
    let cloneFilterData = !isReset
      ? { ...FilterData }
      : {
          name: "",
          iType: "",
          active: "",
          effectiveFrom: "",
          effectiveTo: "",
        };
    const query = makeQuery(cloneFilterData);
    let data: any = await DiscountService.getAllSipPromoCodes(tokens, page, query);
    if (data.status === 200 && data.data.data) {
      setCurrentPage(page);
      setMetaData({
        current: data.data.meta.page,
        pages: data.data.meta.totalPages,
      });
      setDiscounts(data ? data.data.data : []);
      setLoading(false);
    }
  };

  /**
   * Enable or disable discount
   * @param e
   * @param data
   */
  const enableDiscount = async (e: any, data: any) => {
    setLoading(true);
    // let cloneData = { ...data };
    const cloneData = {
      id: data?.id,
      active: e.target.checked ? "ENABLED" : "DISABLED",
    }

    await DiscountService.enableSipPromoCodeCouponStatus(cloneData, tokens);
    await getAllDiscounts(CurrentPage);
    setLoading(false);
    return toast.success("Discount was successfully updated");
  };

  /**
   * Handle filters change event
   * @param e
   */
  const handleChangeFilter = (e: any) => {
    const { name, value } = e.target;
    setFilterData({
      ...FilterData,
      [name]: value,
    });
  };

  /**
   * Reset filters to initial state
   * @param e
   */
  const resetFilterData = async (e: any) => {
    e.preventDefault();
    setFilterData({
      name: "",
      iType: "",
      active: "",
      effectiveFrom: "",
      effectiveTo: "",
    });

    setCurrentPage(0);
    await getAllDiscounts(0, true);
  };

  return (
    <Layout type={"TradeExecution"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">{"SIP Promo Code"}</h1>
        {HasAnyAccess(["discount.master.create"]) && (
          <button
            className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
            type="button"
            onClick={() => history.push("/sip/add")}
          >
            + Add Promo Code
          </button>
        )}
      </div>
      {Loading ? (
        <Loader />
      ) : (
        /* Trade Execution Master Table */
        <div className="w-full rounded border-gray-200 overflow-hidden shadow-lg dark:border-gray-700 bg-white">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Filter SIP Promo Codes
            </h3>
          </div>
          <hr />
          <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
            <div className="col-span-12 sm:col-span-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={(e: any) => {
                  handleChangeFilter(e);
                }}
                value={FilterData.name}
              />
            </div>
            <div className="col-span-12 sm:col-span-4">
              <label
                htmlFor="iType"
                className="block text-sm font-medium text-gray-700"
              >
                Type
              </label>
              <select
                id="iType"
                name="iType"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={(e: any) => {
                  handleChangeFilter(e);
                }}
                value={FilterData.iType}
              >
                <option value="">All</option>
                {SIP_ISSUE_TYPES.map((type: any, index: number) => {
                  return (
                    <option key={index} value={type.value}>
                      {type.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-span-12 sm:col-span-4">
              <label
                htmlFor="active"
                className="block text-sm font-medium text-gray-700"
              >
                Status
              </label>
              <select
                id="active"
                name="active"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={(e: any) => {
                  handleChangeFilter(e);
                }}
                value={FilterData.active}
              >
                <option value="">All</option>
                <option value="ENABLED">ENABLED</option>
                <option value="DISABLED">DISABLED</option>
              </select>
            </div>
            <div className="col-span-12 sm:col-span-4">
              <label
                htmlFor="effectiveFrom"
                className="block text-sm font-medium text-gray-700"
              >
                Effective From
              </label>

              <div>
                <Datetime
                  timeFormat={""}
                  dateFormat={"DD/MM/YYYY"}
                  inputProps={{
                    placeholder: "pick date",
                    className: "focus:outline-none",
                  }}
                  className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4`}
                  onChange={(event: any) => {
                    if (typeof event == "string") {
                      setFilterData({
                        ...FilterData,
                        effectiveFrom: "",
                      });
                    } else {
                      setFilterData({
                        ...FilterData,
                        effectiveFrom: moment(
                          new Date(event).toISOString()
                        ).format("MM/DD/YYYY"),
                      });
                    }
                  }}
                  value={moment(FilterData.effectiveFrom)}
                  renderInput={(props) => {
                    return (
                      <input
                        {...props}
                        style={{width:'100%'}}
                        value={
                          FilterData.effectiveFrom
                            ? FilterData.effectiveFrom
                            : ""
                        }
                      />
                    );
                  }}
                />
              </div>
            </div>

            <div className="col-span-12 sm:col-span-4">
              <label
                htmlFor="effectiveTo"
                className="block text-sm font-medium text-gray-700"
              >
                Effective To
              </label>

              <div>
                <Datetime
                  timeFormat={""}
                  dateFormat={"DD/MM/YYYY"}
                  inputProps={{
                    placeholder: "pick date",
                    className: "focus:outline-none",
                  }}
                  className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4`}
                  onChange={(event: any) => {
                    if (typeof event == "string") {
                      setFilterData({
                        ...FilterData,
                        effectiveTo: "",
                      });
                    } else {
                      setFilterData({
                        ...FilterData,
                        effectiveTo: moment(
                          new Date(event).toISOString()
                        ).format("MM/DD/YYYY"),
                      });
                    }
                  }}
                  value={moment(FilterData.effectiveTo)}
                  renderInput={(props) => {
                    return (
                      <input
                        style={{width:'100%'}}
                        {...props}
                        value={
                          FilterData.effectiveTo ? FilterData.effectiveTo : ""
                        }
                      />
                    );
                  }}
                />
              </div>
            </div>
            <div className="col-span-12 sm:col-span-12 flex justify-end">
              <button
                className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                type="button"
                onClick={() => getAllDiscounts(0)}
              >
                Filter
              </button>
              <button
                className="text-white bg-red-600 border border-red-300 rounded-md background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                type="button"
                onClick={(e) => resetFilterData(e)}
              >
                Reset
              </button>
            </div>
            <div className="col-span-12 sm:col-span-12">
              {" "}
              <hr />
            </div>
            <div className="col-span-12 sm:col-span-12">
              {Loading ? (
                <Loader />
              ) : (
                <div className="border bg-white rounded border-gray-200 shadow">
                  <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                    <thead>
                      <tr className="text-left border-b border-gray-300">
                        <th className="sticky top-0 px-3 py-2 bg-gray-800">
                          Name
                        </th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800">
                          Type
                        </th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800">
                          Effective From
                        </th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800">
                          Effective To
                        </th>
                        {HasAnyAccess(["discount.master.edit"]) && (
                          <th className="sticky top-0 px-3 py-2 bg-gray-800">
                            Status
                          </th>
                        )}
                        {HasAnyAccess(["discount.master.view"]) && (
                          <th className="sticky top-0 px-3 py-2 bg-gray-800">
                            Actions
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {Discounts.length > 0 ? (
                        Discounts.map((discount: any, index: number) => {
                          if (discount.isSip === true) {
                          return (
                            <tr
                              key={index}
                              className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                            >
                              <td className="py-3 px-3 text-left relative">
                                {discount.name}
                              </td>
                              <td className="py-3 px-3 text-left relative">
                                {discount.issueType}
                              </td>
                              <td className="py-3 px-3 text-left relative">
                                {moment(discount.effectFromDate).format(
                                  "DD-MM-YYYY HH:mm"
                                )}
                              </td>
                              <td className="py-3 px-3 text-left relative">
                                {moment(discount.effectToDate).format(
                                  "DD-MM-YYYY HH:mm"
                                )}
                              </td>
                              {HasAnyAccess(["discount.master.edit"]) && (
                                <td className="py-3 px-3 text-left relative">
                                  <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                    <input
                                      type="checkbox"
                                      name="toggle"
                                      id={`${discount.id}toggle`}
                                      checked={discount.active === "ENABLED"}
                                      className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                                      onChange={(e) =>
                                        enableDiscount(e, discount)
                                      }
                                    />
                                    <label
                                      htmlFor={`${discount.id}toggle`}
                                      className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                                    />
                                  </div>
                                </td>
                              )}
                              {HasAnyAccess(["discount.master.view"]) && (
                                <td className="py-3 px-3 text-left relative">
                                  <button
                                    className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() =>
                                      history.push(
                                        `/sip/update/${discount.id}/view`
                                      )
                                    }
                                  >
                                    View
                                  </button>
                                </td>
                              )}
                              {HasAnyAccess(["discount.master.view"]) && (
                                <td className="py-3 px-3 text-left relative">
                                  <button
                                    className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() =>
                                      history.push(
                                        `/sip/update/${discount.id}`
                                      )
                                    }
                                  >
                                    Edit
                                  </button>
                                </td>
                              )}
                            </tr>
                          );
                        }
                        })
                      ) : (
                        <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                          <td
                            colSpan={8}
                            className="text-center py-3 px-3 text-left relative"
                          >
                            No data to display
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
          <div className="col-span-12 sm:col-span-12 px-4 pt-2">
            {/*Pagination Bar*/}
            {!Loading && (
              <Pagination
                meta={MetaData}
                handlePageChange={(number: number) => getAllDiscounts(number)}
              />
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default SipTable;
