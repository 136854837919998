import Layout from "../Layout/Layout";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { CURRENCY, MAIN_RULE_TYPES } from "../TradeExecution/Utils/enum";
import RefundService, {
  getMetals,
} from "../Invoice/InvoiceService";
import Loader from "../../Components-ui/Loader";
import { Pagination } from "../../Components-ui/Pagination";
import { useAuthStore } from "../../Store/AuthStore";
import { toast, ToastContainer } from "react-toastify";
import Modal from "../../Components-ui/Modal/Modal";
import CustomerService from "../Customer/Services/Customer.service";
import HasAnyAccess from "../../Components-ui/hasAnyAccess";
import WarehouseMasterService from "../Metal/WearHouseMaster.Service";
import Datetime from "react-datetime";
// import InvoiceService from "../../Components/Invoice/InvoiceService";
import XLSX from "xlsx";
import SipService, {
  downloadInvoices,
  downloadSipReport,
  getSipList,
} from "./sipService";
import { filter } from "mathjs";
import { makeQuery } from "../../Common/util.helper";

interface PropsType {}

const Sip: React.FC<PropsType> = () => {
  const [metal, setMetal] = useState<any>([]);
  const ORDER_BY = [
    { label: "eb_invoice_ref", value: "eb_invoice_ref" },
    { label: "gst_invoice_ref", value: "gst_invoice_ref" },
    { label: "eb_order_ref", value: "eb_order_ref" },
    { label: "customer_id", value: "customer_id" },
    { label: "warehouse_id", value: "warehouse_id" },
    { label: "created_at", value: "created_at" },
  ];

  const STATUS = [
    { label: "CREATED", value: "CREATED" },
    { label: "CAPTURED", value: "CAPTURED" },
    { label: "COMPLETED", value: "COMPLETED" },
    { label: "FAILED", value: "FAILED" },
  ];
  const { isPartnerAdmin, partnerAdminId } = useAuthStore();

  const [partnerList, setPartnerList] = useState<any>([]);
  const [Filter, setFilter] = useState<any>({
    paymentId: "",
    sipId: "",
    razorpayOrderId: "",
    customerId: "",
    currency: "",
    orderBy: "",
    status: "",
    partnerId: "",
    warehouseId: "",
    metalCode: "",
    fromDate: "",
    toDate: "",
    name: "",
  });
  const [Loading, setLoading] = useState<any>(false);
  const [sip, setInvoices] = useState<any>([]);
  const [selectedRecords, setSelectedRecords] = useState<any>([]);
  const [CurrentPage, setCurrentPage] = useState<number>(0);
  const [show, setModalStatus] = useState<any>(false);
  const [children, setChildren] = useState<any>();
  const [itemList, setItemList] = useState<any>([]);
  const [metaData, setMetaData] = useState<any>({});
  const [warehouses, setWarehouses] = useState<any>([]);
  const [downloadQuery, setDownloadQuery] = useState<any>("");
  useEffect((): any => {
    fetchData(CurrentPage, Filter);
    get_partner_list();
    getMetals();
    getAllWarehouses();
  }, []);

  const { logout, permissions, userData, tokens } = useAuthStore();

  useEffect(() => {
    childrenElements();
  }, [show]);

  useEffect(() => {
    childrenElements();
  }, [itemList]);

  const childrenElements = () => {
    setChildren(
      <div className="w-full">
        <h1 className="font-bold">{"Item Details"}</h1>
        <form className="bg-white rounded pt-6 pb-4 mb-2">
          <div className="mb-4">
            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
              <thead>
                <tr className="text-left border-b border-gray-300">
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Item Code
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Quantity
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Rate
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    UOM
                  </th>
                </tr>
              </thead>
              <tbody>
                {0 !== itemList.length ? (
                  itemList.map((partner: any, index: number) => {
                    return (
                      <tr
                        key={index}
                        className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                      >
                        <td className="py-3 px-3 text-left relative">
                          {partner.itemCode}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {partner.qty}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {partner.rate}
                        </td>{" "}
                        <td className="py-3 px-3 text-left relative">
                          {partner.uom}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                    <td
                      colSpan={6}
                      className="text-center py-3 px-3 text-left relative"
                    >
                      No data to display
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </form>
      </div>
    );
  };

  const paginationFilter = async (page: number) => {
    setCurrentPage(page);
    await fetchData(page, Filter);
  };

  const fetchData = async (page: number, data: any): Promise<void> => {
    // let cloneFilterData = {...Filter}
    // console.log(cloneFilterData);
    // // if(0 !== cloneFilterData.status.length){
    //     cloneFilterData.status = cloneFilterData.status.map((x: any) => x.value);
    // // }
    // const query = makeQuery(cloneFilterData);
    setLoading(true);
    return new Promise(async (resolve) => {
      getSipList(tokens, page, data, true)
        .then((response: any) => {
          console.log(response.data);
          setInvoices(response.data.data.result.results);
          setMetaData({
            current: response.data.meta.page,
            pages: response.data.meta.totalPages,
          });
          resolve();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response);
          resolve();
        });
    });
  };

  //  fetchData(0, {})
  const handleChangeFilter = (e: any) => {
    const { name, value } = e.target;
    setFilter({
      ...Filter,
      [name]: value,
    });
  };

  /**
   * Get orders according to the filtration
   * @param page
   * @param isReset
   */
  const handleSubmit = async (page: number, isReset: boolean = false) => {
    setLoading(true);
    fetchData(0, Filter);
    const query = makeQuery(Filter);
    setDownloadQuery(query);
    // setLoading(false);
  };

  /**
   * Reset filters to initial state
   * @param e
   */
  const resetData = async (e: any) => {
    e.preventDefault();
    setFilter({
      paymentId: "",
      sipId: "",
      razorpayOrderId: "",
      customerId: "",
      currency: "",
      orderBy: "",
      status: "",
      partnerId: "",
      warehouseId: "",
      metalCode: "",
      fromDate: "",
      toDate: "",
    });
    setCurrentPage(0);
    setFilter((prevFilter: any) => {
      fetchData(0, prevFilter);
      return prevFilter;
    });
  };

  const hideModal = () => {
    setModalStatus(false);
  };

  const get_partner_list = async () => {
    await CustomerService.getPartnerList(tokens)
      .then((response: any) => {
        setPartnerList(response.data.data);
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const getMetals = async () => {
    await SipService.getMetals(tokens)
      .then((response: any) => {
        setMetal(response.data.data);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  /**
   * Get all warehouses
   */
  const getAllWarehouses = async () => {
    let data: any = await WarehouseMasterService.getWarehouseMasterData(
      tokens,
      0,
      false
    );
    if (data.status === 200 && data.data.data) {
      let responseData = data.data.data;
      setWarehouses(responseData);
    }
  };

  const handleChangeItem = (selected: any) => {
    setFilter({
      ...Filter,
      status: selected,
    });

    console.log(selected, "selected");
  };

  const downloadOrderReport = async () => {
    setLoading(true);
    await downloadSipReport(downloadQuery, tokens)
      .then((res: any) => {
        if (res.status === 200 && res.data) {
          setLoading(false);
          // saveAs(res.data.data.fileUrl, `order_log_report`);
          window.location.assign(res.data.data.fileUrl);
          // window.open(res.data.data.fileUrl);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Something Went Wrong!");
        setLoading(false);
      });

    setLoading(false);
  };

  var valid = function (current: any) {
    return current.isAfter(moment(Filter.fromDate).subtract(1, "day"));
  };

  return (
    <Layout type={"Refund"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">
          {"SIP Transactions"}
        </h1>
      </div>
      <div className="w-full rounded border-gray-200 overflow-hidden shadow-lg dark:border-gray-700 bg-white">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Filter SIP Log
          </h3>
        </div>
        <hr />
        <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="paymentId"
              className="block text-sm font-medium text-gray-700"
            >
              Payment Id
            </label>
            <input
              type="text"
              name="paymentId"
              id="paymentId"
              min="0"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={Filter.paymentId}
            />
          </div>
          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="sipId"
              className="block text-sm font-medium text-gray-700"
            >
              SIP Id
            </label>
            <input
              type="text"
              name="sipId"
              id="sipId"
              min="0"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={Filter.sipId}
            />
          </div>
          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="razorpayOrderId"
              className="block text-sm font-medium text-gray-700"
            >
              Razorpay Order Id
            </label>
            <input
              type="text"
              name="razorpayOrderId"
              id="razorpayOrderId"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={Filter.razorpayOrderId}
            />
          </div>
          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="customerId"
              className="block text-sm font-medium text-gray-700"
            >
              Customer Id
            </label>
            <input
              type="text"
              name="customerId"
              id="customerId"
              min="0"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={Filter.customerId}
            />
          </div>
          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="partnerId"
              className="block text-sm font-medium text-gray-700"
            >
              Partner
            </label>
            <select
              id="partnerId"
              name="partnerId"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={Filter.partnerId}
              disabled={isPartnerAdmin}
            >
              <option value="">All partners</option>
              {partnerList.map((type: any, index: number) => {
                return (
                  <option key={index} value={type.partnerId}>
                    {type.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="type"
              className="block text-sm font-medium text-gray-700 pb-1"
            >
              Status
            </label>
            <select
              id="status"
              name="status"
              className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={Filter.status}
            >
              {STATUS.map((type: any, index: number) => {
                return (
                  <option key={index} value={type.value}>
                    {type.label}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-span-12 sm:col-span-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="status"
            >
              From Date
            </label>
            <div className="relative">
              <Datetime
                timeFormat={""}
                dateFormat={"YYYY-MM-DD"}
                inputProps={{
                  readOnly: true,
                  placeholder: "",
                  className: "focus:outline-none",
                }}
                className={`rdt mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
             `}
                onChange={(e: any) => {
                  setFilter({
                    ...Filter,
                    fromDate: moment(new Date(e).toISOString()).format(
                      "YYYY-MM-DD"
                    ),
                    // toDate: e.isAfter(new Date(Filter.toDate))
                    //   ? moment(new Date(e).toISOString()).format("YYYY-MM-DD")
                    //   : moment(Filter.toDate).format("YYYY-MM-DD"),
                  });
                }}
                value={Filter.fromDate}
                renderInput={(props) => {
                  return (
                    <input
                      style={{ width: "100%" }}
                      {...props}
                      value={Filter.fromDate ? Filter.fromDate : ""}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="col-span-12 sm:col-span-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="status"
            >
              To Date
            </label>
            <div className="relative">
              <Datetime
                isValidDate={valid}
                timeFormat={""}
                dateFormat={"YYYY-MM-DD"}
                inputProps={{
                  readOnly: true,
                  placeholder: "",
                  className: "focus:outline-none",
                }}
                className={`rdt mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
             `}
                onChange={(e: any) => {
                  setFilter({
                    ...Filter,
                    toDate: moment(new Date(e).toISOString()).format(
                      "YYYY-MM-DD"
                    ),
                  });
                }}
                value={Filter.toDate}
                renderInput={(props) => {
                  return (
                    <input
                      style={{ width: "100%" }}
                      {...props}
                      value={Filter.toDate ? Filter.toDate : ""}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="col-span-12 sm:col-span-12 flex justify-end">
            <button
              className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
              type="button"
              onClick={() => handleSubmit(CurrentPage)}
            >
              Filter
            </button>
            <button
              className="text-white border border-red-300 rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ml-2  ease-linear transition-all duration-150"
              type="button"
              onClick={(e) => resetData(e)}
            >
              Reset
            </button>
            <button
              className="px-4 py-1.5 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none ml-2  ease-linear transition-all duration-150"
              type="button"
              onClick={(e) => downloadOrderReport()}
            >
              Download Report
            </button>
          </div>
          <div className="col-span-12 sm:col-span-12">
            <hr />
          </div>

          <div className="col-span-12 sm:col-span-12">
            {Loading ? (
              <Loader />
            ) : (
              <div className="border bg-white rounded border-gray-200 shadow overflow-x-auto">
                <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                  <thead className="text-truncate">
                    <tr className="text-left border-b border-gray-300">
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Customer Id
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        SIP Id
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Razorpay Order Id
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Payment Id
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Order Id
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Created At
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Installment Amount
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Partner Id
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {0 !== sip.length ? (
                      sip.map((data: any, index: number) => {
                        return (
                          <tr
                            key={index}
                            className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                          >
                            <td className="py-3 px-3 text-left relative">
                              {data.customerId}
                            </td>
                            <td
                              className="py-3 px-3 text-left relative"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {data.sipId}
                            </td>{" "}
                            <td className="py-3 px-3 text-left relative">
                              {data.razorpayOrderId}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {data.paymentId}
                            </td>
                            <td
                              className="py-3 px-3 text-left relative"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {data.orderId}
                            </td>
                            <td
                              className="py-3 px-3 text-left relative"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {data.createdAt
                                ? moment(data.createdAt).format("DD-MM-YYYY")
                                : "N/A"}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {data.installmentAmount}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {data.name}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {STATUS.filter(
                                (x: any) => x.value === data.status
                              ).length > 0
                                ? STATUS.filter(
                                    (x: any) => x.value === data.status
                                  )[0].label
                                : data.status}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                        <td
                          colSpan={17}
                          className="text-center py-3 px-3 text-left relative"
                        >
                          No data to display
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="col-span-12 sm:col-span-12">
            <Pagination
              meta={metaData}
              handlePageChange={(number: number) => {
                paginationFilter(number);
              }}
            />
          </div>
        </div>
        <Modal
          size={"large"}
          show={show}
          handleClose={hideModal}
          children={children}
        />
      </div>
    </Layout>
  );
};

export default Sip;
