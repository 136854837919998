import { useState } from "react";
import { useAuthStore } from "../../../Store/AuthStore";
import { useHistory } from "react-router-dom";
import DashboardCardComponent from "./DashboardCardComponent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

type NewProps = {
  onClick?: any;
  title?: any;
  sellMetals?: any;
  buyMetals?: any;
  expanded?: any;

  redirectFunc?: (e: any, label: any, iconKey: string) => void;
};

const DashboardAccordian = ({
  onClick,
  title,

  sellMetals,
  buyMetals,
  expanded,

  redirectFunc,
}: NewProps) => {
  const { tokens, permissions } = useAuthStore();
  //   const [data, setData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const redirectOrder = () => {
    history.push("/order");
  };


  const totalSellAmount = sellMetals?.reduce((sum: number, num: any) => {
    if (typeof num?.amount === 'string' || typeof num?.amount === 'number') {
      let numericValue = parseFloat(num?.amount.toString().replace(/,/g, ""));
      return sum + (isNaN(numericValue) ? 0 : numericValue);
    }
    return sum;
  }, 0);

  const totalBuyAmount = buyMetals?.reduce((sum: number, num: any) => {
    if (typeof num?.amount === 'string' || typeof num?.amount === 'number') {
      let numericValue = parseFloat(num?.amount.toString().replace(/,/g, ""));
      return sum + (isNaN(numericValue) ? 0 : numericValue);
    }
    return sum;
  }, 0);


  return (
    <div onClick={onClick}>
      <div
        className={` overflow-hidden transition-[max-height] duration-500 ease-in ${expanded ? "max-h-100" : "max-h-0"
          }`}
      >
        <div className="flex items-center justify-between mb-2">
          <p className="m-2 font-bold">SELL Transactions</p>
          {totalSellAmount !== undefined && (
            <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-3 py-1 rounded-full">
              Total: ₹ {totalSellAmount ? totalSellAmount?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"}
            </span>
          )}
        </div>
        <hr className="mb-3 w-30" />
        <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
          {sellMetals?.map((metal: any) => {
            const sellAmount = parseFloat(totalSellAmount);
            const metalAmount = parseFloat(metal?.amount?.replace(/,/g, ""));
            const result = metalAmount / sellAmount * 100;
            return (
              <DashboardCardComponent
                label={metal?.label}
                percentage={result?.toFixed(2)}
                value={metal?.value}
                amount={metal?.amount}
                count={metal?.count}
                iconkey={metal?.key}
                icon={<CheckCircleIcon />}
                redirectFunc={redirectFunc}
              />
            );
          })}

        </div>
        <div className="flex items-center justify-between mb-2">
          <p className="m-2 font-bold">BUYBACK Transactions</p>
          {totalBuyAmount !== undefined && (
            <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-3 py-1 rounded-full">
              Total: ₹ {totalBuyAmount ? totalBuyAmount?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"}
            </span>
          )}
        </div>

        <hr className="mb-3 w-30" />
        <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
          {buyMetals?.map((metal: any) => {
            const buyAmount = parseFloat(totalBuyAmount);
            const metalAmount = parseFloat(metal?.amount?.replace(/,/g, ""));
            const result = metalAmount / buyAmount * 100  ;
            return (
              <DashboardCardComponent
                label={metal?.label}
                percentage={result?.toFixed(2)}
                value={metal?.value}
                amount={metal?.amount}
                count={metal?.count}
                iconkey={metal?.key}
                icon={<CheckCircleIcon />}
                redirectFunc={redirectFunc}
              />
            )
          })}
        </div>
      </div>
    </div>
  );
};

export default DashboardAccordian;
