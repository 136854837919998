import Http from "../../library/http.library";
import {httpMethods} from "../../library/enum";

export const GetAllReports = async (warehouses: any = [], permissions: any = []) => {
  let ids: any = []
  if (permissions.map((x: any) =>  x.permissionCode).includes('vso.receipt') && warehouses.length > 0) {
    ids = warehouses.map((x: any) => x.warehouseId).join(',');
  }
  return await Http.setMethod(httpMethods.get).setUrl(`/vault/inventory/dailybarlist/s3/get${permissions.map((x: any) =>  x.permissionCode).includes('vso.receipt') ? `?warehouseIds=${ids}` : ''}`).request();

  // return new Promise(async (resolve, reject) => {
  //   let apiUrl = process.env.REACT_APP_URL + `/vault/inventory/dailybarlist/s3/get${permissions.map((x: any) =>  x.permissionCode).includes('vso.receipt') ? `?warehouseIds=${ids}` : ''}`;
  //   resolve(await axios.get(apiUrl));
  // });
};

export const DownloadReport = async (Key: string) => {
  return await Http.setMethod(httpMethods.get).setUrl(`/vault/inventory/dailybarlist/s3/download/${Key}`).request();

  // return new Promise(async (resolve, reject) => {
  //   let apiUrl =
  //     process.env.REACT_APP_URL + `/vault/inventory/dailybarlist/s3/download/${Key}`;
  //   resolve(await axios.get(apiUrl));
  // });
};

export const DownloadReportNew = async (Key: string) => {
  let apiUrl = `${process.env.REACT_APP_URL}/order/b2b/buyback/invoice/download/${encodeURIComponent(Key)}`;
  return fetch(apiUrl, {
    method: 'GET'
  });
};


