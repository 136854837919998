import React from "react";
import { Route } from "react-router-dom";

import PermissionTable from "../Components/PermissionTable";


export const PermissionRoutes: React.FC = () => {
    return (
        <>
            <Route path="/permissions" exact component={PermissionTable} />
        </>
    );
};
