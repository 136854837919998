import React, { useState, useEffect } from "react";
import { useAuthStore } from "../../../Store/AuthStore";
import Layout from "../../Layout/Layout";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import MetalInterchangeService from "../MetalInterchangeService";
import Modal from "../../../Components-ui/Modal/Modal";
import SimpleReactValidator from "simple-react-validator";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";

const MetalInterchangeQuantityToBar: React.FC = () => {
  const [Loading, setLoading] = useState<any>(false);
  const [show, setModalStatus] = useState<any>(false);
  const [children, setChildren] = useState<any>();
  const [record, setRecord] = useState<any>();
  const [array, setArray] = useState<any>([]);
  const [selectedData, setSelectedData] = useState<any>([]);
  const [fromBarList, setFromBarList] = useState<any>([]); //main table data
  const [barList, setBarList] = useState<any>([]); //modal
  const [metalList, setMetalList] = useState<any>([]);
  const [vaultList, setVaultList] = useState<any>([]);
  const [itemList, setItemList] = useState<any>([]);
  const [customerList, setCustomerList] = useState<any>([]);
  const [metalBalance, setMetalBalance] = useState<any>(0);
  const [filters, setFilters] = useState<any>({
    vaultId: "",
    fromPool: "",
    metalCode: "",
    weight: "",
    customerId: "",
    type: "QUANTITY_TO_BAR",
  });
  const { logout, permissions, userData, tokens } = useAuthStore();
  const [validatorStatus, setValidator] = useState<boolean>(false);

  const simpleValidator = new SimpleReactValidator({
    locale: "en",
    validators: {},
  });
  const validationRef = React.useRef(simpleValidator);
  const validator = validationRef.current;
  const getValidatorError = (nameOfStateProp: any) => {
    const allErrorMessages = validator.getErrorMessages();
    return allErrorMessages[nameOfStateProp];
  };

  const hideModal = () => {
    setModalStatus(false);
  };

  const getBarList = async () => {
    if (record) {
      MetalInterchangeService.getBarList(tokens, record.id, filters)
        .then((response: any) => {
          setBarList(response.data.data.fromBars);
          removeItems(response.data.data.fromBars);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data.errors[0].title);
        });
    }
  };

  const getMetals = async () => {
    setLoading(true);
    await MetalInterchangeService.getAllMetals(tokens)
      .then((response: any) => {
        setMetalList(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.errors[0].title);
      });
  };

  const changeStatus = async (data: any, i: any) => {
    let newArr = [...barList];
    newArr[i].selected = !data.selected;
    setBarList(newArr);
    if (0 !== array.length) {
      if (array.some((item: any) => item.id === data.id)) {
        array.forEach((element: any, index: number) => {
          if (data.id === element.id) {
            array.splice(index, 1);
          }
          setArray(array);
        });
      } else {
        setArray(array.concat(data));
      }
    } else {
      setArray(array.concat(data));
    }
  };

  const addBars = async () => {
    var arr: { barId: any; exchangeId: any; type: string }[] = [];
    selectedData.map((item: any, index: number) => {
      var data = {
        barId: item.id,
        exchangeId: record.id,
        type: "FROM",
      };
      arr.push(data);
    });
    await MetalInterchangeService.addBarList(tokens, arr)
      .then((response: any) => {
        toast.success("Record Added Successfully!");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.errors[0].title);
      });
  };

  const addModalElements = () => {
    setArray([]);
    getBarList();
    setModalStatus(true);
  };

  const childrenElements = () => {
    setChildren(
      <div className="w-full">
        <h1 className="font-bold">{"Select Bars"}</h1>
        <form className="bg-white rounded pt-6 pb-8 mb-4">
          <div className="mb-4">
            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
              <thead>
                <tr className="text-left border-b border-gray-300">
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10"></th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Bar No
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Bar Description
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Seg ref
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Weight
                  </th>
                </tr>
              </thead>
              <tbody>
                {0 !== barList.length ? (
                  barList.map((partner: any, index: number) => {
                    return (
                      <tr
                        key={index}
                        className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                      >
                        <td className="py-3 px-3 text-left relative">
                          <input
                            type="checkbox"
                            value={partner.selected}
                            checked={partner.selected}
                            onClick={() => {
                              changeStatus(partner, index);
                            }}
                          />
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {partner.barNo}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {partner.brandName}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {/*{partner.itemDescription}*/}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {partner.weight}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                    <td
                      colSpan={5}
                      className="text-center py-3 px-3 text-left relative"
                    >
                      No data to display
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="flex items-center justify-between flex-row-reverse">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={() => {
                Save();
              }}
            >
              {"SAVE"}
            </button>
          </div>
        </form>
      </div>
    );
  };

  useEffect(() => {
    childrenElements();
  }, [array]);

  useEffect(() => {
    childrenElements();
  }, [barList, show]);

  useEffect(() => {
    getItemData();
  }, [
    filters.vaultId,
    filters.metalCode,
    filters.fromPool,
    filters.customerId,
  ]);

  const getItemData = async () => {
    if (
      !(
        filters &&
        filters.vaultId &&
        filters.metalCode &&
        filters.fromPool &&
        filters.customerId
      )
    )
      return;
    try {
      await MetalInterchangeService.getQuantityToBarList(tokens, filters)
        .then((response: any) => {
          setItemList(response.data.data.items);
          setMetalBalance(response.data.data.totalMetalBalance);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          // toast.error(err.response.data.errors[0].title);
        });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const Save = async () => {
    let array1 = [...array];
    setSelectedData(
      0 !== selectedData.length ? selectedData.concat(array1) : array1
    );
    setFromBarList(
      0 !== fromBarList.length ? fromBarList.concat(array1) : array1
    );
    addBars();
    setModalStatus(false);
  };

  const removeItems = (data: any) => {
    for (var i = data.length - 1; i >= 0; i--) {
      for (var j = 0; j < selectedData.length; j++) {
        if (data[i] && data[i].id === selectedData[j].id) {
          data.splice(i, 1);
        }
      }
    }
    setBarList(data);
  };

  const filter = async () => {
    setValidator(true);
    if (validator.allValid()) {
      setLoading(true);
      await MetalInterchangeService.filterData(tokens, filters)
        .then((response: any) => {
          setRecord(response.data.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data.errors[0].title);
        });
      setValidator(false);
    }
  };

  const resetForm = async () => {
    setValidator(false);
    setFilters({
      vaultId: "",
      fromPool: "",
      metalCode: "",
      weight: "",
      customerId: "",
      type: "QUANTITY_TO_BAR",
    });
    setItemList([]);
  };

  const approveBars = () => {
    if (record) {
      var data = {
        approve: true,
        type: record.type,
      };
      MetalInterchangeService.approveBars(tokens, data, record.id)
        .then((response: any) => {
          toast.success("Bars Approved Successfully");
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
        });
    }
  };

  const exchangeBars = () => {
    if (record) {
      MetalInterchangeService.exchangeBars(tokens, record.id)
        .then((response: any) => {
          toast.success("Bars Exchanged Successfully");
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
        });
    }
  };

  const loadVaults = async (): Promise<void> => {
    setLoading(true);
    return new Promise(async (resolve) => {
      MetalInterchangeService.getVaults(tokens)
        .then((response: any) => {
          setLoading(false);
          setVaultList(response.data.data);
          resolve();
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Something Went Wrong!");
          resolve();
        });
    });
  };

  useEffect(() => {
    loadVaults();
    getMetals();
    getCustomers();
  }, []);

  const getCustomers = async (): Promise<void> => {
    setLoading(true);
    return new Promise(async (resolve) => {
      MetalInterchangeService.getCustomerList(tokens)
        .then((response: any) => {
          setCustomerList(response.data.data);
          setLoading(false);
          resolve();
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Something Went Wrong!");
          resolve();
        });
    });
  };
  return (
    <Layout type={"MetalInterchangeModule"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">
          {"Quantity To Bar"}
        </h1>
      </div>
      <div className="border bg-white rounded border-gray-200 shadow">
        <div className="flex-1 p-2">
          <form
            onSubmit={(e) => {
                filter()
              e.preventDefault();
            }}
          >
            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="partner-name"
                >
                  Vault ID
                </label>
                <select
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="user-type"
                  name="vault-Id"
                  onChange={(event) => {
                    setFilters({
                      ...filters,
                      vaultId: event.target.value,
                    });
                  }}
                  value={filters.vaultId}
                >
                  <option value="">Select a Vault</option>
                  {vaultList.map((vault: any) => (
                    <option value={vault.id}>{vault.warehouseId}</option>
                  ))}
                </select>
                {validator.message("vault-Id", filters.vaultId, "required")}
                {validatorStatus ? (
                  <span className="text-xs text-red-700" id="name">
                    {getValidatorError("vault-Id")}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="partner-name"
                >
                  Customer
                </label>
                <select
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="user-type"
                  name="customer-Id"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      customerId: event.target.value,
                    })
                  }
                  value={filters.customerId}
                >
                  <option value="">Select a Customer</option>
                  {customerList.map((b: any) => (
                    <option value={b.customerId}>{b.displayName}</option>
                  ))}
                </select>
                {validator.message(
                  "customer-Id",
                  filters.customerId,
                  "required"
                )}
                {validatorStatus ? (
                  <span className="text-xs text-red-700" id="name">
                    {getValidatorError("customer-Id")}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="partner-name"
                >
                  From Pool
                </label>
                <select
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="user-type"
                  name="from-pool"
                  onChange={(event) => {
                    setFilters({
                      ...filters,
                      fromPool: event.target.value,
                    });
                  }}
                  value={filters.fromPool}
                >
                  <option value="">Select a From Pool</option>
                  <option value="Customer">Customer Pool</option>
                  <option value="EBPL">EBL Pool</option>
                </select>
                {validator.message("from-pool", filters.fromPool, "required")}
                {validatorStatus ? (
                  <span className="text-xs text-red-700" id="name">
                    {getValidatorError("from-pool")}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="partner-name"
                >
                  Metal
                </label>
                <select
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="user-type"
                  name="metal-code"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      metalCode: event.target.value,
                    })
                  }
                  value={filters.metalCode}
                >
                  <option value="">Select a Metal</option>
                  {metalList.length > 0 &&
                    metalList.map((b: any) => (
                      <option value={b.metalCode}>{b.description}</option>
                    ))}
                </select>
                {validator.message("metal-code", filters.metalCode, "required")}
                {validatorStatus ? (
                  <span className="text-xs text-red-700" id="name">
                    {getValidatorError("metal-code")}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="partner-name"
                >
                  Weight
                </label>
                <select
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="user-type"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      weight: event.target.value,
                    })
                  }
                  value={filters.weight}
                >
                  <option value="">Select a Weight</option>
                  {itemList.map((b: any) => (
                    <option value={b.itemCode}>{b.itemName}</option>
                  ))}
                </select>
              </div>
              <div className="w-full md:w-2/4 px-3 mb-6 md:mb-0">
                <div className="my-6">
                  {/* <button
                                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                                    type="button" onClick={() => {
                                    filter()
                                }}>
                                    Filter
                                </button> */}
                  <input
                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                    type="submit"
                    value="Filter"
                  />
                  <button
                    className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      resetForm();
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <div className="py-8 float-right">
                  <span
                    className={`bg-gray-300 font-semibold inline-block last:mr-0 mr-1 px-2 py-1 rounded-full text-xs uppercase`}
                  >
                    Metal Balance : {metalBalance}
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="m-2 rounded">
          {Loading ? (
            <Loader />
          ) : (
            <div>
              <fieldset className="bg-gray-100 border border-gray-300 flex min-w-0 mt-3 p-3 p-1 pb-4 rounded w-full">
                <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
                  {"From Bars"}
                </legend>
                <div className="w-full">
                  <button
                    className="bg-purple-950 float-right font-bold mb-1.5 px-4 py-2 rounded text-sm text-white"
                    type="button"
                    onClick={() => {
                      addModalElements();
                    }}
                  >
                    + Add Bar
                  </button>
                  <div className="">
                    <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                      <thead>
                        <tr className="text-left border-b border-gray-300">
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Bar No
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Bar Description
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Seg ref
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Weight
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {0 !== fromBarList.length ? (
                          fromBarList.map((partner: any, index: number) => {
                            return (
                              <>
                                {undefined !== partner.barNo ? (
                                  <tr
                                    key={index}
                                    className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                                  >
                                    <td className="py-3 px-3 text-left relative">
                                      {partner.barNo}
                                    </td>
                                    <td className="py-3 px-3 text-left relative">
                                      {partner.brandName}
                                    </td>
                                    <td className="py-3 px-3 text-left relative">
                                      {partner.name}
                                    </td>
                                    <td className="py-3 px-3 text-left relative">
                                      {partner.weight}
                                    </td>
                                  </tr>
                                ) : (
                                  <div></div>
                                )}
                              </>
                            );
                          })
                        ) : (
                          <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                            <td
                              colSpan={5}
                              className="text-center py-3 px-3 text-left relative"
                            >
                              No data to display
                            </td>
                          </tr>
                        )}
                        {0 !== fromBarList.length ? (
                          <tr className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                            <td
                              className="py-3 px-3 text-left relative"
                              colSpan={3}
                            >
                              Total
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {0 !== fromBarList.length
                                ? fromBarList
                                    .map((a: any) => Number(a.weight) || 0)
                                    .reduce(function (a: any, b: any) {
                                      return a + b;
                                    })
                                : ""}
                            </td>
                          </tr>
                        ) : (
                          <div></div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </fieldset>
              <div className="flex items-center justify-end sm:py-3  sm:px-6 rounded-b">
                <div>
                  {HasAnyAccess(["quantity.to.bar.exchange"]) && (
                    <button
                      className="text-white bg-purple-950 background-transparent font-bold px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                      type="button"
                      onClick={exchangeBars}
                    >
                      Exchange
                    </button>
                  )}
                  {/*{*/}
                  {/*    permissions.filter((permission: any) => (permission.permissionCode === 'quantity.to.bar.approve')).length > 0 && (*/}
                  {/*<button*/}
                  {/*    className="ml-1.5 text-white bg-purple-950 background-transparent font-bold px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"*/}
                  {/*    type="button" onClick={approveBars}>Approve*/}
                  {/*</button>)}*/}
                </div>
              </div>
              <Modal
                size={"large"}
                show={show}
                handleClose={hideModal}
                children={children}
              />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default MetalInterchangeQuantityToBar;
