import React, {useState, useEffect, Fragment} from "react";
import {useAuthStore} from "../../Store/AuthStore";
import Layout from "../Layout/Layout";
import {toast, ToastContainer} from "react-toastify";
import Loader from "../../Components-ui/Loader";
import moment from "moment";
import {Pagination} from "../../Components-ui/Pagination";
import BankDetailsService from "./BankDetailsService";
import Modal from "../../Components-ui/Modal/Modal";
import SimpleReactValidator from "simple-react-validator";
import {Dialog, Transition} from "@headlessui/react";
import HasAnyAccess from "../../Components-ui/hasAnyAccess";

const BankDetails: React.FC = () => {
    const [meta, setMetaData] = useState<any>({});
    const [Loading, setLoading] = useState<any>(true);
    const [bankDetails, setBankDetails] = useState<any>([]);
    const [show, setModalStatus] = useState<any>(false);
    const [children, setChildren] = useState<any>();
    const [status, setStatus] = useState<any>('ADD');
    const [selectedRecord, setSelectedRecord] = useState<any>({});
    const [currentPage, setCurrentPage] = useState<any>(0);
    let [isOpen, setIsOpen] = useState(false);
    const { logout, permissions, userData , tokens } = useAuthStore();
    const [validatorStatus, setValidator] = useState<boolean>(false);

    const simpleValidator = new SimpleReactValidator({
        locale: "en",
        validators: {},
    });
    const validationRef = React.useRef(simpleValidator);
    const validator = validationRef.current;

    const hideModal = () => {
        setModalStatus(false);
    };

    const getDetailsList = async (page: number) => {
        await BankDetailsService.getBankDetailsList(tokens, page).then((response: any) => {
            setLoading(false);
            setBankDetails(response.data.data);
            setMetaData({
                current: response.data.meta.page,
                pages: response.data.meta.totalPages,
            });
            setCurrentPage(response.data.meta.page);
        }).catch(err => {
            setLoading(false);
            toast.error('Something went wrong!');
        });
    }

    const updateBankDetails = async () => {
        setValidator(true);
        if (validator.allValid()) {
            setLoading(true);
            await BankDetailsService.UpdateDetails(selectedRecord, tokens).then((response: any) => {
                setLoading(false);
                setModalStatus(false);
                getDetailsList(currentPage);
                toast.success('Updated Successfully');
            }).catch(err => {
                setLoading(false);
                toast.error('Something went wrong!');
            });
            setValidator(false);
        }
    }


    useEffect(() => {
        childrenElements();
    }, [selectedRecord, status]);

    useEffect(() => {
        childrenElements();
    }, [validatorStatus]);


    const editModalElements = (data: any) => {
        setStatus('EDIT');
        childrenElements();
        setSelectedRecord(data);
        setModalStatus(true);
    }

    const deleteDetails = (data: any) => {
        setIsOpen(true)
        setSelectedRecord(data);
    }

    const deleteElement = async () => {
        setValidator(true);
        if (validator.allValid()) {
            await BankDetailsService.deleteRecord(selectedRecord, tokens).then((response: any) => {
                setLoading(false);
                setIsOpen(false);
                getDetailsList(currentPage);
                toast.success('Record has been deleted');
            }).catch(err => {
                setLoading(false);
                toast.error('Something went wrong!');
            });
            setValidator(false);
        }
    }


    const addModalElements = () => {
        setValidator(false);
        setSelectedRecord({
            name: '',
            creditLimit: '',
            active: 'ENABLED',
            email: ''
        });
        setStatus('ADD');
        childrenElements();
        setModalStatus(true);
    }

    const getValidatorError = (nameOfStateProp: any) => {
        const allErrorMessages = validator.getErrorMessages();
        return allErrorMessages[nameOfStateProp];
    };

    const childrenElements = () => {
        setChildren(<div className="w-full max-w-xs">
            <h1 className="font-bold mb-1.5">
                {('ADD' === status) ? 'ADD Bank Details' : 'EDIT Bank Details'}
            </h1>
            <form className="bg-white roundedmb-4">
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="username">
                        Code
                    </label>
                    <input
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        id="code" name="code" type="text" placeholder="Code" onChange={(event) => setSelectedRecord({
                        ...selectedRecord,
                        code: event.target.value
                    })} value={selectedRecord.code || ''} autoComplete="off" disabled={('ADD' !== status)}/>
                    {validator.message(
                        "code",
                        selectedRecord.code,
                        "required"
                    )}
                    {
                        validatorStatus ? (<span className="text-xs text-red-700"
                                                 id="name">{getValidatorError("code")}</span>) : ''
                    }
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="username">
                        Bank Name
                    </label>
                    <input
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        id="bankName" name="bank-name" type="text" placeholder="Bank Name"
                        onChange={(event) => setSelectedRecord({
                            ...selectedRecord,
                            bankName: event.target.value
                        })} value={selectedRecord.bankName || ''}
                        autoComplete="off"/>
                    {validator.message(
                        "bank-name",
                        selectedRecord.bankName,
                        "required"
                    )}
                    {
                        validatorStatus ? (<span className="text-xs text-red-700"
                                                 id="name">{getValidatorError("bank-name")}</span>) : ''
                    }
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="username">
                        Account Name
                    </label>
                    <input
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        id="accountName" type="text" name="account-name" placeholder="Account Name"
                        onChange={(event) => setSelectedRecord({
                            ...selectedRecord,
                            accountName: event.target.value
                        })} value={selectedRecord.accountName || ''} autoComplete="off"/>
                    {validator.message(
                        "account-name",
                        selectedRecord.accountName,
                        "required"
                    )}
                    {
                        validatorStatus ? (<span className="text-xs text-red-700"
                                                 id="name">{getValidatorError("account-name")}</span>) : ''
                    }
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="username">
                        Account Number
                    </label>
                    <input
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        id="accountNumber" type="text" name="account-number" placeholder="Account Number"
                        onChange={(event) => setSelectedRecord({
                            ...selectedRecord,
                            accountNumber: event.target.value
                        })} value={selectedRecord.accountNumber || ''} autoComplete="off"/>
                    {validator.message(
                        "account-number",
                        selectedRecord.accountNumber,
                        "required"
                    )}
                    {
                        validatorStatus ? (<span className="text-xs text-red-700"
                                                 id="name">{getValidatorError("account-number")}</span>) : ''
                    }
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="username">
                       IFSC
                    </label>
                    <input
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        id="ifsc" type="text" name="ifsc" placeholder="IFSC"
                        onChange={(event) => setSelectedRecord({
                            ...selectedRecord,
                            ifsc: event.target.value
                        })} value={selectedRecord.ifsc || ''} autoComplete="off"/>
                    {validator.message(
                        "ifsc",
                        selectedRecord.ifsc,
                        "required"
                    )}
                    {
                        validatorStatus ? (<span className="text-xs text-red-700"
                                                 id="name">{getValidatorError("ifsc")}</span>) : ''
                    }
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="username">
                        Party Type
                    </label>
                    <select
                        id="state"
                        name="party-type"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        onChange={(event) => setSelectedRecord({
                            ...selectedRecord,
                            partyType: event.target.value
                        })} value={selectedRecord.partyType || ''}
                    >
                        <option value="" disabled>Select a Party Type</option>
                        <option value="COMPANY">COMPANY</option>
                    </select>
                    {validator.message(
                        "party-type",
                        selectedRecord.partyType,
                        "required"
                    )}
                    {
                        validatorStatus ? (<span className="text-xs text-red-700"
                                                 id="name">{getValidatorError("party-type")}</span>) : ''
                    }
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="username">
                        Remark
                    </label>
                    <textarea
                        rows={3}
                        id="remarks"
                        name="remarks"
                        className="border p-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder={'Remark'}
                        onChange={(event) => setSelectedRecord({
                            ...selectedRecord,
                            remarks: event.target.value
                        })} value={selectedRecord.remarks || ''}
                    />
                    {validator.message(
                        "remarks",
                        selectedRecord.remarks,
                        ('ADD' === status) ? "required" : ""
                    )}
                    {
                        validatorStatus ? (<span className="text-xs text-red-700"
                                                 id="name">{getValidatorError("remarks")}</span>) : ''
                    }
                </div>

                <div className="flex items-center justify-between flex-row-reverse mb-1.5">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button" onClick={() => {
                        if ('ADD' === status) {
                            addNew()
                        } else {
                            updateBankDetails()
                        }
                    }}
                    >
                        {('ADD' === status) ? 'SAVE' : 'UPDATE'}
                    </button>
                </div>
            </form>
        </div>);
    }

    const addNew = async () => {
        setValidator(true);
        if (validator.allValid()) {
            setLoading(true);
            await BankDetailsService.addNewPartner(selectedRecord, tokens).then((response: any) => {
                toast.success('Record Added Successfully');
                setModalStatus(false)
                getDetailsList(0);
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                setModalStatus(false);
                toast.error('Something went wrong!');
            });
            setValidator(false);
        }
    }

    useEffect(() => {
        getDetailsList(0);
    }, []);

    const paginationFilter = async (page: number) => {
        setCurrentPage(page)
        await getDetailsList(page);
    }

    return (
        <Layout type={"MasterModule"}>
            <ToastContainer/>
            <div className="flex justify-between items-center mb-5">
                <h1 className="text-2xl font-bold tracking-tight">
                    {"Banks"}
                </h1>
                {
                    HasAnyAccess(['bank.details.create']) && (
                        <button
                            className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
                            type="button"
                            onClick={() => {
                                addModalElements()
                            }}
                        >
                            + Add Bank Details
                        </button>)}
            </div>

            <div className="border bg-white rounded border-gray-200 shadow">
                <div className="border bg-white rounded border-gray-200 shadow m-2">
                    {Loading ? (<Loader/>) : (
                        <div>
                            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                                <thead>
                                <tr className="truncate text-left border-b border-gray-300">
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Code</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Bank Name</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Account Details</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800">Account Type</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">IFSC</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Party Type</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">vaMaster</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Remark</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Created At</th>
                                    {
                                        HasAnyAccess(['bank.details.edit','bank.details.delete']) && (
                                            <>
                                                <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Action</th>
                                            </>
                                        )}
                                </tr>
                                </thead>
                                <tbody>
                                {(0 !== bankDetails.length) ?
                                    bankDetails.map((data: any, index: number) => {
                                        return (
                                            <tr key={index}
                                                className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                <td className="py-3 px-3 text-left relative">
                                                    {data.code}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {data.bankName}
                                                </td><td className="py-3 px-3 text-left relative">
                                                    <div>{data.accountName}</div>
                                                    <div className="font-bold"> {data.accountNumber}</div>
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {data.accountType}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {data.ifsc}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {data.partyType}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {data.vaMaster}
                                                    <span
                                                        className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${data.vaMaster ? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>
                                                        {data.vaMaster.toString()}
                                                    </span>
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {data.remarks}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {moment(data.createdAt).format("DD-MM-YYYY HH:mm")}
                                                </td>
                                                {
                                                    HasAnyAccess(['bank.details.edit']) && (
                                                        <td className="flex py-3 px-3 text-left relative">
                                                            {
                                                                HasAnyAccess(['bank.details.edit']) && (
                                                            <button
                                                                className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                                type="button"
                                                                onClick={() => {
                                                                    editModalElements(data)
                                                                }}
                                                            >
                                                                Edit
                                                            </button>)}
                                                            {/*{*/}
                                                            {/*    HasAnyAccess(['bank.details.delete']) && (*/}
                                                            {/*    permissions.filter((permission: any) => (permission.permissionCode === 'bank.details.delete')).length > 0 && (*/}
                                                            {/*<button*/}
                                                            {/*    className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"*/}
                                                            {/*    type="button"*/}
                                                            {/*    onClick={() => {*/}
                                                            {/*        deleteDetails(data)*/}
                                                            {/*    }}*/}
                                                            {/*>*/}
                                                            {/*    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z"/></svg>*/}
                                                            {/*</button>)}*/}
                                                        </td>)}
                                            </tr>
                                        )
                                    })
                                    : (<tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                        <td colSpan={15} className="text-center py-3 px-3 text-left relative">No data to
                                            display
                                        </td>
                                    </tr>)
                                }
                                </tbody>
                            </table>
                            <Modal size={''} show={show} handleClose={hideModal} children={children}/>
                        </div>

                    )}
                    <Pagination
                        meta={meta}
                        handlePageChange={(number: number) => {
                            paginationFilter(number)
                        }}
                    />
                </div>
            </div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed inset-0 z-10 overflow-y-auto"
                    onClose={() => null}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Dialog.Overlay className="fixed inset-0 opacity-50  bg-black "/>
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
              &#8203;
            </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div
                                className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Please Confirm!
                                </Dialog.Title>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Are you sure you want to delete this record?
                                    </p>
                                </div>

                                <div className="mt-4">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                        onClick={() => {
                                            deleteElement()
                                        }}
                                    >
                                        Yes, please!
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center ml-2 px-4 py-2 text-sm font-medium text-blue-900 bg-transparent border border-blue-100 rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                        onClick={()=>{
                                            setIsOpen(false);
                                        }}
                                    >
                                        No
                                    </button>
                                </div>

                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </Layout>
    );
};

export default BankDetails;
