import React, { Fragment } from "react";
import { Route } from "react-router-dom";

import BulkUpload from "./BulkUpload";
import ExportCSV from "./ExportCSV";

const BulkUploadRoutes: React.FC = () => {
  return (
    <Fragment>
      <Route path="/bulk-upload" exact component={BulkUpload} />
      <Route path="/bulk-download/:id" exact component={ExportCSV} />
    </Fragment>
  );
};

export default BulkUploadRoutes;
