import React, {useEffect, useState} from "react";
import Layout from "../../Layout/Layout";
import {useParams} from "react-router-dom";
import OrderService from "../Services/order.service";
import {useAuthStore} from "../../../Store/AuthStore";
import moment from "moment";
import Loader from "../../../Components-ui/Loader";
import DataInput from "../Components/DataInput"
import MainItemList from "../Components/MainItemList"
import ItemTable from "./ItemTable";


interface PropsType {
}

const OrderView: React.FC<PropsType> = () => {
    let {id} = useParams<any>();

    const { tokens } = useAuthStore();

    const [Loading, setLoading] = useState<any>(true);
    const [OrderData, setOrderData] = useState<any>({});

    useEffect((): any => {
        getOrderDetails();
        // eslint-disable-next-line
    }, []);

    /**
     * Get order details by id
     */
    const getOrderDetails = async () => {
        let data: any = await OrderService.getOrderDetails(id, tokens);
        if (data.status === 200 && data.data.data) {
            let responseData = data.data.data;
            setOrderData(responseData)
            console.log(responseData, "responseData")
            // setLoading(false)
        }
        setLoading(false)
    };

    return (
        <Layout type={"TradeExecution"}>

            {
                Loading ? <Loader/> : (
                    <div
                        className="w-full rounded border-gray-200 overflow-hidden shadow-lg dark:border-gray-700 bg-white">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Order Details - ({id})</h3>
                        </div>
                        <hr/>
                        <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
                            <div className="col-span-12 sm:col-span-4">
                                <DataInput
                                    label="Order Ref"
                                    value={OrderData.ebOrderRef}
                                    id="orderRef"
                                />
                            </div>
                            <div className="col-span-12 sm:col-span-4">
                                <DataInput
                                    label="Quote Ref"
                                    value={OrderData.ebQuoteRef}
                                    id="quoteRef"
                                />
                            </div>
                            <div className="col-span-12 sm:col-span-4">
                                <DataInput
                                    label="Currency"
                                    value={OrderData.currency}
                                    id="currency"
                                />
                            </div>
                            <div className="col-span-12 sm:col-span-4">
                                <DataInput
                                    label="Type"
                                    value={OrderData.type}
                                    id="type"
                                />
                            </div>

                            <div className="col-span-12 sm:col-span-4">
                                <DataInput
                                    label="Quotation Total"
                                    value={OrderData.quotationTotal}
                                    id="quotationTotal"
                                />
                            </div>
                            <div className="col-span-12 sm:col-span-4">
                                <DataInput
                                    label="Created Date"
                                    value={moment(OrderData.createdAtUnix).format("DD-MM-YYYY HH:mm")}
                                    id="createdAt"
                                />
                            </div>

                            <div className="col-span-12 sm:col-span-4">
                                <DataInput
                                    label="Quoted Date"
                                    value={moment(OrderData.quotedAt).format("DD-MM-YYYY HH:mm")}
                                    id="quotedAt"
                                />
                            </div>
                            <div className="col-span-12 sm:col-span-4">
                                <DataInput
                                    label="Expired Date"
                                    value={moment(OrderData.validUntil).format("DD-MM-YYYY HH:mm")}
                                    id="validUntil"
                                />
                            </div>
                            <div className="col-span-12 sm:col-span-4">
                                <DataInput
                                    label="Status"
                                    value={OrderData.status}
                                    id="status"
                                />
                            </div>

                            <div className="col-span-12 sm:col-span-12">
                                <div className="py-2">
                                    <h4 className="text-md leading-6 font-medium text-gray-500 border-b">Customer Details</h4>
                                </div>
                                <div className="pt-3 grid grid-cols-12 gap-5">
                                    <div className="col-span-12 sm:col-span-4">
                                        <DataInput
                                            label="Name"
                                            value={OrderData.customer?.displayName}
                                            id="name"
                                        />
                                    </div>
                                    <div className="col-span-12 sm:col-span-4">
                                        <DataInput
                                            label="Type"
                                            value={OrderData.customer?.customerType}
                                            id="customerType"
                                        />
                                    </div>
                                    <div className="col-span-12 sm:col-span-4">
                                        <DataInput
                                            label="Category"
                                            value={OrderData.customer?.customerCategory}
                                            id="category"
                                        />
                                    </div>
                                    <div className="col-span-12 sm:col-span-4">
                                        <DataInput
                                            label="Id"
                                            value={OrderData.customer?.customerId}
                                            id="customerId"
                                        />
                                    </div>
                                    <div className="col-span-12 sm:col-span-4">
                                        <DataInput
                                            label="Account"
                                            value={OrderData.accountId}
                                            id="accountId"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-12 sm:col-span-12">
                                <div className="py-2">
                                    <h4 className="text-md leading-6 font-medium text-gray-500">MAIN ITEMS</h4>
                                </div>
                                <div className="border rounded border-gray-200">
                                    <table
                                        className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                                        <thead>
                                        <tr className="text-left border-b border-gray-300">
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800">Item Code</th>
                                            {/*<th className="sticky top-0 px-3 py-2 bg-gray-800">Quantity</th>*/}
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800">UOM</th>
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800">Requested Value</th>
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800">Requested Type</th>
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800">Total Line Amount</th>
                                        </tr>
                                        </thead>
                                        <tbody className="text-gray-800 text-sm font-light accordion accordion-flush"
                                               id="mainItemAccordion">

                                        {
                                            OrderData.items.map((mainItem:any, key: number) => {
                                                return (
                                                    <>
                                                        <MainItemList
                                                            key={key}
                                                            item={mainItem}
                                                            mainItemKey={key}
                                                            parentId="mainItemAccordion"
                                                        />
                                                    </>
                                                )
                                            })
                                        }

                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            {
                                OrderData.invoiceTaxes.length > 0 && (
                                    <div className="col-span-12 sm:col-span-12">
                                        <div className="py-2">
                                            <h4 className="text-md leading-6 font-medium text-gray-500">INVOICE TAXES</h4>
                                        </div>
                                        <div className="border rounded border-gray-200">
                                            <ItemTable
                                                columns={[{label: 'Sub Type', value: 'subTaxType'}, {label: 'Value', value: 'value'}]}
                                                itemList={OrderData.invoiceTaxes}
                                                type='invoice_taxes'
                                            />
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                )
            }

        </Layout>
    )
}

export default OrderView;
