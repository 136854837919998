import { makeQuery } from "../../Common/util.helper";
import Http from "../../library/http.library";
import { httpMethods } from "../../library/enum";

const getBarSummaryData = async (tokens: any, filter: any, page: any) => {
  var query:any = {
    isPaginated: filter.isPaginated,
    ...(filter.warehouseId ? { warehouseId: filter.warehouseId } : {}),
    ...(filter.metalCode ? { metalCode: filter.metalCode } : {}),
    ...(filter.itemCode ? { itemCode: filter.itemCode } : {}),
    ...(filter.barNo ? { barNo: filter.barNo } : {}),
    ...(filter.weight ? { weight: filter.weight } : {}),
    ...(filter.status ? { status: filter.status } : {}),
    ...(filter.trxLock ? { trxLock: filter.trxLock } : {}),
  };
  const params = makeQuery(query);
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/bar-summary?page=${page}${params}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

const getMetalMasterData = async (tokens: any, filter: any) => {
  const params = makeQuery(filter);
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/metal-master?${params}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

const  lockUnlockBar = async (tokens: any, id:number, data:any)=>{
  return Http.setMethod(httpMethods.patch).setUrl(`/bar-summary/${id}`).setData(data).setToken(tokens.idToken.jwtToken).request();
}

const BarSummaryService = {
  lockUnlockBar,
  getBarSummaryData,
  getMetalMasterData,
};

export default BarSummaryService;
