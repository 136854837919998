import React, {useEffect, useState} from "react";
import Loader from "../../../Components-ui/Loader"
import {toast} from "react-toastify";
import TaxDetailsService from "../Services/tax-details.service";
import SimpleReactValidator from "simple-react-validator";
import Datetime from "react-datetime";
import moment from "moment";
import {DATA_TYPE} from "../Utils/enum";
import {useAuthStore} from "../../../Store/AuthStore";

interface PropsType {
    onCloseModal: any,
    taxDetailsModel: any,
    onChangeTax: any,
    validatorRef: any,
    getAllTaxDetails: any,
    hsn:any,
    taxes: any,
    taxCategories: any,
    page: number
}

const TaxCategoryForm: React.FC<PropsType> = ({
          onCloseModal,
          taxDetailsModel,
          onChangeTax,
          validatorRef,
          getAllTaxDetails,
          hsn,
          taxes,
          taxCategories,
          page
      }) => {

    const { tokens } = useAuthStore();

    const [ValidatorStatus, setValidatorStatus] = useState<boolean>(false);

    const [Loading, setLoading] = useState<any>(false);

    const simpleValidator = new SimpleReactValidator({
        locale: "en",
        validators: {},
    });

    const validationRef = React.useRef(simpleValidator);
    const validator = validationRef.current;


    useEffect(() => {
        if (taxDetailsModel.hasOwnProperty('id')) {
            setValidatorStatus(true)
        }
    }, [taxDetailsModel]);

    /**
     * Get validation error messages
     * @param nameOfStateProp
     */
    const getValidatorError = (nameOfStateProp: any) => {
        const allErrorMessages = validator.getErrorMessages();
        return allErrorMessages[nameOfStateProp];
    };


    /**
     * Add or update tax category details
     * @param e
     */
    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (!validatorRef || !validator.allValid()) {
            setValidatorStatus(true)
            return toast.error("Please make sure all the inputs are valid.");
        }

        try {
            setLoading(true)
            if (taxDetailsModel.hasOwnProperty('id')) {
                await TaxDetailsService.updateTaxDetails(taxDetailsModel, tokens);
                await getAllTaxDetails(page);
                setLoading(false);
                onCloseModal()
                return toast.success("Tax Details was successfully updated");
            } else {
                await TaxDetailsService.addTaxDetails(taxDetailsModel, tokens);
                await getAllTaxDetails(page);
                setLoading(false);
                onCloseModal()
                return toast.success("Tax Details was successfully added");
            }

        } catch (error: any) {
            setLoading(false);
            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                console.log(error.response.data.errors[0]);
                toast.error(error.response.data.errors[0].title);
            }
        }
    }

    return (
        <>
            {
                Loading ? <Loader/> : (
                    <div
                        className=" w-full bg-gray-800 bg-opacity-50 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-4xl">
                            <div
                                className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
                                <div
                                    className="items-start justify-between px-6 py-4 border-b border-solid border-blue bg-gray-100 rounded-t">
                                    <h3 className="text-xl font-semibold">{taxDetailsModel.hasOwnProperty('id') ? 'Tax Details' : 'Add New Tax Details'}</h3>
                                </div>

                                <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="masterId"
                                               className="block text-sm font-medium text-gray-700">
                                            Tax Master
                                        </label>
                                        <div className="mt-1">
                                            <select
                                                id="masterId"
                                                name="masterId"
                                                value={taxDetailsModel?.masterId ?? ""}
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                onChange={(e: any) => {
                                                    onChangeTax(e, DATA_TYPE.NORMAL)
                                                }}
                                            >
                                                <option value="" disabled>Select a tax</option>
                                                {
                                                    taxes.map((t: any, index: number) => {
                                                        return (
                                                            <option key={index} value={t.id}>{t.taxCode}</option>
                                                        )

                                                    })
                                                }
                                            </select>
                                            {validator.message(
                                                "masterId",
                                                taxDetailsModel?.masterId,
                                                "required"
                                            )}
                                            {
                                                ValidatorStatus ?
                                                    <span className="text-xs text-red-700"
                                                          id="subTaxType">{getValidatorError("masterId")}</span> : ""
                                            }
                                        </div>
                                    </div>
                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="categoryId"
                                               className="block text-sm font-medium text-gray-700">
                                            Tax Category
                                        </label>
                                        <div className="mt-1">
                                            <select
                                                id="categoryId"
                                                name="categoryId"
                                                value={taxDetailsModel?.categoryId ?? ""}
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                onChange={(e: any) => {
                                                    onChangeTax(e, DATA_TYPE.NORMAL)
                                                }}
                                            >
                                                <option value="" disabled>Select a tax category</option>
                                                {
                                                    taxCategories.map((category: any, index: number) => {
                                                        return (
                                                            <option key={index} value={category.id}>{category.name}</option>
                                                        )

                                                    })
                                                }
                                            </select>
                                            {validator.message(
                                                "categoryId",
                                                taxDetailsModel?.categoryId,
                                                "required"
                                            )}
                                            {
                                                ValidatorStatus ?
                                                    <span className="text-xs text-red-700"
                                                          id="subTaxType">{getValidatorError("categoryId")}</span> : ""
                                            }
                                        </div>
                                    </div>
                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="subTaxType"
                                               className="block text-sm font-medium text-gray-700">
                                            Sub Tax Type
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="subTaxType"
                                                name="subTaxType"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                value={taxDetailsModel?.subTaxType ?? ""}
                                                onChange={(e: any) => {
                                                    onChangeTax(e, DATA_TYPE.NORMAL)
                                                }}
                                            />
                                            {validator.message(
                                                "subTaxType",
                                                taxDetailsModel?.subTaxType,
                                                "required"
                                            )}
                                            {
                                                ValidatorStatus ?
                                                    <span className="text-xs text-red-700"
                                                          id="subTaxType">{getValidatorError("subTaxType")}</span> : ""
                                            }

                                        </div>
                                    </div>
                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="hsnId"
                                               className="block text-sm font-medium text-gray-700">
                                            HSN Id
                                        </label>
                                        <div className="mt-1">
                                            <select
                                                id="hsnId"
                                                name="hsnId"
                                                value={taxDetailsModel?.hsnId ?? ""}
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                onChange={(e: any) => {
                                                    onChangeTax(e, DATA_TYPE.NORMAL)
                                                }}
                                            >
                                                <option value="" disabled>Select a hsn</option>
                                                {
                                                    hsn.map((hsn: any, index: number) => {
                                                        return (
                                                            <option key={index} value={hsn.id}>{hsn.hsnCode}</option>
                                                        )

                                                    })
                                                }
                                            </select>
                                            {validator.message(
                                                "hsnId",
                                                taxDetailsModel?.hsnId,
                                                "required"
                                            )}
                                            {
                                                ValidatorStatus ?
                                                    <span className="text-xs text-red-700"
                                                          id="hsnId">{getValidatorError("hsnId")}</span> : ""
                                            }
                                        </div>
                                    </div>


                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="from" className="block text-sm font-medium text-gray-700">
                                            From Date
                                        </label>
                                        <Datetime
                                            timeFormat={false}
                                            value={moment(taxDetailsModel?.from)}
                                            inputProps={{className: "focus:outline-none w-full"}}
                                            dateFormat={"YYYY-MM-DD"}
                                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            onChange={(e: any) => {
                                                onChangeTax(e, DATA_TYPE.START_DATE)
                                            }}

                                        />
                                    </div>

                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="to" className="block text-sm font-medium text-gray-700">
                                            To Date
                                        </label>
                                        <Datetime
                                            timeFormat={false}
                                            value={moment(taxDetailsModel?.to)}
                                            inputProps={{className: "focus:outline-none w-full"}}
                                            dateFormat={"YYYY-MM-DD"}
                                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            onChange={(e: any) => {
                                                onChangeTax(e, DATA_TYPE.END_DATE)
                                            }}

                                        />
                                    </div>

                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="value"
                                               className="block text-sm font-medium text-gray-700">
                                            Value
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                min="0"
                                                type="number"
                                                id="value"
                                                name="value"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                value={taxDetailsModel?.value ?? ""}
                                                onChange={(e: any) => {
                                                    onChangeTax(e, DATA_TYPE.NORMAL)
                                                }}
                                            />
                                            {validator.message(
                                                "value",
                                                taxDetailsModel?.value,
                                                "required"
                                            )}
                                            {
                                                ValidatorStatus ?
                                                    <span className="text-xs text-red-700"
                                                          id="type">{getValidatorError("value")}</span> : ""
                                            }

                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="flex items-center justify-end sm:py-3  sm:px-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <div>
                                        <button
                                            id="modal"
                                            className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={onCloseModal}
                                        >
                                            Close
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                                            type="button"
                                            onClick={(e) => handleSubmit(e)}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default TaxCategoryForm;
