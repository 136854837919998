import React, { useState, useEffect } from "react";
import { useAuthStore } from "../../../Store/AuthStore";
import Layout from "../../Layout/Layout";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import MetalInterchangeService from "../MetalInterchangeService";
import Modal from "../../../Components-ui/Modal/Modal";
import { Pagination } from "../../../Components-ui/Pagination";
import moment from "moment";

const MetalInterchangeApprove: React.FC = () => {
  const [meta, setMetaData] = useState<any>({});
  const [Loading, setLoading] = useState<any>(false);
  const [show, setModalStatus] = useState<any>(false);
  const [children, setChildren] = useState<any>();
  const [array, setArray] = useState<any>([]);
  const [barList, setBarList] = useState<any>([]); //modal
  const [exchangeMaster, setExchangeMaster] = useState<any>([]);
  const [exchangeDetails, setExchangeDetails] = useState<any>([]);
  const [metalList, setMetalList] = useState<any>([]);
  const [vaultList, setVaultList] = useState<any>([]);
  const [selectedRecordList, setSelectedRecordList] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isApprove, setIsApprove] = useState<boolean>(false);
  const [approveChildren, setApproveChildren] = useState<any>();
  const [filters, setFilters] = useState<any>({
    vaultId: "",
    pool: "",
    metalCode: "",
    status: "",
    type: "",
  });
  const { logout, permissions, userData, tokens } = useAuthStore();

  const hideModal = () => {
    setModalStatus(false);
    setIsApprove(false);
  };

  useEffect(() => {
    loadVaults();
    getMetals();
  }, []);

  useEffect(() => {
    loadData();
  }, [1]);

  // load first time data
  const loadData = async () => {
    try {
      setLoading(true);
      let allExchangeMaster: any = await MetalInterchangeService.getVsoBarList(
        tokens,
        filters
      );

      setExchangeMaster(allExchangeMaster.data.data);
      setMetaData({
        current: allExchangeMaster.data.meta.page,
        pages: allExchangeMaster.data.meta.totalPages,
      });
      setCurrentPage(0);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something Went Wrong!");
      console.error(error);
    }
  };

  // get metal name
  const getMetals = async () => {
    await MetalInterchangeService.getAllMetals(tokens)
      .then((response: any) => {
        setMetalList(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);

        setLoading(false);
        toast.error(err.data.errors[0].title);
      });
  };

  const selectMultipleExchangeRecord = async (master: any) => {
    if (selectedRecordList.some((ele: any) => ele.exchangeId === master.id)) {
      setSelectedRecordList(
        selectedRecordList.filter((x: any) => x.exchangeId !== master.id)
      );
    } else {
      setSelectedRecordList([
        ...selectedRecordList,
        {
          exchangeId: master.id,
          type: master.type,
        },
      ]);
    }
  };

  const viewDetails = (id: any) => {
    setLoading(true);
    MetalInterchangeService.getVsoBarList(tokens, { id: id }).then(
      (response: any) => {
        setExchangeDetails(response.data.data[0]);
        setLoading(false);
        setModalStatus(true);
      }
    );
  };

  const childrenElements = () => {
    setChildren(
      <div className="w-full">
        <h1 className="font-bold">{"From Bars"}</h1>
        <form className="bg-white rounded pt-6 pb-8 mb-4">
          <div className="mb-4">
            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
              <thead>
                <tr className="text-left border-b border-gray-300">
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Bar No
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Brand Name
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Metal Code
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    item Code
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Equivalent Weight
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Allocated Weight
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Weight
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Warehouse
                  </th>
                </tr>
              </thead>
              <tbody>
                {exchangeDetails.fromBars &&
                0 !== exchangeDetails.fromBars.length ? (
                  exchangeDetails.fromBars.map((bar: any, index: number) => {
                    return (
                      <tr
                        key={index}
                        className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                      >
                        <td className="py-3 px-3 text-left relative">
                          {bar.barNo}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.brandName}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.metalCode}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.itemCode}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.equivalentWeight}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.allocatedWeight}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.weight}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.warehouseId}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                    <td
                      colSpan={9}
                      className="text-center py-3 px-3 text-left relative"
                    >
                      No data to display
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </form>
        <h1 className="font-bold">{"To Bars"}</h1>
        <form className="bg-white rounded pt-6 pb-8 mb-4">
          <div className="mb-4">
            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
              <thead>
                <tr className="text-left border-b border-gray-300">
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Bar No
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Brand Name
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Metal Code
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    item Code
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Equivalent Weight
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Allocated Weight
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Weight
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Warehouse
                  </th>
                </tr>
              </thead>
              <tbody>
                {exchangeDetails.toBars &&
                0 !== exchangeDetails.toBars.length ? (
                  exchangeDetails.toBars.map((bar: any, index: number) => {
                    return (
                      <tr
                        key={index}
                        className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                      >
                        <td className="py-3 px-3 text-left relative">
                          {bar.barNo}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.brandName}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.metalCode}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.itemCode}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.equivalentWeight}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.allocatedWeight}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.weight}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {bar.warehouseId}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                    <td
                      colSpan={9}
                      className="text-center py-3 px-3 text-left relative"
                    >
                      No data to display
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </form>
      </div>
    );
  };

  const closeApproveModal = () => {
    setIsApprove(false);
  };
  const approveModal = () => {
    setApproveChildren(
      <div className="w-full m-5 p-5 text-center">
        <p>Are you sure you want to approve these records?</p>
        <div className="d-flex justify-center  ">
          <button
            className="text-white rounded-md bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
            type="button"
            onClick={() => {
              approveRejectRecords(true);
            }}
          >
            Yes
          </button>
          <button
            className="text-white m-3 rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
            type="button"
            onClick={() => {
              closeApproveModal();
            }}
          >
            No
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    approveModal();
  }, [isApprove]);

  useEffect(() => {
    childrenElements();
  }, [barList, show, array]);

  const approveRejectRecords = async (approve: boolean) => {
    console.log(exchangeMaster);

    if (selectedRecordList.length === 0) {
      toast.error("Select records");
    } else {
      let payload = selectedRecordList.map((v: any) => ({
        ...v,
        approve: approve,
      }));

      setLoading(true)
      await MetalInterchangeService.approveMultipleBars(tokens, payload)
        .then((response: any) => {
          toast.success(`Bars ${approve ? "Approved" : "Reject"} Successfully`);
          loadData();
          setIsApprove(false);
      setLoading(false)

        })
        .catch((err) => {
          console.log(err);
          toast.error(`Bars ${approve ? "Approved" : "Reject"} Failed`);
          setIsApprove(false);
      setLoading(false)

        });
    }
  };

  const filter = async (page: any) => {
    setLoading(true);

    filters.page = page;

    await MetalInterchangeService.getVsoBarList(tokens, filters)
      .then((response: any) => {
        setExchangeMaster(response.data.data);
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.data.errors[0].title);
      });
  };

  const reset = async () => {
    filters.vaultId = "";
    filters.pool = "";
    filters.metalCode = "";
    filters.status = "";
    filters.type = "";
    filter(0);
  };

  const loadVaults = async (): Promise<void> => {
    return new Promise(async (resolve) => {
      MetalInterchangeService.getVaults(tokens)
        .then((response: any) => {
          setVaultList(response.data.data);
          resolve();
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
          resolve();
        });
    });
  };

  const paginationFilter = async (page: number) => {
    setCurrentPage(page);
    filter(page);
  };

  return (
    <Layout type={"MetalInterchangeModule"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">
          {"Metal Interchange List"}
        </h1>
      </div>
      <div className="border bg-white rounded border-gray-200 shadow">
        <div className="flex-1 p-2">
          <form
            onSubmit={(e) => {
              filter(0);
              e.preventDefault();
            }}
          >
            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="partner-name"
                >
                  Vault ID
                </label>
                <select
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="user-type"
                  onChange={(event) => {
                    setFilters({
                      ...filters,
                      vaultId: event.target.value,
                    });
                  }}
                  value={filters.vaultId}
                >
                  <option value="">Select a Vault</option>
                  {vaultList.map((vault: any) => (
                    <option value={vault.id}>{vault.warehouseId}</option>
                  ))}
                </select>
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="pool"
                >
                  Pool
                </label>
                <select
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="pool"
                  onChange={(event) => {
                    setFilters({
                      ...filters,
                      pool: event.target.value,
                    });
                  }}
                  value={filters.pool}
                >
                  <option value="">Select a From Pool</option>
                  <option value="Customer">Customer Pool</option>
                  <option value="EBPL">EBL Pool</option>
                </select>
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="status"
                >
                  Status
                </label>
                <select
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="status"
                  onChange={(event) => {
                    setFilters({
                      ...filters,
                      status: event.target.value,
                    });
                  }}
                  value={filters.status}
                >
                  <option value="">Select a Status</option>
                  <option value="CREATED">Created</option>
                  <option value="START">Start</option>
                  <option value="PENDING">Pending</option>
                  <option value="COMPLETE">Completed</option>
                  <option value="REJECTED">Rejected</option>
                </select>
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="type"
                >
                  Type
                </label>
                <select
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="type"
                  onChange={(event) => {
                    setFilters({
                      ...filters,
                      type: event.target.value,
                    });
                  }}
                  value={filters.type}
                >
                  <option value="">Select a Type</option>
                  <option value="BAR_TO_BAR">BAR_TO_BAR</option>
                  <option value="QUANTITY_TO_BAR">QUANTITY_TO_BAR</option>
                </select>
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="partner-name"
                >
                  Metal
                </label>
                <select
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="user-type"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      metalCode: event.target.value,
                    })
                  }
                  value={filters.metalCode}
                >
                  <option value="">Select a Metal</option>
                  {metalList.length > 0 &&
                    metalList.map((b: any) => (
                      <option value={b.metalCode}>{b.description}</option>
                    ))}
                </select>
              </div>
              <div className="w-full md:w-2/4 px-3 mb-6 md:mb-0">
                <div className="my-6">
                  {/* <button
                                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                                    type="button"
                                    onClick={() => {
                                        filter(0);
                                    }}
                                >
                                    Filter
                                </button> */}
                  <input
                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                    type="submit"
                    value="Filter"
                  />
                  <button
                    className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      reset();
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="m-2 rounded">
          {Loading ? (
            <Loader />
          ) : (
            <div>
              <div>
                <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                  <thead>
                    <tr className="text-left border-b border-gray-300">
                      <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10"></th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                        Type
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                        Status
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                        Vault Id
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                        Metal Code
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                        Pool
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                        Customer Id
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                        Quantity
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                        VSO
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                        Approved At
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                        Details
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {0 !== exchangeMaster.length ? (
                      exchangeMaster.map((master: any, index: number) => {
                        return (
                          <tr
                            key={index}
                            className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                          >
                            <td className="py-3 px-3 text-left relative">
                              <input
                                type="checkbox"
                                onClick={() => {
                                  selectMultipleExchangeRecord(master);
                                }}
                                disabled={
                                  master.status == "REJECTED" ? true : false
                                }
                              />
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {master.type}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {master.status}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {master.vaultId}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {master.metalCode}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {master.pool}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {master.customerId}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {master.quantity}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {master.vso}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {master.approvedAt
                                ? moment(master.approvedAt).format(
                                    "DD-MM-YYYY HH:mm"
                                  )
                                : ""}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              <button
                                className="pr-0 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                onClick={() => {
                                  viewDetails(master.id);
                                }}
                              >
                                <svg
                                  className="w-4 h-4 mr-3 fill-current"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                        <td
                          colSpan={11}
                          className="text-center py-3 px-3 text-left relative"
                        >
                          No data to display
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Pagination
                  meta={meta}
                  handlePageChange={(number: number) => {
                    paginationFilter(number);
                  }}
                />
              </div>
              <div>
                {0 !== exchangeMaster.length ? (
                  <div className="flex items-center  flex-row-reverse">
                    <button
                      className="ml-1.5 text-white bg-purple-950 background-transparent font-bold px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                      type="button"
                      onClick={() => {
                        approveRejectRecords(false);
                      }}
                    >
                      {"Reject selected Record"}
                    </button>
                    <button
                      className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold px-3 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        // approveRejectRecords(true);
                        setIsApprove(true);
                      }}
                    >
                      {"Approve selected Record"}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        size={"large"}
        show={show}
        handleClose={hideModal}
        children={children}
      />
      <Modal
        size={"large"}
        show={isApprove}
        handleClose={hideModal}
        children={approveChildren}
      />
    </Layout>
  );
};

export default MetalInterchangeApprove;