import Layout from "../Layout/Layout";
import React, {useEffect, useState} from "react";
import {ToastContainer, toast} from "react-toastify";
import "./react-confirm-alert.css";
import Loader from "../../Components-ui/Loader";
import moment from "moment";
import {Pagination} from "../../Components-ui/Pagination";
import {useHistory} from "react-router-dom";
import Modal from "../../Components-ui/Modal/Modal";
import {useAuthStore} from "../../Store/AuthStore";
import EbReferenceMasterService from "./EbReferenceAndDetails.Service";
import HasAnyAccess from "../../Components-ui/hasAnyAccess";

const EbMasterTable: React.FC = () => {
    const history = useHistory();
    const { logout, permissions, userData , tokens } = useAuthStore();
    const [meta, setMetaData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [referenceMaster, setReferenceMaster] = useState<any>([]);
    const [show, setModalStatus] = useState<any>(false);
    const [children, setChildren] = useState<any>();
    const [referenceDetails, setReferenceDetails] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [filters, setFilters] = useState<any>({
        codeName: '',
        codeDescription: '',
        active: '',
    });

    const hideModal = () => {
        setModalStatus(false);
    };
    useEffect(() => {
        loadData(0);
        console.log(permissions);
    }, [1]);

    useEffect(() => {
        childrenElements();
    }, [referenceDetails]);

    const loadData = async (page: number) => {
        try {
            setLoading(true);
            let allReferenceMaster: any = await EbReferenceMasterService.GetReferenceMasterData(tokens, page);
            setReferenceMaster(allReferenceMaster.data.data.result);
            setMetaData({
                current: allReferenceMaster.data.meta.page,
                pages: allReferenceMaster.data.meta.totalPages,
            });
            setCurrentPage(page);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("Something Went Wrong!");
        }
    };

    const changeStatus = (status: any, key: any, id: any) => {
        let newArr = [...referenceMaster];
        newArr[key].active = ('ENABLED' !== status) ? 'ENABLED' : 'DISABLED'
        setReferenceMaster(newArr);
        updateReferenceData(id, newArr[key]);
    }


    const viewDetails = (id: any) => {
        setModalStatus(true)
        EbReferenceMasterService.GetSingleReferenceMasterRecord(id, tokens).then((response: any) => {
            setReferenceDetails(response.data.data.result[0].details);
        });
    }

    const childrenElements = () => {
        setChildren(<div className="w-full">
            <h1 className="font-bold">
                {'Reference Details'}
            </h1>
            <div className="flex-row w-auto">
                <div className="mb-4">
                    <table
                        className="table rounded w-auto mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                        <thead>
                        <tr className="text-left border-b border-gray-300">
                            <th className="sticky top-0 px-3 py-2 bg-gray-800">Code Value</th>
                            <th className="sticky top-0 px-3 py-2 bg-gray-800">Value Description
                            </th>
                            <th className="sticky top-0 px-3 py-2 bg-gray-800">Value Remark</th>
                            <th className="sticky top-0 px-3 py-2 bg-gray-800">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {(0 !== referenceDetails.length) ? referenceDetails.map((item: any, idx: number) => (
                            <tr className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                                id="addr0" key={idx}>
                                <td className="py-3 px-3 text-left relative">
                                    {item.codeValue}
                                </td>
                                <td className="py-3 px-3 text-left relative">
                                    {item.valueDescription}
                                </td>
                                <td className="py-3 px-3 text-left relative">
                                    {item.valueRemark}
                                </td>
                                <td className="py-3 px-3 text-left relative">
                                    <div className="flex justify-between items-center">
                                        <div
                                            className={`w-14 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out ${("ENABLED" === item.active) ? "bg-green-400" : ""}`}>
                                            <div
                                                className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${("ENABLED" === item.active) ? "translate-x-6" : ""}`}>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>)) : (
                            <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                <td colSpan={5}
                                    className="text-center py-3 px-3 text-left relative">No
                                    data to
                                    display
                                </td>
                            </tr>)
                        }
                        </tbody>
                    </table>

                </div>
            </div>
        </div>);
    }

    const ResetForm = async () => {
        setCurrentPage(0);
        setFilters({
            codeName: '',
            codeDescription: '',
            active: '',
        });
        loadData(0);
    }

    const filter = async (page: any) => {
        setLoading(true);
        await EbReferenceMasterService.filterReferenceMasterRecord(filters, tokens, page).then((response: any) => {
            setReferenceMaster(response.data.data.result);
            setMetaData({
                current: response.data.meta.page,
                pages: response.data.meta.totalPages,
            })
            setCurrentPage(response.data.meta.page);
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            toast.error('Something went wrong');
        });
    }

    const paginationFilter = async (page: number) => {
        setCurrentPage(page);
        filter(page);
    }

    const updateReferenceData = async (id: any, data: any) => {
        await EbReferenceMasterService.UpdateReferenceMasterDetails(id, data, tokens).then((response) => {
            filter(currentPage);
            toast.success("Updated Successfully!");
        }).catch((err) => {
            toast.error('Something went wrong');
        })
    }

    return (
        <Layout type={"MasterModule"}>
            <ToastContainer/>

            <div className="flex justify-between items-center mb-5">
                <h1 className="text-2xl font-bold tracking-tight">
                    {"References"}
                </h1>
                {/*{*/}
                {/*    permissions.filter((permission: any) => permission.permissionCode === 'reference.master.create').length > 0 && (*/}
                {/*        <button onClick={() => {*/}
                {/*            history.push(`/master/ebmaster/view/0`);*/}
                {/*        }}*/}
                {/*                className="mb-1.5 px-4 py-2.5 font-bold text-white text-sm uppercase bg-purple-950 rounded">*/}
                {/*            + Add New*/}
                {/*        </button>*/}
                {/*        )*/}
                {/*}*/}
                
            </div>
            <div className="border bg-white rounded border-gray-200 shadow">
                <div className="flex-1 p-2">
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="code">
                                Code
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"

                                name="code" id="code" type="text" placeholder="Code"
                                autoComplete="none" value={filters.codeName} onChange={event => setFilters({
                                ...filters,
                                codeName: event.target.value
                            })}/>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="description">
                                Description
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="description" type="text" placeholder="Description" autoComplete="none"
                                onChange={event => setFilters({
                                    ...filters,
                                    codeDescription: event.target.value
                                })} value={filters.codeDescription}/>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="status">
                                Status
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="status" value={filters.active} onChange={event => setFilters({
                                    ...filters,
                                    active: event.target.value
                                })}>
                                    <option value="">Select an Status</option>
                                    <option value="ENABLED">ENABLED</option>
                                    <option value="DISABLED">DISABLED</option>
                                </select>
                            </div>
                        </div>
                        <div className="w-fullmd:w-1/4 px-3 mb-6 md:mb-0">
                            <div className="my-6">
                                <button
                                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                                    type="button" onClick={() => {
                                    filter(0)
                                }}>
                                    Filter
                                </button>
                                <button
                                    className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                                    type="button" onClick={ResetForm}>
                                    Reset
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="border bg-white rounded border-gray-200 shadow m-2">
                    {loading ? (<Loader/>) : (
                        <div>
                            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                                <thead>
                                <tr className="text-left border-b border-gray-300">
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Code</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Description</th>
                                    {
                                        HasAnyAccess(['reference.master.edit']) && (
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Status</th>)}
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Created By</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Updated Date</th>
                                    {
                                        HasAnyAccess(['reference.master.edit','reference.master.view']) && (
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Action</th>
                                        )}
                                </tr>
                                </thead>
                                <tbody>
                                {(0 !== referenceMaster.length) ?
                                    referenceMaster.map((user: any, index: number) => {
                                        return (
                                            <tr key={index}
                                                className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                <td className="py-3 px-3 text-left relative">
                                                    {user.codeName}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {user.codeDescription}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {
                                                        HasAnyAccess(['reference.master.edit']) && (
                                                            <div className="flex justify-between items-center"
                                                                 onClick={() => {
                                                                     changeStatus(user.active, index, user.id)
                                                                 }}>
                                                                <div
                                                                    className={`w-14 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out ${("ENABLED" === user.active) ? "bg-green-400" : ""}`}>
                                                                    <div
                                                                        className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${("ENABLED" === user.active) ? "translate-x-6" : ""}`}>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {user.createdBy}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {moment(user.createdAt).format("DD-MM-YYYY HH:mm")}
                                                </td>
                                                {
                                                    HasAnyAccess(['reference.master.edit','reference.master.view']) && (
                                                <td className="py-3 px-3 text-left relative">
                                                    {
                                                        HasAnyAccess(['reference.master.edit']) && (
                                                            <button
                                                                className="pb-0 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                                type="button"
                                                                onClick={() => history.push(`/master/ebmaster/view/${user.id}`)}
                                                            >
                                                                Edit
                                                            </button>
                                                        )
                                                    }
                                                    {
                                                        HasAnyAccess(['reference.master.view']) && (
                                                            <button
                                                                className="pr-0 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                                onClick={() => {
                                                                    viewDetails(user.id)
                                                                }}>
                                                                <svg className="w-4 h-4 mr-3 fill-current" viewBox="0 0 20 20">
                                                                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"/>
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                                                        clipRule="evenodd"
                                                                    />
                                                                </svg>
                                                            </button>
                                                        )
                                                    }
                                                </td>)}
                                            </tr>
                                        )
                                    })
                                    : (<tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                        <td colSpan={6} className="text-center py-3 px-3 text-left relative">No data to
                                            display
                                        </td>
                                    </tr>)
                                }
                                </tbody>
                            </table>
                            <Modal size={'large'} show={show} handleClose={hideModal} children={children}/>

                        </div>

                    )}
                    <Pagination
                        meta={meta}
                        handlePageChange={(number: number) => {
                            paginationFilter(number)
                        }}
                    />
                </div>
            </div>
        </Layout>
    );
};

export default EbMasterTable;
