import React from "react";
import { Route } from "react-router-dom";

import TaxDetailsTable from "../Components/TaxDetailsTable";


export const TaxDetailsRoutes: React.FC = () => {
    return (
        <>
            <Route path="/master/tax-details" exact component={TaxDetailsTable} />
        </>
    );
};
