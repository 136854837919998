import axios from "axios";

// Get All Records
export const GetAllReferenceMaster = async () => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/reference/master/get`;
    resolve(await axios.get(apiUrl));
  });
};

export const GetAllReferenceDetails = async () => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/reference/details/get`;
    resolve(await axios.get(apiUrl));
  });
};

export const GetAllRoleDetails = async () => {
  return new Promise(async (resolve, reject) => {
    let apiUrl =
      process.env.REACT_APP_URL + `/api/v1/reference/role-master/get`;
    resolve(await axios.get(apiUrl));
  });
};

export const GetAllRoleAction = async () => {
  return new Promise(async (resolve, reject) => {
    let apiUrl =
      process.env.REACT_APP_URL + `/api/v1/reference/role-action/get`;
    resolve(await axios.get(apiUrl));
  });
};

export const GetAllUserMaster = async () => {
  return new Promise(async (resolve, reject) => {
    let apiUrl =
      process.env.REACT_APP_URL + `/api/v1/reference/user-master/get`;
    resolve(await axios.get(apiUrl));
  });
};

export const GetAllUserRoleMapping = async () => {
  return new Promise(async (resolve, reject) => {
    let apiUrl =
      process.env.REACT_APP_URL + `/api/v1/reference/user-role-mapping/get`;
    resolve(await axios.get(apiUrl));
  });
};

export const GetAllModuleMaster = async () => {
  return new Promise(async (resolve, reject) => {
    let apiUrl =
      process.env.REACT_APP_URL + `/api/v1/reference/module-master/get`;
    resolve(await axios.get(apiUrl));
  });
};

export const GetAllModuleAction = async () => {
  return new Promise(async (resolve, reject) => {
    let apiUrl =
      process.env.REACT_APP_URL + `/api/v1/reference/module-actions/get`;
    resolve(await axios.get(apiUrl));
  });
};

export const GetAllLogDetails = async () => {
  return new Promise(async (resolve, reject) => {
    let apiUrl =
      process.env.REACT_APP_URL + `/api/v1/reference/log-details/get`;
    resolve(await axios.get(apiUrl));
  });
};

export const GetAllPartnerMaster = async () => {
  return new Promise(async (resolve, reject) => {
    let apiUrl =
      process.env.REACT_APP_URL + `/api/v1/reference/partner-master/get`;
    resolve(await axios.get(apiUrl));
  });
};

export const GetAllPartnerDetails = async () => {
  return new Promise(async (resolve, reject) => {
    let apiUrl =
      process.env.REACT_APP_URL + `/api/v1/reference/partner-details/get`;
    resolve(await axios.get(apiUrl));
  });
};
