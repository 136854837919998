import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import { Pagination } from "../../../Components-ui/Pagination";
import { useAuthStore } from "../../../Store/AuthStore";
import DashboardReportService from "../DashboardReportService";
import TradeReport from "./TradeRawReport";
import TradeSummaryReport from "./TradeSummaryReport";
import DashboardReportServiceHP from "../DashboardServiceHP";
import moment from "moment";
import TreasuryRawReport from "./TreasuryRawReport";
import TreasurySummaryReport from "./TreasurySummaryReport";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";
interface PropsType {
  filters?: any;
  metalCode?: any;
  metalName?: any;
  page?: any;
  setPage?: any;
  flow: any;
}
const TradeTables: React.FC<PropsType> = ({
  filters,
  metalCode,
  metalName,
  page,
  setPage,
  flow,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { tokens, permissions } = useAuthStore();
  const [tablesData, setTablesData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState<any>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [tradeRecords, setTradeRecords] = useState();

  const getData = () => {
    setLoading(true);

    const queryParams = {
      fromDate: moment.utc(filters?.fromDate).format("x"),
      toDate: moment.utc(filters?.toDate).format("x"),
      metalCode: metalCode,
      status: filters?.transactionStatus || [],
      itemCode: filters?.itemCode || "",
      vault: filters?.vault?.id || "",
      partnerId: filters?.CuGroup?.partnerId || "",
      partnerName: filters?.CuGroup?.name,
      orderBy: "created_at",
      order: "desc",
      page: page,
      pagesize: 10,
      isFxCovered: filters?.isFxCovered,
      isMetalCovered: filters?.isMetalCovered,
      isHpCovered: filters?.isHpCovered,
    };

    if (flow === "EB") {
      DashboardReportService.getReportSummary(tokens, queryParams)
        .then((response: any) => {
          // console.log(response, "response?.data?.data");
          setTablesData(response?.data?.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          // toast.error(err.response.data.errors[0].title);
          setTablesData(null);
          toast.error("Something went wrong!");
        });
    }

    if (flow === "HP") {
      if (metalCode === "UsdInr") {
        const UsdqueryParams = {
          fromDate: moment.utc(filters?.fromDate).format("x"),
          toDate: moment.utc(filters?.toDate).format("x"),
          metalCode: metalCode,
          status: filters?.transactionStatus || [],
          itemCode: filters?.itemCode || "",
          vault: filters?.vault?.id || "",
          partnerId: filters?.CuGroup?.partnerId || "",
          partnerName: filters?.CuGroup?.name,
          isFxCovered: filters?.isFxCovered,
          orderBy: "created_at",
          order: "desc",
        };

        DashboardReportServiceHP.getUsdINRReport(tokens, UsdqueryParams)
          .then((response: any) => {
            // console.log(response, "response?.data?.data");
            setTablesData(response?.data?.data);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            setTablesData({});
          });
      } else {
        DashboardReportServiceHP.getReportSummary(tokens, queryParams)
          .then((response: any) => {
            setTablesData(response?.data?.data);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            // toast.error(err.response.data.errors[0].title);
            setTablesData(null);
            toast.error("Something went wrong!");
          });
      }
    }
  };

  const downloadReport = () => {
    const queryParams = {
      fromDate: parseInt(moment(filters?.fromDate)?.format("x")),
      toDate: parseInt(moment(filters?.toDate)?.format("x")),
      metalCode: metalCode,
      status: filters?.transactionStatus || [],
      itemCode: filters?.itemCode || "",
      vault: filters?.vault?.id || "",
      // customerID: filters?.customerID || "",
      partnerId: filters?.CuGroup?.partnerId || "",
      partnerName: filters?.CuGroup?.name,
      isCovered: filters?.covered,
      orderBy: "created_at",
      order: "desc",
      page: page,
      pagesize: 10,
      isFxCovered: filters?.isFxCovered,
      isMetalCovered: filters?.isMetalCovered,
    };

    setLoading(true);
    if (flow === "HP") {
      if (metalCode === "UsdInr") {
        const UsdqueryParams = {
          fromDate: moment.utc(filters?.fromDate).format("x"),
          toDate: moment.utc(filters?.toDate).format("x"),
          metalCode: metalCode,
          status: filters?.transactionStatus || [],
          itemCode: filters?.itemCode || "",
          vault: filters?.vault?.id || "",
          partnerId: filters?.CuGroup?.partnerId || "",
          partnerName: filters?.CuGroup?.name,
          isFxCovered: filters?.isFxCovered,
          orderBy: "created_at",
          order: "desc",
          // page: page,
          // pagesize: 10,
        };

        DashboardReportServiceHP.usdInrReportDownload(tokens, UsdqueryParams)
          .then((response: any) => {
            toast.success(response?.data?.data?.message);
            setLoading(false);
          })
          .catch((err) => {
            toast.error("Something Went Wrong!");
            setLoading(false);
          });
      } else {
        DashboardReportServiceHP.reportDownload(tokens, queryParams)
          .then((response: any) => {
            toast.success(response?.data?.data?.message);
            setLoading(false);
          })
          .catch((err) => {
            toast.error("Something Went Wrong!");
            setLoading(false);
          });
      }
    }
    if (flow === "EB") {
      DashboardReportService.reportDownload(tokens, queryParams)
        .then((response: any) => {
          toast.success(response?.data?.data?.message);
          setLoading(false);
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getData();
  }, [filters, page, metalCode]);

  useEffect(() => {
    setExpanded(false);
  }, [metalCode]);

  return (
    <>
      {loading && <Loader />}
      {flow === "EB" ? (
        <TradeSummaryReport
          data={tablesData}
          metalCode={metalCode}
          metalName={metalName}
          fromDate={filters?.fromDate}
          toDate={filters?.toDate}
          downloadReport={downloadReport}
        />
      ) : (
        <TreasurySummaryReport
          data={tablesData}
          metalCode={metalCode}
          metalName={metalName}
          fromDate={filters?.fromDate}
          toDate={filters?.toDate}
          downloadReport={downloadReport}
          tab={metalName === "US$/₹" ? "UsdInr" : ""}
        />
      )}
      <br />
      <button
        className="focus:outline-none bg-gray-200 collapsed dark:bg-blue-600 flex-wrap p-2 my-2 w-full text-center text-sm text-dark"
        type="button"
        // data-bs-toggle="collapse"
        // data-bs-target="#collapseExample"
        // aria-expanded="false"
        // aria-controls="collapseExample"
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        {expanded ? "Hide Trade Records" : "Display Trade Records"}
      </button>
      {expanded ? (
        <div
          className={`w-full  flex flex-wrap mb-2 align-items-center  ${
            !!tablesData?.toggleableTable?.meta?.totalPages
              ? "justify-between"
              : "justify-end"
          }`}
          //   id="collapseExample"
        >
          {flow === "EB" ? (
            <TradeReport
              // data={tablesData}
              flow={flow}
              selectedRecords={selectedRecords}
              metalCode={metalCode}
              metalName={metalName}
              setSelectedRecords={setSelectedRecords}
              alreadySelectedRecords={tablesData?.toggleableTable?.data?.result.results?.filter(
                (record: any) => record?.isHpCovered
              )}
              filters={filters}
            />
          ) : (
            <TreasuryRawReport
              // data={tablesData}
              flow={flow}
              metalCode={metalCode}
              metalName={metalName}
              // selectedRecords={selectedRecords}
              // setSelectedRecords={setSelectedRecords}
              alreadySelectedRecords={tablesData?.toggleableTable?.data?.result.results?.filter(
                (record: any) => record?.isHpCovered
              )}
              showConfirmModal={showConfirmModal}
              setShowConfirmModal={setShowConfirmModal}
              // changeIsCovered={changeIsCovered}
              getData={getData}
              tab={metalName === "US$/₹" ? "UsdInr" : ""}
              filters={filters}
            />
          )}

          <div>
            {selectedRecords?.length > 0 && (
              <>
                <button
                  className={` mr-3 mt-3 text-white rounded-md bg-green-400 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 ${
                    HasAnyAccess([
                      "trade.report.edit",
                      "treasury.report.edit",
                      "management.report.edit",
                    ]) && selectedRecords?.length
                      ? "bg-green-400 "
                      : "bg-gray-200"
                  }`}
                  type="button"
                  // onClick={downloadReport}
                  disabled={
                    !HasAnyAccess([
                      "trade.report.edit",
                      "treasury.report.edit",
                      "management.report.edit",
                    ]) || !selectedRecords?.length
                  }
                  onClick={() => setShowConfirmModal(true)}
                >
                  Submit
                </button>
              </>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default TradeTables;
