import axios from "axios";
import Http from "../../library/http.library";
import {httpMethods} from "../../library/enum";
import {makeQuery} from "../../Common/util.helper";

export const getUOMConversionList = async (tokens:any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/uom-con-master`).setToken(tokens.idToken.jwtToken).request();

};

export const getUOMList = async (tokens:any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/uom-master`).setToken(tokens.idToken.jwtToken).request();

};

export const updateUOM = async (data: any, tokens: any) => {
    return Http.setMethod(httpMethods.patch).setUrl(`/uom-con-master/${data.id}`).setData(data).setToken(tokens.idToken.jwtToken).request();
};

export const filterUOM = async (data: any, currentPage: number, tokens: any) => {
    var query = {
        isPaginated: true,
        ...(data.cid ? {conversionId: data.cid} : {}),
        ...(data.from ? {uomFrom: data.from} : {}),
        ...(data.to ? {uomTo: data.to} : {}),
        ...(data.active ? {active: data.active} : {}),
        page: currentPage,
    }
    const params = makeQuery(query);
    return await Http.setMethod(httpMethods.get).setUrl(`/uom-con-master?${params}`).setToken(tokens.idToken.jwtToken).request();
};

export const addNewUOM = async (data: any, tokens: any) => {
    return await Http.setMethod(httpMethods.post).setUrl(`/uom-con-master`).setData(data).setToken(tokens.idToken.jwtToken).request();
};

const UOMCoversionService = {
    getUOMList,
    updateUOM,
    filterUOM,
    addNewUOM,
    getUOMConversionList
};

export default UOMCoversionService;
