export const getName = (name: any) => {
    switch (name) {
      case "Ag":
        return "Silver";
      case "Au":
        return "Gold";
      case "Pt":
        return "Platinum";
      case "Pd":
        return "Palladium";
      default:
        return "";
    }
  };
  
  export const DATA_TYPE = Object.freeze({
    NORMAL: "NORMAL",
    START_DATE: "START_DATE",
    END_DATE: "END_DATE",
  });
  
  export const HEAD_CELLS = [
    {
      label: "Rule Ref",
    },
    {
      label: "Description",
    },
    {
      label: "Type",
    },
    {
      label: "Metal",
    },
    {
      label: "Metal Name",
    },
    {
      label: "Effect from",
    },
    {
      label: "Effect To",
    },
    {
      label: "Source",
    },
    {
      label: "Cust Group",
    },
    {
      label: "Customer ID",
    },
    {
      label: "Priority",
    },
    {
      label: "Action",
    },
  ];
  
  export const TH_CHILD = [
    {
      label: "Rule No",
    },
    {
      label: "Lowerbreak in Grams",
    },
    {
      label: "UpperBreak In grams",
    },
    {
      label: "Lower Break in mount",
    },
    {
      label: "Upper Break in Amount",
    },
    {
      label: "Metal Qty in Grams",
    },
  ];

  export const getApplyName = (name: any) => {
    switch (name) {
      case "1":
        return "Actual";
      case "2":
        return "On Previous Amount";
      case "3":
        return "On Net Total";
      case "4":
        return "On Invoice Total";
      default:
        return "";
    }
  };


  export const getPartnerName = (name: any) => {
    switch (name) {
      case 1:
        return "EBL";
      case 2:
        return "BAJAJ";
      default:
        return "";
    }
  };