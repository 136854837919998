import React from "react";
import "react-toastify/dist/ReactToastify.css";

interface PropsType {
    details: any;
}

const ReceiptQty: React.FC<PropsType> = ({ details }) => {

    const formatData = () => {
        const vsoData = details;
        if (vsoData == undefined) {
            return [];
        }
        // console.log("Summary data: ", vsoData);
        
        const initMetals:any = [];

        return vsoData.reduce((acc: any, current: any) => {
            let metalIndex = acc.findIndex((data: any) => data.metalCode == current.metalCode)
            if (metalIndex < 0) {
                acc.push({ metalCode: current.metalCode, saleQty: 0.00, buyBackQty: 0.00, netQty: 0.00 })
            }
            metalIndex = acc.findIndex((data: any) => data.metalCode == current.metalCode)
            if (current.salesquantity >= 0) {
                acc[metalIndex].saleQty = acc[metalIndex].saleQty + current.salesquantity
            } 
            if (current.buybackquantity >= 0) {
                acc[metalIndex].buyBackQty = acc[metalIndex].buyBackQty + current.buybackquantity
            }
            acc[metalIndex].netQty = acc[metalIndex].netQty + current.netquantity

            return acc;
        }, initMetals)
    }



    // const renderRows = (val: any, index: any) => {
    //     return (
    //     <tr key={index} className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100">
    //         <td className="py-3 px-6 text-center">{val.metal}</td>
    //         <td className="py-3 px-6 text-center"> {val.saleQty}</td>
    //         <td className="py-3 px-6 text-center"> {val.buyBackQty}</td>
    //         <td className="py-3 px-6 text-center"> {val.netQty}</td>
    //     </tr>
    // )}

    const SalesQtySum = (arr: []) => {
        let val = arr.reduce((acc, cur: any) => {
            return acc + cur.saleQty
        }, 0)

        return val.toFixed(4);
    }

    const BuyBackQtySum = (arr: []) => {
        let val = arr.reduce((acc, cur: any) => {
            return acc + cur.buyBackQty
        }, 0)

        return val.toFixed(4);
    }

    const NetQtySum = (arr: []) => {
        let val = arr.reduce((acc, cur: any) => {
            return acc + cur.netQty
        }, 0)

        return val.toFixed(4);
    }

    const roundDecimals = (amount: string, num?: number) => {
        return Number(amount).toFixed(num ? num : 4);
    }

    const getMetalName = (metalCode: string) => {
        switch(metalCode) {
            case "Ag": return "Silver"
            case "Au": return "Gold"
            case "Pt": return "Platinum"
            case "Pd": return "Palladium"
            default: return ""
        }
    } 

    return (
        <div className="grid grid-cols-1 gap-4">
            <div className="flex flex-col col-span-2">
            <fieldset className="bg-gray-100 rounded mt-3">

                <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mt-1">Summary</legend>
                <div className="bg-gray-100 rounded mb-5 p-5">
                    {/* <h3>{JSON.stringify(editHrs)}</h3>
                        <h3>{JSON.stringify(editMins)}</h3> */}
                    <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                        <thead>
                            <tr className="text-left border-b border-gray-300">
                                <th className="py-3 px-6 text-center" style={{overflow: "hidden"}}>Metal</th>
                                <th className="py-3 px-6 text-center" style={{overflow: "hidden"}}>Sales Qty</th>
                                <th className="py-3 px-6 text-center" style={{overflow: "hidden"}}>BuyBack Qty</th>
                                <th className="py-3 px-6 text-center" style={{overflow: "hidden"}}>Net Qty</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {formatData().length} */}
                            {details.length > 0 ?
                                details.map((val: any, index: number) => {
                                return (
                                    <tr key={index} className="border-b border-gray-200 bg-white text-black">
                                        <td className="py-3 px-6 text-center" style={{overflow: "hidden"}}>{getMetalName(val.metalCode)}</td>
                                        <td className="py-3 px-6 text-center" style={{overflow: "hidden"}}> {roundDecimals(val.salesquantity)}</td>
                                        <td className="py-3 px-6 text-center" style={{overflow: "hidden"}}> {roundDecimals(Math.abs(val.buybackquantity).toString())}</td>
                                        <td className="py-3 px-6 text-center" style={{overflow: "hidden"}}> {roundDecimals(val.netquantity)}</td>
                                    </tr>)
                            }): (<tr
                                className="border-b border-gray-200 bg-white text-black">
                                <td className="py-3 px-3 text-center relative" colSpan={4}>
                                  No Data. Use the filter for get data.
                                </td>
                              </tr>)}

                            {/* {formatData().length > 0 ? <tr className="border-b border-gray-200 bg-white text-black">
                                <td className="py-3 px-6 text-center" style={{overflow: "hidden"}}></td>
                                <td className="py-3 px-6 text-center font-bold" style={{overflow: "hidden"}}>{SalesQtySum(formatData())}</td>
                                <td className="py-3 px-6 text-center font-bold" style={{overflow: "hidden"}}>{Math.abs(parseFloat(BuyBackQtySum(formatData())))}</td>
                                <td className="py-3 px-6 text-center bg-purple-300 font-bold" style={{overflow: "hidden"}}>{NetQtySum(formatData())}</td>
                            </tr> : ""} */}
                            
                        </tbody>
                    </table>
                </div>
                </fieldset>
            </div>
        </div>)

}

export default ReceiptQty;