import { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import PricingService from "../Pricing/Services/pricing.service";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { WarehouseStockMovement } from "./Metal.Service";
import { ExportToExcel } from "../Util/ExcelExport";
import { Pagination } from "../../Components-ui/Pagination";
import { Tag } from "../../Components-ui/Tag";
import Datetime from "react-datetime";

const WarehouseStock: React.FC = () => {
  const initFilters = () => {
    return FieldsList.reduce((o, c) => ({ ...o, [c]: "" }), {});
  };
  let history = useHistory();
  const [data, setData] = useState<any>([]);
  const [filteredData, setfilteredData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filterActive, setFilterActive] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>(initFilters());

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [metaData, setMetaData] = useState<any>({});
  const [filterParams, setFilterParams] = useState<any>({});

  //filter by date state
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [dateRangeMode, setdateRangeMode] = useState<boolean>(false);

  useEffect(() => {
    fetchdata(currentPage);
  }, []);

  const fetchdata = (page?: number, params?: any) => {
    WarehouseStockMovement(page, params)
      .then((data: any) => {
        console.log("warehouseStock", data.data.meta);
        setData(data.data.data);
        setfilteredData(data.data.data);
        setMetaData(data.data.meta);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const roundNo = (amount: string) => {
    return Number(amount).toFixed(4);
  };

  const roundToTwo = (amount: string) => {
    return Number(amount).toFixed(1);
  };

  const filter = (page: number = 0, forcedfilter: any = null) => {
    let params = {};
    if (forcedfilter != null ? forcedfilter : filterActive) {
      const otherfilters = FieldsList.reduce((ff: any, current: string) => {
        if (filters[current] != null && filters[current].trim().length > 0) {
          ff[current] = filters[current].trim();
        }
        return ff;
      }, {});
      params = { ...params, ...otherfilters };
    }
    if (startDate && endDate) {
      const dates = {
        startDate: new Date(startDate).toISOString(),
        endDate: moment(endDate).endOf("day").toISOString(),
      };
      params = { ...params, ...dates };
      setdateRangeMode(true);
    }

    setFilterParams(params);
    setCurrentPage(page);
    fetchdata(page, params);
  };

  const clearFilters = () => {
    setFilterActive(false);
    setFilters(initFilters());
    filter(0, false);
  };

  const collectAllData = async () => {
    const data: any = await WarehouseStockMovement(0, filterParams, 999999);
    if (data.data.data && data.data.data.length > 0) {
      return data.data.data;
    }
    return [];
  };

  const ExportExcel = async (e: any) => {
    let exceldata = await collectAllData();
    exceldata = exceldata.map((warehouse: any) => {
      return {
        [ColList[0]]: moment(warehouse.date).format("DD-MM-YYYY HH:mm:ss"),
        [ColList[1]]: warehouse.supplierDeliveryNote,
        [ColList[2]]: warehouse.itemCode,
        [ColList[3]]: warehouse.stockUom,
        [ColList[4]]: warehouse.voucher,
        [ColList[5]]: warehouse.inQuantity,
        [ColList[6]]: warehouse.outQuantity,
        [ColList[7]]: warehouse.metalQuantity,
        [ColList[8]]: warehouse.metalCode,
        [ColList[9]]: warehouse.assay,
        [ColList[10]]: warehouse.balanceQuantity,
        [ColList[11]]: warehouse.batchNo,
        [ColList[12]]: warehouse.fineness,
        [ColList[13]]: warehouse.warehouse,
        [ColList[14]]: warehouse.itemName,
        [ColList[15]]: warehouse.manufacturer,
        [ColList[16]]: warehouse.description,
        [ColList[17]]: warehouse.incomingRate,
        [ColList[18]]: warehouse.itemGroup,
        [ColList[19]]: warehouse.balanceValue,
        [ColList[20]]: warehouse.voucherType,
        [ColList[21]]: warehouse.voucherNo,
      };
    });
    ExportToExcel(exceldata, "warehouse_stock_movement", ColTypes);
  };
  const onSearchClicked = (e: any) => {
    filter();
  };

  const onClearClicked = (e: any) => {
    setdateRangeMode(false);
    setStartDate(null);
    setEndDate(null);
    setFilterActive(false);
    setFilters(initFilters());
    fetchdata(0);
    setCurrentPage(0);
    setFilterParams({});
  };

  return (
    <Layout type={"VaultingModule"}>
      <div className="flex justify-between content-bottom">
        <div className="py-3 text-right flex">
          <button
            onClick={() => history.push(`/barlist/daily`)}
            className="inline-flex justify-center p-1 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-700  hover:bg-purple-800  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <h5 className=" font-bold tracking-tight text-gray-600 sm:text-2xl">
            Warehouse Stock Movement &nbsp;&nbsp;
          </h5>
        </div>
      </div>
      {/* filter by date */}
      <div className="flex justify-between bg-white border-gray-300 border px-2 rounded">
        <div className="flex">
          <div className="py-2 text-left flex">
            {/* <label
            className="block text-sm font-medium text-gray-700font-bold"
            for="startPicker"
          >
            Start
          </label> */}
            <div id="startPicker" className="">
              <Datetime
                className="wstart-date"
                inputProps={{
                  readOnly: true,
                  className: "border text-sm px-3 border-gray-300 rounded py-2",
                  placeholder: "Start Date",
                }}
                value={startDate}
                timeFormat={false}
                dateFormat={"DD/MM/YYYY"}
                onChange={(e: any) => {
                  setStartDate(e);
                  setEndDate(e);
                }}
                renderInput={(props) => {
                  return (
                    <input {...props} value={startDate ? props.value : ""} />
                  );
                }}
              />
            </div>
          </div>
          <div className="py-2 text-left flex">
            {/* <label
            className="block text-sm font-medium text-gray-700font-bold"
            for="endPicker"
          >
            End
          </label> */}
            <div id="endPicker" className="pl-2">
              <Datetime
                className="wend-date"
                inputProps={{
                  readOnly: true,
                  className:
                    "border text-sm px-3 border-gray-300 rounded py-2 focus:outline-none",
                  placeholder: "End Date",
                }}
                value={endDate}
                timeFormat={false}
                dateFormat={"DD/MM/YYYY"}
                onChange={(e: any) => {
                  setEndDate(e);
                }}
                renderInput={(props) => {
                  return (
                    <input {...props} value={endDate ? props.value : ""} />
                  );
                }}
              />
            </div>
          </div>
          <div className="flex items-center text-left  px-2">
            <button
              onClick={onSearchClicked}
              className="bg-purple-800 focus:ring-0 text-white px-3 py-2 hover:opacity-70 font-semibold rounded text-sm"
            >
              Search
            </button>
          </div>
          {dateRangeMode && (
            <div className="py-3 text-left flex">
              <button
                onClick={onClearClicked}
                className="bg-purple-500 focus:ring-0 text-white px-3 py-2 hover:opacity-70 font-semibold rounded text-sm"
              >
                Clear
              </button>
            </div>
          )}
        </div>
        <div className="py-2 text-right flex">
          <button
            type="button"
            onClick={(e) => {
              ExportExcel(e);
            }}
            className="ml-2 inline-flex justify-center  px-3 py-2 border border-transparent text-sm font-semibold rounded text-purple-800 
          bg-purple-200 hover:bg-purple-300 focus:outline-none"
          >
            Download Report
          </button>
        </div>
      </div>
      {/* end filter by date */}

      {/* <div className="flex" >
      <div className="flex justify-between content-center" >
      <div className="py-3 text-right flex">
     
      <h5 className=" font-bold tracking-tight text-gray-600 sm:text-xl">
        
      </h5>  
     <div className="py-3 text-right flex">
          <CSVLink 
           filename={'metal-stock-movement.csv'}
            data={exportData()}>
          <button type="submit"
          className="ml-2 inline-flex justify-center py-1 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white 
          bg-green-500 hover:bg-green-500 focus:outline-none">
             Download Report
          </button>
          </CSVLink>
        </div>
      </div>
      </div>
      </div> */}
      <div className="overflow-x-auto">
        <div className="w-full">
          <div className=" shadow-md rounded-lg mt-2 mb-6">
            <div className="px-1 bg-white">
              <div className=" flex flex-wrap ">
                {Object.keys(filterParams).map((key, index) => {
                  let key_value = key.replace(/_/g, " ").toUpperCase();
                  let value =
                    key == "start_date" || key == "end_date"
                      ? filterParams[key].substring(0, 10)
                      : filterParams[key];
                  let final_value = `${key_value} : ${value}`;
                  return <Tag value={final_value} />;
                })}
              </div>
            </div>
            <table className="w-full table-auto rounded-lg text-xs">
              <thead>
                <tr className="sticky top-1 px-1 py-3 text-green-900 bg-purple-300">
                  <th className="align-top py-2 px-1 text-left">Date</th>
                  <th className="align-top py-2 px-1 text-left">
                    Supplier Delivery Note
                  </th>
                  <th className="align-top py-2 px-1 text-left">Item</th>
                  <th className="align-top py-2 px-1 text-left">Stock UOM</th>
                  <th className="align-top py-2 px-1 text-left">Voucher#</th>
                  <th className="align-top py-2 px-1 text-left">In qty</th>
                  <th className="align-top py-2 px-1 text-left">Out qty</th>
                  <th className="align-top py-2 px-1 text-left">Metal qty</th>
                  <th className="align-top py-2 px-1 text-left">Metal Code</th>
                  <th className="align-top py-2 px-1 text-left">Use ASSAY</th>
                  <th className="align-top py-2 px-1 text-right">
                    Balance Qty
                  </th>
                  <th className="align-top py-2 px-1 text-right">Batch</th>
                  <th className="align-top py-2 px-1 text-right">Fineness</th>
                  <th className="align-top py-2 px-1 text-right">Warehouse</th>
                  <th className="align-top py-2 px-1 text-left">Item Name</th>
                  <th className="align-top py-2 px-1 text-right">
                    Manufacture
                  </th>
                  <th className="align-top py-2 px-1 text-right">
                    Description
                  </th>
                  <th className="align-top py-2 px-1 text-right">
                    Incoming Rate
                  </th>
                  <th className="align-top py-2 px-1 text-right">Item Group</th>
                  <th className="align-top py-2 px-1 text-left">
                    Balance Value
                  </th>
                  <th className="align-top py-2 px-1 text-left">
                    Voucher Value
                  </th>
                  <th className="align-top py-2 px-1 text-left">Voucher#</th>
                  <th className="align-top py-2 px-1 text-left"></th>
                </tr>

                {/* --------------Filter----------------- */}

                <tr className="border-b border-gray-200 bg-white">
                  {FieldsList.map((name: string, i: number) => (
                    <td key={i} className="py-2 px-1 text-left">
                      <input
                        disabled={name == "date" ? true : false}
                        value={filters[name]}
                        onChange={(e) =>
                          setFilters((f: any) => ({
                            ...f,
                            [name]: e.target.value,
                          }))
                        }
                        className="border rounded-sm w-full p-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type={"text"}
                        placeholder=""
                      />
                    </td>
                  ))}
                  <td className="py-2 px-1 text-left flex">
                    <div
                      id="warehouse-filter-btn"
                      onClick={() => {
                        setFilterActive(true);
                        filter(0, true);
                      }}
                      className="cursor-pointer font-medium text-xs py-1 px-1 rounded bg-gray-100 text-gray-500"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3 w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div
                      onClick={clearFilters}
                      className={`cursor-pointer px-2 py-1 rounded bg-gray-100 text-gray-500 ml-1`}
                    >
                      <span className={`text-xs font-bold h-4 w-4`}>X</span>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm ">
                {filteredData.map((warehouse: any, i: number) => (
                  <tr className="border-b border-gray-200 bg-white  hover:bg-gray-50">
                    <td className="py-3 px-1 text-left w-1/12">
                      {warehouse.date ? (
                        <span className="font-medium text-xs">
                          {moment(warehouse.date).format("DD-MM-YYYY HH:mm:ss")}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>

                    <td className="py-3 px-1 text-left ">
                      {warehouse.supplierDeliveryNote ? (
                        <span className="font-medium text-xs">
                          {warehouse.supplierDeliveryNote}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>

                    <td className="py-3 px-1 text-left">
                      {warehouse.itemCode ? (
                        <span className="font-medium text-xs">
                          {warehouse.itemCode}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>

                    <td className="py-3 px-1 text-left">
                      {warehouse.stockUom ? (
                        <span className="font-medium text-xs">
                          {warehouse.stockUom}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>

                    <td className="py-3 px-1 text-left">
                      {warehouse.voucher ? (
                        <span className="font-medium text-xs">
                          {warehouse.voucher}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>

                    <td className="py-3 px-1 text-left ">
                      {warehouse.inQuantity ? (
                        <span className="font-medium text-xs">
                          {roundToTwo(warehouse.inQuantity)}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>

                    <td className="py-3 px-1 text-left">
                      {warehouse.outQuantity ? (
                        <span className="font-medium text-xs">
                          {roundToTwo(warehouse.outQuantity)}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>

                    <td className="py-3 px-1 text-left ">
                      {warehouse.metalQuantity ? (
                        <span className="font-medium text-xs">
                          {roundToTwo(warehouse.metalQuantity)}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>

                    <td className="py-3 px-1 text-left ">
                      {warehouse.metalCode ? (
                        <span className="font-medium text-xs">
                          {warehouse.metalCode}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>

                    <td className="py-3 px-1 text-left">
                      {warehouse.assay ? (
                        <span className="font-medium text-xs">
                          {warehouse.assay}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>

                    <td className="py-3 px-1 text-right">
                      {warehouse.balanceQuantity ? (
                        <span className="font-medium text-xs">
                          {roundNo(warehouse.balanceQuantity)}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>

                    <td className="py-3 px-1 text-right">
                      {warehouse.batchNo ? (
                        <span className="font-medium text-xs">
                          {warehouse.batchNo}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>

                    <td className="py-3 px-1 text-right">
                      {warehouse.fineness ? (
                        <span className="font-medium text-xs">
                          {roundNo(warehouse.fineness)}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>

                    <td className="py-3 px-1 text-right">
                      {warehouse.warehouse ? (
                        <span className="font-medium text-xs">
                          {warehouse.warehouse}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>

                    <td className="py-3 px-1 text-left">
                      {warehouse.itemName ? (
                        <span className="font-medium text-xs">
                          {warehouse.itemName}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>

                    <td className="py-3 px-1 text-right">
                      {warehouse.manufacturer ? (
                        <span className="font-medium text-xs">
                          {warehouse.manufacturer}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>

                    <td className="py-3 px-1 text-right">
                      {warehouse.description ? (
                        <span className="font-medium text-xs">
                          {warehouse.description}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>

                    <td className="py-3 px-1 text-right">
                      {warehouse.incomingRate ? (
                        <span className="font-medium text-xs">
                          {warehouse.incomingRate}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>
                    <td className="py-3 px-1 text-right">
                      {warehouse.itemGroup ? (
                        <span className="font-medium text-xs">
                          {warehouse.itemGroup}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>
                    <td className="py-3 px-1 text-left">
                      {warehouse.balanceValue ? (
                        <span className="font-medium text-xs">
                          {roundNo(warehouse.balanceValue)}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>

                    <td className="py-3 px-1 text-left">
                      {warehouse.voucherType ? (
                        <span className="font-medium text-xs">
                          {warehouse.voucherType}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>
                    <td className="py-3 px-1 text-left">
                      {warehouse.voucherNo ? (
                        <span className="font-medium text-xs">
                          {warehouse.voucherNo}
                        </span>
                      ) : (
                        <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">
                          Empty
                        </span>
                      )}
                    </td>

                    <td className="py-3 px-1 text-left"></td>
                  </tr>
                ))}
                {filteredData.length == 0 && filterActive && (
                  <tr>
                    <td
                      className="py-5 px-1 text-center bg-white font-semibold"
                      colSpan={22}
                    >
                      No Records Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Pagination Bar */}
            <Pagination
              meta={metaData}
              handlePageChange={(number: number) => {
                filter(number);
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

const FieldsList = [
  "date",
  "supplierDeliveryNote",
  "itemCode",
  "stockUom",
  "voucher",
  "inQuantity",
  "outQuantity",
  "metalQuantity",
  "metalCode",
  "assay",
  "balanceQuantity",
  "batchNo",
  "fineness",
  "warehouse",
  "itemName",
  "manufacturer",
  "description",
  "incomingRate",
  "itemGroup",
  "balanceValue",
  "voucherType",
  "voucherNo",
];

const ColList = [
  "Date",
  "Supplier Delivery Note",
  "Item",
  "Stock UOM",
  "Voucher#",
  "In qty",
  "Out qty",
  "Metal qty",
  "Metal Code",
  "Use ASSAY for calculating metal Content",
  "Balance Qty",
  "Batch",
  "Fineness",
  "Warehouse",
  "Item Name",
  "Manufacture",
  "Description",
  "incoming Rate",
  "Item Group",
  "Balance Value",
  "Voucher Value",
  "Voucher#",
];

const ColTypes = [
  { index: 5, type: "n" },
  { index: 6, type: "n" },
  { index: 7, type: "n" },
  { index: 10, type: "n" },
  { index: 12, type: "n" },
  { index: 17, type: "n" },
  { index: 19, type: "n" },
];

export default WarehouseStock;
