import Layout from "../Layout/Layout";
import React, {useEffect, useState} from "react";
import {ToastContainer, toast} from "react-toastify";
import "./react-confirm-alert.css";
import Loader from "../../Components-ui/Loader";
import moment from "moment";
import ItemMasterService from "./ItemMaster.Service";
import Modal from "../../Components-ui/Modal/Modal";
import {Pagination} from "../../Components-ui/Pagination";
import {useAuthStore} from "../../Store/AuthStore";
import HasAnyAccess from "../../Components-ui/hasAnyAccess";

const ItemMaster: React.FC = () => {
    const { logout, permissions, userData , tokens } = useAuthStore();
    const [Loading, setLoading] = useState<any>(true);
    const [itemList, setItemList] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [meta, setMetaData] = useState<any>({});
    const [selectedPartner, setSelectedPartner] = useState<any>({});
    const [children, setChildren] = useState<any>();
    const [show, setModalStatus] = useState<any>(false);
    const [filters, setFilters] = useState<any>({
        itemCode: '',
        itemName: '',
        itemGroup: '',
        metalCode: '',
        isStockItem: '',
        status: '',
    });

    const hideModal = () => {
        setModalStatus(false);
    };

    useEffect(() => {
        loadItemData(0);
    }, []);

    useEffect(() => {
        childrenElements();
    }, [selectedPartner, show]);

    const loadItemData = async (page:number) => {
        setLoading(true)
        await ItemMasterService.getItemMasterData(tokens,page).then((response: any) => {
            setItemList(response.data.data.result);
            setMetaData({
                current: response.data.meta.page,
                pages: response.data.meta.totalPages,
            });
            setCurrentPage(page);
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            toast.error("Something Went Wrong!");
        });
    };

    const filter = async (page:any) => {
        setLoading(true);
        await ItemMasterService.filterItemMasterData(filters, page, tokens).then((response: any) => {
            setItemList(response.data.data.result);
            setMetaData({
                current: response.data.meta.page,
                pages: response.data.meta.totalPages,
            });
            setCurrentPage(response.data.meta.page);
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            toast.error("Something Went Wrong!");
        });
    }
    const ResetForm = async (e: any) => {
        e.preventDefault();
        setCurrentPage(0)
        setFilters({
            itemCode: '',
            itemName: '',
            itemGroup: '',
            metalCode: '',
            isStockItem: '',
            status: '',
        });
        await loadItemData(0);
    }

    const paginationFilter = async (page: number) => {
        setCurrentPage(page);
        filter(page);
    }

    const childrenElements = () => {
        setChildren(<div className="w-full">
            <h1 className="font-bold">
                {'Item Record Details'}
            </h1>
            <div className="mb-3 bg-white border-purple-900 border-t-4 card p-2 pb-1 pr-3.5 pt-4 rounded shadow-2xl">
                <div className="flex flex-wrap -mx-3 mb-2">
                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            Item Code
                        </label>
                        <input
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            id="name" type="text" placeholder="Name" disabled={true} readOnly={true}
                            value={selectedPartner.itemCode} autoComplete="off"/>
                    </div>
                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            Item Name
                        </label>
                        <input
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            id="name" type="text" placeholder="Name" disabled={true} readOnly={true}
                            value={selectedPartner.itemName} autoComplete="off"/>
                    </div>
                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            Description
                        </label>
                        <input
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            id="name" type="text" placeholder="Name" disabled={true} readOnly={true}
                            value={selectedPartner.description} autoComplete="off"/>
                    </div>
                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            Item Group
                        </label>
                        <input
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            id="name" type="text" placeholder="Name" disabled={true} readOnly={true}
                            value={selectedPartner.itemGroup} autoComplete="off"/>
                    </div>
                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            Metal Code
                        </label>
                        <input
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            id="name" type="text" placeholder="Name" disabled={true} readOnly={true}
                            value={selectedPartner.metalCode} autoComplete="off"/>
                    </div>
                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            GST HSN Code
                        </label>
                        <input
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            id="name" type="text" placeholder="Name" disabled={true} readOnly={true}
                            value={selectedPartner.gstHsnCode} autoComplete="off"/>
                    </div>

                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            Stock UOM
                        </label>
                        <input
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            id="name" type="text" placeholder="Name" disabled={true} readOnly={true}
                            value={selectedPartner.stockUom} autoComplete="off"/>
                    </div>


                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            UOM
                        </label>
                        <input
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            id="name" type="text" placeholder="Name" disabled={true} readOnly={true}
                            value={selectedPartner.uom} autoComplete="off"/>
                    </div>


                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            Item Tax
                        </label>
                        <input
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            id="name" type="text" placeholder="Name" disabled={true} readOnly={true}
                            value={selectedPartner.itemTax} autoComplete="off"/>
                    </div>
                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            Timestamp
                        </label>
                        <input
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            id="name" type="text" placeholder="Name" disabled={true} readOnly={true}
                            value={selectedPartner.timestamp} autoComplete="off"/>
                    </div>
                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            User
                        </label>
                        <input
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            id="name" type="text" placeholder="Name" disabled={true} readOnly={true}
                            value={selectedPartner.user} autoComplete="off"/>
                    </div>

                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            Created By
                        </label>
                        <input
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            id="name" type="text" placeholder="Name" disabled={true} readOnly={true}
                            value={selectedPartner.createdBy} autoComplete="off"/>
                    </div>
                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            Updated By
                        </label>
                        <input
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            id="name" type="text" placeholder="Name" disabled={true} readOnly={true}
                            value={selectedPartner.updatedBy} autoComplete="off"/>
                    </div>
                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            Updated Date
                        </label>
                        <input
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            id="name" type="text" placeholder="Name" disabled={true} readOnly={true}
                            value={moment(selectedPartner.updatedAt).format("DD-MM-YYYY HH:mm")} autoComplete="off"/>
                    </div>
                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            Created Date
                        </label>
                        <input
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            id="name" type="text" placeholder="Name" disabled={true} readOnly={true}
                            value={moment(selectedPartner.createdAt).format("DD-MM-YYYY HH:mm")} autoComplete="off"/>
                    </div>
                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            No GST
                        </label>

                        <span
                            className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${selectedPartner.isNoGst ? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>
                                                       {selectedPartner.isNoGst ? 'Yes':'No'}
                                                    </span>

                    </div>
                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            Stock Item
                        </label>

                        <span
                            className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${selectedPartner.isStockItem ? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>
                                                   {selectedPartner.isStockItem ? 'Yes':'No'}
                                                    </span>

                    </div>
                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            Has Batch No
                        </label>

                        <span
                            className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${selectedPartner.hasBatchNo ? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>
                                                  {selectedPartner.hasBatchNo ? 'Yes':'No'}
                                                    </span>

                    </div>
                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            Has Automatic Batches
                        </label>

                        <span
                            className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${selectedPartner.hasAtomicBatches ? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>
                                                       {selectedPartner.hasAtomicBatches ? 'Yes':'No'}
                                                    </span>

                    </div>
                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            Use for Metal Count
                        </label>

                        <span
                            className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${selectedPartner.useAssayForCalculatingMetalConent ? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>
                                                       {selectedPartner.useAssayForCalculatingMetalConent ? 'Yes':'No'}
                                                    </span>

                    </div>
                    <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="user-id">
                            Status
                        </label>
                        <span
                            className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${"ENABLED" === selectedPartner.active ? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>
                                                        {selectedPartner.active}
                          </span>

                    </div>
                </div>
            </div>
        </div>);
    }

    const editModalElements = async (item: any) => {
        setSelectedPartner(item);
        setModalStatus(true);
    }

    return (
        <Layout type={"MasterModule"}>
            <ToastContainer/>
            <div className="flex justify-between items-center mb-5">
                <h1 className="text-2xl font-bold tracking-tight">
                    {"Items"}
                </h1>
            </div>
            <div className="border bg-white rounded border-gray-200 shadow">
                <div className="flex-1 p-2">
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-id">
                                Item Code
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                onChange={event => setFilters({
                                    ...filters,
                                    itemCode: event.target.value
                                })}
                                name="user-id" id="user-id" type="text" placeholder="Item Code" value={filters.itemCode}
                                autoComplete="none"/>
                        </div>
                        <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="email">
                                Item Name
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="email" onChange={event => setFilters({
                                ...filters,
                                itemName: event.target.value
                            })} type="text" placeholder="Item Name" value={filters.itemName} autoComplete="none"/>
                        </div>
                        <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="mobile">
                                Item Group
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="mobile" onChange={event => setFilters({
                                ...filters,
                                itemGroup: event.target.value
                            })} type="text" placeholder="Item Group" value={filters.itemGroup} autoComplete="none"/>
                        </div>
                        <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="mobile">
                                Metal Code
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="mobile" onChange={event => setFilters({
                                ...filters,
                                metalCode: event.target.value
                            })} type="text" placeholder="Metal Code" value={filters.metalCode} autoComplete="none"/>
                        </div>
                        <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-type">
                                Stock Item
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="user-type" onChange={event => setFilters({
                                    ...filters,
                                    isStockItem: event.target.value
                                })} value={filters.isStockItem}>
                                    <option value="">Select a Type</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-type">
                                Status
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="user-type" onChange={event => setFilters({
                                    ...filters,
                                    status: event.target.value
                                })} value={filters.status}>
                                    <option value="">Select a status</option>
                                    <option value="ENABLED">ENABLED</option>
                                    <option value="DISABLED">DISABLED</option>
                                </select>
                            </div>
                        </div>
                        <div className="w-full px-3 mb-6 md:mb-0">
                            <div className="flex justify-end">
                                <button
                                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                                    type="button"
                                    onClick={() => filter(0)}
                                >
                                    Filter
                                </button>
                                <button
                                    className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={(e) => ResetForm(e)}>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border bg-white rounded border-gray-200 shadow m-2">
                    {Loading ? (<Loader/>) : (
                        <div>
                            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                                <thead>
                                <tr className="text-left border-b border-gray-300">
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800">Item Code</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800">Item Name</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800">Description</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800">Item Group</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800">metalCode</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Stock Item</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Status</th>
                                    {
                                        HasAnyAccess(['item.master.view']) && (
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Action</th>)}
                                </tr>
                                </thead>
                                <tbody>
                                {(0 !== itemList.length) ?
                                    itemList.map((item: any, index: number) => {
                                        return (
                                            <tr key={index}
                                                className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                <td className="py-3 px-3 text-left relative">
                                                    {item.itemCode}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {item.itemName}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {item.description}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {item.itemGroup}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {item.metalCode}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    <span
                                                        className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${item.isStockItem ? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>
                                                       {item.isStockItem ? 'Yes':'No'}
                                                    </span>
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    <span
                                                        className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${"ENABLED" === item.active ? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>
                                                        {item.active}
                                                    </span>
                                                </td>
                                                    {
                                                        HasAnyAccess(['item.master.view']) && (
                                                            <td className="py-3 px-3 text-left relative">

                                                            <button
                                                                className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                                type="button"
                                                                onClick={() => {
                                                                    editModalElements(item)
                                                                }}
                                                            >
                                                                View
                                                            </button>

                                                </td>  )
                                                    }
                                            </tr>
                                        )
                                    })
                                    : (<tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                        <td colSpan={8} className="text-center py-3 px-3 text-left relative">No data to
                                            display
                                        </td>
                                    </tr>)
                                }
                                </tbody>
                            </table>
                            <Modal size={'large'} show={show} handleClose={hideModal} children={children}/>
                        </div>
                    )}
                    <Pagination
                        meta={meta}
                        handlePageChange={(number: number) => {
                            paginationFilter(number)
                        }}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default ItemMaster;
