import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import BankDetails from "../BankDetails/BankDetails";

const BankDetailsRoutes:React.FC = () => {
    return (
        <Fragment>
            <Route path="/bank-details" exact component={BankDetails}/>
        </Fragment>
    )
};

export default BankDetailsRoutes;
