import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CSVLink } from "react-csv";

import Layout from "../Layout/Layout";
import BulkUploadService from "../BulkUpload/BulUploadService";
import UserTable from "./UserTable";
import Loader from "../../Components-ui/Loader";
import { useAuthStore } from "../../Store/AuthStore";
import { toast } from "react-toastify";
import GiftService from "./GIftService";

const GiftView: React.FC = () => {
  const [Loading, setLoading] = useState<any>(false);
  const { logout, permissions, tokens } = useAuthStore();

  //-----Get bulk users
  const fileName = "users-detail";
  const [userData, setUserData] = useState<any>([]);
  const [csvData, setCsvData] = useState([]);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const { id } = useParams<{ id?: string }>();

  //   const headers = [
  //     { label: "firstName", key: "firstName" },
  //     { label: "lastName", key: "lastName" },
  //     { label: "pan", key: "pan" },
  //     { label: "mobile", key: "mobile" },
  //     { label: "completed", key: "isCompleted" },
  //     { label: "isValid", key: "isValid" },
  //     { label: "createdAt", key: "createdAt" },
  //     { label: "isCompleted", key: "isCompleted" },
  //     { label: "Comment", key: "comment" },
  //   ];

  const getUserData = async (id: any) => {
    setLoading(true);
    await GiftService.viewGift(tokens, id)
      .then((response: any) => {
        // setCsvData(response.data.data.userData);
        // const data = response.data.data.userData[0].orderItem;
        // data.forEach((el: any) => {
        //   el.metal = response.data.data.userData?.map((_: any) => _.metal);
        // });
        setIsCompleted(response.data.data.userData[0].isCompleted);
        const data = response.data.data.userData;
        const headers = [
          "companyId",
          "corUserId",
          "Receiver Name",
          "Mobile",
          "Email",
          "isValid",
          "createdAt",
          "Already Used",
          "isCompleted",
          "Voucher Code",
        ];
        const aliases: any = {
          companyId: "corId",
          corUserId: "corUserId",
          "Receiver Name": "receiverName",
          Mobile: "receiverContactNumber",
          Email: "receiverEmail",
          isValid: "isValid",
          createdAt: "createdAt",
          "Already Used": "isUsed",
          isCompleted: "isCompleted",
          "Voucher Code": "voucherCode",
        };

        // const headers = Object.keys(data[0]);
        const csvData: any = [
          headers,
          ...data.map((row: any) =>
            headers.map((header) =>
              typeof row[aliases[header]] === "boolean"
                ? row[aliases[header]]
                  ? "Yes"
                  : "No"
                : row[aliases[header]]
            )
          ),
        ];
        // const csvData = data.map((row: any) =>
        //   Object.keys(row).map((key: any) =>
        //     typeof row[key] === "boolean" ? (row[key] ? "Yes" : "No") : row[key]
        //   )
        // );
        setUserData(response.data.data.userData);
        setCsvData(csvData);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        toast.error(err);
      });
  };
  useEffect(() => {
    getUserData(id);
  }, []);


  return (
    <Layout type={"PaymentModule"}>
      {Loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-between items-center mb-5">
            <h1 className="text-2xl font-bold tracking-tight">{"Users"}</h1>

            <div>
              <button className="text-white  bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2">
                <CSVLink
                  data={csvData}
                  filename={fileName}
                  style={{ textDecoration: "none" }}
                >
                  {"Export"}
                </CSVLink>
              </button>
            </div>
          </div>

          <div className="border bg-white rounded border-gray-200 shadow">
            <div className="container">
              <UserTable
                userData={userData}
                Loading={Loading}
                // isCompleted={isCompleted}
              />
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default GiftView;
