import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  memo,
} from "react";
import AskBidPriceTable from "./AskBidPriceTable";
import PricingService from "../Services/pricing.service";
import { useParams, useLocation } from "react-router-dom";
import moment from "moment";
import pricingService from "../Services/pricing.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { useStore } from "../../../Store/PricingStore";
import axios from "axios";
import { useAuthStore } from "../../../Store/AuthStore";

interface PropsType {
  getQuantity: any;
  getPrice: any;
  bidRulesSave: any;
  getRefreshBid: any;
  askRulesSave: any;
  getRefreshAsk: any;
  getChangedData: any;
  refreshPricing: any;
  handleSingleBench: any;
  aux: any;
  usdInr: any;
  benVal: any;
  benValAll: any;
  benFeedVal: any;
  feeds: any;
  metVal: any;
  partnerVal: any;
  benchMarkInd: any;
  fetchAuxiliaryData: any;
  handleValuePrices: any;
  handleFeeder: any;
  handleChangeMetal: any;
  selectedMetal: any;
  selectFeeder: any;
  selectedBenchMark: any;
  handleBenchmark: any;
  uomVal: any;
  setLoading: any;
  fetchPricingDataInMasterStatusWhenDataSaved: any;
}
let benchmarks: any = [];

const PricingMaster = forwardRef(
  (
    {
      getQuantity,
      getPrice,
      bidRulesSave,
      askRulesSave,
      getRefreshBid,
      getRefreshAsk,
      getChangedData,
      refreshPricing,
      handleSingleBench,
      aux,
      usdInr,
      benVal,
      benValAll,
      benFeedVal,
      metVal,
      partnerVal,
      feeds,
      benchMarkInd,
      fetchAuxiliaryData,
      handleValuePrices,
      handleFeeder,
      handleChangeMetal,
      selectedMetal,
      selectFeeder = "",
      selectedBenchMark,
      handleBenchmark,
      uomVal,
      setLoading,
      fetchPricingDataInMasterStatusWhenDataSaved,
    }: PropsType,
    ref
  ) => {
    let { id } = useParams<any>();

    let location = useLocation<any>();
    let query = useQuery();
    const [benchmark, setBenchmark] = useState<any>([]);
    const [selectedType, setSelectedType] = useState<string>("");
    const [selectedMetalEx, setSelectedMetalEx] = useState<string>("");
    const [selectOwner, setSelectedOwner] = useState<string>("");
    const [ruleId, setRuleId] = useState<string>("");
    const [ruleName, setRuleName] = useState<string>("");
    // const [priority, setPriority] = useState<string>("");
    const [defaultRule, setDefaultRule] = useState(false);
    const [logStatus, setLogStatus] = useState<string>("");
    const [sdate, setSDate] = useState<any>(new Date().toISOString());
    const [edate, setEDate] = useState<any>(new Date().toISOString());
    const [dateError, setdateError] = useState<boolean>(false);
    const [avoid, setAvoid] = useState<boolean>(false);
    const [defaultAskNsv, setDefaultAskNsv] = useState<string>("0");
    const [defaultBidNsv, setDefaultBidNsv] = useState<string>("0");
    const [defaultBidSpread, setDefaultBidSpread] = useState<string>("0");

    const [isRuleEnabled, setIsRuleEnabled] = useState<string>("");
    const [askLowerbound, setAskLowerbound] = useState<string>("");
    const [askUpperbound, setAskUpperbound] = useState<string>("");
    const [bidLowerbound, setBidLowerbound] = useState<string>("");
    const [bidUpperbound, setBidUpperbound] = useState<string>("");
    const [askThreshold, setAskThreshold] = useState<string>("");
    const [bidThreshold, setBidThreshold] = useState<string>("");

    const def = useStore((state) => state.open);
    const setDisable = useStore((state) => state.defOpen);
    const [editable, setEditable] = useState(false);
    const { logout, permissions, userData, tokens } = useAuthStore();
    const [
      uom,
      currency,
      setUOM,
      setCurrency,
      isruleExpired,
      setIsRuleExpired,
      ruleStatus,
      setIsRuleStatus,
    ] = useStore((state: any) => [
      state.uom,
      state.currency,
      state.setUOM,
      state.setCurrency,
      state.isruleExpired,
      state.setIsRuleExpired,
      state.ruleStatus,
      state.setIsRuleStatus,
    ]);
    // const editable = location?.state.operation ==="edit" ? true :false
    function useQuery() {
      const { search } = useLocation();

      return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    useEffect(() => {
      // console.log("useLocationuseLocation ", location.search, query.get("duplicate"));
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${tokens.idToken.jwtToken}`;
      fetchPricingMasterData();
    }, [selectedMetal]);
    useEffect(() => {
      fetchAuxiliaryData(location?.state?.operation);
    }, []);

    useEffect(() => {
      fetchPricingMasterData();
    }, [currency]);

    useEffect(() => {
      // fetchPricingRulesData();
      fetchInitialData();
    }, [refreshPricing]);

    useEffect(() => {
      fetchPricingRulesData();
    }, [benVal]);

    useEffect(() => {
      if (benValAll.length > 0 && selectedMetal) {
        const newBenchMark = benValAll?.filter(
          (data: any) => data.metalCode === selectedMetal
        )[0];
        if (newBenchMark) {
          handleBenchmark(newBenchMark.basisCode);
        } else {
          // toast.error("No benchMark found for given metal code")
        }
      }
    }, [selectedMetal]);

    useEffect(() => {
      const eventListenerStorage = () => {
        const data: any = location?.state?.operation;
        const dataView: any = localStorage.getItem("data");

        if (data === "edit") {
          setEditable(true);
          localStorage.setItem("data", data);
        }

        if (!!dataView) {
          setEditable(true);
          localStorage.removeItem("data");
        }

        if (data === "create") {
          setEditable(false);
        }

        if (query.get("duplicate") && query.get("duplicate") == "true") {
          setEditable(false);
        }
      };
      eventListenerStorage();
      // window.addEventListener("storage", eventListenerStorage); // this is caused to infinite reload page.
      return () => window.removeEventListener("storage", eventListenerStorage);
    }, [setEditable]);

    useEffect(() => {
      if (bidRulesSave != undefined) {
        updateBidRuleData();
      }
    }, [bidRulesSave]);

    useEffect(() => {
      if (askRulesSave != undefined) {
        updateAskRuleData();
      }
    }, [askRulesSave]);

    // console.log(location?.state?.operation, "location?.state.operation");

    const setLogStatusState = async (status: string) => {
      setLogStatus(status);
      // let tempStatus = status;
      // if (status == "LOGGED") {
      //   tempStatus = "Logged";
      // }
      // if (status == "S") {
      //   tempStatus = "Send for approval";
      // }
      // if (status == "EXPIRED") {
      //   tempStatus = "Expire";
      // }
      // if (status == "APPROVED") {
      //   tempStatus = "Approved";
      // }
    };

    // const setStatus = async (status: string) => {

    // };

    const changeBenchNumber = (code: string) => {
      let benchMarkId = benValAll
        .filter(
          (data: any) =>
            data.metalCode === code &&
            data.feed ===
              (selectFeeder == "" ? changeFeederName(code) : selectFeeder) &&
            data.basisCode === selectedBenchMark
        )
        .map((bench: any) => {
          console.log("BEcnhIDD ", bench.id);

          return bench.id;
        });

      console.log("benchMarkId", benchMarkId[0]);

      return benchMarkId[0];

      // switch (code) {
      //   case "Ag":
      //     return "1";
      //   case "Au":
      //     return "2";
      //   case "Pt":
      //     return "3";
      //   case "Pd":
      //     return "4";
      //   default:
      //     return "";
      // }
    };

    const changeFeederName = (selectedMetal: string) => {
      let benchMarkId = changeBenchNumber(selectedMetal);

      let feederVal = benVal
        .filter((data: any) => data.id === benchMarkId)
        .map((bench: any) => {
          return bench.feed;
        });

      return feederVal[0];
    };

    // ======================== Update Ask Sub Rules ==============================
    const updateAskRuleData = async () => {
      let temp = [...askRulesSave];

      temp.forEach(function (v) {
        delete v.isDropOpen;
        delete v.isNameInvalid;
        delete v.isValid;
        delete v.nameError;
        delete v.value;
        delete v.masterRuleId;
        delete v.askBidType;
      });

      let reqData = {
        masterRuleId: id,
        askBidType: "ASK",
        rules: temp,
      };

      if (onSubmitValidate()) {
        let data: any = await pricingService.updatePricingSubRulesByMasterId(
          id,
          reqData,
          tokens.idToken.jwtToken
        );
        refreshAskRules();
        // alert("Saved successfully");
        toast.success("Ask changes saved successfully");
      }
    };

    // ======================= Update Bid Sub Rules ============================== //

    const updateBidRuleData = async () => {
      let temp = [...bidRulesSave];

      temp.forEach(function (v) {
        delete v.isDropOpen;
        delete v.isNameInvalid;
        delete v.isValid;
        delete v.nameError;
        delete v.value;
        delete v.masterRuleId;
        delete v.askBidType;
      });

      let reqData = {
        masterRuleId: id,
        askBidType: "BID",
        rules: temp,
      };

      if (onSubmitValidate()) {
        let data: any = await pricingService.updatePricingSubRulesByMasterId(
          id,
          reqData,
          tokens.idToken.jwtToken
        );
        refreshBidRules();
        // alert("Saved successfully");
        toast.success("Bid changes saved successfully");
      }
    };

    // ==========================================================================
    const fetchPricingMasterData = async () => {
      const params = {
        isPaginated: false,
      };

      let temp: any = await PricingService.getPricingMaster(tokens, params);
      let getTemp = temp?.data?.data;

      let FOUND = getTemp.find(function (found: any) {
        if (
          found.defaultRule === true &&
          found.type === "Standard" &&
          found.metalCode === selectedMetal &&
          found.currency === currency &&
          found.benchmarkBasisCode === selectedBenchMark &&
          found.partnerId === selectOwner
        ) {
          return found;
        }
      });

      if (!!FOUND?.id) {
        if (FOUND?.id === ruleId) {
          setAvoid(false);
          return toast.info(`It is already ${FOUND.id}!`);
        }
        if (defaultRule) {
          setDefaultRule(false);
        }
        setAvoid(true);
        return toast.warn(`It is already default at Master Rule ${FOUND.id}!`);
      }

      return setAvoid(false);
      //
    };

    // fetchPricingMasterData()
    const updateMasterRuleData = async () => {
      // validating pricing tolerance

      let isDataValid = true;
      console.log(
        "askLowerbound ",
        askLowerbound,
        "askUpperbound ",
        askUpperbound
      );

      if (askLowerbound > askUpperbound) {
        toast.error("Ask Lower Bound should be smaller than Ask upper Bound");
        isDataValid = false;
      } else if (bidLowerbound > bidUpperbound) {
        isDataValid = false;
        toast.error("Bid Lower Bound should be smaller than Bid upper Bound");
      }

      if (isDataValid) {
        let reqData = {
          rule: parseInt(id),
          ruleName: ruleName,
          startDate: sdate,
          endDate: edate,
          defaultRule: defaultRule,
          // status: "LOGGED",
          type: "Standard",
          metalCode: selectedMetal,
          benchmarkId: changeBenchNumber(selectedMetal),
          feedCode:
            selectFeeder == "" ? changeFeederName(selectedMetal) : selectFeeder,
          partnerId: selectOwner,
          // priority: priority,
          askDefaultNsv: localStorage?.getItem("nsvDefaultAsk")
            ? getNsvValuesToSave(localStorage?.getItem("nsvDefaultAsk"))
            : 0,
          bidDefaultNsv: localStorage?.getItem("nsvDefaultBid")
            ? getNsvValuesToSave(localStorage?.getItem("nsvDefaultBid"))
            : 0,
          bidSpreadDefault: localStorage?.getItem("spreadDefaultBid")
            ? localStorage?.getItem("spreadDefaultBid")
            : 0,
          uom: uom,
          currency: currency,
          askLowerbound: parseFloat(askLowerbound),
          askUpperbound: parseFloat(askUpperbound),
          bidLowerbound: parseFloat(bidLowerbound),
          bidUpperbound: parseFloat(bidUpperbound),
          askThreshold: parseFloat(askThreshold),
          bidThreshold: parseFloat(bidThreshold),
        };

        console.log("reqData", reqData);

        let data: any = await pricingService.updatePricingMaster(id, reqData);
        await fetchInitialData(true);
        toast.success("Saved successfully");
        fetchPricingDataInMasterStatusWhenDataSaved();
      }
    };

    function getNsvValuesToSave(val: any) {
      return val;
    }

    const setThresholdValues = (type: any, e: any) => {
      let value = e.target.value;
      if (value <= 100 && value >= -100) {
        if (type == "ask") {
          setAskThreshold(e.target.value);
        } else {
          setBidThreshold(e.target.value);
        }
      } else {
      }
    };

    // ==========================================================================

    const refreshAskRules = () => {
      getRefreshAsk(1);
    };

    const refreshBidRules = () => {
      getRefreshBid(1);
    };

    // ======================================================

    const fetchPricingRulesData = async () => {
      let data: any = await PricingService.getPricingMasterById(id);
      let masterRule: any;
      if (!!data?.data?.data) {
        let responseData = data.data.data;
        masterRule = responseData;

        let selectMetal = !!masterRule?.metalCode ? masterRule.metalCode : "Ag";
        setRuleId(masterRule.id);
        setRuleName(masterRule.ruleName);
        setSelectedType(masterRule.type);
        setSelectedOwner(masterRule.partnerId);
        handleChangeMetal(selectMetal);
        if (benValAll.length > 0) {
          const filterBenchBasis = benValAll.filter(
            (d: any) => d.id === masterRule.benchmarkId
          );
          console.log("filterBenchBasisfilterBenchBasis ", filterBenchBasis[0]);
          if (filterBenchBasis[0] != undefined) {
            handleBenchmark(filterBenchBasis[0].basisCode);
          }
        }
        // setPriority(masterRule.priority);
        setDefaultRule(masterRule.defaultRule);
        setUOM(masterRule.uom);
        setCurrency(masterRule.currency);
        if (masterRule.feedCode) handleFeeder(masterRule.feedCode);

        setIsRuleEnabled(masterRule.isEnabled);
        setAskLowerbound(masterRule.askLowerbound);
        setAskUpperbound(masterRule.askUpperbound);
        setBidLowerbound(masterRule.bidLowerbound);
        setBidUpperbound(masterRule.bidUpperbound);
        setAskThreshold(masterRule.askThreshold);
        setBidThreshold(masterRule.bidThreshold);

        // setDefaultAskNsv(masterRule?.askDefaultNsv);
        // setDefaultBidNsv(masterRule?.bidDefaultNsv);
        // setDefaultBidSpread(masterRule?.bidSpreadDefault);

        // commented due to nsv fix
        // localStorage.setItem("nsvDefaultAsk", masterRule?.askDefaultNsv ?? 0);
        // localStorage.setItem("nsvDefaultBid", masterRule?.bidDefaultNsv ?? 0);
        // localStorage.setItem(
        //   "spreadDefaultBid",
        //   masterRule?.bidSpreadDefault ?? ""
        // );

        // commented this when fixing date-time fix

        // masterRule.metalCode &&
        // filterBenchmarkAccordingToMetal(masterRule.metalCode);
        !!masterRule?.startDate && setSDate(masterRule.startDate);
        !!masterRule?.endDate && setEDate(masterRule.endDate);
        setLogStatusState(masterRule.status);
        setIsRuleExpired(
          masterRule.status == "EXPIRED" ||
            masterRule.status == "PERMANENTLY_DISABLED"
            ? true
            : false
        );
        setIsRuleStatus(masterRule.status);
        // setStatus(masterRule.statusMaster);
      }
      return masterRule;
    };

    const fetchInitialData = async (showLoading?: any) => {
      if (showLoading) {
        setLoading(true);
      }

      const masterRule = await fetchPricingRulesData();
      try {
        let dataTolerance: any = await PricingService.getPricingRuleByRuleId(
          masterRule.metalCode,
          masterRule.partnerId,
          masterRule.uom,
          "QTY",
          "1",
          masterRule.currency,
          masterRule.id
        );
        // console.log("dataTolerance ", dataTolerance);
      } catch (error: any) {
        // console.log("ERRORRRR", error);
        setLoading(false);
        const errMsg = error.data.errors[0].title;
        // console.log(" errMsg ", errMsg);
        if (errMsg != "Benchmark not found") {
          toast.error(errMsg);
        }
      }

      await fetchPricingRulesData();
      if (showLoading) {
        setLoading(false);
      }
    };

    const onTypeChange = (e: any) => {
      fetchAuxiliaryData();
      setSelectedType(e.target.value);
    };

    const onChangeRuleName = (e: any) => {
      setRuleName(e.target.value);
    };

    // const onChangeDomcile = (e: any) => {
    //   setPriority(e.target.value);
    // };
    const onChangeAskNsv = (e: any) => {
      setDefaultAskNsv(e.target.value);
    };

    const onChangeBidNsv = (e: any) => {
      setDefaultBidNsv(e.target.value);
    };

    const onChangeBidSpread = (e: any) => {
      setDefaultBidSpread(e.target.value);
    };

    const onMetalChange = (e: any) => {
      handleChangeMetal(e.target.value);
    };

    const onUOMChange = (e: any) => {
      // handleChangeMetal(e.target.value);
      setUOM(e.target.value);
    };

    const onCurrencyChange = (e: any) => {
      setCurrency(e.target.value);
    };

    // const filterBenchmarkAccordingToMetal = (metalCode: string) => {
    //   let tempBenchmark = benchmarks.filter((benchmarkData: any) => {
    //     return benchmarkData.metalCode === selectedMetal;
    //   });
    //   console.log(tempBenchmark,"tempBenchmark");

    //   setBenchmark(tempBenchmark);
    //   if (tempBenchmark && tempBenchmark.length > 0) {
    //     setSelectedBenchMark(tempBenchmark[0].id);
    //   }
    // };

    const onBenchmarkChange = (e: any) => {
      handleBenchmark(e.target.value);
    };

    const onOwnerChange = (e: any) => {
      setSelectedOwner(e.target.value);
    };

    const onFeederChange = (e: any) => {
      handleFeeder(e.target.value);
    };

    const handleQuantity = (quantity: string) => {
      getQuantity(quantity);
    };

    const handlePrice = (price: any) => {
      getPrice(price);
    };

    const onSubmitValidate = () => {
      return !dateError;
    };

    const disablePrevDt = (current: any) => {
      // return current.isAfter(moment(sdate));

      if (current.isSame(moment(sdate), "day")) {
        return current.isSame(moment(sdate), "day");
      } else if (current.isAfter(moment())) {
        return current.isAfter(moment(sdate));
      }
    };

    const disablePrevDtForStartDate = (current: any) => {
      // console.log(
      //   "XCXCXCXCXCXCXC dateeee ",
      //   current.format("MM/DD/YYYY"),
      //   moment().format("MM/DD/YYYY"),
      //   current.isSame(moment(), "day")
      // );
      if (current.isSame(moment(), "day")) {
        return current.isSame(moment(), "day");
      } else if (current.isAfter(moment())) {
        return current.isAfter(moment());
      }
    };

    // const feeds = [];

    useImperativeHandle(ref, () => ({
      saveAllDataHeader() {
        console.log("Save Price Master header section");
        updateMasterRuleData();
      },
    }));

    return (
      <>
        <div className="flex flex-row">
          <ToastContainer />
          <div className="flex-1 border bg-white shadow-sm sm:rounded-md ">
            <div className="flex p-4">
              <div className="flex-initial">
                <div className="px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-city"
                  >
                    Rule
                  </label>
                  <label
                    className="block border bg-white rounded uppercase w-full  text-grey-darker text-xs font-bold py-3 px-4 "
                    for="grid-city"
                  >
                    {ruleId}
                  </label>
                </div>
              </div>
              <div className="flex-grow-0 w-1/2">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-city"
                  >
                    Name
                  </label>
                  <input
                    className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                    id="grid-city"
                    name="bid"
                    type="text"
                    placeholder=""
                    value={ruleName}
                    disabled={isruleExpired}
                    onChange={onChangeRuleName}
                  />
                </div>
              </div>
              <div className="flex flex-grow-0 w-1/2 justify-end items-center">
                <div className="w-1/3 px-3 mb-6 md:mb-0 text-center">
                  <label
                    className={`block uppercase w-full border-gray-200 border rounded text-grey-darker text-xs font-bold py-3 px-4 ${
                      isRuleEnabled == "ENABLED" ? "bg-green-200" : "bg-red-200"
                    }`}
                    for="grid-city"
                  >
                    {isRuleEnabled}
                  </label>
                </div>
              </div>
            </div>

            <div className="flex bg-gray-200 p-4 py-6">
              <div className="flex-1">
                <div className="px-3">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-state"
                  >
                    Type
                  </label>
                  <div className="relative">
                    {/* <select
                    onChange={onTypeChange}
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    value={selectedType}
                  >
                    {types.length > 0 &&
                      types.map((type: any) => {
                        return <option value={type.type}>{type.type}</option>;
                      })}
                  </select> */}
                    <select
                      onChange={onTypeChange}
                      className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                      id="grid-state"
                      disabled={editable || isruleExpired}
                    >
                      <option value="Standard">Standard</option>
                      <option value="Auto Invest">Auto Invest</option>
                    </select>
                    <div className=" pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <div className="px-3">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-state"
                  >
                    Source
                  </label>
                  <div className="relative">
                    {/* <select
                    onChange={onOwnerChange}
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    value={selectOwner}
                  >
                    {owner.length > 0 &&
                      owner.map((owner: any) => {
                        return <option value={owner.id}>{owner.name}</option>;
                      })}
                  </select> */}
                    <select
                      onChange={onOwnerChange}
                      className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                      id="grid-state"
                      value={selectOwner}
                      disabled={editable || isruleExpired}
                    >
                      {partnerVal.length > 0 &&
                        partnerVal.map((data: any) => {
                          return (
                            <option value={data.partnerId}>{data.name}</option>
                          );
                        })}

                      {/* <option value="1">Ebullion</option>
                    <option value="2">Bajaj</option> */}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                  </div>
                </div>
              </div>

              <div className="flex-1">
                <div className="px-3">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-state"
                  >
                    Metal
                  </label>
                  <div className="relative">
                    <select
                      onChange={onMetalChange}
                      className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                      id="grid-state"
                      value={selectedMetal}
                      disabled={editable || isruleExpired}
                    >
                      {metVal.length > 0 &&
                        metVal.map((data: any) => {
                          return (
                            <option value={data.metalCode}>
                              {data.metalCode}
                            </option>
                          );
                        })}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                  </div>
                </div>
              </div>

              <div className="flex-1">
                <div className="md:w-full px-3">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-state"
                  >
                    Benchmark
                  </label>
                  <div className="relative">
                    <select
                      onChange={onBenchmarkChange}
                      className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                      id="grid-state"
                      value={selectedBenchMark}
                      disabled={editable || isruleExpired}
                    >
                      {benVal.length > 0 &&
                        !!selectedMetal &&
                        benVal

                          .filter(
                            (data: any) => data.metalCode === selectedMetal
                          )
                          .map((bench: any) => {
                            // console.log("chanddeeeeeee ",bench,selectedMetal);
                            return (
                              <option value={bench.basisCode}>
                                {bench.basisCode}
                              </option>
                            );
                          })}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                  </div>
                </div>
              </div>

              <div className="flex-1">
                <div className="px-3">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-state"
                  >
                    Feeder
                  </label>
                  <div className="relative">
                    <select
                      onChange={onFeederChange}
                      className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                      id="grid-state"
                      value={selectFeeder}
                      defaultValue={selectFeeder}
                      // disabled={editable}
                      disabled={isruleExpired}
                    >
                      {benFeedVal.length > 0 &&
                        !!selectedMetal &&
                        !!selectedBenchMark &&
                        benFeedVal

                          .filter(
                            (data: any) => data.basisCode === selectedBenchMark
                          )
                          .map((bench: any) => {
                            return (
                              <option value={bench.feedCode}>
                                {bench.feed.replace("_", " ")}
                              </option>
                            );
                          })}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex p-4 pt-5  border-gray-200">
              <div className="flex-initial">
                <div className="px-3">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-state"
                  >
                    Effective Start Date
                  </label>
                  <div className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border shadow-sm">
                    {/* <input
                    type="datetime-local"
                    id="meeting-time"
                    name="meeting-time"
                    onChange={(e: any) => {
                      setdateError(false);
                      if (moment(edate).valueOf() >= moment(new Date(e.target.value)).valueOf()) {
                        setSDate(new Date(e.target.value).toISOString());
                      } else {
                        setdateError(true);
                      }
                    }}
                    placeholder="dd-mm-yyyy"
                    value={moment(sdate).format("YYYY-MM-DDTHH:mm")}
                    min="2006-06-14T00:00"
                    max="2056-06-14T00:00"
                  /> */}
                    <Datetime
                      className="effect-from"
                      inputProps={{
                        disabled: isruleExpired,
                        className: "focus:outline-none",
                      }}
                      value={moment(sdate)}
                      timeFormat={"HH:mm:ss"}
                      dateFormat={"DD/MM/YYYY"}
                      isValidDate={disablePrevDtForStartDate}
                      onChange={(e: any) => {
                        setdateError(false);
                        if (
                          moment(edate).valueOf() >=
                          moment(new Date(e)).valueOf()
                        ) {
                          setSDate(new Date(e).toISOString());
                        } else {
                          setdateError(true);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-initial">
                <div className="px-3">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-state"
                  >
                    Effective End Date
                  </label>
                  <div className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border shadow-sm">
                    <Datetime
                      className="efect-to"
                      inputProps={{
                        disabled:
                          isruleExpired && ruleStatus == "PERMANENTLY_DISABLED",
                        className: "focus:outline-none",
                      }}
                      value={moment(edate)}
                      timeFormat={"HH:mm:ss"}
                      dateFormat={"DD/MM/YYYY"}
                      isValidDate={disablePrevDt}
                      onChange={(e: any) => {
                        setdateError(false);
                        if (
                          moment(sdate).valueOf() <=
                          moment(new Date(e)).valueOf()
                        ) {
                          setEDate(new Date(e).toISOString());
                        } else {
                          setdateError(true);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-initial">
                <div className=" px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-city"
                  >
                    Status
                  </label>
                  <label
                    className="block uppercase w-full border-yellow-200 border  bg-yellow-100 rounded text-grey-darker text-xs font-bold py-3 px-4 "
                    for="grid-city"
                  >
                    {ruleStatus.replace("_", " ")}
                  </label>
                </div>
              </div>
              {/* <div className="flex-initial">
              <div className="w-full px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  for="grid-city"
                >
                  Priority
                </label>
                <input
                  className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                  id="grid-city"
                  name="bid"
                  type="text"
                  placeholder=""
                  value={priority}
                  onChange={onChangeDomcile}
                />
              </div>
            </div> */}

              <div className="flex-initial">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-city"
                  >
                    Default Rule
                  </label>
                  {editable ? (
                    <input
                      type="checkbox"
                      name="defaultRule"
                      disabled={
                        // editable || isruleExpired
                        !!avoid || isruleExpired || ruleStatus != "APPROVED"
                      }
                      checked={defaultRule}
                      onChange={() => setDefaultRule(!defaultRule)}
                    />
                  ) : (
                    <input
                      type="checkbox"
                      name="defaultRule"
                      checked={defaultRule}
                      onChange={() => setDefaultRule(!defaultRule)}
                    />
                  )}
                </div>
              </div>

              <div className="flex-1">
                <div className="px-3">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-state"
                  >
                    UOM
                  </label>
                  <div className="relative">
                    <select
                      onChange={onUOMChange}
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4 uom-input"
                      id="grid-state"
                      value={uom}
                      disabled={isruleExpired}
                    >
                      {uomVal.length > 0 &&
                        uomVal.map((data: any, i: any) => {
                          return (
                            <option value={data.uomCode}>{data.uomCode}</option>
                          );
                        })}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                  </div>
                </div>
              </div>

              <div className="flex-1">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-city"
                  >
                    Currency
                  </label>
                  <div className="relative">
                    <select
                      onChange={onCurrencyChange}
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                      id="grid-state"
                      value={currency}
                      disabled={editable || isruleExpired}
                    >
                      {["INR", "USD"].length > 0 &&
                        ["INR", "USD"].map((data: any, i: any) => {
                          return <option value={data}>{data}</option>;
                        })}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                  </div>
                </div>
              </div>
            </div>

            {/* ----- */}

            <div className="flex p-4 pt-5  border-gray-200">
              <div className="flex-1">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-city"
                  >
                    Ask Lower Bound
                  </label>
                  <div className="relative">
                    <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                      id="grid-city"
                      name="bid"
                      type="number"
                      placeholder=""
                      value={askLowerbound}
                      disabled={isruleExpired}
                      onChange={(e) => {
                        setAskLowerbound(e.target.value);
                      }}
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-city"
                  >
                    Ask Upper Bound
                  </label>
                  <div className="relative">
                    <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                      id="grid-city"
                      name="bid"
                      type="number"
                      placeholder=""
                      value={askUpperbound}
                      disabled={isruleExpired}
                      onChange={(e) => {
                        setAskUpperbound(e.target.value);
                      }}
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-city"
                  >
                    Bid Lower Bound
                  </label>
                  <div className="relative">
                    <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                      id="grid-city"
                      name="bid"
                      type="number"
                      placeholder=""
                      value={bidLowerbound}
                      disabled={isruleExpired}
                      onChange={(e) => {
                        setBidLowerbound(e.target.value);
                      }}
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-city"
                  >
                    Bid Upper Bound
                  </label>
                  <div className="relative">
                    <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                      id="grid-city"
                      name="bid"
                      type="number"
                      placeholder=""
                      value={bidUpperbound}
                      disabled={isruleExpired}
                      onChange={(e) => {
                        setBidUpperbound(e.target.value);
                      }}
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-city"
                  >
                    Ask Threshold
                  </label>
                  <div className="relative">
                    <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                      id="grid-city"
                      name="bid"
                      type="number"
                      placeholder=""
                      min={-100}
                      max={100}
                      value={askThreshold}
                      disabled={isruleExpired}
                      onChange={(e) => {
                        setThresholdValues("ask", e);
                      }}
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-city"
                  >
                    Bid Threshold
                  </label>
                  <div className="relative">
                    <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                      id="grid-city"
                      name="bid"
                      type="number"
                      placeholder=""
                      min={-100}
                      max={100}
                      value={bidThreshold}
                      disabled={isruleExpired}
                      onChange={(e) => {
                        setThresholdValues("bid", e);
                      }}
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                  </div>
                </div>
              </div>
            </div>

            {/* ----- */}

            <div className="flex w-full">
              <div className="flex-initial w-full py-4">
                {/* <div className="flex items-center justify-end p-2 px-3 pr-6 pb-0 ">
                {
                  permissions.filter((permission: any) => permission.permissionCode === 'update.master.rule.data').length > 0 && (
                <button
                  className={`px-4 py-2 font-bold text-white text-sm uppercase bg-purple-950 rounded ${isruleExpired ? 'opacity-50' : ''}`}
                  onClick={updateMasterRuleData}
                  type="button"
                  disabled={isruleExpired}
                >
                  Save
                </button>)}
              </div> */}
              </div>
            </div>
            {dateError && (
              <div className="mb-4">
                <span className="text-xs font-semibold bg-red-50 border border-red-500 text-red-500 mx-7 px-4 py-2 rounded">
                  From date must be less than To Date
                </span>
              </div>
            )}
          </div>
          <div className="flex-none w-1/4 bg-white shadow-sm border ml-4 p-2 sm:rounded-md ">
            {/* {console.log("selected metal on master: ", selectedMetalEx)} */}
            <AskBidPriceTable
              getPrice={handlePrice}
              getQuantity={handleQuantity}
              benchmark={selectedBenchMark}
              benchmarks={benchmark}
              selectedMetalName={selectedMetalEx}
              benchMarkInd={benchMarkInd}
              aux={aux}
              usdInr={usdInr}
              handleValuePrices={handleValuePrices}
              fetchAuxiliaryData={fetchAuxiliaryData}
            />
          </div>
        </div>
      </>
    );
  }
);

// export default PricingMaster;
export default memo(PricingMaster);
