import Http from "../../../library/http.library";
import {httpMethods} from "../../../library/enum";

class CountryService {

    /**
     * Get all countries
     * @param page
     * @param tokens
     * @param query
     * @param isPaginated
     */
    async getAllCountries(page:number, tokens: any, query: any, isPaginated: boolean = true) {
        return await Http.setMethod(httpMethods.get).setUrl(`/country-master?page=${page}${query}${!isPaginated ? `&isPaginated=false` : ``}`).setToken(tokens.idToken.jwtToken).request();
    }

    /**
     * Add new country
     * @param data
     * @param tokens
     */
    async addCountry(data: any, tokens: any) {
        return await Http.setMethod(httpMethods.post).setUrl(`/country-master`).setData([data]).setToken(tokens.idToken.jwtToken).request();
    }

    /**
     * Update country details
     * @param data
     * @param tokens
     */
    async updateCountry(data: any, tokens: any) {
        return Http.setMethod(httpMethods.patch).setUrl(`/country-master/${data.id}`).setData(data).setToken(tokens.idToken.jwtToken).request();
    }

}

export default new CountryService();
