import React, { useState, useEffect } from "react";
import { useAuthStore } from "../../../Store/AuthStore";
import Layout from "../../Layout/Layout";
import { useHistory, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import SimpleReactValidator from "simple-react-validator";
import InvestorListService from "../Services/InvestorList.Service";
import OTPComponent from "./OTPComponent";
export const SingleInvestor: React.FC = () => {
  const [Loading, setLoading] = useState<any>(true);

  const { tokens } = useAuthStore();
  const [countryCode, setCountryCode] = useState("+91");
  const [gstInsList, setGstInsList] = useState([]);
  const [singleInvestor, setSingleInvestor] = useState<any>({
    investorName:"",
    email: "",
    mobile: "",
    panCard: "",
    gstinNo: "",
    aadharCard: "",
    aadhaarAddress: "",
    address: "",
    commissionRate:""
  });
  const [isPanChanged, setIsPanChanged] = useState<boolean>(false);
  const [showOTP, setShowOTP] = useState(false);
  const [mobileVerified, setMobileVerified] = useState(true);
  const [emailVerified, setEmailVerified] = useState(true);
  const [aadhaarVerified, setAadharVerified] = useState(true);
  const [isEmailVerifyDisabled, setIsEmailVerifyDisabled] = useState(false);
  const [isMobileVerifyDisabled, setIsMobileVerifyDisabled] = useState(false);
  const [isAadhaarVerifiedDisabled, setIsAadhaarVerifiedDisabled] = useState(
    false
  );
  const [key, setKey] = useState("");
  const [otpId, setOtpId] = useState<any>();
  const [updatedGstinNo, setUpdatedGstinNo] = useState<any>("");
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(true);
  const [fieldChanged, setFieldChanged] = useState(false);
  const [busNameDisabled, setbusNameDisabled] = useState(false);


  const { id }: any = useParams();

  let history = useHistory();

  const [validatorStatus, setValidator] = useState<boolean>(false);
  const simpleValidator = new SimpleReactValidator({
    locale: "en",
    validators: {},
  });
  const validationRef = React.useRef(simpleValidator);
  const validator = validationRef.current;

  useEffect(() => {
    setLoading(false);
    // getUserRoles();
    // getAllInvestors();
  }, []);

  useEffect(() => {
    if (fieldChanged && emailVerified && mobileVerified && aadhaarVerified) {
      setIsUpdateButtonDisabled(false);
    } else {
      setIsUpdateButtonDisabled(true);
    }
  }, [
    fieldChanged,
    emailVerified,
    mobileVerified,
    aadhaarVerified,
    isPanChanged,
  ]);

  const updateInvestorData = async () => {
    setValidator(true);

    if (validator.allValid()) {
      setLoading(true);
      if (isPanChanged) {
        if (singleInvestor?.panCard?.length < 10) {
          setLoading(false);
          return toast.error("Pan is invalid");
        }
      }
      if (!mobileVerified) {
        setLoading(false);
        return toast.error("Mobile verification is required");
      }
      if (!emailVerified) {
        setLoading(false);
        return toast.error("Email verification is required");
      }
      let payload = {
        ...singleInvestor,
        mobile: countryCode + singleInvestor.mobile,
      };

      await InvestorListService.updateInvestor(tokens, payload, id)
        .then(() => {
          toast.success("Investor Updated Successfully");

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);

          console.log(err);

          toast.error(err.data.errors[0].title);
        });
      setValidator(false);
      setEmailVerified(false);
      setMobileVerified(false);
      setAadharVerified(false);
      setIsEmailVerifyDisabled(true);
      setIsMobileVerifyDisabled(true);
      setIsAadhaarVerifiedDisabled(true);
      setbusNameDisabled(false);
      history.push("/investors");
    }
  };

  const getValidatorError = (nameOfStateProp: any) => {
    const allErrorMessages = validator.getErrorMessages();
    return allErrorMessages[nameOfStateProp];
  };

  const getGstIn = async (data: any) => {
    try {
      const payload = { pan: data };
      const res = await InvestorListService.getGstinList(tokens, payload);
      setGstInsList(res?.data?.data?.gstins);
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    }
  };

  const getInvestorAddressDetails = async (gstinNum: any) => {
    try {
      const payload = { gstin: gstinNum };
      const res = await InvestorListService.getInvestorAddressDetails(
        tokens,
        payload
      );
      const address =
        res?.data?.data?.address?.address1 +
        " " +
        res?.data?.data?.address?.pinCode +
        " " +
        res?.data?.data?.address?.city +
        " " +
        res?.data?.data?.address?.state;
      // setAddressData(address);
      // setSingleInvestor((prev: any) => ({ ...prev, address }));
      setbusNameDisabled(true);
      setSingleInvestor({
        ...singleInvestor,
        address
      });
      setSingleInvestor({
        ...singleInvestor,
        businessName: res?.data?.data?.gst_metadata?.name
      })
    } catch (err) {
      console.log(err);
    }
  };

  const getSingleInvestor = async (investorId: any) => {
    try {
      const res = await InvestorListService.getInvestorDetails(
        tokens,
        investorId
      );
      const {
        investorName,
        email,
        mobile,
        panCard,
        gstinNo,
        address,
        aadharCard,
        aadhaarAddress,
        businessName,
        commissionRate,
      } = res?.data?.data;
      setSingleInvestor({
        investorName,
        email,
        mobile: mobile.substring(3),
        panCard,
        gstinNo,
        address,
        aadharCard,
        aadhaarAddress,
        businessName,
        commissionRate,
      });
      setCountryCode(mobile.slice(0, 3));
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    }
  };

  const handleEmailChange = (event: any) => {
    setEmailVerified(false);
    const email = event.target.value;
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    setSingleInvestor((prev: any) => ({ ...prev, email }));
    setIsEmailVerifyDisabled(!emailRegex.test(email));
    setFieldChanged(true);
  };

  const handleMobileChange = (event: any) => {
    const mobile = event.target.value;
    setMobileVerified(false);
    setSingleInvestor((prev: any) => ({ ...prev, mobile }));
    setIsMobileVerifyDisabled(mobile.length !== 10);
    setFieldChanged(true);
  };

  const handleAadhaarChange = (event: any) => {
    const aadharCard = event.target.value;
    setSingleInvestor((prev: any) => ({ ...prev, aadharCard }));
    setSingleInvestor((prev: any) => ({ ...prev, aadhaarAddress: "" }));
    setAadharVerified(false);
    setIsAadhaarVerifiedDisabled(aadharCard.length !== 12);

    setFieldChanged(true);
  };

  const handleSendOtp = (key: string) => {
    setKey(key);
    setShowOTP(true);
    sendOtp(key);
  };

  const handleGstInChange = (event: any) => {
    const gstinNo = event.target.value;
    setSingleInvestor(() => ({ ...singleInvestor, gstinNo }));
    setUpdatedGstinNo(gstinNo);
    getInvestorAddressDetails(gstinNo);
    setbusNameDisabled(true);
    setFieldChanged(true);
  };

  const sendOtp = async (key: any) => {
    try {
      if (key == "aadhaarNo") {
        const payload = { aadhaarNo: singleInvestor.aadharCard, consent: true };
        const res = await InvestorListService.sendAadhaarOtp(tokens, payload);
        if (res?.data?.data?.success == true) {
          // setLoading(true);
          setShowOTP(true);
        } else {
          toast.error("Something went wrong!!");
        }
      } else {
        const payload =
          key === "mobile_no"
            ? { mobile_no: countryCode + singleInvestor.mobile }
            : { email: singleInvestor.email };
        const res = await InvestorListService.sendOTP(tokens, payload, key);
        if (res?.data?.data) {
          // setLoading(true);
          setOtpId(res.data.data.otpId);
        }
      }
    } catch (err: any) {
      console.log(err);
      toast.error("Something went wrong");
    }
  };

  const verifyOtp = async (otp: string) => {
    try {
      const payload = {
        otp: `${otp}`,
        otpId: otpId,
      };
      const res = await InvestorListService.verifyOTP(tokens, payload);
      if (res?.data?.data) {
        if (key === "email") {
          setEmailVerified(true);
          setShowOTP(false);
          setKey("");
          toast.success("Email verified successfully!");
        } else if (key === "mobile_no") {
          setMobileVerified(true);
          setShowOTP(false);
          toast.success("Mobile verified successfully!");
          setKey("");
        } else if (key === "aadhaarNo") {
          setAadharVerified(true);
          setShowOTP(false);
          toast.success("Aadhaar verified successfully!");
          setKey("");
        }
      }
    } catch (err: any) {
      console.log(err);
      toast.error(err?.data?.errors[0]?.title);
    }
  };

  const resendOtp = async () => {
    try {
      const payload = {
        otpId: otpId,
      };

      const res = await InvestorListService.resendOTP(tokens, payload, key);
      if (res.data.data) {
        toast.success("OTP Sent successfully!");
      }
    } catch (err: any) {
      console.log(err);
      toast.error(err?.data?.errors[0]?.title);
    }
  };

  const verifyAadhaarOtp = async (otpData: any) => {
    try {
      const data = {
        aadhaarNo: singleInvestor.aadharCard,
        otp: otpData,
      };

      const res = await InvestorListService.verifyAadhaarOtp(tokens, data);
      if (res.status == 200) {
        const res2: any =
          res.data.data.aadharData.address.address1 +
          "," +
          res.data.data.aadharData.address.address2 +
          "," +
          res.data.data.aadharData.address.city +
          "," +
          res.data.data.aadharData.address.pinCode +
          "," +
          res.data.data.aadharData.address.state;
        // setAadharAddress(res2);
        setSingleInvestor((prev: any) => ({ ...prev, aadhaarAddress: res2 }));
        setAadharVerified(true);
        setShowOTP(false);
        toast.success("Aadhar verified successfully!");
        setKey("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSingleInvestor(id);
  }, [id]);

  useEffect(() => {
    if (singleInvestor?.panCard) {
      getGstIn(singleInvestor?.panCard);
    }
  }, [singleInvestor?.panCard]);

  return (
    <Layout type={"InvestorModule"}>
      <ToastContainer />
      {showOTP ? (
        key == "aadhaarNo" ? (
          <OTPComponent
            data={{ aadhaarNo: singleInvestor.aadharCard }}
            onClose={() => setShowOTP(false)}
            onVerify={(otp: any) => verifyAadhaarOtp(otp)}
            onResend={() => sendOtp("aadhaarNo")}
          />
        ) : (
          <OTPComponent
            data={
              key === "email"
                ? { email: singleInvestor.email }
                : { mobile_no: countryCode + singleInvestor.mobile }
            }
            onClose={() => setShowOTP(false)}
            onVerify={(otp: any) => verifyOtp(otp)}
            onResend={() => resendOtp()}
          />
        )
      ) : (
        ""
      )}
      <div>
        <button
          onClick={() => {
            history.goBack();
          }}
          className="-mr-2 flex items-center rounded text-white font-bold text-xs uppercase"
        >
          <svg
            className="w-7"
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fill-rule="evenodd"
            clip-rule="evenodd"
          >
            <path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" />
          </svg>{" "}
        </button>
      </div>
      <div className="flex justi0fy-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">
          {"Edit Business Partner"}
        </h1>
      </div>
      {Loading ? (
        <Loader />
      ) : (
        <div className="flex flex-row">
          <div className="border bg-white rounded border-gray-200 shadow">
            <div className="flex-1 p-5">
              <div className="w-full">
                <div className="flex flex-wrap -mx-3 mb-2">

                <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Name
                    </label>
            
                      <input
                        className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                        id="investorName"
                        type="text"
                        placeholder="investorName"
                        autoComplete="none"
                        onChange={(event) =>
                          {
                            setSingleInvestor({
                              ...singleInvestor,
                              investorName: event.target.value,
                            });
                            setFieldChanged(true);
                          }
              
                        }
                  
                        value={singleInvestor.investorName}
                    
                      />
                     
                    
                    {validator.message(
                      "investorName",
                      singleInvestor.investorName,
                      "required|investorName"
                    )}
                    {validatorStatus ? (
                      <span className="text-xs text-red-700" id="investorName">
                        {getValidatorError("investorName")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <div className="flex">
                      <input
                        className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                        id="email"
                        type="email"
                        placeholder="Email"
                        autoComplete="none"
                        // onChange={(event) =>
                        //   setSingleInvestor({
                        //     ...singleInvestor,
                        //     email: event.target.value,
                        //   })
                        // }
                        onChange={handleEmailChange}
                        value={singleInvestor.email}
                        // disabled={emailVerified}
                      />
                      {emailVerified && !isEmailVerifyDisabled ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="green"
                          style={{ margin: "8px", justifyContent: "center" }}
                        >
                          <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 17.93l-5.477-5.48 1.41-1.41 4.066 4.067 8.044-8.047 1.41 1.41-9.453 9.46z" />
                        </svg>
                      ) : (
                        <button
                          className="ml-2 text-white px-3 py-1 rounded"
                          style={{
                            backgroundColor: isEmailVerifyDisabled
                              ? "gray"
                              : "rgb(34, 11, 59)",
                            fontWeight: "bold",
                            padding: "8px 12px",
                            borderRadius: "4px",
                            color: "white",
                            cursor: isEmailVerifyDisabled
                              ? "not-allowed"
                              : "pointer",
                            opacity: isEmailVerifyDisabled ? 0.6 : 1,
                          }}
                          disabled={isEmailVerifyDisabled}
                          onClick={() => handleSendOtp("email")}
                        >
                          Verify
                        </button>
                      )}
                    </div>

                    {validator.message(
                      "email",
                      singleInvestor.email,
                      "required|email"
                    )}
                    {validatorStatus ? (
                      <span className="text-xs text-red-700" id="email">
                        {getValidatorError("email")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="mobile"
                    >
                      Mobile No
                    </label>

                    <div className="flex flex-wrap items-stretch w-full relative">
                      <div className="flex -mr-px">
                        <select
                          className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark form-control country-select"
                          onChange={(event) =>
                            setCountryCode(event.target.value)
                          }
                          value={countryCode}
                        >
                          <option value={"+91"}>+91</option>
                          <option value={"+94"}>+94</option>
                        </select>
                      </div>
                      <input
                        className="flex-shrink flex-grow flex-auto  w-100 h-10  leading-normal w-px flex-1 bbg-grey-lighter text-grey-darker border border-grey-lighter rounded rounded-l-none px-3 relative focus:border-blue focus:shadow"
                        id="mobile"
                        name="mobile"
                        type="number"
                        placeholder="Mobile Number"
                        autoComplete="none"
                        // onChange={(event) =>
                        //   setSingleInvestor({
                        //     ...singleInvestor,
                        //     mobile: event.target.value,
                        //   })
                        // }
                        onChange={handleMobileChange}
                        value={singleInvestor?.mobile}
                        // value={singleInvestor?.mobile || ''}
                        // disabled={mobileVerified}
                      />
                      {mobileVerified && !isMobileVerifyDisabled ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="green"
                          style={{ margin: "8px", justifyContent: "center" }}
                        >
                          <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 17.93l-5.477-5.48 1.41-1.41 4.066 4.067 8.044-8.047 1.41 1.41-9.453 9.46z" />
                        </svg>
                      ) : (
                        <button
                          className={`ml-2 text-white px-3 py-1 rounded`}
                          style={{
                            backgroundColor: isMobileVerifyDisabled
                              ? "gray"
                              : "rgb(34, 11, 59)",
                            fontWeight: "bold",
                            marginLeft: "8px",
                            padding: "8px 12px",
                            borderRadius: "4px",
                            color: "white",
                            cursor: isMobileVerifyDisabled
                              ? "not-allowed"
                              : "pointer",
                            opacity: isMobileVerifyDisabled ? 0.6 : 1,
                          }}
                          // onClick={() => setShowOTP(true)}
                          disabled={singleInvestor?.mobile?.length !== 10}
                          onClick={() => handleSendOtp("mobile_no")}
                        >
                          Verify
                        </button>
                      )}
                    </div>

                    {validator.message(
                      "mobile",
                      singleInvestor.mobile,
                      "required|number"
                    )}
                    {validatorStatus ? (
                      <span className="text-xs text-red-700" id="mobile">
                        {getValidatorError("mobile")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="aadharCard"
                    >
                      Aadhaar
                    </label>
                    <div className="flex">
                      <input
                        className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                        id="aadharCard"
                        type="text"
                        placeholder="Aadhaar"
                        autoComplete="none"
                        onChange={handleAadhaarChange}
                        value={singleInvestor.aadharCard}
                      />
                      {aadhaarVerified && !isAadhaarVerifiedDisabled ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="green"
                          style={{ margin: "8px", justifyContent: "center" }}
                        >
                          <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 17.93l-5.477-5.48 1.41-1.41 4.066 4.067 8.044-8.047 1.41 1.41-9.453 9.46z" />
                        </svg>
                      ) : (
                        <button
                          className="ml-2 text-white px-3 py-1 rounded"
                          style={{
                            backgroundColor: isAadhaarVerifiedDisabled
                              ? "gray"
                              : "rgb(34, 11, 59)",
                            fontWeight: "bold",
                            padding: "8px 12px",
                            borderRadius: "4px",
                            color: "white",
                            cursor: isAadhaarVerifiedDisabled
                              ? "not-allowed"
                              : "pointer",
                            opacity: isAadhaarVerifiedDisabled ? 0.6 : 1,
                          }}
                          disabled={isAadhaarVerifiedDisabled}
                          onClick={() => handleSendOtp("aadhaarNo")}
                        >
                          Verify
                        </button>
                      )}
                    </div>

                    {validator.message(
                      "aadharCard",
                      singleInvestor.aadharCard,
                      "required|aadharCard"
                    )}
                    {validatorStatus ? (
                      <span className="text-xs text-red-700" id="aadharCard">
                        {getValidatorError("aadharCard")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="aadhaar"
                    >
                      Aadhaar Address
                    </label>

                    <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                      id="aadhaarAddress"
                      type="text"
                      placeholder="Aadhaar Address"
                      autoComplete="none"
                      value={singleInvestor.aadhaarAddress}
                      readOnly
                    />
                  </div>

                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="user-id"
                    >
                      PAN
                    </label>
                    <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                      name="pan"
                      id="pan"
                      type="text"
                      placeholder="PAN"
                      autoComplete="none"
                      onChange={(event) => {
                        setSingleInvestor({
                          ...singleInvestor,
                          panCard: event.target.value,
                          gstinNo: "",
                          address: "",
                          businessName: "",
                        });
                        setIsPanChanged(true);
                        setbusNameDisabled(false);
                        setFieldChanged(true);
                        getGstIn(event.target.value);
                      }}
                      value={singleInvestor?.panCard}
                    />
                    {validator.message(
                      "pan",
                      singleInvestor.panCard,
                      "required"
                    )}
                    {validatorStatus ? (
                      <span className="text-xs text-red-700" id="pan">
                        {getValidatorError("pan")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="gstin"
                    >
                      GSTIN number
                    </label>
                    <select
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="gstinNo"
                      value={
                        updatedGstinNo === ""
                          ? singleInvestor?.gstinNo
                          : updatedGstinNo
                      }
                      onChange={handleGstInChange}

                    >
                      <option selected={singleInvestor?.gstinNo}>
                        Select GSTIN
                      </option>

                      {gstInsList?.map((el: any, index) => (
                        <option key={index} value={el?.gstin}>
                          {el?.gstin}
                        </option>
                      ))}
                    </select>

                    {/* {validator.message(
                        "gstinNo",
                        singleInvestor.gstinNo,
                        "required|gstinNo"
                      )} */}
                    {/* {validatorStatus ? (
                        <span className="text-xs text-red-700" id="gstinNo">
                          {getValidatorError("gstinNo")}
                        </span>
                      ) : (
                        ""
                      )} */}
                  </div>

                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="user-id"
                    >
                      Business Name
                    </label>
                    <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                      name="businessName"
                      id="businessName"
                      type="text"
                      placeholder="BUSINESS NAME"
                      autoComplete="none"
                     disabled={busNameDisabled}
                      value={singleInvestor?.businessName}
                      onChange={(event) => {
                        setSingleInvestor({
                          ...singleInvestor,
                          businessName: event.target.value,
                        });
                        setFieldChanged(true);
                      }}
                    />
                    {validator.message(
                      "businessName",
                      singleInvestor.businessName,
                      "required|commissionRate"
                    )}
                    {validatorStatus ? (
                      <span className="text-xs text-red-700" id="businessName">
                        {getValidatorError("businessName")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="address"
                    >
                      Business Address
                    </label>

                    <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                      id="address"
                      type="text"
                      placeholder="Business Address"
                      autoComplete="none"
                      value={
                        singleInvestor?.address !== "{}"
                          ? singleInvestor?.address
                          : ""
                      }
                      readOnly
                    />
                  </div>

                  <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="commissionRate"
                    >
                    REVENUE SHARE (%)
                    </label>

                    <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                      id="commissionRate"
                      type="text"
                      placeholder="Revenue Share (%)"
                      autoComplete="none" 
                      onChange={(event) => {
                        setSingleInvestor({
                          ...singleInvestor,
                          commissionRate: event.target.value,
                        });
                        setFieldChanged(true);
                      }}
                      value={singleInvestor?.commissionRate}

                    />
                     {validator.message(
                      "commissionRate",
                      singleInvestor.commissionRate,
                      "required|commissionRate"
                    )}
                    {validatorStatus ? (
                      <span className="text-xs text-red-700" id="commissionRate">
                        {getValidatorError("commissionRate")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>


                  <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <div className="flex items-center justify-end sm:py-3  sm:px-6 border-solid border-blueGray-200 rounded-b">
                      <div>
                        <button
                          id="modal"
                          className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => history.goBack()}
                        >
                          Close
                        </button>
                      </div>
                      <div>
                        <button
                          className={`text-white background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md bg-purple-950`}
                          type="button"
                          style={{
                            backgroundColor: isUpdateButtonDisabled
                              ? "gray"
                              : "rgb(34, 11, 59)",
                            fontWeight: "bold",
                            marginLeft: "8px",
                            padding: "8px 12px",
                            borderRadius: "4px",
                            color: "white",
                            cursor: isUpdateButtonDisabled
                              ? "not-allowed"
                              : "pointer",
                            opacity: isUpdateButtonDisabled ? 0.6 : 1,
                          }}
                          onClick={() => {
                            updateInvestorData();
                          }}
                          disabled={isUpdateButtonDisabled}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};
export default SingleInvestor;
