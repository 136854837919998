import React, {useState, useEffect} from "react";
import {useAuthStore} from "../../../Store/AuthStore";
import Layout from "../../Layout/Layout";
import {toast, ToastContainer} from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import moment from "moment";
import {Pagination} from "../../../Components-ui/Pagination";
import SettlementReportService, {
    logReportDownload,
    settlementReportDownload
} from "../Settlement/SettlementReportService";
import Modal from "../../../Components-ui/Modal/Modal";
import {saveAs} from "file-saver";
import * as XLSX from "xlsx";
import {ExportToExcel} from "../../Util/ExcelExport";
import customerService from "../../Customer/Services/Customer.service";
import Datetime from "react-datetime";
import {formatAmount} from "../../../Components-ui/NumberFormat";

const SettlementReport: React.FC = () => {
    const {tokens} = useAuthStore();
    const [children, setChildren] = useState<any>();
    const [show, setModalStatus] = useState<any>(false);
    const [Loading, setLoading] = useState<any>(false);
    const [settlements, setSettlements] = useState<any>([]);
    const [meta, setMetaData] = useState<any>({});
    const [settlementsDetails, setSettlementsDetails] = useState<any>([]);
    const [metaSettlementsDetails, setMetaDataSettlementsDetails] = useState<any>({});
    const [selectedSettlementId, setSelectedSettlementId] = useState<any>();
    const [partnerList, setPartnerList] = useState<any>([]);
    const [filters, setFilters] = useState<any>({
        provider: '',
        fromDate: null,
        toDate: null
    });

    const [modalData, setModalData] = useState<any>({});

    const [currentPage, setCurrentPage] = useState<number>(0);

    const loadSettlements = async (page: number, filter: any): Promise<void> => {
        console.log(filters)
        setLoading(true);
        return new Promise(async (resolve) => {
            SettlementReportService.getSettlements(tokens, page, filter).then((response: any) => {
                setSettlements(response.data.data.result);
                setMetaData({
                    current: response.data.meta.page,
                    pages: response.data.meta.totalPages,
                });
                setCurrentPage(response.data.meta.page);
                setLoading(false);
            }).catch(err => {
                toast.error("Something Went Wrong!");
                setLoading(false);
            });
        })
    };

    const loadSettlementsDetails = async (page: number, filter: any,id:any) => {
        SettlementReportService.getSettlementsDetails(tokens, page, filter,id).then((response: any) => {
            if(response && response.status === 200){
                setSettlementsDetails(response.data.data.result);
                setMetaDataSettlementsDetails({
                    current: response.data.meta.page,
                    pages: response.data.meta.totalPages,
                });
            }
            setLoading(false);
            setModalStatus(true);
        }).catch(err => {
            toast.error("Something Went Wrong!");
            setLoading(false);
        });
    };

    const downloadReport = (data:any) => {
        setLoading(true);
        SettlementReportService.settlementReportDownload(tokens, data).then((response: any) => {
            const href = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download',  'settlement_details_report.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        }).catch(err => {
            if(err){
                toast.error(err.data.errors[0].title);
            }else {
                toast.error("Something Went Wrong!");
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        setLoading(true);
        getPartners();
        loadSettlements(currentPage, {});
    }, []);

    const ResetForm = async () => {
        console.log(filters);
        setFilters({
            provider: '',
            fromDate: null,
            toDate: null
        });
        setCurrentPage(0);
        loadSettlements(0, {})
    }

    useEffect(() => {
        childrenElements();
    }, [settlementsDetails, show, modalData, metaSettlementsDetails]);

    const getPartners = () => {
        customerService.getPartnerList(tokens).then((response: any) => {
            setPartnerList(response.data.data);
        }).catch(err => {
            toast.error("Something Went Wrong!");
            setLoading(false);
        });
    }

    const childrenElements = () => {
        setChildren(<div className="w-full">
            <h1 className="font-bold">
                {'Settlement Record Details'}
            </h1>
            <div className="mb-3 bg-white p-2 pb-1 pr-3.5 pt-4 rounded shadow-2xl">
                <div className="flex-1 p-2">
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-id">
                                Provider
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="name" type="text" placeholder="Provider" disabled={true} readOnly={true}
                                value={modalData.provider} autoComplete="off"/>
                        </div>
                        <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-type">
                                Date
                            </label>
                            <div className="relative">
                                <input disabled={true} readOnly={true}
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="description" type="text" placeholder="Date" autoComplete="none"
                                     value={modalData.date}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-wrap mb-2">
                    {Loading ? (<Loader/>) : (
                        <div className="">
                            <table
                                className="whitespace-nowrap  rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs ">
                                <thead>
                                <tr className="text-left border-b border-gray-300">
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Method
                                    </th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Amount
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {(0 !== settlementsDetails.length) ?
                                    settlementsDetails.map((user: any, index: number) => {
                                        return (
                                            <tr key={index}
                                                className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                <td className="py-3 px-3 text-left relative">
                                                    {user.method}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {formatAmount(Number(user.balance))}
                                                </td>
                                            </tr>
                                        )
                                    })
                                    : (
                                        <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                            <td colSpan={3}
                                                className="text-center py-3 px-3 text-left relative">No
                                                data to
                                                display
                                            </td>
                                        </tr>)
                                }
                                </tbody>
                            </table>
                            <Pagination
                                meta={metaSettlementsDetails}
                                handlePageChange={(number: number) => {
                                    modalPaginationFilter(number)
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>);
    }

    const editModalElements = (item: any) => {
        setLoading(true);
        setModalData(item)
        setSelectedSettlementId(item.id);
        loadSettlementsDetails(0, item,item.id);
    }

    const paginationFilter = async (page: number) => {
        setCurrentPage(page);
        loadSettlements(page, filters);
    }

    const modalPaginationFilter = async (page: number) => {
        loadSettlementsDetails(page, {},selectedSettlementId);
    }

    const hideModal = () => {
        setModalStatus(false);
    };

    var valid = function (current: any) {
        return current.isAfter(moment(filters.fromDate).subtract(1, 'day'));
    };

    var validFrom = function (current: any) {
        const yesterday = moment().subtract(1, 'day');
        return current.isAfter(yesterday);
    };

    return (
        <Layout type={"PaymentModule"}>
            <ToastContainer/>
            <div className="flex justify-between items-center mb-5">
                <h1 className="text-2xl font-bold tracking-tight">
                    {"Settlement Report"}
                </h1>
            </div>
            <div className="border bg-white rounded border-gray-200 shadow">
                <div className="flex-1 p-2">
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-type">
                                Provider
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="user-type" onChange={event => setFilters({
                                    ...filters,
                                    provider: event.target.value
                                })} value={filters.provider}>
                                    <option value="">Select a Provider</option>
                                    <option value="RPY">RPY</option>
                                    <option value="DBS">DBS</option>
                                </select>
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="grid-state"
                            >
                                From Date
                            </label>
                            <div
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <Datetime
                                    // isValidDate={validFrom}
                                    timeFormat={""}
                                    dateFormat={"YYYY-MM-DD"}
                                    inputProps={{
                                        readOnly: true,
                                        placeholder: 'From Date',
                                        className: "focus:outline-none"
                                    }}
                                    onChange={(event: any) => {

                                        setFilters({

                                            ...filters,
                                            fromDate: moment(new Date(event).toISOString()).format("YYYY-MM-DD"),
                                            toDate: ((event.isAfter(new Date(filters.toDate)) ? moment(new Date(event).toISOString()).format("YYYY-MM-DD") : moment(filters.toDate).format("YYYY-MM-DD"))),
                                        });
                                    }} value={moment(filters.fromDate)}

                                    renderInput={(props) => {
                                        return <input {...props}
                                                     style={{width:'100%'}}
                                                      value={(filters.fromDate) ? (filters.fromDate) : ''} />
                                    }}
                                />
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="grid-state"
                            >
                                To Date
                            </label>
                            <div
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <Datetime
                                    isValidDate={valid}
                                    timeFormat={""}
                                    dateFormat={"YYYY-MM-DD"}
                                    inputProps={{ readOnly: true,placeholder: 'To Date', className: "focus:outline-none" }}
                                    onChange={(event: any) => setFilters({
                                        ...filters,
                                        toDate: moment(new Date(event).toISOString()).format("YYYY-MM-DD"),
                                    })} value={moment(filters.toDate)}

                                    renderInput={(props) => {
                                        return <input {...props}
                                        style={{width:'100%'}}
                                                      value={(filters.toDate) ? (filters.toDate) : ''} />
                                    }}
                                />

                            </div>
                        </div>
                        <div className="flex w-full justify-end px-3 mb-6 md:mb-0">
                            <div className="my-3">
                                <button
                                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                                    type="button"
                                    onClick={() => loadSettlements(0, filters)}
                                >
                                    Filter
                                </button>
                                <button
                                    className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150d"
                                    type="button"
                                    onClick={(e) => ResetForm()}>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded border-gray-200 shadow m-2">
                    {Loading ? (<Loader/>) : (
                        <div>
                            <div className="w-full">
                                <div className="overflow-x-auto">
                                    <table
                                        className="whitespace-nowrap  rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs ">
                                        <thead>
                                        <tr className="text-left border-b border-gray-300">
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Provider</th>
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Date</th>
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">In</th>
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Out</th>
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Balance
                                            </th>
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {(0 !== settlements.length) ?
                                            settlements.map((user: any, index: number) => {
                                                return (
                                                    <tr key={index}
                                                        className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                        <td className="py-3 px-3 text-left relative">
                                                            {user.provider || 'N/A'}
                                                        </td>
                                                        <td className="py-3 px-3 text-left relative">
                                                            {moment(user.date).format("DD-MM-YYYY HH:mm:ss") || 'N/A'}
                                                        </td>
                                                        <td className="py-3 px-3 text-left relative">
                                                            {formatAmount(Number(user.in))}
                                                        </td>
                                                        <td className="py-3 px-3 text-left relative">
                                                            {formatAmount(Number(user.out))}
                                                        </td>
                                                        <td className="py-3 px-3 text-left relative">
                                                            {formatAmount(Number(user.balance))}
                                                        </td>
                                                        <td className="py-3 px-3 text-left relative">
                                                            <button
                                                                className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                                type="button"
                                                                onClick={() => {
                                                                    editModalElements(user)
                                                                }}
                                                            >
                                                                View
                                                            </button>
                                                            <button
                                                                className="inline-flex border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                                type="button"
                                                                onClick={() => downloadReport(user)}
                                                            >
                                                                Download
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            : (
                                                <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                                    <td colSpan={22}
                                                        className="text-center py-3 px-3 text-left relative">No
                                                        data to
                                                        display
                                                    </td>
                                                </tr>)
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination
                                    meta={meta}
                                    handlePageChange={(number: number) => {
                                        paginationFilter(number)
                                    }}
                                />
                            </div>
                            <Modal size={'large'} show={show} handleClose={hideModal} children={children}/>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};
export default SettlementReport;
