import React, {useEffect, useState} from "react";
import {APPLY_RATES, APPLY_TYPES} from "../Utils/enum";
import TradeExecutionService from "../Services/trade-execution.service";
import ErrorToolTip from "../../Metal/ErrorToolTip";
import moment from "moment";

interface PropsType {
    mainRule: any,
    item: any,
    onSubItemChange: any,
    onAddItems: any,
    onRemoveItem: any,
    items: any,
    subRuleErrors: any,
    isSelectedSubItemService: any,
    uom: any,
    onEditItem: any,
    onEditSubmitItem: any,
    subRuleEditErrors: any,
    selectedMetalCode: any
}

const ItemList: React.FC<PropsType> = ({
       mainRule,
       item,
       onSubItemChange,
       onAddItems,
       onRemoveItem,
       items,
       subRuleErrors,
       isSelectedSubItemService,
       uom,
       onEditItem,
       onEditSubmitItem,
       subRuleEditErrors,
       selectedMetalCode,
   }) => {

    const [PriceRules, setPriceRules] = useState<any>([]);
    const [Discounts, setDiscounts] = useState<any>([]);
    const [ActiveColSubRule, setActiveColSubRule] = useState<string>("");

    /**
     * Create new roe for sub rules
     */
    const createNewRow = () => {
        return (
            <tr key={0} className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100">
                <td className="py-3 px-3 text-center relative">
                    <div>
                        <input
                            type="checkbox"
                            name="required"
                            id="required"
                            disabled={mainRule.details.length === 0}
                            checked={item.required}
                            onChange={(e) => {
                                onSubItemChange(e)
                            }}
                        />
                    </div>
                </td>
                <td className="py-3 px-3 text-left relative">
                    <div>
                        <select
                            onMouseLeave={(e) => setActiveColSubRule("")}
                            onMouseOver={(e) => setActiveColSubRule("itemCode")}
                            id="itemCode"
                            name="itemCode"
                            autoComplete="item-code"
                            placeholder="Item Code"
                            value={item.itemCode}
                            className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md 
                            shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                            ${subRuleErrors.itemCode && "border-red-600 focus:border-red-600"}`}
                            onChange={(e) => {
                                onSubItemChange(e)
                            }}
                        >
                            {
                                mainRule.details.length > 0 && (
                                    <option value="" disabled>Select an item code</option>
                                )
                            }

                            {
                                items.map((item: any, index: number) => {
                                    return (
                                        <option key={index}
                                                disabled={mainRule.details.length === 0 || mainRule.details.map((x: any) => x.itemCode).includes(item.itemCode)}
                                                value={item.itemCode}>{item.itemCode}</option>
                                    )

                                })
                            }
                        </select>

                        <ErrorToolTip
                            error={subRuleErrors.itemCode}
                            count={1}
                            type={"itemCode"}
                            active={ActiveColSubRule}
                        />
                    </div>

                </td>
                <td className="py-3 px-3 text-left relative">
                    <div>
                        <select
                            onMouseLeave={(e) => setActiveColSubRule("")}
                            onMouseOver={(e) => setActiveColSubRule("applyType")}
                            name="applyType"
                            id="applyType"
                            value={item.applyType}
                            placeholder="Apply Type"
                            className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md 
                            shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                            ${subRuleErrors.applyType && "border-red-600 focus:border-red-600"}`}
                            onChange={(e) => {
                                onSubItemChange(e)
                            }}
                        >
                            <option value="" disabled>Select an apply type</option>
                            {
                                APPLY_TYPES.map((applyType: any, index: number) => {
                                    if (mainRule.details.length > 0) {
                                        return (
                                            <option key={index} value={applyType.value}
                                                    disabled={isSelectedSubItemService && applyType.value === 'RULE'}>{applyType.label}</option>
                                        )
                                    } else {
                                        return (
                                            <option key={index} value={applyType.value}
                                                    disabled={applyType.value === 'RATE'}>{applyType.label}</option>
                                        )
                                    }


                                })
                            }
                        </select>
                        <ErrorToolTip
                            error={subRuleErrors.applyType}
                            count={1}
                            type={"applyType"}
                            active={ActiveColSubRule}
                        />
                    </div>

                </td>
                <td className="py-3 px-3 text-left relative">
                    {
                        item.applyType === 'RULE' ? (
                            <div>
                                <select
                                    onMouseLeave={(e) => setActiveColSubRule("")}
                                    onMouseOver={(e) => setActiveColSubRule("applyTypeValue")}
                                    name="applyTypeValue"
                                    id="applyTypeValue"
                                    value={item.applyTypeValue}
                                    placeholder="Apply Type Value"
                                    className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md 
                            shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                            ${subRuleErrors.applyTypeValue && "border-red-600 focus:border-red-600"}`}
                                    onChange={(e) => {
                                        onSubItemChange(e)
                                    }}
                                >
                                    <option value="" disabled>Select a price rule</option>
                                    {
                                        PriceRules.map((priceRule: any, index: number) => {
                                            return (
                                                <option key={index} value={priceRule.id}>{priceRule.ruleName}</option>
                                            )

                                        })
                                    }


                                </select>
                                <ErrorToolTip
                                    error={subRuleErrors.applyTypeValue}
                                    count={1}
                                    type={"applyTypeValue"}
                                    active={ActiveColSubRule}
                                />
                            </div>

                        ) : (
                            <div>
                                <input
                                    onMouseLeave={(e) => setActiveColSubRule("")}
                                    onMouseOver={(e) => setActiveColSubRule("applyTypeValue")}
                                    type="number"
                                    min="0"
                                    name="applyTypeValue"
                                    id="applyTypeValue"
                                    value={item.applyTypeValue}
                                    placeholder="Apply Type Value"
                                    className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md 
                            shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                            ${subRuleErrors.applyTypeValue && "border-red-600 focus:border-red-600"}`}
                                    onChange={(e) => {
                                        onSubItemChange(e)
                                    }}
                                />
                                <ErrorToolTip
                                    error={subRuleErrors.applyTypeValue}
                                    count={1}
                                    type={"applyTypeValue"}
                                    active={ActiveColSubRule}
                                />
                            </div>
                        )

                    }
                </td>
                <td className="py-3 px-3 text-left relative">
                    <div>
                        <select
                            onMouseLeave={(e) => setActiveColSubRule("")}
                            onMouseOver={(e) => setActiveColSubRule("uom")}
                            name="uom"
                            id="uom"
                            value={item.uom}
                            placeholder="UOM"
                            className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md 
                            shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                            ${subRuleErrors.uom && "border-red-600 focus:border-red-600"}`}
                            onChange={(e) => {
                                onSubItemChange(e)
                            }}
                        >
                            <option value="" disabled>Select an uom</option>
                            {
                                uom.map((uom: any, index: number) => {
                                    return (
                                        <option key={index} value={uom.uomCode}>{uom.uomName}</option>
                                    )

                                })
                            }
                        </select>
                        <ErrorToolTip
                            error={subRuleErrors.uom}
                            count={1}
                            type={"uom"}
                            active={ActiveColSubRule}
                        />
                    </div>
                </td>
                <td className="py-3 px-3 text-left relative">
                    <div>
                        <select
                            onMouseLeave={(e) => setActiveColSubRule("")}
                            onMouseOver={(e) => setActiveColSubRule("applyRateOn")}
                            name="applyRateOn"
                            id="applyRateOn"
                            placeholder="Apply Rate on"
                            value={item.applyRateOn}
                            className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md 
                            shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                            ${subRuleErrors.applyRateOn && "border-red-600 focus:border-red-600"}`}
                            onChange={(e) => {
                                onSubItemChange(e)
                            }}
                        >
                            {/*{
                                mainRule.details.length > 0 && (
                                    <option value="" disabled>Select an item code</option>
                                )
                            }*/}
                            <option value="" disabled>Select an item code</option>

                            {
                                APPLY_RATES.map((applyRate: string, index: number) => {
                                    if (mainRule.details.length === 0) {
                                        return (
                                            <option key={index} disabled value={applyRate}>{applyRate}</option>
                                        )
                                    } else {
                                        return (
                                            <option key={index}
                                                    disabled={item.applyType === 'RATE' && applyRate === 'ACTUAL'}
                                                    value={applyRate}>{applyRate}</option>
                                        )

                                    }

                                })
                            }
                        </select>
                        <ErrorToolTip
                            error={subRuleErrors.applyRateOn}
                            count={1}
                            type={"applyRateOn"}
                            active={ActiveColSubRule}
                        />
                    </div>

                </td>
                <td className="py-3 px-3 text-left relative">
                    <div>
                        <select
                            onMouseLeave={(e) => setActiveColSubRule("")}
                            onMouseOver={(e) => setActiveColSubRule("discountId")}
                            name="discountId"
                            id="discountId"
                            placeholder="Discount"
                            value={item.discountId}
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e) => {
                                onSubItemChange(e)
                            }}
                        >
                            <option value="" disabled>Select a discount</option>
                            {
                                Discounts.map((discount: any, index: number) => {
                                    return (
                                        <option key={index} value={discount.id}>{discount.name}</option>
                                    )

                                })
                            }
                        </select>
                    </div>
                </td>
                <td className="py-3 px-3 text-left relative">
                    <div>
                        <input
                            onMouseLeave={(e) => setActiveColSubRule("")}
                            onMouseOver={(e) => setActiveColSubRule("order")}
                            type="number"
                            name="order"
                            id="order"
                            min="0"
                            value={item.order}
                            disabled={mainRule.details.length === 0}
                            placeholder="order"
                            className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md 
                            shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                            ${subRuleErrors.order && "border-red-600 focus:border-red-600"}`}
                            onChange={(e) => {
                                onSubItemChange(e)
                            }}
                        />
                        <ErrorToolTip
                            error={subRuleErrors.order}
                            count={1}
                            type={"order"}
                            active={ActiveColSubRule}
                        />
                    </div>
                </td>
                <td>
                    <button
                        className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                        type="button"
                        onClick={onAddItems}
                    >
                        Add
                    </button>
                </td>
            </tr>
        )
    }

    /**
     * Render added sub rules
     * @param itemDetails
     * @param index
     */
    const renderTableRow = (itemDetails: any, index: number) => {
        return (
            <tr key={index + 1} className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100">
                <td className="py-3 px-3 text-center relative">
                    <input
                        type="checkbox"
                        checked={itemDetails.required}
                        disabled={!itemDetails.isEdit || index === 0}
                        name="requiredEdit"
                        id="requiredEdit"
                        onChange={(e) => {
                            onSubItemChange(e, itemDetails, index)
                        }}

                    />
                </td>
                <td className="py-3 px-3 text-center relative">
                    {
                        itemDetails.isEdit && itemDetails.itemCode !== mainRule.itemCode ? (
                            <div>
                                <select
                                    onMouseLeave={(e) => setActiveColSubRule("")}
                                    onMouseOver={(e) => setActiveColSubRule("itemCodeEdit")}
                                    id="itemCodeEdit"
                                    name="itemCodeEdit"
                                    autoComplete="item-code"
                                    placeholder="Item Code"
                                    value={itemDetails.itemCode}
                                    className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md 
                            shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                            ${subRuleEditErrors.itemCode && "border-red-600 focus:border-red-600"}`}
                                    onChange={(e) => {
                                        onSubItemChange(e, itemDetails, index)
                                    }}
                                >
                                    {
                                        mainRule.details.length > 0 && (
                                            <option value="" disabled>Select an item code</option>
                                        )
                                    }

                                    {
                                        items.map((item: any, index: number) => {
                                            return (
                                                <option key={index}
                                                        disabled={mainRule.details.length === 0 || mainRule.details.map((x: any) => x.itemCode).includes(item.itemCode)}
                                                        value={item.itemCode}>{item.itemCode}</option>
                                            )

                                        })
                                    }
                                </select>

                                <ErrorToolTip
                                    error={subRuleEditErrors.itemCode}
                                    count={1}
                                    type={"itemCodeEdit"}
                                    active={ActiveColSubRule}
                                />
                            </div>
                        ) : itemDetails.itemCode
                    }
                </td>
                <td className="py-3 px-3 text-center relative">
                    {
                        itemDetails.isEdit ? (
                            <div>
                                <select
                                    onMouseLeave={(e) => setActiveColSubRule("")}
                                    onMouseOver={(e) => setActiveColSubRule("applyTypeEdit")}
                                    name="applyTypeEdit"
                                    id="applyTypeEdit"
                                    value={itemDetails.applyType}
                                    placeholder="Apply Type"
                                    className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md 
                            shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                            ${subRuleEditErrors.applyType && "border-red-600 focus:border-red-600"}`}
                                    onChange={(e) => {
                                        onSubItemChange(e, itemDetails, index)
                                    }}
                                >
                                    <option value="" disabled>Select an apply type</option>
                                    {
                                        APPLY_TYPES.map((applyType: any, index: number) => {
                                            if (itemDetails.itemCode !== mainRule.itemCode) {
                                                return (
                                                    <option key={index} value={applyType.value}
                                                            disabled={isSelectedSubItemService && applyType.value === 'RULE'}>{applyType.label}</option>
                                                )
                                            } else {
                                                return (
                                                    <option key={index} value={applyType.value}
                                                            disabled={applyType.value === 'RATE'}>{applyType.label}</option>
                                                )
                                            }


                                        })
                                    }
                                </select>
                                <ErrorToolTip
                                    error={subRuleEditErrors.applyType}
                                    count={1}
                                    type={"applyTypeEdit"}
                                    active={ActiveColSubRule}
                                />
                            </div>
                        ) : (<p>{itemDetails.applyType}</p>)
                    }
                </td>
                <td className="py-3 px-3 text-center relative">
                    {
                        itemDetails.isEdit ?
                            itemDetails.applyType === 'RULE' ? (
                                <div>
                                    <select
                                        onMouseLeave={(e) => setActiveColSubRule("")}
                                        onMouseOver={(e) => setActiveColSubRule("applyTypeValueEdit")}
                                        name="applyTypeValueEdit"
                                        id="applyTypeValueEdit"
                                        value={itemDetails.applyTypeValue}
                                        placeholder="Apply Type Value"
                                        className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md 
                            shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                            ${subRuleEditErrors.applyTypeValue && "border-red-600 focus:border-red-600"}`}
                                        onChange={(e) => {
                                            onSubItemChange(e, itemDetails, index)
                                        }}
                                    >
                                        {
                                            PriceRules.map((priceRule: any, index: number) => {
                                                return (
                                                    <option key={index} value={priceRule.id}>{priceRule.ruleName}</option>
                                                )

                                            })
                                        }


                                    </select>
                                    <ErrorToolTip
                                        error={subRuleEditErrors.applyTypeValue}
                                        count={1}
                                        type={"applyTypeValueEdit"}
                                        active={ActiveColSubRule}
                                    />
                                </div>

                            ) : (
                                <div>
                                    <input
                                        onMouseLeave={(e) => setActiveColSubRule("")}
                                        onMouseOver={(e) => setActiveColSubRule("applyTypeValueEdit")}
                                        type="number"
                                        min="0"
                                        name="applyTypeValueEdit"
                                        id="applyTypeValueEdit"
                                        value={itemDetails.applyTypeValue}
                                        placeholder="Apply Type Value"
                                        className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md 
                            shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                            ${subRuleEditErrors.applyTypeValue && "border-red-600 focus:border-red-600"}`}
                                        onChange={(e) => {
                                            onSubItemChange(e, itemDetails, index)
                                        }}
                                    />
                                    <ErrorToolTip
                                        error={subRuleEditErrors.applyTypeValue}
                                        count={1}
                                        type={"applyTypeValueEdit"}
                                        active={ActiveColSubRule}
                                    />
                                </div>
                            )
                            :
                            itemDetails.applyType === 'RULE' ? (
                                <p>{PriceRules.filter((x: any) => x.id === Number(itemDetails.applyTypeValue)).length > 0 ? PriceRules.filter((x: any) => x.id === Number(itemDetails.applyTypeValue))[0].ruleName : "N/A"}</p>
                            ) : (<p>{itemDetails.applyTypeValue}</p>)
                    }
                </td>
                <td className="py-3 px-3 text-center relative">
                    {
                        itemDetails.isEdit ? (
                            <div>
                                <select
                                    onMouseLeave={(e) => setActiveColSubRule("")}
                                    onMouseOver={(e) => setActiveColSubRule("uomEdit")}
                                    name="uomEdit"
                                    id="uomEdit"
                                    value={itemDetails.uom}
                                    placeholder="UOM"
                                    className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md 
                            shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                            ${subRuleEditErrors.uom && "border-red-600 focus:border-red-600"}`}
                                    onChange={(e) => {
                                        onSubItemChange(e, itemDetails, index)
                                    }}
                                >
                                    {
                                        uom.map((uom: any, index: number) => {
                                            return (
                                                <option key={index} value={uom.uomCode}>{uom.uomName}</option>
                                            )

                                        })
                                    }
                                </select>
                                <ErrorToolTip
                                    error={subRuleEditErrors.uom}
                                    count={1}
                                    type={"uomEdit"}
                                    active={ActiveColSubRule}
                                />
                            </div>
                        ) : uom.filter((x: any) => x.uomCode === itemDetails.uom).length > 0 ? uom.filter((x: any) => x.uomCode === itemDetails.uom)[0].uomName : "N/A"

                    }
                </td>
                <td className="py-3 px-3 text-center relative">
                    {
                        itemDetails.isEdit && itemDetails.itemCode !== mainRule.itemCode ? (
                            <div>
                                <select
                                    onMouseLeave={(e) => setActiveColSubRule("")}
                                    onMouseOver={(e) => setActiveColSubRule("applyRateOnEdit")}
                                    name="applyRateOnEdit"
                                    id="applyRateOnEdit"
                                    placeholder="Apply Rate on"
                                    value={itemDetails.applyRateOn}
                                    className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md 
                            shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                            ${subRuleEditErrors.applyRateOn && "border-red-600 focus:border-red-600"}`}
                                    onChange={(e) => {
                                        onSubItemChange(e, itemDetails, index)
                                    }}
                                >
                                    {
                                        mainRule.details.length > 0 && (
                                            <option value="" disabled>Select an item code</option>
                                        )
                                    }

                                    {
                                        APPLY_RATES.map((applyRate: string, index: number) => {
                                            return  (
                                                <option key={index}
                                                        disabled={item.applyType === 'RATE' && applyRate === 'ACTUAL'}
                                                        value={applyRate}>{applyRate}</option>
                                            )

                                        })
                                    }
                                </select>
                                <ErrorToolTip
                                    error={subRuleEditErrors.applyRateOn}
                                    count={1}
                                    type={"applyRateOnEdit"}
                                    active={ActiveColSubRule}
                                />
                            </div>
                        ) :  itemDetails.applyRateOn
                    }

                </td>
                <td className="py-3 px-3 text-center relative">
                    {
                        itemDetails.isEdit ? (
                            <div>
                                <select
                                    onMouseLeave={(e) => setActiveColSubRule("")}
                                    onMouseOver={(e) => setActiveColSubRule("discountIdEdit")}
                                    name="discountIdEdit"
                                    id="discountIdEdit"
                                    placeholder="Discount"
                                    value={itemDetails.discountId ? itemDetails.discountId : ""}
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    onChange={(e) => {
                                        onSubItemChange(e, itemDetails, index)
                                    }}
                                >
                                    <option value="" disabled>Select a discount</option>
                                    {
                                        Discounts.map((discount: any, index: number) => {
                                            return (
                                                <option key={index} value={discount.id}>{discount.name}</option>
                                            )

                                        })
                                    }
                                </select>
                            </div>
                        )
                            :
                            itemDetails.discountId && (
                                <p>{Discounts.filter((x: any) => x.id === Number(itemDetails.discountId)).length > 0 ? Discounts.filter((x: any) => x.id === Number(itemDetails.discountId))[0].name : "N/A"}</p>
                            )
                    }
                </td>
                <td className="py-3 px-3 text-center relative">
                    {
                        itemDetails.isEdit && itemDetails.itemCode !== mainRule.itemCode ? (
                            <div>
                                <input
                                    onMouseLeave={(e) => setActiveColSubRule("")}
                                    onMouseOver={(e) => setActiveColSubRule("orderEdit")}
                                    type="number"
                                    name="orderEdit"
                                    id="orderEdit"
                                    min="0"
                                    value={itemDetails.order}
                                    placeholder="Order"
                                    className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md 
                            shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                            ${subRuleEditErrors.order && "border-red-600 focus:border-red-600"}`}
                                    onChange={(e) => {
                                        onSubItemChange(e, itemDetails, index)
                                    }}
                                />
                                <ErrorToolTip
                                    error={subRuleEditErrors.order}
                                    count={1}
                                    type={"orderEdit"}
                                    active={ActiveColSubRule}
                                />
                            </div>
                        ) : itemDetails.order
                    }
                </td>
                <td>
                    <div className="flex py-3 px-3">

                        {
                            !itemDetails.isEdit ? (
                                <button type="button" onClick={() => onEditItem(index, item)} className="h-8 w-8 text-purple-500 mr-3 focus:outline-none">
                                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round">
                                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/>
                                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/>
                                    </svg>
                                </button>
                            )
                                : (
                                    <button type="button" onClick={() => onEditSubmitItem(index, item)} className="h-8 w-8 text-purple-500 mr-3 focus:outline-none">
                                        <svg className="h-7 w-7 text-green-500" fill="none" viewBox="0 0 24 24"
                                             stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                    </button>
                                )
                        }
                        {
                            index !== 0 && (
                                <button type="button" onClick={() => onRemoveItem(index)}
                                        className="h-8 w-8 text-red-500 focus:outline-none">
                                    <svg className="h-6 w-6 text-red-500" viewBox="0 0 24 24" fill="none"
                                         stroke="currentColor"
                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <circle cx="12" cy="12" r="10"/>
                                        <line x1="15" y1="9" x2="9" y2="15"/>
                                        <line x1="9" y1="9" x2="15" y2="15"/>
                                    </svg>
                                </button>
                            )
                        }
                    </div>
                </td>
            </tr>
        )
    }

    useEffect((): any => {
        if (mainRule.currency !== "") {
            let params = {
                currency: mainRule.currency
            }
            getAllDiscounts(params);
        }
    }, [mainRule.currency]);

    useEffect((): any => {
        if (selectedMetalCode !== "" && mainRule.currency !== "") {
            getPriceRules();
        }
        // eslint-disable-next-line
    }, [selectedMetalCode, mainRule.currency, mainRule.partnerId]);

    useEffect((): any => {
        if (isSelectedSubItemService) {
            let params = {
                dType: 'AMOUNT',
                iTypes: ['AMOUNT', 'RATE'],
                currency: mainRule.currency
            }
            getAllDiscounts(params)
        }
    }, [isSelectedSubItemService]);

    /**
     * Get price rules
     */
    const getPriceRules = async () => {
        let params = {
            metalCode: selectedMetalCode,
            currency: mainRule.currency,
            partnerId: mainRule.partnerId,
            status:'APPROVED',
            effectDate:moment(new Date().toISOString()).format("MM/DD/YYYY")
        }
        let data: any = await TradeExecutionService.getPriceRules(params);
        if (data.status === 200 && data.data.data) {
            let responseData = data.data.data;
            setPriceRules(responseData);
        }
    };

    /**
     * Get all discounts
     * @param params
     */
    const getAllDiscounts = async (params: any = null) => {
        let data: any = await TradeExecutionService.getAllDiscounts(params);
        if (data.status === 200 && data.data.data) {
            let responseData = data.data.data;
            setDiscounts(responseData);
        }
    };

    return (
        <>
            {/* Sub Rule List */}
            <div className="border rounded border-gray-200">
                <table
                    className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                    <thead>
                    <tr className="text-left border-b border-gray-300">
                        <th className="sticky top-0 px-3 py-2 bg-gray-800">Required</th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800">Item Code</th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800">Apply Type</th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800">Value</th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800">UOM</th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800">Apply Rate On</th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800">Discount</th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800">Order</th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800">Actions</th>
                    </tr>
                    </thead>
                    <tbody className="text-gray-800 text-sm font-light">
                    {createNewRow()}
                    {mainRule.details.map((item: any, index: number) => renderTableRow(item, index))}
                    </tbody>
                </table>
            </div>
        </>
    )

}

export default ItemList;
