import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import UOM from "./Components/UOM";
import AddUpdateModal from "../../Components-ui/Modal/Modal";

const UOMRoutes:React.FC = () => {
    return (
        <Fragment>
            <Route path="/uom" exact component={UOM}/>
        </Fragment>
    )
};

export default UOMRoutes;