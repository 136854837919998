import React from "react";
import Layout from "../../Layout/Layout";
import {ToastContainer} from "react-toastify";
import DiscountForm from "./DiscountForm";

interface PropsType {
}

const AddNewDiscount: React.FC<PropsType> = () => {

    return (
        <>
            <Layout type={"TradeExecution"}>
                <ToastContainer />
                <div className="flex justify-between items-center mb-5">
                    <DiscountForm
                        isEdit={false}
                        id={null}
                    />
                </div>
            </Layout>
        </>
    );
};

export default AddNewDiscount;
