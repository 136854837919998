import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import {
  CreateCalendar,
  GetAllReferences,
  CalenderDetailsByDetails,
} from "./Metal.Service";

import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EditTime } from "./Metal.Service";
import { useAuthStore } from "../../Store/AuthStore";
const dayjs = require('dayjs');

const ReceiptMaster: React.FC = () => {
  const [selectedVault, setSelectedVault] = useState<any>("");
  const [vaults, setVaults] = useState<any>([]);
  const [sdate, setSDate] = useState<any>(new Date().toISOString());
  const [edate, setEDate] = useState<any>(new Date().toISOString());
  const [dateError, setdateError] = useState<boolean>(false);
  const [detail, setDetail] = useState<any>([]);
  const { tokens, warehouses, permissions } = useAuthStore();

  const [hrs, setHrs] = useState<any>([]);
  const [mins, setMins] = useState<any>([]);

  const [editMode, setEditMode] = useState<any>({
    state: false,
    index: -1,
    row: {},
  });
  const [editMins, setEditMins] = useState<number>(-1);
  const [editHrs, setEditHrs] = useState<number>(-1);

  useEffect(() => {
    // fetchVaultReference();
    loadHours();
    loadMins();
  }, []);

  useEffect(() => {
    if (moment(sdate).valueOf() <= moment(edate).valueOf()) {
      setdateError(false);
    } else {
      setdateError(true);
    }
  }, [sdate, edate]);

  const onVaultChange = (e: any) => {
    setSelectedVault(e.target.value);
  };

  const createMetalCalendar = async (data: any) => {
    try {
      console.log("Create calendar data: ", data);
      let responseData: any = await CreateCalendar(data);
      console.log("Created details: ", responseData);

      if (responseData && responseData?.data?.data) {
        setDetail(responseData.data.data);
      }
      else {
        setDetail([]);
      }
    } catch (error: any) {
      console.log(error);
      throw new error(error);
    }
  };

  const fetchVaultReference = async () => {
    try {
      let allRef: any = await GetAllReferences(tokens.idToken.jwtToken);
      setVaults(allRef?.data);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong!");
    }
  };

  const fetchMasterCalendarDetails = async (
    sdate: any,
    edate: any,
    vaults: any
  ) => {
    try {
      let details: any = await CalenderDetailsByDetails(sdate, edate, vaults);

      if (details && details?.data?.data?.length == 0) {
        setDetail([]);
        toast.error("No Results Found");
      } else {
        setDetail(details.data.data);
        toast.success("Results Found");
      }
    } catch (err) {
      console.error(err);
      setDetail([]);
      toast.error("No records found!");
    }
  };

  const fetchMasterCalendarDetailsRefresh = async (
    sdate: any,
    edate: any,
    vaults: any
  ) => {
    try {
      let details: any = await CalenderDetailsByDetails(sdate, edate, vaults);

      if (details && details?.data?.data?.length == 0) {
        setDetail([]);
        // toast.error("No Results Found");
      } else {
        setDetail(details.data.data);
        // toast.success("Results Found");
      }
    } catch (err) {
      console.error(err);
      setDetail([]);
      toast.error("No records found!");
    }
  };

  console.log(detail);

  const onSubmitClick = async () => {
    try {
      if (!selectedVault) {
        toast.error("Please select a Vault ID");
        return;
      }
      if (!sdate) {
        toast.error("Please select Start Date");
        return;
      }
      if (!edate) {
        toast.error("Please select End Date");
        return;
      }
      if (dateError) {
        toast.error("Please Select Valid Date Range");
        return;
      }
      const data = {
        startDate: moment(sdate).format("YYYY-MM-DD"),
        endDate: moment(edate).format("YYYY-MM-DD"),
        vaultId: selectedVault,
      };

      try {
        await createMetalCalendar(data);
        toast.success("Added Sucessfully.");
      } catch (err) {
        if (sdate === edate) {
          toast.error("You have selected same date!");
          return;
        }
        toast.error("You have selected existing range!");
      }
    } catch (error) {
      toast.error("Invaid input");
      console.error(error);
    }
  };

  const onQueryClick = async (e: any) => {
    try {
      e.preventDefault();

      if (selectedVault == "") {
        toast.error("Please select a vault first");
        return;
      }

      const data = {
        startDate: moment(sdate).format("YYYY-MM-DD"),
        endDate: moment(edate).format("YYYY-MM-DD"),
        vaultId: selectedVault,
      };
      fetchMasterCalendarDetails(data.startDate, data.endDate, data.vaultId);
    } catch (error) {
      toast.error("Invaid input");
      console.error(error);
    }
  };

  const onSaveClicked = (e: any, row: any, index: number) => {
    e.preventDefault();

    if (editHrs == -1 || editMins == -1) {
      toast.error("Please Enter Valid Dates");
      return;
    }

    const updateData = {
      date: dayjs(row.date).format("YYYY-MM-DD"),
      time: editHrs + ":" + editMins,
      vaultId: selectedVault
    };

    console.log("SEND DATA: ", updateData);

    EditTime(1, updateData).then(data => {
      // console.log(data);
      toast.success("Time successfully updated");

      const sendData = {
        startDate: dayjs(sdate).format("YYYY-MM-DD"),
        endDate: dayjs(edate).format("YYYY-MM-DD"),
        vaultId: selectedVault,
      };

      fetchMasterCalendarDetailsRefresh(
        sendData.startDate,
        sendData.endDate,
        sendData.vaultId
      ).then(d => {}).catch(err => {})
        
      setEditMode({
        state: false,
        index: -1,
        row: {},
      });

      setEditMins(-1);
      setEditHrs(-1);

    }).catch(err => {
      toast.error("Update Failed");
      console.log(err);
    })
    
    // EditTime(row.id, updateData)
    //   .then((data) => {
    //     toast.success("Time successfully updated");
    //     const sendData = {
    //       startDate: dayjs(sdate).format("YYYY-MM-DD"),
    //       endDate: dayjs(edate).format("YYYY-MM-DD"),
    //       vaultId: selectedVault,
    //     };
    //     fetchMasterCalendarDetailsRefresh(
    //       sendData.startDate,
    //       sendData.endDate,
    //       sendData.vaultId
    //     );
    //     // createMasterCalendar(sendData.startDate, sendData.endDate, sendData.vaultId);
    //     // let altered = detail;
    //     // altered[index].time = moment(altered[index].date).format("YYYY-MM-DD") + " " + editHrs + ":" + editMins + ":00";
    //     // setDetail(altered);

    //     setEditMode({
    //       state: false,
    //       index: -1,
    //       row: {},
    //     });

    //     setEditMins(-1);
    //     setEditHrs(-1);
    //   })
    //   .catch((err) => {
    //     toast.error("Update Failed");
    //   });
  };

  const onEditClicked = (e: any, index: number, val: any) => {
    e.preventDefault();

    let fullDate = new Date(val.time);

    setEditMode({
      state: true,
      index: index,
      row: val,
    });

    setEditHrs(fullDate.getHours());
    setEditMins(fullDate.getMinutes());
  };

  const loadHours = () => {
    let hrs = [];
    for (let i = 0; i < 24; i++) {
      hrs.push(i);
    }

    let hrsSorted = hrs.map((i) => {
      return <option value={i}>i</option>;
    });

    setHrs(hrs);
  };

  const loadMins = () => {
    let min = [];
    for (let i = 0; i < 60; i++) {
      min.push(i);
    }

    let minSorted = min.map((i) => {
      return <option value={i}>i</option>;
    });

    setMins(min);
  };

  const getUtcHours = (time: any) => {
    let d = new Date(time);
    return d.getUTCHours();
  };

  const getUtcMins = (time: any) => {
    let d = new Date(time);
    return d.getUTCMinutes();
  };

  const getFullTime = (time: any) => {
    let date = new Date(time);
    let hours = date.getHours() <= 9 ? "0" + date.getHours() : date.getHours();
    let mins =
      date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes();
    return `${hours}:${mins}`;
  };

  const onCancelClicked = (e: any) => {
    e.preventDefault();

    setEditMode({
      state: false,
      index: -1,
      row: {},
    });

    setEditMins(-1);
    setEditHrs(-1);
  };

  console.log(sdate, "START");
  console.log(edate, "END");

  return (
    <Layout type={"VaultingModule"}>
      <h1 className="text-3xl font-bold mb-4">VSO Calendar</h1>
      <ToastContainer />
      <div className="flex flex-row">
        {/* <h3>{sdate}</h3>
        <div>] [</div>
        <h3>{edate}</h3> */}
        <div className="flex-1 border bg-white shadow-sm sm:rounded-md ">
          <div className="flex bg-gray-200 p-4 py-6">
            <div className="flex-1">
              <div className="md:w-full px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  for="grid-state"
                >
                  Vault
                </label>
                <div className="relative">
                  <select
                    onChange={onVaultChange}
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    value={selectedVault}
                  >
                    <option value="">Select one</option>
                    <option value="V001">V001</option>
                    <option value="V002">V002</option>
                    {/* {vaults?.data?.length &&
                      [...vaults.data]
                        .filter((data: any) => {
                          return data.Code_Name === "Warehouse";
                        })
                        .map((bench: any) => {
                          return (
                            <option value={bench.Code_Value}>
                              {bench.Code_Value}
                            </option>
                          );
                        })} */}
                  </select>
                </div>
              </div>
            </div>

            <div className="flex-initial">
              <div className="px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  for="grid-state"
                >
                  Effected From
                </label>
                <div className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border shadow-sm">
                  <Datetime
                    className="effect-from"
                    inputProps={{ className: "focus:outline-none" }}
                    value={moment(sdate)}
                    timeFormat={false}
                    dateFormat={"DD/MM/YYYY"}
                    onChange={(e: any) => {
                      // setdateError(false);
                      // if (
                      //   moment(edate).valueOf() >= moment(new Date(e)).valueOf()
                      // ) {
                      setSDate(new Date(e).toISOString());
                      // } else {
                      //   setdateError(true);
                      // }
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="flex-initial">
              <div className="px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  for="grid-state"
                >
                  Effected To
                </label>
                <div
                  id="to-picker"
                  className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border shadow-sm"
                >
                  <Datetime
                    className="efect-to"
                    inputProps={{ className: "focus:outline-none" }}
                    value={moment(edate)}
                    timeFormat={false}
                    dateFormat={"DD/MM/YYYY"}
                    onChange={(e: any) => {
                      // setdateError(false);
                      // if (

                      //   moment(sdate).valueOf() <= moment(new Date(e)).valueOf()
                      // ) {
                      setEDate(new Date(e).toISOString());
                      // } else {
                      //   setdateError(true);
                      // }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {dateError && (
            <div className="mb-4">
              <span className="text-xs font-semibold bg-red-50 border border-red-500 text-red-500 mx-7 px-4 py-2 rounded">
                From date must be less than To Date
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="flex py-5 justify-end">
        <button
          className="bg-red-400 text-white px-4 py-2 rounded font-bold mr-3"
          onClick={() => onSubmitClick()}
        >
          Create Calendar
        </button>
        <button
          className="bg-indigo-400  text-white px-4 py-2 rounded font-bold"
          onClick={(e) => onQueryClick(e)}
        >
          Query
        </button>
      </div>
      {/* <VsoCalendar details={detail} /> */}
      <div className="flex flex-col">
        <div className="border bg-white rounded border-gray-200 shadow">
          {/* <h3>{JSON.stringify(editHrs)}</h3>
        <h3>{JSON.stringify(editMins)}</h3> */}
          <table className="min-w-max w-full table-fixed rounded-lg">
            <thead>
              <tr className="sticky top-0 px-6 py-3 text-green-900 bg-purple-300">
                <th className="py-3 px-6 text-center">Date</th>
                <th className="py-3 px-6 text-center">Time</th>
                <th className="py-3 px-6 text-center">Receipting Status</th>
                <th className="py-3 px-6 text-center">VSO</th>
                <th className="py-3 px-6 text-center">Receipt Time</th>
                <th className="py-3 px-6 text-center">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-800 text-m font-dark">
              {detail.map((val: any, index: any) => {
                return (
                  <tr
                    key={index}
                    className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
                  >
                    <td className="py-3 px-6 text-center">
                      {dayjs(val.date).format("YYYY-MM-DD")}
                    </td>
                    <td className="py-3 px-6 text-center">
                      {/* {moment(val.time).format("YYYY-MM-DD")} */}
                      {/* <br /> */}
                      {/* {getUtcHours(val.time)} : {getUtcMins(val.time)} */}

                      {editMode.state && editMode.index == index ? (
                        <>
                          <select
                            value={editHrs}
                            onChange={(e) =>
                              setEditHrs(parseInt(e.target.value))
                            }
                            className="text-black border border-gray-300 rounded-md px-1 py-1 mx-1"
                          >
                            {hrs.map((i: any) => {
                              return <option value={i}>{i}</option>;
                            })}
                          </select>
                          <select
                            value={editMins}
                            onChange={(e) =>
                              setEditMins(parseInt(e.target.value))
                            }
                            className="text-black border border-gray-300 rounded-md px-1 py-1 mx-1"
                          >
                            {mins.map((i: any) => {
                              return <option value={i}>{i}</option>;
                            })}
                          </select>
                        </>
                      ) : (
                        getFullTime(val.time)
                      )}

                      {/* <button onClick={(e) => handleSumit(e, index)}>Update</button> */}
                      {/* <button onClick={editAccess} disabled={!!!editRow}>
                      Edit
                    </button> */}
                    </td>
                    <td className="py-3 px-6 text-center">
                      {/* {" "} */}
                      {val.receiptingStatus ? (
                        <input type="checkbox" checked={val.receiptingStatus} />
                      ) : (
                        <input type="checkbox" checked={false} />
                      )}
                      {/* {
                        !val.receiptingStatus && val.pending == 0 ? 
                          <span className="mx-3 bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-full text-s">
                            Not Receipted
                          </span>
                          : 
                          "" 
                      } */}
                    </td>
                    <td className="py-3 px-6 text-center"> {val.vso}</td>
                    <td className="py-3 px-6 text-center">
                      {" "}
                      {val.receiptTime ? getFullTime(val.receiptTime) : ""}
                    </td>
                    <td className="py-3 px-6 text-center">
                      {/* <span className="mx-3 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full text-xs cursor-pointer"
                      onClick={(e) => {onEditClicked(e, index, val)}}
                    >
                      Edit
                    </span> */}
                      {editMode.state && editMode.index == index ? (
                        <div>
                          <span
                            className="mx-3 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full text-xs cursor-pointer"
                            onClick={(e) => onSaveClicked(e, val, index)}
                          >
                            Save
                          </span>
                          <span
                            className="mx-3 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full text-xs cursor-pointer"
                            onClick={(e) => {
                              onCancelClicked(e);
                            }}
                          >
                            Cancel
                          </span>
                        </div>
                      ) : !val.receiptingStatus ? (
                        <span
                          className="mx-3 bg-blue-500 hover:bg-purple-950 text-white font-bold py-2 px-4 rounded-full text-xs cursor-pointer"
                          onClick={(e) => {
                            onEditClicked(e, index, val);
                          }}
                        >
                          Edit
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default ReceiptMaster;
