import React from "react";
import { Route } from "react-router-dom";

import TaxCategoryTable from "../Components/TaxCategoryTable";


export const TaxCategoryRoutes: React.FC = () => {
    return (
        <>
            <Route path="/master/tax-category" exact component={TaxCategoryTable} />
        </>
    );
};
