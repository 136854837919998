import React, { useEffect, useState } from "react";
import { CURRENCY, DATA_TYPE, MAIN_RULE_TYPES } from "../Utils/enum";
import Datetime from "react-datetime";
import moment from "moment";
import ItemListTable from "./ItemListTable";
import SimpleReactValidator from "simple-react-validator";
import { useHistory } from "react-router-dom";
import TradeExecutionService from "../Services/trade-execution.service";
import { toast } from "react-toastify";
import { useAuthStore } from "../../../Store/AuthStore";
import Loader from "../../../Components-ui/Loader";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";

interface PropsType {
    isEdit: boolean,
    data: any
}

const TradeExecutionForm: React.FC<PropsType> = ({
    isEdit,
    data
}) => {

    const history = useHistory();

    const { tokens, permissions } = useAuthStore();

    const [Items, setItems] = useState<any>([]);
    const [Partners, setPartners] = useState<any>([]);
    const [UOM, setUOM] = useState<any>([]);
    const [SelectedMetalCode, setSelectedMetalCode] = useState<any>("");
    const [IsSelectedSubItemService, setIsSelectedSubItemService] = useState(false);
    const [Validator, setValidator] = useState(false);
    const [Loading, setLoading] = useState<any>(false);
    const [SubRuleErrors, setSubRuleErrors] = useState<any>({
        itemCode: "",
        applyTypeValue: "",
        order: "",
        applyRateOn: "",
        applyType: "",
        uom: ""
    });

    const [SubRuleErrorsEdit, setSubRuleErrorsEdit] = useState<any>({
        itemCode: "",
        applyTypeValue: "",
        order: "",
        applyRateOn: "",
        applyType: "",
        uom: ""
    });

    const [Item, setItem] = useState<any>({
        itemCode: "",
        applyTypeValue: "",
        order: "",
        applyRateOn: "",
        applyType: "",
        discountId: "",
        required: true,
        uom: ""
    });

    const [MainRule, setMainRule] = useState<any>({
        description: "",
        type: "",
        itemCode: "",
        effectFromDate: new Date().toISOString(),
        effectToDate: new Date().toISOString(),
        partnerId: "",
        // customerId: 0,
        customerGroup: 0,
        priority: "",
        currency: "",
        status: "LOGGED",
        active: "ENABLED",
        lowerLimitQuantity: "",
        upperLimitQuantity: "",
        lowerLimitAmount: "",
        upperLimitAmount: "",
        details: []
    });

    const [CustomerGroups, setCustomerGroups] = useState<any>([]);
    const [ValidatorStatus, setValidatorStatus] = useState<boolean>(false);

    useEffect((): any => {
        getItems();
        getPartners();
        getUOMs();
        getCustomerGroups();
    }, []);

    useEffect((): any => {
        if (isEdit) {
            setMainRule(data)
            setValidator(true)
            setValidatorStatus(true)
        }
        // eslint-disable-next-line
    }, [isEdit]);

    const simpleValidator = new SimpleReactValidator({
        locale: "en",
        validators: {},
    });
    const validationRef = React.useRef(simpleValidator);
    const validator = validationRef.current;

    /**
     * Get validation error messages
     * @param nameOfStateProp
     */
    const getValidatorError = (nameOfStateProp: any) => {
        const allErrorMessages = validator.getErrorMessages();
        return allErrorMessages[nameOfStateProp];
    };


    /**
     * Get all items
     */
    const getItems = async () => {
        let response: any = await TradeExecutionService.getItems(tokens);
        if (response.status === 200 && response.data.data) {
            console.log(response.data.data)
            let responseData = response.data.data;
            setItems(responseData);
            if (isEdit && data.details.length > 0) {
                setSelectedMetalCode(responseData.filter((x: any) => x.itemCode === data.details[0].itemCode).length > 0 ?
                    responseData.filter((x: any) => x.itemCode === data.details[0].itemCode)[0].metalCode : '')
            }
        }
    };

    /**
     * Get all partners
     */
    const getPartners = async () => {
        let data: any = await TradeExecutionService.getPartners(tokens);
        if (data.status === 200 && data.data.data) {
            let responseData = data.data.data;
            setPartners(responseData);
        }
    };

    /**
     * Get all uoms
     */
    const getUOMs = async () => {
        let data: any = await TradeExecutionService.getUOMs(tokens);
        if (data.status === 200 && data.data.data) {
            let responseData = data.data.data;
            setUOM(responseData);
        }
    };

    /**
     * Get all customer groups
     */
    const getCustomerGroups = async () => {
        let data: any = await TradeExecutionService.getCustomerGroups(tokens);
        if (data.status === 200 && data.data.data) {
            let responseData = data.data.data;
            setCustomerGroups(responseData);
        }
    };

    /**
     * Validate main rule fields according to their data types
     * @param e
     * @param type
     */
    const onChangeMainRule = (e: any, type: any) => {
        switch (type) {
            case DATA_TYPE.START_DATE:
                if (
                    moment(MainRule?.effectToDate).valueOf() >=
                    moment(new Date(e)).valueOf() && moment(e).valueOf() >= moment().subtract(1, "days").valueOf()
                ) {
                    setValidator(true);
                    return setMainRule({
                        ...MainRule,
                        effectFromDate: new Date(e).toISOString(),
                    });
                }

                if (
                    moment(MainRule?.effectToDate).valueOf() <
                    moment(new Date(e)).valueOf()
                ) {
                    setValidator(true);
                    return setMainRule({
                        ...MainRule,
                        effectToDate: new Date(e).toISOString(),
                        effectFromDate: new Date(e).toISOString(),
                    });
                }
                return setValidator(false);
            case DATA_TYPE.END_DATE:
                if (
                    moment(MainRule?.effectFromDate).valueOf() <=
                    moment(new Date(e)).valueOf()
                ) {
                    setValidator(true);
                    return setMainRule({
                        ...MainRule,
                        effectToDate: new Date(e).toISOString(),
                    });
                }

                return setValidator(false);
            case DATA_TYPE.NORMAL:
                if (e.target.name === 'itemCode') {
                    setItem({
                        ...Item,
                        itemCode: e.target.value,
                        applyRateOn: "ACTUAL",
                        order: 1
                    });

                    setMainRule({
                        ...MainRule,
                        [e.target.name]: e.target.value,
                        details: [],
                    });
                    const selectedItem = Items.filter((x: any) => x.itemCode === e.target.value);
                    if (selectedItem.length > 0) {
                        setSelectedMetalCode(selectedItem[0].metalCode)
                    }

                    return setValidator(true);

                }
                if (e.target.name === 'partnerId') {
                    setMainRule({
                        ...MainRule,
                        [e.target.name]: parseInt(e.target.value),
                    });
                    return setValidator(true);
                } else {
                    setMainRule({
                        ...MainRule,
                        [e.target.name]: e.target.value,
                    });
                    return setValidator(true);
                }

            default:
                return "";
        }
    };


    /**
     * Handle sub item fields
     * @param e
     * @param data
     * @param index
     */
    const handleSubItemOnChange = (e: any, data: any = null, index: any = null) => {
        let { name, value, checked } = e.target;

        if (data && data.isEdit) {

            name = name.replace('Edit', '');
            let cloneData = { ...data }
            cloneData[name] = name === 'required' ? checked : value;

            let subItemClone = [...MainRule.details]
            subItemClone[index] = cloneData;

            setMainRule({ ...MainRule, details: subItemClone });
        } else {

            setItem({
                ...Item,
                [name]: name === 'required' ? checked : value,
            });
        }


        let object = {
            [name]: value
        }

        if (name === 'itemCode') {
            const selectedItem = Items.filter((x: any) => x.itemCode === value);
            if (selectedItem.length > 0) {
                setIsSelectedSubItemService(selectedItem[0].metalCode === "" || selectedItem[0].metalCode === null)
                setSelectedMetalCode(selectedItem[0].metalCode)
            }
        }

        return data && data.isEdit ? validateSubItemEditField(name, index, object) : validateSubItemField(name, object);
    }

    /**
     * Validate sub item fields
     * @param type
     * @param data
     */
    const validateSubItemField = (type: string, data: any) => {

        let result = true;

        let updatedState = {
            ...Item,
            ...data
        }

        const text = type.replace(/([A-Z])/g, " $1");
        const word = text.charAt(0).toUpperCase() + text.slice(1);

        if (updatedState[type].length === 0) {
            SubRuleErrors[type] = `${word} can't be empty!`
            result = false;
        } else {
            SubRuleErrors[type] = ""
        }

        if (type === 'applyTypeValue' && updatedState.applyType === 'RATE' && updatedState.applyTypeValue > 100) {
            SubRuleErrors[type] = `${word} can't be above 100%!`
            result = false;
        }

        setSubRuleErrors(SubRuleErrors)
        return result;
    }

    /**
     * Validate sub item fields when editing
     * @param type
     * @param index
     * @param data
     */
    const validateSubItemEditField = (type: string, index: any, data: any) => {

        let result = true;

        const text = type.replace(/([A-Z])/g, " $1");
        const word = text.charAt(0).toUpperCase() + text.slice(1);

        let item = MainRule.details[index]

        let updatedItem = { ...item, ...data }

        if (updatedItem[type].length === 0) {
            SubRuleErrorsEdit[type] = `${word} can't be empty!`
            result = false;
        } else {
            SubRuleErrorsEdit[type] = ""
        }

        if (type === 'applyTypeValue' && updatedItem.applyType === 'RATE' && updatedItem.applyTypeValue > 100) {
            SubRuleErrorsEdit[type] = `${word} can't be above 100%!`
            result = false;
        }

        setSubRuleErrorsEdit(SubRuleErrorsEdit)
        return result;
    }

    /**
     * Add sub items to the list
     */
    const handleItems = () => {
        let results: any = []
        Object.keys(Item).filter(x => x !== 'required' && x !== 'discountId').forEach((item) => {
            results.push(validateSubItemField(item, {}))
        })

        const isChecked = results.every((x: any) => x === true);

        if (isChecked) {
            let subItems = [...MainRule.details, ...[Item]]
            setMainRule({ ...MainRule, details: subItems });
            setInitialStateItem();
        }
    }

    /**
     * Remove sub item
     * @param index
     */
    const handleRemoveItem = (index: number) => {
        const cloneList = [...MainRule.details]
        if (index > -1) {
            cloneList.splice(index, 1);
            setMainRule({ ...MainRule, details: cloneList });
        }
    }

    /**
     * Add or update trade execution rule details
     * @param e
     * @param approve
     */
    const handleOnSubmit = async (e: any, approve?: boolean) => {
        e.preventDefault()
        if (!Validator || !validator.allValid()) {
            setValidatorStatus(true)
            return toast.error("Please make sure all the inputs are valid.");
        }

        if (MainRule.details.length === 0) {
            return toast.error("You have to add atleast one sub rule.");
        }

        try {
            setLoading(true)
            let cloneMainRule = { ...MainRule }
            cloneMainRule.details = cloneMainRule.details.map((x: any) => {
                x.discountId = x.discountId === "" ? null : x.discountId;
                if (x.isEdit) {
                    delete x.isEdit;
                }
                return x
            });

            if (approve) {
                cloneMainRule['status'] = 'APPROVED';
            }
            if (isEdit) {
                const res =  await TradeExecutionService.updateTradeExecutionRule(cloneMainRule, tokens);
                setLoading(false);
                setMainRule(res.data.data)
                // window.location.reload();
                return toast.success("Trade execution was successfully updated");
            } else {
                const res = await TradeExecutionService.addNewTradeExecutionRule(cloneMainRule, tokens);
                setInitialStateItem()
                setInitialStateMainRule();
                history.push(`/trade-execution/update/${res.data.data.id}`)
                setLoading(false);
                return toast.success("Trade execution was successfully added");
            }
        } catch (error: any) {
            setLoading(false);
            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                console.log(error.response.data.errors[0]);
                toast.error(error.response.data.errors[0].title);
            }
        }
    }

    /**
     * Set item to initial state
     */
    const setInitialStateItem = () => {
        setItem({
            itemCode: "",
            applyTypeValue: "",
            order: "",
            applyRateOn: "",
            applyType: "",
            required: false,
            discountId: "",
            uom: ""
        })
    }

    /**
     * Set main rule to initial state
     */
    const setInitialStateMainRule = () => {
        setMainRule({
            description: "",
            type: "",
            itemCode: "",
            effectFromDate: new Date().toISOString(),
            effectToDate: new Date().toISOString(),
            partnerId: "",
            // customerId: 0,
            customerGroup: 0,
            priority: "",
            currency: "",
            active: "ENABLED",
            status: "LOGGED",
            lowerLimitQuantity: "",
            upperLimitQuantity: "",
            lowerLimitAmount: "",
            upperLimitAmount: "",
            details: []
        })
    }

    /**
     * Handle edit item functionality
     * @param index
     * @param item
     */
    const handleEditItem = (index: number, item: any) => {
        let selectedItem = { ...MainRule.details[index] };
        selectedItem.isEdit = true;

        let subItemClone = [...MainRule.details]
        subItemClone[index] = selectedItem;

        setMainRule({ ...MainRule, details: subItemClone });
    }

    /**
     * Handle edit item submit functionality
     * @param index
     * @param item
     */
    const handleEditSubmit = (index: number, item: any) => {
        let results: any = [];

        Object.keys(Item).filter(x => x !== 'required' && x !== 'discountId').forEach((item) => {
            results.push(validateSubItemEditField(item, index, {}))
        })

        const isValidate = results.every((x: any) => x === true);

        console.log(isValidate, "isValidate")

        if (isValidate) {
            let selectedItem = MainRule.details[index];
            delete selectedItem.isEdit

            let subItemClone = [...MainRule.details]
            subItemClone[index] = selectedItem;

            setMainRule({ ...MainRule, details: subItemClone });
        }

    }

    return (
        <>
            {Loading && (<Loader />)}
            <div
                className="w-full rounded border-gray-200 overflow-hidden shadow-lg dark:border-gray-700 bg-white">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Trading Rule Details</h3>
                </div>
                <hr />
                <div className="px-4 py-2 sm:px-6">
                    <h4 className="text-md leading-6 font-medium text-gray-500">MASTER RULE DETAILS</h4>
                </div>
                {/* Trade Execution Main Form */}
                <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
                    <div className="col-span-12 sm:col-span-12">
                        <label htmlFor="description"
                            className="block text-sm font-medium text-gray-700">
                            Description
                        </label>
                        <div className="mt-1">
                            <textarea
                                id="description"
                                name="description"
                                rows={3}
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                value={MainRule?.description ?? ""}
                                onChange={(e: any) => {
                                    onChangeMainRule(e, DATA_TYPE.NORMAL)
                                }}
                                autoComplete="off"
                            />
                            {validator.message(
                                "description",
                                MainRule?.description,
                                "required"
                            )}
                            {
                                ValidatorStatus && (
                                    <span className="text-xs text-red-700"
                                          id="description">{getValidatorError("description")}</span>
                                )
                            }

                        </div>
                    </div>

                    <div className="col-span-12 sm:col-span-3">
                        <label htmlFor="partnerId" className="block text-sm font-medium text-gray-700">
                            Partner
                        </label>
                        <select
                            id="partnerId"
                            name="partnerId"
                            value={MainRule?.partnerId}
                            className={`mt-1 block w-full py-2 px-3 border border-gray-300 ${isEdit ? 'bg-gray-200' : 'bg-white'} rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                            onChange={(e: any) => {
                                onChangeMainRule(e, DATA_TYPE.NORMAL)
                            }}
                            disabled={isEdit}
                        >
                            <option value="" disabled>Select a partner</option>
                            {
                                Partners.map((partner: any, index: number) => {
                                    return (
                                        <option key={index} value={parseInt(partner.partnerId)}>{partner.name}</option>
                                    )

                                })
                            }
                        </select>
                        {validator.message(
                            "partnerId",
                            MainRule?.partnerId,
                            "required"
                        )}
                        {
                            ValidatorStatus && (
                                <span className="text-xs text-red-700"
                                      id="partnerId">{getValidatorError("partnerId")}</span>
                            )
                        }

                    </div>

                    <div className="col-span-12 sm:col-span-3">
                        <label htmlFor="itemCode" className="block text-sm font-medium text-gray-700">
                            Item Code
                        </label>
                        <select
                            id="itemCode"
                            name="itemCode"
                            value={MainRule?.itemCode}
                            className={`mt-1 block w-full py-2 px-3 border border-gray-300 ${isEdit ? 'bg-gray-200' : 'bg-white'} rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                            onChange={(e: any) => {
                                onChangeMainRule(e, DATA_TYPE.NORMAL)
                            }}
                            disabled={isEdit}
                        >
                            <option value="" disabled>Select an Item Code</option>
                            {
                                Items.map((item: any, index: number) => {
                                    return (
                                        <option key={index} value={item.itemCode}>{item.itemCode}</option>
                                    )
                                })
                            }
                        </select>
                        {validator.message(
                            "itemCode",
                            MainRule?.itemCode,
                            "required"
                        )}
                        {
                            ValidatorStatus && (
                                <span className="text-xs text-red-700"
                                      id="itemCode">{getValidatorError("itemCode")}</span>
                            )
                        }

                    </div>

                    <div className="col-span-12 sm:col-span-3">
                        <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                            Type
                        </label>
                        <select
                            id="type"
                            name="type"
                            value={MainRule?.type}
                            className={`mt-1 block w-full py-2 px-3 border border-gray-300 ${isEdit ? 'bg-gray-200' : 'bg-white'} rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                            onChange={(e: any) => {
                                onChangeMainRule(e, DATA_TYPE.NORMAL)
                            }}
                            disabled={isEdit}
                        >
                            <option value="" disabled>Select a type</option>
                            {
                                MAIN_RULE_TYPES.map((type: string, index: number) => {
                                    return (
                                        <option key={index} value={type}>{type}</option>
                                    )

                                })
                            }
                        </select>
                        {validator.message(
                            "type",
                            MainRule?.type,
                            "required"
                        )}
                        {
                            ValidatorStatus && (
                                <span className="text-xs text-red-700" id="type">{getValidatorError("type")}</span>
                            )
                        }
                    </div>

                    <div className="col-span-12 sm:col-span-3">
                        <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
                            Currency
                        </label>
                        <select
                            id="currency"
                            name="currency"
                            autoComplete="currency"
                            value={MainRule?.currency}
                            className={`mt-1 block w-full py-2 px-3 border border-gray-300 ${isEdit ? 'bg-gray-200' : 'bg-white'} rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                            onChange={(e: any) => {
                                onChangeMainRule(e, DATA_TYPE.NORMAL)
                            }}
                            disabled={isEdit}
                        >
                            <option value="" disabled>Select a currency</option>
                            {
                                CURRENCY.map((currency: string, index: number) => {
                                    return (
                                        <option key={index} value={currency}>{currency}</option>
                                    )

                                })
                            }
                        </select>
                        {validator.message(
                            "currency",
                            MainRule?.currency,
                            "required"
                        )}
                        {
                            ValidatorStatus && (
                                <span className="text-xs text-red-700"
                                      id="currency">{getValidatorError("currency")}</span>
                            )
                        }

                    </div>

                    <div className="col-span-12 sm:col-span-3">
                        <label htmlFor="effectFromDate" className="block text-sm font-medium text-gray-700">
                            Start Date
                        </label>
                        <Datetime
                            value={moment(MainRule?.effectFromDate)}
                            timeFormat={"HH:mm"}
                            dateFormat={"DD/MM/YYYY"}
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e: any) => {
                                onChangeMainRule(e, DATA_TYPE.START_DATE)
                            }}
                            inputProps={{className: "focus:outline-none w-full"}}
                        />
                    </div>

                    <div className="col-span-12 sm:col-span-3">
                        <label htmlFor="effectToDate" className="block text-sm font-medium text-gray-700">
                            End Date
                        </label>
                        <Datetime
                            value={moment(MainRule?.effectToDate)}
                            timeFormat={"HH:mm"}
                            dateFormat={"DD/MM/YYYY"}
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e: any) => {
                                onChangeMainRule(e, DATA_TYPE.END_DATE)
                            }}
                            inputProps={{className: "focus:outline-none w-full"}} 
                        />
                    </div>



                    {/*<div className="col-span-12 sm:col-span-3">
                        <label htmlFor="uom" className="block text-sm font-medium text-gray-700">
                            UOM
                        </label>
                        <select
                            id="uom"
                            name="uom"
                            autoComplete="uom"
                            value={MainRule?.uom}
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e: any) => {
                                onChangeMainRule(e, DATA_TYPE.NORMAL)
                            }}
                        >
                            <option value="" disabled>Select an uom</option>
                            {
                                UOM.map((uom: any, index: number) => {
                                    return (
                                        <option key={index} value={uom.uomCode}>{uom.uomName}</option>
                                    )

                                })
                            }
                        </select>
                        {validator.message(
                            "uom",
                            MainRule?.uom,
                            "required"
                        )}
                        <span className="text-xs text-red-700" id="uom">{getValidatorError("uom")}</span>
                    </div>*/}

                    <div className="col-span-12 sm:col-span-3">
                        <label htmlFor="priority" className="block text-sm font-medium text-gray-700">
                            Priority
                        </label>
                        <input
                            type="number"
                            name="priority"
                            id="priority"
                            min="0"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={MainRule?.priority}
                            onChange={(e: any) => {
                                onChangeMainRule(e, DATA_TYPE.NORMAL)
                            }}
                        />
                        {validator.message(
                            "priority",
                            MainRule?.priority,
                            "required"
                        )}
                        {
                            ValidatorStatus && (
                                <span className="text-xs text-red-700"
                                      id="description">{getValidatorError("priority")}</span>
                            )
                        }

                    </div>

                    <div className="col-span-12 sm:col-span-3">
                        <label htmlFor="customerGroup" className="block text-sm font-medium text-gray-700">
                            Customer Group
                        </label>
                        <select
                            id="customerGroup"
                            name="customerGroup"
                            value={MainRule?.customerGroup}
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e: any) => {
                                onChangeMainRule(e, DATA_TYPE.NORMAL)
                            }}
                        >
                            <option value={0} disabled>Select a customer group</option>
                            {
                                CustomerGroups.map((customerGroup: any, index: number) => {
                                    return (
                                        <option key={index} value={customerGroup.id}>{customerGroup.name}</option>
                                    )

                                })
                            }
                        </select>
                    </div>

                    <div className="col-span-12 sm:col-span-3">
                        <label htmlFor="lowerLimitAmount" className="block text-sm font-medium text-gray-700">
                            Lower Limit Amount
                        </label>
                        <input
                            type="number"
                            name="lowerLimitAmount"
                            id="lowerLimitAmount"
                            min="0"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={MainRule?.lowerLimitAmount}
                            onChange={(e: any) => {
                                onChangeMainRule(e, DATA_TYPE.NORMAL)
                            }}
                        />
                        {validator.message(
                            "lowerLimitAmount",
                            MainRule?.lowerLimitAmount,
                            `required|max:${MainRule?.upperLimitAmount},num`
                        )}
                        {
                            ValidatorStatus && (
                                <span className="text-xs text-red-700"
                                      id="description">{getValidatorError("lowerLimitAmount")}</span>
                            )
                        }

                    </div>

                    <div className="col-span-12 sm:col-span-3">
                        <label htmlFor="upperLimitAmount" className="block text-sm font-medium text-gray-700">
                            Upper Limit Amount
                        </label>
                        <input
                            type="number"
                            name="upperLimitAmount"
                            id="upperLimitAmount"
                            min="0"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={MainRule?.upperLimitAmount}
                            onChange={(e: any) => {
                                onChangeMainRule(e, DATA_TYPE.NORMAL)
                            }}
                        />
                        {validator.message(
                            "upperLimitAmount",
                            MainRule?.upperLimitAmount,
                            `required|min:${MainRule?.lowerLimitAmount},num`
                        )}
                        {
                            ValidatorStatus && (
                                <span className="text-xs text-red-700"
                                      id="description">{getValidatorError("upperLimitAmount")}</span>
                            )
                        }

                    </div>

                    <div className="col-span-12 sm:col-span-3">
                        <label htmlFor="lowerLimitQuantity" className="block text-sm font-medium text-gray-700">
                            Lower Limit Quantity
                        </label>
                        <input
                            type="number"
                            name="lowerLimitQuantity"
                            id="lowerLimitQuantity"
                            min="0"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={MainRule?.lowerLimitQuantity}
                            onChange={(e: any) => {
                                onChangeMainRule(e, DATA_TYPE.NORMAL)
                            }}
                        />
                        {validator.message(
                            "lowerLimitQuantity",
                            MainRule?.lowerLimitQuantity,
                            `required|max:${MainRule?.upperLimitQuantity},num`
                        )}
                        {
                            ValidatorStatus && (
                                <span className="text-xs text-red-700"
                                      id="description">{getValidatorError("lowerLimitQuantity")}</span>
                            )
                        }

                    </div>

                    <div className="col-span-12 sm:col-span-3">
                        <label htmlFor="upperLimitQuantity" className="block text-sm font-medium text-gray-700">
                            Upper Limit Quantity
                        </label>
                        <input
                            type="number"
                            name="upperLimitQuantity"
                            id="upperLimitQuantity"
                            min="0"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={MainRule?.upperLimitQuantity}
                            onChange={(e: any) => {
                                onChangeMainRule(e, DATA_TYPE.NORMAL)
                            }}
                        />
                        {validator.message(
                            "upperLimitQuantity",
                            MainRule?.upperLimitQuantity,
                            `required|min:${MainRule?.lowerLimitQuantity},num`
                        )}
                        {
                            ValidatorStatus && (
                                <span className="text-xs text-red-700"
                                      id="description">{getValidatorError("upperLimitQuantity")}</span>
                            )
                        }

                    </div>

                    <div className="col-span-12 sm:col-span-3">
                        <label htmlFor="priority" className="block text-sm font-medium text-gray-700">
                            Status
                        </label>
                        <div className="mt-2 whitespace-nowrap bg-yellow-100 p-2 px-3 text-xs uppercase font-bold rounded w-24">
                            {MainRule?.status}
                        </div>
                    </div>



                    {/*

                            <div className="col-span-12 sm:col-span-4">
                                <label htmlFor="customerId" className="block text-sm font-medium text-gray-700">
                                    Customer
                                </label>
                                <select
                                    id="customerId"
                                    name="customerId"
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                >
                                </select>
                            </div>*/}

                    <div className="col-span-12 sm:col-span-12">
                        <div className="py-2">
                            <h4 className="text-md leading-6 font-medium text-gray-500">SUB RULES</h4>
                        </div>
                        {/* Trade Execution Sub Rules */}
                        <ItemListTable
                            mainRule={MainRule}
                            item={Item}
                            onSubItemChange={handleSubItemOnChange}
                            onAddItems={handleItems}
                            onRemoveItem={handleRemoveItem}
                            items={MainRule.details.length > 0 ? Items.filter((item: any) => item.metalCode === "" || item.metalCode === null) : Items}
                            subRuleErrors={SubRuleErrors}
                            isSelectedSubItemService={IsSelectedSubItemService}
                            uom={UOM}
                            onEditItem={handleEditItem}
                            onEditSubmitItem={handleEditSubmit}
                            subRuleEditErrors={SubRuleErrorsEdit}
                            selectedMetalCode={SelectedMetalCode}
                        />
                    </div>
                </div>
                <div
                    className="flex items-center justify-end sm:py-3  sm:px-6 border-t border-solid border-blueGray-200 rounded-b">
                    <div>
                        <button
                            id="modal"
                            className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => history.push(`/trade-execution`)}
                        >
                            Close
                        </button>
                    </div>
                    <div>
                        {
                            HasAnyAccess(['trading.rule.approve']) && MainRule?.status == 'LOGGED' && isEdit && (
                                <button
                                    id="modal"
                                    className="text-white rounded-md bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={(e) => handleOnSubmit(e, true)}
                                >
                                    Approve
                                </button>
                            )
                        }
                    </div>
                    <div>
                        <button
                            className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                            type="button"
                            onClick={(e) => handleOnSubmit(e)}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </>
    )

}

export default TradeExecutionForm;
