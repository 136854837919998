export const reducer = (state : any, action : any) => {
    let data = action.data;
    switch (action.type) {
        case "ADD_METAL":
            return {
                ...state,
                metals: [...state.metals, {
                    id: Math.random(), // not really unique but it's just an example
                    metalName: "Bronze",
                    qty: "1 gm",
                    price: "$7.45"
                }]
            };
        case "UPDATE_METAL":
            return {
                ...state,
                metals: data
            };
        default:
            return state;
    }
};
