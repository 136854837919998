import React from "react";
import { Route } from "react-router-dom";
import SipTable from "../Components/SipTable";
import AddNewSipPromoCode from "../Components/AddNewSipPromoCode";
import EditSipPromoCode from "../Components/EditSipPromoCode";

export const SipRoutes: React.FC = () => {
  return (
    <>
      <Route path="/sip" exact component={SipTable} />
      <Route path="/sip/add" exact component={AddNewSipPromoCode} />
      <Route path="/sip/update/:id" exact component={EditSipPromoCode} />
      <Route path="/sip/update/:id/:view" exact component={EditSipPromoCode} />
    </>
  );
};
