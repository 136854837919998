import axios from "axios";
import { makeQuery } from "../../Common/util.helper";
import Http from "../../library/http.library";
import { httpMethods } from "../../library/enum";

export const getItemList = async (tokens: any, data: any) => {
  var query = {
    ...(data.vaultId ? { vaultId: data.vaultId } : {}),
    ...(data.metalCode ? { metalCode: data.metalCode } : {}),
    ...(data.fromPool ? { pool: data.fromPool } : {}),
  };
  const params = makeQuery(query);
  console.log(params);
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/bar-exchange/b2b/bar-list?isPaginated=true${params}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getQuantityToBarList = async (tokens: any, data: any) => {
  var query = {
    ...(data.vaultId ? { vaultId: data.vaultId } : {}),
    ...(data.metalCode ? { metalCode: data.metalCode } : {}),
    ...(data.fromPool ? { pool: data.fromPool } : {}),
    ...(data.customerId ? { customerId: data.customerId } : {}),
  };
  const params = makeQuery(query);
  console.log(params);
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/bar-exchange/q2b/bar-list?isPaginated=true${params}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const filterData = async (tokens: any, data: any) => {
  return await Http.setMethod(httpMethods.post)
    .setUrl(`/bar-exchange`)
    .setData(data)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const addBarList = async (tokens: any, data: any) => {
    return await Http.setMethod(httpMethods.post).setUrl(`/bar-exchange/add-bar`).setData(data).setToken(tokens.idToken.jwtToken).request();

};

export const getAllMetals = async (tokens: any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/metal-master?isPaginated=false`).setToken(tokens.jwtToken).request();

}

export const getVaults = async (tokens: any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/warehouse-master?isPaginated=false`).setToken(tokens.idToken.jwtToken).request();

};

export const exchangeBars = async (
  tokens: any,
  id: any,
  fromBarsTotal?: any,
  targetBarsTotal?: any,
  targetBarsTotalAWeight?: any
) => {
  return Http.setMethod(httpMethods.patch)
    .setUrl(
      `/bar-exchange?exchangeId=${id}&fromBarsTotalEqWeight=${fromBarsTotal}&targetBarsTotalEqWeight=${targetBarsTotal}&targetBarsTotalAWeight=${targetBarsTotalAWeight}
      `
    )
    .setData({})
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const approveBars = async (tokens: any, data: any, id: any) => {
  return Http.setMethod(httpMethods.patch)
    .setUrl(`/bar-exchange/vso/approve/${id}`)
    .setData(data)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const approveMultipleBars = async (tokens: any, data: any) => {
  return Http.setMethod(httpMethods.patch)
    .setUrl(`/bar-exchange/vso/approve/list`)
    .setData(data)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getVsoBarList = async (tokens: any, data: any) => {
  var query = {
    ...(data.id ? { id: data.id } : {}),
    ...(data.vaultId ? { vaultId: data.vaultId } : {}),
    ...(data.metalCode ? { metalCode: data.metalCode } : {}),
    ...(data.pool ? { pool: data.pool } : {}),
    ...(data.status ? { status: data.status } : {}),
    ...(data.type ? { type: data.type } : {}),
    ...(data.page ? { page: data.page } : {}),
  };
  const params = makeQuery(query);

  return await Http.setMethod(httpMethods.get)
    .setUrl(`/bar-exchange/vso/bar-list?${params}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getBarList = async (
  tokens: any,
  id: any,
  data: any,
  bars?: any
) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(
      `/bar-exchange/bar-list/${id}?isPaginated=false&bars=${bars}&type=${
        data.type ? data.type : ""
      }`
    )
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getCustomerList = async (tokens: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/customers?isPaginated=false`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};
//get bar list for q to b
export const getBarListForQuantity = async (tokens: any, data: any) => {
  var query = {
    ...(data.vaultId ? { vaultId: data.vaultId } : {}),
    ...(data.metalCode ? { metalCode: data.metalCode } : {}),
    ...(data.fromPool ? { pool: data.fromPool } : {}),
    ...(data.customerId ? { customerId: data.customerId } : {}),
  };
  const params = makeQuery(query);
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/bar-exchange/q2b/bar-list?isPaginated=false${params}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

const MetalInterchangeService = {
  getItemList,
  getBarList,
  getAllMetals,
  getVaults,
  getVsoBarList,
  filterData,
  addBarList,
  approveBars,
  getQuantityToBarList,
  getCustomerList,
  exchangeBars,
  getBarListForQuantity,
  approveMultipleBars,
};

export default MetalInterchangeService;