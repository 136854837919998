import React from "react";
import { Route } from "react-router-dom";

import TaxTable from "../Components/TaxTable";


export const TaxRoutes: React.FC = () => {
    return (
        <>
            <Route path="/master/tax" exact component={TaxTable} />
        </>
    );
};
