export class HandleData {
    removeNsvData = () => {
        localStorage.removeItem('nsvDefaultAsk');
        localStorage.removeItem('nsvDefaultBid');
    }

    removeWeightData = () => {
        localStorage.removeItem('weightDataBid');
        localStorage.removeItem('weightDataAsk');
    }
}