import axios from "axios";
import Http from "../../library/http.library";
import {httpMethods} from "../../library/enum";


export const getTaxCategoryLookUpData = async (tokens: any,currentPage:any) => {
    return new Promise(async (resolve, reject) => {
        let apiUrl = process.env.REACT_APP_URL + `/common/tax-category-lookup`;
        try {
            let result = await axios.get(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                    Authorization: `Bearer ${tokens.idToken.jwtToken}`,
                },
                params: {
                    isPaginated: true,
                    pageSize:10,
                    page: currentPage,
                }
            });
            resolve(result);
        } catch (error) {
            reject(error);
        }
    });
};

export const updateTaxCategoryLookUpData = async (data: any,tokens:any) => {
    return new Promise(async (resolve, reject) => {
        let apiUrl = process.env.REACT_APP_URL + `/common/tax-category-lookup/${data.id}`;
        try {
            let result = await axios.patch(apiUrl,data, {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                        "Authorization": `Bearer ${tokens.idToken.jwtToken}`,
                    }
                },
            );
            resolve(result);
        } catch (error) {
            reject(error);
        }
    });
};

export const createTaxCategoryLookUpData = async (data: any,tokens:any) => {
    return new Promise(async (resolve, reject) => {
        let apiUrl = process.env.REACT_APP_URL + `/common/tax-category-lookup`;
        try {
            let result = await axios.post(apiUrl,data,{
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                    "Authorization": `Bearer ${tokens.idToken.jwtToken}`,
                }
            });
            resolve(result);
        } catch (error) {
            reject(error);
        }
    });
};

export const filterTaxCategoryLookUpData = async (tokens: any,data:any,currentPage:any) => {
    return new Promise(async (resolve, reject) => {
        let apiUrl = process.env.REACT_APP_URL + `/common/tax-category-lookup`;
        try {
            let result = await axios.get(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                    "Authorization": `Bearer ${tokens.idToken.jwtToken}`,
                },
                params: {
                    isPaginated: true,
                    ...(data.quoteType ? {quoteType: data.quoteType} : {}),
                    ...(data.itemType ? {itemType: data.itemType} : {}),
                    ...(data.pos ? {pos: data.pos} : {}),
                    ...(data.state ? {state: data.state} : {}),
                    ...(data.countryCode ? {countryCode: data.countryCode} : {}),
                    ...(data.taxType ? {taxType: data.taxType} : {}),
                    ...(data.isGstReg ? {isGstReg: data.isGstReg} : {}),
                    ...(data.ignoreState ? {ignoreState: data.ignoreState} : {}),
                    ...(data.taxCategory ? {taxCategory: data.taxCategory} : {}),
                    page: currentPage,
                    pageSize: 10,
                }
            });
            resolve(result);
        } catch (error) {
            reject(error);
        }
    });
};

export const getAllTaxCategories =async (tokens: any, isPaginated: boolean = false) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/tax-cat-master?isPaginated=${!isPaginated}`).setToken(tokens.idToken.jwtToken).request();
}

const TaxCategoryLookUpService = {
    getTaxCategoryLookUpData,
    updateTaxCategoryLookUpData,
    createTaxCategoryLookUpData,
    filterTaxCategoryLookUpData,
    getAllTaxCategories
};

export default TaxCategoryLookUpService;
