import React, { useEffect, useState, Fragment } from "react";
import { useAuthStore } from "../../../Store/AuthStore";
import moment from "moment";
import Layout from "../../Layout/Layout";
import SummaryReceipt from "../ReceiptQty";
import VsoReceipt from "../VsoReceipt";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datetime/css/react-datetime.css";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import {
  getMetals,
  getVSOReceiptDetails,
  initiateVSOReceiptingProcess,
  getCalender,
  getPendingCalenders,
} from "./VSOReceipting.Service";
import { getValuts } from "../vsoCalendar/VSO.Service";
import { Dialog, Transition } from "@headlessui/react";
import Loader from "../../../Components-ui/Loader";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";
import { Pagination } from "../../../Components-ui/Pagination";

const VsoReceipting: React.FC = ({}) => {
  const [selectedVault, setSelectedVault] = useState<any>("");
  const [selectedMetal, setSelectedMetal] = useState<any>("");
  const [selectedDate, setSelectedDate] = useState<any>("");
  const [receiptDate, setReceiptDate] = useState<any>([]);
  const [date, setDate] = useState<any>(new Date().toISOString());
  const [detail, setDetail] = useState<any>([]);
  const [meta, setMetaData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<any>(0);
  const [summery, setSummery] = useState<any>([]);
  const [Loading, setLoading] = useState<any>(true);
  // @ts-ignore
  const { tokens, warehouses, permissions } = useAuthStore();
  const [vaults, setVaults] = useState<any>([]);
  const [metals, setMetals] = useState<any>([]);
  let [isOpen, setIsOpen] = useState(false);

  const [disable, setDisable] = useState<boolean>(true);
  const [msg, setMsg] = useState<string>("");
  const positive = !selectedMetal
    ? detail?.data
    : !!detail?.data?.length &&
      detail.data.filter(
        (fil: any) => fil.metal === selectedMetal && fil.quantity >= 0
      );
  const negative = !selectedMetal
    ? detail?.data
    : !!detail?.data?.length &&
      detail.data.filter(
        (fil: any) => fil.metal === selectedMetal && fil.quantity < 0
      );

  const [reciptsIds, setReciptsIds] = useState<any>([]);

  useEffect(() => {
    // fetchVaultReference();
    fetchMetals();
    fetchVaults();
  }, []);

  const fetchVaults = async () => {
    try {
      let allRef: any = await getValuts(tokens, warehouses, permissions);
      setVaults(allRef?.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Something Went Wrong! (getting vaults)");
    }
  };

  const fetchMetals = async () => {
    try {
      let allRef: any = await getMetals(tokens);
      setMetals(allRef?.data.data);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong! (getting metals)");
    }
  };

  const fetchReceiptDates = async (vaultId: any) => {
    setLoading(true);
    try {
      let allRef: any = await getPendingCalenders("", vaultId, tokens);
      setReceiptDate(
        allRef?.data?.data.filter((v: any) => v.receiptingStatus === false)
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Something Went Wrong! (getting receipt dates)");
    }
  };

  const onQueryClick = async (e: any) => {
    try {
      e.preventDefault();
      setLoading(true);
      setDisable(false);
      setMsg("");
      fetchVSOReceipts(currentPage);
      await fetchCalendar();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // toast.error("Invaid input");
      console.error(error);
    }
  };

  const fetchCalendar = async () => {
    const selected_d = receiptDate.filter(
      (v: any) => v.id === parseInt(selectedDate)
    )[0];
    const nowStr = moment().format("YYYY-MM-DD 00:00:00");

    const now = new Date(nowStr);
    const given = new Date(
      moment(selected_d.date).format("YYYY-MM-DD 00:00:00")
    );

    try {
      //   const selected = vaults.filter((v:any) => v.id === parseInt(selectedVault))[0];
      let d = given ? given : now;

      let allRef: any = await getCalender(
        moment(d).format("YYYY-MM-DD"),
        selectedVault,
        tokens
      );

      if (allRef?.data?.data?.result.length === 0) {
        setDisable(true);
        setMsg("Cannot find the VSO calendar for today.");
        toast.warning("Cannot find the VSO calendar for today.");
      }
      //   else if(allRef?.data?.data?.result[0].receiptingStatus === true){
      //     setDisable(true);
      //     setMsg('Already receipted!');
      //     toast.warning('Already receipted!');
      //   }
      else if (now.getTime() < given.getTime()) {
        setDisable(true);
        setMsg(`Cannot process for upcoming date`);
        toast.warning(`Cannot process for upcoming date`);
      } else if (now.getTime() > given.getTime()) {
        setDisable(false);
      } else if (
        !canEdit(
          moment(allRef?.data?.data?.result[0].date).format("YYYY-MM-DD") +
            " " +
            getFullTime(allRef?.data?.data?.result[0].time)
        )
      ) {
        setDisable(true);
        setMsg(
          `Receipting time is ${getFullTime(
            allRef?.data?.data?.result[0].time
          )}`
        );
        toast.warning(
          `Receipting time is ${getFullTime(
            allRef?.data?.data?.result[0].time
          )}`
        );
      }
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong! (geting calendar)");
    }
  };

  const canEdit = (date: any) => {
    const now = new Date();
    const given = new Date(date);
    return now.getTime() >= given.getTime();
  };

  const getFullTime = (time: any) => {
    let date = new Date(time);
    let hours = date.getHours() <= 9 ? "0" + date.getHours() : date.getHours();
    let mins =
      date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes();
    return `${hours}:${mins}`;
  };

  const fetchVSOReceipts = async (currentPage: any) => {
    try {
      if (selectedVault == "") {
        toast.error("Please enter a vault");
        return;
      }

      const sendData = {
        startDate: moment(date).format("YYYY-MM-DD"),
        vaultId: selectedVault,
        metalCode: selectedMetal,
      };

      if (!disable) {
        let data: any = await getVSOReceiptDetails(
          selectedDate,
          sendData.vaultId,
          sendData.metalCode,
          tokens,
          currentPage
        );
        console.log("DATA IN IF: ", data?.data?.data);
        setDetail(data?.data?.data?.result);
        setMetaData({
          current: data?.data?.meta.page,
          pages: data?.data?.meta.totalPages,
        });
        setSummery(data?.data?.data?.summary);
      } else {
        let data: any = await getVSOReceiptDetails(
          selectedDate,
          sendData.vaultId,
          sendData.metalCode,
          tokens,
          currentPage
        );
        console.log("DATA IN ELSE: ", data?.data?.data?.result);
        setDetail(data?.data?.data?.result);
        setMetaData({
          current: data?.data?.meta.page,
          pages: data?.data?.meta.totalPages,
        });
        setSummery(data?.data?.data?.summary);
      }
    } catch (error: any) {
      console.log("Error: ", error);
      if (error?.data?.errors) {
        toast.error(error.data.errors[0].title);
      }
    }
  };

  const sendForReceipt = async (e: any) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (selectedVault == "") {
        toast.error("Please enter a vault");
        return;
      }

      const sendData = {
        startDate: moment(date).format("YYYY-MM-DD hh:mm:ss"),
        vaultId: selectedVault,
        metal: selectedMetal,
        calendarId: selectedDate,
        reciptsIds: reciptsIds,
        vso: "SYSTEM", // send user details in vso
      };

      console.log("Send for receipting: ", sendData);

      let response = await initiateVSOReceiptingProcess(
        sendData.startDate,
        sendData.vaultId,
        sendData.vso,
        sendData.calendarId,
        sendData.reciptsIds,
        sendData.metal,
        tokens
      );

      setMsg("");
      fetchReceiptDates(selectedVault);
      fetchVSOReceipts(currentPage);
      await fetchCalendar();
      setLoading(false);
      toast.success("Sent for recepting");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Something Went Wrong!");
    }
  };

  const changeStatus = async (data: any) => {
    setReciptsIds(data);
    console.log("reciptsIds", reciptsIds);
  };

  const ifTheSameDate = (e: any) => {
    let day = moment(new Date(e)).format("YYYY-MM-DD");
    let today = moment().format("YYYY-MM-DD");

    if (day == today) {
      setDisable(true);
      return;
    } else {
      setDisable(false);
      return;
    }
  };

  const styles = {
    fontFamily: "sans-serif",
    textAlign: "center",
  };
  const colstyle = {
    width: "5%",
  };
  const tableStyle = {
    width: "100%",
  };

  const roundDecimals = (amount: string, num?: number) => {
    return Number(amount).toFixed(num ? num : 4);
  };

  const SaleAsPDF = () => {
    return;
    const printVal =
      !!positive?.length &&
      positive.map((s: any) => {
        return {
          customerId: s.customerId,
          gstTaxInvoiceNo: s.gstTaxInvoice,
          invoiceDate: moment(s.invoiceDate).format("YYYY-MM-DD hh:mm"),
          metal: getMetalName(s.metalCode),
          quantity: s.quantity,
          payOutRef: s.payoutReference,
        };
      });

    console.log("Details: ", detail);
    if (detail.length == 0) {
      toast.error("Select some data");
      return;
    }

    if (printVal == false) {
      if (!selectedMetal) {
        toast.error("There are no Sale records for All ");
      } else {
        toast.error(
          "There are no Sale records for " + getMetalName(selectedMetal)
        );
      }
      return;
    }

    let filteredPrintVal = printVal?.filter((i: any) => {
      return i.quantity >= 0;
    });

    if (!filteredPrintVal) {
      let errMsg =
        selectedMetal == ""
          ? "There are no Sales records"
          : `There are no Sales records for ${getMetalName(selectedMetal)}`;
      toast.warning(errMsg);
      return;
    }

    filteredPrintVal.forEach((element: any) => {
      element.quantity = roundDecimals(element.quantity);
    });

    // console.log("Filtered: ", filteredPrintVal)
    var finalVal = filteredPrintVal.map(function (obj: any) {
      return Object.keys(obj).map(function (key) {
        return obj[key];
      });
    });

    const doc = new jsPDF();
    const columns = [
      [
        "Customer ID",
        "GST Tax Invoice No.",
        "Invoice Date",
        "Metal",
        "Quantity",
        "Pay Out Ref",
      ],
    ];
    const data = finalVal;
    console.log("SALE PDF DATA: ", data);

    autoTable(doc, {
      head: columns,
      body: data,
      didDrawPage: (dataArg) => {
        doc.setFontSize(20);
        doc.setTextColor(40);

        doc.text(
          selectedMetal == ""
            ? `Sale - All Metals`
            : `Sale - ${getMetalName(selectedMetal)}`,
          dataArg.settings.margin.left,
          10
        );

        // doc.text("---------", dataArg.settings.margin.top, 500);
      },
    });

    let datePrint = moment(date).format("DD-MM-YYYY");

    doc.save(`Sale_${datePrint}_${selectedMetal}.pdf`);
  };

  const BuyAsPDF = () => {
    return;
    const negVal =
      !!negative?.length &&
      negative.map((s: any) => {
        return {
          customerId: s.customerId,
          gstTaxInvoiceNo: s.gstTaxInvoice,
          invoiceDate: moment(s.invoiceDate).format("YYYY-MM-DD hh:mm"),
          metal: getMetalName(s.metalCode),
          quantity: s.quantity,
          payOutRef: s.payoutReference,
        };
      });

    // console.log("Details: ", detail)
    if (detail.length == 0) {
      toast.error("Select some data");
      return;
    }

    console.log("Neg val: ", negVal);
    if (negVal == false) {
      if (!selectedMetal) {
        toast.error("There are no Sale records for All ");
      } else {
        toast.error(
          "There are no Sale records for " + getMetalName(selectedMetal)
        );
      }
      return;
    }

    // console.log(negVal)
    let filteredNegVal = negVal?.filter((i: any) => {
      return i.quantity < 0;
    });

    if (!filteredNegVal) {
      let errMsg =
        selectedMetal == ""
          ? "There are no Buyback records"
          : `There are no Buyback records for ${getMetalName(selectedMetal)}`;
      toast.warning(errMsg);
      return;
    }

    filteredNegVal.forEach((element: any) => {
      element.quantity = roundDecimals(element.quantity);
    });

    var finalVal1 = filteredNegVal.map(function (obj: any) {
      return Object.keys(obj).map(function (key) {
        return obj[key];
      });
    });

    const doc = new jsPDF();
    const columns = [
      [
        "Customer ID",
        "GST Tax Invoice No.",
        "Invoice Date",
        "Metal",
        "Quantity",
        "Pay Out Ref",
      ],
    ];
    const data = finalVal1;
    console.log(data);

    autoTable(doc, {
      head: columns,
      body: data,
      didDrawPage: (dataArg) => {
        doc.setFontSize(20);
        doc.setTextColor(40);

        doc.text(
          selectedMetal == ""
            ? `Buyback - All Metals`
            : `Buyback - ${getMetalName(selectedMetal)}`,
          dataArg.settings.margin.left,
          10
        );

        // doc.text("---------", dataArg.settings.margin.top, 500);
      },
    });
    let datePrint = moment(date).format("DD-MM-YYYY");

    doc.save(`Buyback_${datePrint}_${selectedMetal}.pdf`);
  };

  const getMetalName = (metalCode: string) => {
    switch (metalCode) {
      case "Ag":
        return "Silver";
      case "Au":
        return "Gold";
      case "Pt":
        return "Platinum";
      case "Pd":
        return "Palladium";
      default:
        return "";
    }
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const paginationFilter = async (page: number) => {
    setCurrentPage(page);
    await fetchVSOReceipts(page);
  };

  return (
    <Layout type={"VSOModule"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">{"Receipting"}</h1>
      </div>
      {Loading ? (
        <Loader />
      ) : (
        <>
          <div className="">
            <div className="border bg-white shadow sm:rounded-md  p-4 py-6">
              <form
                onSubmit={(e) => {
                  onQueryClick(e);
                  e.preventDefault();
                }}
              >
                <div className="flex bg-white">
                  <div className="flex-4">
                    <div className="md:w-full px-3">
                      <label
                        className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                        for="grid-state"
                      >
                        Vault
                      </label>
                      <div className="relative">
                        <select
                          onChange={(e) => {
                            setSelectedVault(e.target.value);
                            fetchReceiptDates(e.target.value);
                          }}
                          className="mt-1 block w-full py-3 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          id="grid-state"
                          value={selectedVault}
                          required={true}
                        >
                          <option value="">Select One</option>
                          {vaults.map((bench: any) => {
                            return (
                              <option value={bench.id}>
                                {bench.warehouseName} ({bench.warehouseId})
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="flex-initial">
                    <div className="px-3">
                      <label
                        className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                        for="grid-state"
                      >
                        Receipt Date
                      </label>
                      <div className="relative">
                        <select
                          onChange={(e) => {
                            setSelectedDate(e.target.value);
                          }}
                          className="mt-1 block w-full py-3 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          id="grid-state"
                          value={selectedDate}
                          required={true}
                        >
                          <option value="">Select One</option>
                          {receiptDate.map((val: any) => {
                            return (
                              <option value={val.id}>
                                {moment(val.date).format("YYYY-MM-DD")}{" "}
                                {val.time}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="flex-initial">
                    <div className="md:w-full px-3">
                      <label
                        className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                        for="grid-state"
                      >
                        Metal
                      </label>
                      <div className="relative">
                        <select
                          onChange={(e) => setSelectedMetal(e.target.value)}
                          className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                          id="grid-state"
                          value={selectedMetal}
                        >
                          <option value="">All</option>
                          {metals.map((metal: any) => {
                            return (
                              <option value={metal.metalCode}>
                                {metal.description} ({metal.metalCode})
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="flex py-5 mt-1 justify-end">
                    {/* <button
                                className="bg-purple-950 text-white px-4 py-2 rounded font-bold mr-3"
                                onClick={(e) => onQueryClick(e)}
                                >
                                Filter
                            </button> */}
                    <input
                      className="bg-purple-950 text-white px-4 py-2 rounded font-bold mr-3"
                      type="submit"
                      value="Filter"
                    />
                  </div>
                </div>
              </form>
              {/* Summary Table */}
              <SummaryReceipt details={summery} />
              {/* end Summary */}

              {/* Receipt Table */}
              <VsoReceipt
                details={detail}
                selectMetal={!!selectedMetal && selectedMetal}
                changeStatus={changeStatus}
              />
              {/* End Receipt  */}
              <Pagination
                meta={meta}
                handlePageChange={(number: number) => {
                  paginationFilter(number);
                }}
              />
              <div className="flex flex-row-reverse">
                <div className="flex-2 pt-5">
                  {/* {JSON.stringify(detail)} */}
                  {HasAnyAccess(["vso.calendar.receipting.process"]) && (
                    <>
                      {selectedVault !== "" && !disable && detail.length > 0 ? (
                        <button
                          id="vso-receipt-btn"
                          className="bg-purple-950 text-white px-4 py-2 rounded font-bold mr-3"
                          onClick={() => {
                            openModal();
                          }}
                        >
                          Make to process
                        </button>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  <span className="text-red-600 font-semibold">
                    {disable && msg !== "" ? "Note: " + msg : ""}
                  </span>
                </div>
                {/* <div className="flex-1 pt-5 items-end">
                            <button className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150" onClick={() => SaleAsPDF()}>Download - Vault Receipt (Sale)</button>
                            <button className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150" onClick={() => BuyAsPDF()}>Download - Vault Receipt (BuyBack)</button>
                        </div> */}
              </div>
            </div>
          </div>
        </>
      )}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => null}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 opacity-50  bg-black " />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Please Confirm!
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Are you sure you want to verify this document?
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={(e) => {
                      closeModal();
                      sendForReceipt(e);
                    }}
                  >
                    Yes, please!
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center ml-2 px-4 py-2 text-sm font-medium text-blue-900 bg-transparent border border-blue-100 rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={closeModal}
                  >
                    No
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </Layout>
  );
};

export default VsoReceipting;
