import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../Components-ui/Loader";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useAuthStore } from "../../Store/AuthStore";
import EbReferenceMasterService from "./EbReferenceAndDetails.Service";
import SimpleReactValidator from "simple-react-validator";

const SingleVIewEbMaster: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    let metal_id: any = useParams();
    const { tokens } = useAuthStore();
    const [loading, setLoading] = useState<boolean>(true);
    const [status, setStatus] = useState<any>(false);//true-Add...False..Edit
    const [mainToggleStatus, setMainToggleStatus] = useState<any>('ENABLED');
    const [dataRecord, setDataRecord] = useState<any>([]);
    const [newRecords, setNewRecords] = useState<any>([]);
    const [rows, setRow] = useState<any>([]);
    const [validatorStatus, setValidator] = useState<boolean>(false);

    const simpleValidator = new SimpleReactValidator({
        locale: "en",
        validators: {},
    });
    const validationRef = React.useRef(simpleValidator);
    const validator = validationRef.current;
    const getValidatorError = (nameOfStateProp: any) => {
        const allErrorMessages = validator.getErrorMessages();
        return allErrorMessages[nameOfStateProp];
    };

    let handleAddRow = async () => {
        const item = {
            // id: 0,
            codeValue: "",
            valueDescription: "",
            valueRemark: "",
            active: 'ENABLED',
            isNew: true
        };
        var newRow = rows.concat(item)
        setRow(newRow);
    };

    const handleRemoveSpecificRow = (idx: number) => () => {
        const row = [...rows]
        row.splice(idx, 1)
        setRow(row)
    }

    useEffect(() => {
        setStatus(0 !== parseInt(metal_id.id));
    }, [1]);

    useEffect(() => {
        getReferenceDetails();
    }, [status]);

    const getReferenceDetails = async () => {
        try {
            if (status) {
                await EbReferenceMasterService.GetSingleReferenceMasterRecord(metal_id.id, tokens).then((response: any) => {
                    setNewRecords(response.data.data.result[0].details);
                    setRow(response.data.data.result[0].details);
                    setMainToggleStatus(response.data.data.result[0].active)
                    setDataRecord(response.data.data.result[0])
                    setLoading(false);
                }).catch((err) => {
                    toast.error('Something went wrong');
                })
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            toast.error("Something Went Wrong!");
        }
    }

    const changeMainStatus = (active: String) => {
        setMainToggleStatus(("ENABLED" === active) ? 'DISABLED' : 'ENABLED');
    }

    const changeStatus = async (status: any, key: any, id: any) => {
        let newArr = [...rows];
        newArr[key].active = ('ENABLED' !== status) ? 'ENABLED' : 'DISABLED'
        setRow(newArr);
    }
    //main record update function
    const updateMasterDetails = async () => {
        setValidator(true);
        if (validator.allValid()) {

            rows.forEach((element: any) => {
                delete element['isNew'];
            });

            try {
                await EbReferenceMasterService.UpdateReferenceMasterDetails(dataRecord.id, {
                    codeName: dataRecord.codeName,
                    codeDescription: dataRecord.codeDescription,
                    active: mainToggleStatus,
                    details: rows,
                    updatedBy: dataRecord.updatedBy,
                    updatedAt: new Date().toISOString(),
                }, tokens);
                await updateReferenceDetails();
                setLoading(false);
                toast.success("Updated successfully");
                window.location.reload();
            } catch (error) {
                setLoading(false);
                toast.error("Something Went Wrong!");
            }
            setValidator(false);
        }
    }


    const updateReferenceDetails = async () => {
        for await (const record of rows) {
            if (record.id) {
                console.log("UPDATINGGG .... ");

                try {
                    await EbReferenceMasterService.UpdateReferenceDetailsRecord(record.id,
                        record,
                        tokens);
                } catch (error) {
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                }
            } else {
                console.log("adddinggggg .... ");
                if ('' !== record.codeValue || '' !== record.valueDescription || '' !== record.valueRemark) {
                    record.masterId = metal_id.id
                    record.createdBy = ''
                    delete record['isNew'];
                    try {
                        await EbReferenceMasterService.CreateNewReferenceDetails([record], tokens);
                    } catch (error) {
                        setLoading(false);
                        toast.error("Something Went Wrong!");
                    }
                }
            }

        }
    }

    const save = () => {
        setLoading(true);
        if (!status) {
            createReferenceMaster();
        } else {
            updateMasterDetails();
        }
    }

    const changeCodeValue = (e: any, idx: number) => {
        const { value } = e.target;
        let newArr = [...rows];
        newArr[idx].codeValue = value
        setRow(newArr);
    }

    const changeValueDescription = (e: any, idx: number) => {
        const { value } = e.target;
        let newArr = [...rows];
        newArr[idx].valueDescription = value
        setRow(newArr);
    }

    const changeValueRemark = (e: any, idx: number) => {
        const { value } = e.target;
        let newArr = [...rows];
        newArr[idx].valueRemark = value
        setRow(newArr);
    }

    const createReferenceMaster = async () => {
        setValidator(true);
        if (validator.allValid()) {
            rows.forEach((element: any) => {
                delete element['isNew'];
            });

            try {
                const dataRes: any = await EbReferenceMasterService.CreateNewReferenceMaster(
                    {
                        // id: 0,
                        codeName: dataRecord.codeName,
                        codeDescription: dataRecord.codeDescription,
                        active: mainToggleStatus,
                        createdBy: "",
                        updatedBy: "",
                        value: rows,
                    }, tokens
                );
                setRow([])
                setDataRecord({
                    codeName: '',
                    codeDescription: '',
                    active: 'ENABLED',
                });
                setLoading(false);
                toast.success("Record Added successfully");
                console.log("location", location, window.location.hostname);
                window.location.replace(`${dataRes.data.data.id}`)

            } catch (error: any) {
                let formatRows = rows.map((v: any) => ({ ...v, isNew: true }));
                setRow(formatRows);
                setLoading(false);
                toast.error(error.data.errors[0].title ?? "Something Went Wrong!");
            }
            setValidator(false);
        }
    }

    const checkIfRefDetailDuplicates = () => {

        const unique = new Set();

        const showError = rows.some((element: any) => unique.size === unique.add(element.codeValue).size);

        // console.log(showError);

        return showError;
    }

    return <Layout type={"MasterModule"}>
        <ToastContainer />
        <div>
            <button
                onClick={() => {
                    history.goBack();
                }}
                className="-mr-2 flex items-center rounded text-white font-bold text-xs uppercase"
            >
                <svg className="w-7" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd"
                    clip-rule="evenodd">
                    <path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" />
                </svg>
                {" "}

            </button>
        </div>
        {loading ? (<Loader />) : (
            <div className="flex-row">
                <div className="bg-white border-gray-200 rounded shadow">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">{status ? 'Edit Record' : 'Add New Record'}</h3>
                    </div>
                    <hr />
                    <div className="flex flex-row mt-4">
                        <div className="bg-white rounded border-gray-200 shadow border-purple-950 border-t-4">
                            <div className="flex-1 p-2">
                                <fieldset className="border rounded p-1 border-gray-300 pb-4 flex mt-3">
                                    <legend
                                        className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
                                        {"Master Reference Details"}
                                    </legend>
                                    <div className="w-full">
                                        <div className="flex flex-wrap -mx-3 mb-2">
                                            <div className="pb-3 w-full px-3 mb-6 md:mb-0">
                                                <label
                                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="user-id">
                                                    Code
                                                </label>
                                                <input disabled={status}
                                                    className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${(status) ? "bg-gray-200" : ""}`}
                                                    name="codeName" id="user-id" type="text" placeholder="Code"
                                                    autoComplete="none" onChange={(event) => setDataRecord({
                                                        ...dataRecord,
                                                        codeName: event.target.value
                                                    })} value={dataRecord.codeName} />
                                                {validator.message(
                                                    "codeName",
                                                    dataRecord.codeName,
                                                    "required"
                                                )}
                                                {
                                                    validatorStatus ? (<span className="text-xs text-red-700"
                                                        id="name">{getValidatorError("codeName")}</span>) : ''
                                                }
                                            </div>
                                            <div className="pb-3 w-full px-3 mb-6 md:mb-0">
                                                <label
                                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="email">
                                                    Description
                                                </label>
                                                <textarea
                                                    id="description"
                                                    name="description"
                                                    rows={3}
                                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    onChange={(event) => setDataRecord({
                                                        ...dataRecord,
                                                        codeDescription: event.target.value
                                                    })} value={dataRecord.codeDescription}
                                                />
                                                {validator.message(
                                                    "description",
                                                    dataRecord.codeDescription,
                                                    "required"
                                                )}
                                                {
                                                    validatorStatus ? (<span className="text-xs text-red-700"
                                                        id="name">{getValidatorError("description")}</span>) : ''
                                                }
                                            </div>

                                            <div className="pb-3 w-full px-3 mb-6 md:mb-0">
                                                <label
                                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="partner">
                                                    Active
                                                </label>
                                                <div className="relative">
                                                    <div className="flex justify-between items-center" onClick={() => {
                                                        changeMainStatus(mainToggleStatus)
                                                    }
                                                    }>
                                                        <div
                                                            className={`w-14 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out ${("ENABLED" === mainToggleStatus) ? "bg-green-400" : ""}`}>
                                                            <div
                                                                className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${("ENABLED" === mainToggleStatus) ? "translate-x-6" : ""}`}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="bg-white rounded border-gray-200 shadow border-purple-950 border-t-4 ml-3">
                            <div className="flex-1 p-2">
                                <fieldset className="border rounded p-1 border-gray-300 pb-4 flex mt-3">
                                    <legend
                                        className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
                                        {"Details"}
                                    </legend>
                                    <div className="w-full">
                                        {/*<button onClick={handleAddRow}*/}
                                        {/*    className="float-right mb-1.5 px-4 py-2.5 font-bold text-white text-sm uppercase bg-purple-950 rounded">*/}
                                        {/*    + Add Row*/}
                                        {/*</button>*/}
                                        <table
                                            className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                                            <thead>
                                                <tr className="text-left border-b border-gray-300">
                                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Code Value</th>
                                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Value
                                                        Description
                                                    </th>
                                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Value Remark
                                                    </th>
                                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Status</th>
                                                    <th className={`sticky top-0 px-3 py-2 bg-gray-800 z-30 ${!status ? "" : "hidden"}`}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(0 !== rows.length) ? rows.map((item: any, idx: number) => (
                                                    <tr className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                                                        id="addr0" key={idx}>
                                                        <td className="py-3 px-3 text-left relative">
                                                            <input
                                                                disabled={item.isNew ? false : true}
                                                                type="text"
                                                                name="codeValue"
                                                                value={item.codeValue}
                                                                onChange={(event) => {
                                                                    changeCodeValue(event, idx)
                                                                }}
                                                                className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${(item.isNew) ? "" : "bg-gray-200"}`}
                                                            />
                                                        </td>
                                                        <td className="py-3 px-3 text-left relative">
                                                            <input
                                                                type="text"
                                                                name="valueDescription"
                                                                value={item.valueDescription}
                                                                onChange={(event) => {
                                                                    changeValueDescription(event, idx)
                                                                }}
                                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            />
                                                        </td>
                                                        <td className="py-3 px-3 text-left relative">
                                                            <input
                                                                type="text"
                                                                name="valueRemark"
                                                                value={item.valueRemark}
                                                                onChange={(event) => {
                                                                    changeValueRemark(event, idx)
                                                                }}
                                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            />
                                                        </td>
                                                        <td className="py-3 px-3 text-left relative">
                                                            <div className="flex justify-between items-center"
                                                                onClick={() => {
                                                                    changeStatus(item.active, idx, item.id)
                                                                }}>
                                                                <div
                                                                    className={`w-14 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out ${("ENABLED" === item.active) ? "bg-green-400" : ""}`}>
                                                                    <div
                                                                        className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${("ENABLED" === item.active) ? "translate-x-6" : ""}`}>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className={`py-3 px-3 text-left relative ${!status ? "display-block" : "display-none"}`}>
                                                            <button
                                                                className="bg-red-800 font-bold px-4 py-2 rounded text-sm text-white uppercase"
                                                                onClick={handleRemoveSpecificRow(idx)}
                                                            >
                                                                Remove
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )) : (
                                                    <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                                        <td colSpan={9}
                                                            className="text-center py-3 px-3 text-left relative">No
                                                            data to
                                                            display
                                                        </td>
                                                    </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex items-center justify-end sm:py-3  sm:px-6 border-solid border-blueGray-200 rounded-b">
                        <div>
                            <button
                                id="modal"
                                className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => history.goBack()}
                            >
                                Close
                            </button>
                        </div>
                        <div>
                            <button
                                disabled={
                                    checkIfRefDetailDuplicates()
                                }
                                className={`text-white background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md ${(!checkIfRefDetailDuplicates()) ? "bg-purple-950" : "bg-gray-200"}`}
                                type="button"
                                onClick={() => {
                                    save()
                                }}>
                                Save
                            </button>
                        </div>
                    </div>


                </div>
            </div>
        )}
    </Layout>
}
export default SingleVIewEbMaster;
