import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import pricingService from "../Services/pricing.service";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useAuthStore } from "../../../Store/AuthStore";
import axios from "axios";
import { useStore } from "../../../Store/PricingStore";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";
import Modal from "../../../Components-ui/Modal/Modal";

interface PropsType {
  refreshPricingMaster: any;
  saveAllData(): void;
}

const PricingMasterStatus = forwardRef(
  ({ refreshPricingMaster, saveAllData }: PropsType, ref) => {
    const location = useLocation<any>();
    let { id } = useParams<any>();
    const { logout, permissions, roles, userData, tokens } = useAuthStore();
    const [
      isruleExpired,
      setIsRuleStatus,
      ruleStatus,
    ] = useStore((state: any) => [
      state.isruleExpired,
      state.setIsRuleStatus,
      state.ruleStatus,
    ]);
    const [initialState, setInitialState] = useState();
    const [createdBy, setCreatedBy] = useState();
    const [isErrorDisplayed, setIsErrorDisplayed] = useState(false);
    const [missingAskRules, setMissingAskRules] = useState([]);
    const [missingBidRules, setMissingBidRules] = useState([]);
    useEffect(() => {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${tokens.idToken.jwtToken}`;
      console.log("Effect called");
      fetchPricingRulesData();
    }, []);
    const fetchPricingRulesData = async () => {
      //console.log(permissions);

      let data: any = await pricingService.getPricingMasterById(id);

      if (!!data?.data?.data) {
        let responseData = data.data.data;
        let masterRule = responseData;
        setInitialState(masterRule.status);
        setCreatedBy(masterRule.createdBy);
        // setStatus(masterRule.statusMaster);
      }
    };

    useImperativeHandle(ref, () => ({
      fetchPricingRulesDataWhenSaved() {
        console.log("fetch Pricing Rules Data When Saved");
        fetchPricingRulesData();
      },
    }));

    // const createPricingRuleDuplicate = async () => {
    //   try {
    //     let priceMaster:any = await pricingService.getPricingMasterById(id);
    //     let priceSub:any = await pricingService.getPricingSubRulesByMasterId(id);
    //     console.log(priceSub,"priceSub");

    //     let cloneData = {
    //       ...priceMaster?.data?.data,
    //       rule:(priceMaster?.data?.data?.rule)+1,
    //       ruleName:"Duplicate of" + priceMaster?.data?.data?.id
    //     }

    //     if(!!priceMaster?.data?.data && !!priceSub?.data?.data){
    //       delete cloneData.id;

    //         window.open(`/pricing/${priceMaster?.data?.data?.id}`, "_blank");
    //         let copyMaster:any = await pricingService.createPricingMaster(cloneData);
    //         let copypriceSub:any = await pricingService.createPricingSubRules(priceSub?.data?.data);

    //       }
    //     // let copyMaster:any = await pricingService.createPricingMaster(priceMaster?.data?.data);
    //     // let copypriceSub:any = await pricingService.createPricingSubRules(priceSub?.data?.data);

    //     console.log(priceMaster?.data?.data,"priceMaster priceMaster priceMaster API");
    //     console.log(priceSub?.data?.data,"priceSub priceSub priceSub priceSub ++++++++++++++++++");
    //   }
    //   catch (error) {
    //     console.log(error);
    //   }
    // };

    const createPricingRuleDuplicate = async () => {
      try {
        let data: any = await pricingService.duplicatePricingMaster(id);

        if (!!data?.data) {
          window.open(`/pricing/${data.data.data.id}?duplicate=true`, "_blank");
        }
      } catch (error) {
        console.log(error);
      }
    };

    const setLogStatusState = async (status: string) => {
      // let tempStatus = status;
      // if (status == "L") {
      //   tempStatus = "Logged";
      // }
      // if (status == "S") {
      //   tempStatus = "Send for approval";
      // }
      // if (status == "E") {
      //   tempStatus = "Expire";
      // }
      // if (status == "A") {
      //   tempStatus = "Approved";
      // }
      // console.log(status, "status status status");

      let temp = {
        status: status,
      };

      let response: any = await pricingService.updatePricingMaster(id, temp);
      if (response.data.data.code === 400) {
        if (response.data?.data?.data?.isAnySubRuleMissed) {
          if (response.data?.data?.data?.missedSubRuleAsk?.isMissed) {
            let missedSubRuleAsk = response.data.data.data.missedSubRuleAsk;
            if (missedSubRuleAsk.subRules?.length > 0) {
              let missedSubRulesAsk = missedSubRuleAsk.subRules.map(
                (item: { ruleName: string }) => item.ruleName
              );
              setMissingAskRules(missedSubRulesAsk);
            }
          }
          if (response.data?.data?.data?.missedSubRuleBid?.isMissed) {
            let missedSubRuleBid = response.data.data.data.missedSubRuleBid;
            if (missedSubRuleBid.subRules?.length > 0) {
              let missedSubRulesBid = missedSubRuleBid.subRules.map(
                (item: { ruleName: any }) => item.ruleName
              );
              setMissingBidRules(missedSubRulesBid);
            }
          }
          setIsErrorDisplayed(true);
        } else {
          setIsErrorDisplayed(false);
          setMissingAskRules([]);
          setMissingBidRules([]);
        }
      } else {
        if (!!response?.data?.data) {
          refreshPricingMaster(status);
          fetchPricingRulesData();
          toast.success("Successfully changed pricing status");
          setIsRuleStatus(response?.data?.data.status);
          // window.location.reload();
        }
        setIsErrorDisplayed(false);
        setMissingAskRules([]);
        setMissingBidRules([]);
      }
    };

    //   const Duplicate = async () => {
    //     let data: any = await pricingService.updatePriceMasterStatus(id, status);
    //   };

    return (
      <div>
        <Modal
          show={isErrorDisplayed}
          handleClose={() => setIsErrorDisplayed(false)}
          size="large"
        >
          {missingAskRules.length > 0 ? (
            <div
              className="flex p-4 mt-5 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
              role="alert"
            >
              <svg
                aria-hidden="true"
                className="flex-shrink-0 inline w-5 h-5 mr-3"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Danger</span>
              <div>
                <span className="font-medium">
                  Following sub-rules are missing <b>Ask Rules Section</b>:
                </span>
                <ul className="mt-1.5 ml-4 text-red-700 list-disc list-inside">
                  {missingAskRules.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
          {missingBidRules.length > 0 ? (
            <div
              className="flex p-4 mt-5 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
              role="alert"
            >
              <svg
                aria-hidden="true"
                className="flex-shrink-0 inline w-5 h-5 mr-3"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Danger</span>
              <div>
                <span className="font-medium">
                  Following sub-rules are missing in <b>Bid Rules Section</b>:
                </span>
                <ul className="mt-1.5 ml-4 text-red-700 list-disc list-inside">
                  {missingBidRules.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
        </Modal>
        {createdBy ? (
          <div className="border mt-5  bg-white p-4 flex justify-end statusDiv">
            {HasAnyAccess(["update.master.rule.data"]) && (
              <button
                className={`px-4 py-2 mr-3 text-sm font-bold text-white uppercase bg-purple-950 rounded cursor-pointer ${
                  isruleExpired && ruleStatus == "PERMANENTLY_DISABLED"
                    ? "opacity-50"
                    : ""
                }`}
                onClick={saveAllData}
                type="button"
                disabled={isruleExpired && ruleStatus == "PERMANENTLY_DISABLED"}
              >
                Save
              </button>
            )}
            {HasAnyAccess(["price.rule.duplicate"]) && (
              <button
                disabled={isruleExpired}
                className={`px-4 py-2 mr-3 text-sm font-bold text-white uppercase bg-purple-950 rounded cursor-pointer ${
                  isruleExpired ? "opacity-50" : ""
                }`}
                type="button"
                onClick={() => createPricingRuleDuplicate()}
              >
                Duplicate
              </button>
            )}
            {HasAnyAccess(["price.rule.approve"]) &&
              initialState == "LOGGED" &&
              (userData.sub != createdBy ||
                roles.map((role: any) => role.name).includes("Admin")) && (
                <button
                  disabled={isruleExpired}
                  className={`px-4 py-2 mr-3 text-sm font-bold text-white uppercase bg-purple-950 rounded cursor-pointer ${
                    isruleExpired ? "opacity-50" : ""
                  }`}
                  onClick={() => setLogStatusState("APPROVED")}
                  type="button"
                >
                  Approve
                </button>
              )}

            {location?.state?.operation !== "create" && (
              <>
                {HasAnyAccess(["price.rule.expire"]) && (
                  <>
                    {(initialState == "LOGGED" ||
                      initialState == "APPROVED" ||
                      initialState == "EXPIRED") && (
                      <button
                        className="px-4 py-2 text-sm font-bold text-white uppercase bg-gray-700 rounded"
                        onClick={() =>
                          setLogStatusState("PERMANENTLY_DISABLED")
                        }
                        type="button"
                      >
                        Permanently Disable
                      </button>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
);

// export default PricingMasterStatus;
export default memo(PricingMasterStatus);
