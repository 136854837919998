import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { Pagination } from "../../../Components-ui/Pagination";
import { useAuthStore } from "../../../Store/AuthStore";
import DashboardReportService from "../DashboardReportService";
import "./table.css";
import { formatAmount, formatMoney, formatQuantity } from "../utils";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";
import Loader from "../../../Components-ui/Loader";
type Props = {
  data?: any;
  flow?: any;
  selectedRecords?: any;
  setSelectedRecords?: any;
  alreadySelectedRecords?: any;
  metalCode?: any;
  metalName?: any;
  filters?: any;
};

const TradeReport = ({
  data,
  flow,
  selectedRecords,
  setSelectedRecords,
  alreadySelectedRecords,
  metalCode,
  metalName,
  filters,
}: Props) => {
  const { permissions } = useAuthStore();
  const isEditAllowed = HasAnyAccess([
    "trade.report.edit",
    "management.report.edit",
  ]);
  const results = data?.toggleableTable?.data?.result?.results;
  const [tableData, setTableData] = useState<any>(null);
  const { tokens } = useAuthStore();
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const [loading, setLoading] = useState(false);
  // const selectAll = () => {
  //   const newRecords = results;
  //   setSelectedRecords(newRecords);
  // };
  // console.log(permissions,"permissions")
  // console.log(data, "data");

  useEffect(() => {
    const queryParams = {
      fromDate: parseInt(moment(filters?.fromDate)?.format("x")),
      toDate: parseInt(moment(filters?.toDate)?.format("x")),
      metalCode: metalCode,
      status: filters?.transactionStatus || [],
      itemCode: filters?.itemCode || "",
      vault: filters?.vault?.id || "",
      // customerID: filters?.customerID || "",
      partnerId: filters?.CuGroup?.partnerId || "",
      partnerName: filters?.CuGroup?.name,
      isCovered: filters?.covered,
      orderBy: "created_at",
      order: "desc",
      page: page,
      pagesize: pageSize,
      isFxCovered: filters?.isFxCovered,
      isMetalCovered: filters?.isMetalCovered,
      isPaginated: true,
    };
    setLoading(true);
    DashboardReportService.getTradeRecords(tokens, queryParams)
      .then((response: any) => {
        setTableData(response?.data?.data);
        setLoading(false);
        // console.log(resp?.data?.data, "resp");
      })
      .catch((error) => {
        setLoading(false);
        setTableData({});
        toast.error("Something went wrong");
      });
  }, [filters, page]);

  // useEffect(() => {
  //   // if (tableData) console.log(tableData, "tableData");
  //   console.log(tableData?.toggleableTable?.data?.result, "tableData");
  // }, [tableData]);

  return (
    <div className="w-full">
      {loading && <Loader />}
      <div className="overflow-x-auto ">
        <table className="rounded min-w-full mx-auto  text-gray-200 text-xs table-stripped">
          <thead>
            <tr className="text-left border-b border-gray-300 bg-gray-800">
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Txn Type
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">Date</th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Order Number
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Order Status
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                US$/₹ Bid
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                US$/₹ Ask
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Metal Rate US$/Ozt Bid
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Metal Rate US$/Ozt Ask
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Loco Premium (Discount) US$/Ozt
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Order Metal Rate Ozt $
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                <a data-tip data-for="omr_tooltip" className="z-50">
                  Order Metal Rate g ₹
                </a>
                <div className="z-50">
                  <ReactTooltip
                    id="omr_tooltip"
                    place="top"
                    type="dark"
                    effect="solid"
                    backgroundColor="#000"
                    className="z-50"
                  >
                    <span>
                      Fx Rate x (Metal Rate Ozt + loco premium) in grams
                    </span>
                  </ReactTooltip>
                </div>
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Customs Duty ₹
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Landed Cost Base ₹
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Net Sales Value
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Customer Rate ₹ per base UOM
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Customer g Quantity
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Customer Amount in ₹
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Discount
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Customer Amount After Discount
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Customer Ozt Quantity
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Customer Amount in US$
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Price Offset ₹ per Base UOM
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Metal Covered
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Fx Covered
              </th>
              {/* <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Vault Name
              </th> */}
            </tr>
          </thead>
          <tbody>
            {tableData?.toggleableTable?.data?.result?.length > 0 ? (
              <>
                {tableData?.toggleableTable?.data?.result?.map(
                  (item: any, index: any) => {
                    return (
                      <tr
                        key={index}
                        className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                      >
                        <td className="py-3 px-3 text-left  w-10 capitalize">
                          {item?.transactionType?.toLowerCase()}
                        </td>
                        <td className="py-3 px-3 text-left td-sr">
                          {item?.createdAt
                            ? moment(new Date(item?.createdAt)).format(
                                "DD-MM-YYYY hh:mm A"
                              )
                            : ""}
                        </td>
                        <td className="py-3 px-3 text-left td-sr">
                          {item?.ebOrderRef}
                        </td>
                        <td className="py-3 px-3 text-left capitalize">
                          {item?.status?.toLowerCase()}
                        </td>
                        <td className="py-3 px-3 text-right ">
                          ${item?.usdInrBid.toFixed(4)}
                        </td>
                        <td className="py-3 px-3 text-right ">
                          ${item?.usdInrAsk.toFixed(4)}
                        </td>
                        <td className="py-3 px-3 text-right ">
                          ${formatAmount(item?.metalRateUsdOztBid, metalCode)}
                        </td>
                        <td className="py-3 px-3 text-right ">
                          ${formatAmount(item?.metalRateUsdOztAsk, metalCode)}
                        </td>
                        <td className="py-3 px-3 text-right ">
                          {formatMoney(
                            formatAmount(item?.locoPremiumUsdOzt, metalCode),
                            "US$"
                          )}
                        </td>
                        <td className="py-3 px-3 text-right ">
                          ${formatAmount(item?.orderMetalRateInOzt, metalCode)}
                        </td>
                        <td className="py-3 px-3 text-right ">
                          ₹{item?.orderMetalRateInGm.toFixed(2)}
                        </td>
                        <td className="py-3 px-3 text-right ">
                          ₹{item?.customDutyInr.toFixed(2)}
                        </td>
                        <td className="py-3 px-3 text-right ">
                          ₹{item?.landedCostBaseInr.toFixed(2)}
                        </td>
                        <td className="py-3 px-3 text-right ">
                          ₹{item?.nsvAmount.toFixed(2)}
                        </td>
                        <td className="py-3 px-3 text-right ">
                          ₹{item?.customerRateInrPerGm.toFixed(2)}
                        </td>
                        <td className="py-3 px-3 text-right ">
                          {item?.customerGmQuantity.toFixed(4)}g
                        </td>
                        <td className="py-3 px-3 text-right ">
                          ₹{item?.customerInrAmount.toFixed(2)}
                        </td>
                        <td className="py-3 px-3 text-right ">
                          ₹{parseFloat(item?.discount)?.toFixed(2)}
                        </td>
                        <td className="py-3 px-3 text-right ">
                          ₹{item?.customerAmountAfterDiscount.toFixed(2)}
                        </td>
                        <td className="py-3 px-3 text-right ">
                          {item?.customerOzQuantity.toFixed(4)}
                        </td>
                        <td className="py-3 px-3 text-right ">
                          {formatAmount(item?.customerUsdAmount, metalCode)}
                        </td>
                        <td className="py-3 px-3 text-right ">
                          ₹{item?.offsetPrice.toFixed(4)}
                        </td>
                        <td className="py-3 px-3 text-right sticky bg-white ">
                          {item?.isMetalCovered === true ? "Yes" : "No"}
                        </td>
                        <td className="py-3 px-3 text-right sticky  bg-white ">
                          {item?.isFxCovered === true ? "Yes" : "No"}
                        </td>
                      </tr>
                    );
                  }
                )}
              </>
            ) : (
              <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                <td colSpan={10} className="text-center py-3 px-3 text-left ">
                  No data to display
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex ">
        <Pagination
          meta={{
            current: tableData?.toggleableTable?.meta?.page,
            pages: tableData?.toggleableTable?.meta?.totalPages - 1,
          }}
          handlePageChange={(page: number) => {
            // console.log(page);
            setPage(page);
            // setSelectedRecords([]);
          }}
        />
      </div>
    </div>
  );
};

export default TradeReport;
