import React, { useEffect, useState } from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";
import Modal from "../../../Components-ui/Modal/Modal";
import DashboardReportServiceHP from "../DashboardServiceHP";
import { toast } from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import { useAuthStore } from "../../../Store/AuthStore";
import moment from "moment";
import { formatAmount, formatMoney, formatQuantity } from "../utils";
import DashboardReportService from "../DashboardReportService";
import { Pagination } from "../../../Components-ui/Pagination";
import { isNullishCoalesce } from "typescript";
interface Data {
  calories: number;
  carbs: number;
  fat: number;
  name: string;
  protein: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[] = [],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const UsdInrHEadCells: HeadCell[] = [
  {
    id: "transactionType",
    numeric: false,
    disablePadding: true,
    label: "Txn Type",
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "ebOrderRef",
    numeric: true,
    disablePadding: false,
    label: "Order Number",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Order Status",
  },
  {
    id: "usdInrBid",
    numeric: false,
    disablePadding: true,
    label: "US$/₹ Bid",
  },
  {
    id: "usdInrAsk",
    numeric: true,
    disablePadding: false,
    label: "US$/₹ Ask",
  },
  {
    id: "usdInrAsk",
    numeric: true,
    disablePadding: false,
    label: "Customer Amount in US$",
  },
  {
    id: "customerUsdAmount",
    numeric: true,
    disablePadding: false,
    label: "Customer Amount Net in ₹",
  },
  {
    id: "isFxCovered",
    numeric: true,
    disablePadding: false,
    label: "Fx Covered",
  },
  {
    id: "fxCoveredRate",
    numeric: true,
    disablePadding: false,
    label: "Fx Covered Rate",
  },
  {
    id: "fxCoveredTime",
    numeric: true,
    disablePadding: false,
    label: "Fx Covered Timestamp",
  },
];

const headCells: readonly HeadCell[] = [
  {
    id: "transactionType",
    numeric: false,
    disablePadding: true,
    label: "Txn Type",
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "ebOrderRef",
    numeric: true,
    disablePadding: false,
    label: "Order Number",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Order Status",
  },
  {
    id: "customerOzQuantity",
    numeric: true,
    disablePadding: false,
    label: "Quantity in Ozt",
  },
  {
    id: "metalRateUsdOztBid",
    numeric: true,
    disablePadding: false,
    label: "Metal Rate US$ Ozt Bid",
  },
  {
    id: "metalRateUsdOztAsk",
    numeric: true,
    disablePadding: false,
    label: "Metal Rate US$ Ozt Ask",
  },
  {
    id: "locoPremiumUsdOzt",
    numeric: true,
    disablePadding: false,
    label: "Loco Premium",
  },

  {
    id: "customerGmQuantity",
    numeric: true,
    disablePadding: false,
    label: "Customer Quantity in g",
  },
  {
    id: "",
    numeric: true,
    disablePadding: false,
    label: "Customer Rate ₹ per g",
  },
  {
    id: "offsetPrice",
    numeric: true,
    disablePadding: false,
    label: "Price offset ₹ per g",
  },

  {
    id: "metalCoveredRate",
    numeric: true,
    disablePadding: false,
    label: "Metal Covered",
  },

  {
    id: "metalCoveredRate",
    numeric: true,
    disablePadding: false,
    label: "Metal Covered Rate",
  },
  {
    id: "metalCoveredTime",
    numeric: true,
    disablePadding: false,
    label: "Metal Covered Timestamp",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  tab?: any;
  isAllSelected: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tab,
    isAllSelected,
  } = props;
  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow
        className="text-left border-b border-gray-300 bg-gray-800 text-white hover:text-white"
        sx={{
          color: "#ffffff",
          "&:hover": {
            color: "#ffffff",
          },
        }}
      >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && isAllSelected}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all records",
            }}
            sx={{
              color: "#ffffff",
              "&:hover": {
                color: "#ffffff",
              },
            }}
          />
        </TableCell>
        {tab === "UsdInr"
          ? UsdInrHEadCells?.map((headCell, index) => (
              <TableCell
                key={headCell.id}
                align={index === 0 ? "left" : "right"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
                className="sticky top-0 px-3 py-2 td-sr hover:text-white"
                sx={{
                  color: "#ffffff",
                  "&:hover": {
                    color: "#ffffff",
                  },
                }}
              >
                <TableSortLabel
                  // active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  sx={{
                    color: "#ffffff",
                    "&:hover": {
                      color: "#ffffff",
                    },
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))
          : headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
                className="sticky top-0 px-3 py-2  td-sr"
                sx={{
                  color: "#ffffff",
                  "&:hover": {
                    color: "#ffffff",
                  },
                  "&:active": {
                    color: "#ffffff",
                  },
                }}
              >
                <TableSortLabel
                  // active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  sx={{
                    color: "#ffffff",
                    "&:hover": {
                      color: "#ffffff",
                    },
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  sSelected: number;
  bbSelected: number;
  setShowConfirmModal: any;
  WeightedAvg?: any;
  tab?: any;
  buyBackWavg: any;
  sellInput?: any;
  buyInput?: any;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const {
    numSelected,
    sSelected,
    bbSelected,
    setShowConfirmModal,
    WeightedAvg,
    tab,
    buyBackWavg,
    sellInput,
    buyInput,
  } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {/* {numSelected > 0 && (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      )} */}

      {numSelected > 0 && (
        <Typography
          sx={{
            flex: "1 1 100%",
            justifyContent: "start",
            alignContent: "center",
            alignItems: "center",
            display: "flex",
            gap: "30px",
            fontSize: 14,
          }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          <span style={{ textAlign: "right" }}>
            Buyback: <b>{bbSelected}</b> <br />
            Sell: <b>{sSelected}</b> <br />
            <hr />
            Total: <b>{numSelected}</b>
          </span>
          <span>
            Sell WAVG:{" "}
            <strong>
              {WeightedAvg ? WeightedAvg?.toFixed(4) : 0}
              {tab === "UsdInr" ? "(₹)" : " ($)"}
            </strong>
          </span>

          <span>
            Buyback WAVG:{" "}
            <strong>
              {buyBackWavg ? buyBackWavg?.toFixed(4) : 0}
              {`${tab === "UsdInr" ? "(₹)" : " ($)"}`}
            </strong>
          </span>
        </Typography>
      )}
      {numSelected > 0 && (
        <div className="flex items-center ">
          {sellInput}
          {buyInput}
          <button
            className={`px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ${
              HasAnyAccess([
                "trade.report.edit",
                "treasury.report.edit",
                "management.report.edit",
              ]) && numSelected
                ? "bg-green-400 "
                : "bg-gray-200"
            }`}
            type="button"
            // onClick={downloadReport}
            disabled={
              !HasAnyAccess([
                "trade.report.edit",
                "treasury.report.edit",
                "management.report.edit",
              ]) || !numSelected
            }
            onClick={
              numSelected > 0 ? () => setShowConfirmModal(true) : () => null
            }
          >
            Submit
          </button>
        </div>
      )}
    </Toolbar>
  );
}

type Props = {
  // data: any;
  flow: any;
  // selectedRecords?: any;
  setSelectedRecords?: any;
  alreadySelectedRecords?: any;
  showConfirmModal?: any;
  setShowConfirmModal?: any;
  metalCode?: any;
  metalName?: any;
  getData?: any;
  tab?: any;
  filters?: any;
};

export default function TreasuryRawReport({
  // data,
  flow,
  // selectedRecords,
  // setSelectedRecords,
  alreadySelectedRecords,
  setShowConfirmModal,
  showConfirmModal,
  getData,
  metalCode,
  metalName,
  tab,
  filters,
}: Props) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("txnType");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [loading, setLoading] = React.useState(false);
  const { tokens } = useAuthStore();
  const [Wavg, setWavg] = React.useState(0);
  const [buyBackWavg, setBuyBackWavg] = React.useState(0);
  const [sellCoveredRate, setSellCoveredRate] = React.useState<number>();
  const [buyCoveredRate, setBuyCoveredRate] = React.useState<number>();
  const [data, setData] = useState<any>(null);

  function fetchRecords() {
    let queryParams = {
      fromDate: parseInt(moment(filters?.fromDate)?.format("x")),
      toDate: parseInt(moment(filters?.toDate)?.format("x")),
      metalCode: tab === "UsdInr" ? "" : metalCode,
      status: filters?.transactionStatus || [],
      itemCode: filters?.itemCode || "",
      vault: filters?.vault?.id || "",
      // customerID: filters?.customerID || "",
      partnerId: filters?.CuGroup?.partnerId || "",
      partnerName: filters?.CuGroup?.name,
      isCovered: filters?.covered,
      orderBy: "created_at",
      order: "desc",
      page: page,
      pagesize: rowsPerPage,
      isPaginated: true,
      isFxCovered: tab === "UsdInr" ? filters?.isFxCovered : "All",
      isMetalCovered: tab === "UsdInr" ? "All" : filters?.isMetalCovered,
    };

    setLoading(true);
    DashboardReportService.getTradeRecords(tokens, queryParams)
      .then((response: any) => {
        setData(response?.data?.data);
        setLoading(false);
        // console.log(resp?.data?.data, "resp");
      })
      .catch((error) => {
        setLoading(false);
        setData({});
        toast.error("Something went wrong");
      });
  }

  useEffect(() => {
    fetchRecords();
  }, [filters, page, rowsPerPage]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    let prevSelected: string[] = [];
    let keyToCheck = "isMetalCovered";
    if (tab === "UsdInr") {
      keyToCheck = "isFxCovered";
    }
    const newSelected = data?.toggleableTable?.data?.result?.filter(
      (item: any) => item[keyToCheck] !== true && !selected.includes(item)
    );
    if (event.target.checked) {
      prevSelected = prevSelected.concat(selected, newSelected);
      setSelected(prevSelected);
      return;
    } else {
      setSelected([]);
    }
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    row: any,
    isSelectDisabled?: any
  ) => {
    if (isSelectDisabled) return;
    const selectedIndex = selected.findIndex(
      (item: any) => item?.ebOrderRef === row?.ebOrderRef
    );
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (row: any) =>
    selected?.some((item: any) => item?.ebOrderRef === row?.ebOrderRef);

  // const isSelected = (row: any) => {
  //   console.log(selected, "selected inside isSelected");
  //   return false;
  // };

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const changeIsCovered = () => {
    if (selected?.length) {
      const sellSelected = selected
        ?.filter((item: any) => item?.transactionType === "SELL")
        ?.map((item: any) => item?.orderItemId);
      const buySelected = selected
        ?.filter((item: any) => item?.transactionType === "BUYBACK")
        ?.map((item: any) => item?.orderItemId);
      let promises = [];
      if (tab === "UsdInr") {
        if (sellSelected?.length > 0) {
          if (!sellCoveredRate) {
            toast.error("Please provide valid sell covered rate.");
            return;
          }
          promises.push(
            DashboardReportServiceHP.setIsFxCovered(
              tokens,
              sellCoveredRate,
              sellSelected
            )
          );
        }
        if (buySelected?.length > 0) {
          if (!buyCoveredRate) {
            toast.error("Please provide valid buy covered rate.");
            return;
          }
          promises.push(
            DashboardReportServiceHP.setIsFxCovered(
              tokens,
              buyCoveredRate,
              buySelected
            )
          );
        }
      } else {
        if (sellSelected?.length > 0) {
          if (!sellCoveredRate) {
            toast.error("Please provide valid sell covered rate.");
            return;
          }
          promises.push(
            DashboardReportServiceHP.setIsMetalCovered(
              tokens,
              sellCoveredRate,
              sellSelected
            )
          );
        }
        if (buySelected?.length > 0) {
          if (!buyCoveredRate) {
            toast.error("Please provide valid buy covered rate.");
            return;
          }
          promises.push(
            DashboardReportServiceHP.setIsMetalCovered(
              tokens,
              buyCoveredRate,
              buySelected
            )
          );
        }
      }
      if (promises.length > 0) {
        setShowConfirmModal(false);
        setLoading(true);
        Promise.all(promises)
          .then((values) => {
            setSelected([]);
            getData();
            fetchRecords();
            setLoading(false);
            toast.success("Records Updated successfully");
          })
          .catch((e) => {
            setLoading(false);
            toast.error("Something went wrong");
          });
      }
    }
  };

  function weightedAverage(numbers: any, weights: any) {
    let total = 0;
    let weightSum = 0;
    for (let i = 0; i < numbers.length; i++) {
      total = total + numbers[i] * weights[i];
      weightSum += weights[i];
    }
    return total / weightSum;
  }

  useEffect(() => {
    let orderMetalRateInOztSell = [];
    let customerOzQuantitySell = [];
    let orderMetalRateInOztBuyBack = [];
    let customerOzQuantityBuyBack = [];
    if (tab === "UsdInr") {
      orderMetalRateInOztSell = selected
        ?.filter((item: any) => item?.transactionType === "SELL")
        ?.map((item: any) => item.usdInrAsk);

      customerOzQuantitySell = selected
        ?.filter((item: any) => item?.transactionType === "SELL")
        ?.map((item: any) => item.customerUsdAmount);

      orderMetalRateInOztBuyBack = selected
        ?.filter((item: any) => item?.transactionType === "BUYBACK")
        ?.map((item: any) => item.usdInrBid);
      customerOzQuantityBuyBack = selected
        ?.filter((item: any) => item?.transactionType === "BUYBACK")
        ?.map((item: any) => item.customerUsdAmount);
    } else {
      orderMetalRateInOztSell = selected
        ?.filter((item: any) => item?.transactionType === "SELL")
        ?.map((item: any) => item.metalRateUsdOztAsk);
      customerOzQuantitySell = selected
        ?.filter((item: any) => item?.transactionType === "SELL")
        ?.map((item: any) => item.customerGmQuantity);

      orderMetalRateInOztBuyBack = selected
        ?.filter((item: any) => item?.transactionType === "BUYBACK")
        ?.map((item: any) => item.metalRateUsdOztBid);
      customerOzQuantityBuyBack = selected
        ?.filter((item: any) => item?.transactionType === "BUYBACK")
        ?.map((item: any) => item.customerGmQuantity);
    }

    if (metalCode === "Ag") {
      orderMetalRateInOztSell = orderMetalRateInOztSell?.map((item) =>
        item?.toFixed(4)
      );
      orderMetalRateInOztBuyBack = orderMetalRateInOztBuyBack?.map((item) =>
        item?.toFixed(4)
      );
    }
    setWavg(weightedAverage(orderMetalRateInOztSell, customerOzQuantitySell));
    setBuyBackWavg(
      weightedAverage(orderMetalRateInOztBuyBack, customerOzQuantityBuyBack)
    );
  }, [selected]);

  useEffect(() => {
    setSelected([]);
  }, [filters, metalCode]);

  const NoFxCoveredRowCount = data?.toggleableTable?.data?.result?.filter(
    (item: any) => item?.isFxCovered !== true
  )?.length;
  const NoMetaCoveredRowCount = data?.toggleableTable?.data?.result?.filter(
    (item: any) => item?.isMetalCovered !== true
  )?.length;

  const isAllSelected = () => {
    let allSelected = true;
    let keyToCheck = "isMetalCovered";
    if (tab === "UsdInr") {
      keyToCheck = "isFxCovered";
    }
    const NoCoveredRows = data?.toggleableTable?.data?.result?.filter(
      (item: any) => item[keyToCheck] !== true
    );
    for (let i = 0; i < NoCoveredRows?.length; i++) {
      if (
        !selected?.some(
          (item: any) => item?.ebOrderRef === NoCoveredRows[i]?.ebOrderRef
        )
      ) {
        allSelected = false;
        break;
      }
    }

    // console.log(allSelected,"allSelected");
    return allSelected;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        {loading && <Loader />}
        <Modal
          size={"small"}
          show={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          children={
            <div>
              <h3>
                Transactions once covered, cannot be reversed.
                <br />
                Do you want to submit?
              </h3>

              <div className="flex justify-end gap-3 py-3">
                <button
                  className="text-white rounded-md bg-gray-400 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowConfirmModal(false)}
                >
                  No
                </button>
                <button
                  className="text-white rounded-md bg-red-400 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                  type="button"
                  onClick={changeIsCovered}
                >
                  Yes
                </button>
              </div>
            </div>
          }
        />
        {selected.length > 0 && (
          <EnhancedTableToolbar
            numSelected={selected.length}
            sSelected={
              selected.filter((sitm: any) => sitm?.transactionType == "SELL")
                .length
            }
            bbSelected={
              selected.filter((sitm: any) => sitm?.transactionType == "BUYBACK")
                .length
            }
            setShowConfirmModal={setShowConfirmModal}
            WeightedAvg={Wavg}
            buyBackWavg={buyBackWavg}
            tab={tab}
            sellInput={
              <input
                className="block w-40 p-2 mr-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                type="number"
                placeholder="Sell Covered Rate"
                autoComplete="none"
                value={sellCoveredRate}
                onChange={(e) => setSellCoveredRate(parseFloat(e.target.value))}
              />
            }
            buyInput={
              <input
                className="block w-40 p-2 mr-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                type="number"
                placeholder="Buy Covered Rate"
                autoComplete="none"
                value={buyCoveredRate}
                onChange={(e) => setBuyCoveredRate(parseFloat(e.target.value))}
              />
            }
          />
        )}
        <TableContainer>
          <Table
            sx={{ minWidth: 750, color: "#fff", fontSize: 12 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={
                tab === "UsdInr" ? NoFxCoveredRowCount : NoMetaCoveredRowCount
              }
              tab={tab}
              isAllSelected={isAllSelected()}
            />
            <TableBody>
              {data?.toggleableTable?.data?.result?.length > 0 ? (
                stableSort(
                  data?.toggleableTable?.data?.result,
                  getComparator(order, orderBy)
                )?.map((row: any, index: number) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const isSelectDisabled =
                    tab === "UsdInr" ? row?.isFxCovered : row?.isMetalCovered;
                  return (
                    <TableRow
                      hover
                      onClick={(event) =>
                        handleClick(event, row, isSelectDisabled)
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.ebOrderRef}
                      selected={isItemSelected}
                      className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                    >
                      <TableCell padding="checkbox" className="py-3 px-3 td-sr">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected || isSelectDisabled}
                          disabled={isSelectDisabled}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      {tab === "UsdInr" ? (
                        <>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            className="py-3 px-3 td-sr"
                          >
                            {row?.transactionType?.toLowerCase()}
                          </TableCell>
                          <TableCell align="right" className="py-3 px-3 td-sr">
                            {row?.createdAt
                              ? moment(new Date(row?.createdAt)).format(
                                  "DD-MM-YYYY hh:mm A"
                                )
                              : ""}
                          </TableCell>

                          <TableCell align="right" className="py-3 px-3 td-sr">
                            {row.ebOrderRef}
                          </TableCell>
                          <TableCell align="right" className="py-3 px-3 td-sr">
                            {row.status?.toLowerCase()}
                          </TableCell>
                          <TableCell align="right" className="py-3 px-3 td-sr">
                            ₹{row?.usdInrBid?.toFixed(4)}
                          </TableCell>
                          <TableCell align="right" className="py-3 px-3 td-sr">
                            ₹{row?.usdInrAsk?.toFixed(4)}
                          </TableCell>
                          <TableCell align="right" className="py-3 px-3 td-sr">
                            ${row.customerUsdAmount?.toFixed(4)}
                          </TableCell>
                          <TableCell align="right" className="py-3 px-3 td-sr">
                            ₹{row?.customerInrAmount?.toFixed(4)}
                          </TableCell>
                          <TableCell align="right" className="py-3 px-3 td-sr">
                            {row.isFxCovered ? "Yes" : "No"}
                          </TableCell>
                          <TableCell align="right" className="py-3 px-3 td-sr">
                            {row?.fxCoveredRate
                              ? "₹" + parseFloat(row?.fxCoveredRate)?.toFixed(4)
                              : ""}
                          </TableCell>
                          <TableCell align="right" className="py-3 px-3 td-sr">
                            {row?.fxCoveredTime}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            className="py-3 px-3 td-sr capitalize"
                          >
                            {row.transactionType?.toLowerCase()}
                          </TableCell>
                          <TableCell align="right" className="py-3 px-3 td-sr">
                            {row?.createdAt
                              ? moment(new Date(row?.createdAt)).format(
                                  "DD-MM-YYYY hh:mm A"
                                )
                              : ""}
                          </TableCell>

                          <TableCell align="right" className="py-3 px-3 td-sr">
                            {row?.ebOrderRef}
                          </TableCell>
                          <TableCell
                            align="right"
                            className="py-3 px-3 td-sr capitalize"
                          >
                            {row?.status?.toLowerCase()}
                          </TableCell>
                          <TableCell align="right" className="py-3 px-3 td-sr">
                            {row?.customerOzQuantity}
                          </TableCell>

                          <TableCell align="right" className="py-3 px-3 td-sr">
                            $
                            {formatAmount(
                              row.metalRateUsdOztBid || 0,
                              metalCode
                            )}
                          </TableCell>
                          <TableCell align="right" className="py-3 px-3 td-sr">
                            $
                            {formatAmount(
                              row?.metalRateUsdOztAsk || 0,
                              metalCode
                            )}
                          </TableCell>
                          <TableCell align="right" className="py-3 px-3 td-sr">
                            {row?.discount}
                          </TableCell>
                          <TableCell align="right" className="py-3 px-3 td-sr">
                            {row.customerGmQuantity?.toFixed(4)}g
                          </TableCell>
                          <TableCell align="right" className="py-3 px-3 td-sr">
                            ₹{row.customerRateInrPerGm?.toFixed(2)}
                          </TableCell>
                          <TableCell align="right" className="py-3 px-3 td-sr">
                            ₹{row.offsetPrice?.toFixed(2)}
                          </TableCell>
                          <TableCell align="right" className="py-3 px-3 td-sr">
                            {row?.isMetalCovered === true ? "Yes" : "No"}
                          </TableCell>
                          <TableCell align="right" className="py-3 px-3 td-sr">
                            {row.metalCoveredRate
                              ? "$" +
                                parseFloat(row.metalCoveredRate)?.toFixed(4)
                              : ""}
                          </TableCell>
                          <TableCell align="right" className="py-3 px-3 td-sr">
                            {row.metalCoveredTime}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    No data to display
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[100,1000]}
          component="div"
          count={data?.toggleableTable?.meta?.total || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}

        <Pagination
          meta={{
            current: data?.toggleableTable?.meta?.page,
            pages: data?.toggleableTable?.meta?.totalPages,
          }}
          handlePageChange={(page: number) => {
            // console.log(page);
            setPage(page);
            // setSelectedRecords([]);
          }}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
}
