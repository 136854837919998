import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { Tab } from "@headlessui/react";
import { useAuthStore } from "../../Store/AuthStore";
import moment from "moment";
import CustomerService from "../Customer/Services/Customer.service";
import Service from "../CustomerGroup/CustomerGroup.Service";
import { toast, ToastContainer } from "react-toastify";
import { Pagination } from "../../Components-ui/Pagination";
import SimpleReactValidator from "simple-react-validator";
import CSVReader from "react-csv-reader";
import "./styles.css";
import Loader from "../../Components-ui/Loader";
import { CSVLink } from "react-csv";
import HasAnyAccess from "../../Components-ui/hasAnyAccess";
import { useHistory, useParams } from "react-router-dom";
const AddCustomer: React.FC = () => {
  const { logout, permissions, userData, tokens } = useAuthStore();
  const [tab, setTab] = useState<any>(); // 0-Manual 1 - upload
  const [fileData, setFileData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [customers, setCustomers] = useState<any>([]);
  const [array, setArray] = useState<any>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<any>(new Set());
  const [customerId, setCustomerId] = useState<any>("");
  const [metaData, setMetaData] = useState<any>({});
  const [validatorStatus, setValidator] = useState<boolean>(false);
  const simpleValidator = new SimpleReactValidator({
    locale: "en",
    validators: {},
  });
  const validationRef = React.useRef(simpleValidator);
  const validator = validationRef.current;
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState<any>(0);
  const { id }: any = useParams();
  useEffect(() => {
    get_customer_list({}, 0);
  }, []);

  const get_customer_list = async (filters_: any, page: any) => {
    setLoading(true);
    console.log("filters_", filters_);
    await CustomerService.getCustomerList(filters_, tokens, page)
      .then((response: any) => {
        setLoading(false);
        setCustomers(response.data.data);
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPage(page);
      })
      .catch((err) => {
        setLoading(false);
        setMetaData({});
        toast.error(err);
      });
  };

  //   const saveCustomerGroup = async () => {
  //     setValidator(true);
  //     if (validator.allValid()) {
  //       var data = [
  //         {
  //           name: name,
  //           active: "ENABLED",
  //         },
  //       ];
  //       Service.saveCustomerGroup(tokens, data)
  //         .then((response: any) => {
  //           assignCustomers(response.data.data[0].id);
  //         })
  //         .catch((err) => {
  //           setLoading(false);
  //           toast.error(err.data.errors[0].title);
  //         });
  //       setValidator(false);
  //     }
  //   };

  console.log("selected", [...selectedCustomers]);
  const assignCustomers = async () => {
    var status = false;
    const groupObjArray: any = [];
    // [selectedCustomers].map((sc:any)=>{
    //     console.log("sc",sc)
    // })
    if (1 !== tab) {
      //   if (0 !== array.length) {
      //     array.map((customer: any, index: number) => {
      //       customer.customerGroupId = id;
      //       if (index === array.length - 1) {
      //         status = true;
      //       }
      //     });
      //     if (status) {
      //       assign(array);
      //     }
      //   }
      status = true;

      [...selectedCustomers].map((sc) => {
        groupObjArray.push({
          customerId: sc,
          customerGroupId: id,
          active: "ENABLED",
        });
      });
      if (status) {
        assign(groupObjArray);
        history.push(`/customer-group/edit/${id}`);
      }
    } else {
      fileData.map((customer: any, index: number) => {
        customer.customerGroupId = id;
        if (index === fileData.length - 1) {
          status = true;
        }
      });
      if (status) {
        assign(fileData);
        history.push(`/customer-group/edit/${id}`);
      }
    }
  };

  const assign = async (array: any) => {
    await Service.assignCustomers(tokens, array)
      .then((response: any) => {
        setCustomerId("");
        setArray([]);
        get_customer_list({}, 0);
        toast.success("Customers has been added to the Group");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.data.errors[0].title);
      });
  };

  const changeStatus = (customerId: any) => {
    setSelectedCustomers((prev: any) => {
      const updatedSet = new Set(prev);
      if (updatedSet.has(customerId)) {
        updatedSet.delete(customerId);
      } else {
        updatedSet.add(customerId);
      }
      return updatedSet;
    });
  };
  const handleForce = (data: any, fileInfo: any) => {
    let newArray = data.map((item: any) => {
      return {
        customerId: item.customerid,
        active: item.active,
      };
    });
    setFileData(newArray);
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, "_"),
  };

  const sampleData = [
    { customerId: "242652", active: "ENABLED" },
    { customerId: "257001", active: "ENABLED" },
  ];

  console.log("customers", customers);
  return (
    <Layout type={"UserModule"}>
      <ToastContainer />
      <button
        onClick={() => {
          history.goBack();
        }}
        className="-mr-2 flex items-center rounded text-white font-bold text-xs uppercase"
      >
        <svg
          className="w-7"
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
        >
          <path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" />
        </svg>{" "}
        {/* <span className="text-black">Back</span> */}
      </button>
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">
          {"Add New Customer"}
        </h1>
      </div>
      <div className="overflow-x-auto">
        <div className="w-full px-2 sm:px-0">
          {!tab && (
            <div className="pb-3 w-full md:w-1/2 mb-6 md:mb-0">
              <div className="flex items-center space-x-4">
                {/* Input Field */}
                <input
                  className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="name"
                  type="text"
                  name="customerId"
                  placeholder="Customer Id"
                  autoComplete="none"
                  value={customerId}
                  onChange={(event) => setCustomerId(event.target.value)}
                />

                {/* Filter Button */}
                <button
                  className="text-white bg-purple-950 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none transition-all duration-150 rounded-md"
                  type="button"
                  onClick={() => {
                    get_customer_list({ customer_id: customerId }, 0);
                  }}
                >
                  Filter
                </button>

                {/* Reset Button */}
                <button
                  className="text-white bg-red-600 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none transition-all duration-150 rounded-md"
                  type="button"
                  onClick={() => {
                    setCustomerId("");
                    get_customer_list({}, 0);
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          )}

          <Tab.Group selectedIndex={tab} onChange={setTab}>
            {loading ? (
              <Loader />
            ) : (
              <>
                <Tab.List className="flex p-1 space-x-1 bg-gray-300 rounded-xl">
                  <Tab
                    key={1}
                    className={({ selected }) =>
                      classNames(
                        "w-full py-2.5 text-sm leading-5 font-bold text-gray-600 rounded-lg",
                        "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                        selected
                          ? "bg-white shadow"
                          : "text-white-600 hover:bg-white/[0.12] hover:text-gray-600"
                      )
                    }
                  >
                    {`By Manual`}
                  </Tab>
                  <Tab
                    key={1}
                    className={({ selected }) =>
                      classNames(
                        "w-full py-2.5 text-sm leading-5 font-bold text-gray-600 rounded-lg",
                        "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                        selected
                          ? "bg-white shadow"
                          : "text-white-600 hover:bg-white/[0.12] hover:text-gray-600"
                      )
                    }
                  >
                    {`By Upload`}
                  </Tab>
                </Tab.List>
                <Tab.Panels className="mt-2">
                  <Tab.Panel
                    key={1}
                    className={classNames(
                      "bg-white rounded-xl p-3",
                      "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60"
                    )}
                  >
                    <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                      <thead>
                        <tr className="text-left border-b border-gray-300">
                          {HasAnyAccess(["customer.group.create"]) && (
                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10"></th>
                          )}
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Customer id
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Name
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Category
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Type
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Source By
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Partner
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Created At
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {customers.length > 0 ? (
                          customers.map((customer: any, index: number) => {
                            return (
                              <tr
                                key={index}
                                className="border-b border-gray-200 bg-white text-black hover:bg-gray-100"
                              >
                                {HasAnyAccess(["customer.group.create"]) && (
                                  <td className="py-3 px-3 text-left">
                                    <input
                                      type="checkbox"
                                      checked={selectedCustomers.has(
                                        customer.customerId
                                      )}
                                      onChange={() =>
                                        changeStatus(customer.customerId)
                                      }
                                    />
                                  </td>
                                )}
                                <td className="py-3 px-3 text-left">
                                  {customer.customerId}
                                </td>
                                <td className="py-3 px-3 text-left">
                                  {customer.displayName}
                                </td>
                                <td className="py-3 px-3 text-left">
                                  {customer.customerCategory}
                                </td>
                                <td className="py-3 px-3 text-left">
                                  {customer.customerType}
                                </td>
                                <td className="py-3 px-3 text-left">
                                  {customer.source}
                                </td>
                                <td className="py-3 px-3 text-left">
                                  {customer.partner.name}
                                </td>
                                <td className="py-3 px-3 text-left">
                                  {moment(customer.createdAt).format(
                                    "DD-MM-YYYY HH:mm"
                                  )}
                                </td>
                                <td className="py-3 px-3 text-left">
                                  <span
                                    className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full last:mr-0 mr-1 ${
                                      customer.active === "ENABLED"
                                        ? "text-green-600 bg-green-200"
                                        : "text-black-600 bg-gray-400"
                                    }`}
                                  >
                                    {customer.active}
                                  </span>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                            <td
                              className="py-3 px-3 text-center relative"
                              colSpan={9}
                            >
                              No Data To Display
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <Pagination
                      meta={metaData}
                      handlePageChange={(number: number) => {
                        get_customer_list({}, number);
                      }}
                    />
                    <div className="col-span-12 sm:col-span-12 flex justify-end mt-4">
                      {HasAnyAccess(["customer.group.create"]) && (
                        <button
                          className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                          type="button"
                          onClick={assignCustomers}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </Tab.Panel>
                  <Tab.Panel
                    key={2}
                    className={classNames(
                      "bg-white rounded-xl p-3",
                      "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60"
                    )}
                  >
                    <div>
                      <CSVReader
                        accept=".csv"
                        cssClass="csv-reader-input"
                        label=""
                        onFileLoaded={handleForce}
                        parserOptions={papaparseOptions}
                        inputId="ObiWan"
                        inputName="ObiWan"
                        inputStyle={{}}
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-12 flex justify-end mt-4">
                      <CSVLink
                        className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                        style={{ textDecoration: "none" }}
                        data={sampleData}
                        filename={"sample.csv"}
                        target="_blank"
                      >
                        <div className="flex">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="#572595"
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
                          </svg>
                          Template
                        </div>
                      </CSVLink>
                      {HasAnyAccess(["customer.group.create"]) && (
                        <button
                          className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                          type="button"
                          onClick={assignCustomers}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </Tab.Panel>
                </Tab.Panels>
              </>
            )}
          </Tab.Group>
        </div>
      </div>
    </Layout>
  );
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default AddCustomer;
