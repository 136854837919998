import moment from "moment";
import React, { useState } from "react";
import Datetime from "react-datetime";
import { CalendarIcon, FilterIcon } from "@heroicons/react/outline";
import MultipleSelect from "../../../Components-ui/MultipleSelect";
import TradeSummaryReport from "./TradeSummaryReport";
import TreasurySummaryReport from "./TreasurySummaryReport";
import DashboardReportServiceHP from "../DashboardServiceHP";
import { useAuthStore } from "../../../Store/AuthStore";

const isCoveredStatus = [
  {
    id: "Uncovered",
    status: "Uncovered",
  },
  {
    id: "All",
    status: "All",
  },
  {
    id: "Covered",
    status: "Covered",
  },
];
const transactionStatus = [
  {
    id: "COMPLETED",
    status: "COMPLETED",
  },
  {
    id: "PENDING",
    status: "PENDING",
  },
  {
    id: "PAYMENT_PENDING",
    status: "PAYMENT_PENDING",
  },
  {
    id: "PARTIAL_COMPLETED",
    status: "PARTIAL_COMPLETED",
  },
];

const initialFilterValues = () => {
  let fromDate = new Date(moment().startOf("day").format("YYYY-MM-DD hh:mm A"));
  let toDate = new Date();
  return {
    fromDate,
    toDate,
    itemCode: "",
    vault: "",
    CuGroup: "",
    isFxCovered: "Uncovered",
    transactionStatus: [],
  };
};

interface PropsType {
  CustomerGroups?: any;
  setIsUsdInrLoading?: any;
}
const UsdInrTable: React.FC<PropsType> = ({
  CustomerGroups,
  setIsUsdInrLoading,
}) => {
  const [filters, setFilters] = useState<any>(initialFilterValues());
  const [selectedFilters, setSelectedFilters] = useState<any>(
    initialFilterValues()
  );
  // const [CustomerGroups, setCustomerGroups] = useState<any>([]);
  //   const [selectedMetal, setSelectedMetal] = useState(Metals[0]);
  const [expanded, setExpanded] = useState(false);
  const [page, setPage] = useState(0);
  const { tokens } = useAuthStore();
  const [tablesData, setTablesData] = useState({});
  // const [isUdInrLoading,setIsUsdInrLoading] = useState(false)

  const resetForm = async () => {
    setFilters(initialFilterValues());
    setSelectedFilters(initialFilterValues());
    // getPartnerList(0);
  };
  const validDateFromDate = function (current: any) {
    return (
      current.isBefore(moment()) && current.isSameOrAfter(moment("01-04-2020"))
    );
  };

  React.useEffect(() => {
    const queryParams = {
      fromDate: moment.utc(filters?.fromDate).format("x"),
      toDate: moment.utc(filters?.toDate).format("x"),
      // metalCode: metalCode,
      status: filters?.transactionStatus || [],
      itemCode: filters?.itemCode || "",
      vault: filters?.vault?.id || "",
      partnerId: filters?.CuGroup?.partnerId || "",
      isFxCovered: filters?.isFxCovered,
      orderBy: "created_at",
      order: "desc",
      // page: page,
      // pagesize: 10,
    };
    setIsUsdInrLoading(true);
    DashboardReportServiceHP.getUsdINRReport(tokens, queryParams)
      .then((response: any) => {
        console.log(response, "response?.data?.data");
        setTablesData(response?.data?.data);
        setIsUsdInrLoading(false);
      })
      .catch((error) => {
        setTablesData({});
        setIsUsdInrLoading(false);
      });
  }, [selectedFilters]);

  const validateToDate = function (current: any) {
    return current.isSameOrAfter(filters?.fromDate, "day");
  };
  const tabClasses =
    "nav-link block border-r border-l  font-medium text-xs leading-tight uppercase border-collapse px-6 py-3  hover:border-transparent hover:bg-gray-100 focus:border-transparent ";

  return (
    <div>
      <div className="flex justify-between w-full border-b border-t items-center pl-2 mb-2">
        <div>
          <p className="text-lg font-bold">
            US$/₹{" "}
            <span className="text-sm font-normal">
              (
              {moment(new Date(filters?.fromDate)).format("DD-MM-YYYY hh:mm A")}{" "}
              To{" "}
              {moment(new Date(filters?.toDate)).format("DD-MM-YYYY hh:mm A")})
            </span>
          </p>
        </div>
        <button
          // style={{ pointerEvents: "unset" }}
          // className={`border border-purple-300 rounded-md background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150 ${!data.fileUploaded ? "cursor-not-allowed bg-gray-300" : "bg-purple-100 hover:bg-purple-950 hover:text-white"}`}
          className={`${tabClasses} w-fit outline-none`}
          type="button"
          // onClick={() => data.fileUploaded && downloadReport(data.activePartnerSettlementProcessId)}
          onClick={() => setExpanded(!expanded)}
        >
          <FilterIcon className="h-6" />
        </button>
      </div>
      {expanded && (
        <div className="flex flex-wrap -mx-3 mb-2 p-4 ">
          <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-state"
            >
              From Date
            </label>
            <div className="flex justify-between rounded-md appearance-none block w-full rounded py-2 px-4 border border-grey-darker">
              <Datetime
                // timeFormat={"hh:mm A"}
                dateFormat={"DD-MM-YYYY"}
                inputProps={{
                  readOnly: true,
                  placeholder: "dd/mm/yyyy",
                  className: "focus:outline-none",
                }}
                value={filters?.fromDate}
                onChange={(date: any) => {
                  setFilters({
                    ...filters,
                    fromDate: date,
                    toDate:
                      date.diff(filters.toDate) > 0 ? moment() : filters.toDate,
                  });
                }}
                isValidDate={validDateFromDate}
              />
              <CalendarIcon
                className="h-6"
                style={{
                  color: "rgb(204,204,204)",
                }}
              />
            </div>
          </div>
          <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-state"
            >
              To Date
            </label>
            <div className="flex justify-between rounded-md appearance-none block w-full rounded py-2 px-4 border border-grey-darker">
              <Datetime
                // isValidDate={valid}
                timeFormat={"hh:mm A"}
                dateFormat={"DD-MM-YYYY"}
                inputProps={{
                  readOnly: true,
                  placeholder: "dd/mm/yyyy",
                  className: "focus:outline-none",
                }}
                onChange={(date: any) => {
                  if (date.diff(filters.fromDate) > 0)
                    setFilters({
                      ...filters,
                      toDate: date,
                    });
                }}
                isValidDate={validateToDate}
                value={filters.toDate}
              />
              <CalendarIcon
                className="h-6 text-grey-darker"
                style={{
                  color: "rgb(204,204,204)",
                }}
              />
            </div>
          </div>
          <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="partner-name"
            >
              Item Code
            </label>
            <input
              className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
              name="partner-name"
              id="partner-name"
              type="text"
              placeholder="Item Code"
              autoComplete="none"
              onChange={(event) =>
                setFilters({
                  ...filters,
                  itemCode: event.target.value,
                })
              }
              value={filters.itemCode}
            />
          </div>
          {/* <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="partner-name"
          >
            Vault
          </label>

          <MultipleSelect
            optionList={vaults}
            onChangeItem={(value: any) => {
              setFilters({
                ...filters,
                vault: value,
              });
            }}
            selectedValue={filters?.vault}
            closeMenuOnSelect={true}
            isMulti={false}
            getOptionLabel={(option: any) => `${option.warehouseId}`}
            getOptionValue={(option: any) => `${option.id}`}
          />
        </div> */}
          <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="partner-name"
            >
              Partner Code
            </label>
            <MultipleSelect
              optionList={CustomerGroups}
              onChangeItem={(value: any) => {
                setFilters({
                  ...filters,
                  CuGroup: value,
                });
              }}
              selectedValue={filters?.CuGroup}
              closeMenuOnSelect={true}
              isMulti={false}
              getOptionLabel={(option: any) => `${option.name}`}
              getOptionValue={(option: any) => `${option.partnerId}`}
            />
          </div>
          <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="partner-name"
            >
              Status
            </label>
            <MultipleSelect
              optionList={transactionStatus}
              onChangeItem={(values: any) => {
                setFilters({
                  ...filters,
                  transactionStatus: values,
                });
              }}
              selectedValue={filters?.transactionStatus}
              closeMenuOnSelect={false}
              getOptionLabel={(option: any) => `${option.status}`}
              getOptionValue={(option: any) => `${option.id}`}
            />
          </div>
          <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="partner-name"
            >
              Fx Covered
            </label>
            <select
              name="coveredStatusHP"
              id="coveredStatusHP"
              className="form-select appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              onChange={(event: any) => {
                let isFxCovered = event.target.value;
                setFilters({
                  ...filters,
                  isFxCovered,
                });
              }}
            >
              {isCoveredStatus?.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.status}
                  </option>
                );
              })}
            </select>
          </div>
          {/* <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0"> */}
          {/* <div className="flex items-center">
          <input
            className="mx-3 mt-2"
            type="checkbox"
            onChange={(event: any) => {
              setFilters({
                ...filters,
                covered: !filters?.covered,
              });
            }}
            // value={filters?.covered }
            checked={filters?.covered}
          />
          <label className="pt-2"> Covered</label>
        </div> */}
          {/* </div> */}
          <br />
          <div className="w-full px-3 mb-6 md:mb-0">
            <div className="md:w-1/3" />
            <div className="md:w-2/3 my-6">
              <button
                className="px-4 py-2.5 font-bold text-white text-sm uppercase bg-purple-800 rounded"
                type="button"
                onClick={() => {
                  setPage(0);
                  setSelectedFilters({ ...filters });
                }}
              >
                Filter
              </button>
              <button
                className="ml-4 px-4 py-2.5 font-bold text-white text-sm uppercase bg-red-600 rounded"
                type="button"
                onClick={() => {
                  setPage(0);
                  resetForm();
                }}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      )}
      <TreasurySummaryReport
        data={tablesData}
        metalCode={""}
        metalName={""}
        fromDate={selectedFilters?.fromDate}
        toDate={selectedFilters?.toDate}
        downloadReport={() => {}}
        header={false}
        downloadBtn={false}
        tab="UsdInr"
      />
    </div>
  );
};

export default UsdInrTable;
