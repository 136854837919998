import React, { useState, useEffect } from "react";
import { useAuthStore } from "../../../Store/AuthStore";
import { useHistory } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import moment from "moment";
import CustomerService, {
  downloadSipReport,
} from "../Services/Customer.service";
import { Pagination } from "../../../Components-ui/Pagination";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";
import Datetime from "react-datetime";
import { makeQuery } from "../../../Common/util.helper";

const ListOfCustomers: React.FC = () => {
  // const [customers, setCustomers] = useState([]);
  const { isPartnerAdmin, partnerAdminId } = useAuthStore();

  const [currentPage, setCurrentPage] = useState<any>(0);
  const [Loading, setLoading] = useState<any>(true);
  const [selectedPartner, setSelectedPartner] = useState<any>({});
  const { logout, permissions, userData, tokens } = useAuthStore();
  const history = useHistory();
  const [customers, setCustomers] = useState<any>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<any>([]);
  const [partnerList, setPartnerList] = useState<any>([]);
  const [toggle, setToggle] = useState<any>("ENABLED");
  const [filters, setFilters] = useState<any>({
    partner: partnerAdminId,
    user_id: "",
    investorId:"",
    name: "",
    verifyStatus: "",
    status: "",
    mobile: "",
    fromDate: null,
    toDate: null,
  });
  const [metaData, setMetaData] = useState<any>({});
  const [updateStatus, setUpdateStatus] = useState<any>("");
  const [downloadQuery, setDownloadQuery] = useState<any>("");

  const searchParams = new URLSearchParams(window.location.search);
  const status = searchParams.get("status");
  const interval = searchParams.get("interval");
  const decodedStatus = status ? decodeURIComponent(status) : undefined;
  const decodedInterval: any = interval
    ? decodeURIComponent(interval)
    : undefined;

  const changeStatus = async (status: any, key: any, id: any) => {
    setUpdateStatus("STATUS");
    let newArr = [...customers];
    newArr[key].requiredStatus = "ENABLED" !== status ? "ENABLED" : "DISABLED";
    setSelectedPartner(newArr[key]);
    setCustomers(newArr);
    setSelectedCustomers(newArr[key].customerId);
    updateCustomer(newArr[key].customerId, newArr[key]);
    // updateMultiRecords(('ENABLED' !== status) ? 'ENABLED' : 'DISABLED',[newArr[key].customerId]);
  };

  const updateCustomer = async (id: any, data: any) => {
    CustomerService.updateCustomer(id, data, tokens)
      .then((response: any) => {
        setSelectedCustomers([]);
        setUpdateStatus("");
        setLoading(false);
        get_customer_list(filters, currentPage);
        toast.success("Updated Successfully");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const updateCustomerStatus = async (data: any) => {
    CustomerService.updateCustomerStatus(data, tokens)
      .then((response: any) => {
        setSelectedCustomers([]);
        setUpdateStatus("");
        setLoading(false);
        get_customer_list(filters, currentPage);
        toast.success("Updated Successfully");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const get_partner_list = async () => {
    await CustomerService.getPartnerList(tokens)
      .then((response: any) => {
        setPartnerList(response.data.data);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const get_customer_list = async (filters_: any, page: any) => {
    await CustomerService.getCustomerList(filters_, tokens, page)
      .then((response: any) => {
        setLoading(false);
        setCustomers(response.data.data);
        customers.map((customer: any, index: number) => {
          setToggle({
            ...toggle,
            key: index,
            active: customer.active,
          });
        });
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPage(page);
        // setMetaData(response.data.meta);
      })
      .catch((err) => {
        setLoading(false);
        setMetaData({});
        toast.error(err);
      });
  };

  const getSelectedRecords = async (data: any, i: any) => {
    let newArr = [...customers];
    newArr[i].selected = !data.selected;
    setCustomers(newArr);
    if (0 !== selectedCustomers.length) {
      if (selectedCustomers.some((item: any) => item === data.customerId)) {
        selectedCustomers.forEach((element: any, index: number) => {
          if (data.customerId === element) {
            selectedCustomers.splice(index, 1);
          }
          setSelectedCustomers(selectedCustomers);
        });
      } else {
        setSelectedCustomers(selectedCustomers.concat(data.customerId));
      }
    } else {
      setSelectedCustomers(selectedCustomers.concat(data.customerId));
    }
  };

  const resetForm = async () => {
    setLoading(true);
    setFilters({
      partner: partnerAdminId,
      user_id: "",
      investorId:"",
      customer_id: "",
      name: "",
      gst: 0,
      verifyStatus: "",
      status: "",
      mobile: "",
    });
    setDownloadQuery('')
    get_customer_list(
      {
        partner: partnerAdminId,
        user_id: "",
        customer_id: "",
        investorId:"",
        name: "",
        mobile: "",
        gst: 0,
      },
      0
    );
  };
  const downloadOrderReport = async () => {
    setLoading(true);
    await downloadSipReport(downloadQuery, tokens)
      .then((res: any) => {
        if (res.status === 200 && res.data) {
          setLoading(false);
          // saveAs(res.data.data.fileUrl, `order_log_report`);
          window.location.assign(res.data.data.fileUrl);
          // window.open(res.data.data.fileUrl);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Something Went Wrong!");
        setLoading(false);
      });

    setLoading(false);
  };
  const filter = async (page: number = 0, forcedfilter: any = null) => {
    setLoading(true);
    if (searchParams.has("status")) {
      let filterss: any;
      if (decodedStatus == "B2B_USERS") {
        filterss = {
          ...filters,
          customerCategory: "REGISTERED",
          fromDate:
            decodedInterval < 1
              ? ""
              : moment().subtract(decodedInterval, "d").format("YYYY-MM-DD"),
          toDate: decodedInterval < 1 ? "" : moment().format("YYYY-MM-DD"),
          flag: decodedInterval == 1 ? true : false,
        };
      } else if (decodedStatus == "B2C_USERS") {
        filterss = {
          ...filters,
          customerCategory: "UNREGISTERED",
          fromDate:
            decodedInterval < 1
              ? ""
              : moment().subtract(decodedInterval, "d").format("YYYY-MM-DD"),
          toDate: decodedInterval < 1 ? "" : moment().format("YYYY-MM-DD"),
          flag: decodedInterval == 1 ? true : false,
        };
      } else if (decodedStatus == "ACTIVE_USERS") {
        filterss = {
          ...filters,
          verifyStatus: "ENABLED",
          status: "ENABLED",
          fromDate:
            decodedInterval < 1
              ? ""
              : moment().subtract(decodedInterval, "d").format("YYYY-MM-DD"),
          toDate: decodedInterval < 1 ? "" : moment().format("YYYY-MM-DD"),
          flag: decodedInterval == 1 ? true : false,
        };
      } else {
        filterss = {
          ...filters,
          customerCategory: "",
          verifyStatus: "",
          status: "",
        };
      }
      await CustomerService.getCustomerList(filterss, tokens, page)
        .then((response: any) => {
          setCustomers(response.data.data);
          setLoading(false);
          setCurrentPage(page);
          get_customer_list(filterss, page);
          get_partner_list();
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    } else {
      await CustomerService.getCustomerList(filters, tokens, page)
        .then((response: any) => {
          setCustomers(response.data.data);
          setLoading(false);
          setCurrentPage(page);
          get_customer_list(filters, page);
          // const query = makeQuery(filters);
          setDownloadQuery(filters);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    }
  };

  useEffect(() => {
    if (searchParams.has("status")) {
      filterFunction();
      filter(0);
      // get_customer_list(filters, 0);
    } else {
      get_customer_list(filters, 0);
      get_partner_list();
     
      // const query = makeQuery(filters);
      setDownloadQuery(filters);
    }
  }, []);

  const updateMultiRecords = (status: any, arr: any) => {
    var data = {
      enable: status,
      customers: arr,
    };
    updateCustomerStatus(data);
  };

  const filterFunction = () => {
    if (decodedStatus == "B2B_USERS") {
      setFilters({
        ...filters,
        customerCategory: "REGISTERED",
        fromDate:
          decodedInterval < 1
            ? ""
            : moment().subtract(decodedInterval, "d").format("YYYY-MM-DD"),
        toDate: decodedInterval < 1 ? "" : moment().format("YYYY-MM-DD"),
      });
    } else if (decodedStatus == "B2C_USERS") {
      setFilters({
        ...filters,
        customerCategory: "UNREGISTERED",
        fromDate:
          decodedInterval < 1
            ? ""
            : moment().subtract(decodedInterval, "d").format("YYYY-MM-DD"),
        toDate: decodedInterval < 1 ? "" : moment().format("YYYY-MM-DD"),
      });
    } else if (decodedStatus == "ACTIVE_USERS") {
      setFilters({
        ...filters,
        verifyStatus: "ENABLED",
        status: "ENABLED",
        fromDate:
          decodedInterval < 1
            ? ""
            : moment().subtract(decodedInterval, "d").format("YYYY-MM-DD"),
        toDate: decodedInterval < 1 ? "" : moment().format("YYYY-MM-DD"),
      });
    } else {
      setFilters({
        ...filters,
        customerCategory: "",
        verifyStatus: "",
        status: "",
      });
    }
  };
  return (
    <Layout type={"UserModule"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">{"Customers List"}</h1>
      </div>
      {Loading ? (
        <Loader />
      ) : (
        <div className="border bg-white rounded border-gray-200 shadow">
          <div className="flex-1 p-2">
            <form
              onSubmit={(e) => {
                filter();
                e.preventDefault();
              }}
            >
              <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="grid-customer-id"
                  >
                    Customer ID
                  </label>
                  <input
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={(event) =>
                      setFilters({
                        ...filters,
                        customer_id: event.target.value,
                      })
                    }
                    value={filters.customer_id}
                    id="grid-customer-id"
                    type="text"
                    placeholder="Customer ID"
                    autoComplete="none"
                  />
                </div>
                <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="grid-customer-id"
                  >
                    Investor ID
                  </label>
                  <input
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={(event) =>
                      setFilters({
                        ...filters,
                        investorId: event.target.value,
                      })
                    }
                    value={filters.investorId}
                    id="grid-customer-id"
                    type="text"
                    placeholder="Investor ID"
                    autoComplete="none"
                  />
                </div>
                <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="grid-customer-id"
                  >
                    Name
                  </label>
                  <input
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={(event) =>
                      setFilters({
                        ...filters,
                        name: event.target.value,
                      })
                    }
                    value={filters.name}
                    id="grid-customer-id"
                    type="text"
                    placeholder="Name"
                    autoComplete="none"
                  />
                </div>
                <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="grid-user-id"
                  >
                    User ID
                  </label>
                  <input
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={(event) =>
                      setFilters({
                        ...filters,
                        user_id: event.target.value,
                      })
                    }
                    value={filters.user_id}
                    id="grid-user-id"
                    type="text"
                    placeholder="User ID"
                    autoComplete="none"
                  />
                </div>
                <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="grid-gst"
                  >
                    Category
                  </label>
                  <div className="relative">
                    <select
                      className={`mt-1 block w-full py-2 px-3 border border-gray-300 
                      ${
                        decodedStatus == "B2B_USERS" ||
                        decodedStatus == "B2C_USERS"
                          ? "bg-gray-200"
                          : "bg-white"
                      }
                      rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                      id="grid-gst"
                      onChange={(event) =>
                        setFilters({
                          ...filters,
                          customerCategory: event.target.value,
                        })
                      }
                      value={filters.customerCategory}
                      disabled={
                        decodedStatus == "B2B_USERS" ||
                        decodedStatus == "B2C_USERS"
                          ? true
                          : false
                      }
                    >
                      <option value="">Select a category</option>
                      <option value="REGISTERED">REGISTERED</option>
                      <option value="UNREGISTERED">UNREGISTERED</option>
                    </select>
                  </div>
                </div>
                <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="grid-gst"
                  >
                    Source
                  </label>
                  <div className="relative">
                    <select
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="grid-gst"
                      onChange={(event) =>
                        setFilters({
                          ...filters,
                          source: event.target.value,
                        })
                      }
                      value={filters.source}
                    >
                      <option value="">Select a Source</option>
                      <option value="APP">APP</option>
                      <option value="API">API</option>
                      <option value="WEB">WEB</option>
                    </select>
                  </div>
                </div>
                <div className="w-full mt-1 md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="grid-partner"
                  >
                    Partner
                  </label>
                  <div className="relative">
                    <select
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="grid-partner"
                      onChange={(event) =>
                        setFilters({
                          ...filters,
                          partner: event.target.value,
                        })
                      }
                      value={filters.partner}
                      disabled={isPartnerAdmin}
                    >
                      <option value="">Select a Partner</option>
                      {partnerList.map((partner: any) => (
                        <option value={partner.partnerId}>
                          {partner.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="w-full mt-1 md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="grid-gst"
                  >
                    Type
                  </label>
                  <div className="relative">
                    <select
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="grid-gst"
                      onChange={(event) =>
                        setFilters({
                          ...filters,
                          gst: event.target.value,
                        })
                      }
                      value={filters.gst}
                    >
                      <option value="">Select an Type</option>
                      <option value="IND">IND</option>
                      <option value="HUF">HUF</option>
                      <option value="FIRM">FIRM</option>
                      <option value="LLP">LLP</option>
                      <option value="CO">CO</option>
                      <option value="TRUST">TRUST</option>
                      <option value="SOLE">SOLE</option>
                    </select>
                  </div>
                </div>
                <div className="w-full mt-1 md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="grid-gst"
                  >
                    Verify Status
                  </label>
                  <div className="relative">
                    <select
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="grid-gst"
                      onChange={(event) =>
                        setFilters({
                          ...filters,
                          verifyStatus: event.target.value,
                        })
                      }
                      value={filters.verifyStatus}
                    >
                      <option value="">Select a status</option>
                      <option value="ENABLED">ENABLED</option>
                      <option value="DISABLED">DISABLED</option>
                    </select>
                  </div>
                </div>
                <div className="w-full mt-1 md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="grid-gst"
                  >
                    Status
                  </label>
                  <div className="relative">
                    <select
                      className={`mt-1 block w-full py-2 px-3 border border-gray-300 ${
                        decodedStatus == "ACTIVE_USERS"
                          ? "bg-gray-200"
                          : "bg-white"
                      } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                      id="grid-gst"
                      onChange={(event) =>
                        setFilters({
                          ...filters,
                          status: event.target.value,
                        })
                      }
                      value={filters.status}
                    >
                      <option value="">Select a status</option>
                      <option value="ENABLED">ENABLED</option>
                      <option value="DISABLED">DISABLED</option>
                    </select>
                  </div>
                </div>
                <div className="w-full mt-1 md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="grid-user-id"
                  >
                    Mobile No
                  </label>
                  <input
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={(event) =>
                      setFilters({
                        ...filters,
                        mobile: event.target.value,
                      })
                    }
                    value={filters.mobile}
                    id="grid-user-id"
                    type="number"
                    placeholder="Mobile Number"
                    autoComplete="none"
                  />
                </div>
                <div className="w-full mt-1 md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="status"
                  >
                    From Date
                  </label>
                  <div className="relative">
                    <Datetime
                      timeFormat={""}
                      dateFormat={"YYYY-MM-DD"}
                      inputProps={{
                        readOnly: true,
                        placeholder: "",
                        className: "focus:outline-none",
                        disabled: interval ? true : false,
                      }}
                      className={`rdt mt-1 block w-full py-2 px-3 border border-gray-300 
                      ${interval ? "bg-gray-200" : "bg-white"}
                      bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm         
                      `}
                      onChange={(e: any) => {
                        setFilters({
                          ...filters,
                          fromDate: moment(new Date(e).toISOString()).format(
                            "YYYY-MM-DD"
                          ),
                          toDate: e.isAfter(new Date(filters.toDate))
                            ? moment(new Date(e).toISOString()).format(
                                "YYYY-MM-DD"
                              )
                            : moment(filters.toDate).format("YYYY-MM-DD"),
                        });
                      }}
                      value={filters.fromDate}
                      renderInput={(props) => {
                        return (
                          <input
                            style={{ width: "100%" }}
                            {...props}
                            value={filters.fromDate ? filters.fromDate : ""}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="w-full mt-1 md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="status"
                  >
                    To Date
                  </label>
                  <div className="relative">
                    <Datetime
                      // isValidDate={valid}
                      timeFormat={""}
                      dateFormat={"YYYY-MM-DD"}
                      inputProps={{
                        readOnly: true,
                        placeholder: "",
                        className: "focus:outline-none",
                        disabled: interval ? true : false,
                      }}
                      className={`rdt mt-1 block w-full py-2 px-3 border border-gray-300 ${
                        interval ? "bg-gray-200" : "bg-white"
                      } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
             `}
                      onChange={(e: any) => {
                        setFilters({
                          ...filters,
                          toDate: moment(new Date(e).toISOString()).format(
                            "YYYY-MM-DD"
                          ),
                        });
                      }}
                      value={filters.toDate}
                      renderInput={(props) => {
                        return (
                          <input
                            style={{ width: "100%" }}
                            {...props}
                            value={filters.toDate ? filters.toDate : ""}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="w-full px-3 mb-6 md:mb-0">
                  <div className="md:w-1/3" />
                  <div className="md:w-3/3 my-6">
                    {/* <button
                                        className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                                        type="button" onClick={() => filter()}>
                                        Filter
                                    </button> */}
                    <input
                      className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                      type="submit"
                      value="Filter"
                    />
                    {decodedInterval ? (
                      ""
                    ) : (
                      <button
                        className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => resetForm()}
                      >
                        Reset
                      </button>
                    )}

                    <button
                      className="ml-2  px-4 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                      // className="px-4 py-1.5 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none ml-2  ease-linear transition-all duration-150"
                      type="button"
                      onClick={(e) => downloadOrderReport()}
                    >
                      Download Report
                    </button>
                    {/* <button
                      className="ml-2  px-4 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                      // className="px-4 py-1.5 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none ml-2  ease-linear transition-all duration-150"
                      type="button"
                      onClick={(e) => downloadOrderReport()}
                    >
                      Download Report
                    </button> */}
                  </div>
                </div>
              </div>
            </form>
          </div>
          {/*user Table*/}
          <div className="border bg-white rounded border-gray-200 shadow m-2">
          <div className="overflow-x-auto">
            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
              <thead>
                <tr className="text-left border-b border-gray-300">
                  {HasAnyAccess(["customer.list.edit"]) && (
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10"></th>
                  )}
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Customer id
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Investor id
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Name
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Mobile No
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Category
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Type
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Source By
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Partner
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                  Investor Business Name
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Created At
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Status
                  </th>
                  {HasAnyAccess(["customer.list.edit"]) && (
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Verify Status
                    </th>
                  )}
                  {HasAnyAccess(["customer.list.view"]) && (
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Action
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {customers.length > 0 ? (
                  customers.map((customer: any, index: number) => {
                    return (
                      <tr
                        key={index}
                        className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                      >
                        {HasAnyAccess(["customer.list.edit"]) && (
                          <td className="py-3 px-3 text-left relative">
                            <input
                              type="checkbox"
                              name="required"
                              id="required"
                              checked={customer.selected}
                              value={customer.selected}
                              onChange={(e) => {
                                getSelectedRecords(customer, index);
                              }}
                            />
                          </td>
                        )}
                        <td className="py-3 px-3 text-left relative">
                          {customer.customerId}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {customer.investorId}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {customer.displayName}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {customer.kyc.mobile.value}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {customer.customerCategory}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {customer.customerType}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {customer.source}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {customer.partner.name}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {customer.investorBusinessName}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {moment(customer.createdAt).format(
                            "DD-MM-YYYY HH:mm"
                          )}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          <span
                            className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${
                              "ENABLED" === customer.active
                                ? "text-green-600 bg-green-200"
                                : "text-black-600 bg-gray-400"
                            }`}
                          >
                            {customer.active}
                          </span>
                        </td>
                        {HasAnyAccess(["customer.list.edit"]) && (
                          <td className="py-3 px-3 text-left relative">
                            <div
                              className="flex justify-between items-center"
                              onClick={() => {
                                changeStatus(
                                  customer.requiredStatus,
                                  index,
                                  customer.customerId
                                );
                              }}
                            >
                              <div
                                className={`w-14 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out ${
                                  "ENABLED" === customer.requiredStatus
                                    ? "bg-green-400"
                                    : ""
                                }`}
                              >
                                <div
                                  className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${
                                    "ENABLED" === customer.requiredStatus
                                      ? "translate-x-6"
                                      : ""
                                  }`}
                                ></div>
                              </div>
                            </div>
                          </td>
                        )}
                        {HasAnyAccess(["customer.list.view"]) && (
                          <td className="py-3 px-3 text-left relative">
                            <button
                              className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                              type="button"
                              onClick={() =>
                                history.push(
                                  `/customers/${customer.customerId}`
                                )
                              }
                            >
                              View
                            </button>
                          </td>
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <tr className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                    <td className="py-3 px-3 text-center relative" colSpan={11}>
                      No Data To Display
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            </div>
            {/* Pagination Bar */}
            <Pagination
              meta={metaData}
              handlePageChange={(number: number) => {
                filter(number);
              }}
            />
          </div>
          <div className="flex items-center justify-end sm:py-3  sm:px-6 rounded-b">
            {HasAnyAccess(["customer.list.edit"]) && (
              <>
                <div>
                  <button
                    className="background-transparent bg-purple-100 border border-purple-300 font-bold px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                    type="button"
                    onClick={() => updateMultiRecords(true, selectedCustomers)}
                  >
                    Enable
                  </button>
                </div>
                <div>
                  <button
                    className="ml-2 text-white bg-purple-950 background-transparent font-bold px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                    type="button"
                    onClick={() => updateMultiRecords(false, selectedCustomers)}
                  >
                    Disable
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default ListOfCustomers;
