import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import MetalCreate from "./MetalCreate";
import MetalTable from "./MetalTable";
import ViewMetalTable from "./ViewMetalTable";
import DailyBarList from "./BarList";
import DailyBarListInGrams from "./BarListGrams";
import SummeryReport from "./BarListSummeryReport";
import WarehouseStock from "./Warehouse";
import ReceiptMaster from "./ReceiptMaster";
import StockAllocation from "./StockAllocation";
import MasterTable from "./MasterTable";
import EbMaster from "./EbMaster";
import EbMasterSync from "./EbMasterSync";
import SingleVIewEbMaster from "./SingleVIewEbMaster";
import EbMasterTable from "./EbMaster";
import CurrencyMasterTable from "./CurrencyMaster";
import HsnMaster from "./HsnTable";
import ItemMaster from "./ItemMasterTable";
import VsoCalendar from "./vsoCalendar/VsoCalendar";
import WearHouseMaster from "./WearHouseMasterTable";
import WarehouseMaster from "./WearHouseMasterTable";
import TaxCategoryLookUp from "./TaxCategoryLookUp";
// import FeederPopup from "./FeederPopup";
import VSOReceipting from "./VsoReceipting/VSORecepting";
import BarSummary from "../BarSummary/BarSummary";
import VsoDashboard from "./VsoDashboard/VsoDashboard";

const PricingRoutes: React.FC = () => {
  return (
    <Fragment>
      <Route path="/metal-master/view/:id" exact component={ViewMetalTable} />
      <Route path="/metal-master/edit/:id" exact component={MetalTable} />
      <Route
        path="/metal-allocation/view/:id"
        exact
        component={StockAllocation}
      />
      <Route path="/metal-master" exact component={MetalCreate} />
      <Route path="/currency-master" exact component={CurrencyMasterTable} />
      <Route path="/barlist/daily" exact component={DailyBarList} />
      <Route path="/barlist/summary" exact component={DailyBarListInGrams} />
      <Route path="/barlist/dashboard" exact component={VsoDashboard} />
      <Route path="/barlist/summary/reports" exact component={SummeryReport} />
      <Route path="/bar-summary" exact component={BarSummary} />
      <Route
        path="/warehouse-stock/movement"
        exact
        component={WarehouseStock}
      />
      <Route path="/master/tables" exact component={MasterTable} />
      <Route path="/master/ebmaster" exact component={EbMasterTable} />
      <Route
        path="/master/ebmaster/view/:id"
        exact
        component={SingleVIewEbMaster}
      />
      <Route path="/master/ebmastersync" exact component={EbMasterSync} />
      {/* <Route path="/Metal-Allocation" exact component={MetalAllocation} /> */}
      <Route path="/Metal-Calendar" exact component={ReceiptMaster} />
      <Route path="/metal-receipting" exact component={VSOReceipting} />
      <Route path="/vso-calendar" exact component={VsoCalendar} />
      <Route path="/hsn-master" exact component={HsnMaster} />
      <Route path="/item-master" exact component={ItemMaster} />
      <Route path="/warehouse-master" exact component={WarehouseMaster} />
      <Route path="/tax-category-lookup" exact component={TaxCategoryLookUp} />
      {/* <Route path="/Reference-details" exact component={FeederPopup} /> */}
    </Fragment>
  );
};

export default PricingRoutes;
