// import MasterRuleSettings from "./MasterRuleSettings";
import React, { memo, useEffect, useState } from "react";


interface PropsType {
    //   data_set: any;
    // askPrice: number;
    // bitPrice: number;
    propsValues: any;
    getRules: any;
    currentIndex: number;
}

const DropownListCustom: React.FC<PropsType> = ({ propsValues, getRules, currentIndex }) => {
    const [values, setValues] = useState<any>([]);
 
    useEffect((): any => {
        setValues(propsValues);
      }, [propsValues]);

    const addClickedRule = (rule: string) => {
        getRules(rule, currentIndex);
    }

    return (
            <div className=''>
               {
                   values && values.map((value: any)=>{
                       return(<div onClick={(e) => addClickedRule(value.ruleName)}>{value.ruleName}</div>)
                   })
               }
            </div>
    );
};

// export default DropownListCustom;
export default memo(DropownListCustom);