import { useEffect, useState,  } from "react";
import Layout from "../Layout/Layout";
import { useHistory, useParams} from "react-router-dom";
import moment from "moment";
import { GetMetalAllocationByID } from './Metal.Service'

const  WarehouseStock: React.FC = () => {

  let history = useHistory();
  const [data , setData] = useState<any>([]);
  const [loading , setLoading] = useState<boolean>(true);
  let { id } = useParams<any>();

    useEffect( () => {
      fetchdata();
    },[])
  
    const fetchdata = () => {
    GetMetalAllocationByID(id)
      .then((data: any) => {
        setData(data.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err)
      })
    }

    const roundNo = (amount: string) => {
      return Number(amount).toFixed(2);
    }
   
   
    return (
    <Layout type={"VaultingModule"}>
       <div className="flex" >
       <button onClick={() =>  history.push(`/barlist/daily`)}
      className="inline-flex justify-center p-1 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
       <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
       <path fillRule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
       </svg>
      </button>
     <h5 className=" font-bold tracking-tight text-gray-600 sm:text-xl">
       Metal Allocation - {id} 
     </h5>	&nbsp;	&nbsp;	&nbsp;	&nbsp;
     </div>
      <div className="overflow-x-auto">
        <div className="w-full">
        <div className=" shadow-md rounded-lg my-6">
        <table className="w-full table-auto rounded-lg text-sm">
            <thead>

              <tr className="sticky top-1 px-1 py-3 text-green-900 bg-purple-300">
                <th className="py-2 px-1 text-left">Allocation Reference</th>
                <th className="py-2 px-1 text-left">Transaction Reference</th>
                <th className="py-2 px-1 text-left">Transaction Quantity</th>
                <th className="py-2 px-1 text-left">Date</th>
                <th className="py-2 px-1 text-left">TR Type</th>
                 <th className="py-2 px-1 text-left w-10"></th>
              </tr>

            </thead>
            <tbody className="text-gray-800 text-sm ">
              {data && data.map((row:any,i:number) => (
              <tr className="border-b border-gray-200 bg-white  hover:bg-gray-50">
                <td className="py-3 px-1 text-left">
                {
                  row.Allocation_reference 
                  ? <span className="font-medium text-sm">{row.Allocation_reference}</span>
                  : <span className="font-medium text-sm px-1 rounded bg-gray-100 text-gray-500">Empty</span>
                }   
                </td>
                <td className="py-3 px-1 text-left">
                {
                  row.Transaction_Reference 
                  ? <span className="font-medium text-sm">{row.Transaction_Reference}</span>
                  : <span className="font-medium text-sm px-1 rounded bg-gray-100 text-gray-500">Empty</span>
                }   
                </td>
                <td className="py-3 px-1 text-left">
                {
                  row.Trn_Qty 
                  ? <span className="font-medium text-sm">{roundNo(row.Trn_Qty)}</span>
                  : <span className="font-medium text-sm px-1 rounded bg-gray-100 text-gray-500">Empty</span>
                }   
                </td>
                <td className="py-3 px-1 text-left">
                {
                  row.Trn_date 
                  ? <span className="font-medium text-sm">{moment(row.Trn_date).format('DD-MMMM-YYYY')}</span>
                  : <span className="font-medium text-sm px-1 rounded bg-gray-100 text-gray-500">Empty</span>
                }   
                </td>
                <td className="py-3 px-1 text-left">
                {
                  row.Trn_type 
                  ? <span className="font-medium text-sm">{row.Trn_type}</span>
                  : <span className="font-medium text-sm px-1 rounded bg-gray-100 text-gray-500">Empty</span>
                }   
                </td>
                <td className="cursor-pointer p-0 text-center bg-purple-200">    
                    <span onClick={() => alert("Transaction details pages Working in progress...")} className="font-semibold text-gray-900">{'>>'}</span>    
                </td>
              </tr>))}
            </tbody>
          </table>
        </div>
        </div>
      </div>
    </Layout>
    );
    };

  
    export default WarehouseStock;