import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import Pricing from "./Pricing";
import PricingTable from "./PricingTable";
import Benchmark from "./Benchmark/benchmark";

const PricingRoutes: React.FC = () => {
  return (
    <Fragment>
      <Route path="/pricing/:id" exact component={Pricing} />
      <Route path="/pricing" exact component={PricingTable} />
      <Route path="/locopremium" exact component={PricingTable} />
      <Route path="/benchmark" exact component={Benchmark} />
    </Fragment>
  );
};

export default PricingRoutes;
