import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { useParams } from "react-router-dom";
import OrderService from "../Services/order.service";
import { useAuthStore } from "../../../Store/AuthStore";
import moment from "moment";
import Loader from "../../../Components-ui/Loader";
import DataInput from "../Components/DataInput";
import InvoiceBatch from "../Components/InvoiceBatch";
import orderService from "../Services/order.service";
import { toast, ToastContainer } from "react-toastify";

interface PropsType {}

const Invoices: React.FC<PropsType> = () => {
  const { tokens } = useAuthStore();

  let { id } = useParams<any>();

  const [Loading, setLoading] = useState<any>(true);
  const [OrderData, setOrderData] = useState<any>({});
  const [Invocies, setInvoices] = useState<any>([]);
  const [salesInvoices, setSalesInvoices] = useState<any>([]);
  const [viewPrevBatches, setViewPrevBatches] = useState<any>(false);

  useEffect((): any => {
    getOrderDetails();
    getOrderInvoices();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {}, [salesInvoices]);
  /**
   * Get order details by id
   */
  const getOrderDetails = async () => {
    let data: any = await OrderService.getOrderDetails(id, tokens);
    if (data.status === 200 && data.data.data) {
      let responseData = data.data.data;
      setOrderData(responseData);
      setSalesInvoices(responseData.invoiceIds);
      // console.log(responseData, "responseData")
      // setLoading(false)
    }
    setLoading(false);
  };

  const getOrderInvoices = async () => {
    let data: any = await OrderService.getOrderInvoices(id);
    if (data.status === 200 && data.data.data) {
      let responseData = data.data.data;
      setInvoices(responseData);
      // setLoading(false)
    }
    setLoading(false);
  };

  const refreshPage = () => {
    getOrderDetails();
    getOrderInvoices();
  };

  const approveBankTransferFunc = async (ordeRef: any, status: any) => {
    try {
      console.log(ordeRef, "ordeRef");
      const data = {
        orderRef: ordeRef,
        status: status,
      };
      setLoading(true);
      let res: any = await OrderService.approveBankTransfer(data, tokens);
      if (res?.data?.data?.status == "ACCEPTED") {
        setLoading(false);
        getOrderDetails();
        toast.success("Accepted successfully!");
      } else if (res?.data?.data?.status == "REJECTED") {
        setLoading(false);
        getOrderDetails();

        toast.success("Rejected successfully!");
      } else {
        setLoading(false);
        getOrderDetails();

        toast.error("Something Went Wrong");
      }
      console.log("banktransfer res", res);
    } catch (err) {
      setLoading(false);

      console.log(err);
      // toast.error("Something went wrong");
    }
  };

  return (
    <Layout type={"TradeExecution"}>
      {Loading ? (
        <Loader />
      ) : (
        <div className="w-full rounded border-gray-200 overflow-hidden shadow-lg dark:border-gray-700 bg-white">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Order Details - ({id})
            </h3>
          </div>
          <hr />
          <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
            <div className="col-span-12 sm:col-span-4">
              <DataInput
                label="Order Ref"
                value={OrderData?.ebOrderRef}
                id="orderRef"
              />
            </div>
            <div className="col-span-12 sm:col-span-4">
              <DataInput
                label="Quote Ref"
                value={OrderData?.ebQuoteRef}
                id="quoteRef"
              />
            </div>
            <div className="col-span-12 sm:col-span-4">
              <DataInput
                label="Currency"
                value={OrderData?.currency}
                id="currency"
              />
            </div>
            <div className="col-span-12 sm:col-span-4">
              <DataInput label="Type" value={OrderData.type} id="type" />
            </div>

            <div className="col-span-12 sm:col-span-4">
              <DataInput
                label="Quotation Total"
                value={OrderData?.quotationTotal}
                id="quotationTotal"
              />
            </div>
            <div className="col-span-12 sm:col-span-4">
              <DataInput
                label="Created Date"
                value={moment(OrderData.createdAtUnix).format(
                  "DD-MM-YYYY HH:mm"
                )}
                id="createdAt"
              />
            </div>

            <div className="col-span-12 sm:col-span-4">
              <DataInput
                label="Quoted Date"
                value={moment(OrderData?.quotedAt).format("DD-MM-YYYY HH:mm")}
                id="quotedAt"
              />
            </div>
            <div className="col-span-12 sm:col-span-4">
              <DataInput
                label="Expired Date"
                value={moment(OrderData.validUntil).format("DD-MM-YYYY HH:mm")}
                id="validUntil"
              />
            </div>
            <div className="col-span-12 sm:col-span-4">
              <DataInput label="Status" value={OrderData.status} id="status" />
            </div>

            <div className="col-span-12 sm:col-span-12">
              <div className="py-2">
                <h4 className="text-md leading-6 font-medium text-gray-500 border-b">
                  Customer Details
                </h4>
              </div>
              <div className="pt-3 grid grid-cols-12 gap-5">
                <div className="col-span-12 sm:col-span-4">
                  <DataInput
                    label="Name"
                    value={OrderData.customer?.displayName}
                    id="name"
                  />
                </div>
                <div className="col-span-12 sm:col-span-4">
                  <DataInput
                    label="Type"
                    value={OrderData.customer?.customerType}
                    id="customerType"
                  />
                </div>
                <div className="col-span-12 sm:col-span-4">
                  <DataInput
                    label="Category"
                    value={OrderData.customer?.customerCategory}
                    id="category"
                  />
                </div>
                <div className="col-span-12 sm:col-span-4">
                  <DataInput
                    label="Id"
                    value={OrderData.customer?.customerId}
                    id="customerId"
                  />
                </div>
                <div className="col-span-12 sm:col-span-4">
                  <DataInput
                    label="Account"
                    value={OrderData.accountId}
                    id="accountId"
                  />
                </div>
              </div>
              {OrderData?.status == "BANK_TRANSFER_APPROVAL_PENDING" && (
                <div>
                  <button
                    type="button"
                    className="mt-4 mr-2 px-2 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => {
                      approveBankTransferFunc(OrderData.ebOrderRef, "ACCEPT");
                    }}
                  >
                    Approve
                  </button>
                  <button
                    type="button"
                    className="mr-2 px-2 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => {
                      approveBankTransferFunc(OrderData.ebOrderRef, "REJECT");
                    }}
                  >
                    Reject
                  </button>
                </div>
              )}
            </div>

            {Invocies.length > 0 && (
              <div className="col-span-12 sm:col-span-12">
                <div className="py-2">
                  <h4 className="text-md leading-6 font-medium text-gray-500 border-b">
                    Invoices
                  </h4>
                </div>

                {OrderData.invoiceDate && OrderData.invoiceNo && (
                  <div className="pt-3 grid grid-cols-12 gap-5">
                    <div className="col-span-12 sm:col-span-4">
                      <DataInput
                        label="Invoice Date"
                        value={moment(OrderData.invoiceDate).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                        id="invoiceDate"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-4">
                      <DataInput
                        label="Invoice No"
                        value={OrderData.invoiceNo}
                        id="invoiceNo"
                      />
                    </div>
                  </div>
                )}

                {Invocies.find((batch: any) => batch.status === "PENDING")?.docs
                  ?.length == 0 && (
                  <p className="text-sm leading-6 font-medium text-gray-500">
                    No Pending Invoices
                  </p>
                )}

                {Invocies.map((invoice: any, key: number) => {
                  return (
                    <InvoiceBatch
                      key={key}
                      batch={invoice}
                      order={OrderData}
                      refreshPage={refreshPage}
                      prev={false}
                    />
                  );
                })}
                {Invocies.length > 1 && (
                  <div className="col-span-12 sm:col-span-12 cursor-pointer pt-5">
                    <a
                      className="underline text-blue-400"
                      onClick={() => setViewPrevBatches(!viewPrevBatches)}
                    >
                      {viewPrevBatches ? "Hide" : "View"} Previous batches
                    </a>
                  </div>
                )}

                {viewPrevBatches &&
                  Invocies.map((invoice: any, key: number) => {
                    return (
                      <InvoiceBatch
                        key={key}
                        batch={invoice}
                        order={OrderData}
                        refreshPage={refreshPage}
                        prev={true}
                      />
                    );
                  })}
              </div>
            )}
            {salesInvoices.length > 0 && (
              <div className="col-span-12 sm:col-span-12">
                <div className="py-2">
                  <h4 className="text-md leading-6 font-medium text-gray-500 border-b">
                    Invoices
                  </h4>
                </div>
                {salesInvoices.map((invoice: any, key: number) => {
                  return (
                    <div>
                      {/* <p>wijeeee : {invoice}</p> */}
                      <InvoiceBatch
                        key={key}
                        batch={[]}
                        salesPdfs={invoice}
                        order={OrderData}
                        refreshPage={refreshPage}
                        prev={true}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Invoices;
