import React, { useEffect, useState } from "react";
import LoginLayout from "../../Layout/LoginLayout";
import { Form } from "../../../Components-ui/Form";
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import { UserPool } from "../../../Services/UserPool";
import { LoginAlert } from "../../../Components-ui/LoginAlart";
import { useAuthStore } from "../../../Store/AuthStore";
import { useHistory } from "react-router-dom";
import {
  getUserDetails,
  patchUser,
} from "../../Userlist/Services/UserList.Service";
import Modal from "../../../Components-ui/Modal/Modal";
// @ts-ignore
import OtpInput from "react-otp-input";
import { toast, ToastContainer } from "react-toastify";
import { trueDependencies } from "mathjs";

const LoginForm: React.FC = () => {
  let history = useHistory();

  const [congnitoUser, setCognitoUser] = useState<CognitoUser>(
    new CognitoUser({
      Username: "",
      Pool: UserPool,
    })
  );
  const [show, setShow] = useState(false);
  const [resend, setResend] = useState(false);
  const [otp, setOtp] = useState<any>();
  const [userName, setUserName] = useState<any>();
  const [UserAtr, setUserAtr] = useState({});
  const [loginFailed, setLoginFailed] = useState(false);
  const [loginFailedMsg, setLoginFailedMsg] = useState("");
  const [password, setPassword] = useState("");
  const [showModal, setModalStatus] = useState<any>(false);
  const [children, setChildren] = useState<any>();
  const { logout, permissions, setLoginData, tokens } = useAuthStore();
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);
  const [resendStatus, setResendStatus] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const handleChange = (data: any) => {
    setOtp(data);
  };
  const [state, setState] = useState<any>(0);
  const hideModal = () => {
    setModalStatus(false);
  };

  useEffect(() => {
    childrenElements();
  }, [loginFailed]);

  useEffect(() => {
    childrenElements();
  }, [otp]);

  useEffect(() => {
    childrenElements();
  }, [resend]);

  useEffect(() => {
    childrenElements();
  }, [seconds]);

  useEffect(() => {
    childrenElements();
  }, [resendStatus]);

  useEffect(() => {
    if (resendStatus) {
      const myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
            setResend(false);
            setResendStatus(false);
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [resendStatus, seconds]);

  useEffect(() => {
    setMinutes(2);
    setSeconds(0);
  }, [resendStatus]);

  const childrenElements = () => {
    setChildren(
      <div className="w-full">
        <form className="bg-white rounded pt-6 pb-8 mb-4">
          <div>
            <svg
              data-name="Layer 1"
              height="200"
              viewBox="0 0 505.46625 596.94537"
              xmlns="http://www.w3.org/1999/xlink"
              className="rounded-b-full shadow-lg w-full"
            >
              <path
                d="M706.73312,652.47268l5,96S500.239,547.178,473.89063,488.28153,497.13921,283.694,497.13921,283.694l110.04332,60.44633Z"
                transform="translate(-347.26688 -151.52732)"
                fill="#9f616a"
              />
              <path
                d="M382.44618,243.39641l-32.548,3.09981s-17.049,37.19774,35.64783,40.29756Z"
                transform="translate(-347.26688 -151.52732)"
                fill="#9f616a"
              />
              <path
                d="M382.44618,300.74293l-32.548,3.09981s-17.049,37.19774,35.64783,40.29755Z"
                transform="translate(-347.26688 -151.52732)"
                fill="#9f616a"
              />
              <path
                d="M382.44618,362.73916l-32.548,3.09981s-17.049,37.19774,35.64783,40.29756Z"
                transform="translate(-347.26688 -151.52732)"
                fill="#9f616a"
              />
              <path
                d="M391.74562,424.7354l-32.548,3.09981s-17.049,37.19774,35.64784,40.29755Z"
                transform="translate(-347.26688 -151.52732)"
                fill="#9f616a"
              />
              <path
                d="M366.60044,258.3401h2.46966V190.68455a39.15718,39.15718,0,0,1,39.15715-39.15723H551.56477a39.15718,39.15718,0,0,1,39.15726,39.1571V561.84816a39.15719,39.15719,0,0,1-39.15715,39.15723H408.22744a39.1572,39.1572,0,0,1-39.15731-39.15708V306.49848h-2.46969Z"
                transform="translate(-347.26688 -151.52732)"
                fill="#3f3d56"
              />
              <path
                d="M406.64739,161.71467h18.71028a13.8929,13.8929,0,0,0,12.86292,19.13985h82.1162a13.89286,13.89286,0,0,0,12.86291-19.13987h17.47545a29.24215,29.24215,0,0,1,29.24218,29.24211V561.57589a29.24216,29.24216,0,0,1-29.24214,29.24218H406.64739a29.24216,29.24216,0,0,1-29.24218-29.24214h0V190.95679A29.24214,29.24214,0,0,1,406.64739,161.71467Z"
                transform="translate(-347.26688 -151.52732)"
                fill="#fff"
              />
              <rect
                x="100.53673"
                y="114.01642"
                width="61.71533"
                height="14.89247"
                fill="#6c63ff"
              />
              <rect
                x="97.59857"
                y="178.00873"
                width="67.59164"
                height="14.89247"
                fill="#e5e5e5"
              />
              <rect
                x="55.96534"
                y="209.9996"
                width="150.85812"
                height="14.89247"
                fill="#e5e5e5"
              />
              <rect
                x="55.96534"
                y="241.99047"
                width="150.85812"
                height="14.89247"
                fill="#e5e5e5"
              />
              <path
                d="M852.73312,702.47268l-173.48-197.91713-6.19962-127.09228-65.096-108.49341-18.59887-46.49718s-43.39736,4.64972-7.74953,92.99435l14.72411,47.27213,0,0a216.67408,216.67408,0,0,0-20.14876,91.24394v108.3237c0,25.50678,121.55,164.44287,135.69866,185.6658l0,0Z"
                transform="translate(-347.26688 -151.52732)"
                fill="#9f616a"
              />
              <polygon
                points="247.195 126.636 260.136 120.276 259.695 119.378 247.738 125.255 229.688 77.932 228.754 78.289 247.195 126.636"
                opacity="0.2"
              />
              <rect
                x="358.98404"
                y="239.81058"
                width="1.00012"
                height="20.43016"
                transform="translate(-346.65612 349.39987) rotate(-69.7779)"
                opacity="0.2"
              />
              <rect
                x="358.98404"
                y="297.81058"
                width="1.00012"
                height="20.43016"
                transform="translate(-401.08099 387.35158) rotate(-69.7779)"
                opacity="0.2"
              />
              <rect
                x="358.98404"
                y="359.81058"
                width="1.00012"
                height="20.43016"
                transform="translate(-459.25929 427.92066) rotate(-69.7779)"
                opacity="0.2"
              />
              <rect
                x="364.06574"
                y="425.55799"
                width="0.99981"
                height="10.35283"
                transform="translate(-528.7721 421.49849) rotate(-64.36101)"
                opacity="0.2"
              />
              <circle
                cx="131.47081"
                cy="342.17535"
                r="43.22999"
                fill="#6c63ff"
              />
              <polygon
                points="127.351 360.424 114.413 343.787 121.937 337.935 128.063 345.812 148.76 323.964 155.681 330.521 127.351 360.424"
                fill="#fff"
              />
            </svg>
          </div>
          <h1 className="text-center font-bold mt-4">{"OTP Verification "}</h1>
          <div className="mb-4">
            <OtpInput
              inputStyle={{
                width: "2.7rem",
                height: "2.7rem",
                margin: "10px 0.4rem",
                fontSize: "2rem",
                borderRadius: 4,
                border: "2px solid rgba(0,0,0,0.3)",
              }}
              isInputNum={true}
              value={otp}
              onChange={handleChange}
              numInputs={6}
              shouldAutoFocus
            />
          </div>
          <div
            className="flex justify-evenly text-center mb-2"
            style={{ fontSize: "12px" }}
          >
            <div>
              <span>Didn't you receive the OTP? </span>
              <button
                disabled={resendStatus}
                className="outline-none focus:outline-none text-red-400"
                type="button"
                style={{ fontSize: "12px", cursor: "pointer" }}
                onClick={() => resendOtp(userName)}
              >
                Resend OTP
              </button>
            </div>
            {!resendStatus ? null : (
              <h5 className="float-end">
                {" "}
                {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
              </h5>
            )}
          </div>
          {resend ? (
            <div className="mb-2 text-center" style={{ fontSize: "10px" }}>
              <span className="text-green">Successfully send the OTP to </span>
              <span className="text-red-600">{userName}</span>
            </div>
          ) : (
            ""
          )}
          <div className="flex items-center justify-between flex-row-reverse">
            <button
              className="w-full bg-purple-950 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={() => {
                otpVerification();
              }}
            >
              {"Verify"}
            </button>
          </div>
        </form>
      </div>
    );
  };

  const loginHandle = (email: string, password: string): void => {
    setLoading(true);
    setUserName(email);
    setPassword(password);
    const user: CognitoUser = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    setCognitoUser(user);

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: async (data) => {
        // console.log(data);

        const userDet = data.getIdToken().decodePayload();
        const isFirstTime = userDet["custom:isFirstTime"];

        // console.log(userDet);
        // console.log(isFirstTime);
        if (isFirstTime != 1) {
          const payload = {
            email: {
              isVerified: true,
            },
            mobile: {
              value: userDet.phone_number,
              isVerified: userDet.phone_number_verified,
            },
            firstName: userDet.given_name,
            lastName: userDet.family_name,
            isFirstTime: true,
          };

          await patchUser(data.getIdToken(), userDet.sub, payload);

          // var attributeList = [];
          // attributeList.push(new CognitoUserAttribute({
          //     Name: 'custom:isFirstTime',
          //     Value: '1',
          // }));

          // attributeList.push(new CognitoUserAttribute({
          //     Name: 'email_verified',
          //     Value: 'True',
          // }))

          // user.updateAttributes(attributeList, (err, result) => {
          //     console.log(err);
          //     console.log(result);
          // });
        }

        let permissionsRes: any = await getUserDetails(data.getIdToken(), true);


        setLoginData({
          tokens: {
            accessToken: data.getAccessToken(),
            idToken: data.getIdToken(),
            refreshToken: data.getRefreshToken(),
          },
          userData: data.getIdToken().decodePayload(),
          permissions:
            permissionsRes.status === 200 && permissionsRes.data.data
              ? permissionsRes.data.data.permissions
              : [],
          warehouses:
            permissionsRes.status === 200 && permissionsRes.data.data
              ? permissionsRes.data.data.warehouses
              : [],
          roles:
            permissionsRes.status === 200 && permissionsRes.data.data
              ? permissionsRes.data.data.roles
              : [],
          partner:
            permissionsRes.status === 200 && permissionsRes.data.data
              ? permissionsRes.data.data.partner
              : [],
          partnerAdminId:
            permissionsRes.status === 200 &&
            permissionsRes.data.data.partnerAdminId !== 1
              ? permissionsRes.data.data.partnerAdminId
              : "",
          isPartnerAdmin:
            permissionsRes.status === 200 && permissionsRes.data.data
              ? permissionsRes.data.data.isPartnerAdmin
              : false,
        });
        setLoginFailed(false);
        if (0 !== permissionsRes.data.data.permissions.length) {
          if (permissionsRes.data.data.isPartnerAdmin) {
            history.push("/customers");
          } else {
            history.push("/statistics");
          }
        } else {
          history.push("/unauthorized");
        }
        setLoading(false);
        // history.push("/pricing");
        // history.push("/pricingfeed");
      },

      onFailure: (err) => {
        setLoginFailed(true);
        setLoading(false);
        setLoginFailedMsg(err.message);
        if (err.name === "UserNotConfirmedException") {
          console.log("EORR", err);
          setModalStatus(true);
          //resendOtp(email);
          // setModalStatus(true);
        }
      },

      newPasswordRequired: (userAtr, required) => {
        delete userAtr.email_verified;
        delete userAtr.phone_number_verified;

        setShow(true);
        setUserAtr(userAtr);
      },
    });
  };

  const handleNewPassword = (userAtr: any): void => {
    setShow(false);

    let params = {
      ...UserAtr,
      given_name: userAtr.given_name,
      family_name: userAtr.family_name,
      phone_number: userAtr.phoneNumber,
    };
    //TikTok@123
    congnitoUser.completeNewPasswordChallenge(userAtr.password, params, {
      onSuccess: (data) => {
        // setLoginData({
        //     tokens: {
        //         accessToken: data.getAccessToken(),
        //         idToken: data.getIdToken(),
        //         refreshToken: data.getRefreshToken(),
        //     },
        //     userData: data.getIdToken().decodePayload(),
        // });
        // history.push("/pricingfeed");
        loginHandle(userName, userAtr.password);
      },

      onFailure: (err) => {
        console.error("onFailure:", err);
      },
    });
  };

  const otpVerification = () => {
    var userData = {
      Username: userName,
      Pool: UserPool,
    };

    var cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmRegistration(otp, true, async function (err, result) {
      if (err) {
        toast.error(err.message || JSON.stringify(err));
        // alert(err.message || JSON.stringify(err));
        return;
      }
      toast.success("User has been confirmed, please wait..!");
      // let permissionsRes: any = await getUserDetails(data.getIdToken(), true);

      //     console.log(permissionsRes);

      //     setLoginData({
      //         tokens: {
      //             accessToken: data.getAccessToken(),
      //             idToken: data.getIdToken(),
      //             refreshToken: data.getRefreshToken(),
      //         },
      //         userData: data.getIdToken().decodePayload(),
      //         permissions: permissionsRes.status === 200 && permissionsRes.data.data ? permissionsRes.data.data.permissions : [],
      //         warehouses: permissionsRes.status === 200 && permissionsRes.data.data ? permissionsRes.data.data.warehouses : [],
      //         roles: permissionsRes.status === 200 && permissionsRes.data.data ? permissionsRes.data.data.roles : []
      //     });
      loginHandle(userName, password);
      //setModalStatus(false);
      //setLoginFailedMsg('');
    });
  };

  const resendOtp = (email: any) => {
    setResend(false);
    setResendStatus(true);
    const user: CognitoUser = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    user.resendConfirmationCode(function (err, result) {
      if (err) {
        toast.error(err.message || JSON.stringify(err));
        setResendStatus(false);
        // alert(err.message || JSON.stringify(err));
        return;
      }
      setResend(true);
      setModalStatus(true);
    });
  };
  return (
    <LoginLayout>
      <ToastContainer />
      <Modal
        size={""}
        show={showModal}
        handleClose={hideModal}
        children={children}
      />
      <Form
        loginHandle={loginHandle}
        loginFaild={loginFailed}
        loginFailedMsg={loginFailedMsg}
        loading={loading}
      />
      <LoginAlert show={show} newPasswordHandle={handleNewPassword} />
    </LoginLayout>
  );
};

export default LoginForm;
