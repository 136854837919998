import "./styles/index.css";
import Routes from "./Bootstrap/Route";
import MetalProvider from "./Context/metalContext";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <MetalProvider>
      <ToastContainer />
      <Routes />
      {/* <AddMetal /> */}
    </MetalProvider>
  );
}

export default App;
