import React, { useEffect, useState } from "react";
import Loader from "../../../Components-ui/Loader";
import { toast } from "react-toastify";
import { useAuthStore } from "../../../Store/AuthStore";
import MultipleSelect from "../../../Components-ui/MultipleSelect";
import UserListService from "../Services/UserList.Service";

interface PropsType {
  onCloseModal: any;
  user: any;
  getAllUsers: any;
  page: number;
  warehouses: any;
}

const AssignWarehouseForm: React.FC<PropsType> = ({
  onCloseModal,
  user,
  getAllUsers,
  page,
  warehouses,
}) => {
  const { tokens } = useAuthStore();

  const [Loading, setLoading] = useState<any>(false);
  const [SelectedWarehouses, setSelectedWarehouses] = useState<any>([]);

  useEffect(() => {
    let selectedWarehouses: any = [];
    user.warehouses.forEach((warehouse: any) => {
      selectedWarehouses = [
        ...selectedWarehouses,
        ...warehouses.filter((x: any) => x.id === warehouse.warehouseId),
      ];
    });
    setSelectedWarehouses(selectedWarehouses);
  }, [user]);

  /**
   * Assign warehouse to user
   * @param e
   */
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (SelectedWarehouses.length === 0)
      return toast.error("You have to add at least one warehouse.");

    try {
      setLoading(true);
      await assignWarehouseUser();
      await getAllUsers(page);
      setLoading(false);
      onCloseModal();
      return toast.success("Warehouse was successfully assigned to the user");
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.length > 0
      ) {
        console.log(error.response.data.errors[0]);
        toast.error(error.response.data.errors[0].title);
      }
    }
  };

  /**
   * Assign warehouse to user
   */
  const assignWarehouseUser = async () => {
    if (SelectedWarehouses.length > 0) {
      let payload = SelectedWarehouses.map((warehouse: any) => {
        return {
          userId: user.userId,
          warehouseId: warehouse.id,
          active: "ENABLED",
        };
      });
      await UserListService.assignWarehouseUser(tokens, payload);
    }
  };

  /**
   * Handle multiple select change event
   * @param selected
   */
  const handleChangeItem = (selected: any) => {
    setSelectedWarehouses(selected);
    console.log(selected, "selected");
  };

  return (
    <>
      {Loading ? (
        <Loader />
      ) : (
        <div className=" w-full bg-gray-800 bg-opacity-50 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-4xl">
            <div className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
              <div className="items-start justify-between px-6 py-4 border-b border-solid border-blue bg-gray-100 rounded-t">
                <h3 className="text-xl font-semibold">Assign Warehouse</h3>
              </div>

              <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
                <div className="col-span-12 sm:col-span-12">
                  <label
                    htmlFor="role"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Warehouses
                  </label>
                  <div className="mt-1">
                    <MultipleSelect
                      optionList={warehouses}
                      onChangeItem={handleChangeItem}
                      selectedValue={SelectedWarehouses}
                      closeMenuOnSelect={false}
                      getOptionLabel={(option: any) => `${option.warehouseId}`}
                      getOptionValue={(option: any) => `${option.id}`}
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-end sm:py-3  sm:px-6 border-t border-solid border-blueGray-200 rounded-b">
                <div>
                  <button
                    id="modal"
                    className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                    type="button"
                    onClick={onCloseModal}
                  >
                    Close
                  </button>
                </div>
                <div>
                  <button
                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                    type="button"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AssignWarehouseForm;
