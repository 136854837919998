import Http from "../../../library/http.library";
import { httpMethods } from "../../../library/enum";
import { makeQuery } from "../../../Common/util.helper";

class TradeExecutionService {
  /**
   * Get all item codes
   * @param tokens
   */
  async getItems(tokens: any) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/item-master?isPaginated=false`)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }

  async getMetals(tokens: any) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/metal-master?isPaginated=false`)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }

  async filterMetals(data: any, tokens: any) {
    console.log("metalcode", data.metalCode);
    var query = {
      isPaginated: true,
      ...(data.metalCode ? { metalCode: data.metalCode } : {}),
      active: "ENABLED",
    };
    const params = makeQuery(query);
    console.log("params", params);

    return await Http.setMethod(httpMethods.get)
      .setUrl(`/item-master?${params}`)
      //   https://dev-api.ebullion.in/item-master?isPaginated=true&metalCode=Au&active=ENABLED
      .setToken(tokens.idToken.jwtToken)
      .request();

    // return new Promise(async (resolve, reject) => {
    //   let apiUrl = process.env.REACT_APP_URL + `/api/v1/metalcodemaster/all/with-sync`;
    //   resolve(await axios.get(apiUrl));
    // });
  }

  async getCustomerGroup(data: any, tokens: any) {
    var data = data;
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/account/customerGroups/${data}`)
      //   https://dev-api.ebullion.in/item-master?isPaginated=true&metalCode=Au&active=ENABLED
      .setToken(tokens.idToken.jwtToken)
      .request();
  }
  async getAllPartners(tokens: any) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/account/partnerList?isPaginated=false`)
      //   https://dev-api.ebullion.in/item-master?isPaginated=true&metalCode=Au&active=ENABLED
      .setToken(tokens.idToken.jwtToken)
      .request();
  }

  /**
   * Get all partners
   */
  async getPartners(tokens: any) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/partners?isPaginated=false`)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }

  /**
   * Get all uoms
   * @param tokens
   */
  async getUOMs(tokens: any) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/uom-master?isPaginated=false`)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }

  /**
   * Get all currencies
   */
  async getCurrency() {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/currency-master`)
      .request();
  }

  /**
   * Get all price rules
   * @param params
   */
  async getPriceRules(params: any) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(
        `/price/master?isPaginated=false&metalCode=${params.metalCode}&currency=${params.currency}&partnerId=${params.partnerId}&status=${params.status}&effectDate=${params.effectDate}`
      )
      .request();
  }

  /**
   * Get all discounts
   * @param params
   */
  async getAllDiscounts(params: any = null) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(
        `/discount?isPaginated=false${
          params
            ? `${params.dType ? `&dType=${params.dType}` : ``}${
                params.iTypes
                  ? `&iType=${params.iTypes[0]}&iType=${params.iTypes[0]}`
                  : ``
              }${params.currency ? `&currency=${params.currency}` : ``}`
            : ""
        }`
      )
      .request();
  }

  //////////////////////////// TRADE EXECUTION ENDPOINTS ///////////////////////////////////

  /**
   * Get all trade execution rules
   * @param page
   */
  async getAllTradeExecutions(page: number, query: any,tokens: any,) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/trade-execution?&page=${page}${query}`)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }

  /**
   * Get trade execution rule details
   * @param id
   */
  async getTradeExecutionDetails(id: any,tokens:any) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/trade-execution?id=${id}`)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }

  /**
   * Add new trade execution rule
   * @param data
   * @param tokens
   */
  async addNewTradeExecutionRule(data: any, tokens: any) {
    return await Http.setMethod(httpMethods.post)
      .setUrl(`/trade-execution`)
      .setData(data)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }

  /**
   * Update trade execution rule
   * @param data
   * @param tokens
   */
  async updateTradeExecutionRule(data: any, tokens: any) {
    return Http.setMethod(httpMethods.patch)
      .setUrl(`/trade-execution/${data.id}`)
      .setData(data)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }

  /**
   * Get customer groups
   * @param tokens
   */
  async getCustomerGroups(tokens: any) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/customer-group?isPaginated=false`)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }
}

export default new TradeExecutionService();
