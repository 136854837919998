import React from "react";
import ReactSelect from "react-select";
import makeAnimated from "react-select/animated";
import "./styless.css";

interface PropsType {
  optionList: any;
  onChangeItem: any;
  selectedValue: any;
  closeMenuOnSelect: boolean;
  getOptionLabel: any;
  getOptionValue: any;
  isSearchable?: boolean;
  isMulti?: any;

}

const animatedComponents = makeAnimated();

const MultipleSelect: React.FC<PropsType> = ({
  optionList,
  onChangeItem,
  selectedValue,
  closeMenuOnSelect,
  getOptionLabel,
  getOptionValue,
  isSearchable,
  isMulti = true,
}) => {
  return (
    <ReactSelect
      options={optionList}
      isSearchable={isSearchable}
      isMulti={isMulti}
      closeMenuOnSelect={closeMenuOnSelect}
      hideSelectedOptions={false}
      components={animatedComponents}
      onChange={onChangeItem}
      value={selectedValue}
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        control: (base) => ({ ...base, padding: "2px", border: "1px solid rgb(229,231,235)" }),
        indicatorSeparator: () => ({ display: "none"})
      }}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      blurInputOnSelect
    />
  );
};

export default MultipleSelect;
 