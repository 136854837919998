import React from "react";
import { Transition } from "@headlessui/react";
import { useState } from "react";
import ReactPaginate from "react-paginate";

interface PropsType {
  meta: any;
  handlePageChange: any;
}

export const Pagination: React.FC<PropsType> = ({ meta, handlePageChange }) => {
  if (meta.pages && meta.pages + 1 > 1) {
    return (
      <div className="bg-white px-2 py-3 flex items-center justify-between border-t border-gray-200 sm:px-2">
        <div className="flex justify-end w-full">
          <div>
            <ReactPaginate
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              forcePage={meta.current ?? 0}
              pageCount={meta.pages + 1 ?? 0}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={(data: any) => {
                handlePageChange(data.selected);
              }}
              breakClassName={`relative inline-flex bg-white border-gray-300 text-gray-500
            items-center px-4 py-2 border text-sm font-medium`}
              previousClassName={`relative inline-flex items-center px-2 py-2 rounded-l-md border 
            border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
              nextClassName={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
              pageClassName={`relative inline-flex bg-white border-gray-300 text-gray-500
            items-center px-4 py-2 border text-sm font-medium`}
              activeClassName={`z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex 
            items-center px-4 py-2 border text-sm font-medium`}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};
