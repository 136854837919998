import {useParams} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import React, {useEffect, useState} from "react";
import Layout from "../../Layout/Layout";
import TradeExecutionForm from "./TradeExecutionForm";
import TradeExecutionService from "../Services/trade-execution.service";
import Loader from "../../../Components-ui/Loader";
import { useAuthStore } from "../../../Store/AuthStore";


interface PropsType {
}

const EditTradeExecution: React.FC<PropsType> = () => {

    let {id} = useParams<any>();

    const [Loading, setLoading] = useState<any>(true);
    const [TradeExecutionData, setTradeExecutionData] = useState<any>({});
    const { tokens } = useAuthStore();

    useEffect((): any => {
        getTradeExecutionDetails();
        // eslint-disable-next-line
    }, []);

    /**
     * Get trade execution rule details by id
     */
    const getTradeExecutionDetails = async () => {
        let data: any = await TradeExecutionService.getTradeExecutionDetails(id,tokens);
        if (data.status === 200 && data.data.data) {
            let responseData = data.data.data;
            if (responseData.length > 0) {
                setTradeExecutionData(responseData[0]);
            }
            setLoading(false)
        }
    };

    return (
        <>
            <Layout type={"TradeExecution"}>
                <ToastContainer />
                <div className="flex justify-between items-center mb-5">
                    {Loading ? (<Loader/>) : (
                        <TradeExecutionForm
                            isEdit={true}
                            data={TradeExecutionData}
                        />
                    )}
                </div>
            </Layout>
        </>
    )
}


export default EditTradeExecution;
