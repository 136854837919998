import React from "react";
import { Redirect } from "react-router-dom";
import { useAuthStore } from "../Store/AuthStore";
import { routesArray } from "./RoutePermissionList";

// @ts-ignore
const ProtectedRoutes = ({ isAuth, type }) => {
  const { permissions } = useAuthStore();

  function checkPermission(arr: any) {
    return (
      permissions?.filter(
        (permission: any) =>
          permission.permissionCode === "super.admin" ||
          arr?.includes(permission.permissionCode)
      ).length > 0
    );
  }

  if (isAuth) {
    if (
      checkPermission(
        routesArray.filter((data: any) => data.type === type)[0].permisson
      )
    ) {
      return routesArray.filter((data: any) => data.type === type)[0]
        .componenet;
    } else {
      return <Redirect to={window.location.pathname} />;
    }
  }

  return <Redirect to="/login" />;
};

export default ProtectedRoutes;
