import Http from "../../../library/http.library";
import { httpMethods } from "../../../library/enum";
import { createQuery } from "../../../Common/util.helper";

export const getDashboardData = async (data: any, tokens: any) => {
  let queryParams = createQuery(data);
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/summary-bar-reconciliation${queryParams}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};
