import { useEffect, useState } from "react";
import { GetMetalBasisByMetalCode, InsertMetalBasis, EditMetalBasis, DeleteMetalBasis } from './Metal.Service'
import {  useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SetDeleteConfirmAlert } from '../Util/util.component'
import './react-confirm-alert.css';
import ErrorToolTip  from './ErrorToolTip'
import FeederPopUp from "./FeederPopup";

interface PropsType {
  metal: any;
  inCreateMode: boolean,
  isEditable:boolean,
}

const MetalBasisTable: React.FC<PropsType> = ( { metal,isEditable } ) => {

    let { id } = useParams<any>();
    const [editRow , setEditRow] = useState<any>({});
    const [data , setData] = useState<any>([]);
    // const [isOpen, setIsOpen] = useState(false);
    // const [metalCode, setMetalCode] = useState<string>(""); 
    const [inEditMode , setInEditMode] = useState({
        status : false,
        row : -1
    })
    
    //my edit
    // const [inEdit, setInEdit] = useState<any>({});
    const [inCreateMode , setInCreateMode] = useState<boolean>(false);
    const [Metal_code, setMetalCode] = useState<string>("");
    const [Basis_Code, setBasisCode] = useState<string>("");
    const [External_Desc, setExternalDesc] = useState<string>("");
    const [Description, setDescription] = useState<string>("");
    const [Basis_UOM, setBasisUOM] = useState<string>("");
    const [Rounding_Decimel, setRoundingDecimel] = useState<string>("");
    const [Currency, setCurrency] = useState<string>("");
    const [Base_UoM, setBaseUoM] = useState<string>("");
    const [UoM_Conv, setUoMConv] = useState<string>("");
    const [Loco, setLoco] = useState<string>("");
    const [Domicile, setDomicile] = useState<string>("");
    const [CreateRowErrors , setCreateRowErrors] = useState<any>({});
    const [EditRowErrors , setEditRowErrors] = useState<any>({});
    const [CreateRowSubmitCount , setCreateRowSubmitCount] = useState<number>(0);
    const [ActiveColCreate , setActiveColCreate] = useState<string>("");
    const [ActiveColEdit , setActiveColEdit] = useState<string>("");
    const [InitLoading , setInitLoading] = useState<boolean>(true);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const onClickEdit = (i:number) => {
        setEditRowErrors({});
        setActiveColEdit("")
        setInEditMode({ status : true , row : i})
        setEditRow(data[i]);
    }

    const onClickSave = (index:number) => {
      if(validateMetalBasisEdit(editRow)) {
        setData(data.map(( row:any ,i:number) => i == index ? editRow : row ) );
        setInEditMode({ status : false, row : -1 })
        SaveEditMetalBasis({
          id : editRow.id,
          Metal_code: editRow.Metal_code,
          Basis_Code: editRow.Basis_Code.trim(),
          External_Desc: editRow.External_Desc.trim(),
          Description: editRow.Description.trim(),
          Basis_UOM: editRow.Basis_UOM.trim(),
          Rounding_Decimel: editRow.Rounding_Decimel,
          Currency: editRow.Currency.trim(),
          Base_UoM: editRow.Base_UoM.trim(),
          UoM_Conv: editRow.UoM_Conv,
          Loco: editRow.Loco.trim(),
          Domicile: editRow.Domicile.trim()
        });
      }
    }

    const validateMetalBasisEdit = (state:any) => {
      let result = true;
      let errors:any = {};
      
      if(state.Metal_code.length == 0){
        result = false;
        errors.Metal_code = "Metal Code can't be empty!"
      }else{
        errors.Metal_code = ""
      }
      if(state.Basis_Code.length == 0){
        result = false;
        errors.Basis_Code = "Basis Code can't be empty!"
      }else{
        errors.Basis_Code = ""
      }
      if(state.External_Desc.length == 0){
        result = false;
        errors.External_Desc = "External Desc can't be empty!"
      }else{
        errors.External_Desc = ""
      }
      if(state.Description.length == 0){
        result = false;
        errors.Description = "Description can't be empty!"
      }else{
        errors.Description = ""
      }
      if(state.Basis_UOM.length == 0){
        result = false;
        errors.Basis_UOM = "Basis_UOM can't be empty!"
      }else{
        errors.Basis_UOM = ""
      }

      if(state.Rounding_Decimel.length == 0){
        result = false;
        errors.Rounding_Decimel = "Rounding decimel can't be empty!"
      }else if(isNaN(state.Rounding_Decimel)){
        result = false;
        errors.Rounding_Decimel = "Rounding decimel must be a number!"
      }else if(state.Rounding_Decimel <= 0 ){
        result = false;
        errors.Rounding_Decimel = "Rounding decimel can't be less than zero!"
      }else if(!Number.isInteger( parseFloat(state.Rounding_Decimel))){
        result = false;
        errors.Rounding_Decimel = "Rounding decimel must be an integer !"
      }
      else{
        errors.Rounding_Decimel = ""
      }

      if(state.Currency.length == 0){
        result = false;
        errors.Currency = "Currency can't be empty!"
      }else{
        errors.Currency = ""
      }
      if(state.Base_UoM.length == 0){
        result = false;
        errors.Base_UoM = "Base_UoM can't be empty!"
      }else{
        errors.Base_UoM = ""
      }

      if(state.UoM_Conv.length == 0){
        result = false;
        errors.UoM_Conv = "UoM_Conv can't be empty!"
      }else if(isNaN(state.UoM_Conv)){
        result = false;
        errors.UoM_Conv = "UoM_Conv must be a number!"
      }else if(state.UoM_Conv <= 0 ){
        result = false;
        errors.UoM_Conv = "UoM_Conv can't be less than zero!"
      }
      else{
        errors.UoM_Conv = ""
      }

      // if(state.Loco.length == 0){
      //   result = false;
      //   errors.Loco = "Loco can't be empty!"
      // }else{
      //   errors.Loco = ""
      // }
      if(state.Domicile.length == 0){
        result = false;
        errors.Domicile = "Domicile can't be empty!"
      }else{
        errors.Domicile = ""
      }
      setEditRowErrors(errors);
      return result;
    }

    const onClickDelete = (item: any) => {
      console.log("Delete item: ", item);

      SetDeleteConfirmAlert(
        "Confirm Delete", 
        "Are you sure you want to delete this?",
        () => {DeleteMetalBasisFn(item)},
        () => {}
        )
    }

    const DeleteMetalBasisFn = (item: any) => {
      DeleteMetalBasis(item.id).then(data => {
        fetchMetalBasis();
        toast.success("Deleted!");
      }).catch(err => {
        toast.error("Something went wrong!");
      })
    }

    const SaveEditMetalBasis = (data : any) => {
      EditMetalBasis(data.id, data).then(data => {
      fetchMetalBasis();
      toast.success("Edit Successful!");
      }).catch(err => {
        toast.error("Something went wrong!");
      })
    }

    useEffect( () => {
      console.log("Metal basis metal: ", metal)
      if(!inCreateMode) {
        setMetalCode(metal ? metal[0].Metal_code : "");
        //Get metal basis
        // fetchMetalBasis();
        GetMetalBasisByMetalCode(metal ? metal[0].Metal_code : "").then((data: any) => {
          console.log("Metal basis data: ", data);
          setData(data ? data.data.data : []);
          setInitLoading(false);
        }).catch(err => {
          setData([]);
          toast.error("Metal basis data not found");
        })
      }else{
        setInitLoading(false);
      }

    }, [metal])

    const onAddNewMetalBasis = () => {
      setInitLoading(true);
      setInCreateMode(!inCreateMode);
      clearDataInForm();
      setCreateRowSubmitCount(0)
      setCreateRowErrors({});
      setInEditMode({ status : false, row : -1 })
    }
    
    const onCreateMetalBasisClicked = (e: any) => {
      e.preventDefault();
      setCreateRowSubmitCount(1);
      if(validateMetalBasisCreate({})) {
        setInCreateMode(false);
        InsertMetalBasis({
          Metal_code: Metal_code,
          Basis_Code: Basis_Code.trim(),
          External_Desc: External_Desc.trim(),
          Description: Description.trim(),
          Basis_UOM: Basis_UOM.trim(),
          Rounding_Decimel: Rounding_Decimel,
          Currency: Currency.trim(),
          Base_UoM: Base_UoM.trim(),
          UoM_Conv: UoM_Conv,
          Loco: Loco.trim(),
          Domicile: Domicile.trim()
        }).then(data => {
          setCreateRowSubmitCount(0);
          toast.success("Successfully Added!");
          clearDataInForm();
          fetchMetalBasis();
        }).catch(err => {
          setInCreateMode(true);
          toast.error("Something went wrong!");
        })
      }
    }

    const latest_state = (data:any) => {
      return {
        Metal_code,Basis_Code,External_Desc,Description,Basis_UOM,Rounding_Decimel,
        Currency,Base_UoM,UoM_Conv,Loco,Domicile,
        ...data
      }
    }

    const validateMetalBasisCreate = (data:any) => {
      let result = true;
      let errors:any = {};
      let state = latest_state(data);
      console.log(state);
      
      if(state.Metal_code.length == 0){
        result = false;
        errors.Metal_code = "Metal Code can't be empty!"
      }else{
        errors.Metal_code = ""
      }
      if(state.Basis_Code.length == 0){
        result = false;
        errors.Basis_Code = "Basis Code can't be empty!"
      }else{
        errors.Basis_Code = ""
      }
      if(state.External_Desc.length == 0){
        result = false;
        errors.External_Desc = "External Desc can't be empty!"
      }else{
        errors.External_Desc = ""
      }
      if(state.Description.length == 0){
        result = false;
        errors.Description = "Description can't be empty!"
      }else{
        errors.Description = ""
      }
      if(state.Basis_UOM.length == 0){
        result = false;
        errors.Basis_UOM = "Basis_UOM can't be empty!"
      }else{
        errors.Basis_UOM = ""
      }

      if(state.Rounding_Decimel.length == 0){
        result = false;
        errors.Rounding_Decimel = "Rounding decimel can't be empty!"
      }else if(isNaN(state.Rounding_Decimel)){
        result = false;
        errors.Rounding_Decimel = "Rounding decimel must be a number!"
      }else if(state.Rounding_Decimel <= 0 ){
        result = false;
        errors.Rounding_Decimel = "Rounding decimel can't be less than zero!"
      }else if(!Number.isInteger( parseFloat(state.Rounding_Decimel))){
        result = false;
        errors.Rounding_Decimel = "Rounding decimel must be an integer !"
      }
      else{
        errors.Rounding_Decimel = ""
      }

      if(state.Currency.length == 0){
        result = false;
        errors.Currency = "Currency can't be empty!"
      }else{
        errors.Currency = ""
      }
      if(state.Base_UoM.length == 0){
        result = false;
        errors.Base_UoM = "Base_UoM can't be empty!"
      }else{
        errors.Base_UoM = ""
      }

      if(state.UoM_Conv.length == 0){
        result = false;
        errors.UoM_Conv = "UoM_Conv can't be empty!"
      }else if(isNaN(state.UoM_Conv)){
        result = false;
        errors.UoM_Conv = "UoM_Conv must be a number!"
      }else if(state.UoM_Conv <= 0 ){
        result = false;
        errors.UoM_Conv = "UoM_Conv can't be less than zero!"
      }
      else{
        errors.UoM_Conv = ""
      }
      // if(state.Loco.length == 0){
      //   result = false;
      //   errors.Loco = "Loco can't be empty!"
      // }else{
      //   errors.Loco = ""
      // }
      if(state.Domicile.length == 0){
        result = false;
        errors.Domicile = "Domicile can't be empty!"
      }else{
        errors.Domicile = ""
      }
      setCreateRowErrors(errors);
      return result;
    }

    const clearDataInForm = () => {
      setBasisCode("");
      setExternalDesc("");
      setDescription("");
      setBasisUOM("");
      setRoundingDecimel("");
      setCurrency("");
      setBaseUoM("");
      setUoMConv("");
      setLoco("");
      setDomicile("");
    }

    const fetchMetalBasis = () => {
      GetMetalBasisByMetalCode(metal ? metal[0].Metal_code : "").then((data: any) => {
        console.log("Metal basis data: ", data);
        setData(data ? data.data.data : []);
        setInitLoading(false);
      }).catch(err => {
        setData([]);
        setInitLoading(false);
      })
    }
    const renderAddMetalBasis = () => {
      return ( 
        <tr className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100" key={0}>
                <td className="py-3 px-2 text-left relative">
                  <input
                      onBlur={(e) => setActiveColCreate("")}
                      onFocus={(e) => setActiveColCreate("Basis_Code")} 
                    className={`appearance-none rounded relative block w-full px-2
                     py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                    focus:outline-none text-sm
                    ${ CreateRowSubmitCount > 0 && CreateRowErrors.Basis_Code && "border-red-600 focus:border-red-600"}`}
                    value={Basis_Code}
                    placeholder="Basis Code"  
                    onChange={(e) => { setBasisCode(e.target.value); 
                      validateMetalBasisCreate({Basis_Code:e.target.value}); }}
                  />
                  <ErrorToolTip 
                  error={CreateRowErrors.Basis_Code} 
                  count={CreateRowSubmitCount}
                  type={"Basis_Code"}
                  active={ActiveColCreate}
                  />
                </td>
                <td className="py-3 px-2 text-left relative">
                  <input
                      onBlur={(e) => setActiveColCreate("")}
                      onFocus={(e) => setActiveColCreate("External_Desc")} 
                      className={`appearance-none rounded  block w-full px-2
                       py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                      focus:outline-none text-sm
                      ${ CreateRowSubmitCount > 0 && CreateRowErrors.External_Desc && "border-red-600 focus:border-red-600"}`}
                      value={External_Desc}
                      placeholder="Ex Description"  
                      onChange={(e) => { setExternalDesc(e.target.value); 
                        validateMetalBasisCreate({External_Desc:e.target.value}); }}
                    />
                     <ErrorToolTip 
                  error={CreateRowErrors.External_Desc} 
                  count={CreateRowSubmitCount}
                  type={"External_Desc"}
                  active={ActiveColCreate}
                  />
                </td>
                
                <td className="py-3 px-2 relative text-center">
                  <input
                      onBlur={(e) => setActiveColCreate("")}
                      onFocus={(e) => setActiveColCreate("Description")} 
                      className={`appearance-none rounded  block w-full px-2
                       py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                      focus:outline-none text-sm
                      ${ CreateRowSubmitCount > 0 && CreateRowErrors.Description && "border-red-600 focus:border-red-600"}`}
                      value={Description}
                      placeholder="Description"  
                      onChange={(e) => { setDescription(e.target.value); 
                        validateMetalBasisCreate({Description:e.target.value}); }}
                    />
                     <ErrorToolTip 
                  error={CreateRowErrors.Description} 
                  count={CreateRowSubmitCount}
                  type={"Description"}
                  active={ActiveColCreate}
                  />
                </td>
              
                <td className="py-3 px-2 relative text-center">
                  <input
                      onBlur={(e) => setActiveColCreate("")}
                      onFocus={(e) => setActiveColCreate("Basis_UOM")} 
                      className={`appearance-none rounded  block w-full px-2
                       py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                      focus:outline-none text-sm
                      ${ CreateRowSubmitCount > 0 && CreateRowErrors.Basis_UOM && "border-red-600 focus:border-red-600"}`}
                      value={Basis_UOM}
                      placeholder="UOM"  
                      onChange={(e) => { setBasisUOM(e.target.value); 
                        validateMetalBasisCreate({Basis_UOM:e.target.value}); }}
                    />
                     <ErrorToolTip 
                  error={CreateRowErrors.Basis_UOM} 
                  count={CreateRowSubmitCount}
                  type={"Basis_UOM"}
                  active={ActiveColCreate}
                  />
                </td>

                <td className="py-3 px-2 relative text-center">
                  <input
                      onBlur={(e) => setActiveColCreate("")}
                      onFocus={(e) => setActiveColCreate("Rounding_Decimel")} 
                    className={`appearance-none rounded  block w-full px-2
                     py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                    focus:outline-none text-sm
                    ${ CreateRowSubmitCount > 0 && CreateRowErrors.Rounding_Decimel && "border-red-600 focus:border-red-600"}`}
                    value={Rounding_Decimel}
                    placeholder="Decimal"  
                    onChange={(e) => { setRoundingDecimel(e.target.value); 
                      validateMetalBasisCreate({Rounding_Decimel:e.target.value}); }}
                  />
                   <ErrorToolTip 
                  error={CreateRowErrors.Rounding_Decimel} 
                  count={CreateRowSubmitCount}
                  type={"Rounding_Decimel"}
                  active={ActiveColCreate}
                  />
                </td>

                <td className="py-3 px-2 relative text-center">
                  <input
                      onBlur={(e) => setActiveColCreate("")}
                      onFocus={(e) => setActiveColCreate("Currency")} 
                    className={`appearance-none rounded  block w-full px-2
                     py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                    focus:outline-none text-sm
                    ${ CreateRowSubmitCount > 0 && CreateRowErrors.Currency && "border-red-600 focus:border-red-600"}`}
                    value={Currency}
                    placeholder="Currency"  
                    onChange={(e) => { setCurrency(e.target.value); 
                      validateMetalBasisCreate({Currency:e.target.value}); }}
                  />
                   <ErrorToolTip 
                  error={CreateRowErrors.Currency} 
                  count={CreateRowSubmitCount}
                  type={"Currency"}
                  active={ActiveColCreate}
                  />
                </td>

                <td className="py-3 px-2 relative text-center">
                  <input
                      onBlur={(e) => setActiveColCreate("")}
                      onFocus={(e) => setActiveColCreate("Base_UoM")} 
                    className={`appearance-none rounded  block w-full px-2
                     py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                    focus:outline-none text-sm
                    ${ CreateRowSubmitCount > 0 && CreateRowErrors.Base_UoM && "border-red-600 focus:border-red-600"}`}
                    value={Base_UoM}
                    placeholder="Base UOM"  
                    onChange={(e) => { setBaseUoM(e.target.value); 
                      validateMetalBasisCreate({Base_UoM:e.target.value}); }}
                  />
                   <ErrorToolTip 
                  error={CreateRowErrors.Base_UoM} 
                  count={CreateRowSubmitCount}
                  type={"Base_UoM"}
                  active={ActiveColCreate}
                  />
                </td>

                <td className="py-3 px-2 relative text-center">
                  <input
                      onBlur={(e) => setActiveColCreate("")}
                      onFocus={(e) => setActiveColCreate("UoM_Conv")} 
                    className={`appearance-none rounded  block w-full px-2
                     py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                    focus:outline-none text-sm
                    ${ CreateRowSubmitCount > 0 && CreateRowErrors.UoM_Conv && "border-red-600 focus:border-red-600"}`}
                    value={UoM_Conv}
                    placeholder="Convertion"  
                    onChange={(e) => { setUoMConv(e.target.value); 
                      validateMetalBasisCreate({UoM_Conv:e.target.value}); }}
                  />
                   <ErrorToolTip 
                  error={CreateRowErrors.UoM_Conv} 
                  count={CreateRowSubmitCount}
                  type={"UoM_Conv"}
                  active={ActiveColCreate}
                  />
                </td>

                <td className="py-3 px-2 relative text-center">
                  <input
                      onBlur={(e) => setActiveColCreate("")}
                      onFocus={(e) => setActiveColCreate("Loco")} 
                    className={`appearance-none rounded  block w-full px-2
                     py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                    focus:outline-none text-sm
                    ${ CreateRowSubmitCount > 0 && CreateRowErrors.Loco && "border-red-600 focus:border-red-600"}`}
                    value={Loco}
                    placeholder="LOCO"  
                    onChange={(e) => { setLoco(e.target.value); 
                      validateMetalBasisCreate({Loco:e.target.value}); }}
                  />
                   <ErrorToolTip 
                  error={CreateRowErrors.Loco} 
                  count={CreateRowSubmitCount}
                  type={"Loco"}
                  active={ActiveColCreate}
                  />
                </td>

                <td className="py-3 px-2 relative text-center">
                  <input
                      onBlur={(e) => setActiveColCreate("")}
                      onFocus={(e) => setActiveColCreate("Domicile")} 
                    className={`appearance-none rounded  block w-full px-2
                     py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                    focus:outline-none text-sm
                    ${ CreateRowSubmitCount > 0 && CreateRowErrors.Domicile && "border-red-600 focus:border-red-600"}`}
                    value={Domicile}
                    placeholder="Domicile"  
                    onChange={(e) => { setDomicile(e.target.value); 
                      validateMetalBasisCreate({Domicile:e.target.value}); }}
                  />
                   <ErrorToolTip 
                  error={CreateRowErrors.Domicile} 
                  count={CreateRowSubmitCount}
                  type={"Domicile"}
                  active={ActiveColCreate}
                  />
                </td>

                <td className="py-3 px-2 relative text-center">
                  <div className="flex item-center justify-center">
                    <div
                        onClick={(e) => onCreateMetalBasisClicked(e)}
                        className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                    </svg>
                    </div> 
                    <div className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110" 
                    onClick={() => onAddNewMetalBasis()}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d=" M6 18L18 6M6 6l12 12" />
                      </svg>
                    </div>
                  </div>
                </td>
              </tr>
      )
    }

    const setShowAlert = () => {
      setIsOpen(!isOpen)
    }

    return (
 <div className=" my-6">
   <FeederPopUp show={isOpen} setOpen={() => setShowAlert}></FeederPopUp>
     <h6 className="text-xl font-bold text-left font-normal leading-normal mt-0 mb-4 text-gray-800 ">
      Metal Basis &nbsp;&nbsp;&nbsp;&nbsp;
    { isEditable && id != -1 && <button className="inline-flex justify-center py-2 px-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
     onClick={() => onAddNewMetalBasis()}>
         <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
      </svg>
    </button>}
    </h6>
        <table className="table-fixed rounded-lg text-sm w-full">
            <thead>
              <tr className="sticky top-0 px-6 py-3 text-green-900 bg-purple-300">
                <th className="py-3 text-sm px-2 text-left">Basic Code</th>
                <th className="py-3 text-sm px-2 text-left">External Description</th>
                <th className="py-3 text-sm px-2 text-left">Description</th>
                <th className="py-3 text-sm px-2 text-left">UOM</th>
                <th className="py-3 text-sm px-2 text-left">Rounding Decimal</th>
                <th className="py-3 text-sm px-2 text-left">Currency</th>
                <th className="py-3 text-sm px-2 text-left">Base UOM</th>
                <th className="py-3 text-sm px-2 text-left">UOM Conversion</th>
                <th className="py-3 text-sm px-2 text-left">LOCO</th>
                <th className="py-3 text-sm px-2 text-left">Domicile</th>
                {isEditable && <th className="py-3 text-sm px-2 text-left">Action</th>}
              
              </tr>
            </thead>
            <tbody className="text-gray-800 text-sm font-light">
              {inCreateMode ? renderAddMetalBasis() : ""}
              {data.map((items:any,i:number) => (
              <tr className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100">
                <td className="py-3 px-2 text-left relative">
                    { (inEditMode.status && inEditMode.row == i) 
                       ? <div>
                         <input 
                            onBlur={(e) => setActiveColEdit("")}
                            onFocus={(e) => setActiveColEdit("Basis_Code")} 
                            onChange={(e:any) => {
                              validateMetalBasisEdit({...editRow , Basis_Code : e.target.value})
                              setEditRow({...editRow , Basis_Code : e.target.value}) }
                            }
                            className={`appearance-none rounded relative block w-full px-2
                            py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm  
                            ${EditRowErrors.Basis_Code && "border-red-600 focus:border-red-600"}`}
                            value={editRow.Basis_Code} >
                        </input>
                        <ErrorToolTip 
                          error={EditRowErrors.Basis_Code} 
                          count={1}
                          type={"Basis_Code"}
                          active={ActiveColEdit}
                        />
                        </div>
                       : <span className="text-sm font-medium">{items.Basis_Code}</span>
                    }
                </td>
                <td className="py-3 px-2 text-left relative ">
                { (inEditMode.status && inEditMode.row == i) 
                       ? <div><input 
                            onBlur={(e) => setActiveColEdit("")}
                            onFocus={(e) => setActiveColEdit("External_Desc")} 
                            onChange={(e:any) => {
                              validateMetalBasisEdit({...editRow , External_Desc : e.target.value})
                              setEditRow({...editRow , External_Desc : e.target.value}) }
                            }
                            className={`appearance-none rounded relative block w-full px-2
                            py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm  
                            ${EditRowErrors.External_Desc && "border-red-600 focus:border-red-600"}`}
                            value={editRow.External_Desc} >
                        </input>
                        <ErrorToolTip 
                          error={EditRowErrors.External_Desc} 
                          count={1}
                          type={"External_Desc"}
                          active={ActiveColEdit}
                        />
                        </div>
                       : <span className="text-sm font-medium font-semibold whitespace-normal break-all">{items.External_Desc}</span>
                    }
                </td>
                <td className="py-3 px-2 text-left relative">
                { (inEditMode.status && inEditMode.row == i) 
                       ? <div><input 
                            onBlur={(e) => setActiveColEdit("")}
                            onFocus={(e) => setActiveColEdit("Description")} 
                            onChange={(e:any) => {
                              validateMetalBasisEdit({...editRow , Description : e.target.value})
                              setEditRow({...editRow , Description : e.target.value}) }
                            }
                            className={`appearance-none rounded relative block w-full px-2
                            py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm  
                            ${EditRowErrors.Description && "border-red-600 focus:border-red-600"}`}
                            value={editRow.Description} >
                        </input>
                        <ErrorToolTip 
                          error={EditRowErrors.Description} 
                          count={1}
                          type={"Description"}
                          active={ActiveColEdit}
                        />
                        </div>
                       : <span className="text-sm font-medium font-semibold whitespace-normal break-all">{items.Description}</span>
                    }
                </td>
              
                <td className="py-3 px-2 text-left relative">
                { (inEditMode.status && inEditMode.row == i) 
                       ? <div><input 
                            onBlur={(e) => setActiveColEdit("")}
                            onFocus={(e) => setActiveColEdit("Basis_UOM")} 
                            onChange={(e:any) => {
                              validateMetalBasisEdit({...editRow , Basis_UOM : e.target.value})
                              setEditRow({...editRow , Basis_UOM : e.target.value}) }
                            }
                            className={`appearance-none rounded relative block w-full px-2
                            py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm  
                            ${EditRowErrors.Basis_UOM  && "border-red-600 focus:border-red-600"}`}
                            value={editRow.Basis_UOM} >
                        </input>
                         <ErrorToolTip 
                         error={EditRowErrors.Basis_UOM} 
                         count={1}
                         type={"Basis_UOM"}
                         active={ActiveColEdit}
                       /></div>
                       : <span className="text-sm font-medium">{items.Basis_UOM}</span>
                    }
                </td>

                <td className="py-3 px-2 text-left relative">
                { (inEditMode.status && inEditMode.row == i) 
                       ? <div><input 
                            onBlur={(e) => setActiveColEdit("")}
                            onFocus={(e) => setActiveColEdit("Rounding_Decimel")} 
                            onChange={(e:any) => {
                              validateMetalBasisEdit({...editRow , Rounding_Decimel : e.target.value})
                              setEditRow({...editRow , Rounding_Decimel : e.target.value}) }
                            }
                            className={`appearance-none rounded relative block w-full px-2
                            py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm  
                            ${EditRowErrors.Rounding_Decimel && "border-red-600 focus:border-red-600"}`}
                            value={editRow.Rounding_Decimel}
                            >
                        </input>
                        <ErrorToolTip 
                         error={EditRowErrors.Rounding_Decimel} 
                         count={1}
                         type={"Rounding_Decimel"}
                         active={ActiveColEdit}
                       />
                       </div>
                       : <span className="text-sm font-medium">{items.Rounding_Decimel}</span>
                    }
                </td>

                <td className="py-3 px-2 text-left relative">
                { (inEditMode.status && inEditMode.row == i) 
                       ? <div><input 
                            onBlur={(e) => setActiveColEdit("")}
                            onFocus={(e) => setActiveColEdit("Currency")} 
                            onChange={(e:any) => {
                              validateMetalBasisEdit({...editRow , Currency : e.target.value})
                              setEditRow({...editRow , Currency : e.target.value}) }
                            }
                            className={`appearance-none rounded relative block w-full px-2
                            py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm  
                            ${EditRowErrors.Currency  && "border-red-600 focus:border-red-600"}`}
                            value={editRow.Currency} >
                        </input>
                        <ErrorToolTip 
                         error={EditRowErrors.Currency} 
                         count={1}
                         type={"Currency"}
                         active={ActiveColEdit}
                       />
                        </div>
                       : <span className="text-sm font-medium">{items.Currency}</span>
                    }
                </td>

                <td className="py-3 px-2 text-left relative">
                { (inEditMode.status && inEditMode.row == i) 
                       ? <div><input 
                            onBlur={(e) => setActiveColEdit("")}
                            onFocus={(e) => setActiveColEdit("Base_UoM")} 
                            onChange={(e:any) => {
                              validateMetalBasisEdit({...editRow , Base_UoM : e.target.value})
                              setEditRow({...editRow , Base_UoM : e.target.value}) }
                            }
                            className={`appearance-none rounded relative block w-full px-2
                            py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm  
                            ${EditRowErrors.Base_UoM && "border-red-600 focus:border-red-600"}`}
                            value={editRow.Base_UoM} >
                        </input>
                        <ErrorToolTip 
                         error={EditRowErrors.Base_UoM} 
                         count={1}
                         type={"Base_UoM"}
                         active={ActiveColEdit}
                       />
                        </div>
                       : <span className="text-sm font-medium">{items.Base_UoM}</span>
                    }
                </td>

                <td className="py-3 px-2 text-left relative">
                { (inEditMode.status && inEditMode.row == i) 
                       ? <div><input 
                            onBlur={(e) => setActiveColEdit("")}
                            onFocus={(e) => setActiveColEdit("UoM_Conv")} 
                            onChange={(e:any) => {
                              validateMetalBasisEdit({...editRow , UoM_Conv : e.target.value})
                              setEditRow({...editRow , UoM_Conv : e.target.value}) }
                            }
                            className={`appearance-none rounded relative block w-full px-2
                            py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm  
                            ${EditRowErrors.UoM_Conv  && "border-red-600 focus:border-red-600"}`}
                            value={editRow.UoM_Conv }
                            >
                        </input>
                        <ErrorToolTip 
                         error={EditRowErrors.UoM_Conv} 
                         count={1}
                         type={"UoM_Conv"}
                         active={ActiveColEdit}
                       />
                        </div>
                       : <span className="text-sm font-medium">{items.UoM_Conv }</span>
                    }
                </td>

                <td className="py-3 px-2 text-left relative">
                { (inEditMode.status && inEditMode.row == i) 
                       ? <div><input 
                            onBlur={(e) => setActiveColEdit("")}
                            onFocus={(e) => setActiveColEdit("Loco")} 
                            onChange={(e:any) => {
                              validateMetalBasisEdit({...editRow , Loco : e.target.value})
                              setEditRow({...editRow , Loco : e.target.value}) }
                            }
                            className={`appearance-none rounded relative block w-full px-2
                            py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm  
                            ${EditRowErrors.Loco && "border-red-600 focus:border-red-600"}`}
                            value={editRow.Loco } >
                        </input>
                        <ErrorToolTip 
                         error={EditRowErrors.Loco} 
                         count={1}
                         type={"Loco"}
                         active={ActiveColEdit}
                       />
                        </div>
                      : items.Loco != "" ? <span className="text-sm font-medium">{items.Loco }</span>
                      : <span className="text-xs font-normal bg-gray-200 px-2 py-1 rounded border-gray-300">Empty</span>
                    }
                </td>

                <td className="py-3 px-2 text-left relative">
                { (inEditMode.status && inEditMode.row == i) 
                       ? <div><input 
                            onBlur={(e) => setActiveColEdit("")}
                            onFocus={(e) => setActiveColEdit("Domicile")} 
                            onChange={(e:any) => {
                              validateMetalBasisEdit({...editRow , Domicile : e.target.value})
                              setEditRow({...editRow , Domicile : e.target.value}) }
                            }
                            className={`appearance-none rounded relative block w-full px-2
                            py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm  
                            ${EditRowErrors.Domicile  && "border-red-600 focus:border-red-600"}`}
                            value={editRow.Domicile } >
                        </input>
                        <ErrorToolTip 
                         error={EditRowErrors.Domicile} 
                         count={1}
                         type={"Domicile"}
                         active={ActiveColEdit}
                       />
                        </div>
                       : <span className="text-sm font-medium">{items.Domicile }</span>
                    }
                </td>

                { isEditable && <td className="py-3 px-2 text-left relative">
                <div className="flex item-center justify-center">
                    { inEditMode.status && inEditMode.row == i &&
                    <div
                        onClick={() => onClickSave(i)}
                        className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                    </svg>
                    </div> }
                    {  inEditMode.row != i &&
                    <div 
                    onClick={() => onClickEdit(i)}
                    className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110 ">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                      </svg>
                    </div> }
                    {  inEditMode.row != i &&
                    <div className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110" onClick={ () => onClickDelete(items) }>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </div>
                    }
                     { inEditMode.status && inEditMode.row == i &&
                    <div className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110" 
                    onClick={ () =>  {setEditRowErrors({});  setActiveColEdit("");
                    setInEditMode({ status : false, row : -1 }) }}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d=" M6 18L18 6M6 6l12 12" />
                      </svg>
                    </div>
                    }

                   {/* <div className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110" onClick={() => setIsOpen(!isOpen)}>
                   <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                   <path fillRule="evenodd" d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
                  <path fillRule="evenodd" d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
                  </svg>
                     </div> */}
                  </div>
                </td> }
{/* 
                <td className="py-3 px-2 text-center">
                <div className="flex item-center justify-center">
                <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Save
            </button>

            </div>
                </td> */}

              </tr>))}
              { !InitLoading && data.length == 0 && 
              <tr className="border-b border-gray-200 bg-gray-50 py-3">
                <td className="py-3 px-2 text-center relative" colSpan={isEditable ? 11 : 10} >
                  <span className="text-sm font-medium" >No Metal Basis Records Found !</span>
                </td>
              </tr> }
            </tbody>
          </table>
        </div>

   
  );
  };

  const sampleData = [{
    Basis_Code: "XAG",
    External_Desc : "XAG",
    Description : "Silver Spot Price",
    Basis_UOM : "Ozt",
    Rounding_Decimel:"4",
    Currency :"USD" ,
    Base_UoM :"KGS" ,
    UoM_Conv :"0.011312	" ,
    Loco :"" ,
    Domicile :"International",
  },{
    Basis_Code: "LBMAFIX",
    External_Desc : "LBMAFIX",
    Description : "LBMA Silver AM Fix",
    Basis_UOM : "KGS",
    Rounding_Decimel:"4",
    Currency :"USD" ,
    Base_UoM :"KGS" ,
    UoM_Conv :"0.011312	" ,
    Loco :"" ,
    Domicile :"International",
  },{
    Basis_Code: "Agcust",
    External_Desc : "Ag import",
    Description : "Custom Notify Rate",
    Basis_UOM : "KGS",
    Rounding_Decimel:"2",
    Currency :"USD" ,
    Base_UoM :"g" ,
    UoM_Conv :"1000" ,
    Loco :"" ,
    Domicile :"International",
  }]

 
  export default MetalBasisTable;