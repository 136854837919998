import { useEffect, useState } from "react";
import { EditMetalMaster, GetMetalByID, CreateMetal } from './Metal.Service'
import { useHistory, useParams} from "react-router-dom";
import {  toast } from "react-toastify";
import ErrorToolTip  from './ErrorToolTip'
import {useAuthStore} from "../../Store/AuthStore";
interface PropsType {
  metal: any;
  setMetalFunction: any,
  isEditable: boolean,
}

const MetalMasterTable: React.FC<PropsType> = ( {metal, setMetalFunction , isEditable} ) => {
    const {tokens} = useAuthStore();
    let history = useHistory();
    const [data , setData] = useState<any>([]);
    const [inEdit, setInEdit] = useState<boolean>(false);
    let { id } = useParams<any>();

    //form values
    const [inCreate , setInCreateMode] = useState<boolean>(false);
    const [Metal_code, setMetalCode] = useState<string>("");
    const [Description, setDescription] = useState<string>("");
    const [UOM, setUOM] = useState<string>("");
    const [Minimum_Quantity, setMinimumQuantity] = useState<string>("");
    const [Rounding, setRounding] = useState<string>("");
    const [Active, setActive] = useState<string>("");
    const [metalId, setMetalId] = useState<number>(0);
    const [CreateRowErrors , setCreateRowErrors] = useState<any>({});
    const [EditRowErrors , setEditRowErrors] = useState<any>({});
    const [CreateRowSubmitCount , setCreateRowSubmitCount] = useState<number>(0);
    const [ActiveColCreate , setActiveColCreate] = useState<string>("");
    const [ActiveColEdit , setActiveColEdit] = useState<string>("");

    useEffect( () => {
      setData(metal ? metal : []);
      console.log("Metal form master: ", inCreate)
      // fetchMetal();
    }, [metal])

    const fetchMetal = () => {
      GetMetalByID(data[0].id).then((data: any) => {
        let a = [];
        a.push(data.data.data)
        setData(a);
        console.log("fetch data: ", data)
      }).catch(err => {
        console.log(err)
      })
    }

    const fetchMetalOnSave = (id: number) => {
      GetMetalByID(id).then((data: any) => {
        let a = [];
        a.push(data.data.data)
        setMetalFunction(a);
        setData(a);
      }).catch(err => {
        console.log(err)
      })
    }

    const onEditClicked = (e: any, item: any) => {
      e.preventDefault();
      setDescription(item.Description)
      setUOM(item.UOM)
      setMinimumQuantity(item.Minimum_Quantity)
      setRounding(item.Rounding)
      setMetalId(item.id);
      setActive(item.Enabled)
      setInEdit(!inEdit)
    } 

    const onCancelClicked = (e: any) => {
      e.preventDefault();
      setDescription("")
      setUOM("")
      setMinimumQuantity("")
      setRounding("")
      setMetalId(0);
      setInEdit(!inEdit)
      setEditRowErrors({});
      setActiveColEdit("");
    }

    const onSaveClicked = (e: any) => {
      e.preventDefault();
      setCreateRowErrors({});
      if(validateMetalEdit({})) {
        EditMetalMaster(metalId, {
          Description: Description.trim(),
          UOM: UOM.trim(),
          Minimum_Quantity: Minimum_Quantity,
          Rounding: Rounding,
          Updated_User: "",
          Enabled : Active
        }).then(data => {
          setInEdit(!inEdit);
          fetchMetal();
          toast.success("Updated Successfully!");
        }).catch(err => {
          toast.error("Something Went Wrong!");
        })
      }
      
    }

    const validateMetalEdit = (data:any) => {
      let result = true;
      let errors:any = {};
      let state = latest_state(data);
      console.log(state);
     
      if(state.Description.length == 0){
        result = false;
        errors.Description = "Description can't be empty!"
      }else{
        errors.Description = ""
      }
      if(state.UOM.length == 0){
        result = false;
        errors.UOM = "UOM can't be empty!"
      }else{
        errors.UOM = ""
      }
      if(state.Minimum_Quantity.length == 0){
        result = false;
        errors.Minimum_Quantity = "Minimum Quantity can't be empty!"
      }else if(isNaN(state.Minimum_Quantity)){
        result = false;
        errors.Minimum_Quantity = "Minimum Quantity must be a number!"
      }else if(state.Minimum_Quantity <= 0 ){
        result = false;
        errors.Minimum_Quantity = "Minimum Quantity can't be less than zero!"
      }
      else if(!Number.isInteger( parseFloat(state.Minimum_Quantity))){
        result = false;
        errors.Minimum_Quantity = "Minimum Quantity must be an integer !"
      }
      else{
        errors.Minimum_Quantity = ""
      }

      if(state.Rounding.length == 0){
        result = false;
        errors.Rounding = "Rounding can't be empty!"
      }else if(isNaN(state.Rounding)){
        result = false;
        errors.Rounding = "Rounding must be a number!"
      }else if(state.Rounding <= 0 ){
          result = false;
          errors.Rounding = "Rounding can't be less than zero!"
      }
      else if(!Number.isInteger( parseFloat(state.Rounding))){
        result = false;
        errors.Rounding = "Rounding must be an integer !"
      }
      else{
        errors.Rounding = ""
      }
      setEditRowErrors(errors);
      return result;
    }

    const setEmptyValues = () => {
      setMetalCode("");
      setDescription("");
      setUOM("");
      setMinimumQuantity("");
      setRounding("");
      setActive("Y")
    }

    const onClickSave = async (e: any) => {
      e.preventDefault();
      setCreateRowSubmitCount(1);
      if(validateMetalCreate({})) {
        CreateMetal({
          Metal_code: Metal_code.trim(),
          Description: Description.trim(),
          UOM: UOM.trim(),
          Minimum_Quantity: Minimum_Quantity,
          Rounding: Rounding,
          Created_User: "",
        },tokens).then((result: any) => {
          toast.success("Success!");
          window.location.replace(`/metal-master/edit/${result.data.data.id}`);
        }).catch(err => {
          if(err.response && err.response.data && err.response.data.message){
            toast.error(err.response.data.message);
          }else{
            toast.error( "Something went wrong");
          }
          
        })
      } 
    }

    const latest_state = (data:any) => {
      return {
        Metal_code,Description,UOM,Minimum_Quantity,Rounding,
        ...data
      }
    }

    const validateMetalCreate = (data:any) => {
      let result = true;
      let errors:any = {};
      let state = latest_state(data);
      console.log(state);
      if(state.Metal_code.length == 0){
        result = false;
        errors.Metal_code = "Metal Code can't be empty!"
      }else{
        errors.Metal_code = ""
      }
      if(state.Description.length == 0){
        result = false;
        errors.Description = "Description can't be empty!"
      }else{
        errors.Description = ""
      }
      if(state.UOM.length == 0){
        result = false;
        errors.UOM = "UOM can't be empty!"
      }else{
        errors.UOM = ""
      }
      if(state.Minimum_Quantity.length == 0){
        result = false;
        errors.Minimum_Quantity = "Minimum Quantity can't be empty!"
      }else if(isNaN(state.Minimum_Quantity)){
        result = false;
        errors.Minimum_Quantity = "Minimum Quantity must be a number!"
      }else if(state.Minimum_Quantity <= 0 ){
        result = false;
        errors.Minimum_Quantity = "Minimum Quantity can't be less than zero!"
      }
      else{
        errors.Minimum_Quantity = ""
      }
      if(state.Rounding.length == 0){
        result = false;
        errors.Rounding = "Rounding can't be empty!"
      }else if(isNaN(state.Rounding)){
        result = false;
        errors.Rounding = "Rounding must be a number!"
      }else if(state.Rounding <= 0 ){
          result = false;
          errors.Rounding = "Rounding can't be less than zero!"
      }else{
        errors.Rounding = ""
      }
      setCreateRowErrors(errors);
      return result;
    } 

    const createNewRow = () => {
      return (
        <tr key={0} className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100">
          <td className="py-3 px-3 text-left relative">
            <input 
                onBlur={(e) => setActiveColCreate("")}
                onFocus={(e) => setActiveColCreate("Metal_code")} 
              className={`appearance-none rounded relative block w-full px-2
                     py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                    focus:outline-none text-sm
                    ${ CreateRowSubmitCount > 0 && CreateRowErrors.Metal_code && "border-red-600 focus:border-red-600"}`}
              value={Metal_code}
              placeholder="Metal Code"  
              onChange={(e) => { 
                validateMetalCreate({Metal_code:e.target.value});
                setMetalCode(e.target.value) }}
            />
            <ErrorToolTip 
                  error={CreateRowErrors.Metal_code} 
                  count={CreateRowSubmitCount}
                  type={"Metal_code"}
                  active={ActiveColCreate}
            />
          </td>
          <td className="py-3 px-3 text-left relative">
            <input 
                onBlur={(e) => setActiveColCreate("")}
                onFocus={(e) => setActiveColCreate("Description")} 
              className={`appearance-none rounded relative block w-full px-2
                     py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                    focus:outline-none text-sm
                    ${ CreateRowSubmitCount > 0 && CreateRowErrors.Description && "border-red-600 focus:border-red-600"}`}
              value={Description}  
              placeholder="Description"
              onChange={(e) => { 
                validateMetalCreate({Description:e.target.value});
                setDescription(e.target.value) }}
            />
            <ErrorToolTip 
                  error={CreateRowErrors.Description} 
                  count={CreateRowSubmitCount}
                  type={"Description"}
                  active={ActiveColCreate}
            />
          </td>
          <td className="py-3 px-3 text-left relative">
            <input 
                onBlur={(e) => setActiveColCreate("")}
                onFocus={(e) => setActiveColCreate("UOM")} 
              className={`appearance-none rounded relative block w-full px-2
                     py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                    focus:outline-none text-sm
                    ${ CreateRowSubmitCount > 0 && CreateRowErrors.UOM && "border-red-600 focus:border-red-600"}`}
              value={UOM}  
              placeholder="UOM"
              onChange={(e) => { 
                validateMetalCreate({UOM:e.target.value});
                setUOM(e.target.value) }}
            />
            <ErrorToolTip 
                  error={CreateRowErrors.UOM} 
                  count={CreateRowSubmitCount}
                  type={"UOM"}
                  active={ActiveColCreate}
            />
          </td>
          <td className="py-3 px-3 text-left relative">
            <input 
                onBlur={(e) => setActiveColCreate("")}
                onFocus={(e) => setActiveColCreate("Minimum_Quantity")} 
                className={`appearance-none rounded relative block w-full px-2
                     py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                    focus:outline-none text-sm
                    ${ CreateRowSubmitCount > 0 && CreateRowErrors.Minimum_Quantity && "border-red-600 focus:border-red-600"}`}
                value={Minimum_Quantity}  
                placeholder="Min Quantity"
                onChange={(e) => { 
                  validateMetalCreate({Minimum_Quantity:e.target.value});
                  setMinimumQuantity(e.target.value) }}
              />
              <ErrorToolTip 
                  error={CreateRowErrors.Minimum_Quantity} 
                  count={CreateRowSubmitCount}
                  type={"Minimum_Quantity"}
                  active={ActiveColCreate}
            />
          </td>
          <td className="py-3 px-3 text-left relative">
            <input 
                onBlur={(e) => setActiveColCreate("")}
                onFocus={(e) => setActiveColCreate("Rounding")} 
              className={`appearance-none rounded relative block w-full px-2
                     py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                    focus:outline-none text-sm
                    ${ CreateRowSubmitCount > 0 && CreateRowErrors.Rounding && "border-red-600 focus:border-red-600"}`}
              value={Rounding}  
              placeholder="Rounding"
              onChange={(e) => { 
                validateMetalCreate({Rounding:e.target.value});
                setRounding(e.target.value) }}
            />
            <ErrorToolTip 
                  error={CreateRowErrors.Rounding} 
                  count={CreateRowSubmitCount}
                  type={"Rounding"}
                  active={ActiveColCreate}
            />
          </td>
          <td className="py-3 px-3 text-left">
            <span className="font-semibold bg-green-200 text-green-600 py-1 px-4 rounded-full text-xs">Enabled</span>
          </td>
          <td>
            <div className="flex item-center justify-center">
              <span className="mx-3 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full text-xs cursor-pointer" onClick = {(e) => onClickSave(e)}>
                Save
              </span>
            </div>
          </td>
        </tr>
    )
  }

    const renderTableRow = (item: any) => {
      return (
        <tr key={item.id} className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100">
                  <td className="py-3 px-3 text-left">
                    <span className="font-semibold">{item.Metal_code}</span>
                  </td>
                  <td className="py-3 px-3 text-left relative">
                    {(inEdit) ? 
                    <div>
                      <input 
                       onBlur={(e) => setActiveColEdit("")}
                       onFocus={(e) => setActiveColEdit("Description")}
                      className={`appearance-none rounded relative block w-full px-2
                      py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                     focus:outline-none text-sm
                     ${ EditRowErrors.Description && "border-red-600 focus:border-red-600"}`}
                      value={Description}  
                      placeholder="Enter a value..."
                      onChange={(e) => { 
                        validateMetalEdit({Description:e.target.value});
                        setDescription(e.target.value) }}
                      />
                      <ErrorToolTip 
                          error={EditRowErrors.Description} 
                          count={1}
                          type={"Description"}
                          active={ActiveColEdit}
                        />
                    </div>
                     : <span className="font-semibold font-semibold whitespace-normal break-all">{item.Description}</span>}
                  </td>
                  <td className="py-3 px-3 text-left relative">
                    {(inEdit) ? 
                    <div>
                    <input 
                      onBlur={(e) => setActiveColEdit("")}
                      onFocus={(e) => setActiveColEdit("UOM")}
                       className={`appearance-none rounded relative block w-full px-2
                       py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                      focus:outline-none text-sm
                      ${ EditRowErrors.UOM && "border-red-600 focus:border-red-600"}`}
                      value={UOM}  
                      placeholder="Enter a value..."
                      onChange={(e) => { 
                        validateMetalEdit({UOM:e.target.value});
                        setUOM(e.target.value) }}
                      />
                       <ErrorToolTip 
                          error={EditRowErrors.UOM} 
                          count={1}
                          type={"UOM"}
                          active={ActiveColEdit}
                        />
                    </div>
                     : <span className="font-semibold">{item.UOM}</span>}
                  </td>
                  <td className="py-3 px-3 text-left relative">
                    {(inEdit) ? 
                     <div>
                     <input 
                        onBlur={(e) => setActiveColEdit("")}
                        onFocus={(e) => setActiveColEdit("Minimum_Quantity")}
                         className={`appearance-none rounded relative block w-full px-2
                         py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                        focus:outline-none text-sm
                        ${ EditRowErrors.Minimum_Quantity && "border-red-600 focus:border-red-600"}`}
                        value={Minimum_Quantity}  
                        placeholder="Enter a value..."
                        onChange={(e) => { 
                          validateMetalEdit({Minimum_Quantity:e.target.value});
                          setMinimumQuantity(e.target.value) }}
                        />
                        <ErrorToolTip 
                          error={EditRowErrors.Minimum_Quantity} 
                          count={1}
                          type={"Minimum_Quantity"}
                          active={ActiveColEdit}
                        />
                        </div>
                      : <span className="font-semibold">{item.Minimum_Quantity}</span>}
                  </td>
                  <td className="py-3 px-3 text-left relative">
                    {(inEdit) ? 
                    <div>
                    <input 
                      onBlur={(e) => setActiveColEdit("")}
                      onFocus={(e) => setActiveColEdit("Rounding")}
                      className={`appearance-none rounded relative block w-full px-2
                      py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                     focus:outline-none text-sm
                     ${ EditRowErrors.Rounding && "border-red-600 focus:border-red-600"}`}
                      value={Rounding}  
                      placeholder="Enter a value..."
                      onChange={(e) => { 
                        validateMetalEdit({Rounding:e.target.value});
                        setRounding(e.target.value) }}
                      />
                       <ErrorToolTip 
                          error={EditRowErrors.Rounding} 
                          count={1}
                          type={"Rounding"}
                          active={ActiveColEdit}
                        />
                      </div>
                      : <span className="font-semibold">{item.Rounding}</span>}
                  </td>
                  <td className="py-3 px-3 text-left">
                  {(inEdit) ? 
                    <select 
                    className={`appearance-none rounded  block w-full px-2
                    py-1 border border-gray-300 placeholder-gray-500 text-gray-900
                   focus:outline-none text-sm`}
                      value={Active} 
                      onChange={(e) => setActive(e.target.value) }>
                      <option value="Y" selected={Active == "Y"}>Enabled</option>
                      <option value="N" selected={Active  == "N" }>Disabled</option>
                    </select>
                    :(item.Enabled == "Y")
                    ? <span className="font-semibold bg-green-200 text-green-600 py-1 px-4 rounded-full text-xs">Enabled</span>
                    : <span className="font-semibold bg-red-200 text-red-600 py-1 px-4 rounded-full text-xs">Disabled</span>
                    }

                  </td>
                  {isEditable && <td>
                    {(inEdit == true) ? 
                    // save
                      <div className="flex item-center justify-center">
                        <span className="mx-3 bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full text-xs cursor-pointer" onClick={ (e) => onSaveClicked(e)}>
                          Save
                        </span>
                        
                        {/* cancel */}
                        <span className="mx-3 bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-4 rounded-full text-xs cursor-pointer" onClick={ (e) => onCancelClicked(e)}>
                          Cancel
                        </span>
                      </div>
                     : 
                    // edit
                      <div className="flex item-center justify-center">
                        <span className="mx-3 bg-blue-500 hover:bg-purple-950 text-white font-bold py-1 px-4 rounded-full text-xs cursor-pointer" onClick={ (e) => onEditClicked(e, item)}>
                          Edit
                        </span>
                      </div> 
                    }
                  </td>}
                </tr>
      )
    } 

    return (
    <div className="my-6">
        <h6 className="text-xl font-bold text-left font-normal leading-normal mt-0 mb-2 text-gray-800 ">
          Metal Master
          
        </h6>
    <table className="w-full table-fixed rounded-lg">
    <thead>
              <tr className="sticky top-0 px-6 py-3 text-green-900 bg-purple-300">
                <th className="py-3 px-3 text-left">Metal Code</th>
                <th className="py-3 px-3 text-left">Description</th>
                <th className="py-3 px-3 text-left">UOM</th>
                <th className="py-3 px-3 text-left">Minimum qty</th>
                <th className="py-3 px-3 text-left">Rounding</th>
                <th className="py-3 px-3 text-left z-10">Active</th>
                {isEditable && <th className="py-3 px-3 text-left z-10">Actions</th>}
               
              </tr>
            </thead>
            <tbody className="text-gray-800 text-sm font-light">
              {id == -1 && data.length == 0 ? createNewRow() : <></>}
              { data.map( (item: any) => renderTableRow(item) )}
            </tbody>
      
      </table>
      
    </div>
   
  );
  };

  const sampleData = [{
    Metal_Code: " Ag",
    Description: "Silver",
    UOM : "g",
    Minimum_qty : "1",
    Rounding:"0.1",
    Enabled :"" ,
   
  },]


  export default MetalMasterTable;