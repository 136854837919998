/**
 * STATUS
 */
export const STATUS = [
  { label: "CREATED", value: "CREATED" },
  { label: "PENDING", value: "PENDING" },
  { label: "PAYMENT PENDING", value: "PAYMENT_PENDING" },
  { label: "PARTIAL COMPLETED", value: "PARTIAL_COMPLETED" },
  { label: "COMPLETED", value: "COMPLETED" },
  { label: "CANCELLED", value: "CANCELLED" },
  { label: "INVOICE UPLOAD PENDING", value: "INVOICE_UPLOAD_PENDING" },
  { label: "INVOICE_APPROVAL_PENDING", value: "INVOICE_APPROVAL_PENDING" },
  {
    label: "BANK_TRANSFER_APPROVAL_PENDING",
    value: "BANK_TRANSFER_APPROVAL_PENDING",
  },
];

export const ORDER_BY = [
  { label: "ORDER REFERENCE DECS", value: "eb_order_ref/desc" },
  { label: "ORDER REFERENCE ASC", value: "eb_order_ref/asc" },
  { label: "TYPE DESC", value: "type/desc" },
  { label: "TYPE ASC", value: "type/asc" },
  { label: "STATUS DESC", value: "status/desc" },
  { label: "STATUS ASC", value: "status/asc" },
  { label: "ACCOUNT ID DESC", value: "account_id/desc" },
  { label: "ACCOUNT ID ASC", value: "account_id/asc" },
  { label: "CUSTOMER ID DESC", value: "customer_id/desc" },
  { label: "CUSTOMER ID ASC", value: "customer_id/asc" },
  { label: "CURRENCY DESC", value: "currency/desc" },
  { label: "CURRENCY ASC", value: "currency/asc" },
  { label: "QUOTATION DESC", value: "quotation_total/desc" },
  { label: "QUOTATION ASC", value: "quotation_total/asc" },
  { label: "ORDER DATE DESC", value: "created_at/desc" },
  { label: "ORDER DATE ASC", value: "created_at/asc" },
];
