import Layout from "../../Layout/Layout";
import React, {useEffect, useState} from "react";
import moment from "moment";
import TaxCategoryService from "../Services/tax-category.service"
import Loader from"../../../Components-ui/Loader"
import {toast, ToastContainer} from "react-toastify";
import TaxCategoryForm from "./TaxCategoryForm";
import {useAuthStore} from "../../../Store/AuthStore";
import {Pagination} from "../../../Components-ui/Pagination";
import {makeQuery} from "../../../Common/util.helper";
import {TYPES} from "../Utils/enum";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";

interface PropsType {
}

const TaxCategoryTable: React.FC<PropsType> = () => {

    const { logout, permissions, userData , tokens } = useAuthStore();

    const [MetaData , setMetaData] = useState<any>({});
    const [CurrentPage , setCurrentPage] = useState<number>(0);
    const [Loading, setLoading] = useState<any>(false);
    const [TaxCategories, setTaxCategories] = useState<any>([]);
    const [OpenModal, setOpenModal] = useState(false);
    const [Validator, setValidator] = useState(false);
    const [TaxCategory, setTaxCategory] = useState<any>({
        name: "",
        type: "",
        categoryCode: "",
        options: "",
        active: "ENABLED",
        createdBy: "string"
    })
    const [FilterData, setFilterData] = useState<any>({
        categoryCode: "",
        stateType: "",
        stateName: "",
        active: "",
    });


    useEffect((): any => {
        getAllTaxCategories(CurrentPage)
        // eslint-disable-next-line
    }, []);


    /**
     * Validate main rule fields according to their data types
     * @param e
     */
    const onChangeTaxCategory = (e: any) => {
        setTaxCategory({
            ...TaxCategory,
            [e.target.name]: e.target.value,
        });

        return setValidator(true);
    };


    /**
     * Get all tax categories
     * @param page
     * @param isReset
     */
    const getAllTaxCategories = async (page: number, isReset: boolean = false) => {
        setLoading(true)
        let cloneFilterData = !isReset ? {...FilterData} : {
            categoryCode: "",
            stateType: "",
            stateName: "",
            active: "",
        }
        const query = makeQuery(cloneFilterData);
        let data: any = await TaxCategoryService.getAllTaxCategories(page, tokens, query);
        if (data.status === 200 && data.data.data) {
            setCurrentPage(page)
            setMetaData({
                current: data.data.meta.page,
                pages: data.data.meta.totalPages,
            })
            setTaxCategories(data ? data.data.data.result : []);
        }
        setLoading(false)
    };

    /**
     * Handle filters change event
     * @param e
     */
    const handleChangeFilter = (e: any) => {
        const {name, value} = e.target
        setFilterData({
            ...FilterData,
            [name]: value
        });
    }

    /**
     * Reset filters to initial state
     * @param e
     */
    const resetFilterData = async (e: any) => {
        e.preventDefault()
        setFilterData({
            categoryCode: "",
            stateType: "",
            stateName: "",
            active: "",
        })

        setCurrentPage(0)
        await getAllTaxCategories(0, true)
    }

    /**
     * Close modal
     */
    const closeModal = () => {
        setOpenModal(false)
    }

    /**
     * Open tax category modal
     */
    const onAddTaxCategory = () => {
        setInitialState()
        setOpenModal(true)
    }

    /**
     * Enable or disable tax category
     * @param e
     * @param data
     */
    const enableDisableTaxCategory = async (e: any, data:any) => {
        setLoading(true)
        let cloneData = {...data}

        cloneData.active = e.target.checked ? 'ENABLED' : 'DISABLED';
        await TaxCategoryService.updateTaxCategory(cloneData, tokens);
        await getAllTaxCategories(CurrentPage);
        setLoading(false)
        return toast.success("Tax Category was successfully updated");
    }


    /**
     * Get tax category details
     * @param id
     */
    const getTaxCategory = (id: any) => {
        let taxCategory: any = TaxCategories.filter((category:any) => category.id === id);
        if (taxCategory.length > 0) {
            setTaxCategory(taxCategory[0])
            setOpenModal(true)
        }
    }


    /**
     * Set category details to initial state
     */
    const setInitialState = () => {
        setTaxCategory({
            name: "",
            type: "",
            categoryCode: "",
            options: "",
            active: "ENABLED",
            createdBy: "string"
        })
    }

    return (
        <Layout type={"TaxModule"}>
            <ToastContainer />
            <div className="flex justify-between items-center mb-5">
                <h1 className="text-2xl font-bold tracking-tight">
                    {"Tax Categories"}
                </h1>
                {
                    HasAnyAccess(['tax.category.master.create']) && (
                        <button
                            className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
                            type="button"
                            onClick={onAddTaxCategory}
                        >
                            + Add Tax Category
                        </button>
                    )
                }
            </div>

            <div
                className="w-full rounded border-gray-200 overflow-hidden shadow-lg dark:border-gray-700 bg-white">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Filter Tax Categories</h3>
                </div>
                <hr/>
                <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
                    <div className="col-span-12 sm:col-span-3">
                        <label htmlFor="categoryCode" className="block text-sm font-medium text-gray-700">
                            Code
                        </label>
                        <input
                            type="text"
                            name="categoryCode"
                            id="categoryCode"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e: any) => {
                                handleChangeFilter(e)
                            }}
                            value={FilterData.categoryCode}
                        />
                    </div>
                    {/*<div className="col-span-12 sm:col-span-3">*/}
                    {/*    <label htmlFor="stateType" className="block text-sm font-medium text-gray-700">*/}
                    {/*        Type*/}
                    {/*    </label>*/}
                    {/*    <select*/}
                    {/*        id="stateType"*/}
                    {/*        name="stateType"*/}
                    {/*        autoComplete="off"*/}
                    {/*        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"*/}
                    {/*        onChange={(e: any) => {*/}
                    {/*            handleChangeFilter(e)*/}
                    {/*        }}*/}
                    {/*        value={FilterData.stateType}*/}
                    {/*    >*/}
                    {/*        <option value="">All</option>*/}
                    {/*        {*/}
                    {/*            TYPES.map((type: any, index: number) => {*/}
                    {/*                return (*/}
                    {/*                    <option key={index} value={type.value}>{type.label}</option>*/}
                    {/*                )*/}

                    {/*            })*/}
                    {/*        }*/}
                    {/*    </select>*/}
                    {/*</div>*/}
                    <div className="col-span-12 sm:col-span-3">
                        <label htmlFor="stateName" className="block text-sm font-medium text-gray-700">
                            Name
                        </label>
                        <input
                            type="text"
                            name="stateName"
                            id="stateName"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e: any) => {
                                handleChangeFilter(e)
                            }}
                            value={FilterData.stateName}
                        />
                    </div>
                    <div className="col-span-12 sm:col-span-3">
                        <label htmlFor="active" className="block text-sm font-medium text-gray-700">
                            Status
                        </label>
                        <select
                            id="active"
                            name="active"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e: any) => {
                                handleChangeFilter(e)
                            }}
                            value={FilterData.active}
                        >
                            <option value="">All</option>
                            <option value="ENABLED">ENABLED</option>
                            <option value="DISABLED">DISABLED</option>

                        </select>
                    </div>
                    <div className="col-span-12 sm:col-span-12 flex justify-end">
                        <button
                            className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                            type="button"
                            onClick={() => getAllTaxCategories(0)}
                        >
                            Filter
                        </button>
                        <button
                            className="text-white bg-red-600 border border-red-300 rounded-md background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                            type="button"
                            onClick={(e) => resetFilterData(e)}
                        >
                            Reset
                        </button>

                    </div>
                    <div className="col-span-12 sm:col-span-12"> <hr/></div>
                    <div className="col-span-12 sm:col-span-12">
                        {
                            Loading ? (<Loader/>) : (
                                    <div className="border bg-white rounded border-gray-200 shadow">

                                        <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                                            <thead>
                                            <tr className="text-left border-b border-gray-300">
                                                <th className="sticky top-0 px-3 py-2 bg-gray-800">Name</th>
                                                <th className="sticky top-0 px-3 py-2 bg-gray-800">Code</th>
                                                <th className="sticky top-0 px-3 py-2 bg-gray-800">Date</th>
                                                {
                                                    HasAnyAccess([ 'tax.category.master.edit']) && (
                                               <>
                                                   <th className="sticky top-0 px-3 py-2 bg-gray-800">Status</th>
                                                   <th className="sticky top-0 px-3 py-2 bg-gray-800">Actions</th>
                                               </>
                                                    )}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {(0 !== TaxCategories.length) ?
                                                TaxCategories.map((category:any, index:number) => {
                                                    return (
                                                        <tr key={index} className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                            <td className="py-3 px-3 text-left relative">
                                                                {category.name}
                                                            </td>
                                                            <td className="py-3 px-3 text-left relative">
                                                                {category.categoryCode}
                                                            </td>
                                                            <td className="py-3 px-3 text-left relative">
                                                                {moment(category.createdAt).format("DD-MM-YYYY HH:mm")}
                                                            </td>
                                                                {
                                                                    HasAnyAccess(['tax.category.master.edit']) && (
                                                                        <td className="py-3 px-3 text-left relative">

                                                                        <div
                                                                    className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="toggle"
                                                                        id={`${category.id}toggle`}
                                                                        checked={category.active === 'ENABLED'}
                                                                        className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                                                                        onChange={(e) => enableDisableTaxCategory(e, category)}
                                                                    />
                                                                    <label htmlFor={`${category.id}toggle`}
                                                                           className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"/>
                                                                </div>
                                                            </td>)}
                                                                {
                                                                    HasAnyAccess(['tax.category.master.edit']) && (
                                                                        <td className="py-3 px-3 text-left relative">

                                                                        <button
                                                                            className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                                            type="button"
                                                                            onClick={() => getTaxCategory(category.id)}
                                                                        >
                                                                            Edit
                                                                        </button>

                                                            </td>   )
                                                                }
                                                        </tr>
                                                    )
                                                })
                                                : (<tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                                    <td colSpan={9} className="text-center py-3 px-3 text-left relative">No data to
                                                        display
                                                    </td>
                                                </tr>)
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                )
                        }
                    </div>
                </div>
                <div className="col-span-12 sm:col-span-12 px-4 pt-2">
                    {/*Pagination Bar*/}
                    {
                        !Loading && (
                            <Pagination
                                meta={MetaData}
                                handlePageChange={(number:number) => getAllTaxCategories(number)}
                            />
                        )
                    }

                </div>
            </div>
            {
                OpenModal && (
                    <TaxCategoryForm
                        onCloseModal={closeModal}
                        taxCategory={TaxCategory}
                        onChangeTaxCategory={onChangeTaxCategory}
                        getAllTaxCategories={getAllTaxCategories}
                        validatorRef={Validator}
                        page={CurrentPage}
                    />
                )
            }
        </Layout>
    );
};

export default TaxCategoryTable;
