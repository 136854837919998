import Http from "../../../library/http.library";
import {httpMethods} from "../../../library/enum";

class TaxService {

    /**
     * Get all taxes
     * @param page
     * @param tokens
     * @param query
     * @param isPaginated
     */
    async getAllTaxes(page:number, tokens: any, query: any, isPaginated: boolean = true) {
        return await Http.setMethod(httpMethods.get).setUrl(`/tax-master?page=${page}${query}${!isPaginated ? `&isPaginated=false` : ``}`).setToken(tokens.idToken.jwtToken).request();
    }

    /**
     * Add new tax
     * @param data
     * @param tokens
     */
    async addTax(data: any, tokens: any) {
        return await Http.setMethod(httpMethods.post).setUrl(`/tax-master`).setData([data],).setToken(tokens.idToken.jwtToken).request();

    }

    /**
     * Update tax details
     * @param data
     * @param tokens
     */
    async updateTax(data: any, tokens: any) {
        return Http.setMethod(httpMethods.patch).setUrl(`/tax-master/${data.id}`).setData(data).setToken(tokens.idToken.jwtToken).request();

    }
}

export default new TaxService();
