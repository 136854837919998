import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";

interface PropsType {
    onCloseModal: any,
    data: any,
}

const WareHouseAddressDetails: React.FC<PropsType> = ({
    onCloseModal,
    data,
}) => {


    useEffect(() => {
    }, [data]);



    return (
        <>
            {
                <div
                    className=" w-full bg-gray-800 bg-opacity-50 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-4xl">
                        <div
                            className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
                            <div className="flex flex-row space-x-1">
                                <div className="w-6/12">
                                    <div
                                        className="items-start justify-between px-6 py-4 border-b border-solid border-blue bg-gray-100 rounded-t">
                                        <h3 className="text-xl font-semibold">Shipping Address</h3>
                                    </div>
                                    <div className="w-full max-w-xs">
                                        <form className="bg-white px-8 pt-6 pb-8 mb-4">
                                            <div className="mb-4">
                                                <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
                                                    Address Line 1
                                                </label>
                                                <div className="text-sm font-medium mb-2 py-2 px-3 text-gray-500" for="username">
                                                    {data.addressLine1 ?? "-"}
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
                                                    Address Line 2
                                                </label>
                                                <div className="text-sm font-medium mb-2 py-2 px-3 text-gray-500" for="username">
                                                    {data.addressLine2 ?? "-"}
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
                                                    City
                                                </label>
                                                <label className="text-sm font-medium mb-2 py-2 px-3 text-gray-500" for="username">
                                                    {data.city ?? "-"}
                                                </label>
                                            </div>
                                            <div className="mb-4">
                                                <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
                                                    State
                                                </label>
                                                <label className="text-sm font-medium mb-2 py-2 px-3 text-gray-500" for="username">
                                                    {data.state ?? "-"}
                                                </label>
                                            </div>
                                            <div className="mb-4">
                                                <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
                                                    Pin Code
                                                </label>
                                                <label className="text-sm font-medium mb-2 py-2 px-3 text-gray-500" for="username">
                                                    {data.pincode ?? "-"}
                                                </label>
                                            </div>
                                            <div className="mb-4">
                                                <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
                                                    GSTIN
                                                </label>
                                                <label className="text-sm font-medium mb-2 py-2 px-3 text-gray-500" for="username">
                                                    {data.gstin ?? "-"}
                                                </label>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="w-6/12">
                                    <div
                                        className="items-start justify-between px-6 py-4 border-b border-solid border-blue bg-gray-100 rounded-t">
                                        <h3 className="text-xl font-semibold">Billing Address</h3>
                                    </div>
                                    <div className="w-full max-w-xs">
                                        <form className="bg-white px-8 pt-6 pb-8 mb-4">
                                            <div className="mb-4">
                                                <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
                                                    Address Line 1
                                                </label>
                                                <div className="text-sm font-medium mb-2 py-2 px-3 text-gray-500" for="username">
                                                    {data.billingAddressLine1 ?? "-"}
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
                                                    Address Line 2
                                                </label>
                                                <div className="text-sm font-medium mb-2 py-2 px-3 text-gray-500" for="username">
                                                    {data.billingAddressLine2 ?? "-"}
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
                                                    City
                                                </label>
                                                <label className="text-sm font-medium mb-2 py-2 px-3 text-gray-500" for="username">
                                                    {data.billingCity ?? "-"}
                                                </label>
                                            </div>
                                            <div className="mb-4">
                                                <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
                                                    State
                                                </label>
                                                <label className="text-sm font-medium mb-2 py-2 px-3 text-gray-500" for="username">
                                                    {data.billingState ?? "-"}
                                                </label>
                                            </div>
                                            <div className="mb-4">
                                                <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
                                                    Pin Code
                                                </label>
                                                <label className="text-sm font-medium mb-2 py-2 px-3 text-gray-500" for="username">
                                                    {data.billingPincode ?? "-"}
                                                </label>
                                            </div>
                                            <div className="mb-4">
                                                <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
                                                    GSTIN
                                                </label>
                                                <label className="text-sm font-medium mb-2 py-2 px-3 text-gray-500" for="username">
                                                    {data.billingGstin ?? "-"}
                                                </label>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>



                            <div
                                className="flex items-center justify-end sm:py-3  sm:px-6 border-t border-solid border-blueGray-200 rounded-b">
                                <div>
                                    <button
                                        id="modal"
                                        className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={onCloseModal}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            }
        </>
    );
};

export default WareHouseAddressDetails;
