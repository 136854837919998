
interface PropsType {
    error: string;
    type: string;
    active: string;
    count: number;
   
  }
const ErrorToolTip: React.FC<PropsType> = ( { error,type,active,count } ) => {
    return (
        <>
        { error && error.length > 0 && type == active && count > 0 ? <div
                  className="absolute top-12 text-xs
                font-medium z-10 px-2 py-1 border border-red-500 bg-red-50 text-red-500 shadow-sm rounded-lg"
                >
                  {error}
                </div> : <></>}
        </>
    );
}

export default ErrorToolTip;