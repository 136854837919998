// import MasterRuleSettings from "./MasterRuleSettings";
import { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import PricingService from "../Services/pricing.service";
import moment from "moment";
import { useAuthStore } from "../../../Store/AuthStore";
import { toast, ToastContainer } from "react-toastify";
import { Pagination } from "../../../Components-ui/Pagination";
import Loader from "../../../Components-ui/Loader";
import Modal from "../../../Components-ui/Modal/Modal";
import { makeQuery } from "../../../Common/util.helper";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";

const Benchmark: React.FC = () => {
  const { logout, permissions, userData, tokens } = useAuthStore();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [benchmarkData, setBenchmarkData] = useState<any>([]);
  const [feedData, setFeedData] = useState<any>([]);
  const [metalData, setMetalData] = useState<any>([]);
  const [meta, setMetaData] = useState<any>({});
  const [Loading, setLoading] = useState<any>(true);
  const [status, setStatus] = useState<any>("ADD");
  const [children, setChildren] = useState<any>();
  const [show, setModalStatus] = useState<any>(false);
  const [selectedRecord, setSelectedRecord] = useState<any>({
    metalCode: "",
    basisCode: "",
    description: "",
    feed: "",
  });
  const [filters, setFilters] = useState<any>({
    metalCode: "",
    basisCode: "",
    description: "",
    feed: "",
  });

  useEffect(() => {
    loadData(currentPage);
    loadFeedData();
    loadMetalData();
  }, []);

  useEffect(() => {
    childrenElements();
  }, [selectedRecord, status]);

  const loadData = async (page: number) => {
    await PricingService.getBenchData(tokens, page, true, filters)
      .then((response: any) => {
        setBenchmarkData(response.data.data.result);
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPage(page);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Benchmark data load error!");
        setLoading(false);
      });  
  };

  const loadFeedData = async () => {
    let res: any = await PricingService.getFeedMaster();
    setFeedData(res.data.data);
  };

  const loadMetalData = async () => {
    let params = {
      isPaginated: false,
    };
    const query = makeQuery(params);

    let res: any = await PricingService.getMetalMasterData(tokens, query);
    setMetalData(res.data.data);
  };

  /**
   * Handle filters change event
   * @param e
   */
  const handleChangeFilter = (e: any) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  /**
   * Reset filters to initial state
   * @param e
   */
  const ResetForm = async () => {
    setCurrentPage(0);
    setFilters({
      metalCode: "",
      basisCode: "",
      description: "",
      feed: "",
    });
    setLoading(true);
    await PricingService.getBenchData(tokens, 0, true)
      .then((response: any) => {
        setBenchmarkData(response.data.data.result);
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPage(0);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
        setLoading(false);
      });
  };

  const filter = async (page: any) => {
    setLoading(true);
    await PricingService.getBenchData(tokens, page, true, filters)
      .then((response: any) => {
        setBenchmarkData(response.data.data.result);
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPage(page);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
        setLoading(false);
      });
  };

  const paginationFilter = async (page: number) => {
    setCurrentPage(page);
    filter(page);
  };

  const addModalElements = () => {
    setSelectedRecord({
      metalCode: "",
      basisCode: "",
      description: "",
      feed: "",
    });
    setStatus("ADD");
    childrenElements();
    setModalStatus(true);
  };

  const editModalElements = (data: any) => {
    setStatus("EDIT");
    setSelectedRecord(data);
    childrenElements();
    setModalStatus(true);
  };

  const addNew = async () => {
    var data = {
      metalCode: selectedRecord.metalCode,
      basisCode: selectedRecord.basisCode,
      description: selectedRecord.description,
      feed: selectedRecord.feed,
    };

    //validation
    if (data.metalCode === "" || data.basisCode === "" || data.feed === "") {
      toast.error("Required field are empty");
    } else {
      await PricingService.createBenchData(data, tokens)
        .then(() => {
          loadData(currentPage);
          toast.success("Record Added Successfully");
          setModalStatus(false);
        })
        .catch((err) => {
          toast.error(err.data.errors[0].title);
        });
    }
  };

  const hideModal = () => {
    setModalStatus(false);
  };

  const updateRecord = async () => {
    //validation
    if (
      selectedRecord.metalCode === "" ||
      selectedRecord.basisCode === "" ||
      selectedRecord.feed === ""
    ) {
      toast.error("Required field are empty");
    } else {
      await PricingService.updateBenchData(selectedRecord, tokens)
        .then((response: any) => {
          setLoading(false);
          setModalStatus(false);
          loadData(currentPage);
          toast.success("Updated Successfully");
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.data.errors[0].title);
          loadData(currentPage);
        });
    }
  };

  const handleFormSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  const childrenElements = () => {
    setChildren(
      <div className="w-full max-w-xs">
        <h1 className="font-bold">
          {"ADD" === status ? "Add Benchmark Record" : "Edit Benchmark Record"}
        </h1>
        <form
          className="bg-white rounded pt-6 mb-4"
          onSubmit={handleFormSubmit}
        >
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 required"
              htmlFor="metalCode"
            >
              Metal Code
            </label>

            <select
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              id="metalCode"
              name="metalCode"
              onChange={(event) =>
                setSelectedRecord({
                  ...selectedRecord,
                  metalCode: event.target.value,
                })
              }
              value={selectedRecord.metalCode}
            >
              <option value="">Select a Metal</option>
              {metalData.map((metal: any, index: number) => {
                return (
                  <option key={index} value={metal.metalCode}>
                    {metal.metalCode}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 required"
              htmlFor="basisCode"
            >
              Basis Code
            </label>
            <input
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              id="basisCode"
              name="basisCode"
              type="text"
              placeholder="Basis Code"
              onChange={(event) =>
                setSelectedRecord({
                  ...selectedRecord,
                  basisCode: event.target.value,
                })
              }
              value={selectedRecord.basisCode}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="description"
            >
              Description
            </label>
            <input
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              id="description"
              name="description"
              type="text"
              placeholder="Description"
              onChange={(event) =>
                setSelectedRecord({
                  ...selectedRecord,
                  description: event.target.value,
                })
              }
              value={selectedRecord.description}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 required"
              htmlFor="feed"
            >
              Feed
            </label>
            <select
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              id="feed"
              name="feed"
              onChange={(event) =>
                setSelectedRecord({
                  ...selectedRecord,
                  feed: event.target.value,
                })
              }
              value={selectedRecord.feed}
            >
              <option value="">Select a feed</option>
              {feedData.map((feed: any, index: number) => {
                return (
                  <option key={index} value={feed.feedCode}>
                    {feed.feedCode}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="flex items-center justify-between flex-row-reverse">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={() => {
                if ("ADD" === status) {
                  addNew();
                } else {
                  updateRecord();
                }
              }}
            >
              {"ADD" === status ? "SAVE" : "UPDATE"}
            </button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <Layout type={"PricingModule"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">{"Benchmark"}</h1>
        {HasAnyAccess(["benchmark.create"]) && (
          <button
            className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
            type="button"
            onClick={() => {
              addModalElements();
            }}
          >
            + Add Benchmark
          </button>
        )}
      </div>
      {/* filter rules */}
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Filter Records
        </h3>
      </div>
      <hr />

      <form
        onSubmit={(e) => {
          loadData(0)
          e.preventDefault()
        }}
      >
        <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="metalCode"
              className="block text-sm font-medium text-gray-700"
            >
              Metal
            </label>
            <select
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              id="metalCode"
              name="metalCode"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={filters.metalCode}
            >
              <option value="">All</option>
              {metalData.map((metal: any, index: number) => {
                return (
                  <option key={index} value={metal.metalCode}>
                    {metal.metalCode}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="basisCode"
              className="block text-sm font-medium text-gray-700"
            >
              Basis code
            </label>
            <input
              type="text"
              name="basisCode"
              id="basisCode"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={filters.basisCode}
            />
          </div>
          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="feed"
              className="block text-sm font-medium text-gray-700"
            >
              Feed
            </label>

            <select
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              id="feed"
              name="feed"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={filters.feed}
            >
              <option value="">All</option>
              {feedData.map((feed: any, index: number) => {
                return (
                  <option key={index} value={feed.feedCode}>
                    {feed.feedCode}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700"
            >
              Description
            </label>
            <input
              type="text"
              name="description"
              id="description"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={filters.description}
            />
          </div>

          <div className="col-span-12 sm:col-span-12 flex justify-end">
            {/* <button
            className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
            type="button"
            onClick={() => loadData(0)}
          >
            Filter
          </button> */}
            <input
              className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
              type="submit"
              value="Filter"
            />
            <button
              className="text-white bg-red-600 border border-red-300 rounded-md background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
              type="button"
              onClick={(e) => ResetForm()}
            >
              Reset
            </button>
          </div>
        </div>
      </form>
      <div className="col-span-12 sm:col-span-12">
        {" "}
        <hr />
      </div>
      {/* end filter */}
      <div className="border bg-white rounded border-gray-200 shadow">
        {Loading ? (
          <Loader />
        ) : (
          <div>
            {" "}
            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
              <thead>
                <tr className="text-left border-b border-gray-300">
                  <th className="sticky top-0 px-3 py-2 bg-gray-800">Metal</th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800">
                    Basis Code
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800">
                    Description
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800">Feed</th>
                  {HasAnyAccess(["benchmark.edit"]) && (
                    <th className="sticky top-0 px-3 py-2 bg-gray-800"></th>
                  )}
                </tr>
              </thead>
              <tbody>
                {0 !== benchmarkData.length ? (
                  benchmarkData.map((ebl: any) => {
                    return (
                      <tr className="bg-white border-b border-gray-300 text-black">
                        <td className="px-3 py-3">{ebl.metalCode}</td>
                        <td className="px-3 py-3">
                          {ebl.basisCode ? ebl.basisCode : <EmptyValue />}
                        </td>
                        <td className="px-3 py-3">
                          {ebl.description ? ebl.description : <EmptyValue />}
                        </td>
                        <td className="px-3 py-3">
                          {ebl.feed ? ebl.feed : <EmptyValue />}
                        </td>
                        <td className="px-3 py-3 text-right ">
                          {HasAnyAccess(["benchmark.edit"]) && (
                            <button
                              className="whitespace-nowrap bg-purple-100 text-purple-900 px-3 py-1 text-sm rounded font-bold cursor-pointer"
                              onClick={() => {
                                editModalElements(ebl);
                              }}
                            >
                              Edit
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                    <td
                      colSpan={6}
                      className="text-center py-3 px-3 text-left relative"
                    >
                      No data to display
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        <Pagination
          meta={meta}
          handlePageChange={(number: number) => {
            paginationFilter(number);
          }}
        />
      </div>
      <Modal
        size={""}
        show={show}
        handleClose={hideModal}
        children={children}
      />
    </Layout>
  );
};

const EmptyValue = () => (
  <span
    className="bg-gray-50 px-2
py-1 font-bold text-gray-500 text-xs border-gray-300 border rounded"
  >
    Empty
  </span>
);

export default Benchmark;
