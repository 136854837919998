import React from "react";
import { Route } from "react-router-dom";
import TableComponent from "../Components/TableComponent";
import GoldSilverGraph from "../Components/GoldSilverGraph";


export const ChartRoute: React.FC = () => {
  return (
    <>
      <Route path="/chart" exact component={TableComponent} />
      <Route path="/gold-silver" exact component={GoldSilverGraph} />
    </>
  );
};

