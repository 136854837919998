import axios from "axios";
import {makeQuery} from "../../Common/util.helper";
import Http from "../../library/http.library";
import {httpMethods} from "../../library/enum";

export const GetAllDocType = async () => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/documents/type/get`;
    resolve(await axios.get(apiUrl));
  });
};

export const GetAllDocTypeByUserType = async (userType : string) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/documents/type/usertype/${userType}`;
    resolve(await axios.get(apiUrl));
  });
};

export const CreateNewDocType = async (data : any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/documents/type/insert`;
    try {
      let result = await axios.post(apiUrl, data);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const FetchADocType = async (id: number) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/documents/type/get/${id}`;
    try {
      let result = await axios.get(apiUrl);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const EditDocType = async (id: number, data: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/documents/type/${id}`;
    try {
      let result = await axios.put(apiUrl, data);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteDocType = async (id: number) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/documents/type/${id}`;
    try {
      let result = await axios.delete(apiUrl);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const uploadSampleDoc = async (tokens: any, data: any) => {
  return fetch(`${process.env.REACT_APP_URL}/customer/document/type/sample`, {
    method: 'POST',
    headers: {
      'Authorization' :`Bearer ${tokens.idToken.jwtToken}`
    },
    body: data
  });

};

export const getUploadedFiles = async (tokens: any, docTypeId: any) => {

  return await Http.setMethod(httpMethods.get).setUrl(`/customer/document/type/sample/${docTypeId}`).setToken(tokens.idToken.jwtToken).request();

};

export const delteUploadedFiles = async (tokens: any, id: any) => {

  return await Http.setMethod(httpMethods.delete).setUrl(`/customer/document/type/sample/${id}`).setToken(tokens.idToken.jwtToken).request();

};

export const updateUploadedFiles = async (tokens: any, data: any) => {
  return await Http.setMethod(httpMethods.patch).setUrl(`/customer/document/type/sample`).setData(data).setToken(tokens.idToken.jwtToken).request();

}

const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  let [resource, config] = args;
  let response = await originalFetch(resource, config);
  if (!response.ok && response.status === 401) {
    // 401 error handling
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/login"
    return Promise.reject(response);
  }
  return response;
};

