import React from "react";
import { Route } from "react-router-dom";

import ManualRefund from "../ManualRefund/ManualRefund";
import ManualRefundApprove from "../ManualRefund/ManualRefundApprove";


export const ManualRefundRoute: React.FC = () => {
  return (
    <>
      <Route path="/manual-refund" exact component={ManualRefund} />
      <Route path="/manual-refund-approve" exact component={ManualRefundApprove} />
    </>
  );
};
