import React, { createContext, useReducer } from "react";
import { reducer } from "../Reducers/reducer";

export const MetalContext = createContext<any>(undefined);

const MetalProvider = ({ children } : any) => {

    const [state, dispatch] = useReducer(reducer, {
        metals: [
            // { id: 1, metalName: "Gold", qty: "1 gm", price: "$14.00" },
            // { id: 2, metalName: "Silver", qty: "1 gm", price: "$10.90" },
        ]
    });

    return (
        <MetalContext.Provider value={{ state, dispatch }}>
            {children}
        </MetalContext.Provider>
    );
}

export default MetalProvider;