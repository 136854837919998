import React from "react";
import { Route } from "react-router-dom";

import OrderTable from "../Components/OrderTable";
import OrderView from "../Components/OrderView";
import Invoices from "../Components/Invoices";


export const OrderRoutes: React.FC = () => {
  return (
    <>
      <Route path="/order" exact component={OrderTable} />
      <Route path="/order/:id" exact component={OrderView} />
      <Route path="/order/:id/invoices" exact component={Invoices} />
      {/* <Route path="/order/:status/:interval" exact component={OrderTable} /> */}
    </>
  );
};
