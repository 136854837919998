

import React from "react";

interface PropsType {
  value : string,
  onClose? : any,
}

export const Tag: React.FC<PropsType> = ({ value , onClose}) => {
  return (
<div className="my-2 flex flex-wrap m-1 border-2 border-gray-300 text-gray-700 rounded px-2 font-semibold text-xs leading-loose " >
    {value}
    {/* <div className="">
        <div 
            onClick={onClose()}
            className="ml-2 pl-1 pr-2 font-semibold border-l-2 border-dotted border-gray-300 cursor-pointer hover:bg-gray-100">X</div>
    </div> */}
</div>
  )
};
