import React, { useState } from "react";
import {getApplyName} from "./utils";

interface PropsType {
  rules: [];
  delRule: any;
  editRule: any;
  subAllDetail: any;
}

const HeadTE: React.FC<PropsType> = ({
  rules,
  delRule,
  editRule,
  subAllDetail,
}) => {
  const [expandedRows, setExpandedRows] = useState<any>([]);

  const [expandState, setExpandState] = useState<any>({});
  console.log(subAllDetail,"selctedItemDetails")
  const handleEpandRow = (event: any, index: any) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(index);

    let obj: any = {};
    isRowExpanded ? (obj[index] = false) : (obj[index] = true);
    setExpandState(obj);

    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id: any) => id !== index)
      : currentExpandedRows.concat(index);

    setExpandedRows(newExpandedRows);
  };

  const getItemName = (id: any) => {
    const selctedItemDetails =
    !!subAllDetail&&
      !!subAllDetail?.item?.length &&
      subAllDetail?.item.find((data: any) => data?.Item_code === id);
    return selctedItemDetails.Item_name;
  };
  console.log(rules,"TRTRTRTRT");
  
  return (
    <div className="my-6">
      <table className="w-full table-fixed rounded-lg">
        <thead>
          <tr className="sticky top-0 px-6 py-3 text-white bg-gray-700 text-sm">
            <th className="py-3 px-2 text-left">Rule Ref</th>
            <th className="py-3 px-2 text-left">Line No.</th>
            <th className="py-3 px-2 text-left">Item Code</th>
            <th className="py-3 px-2 text-left">Item Name</th>
            <th className="py-3 px-2 text-left">Apply Rate On</th>
            <th className="py-3 px-2 text-left">Amount/Rate</th>
            <th className="py-3 px-2 text-left">Value</th>
            <th className="py-3 px-2 text-left">Delete</th>
            <th className="py-3 px-2 text-left">Edit</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {!!rules?.length &&
            rules.map((rule: any, index: number) => {
              return (
                <tr className="p-0" key={index}>
                  <React.Fragment>
                    <td className="px-2 py-2 w-100">
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            <div className="p-1 px-2 mb-1 font-bold  h-8">
                              <label>{rule?.Rule_ref}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-2 py-2 w-100">
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            <div className="p-1 px-2 mb-1 font-bold  h-8">
                              <label>{index + 1}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-2 py-2 w-100">
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            <div className="p-1 px-2 mb-1 font-bold  h-8">
                              <label>{rule?.item_code}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-2 py-2 w-100">
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            <div className="p-1 px-2 mb-1 font-bold  h-8">
                               <label>{getItemName(rule?.item_code)}</label>  
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-2 py-2 w-100">
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            <div className="p-1 px-2 mb-1 font-bold  h-8">
                              <label>{getApplyName(rule?.apply_rate_on)}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-2 py-2 w-100">
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            <div className="p-1 px-2 mb-1 font-bold  h-8">
                              <label>{rule?.amount_rate}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-2 py-2 w-100">
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            <div className="p-1 px-2 mb-1 font-bold  h-8">
                              <label>{rule?.value}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-2 py-2 w-100">
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            <div className="p-1 px-2 mb-1 font-bold  h-8">
                              <button
                                type="button"
                                className="px-2 cursor-pointer"
                                onClick={() => {
                                  return delRule(index);
                                }}
                              >
                                ⛔️
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-2 py-2 w-100">
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            <div className="p-1 px-2 mb-1 font-bold  h-8">
                              <button
                                type="button"
                                className="px-2 cursor-pointer"
                                onClick={() => {
                                  return editRule(rule, index);
                                }}
                              >
                                ✏️
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <button className='bg-purple-200 p-2 rounded-md px-3 font-semibold text-sm' onClick={(event) => handleEpandRow(event, index)}>
                        {expandState[index] ? "Hide " : "Show "}
                         / View
                      </button>
                    </td>
                    <>
                      {expandedRows.includes(index) ? (
                        <tr>
                          <td>
                            <div
                              style={{
                                backgroundColor: "#343A40",
                                color: "#FFF",
                                padding: "10px",
                              }}
                            >
                              <ul>
                                <li>
                                  <span>
                                    <b>Description:</b>
                                  </span>
                                  <span> {rule.description}</span>
                                </li>
                                <li>
                                  <span>
                                    <b>is Maintain Stock:</b>
                                  </span>
                                  <span> {(rule.is_Maintain_Stock).toString()}</span>
                                </li>
                                <li>
                                  <span>
                                    <b>Allocate Vault:</b>
                                  </span>
                                  <span> {(rule.allocate_Vault).toString()}</span>
                                </li>
                                <li>
                                  <span>
                                    <b>Pricing Rule Status:</b>
                                  </span>
                                  <span> {(rule.pricing_enabled).toString()}</span>
                                </li>
                                <li>
                                  <span>
                                    <b>Pricing Rule:</b>
                                  </span>
                                  <span> {rule.pricing_rule}</span>
                                </li>
                                <li>
                                  <span>
                                    <b>UOM:</b>
                                  </span>
                                  <span> {rule.UOM}</span>
                                </li>
                                <li>
                                  <span>
                                    <b>Currency:</b>
                                  </span>
                                  <span> {rule.currency}</span>
                                </li>
                                <li>
                                  <span>
                                    <b>Discount Amount/Rate:</b>
                                  </span>
                                  <span> {rule.discountAmount_rate}</span>
                                </li>
                                <li>
                                  <span>
                                    <b>Discount Rate:</b>
                                  </span>
                                  <span> {rule.discount_value}</span>
                                </li>
                                <li>
                                  <span>
                                    <b>Add or Deduct to Invoice Net Total:</b>
                                  </span>
                                  <span> {(rule.add_ded).toString()}</span>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </>
                  </React.Fragment>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default HeadTE;