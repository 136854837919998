/**
 * TYPES
 */
export const TYPES = [
    {value:"AMOUNT", label: "AMOUNT"},
    {value:"PRODUCT_QTY", label: "PRODUCT QUANTITY"},
]

/**
 * ISSUE TYPES
 */
export const ISSUE_TYPES = [
    {value:"AMOUNT", label: "AMOUNT"},
    {value:"PRODUCT_QTY", label: "PRODUCT QUANTITY"},
    {value:"RATE", label: "RATE"},
]
/**
 * SIP ISSUE TYPES
 */
export const SIP_ISSUE_TYPES = [
    // {value:"AMOUNT", label: "AMOUNT"},
    {value:"PRODUCT_QTY", label: "PRODUCT QUANTITY"},
    // {value:"RATE", label: "RATE"},
]

