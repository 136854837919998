import React, {useState, useEffect} from "react";
import {useAuthStore} from "../../../Store/AuthStore";
import Layout from "../../Layout/Layout";
import {toast, ToastContainer} from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import moment from "moment";
import {Pagination} from "../../../Components-ui/Pagination";
import SettlementReportService from "../Settlement/SettlementReportService";
import UserListService from "../../Userlist/Services/UserList.Service";
import Datetime from "react-datetime";
import Modal from "../../../Components-ui/Modal/Modal";
import '../BarReconciliationReport/BarReconciliation.css';
import {formatAmount} from "../../../Components-ui/NumberFormat";

const SettlementReportApiLogs: React.FC = () => {
    const {tokens} = useAuthStore();
    const [Loading, setLoading] = useState<any>(false);
    const [settlements, setSettlements] = useState<any>([]);
    const [meta, setMetaData] = useState<any>({});
    const [partnerList, setPartnerList] = useState<any>([]);
    const [filters, setFilters] = useState<any>({
        provider: '',
        partner: '',
        method: '',
        createdAtFrom: null,
        createdAtTo: null
    });
    const [selectedSettlement, setSelectedSettlement] = useState<any>();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [show, setModalStatus] = useState<any>(false);
    const [showView, setModalViewStatus] = useState<any>(false);
    const [children, setChildren] = useState<any>();
    const [childrenModal, setChildrenModal] = useState<any>();
    const [detailsList, setDetailsList] = useState<any>([]);
    const [modalMeta, setModalMetaData] = useState<any>({});
    const [viewData, setViewData] = useState<any>({});

    const loadSettlementsLogsData = async (page: number, filters: any) => {
        setLoading(true);
        SettlementReportService.getApiSettlementsLogsData(tokens, page, filters).then((response: any) => {
            setSettlements(response.data.data.result);
            setMetaData({
                current: response.data.meta.page,
                pages: response.data.meta.totalPages,
            });
            setCurrentPage(response.data.meta.page);
            setLoading(false);
        }).catch(err => {
            toast.error("Something Went Wrong!");
            setLoading(false);
        });
    };

    useEffect(() => {
        get_partner_list();
        setLoading(true);
        loadSettlementsLogsData(currentPage, {});
    }, []);

    const get_partner_list = async () => {
        await UserListService.getPartnerList(tokens).then((response: any) => {
            setPartnerList(response.data.data);
        }).catch(err => {
            // toast.error(err.response.data.errors[0].title);
        });
    }

    const ResetForm = async () => {
        setFilters({
            provider: '',
            partner: '',
            method: '',
            createdAtFrom: null,
            createdAtTo: null
        });
        setCurrentPage(0);
        loadSettlementsLogsData(0, {});
    }

    const paginationFilter = async (page: number) => {
        setCurrentPage(page);
        loadSettlementsLogsData(page, filters);
    }

    const downloadReport = (data:any) => {
        setLoading(true);
        SettlementReportService.logReportDownload(tokens,data).then((response: any) => {
            const href = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download',  'settlement_logs_report.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        }).catch(err => {
            toast.error("Something Went Wrong!");
            setLoading(false);
        });
    };

    const editModalElements = (item: any) => {
        setLoading(true);
        setSelectedSettlement(item);
        loadSettlementsLogsDetailsData(item.id,0);
    }

    const editDataModalElements = (item: any) => {
        setViewData(item);
        setModalViewStatus(true);
        // loadSettlementsLogsDetailsData(item.id,0)
    }

    const loadSettlementsLogsDetailsData = async (id: any,page:any) => {
        SettlementReportService.getSettlementsLogsDetails(tokens,id,page).then((response: any) => {
            setDetailsList(response.data.data.result);
            setModalMetaData({
                current: response.data.meta.page,
                pages: response.data.meta.totalPages,
            });
            setLoading(false);
            setModalStatus(true);
        }).catch(err => {
            toast.error("Something Went Wrong!");
            setLoading(false);
        });
    };

    const childrenElements = () => {
        setChildren(<div className="w-full">
            <h1 className="font-bold">
                {'Settlement Log Record Details'}
            </h1>
            <div className="mb-3 bg-white p-2 pb-1 pr-3.5 pt-4 rounded shadow-2xl">
                <div className="flex-wrap mb-2">
                    {Loading ? (<Loader/>) : (
                        <div className="">
                            <table
                                className="whitespace-nowrap  rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs ">
                                <thead>
                                <tr className="text-left border-b border-gray-300">
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Date
                                    </th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Transaction ID
                                    </th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Amount
                                    </th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Utr</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {(0 !== detailsList.length) ?
                                    detailsList.map((user: any, index: number) => {
                                        return (
                                            <tr key={index}
                                                className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                <td className="py-3 px-3 text-left relative">
                                                    {moment(user.date).format("YYYY-MM-DD")}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {user.transactionLogId}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {formatAmount(Number(user.amount))}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {user.utr}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    <button
                                                        className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                        type="button"
                                                        onClick={() => {
                                                            editDataModalElements(user)
                                                        }}
                                                    >
                                                        View
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    : (
                                        <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                            <td colSpan={10}
                                                className="text-center py-3 px-3 text-left relative">No
                                                data to
                                                display
                                            </td>
                                        </tr>)
                                }
                                </tbody>
                            </table>
                            <Pagination
                                meta={modalMeta}
                                handlePageChange={(number: number) => {
                                    modalPaginationFilter(number)
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>);
    }

    const modalPaginationFilter = async (page: number) => {
        loadSettlementsLogsDetailsData(selectedSettlement.id,page);
    }

    useEffect(() => {
        childrenElements();
    }, [selectedSettlement,show,detailsList]);

    useEffect(() => {
        childrenElements();
    }, [detailsList,modalMeta]);

    useEffect(() => {
        viewModalElements();
    }, [viewData,showView]);

    const hideModal = () => {
        setModalStatus(false);
    };

    const hide = () => {
        setModalViewStatus(false);
    };

    const viewModalElements = () => {
        setChildrenModal(
            <div className="w-full">
                <div
                    className="mb-3 bg-white border-purple-900 border-t-4 card p-2 pb-1 pr-3.5 pt-4 rounded shadow-2xl">
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="pb-3 w-full md:w-2/4 px-3 mb-6 md:mb-0">
                            <pre>{JSON.stringify(viewData.data, undefined, 8)}</pre>
                        </div>
                    </div>
                </div>
            </div>);
    }

    var valid = function (current: any) {
        return current.isAfter(moment(filters.createdAtFrom).subtract(1, 'day'));
    };

    return (
        <Layout type={"PaymentModule"}>
            <ToastContainer/>
            <div className="flex justify-between items-center mb-5">
                <h1 className="text-2xl font-bold tracking-tight">
                    {"Settlement Logs"}
                </h1>
            </div>
            <div className="border bg-white rounded border-gray-200 shadow">
                <div className="flex-1 p-2">
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-type">
                                Payment Provider
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="user-type" onChange={event => setFilters({
                                    ...filters,
                                    provider: event.target.value
                                })} value={filters.provider}>
                                    <option value="">Select a Provider</option>
                                    <option value="RPY">RPY</option>
                                    <option value="DBS">DBS</option>
                                    <option value="EBL">EBL</option>
                                </select>
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="grid-state"
                            >
                                From Date
                            </label>
                            <div
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            <Datetime
                                    // isValidDate={validFrom}
                                    timeFormat={""}
                                    dateFormat={"YYYY-MM-DD"}
                                    inputProps={{
                                        readOnly: true,
                                        placeholder: 'From Date',
                                        className: "focus:outline-none"
                                    }}
                                    onChange={(event: any) => {

                                        setFilters({

                                            ...filters,
                                            createdAtFrom: moment(new Date(event).toISOString()).format("YYYY-MM-DD"),
                                            createdAtTo: ((event.isAfter(new Date(filters.createdAtTo)) ? moment(new Date(event).toISOString()).format("YYYY-MM-DD") : moment(filters.createdAtTo).format("YYYY-MM-DD"))),
                                        });
                                    }} value={moment(filters.createdAtFrom)}

                                    renderInput={(props) => {
                                        return <input {...props}
                                        style={{width:'100%'}}
                                                      value={(filters.createdAtFrom) ? (filters.createdAtFrom) : ''} />
                                    }}
                                />
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="grid-state"
                            >
                                To Date
                            </label>
                            <div
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            <Datetime
                                    isValidDate={valid}
                                    timeFormat={""}
                                    dateFormat={"YYYY-MM-DD"}
                                    inputProps={{ readOnly: true,placeholder: 'To Date', className: "focus:outline-none" }}
                                    onChange={(event: any) => setFilters({
                                        ...filters,
                                        createdAtTo: moment(new Date(event).toISOString()).format("YYYY-MM-DD"),
                                    })} value={moment(filters.createdAtTo)}

                                    renderInput={(props) => {
                                        return <input {...props}
                                                      style={{width:'100%'}}
                                                      value={(filters.createdAtTo) ? (filters.createdAtTo) : ''} />
                                    }}
                                />

                            </div>
                        </div>
                        {/*<div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">*/}
                        {/*    <label*/}
                        {/*        className="block text-sm font-medium text-gray-700"*/}
                        {/*        htmlFor="code">*/}
                        {/*        Amount*/}
                        {/*    </label>*/}
                        {/*    <input*/}
                        {/*        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"*/}
                        {/*        name="code" id="code" type="text" placeholder="Amount"*/}
                        {/*        autoComplete="none" onChange={event => setFilters({*/}
                        {/*        ...filters,*/}
                        {/*        amount: event.target.value*/}
                        {/*    })} value={filters.amount}/>*/}
                        {/*</div>*/}
                        <div className="flex w-full justify-end px-3 mb-6 md:mb-0">
                            <div className="my-3">
                                <button
                                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                                    type="button"
                                    onClick={() => loadSettlementsLogsData(0, filters)}
                                >
                                    Filter
                                </button>
                                <button
                                    className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={(e) => ResetForm()}>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded border-gray-200 shadow m-2">
                    {Loading ? (<Loader/>) : (
                        <div>
                            <div className="overflow-x-auto">
                                <table
                                    className="whitespace-nowrap  rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs ">
                                    <thead>
                                    <tr className="text-left border-b border-gray-300">
                                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Payment Provider
                                        </th>
                                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Date</th>
                                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Amount</th>
                                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(0 !== settlements.length) ?
                                        settlements.map((user: any, index: number) => {
                                            return (
                                                <tr key={index}
                                                    className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                    <td className="py-3 px-3 text-left relative">
                                                        {user.provider || 'N/A'}
                                                    </td>
                                                    <td className="py-3 px-3 text-left relative">
                                                        {moment(user.date).format("DD-MM-YYYY HH:mm:ss") || 'N/A'}
                                                    </td>
                                                    <td className="py-3 px-3 text-left relative">
                                                        {formatAmount(Number(user.amount))}
                                                    </td>
                                                    <td className="py-3 px-3 text-left relative">
                                                        <button
                                                            className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                            type="button"
                                                            onClick={() => {
                                                                editModalElements(user)
                                                            }}
                                                        >
                                                            View
                                                        </button>
                                                        <button
                                                            className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                            type="button"
                                                            onClick={() => downloadReport(user.id)}
                                                        >
                                                            Download
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        : (
                                            <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                                <td colSpan={6}
                                                    className="text-center py-3 px-3 text-left relative">No
                                                    data to
                                                    display
                                                </td>
                                            </tr>)
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                                meta={meta}
                                handlePageChange={(number: number) => {
                                    paginationFilter(number)
                                }}
                            />
                        </div>
                    )}
                    <Modal size={'large'} show={show} handleClose={hideModal} children={children}/>
                    <Modal size={'large'} show={showView} handleClose={hide} children={childrenModal}/>
                </div>
            </div>

        </Layout>
    );
};
export default SettlementReportApiLogs;
