import axios from "axios";
import Http from "../../../library/http.library";
import {httpMethods} from "../../../library/enum";

class StateService {

    /**
     * Get all states
     * @param page
     * @param tokens
     * @param query
     * @param isPaginated
     */
    async getAllStates(page: number, tokens: any, query: any, isPaginated: boolean = true) {
        return await Http.setMethod(httpMethods.get).setUrl(`/state-master?page=${page}${query}${!isPaginated ? `&isPaginated=false` : ``}`).setToken(tokens.idToken.jwtToken).request();
    }

    /**
     * Add new state
     * @param data
     * @param tokens
     */
    async addState(data: any, tokens: any) {
        return await Http.setMethod(httpMethods.post).setUrl(`/state-master`).setData([data]).setToken(tokens.idToken.jwtToken).request();
    }

    /**
     * Update state details
     * @param data
     * @param tokens
     */
    async updateState(data: any, tokens: any) {
        return new Promise(async (resolve, reject) => {
            let apiUrl = `${process.env.REACT_APP_URL}/state-master/${data.id}`;
            try {
                resolve(await axios.patch(apiUrl, data, {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                        Authorization: `Bearer ${tokens.idToken.jwtToken}`,
                    },
                }));
            } catch (e) {
                reject(e)
            }
        })
    }
}

export default new StateService();
