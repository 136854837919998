import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import PushNotification from "./PushNotification";

// import DashboardCard from "../Components/DashboardCard";

export const PushNotificationRoutes: React.FC = () => {
  return (
    <>
      <Fragment>
        <Route path="/push-notification" exact component={PushNotification} />
      </Fragment>
      {/* <Route path="/push-notification" exact component={PushNotification} /> */}
    </>
  );
};

export default PushNotificationRoutes;
