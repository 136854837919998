import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import Login from './Components/Login';
import ResetPassword from "./Components/ForgetPasswrd";
import UnAuthorized from "./Components/UnAuthorized";

const IndexRoutes:React.FC = () => {
    return (
        <Fragment>
            <Route path="/unauthorized" exact component={UnAuthorized} />
            <Route path="/" exact component={Login} />
            <Route path="/Login" exact component={Login} />
            <Route path="/forget-password" exact component={ResetPassword} />
        </Fragment>
    )
};

export default IndexRoutes;
