import moment from "moment";
import Http from "../../../library/http.library";
import {httpMethods} from "../../../library/enum";

class TaxDetailsService {

    /**
     * Get all tax details
     * @param page
     * @param tokens
     * @param query
     */
    async getAllTaxDetails(page:number, tokens: any, query:any) {
        return await Http.setMethod(httpMethods.get).setUrl(`/tax-details?page=${page}${query}`).setToken(tokens.idToken.jwtToken).request();

    }

    /**
     * Add new tax details
     * @param data
     * @param tokens
     */
    async addTaxDetails(data: any, tokens: any) {
        return await Http.setMethod(httpMethods.post).setUrl(`/tax-details`).setData([data]).setToken(tokens.idToken.jwtToken).request();

    }

    /**
     * Update tax details
     * @param data
     * @param tokens
     */
    async updateTaxDetails(data: any, tokens: any) {
        data.from = moment(data.from).format('YYYY-MM-DD')
        data.to = moment(data.to).format('YYYY-MM-DD')
        return Http.setMethod(httpMethods.patch).setUrl(`/tax-details/${data.id}`).setData(data).setToken(tokens.idToken.jwtToken).request();
    }

    /**
     * Get all hsn
     * @param tokens
     * @param isPaginated
     */
    async getAllHSN(tokens: any, isPaginated: boolean = true) {
        return await Http.setMethod(httpMethods.get).setUrl(`/hsn-master${!isPaginated ? `?isPaginated=false` : ``}`).setToken(tokens.idToken.jwtToken).request();
    }
}

export default new TaxDetailsService();
