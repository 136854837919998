import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../Layout/Layout";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Components-ui/Loader";
import { Pagination } from "../../Components-ui/Pagination";
import FundManagementService from "./Services/FundManagementService";
import moment from "moment";
import { useAuthStore } from "../../Store/AuthStore";
import UserListService from "../Userlist/Services/UserList.Service";
import Datetime from "react-datetime";
import { ExportToExcel } from "../Util/ExcelExport";
import HasAnyAccess from "../../Components-ui/hasAnyAccess";
import { formatAmount } from "../../Components-ui/NumberFormat";

const FundManagement: React.FC = () => {
  const { logout, permissions, userData, tokens } = useAuthStore();
  let history = useHistory();
  const params: any = useParams();

  const { isPartnerAdmin, partnerAdminId } = useAuthStore();

  const searchParams = new URLSearchParams(window.location.search);
  const status = searchParams.get("status");
  const interval = searchParams.get("interval");
  const decodedStatus = status ? decodeURIComponent(status) : undefined;
  const decodedInterval: any = interval
    ? decodeURIComponent(interval)
    : undefined;

  const [collapse, setCollapse] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [meta, setMetaData] = useState<any>({});
  const [Loading, setLoading] = useState<any>(false);
  const [fundDataList, setFundDataList] = useState<any>([]);
  const [partnerList, setPartnerList] = useState<any>([]);
  const [filters, setFilters] = useState<any>({
    method: "",
    transactionMasterId: "",
    id: "",
    customerID: "",
    transactionRefId: "",
    providerTransactionId: "",
    pge: "",
    partner: partnerAdminId,
    entity: "",
    sourceModule: "",
    paymentStatus: "",
    settlementStatus: "",
    utr: "",
    debitCredit: "",
    transactionFromTime: null,
    transactionToTime: null,
    settlementFromTime: null,
    settlementToTime: null,
  });
  const width = 130;

  const get_partner_list = async () => {
    await UserListService.getPartnerList(tokens)
      .then((response: any) => {
        setPartnerList(response.data.data);
        sessionStorage.setItem(
          "partnerList",
          JSON.stringify(response.data.data)
        );
      })
      .catch((err) => {
        // toast.error(err.response.data.errors[0].title);
      });
  };

  useEffect(() => {
    let partnerList = sessionStorage.getItem("partnerList");
    let oldFilters = sessionStorage.getItem("fundFilters");
    let fundDataList = sessionStorage.getItem("fundDataList");

    if (!partnerList) get_partner_list();
    else setPartnerList(JSON.parse(partnerList));

    if (!fundDataList) filter(0);
    // if (!fundDataList) loadData();
    else setFundDataList(JSON.parse(fundDataList));

    // if (oldFilters) setFilters(JSON.parse(oldFilters));
    filter(0);

    // if (Object.keys(params).length > 0) {
    if (searchParams.has("status")) {
      filterFunction();
    }
    return () => {
      console.log("Unmounted");
    };
  }, []);

  const loadData = async () => {
    setLoading(true);
    await FundManagementService.getFundData(tokens)
      .then((response: any) => {
        setFundDataList(response.data.data.result);
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPage(response.data.meta.page);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Something Went Wrong!");
      });
  };

  const filter = async (page: any, data?: any) => {
    setLoading(true);
    sessionStorage.setItem("fundFilters", JSON.stringify(filters));
    let newFilter = data ? data : filters;

    // if (Object.keys(params).length > 0) {
    if (searchParams.has("status")) {
      let filterss: any;
      if (decodedStatus == "WALLET" || decodedStatus == "VERIFICATION") {
        filterss = {
          ...filters,
          debitCredit: decodedStatus,
          // transactionFromTime: interval,
          transactionFromTime:
            decodedInterval < 1
              ? ""
              : moment().subtract(decodedInterval, "d").format("YYYY-MM-DD"),
          transactionToTime:
            decodedInterval < 1 ? "" : moment().format("YYYY-MM-DD"),
        };
      } else {
        filterss = {
          ...filters,
          debitCredit: decodedStatus == "TOTAL" ? "" : "ORDER_" + decodedStatus,
          transactionFromTime:
            decodedInterval < 1
              ? ""
              : moment().subtract(decodedInterval, "d").format("YYYY-MM-DD"),
          transactionToTime:
            decodedInterval < 1 ? "" : moment().format("YYYY-MM-DD"),
        };
      }
      await FundManagementService.filterData(tokens, filterss, page)
        .then((response: any) => {
          setFundDataList(response.data.data.result);

          sessionStorage.setItem(
            "fundDataList",
            JSON.stringify(response.data.data.result)
          );
          setMetaData({
            current: response.data.meta.page,
            pages: response.data.meta.totalPages,
          });
          setCurrentPage(response.data.meta.page);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    } else {
      await FundManagementService.filterData(tokens, newFilter, page)
        .then((response: any) => {
          setFundDataList(response.data.data.result);

          sessionStorage.setItem(
            "fundDataList",
            JSON.stringify(response.data.data.result)
          );
          setMetaData({
            current: response.data.meta.page,
            pages: response.data.meta.totalPages,
          });
          setCurrentPage(response.data.meta.page);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    }
  };

  const ResetForm = async () => {
    setCurrentPage(0);
    setFilters({
      id: "",
      method: "",
      transactionMasterId: "",
      customerID: "",
      amount: "",
      utr: "",
      debitCredit: "",
      paymentMethod: "",
      status: "",
      transactionRefId: "",
      providerTransactionId: "",
      pge: "",
      partner: partnerAdminId,
      entity: "",
      sourceModule: "",
      paymentStatus: "",
      settlementStatus: "",
      transactionFromTime: null,
      transactionToTime: null,
      settlementFromTime: null,
      settlementToTime: null,
    });
    // loadData();
    setFilters((prevFilter: any) => {
      // fetchData(0, prevFilter);
      // loadData();
      
      filter(0, prevFilter);
      return prevFilter;
    });
  
  };

  const paginationFilter = async (page: number) => {
    setCurrentPage(page);
    filter(page);
  };

  const downloadReport = async () => {
    setLoading(true);
    await FundManagementService.reportDownload(tokens, filters)
      .then((response: any) => {
        const href = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "transaction_logs_report.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Something Went Wrong!");
        setLoading(false);
      });
  };

  const checkStatus = () => {
    setCollapse(!collapse);
  };

  const filterFunction = () => {
    if (decodedStatus == "WALLET" || decodedStatus == "VERIFICATION") {
      setFilters({
        ...filters,
        debitCredit: decodedStatus,
        transactionFromTime:
          decodedInterval < 1
            ? ""
            : moment().subtract(decodedInterval, "d").format("YYYY-MM-DD"),
        transactionToTime:
          decodedInterval < 1 ? "" : moment().format("YYYY-MM-DD"),
      });
    } else {
      setFilters({
        ...filters,
        debitCredit: decodedStatus == "TOTAL" ? "" : "ORDER_" + decodedStatus,
        transactionFromTime:
          decodedInterval < 1
            ? ""
            : moment().subtract(decodedInterval, "d").format("YYYY-MM-DD"),
        transactionToTime:
          decodedInterval < 1 ? "" : moment().format("YYYY-MM-DD"),
      });
    }
  };

  const labelColor = (label: any) => {
    switch (label) {
      case "CREATED":
        return "bg-blue-200 text-blue-600";
      case "PENDING":
        return "bg-yellow-200 text-yellow-600";
      case "ACCEPTED":
        return "text-green-600 bg-green-200";
      case "REJECTED":
        return "bg-red-400 text-red-900";
      default:
        return "bg-gray-200";
    }
  };
  return (
    <Layout type={"PaymentModule"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">
          {"Transaction Logs"}
        </h1>
      </div>

      <div className="border bg-white rounded border-gray-200 shadow">
        <div className="flex-1 p-2">
          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="code"
              >
                Transaction master ID
              </label>
              <input
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                name="code"
                id="code"
                type="text"
                placeholder="Transaction master ID"
                autoComplete="none"
                onChange={(event) =>
                  setFilters({
                    ...filters,
                    transactionMasterId: event.target.value,
                  })
                }
                value={filters.transactionMasterId}
              />
            </div>
            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="code"
              >
                Transaction ID
              </label>
              <input
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                name="code"
                id="code"
                type="text"
                placeholder="Transaction ID"
                autoComplete="none"
                onChange={(event) =>
                  setFilters({
                    ...filters,
                    id: event.target.value,
                  })
                }
                value={filters.id}
              />
            </div>
            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="status"
              >
                Partner
              </label>
              <div className="relative">
                <select
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="partner"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      partner: event.target.value,
                    })
                  }
                  value={filters.partner}
                  disabled={isPartnerAdmin}
                >
                  <option value="">Select a Partner</option>
                  {partnerList.map((partner: any) => (
                    <option value={partner.partnerId}>{partner.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="code"
              >
                Customer ID
              </label>
              <input
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                name="code"
                id="code"
                type="text"
                placeholder=" Customer ID"
                autoComplete="none"
                onChange={(event) =>
                  setFilters({
                    ...filters,
                    customerID: event.target.value,
                  })
                }
                value={filters.customerID}
              />
            </div>
            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="code"
              >
                Amount
              </label>
              <input
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                name="code"
                id="code"
                type="text"
                placeholder="Amount"
                autoComplete="none"
                onChange={(event) =>
                  setFilters({
                    ...filters,
                    amount: event.target.value,
                  })
                }
                value={filters.amount}
              />
            </div>
            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="status"
              >
                PGE
              </label>
              <div className="relative">
                <select
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="status"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      pge: event.target.value,
                    })
                  }
                  value={filters.pge}
                >
                  <option value="">Select a PGE</option>
                  <option value="RPY">RPY</option>
                  <option value="DBS">DBS</option>
                  <option value="EBL">EBL</option>
                  <option value="KARZA">KARZA</option>
                </select>
              </div>
            </div>
            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="status"
              >
                Transaction Type
              </label>
              <div className="relative">
                <select
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="status"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      debitCredit: event.target.value,
                    })
                  }
                  value={filters.debitCredit}
                >
                  <option value="">Select a type</option>
                  <option value="ORDER_SELL">ORDER SELL</option>
                  <option value="ORDER_BUYBACK">ORDER BUYBACK</option>
                  <option value="ORDER_REFUND">ORDER REFUND</option>
                  <option value="WALLET">WALLET</option>
                  <option value="VERIFICATION">VERIFICATION</option>
                </select>
              </div>
            </div>
            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="status"
              >
                Method
              </label>
              <div className="relative">
                <select
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="status"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      method: event.target.value,
                    })
                  }
                  value={filters.method}
                >
                  <option value="">Select a Method</option>
                  <option value="WALLET">WALLET</option>
                  <option value="NEBK">NEBK</option>
                  <option value="UPI">UPI</option>
                  <option value="NEFT">NEFT</option>
                  <option value="IMPS">IMPS</option>
                  <option value="RTGS">RTGS</option>
                  <option value="VA">VA</option>
                </select>
              </div>
            </div>
            <div
              className="w-full collapse flex flex-wrap mb-2"
              id="collapseExample"
            >
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="status"
                >
                  Status
                </label>
                <div className="relative">
                  <select
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    id="status"
                    onChange={(event) =>
                      setFilters({
                        ...filters,
                        status: event.target.value,
                      })
                    }
                    value={filters.status}
                  >
                    <option value="">Select a status</option>
                    <option value="ACCEPTED">ACCEPTED</option>
                    <option value="PENDING">PENDING</option>
                    <option value="CREATED">CREATED</option>
                    <option value="REJECTED">REJECTED</option>
                    <option value="TRANSACTION_APPROVAL_PENDING">
                      TRANSACTION APPROVAL PENDING
                    </option>
                    <option value="TRANSACTION_VERIFICATION_PENDING">
                      TRANSACTION VERIFICATION PENDING
                    </option>
                  </select>
                </div>
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="status"
                >
                  Is Settled
                </label>
                <div className="relative">
                  <select
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    id="status"
                    onChange={(event) =>
                      setFilters({
                        ...filters,
                        settlementStatus: event.target.value,
                      })
                    }
                    value={filters.settlementStatus}
                  >
                    <option value="">Select a Settlement Status</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="code"
                >
                  Utr
                </label>
                <input
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  name="code"
                  id="code"
                  type="text"
                  placeholder="Utr"
                  autoComplete="none"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      utr: event.target.value,
                    })
                  }
                  value={filters.utr}
                />
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="description"
                >
                  Provider Transaction Ref ID
                </label>
                <input
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="description"
                  type="text"
                  placeholder="Provider Transaction Ref ID"
                  autoComplete="none"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      providerTransactionId: event.target.value,
                    })
                  }
                  value={filters.providerTransactionId}
                />
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="description"
                >
                  Eb Transaction ID
                </label>
                <input
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="description"
                  type="text"
                  placeholder="Transaction Ref ID"
                  autoComplete="none"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      transactionRefId: event.target.value,
                    })
                  }
                  value={filters.transactionRefId}
                />
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="status"
                >
                  Transaction from Time
                </label>
                <div className="relative">
                  <Datetime
                    timeFormat={"HH:mm"}
                    dateFormat={"YYYY-MM-DD"}
                    inputProps={{
                      readOnly: true,
                      placeholder: "pick date time",
                      className: "focus:outline-none",
                    }}
                    className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
             `}
                    onChange={(event: any) =>
                      setFilters({
                        ...filters,
                        transactionFromTime: moment(
                          new Date(event).toISOString()
                        ).format("YYYY-MM-DD"),
                      })
                    }
                    value={filters.transactionFromTime}
                    renderInput={(props) => {
                      return (
                        <input
                          {...props}
                          value={
                            filters.transactionFromTime
                              ? filters.transactionFromTime
                              : ""
                          }
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="status"
                >
                  Transaction to Time
                </label>
                <div className="relative">
                  <Datetime
                    timeFormat={"HH:mm"}
                    dateFormat={"YYYY-MM-DD"}
                    inputProps={{
                      readOnly: true,
                      placeholder: "pick date time",
                      className: "focus:outline-none",
                    }}
                    className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
             `}
                    onChange={(event: any) =>
                      setFilters({
                        ...filters,
                        transactionToTime: moment(
                          new Date(event).toISOString()
                        ).format("YYYY-MM-DD"),
                      })
                    }
                    value={filters.transactionToTime}
                    renderInput={(props) => {
                      return (
                        <input
                          {...props}
                          value={
                            filters.transactionToTime
                              ? filters.transactionToTime
                              : ""
                          }
                        />
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <button
              className="focus:outline-none bg-gray-400 collapsed dark:bg-blue-600 flex-wrap ml-3 mr-3 w-full text-center text-sm text-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
              onClick={() => {
                checkStatus();
              }}
            >
              {collapse ? "Hide" : "Show More"}
            </button>
            <div className="flex w-full justify-end px-3 mb-6 md:mb-0">
              <div className="my-3">
                <button
                  className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                  type="button"
                  onClick={() => {
                    filter(0);
                  }}
                >
                  Filter
                </button>
                <button
                  className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 mr-2"
                  type="button"
                  onClick={(e) => ResetForm()}
                >
                  Reset
                </button>
                {HasAnyAccess(["fund.management.report.download"]) && (
                  <button
                    className="text-white rounded-md bg-green-400 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                    type="button"
                    onClick={(e) => {
                      downloadReport();
                    }}
                  >
                    Download
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="border bg-white rounded border-gray-200 shadow m-2">
          {Loading ? (
            <Loader />
          ) : (
            <div className="overflow-x-auto">
              <table className="whitespace-nowrap rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                <thead>
                  <tr className="text-left border-b border-gray-300">
                    <th
                      style={{ minWidth: width + "px", left: 0 + "px" }}
                      className="sticky-col  sticky top-0 px-3 py-2 bg-gray-800 z-10"
                    >
                      Transaction ID
                    </th>
                    <th
                      style={{ minWidth: width + "px", left: width * 1 + "px" }}
                      className="sticky-col  sticky top-0 px-3 py-2 bg-gray-800 z-10"
                    >
                      Partner
                    </th>
                    <th
                      style={{ minWidth: width + "px", left: width * 1 + "px" }}
                      className="sticky-col  sticky top-0 px-3 py-2 bg-gray-800 z-10"
                    >
                      Customer ID
                    </th>
                    <th
                      style={{ minWidth: width + "px", left: width * 1 + "px" }}
                      className="sticky-col  sticky top-0 px-3 py-2 bg-gray-800 z-10"
                    >
                      Date
                    </th>
                    <th
                      style={{ minWidth: width + "px", left: width * 2 + "px" }}
                      className="sticky-col  sticky top-0 px-3 py-2 bg-gray-800 z-10"
                    >
                      Currency
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800">
                      Amount
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800">PGE</th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Transaction Type
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800">
                      Method
                    </th>

                    {/*<th className="sticky top-0 px-3 py-2 bg-gray-800">Payment Method</th>*/}
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Status
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Is Settled
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      UTR
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Provider Transaction ID
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Eb Transaction ID
                    </th>
                    {HasAnyAccess(["fund.management.report.view"]) && (
                      <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                        Action
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {0 !== fundDataList.length ? (
                    fundDataList.map((metal: any, index: number) => {
                      return (
                        <tr
                          key={index}
                          className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                        >
                          <td
                            style={{ minWidth: width + "px", left: 0 + "px" }}
                            className="bg-white z-10 sticky-col  py-3 px-3 text-left relative"
                          >
                            {metal.id}
                            <div className="font-bold">
                              ({metal.transactionMasterId})
                            </div>
                          </td>
                          <td
                            style={{
                              minWidth: width + "px",
                              left: width * 1 + "px",
                            }}
                            className="bg-white z-10 sticky-col  py-3 px-3 text-left relative"
                          >
                            <span>
                              {
                                metal.partnerId
                                  ? partnerList?.find(
                                      (i: any) =>
                                        i.partnerId ===
                                        parseInt(metal.partnerId)
                                    )?.name
                                  : "" // partnerList.map((x: any) =>  x.partnerId).includes(parseInt(data.partnerId)).name
                              }
                            </span>
                          </td>
                          <td
                            style={{
                              minWidth: width + "px",
                              left: width * 1 + "px",
                            }}
                            className="bg-white z-10 sticky-col  py-3 px-3 text-left relative"
                          >
                            {metal.customerId}
                          </td>
                          <td
                            style={{
                              minWidth: width + "px",
                              left: width * 1 + "px",
                            }}
                            className="bg-white z-10 sticky-col  py-3 px-3 text-left relative"
                          >
                            {moment(metal.date, "DD/MM/YYYY HH:mm:ss").format(
                              "DD-MM-YYYY HH:mm:ss"
                            )}
                          </td>
                          <td
                            style={{
                              minWidth: width + "px",
                              left: width * 2 + "px",
                            }}
                            className="bg-white z-10 sticky-col  py-3 px-3 text-left relative"
                          >
                            {metal.currency}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {formatAmount(metal.amount)}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {metal.provider}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {metal.type.replace("_", " ")}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {metal.method}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            <span
                              className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${labelColor(
                                metal.status
                              )}`}
                            >
                              {metal.status}
                            </span>
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            <span
                              className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${
                                metal.isSettled
                                  ? "text-green-600 bg-green-200"
                                  : "text-black-600 bg-gray-400"
                              }`}
                            >
                              {metal.isSettled ? "Yes" : "No"}
                            </span>
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {metal.utr}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {metal.providerTransactionId}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {metal.transactionRefId}
                          </td>
                          {HasAnyAccess(["fund.management.report.view"]) && (
                            <td className="py-3 px-3 text-left relative">
                              <button
                                className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                type="button"
                                onClick={() =>
                                  history.push(`/fund/${metal.id}`)
                                }
                              >
                                View
                              </button>
                            </td>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                      <td
                        colSpan={16}
                        className="text-center py-3 px-3 text-left relative"
                      >
                        No data to display
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          <Pagination
            meta={meta}
            handlePageChange={(number: number) => {
              paginationFilter(number);
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default FundManagement;
