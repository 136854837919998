import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const ExportToExcel = (apiData: any, fileName: string, columnTypes:any = []) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    let ws = XLSX.utils.json_to_sheet(apiData);
    ws["!cols"] = getMaxWidth(apiData);
    change_column_types(ws, columnTypes);

    const wb = {
        Sheets: {
            data: ws
        },
        SheetNames: ["data"]
    };
    const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array"

    });
    const data = new Blob([excelBuffer], {
        type: fileType
    });
    saveAs(data, fileName + fileExtension);
};

function getMaxWidth(data:any){

    if(data.length == 0 ){
        return []
    }

    let keys = Object.keys(data[0])
    let initLengths = keys.reduce((acc:any, current:any) => {
        acc.push(current.length)
        return acc;
    },[])

    let maxWidths = data.reduce((acc:any, current:any) => {
        
        let values:any = Object.values(current);
        for (let j = 0; j < values.length; j++) {
            if(values[j] && values[j] != null){
                if (typeof values[j] == "number") {
                    acc[j] = acc[j] >= 10 ? acc[j] : 10;
                } else {
                    acc[j] =
                    acc[j] >= values[j].length
                    ? acc[j]
                    : values[j].length;
                }
            }
        }
        return acc;

    },initLengths)
    return maxWidths.map((value:number) => {
        let padding = 3
        return{
            width : value + padding,
            font : {
                bold : true
            }
        }
    })
}

function sheet_set_column_format(ws:any, index:number, type:string) {
    var range = XLSX.utils.decode_range(ws["!ref"]);
    for(var R = range.s.r + 1; R <= range.e.r; ++R) {
      var cell = ws[XLSX.utils.encode_cell({r:R,c:index})];
      if(!cell) continue;
      cell.t = type
    }
  }

function change_column_types(ws:any, column_types = []){
    column_types.forEach((col:any) => sheet_set_column_format(ws, col.index , col.type))
}