import React, { useState, useEffect } from "react";
import { useAuthStore } from "../../../Store/AuthStore";
import Layout from "../../Layout/Layout";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import moment from "moment";
import { Pagination } from "../../../Components-ui/Pagination";
import { useHistory } from "react-router-dom";
import RoleService from "../../Role/Services/role.service";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";
import UserListService from "../../Userlist/Services/UserList.Service";
import InvestorListService from "../Services/InvestorList.Service";

const ListOfInvestors: React.FC = () => {
  const history = useHistory();
  const [Loading, setLoading] = useState<any>(true);
  const [userList, setUserList] = useState<any>([]);
  const [meta, setMetaData] = useState<any>({});
  const [partnerList, setPartnerList] = useState<any>([]);

  const [filters, setFilters] = useState<any>({
    partner: 0,
    investorId: "",
    investorName: "",
    mobile: "",
    referralCode: "",
    pan: "",
  });
  const [currentPage, setCurrentPage] = useState<number>(0);

  const { logout, permissions, userData, tokens } = useAuthStore();

  const get_user_list = async (page: number) => {
    setLoading(true);
    await InvestorListService.getAllInvestors(tokens, page, true)

      .then((response: any) => {
        setUserList(response.data.data.result);
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPage(page);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // toast.error(err.response.data.errors[0].title);
      });
  };

  const filter = async (page: number) => {
    setLoading(true);
    await InvestorListService.filterInvestors(filters, page, tokens)
      .then((response: any) => {
        setUserList(response.data.data.result);
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPage(response.data.meta.page);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err?.data?.errors[0]?.title);
      });
  };

  const ResetForm = async (e: any) => {
    e.preventDefault();
    setCurrentPage(0);
    setFilters({
      partner: 0,
      investorId: "",
      investorName: "",
      mobile: "",
      referralCode: "",
      pan: "",
    });
    await get_user_list(0);
  };

  const paginationFilter = async (page: number) => {
    await filter(page);
  };

  const editInvestor = async (id: any) => {
    try {
      history.push(`/updateInvestor/${id}`);
    } catch (err) {
      console.log(err);
    }
  };

  const enableDisableUser = async (e: any, investorId: string) => {
    setLoading(true);
    let payload = {
      investorId: investorId,
      status: e.target.checked,
    };
    try {
      await InvestorListService.updateInvestorStatus(tokens, payload);
      await get_user_list(currentPage);
      setLoading(false);
      return toast.success("Investor was successfully updated");
    } catch (e: any) {
      setLoading(false);
      if (e.data.errors.length > 0) {
        return toast.error(e.data.errors[0].title);
      }
      return toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    get_user_list(0);
    // get_partner_list();
  }, []);

  return (
    <Layout type={"InvestorModule"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">
          {"Business Partners"}
        </h1>
        {HasAnyAccess(["user.create"]) && (
          <button
            className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
            type="button"
            onClick={() => {
              history.push(`/investor/create`);
            }}
          >
            + Create New
          </button>
        )}
      </div>
      <div className="border bg-white rounded border-gray-200 shadow">
        <div className="flex-1 p-2">
          <form
            onSubmit={(e) => {
              filter(0);
              e.preventDefault();
            }}
          >
            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="user-id"
                >
                  Partner ID
                </label>
                <input
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      investorId: event.target.value,
                    })
                  }
                  name="user-id"
                  id="user-id"
                  type="text"
                  placeholder="Partner ID"
                  value={filters.investorId}
                  autoComplete="none"
                />
              </div>
              <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="user-id"
                >
                  Partner Name
                </label>
                <input
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      investorName: event.target.value,
                    })
                  }
                  name="user-name"
                  id="user-name"
                  type="text"
                  placeholder="Partner Name"
                  value={filters.investorName}
                  autoComplete="none"
                />
              </div>
              <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="user-id"
                >
                  Referral Code
                </label>
                <input
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      referralCode: event.target.value,
                    })
                  }
                  name="referralCode"
                  id="referralCode"
                  type="text"
                  placeholder="Referral Code"
                  value={filters.referralCode}
                  autoComplete="none"
                />
              </div>
              <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="panCard"
                >
                  PAN
                </label>
                <input
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      pan: event.target.value,
                    })
                  }
                  name="pan"
                  id="pan"
                  type="text"
                  placeholder="PAN"
                  value={filters.pan}
                  autoComplete="none"
                />
              </div>

              <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="mobile"
                >
                  Mobile
                </label>
                <input
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="mobile"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      mobile: event.target.value,
                    })
                  }
                  type="text"
                  placeholder="Mobile"
                  value={filters.mobile}
                  autoComplete="none"
                />
              </div>

              {/* <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="partner"
                >
                  Partner
                </label>
                <div className="relative">
                  <select
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    id="partner"
                    onChange={(event) =>
                      setFilters({
                        ...filters,
                        partner: event.target.value,
                      })
                    }
                    value={filters.partner}
                  >
                    <option value="">Select a Partner</option>
                    {partnerList.map((partner: any) => (
                      <option value={partner.partnerId}>{partner.name}</option>
                    ))}
                  </select>
                </div>
              </div> */}
              <div className="w-full px-3 mb-6 md:mb-0 justify-end">
                <div className="flex my-2 justify-end">
                  {/* <button
                  className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                  type="button"
                  onClick={() => filter(0)}
                >
                  Filter
                </button> */}
                  <input
                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                    type="submit"
                    value="Filter"
                  />
                  <button
                    className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                    type="button"
                    onClick={(e) => ResetForm(e)}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="border bg-white rounded border-gray-200 shadow m-2">
          {Loading ? (
            <Loader />
          ) : (
            <div>
              <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                <thead>
                  <tr className="text-left border-b border-gray-300">
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Partner Id
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                     Partner Name
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Mobile
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      PAN
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Business Partner
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Referral Code
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Revenue Share (%)
                    </th>
                    {HasAnyAccess(["investor.list.edit"]) && (
                      <>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                          Status
                        </th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                          Action
                        </th>
                      </>
                    )}

                    {/* <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Created Date
                    </th> */}
                    {/*{*/}
                    {/*    permissions.filter((permission: any) => permission.permissionCode === 'user.edit').length > 0 && (*/}
                    {/*<th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Status</th>)}*/}
                    {/* {HasAnyAccess([
                      "user.view",
                      "assign.role",
                      "assign.warehouse",
                    ]) && (
                      <th
                        className="sticky top-0 px-3 py-2 bg-gray-800 z-10"
                        colSpan={2}
                      >
                        Status
                      </th>
                    )} */}
                  </tr>
                </thead>
                <tbody>
                  {0 !== userList.length ? (
                    userList.map((user: any, index: number) => {
                      return (
                        <tr
                          key={index}
                          className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                        >
                          <td className="py-3 px-3 text-left relative">
                            {user.investorId}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {user.investorName}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {user.mobile}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {user.panCard}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {user.businessName}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {user.referralCode}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {user.commissionRate}
                          </td>
                          {/* {HasAnyAccess(["user.edit"]) && ( */}
                          {HasAnyAccess(["investor.list.edit"]) && (
                            <>
                              <td className="py-3 px-3 text-left relative">
                                <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                  <input
                                    type="checkbox"
                                    name="toggle"
                                    id={`${user.investorId}toggle`}
                                    checked={user.status == true}
                                    className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                                    onChange={(e) =>
                                      enableDisableUser(e, user.investorId)
                                    }
                                  />
                                  <label
                                    htmlFor={`${user.investorId}toggle`}
                                    className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                                  />
                                </div>
                              </td>

                              <td className="py-3 px-3 text-left relative">
                                <button
                                  className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                  type="button"
                                  onClick={() => {
                                    editInvestor(user?.investorId);
                                  }}
                                >
                                  View
                                </button>
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                      <td
                        colSpan={9}
                        className="text-center py-3 px-3 text-left relative"
                      >
                        No data to display
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          <Pagination
            meta={meta}
            handlePageChange={(number: number) => {
              paginationFilter(number);
            }}
          />
        </div>
      </div>

      {/* {OpenModal && (
        <AssignRoleForm
          onCloseModal={closeModal}
          user={User}
          getAllUsers={get_user_list}
          page={currentPage}
          roles={Roles}
        />
      )}

      {OpenModalAssignWarehouse && (
        <AssignWarehouseForm
          onCloseModal={onCloseWarehouseModal}
          user={UserWarehouse}
          getAllUsers={get_user_list}
          page={currentPage}
          warehouses={Warehouses}
        />
      )} */}
    </Layout>
  );
};

export default ListOfInvestors;
