import React, { useEffect, useState } from "react";
import { CURRENCY, DATA_TYPE } from "../../TradeExecution/Utils/enum";
import { ISSUE_TYPES } from "../Utils/enum";
import { useLocation, useParams } from "react-router-dom";
import Datetime from "react-datetime";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import { useHistory } from "react-router-dom";
import DiscountService from "../Services/discount.service";
import TradeExecutionService from "../../TradeExecution/Services/trade-execution.service";
import { toast, ToastContainer } from "react-toastify";
import { useAuthStore } from "../../../Store/AuthStore";
import SubItemList from "./SubItemList";
import Loader from "../../../Components-ui/Loader";
import MultipleSelect from "../../../Components-ui/MultipleSelect";
import { log } from "console";

interface PropsType {
  isEdit: boolean;
  id: any;
}

const TradeExecutionForm: React.FC<PropsType> = ({ isEdit, id }) => {
  const history = useHistory();
  const params: any = useParams();
  const { tokens } = useAuthStore();

  const [UOM, setUOM] = useState<any>([]);
  const [showCrossMetal, setShowCrossMetal] = useState<boolean>(false);
  const [isSlabValid, setIsSlabValid] = useState<any>(true);
  const [isView, setIsView] = useState<any>(false);
  const [SubItemsAmount, setSubItemsAmount] = useState<any>([]);
  const [SubItemsProductQty, setSubItemsProductQty] = useState<any>([]);
  const [SubItemsRate, setSubItemsRate] = useState<any>([]);
  const [Validator, setValidator] = useState(false);
  const [Loading, setLoading] = useState<any>(true);
  const [disCategory, setDisCategory] = useState<any>("");
  const [metals, setMetals] = useState<any>([]);
  const [secmetals, setSecMetals] = useState<any>([]);
  const [metalCodes, setMetalCodes] = useState<any>([]);
  const [customerGroup, setCustomerGroup] = useState<any>([]);
  const [partners, setPartners] = useState<any>([]);
  const [multiplePartners, setMulPartners] = useState<any>([]);
  const [customerGroupId, setCustomerGroupId] = useState<any>([]);
  const [partnerId, setPartnerId] = useState<any>([]);
  const [custSpecificId, setCustSpecificId] = useState<any>();
  const [isFocus, setIsFocus] = useState<any>(false);
  const [customer, setCustomer] = useState<any>({
    name: "",
    id: "",
  });
  const [selectedMetal, setSelectedMetal] = useState<any>({
    metalName: "",
    metalCode: "",
  });
  const [selectedSecondaryMetal, setSelectedSecondaryMetal] = useState<any>({
    metalName: "",
    metalCode: "",
  });

  const [SubItemError, setSubItemError] = useState<any>({
    AMOUNT: {
      lowerLimit: "",
      upperLimit: "",
      issueValue: "",
    },
    PRODUCT_QTY: {
      lowerLimit: "",
      upperLimit: "",
      issueValue: "",
    },
    RATE: {
      lowerLimit: "",
      upperLimit: "",
      issueValue: "",
    },
  });

  const [SubItemErrorEdit, setSubItemErrorEdit] = useState<any>({
    AMOUNT: {
      lowerLimit: "",
      upperLimit: "",
      issueValue: "",
    },
    PRODUCT_QTY: {
      lowerLimit: "",
      upperLimit: "",
      issueValue: "",
    },
    RATE: {
      lowerLimit: "",
      upperLimit: "",
      issueValue: "",
    },
  });

  const [ItemAmount, setItemAmount] = useState<any>({
    lowerLimit: "",
    upperLimit: "",
    issueValue: "",
    type: "AMOUNT",
  });

  const [ItemProductQty, setItemProductQty] = useState<any>({
    lowerLimit: "",
    upperLimit: "",
    issueValue: "",
    type: "PRODUCT_QTY",
  });
  const [ItemRate, setItemRate] = useState<any>({
    lowerLimit: "",
    upperLimit: "",
    issueValue: "",
    type: "RATE",
  });

  const [Discount, setDiscount] = useState<any>({
    name: "",
    effectFromDate: new Date().toISOString(),
    effectToDate: new Date().toISOString(),
    issueType: "",
    uom: "",
    currency: "",
    active: "ENABLED",
    details: [],
    description: "",
    discountCategory: "",
    discountUsageType: "",
    discountMaxUsageLimit: "",
    discountRateMaxValue: "",
    // metal: "",
    // itemCode: "",
    secondaryMetal: "",
    secondaryItemCode: "",
    is_cross_metal: false,
    isVisible: true,
  });

  const [ValidatorStatus, setValidatorStatus] = useState<boolean>(false);
  const metal = ["Silver", "Platinum", "Palladium", "Gold"];
  const discountCategories = [
    { label: "B2B", value: "B2B" },
    { label: "B2C", value: "B2C" },
    { label: "B2B & B2C", value: "BOTH" },
    { label: "PARTNER", value: "PARTNER_API_USER" },
  ];

  useEffect((): any => {
    if (isEdit) {
      getDiscountDetails();
      setValidatorStatus(true);
    } else {
      getUOMs();
    }
    // eslint-disable-next-line
  }, [isEdit]);

  useEffect(() => {
    if (params?.view == "view") {
      setIsView(true);
    }
  }, []);

  const simpleValidator = new SimpleReactValidator({
    locale: "en",
    validators: {},
  });
  const validationRef = React.useRef(simpleValidator);
  const validator = validationRef.current;

  /**
   * Get validation error messages
   * @param nameOfStateProp
   */
  const getValidatorError = (nameOfStateProp: any) => {
    const allErrorMessages = validator.getErrorMessages();

    return allErrorMessages[nameOfStateProp];
  };

  /**
   * Get all uoms
   */
  const getUOMs = async () => {
    let data: any = await TradeExecutionService.getUOMs(tokens);
    if (data.status === 200 && data.data.data) {
      let responseData = data.data.data;
      setUOM(responseData);
    }
    setLoading(false);
  };

  /**
   * Get discount details by id
   */
  const getDiscountDetails = async () => {
    let data: any = await DiscountService.getDiscountDetails(id, tokens);
    if (data.status === 200 && data?.data?.data) {
      let responseData = data?.data?.data;
      if (responseData) {
        setSubItemsProductQty(
          responseData?.discountDetails?.filter(
            (x: any) => x.type === "PRODUCT_QTY"
          )
        );
        setSubItemsAmount(
          responseData?.discountDetails?.filter((x: any) => x.type === "AMOUNT")
        );

        setSubItemsRate(
          responseData?.discountDetails?.filter((x: any) => x.type === "RATE")
        );
        let parsedPartnerObj = responseData?.partnerObj?.map((objString: any) =>
          JSON.parse(objString)
        );
        let parsedMetalObj = responseData?.metalObj?.map((objString: any) =>
          JSON.parse(objString)
        );

        setDiscount({
          ...responseData,
          partnerObj: parsedPartnerObj,
          metalObj: parsedMetalObj,
        });
        await getUOMs();
        // setDiscount(responseData);

        setCustomerGroupId(responseData?.customerGroups[0]);
        setPartnerId(responseData?.partnerIds[0]);
        setCustSpecificId(responseData?.customerId);
        // setDiscount({ ...responseData, customerGroups: ["19"] });
        // setSelectedMetal({ metalName: responseData[0].metal });
        // customerGroup?.filter((customer: any) =>
        //   console.log("ll", customer?.id)
        // );
        // console.log("cust", responseDat);
        // setCustomer({
        //   id: responseData?.id,
        // });
        setValidator(true);
      }
    }
    setLoading(false);
  };

  const getMetals = async () => {
    let response: any = await TradeExecutionService.getMetals(tokens);
    if (response.status === 200 && response.data.data) {
      let responseData = response.data.data;
      setSecMetals(responseData);
      // setMetals(responseData);
      // console.log("setmetals",responseData)
      let convertedData = responseData.map((metal: any) => ({
        label: metal.description.toUpperCase(),
        value: metal.description.toUpperCase(),
      }));
      convertedData = [{ label: "ALL", value: "ALL" }, ...convertedData];
      setMetals(convertedData);
    }
  };

  const filterMetals = async () => {
    let response: any = await TradeExecutionService.filterMetals(
      selectedMetal,
      tokens
    );
    if (response.status === 200 && response.data.data) {
      let responseData = response.data.data.result;
      setMetalCodes(responseData);
    }
  };

  const getCustomerGroup = async () => {
    let response: any = await TradeExecutionService.getCustomerGroup(
      Discount?.discountCategory,
      tokens
    );
    if (response.status === 200 && response.data.data) {
      let responseData = response.data.data;

      setCustomerGroup(responseData);
    }
  };
  const getPartners = async () => {
    let response: any = await TradeExecutionService.getPartners(tokens);
    if (response.status === 200 && response.data.data) {
      let responseData = response.data.data;
      setPartners(responseData);

      let convertedData = responseData.map((partner: any) => ({
        label: partner.name,
        value: partner.partnerId,
      }));
      convertedData = [{ label: "ALL", value: "ALL" }, ...convertedData];
      setMulPartners(convertedData);
    }
  };

  const handleChangeItem = (selected: any) => {
    if (selected.some((item: any) => item.value === "ALL")) {
      setDiscount({
        ...Discount,
        partnerObj: selected,
        partnerIds: multiplePartners
          .filter((option: any) => option.value !== "ALL") // Exclude "ALL"
          .map((option: any) => option.value),
      });
    } else {
      setDiscount({
        ...Discount,
        partnerObj: selected, // Only store the selected values
        partnerIds: selected.map((item: any) => item.value),
      });
    }
  };

  const handleChangeMetal = (selected: any) => {
    if (selected.some((item: any) => item.value === "ALL")) {
      setDiscount({
        ...Discount,
        metalObj: selected,
        metalName: metals
          .filter((option: any) => option.value !== "ALL") // Exclude "ALL"
          .map((option: any) => option.value),
      });
    } else {
      setDiscount({
        ...Discount,
        metalObj: selected, // Only store the selected values
        metalName: selected.map((item: any) => item.value),
      });
    }
  };
  useEffect(() => {
    getMetals();
  }, []);
  useEffect(() => {
    filterMetals();
  }, [selectedMetal]);
  //   useEffect(() => {
  //     getCustomerGroup();
  //   }, [Discount?.discountCategory]);
  useEffect(() => {
    if (Discount?.discountCategory == "PARTNER_API_USER") {
      getPartners();
    } else {
      getCustomerGroup();
    }
  }, [Discount?.discountCategory]);

  /**
   * Validate main rule fields according to their data types
   * @param e
   * @param type
   */
  const onChangeDiscount = (e: any, type: any) => {
    switch (type) {
      case DATA_TYPE.START_DATE:
        if (
          moment(Discount?.effectToDate).valueOf() >=
          moment(new Date(e)).valueOf() &&
          moment(e).valueOf() >= moment().subtract(1, "days").valueOf()
        ) {
          setValidator(true);
          return setDiscount({
            ...Discount,
            effectFromDate: new Date(e).toISOString(),
          });
        }
        if (
          moment(Discount?.effectToDate).valueOf() <
          moment(new Date(e)).valueOf()
        ) {
          setValidator(true);
          return setDiscount({
            ...Discount,
            effectToDate: new Date(e).toISOString(),
            effectFromDate: new Date(e).toISOString(),
          });
        }

        return setValidator(false);
      case DATA_TYPE.END_DATE:
        if (
          moment(Discount?.effectFromDate).valueOf() <=
          moment(new Date(e)).valueOf()
        ) {
          setValidator(true);
          return setDiscount({
            ...Discount,
            effectToDate: new Date(e).toISOString(),
          });
        }

        return setValidator(false);
      case DATA_TYPE.NORMAL:
        // itemCode
        if (e.target.name === "metal") {
          // setDiscount({
          //   ...Discount,
          //   [e.target.name]: e.target.value,
          //   itemCode: "",
          // });
          setDiscount({
            ...Discount,
            [e.target.name]: e.target.value,
            // itemCode:
            //   itemCodes.find((option) => option.label === e.target.value)
            //     ?.value || "",
          });
        } else if (e.target.name == "is_cross_metal") {
          setDiscount({
            ...Discount,
            [e.target.name]: e.target.value == "true" ? true : false,
          });
        } else if (e.target.name == "isVisible") {
          setDiscount({
            ...Discount,
            [e.target.name]: e.target.value == "false" ? false : true,
          });
        } else if (e.target.name === "secondaryMetal") {
          const index = e.target.selectedIndex;
          const el = e.target.childNodes[index];
          const option = el.getAttribute("id");
          setDiscount({
            ...Discount,
            [e.target.name]: e.target.value,
            secondaryMetalCode: option,

            secondaryItemCode:
              itemCodes.find((option) => option.label === e.target.value)
                ?.value || "",
          });
        } else if (e.target.name === "discountCategory") {
          setDiscount({
            ...Discount,
            [e.target.name]: e.target.value,
          });
          setPartnerId([]);
          setCustomerGroupId([]);
        } else if (e.target.name == "name") {
          setDiscount({
            ...Discount,
            name: e.target.value.toUpperCase(),
          });
        } else {
          setDiscount({
            ...Discount,
            [e.target.name]: e.target.value,
          });
        }

        return setValidator(true);
      default:
        return "";
    }
  };

  /**
   * Handle sub item fields
   * @param e
   * @param data
   * @param index
   * @param type
   */
  const handleSubItemOnChange = (
    e: any,
    type: any,
    data: any = null,
    index: any = null
  ) => {
    let { name, value } = e.target;
    if (data && data.isEdit) {
      name = name.replace("Edit", "");
      let cloneData = { ...data };
      cloneData[name] = value;

      //   let subItemClone =
      //     type === "AMOUNT" ? [...SubItemsAmount] : [...SubItemsProductQty];

      let subItemClone: any;
      if (type === "AMOUNT") {
        subItemClone = [...SubItemsAmount];
      } else if (type === "RATE") {
        subItemClone = [...SubItemsRate];
      } else {
        subItemClone = [...SubItemsProductQty];
      }
      subItemClone[index] = cloneData;

      if (type === "AMOUNT") {
        setSubItemsAmount(subItemClone);
      } else if (type === "RATE") {
        setSubItemsRate(subItemClone);
      } else {
        setSubItemsProductQty(subItemClone);
      }
    } else {
      if (type === "AMOUNT") {
        setItemAmount({
          ...ItemAmount,
          [name]: value,
        });
      } else if (type === "RATE") {
        setItemRate({
          ...ItemRate,
          [name]: value,
        });
      } else {
        setItemProductQty({
          ...ItemProductQty,
          [name]: value,
        });
      }
    }

    let object = {
      [name]: value,
    };

    return data && data.isEdit
      ? validateSubItemEditField(name, index, object, type)
      : validateSubItemField(name, object, type);
  };

  /**
   * Validate sub item fields
   * @param type
   * @param data
   * @param mainType
   */
  const validateSubItemField = (type: string, data: any, mainType: any) => {
    let result = true;

    // let mainObj = mainType === "AMOUNT" ? ItemAmount : ItemProductQty;
    let mainObj: any;
    if (mainType === "AMOUNT") {
      mainObj = ItemAmount;
    } else if (mainType === "RATE") {
      mainObj = ItemRate;
    } else {
      mainObj = ItemProductQty;
    }

    let updatedState = {
      ...mainObj,
      ...data,
    };

    const text = type.replace(/([A-Z])/g, " $1");
    const word = text.charAt(0).toUpperCase() + text.slice(1);
    const regexamt = /^\d+(\.\d{1,2})?$/;
    const regexprod = /^\d+(\.\d{1,4})?$/;
    if (mainType === "RATE" || mainType === "AMOUNT") {
      if (
        (updatedState[type] > 10000000 || updatedState[type] < 1) &&
        type !== "issueValue"
      ) {
        SubItemError[mainType][
          type
        ] = `${word} Should be between 1 to 1,00,00,000!`;
        result = false;
        setSubItemError(SubItemError);
        return result;
      } else if (!regexamt.test(updatedState[type])) {
        if (updatedState[type] !== "AMOUNT" && updatedState[type] !== "RATE") {
          SubItemError[mainType][
            type
          ] = `${word} should be only 2 digit after decimal!`;
          result = false;
          setSubItemError(SubItemError);
          return result;
        }
      }
    }
    if (mainType === "RATE" && type == "issueValue") {
      if (updatedState[type] > 100 || updatedState[type] < 0.1) {
        SubItemError[mainType][type] = `${word} Should be between 0.1 to 100`;
        result = false;
        setSubItemError(SubItemError);
        return result;
      }
    }
    if (mainType === "AMOUNT" && type == "issueValue") {
      if (updatedState[type] < 1) {
        SubItemError[mainType][type] = `${word} Should be more than 0`;
        result = false;
        setSubItemError(SubItemError);
        return result;
      }
    }
    if (mainType === "PRODUCT_QTY") {
      if (updatedState[type] > 1000 || updatedState[type] < 0.1) {
        SubItemError[mainType][
          type
        ] = `${word} Should be between 0.1g to 1000g`;
        result = false;
        setSubItemError(SubItemError);
        return result;
      } else if (!regexprod.test(updatedState[type])) {
        if (updatedState[type] !== "PRODUCT_QTY") {
          SubItemError[mainType][
            type
          ] = `${word} should be only 4 digit after decimal!`;
          result = false;
          setSubItemError(SubItemError);
          return result;
        }
      }
    }
    if (updatedState[type]?.length === 0 && type !== "upperLimit") {
      SubItemError[mainType][type] = `${word} can't be empty!`;
      result = false;
      setSubItemError(SubItemError);
      return result;
    } else {
      SubItemError[mainType][type] = "";
    }
    if (
      type === "issueValue" &&
      mainType !== "RATE" &&
      Number(updatedState?.issueValue) > Number(updatedState?.upperLimit) &&
      updatedState.issueType !== ""
    ) {
      SubItemError[mainType][type] = `${word} should be less than upper limit!`;
      result = false;
      setSubItemError(SubItemError);
      return result;
    } else {
      SubItemError[mainType][type] = "";
    }
    if (
      type === "upperLimit" &&
      Number(updatedState?.lowerLimit) >= Number(updatedState?.upperLimit) &&
      updatedState?.upperLimit !== ""
    ) {
      SubItemError[mainType][
        type
      ] = `${word} should be larger than lower limit!`;
      result = false;
      setSubItemError(SubItemError);
      return result;
    } else {
      SubItemError[mainType][type] = "";
    }

    const decimalCount = (num: any) => {
      const numStr = String(num);
      if (numStr.includes(".")) {
        return numStr.split(".")[1].length;
      }
      return 0;
    };

    if (mainType === "AMOUNT") {
      if (SubItemsAmount?.length > 0 && type === "lowerLimit") {
        let count = decimalCount(
          Number(SubItemsAmount[SubItemsAmount?.length - 1]?.upperLimit)
        );
        let new_lowerlimit =
          (Number(SubItemsAmount[SubItemsAmount?.length - 1]?.upperLimit) *
            10 ** count +
            1) /
          10 ** count;

        updatedState.lowerLimit = new_lowerlimit.toString();
        // SubItemsAmount[SubItemsAmount?.length - 1]?.upperLimit
        setItemAmount({
          ...ItemAmount,
          ...updatedState,
        });
      }
    } else if (mainType === "RATE") {
      if (SubItemsRate?.length > 0 && type === "lowerLimit") {
        let count = decimalCount(
          Number(SubItemsRate[SubItemsRate?.length - 1]?.upperLimit)
        );
        let new_lowerlimit =
          (Number(SubItemsRate[SubItemsRate?.length - 1]?.upperLimit) *
            10 ** count +
            1) /
          10 ** count;

        updatedState.lowerLimit = new_lowerlimit.toString();
        // updatedState.lowerLimit =
        //   SubItemsRate[SubItemsRate?.length - 1]?.upperLimit;
        setItemRate({
          ...ItemRate,
          ...updatedState,
        });
      }
    } else {
      if (SubItemsProductQty?.length > 0 && type === "lowerLimit") {
        let count = decimalCount(
          Number(SubItemsProductQty[SubItemsProductQty?.length - 1]?.upperLimit)
        );
        let new_lowerlimit =
          (Number(
            SubItemsProductQty[SubItemsProductQty?.length - 1]?.upperLimit
          ) *
            10 ** count +
            1) /
          10 ** count;
        new_lowerlimit = Math.round(new_lowerlimit * 10 ** count) / 10 ** count;

        updatedState.lowerLimit = new_lowerlimit.toString();
        // updatedState.lowerLimit =
        //   SubItemsProductQty[SubItemsProductQty?.length - 1]?.upperLimit;
        setItemProductQty({
          ...ItemProductQty,
          ...updatedState,
        });
      }
    }

    setSubItemError(SubItemError);
    return result;
  };

  /**
   * Validate sub item fields when editing
   * @param type
   * @param index
   * @param data
   * @param mainType
   */
  const validateSubItemEditField = (
    type: string,
    index: any,
    data: any,
    mainType: any
  ) => {
    let result = true;

    const text = type.replace(/([A-Z])/g, " $1");
    const word = text.charAt(0).toUpperCase() + text.slice(1);

    // let item =
    //   mainType === "AMOUNT" ? SubItemsAmount[index] : SubItemsProductQty[index];
    let item: any;
    if (mainType === "AMOUNT") {
      item = SubItemsAmount[index];
    } else if (mainType === "RATE") {
      item = SubItemsRate[index];
    } else {
      item = SubItemsProductQty[index];
    }

    let updatedItem = {
      ...item,
      ...data,
    };

    if (mainType === "RATE" || mainType === "AMOUNT") {
      if (
        (updatedItem[type] > 10000000 || updatedItem[type] < 1) &&
        type !== "issueValue"
      ) {
        SubItemErrorEdit[mainType][
          type
        ] = `${word} Should be between 1 to 1,00,00,000!`;
        result = false;
        setSubItemErrorEdit(SubItemErrorEdit);
        return result;
      }
    }
    if (mainType === "RATE" && type == "issueValue") {
      if (updatedItem[type] > 100 || updatedItem[type] < 0.1) {
        SubItemErrorEdit[mainType][
          type
        ] = `${word} Should be between 0.1 to 100`;
        result = false;
        setSubItemErrorEdit(SubItemErrorEdit);
        return result;
      }
    }
    if (mainType === "AMOUNT" && type == "issueValue") {
      if (updatedItem[type] < 1) {
        SubItemErrorEdit[mainType][type] = `${word} Should be more than 0`;
        result = false;
        setSubItemErrorEdit(SubItemErrorEdit);
        return result;
      }
    }
    if (mainType === "PRODUCT_QTY") {
      if (updatedItem[type] > 1000 || updatedItem[type] < 0.1) {
        SubItemErrorEdit[mainType][
          type
        ] = `${word} Should be between 0.1g to 1000g`;
        result = false;
        setSubItemErrorEdit(SubItemErrorEdit);
        return result;
      }
    }
    if (updatedItem[type]?.length === 0 && type !== "upperLimit") {
      SubItemErrorEdit[mainType][type] = `${word} can't be empty!`;
      result = false;
      setSubItemErrorEdit(SubItemErrorEdit);
      return result;
    } else {
      SubItemError[mainType][type] = "";
    }
    if (updatedItem[type]?.length === 0 && type !== "upperLimit") {
      SubItemErrorEdit[mainType][type] = `${word} can't be empty!`;
      result = false;
      setSubItemErrorEdit(SubItemErrorEdit);
      return result;
    } else {
      SubItemErrorEdit[mainType][type] = "";
    }
    if (
      type === "upperLimit" &&
      Number(updatedItem?.lowerLimit) >= Number(updatedItem?.upperLimit) &&
      updatedItem.upperLimit !== ""
    ) {
      SubItemErrorEdit[mainType][
        type
      ] = `${word} should be larger than lower limit!`;
      result = false;
      setSubItemErrorEdit(SubItemErrorEdit);
      return result;
    } else {
      SubItemErrorEdit[mainType][type] = "";
    }

    setSubItemErrorEdit(SubItemErrorEdit);
    return result;
  };

  /**
   * Add sub items to the list
   */
  const handleItems = (type: any) => {
    let results: any = [];
    let items: any;
    // Object.keys(type === "AMOUNT" ? ItemAmount : ItemProductQty).forEach(
    //   (item) => {
    //     results.push(validateSubItemField(item, {}, type));
    //   }
    // );
    if (type === "AMOUNT") {
      items = ItemAmount;
    } else if (type === "RATE") {
      items = ItemRate;
    } else {
      items = ItemProductQty;
    }
    Object.keys(items).forEach((item) => {
      results.push(validateSubItemField(item, {}, type));
    });
    const isChecked = results.every((x: any) => x === true);

    if (isChecked) {
      if (type === "AMOUNT") {
        let subItems = [...SubItemsAmount, ...[ItemAmount]];
        setSubItemsAmount(subItems);
        setInitialStateItemAmount();
      } else if (type === "RATE") {
        let subItems = [...SubItemsRate, ...[ItemRate]];
        setSubItemsRate(subItems);
        setInitialStateItemRate();
      } else {
        let subItems = [...SubItemsProductQty, ...[ItemProductQty]];
        // let subItems = [SubItemsProductQty];
        setSubItemsProductQty(subItems);
        setInitialStateItemProductQty();
      }
    }
  };

  /**
   * Remove sub item
   * @param index
   * @param type
   */
  const handleRemoveItem = (index: number, type: any) => {
    // const cloneList =
    //   type === "AMOUNT" ? [...SubItemsAmount] : [...SubItemsProductQty];
    let cloneList: any;
    if (type === "AMOUNT") {
      cloneList = [...SubItemsAmount];
    } else if (type === "RATE") {
      cloneList = [...SubItemsRate];
    } else {
      cloneList = [...SubItemsProductQty];
    }
    if (index > -1) {
      cloneList.splice(index, 1);
      if (type === "AMOUNT") {
        setSubItemsAmount(cloneList);
      } else if (type === "RATE") {
        setSubItemsRate(cloneList);
      } else {
        setSubItemsProductQty(cloneList);
      }
    }
  };

  /**
   * Remove isEdit from an array
   * @param data
   */
  const removeIsEdit = (data: any) => {
    return data.map((x: any) => {
      if (x.isEdit) {
        delete x.isEdit;
      }
      return x;
    });
  };

  /**
   * Add or update discount details
   * @param e
   */
  const handleOnSubmit = async (e: any) => {
    e.preventDefault();
    if (
      moment(Discount?.effectToDate).valueOf() ===
      moment(Discount?.effectFromDate).valueOf()
    ) {
      return toast.error(
        "Please make sure End Date is greater than Start Date."
      );
    }
    if (!Validator || !validator.allValid()) {
      setValidatorStatus(true);
      return toast.error("Please make sure all the inputs are valid.");
    }

    try {
      let cloneDiscount = { ...Discount };
      // delete cloneDiscount.partnerObj;
      let subItemsAmount = [...SubItemsAmount];
      let subItemsProductQty = [...SubItemsProductQty];
      let subItemsRate = [...SubItemsRate];
      if (subItemsAmount?.length > 0) {
        subItemsAmount[subItemsAmount?.length - 1].upperLimit =
          subItemsAmount[subItemsAmount?.length - 1]?.upperLimit === ""
            ? -1
            : subItemsAmount[subItemsAmount?.length - 1]?.upperLimit;
      }
      if (subItemsProductQty?.length > 0) {
        subItemsProductQty[subItemsProductQty?.length - 1].upperLimit =
          subItemsProductQty[subItemsProductQty?.length - 1]?.upperLimit === ""
            ? -1
            : subItemsProductQty[subItemsProductQty?.length - 1]?.upperLimit;
      }
      if (subItemsRate?.length > 0) {
        subItemsRate[subItemsRate?.length - 1].upperLimit =
          subItemsRate[subItemsRate?.length - 1]?.upperLimit === ""
            ? -1
            : subItemsRate[subItemsRate?.length - 1]?.upperLimit;
      }

      subItemsAmount = removeIsEdit(subItemsAmount);
      subItemsProductQty = removeIsEdit(subItemsProductQty);
      subItemsRate = removeIsEdit(subItemsRate);

      if (Discount?.issueType === "AMOUNT") {
        cloneDiscount.details = [...SubItemsAmount];
      } else if (Discount?.issueType === "RATE") {
        cloneDiscount.details = [...SubItemsRate];
      } else {
        cloneDiscount.details = [...SubItemsProductQty];
      }
      //   cloneDiscount.details = [...SubItemsAmount, ...subItemsProductQty];
      if (Discount?.discountCategory === "PARTNER_API_USER") {
        if (Discount?.discountUsageType !== "CUSTOMER_SPECIFIC")
          // cloneDiscount.partnerIds = [partnerId];
          console.log("partnerId");
      } else {
        if (Discount?.discountUsageType !== "CUSTOMER_SPECIFIC")
          cloneDiscount.customerGroups = [customerGroupId];

        // cloneDiscount.customerGroups = [customerGroupId];
      }
      //   cloneDiscount.customerGroups = [customerGroupId];
      //   cloneDiscount.details = [...subItemsAmount, ...subItemsProductQty];
      if (Discount?.discountUsageType === "CUSTOMER_SPECIFIC") {
        cloneDiscount.customerId = Number(custSpecificId);
      }
      if (cloneDiscount.details?.length === 0) {
        return toast.error("You have to add atleast one sub item.");
      }

      if (!isSlabValid) {
        return toast.error("You have to add atleast one CORRECT sub item.");
      }

      setLoading(true);

      if (isEdit) {
        await DiscountService.updateTDiscount(cloneDiscount, tokens);
        setLoading(false);
        history.push("/discount");
        toast.success("Discount was successfully updated");
      } else {
        await DiscountService.addDiscount(cloneDiscount, tokens);
        setInitialStateDiscount();
        setInitialStateItemAmount();
        setInitialStateItemProductQty();
        setInitialStateItemRate();
        setLoading(false);
        history.push("/discount");
        toast.success("Discount was successfully added");
        // return toast.success("Discount was successfully added");
      }
    } catch (error: any) {
      setInitialStateDiscount();
      setInitialStateItemAmount();
      setInitialStateItemProductQty();
      setInitialStateItemRate();
      setIsFocus(false);

      setLoading(false);
      setDiscount({
        name: "",
        effectFromDate: new Date().toISOString(),
        effectToDate: new Date().toISOString(),
        issueType: "",
        uom: "",
        currency: "",
        active: "ENABLED",
        details: [],
        description: "",
        discountCategory: "",
        discountUsageType: "",
        discountMaxUsageLimit: "",
        discountRateMaxValue: "",
        // metal: "",
        // itemCode: "",
        secondaryMetal: "",
        secondaryItemCode: "",
        is_cross_metal: false,
      });
      if (
        error &&
        error?.data &&
        error?.data?.errors &&
        error.data.errors?.length > 0
      ) {
        toast.error(error?.data?.errors[0]?.title);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  /**
   * Set item amount to initial state
   */
  const setInitialStateItemAmount = () => {
    setItemAmount({
      lowerLimit: "",
      upperLimit: "",
      issueValue: "",
      type: "AMOUNT",
    });
  };

  /**
   * Set item product qty to initial state
   */
  const setInitialStateItemProductQty = () => {
    setItemProductQty({
      lowerLimit: "",
      upperLimit: "",
      issueValue: "",
      type: "PRODUCT_QTY",
    });
  };
  const setInitialStateItemRate = () => {
    setItemRate({
      lowerLimit: "",
      upperLimit: "",
      issueValue: "",
      type: "RATE",
    });
  };

  /**
   * Set discount to initial state
   */
  const setInitialStateDiscount = () => {
    setDiscount({
      name: "",
      effectFromDate: new Date().toISOString(),
      effectToDate: new Date().toISOString(),
      issueType: "",
      uom: "",
      currency: "",
      active: "ENABLED",
      details: [],
      description: "",
      discountCategory: "",
      discountUsageType: "",
      discountMaxUsageLimit: "",
      discountRateMaxValue: "",
      // metal: "",
      // itemCode: "",
    });

    setSubItemsAmount([]);
    setSubItemsProductQty([]);
    setSubItemsRate([]);
    setCustomer({ name: "", id: "" });
    setCustomerGroupId([]);
    setPartnerId([]);
  };

  /**
   * Handle edit functionality in sub rules
   * @param index
   * @param item
   * @param type
   */
  const handleEditItem = (index: number, item: any, type: any) => {
    // let selectedItem =
    //   type === "AMOUNT"
    //     ? { ...SubItemsAmount[index] }
    //     : { ...SubItemsProductQty[index] };
    // selectedItem.isEdit = true;
    setIsSlabValid(false);
    let selectedItem: any;
    if (type === "AMOUNT") {
      selectedItem = { ...SubItemsAmount[index] };
    } else if (type === "RATE") {
      selectedItem = { ...SubItemsRate[index] };
    } else {
      selectedItem = { ...SubItemsProductQty[index] };
    }
    selectedItem.isEdit = true;

    // let subItemClone =
    //   type === "AMOUNT" ? [...SubItemsAmount] : [...SubItemsProductQty];
    // subItemClone[index] = selectedItem;
    let subItemClone: any;
    if (type === "AMOUNT") {
      subItemClone = [...SubItemsAmount];
    } else if (type === "RATE") {
      subItemClone = [...SubItemsRate];
    } else {
      subItemClone = [...SubItemsProductQty];
    }
    subItemClone[index] = selectedItem;

    if (type === "AMOUNT") {
      setSubItemsAmount(subItemClone);
    } else if (type === "RATE") {
      setSubItemsRate(subItemClone);
    } else {
      setSubItemsProductQty(subItemClone);
    }
  };

  /**
   * Handle update functionality in sub rules
   * @param index
   * @param item
   * @param type
   */
  const handleEditSubmit = (index: number, item: any, type: any) => {
    let results: any = [];
    let items: any;
    // Object.keys(type === "AMOUNT" ? ItemAmount : ItemProductQty).forEach(
    //   (item) => {
    //     results.push(validateSubItemEditField(item, index, {}, type));
    //   }
    // );

    if (type === "AMOUNT") {
      items = ItemAmount;
    } else if (type === "RATE") {
      items = ItemRate;
    } else {
      items = ItemProductQty;
    }
    Object.keys(items).forEach((item) => {
      results.push(validateSubItemEditField(item, index, {}, type));
    });

    const isValidate = results.every((x: any) => x === true);
    setIsSlabValid(isValidate);

    if (isValidate) {
      //   let selectedItem =
      //     type === "AMOUNT" ? SubItemsAmount[index] : SubItemsProductQty[index];
      //   delete selectedItem.isEdit;

      let selectedItem: any;
      if (type === "AMOUNT") {
        selectedItem = SubItemsAmount[index];
      } else if (type === "RATE") {
        selectedItem = SubItemsRate[index];
      } else {
        selectedItem = SubItemsProductQty[index];
      }

      // ? SubItemsAmount[index] : SubItemsProductQty[index];
      delete selectedItem.isEdit;

      if (type === "AMOUNT") {
        let subItemClone = [...SubItemsAmount];
        subItemClone[index] = selectedItem;

        setSubItemsAmount(subItemClone);
      } else if (type === "RATE") {
        let subItemClone = [...SubItemsRate];
        subItemClone[index] = selectedItem;

        setSubItemsRate(subItemClone);
      } else {
        let subItemClone = [...SubItemsProductQty];
        subItemClone[index] = selectedItem;
        setSubItemsProductQty(subItemClone);
      }
    }
  };

  const showCrossmetalFunc = (value: string) => {
    if (value == "true") {
      setShowCrossMetal(true);
    } else {
      setShowCrossMetal(false);
    }
  };
  const itemCodes = [
    { label: "PLATINUM", value: "Pt00003" },
    { label: "PALLADIUM", value: "Pd00008" },
    { label: "GOLD", value: "Au00005" },
    { label: "SILVER", value: "Ag0003" },
  ];
  return (
    <>
      <ToastContainer />
      {Loading && <Loader />}
      <div className="w-full rounded border-gray-200 overflow-hidden shadow-lg dark:border-gray-700 bg-white">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Discount Details
          </h3>
        </div>
        <hr />
        <div className="px-4 py-2 sm:px-6">
          <h4 className="text-md leading-6 font-medium text-gray-500">
            MASTER DETAILS
          </h4>
        </div>
        {/* Trade Execution Main Form */}
        <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
          <div className="col-span-12 sm:col-span-12">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <div className="mt-1">
              <input
                id="name"
                name="name"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={Discount?.name ?? ""}
                onChange={(e: any) => {
                  setIsFocus(true);
                  onChangeDiscount(e, DATA_TYPE.NORMAL);
                }}
                autoComplete="off"
                maxLength={10}
                disabled={isView || isEdit}
              />

              {validator.message("name", Discount?.name, "required|alpha_num")}

              {(isFocus || ValidatorStatus) && (
                <span className="text-xs text-red-700" id="name">
                  {getValidatorError("name")}
                </span>
              )}
            </div>
          </div>
          <div className="col-span-12 sm:col-span-12">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Description
            </label>
            <div className="mt-1">
              <input
                id="description"
                name="description"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={Discount?.description ?? ""}
                onChange={(e) => onChangeDiscount(e, DATA_TYPE.NORMAL)}
                autoComplete="off"
                disabled={isView}
              />

              {validator.message(
                "description",
                Discount?.description,
                "required"
              )}
              {ValidatorStatus && (
                <span className="text-xs text-red-700" id="description">
                  {getValidatorError("description")}
                </span>
              )}
            </div>
          </div>

          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="effectFromDate"
              className="block text-sm font-medium text-gray-700"
            >
              Start Date
            </label>
            {isView ? (
              <input
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                // value={moment(Discount?.effectFromDate).format()}
                value={moment(Discount?.effectFromDate).format("MM/DD/YYYY")}
                autoComplete="off"
                disabled={true}
              />
            ) : (
              <Datetime
                value={moment(Discount?.effectFromDate)}
                inputProps={{ className: "focus:outline-none w-full" }}
                timeFormat={"HH:mm"}
                dateFormat={"DD/MM/YYYY"}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={(e: any) => {
                  onChangeDiscount(e, DATA_TYPE.START_DATE);
                }}
                closeOnSelect={true}
              />
            )}
          </div>

          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="effectToDate"
              className="block text-sm font-medium text-gray-700"
            >
              End Date
            </label>
            {isView ? (
              <input
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={moment(Discount?.effectToDate).format("MM/DD/YYYY")}
                autoComplete="off"
                disabled={true}
              />
            ) : (
              <Datetime
                value={moment(Discount?.effectToDate)}
                inputProps={{ className: "focus:outline-none w-full" }}
                timeFormat={"HH:mm"}
                dateFormat={"DD/MM/YYYY"}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={(e: any) => {
                  onChangeDiscount(e, DATA_TYPE.END_DATE);
                }}
                closeOnSelect={true}
              />
            )}
          </div>

          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="metals"
              className="block text-sm font-medium text-gray-700"
            >
              Coupon Visible
            </label>
            <select
              id="isVisible"
              name="isVisible"
              autoComplete="isVisible"
              value={Discount?.isVisible}
              onChange={(e: any) => {
                onChangeDiscount(e, DATA_TYPE.NORMAL);
              }}
              // onChange={(e: any) => {
              //   setCustomerGroupId(e.target.value);

              // }}
              //   disabled={isEdit}
              className={`mt-1 block w-full py-2 px-3 border border-gray-300  rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            >
              <option value="" disabled>
                Select
              </option>

              <option value={"true"}>YES</option>
              <option value={"false"}>NO</option>
            </select>
          </div>

          {/*<div className="col-span-12 sm:col-span-4">
                        <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                            Type
                        </label>
                        <select
                            id="type"
                            name="type"
                            value={Discount?.type}
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e: any) => {
                                onChangeDiscount(e, DATA_TYPE.NORMAL)
                            }}
                        >
                            <option value="" disabled>Select a type</option>
                            {
                                TYPES.map((type: any, index: number) => {
                                    return (
                                        <option key={index} value={type.value}>{type.label}</option>
                                    )

                                })
                            }
                        </select>
                        {validator.message(
                            "type",
                            Discount?.type,
                            "required"
                        )}
                        <span className="text-xs text-red-700" id="type">{getValidatorError("type")}</span>
                    </div>*/}

          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="issueType"
              className="block text-sm font-medium text-gray-700"
            >
              Type
            </label>
            <select
              id="issueType"
              name="issueType"
              autoComplete="issueType"
              value={Discount?.issueType}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                onChangeDiscount(e, DATA_TYPE.NORMAL);
              }}
              disabled={isView}
            >
              <option value="" disabled>
                Select a type
              </option>
              {ISSUE_TYPES.map((currency: any, index: number) => {
                return (
                  <option key={index} value={currency.value}>
                    {currency.label}
                  </option>
                );
              })}
            </select>
            {validator.message("issueType", Discount?.issueType, "required")}
            {ValidatorStatus && (
              <span className="text-xs text-red-700" id="issueType">
                {getValidatorError("issueType")}
              </span>
            )}
          </div>


          {Discount?.issueType == "RATE" && (
            <div className="col-span-12 sm:col-span-4">
              <label
                htmlFor="discountRateMaxValue"
                className="block text-sm font-medium text-gray-700"
              >
                Maximum Discount Value
              </label>
              <div className="mt-1">
                <input
                  id="discountRateMaxValue"
                  name="discountRateMaxValue"
                  type="number"
                  value={Discount?.discountRateMaxValue ?? ""}
                  onChange={(e: any) => {
                    onChangeDiscount(e, DATA_TYPE.NORMAL);
                  }}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  autoComplete="off"
                  disabled={isView}
                />
              </div>
            </div>
          )}


          {Discount?.issueType == "PRODUCT_QTY" && (
            <div className="col-span-12 sm:col-span-4">
              <label
                htmlFor="metals"
                className="block text-sm font-medium text-gray-700"
              >
                Cross Metal
              </label>
              <select
                id="crossmetalId"
                name="is_cross_metal"
                autoComplete="crossmetalId"
                value={Discount?.is_cross_metal}
                onChange={(e: any) => {
                  showCrossmetalFunc(e.target.value);
                  onChangeDiscount(e, DATA_TYPE.NORMAL);
                }}
                // onChange={(e: any) => {
                //   setCustomerGroupId(e.target.value);

                // }}
                //   disabled={isEdit}
                className={`mt-1 block w-full py-2 px-3 border border-gray-300  rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              >
                <option value="" disabled>
                  Select
                </option>

                <option value={"true"}>YES</option>
                <option value={"false"}>NO</option>
              </select>
            </div>
          )}

          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="uom"
              className="block text-sm font-medium text-gray-700"
            >
              UOM
            </label>
            <select
              id="uom"
              name="uom"
              autoComplete="uom"
              value={Discount?.uom}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                onChangeDiscount(e, DATA_TYPE.NORMAL);
              }}
              disabled={isView}
            >
              <option value="" disabled>
                Select an uom
              </option>
              {/* {UOM.map((uom: any, index: number) => {
                return (
                  <option key={index} value={uom.uomCode}>
                    {uom.uomName}
                  </option>
                );
              })} */}
              <option value={"g"}>gram</option>
            </select>
            {validator.message("uom", Discount?.uom, "required")}
            {ValidatorStatus && (
              <span className="text-xs text-red-700" id="uom">
                {getValidatorError("uom")}
              </span>
            )}
          </div>

          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="currency"
              className="block text-sm font-medium text-gray-700"
            >
              Currency
            </label>
            <select
              id="currency"
              name="currency"
              autoComplete="currency"
              value={Discount?.currency}
              //   disabled={isEdit}
              className={`mt-1 block w-full py-2 px-3 border border-gray-300  rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              onChange={(e: any) => {
                onChangeDiscount(e, DATA_TYPE.NORMAL);
              }}
              disabled={isView}
            >
              <option value="" disabled>
                Select a currency
              </option>
              {/* {CURRENCY.map((currency: string, index: number) => {
                return (
                  <option key={index} value={currency}>
                    {currency}
                  </option>
                );
              })} */}
              <option value="INR">INR</option>
            </select>
            {validator.message("currency", Discount?.currency, "required")}
            {ValidatorStatus && (
              <span className="text-xs text-red-700" id="currency">
                {getValidatorError("currency")}
              </span>
            )}
          </div>

          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="metals"
              className="block text-sm font-medium text-gray-700"
            >
              Metals
            </label>
            <MultipleSelect
              optionList={metals}
              onChangeItem={handleChangeMetal}
              selectedValue={Discount.metalObj}
              closeMenuOnSelect={false}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
            />
            {/* <select
              id="metal"
              name="metal"
              autoComplete="metal"
              value={Discount?.metal}
              disabled={isView}
              //   disabled={isEdit}
              onChange={(e: any) => {
                const index = e.target.selectedIndex;
                const el = e.target.childNodes[index];
                const option = el.getAttribute("id");
                setSelectedMetal({
                  metalCode: option,
                });

                onChangeDiscount(e, DATA_TYPE.NORMAL);
              }}
              className={`mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            >
              <option value="" disabled>
                Select metal
              </option>
              <option value={"ALL"}>ALL</option>
              {metals?.map((metal: any, index: number) => {
                return (
                  <>
                    <option
                      key={index}
                      value={metal.description.toUpperCase()}
                      id={metal.metalCode}
                    >
                      {metal.description.toUpperCase()}
                    </option>
                  </>
                );
              })}
            </select> */}
            {validator.message("metal", Discount.metalObj, "required")}
            {ValidatorStatus && (
              <span className="text-xs text-red-700" id="metal">
                {getValidatorError("metal")}
              </span>
            )}
          </div>
          {/* <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="metals"
              className="block text-sm font-medium text-gray-700"
            >
              Item Code
            </label>
            <input
              id="itemCode"
              name="itemCode"
              value={Discount?.itemCode}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              autoComplete="off"
            />
          </div> */}

          {showCrossMetal || Discount.isCrossMetal ? (
            <>
              <div className="col-span-12 sm:col-span-4">
                <label
                  htmlFor="metals"
                  className="block text-sm font-medium text-gray-700"
                >
                  Secondary Metals
                </label>
                <select
                  id="secondaryMetal"
                  name="secondaryMetal"
                  autoComplete="secondaryMetal"
                  value={Discount?.secondaryMetal}
                  disabled={isView}
                  //   disabled={isEdit}
                  onChange={(e: any) => {
                    const index = e.target.selectedIndex;
                    const el = e.target.childNodes[index];
                    const option = el.getAttribute("id");
                    setSelectedSecondaryMetal({
                      metalCode: option,
                    });
                    onChangeDiscount(e, DATA_TYPE.NORMAL);
                    // setDiscount({
                    //   ...Discount,
                    //   metal: e.target.value.toUpperCase(),
                    // });
                  }}
                  // onChange={(e: any) => {
                  //   console.log("ee-", e);
                  //   setSelectedMetal({
                  //     metalName: JSON.parse(e.target.value).description,
                  //     metalCode: JSON.parse(e.target.value).metalCode,
                  //   });
                  //   setDiscount({
                  //     ...Discount,
                  //     metal: JSON.parse(e.target.value).description.toUpperCase(),
                  //   });
                  // }}
                  className={`mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                >
                  <option value="" disabled>
                    Select metal
                  </option>
                  {secmetals?.map((metal: any, index: number) => {
                    return (
                      <option
                        key={index}
                        value={metal.description.toUpperCase()}
                        id={metal.metalCode}
                      >
                        {metal.description.toUpperCase()}
                      </option>
                      // <option key={index} value={JSON.stringify(metal)}>
                      //   {metal.description.toUpperCase()}
                      // </option>
                    );
                  })}
                </select>
                {validator.message(
                  "secondaryMetal",
                  Discount?.secondaryMetal,
                  "required"
                )}
                {ValidatorStatus && (
                  <span className="text-xs text-red-700" id="secondaryMetal">
                    {getValidatorError("secondaryMetal")}
                  </span>
                )}
              </div>
              <div className="col-span-12 sm:col-span-4">
                <label
                  htmlFor="secondaryItemCode"
                  className="block text-sm font-medium text-gray-700"
                >
                  Secondary Item Code
                </label>
                <input
                  id="secondaryItemCode"
                  name="secondaryItemCode"
                  value={Discount?.secondaryItemCode}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  autoComplete="off"
                />
              </div>
            </>
          ) : (
            ""
          )}

          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="metals"
              className="block text-sm font-medium text-gray-700"
            >
              Coupon Usage Type
            </label>
            <select
              id="discountUsageType"
              name="discountUsageType"
              autoComplete="discountUsageType"
              disabled={isView}
              value={Discount?.discountUsageType}
              onChange={(e) => onChangeDiscount(e, DATA_TYPE.NORMAL)}
              //   disabled={isEdit}
              className={`mt-1 block w-full py-2 px-3 border border-gray-300  rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            >
              <option value="" disabled>
                Select Usage Type
              </option>
              <option value="PER_PERSON">PER PERSON</option>
              <option value="OVERALL">OVER ALL</option>
              <option value="CUSTOMER_SPECIFIC">CUSTOMER SPECIFIC</option>
            </select>
            {validator.message(
              "discountUsageType",
              Discount?.discountUsageType,
              "required"
            )}
            {ValidatorStatus && (
              <span className="text-xs text-red-700" id="discountUsageType">
                {getValidatorError("discountUsageType")}
              </span>
            )}
          </div>

          {Discount?.discountUsageType == "PER_PERSON" && (
            <div className="col-span-12 sm:col-span-4">
              <label
                htmlFor="overallMaxUsageLimit"
                className="block text-sm font-medium text-gray-700"
              >
                Customer Limit
              </label>
              <div className="mt-1">
                <input
                  id="overallMaxUsageLimit"
                  name="overallMaxUsageLimit"
                  type="number"
                  value={Discount?.overallMaxUsageLimit ?? ""}
                  onChange={(e: any) => {
                    onChangeDiscount(e, DATA_TYPE.NORMAL);
                  }}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  autoComplete="off"
                  disabled={isView}
                />
              </div>
            </div>
          )}
          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="metals"
              className="block text-sm font-medium text-gray-700"
            >
              Category
            </label>
            <select
              id="discountCategory"
              name="discountCategory"
              autoComplete="discountCategory"
              disabled={isView}
              value={Discount?.discountCategory}
              //   disabled={isEdit}
              onChange={(e: any) => {
                // setDisCategory(e.target.value);
                onChangeDiscount(e, DATA_TYPE.NORMAL);
              }}
              className={`mt-1 block w-full py-2 px-3 border border-gray-300  rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            >
              <option value="" disabled>
                Select Category
              </option>
              {Discount?.discountUsageType == "CUSTOMER_SPECIFIC" ? (
                <>
                  {discountCategories
                    .slice(0, 2)
                    .map((category: any, index: number) => {
                      return (
                        <option key={index} value={category.value}>
                          {category.label}
                        </option>
                      );
                    })}
                </>
              ) : (
                <>
                  {discountCategories.map((category: any, index: number) => {
                    return (
                      <option key={index} value={category.value}>
                        {category.label}
                      </option>
                    );
                  })}
                </>
              )}
            </select>
            {validator.message(
              "discountCategory",
              Discount?.discountCategory,
              "required"
            )}
            {ValidatorStatus && (
              <span className="text-xs text-red-700" id="discountCategory">
                {getValidatorError("discountCategory")}
              </span>
            )}
          </div>

          {Discount?.discountCategory ? (
            Discount?.discountCategory == "PARTNER_API_USER" ? (
              Discount?.discountCategory == "PARTNER_API_USER" &&
                Discount?.discountUsageType == "CUSTOMER_SPECIFIC" ? (
                <div className="col-span-12 sm:col-span-4">
                  <label
                    htmlFor="metals"
                    className="block text-sm font-medium text-gray-700"
                  >
                    CUSTOMER ID
                  </label>
                  <input
                    id="customerid"
                    name="customerid"
                    value={custSpecificId}
                    onChange={(e) => setCustSpecificId(e.target.value)}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    disabled={isView}
                  />

                  {validator.message("customerid", custSpecificId, "required")}
                  {ValidatorStatus && (
                    <span className="text-xs text-red-700" id="customerid">
                      {getValidatorError("customerid")}
                    </span>
                  )}
                </div>
              ) : (
                <div className="col-span-12 sm:col-span-4">
                  <label
                    htmlFor="metals"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Partner
                  </label>
                  {/* <select
                    id="partnerIds"
                    name="partnerIds"
                    autoComplete="partnerIds"
                    onChange={(e: any) => {
                      setPartnerId(e.target.value);
                    }}
                    value={partnerId}
                    disabled={isView}
                    // onChange=(())
                    //   disabled={isEdit}
                    className={`mt-1 block w-full py-2 px-3 border border-gray-300  rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  >
                    <option value="" disabled>
                      Select Partner
                    </option>
                    {partners[0] &&
                      partners?.map((category: any, index: number) => {
                        return (
                          <option key={index} value={category.partnerId}>
                            {category.name}
                          </option>
                        );
                      })}
                  </select> */}
                  <MultipleSelect
                    optionList={multiplePartners}
                    onChangeItem={handleChangeItem}
                    selectedValue={Discount.partnerObj}
                    closeMenuOnSelect={false}
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                  />
                  {/* {validator.message("partner", partnerId, "required")}
                  {ValidatorStatus && (
                    <span className="text-xs text-red-700" id="partnerIds">
                      {getValidatorError("partner")}
                    </span>
                  )} */}
                </div>
              )
            ) : Discount?.discountUsageType == "CUSTOMER_SPECIFIC" ? (
              <div className="col-span-12 sm:col-span-4">
                <label
                  htmlFor="metals"
                  className="block text-sm font-medium text-gray-700"
                >
                  CUSTOMER ID
                </label>
                <input
                  id="customerid"
                  name="customerid"
                  value={custSpecificId}
                  onChange={(e) => setCustSpecificId(e.target.value)}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  disabled={isView}
                />
                {validator.message("customerid", custSpecificId, "required")}
                {ValidatorStatus && (
                  <span className="text-xs text-red-700" id="customerid">
                    {getValidatorError("customerid")}
                  </span>
                )}
              </div>
            ) : (
              <div className="col-span-12 sm:col-span-4">
                <label
                  htmlFor="metals"
                  className="block text-sm font-medium text-gray-700"
                >
                  Customer Group
                </label>
                <select
                  id="customerGroupIds"
                  name="customerGroupIds"
                  autoComplete="customerGroupIds"
                  disabled={isView}
                  value={customerGroupId}
                  //   onChange={(e: any) => setCustomerGroupId(e.target.value)}
                  onChange={(e: any) => {
                    setCustomerGroupId(e.target.value);

                    // setCustomer({
                    //   name: JSON.parse(e.target.value).name,
                    //   id: JSON.parse(e.target.value).id,
                    // });
                  }}
                  //   disabled={isEdit}
                  className={`mt-1 block w-full py-2 px-3 border border-gray-300  rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                >
                  <option value="" disabled>
                    Select Customer Group
                  </option>
                  {customerGroup[0] &&
                    customerGroup?.map((category: any, index: number) => {
                      return (
                        <option key={index} value={category.id}>
                          {category.name}
                        </option>
                        // <option key={index} value={JSON.stringify(category)}>
                        //   {category.name}
                        // </option>
                      );
                    })}
                </select>
                {/* {validator.message("customer", customerGroupId, "required")}
                {ValidatorStatus && (
                  <span className="text-xs text-red-700" id="customerGroupIds">
                    {getValidatorError("customer")}
                  </span>
                )} */}
              </div>
            )
          ) : null}

          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Usage Limit
            </label>
            <div className="mt-1">
              <input
                id="discountMaxUsageLimit"
                name="discountMaxUsageLimit"
                type="number"
                value={Discount?.discountMaxUsageLimit ?? ""}
                onChange={(e: any) => {
                  onChangeDiscount(e, DATA_TYPE.NORMAL);
                }}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                autoComplete="off"
                disabled={isView}
              />

              {validator.message(
                "discountMaxUsageLimit",
                Discount?.discountMaxUsageLimit,
                "required"
              )}
              {ValidatorStatus && (
                <span
                  className="text-xs text-red-700"
                  id="discountMaxUsageLimit"
                >
                  {getValidatorError("discountMaxUsageLimit")}
                </span>
              )}
            </div>
          </div>

          {/* <div
            className="col-span-12 sm:col-span-4 "
            style={{ marginTop: "40px" }}
          >
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              <input
                type="checkbox"
                // checked={isChecked}
                // onChange={handleCheckboxChange}
              />
              Cross Metal
            </label>
          </div> */}

          {/* <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="metals"
              className="block text-sm font-medium text-gray-700"
            >
              Coupon Usage Type
            </label>
            <select
              id="discountUsageType"
              name="discountUsageType"
              autoComplete="discountUsageType"
              disabled={isView}
              value={Discount?.discountUsageType}
              onChange={(e) => onChangeDiscount(e, DATA_TYPE.NORMAL)}
              //   disabled={isEdit}
              className={`mt-1 block w-full py-2 px-3 border border-gray-300  rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            >
              <option value="" disabled>
                Select Usage Type
              </option>
              <option value="PER_PERSON">PER PERSON</option>
              <option value="OVERALL">OVER ALL</option>
            </select>
            {validator.message(
              "discountUsageType",
              Discount?.discountUsageType,
              "required"
            )}
            {ValidatorStatus && (
              <span className="text-xs text-red-700" id="discountUsageType">
                {getValidatorError("discountUsageType")}
              </span>
            )}
          </div> */}
          {Discount?.issueType == "AMOUNT" && (
            <div className="col-span-12 sm:col-span-12">
              <div className="py-2">
                <h4 className="text-md leading-6 font-medium text-gray-500">
                  SLABS - AMOUNT
                </h4>
              </div>
              {/* Discount SLABS */}
              <SubItemList
                subItems={SubItemsAmount}
                item={ItemAmount}
                onSubItemChange={handleSubItemOnChange}
                onAddItems={handleItems}
                onRemoveItem={handleRemoveItem}
                subRuleErrors={SubItemError}
                subRuleErrorsEdit={SubItemErrorEdit}
                onEditItem={handleEditItem}
                onEditSubmitItem={handleEditSubmit}
                isView={isView}
                type="AMOUNT"
              />
            </div>
          )}
          {Discount?.issueType == "PRODUCT_QTY" && (
            <div className="col-span-12 sm:col-span-12">
              <div className="py-2">
                <h4 className="text-md leading-6 font-medium text-gray-500">
                  SLABS - PRODUCT QUANTITY
                </h4>
              </div>
              {/* Discount SLABS */}
              <SubItemList
                subItems={SubItemsProductQty}
                item={ItemProductQty}
                onSubItemChange={handleSubItemOnChange}
                onAddItems={handleItems}
                onRemoveItem={handleRemoveItem}
                subRuleErrors={SubItemError}
                subRuleErrorsEdit={SubItemErrorEdit}
                onEditItem={handleEditItem}
                onEditSubmitItem={handleEditSubmit}
                isView={isView}
                type="PRODUCT_QTY"
              />
            </div>
          )}
          {Discount?.issueType == "RATE" && (
            <div className="col-span-12 sm:col-span-12">
              <div className="py-2">
                <h4 className="text-md leading-6 font-medium text-gray-500">
                  SLABS - RATE
                </h4>
              </div>
              {/* Discount SLABS */}
              <SubItemList
                subItems={SubItemsRate}
                item={ItemRate}
                onSubItemChange={handleSubItemOnChange}
                onAddItems={handleItems}
                onRemoveItem={handleRemoveItem}
                subRuleErrors={SubItemError}
                subRuleErrorsEdit={SubItemErrorEdit}
                onEditItem={handleEditItem}
                onEditSubmitItem={handleEditSubmit}
                isView={isView}
                type="RATE"
              />
            </div>
          )}
        </div>
        <div className="flex items-center justify-end sm:py-3  sm:px-6 border-t border-solid border-blueGray-200 rounded-b">
          <div>
            <button
              id="modal"
              className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
              type="button"
              onClick={() => history.goBack()}
            >
              Close
            </button>
          </div>
          {!isView && (
            <div>
              <button
                className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                type="button"
                onClick={(e) => handleOnSubmit(e)}
              >
                {isEdit ? "Update" : "Save"}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TradeExecutionForm;
