import Http from "../../library/http.library";
import {httpMethods} from "../../library/enum";

export const getBankDetailsList = async (tokens: any, currentPage: any) => {
    return await Http.setMethod(httpMethods.post).setUrl(`/ebl/payment-account/get-all`).setToken(tokens.idToken.jwtToken).setData({
        "page":currentPage,
        "pageSize": 10
    }).request();

};

export const UpdateDetails = async (data: any, tokens: any) => {
    return Http.setMethod(httpMethods.patch).setUrl(`/ebl/payment-account/update`).setData({
        "code": data.code,
        "bankName": data.bankName,
        "accountName": data.accountName,
        "accountNumber": data.accountNumber,
        "ifsc": data.ifsc,
        "partyType": data.partyType,
        "remarks": data.remarks
    }).setToken(tokens.idToken.jwtToken).request();

};

export const addNewPartner = async (data: any, tokens: any) => {
    return await Http.setMethod(httpMethods.post).setUrl(`/ebl/payment-account/create`).setData({
        "code": data.code,
        "bankName": data.bankName,
        "accountName": data.accountName,
        "accountNumber": data.accountNumber,
        "ifsc": data.ifsc,
        "partyType": data.partyType,
        "remarks": data.remarks
    }).setToken(tokens.idToken.jwtToken).request();

};

export const deleteRecord = async (data: any, tokens: any) => {
    return await Http.setMethod(httpMethods.delete).setUrl(`/ebl/payment-account/delete`).setData({
        "code": data.code
    }).setToken(tokens.idToken.jwtToken).request();

};


const BankDetailsService = {
    getBankDetailsList,
    UpdateDetails,
    addNewPartner,
    deleteRecord
};

export default BankDetailsService;
