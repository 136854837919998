import { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import Layout from '../../Layout/Layout';
import ChartComponent from './ChartComponent';
import './table.css';

const TableComponent = () => {
  const [selectedButton, setSelectedButton] = useState(0); // Set default to 'Gold'
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(5); // Set default to '1W'

  const handleButtonClick = (buttonIndex:any) => {
    if (buttonIndex < 4) {
      // If it's a metal button
      setSelectedButton(buttonIndex);
    } else {
      // If it's a time frame button
      setSelectedTimeFrame(buttonIndex);
    }
  };
  const metalCodes = ['Au', 'Ag', 'Pt', 'Pd'];
  const buttonNames = [
    'Gold',
    'Silver',
    'Platinum',
    'Palladium',
    '1D',
    '1W',
    '1M',
  ];
  const renderButtons = () => {
    return buttonNames.map((buttonName, index) => (
      <Button
        key={index}
        variant='light'
        className={`rounded-square ${
          selectedButton === index || selectedTimeFrame === index
            ? 'selected'
            : ''
        }`}
        onClick={() => handleButtonClick(index)}
        style={{
          backgroundColor:
            selectedButton === index || selectedTimeFrame === index
              ? '#301250'
              : '#fff',
          fontWeight: 'bold',
          color:
            selectedButton === index || selectedTimeFrame === index
              ? '#fff'
              : '#000',
          border: '1px solid #ccc',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          margin: '5px',
          fontSize: '14px',
        }}
      >
        {buttonName}
      </Button>
    ));
  };

  // useEffect(() => {
  //   const selectedMetal = metalCodes[selectedButton];
  //   const selectedDuration = buttonNames[selectedTimeFrame];
  // }, [selectedButton, selectedTimeFrame]);

  return (
    <Layout type="ChartModule">
       <div className="flex items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight" style={{marginLeft:'14px'}}>Price Charts</h1>
      </div>
      <div>
       
        <div className='chart-table-responsive'>
          <Table striped bordered className='custom-table-chart'>
            <tbody>
              <tr>
                <td colSpan={3} className='small-width'>
                  {renderButtons()}
                </td>
              </tr>
              <tr className='white-background'>
                <td colSpan={3} className='bigger-width'>
                  <ChartComponent
                    selectedMetal={metalCodes[selectedButton]}
                    selectedDuration={buttonNames[selectedTimeFrame]}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        {/* <div
          className='m-2 text-center'
          style={{ fontSize: '14px', color: '#890105' }}
        >
          *Note: Daily prices are captured at 9:00 am (IST).
        </div> */}
      </div>
    </Layout>
  );
};

export default TableComponent;
