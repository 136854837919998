import React, { useState, useEffect, useRef } from "react";

interface OTPComponentProps {
  onClose: () => void;
  onVerify: (otp: string) => void;
  onResend: () => void;
  data: any;
}

const OTPComponent: React.FC<OTPComponentProps> = ({
  onClose,
  onVerify,
  onResend,
  data,
}) => {
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
  const [seconds, setSeconds] = useState(60);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        setIsResendVisible(true);
        clearInterval(timer);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [seconds]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;
    if (isNaN(Number(value)) || value.length > 1) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleResend = () => {
    setOtp(new Array(6).fill(""));
    setSeconds(60);
    setIsResendVisible(false);
    if (onResend) {
      onResend();
    }
  };

  // const maskedMobileNumber = `**** **** ${data?.slice(-4)}`;
  const isOtpComplete = otp.every((digit) => digit !== "");


  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50 p-4">
      <div className="bg-white p-6 rounded shadow-lg relative w-full max-w-md">
        <button
          className="absolute top-2 right-2 text-gray-600"
          onClick={onClose}
        >
          ✖️
        </button>
        <div className="text-center mb-6">
          <h3 className="text-xl font-bold mb-2">Enter OTP</h3>
          {"aadhaarNo" in data ? (
            <p className="text-gray-600">{`Please enter the 6-digit OTP sent to your aadhaar registered mobile number
             
            `}</p>
          ) : (
            <p className="text-gray-600">{`Please enter the 6-digit OTP sent to your ${
              "mobile_no" in data
                ? "number " + data.mobile_no
                : "email " + data.email
            } `}</p>
          )}
        </div>
        <div className="flex justify-center mb-6 space-x-2">
          {otp.map((data, index) => (
            <input
              ref={(el) => (inputRefs.current[index] = el)}
              className="w-10 h-10 text-center border border-gray-400 rounded"
              type="text"
              maxLength={1}
              key={index}
              value={data}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onFocus={(e) => e.target.select()}
              autoFocus={index === 0}
            />
          ))}
        </div>
        <div className="text-center mb-6">
          {isResendVisible ? (
            <button
              className="text-blue-500 underline"
              onClick={() => handleResend()}
            >
              Resend OTP
            </button>
          ) : (
            <span className="text-gray-600">Time remaining: {seconds}s</span>
          )}
        </div>
        <div className="flex justify-around">
          <button
            className="text-white px-4 py-2 rounded hover:opacity-90"
            style={{ backgroundColor: "rgb(34, 11, 59)" }}
            onClick={() => onVerify && onVerify(otp.join(""))}
            disabled={!isOtpComplete}
          >
            Verify
          </button>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default OTPComponent;
