import Http from "../../../library/http.library";
import { httpMethods } from "../../../library/enum";
import { createQuery } from "../../../Common/util.helper";

export const getUserStatistics = async (data: any, tokens: any) => {
  let queryParams = createQuery(data);
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/user/getUserStatistics${queryParams}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};
export const getTransactionStatistics = async (data: any, tokens: any) => {
  let queryParams = createQuery(data);
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/payment/getTransactionStatistics${queryParams}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};
export const getOrderStatistics = async (data: any, tokens: any) => {
  let queryParams = createQuery(data);
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/order/getOrderStatistics${queryParams}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};
