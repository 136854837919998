import React, { Fragment } from "react";
import { Route } from "react-router-dom";

import Main from "../../TestPage/Compnents/Main";
import Branch from "../Compnents/Branch";
import PricingFeed from "../Compnents/PricingFeed";

const TestRoutes: React.FC = () => {
  return (
    <Fragment>
      <Route path="/test" exact component={Branch} />
      <Route path="/pricingfeed" exact component={PricingFeed} />
    </Fragment>
  );
};

export default TestRoutes;
