import React from "react";
import { Route } from "react-router-dom";

import RoleTable from "../Components/RoleTable";


export const RoleRoutes: React.FC = () => {
    return (
        <>
            <Route path="/roles" exact component={RoleTable} />
        </>
    );
};
