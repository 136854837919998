import Layout from "../Layout/Layout";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "./react-confirm-alert.css";
import Loader from "../../Components-ui/Loader";
import moment from "moment";
import WarehouseMasterService from "./WearHouseMaster.Service";
import { Pagination } from "../../Components-ui/Pagination";
import { useAuthStore } from "../../Store/AuthStore";
import WareHouseAddressDetails from "./WareHouseAddressDetails";

const WarehouseMaster: React.FC = () => {
    const { tokens } = useAuthStore();
    const [Loading, setLoading] = useState<any>(true);
    const [itemList, setItemList] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [meta, setMetaData] = useState<any>({});
    const [modalData, setModalData] = useState<any>({});
    const [filters, setFilters] = useState<any>({
        active: "",
        addressLine1: "",
        city: "",
        gstState: "",
        state: "",
        pincode: null,
        type: "",
        warehouseId: "",
        warehouseName: "",
    });
    const [OpenModal, setOpenModal] = useState(false);


    /**
     * Set modal data
     */
    const setModal = (data: any) => {
        setModalData(data);
    }

    /**
     * Close modal
     */
    const closeModal = () => {
        setModalData({});
        setOpenModal(false);
    }


    useEffect(() => {
        loadItemData(0);
    }, []);

    useEffect(() => {
        if (Object.keys(modalData).length > 0) {
            setOpenModal(true);
        }

    }, [modalData]);

    const loadItemData = async (page: number) => {
        setLoading(true)
        await WarehouseMasterService.getWarehouseMasterData(tokens, page).then((response: any) => {
            setItemList(response.data.data.result);
            setMetaData({
                current: response.data.meta.page,
                pages: response.data.meta.totalPages,
            });
            setCurrentPage(page);
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            toast.error("Something Went Wrong!");
        });
    };

    const filter = async (page: any) => {
        setLoading(true);
        await WarehouseMasterService.filterWarehouseMasterData(filters, page, tokens).then((response: any) => {
            setItemList(response.data.data.result);
            setMetaData({
                current: response.data.meta.page,
                pages: response.data.meta.totalPages,
            });
            setCurrentPage(response.data.meta.page);
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            toast.error("Something Went Wrong!");
        });
    }

    const ResetForm = async (e: any) => {
        e.preventDefault();
        setCurrentPage(0);
        setFilters({
            active: "",
            addressLine1: "",
            city: "",
            gstState: "",
            state: "",
            pincode: null,
            type: "",
            warehouseId: "",
            warehouseName: "",
        });
        await loadItemData(0);
    }

    const paginationFilter = async (page: number) => {
        setCurrentPage(page);
        await filter(page);
    }

    return (
        <Layout type={"MasterModule"}>
            <ToastContainer />
            <div className="flex justify-between items-center mb-5">
                <h1 className="text-2xl font-bold tracking-tight">
                    {"Warehouse Master"}
                </h1>
            </div>
            <div className="border bg-white rounded border-gray-200 shadow">
                <div className="flex-1 p-2">
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-id">
                                Warehouse Id
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                onChange={event => setFilters({
                                    ...filters,
                                    warehouseId: event.target.value
                                })}
                                name="user-id" id="user-id" type="text" placeholder="Warehouse Id"
                                value={filters.warehouseId}
                                autoComplete="none" />
                        </div>
                        <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="email">
                                Warehouse Name
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="email" onChange={event => setFilters({
                                    ...filters,
                                    warehouseName: event.target.value
                                })} type="text" placeholder="Warehouse Name" value={filters.warehouseName}
                                autoComplete="none" />
                        </div>
                        <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="mobile">
                                Gst State
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="mobile" onChange={event => setFilters({
                                    ...filters,
                                    gstState: event.target.value
                                })} type="text" placeholder="Gst State" value={filters.gstState} autoComplete="none" />
                        </div>
                        <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="mobile">
                                Address Line 1
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="mobile" onChange={event => setFilters({
                                    ...filters,
                                    addressLine1: event.target.value
                                })} type="text" placeholder="Address Line 1" value={filters.addressLine1}
                                autoComplete="none" />
                        </div>
                        <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="mobile">
                                City
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="mobile" onChange={event => setFilters({
                                    ...filters,
                                    city: event.target.value
                                })} type="text" placeholder="City" value={filters.city} autoComplete="none" />
                        </div>
                        <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="mobile">
                                State
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="mobile" onChange={event => setFilters({
                                    ...filters,
                                    state: event.target.value
                                })} type="text" placeholder="State" value={filters.state} autoComplete="none" />
                        </div>
                        <div className="pb-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-type">
                                Status
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="user-type" onChange={event => setFilters({
                                        ...filters,
                                        active: event.target.value
                                    })} value={filters.active}>
                                    <option value="">Select a Status</option>
                                    <option value="ENABLED">ENABLED</option>
                                    <option value="DISABLED">DISABLED</option>
                                </select>
                            </div>
                        </div>
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <div className="" />
                            <div className="my-6">
                                <button
                                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                                    type="button"
                                    onClick={() => filter(0)}
                                >
                                    Filter
                                </button>
                                <button
                                    className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={(e) => ResetForm(e)}>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border bg-white rounded border-gray-200 shadow m-2">
                    {Loading ? (<Loader />) : (
                        <div>
                            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                                <thead>
                                    <tr className="text-left border-b border-gray-300">
                                        <th className="sticky top-0 px-3 py-2 bg-gray-800">Warehouse Id</th>
                                        <th className="sticky top-0 px-3 py-2 bg-gray-800">Warehouse Name</th>
                                        <th className="sticky top-0 px-3 py-2 bg-gray-800">Address Line 1</th>
                                        <th className="sticky top-0 px-3 py-2 bg-gray-800">Address Line 2</th>
                                        <th className="sticky top-0 px-3 py-2 bg-gray-800">City</th>
                                        <th className="sticky top-0 px-3 py-2 bg-gray-800">Gst State</th>
                                        <th className="sticky top-0 px-3 py-2 bg-gray-800">State</th>
                                        {/* <th className="sticky top-0 px-3 py-2 bg-gray-800">Type</th> */}
                                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Status</th>
                                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(0 !== itemList.length) ?
                                        itemList.map((item: any, index: number) => {
                                            return (
                                                <tr key={index}
                                                    className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                    <td className="py-3 px-3 text-left relative">
                                                        {item.warehouseId}
                                                    </td>
                                                    <td className="py-3 px-3 text-left relative">
                                                        {item.warehouseName}
                                                    </td>
                                                    <td className="py-3 px-3 text-left relative">
                                                        {item.addressLine1}
                                                    </td>
                                                    <td className="py-3 px-3 text-left relative">
                                                        {item.addressLine2}
                                                    </td>
                                                    <td className="py-3 px-3 text-left relative">
                                                        {item.city}
                                                    </td>
                                                    <td className="py-3 px-3 text-left relative">
                                                        {item.gstState}
                                                        {/*<span*/}
                                                        {/*    className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${item.isStockItem ? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>*/}
                                                        {/*   {item.isStockItem ? 'Yes':'No'}*/}
                                                        {/*</span>*/}
                                                    </td>
                                                    <td className="py-3 px-3 text-left relative">
                                                        {item.state}
                                                        {/*<span*/}
                                                        {/*    className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${item.isStockItem ? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>*/}
                                                        {/*   {item.isStockItem ? 'Yes':'No'}*/}
                                                        {/*</span>*/}
                                                    </td>
                                                    {/* <td className="py-3 px-3 text-left relative">
                                                        {item.type} */}
                                                        {/*<span*/}
                                                        {/*    className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${item.isStockItem ? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>*/}
                                                        {/*   {item.isStockItem ? 'Yes':'No'}*/}
                                                        {/*</span>*/}
                                                    {/* </td> */}
                                                    <td className="py-3 px-3 text-left relative">
                                                        <span
                                                            className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${"ENABLED" === item.active ? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>
                                                            {item.active}
                                                        </span>
                                                    </td>
                                                    <td className="py-3 px-3 text-left relative">
                                                        <button
                                                            className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                            type="button"
                                                            onClick={() => setModal(item)}
                                                        >
                                                            View
                                                        </button>
                                                    </td>
                                                    {/*<td className="py-3 px-3 text-left relative">*/}
                                                    {/*    <button*/}
                                                    {/*        className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"*/}
                                                    {/*        type="button"*/}
                                                    {/*        onClick={() => {*/}
                                                    {/*            editModalElements(item)*/}
                                                    {/*        }}*/}
                                                    {/*    >*/}
                                                    {/*        View*/}
                                                    {/*    </button>*/}
                                                    {/*</td>*/}
                                                </tr>
                                            )
                                        })
                                        : (<tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                            <td colSpan={9} className="text-center py-3 px-3 text-left relative">No data to
                                                display
                                            </td>
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        </div>
                    )}
                    <div className="col-span-12 sm:col-span-12">
                        <Pagination
                            meta={meta}
                            handlePageChange={(number: number) => {
                                paginationFilter(number)
                            }}
                        />
                    </div>
                </div>
            </div>
            {
                OpenModal && (
                    <WareHouseAddressDetails
                        onCloseModal={closeModal}
                        data={modalData}
                    />
                )
            }
        </Layout>
    )
}

export default WarehouseMaster;
