import axios from "axios";
import Http from "../../../library/http.library";
import {httpMethods} from "../../../library/enum";

export const PriceFeedGetAll = async (data?: any) => {

  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/price/pricefeed/all`;
    try {
      let result = await axios.get(apiUrl, data);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

// export const PriceFeedGetAllEb = async (tokens:any,data: any) => {
//   return await Http.setMethod(httpMethods.get).setUrl(`/price/getprice?metalCode=${data}&partnerId=${"1"}&type=${"QTY"}&uom=${"g"}&value=${1}&currency=${"INR"}`).setToken(tokens.idToken.jwtToken).request();
export const PriceFeedGetAllEb = async (data?: string) => {
  return await Http.setMethod(httpMethods.get).setUrl(`/price/getprice?metalCode=${data}&partnerId=${"1"}&type=${"QTY"}&uom=${"g"}&value=${1}&currency=${"INR"}`).request();

  // return new Promise(async (resolve, reject) => {
  //   let apiUrl =
  //     process.env.REACT_APP_URL +
  //     `/price/getprice?metalCode=${data}&partnerId=${"1"}&type=${"QTY"}&uom=${"g"}&value=${1}&currency=${"INR"}`;
  //   try {
  //     let result = await axios.get(apiUrl, {});
  //     resolve(result);
  //   } catch (error) {
  //     reject(error);
  //   }
  // });
};
