import React, {useEffect, useState} from "react";
import Loader from "../../../Components-ui/Loader"
import {toast} from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import {useAuthStore} from "../../../Store/AuthStore";
import {DATA_TYPE} from "../../TaxDetails/Utils/enum";
import Datetime from "react-datetime";
import moment from "moment";
import CustomsDutyService from "../Services/customs-duty.service"

interface PropsType {
    onCloseModal: any,
    customsDuty: any,
    onChangeCustomDuty: any,
    validatorRef: any,
    getAllCustomDuties: any,
    page: number,
    metals: any,
    hsn: any
}

const CustomDutyForm: React.FC<PropsType> = ({
      onCloseModal,
      customsDuty,
      onChangeCustomDuty,
      validatorRef,
      getAllCustomDuties,
      page,
      metals,
      hsn
  }) => {

    const { tokens } = useAuthStore();

    const [ValidatorStatus, setValidatorStatus] = useState<boolean>(false);


    const [Loading, setLoading] = useState<any>(false);

    const simpleValidator = new SimpleReactValidator({
        locale: "en",
        validators: {},
    });

    const validationRef = React.useRef(simpleValidator);
    const validator = validationRef.current;

    /**
     * Get validation error messages
     * @param nameOfStateProp
     */
    const getValidatorError = (nameOfStateProp: any) => {
        const allErrorMessages = validator.getErrorMessages();
        return allErrorMessages[nameOfStateProp];
    };

    useEffect(() => {
        if (customsDuty.hasOwnProperty('id')) {
            setValidatorStatus(true)
        }
    }, [customsDuty]);


    /**
     * Add or update customs duty
     * @param e
     */
    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (!validatorRef || !validator.allValid()) {
            setValidatorStatus(true)
            return toast.error("Please make sure all the inputs are valid.");
        }

        try {
            setLoading(true)
            if (customsDuty.hasOwnProperty('id')) {
                await CustomsDutyService.updateCustomsDuty(customsDuty, tokens);
                await getAllCustomDuties(page);
                setLoading(false);
                onCloseModal()
                return toast.success("Customs duty was successfully updated");
            } else {
                await CustomsDutyService.addCustomsDuty(customsDuty, tokens);
                await getAllCustomDuties(page);
                setLoading(false);
                onCloseModal()
                return toast.success("Customs duty was successfully added");
            }

        } catch (error: any) {
            setLoading(false);
            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                console.log(error.response.data.errors[0]);
                toast.error(error.response.data.errors[0].title);
            }
        }
    }

    return (
        <>
            {
                Loading ? <Loader/> : (
                    <div
                        className=" w-full bg-gray-800 bg-opacity-50 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-4xl">
                            <div
                                className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
                                <div
                                    className="items-start justify-between px-6 py-4 border-b border-solid border-blue bg-gray-100 rounded-t">
                                    <h3 className="text-xl font-semibold">{customsDuty.hasOwnProperty('id') ? 'Customs Duty Details' : 'Add New Customs Duty'}</h3>
                                </div>

                                <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="codeDuty"
                                               className="block text-sm font-medium text-gray-700">
                                            Code
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                autoComplete="off"
                                                id="codeDuty"
                                                name="codeDuty"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                value={customsDuty?.codeDuty ?? ""}
                                                onChange={(e: any) => {
                                                    onChangeCustomDuty(e, DATA_TYPE.NORMAL)
                                                }}
                                            />
                                            {validator.message(
                                                "codeDuty",
                                                customsDuty?.codeDuty,
                                                "required"
                                            )}
                                            {
                                                ValidatorStatus ?
                                                    <span className="text-xs text-red-700"
                                                          id="name">{getValidatorError("codeDuty")}</span> : ""
                                            }
                                        </div>
                                    </div>
                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="metalCode"
                                               className="block text-sm font-medium text-gray-700">
                                           Metal Code
                                        </label>
                                        <div className="mt-1">
                                            <select
                                                id="metalCode"
                                                name="metalCode"
                                                value={customsDuty?.metalCode ?? ""}
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                onChange={(e: any) => {
                                                    onChangeCustomDuty(e, DATA_TYPE.NORMAL)
                                                }}
                                            >
                                                <option value="" disabled>Select a metal code</option>
                                                {
                                                    metals.map((metal: any, index: number) => {
                                                        return (
                                                            <option key={index} value={metal.metalCode}>{metal.metalCode}</option>
                                                        )

                                                    })
                                                }
                                            </select>
                                            {validator.message(
                                                "metalCode",
                                                customsDuty?.metalCode,
                                                "required"
                                            )}
                                            {
                                                ValidatorStatus ?
                                                    <span className="text-xs text-red-700"
                                                          id="categoryCode">{getValidatorError("metalCode")}</span> : ""
                                            }

                                        </div>
                                    </div>
                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="hsn"
                                               className="block text-sm font-medium text-gray-700">
                                            HSN
                                        </label>
                                        <div className="mt-1">
                                            <select
                                                id="hsn"
                                                name="hsn"
                                                value={customsDuty?.hsn ?? ""}
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                onChange={(e: any) => {
                                                    onChangeCustomDuty(e, DATA_TYPE.NORMAL)
                                                }}
                                            >
                                                <option value="" disabled>Select a hsn</option>
                                                {
                                                    hsn.map((h: any, index: number) => {
                                                        return (
                                                            <option key={index} value={h.id}>{h.hsnCode}</option>
                                                        )

                                                    })
                                                }
                                            </select>
                                            {validator.message(
                                                "hsn",
                                                customsDuty?.hsn,
                                                "required"
                                            )}
                                            {
                                                ValidatorStatus ?
                                                    <span className="text-xs text-red-700"
                                                          id="categoryCode">{getValidatorError("hsn")}</span> : ""
                                            }

                                        </div>
                                    </div>
                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="customsRate"
                                               className="block text-sm font-medium text-gray-700">
                                            Customs Rate
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                autoComplete="off"
                                                id="customsRate"
                                                name="customsRate"
                                                type="number"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                value={customsDuty?.customsRate ?? ""}
                                                onChange={(e: any) => {
                                                    onChangeCustomDuty(e, DATA_TYPE.NORMAL)
                                                }}
                                            />
                                            {validator.message(
                                                "customsRate",
                                                customsDuty?.customsRate,
                                                "required"
                                            )}
                                            {
                                                ValidatorStatus ?
                                                    <span className="text-xs text-red-700"
                                                          id="type">{getValidatorError("customsRate")}</span> : ""
                                            }

                                        </div>
                                    </div>

                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">
                                            Start Date
                                        </label>
                                        <Datetime
                                            timeFormat={false}
                                            value={moment(customsDuty?.startDate)}
                                            dateFormat={"YYYY-MM-DD"}
                                            className="mt-1 block w-100 w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            onChange={(e: any) => {
                                                onChangeCustomDuty(e, DATA_TYPE.START_DATE)
                                            }}

                                        />
                                    </div>

                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">
                                            End Date
                                        </label>
                                        <Datetime                                   
                                            timeFormat={false}
                                            value={moment(customsDuty?.endDate)}
                                            dateFormat={"YYYY-MM-DD"}
                                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            onChange={(e: any) => {
                                                onChangeCustomDuty(e, DATA_TYPE.END_DATE)
                                            }}

                                        />
                                    </div>

                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="dutyFct"
                                               className="block text-sm font-medium text-gray-700">
                                            FCT
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                autoComplete="off"
                                                id="dutyFct"
                                                name="dutyFct"
                                                type="number"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                value={customsDuty?.dutyFct ?? ""}
                                                onChange={(e: any) => {
                                                    onChangeCustomDuty(e, DATA_TYPE.NORMAL)
                                                }}
                                            />
                                            {validator.message(
                                                "dutyFct",
                                                customsDuty?.dutyFct,
                                                "required"
                                            )}
                                            {
                                                ValidatorStatus ?
                                                    <span className="text-xs text-red-700"
                                                          id="type">{getValidatorError("dutyFct")}</span> : ""
                                            }

                                        </div>
                                    </div>

                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="dutyOnNotifiedRates"
                                               className="block text-sm font-medium text-gray-700">
                                            Notified Rates
                                        </label>
                                        <div className="mt-1">
                                            <div
                                                className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                <input
                                                    type="checkbox"
                                                    name="dutyOnNotifiedRates"
                                                    id="custom-duty-toggle"
                                                    checked={customsDuty.dutyOnNotifiedRates}
                                                    className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                                                    onChange={(e) => onChangeCustomDuty(e, DATA_TYPE.NORMAL)}
                                                />
                                                <label htmlFor="custom-duty-toggle"
                                                       className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="flex items-center justify-end sm:py-3  sm:px-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <div>
                                        <button
                                            id="modal"
                                            className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={onCloseModal}
                                        >
                                            Close
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                                            type="button"
                                            onClick={(e) => handleSubmit(e)}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default CustomDutyForm;
