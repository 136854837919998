import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {ToastContainer, toast} from "react-toastify";
import Layout from "../Layout/Layout";
import "../Metal/react-confirm-alert.css";
import Loader from "../../Components-ui/Loader";
import moment from "moment";
import {Pagination} from "../../Components-ui/Pagination";
import DocumentTypeService from "../../Components/Util/DocumentType.Service";
import {useAuthStore} from "../../Store/AuthStore";
import Datetime from "react-datetime";
import HasAnyAccess from "../../Components-ui/hasAnyAccess";

const ConfigDoc: React.FC = () => {
    let history = useHistory();
    const { logout, permissions, userData , tokens } = useAuthStore();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [selectedRecord, setSelectedRecord] = useState<any>({});
    const [updateStatus, setUpdateStatus] = useState<any>('');
    const [meta, setMetaData] = useState<any>({});
    const [Loading, setLoading] = useState<any>(true);
    const [dataList, setDataList] = useState<any>([]);
    const [checked, setChecked] = useState<any>(false);
    const [filters, setFilters] = useState<any>({
        customerType: '',
        title: '',
        description: '',
        optional: '',
        validDuration: '',
        defaultVerified: '',
        fromDate: null,
        toDate: null,
    });

    useEffect(() => {
        getDataList(0);
    }, []);

    useEffect(() => {
        if ('STATUS' === updateStatus) {
            updateUser();
        }
    }, [updateStatus, selectedRecord]);

    const getDataList = async (page: number) => {
        setLoading(true);
        await DocumentTypeService.getDocumentList(tokens, page).then((response: any) => {
            setLoading(false);
            setDataList(response.data.data.result);
            setMetaData({
                current: response.data.meta.page,
                pages: response.data.meta.totalPages,
            });
            setCurrentPage(page);
        }).catch(err => {
            setLoading(false);
            toast.error(err.response.data.errors[0].title);
        });
    };

    const changeStatus = async (status: any, key: any, id: any) => {
        setUpdateStatus('STATUS');
        let newArr = [...dataList];
        newArr[key].active = ('ENABLED' !== status) ? 'ENABLED' : 'DISABLED';
        setSelectedRecord(newArr[key]);
        setDataList(newArr);
    }

    const paginationFilter = async (page: number) => {
        setLoading(true);
        await filter(page);
    }

    const filter = async (page: number) => {
        setLoading(true);
        await DocumentTypeService.filterDocs(filters, page, tokens).then((response: any) => {
            setDataList(response.data.data.result);
            setMetaData({
                current: response.data.meta.page,
                pages: response.data.meta.totalPages,
            });
            setCurrentPage(response.data.meta.page)
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            toast.error(err.response.data.errors[0].title);
        });
    }

    const updateUser = async () => {
        await DocumentTypeService.updateDoc(selectedRecord, tokens).then((response: any) => {
            getDataList(currentPage);
            setLoading(false);
            toast.success("Updated Successfully");
        }).catch(err => {
            setLoading(false);
            getDataList(currentPage);
            toast.error(err.response.data.errors[0].title);
        });
        setUpdateStatus('');
    }

    const ResetForm = async () => {
        setCurrentPage(0)
        setFilters({
            customerType: '',
            title: '',
            description: '',
            optional: '',
            validDuration: '',
            defaultVerified: '',
            fromDate: null,
            toDate: null,
        });
        await getDataList(0);
    }

    return (
        <Layout type={"MasterModule"}>
            <ToastContainer/>
            <div className="flex justify-between items-center mb-5">
                <h1 className="text-2xl font-bold tracking-tight">
                    Document types
                </h1>
                {
                    HasAnyAccess(['document.type.create']) && (
                <button
                    className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
                    type="button"
                    onClick={() => history.push('/document-config/create')}
                >
                    + Create New
                </button>)}
            </div>
            <div className="border bg-white rounded border-gray-200 shadow">
                <div className="flex-1 p-2">
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-type">
                                Customer Type
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="user-type" onChange={event => setFilters({
                                    ...filters,
                                    customerType: event.target.value
                                })} value={filters.customerType}>
                                    <option value="">Select a Customer Type</option>
                                    <option value="CO">CO</option>
                                    <option value="FIRM">FIRM</option>
                                    <option value="HUF">HUF</option>
                                    <option value="LLP">LLP</option>
                                    <option value="SOLE">SOLE</option>
                                    <option value="TRUST">TRUST</option>
                                </select>
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-id">
                                Title
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                onChange={event => setFilters({
                                    ...filters,
                                    title: event.target.value
                                })}
                                name="user-id" id="user-id" type="text" placeholder="Title" value={filters.title}
                                autoComplete="none"/>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-id">
                                Description
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                onChange={event => setFilters({
                                    ...filters,
                                    description: event.target.value
                                })}
                                name="user-id" id="user-id" type="text" placeholder="Description"
                                value={filters.description}
                                autoComplete="none"/>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-type">
                                Required Status
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="user-type" onChange={event => setFilters({
                                    ...filters,
                                    optional: event.target.value
                                })} value={filters.optional}>
                                    <option value="">Select a status</option>
                                    <option value="true">No</option>
                                    <option value="false">Yes</option>
                                </select>
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-type">
                                Default Verified
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="user-type" onChange={event => setFilters({
                                    ...filters,
                                    defaultVerified: event.target.value
                                })} value={filters.defaultVerified}>
                                    <option value="">Select a status</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-id">
                                Valid Duration
                            </label>
                            <div className="flex justify-between">
                                <div className="col-span-12 sm:col-span-2">
                                    <input type="checkbox" onChange={(event) => {
                                        setChecked(!checked);
                                        setFilters({
                                            ...filters,
                                            validDuration: !checked ? -1 : ''
                                        });
                                        // if(!checked){
                                        //     setSelectedRecord({
                                        //         ...selectedRecord,
                                        //         validDuration: 1
                                        //     });
                                        // }
                                    }} value={-1} checked={checked} id="not-expired" name="valid-duration" />
                                    <label htmlFor="not-expired" className="font-medium text-gray-700 text-sm"> Not Expired</label>
                                </div>
                                <div className="col-span-12 sm:col-span-2">
                                    <input disabled={checked}
                                           className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${checked ? "bg-gray-200" : ""}`}
                                           placeholder="Valid Duration"
                                           required
                                           id="number"
                                           min={1}
                                           type="number"
                                           name="valid-duration"
                                           onKeyPress={(e: any) => {
                                               if (e.code === 'Minus') {
                                                   e.preventDefault();
                                               }
                                           }}
                                           onChange={(event) => {
                                               setFilters({
                                                   ...filters,
                                                   validDuration: event.target.value
                                               });
                                           }} value={(checked) ? '' : filters.validDuration}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="status">
                                Valid From
                            </label>
                            <div className="relative">
                                <Datetime 
                                    timeFormat={""}
                                    dateFormat={"DD/MM/YYYY"}
                                    inputProps={{readOnly: true,placeholder: 'pick date', className: "focus:outline-none"}}
                                    className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
             `}
                                    onChange={(event: any) => setFilters({
                                        ...filters,
                                        fromDate: moment(new Date(event).toISOString()).format("MM/DD/YYYY"),
                                    })} value={moment(filters.fromDate)}
                                    renderInput={(props) => {
                                        return <input {...props} style={{width:'100%'}} value={(filters.fromDate) ? (filters.fromDate) : ''}/>
                                    }}
                                />
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="status">
                                Valid To
                            </label>
                            <div className="relative">
                                <Datetime
                                    timeFormat={""}
                                    dateFormat={"DD/MM/YYYY"}
                                    inputProps={{readOnly: true,placeholder: 'pick date', className: "focus:outline-none"}}
                                    className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
             `}
                                    onChange={(event: any) => setFilters({
                                        ...filters,
                                        toDate: moment(new Date(event).toISOString()).format("MM/DD/YYYY"),
                                    })} value={moment(filters.toDate)}

                                    renderInput={(props) => {
                                        return <input {...props} style={{width:'100%'}} value={(filters.toDate) ? (filters.toDate) : ''}/>
                                    }}
                                />
                            </div>
                        </div>
                        <div className="w-full px-3 mb-6 md:mb-0">
                            <div className="flex justify-end">
                                <button
                                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                                    type="button" onClick={() => filter(0)}>
                                    Filter
                                </button>
                                <button
                                    className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                                    type="button" onClick={() => ResetForm()}>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border bg-white rounded border-gray-200 shadow m-2">
                    {Loading ? (<Loader/>) : (
                        <div>
                            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                                <thead>
                                <tr className="text-left border-b border-gray-300">
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Title</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Description</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Type</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Required Status</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Customer Type</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Index</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Default Verified</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">From Date</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">To Date</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Valid Duration</th>
                                    {
                                        HasAnyAccess(['document.type.edit']) && (
                                            <>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Status</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Action</th>
                                            </>
                                        )}
                                </tr>
                                </thead>
                                <tbody>
                                {(0 !== dataList.length) ?
                                    dataList.map((item: any, index: number) => {
                                        return (
                                            <tr key={index}
                                                className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                <td className="py-3 px-3 text-left relative">
                                                    {item.title}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {item.description}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {item.fileType}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    <span
                                                        className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${item.optional ? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>
                                                        {item.optional ? 'No' : 'Yes'}
                                                    </span>
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {item.customerType}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {item.index}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    <span
                                                        className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${item.defaultVerified ? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>
                                                        {item.defaultVerified ? 'Yes' : 'No'}
                                                    </span>
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {moment(item.fromDate).format("DD-MM-YYYY HH:mm")}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {moment(item.toDate).format("DD-MM-YYYY HH:mm")}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {-1 !== item.validDuration ? item.validDuration :'Unlimited'}
                                                </td>
                                                    {
                                                        HasAnyAccess(['document.type.edit']) && (
                                                            <td className="py-3 px-3 text-left relative">

                                                            <div className="flex justify-between items-center" onClick={() => {
                                                        changeStatus(item.active, index, item.docTypeId)
                                                    }}>
                                                        <div
                                                            className={`w-14 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out ${("ENABLED" === item.active) ? "bg-green-400" : ""}`}>
                                                            <div
                                                                className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${("ENABLED" === item.active) ? "translate-x-6" : ""}`}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>)}
                                                    {
                                                        HasAnyAccess(['document.type.edit']) && (
                                                            <td className="py-3 px-3 text-left relative">

                                                            <button
                                                        className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                        type="button"
                                                        onClick={() => {
                                                            history.push(`/document-config/edit/${item.docTypeId}`);
                                                        }}
                                                    >
                                                        Edit
                                                    </button>
                                                </td>)}
                                            </tr>
                                        )
                                    } )
                                    : (<tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                        <td colSpan={12} className="text-center py-3 px-3 text-left relative">No data to
                                            display
                                        </td>
                                    </tr>)
                                }
                                </tbody>
                            </table>
                        </div>
                    )}
                    <Pagination
                        meta={meta}
                        handlePageChange={(number: number) => {
                            paginationFilter(number)
                        }}
                    />
                </div>
            </div>
        </Layout>
    );
};

export default ConfigDoc;
