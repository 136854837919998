import { unit } from "mathjs";

class CustomFunction {
  midPrice(a: number, b: number) {
    return (a + b) / 2.0;
  }

  average(...values: any) {
    let sum = 0.0;
    let count = 0;
    let issue = false;
    for (let index = 0; index < values.length; index++) {
      const element = values[index];
      if (typeof element == "number") {
        sum += element;
        count++;
      } else {
        issue = true;
      }
    }
    return issue ? 0 : sum / count;
  }

  async convertUOM(type1: string, type2: string) {

    let apiUrl =
      process.env.REACT_APP_URL +
      `/uom-convert?from=${type1}&to=${type2}&value=${1}`;
    try {
      const conversionRaw = await fetch(apiUrl);

      const conversion = await conversionRaw.json();
      
      return conversion.data.value;
    } catch (error) {

      return 0;
    }

    
    
    // var array = ["troy", "g", "ounce", "kg"];
    // if (
    //   !(
    //     array.includes(type1.toLowerCase()) &&
    //     array.includes(type2.toLowerCase())
    //   )
    // ) {
    //   return 0;
    // }
    // let oneTroyToGram = 31.1;
    // if (type1 == "troy" && type2 == "troy") {
    //   return 1;
    // } else if (type1 == "troy") {
    //   return unit(1 * oneTroyToGram, "g").toNumber(type2);
    // } else if (type2 == "troy") {
    //   return unit(1 / oneTroyToGram, "g").toNumber(type1);
    // } else {
    //   return unit(1, type1).toNumber(type2);
    // }
  }
}
export default new CustomFunction();
