import { number } from "mathjs";

export const formatAmount = (number: any, metalCode: string) => {
  if (metalCode == "Ag") {
    return number.toFixed(4);
  } else {
    return number.toFixed(2);
  }
};
export const formatQuantity = (number: any) => {
  return number.toFixed(4);
};

export const formatMoney = (amount: number, currancyString: string) => {
  if (amount < 0)
    return `(-${currancyString} ${amount.toString().replace(/-/, "")})`;
  else return `${currancyString} ${amount.toString().replace(/-/, "")}`;
};
