import Layout from "../../Layout/Layout";
import React, {useEffect, useState} from "react";
import moment from "moment";
import TaxService from "../Services/tax.service"
import Loader from"../../../Components-ui/Loader"
import {toast, ToastContainer} from "react-toastify";
import TaxForm from "./TaxForm";
import {useAuthStore} from "../../../Store/AuthStore";
import {Pagination} from "../../../Components-ui/Pagination";
import {makeQuery} from "../../../Common/util.helper";
import CountryService from "../../Country/Services/country.service";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";

interface PropsType {
}

const TaxTable: React.FC<PropsType> = () => {

    const { logout, permissions, userData , tokens } = useAuthStore();

    const [Loading, setLoading] = useState<any>(false);
    const [MetaData , setMetaData] = useState<any>({});
    const [CurrentPage , setCurrentPage] = useState<number>(0);
    const [Taxes, setTaxes] = useState<any>([]);
    const [Countries, setCountries] = useState<any>([]);
    const [OpenModal, setOpenModal] = useState(false);
    const [Validator, setValidator] = useState(false);
    const [FilterData, setFilterData] = useState<any>({
        taxCode: "",
        taxType: "",
        countryId: "",
        active: "",
    });
    const [Tax, setTax] = useState<any>({
        description: "",
        taxType: "",
        taxCode: "",
        countryId: "",
        active: "ENABLED",
        createdBy: "string"
    })


    useEffect((): any => {
        getAllTaxes(CurrentPage)
        getAllCountries()
        // eslint-disable-next-line
    }, []);


    /**
     * Get all countries
     */
    const getAllCountries = async () => {
        let data: any = await CountryService.getAllCountries(0, tokens, '', false);
        if (data.status === 200 && data.data.data) {
            setCountries(data.data.data);
        }
    }


    /**
     * Validate tax details
     * @param e
     */
    const onChangeTax = (e: any) => {
        setTax({
            ...Tax,
            [e.target.name]: e.target.value,
        });

        return setValidator(true);
    };

    /**
     * Handle filters change event
     * @param e
     */
    const handleChangeFilter = (e: any) => {
        const {name, value} = e.target
        setFilterData({
            ...FilterData,
            [name]: value
        });
    }


    /**
     * Get all tac categories
     * @param page
     * @param isReset
     */
    const getAllTaxes = async (page: number, isReset: boolean = false) => {
        setLoading(true);
        let cloneFilterData = !isReset ? {...FilterData} : {
            taxCode: "",
            taxType: "",
            countryId: "",
            active: "",
        }
        const query = makeQuery(cloneFilterData);

        let data: any = await TaxService.getAllTaxes(page, tokens, query);
        if (data.status === 200 && data.data.data) {
            setCurrentPage(page)
            setMetaData({
                current: data.data.meta.page,
                pages: data.data.meta.totalPages,
            })
            setTaxes(data ? data.data.data.result : []);
        }

        setLoading(false)

    };

    /**
     * Close modal
     */
    const closeModal = () => {
        setOpenModal(false)
    }

    /**
     * Open tax modal
     */
    const onAddTax = () => {
        setInitialState()
        setOpenModal(true)
    }


    /**
     * Get tax details
     * @param id
     */
    const getTax = (id: any) => {
        let taxCategory: any = Taxes.filter((category:any) => category.id === id);
        if (taxCategory.length > 0) {
            setTax(taxCategory[0])
            setOpenModal(true)
        }
    }

    /**
     * Enable or disable tax
     * @param e
     * @param data
     */
    const enableDisableTax = async (e: any, data:any) => {
        setLoading(true)
        let cloneData = {...data}

        cloneData.active = e.target.checked ? 'ENABLED' : 'DISABLED';
        await TaxService.updateTax(cloneData, tokens);
        await getAllTaxes(CurrentPage);
        setLoading(false)
        return toast.success("Tax was successfully updated");
    }


    /**
     * Set category details to initial state
     */
    const setInitialState = () => {
        setTax({
            description: "",
            taxType: "",
            taxCode: "",
            countryId: "",
            active: "ENABLED",
            createdBy: "string"
        })
    }

    /**
     * Reset filters to initial state
     * @param e
     */
    const resetFilterData = async (e: any) => {
        e.preventDefault()
        setFilterData({
            taxCode: "",
            taxType: "",
            countryId: "",
            active: "",
        })

        setCurrentPage(0)
        await getAllTaxes(0, true)
    }

    return (
        <Layout type={"TaxModule"}>
            <ToastContainer />
            <div className="flex justify-between items-center mb-5">
                <h1 className="text-2xl font-bold tracking-tight">
                    {"Taxes"}
                </h1>
                {
                    HasAnyAccess(['tax.master.create']) && (
                        <button
                            className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
                            type="button"
                            onClick={onAddTax}
                        >
                            + Add Tax
                        </button>
                    )
                }
            </div>

            <div
                className="w-full rounded border-gray-200 overflow-hidden shadow-lg dark:border-gray-700 bg-white">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Filter Taxes</h3>
                </div>
                <hr/>
                <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
                    <div className="col-span-12 sm:col-span-3">
                        <label htmlFor="taxCode" className="block text-sm font-medium text-gray-700">
                            Code
                        </label>
                        <input
                            type="number"
                            name="taxCode"
                            id="taxCode"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e: any) => {
                                handleChangeFilter(e)
                            }}
                            value={FilterData.taxCode}
                        />
                    </div>
                    <div className="col-span-12 sm:col-span-3">
                        <label htmlFor="taxType" className="block text-sm font-medium text-gray-700">
                            Type
                        </label>
                        <input
                            type="text"
                            name="taxType"
                            id="taxType"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e: any) => {
                                handleChangeFilter(e)
                            }}
                            value={FilterData.taxType}
                        />
                    </div>
                    <div className="col-span-12 sm:col-span-3">
                        <label htmlFor="countryId" className="block text-sm font-medium text-gray-700">
                            Country
                        </label>
                        <select
                            id="countryId"
                            name="countryId"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e: any) => {
                                handleChangeFilter(e)
                            }}
                            value={FilterData.countryId}
                        >
                            <option value="">All</option>
                            {
                                Countries.map((country: any, index: number) => {
                                    return (
                                        <option key={index} value={country.id}>{country.name}</option>
                                    )

                                })
                            }
                        </select>
                    </div>
                    <div className="col-span-12 sm:col-span-3">
                        <label htmlFor="active" className="block text-sm font-medium text-gray-700">
                            Status
                        </label>
                        <select
                            id="active"
                            name="active"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e: any) => {
                                handleChangeFilter(e)
                            }}
                            value={FilterData.active}
                        >
                            <option value="">All</option>
                            <option value="ENABLED">ENABLED</option>
                            <option value="DISABLED">DISABLED</option>

                        </select>
                    </div>
                    <div className="col-span-12 sm:col-span-12 flex justify-end">
                        <button
                            className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                            type="button"
                            onClick={() => getAllTaxes(0)}
                        >
                            Filter
                        </button>
                        <button
                            className="text-white bg-red-600 border border-red-300 rounded-md background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                            type="button"
                            onClick={(e) => resetFilterData(e)}
                        >
                            Reset
                        </button>

                    </div>
                    <div className="col-span-12 sm:col-span-12"> <hr/></div>
                    <div className="col-span-12 sm:col-span-12">
                        {
                            Loading ? (<Loader/>) :
                               (
                                    <div className="border bg-white rounded border-gray-200 shadow">

                                        <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                                            <thead>
                                            <tr className="text-left border-b border-gray-300">
                                                <th className="sticky top-0 px-3 py-2 bg-gray-800">Description</th>
                                                <th className="sticky top-0 px-3 py-2 bg-gray-800">Code</th>
                                                <th className="sticky top-0 px-3 py-2 bg-gray-800">Type</th>
                                                <th className="sticky top-0 px-3 py-2 bg-gray-800">Date</th>
                                                {
                                                    HasAnyAccess(['tax.master.edit']) && (
                                               <>
                                                   <th className="sticky top-0 px-3 py-2 bg-gray-800">Status</th>
                                                   <th className="sticky top-0 px-3 py-2 bg-gray-800">Actions</th>
                                               </>
                                                    )}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {(0 !== Taxes.length) ?
                                                Taxes.map((tax:any, index:number) => {
                                                    return (
                                                        <tr key={index} className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                            <td className="py-3 px-3 text-left relative">
                                                                {tax.description}
                                                            </td>
                                                            <td className="py-3 px-3 text-left relative">
                                                                {tax.taxCode}
                                                            </td>
                                                            <td className="py-3 px-3 text-left relative">
                                                                {tax.taxType}
                                                            </td>
                                                            <td className="py-3 px-3 text-left relative">
                                                                {moment(tax.createdAt).format("DD-MM-YYYY HH:mm")}
                                                            </td>
                                                                {
                                                                    HasAnyAccess(['tax.master.edit']) && (
                                                                        <td className="py-3 px-3 text-left relative">

                                                                        <div
                                                                    className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="toggle"
                                                                        id={`${tax.id}toggle`}
                                                                        checked={tax.active === 'ENABLED'}
                                                                        className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                                                                        onChange={(e) => enableDisableTax(e, tax)}
                                                                    />
                                                                    <label htmlFor={`${tax.id}toggle`}
                                                                           className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"/>
                                                                </div>
                                                            </td>)}
                                                                {
                                                                    HasAnyAccess(['tax.master.edit']) && (
                                                                        <td className="py-3 px-3 text-left relative">

                                                                        <button
                                                                            className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                                            type="button"
                                                                            onClick={() => getTax(tax.id)}
                                                                        >
                                                                            Edit
                                                                        </button>

                                                            </td> )
                                                                }
                                                        </tr>
                                                    )
                                                }): (<tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                                    <td colSpan={9} className="text-center py-3 px-3 text-left relative">No data to
                                                        display
                                                    </td>
                                                </tr>)
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                )
                        }
                    </div>
                </div>
                <div className="col-span-12 sm:col-span-12 px-4 pt-2">
                    {/*Pagination Bar*/}
                    {
                        !Loading && (
                            <Pagination
                                meta={MetaData}
                                handlePageChange={(number:number) => getAllTaxes(number)}
                            />
                        )
                    }

                </div>
            </div>
            {
                OpenModal && (
                    <TaxForm
                        onCloseModal={closeModal}
                        tax={Tax}
                        onChangeTax={onChangeTax}
                        getAllTaxes={getAllTaxes}
                        validatorRef={Validator}
                        countries={Countries}
                        page={CurrentPage}
                    />
                )
            }
        </Layout>
    );
};

export default TaxTable;
