import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import SettlementReport from "../Reports/Settlement/SettlementReport";
import SettlementReportApiLogs from "../Reports/Settlement/SettlementReportApiLogs";
import BarReconciliation from "./BarReconciliationReport/BarReconciliationReport";
import BarSegregationList from "./BarReconciliationReport/BarSegregationList";
import CustomerTotalStockList from "./BarReconciliationReport/CustomerTotalStockList";
import PartnerSettlementReport from "./PartnerSettlement/PartnerSettlementReport";

const ReportRoutes:React.FC = () => {
    return (
        <Fragment>
            <Route path="/bar-reconciliation-report" exact component={BarReconciliation} />
            <Route path="/settlement-report" exact component={SettlementReport} />
            <Route path="/settlement-report-api-logs" exact component={SettlementReportApiLogs} />
            <Route path="/partner-settlement-report" exact component={PartnerSettlementReport} />
            <Route path="/bar-segregation-list" exact component={BarSegregationList} />
            <Route path="/customer-total-stock-list" exact component={CustomerTotalStockList} />
        </Fragment>
    )
};

export default ReportRoutes;
