import React, { useState, useEffect } from 'react';
import logo from "../../../Images/space.svg";

import "../css/styless.css";
import {useHistory} from "react-router-dom";
interface PropsType {
}

const UnAuthorized:React.FC<PropsType> = () => {
    let history = useHistory();
  return (
      <div className="h-full flex items-center justify-center py-16 px-4 sm:px-6 lg:px-8 mt-11">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img className="mx-auto w-auto h-auto" src={logo} alt="Workflow"/>
          </div>
        </div>
        <div className="max-w-md w-full space-y-8">
          <div>
            {/*<img className="mx-auto h-12 w-auto" src={logo} alt="Workflow"/>*/}
            <h2 className="text-9xl text-center">401</h2>
            <h2 className="whitespace-nowrap mt-6 text-center text-xl font-bold text-gray-900">
              You don't have permission to access the resources
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              The resource that you are attempting to access is protected and you don't have the necessary permissions to view it.
              Please contact the admin.
            </p>
          </div>
            <div>
                <a onClick={() =>  {
                    history.push('/login')
                }} href="" className="flex font-medium text-gray-600 hover:text-gray-500">
                      <svg className="m-0.5" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/></svg>
                Back to login
                </a>
            </div>
        </div>

      </div>
  );
};

export default UnAuthorized;
