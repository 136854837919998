import React, { useState, useEffect } from "react";
import { useAuthStore } from "../../../Store/AuthStore";
import { useHistory } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import moment from "moment";
import CustomerService from "../../Customer/Services/Customer.service";
import InvestorService from "../Services/InvestorList.Service";
import { Pagination } from "../../../Components-ui/Pagination";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";
import Datetime from "react-datetime";
import { makeQuery } from "../../../Common/util.helper";
import orderService from "../../Order/Services/order.service";
import { STATUS } from "../../Order/Utils/enum";
import MultipleSelect from "../../../Components-ui/MultipleSelect";

const ListOfInvCustomers: React.FC = () => {
  // const [customers, setCustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState<any>(0);
  const [Loading, setLoading] = useState<any>(true);
  const { logout, permissions, userData, tokens } = useAuthStore();
  const history = useHistory();
  const [customers, setCustomers] = useState<any>([]);
  const [investorList, setInvestorList] = useState<any>([]);
  const [toggle, setToggle] = useState<any>("ENABLED");
  const [downloadQuery, setDownloadQuery] = useState<any>("");
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [metalList, setMetalList] = useState<any>([]);

  const [filters, setFilters] = useState<any>({
    orderRef: "",
    metalCode: "",
    status: "",
    customerId: "",
    fromDate: null,
    toDate: null,
    investorId: "",
  });
  const [metaData, setMetaData] = useState<any>({});

  const get_customer_list = async (filters_: any, page: any) => {
    setLoading(true);
    await InvestorService.getAllInvOrders(filters_, tokens, page)
      .then((response: any) => {
        setLoading(false);
        setCustomers(response.data.data.result);
        customers.map((customer: any, index: number) => {
          setToggle({
            ...toggle,
            key: index,
            active: customer.active,
          });
        });
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPage(page);
        // setMetaData(response.data.meta);
      })
      .catch((err: any) => {
        setLoading(false);
        setMetaData({});
        toast.error(err);
      });
  };

  const get_businessPartner_list = async () => {
    setLoading(true);
    await InvestorService.getAllInvestors(tokens, 0, false)

      .then((response: any) => {
        setInvestorList(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // toast.error(err.response.data.errors[0].title);
      });
  };

  const resetForm = async () => {
    setLoading(true);
    setDownloadQuery("");
    setIsFiltered(false);
    setFilters({
      orderRef: "",
      metalCode: "",
      status: "",
      customerId: "",
      fromDate: null,
      toDate: null,
      investorId: "",
    });
    get_customer_list(
      {
        metalCode: "",
        status: "",
        customerId: "",
        fromDate: null,
        toDate: null,
        investorId: "",
      },
      0
    );
  };

  const filter = async (page: number = 0, forcedfilter: any = null) => {
    setLoading(true);
    try {
      get_customer_list(filters, page);
      const query = makeQuery(filters);
      setDownloadQuery(query);
      setIsFiltered(true);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      //   toast.error(err);
    }
  };

  const downloadCustomerReport = async () => {
    setLoading(true);
    await InvestorService.downloadInvOrderReport(downloadQuery, tokens)
      .then((res: any) => {
        if (res.status === 200 && res.data) {
          setLoading(false);
          // saveAs(res.data.data.fileUrl, `order_log_report`);
          window.location.assign(res.data.data.fileUrl);
          // window.open(res.data.data.fileUrl);
        }
        setLoading(false);
      })
      .catch((err: any) => {
        toast.error("Something Went Wrong!");
        setLoading(false);
      });

    setLoading(false);
  };
  const sendCustomerReportMail = async () => {
    setLoading(true);
    await InvestorService.sendEmailInvOrderReport(downloadQuery, tokens)
      .then((res: any) => {
        if (res.status === 200 && res.data) {
          setLoading(false);
          // saveAs(res.data.data.fileUrl, `order_log_report`);
          // window.open(res.data.data.fileUrl);
        }
        setLoading(false);
      })
      .catch((err: any) => {
        toast.error("Something Went Wrong!");
        setLoading(false);
      });

    setLoading(false);
  };

  const loadMetals = async (): Promise<void> => {
    return new Promise(async (resolve) => {
      orderService
        .getMetals(tokens)
        .then((response: any) => {
          setMetalList(response.data.data);
          resolve();
        })
        .catch((err) => {
          toast.error("Something Went Wrong in metal list load!");
          resolve();
        });
    });
  };

  useEffect(() => {
    get_customer_list(filters, 0);
    // get_partner_list();
    get_businessPartner_list();
    loadMetals();
  }, []);


  return (
    <Layout type={"InvestorModule"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">{"Orders"}</h1>
      </div>
      {Loading ? (
        <Loader />
      ) : (
        <div className="border bg-white rounded border-gray-200 shadow">
          <div className="flex-1 p-2">
            <form
              onSubmit={(e) => {
                filter();
                e.preventDefault();
              }}
            >
              <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    htmlFor="orderRef"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Order Ref
                  </label>
                  <input
                    type="text"
                    name="orderRef"
                    id="orderRef"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={(e: any) => {
                      setFilters({
                        ...filters,
                        orderRef: e.target.value,
                      });
                    }}
                    value={filters.orderRef}
                  />
                </div>
                <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="metal"
                  >
                    Metal Code
                  </label>
                  <select
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    name="metalCode"
                    id="metal"
                    placeholder="Metal Code"
                    onChange={(e: any) => {
                      setFilters({
                        ...filters,
                        metalCode: e.target.value,
                      });
                    }}
                    value={filters.metalCode}
                  >
                    <option value="">All</option>
                    {metalList.map((metal: any) => (
                      <option value={metal.metalCode}>{metal.metalCode}</option>
                    ))}
                  </select>
                </div>
                <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="grid-user-id"
                  >
                    Customer ID
                  </label>
                  <input
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={(event) =>
                      setFilters({
                        ...filters,
                        customerId: event.target.value,
                      })
                    }
                    value={filters.customerId}
                    id="grid-user-id"
                    type="text"
                    placeholder="User ID"
                    autoComplete="none"
                  />
                </div>

                <div className="w-full mt-1 md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="grid-partner"
                  >
                    Business Partner
                  </label>
                  <div className="relative">
                    <select
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="grid-partner"
                      onChange={(event) =>
                        setFilters({
                          ...filters,
                          investorId: event.target.value,
                        })
                      }
                      value={filters.investorId}
                    >
                      <option value="">Select a Partner</option>
                      {investorList.map((partner: any) => (
                        <option value={partner.investorId}>
                          {partner.businessName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="w-full mt-1 md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    htmlFor="type"
                    className="block text-sm font-medium text-gray-700 pb-1"
                  >
                    Status
                  </label>
                  <select
                    className={`mt-1 block w-full py-2 px-3 border border-gray bg-white
                      rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                    id="grid-gst"
                    onChange={(event) =>
                      setFilters({
                        ...filters,
                        status: event.target.value,
                      })
                    }
                    value={filters.status}
                  >
                    <option value="">Select a status</option>
                    <option value="CREATED">CREATED</option>
                    <option value="PENDING”">PENDING</option>
                    <option value="PAYMENT PENDING">PAYMENT PENDING</option>
                    <option value="PARTIAL COMPLETED">PARTIAL COMPLETED</option>
                    <option value="COMPLETED">COMPLETED</option>
                    <option value="CANCELLED">CANCELLED</option>
                    <option value="INVOICE_UPLOAD_PENDING">
                      INVOICE_UPLOAD_PENDING
                    </option>
                    <option value="INVOICE_APPROVAL_PENDING”">
                      INVOICE_APPROVAL_PENDING”
                    </option>
                    <option value="BANK_TRANSFER_APPROVAL_PENDING">
                      BANK_TRANSFER_APPROVAL_PENDING
                    </option>
                  </select>
                </div>

                <div className="w-full mt-1 md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="status"
                  >
                    From Date
                  </label>
                  <div className="relative">
                    <Datetime
                      timeFormat={""}
                      dateFormat={"YYYY-MM-DD"}
                      inputProps={{
                        readOnly: true,
                        placeholder: "",
                        className: "focus:outline-none",
                      }}
                      className={`rdt mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
             `}
                      onChange={(e: any) => {
                        setFilters({
                          ...filters,
                          fromDate: moment(new Date(e).toISOString()).format(
                            "YYYY-MM-DD"
                          ),
                          toDate: e.isAfter(new Date(filters.toDate))
                            ? moment(new Date(e).toISOString()).format(
                                "YYYY-MM-DD"
                              )
                            : moment(filters.toDate).format("YYYY-MM-DD"),
                        });
                      }}
                      value={filters.fromDate}
                      renderInput={(props) => {
                        return (
                          <input
                            style={{ width: "100%" }}
                            {...props}
                            value={filters.fromDate ? filters.fromDate : ""}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="w-full mt-1 md:w-1/4 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="status"
                  >
                    To Date
                  </label>
                  <div className="relative">
                    <Datetime
                      // isValidDate={valid}
                      timeFormat={""}
                      dateFormat={"YYYY-MM-DD"}
                      inputProps={{
                        readOnly: true,
                        placeholder: "",
                        className: "focus:outline-none",
                      }}
                      className={`rdt mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
             `}
                      onChange={(e: any) => {
                        setFilters({
                          ...filters,
                          toDate: moment(new Date(e).toISOString()).format(
                            "YYYY-MM-DD"
                          ),
                        });
                      }}
                      value={filters.toDate}
                      renderInput={(props) => {
                        return (
                          <input
                            style={{ width: "100%" }}
                            {...props}
                            value={filters.toDate ? filters.toDate : ""}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="w-full md:w-3/3 px-3 mb-6 md:mb-0">
                  <div className="md:w-1/3" />
                  <div className="md:w-2/3 my-6">
                    {/* <button
                                        className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                                        type="button" onClick={() => filter()}>
                                        Filter
                                    </button> */}
                    <input
                      className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                      type="submit"
                      value="Filter"
                    />
                    <button
                      className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => resetForm()}
                    >
                      Reset
                    </button>
                    <button
                      className="px-4 py-1.5 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none ml-2  ease-linear transition-all duration-150"
                      type="button"
                      onClick={(e) => downloadCustomerReport()}
                    >
                      Download Report
                    </button>
                    {filters?.investorId && isFiltered && (
                      <button
                        className="px-4 py-1.5 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none ml-2  ease-linear transition-all duration-150"
                        type="button"
                        onClick={(e) => sendCustomerReportMail()}
                      >
                        Send Email
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
          {/*user Table*/}
          <div className="border bg-white rounded border-gray-200 shadow m-2">
            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
              <thead>
                <tr className="text-left border-b border-gray-300">
                  <th className="sticky top-0 px-3 py-2 bg-gray-800">
                    Order Ref
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800">
                    Quote Ref
                  </th>

                  <th className="sticky top-0 px-3 py-2 bg-gray-800">
                    Created At
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800">
                    Customer
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800">
                    Business Partner
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800">
                    Quotation Total
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800">
                    Currency
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800">
                    Metal Code
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800">Type</th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800">Source</th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800">Status</th>
                </tr>
              </thead>
              <tbody>
                {customers.length > 0 ? (
                  customers.map((order: any, index: number) => {
                    return (
                      <tr
                        key={index}
                        className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                      >
                        <td className="py-3 px-3 text-left relative">
                          {order.ebOrderRef}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {order.ebQuoteRef}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {moment(order.createdAtUnix).format(
                            "DD-MM-YYYY HH:mm"
                          )}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {order.customerId}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {order?.order?.investorName}
                        </td>

                        <td className="py-3 px-3 text-left relative">
                          {order.quotationTotal}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {order.currency}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {order.items.length > 0 &&
                          order.items[0].itemBreakDown.length > 0
                            ? order.items[0].itemBreakDown[0].metalCode
                            : "N/A"}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {order.type}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {order?.order?.source}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {order.status}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                    <td
                      colSpan={10}
                      className="text-center py-3 px-3 text-left relative"
                    >
                     {customers ? "Loading...": "No data to display"} 
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {/* Pagination Bar */}
            <Pagination
              meta={metaData}
              handlePageChange={(number: number) => {
                filter(number);
              }}
            />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default ListOfInvCustomers;
