import { useEffect, useState, useCallback } from "react";
import Layout from "../../Layout/Layout";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { Button, Table } from "react-bootstrap";
import "./table.css";

const GoldSilverGraph = () => {
  const [res, setRes] = useState([]);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState("1W");

  const buttonNames = ["1D", "1W", "1M"];

  const calculateFromDate = useCallback((duration: string): string => {
    const today = new Date();
    if (duration === "1D") today.setDate(today.getDate() - 1);
    else if (duration === "1W") today.setDate(today.getDate() - 7);
    else if (duration === "1M") today.setMonth(today.getMonth() - 1);
    return today.toISOString().slice(0, 10);
  }, []);

  const fetchData = useCallback(async () => {
    const fromDate = calculateFromDate(selectedTimeFrame);

    const payload = {
      metalCode: ["AG", "AU"],
      fromDate,
    };

    try {
      const response = await axios.post(
        "https://api.ebullion.in/get/gold-by-silver/ratio",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setRes(response?.data?.data || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [calculateFromDate, selectedTimeFrame]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const series = [
    {
      name: "INR / g",
      data: res,
    },
  ];

  const chartOptions:any = {
    chart: {
      id: "area-datetime",
      type: "area",
      zoom: { autoScaleYaxis: true },
    },
    title: { text: "Gold / Silver Ratio", align: "left" },
    yaxis: { title: { text: "Price (INR)" } },
    colors: ["#99834d"],
    stroke: { curve: "straight", width: 1 },
    dataLabels: { enabled: false },
    tooltip: { x: { format: "dd MMMM yyyy hh:mm TT" } },
    xaxis: {
      type: "datetime",
      tickAmount: 6,
    },
  };

  const renderButtons = () =>
    buttonNames.map((buttonName) => (
      <Button
        key={buttonName}
        variant="light"
        className={`rounded-square ${
          selectedTimeFrame === buttonName ? "selected" : ""
        }`}
        onClick={() => setSelectedTimeFrame(buttonName)}
        style={{
          backgroundColor: selectedTimeFrame === buttonName ? "#301250" : "#fff",
          fontWeight: "bold",
          color: selectedTimeFrame === buttonName ? "#fff" : "#000",
          border: "1px solid #ccc",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          margin: "5px",
          fontSize: "14px",
        }}
      >
        {buttonName}
      </Button>
    ));

  return (
    <Layout type="ChartModule">
      <div className="flex items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight" style={{ marginLeft: "14px" }}>
          Gold / Silver Ratio
        </h1>
      </div>
      <div className="chart-table-responsive">
        <Table striped bordered className="custom-table-chart">
          <tbody>
            <tr>
              <td colSpan={3} className="small-width">
                {renderButtons()}
              </td>
            </tr>
            <tr className="white-background">
              <td colSpan={3} className="bigger-width">
                <ReactApexChart
                  options={chartOptions}
                  series={series}
                  type="area"
                  height={400}
                  width={950}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Layout>
  );
};

export default GoldSilverGraph;
