import React, { Fragment } from "react";
import { Route } from "react-router-dom";

import ReportDashboard from "./Components/ReportDashboard";
import AddUpdateModal from "../../Components-ui/Modal/Modal";
import ReportDashboardHP from "./Components/ReportDashboardHP";

const DashboardReportRoutes: React.FC = () => {
  return (
    <Fragment>
      <Route path="/report-dashboard/eb" exact component={ReportDashboard} />
      <Route path="/report-dashboard/hp" exact component={ReportDashboardHP} />
    </Fragment>
  );
};

export default DashboardReportRoutes;
