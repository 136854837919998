import axios from "axios";
import Http from "../../library/http.library";
import {httpMethods} from "../../library/enum";
import {makeQuery} from "../../Common/util.helper";

//master details
export const CreateNewReferenceMaster = async (data: any, tokens: any) => {
    return await Http.setMethod(httpMethods.post).setUrl(`/ref-master`).setData(data).setToken(tokens.idToken.jwtToken).request();
};

export const UpdateReferenceMasterDetails = async (id: number, data: any, tokens: any) => {
    return Http.setMethod(httpMethods.patch).setUrl(`/ref-master/${id}`).setData(data).setToken(tokens.idToken.jwtToken).request();

};

export const GetReferenceMasterData = async (tokens: any,currentPage:any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/ref-master`).setToken(tokens.idToken.jwtToken).request();
};

export const GetSingleReferenceMasterRecord = async (id: any, tokens: any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/ref-master?id=${id}`).setToken(tokens.idToken.jwtToken).request();

};

export const filterReferenceMasterRecord = async (data: any, tokens: any, currentPage: any) => {
    var query = {
        isPaginated: true,
        ...(data.codeName ? {codeName: data.codeName} : {}),
        ...(data.codeDescription ? {codeDescription: data.codeDescription} : {}),
        ...(data.active ? {active: data.active} : {}),
        page: currentPage,
    }
    const params = makeQuery(query);
    return await Http.setMethod(httpMethods.get).setUrl(`/ref-master?${params}`).setToken(tokens.idToken.jwtToken).request();
};


//details

export const CreateNewReferenceDetails = async (data: any, tokens: any) => {
    return await Http.setMethod(httpMethods.post).setUrl(`/ref-details`).setData(data).setToken(tokens.idToken.jwtToken).request();

};

export const UpdateReferenceDetailsRecord = async (id: any, data: any, tokens: any) => {
    return Http.setMethod(httpMethods.patch).setUrl(`/ref-details/${id}`).setData(data).setToken(tokens.idToken.jwtToken).request();

};

export const getReferenceDetailsByCodeRecord = async (code: string, tokens: any) => {
    return new Promise(async (resolve, reject) => {
        let apiUrl = process.env.REACT_APP_URL + `/ref-details/code-name/${code}`;
        try {
            let result = await axios.get(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                    "Authorization": `Bearer ${tokens.idToken.jwtToken}`,
                },
                params: {
                    isPaginated: false,
                },
            });
            resolve(result);
        } catch (error) {
            reject(error);
        }
    });
};




const EbReferenceMasterService = {
    CreateNewReferenceMaster,
    UpdateReferenceMasterDetails,
    GetReferenceMasterData,
    GetSingleReferenceMasterRecord,
    CreateNewReferenceDetails,
    UpdateReferenceDetailsRecord,
    filterReferenceMasterRecord,
    getReferenceDetailsByCodeRecord
};

export default EbReferenceMasterService;
