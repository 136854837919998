import React from "react";
import moment from "moment";
import { getName,getPartnerName } from "./utils";

interface ProtoType {
  ebl: any;
  selectedIndex: any;
  delRule: any;
  viewClick: any;
  hanldleEdit: any;
}

const DisplayRuleMasterDetails: React.FC<ProtoType> = ({
  ebl,
  selectedIndex,
  delRule,
  viewClick,
  hanldleEdit,
}) => {

  return (
    <>
      <tr className="border-b border-gray-200  bg-white  ">
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto  w-full flex-wrap items-stretch">
            <label className="text-black">{ebl?.Rule_ref ?? ""} </label>
          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto  w-full flex-wrap items-stretch">
            <label className="text-black"> {ebl?.description ?? ""}</label>
          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto  w-full flex-wrap items-stretch">
            <label className="text-black">{ebl?.type ?? ""} </label>
          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto  w-full flex-wrap items-stretch">
            <label className="text-black"> {ebl?.metal ?? ""}</label>
          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto  w-full flex-wrap items-stretch">
            <label className="text-black">{getName(ebl?.metal)}</label>
          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto  w-full flex-wrap items-stretch">
            <label className="text-black">
              {moment(ebl?.Effect_From_Date).format("DD-MM-YYYY") ?? ""}{" "}
            </label>
          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto  w-full flex-wrap items-stretch">
            <label className="text-black">
              {moment(ebl?.Effect_To_Date).format("DD-MM-YYYY") ?? ""}
            </label>
          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto  w-full flex-wrap items-stretch">

            <label className="text-black">{getPartnerName(ebl?.source)}</label>

          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto  w-full flex-wrap items-stretch">
            <label className="text-black">{ebl?.Customer_Group ?? ""}</label>
          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto  w-full flex-wrap items-stretch">
            <label className="text-black">{ebl?.customer_Id ?? ""}</label>
          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto  w-full flex-wrap items-stretch">
            <label className="text-black"> {ebl?.priority ?? ""}</label>
          </div>
        </td>

        <td className="px-3 py-2 text-left">
          <div className="flex item-center justify-center">
            <button
              className="w-4 mr-2 transform hover:text-blue-500 hover:scale-110"
              onClick={() => {
                viewClick(ebl?.Rule_ref);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="gray"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </svg>
            </button>
            <button
              className="w-4 mr-2 transform hover:text-blue-500 hover:scale-110"
              onClick={() => {
                return hanldleEdit(ebl?.Rule_ref, ebl);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="gray"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
            </button>
            <button
              className="w-4 mr-2 transform hover:text-red-500 hover:scale-110"
              onClick={() => {
                return delRule(selectedIndex);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="gray"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default DisplayRuleMasterDetails;