import 'cross-fetch/polyfill';
import {CognitoUserAttribute} from "amazon-cognito-identity-js";
import {UserPool} from "../../../Services/UserPool";
import {httpMethods} from "../../../library/enum";
import Http from "../../../library/http.library";
import {makeQuery} from "../../../Common/util.helper";
import { cognito } from '../../../Config/consts';

export const getUserList = async (tokens: any, currentPage: any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/users?page=${currentPage}`).setToken(tokens.idToken.jwtToken).request();

};

export const filterUsers = async (data: any, currentPage: number, tokens: any) => {
  var query = {
        ...(data.email ? {email: data.email} : {}),
        ...(data.mobile ? {mobile: data.mobile} : {}),
        ...(data.name ? {firstName: data.name} : {}),
        ...(data.user_id ? {id: data.user_id} : {}),
        ...(data.user_type ? {userType: data.user_type} : {}),
        ...(data.partner ? {partnerId: data.partner} : {}),
    }
  const params = makeQuery(query);
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/users?page=${currentPage}${params}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};
export const getSingleUser = async (data: any, tokens: any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/user/${data}`).setToken(tokens.idToken.jwtToken).request();

};

export const updateUser = async (data: any, tokens: any) => {
    var query ={
    userId: data.userId,
        active: data.active
    }
    return Http.setMethod(httpMethods.patch).setUrl(`/user`).setData(query).setToken(tokens.idToken.jwtToken).request();
};

export const getPartnerList = async (tokens: any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/partners?isPaginated=false`).setToken(tokens.idToken.jwtToken).request();
};

/**
 * Get specific user details
 * @param tokens
 * @param isPermission
 */
export const getUserDetails = async (tokens: any, isPermission: boolean = false) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/user?isPermission=${isPermission}`).setToken(tokens.jwtToken).request();
};

/**
 * create user
 * @param tokens
 * @param data
 */
export const createUser = async (tokens: any, data: any) => {
  const payload: any = {
        userType : "STAFF",
        mobile : {
            value : data.mobile,
            isVerified : false
    },
        email : {
            value : data.email,
            isVerified : false
    },
        firstName : data.name,
        poolId : cognito.UserPoolId ,
    active: "ENABLED",
        roles: data.role
  };

    return await Http.setMethod(httpMethods.post).setUrl(`/users`)
    .setData(payload)
    .setToken(tokens.idToken.jwtToken)
    .request();
  /*return new Promise(async (resolve, reject) => {
        try {
            var attributeList = [];

            var attributeGivenName = new CognitoUserAttribute({
                Name: 'given_name',
                Value: data.name,
            });
            var attributeFamilyName = new CognitoUserAttribute({
                Name: 'family_name',
                Value: '',
            });
            var attributePhoneNumber = new CognitoUserAttribute({
                Name: 'phone_number',
                Value: data.mobile,
            });
            var attributeRole = new CognitoUserAttribute({
                Name: 'custom:role',
                Value: data.role,
            });
            attributeList.push(attributeGivenName);
            attributeList.push(attributeFamilyName);
            attributeList.push(attributePhoneNumber);
            attributeList.push(attributeRole);


            UserPool.signUp(data.email, data.password, attributeList, [], function (
                err: any,
                result: any
            ) {
                if (err) {
                    reject(err);
                    return;
                }
                resolve(result.user);

            });
        } catch (error) {
            reject(error);
        }
    });*/
};

export const patchUser = async (tokens: any, userId: string, data: any) => {
  const payload: any = {
        mobile : {
            value : data.mobile.value,
            isVerified : data.mobile.isVerified
    },
        email : {
            isVerified : data.email.isVerified
    },
    cognitoUpdate: true,
        firstName : data.firstName,
    lastName: data.lastName,
    isFirstTime: data.isFirstTime,
  };

    return await Http.setMethod(httpMethods.patch).setUrl(`/user/${userId}`)
    .setData(payload)
    .setToken(tokens.jwtToken)
    .request();
};

/**
 * Assign warehouse to user
 * @param tokens
 * @param payload
 */
export const assignWarehouseUser = async (tokens: any, payload: any) => {
    return await Http.setMethod(httpMethods.post).setUrl(`/user-warehouse`).setData(payload).setToken(tokens.idToken.jwtToken).request();
};

export const getAllRoles = async (tokens: any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/role?isPaginated=false`).setToken(tokens.idToken.jwtToken).request();

}

/**
 * Enable or disable user
 * @param tokens
 * @param payload
 */
const enableDisableUser = async (tokens: any, payload: any) => {
  return await Http.setMethod(httpMethods.patch)
    .setUrl(`/user-required-status`)
    .setData(payload)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

/**
 * Enable or disable user activity logd
 * @param tokens
 * @param payload
 */
const enableDisableUserActivityLogs = async (tokens: any, userId: any) => {
  return await Http.setMethod(httpMethods.get)
    .unsetData()
    .setUrl(`/user/activity/logs?userId=${userId}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

const UserListService = {
  getUserList,
  getPartnerList,
  filterUsers,
  getSingleUser,
  updateUser,
  getUserDetails,
  createUser,
  assignWarehouseUser,
  getAllRoles,
  enableDisableUser,
  enableDisableUserActivityLogs,
};

export default UserListService;
