import axios from "axios";
import Http from "../../../library/http.library";
import {httpMethods} from "../../../library/enum";

class RoleService {

    /**
     * Get all permissions
     * @param page
     * @param tokens
     * @param isPaginated
     * @param query
     */
    async getAllRoles(page:number, tokens: any, isPaginated: boolean = true, query = "") {
        return await Http.setMethod(httpMethods.get).setUrl(`/role?page=${page}${!isPaginated ? `&isPaginated=false` : ``}${query}`).setToken(tokens.idToken.jwtToken).request();

    }

    /**
     * Add new role
     * @param data
     * @param tokens
     */
    async addRole(data: any, tokens: any) {
        return await Http.setMethod(httpMethods.post).setUrl(`/role`).setData([data]).setToken(tokens.idToken.jwtToken).request();

    }

    /**
     * Add permissions to role
     * @param data
     * @param tokens
     */
    async addRolePermission(data: any, tokens: any) {
        return await Http.setMethod(httpMethods.post).setUrl(`/role-permission`).setData(data).setToken(tokens.idToken.jwtToken).request();
    }

    /**
     * Assigns role to user
     * @param data
     * @param tokens
     */
    async addUserRole(data: any, tokens: any) {
        return await Http.setMethod(httpMethods.post).setUrl(`/user-role`).setData(data).setToken(tokens.idToken.jwtToken).request();

    }

    /**
     * Update role details
     * @param data
     * @param tokens
     */
    async updateRole(data: any, tokens: any) {
        return Http.setMethod(httpMethods.patch).setUrl(`/role/${data.id}`).setData(data).setToken(tokens.idToken.jwtToken).request();

    }

    /**
     * Remove role
     * @param id
     * @param tokens
     */
    async removeRole(id: number, tokens: any) {

        return Http.setMethod(httpMethods.delete).setUrl(`/role/${id}`).setToken(tokens.idToken.jwtToken).request();

    }

}

export default new RoleService();
