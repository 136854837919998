import Layout from "../../Layout/Layout";
import React, {useEffect, useState} from "react";
import moment from "moment";
import PermissionService from "../Services/permission.service"
import Loader from"../../../Components-ui/Loader"
import {toast, ToastContainer} from "react-toastify";
import PermissionForm from "./PermissionForm";
import {useAuthStore} from "../../../Store/AuthStore";
import {Pagination} from "../../../Components-ui/Pagination";
import ConfirmationModal from "../../../Components-ui/ConfirmationModal";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";

interface PropsType {
}

const PermissionTable: React.FC<PropsType> = () => {

    const { logout, permissions, userData , tokens } = useAuthStore();

    const [Loading, setLoading] = useState<any>(false);
    const [Permissions, setPermissions] = useState<any>([]);
    const [MetaData , setMetaData] = useState<any>({});
    const [CurrentPage , setCurrentPage] = useState<number>(0);
    const [ConfirmationOpenModal, setConfirmationOpenModal] = useState(false);
    const [SelectedId, setSelectedId] = useState<number>(0);
    const [OpenModal, setOpenModal] = useState(false);
    const [Validator, setValidator] = useState(false);
    const [Permission, setPermission] = useState<any>({
        permissionCode: "",
        description: "",
        isSystem: false,
        active: "ENABLED",
    })

    useEffect((): any => {
        getAllPermissions(CurrentPage)
        // eslint-disable-next-line
    }, []);


    /**
     * Validate permission data
     * @param e
     */
    const onChangePermission = (e: any) => {

        setPermission({
            ...Permission,
            [e.target.name]: e.target.value,
        });

        return setValidator(true);
    };


    /**
     * Get all permissions
     * @param page
     */
    const getAllPermissions = async (page: number) => {
        setLoading(true)
        let data: any = await PermissionService.getAllPermissions(page, tokens);
        if (data.status === 200 && data.data.data) {
            setCurrentPage(page)
            setMetaData({
                current: data.data.meta.page,
                pages: data.data.meta.totalPages,
            })
            setPermissions(data ? data.data.data.result : []);
        }
        setLoading(false)

    };

    /**
     * Close modal
     */
    const closeModal = () => {
        setOpenModal(false)
    }

    /**
     * Open permission modal
     */
    const onAddPermission = () => {
        setInitialState()
        setOpenModal(true)
    }


    /**
     * Get permission details
     * @param id
     */
    const getPermission = (id: any) => {
        let permission: any = Permissions.filter((permission:any) => permission.id === id);
        if (permission.length > 0) {
            setPermission(permission[0])
            setOpenModal(true);
            setValidator(true)
        }
    }


    /**
     * Enable or disable permission
     * @param e
     * @param data
     */
    const enableDisablePermission = async (e: any, data:any) => {
        setLoading(true)
        let cloneData = {...data}

        cloneData.active = e.target.checked ? 'ENABLED' : 'DISABLED';
        await PermissionService.updatePermission(cloneData, tokens);
        await getAllPermissions(CurrentPage);
        setLoading(false)
        return toast.success("Permission was successfully updated");
    }


    /**
     * Set permission details to initial state
     */
    const setInitialState = () => {
        setPermission({
            permissionCode: "",
            description: "",
            isSystem: false,
            active: "ENABLED",
        })
    }

    /**
     * Open or close confirmation modal
     * @param value
     */
    const setConfirmModal = (value: boolean) => {
        setConfirmationOpenModal(value)
    }

    /**
     * Set selected record id
     * @param id
     */
    const onRemovePermission = async (id: number) => {
        setSelectedId(id);
        setConfirmationOpenModal(true)
    }

    /**
     * Remove permission
     */
    const removePermission = async () => {
        try {
            setLoading(true);
            await PermissionService.removePermission(SelectedId, tokens);
            await getAllPermissions(CurrentPage);
            setLoading(false)
            return toast.success("Permission was successfully removed");

        }catch (error: any) {
            setLoading(false);
            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                console.log(error.response.data.errors[0]);
                toast.error(error.response.data.errors[0].title);
            }
        }
    }

    return (
        <Layout type={"AuthorizationModule"}>
            <ToastContainer />
            <div className="flex justify-between items-center mb-5">
                <h1 className="text-2xl font-bold tracking-tight">
                    {"Permissions"}
                </h1>
               
            </div>

            <div
                className="w-full rounded border-gray-200 overflow-hidden shadow-lg dark:border-gray-700 bg-white">

                <div className="px-4 pt-4 pb-4 sm:px-6 grid grid-cols-12 gap-5">
                    <div className="col-span-12 sm:col-span-12">
                        {
                            Loading ? (<Loader/>) :
                                    <div className="border bg-white rounded border-gray-200 shadow">

                                        <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                                            <thead>
                                            <tr className="text-left border-b border-gray-300">
                                                <th className="sticky top-0 px-3 py-2 bg-gray-800">Description</th>
                                                <th className="sticky top-0 px-3 py-2 bg-gray-800">Code</th>
                                                <th className="sticky top-0 px-3 py-2 bg-gray-800">System Rule</th>
                                                <th className="sticky top-0 px-3 py-2 bg-gray-800">Date</th>
                                                {
                                                    HasAnyAccess(['permission.edit']) && (
                                                <th className="sticky top-0 px-3 py-2 bg-gray-800">Status</th>)}
                                                {
                                                    HasAnyAccess(['permission.remove','permission.edit']) && (
                                                <th className="sticky top-0 px-3 py-2 bg-gray-800">Actions</th>)}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                Permissions.length > 0 ?
                                                Permissions.map((permission:any, index:number) => {
                                                    return (
                                                        <tr key={index} className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                            <td className="py-3 px-3 text-left relative">
                                                                {permission.description}
                                                            </td>
                                                            <td className="py-3 px-3 text-left relative">
                                                                {permission.permissionCode}
                                                            </td>
                                                            <td className="py-3 px-3 text-left relative">
                                                                {permission.isSystem ? 'True' : 'False'}
                                                            </td>
                                                            <td className="py-3 px-3 text-left relative">
                                                                {moment(permission.createdAt).format("DD-MM-YYYY HH:mm")}
                                                            </td>
                                                                {
                                                                    HasAnyAccess(['permission.edit']) && (
                                                                        <td className="py-3 px-3 text-left relative">

                                                                        <div
                                                                    className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="toggle"
                                                                        id={`${permission.id}table-toggle`}
                                                                        checked={permission.active === 'ENABLED'}
                                                                        className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                                                                        onChange={(e) => enableDisablePermission(e, permission)}
                                                                    />
                                                                    <label htmlFor={`${permission.id}table-toggle`}
                                                                           className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"/>
                                                                </div>
                                                            </td>)}
                                                            {
                                                                HasAnyAccess(['permission.edit', 'permission.remove']) && (
                                                            <td className="py-3 px-3 text-left relative">
                                                                {
                                                                    HasAnyAccess(['permission.edit']) && (
                                                                <button
                                                                    className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                                    type="button"
                                                                    onClick={() => getPermission(permission.id)}
                                                                >
                                                                    Edit
                                                                </button>)}
                                                                {/*{*/}
                                                                {/*    permissions.filter((permission: any) => permission.permissionCode === 'permission.remove').length > 0 && (*/}
                                                                {/*<button*/}
                                                                {/*    className="text-white bg-red-600 border border-red-300 rounded-md background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"*/}
                                                                {/*    type="button"*/}
                                                                {/*    onClick={() => onRemovePermission(permission.id)}*/}
                                                                {/*>*/}
                                                                {/*    Remove*/}
                                                                {/*</button>)}*/}
                                                            </td>)}
                                                        </tr>
                                                    )
                                                }) :
                                                        <tr className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                            <td colSpan={6} className="py-3 px-3 text-left relative text-center">
                                                                No data available.
                                                            </td>
                                                        </tr>
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                        }
                    </div>
                </div>
                <div className="col-span-12 sm:col-span-12 px-4 pt-2">
                    {/*Pagination Bar*/}
                    {
                        !Loading && (
                            <Pagination
                                meta={MetaData}
                                handlePageChange={(number:number) => getAllPermissions(number)}
                            />
                        )
                    }

                </div>
            </div>
            {
                OpenModal && (
                    <PermissionForm
                        onCloseModal={closeModal}
                        permission={Permission}
                        onChangePermission={onChangePermission}
                        getAllPermissions={getAllPermissions}
                        validatorRef={Validator}
                        page={CurrentPage}
                    />
                )
            }

            <ConfirmationModal
                isOpen={ConfirmationOpenModal}
                onSubmit={removePermission}
                setOpenModel={(value:boolean) => setConfirmModal(value)}
            />
        </Layout>
    );
};

export default PermissionTable;
