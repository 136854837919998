import React, {useEffect, useState} from "react";
import Loader from "../../../Components-ui/Loader"
import {toast} from "react-toastify";
import RoleService from "../Services/role.service";
import SimpleReactValidator from "simple-react-validator";
import {useAuthStore} from "../../../Store/AuthStore";
import MultipleSelect from "../../../Components-ui/MultipleSelect";

interface PropsType {
    onCloseModal: any,
    role: any,
    onChangeRole: any,
    validatorRef: any,
    getAllRoles: any,
    page: number,
    permissions: any
}

const RoleForm: React.FC<PropsType> = ({
      onCloseModal,
      role,
      onChangeRole,
      validatorRef,
      getAllRoles,
      page,
      permissions
  }) => {

    const { tokens } = useAuthStore();

    const [ValidatorStatus, setValidatorStatus] = useState<boolean>(false);


    const [Loading, setLoading] = useState<any>(false);
    const [SelectedPermissions, setSelectedPermissions] = useState<any>([]);

    const simpleValidator = new SimpleReactValidator({
        locale: "en",
        validators: {},
    });

    const validationRef = React.useRef(simpleValidator);
    const validator = validationRef.current;

    /**
     * Get validation error messages
     * @param nameOfStateProp
     */
    const getValidatorError = (nameOfStateProp: any) => {
        const allErrorMessages = validator.getErrorMessages();
        return allErrorMessages[nameOfStateProp];
    };

    useEffect(() => {
        if (role.hasOwnProperty('id')) {
            setValidatorStatus(true)
            setSelectedPermissions(role.permissions)
        }
    }, [role]);


    /**
     * Add or update role details
     * @param e
     */
    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (!validatorRef || !validator.allValid()) {
            setValidatorStatus(true)
            return toast.error("Please make sure all the inputs are valid.");
        }

        try {
            setLoading(true)
            if (role.hasOwnProperty('id')) {
                const roleResponse: any  = await RoleService.updateRole(role, tokens);
                await addRolePermission(roleResponse)
                await getAllRoles(page);
                setLoading(false);
                onCloseModal()
                return toast.success("Role was successfully updated");
            } else {
                const roleResponse: any = await RoleService.addRole(role, tokens);
                await addRolePermission(roleResponse)
                await getAllRoles(page);
                setLoading(false);
                onCloseModal()
                return toast.success("Role was successfully added");
            }

        } catch (error: any) {
            setLoading(false);
            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                console.log(error.response.data.errors[0]);
                toast.error(error.response.data.errors[0].title);
            }
        }
    }

    /**
     * Add permissions to role
     * @param roleData
     */
    const addRolePermission = async (roleData: any) => {
        if (roleData.data.data) {
            let payload = SelectedPermissions.map((permission: any) => {
                return {
                    roleId: Array.isArray(roleData.data.data) ? roleData.data.data[0].id : roleData.data.data.id,
                    permissionId: permission.id,
                    active: "ENABLED"
                }
            })
            await RoleService.addRolePermission(payload, tokens)
        }
    }

    /**
     * Handle multiple select change event
     * @param selected
     */
    const handleChangeItem = (selected: any) => {
        setSelectedPermissions(selected)
        console.log(selected, "selected")
    };

    return (
        <>
            {
                Loading ? <Loader/> : (
                    <div
                        className=" w-full bg-gray-800 bg-opacity-50 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-4xl">
                            <div
                                className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
                                <div
                                    className="items-start justify-between px-6 py-4 border-b border-solid border-blue bg-gray-100 rounded-t">
                                    <h3 className="text-xl font-semibold">{role.hasOwnProperty('id') ? 'Role Details' : 'Add New Role'}</h3>
                                </div>

                                <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
                                    <div className="col-span-12 sm:col-span-12">
                                        <label htmlFor="name"
                                               className="block text-sm font-medium text-gray-700">
                                            Name
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                autoComplete="off"
                                                id="name"
                                                name="name"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                value={role?.name ?? ""}
                                                onChange={(e: any) => {
                                                    onChangeRole(e)
                                                }}
                                            />
                                            {validator.message(
                                                "name",
                                                role?.name,
                                                "required"
                                            )}
                                            {
                                                ValidatorStatus ?
                                                    <span className="text-xs text-red-700"
                                                          id="name">{getValidatorError("name")}</span> : ""
                                            }
                                        </div>
                                    </div>
                                    <div className="col-span-12 sm:col-span-12">
                                        <label htmlFor="description"
                                               className="block text-sm font-medium text-gray-700">
                                            Description
                                        </label>
                                        <div className="mt-1">
                                            <textarea
                                                autoComplete="off"
                                                id="description"
                                                name="description"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                value={role?.description ?? ""}
                                                onChange={(e: any) => {
                                                    onChangeRole(e)
                                                }}
                                            />
                                            {validator.message(
                                                "description",
                                                role?.description,
                                                "required"
                                            )}
                                            {
                                                ValidatorStatus ?
                                                    <span className="text-xs text-red-700"
                                                          id="description">{getValidatorError("description")}</span> : ""
                                            }

                                        </div>
                                    </div>

                                    <div className="col-span-12 sm:col-span-12">
                                        <label htmlFor="permission"
                                               className="block text-sm font-medium text-gray-700">
                                            Permissions
                                        </label>
                                        <div className="mt-1">
                                            <MultipleSelect
                                                optionList={permissions}
                                                onChangeItem={handleChangeItem}
                                                selectedValue={SelectedPermissions}
                                                closeMenuOnSelect={false}
                                                getOptionLabel={(option:any) => `${option.permissionCode} (${option.description})`}
                                                getOptionValue={(option:any) => `${option.id}`}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="flex items-center justify-end sm:py-3  sm:px-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <div>
                                        <button
                                            id="modal"
                                            className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={onCloseModal}
                                        >
                                            Close
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                                            type="button"
                                            onClick={(e) => handleSubmit(e)}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default RoleForm;
