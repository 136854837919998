import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import BulkUploadService from "../BulkUpload/BulUploadService";
import { useAuthStore } from "../../Store/AuthStore";
import Loader from "../../Components-ui/Loader";
import SimpleReactValidator from "simple-react-validator";
import Service from "../CustomerGroup/CustomerGroup.Service";
import { ToastContainer, toast } from "react-toastify";
import GiftService from "./GIftService";
import CustomerService from "../Customer/Services/Customer.service";
import { useHistory } from "react-router-dom";

interface PropsType {
  onCloseModal: any;
  giftList: any;
}

const AddGiftModal: React.FC<PropsType> = ({ onCloseModal, giftList }) => {
  const [Loading, setLoading] = useState<any>(false);

  const [b2bCustomers, setb2bCustomers] = useState([]);
  const [partnerList, setPartnerList] = useState([]);
  const [partnerId, setPartnerId] = useState("");
  const [metalName, setMetalName] = useState("");
  const [gramsValue, setGramsValue] = useState("");
  const [message, setMessage] = useState("");
  const [b2bSenderId, setB2BSenderId] = useState("");
  const [value, setValue] = useState("");
  const [typeOfValue, setTypeOfValue] = useState("");
  const [file, setFile] = useState("");

  const [validatorStatus, setValidator] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState("");

  const { logout, permissions, userData, tokens } = useAuthStore();

  let groupList = [
    { name: "ebullion", id: "123456" },
    { name: "xvz", id: "129956" },
    { name: "mnop", id: "123400" },
  ];
  const simpleValidator = new SimpleReactValidator({
    locale: "en",
    validators: {},
  });
  const validationRef = React.useRef(simpleValidator);
  const validator = validationRef.current;

  const history = useHistory();
  const changeHandler = (event: any) => {
    let selectedFile = event.target.files[0];
    setFileToUpload(selectedFile);
    let reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onloadend = (e: any) => {
      setFile(e.target.result);
    };
  };

  const get_partner_list = async () => {
    await CustomerService.getPartnerList(tokens)
      .then((response: any) => {
        setPartnerList(response.data.data);
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const getB2BCustomers = async () => {
    try {
      const res = await GiftService.getB2BCustomers(tokens, partnerId);
      setb2bCustomers(res ? res.data.data : []);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchData = async () => {
    // setLoading(true);
    // await Service.getAllGroupData(tokens)
    //   .then((response: any) => {
    //     console.log("groups", response.data);
    //     setGroupList(response.data.data);
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setLoading(false);
    //   });
  };

  const submitForm = async () => {
    setValidator(true);

    if (validator.allValid()) {
      let formData = new FormData();

      formData.append("metal", metalName);
      formData.append("metalType", typeOfValue);
      formData.append("b2bSenderId", b2bSenderId);
      formData.append("metalVal", gramsValue);
      formData.append("message", message);
      formData.append("file", fileToUpload);
      formData.append("partnerId", partnerId);

      setValidator(true);
      setLoading(true);

      await GiftService.uploadData(tokens, formData)
        .then((response: any) => {
          setLoading(false);
          toast.success(response.data.data.payload.data.msg);
          onCloseModal();
          // window.location.href = window.location.href;
          giftList(0);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.errors[0]?.title);
          setLoading(false);
          onCloseModal();
        });
    } else {
      validator.showMessages();

      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      // forceUpdate();
    }
  };

  const getValidatorError = (nameOfStateProp: any) => {
    const allErrorMessages = validator.getErrorMessages();
    return allErrorMessages[nameOfStateProp];
  };

  useEffect(() => {
    getB2BCustomers();
    get_partner_list();
  }, [partnerId]);

  return (
    <>
      {Loading ? (
        <Loader />
      ) : (
        <div className=" w-full bg-gray-800 bg-opacity-50 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className=" w-full bg-gray-800 bg-opacity-50 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-4xl">
              <div className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
                <div className="items-start justify-between px-6 py-4 border-b border-solid border-blue bg-gray-100 rounded-t">
                  <div className="flex justify-between items-center mb-5">
                    <h1 className="text-2xl font-bold tracking-tight">
                      {"Add Gift"}
                    </h1>
                    {/* <button
              onClick={() => downloadSample()}
              className="text-white  bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
              type="button"
            >
              Download sample
            </button> */}
                  </div>

                  <div className="flex flex-row">
                    <div className="border bg-white rounded border-gray-200 shadow">
                      <div className="flex-1 p-2">
                        <fieldset className=" rounded p-1 border-gray-300 pb-4 flex mt-3">
                          {/* <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
                                {"Details"}
                            </legend> */}
                          <div className="w-full">
                            <div className="flex flex-wrap -mx-3 mb-2">
                              <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label
                                  className="block text-sm font-medium text-gray-700"
                                  htmlFor="email"
                                >
                                  Select Partner
                                </label>
                                <select
                                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  id="partner"
                                  value={partnerId}
                                  onChange={(e) => setPartnerId(e.target.value)}
                                >
                                  <option disabled value="">
                                    Select Partner
                                  </option>
                                  {/* <option value="quantity">Quantity</option> */}
                                  {partnerList.length > 0 &&
                                    partnerList?.map((partner: any) => (
                                      <option
                                        key={partner?.partnerId}
                                        value={partner?.partnerId}
                                      >
                                        {partner?.name}
                                      </option>
                                    ))}
                                </select>
                                <span
                                  className="text-xs text-red-700"
                                  id="partner"
                                >
                                  {validator.message(
                                    "Partner",
                                    partnerId,
                                    "required"
                                  )}
                                </span>
                              </div>
                              <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label
                                  className="block text-sm font-medium text-gray-700"
                                  htmlFor="email"
                                >
                                  Select B2B Customer
                                </label>
                                <select
                                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  id="grid-state"
                                  value={b2bSenderId}
                                  onChange={(e) =>
                                    setB2BSenderId(e.target.value)
                                  }
                                >
                                  <option disabled value="">
                                    Select B2B Customer
                                  </option>
                                  {/* <option value="quantity">Quantity</option> */}
                                  {b2bCustomers.length > 0 &&
                                    b2bCustomers?.map((b2bcustomer: any) => (
                                      <option
                                        key={b2bcustomer?.customerId}
                                        value={b2bcustomer?.customerId}
                                      >
                                        {b2bcustomer?.displayName} (
                                        {b2bcustomer?.partnerName})
                                      </option>
                                    ))}
                                </select>
                                <span
                                  className="text-xs text-red-700"
                                  id="name"
                                >
                                  {validator.message(
                                    "B2B_customer",
                                    b2bSenderId,
                                    "required"
                                  )}
                                </span>
                              </div>
                              <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label
                                  className="block text-sm font-medium text-gray-700"
                                  htmlFor="email"
                                >
                                  Select type
                                </label>
                                <select
                                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  id="type"
                                  value={typeOfValue}
                                  onChange={(e) =>
                                    setTypeOfValue(e.target.value)
                                  }
                                >
                                  <option disabled value="">
                                    Select type
                                  </option>
                                  {/* <option value="quantity">Quantity</option> */}
                                  <option value="Quantity">Quantity</option>
                                </select>
                                <span
                                  className="text-xs text-red-700"
                                  id="type"
                                >
                                  {validator.message(
                                    "type",
                                    typeOfValue,
                                    "required"
                                  )}
                                </span>
                              </div>

                              <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label
                                  className="block text-sm font-medium text-gray-700"
                                  htmlFor="email"
                                >
                                  Metal type
                                </label>
                                <select
                                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  id="metal_type"
                                  value={metalName}
                                  onChange={(e) => setMetalName(e.target.value)}
                                >
                                  <option disabled value="">
                                    Metal type
                                  </option>
                                  {/* <option value="quantity">Quantity</option> */}
                                  <option value="Ag">Silver</option>
                                  <option value="Pt">Platinum</option>
                                  <option value="Pd">Palladium</option>
                                  <option value="Au">Gold</option>
                                </select>
                                <span
                                  className="text-xs text-red-700"
                                  id="metal_type"
                                >
                                  {validator.message(
                                    "metal_type",
                                    typeOfValue,
                                    "required"
                                  )}
                                </span>
                              </div>

                              <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label
                                  className="block text-sm font-medium text-gray-700"
                                  htmlFor="user-id"
                                >
                                  Grams Value
                                </label>
                                <input
                                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  name="user-id"
                                  id="grams_value"
                                  type="number"
                                  placeholder="Grams Value"
                                  autoComplete="none"
                                  value={gramsValue}
                                  onChange={(e) =>
                                    setGramsValue(e.target.value)
                                  }
                                  maxLength={30}
                                />
                                <span
                                  className="text-xs text-red-700"
                                  id="grams_value"
                                >
                                  {validator.message(
                                    "Value",
                                    gramsValue,
                                    "required"
                                  )}
                                </span>
                                {/* {validatorStatus ? (
                          <span className="text-xs text-red-700" id="name">
                            {getValidatorError("title")}
                          </span>
                        ) : (
                          ""
                        )} */}
                              </div>

                              <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label
                                  className="block text-sm font-medium text-gray-700"
                                  htmlFor="user-id"
                                >
                                  Message
                                </label>
                                <input
                                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  name="message"
                                  id="message"
                                  type="textarea"
                                  placeholder="Message"
                                  autoComplete="none"
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                                />
                                <span
                                  className="text-xs text-red-700"
                                  id="message "
                                >
                                  {validator.message(
                                    "message",
                                    message,
                                    "required"
                                  )}
                                </span>
                                {/* {validatorStatus ? (
                          <span className="text-xs text-red-700" id="name">
                            {getValidatorError("title")}
                          </span>
                        ) : (
                          ""
                        )} */}
                              </div>
                              <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label
                                  className="block text-sm font-medium text-gray-700"
                                  htmlFor="user-type"
                                >
                                  Upload file
                                </label>
                                <div className="relative">
                                  <input
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="mobile"
                                    type="file"
                                    accept=".xls,.xlsx"
                                    // value={file}
                                    onChange={(event) => {
                                      changeHandler(event);
                                    }}
                                    // autoComplete="none"
                                    // value={"userDetails.userType"}
                                    // disabled
                                  />

                                  <span
                                    className="text-xs text-red-700"
                                    id="name"
                                  >
                                    {validator.message(
                                      "Document",
                                      file,
                                      "required"
                                    )}
                                  </span>
                                  <li
                                    style={{ color: "#4fb645" }}
                                    className="text-xs"
                                  >
                                    File size should not be greater than 15 MB
                                  </li>
                                  <li
                                    style={{ color: "#4fb645" }}
                                    className="text-xs"
                                  >
                                    Only xlsx file is allowed to upload
                                  </li>
                                  {/* <li
                                    style={{ color: "#0000EE" }}
                                    className="text-xs"
                                  >
                                    {" "}
                                    <a
                                      href="#"
                                      onClick={() => downloadSample()}
                                    >
                                      Download Sample
                                    </a>
                                  </li> */}
                                </div>
                              </div>
                              {/* <div className="pb-3 w-full px-3 mb-6 md:mb-0">
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="partner"
                    >
                      Upload file
                    </label>
                    <div className="relative">
                      <input
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        id="mobile"
                        type="file"
                        // autoComplete="none"
                        // value={"userDetails.partner?.name"}
                        // disabled
                      />
                    </div>
                  </div> */}
                            </div>
                          </div>
                        </fieldset>

                        {/* <div className="flex justify-center  items-center mb-5">
                          <button
                            onClick={() => submitForm()}
                            className="text-white  bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                            type="button"
                          >
                            Submit
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-end sm:py-3  sm:px-6 border-t border-solid border-blueGray-200 rounded-b">
                    <div>
                      <button
                        id="modal"
                        className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                        type="button"
                        onClick={onCloseModal}
                      >
                        Close
                      </button>
                    </div>
                    <div>
                      <button
                        className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                        type="button"
                        onClick={(e) => submitForm()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddGiftModal;
