import Http from "../../library/http.library";
import {httpMethods} from "../../library/enum";
import {makeQuery} from "../../Common/util.helper";

export const getUOMList = async (tokens:any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/uom-master`).setToken(tokens.idToken.jwtToken).request();

};

export const updateUOM = async (data: any, tokens: any) => {
    return Http.setMethod(httpMethods.patch).setUrl(`/uom-master/${data.id}`).setData(data).setToken(tokens.idToken.jwtToken).request();
};

export const filterUOM = async (data: any, currentPage: number, tokens: any) => {
    var query = {
        isPaginated: true,
        ...(data.name ? {uomName: data.name} : {}),
        ...(data.code ? {uomCode: data.code} : {}),
        ...(data.active ? {active: data.active} : {}),
        page: currentPage,
    }
    const params = makeQuery(query);
    return await Http.setMethod(httpMethods.get).setUrl(`/uom-master?${params}`).setToken(tokens.idToken.jwtToken).request();
};

export const addNewUOM = async (data: any, tokens: any) => {
    return await Http.setMethod(httpMethods.post).setUrl(`/uom-master`).setData(data).setToken(tokens.idToken.jwtToken).request();
};

const UOMService = {
    getUOMList,
    updateUOM,
    filterUOM,
    addNewUOM
};

export default UOMService;
