import React, {useEffect, useState} from "react";
import Loader from "../../../Components-ui/Loader"
import {toast} from "react-toastify";
import CityService from "../Services/city.service";
import SimpleReactValidator from "simple-react-validator";
import {useAuthStore} from "../../../Store/AuthStore";

interface PropsType {
    onCloseModal: any,
    city: any,
    onChangeCity: any,
    validatorRef: any,
    getAllCities: any,
    states: any,
    page: number
}

const CityForm: React.FC<PropsType> = ({
      onCloseModal,
      city,
      onChangeCity,
      validatorRef,
      getAllCities,
      states,
      page
  }) => {

    const { tokens } = useAuthStore();

    const [ValidatorStatus, setValidatorStatus] = useState<boolean>(false);
    const [Loading, setLoading] = useState<any>(false);

    const simpleValidator = new SimpleReactValidator({
        locale: "en",
        validators: {},
    });

    const validationRef = React.useRef(simpleValidator);
    const validator = validationRef.current;

    /**
     * Get validation error messages
     * @param nameOfStateProp
     */
    const getValidatorError = (nameOfStateProp: any) => {
        const allErrorMessages = validator.getErrorMessages();
        return allErrorMessages[nameOfStateProp];
    };

    useEffect(() => {
        if (city.hasOwnProperty('id')) {
            setValidatorStatus(true)
        }
    }, [city]);


    /**
     * Add or update city details
     * @param e
     */
    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (!validatorRef || !validator.allValid()) {
            setValidatorStatus(true)
            return toast.error("Please make sure all the inputs are valid.");
        }

        try {
            setLoading(true)
            if (city.hasOwnProperty('id')) {
                await CityService.updateCity(city, tokens);
                await getAllCities(page);
                setLoading(false);
                onCloseModal()
                return toast.success("City was successfully updated");
            } else {
                await CityService.addCity(city, tokens);
                await getAllCities(page);
                setLoading(false);
                onCloseModal()
                return toast.success("City was successfully added");
            }

        } catch (error: any) {
            setLoading(false);
            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                console.log(error.response.data.errors[0]);
                toast.error(error.response.data.errors[0].title);
            }
        }
    }

    return (
        <>
            {
                Loading ? <Loader/> : (
                    <div
                        className=" w-full bg-gray-800 bg-opacity-50 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-4xl">
                            <div
                                className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
                                <div
                                    className="items-start justify-between px-6 py-4 border-b border-solid border-blue bg-gray-100 rounded-t">
                                    <h3 className="text-xl font-semibold">{city.hasOwnProperty('id') ? 'City Details' : 'Add New City'}</h3>
                                </div>

                                <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="city"
                                               className="block text-sm font-medium text-gray-700">
                                            Name
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="city"
                                                name="city"
                                                autoComplete="off"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                value={city?.city ?? ""}
                                                onChange={(e: any) => {
                                                    onChangeCity(e)
                                                }}
                                            />
                                            {validator.message(
                                                "city",
                                                city?.city,
                                                "required"
                                            )}
                                            {
                                                ValidatorStatus ?
                                                    <span className="text-xs text-red-700"
                                                          id="city">{getValidatorError("city")}</span> : ""
                                            }

                                        </div>
                                    </div>
                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                                            State
                                        </label>
                                        <select
                                            id="state"
                                            name="state"
                                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            onChange={(e: any) => {
                                                onChangeCity(e)
                                            }}
                                            value={city?.state ?? ""}
                                        >
                                            <option value="" disabled>Please select a state</option>
                                            {
                                                states.map((state:any) => {
                                                    return (
                                                        <option value={state.stateName}>{state.stateName}</option>
                                                    )
                                                })
                                            }

                                        </select>
                                        {validator.message(
                                            "state",
                                            city?.state,
                                            "required"
                                        )}
                                        {
                                            ValidatorStatus ?
                                                <span className="text-xs text-red-700"
                                                      id="state">{getValidatorError("state")}</span> : ""
                                        }
                                    </div>
                                </div>

                                <div
                                    className="flex items-center justify-end sm:py-3  sm:px-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <div>
                                        <button
                                            id="modal"
                                            className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={onCloseModal}
                                        >
                                            Close
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                                            type="button"
                                            onClick={(e) => handleSubmit(e)}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default CityForm;
