import React from "react";
import { Route } from "react-router-dom";

import CityTable from "../Components/CityTable";


export const CityRoutes: React.FC = () => {
    return (
        <>
            <Route path="/master/city" exact component={CityTable} />
        </>
    );
};
