import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import BarListReport from "./BarListReport";

const AwsRoutes: React.FC = () => {
  return (
    <Fragment>
      <Route path="/daily-barlist-report" exact component={BarListReport} />
    </Fragment>
  );
};

export default AwsRoutes;
