import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import Partner from "./Components/Partner";
import AddUpdateModal from "../../Components-ui/Modal/Modal";

const PartnerRoutes:React.FC = () => {
    return (
        <Fragment>
            <Route path="/partners" exact component={Partner}/>
        </Fragment>
    )
};

export default PartnerRoutes;