import React, {useEffect, useState} from "react";
import Loader from "../../../Components-ui/Loader"
import {toast} from "react-toastify";
import StateService from "../Services/state.service";
import SimpleReactValidator from "simple-react-validator";
import {useAuthStore} from "../../../Store/AuthStore";

interface PropsType {
    onCloseModal: any,
    state: any,
    onChangeState: any,
    validatorRef: any,
    getAllStates: any,
    countries: any,
    page: number
}

const StateForm: React.FC<PropsType> = ({
        onCloseModal,
        state,
        onChangeState,
        validatorRef,
        getAllStates,
        countries,
        page
    }) => {

    const { tokens } = useAuthStore();

    const [Loading, setLoading] = useState<any>(false);

    const [ValidatorStatus, setValidatorStatus] = useState<boolean>(false);

    const simpleValidator = new SimpleReactValidator({
        locale: "en",
        validators: {},
    });

    const validationRef = React.useRef(simpleValidator);
    const validator = validationRef.current;

    useEffect(() => {
        if (state.hasOwnProperty('id')) {
            setValidatorStatus(true)
        }
    }, [state]);

    /**
     * Get validation error messages
     * @param nameOfStateProp
     */
    const getValidatorError = (nameOfStateProp: any) => {
        const allErrorMessages = validator.getErrorMessages();
        return allErrorMessages[nameOfStateProp];
    };


    /**
     * Add or update state details
     * @param e
     */
    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (!validatorRef || !validator.allValid()) {
            setValidatorStatus(true)
            return toast.error("Please make sure all the inputs are valid.");
        }

        try {
            setLoading(true)
            if (state.hasOwnProperty('id')) {
                await StateService.updateState(state, tokens);
                await getAllStates(page);
                setLoading(false);
                onCloseModal()
                return toast.success("State was successfully updated");
            } else {
                await StateService.addState(state, tokens);
                await getAllStates(page);
                setLoading(false);
                onCloseModal()
                return toast.success("State was successfully added");
            }

        } catch (error: any) {
            setLoading(false);
            if (error && error.data && error.data.errors && error.data.errors.length > 0) {
                console.log(error.data.errors[0]);
                toast.error(error.data.errors[0].title);
            }
        }
    }

    return (
        <>
            {
                Loading ? <Loader/> : (
                    <div
                        className=" w-full bg-gray-800 bg-opacity-50 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-4xl">
                            <div
                                className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
                                <div
                                    className="items-start justify-between px-6 py-4 border-b border-solid border-blue bg-gray-100 rounded-t">
                                    <h3 className="text-xl font-semibold">{state.hasOwnProperty('id') ? 'State Details' : 'Add New State'}</h3>
                                </div>

                                <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="stateName"
                                               className="block text-sm font-medium text-gray-700">
                                            Name
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="stateName"
                                                name="stateName"
                                                autoComplete="off"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                value={state?.stateName ?? ""}
                                                onChange={(e: any) => {
                                                    onChangeState(e)
                                                }}
                                            />
                                            {validator.message(
                                                "stateName",
                                                state?.stateName,
                                                "required"
                                            )}
                                            {
                                                ValidatorStatus ?
                                                    <span className="text-xs text-red-700"
                                                          id="description">{getValidatorError("stateName")}</span> : ""
                                            }

                                        </div>
                                    </div>
                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="stateCode"
                                               className="block text-sm font-medium text-gray-700">
                                            Code
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="stateCode"
                                                name="stateCode"
                                                type="number"
                                                autoComplete="off"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                value={state?.stateCode ?? ""}
                                                onChange={(e: any) => {
                                                    onChangeState(e)
                                                }}
                                            />
                                            {validator.message(
                                                "stateCode",
                                                state?.stateCode,
                                                "required"
                                            )}
                                            {
                                                ValidatorStatus ?
                                                    <span className="text-xs text-red-700"
                                                          id="taxCode">{getValidatorError("stateCode")}</span> : ""
                                            }

                                        </div>
                                    </div>
                                    <div className="col-span-12 sm:col-span-6">
                                        <label htmlFor="countryMasterId"
                                               className="block text-sm font-medium text-gray-700">
                                            Country
                                        </label>
                                        <div className="mt-1">
                                            <select
                                                id="countryMasterId"
                                                name="countryMasterId"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                onChange={(e: any) => {
                                                    onChangeState(e)
                                                }}
                                                value={state?.countryMasterId ?? ""}
                                            >
                                                <option value="" disabled>Please select a country</option>
                                                {
                                                    countries.map((country:any, index:number) => {
                                                        return (
                                                            <option key={index} value={country.id}>{country.name}</option>
                                                        )
                                                    })
                                                }

                                            </select>
                                            {validator.message(
                                                "countryMasterId",
                                                state?.countryMasterId,
                                                "required"
                                            )}
                                            {
                                                ValidatorStatus ?
                                                    <span className="text-xs text-red-700"
                                                          id="taxType">{getValidatorError("countryMasterId")}</span> : ""
                                            }

                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="flex items-center justify-end sm:py-3  sm:px-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <div>
                                        <button
                                            id="modal"
                                            className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={onCloseModal}
                                        >
                                            Close
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                                            type="button"
                                            onClick={(e) => handleSubmit(e)}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default StateForm;
