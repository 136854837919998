import Http from "../../../library/http.library";
import { httpMethods } from "../../../library/enum";

class OrderService {
  /**
   * Get order details
   * @param id
   * @param tokens
   */
  async getOrderDetails(id: any, tokens: any) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/order/${id}`)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }
  /**
   * Get all discounts
   * @param query
   * @param page
   */
  async getAllOrders(query: any, page: number, tokens: any, userId: any) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/order?user_id=${userId}&page=${page}${query}`)
      .setToken(tokens.idToken.jwtToken)
      .request();

    // return new Promise(async (resolve, reject) => {
    //     let apiUrl = `${process.env.REACT_APP_URL}/order/${id}`;
    //     resolve(await axios.get(apiUrl));
    // });
  }

  /**
   * Get invoices related to specific order
   * @param id
   */
  async getOrderInvoices(id: any) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/order/b2b/buyback/invoice/all/${id}`)
      .request();
  }

  /**
   * Reject , Accept or Reject and Resend batches
   * @param id
   */
  async orderInvoicesActions(id: any, action: string, body: any) {
    return Http.setMethod(httpMethods.patch)
      .setUrl(`/order/b2b/buyback/invoice/status/${id}/${action}`)
      .setData(body)
      .request();
  }

  /**
   * orderInvoicesDownload
   * @param key Invoice Key
   * @param tokens Auth tokens from Store
   * @returns Response
   */
  async orderInvoicesDownload(key: string, tokens: any) {
    return Http.setMethod(httpMethods.get)
      .setUrl(`/order/b2b/buyback/invoice/download/${encodeURIComponent(key)}`)
      .setResponseType("blob")
      .setToken(tokens.idToken.jwtToken)
      .request();
  }

  /**
   * orderSalesInvoicesDownload
   * @param key Invoice Key
   * @param tokens Auth tokens from Store
   * @returns Response
   */
  async orderSalesInvoicesDownload(key: string, tokens: any) {
    return Http.setMethod(httpMethods.get)
      .setUrl(`/invoice/pdf/${key}`)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }

  async getMetals(tokens: any) {
    return await Http.setMethod(httpMethods.get)
      .setUrl(`/metal-master?isPaginated=false`)
      .request();
  }

  async approveBankTransfer(data: any, tokens: any) {
    return Http.setMethod(httpMethods.post)
      .setUrl(`/bank-approve-status/invoice`)
      .setData(data)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }
  async downloadOrderReport(data: any, tokens: any) {
    return Http.setMethod(httpMethods.get)
      .unsetData()
      .setUrl(`/order/download?${data}`)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }
}

export default new OrderService();
