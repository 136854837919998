import React, {useEffect, useState} from "react";
import Layout from "../Layout/Layout";
import {useHistory, Link} from "react-router-dom";
import {BarListSummaryByLocation, BarListSummaryByPool} from './Metal.Service'
import {Tab} from "@headlessui/react";
import {useAuthStore} from "../../Store/AuthStore";
import BarReconciliationReportService from "../Reports/BarReconciliationReport/BarReconciliationReportService";
import {toast} from "react-toastify";
import Loader from "../../Components-ui/Loader";

const DailyBarListInGrams: React.FC = () => {
    const {tokens, warehouses, permissions} = useAuthStore();
    let history = useHistory();
    const [loading, setLoading] = useState<boolean>(true);
    const [locationData, setLocationData] = useState<any>([]);
    const [poolData, setPoolData] = useState<any>([]);
    const [pools, setPools] = useState<any>([]);
    const [metalList, setMetalList] = useState<any>([]);
    const [metaPoolLList, setMetalPoolLList] = useState<any>([]);
    const [metalArray, setMetal] = useState<any>([]);
    const locationMapping: any = {
        "V001": "BOM",
        "V002": "AMD"
    }

    useEffect(() => {
        fetchdata();
        loadMetals();
    }, [])

    const loadMetals = async (): Promise<void> => {
        return new Promise(async (resolve) => {
            BarReconciliationReportService.getMetals(tokens).then((response: any) => {
                setMetal(response.data.data);
                resolve();
            }).catch(err => {
                toast.error("Something Went Wrong!");
                resolve();
            });
        })
    };


    const fetchdata = () => {
        BarListSummaryByLocation(tokens, warehouses, permissions)
            .then((data: any) => {
                setLocationData(data.data.data.vault);
                setMetalList(data.data.data.summary);
                return BarListSummaryByPool(tokens, warehouses, permissions);
            })
            .then((data: any) => {
                setPoolData(data.data.data.pool);
                setMetalPoolLList(data.data.data.summary);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })
    }

    const roundNo = (amount: string) => {
        return Number(amount).toFixed(4);
    }

    const sumofAll = (location: any, i: number) => {
        let total = parseFloat(location.ebullion[i]) +
            parseFloat(location.customer[i]) +
            parseFloat(location.inTransit[i]);
        return total ? total.toFixed(4) : 0.00.toFixed(4)
    }

    const sumofPool = (pool: any, i: number, row: string) => {
        let total = pool.reduce((total: number, current: any) => {
            total = total + parseFloat(current[row][i])
            return total;
        }, 0.00);
        return total ? total.toFixed(4) : 0.00.toFixed(4)
    }

    const fullTotal = (i: number) => {
        let total = locationData.reduce((total: number, current: any) => {
            total = total + parseFloat(sumofAll(current, i))
            return total;
        }, 0.00);
        return total ? total.toFixed(4) : 0.00.toFixed(4)
    }

    const fullTotalPool = (i: number) => {
        let fulltotal = pools.reduce((pooltotal: number, current: any) => {
            pooltotal += poolData[current].reduce((total: number, c: any) => {
                total = total + parseFloat(c[current][i])
                return total;
            }, 0.00)
            return pooltotal;
        }, 0.00);
        return fulltotal ? fulltotal.toFixed(4) : 0.00.toFixed(4)
    }

    const addDecimal =(num:any)=>{
        return (parseInt(String(num * Math.pow(10, 4)))/Math.pow(10,4)).toFixed(4)
    }

    return (
        <Layout type={"Reports"}>
            <div className="flex justify-between items-center mb-5">
                <h1 className="text-2xl font-bold tracking-tight">
                    {"Daily Bar Transactions"}
                </h1>
            </div>
            <div className="overflow-x-auto">
                {loading ? (<Loader/>) : (
                    <>

                    <div className="w-full px-2 sm:px-0">
                    <Tab.Group>
                        <Tab.List className="flex p-1 space-x-1 bg-gray-300 rounded-xl">
                            <Tab
                                key={1}
                                className={({selected}) =>
                                    classNames(
                                        'w-full py-2.5 text-sm leading-5 font-bold text-gray-600 rounded-lg',
                                        'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                                        selected
                                            ? 'bg-white shadow'
                                            : 'text-white-600 hover:bg-white/[0.12] hover:text-gray-600'
                                    )
                                }
                            >
                                {`By Location`}
                            </Tab>
                            <Tab
                                key={1}
                                className={({selected}) =>
                                    classNames(
                                        'w-full py-2.5 text-sm leading-5 font-bold text-gray-600 rounded-lg',
                                        'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                                        selected
                                            ? 'bg-white shadow'
                                            : 'text-white-600 hover:bg-white/[0.12] hover:text-gray-600'
                                    )
                                }
                            >
                                {`By Pool`}
                            </Tab>

                        </Tab.List>
                        <Tab.Panels className="mt-2">
                            <Tab.Panel
                                key={1}
                                className={classNames(
                                    'bg-white rounded-xl p-3',
                                    'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60'
                                )}
                            >
                                <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                                    <thead className="text-left border-b border-gray-300 text-xs">
                                    <tr className="text-white sticky top-0 px-6 py-3 text-gray-600 bg-purple-300 font-semibold">
                                        <th className="text-white sticky top-0 px-3 py-2 bg-gray-800 z-10 px-3 py-2 text-left">Vault</th>
                                        <th className="text-white sticky top-0 px-3 py-2 bg-gray-800 z-10 px-3 py-2 text-left">Detail</th>
                                        {metalList.map((metal: any) => (
                                            <>
                                                {
                                                    < th
                                                        className="text-white sticky top-0 px-3 py-2 bg-gray-800 z-10 px-3 py-2 text-right">{metalArray.find((i: any) => i.metalCode === metal.metalCode).description}</th>
                                                }
                                            </>
                                        ))
                                        }
                                    </tr>
                                    </thead>
                                    <tbody className="text-gray-800 text-xs ">
                                    {
                                        locationData.map((location: any) => (
                                            <>
                                                {
                                                    location.data.map((data: any) => (
                                                        <>
                                                            <tr className="border-b border-gray-200  bg-white">
                                                                <td className="px-3 py-2 text-left">
                                            <span
                                                className="font-semibold">{location.warehouseId ? location.warehouseId : `Vault ${location.warehouseId}`}
                                            </span>
                                                                </td>
                                                                <td className="px-3 py-2 text-left">
                                                                    <span className="">{data.pool}</span>
                                                                </td>
                                                                {
                                                                    data.summary.map((summary: any) => (
                                                                        <td className="px-3 py-2 text-right">
                                                                            <span
                                                                                className="">{('EBL Pool' === data.pool) ? addDecimal(summary.equivalentWeight) : addDecimal(summary.allocatedWeight)} g</span>
                                                                        </td>
                                                                    ))
                                                                }
                                                            </tr>
                                                        </>
                                                    ))
                                                }
                                                <tr className="bg-gray-200 border-b border-gray-200  bg-white">
                                                    <td className="px-3 py-2 text-right"></td>
                                                    <td className="px-3 py-2 text-right"></td>
                                                    {
                                                        location.summary.map((data: any, key: number) => (
                                                            <>
                                                                <td className="px-3 py-2 text-right">
                                            <span
                                                className="font-semibold">{addDecimal(data.equivalentWeight)} g</span>
                                                                </td>
                                                            </>

                                                        ))
                                                    }
                                                </tr>
                                            </>
                                        ))
                                    }
                                    <tr className="bg-gray-400 border-b border-gray-200  bg-white">
                                        <td className="px-3 py-2 font-semibold text-center" colSpan={2}>Total</td>
                                        {
                                            metalList.map((data: any, key: number) => (
                                                <>
                                                    <td className="px-3 py-2 text-right">
                                            <span
                                                className="font-semibold">{addDecimal(data.equivalentWeight) } g</span>
                                                    </td>
                                                </>

                                            ))
                                        }
                                    </tr>
                                    </tbody>
                                </table>
                            </Tab.Panel>
                            <Tab.Panel
                                key={2}
                                className={classNames(
                                    'bg-white rounded-xl p-3',
                                    'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60'
                                )}
                            >

                                <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                                    <thead className="text-left border-b border-gray-300 text-xs">
                                    <tr className="text-white sticky top-0 px-6 py-3 text-gray-600 bg-purple-300 font-semibold">
                                        <th className="text-white sticky top-0 px-3 py-2 bg-gray-800 z-10 px-3 py-2 text-left">Vault</th>
                                        <th className="text-white sticky top-0 px-3 py-2 bg-gray-800 z-10 px-3 py-2 text-left">Detail</th>
                                        {metaPoolLList.map((metal: any) => (<>
                                                {
                                                    < th
                                                        className="text-white sticky top-0 px-3 py-2 bg-gray-800 z-10 px-3 py-2 text-right">{metalArray.find((i: any) => i.metalCode === metal.metalCode).description}</th>
                                                }
                                            </>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody className="text-gray-800 text-xs ">
                                    {
                                        poolData.map((location: any, index: number) => (
                                            <>
                                                {
                                                    location.data.map((data: any) => (
                                                        <>
                                                            <tr key={index}
                                                                className="border-b border-gray-200  bg-white">
                                                                <td className="px-3 py-2 text-left">
                                            <span
                                                className="font-semibold">{location.pool ? location.pool : `${location.pool}`}
                                            </span>
                                                                </td>
                                                                <td className="px-3 py-2 text-left">
                                                                    <span className="">{data.warehouseId}</span>
                                                                </td>
                                                                {
                                                                    data.summary.map((data: any) => (
                                                                        <td className="px-3 py-2 text-right">
                                            <span
                                                className="">{addDecimal(data.equivalentWeight)} g</span>
                                                                        </td>
                                                                    ))
                                                                }
                                                            </tr>
                                                        </>
                                                    ))
                                                }
                                                <tr className="bg-gray-200 border-b border-gray-200  bg-white">
                                                    <td className="px-3 py-2 text-right"></td>
                                                    <td className="px-3 py-2 text-right"></td>
                                                    {
                                                        location.summary.map((data: any) => (
                                                            <>
                                                                <td className="px-3 py-2 text-right">
                                            <span
                                                className="font-semibold">{addDecimal(data.equivalentWeight)} g</span>
                                                                </td>
                                                            </>

                                                        ))
                                                    }
                                                </tr>
                                            </>
                                        ))
                                    }
                                    <tr className="bg-gray-400 border-b border-gray-200  bg-white">
                                        <td className="px-3 py-2 font-semibold text-center" colSpan={2}>Total</td>
                                        {
                                            metaPoolLList.map((data: any, key: number) => (
                                                <>
                                                    <td className="px-3 py-2 text-right">
                                            <span
                                                className="font-semibold">{addDecimal(data.equivalentWeight)} g</span>
                                                    </td>
                                                </>

                                            ))
                                        }
                                    </tr>
                                    </tbody>
                                </table>

                                {/*<table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">*/}
                                {/*  <thead className="text-left border-b border-gray-300 text-xs text-xs">*/}
                                {/*   <tr className="sticky top-0 px-6 py-3 font-semibold">*/}
                                {/*    <th className="px-3 py-2 text-left">Pool</th>*/}
                                {/*    <th className="text-white sticky top-0 px-6 px-3 py-2 text-left">Vault</th>*/}
                                {/*    { metalList.map( (metal:string) => (<th className="text-white sticky top-0 px-6 px-3 py-2 text-right">{metal}</th>))}*/}
                                {/*    */}
                                {/*    </tr>*/}
                                {/*  </thead>*/}
                                {/*  <tbody className="text-gray-800 text-xs ">*/}
                                {/*    {*/}
                                {/*       pools.map((row:string) => (*/}
                                {/*      <>*/}
                                {/*      {*/}
                                {/*        poolData && poolData[row].map( (pool:any,i:number, array:any) => (*/}
                                {/*          <>*/}
                                {/*          <tr className="border-b border-gray-200  bg-white  ">*/}
                                {/*          { i == 0 && <td className="px-3 py-2 text-left"  rowSpan={array.length}>*/}
                                {/*              <span className="font-semibold">{row}</span>*/}
                                {/*          </td>}*/}
                                {/*          <td className="px-3 py-2 text-left">*/}
                                {/*              <span className="font-semibold">{pool.vaultName ? pool.vaultName : `Vault ${pool.vaultId}` }</span>*/}
                                {/*          </td>*/}
                                {/*          {*/}
                                {/*            pool[row].map( (values:any) => (*/}
                                {/*              <td className="px-3 py-2 text-right" >*/}
                                {/*                <span className="font-semibold">{roundNo(values)}</span>*/}
                                {/*              </td>*/}
                                {/*            ))*/}
                                {/*          }*/}
                                {/*          </tr>*/}
                                {/*          </>*/}
                                {/*        ))*/}
                                {/*      }*/}
                                {/*      <tr className="border-b border-gray-200 bg-gray-200   font-semibold">*/}
                                {/*          <td className="px-3 py-2 text-right"></td>*/}
                                {/*          <td className="px-3 py-2 text-left">Total</td>*/}
                                {/*          {*/}
                                {/*            metalList.map( (values:string,i:number) => (*/}
                                {/*              <td className="px-3 py-2 text-right" >*/}
                                {/*                <span className="font-semibold">{sumofPool(poolData[row],i,row)}</span>*/}
                                {/*              </td>*/}
                                {/*            ))*/}
                                {/*          } */}
                                {/*       </tr>*/}
                                {/*      </>*/}
                                {/*      ))*/}
                                {/*    }*/}
                                {/*    <tr className="sticky top-0 px-3 py-2 text-gray-600 bg-purple-300 font-semibold">*/}
                                {/*    <td className="px-3 py-2 text-left" colSpan={2}></td>*/}
                                {/*    {*/}
                                {/*      metalList.map( (values:string,i:number) => (*/}
                                {/*        <td className="px-3 py-2 text-right" >*/}
                                {/*          <span className="font-semibold">{fullTotalPool(i)}</span>*/}
                                {/*        </td>*/}
                                {/*      ))*/}
                                {/*    } */}
                                {/*    </tr>*/}
                                {/*  </tbody>*/}
                                {/*</table>*/}
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                </div>
                {/*{!loading && <div className="my-3 flex">*/}

                {/*</div>}*/}

                <div className="py-3 text-right">
                    <Link to={'/barlist/daily'}>
                        <button type="submit"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                            Details
                        </button>
                    </Link>
                </div> </>)}

            </div>
        </Layout>
    );
};

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default DailyBarListInGrams;
