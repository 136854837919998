import Http from "../../library/http.library";
import { httpMethods } from "../../library/enum";
import { makeQuery } from "../../Common/util.helper";
import axios from "axios";

export const getReportSummary = async (tokens: any, filters: any) => {
  // console.log("getReportSummary // filters?.status", filters?.status);
  let status =
    filters?.status?.length > 0
      ? "&status=" +
        filters?.status?.map((item: any) => item?.status)?.join(",")
      : "";
  return await Http.setMethod(httpMethods.get)
    .setUrl(
      `/order-trade-summary/hp?page=${filters?.page}&pageSize=${
        filters?.pagesize
      }&fromDate=${filters?.fromDate}&toDate=${filters?.toDate}${
        filters?.partnerId ? "&partnerId=" + filters?.partnerId : ""
      }&metalCode=${filters?.metalCode}&itemCode=${filters?.itemCode}&vault=${
        filters?.vault
      }${
        filters?.isMetalCovered !== "All"
          ? filters?.isMetalCovered === "Covered"
            ? "&isMetalCovered=true"
            : "&isMetalCovered=false"
          : ""
      }&orderBy=${filters?.orderBy}&order=${
        filters?.order
      }${status}&isPaginated=false`
    )
    .setResponseType("application/json")
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getUsdINRReport = async (tokens: any, filters: any) => {
  // console.log("getReportSummary // filters?.status", filters?.status);
  let status =
  filters?.status?.length > 0
    ? "&status=" +
      filters?.status?.map((item: any) => item?.status)?.join(",")
    : "";
  return await Http.setMethod(httpMethods.get)
    .setUrl(
      `/order-trade-summary/hp-fx?fromDate=${filters?.fromDate}&toDate=${
        filters?.toDate
      }${
        filters?.partnerId ? "&partnerId=" + filters?.partnerId : ""
      }&itemCode=${filters?.itemCode}&vault=${filters?.vault}${
        filters?.isFxCovered !== "All"
          ? filters?.isFxCovered === "Covered"
            ? "&isFxCovered=true"
            : "&isFxCovered=false"
          : ""
      }&orderBy=${filters?.orderBy}&order=${
        filters?.order
      }${status}&isPaginated=false`
    )
    .setResponseType("application/json")
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const usdInrReportDownload = async (tokens: any, filters: any) => {
  let status =
  filters?.status?.length > 0
    ? "&status=" +
      filters?.status?.map((item: any) => item?.status)?.join(",")
    : "";
  return await Http.setMethod(httpMethods.get)
    .setResponseType("application/json")
    .setUrl(
      `/trade-summary-report-download/hp-fx?fromDate=${
        filters?.fromDate
      }&toDate=${filters?.toDate}${
        filters?.partnerId ? "&partnerId=" + filters?.partnerId : ""
      }${
        filters?.partnerName ? "&partnerName=" + filters?.partnerName : ""
      }&itemCode=${filters?.itemCode}&vault=${filters?.vault}${
        filters?.isFxCovered !== "All"
          ? filters?.isFxCovered === "Covered"
            ? "&isFxCovered=true"
            : "&isFxCovered=false"
          : ""
      }&orderBy=${filters?.orderBy}&order=${filters?.order}${status}`
    )
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const reportDownload = async (tokens: any, filters: any) => {
  let status =
  filters?.status?.length > 0
    ? "&status=" +
      filters?.status?.map((item: any) => item?.status)?.join(",")
    : "";
  return await Http.setMethod(httpMethods.get)
    .setResponseType("application/json")
    .setUrl(
      `/trade-summary-report-download/hp?page=${filters?.page}&pageSize=${
        filters?.pagesize
      }&fromDate=${filters?.fromDate}&toDate=${filters?.toDate}${
        filters?.partnerId ? "&partnerId=" + filters?.partnerId : ""
      }${
        filters?.partnerName ? "&partnerName=" + filters?.partnerName : ""
      }&metalCode=${filters?.metalCode}&itemCode=${filters?.itemCode}&vault=${
        filters?.vault
      }${
        filters?.isMetalCovered !== "All"
          ? filters?.isMetalCovered === "Covered"
            ? "&isMetalCovered=true"
            : "&isMetalCovered=false"
          : ""
      }&orderBy=${filters?.orderBy}&order=${filters?.order}${status}`
    )
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const setIsMetalCovered = async (
  tokens: any,
  coveredRate: any,
  items: any
) => {
  return await Http.setMethod(httpMethods.patch)
    .setUrl(`/order-trade-summary-metal-cover/hp`)
    .setData({
      orderItemId: items,
      avgMetalRate: coveredRate,
    })
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const setIsFxCovered = async (
  tokens: any,
  coveredRate: any,
  items: any
) => {
  return await Http.setMethod(httpMethods.patch)
    .setUrl(`/order-trade-summary-fx-cover/hp`)
    .setData({
      orderItemId: items,
      avgFxRate: coveredRate,
    })
    .setToken(tokens.idToken.jwtToken)
    .request();
};

const DashboardReportServiceHP = {
  getReportSummary,
  reportDownload,
  setIsMetalCovered,
  setIsFxCovered,
  getUsdINRReport,
  usdInrReportDownload,
};

export default DashboardReportServiceHP;
