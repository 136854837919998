import React from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { DATA_TYPE } from "./utils";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import {  toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
interface ProtoType {
  handleChange: any;
  handleCreateRule: any;
  onClose: any;
  createData: any;
  validate: any;
  rules: any;
}

const AddNewMaster: React.FC<ProtoType> = ({
  createData,
  onClose,
  handleChange,
  handleCreateRule,
  validate,
  rules,
}) => {
  const simpleValidator = new SimpleReactValidator({
    locale: "en",
    validators: {},
  });
  const validationRef = React.useRef(simpleValidator);
  const validator = validationRef.current;

  const getValidatorError = (nameOfStateProp: any) => {
    const allErrorMessages = validator.getErrorMessages();
    return allErrorMessages[nameOfStateProp];
  };
  const handleSave = () => {
    if (validate || !validator.allValid()) {
      return toast.error("Please make sure all the inputs are valid.");
    }
    handleCreateRule();
  };
  return (
    
    <>
      <>
      <ToastContainer />
        <div className=" w-full bg-gray-800 bg-opacity-50 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-4xl">
            <div className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
              <div className="items-start justify-between p-3 border-b border-solid border-blue bg-gray-100 rounded-t">
                <h3 className="text-xl font-semibold">Add New Master Rule</h3>
              </div>

             
              <div className='p-4 pb-0'>
              <div className='flex items-center'>
              <div className="items-center flex-col flex p-2">
                    <label className="text-base font-medium text-gray-800 pr-20">
                      Rule Ref
                    </label>
                    <label
                      className="shadow-sm font-medium text-md border p-2 border-grey-lighter px-3 rounded-md w-full text-gray-700"
                      for="grid-state"
                    >
                      {rules.length + 1 ?? ""}
                    </label>
                  </div>
                  <div className="items-center justify-center flex-col flex-1 p-2">
                    <label className="text-base font-medium text-gray-800 pr-20">
                      <span className='mr-2'>Description</span>
                      {validator.message(
                        "description",
                        createData?.description,
                        "required"
                      )}
                      <span className="text-red-500 text-sm"> - {getValidatorError("description")}</span>
                    </label>

                    <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-black border border-grey-lighter rounded py-2 px-4"
                      id="grid-city"
                      name="description"
                      type="text"
                      onChange={(e: any) => {
                        handleChange(e, DATA_TYPE.NORMAL);
                      }}
                      value={createData?.description ?? ""}
                    />
                   
                  </div>

              </div>

              <div className='flex items-center mb-3'>
              <div className="items-center justify-center flex-col p-2">
                    <label className="text-base font-medium text-gray-800 pr-20">
                      Type
                    </label>
                    <select
                      className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-black border-grey-lighter rounded py-2 px-4"
                      id="grid-state"
                      name="type"
                      onChange={(e: any) => {
                        handleChange(e, DATA_TYPE.NORMAL);
                      }}
                      value={createData?.type ?? ""}
                    >
                      <option value="Sell">Add</option>
                      <option value="Buyback">Buyback</option>
                    </select>
                  </div>

                  <div className="items-center justify-center flex-col p-2">
                    <label className="text-base font-medium text-gray-800 pr-20">
                      Metal
                    </label>
                    <select
                      className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-black border-grey-lighter rounded py-2 px-4"
                      id="grid-state"
                      name="metal"
                      onChange={(e: any) => {
                        handleChange(e, DATA_TYPE.NORMAL);
                      }}
                      value={createData?.metal ?? ""}
                    >
                      <option value="Au">Au</option>
                      <option value="Ag">Ag</option>
                      <option value="Pd">Pd</option>
                      <option value="Pt">Pt</option>
                    </select>
                  </div>
                  <div className="items-center justify-center flex-col p-2">
                    <label className="text-base font-medium text-gray-800 pr-20">
                      Start Date
                    </label>
                    <Datetime
                      value={moment(createData?.Effect_From_Date)}
                      timeFormat={"HH:mm"}
                      dateFormat={"DD/MM/YYYY"}
                      className={`appearance-none rounded  block w-full px-2
                              py-2 border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm
                           `}
                      onChange={(e: any) => {
                        handleChange(e, DATA_TYPE.START_DATE);
                      }}
                    />
                  </div>

                  <div className="items-center justify-center flex-col p-2">
                    <label className="text-base font-medium text-gray-800 pr-20">
                      End Date
                    </label>
                    <Datetime
                      value={moment(createData?.Effect_To_Date)}
                      timeFormat={"HH:mm"}
                      dateFormat={"DD/MM/YYYY"}
                      className={`appearance-none rounded  block w-full px-2
                              py-2 border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm
                           `}
                      onChange={(e: any) => {
                        handleChange(e, DATA_TYPE.END_DATE);
                      }}
                    />
                  </div>

                  <div className="items-center justify-center flex-col p-2">
                    <label className="text-base font-medium text-gray-800 pr-20">
                      Source
                    </label>
                    <select
                      className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-black border-grey-lighter rounded py-2 px-4"
                      id="grid-state"
                      name="source"
                      onChange={(e: any) => {
                        handleChange(e, DATA_TYPE.NORMAL);
                      }}
                      value={createData?.source ?? ""}
                    >
                      <option value="1">EBL</option>
                      <option value="2">BAJFINSERV</option>
                    </select>
                  </div>
                
                </div>        
                        
                <div className='flex items-center'>
                <div className="items-center justify-center flex flex-col p-2">
                    <label className="text-base font-medium text-gray-800 pr-20">
                      Customer Group
                    </label>
                    <label
                      className="shadow-sm font-medium border h-10 p-2 border-grey-lighter px-3 rounded-md w-full text-gray-700"
                      for="grid-state"
                    ></label>
                    {/* <select
                      className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-black border-grey-lighter rounded py-2 px-4"
                      id="grid-state"
                      name="Customer_Group"
                      onChange={(e: any) => {
                        handleChange(e, DATA_TYPE.NORMAL);
                      }}
                      value={createData?.Customer_Group ?? ""}
                    >
                      <option value="g1">Gr 1</option>
                      <option value="g2">Gr 2</option>
                      <option value="g3">Gr 3</option>
                      <option value="g4">Gr 4</option>
                    </select> */}
                  </div>

                  <div className="items-center justify-center flex-col flex p-2">
                    <label className="text-base font-medium text-gray-800 pr-20">
                      Customer ID
                    </label>
                    <label
                      className="shadow-sm font-medium border h-10 p-2 border-grey-lighter px-3 rounded-md w-full text-gray-700"
                      for="grid-state"
                    ></label>
                    {/* <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-black border border-grey-lighter rounded py-2 px-4"
                      id="grid-city"
                      name="customer_Id"
                      type="text"
                      onChange={(e: any) => {
                        handleChange(e, DATA_TYPE.NORMAL);
                      }}
                      value={createData?.customer_Id ?? ""}
                    /> */}
                      <div className="text-red-500">
                      {/* {validator.message(
                        "customer_Id",
                        createData?.customer_Id,
                        "required"
                      )} */}
                      <span>{getValidatorError("customer_Id")}</span>
                    </div>
                  </div>

                  <div className="items-center justify-center flex-col p-2">
                    <label className="text-base flex w-full items-center justify-between font-medium text-gray-800 pr-20">
                      <span className='flex mr-2'>Priority</span>  {validator.message(
                      "priority",
                      createData?.priority,
                      "required|integer"
                    )}
                    <span className="text-red-600 flex text-sm"> - {getValidatorError("priority")}</span>
                    </label>
                    <input
                      className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-black border border-grey-lighter rounded py-2 px-4"
                      id="grid-city"
                      name="priority"
                      type="number"
                      min="0"
                      onChange={(e: any) => {
                        handleChange(e, "normal");
                      }}
                      value={createData?.priority ?? ""}
                    />
                      <div className="text-red-500">
                   
                    </div>
                  </div>
                </div>

              <div className="flex justify-items-center">

                <div className="relative flex-auto mt-6">
              
                
                 
                  
                
                 
                  
                  <div className="flex items-center justify-end p-4 pr-0 border-t border-solid border-blueGray-200 rounded-b">
                  <div>
                      <button
                        id="modal"
                        className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                        type="button"
                        onClick={onClose}
                      >
                        Close
                      </button>
                    </div>
                    <div>
                      <button
                        className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                        type="button"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                    </div>

                    
                  </div>
                  {validate && (
                    <div className="mb-4">
                      <span className="text-xs font-semibold bg-red-50 border border-red-500 text-red-500 mx-7 px-4 py-2 ">
                        From date must be less than To Date
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </>
    </>
  );
};

export default AddNewMaster;