import Http from "../../library/http.library";
import {httpMethods} from "../../library/enum";
import {makeQuery} from "../../Common/util.helper";


export const getItemMasterData = async (tokens: any,currentPage:any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/item-master?isPaginated=true&page=${currentPage}`).setToken(tokens.idToken.jwtToken).request();
};

export const filterItemMasterData = async (data:any,currentPage:any,tokens:any) => {
    var query = {
        isPaginated: true,
        ...(data.itemCode ? {itemCode: data.itemCode} : {}),
        ...(data.itemName ? {itemName: data.itemName} : {}),
        ...(data.itemGroup ? {itemGroup: data.itemGroup} : {}),
        ...(data.metalCode ? {metalCode: data.metalCode} : {}),
        ...(data.isStockItem ? {isStockItem: JSON.parse(data.isStockItem)} : {}),
        ...(data.status ? {status: data.status} : {}),
        page: currentPage,
    }
    const params = makeQuery(query);
    return await Http.setMethod(httpMethods.get).setUrl(`/item-master?${params}`).setToken(tokens.idToken.jwtToken).request();

};


const ItemMasterService = {
    getItemMasterData,
    filterItemMasterData
};

export default ItemMasterService;
