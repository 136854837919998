import { makeQuery } from "../../Common/util.helper";
import Http from "../../library/http.library";
import { httpMethods } from "../../library/enum";
import axios from "axios";

const downloadBulkSample = async (tokens: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/order/bulk-upload/download-sample`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

// const uploadData = async (tokens: any, data: any) => {
//   const params = makeQuery(data);
//   console.log('data',data)
//   return await Http.setMethod(httpMethods.post)
//     .setUrl(`/order/bulk-upload`)
//     .setData(data)
//     .setToken(tokens.idToken.jwtToken)
//     .request();
// };

export const uploadData = async (tokens: any, data: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/corporate-gift/bulk-upload`;
    try {
      let result = await axios.post(apiUrl, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          Authorization: `Bearer ${tokens.idToken.jwtToken}`,
        },
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Get all bulk data
 * @param query
 * @param page
 */
const getBulkData = async (query: any, page: number, tokens: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/order/getAllbulkUserDetails?page=${page}${query}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};
const lockUnlockBar = async (tokens: any, id: number, data: any) => {
  return Http.setMethod(httpMethods.patch)
    .setUrl(`/bar-summary/${id}`)
    .setData(data)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

const getBulkUsersData = async (id: string, tokens: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/order/getParticularUserDetail/${id}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

const getB2BCustomers = async (tokens: any, partnerId?: any) => {
  let url = "/getb2b/customers";
  if (partnerId[0]) {
    url += `?partnerId=${partnerId}`;
  }
  return await Http.setMethod(httpMethods.get)
    .setUrl(url)
    .setToken(tokens.idToken.jwtToken)
    .request();
};
const getAllGiftsList = async (query: any, page: number, tokens: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(
      `/order/getAllCorporateUserDetails?isPaginated=true&page=${page}${query}`
    )
    .setToken(tokens.idToken.jwtToken)
    .request();
};
const viewGift = async (tokens: any, corId?: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/order/getParticularCorporateUserDetail/${corId}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

const downloadCorporateBulkSample = async (tokens: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/order/corporate-gift/bulk-upload/download-sample`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

const GiftService = {
  getB2BCustomers,
  getAllGiftsList,
  viewGift,
  uploadData,
  downloadCorporateBulkSample,
};

export default GiftService;
