import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import ListOfCustomers from "../Customer/Components/ListOfCustomers";
import GiftList from "./GiftList";
import GiftView from "./GiftView";
// import ListOfCustomers from "../Customer/Components/ListOfCustomers";

const CorporateGiftRoutes: React.FC = () => {
  return (
    <Fragment>
      {" "}
      <Route path="/get-corporate-gifts" exact component={GiftList} />
      <Route path="/gift-view/:id" exact component={GiftView} />
    </Fragment>
  );
};

export default CorporateGiftRoutes;
