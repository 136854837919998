import React from "react";

interface PropsType {
    value: any,
    id: string,
    label: string
}

const DataInput: React.FC<PropsType> = ({
    value,
    id,
    label
   }) => {

    return (
        <>
            <label htmlFor={id} className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            <input
                type="text"
                name={id}
                id={id}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 gray-200 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                disabled
                value={value}
            />
        </>
    )
}

export default DataInput;
