import React, {useEffect, useState} from "react";
import Layout from "../Layout/Layout";
import {ToastContainer, toast} from "react-toastify";
import Loader from "../../Components-ui/Loader";
import {Pagination} from "../../Components-ui/Pagination";
import Modal from "../../Components-ui/Modal/Modal";
import TaxCategoryLookUpService from "./TaxCategoryLookUpService";
import {useAuthStore} from "../../Store/AuthStore";
import SimpleReactValidator from "simple-react-validator";
import CountryService from "../Country/Services/country.service";
import TaxService from "../Tax/Services/tax.service"
import HasAnyAccess from "../../Components-ui/hasAnyAccess";

const TaxCategoryLookUp: React.FC = () => {
    const { logout, permissions, userData , tokens } = useAuthStore();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [meta, setMetaData] = useState<any>({});
    const [Loading, setLoading] = useState<any>(true);
    const [taxCategoryLookUpList, setTaxCategoryLookUpList] = useState<any>([]);
    const [taxCategoryList, setTaxCategoryList] = useState<any>([]);
    const [show, setModalStatus] = useState<any>(false);
    const [updateStatus, setUpdateStatus] = useState<any>('');
    const [status, setStatus] = useState<any>('ADD');
    const [children, setChildren] = useState<any>();
    const [Countries, setCountries] = useState<any>([]);
    const [Taxes, setTaxes] = useState<any>([]);
    const [selectedRecord, setSelectedRecord] = useState<any>({
        quoteType: '',
        itemType: '',
        isSubitem: '',
        isGstReg: '',
        ignoreState: '',
        pos: "",
        state: "",
        taxCategory: "",
        countryCode:"",
        taxType:""
    });
    const [filters, setFilters] = useState<any>({
        quoteType: "",
        itemType: "",
        isSubitem: '',
        isGstReg: '',
        ignoreState: '',
        pos: "",
        state: "",
        taxCategory: "",
        countryCode:"",
        taxType:"",
    });
    const [validatorStatus, setValidator] = useState<boolean>(false);

    const simpleValidator = new SimpleReactValidator({
        locale: "en",
        validators: {},
    });
    const validationRef = React.useRef(simpleValidator);
    const validator = validationRef.current;

    useEffect(() => {
        getTaxCategories();
        getAllCountries();
        getAllTaxes();
        loadData(0);
    }, []);

    useEffect(() => {
        childrenElements();
    }, [selectedRecord, status]);

    useEffect(() => {
        if (selectedRecord && 'STATUS' === updateStatus) {
            updateRecord();
        }
    }, [updateStatus, status, selectedRecord]);

    useEffect(() => {
        childrenElements();
    }, [validatorStatus]);

    /**
     * Get all countries
     */
     const getAllCountries = async () => {
        let data: any = await CountryService.getAllCountries(0, tokens, '', false);
        if (data.status === 200 && data.data.data) {
            setCountries(data.data.data);
        }
    }

    /**
     * Get all tax categories
     * @param page
     * @param isReset
     */
    const getAllTaxes = async () => {
        setLoading(true);

        let data: any = await TaxService.getAllTaxes(0, tokens, "", false);
        if (data.status === 200 && data.data.data) {
            setTaxes(data ? data.data.data : []);
        }

        setLoading(false)

    };

    const loadData = async (page:number) => {
        setLoading(true)
        await TaxCategoryLookUpService.getTaxCategoryLookUpData(tokens,page).then((response: any) => {
            setTaxCategoryLookUpList(response.data.data.result);
            setMetaData({
                current: response.data.meta.page,
                pages: response.data.meta.totalPages,
            });
            setCurrentPage(page);
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            toast.error("Something Went Wrong!");
        });
    };

    const getTaxCategories = async () => {
        await TaxCategoryLookUpService.getAllTaxCategories(tokens).then((response: any) => {
            setTaxCategoryList(response.data.data.result);
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            toast.error("Something Went Wrong!");
        });
    };

    const childrenElements = () => {
        setChildren(<div className="w-full">
            <h1 className="font-bold">
                {('ADD' === status) ? 'Add New Record' : 'Edit Record'}
            </h1>
            <form className="bg-white rounded pt-6 mb-4">
                <div className="flex-1 p-2">
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="currencyCode">
                                Quote Type
                            </label>
                            <select
                                className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                                id="from" name="quote_type" onChange={event => setSelectedRecord({
                                ...selectedRecord,
                                quoteType: event.target.value
                            })} value={selectedRecord.quoteType || ''}>
                                <option value="">Select a Quote Type</option>
                                <option value="BUYBACK">By Back</option>
                                <option value="SELL">Sell</option>
                            </select>
                            {validator.message(
                                "quote_type",
                                selectedRecord.quoteType,
                                "required"
                            )}
                            {
                                validatorStatus ? (<span
                                    className="text-xs text-red-700">{getValidatorError("quote_type")}</span>) : ''
                            }
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="currencyCode">
                                Item Type
                            </label>
                            <select
                                className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                                id="from" name="item_type" onChange={event => setSelectedRecord({
                                ...selectedRecord,
                                itemType: event.target.value
                            })} value={selectedRecord.itemType}>
                                <option value="">Select a Item Type</option>
                                <option value="GOODS">Goods</option>
                                <option value="SERVICE">Service</option>
                            </select>
                            {validator.message(
                                "item_type",
                                selectedRecord.itemType,
                                "required"
                            )}
                            {
                                validatorStatus ? (<span className="text-xs text-red-700"
                                                         id="currencyCode">{getValidatorError("item_type")}</span>) : ''
                            }
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="currencyCode">
                                Quotation sub item
                            </label>
                            <select
                                className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                                id="from" name="is_subitem" onChange={event => setSelectedRecord({
                                ...selectedRecord,
                                isSubitem: event.target.value
                            })} value={selectedRecord.isSubitem}>
                                <option value="">Select a Type</option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </select>
                            {validator.message(
                                "is_subitem",
                                selectedRecord.isSubitem,
                                "required"
                            )}
                            {
                                validatorStatus ? (<span className="text-xs text-red-700"
                                                         id="currencyCode">{getValidatorError("is_subitem")}</span>) : ''
                            }
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="currencyCode">
                                GST Registered
                            </label>
                            <select
                                className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                                id="from" name="is_gst_reg" onChange={event => setSelectedRecord({
                                ...selectedRecord,
                                isGstReg: event.target.value
                            })} value={selectedRecord.isGstReg}>
                                <option value="">Select a Type</option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </select>
                            {validator.message(
                                "is_gst_reg",
                                selectedRecord.isGstReg,
                                "required"
                            )}
                            {
                                validatorStatus ? (<span className="text-xs text-red-700"
                                                         id="currencyCode">{getValidatorError("is_gst_reg")}</span>) : ''
                            }
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="currencyCode">
                                Ignore State
                            </label>
                            <select
                                className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                                id="from" name="ignore_state" onChange={event => setSelectedRecord({
                                ...selectedRecord,
                                ignoreState: event.target.value
                            })} value={selectedRecord.ignoreState}>
                                <option value="">Select a Type</option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </select>
                            {validator.message(
                                "ignore_state",
                                selectedRecord.ignoreState,
                                "required"
                            )}
                            {
                                validatorStatus ? (<span className="text-xs text-red-700"
                                                         id="currencyCode">{getValidatorError("ignore_state")}</span>) : ''
                            }
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="currencyCode">
                                POS
                            </label>
                            <select
                                className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                                id="from" name="pos" onChange={event => setSelectedRecord({
                                ...selectedRecord,
                                pos: event.target.value
                            })} value={selectedRecord.pos || ''}>
                                <option value="">Select a Type</option>
                                <option value="VAULT">Vault</option>
                                <option value="PHYSICAL">Physical</option>
                            </select>
                            {validator.message(
                                "pos",
                                selectedRecord.pos,
                                "required"
                            )}
                            {
                                validatorStatus ? (
                                    <span className="text-xs text-red-700">{getValidatorError("pos")}</span>) : ''
                            }
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="currencyCode">
                                State
                            </label>
                            <select
                                className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                                id="from" name="state" onChange={event => setSelectedRecord({
                                ...selectedRecord,
                                state: event.target.value
                            })} value={selectedRecord.state || ''}>
                                <option value="">Select a state</option>
                                <option value="INTRA">Intra</option>
                                <option value="INTER">Inter</option>
                            </select>
                            {validator.message(
                                "state",
                                selectedRecord.state,
                                "required"
                            )}
                            {
                                validatorStatus ? (<span className="text-xs text-red-700"
                                                         id="currencyCode">{getValidatorError("state")}</span>) : ''
                            }
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="currencyCode">
                                Tax Category
                            </label>
                            <select
                                className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                                id="from" name="tax_category" onChange={event => setSelectedRecord({
                                ...selectedRecord,
                                taxCategory: event.target.value
                            })} value={selectedRecord.taxCategory || ''}>
                                <option value="">Select a Tax Category</option>
                                <option value="MAIN_ITEM_TAX">Main Item Tax</option>
                                {taxCategoryList.map((tax: any, index: number) => {
                                    return (
                                        <option value={tax.categoryCode.toUpperCase()}>{tax.name}</option>
                                    )
                                })
                                };
                            </select>
                            {validator.message(
                                "tax_category",
                                selectedRecord.taxCategory,
                                "required"
                            )}
                            {
                                validatorStatus ? (<span className="text-xs text-red-700"
                                                         id="currencyCode">{getValidatorError("tax_category")}</span>) : ''
                            }
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="currencyCode">
                                Country Code
                            </label>
                            <select
                                className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                                id="from" name="country_code" onChange={event => setSelectedRecord({
                                    ...selectedRecord,
                                    countryCode: event.target.value
                                })} value={selectedRecord.countryCode || ''}>
                                <option value="">Select a Country Code</option>
                                {Countries?.map((code: any, i: number) => (
                                    <option value={code.countryCode}>{code.countryCode}</option>
                                ))
                                }
                            </select>
                            {validator.message(
                                "country_code",
                                selectedRecord.countryCode,
                                "required"
                            )}
                            {
                                validatorStatus ? (<span className="text-xs text-red-700"
                                    id="currencyCode">{getValidatorError("country_code")}</span>) : ''
                            }
                        </div>

                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="currencyCode">
                                Tax Type
                            </label>
                            <select
                                className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                                id="from" name="tax_type" onChange={event => setSelectedRecord({
                                    ...selectedRecord,
                                    taxType: event.target.value
                                })} value={selectedRecord.taxType || ''}>
                                <option value="">Select a Tax Type</option>
                                {Taxes?.map((tax: any, i: number) => (
                                    <option value={tax.taxType}>{tax.taxType}</option>
                                ))
                                }
                            </select>
                            {validator.message(
                                "tax_type",
                                selectedRecord.taxType,
                                "required"
                            )}
                            {
                                validatorStatus ? (<span className="text-xs text-red-700"
                                    id="currencyCode">{getValidatorError("tax_type")}</span>) : ''
                            }
                        </div>

                        <div
                            className="pb-3 w-full px-3 mb-6 md:mb-0 flex items-center justify-between flex-row-reverse">
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="button" onClick={() => {
                                if ('ADD' === status) {
                                    addNew()
                                } else {
                                    updateRecord()
                                }
                            }}>
                                {('ADD' === status) ? 'SAVE' : 'UPDATE'}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>);
    }

    const addNew = async () => {
        setValidator(true);
        var data =
            {
                quoteType: selectedRecord.quoteType || '',
                itemType: selectedRecord.itemType || '',
                isMaintainStock: !('VAULT' === selectedRecord.pos),
                isSubitem: JSON.parse(selectedRecord.isSubitem),
                isGstReg: JSON.parse(selectedRecord.isGstReg),
                ignoreState: JSON.parse(selectedRecord.ignoreState),
                pos: selectedRecord.pos || '',
                state: selectedRecord.state || '',
                taxCategory: selectedRecord.taxCategory || '',
                countryCode: selectedRecord.countryCode || '',
                taxType: selectedRecord.taxType || ''
            }
        if (validator.allValid()) {
            await TaxCategoryLookUpService.createTaxCategoryLookUpData(data, tokens).then(() => {
                loadData(currentPage);
                toast.success("Record Added Successfully");
                setModalStatus(false);
            }).catch(err => {
                toast.error(err.response.data.errors[0].title);
            });
            setValidator(false);

        }

    }

    const updateRecord = async () => {
        setValidator(true);
        selectedRecord.isMaintainStock = !('VAULT' === selectedRecord.pos);
        if (validator.allValid()) {
            await TaxCategoryLookUpService.updateTaxCategoryLookUpData(selectedRecord, tokens).then((response: any) => {
                setLoading(false);
                setModalStatus(false);
                setUpdateStatus('');
                loadData(currentPage);
                toast.success("Updated Successfully");
            }).catch(err => {
                setLoading(false)
                toast.error("Something Went Wrong!");
                loadData(currentPage);
            });
            setValidator(false);

        }
    }

    const editModalElements = (data: any) => {
        setUpdateStatus('EDIT');
        setStatus('EDIT');
        childrenElements();
        setSelectedRecord(data);
        setModalStatus(true);
    }

    const addModalElements = () => {
        setValidator(false)
        setSelectedRecord({
            quoteType: '',
            itemType: '',
            isSubitem: '',
            isGstReg: '',
            ignoreState: '',
            pos: "",
            state: "",
            taxCategory: "",
            countryCode: "",
            taxType: ""
        });
        setStatus('ADD');
        childrenElements();
        setModalStatus(true);
    }

    const filter = async (page: any) => {
        console.log("FILTERERERRS ",filters);
        
        setLoading(true);
        await TaxCategoryLookUpService.filterTaxCategoryLookUpData(tokens, filters, page).then((response: any) => {
            setTaxCategoryLookUpList(response.data.data.result);
            setMetaData({
                current: response.data.meta.page,
                pages: response.data.meta.totalPages,
            });
            setCurrentPage(response.data.meta.page);
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            toast.error(err);
        });
    }

    const ResetForm = async () => {
        setCurrentPage(0);
        setFilters({
            quoteType: "",
            itemType: "",
            isSubitem: '',
            isGstReg: '',
            ignoreState: '',
            pos: "",
            state: "",
            taxCategory: "",
            countryCode: "",
            taxType: ""
        });
        loadData(0);
    }

    const getValidatorError = (nameOfStateProp: any) => {
        const allErrorMessages = validator.getErrorMessages();
        return allErrorMessages[nameOfStateProp];
    };

    const hideModal = () => {
        setModalStatus(false);
    };

    const paginationFilter = async (page: number) => {
        setCurrentPage(page);
        filter(page);
    }

    return (
        <Layout type={"TaxModule"}>
            <ToastContainer/>
            <div className="flex justify-between items-center mb-5">
                <h1 className="text-2xl font-bold tracking-tight">
                    {"Tax Category Look Up"}
                </h1>
                {
                    HasAnyAccess(['tax.category.look.up.master.create']) && (
                        <button
                            className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
                            type="button"
                            onClick={() => {
                                addModalElements()
                            }}
                        >
                            + Create New
                        </button>
                    )
                }
            </div>
            <div className="border bg-white rounded border-gray-200 shadow">
                <div className="flex-1 p-2">
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="status">
                                Quote Type
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="status" onChange={event => setFilters({
                                    ...filters,
                                    quoteType: event.target.value
                                })} value={filters.quoteType}>
                                    <option value="">Select a Quote Type</option>
                                    <option value="BUYBACK">By Back</option>
                                    <option value="SELL">Sell</option>
                                </select>
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="status">
                                Item Type
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="status" onChange={event => setFilters({
                                    ...filters,
                                    itemType: event.target.value
                                })} value={filters.itemType}>
                                    <option value="">Select a Item Type</option>
                                    <option value="GOODS">Goods</option>
                                    <option value="SERVICE">Service</option>
                                </select>
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="status">
                                POS
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="status" onChange={event => setFilters({
                                    ...filters,
                                    pos: event.target.value
                                })} value={filters.pos}>
                                    <option value="">Select a POS Type</option>
                                    <option value="VAULT">Vault</option>
                                    <option value="PHYSICAL">Physical</option>
                                </select>
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="status">
                                State
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="status" onChange={event => setFilters({
                                    ...filters,
                                    state: event.target.value
                                })} value={filters.state}>
                                    <option value="">Select a State</option>
                                    <option value="INTRA">Intra</option>
                                    <option value="INTER">Inter</option>
                                </select>
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="status">
                                Country Code
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="status" onChange={event => setFilters({
                                    ...filters,
                                    countryCode: event.target.value
                                })} value={filters.countryCode}>
                                    <option value="">Select a Country Code</option>
                                    {Countries?.map((code:any,i:number)=>(
                                        <option value={code.countryCode}>{code.countryCode}</option>
                                    ))
                                    }
                                    
                                </select>
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="status">
                                Tax Type
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="status" onChange={event => setFilters({
                                    ...filters,
                                    taxType: event.target.value
                                })} value={filters.taxType}>
                                    <option value="">Select a Tax Type</option>
                                    {Taxes?.map((tax:any,i:number)=>(
                                        <option value={tax.taxType}>{tax.taxType}</option>
                                    ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-type">
                                Tax Category
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="user-type" onChange={event => setFilters({
                                    ...filters,
                                    taxCategory: event.target.value
                                })} value={filters.taxCategory}>
                                    <option value="">Select a category</option>
                                    <option value="ANY">ANY</option>
                                    <option value="INTRA-STATE">INTRA-STATE</option>
                                    <option value="INTER-STATE">INTER-STATE</option>
                                    <option value="MAIN_ITEM_TAX">MAIN_ITEM_TAX</option>
                                </select>
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-type">
                                GST Registered
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="user-type" onChange={event => setFilters({
                                    ...filters,
                                    isGstReg: event.target.value
                                })} value={filters.isGstReg}>
                                    <option value="">Select a status</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </div>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="user-type">
                                Ignore Status
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="user-type" onChange={event => setFilters({
                                    ...filters,
                                    ignoreState: event.target.value
                                })} value={filters.ignoreState}>
                                    <option value="">Select a status</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </div>
                        </div>
                        <div className="mt-3 w-full md:w-1/4 px-3 mb-6 md:mb-0 flex items-center justify-items-center">
                            <div className="">
                                <button
                                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                                    type="button" onClick={() => {
                                    filter(0)
                                }}>
                                    Filter
                                </button>
                                <button
                                    className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                                    type="button" onClick={() => ResetForm()}>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border bg-white rounded border-gray-200 shadow m-2">
                    {Loading ? (<Loader/>) : (
                        <div>
                            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                                <thead>
                                <tr className="text-left border-b border-gray-300">
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Quote Type</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Item Type</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Quotation sub item</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">GST Registered</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Ignore state</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">POS</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">State</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Tax Category</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Country Code</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Tax Type</th>
                                    {
                                        HasAnyAccess(['tax.category.look.up.master.edit']) && (
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Actions</th>
                                        )}
                                </tr>
                                </thead>
                                <tbody>
                                {(0 !== taxCategoryLookUpList.length) ?
                                    taxCategoryLookUpList.map((metal: any, index: number) => {
                                        return (
                                            <tr key={index}
                                                className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                <td className="py-3 px-3 text-left relative">
                                                    {metal.quoteType}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {metal.itemType}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    <span
                                                        className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${metal.isSubitem ? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>
                                                       {metal.isSubitem ? 'Yes' : 'No'}
                                                    </span>
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    <span
                                                        className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${metal.isGstReg ? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>
                                                       {metal.isGstReg ? 'Yes' : 'No'}
                                                    </span>
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                   <span
                                                       className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${metal.ignoreState ? "text-green-600 bg-green-200" : "text-black-600 bg-gray-400"}`}>
                                                       {metal.ignoreState ? 'Yes' : 'No'}
                                                    </span>
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {metal.pos}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {metal.state}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {metal.taxCategory}
                                                </td>
                                                <td className="py-3 px-3 text-left relative text-center">
                                                    {metal.countryCode}
                                                </td>
                                                <td className="py-3 px-3 text-left relative text-center">
                                                    {metal.taxType}
                                                </td>
                                                    {
                                                        HasAnyAccess(['tax.category.look.up.master.edit']) && (
                                                            <td className="py-3 px-3 text-left relative">

                                                            <button
                                                                className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                                type="button"
                                                                onClick={() => {
                                                                    editModalElements(metal)
                                                                }}
                                                            >
                                                                Edit
                                                            </button>

                                                </td>)
                                                    }
                                            </tr>
                                        )
                                    })
                                    : (<tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                        <td colSpan={12} className="text-center py-3 px-3 text-left relative">No data to
                                            display
                                        </td>
                                    </tr>)
                                }
                                </tbody>
                            </table>
                        </div>
                    )}
                    <Pagination
                        meta={meta}
                        handlePageChange={(number: number) => {
                            paginationFilter(number)
                        }}
                    />
                </div>
                <Modal size={'large'} show={show} handleClose={hideModal} children={children}/>
            </div>
        </Layout>
    );
};

export default TaxCategoryLookUp;
