import Layout from "../Layout/Layout";
import React, {useEffect, useState} from "react";
import Datetime from "react-datetime";
import moment from "moment";
import {CURRENCY, MAIN_RULE_TYPES} from "../TradeExecution/Utils/enum";
import {ORDER_BY, STATUS} from "../Order/Utils/enum";
import RefundService from "../ManualRefund/ManualRefundService"
import Loader from "../../Components-ui/Loader"
import {Pagination} from "../../Components-ui/Pagination";
import {useAuthStore} from "../../Store/AuthStore";
import {toast, ToastContainer} from "react-toastify";
import Modal from "../../Components-ui/Modal/Modal";
import HasAnyAccess from "../../Components-ui/hasAnyAccess";

interface PropsType {
}

const ManualRefund: React.FC<PropsType> = () => {

    const [Filter, setFilter] = useState<any>({
        ebOrderRef: "",
        type: "",
        accountId: "",
        customerId: "",
        currency: "",
        fromDate: "",
        toDate: "",
        orderBy: "desc",

    });
    const [Loading, setLoading] = useState<any>(false);
    const [Orders, setOrders] = useState<any>([]);
    const [selectedRecords, setSelectedRecords] = useState<any>([]);
    const [MetaData, setMetaData] = useState<any>({});
    const [CurrentPage, setCurrentPage] = useState<number>(0);
    const [show, setModalStatus] = useState<any>(false);
    const [children, setChildren] = useState<any>();
    const [itemList, setItemList] = useState<any>([]);
    useEffect((): any => {
        fetchData(CurrentPage, Filter);
    }, []);

    const { logout, permissions, userData , tokens } = useAuthStore();

    const Save = async (): Promise<void> => {
        if (0 !== selectedRecords.length) {
            var arr: any[] = []
            selectedRecords.map((data: any, index: number) => {
                arr.push(data.id)
            })

            return new Promise(async (resolve) => {
                var data = {
                    "ebOrderItemRef": arr
                }
                RefundService.postSelectRefundList(tokens, data).then((response: any) => {
                    setModalStatus(false);
                    setSelectedRecords([]);
                    toast.success('Refund has been added successfully');
                    fetchData(CurrentPage, Filter);
                    resolve();
                }).catch(err => {
                    toast.error(err.response.data.errors[0].title);
                    resolve();
                });
            })
        }
    }

    useEffect(() => {
        childrenElements();
    }, [show]);

    useEffect(() => {
        childrenElements();
    }, [itemList]);

    const childrenElements = () => {
        setChildren(<div className="w-full">
            <h1 className="font-bold">
                {'Select Items'}
            </h1>
            <form className="bg-white rounded pt-6 pb-4 mb-2">
                <div className="mb-4">
                    <table
                        className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                        <thead>
                        <tr className="text-left border-b border-gray-300">
                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10"></th>
                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">ID</th>
                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Metal Code</th>
                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Main Item Code</th>
                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Status</th>
                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Line Amount</th>
                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Requested Type</th>
                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Requested Value</th>
                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Quantity</th>
                            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">UOM</th>
                        </tr>
                        </thead>
                        <tbody>
                        {(0 !== itemList.length) ?
                            itemList.map((item: any, index: number) => {
                                return (
                                    <tr key={index}
                                        className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                        <td className="py-3 px-3 text-left relative">
                                            <input type="checkbox" value={item.selected} checked={item.selected}
                                                   onClick={() => {
                                                       changeStatus(item, index)
                                                   }}/>
                                        </td>
                                        <td className="py-3 px-3 text-left relative">
                                            {item.id}
                                        </td>
                                        <td className="py-3 px-3 text-left relative">
                                            {item.metalCode}
                                        </td>
                                        <td className="py-3 px-3 text-left relative">
                                            {item.mainItemCode}
                                        </td>
                                        <td className="py-3 px-3 text-left relative">
                                            {item.status}
                                        </td>
                                        <td className="py-3 px-3 text-right relative">
                                            {parseFloat(item.lineAmount).toFixed(2)}
                                        </td>
                                        <td className="py-3 px-3 text-left relative">
                                            {item.requestedType}
                                        </td>
                                        <td className="py-3 px-3 text-right relative">
                                            {item.requestedValue}
                                        </td>
                                        <td className="py-3 px-3 text-right relative">
                                            {item.quantity}
                                        </td>
                                        <td className="py-3 px-3 text-left relative">
                                            {item.uom}
                                        </td>
                                    </tr>
                                )
                            })
                            : (
                                <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                    <td colSpan={11}
                                        className="text-center py-3 px-3 text-left relative">No data
                                        to
                                        display
                                    </td>
                                </tr>)
                        }
                        </tbody>
                    </table>
                </div>
                <div className="flex items-center justify-between flex-row-reverse">
                    <button
                        className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                        type="button" onClick={() => {
                        Save()
                    }}
                    >
                        {'Refund'}
                    </button>
                </div>
            </form>
        </div>);
    }

    const fetchData = async (page: number, data: any): Promise<void> => {
        setLoading(true)
        return new Promise(async (resolve) => {
            RefundService.getRefundList(tokens, page, data).then((response: any) => {
                setOrders(response.data.data.result);
                setMetaData({
                    current: response.data.meta.page,
                    pages: response.data.meta.totalPages,
                });
                setCurrentPage(page);
                resolve();
                setLoading(false)
            }).catch(err => {
                toast.error(err.data.errors[0].title);
                resolve();
                setLoading(false)
            });
        })
    };


    const handleChangeFilter = (e: any) => {
        const {name, value} = e.target
        setFilter({
            ...Filter,
            [name]: value
        });
    }

    /**
     * Get orders according to the filtration
     * @param page
     * @param isReset
     */
    const handleSubmit = async (page: number, isReset: boolean = false) => {
        console.log('handleSubmit');
        setLoading(true)
        fetchData(page, Filter);
        // setLoading(false)
    }

    /**
     * Reset filters to initial state
     * @param e
     */
    const resetData = async (e: any) => {
        e.preventDefault()
        setFilter({
            ebOrderRef: "",
            type: "",
            accountId: "",
            customerId: "",
            currency: "",
            fromDate: "",
            toDate: "",
            status: "",
            orderBy: "desc",
        })
        setCurrentPage(0)
        fetchData(0, {})
    }

    const changeStatus = async (data: any, key: any) => {
        let newArr = [...itemList];
        newArr[key].selected = !(data.selected);
        setItemList(newArr)
        if (0 !== selectedRecords.length) {
            if (selectedRecords.some((item: any) => item.itemCode === data.itemCode)) {
                selectedRecords.forEach((element: any, index: number) => {
                    if (data.itemCode === element.itemCode) {
                        selectedRecords.splice(index, 1);
                    }
                    setSelectedRecords(selectedRecords);
                });
            } else {
                setSelectedRecords(selectedRecords.concat(data));
            }
        } else {
            setSelectedRecords(selectedRecords.concat(data));
        }
    }

    const hideModal = () => {
        setModalStatus(false);
    };

    return (
        <Layout type={"Refund"}>
            <ToastContainer/>
            <div className="flex justify-between items-center mb-5">
                <h1 className="text-2xl font-bold tracking-tight">
                    {"Manual Refund"}
                </h1>
            </div>
            <div
                className="w-full rounded border-gray-200 overflow-hidden shadow-lg dark:border-gray-700 bg-white">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Filter Refund Orders</h3>
                </div>
                <hr/>
                <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">

                    <div className="col-span-12 sm:col-span-4">
                        <label htmlFor="ebOrderRef" className="block text-sm font-medium text-gray-700">
                            Order Ref
                        </label>
                        <input
                            type="text"
                            name="ebOrderRef"
                            id="ebOrderRef"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e: any) => {
                                handleChangeFilter(e)
                            }}
                            value={Filter.ebOrderRef}
                        />
                    </div>

                    <div className="col-span-12 sm:col-span-4">
                        <label htmlFor="qType" className="block text-sm font-medium text-gray-700">
                            Type
                        </label>
                        <select
                            id="qType"
                            name="type"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e: any) => {
                                handleChangeFilter(e)
                            }}
                            value={Filter.type}
                        >
                            <option value="">All</option>
                            {
                                MAIN_RULE_TYPES.map((type: string, index: number) => {
                                    return (
                                        <option key={index} value={type}>{type}</option>
                                    )

                                })
                            }
                        </select>
                    </div>
                    <div className="col-span-12 sm:col-span-4">
                        <label htmlFor="accountId" className="block text-sm font-medium text-gray-700">
                            Account
                        </label>
                        <input
                            type="text"
                            name="accountId"
                            id="accountId"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e: any) => {
                                handleChangeFilter(e)
                            }}
                            value={Filter.accountId}
                        />
                    </div>
                    <div className="col-span-12 sm:col-span-4">
                        <label htmlFor="customerId" className="block text-sm font-medium text-gray-700">
                            Customer ID
                        </label>
                        <input
                            type="text"
                            name="customerId"
                            id="customerId"
                            min="0"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e: any) => {
                                handleChangeFilter(e)
                            }}
                            value={Filter.customerId}
                        />
                    </div>

                    <div className="col-span-12 sm:col-span-4">
                        <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
                            Currency
                        </label>
                        <select
                            id="currency"
                            name="currency"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e: any) => {
                                handleChangeFilter(e)
                            }}
                            value={Filter.currency}
                        >
                            <option value="">All</option>
                            {
                                CURRENCY.map((type: string, index: number) => {
                                    return (
                                        <option key={index} value={type}>{type}</option>
                                    )

                                })
                            }
                        </select>
                    </div>

                    {/*<div className="col-span-12 sm:col-span-4">*/}
                    {/*    <label*/}
                    {/*        htmlFor="qType"*/}
                    {/*        className="block text-sm font-medium text-gray-700"*/}
                    {/*    >*/}
                    {/*        Status*/}
                    {/*    </label>*/}
                    {/*    <select*/}
                    {/*        id="qType"*/}
                    {/*        name="status"*/}
                    {/*        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"*/}
                    {/*        onChange={(e: any) => {*/}
                    {/*            handleChangeFilter(e);*/}
                    {/*        }}*/}
                    {/*        value={Filter.status}*/}
                    {/*    >*/}
                    {/*        <option value="">All</option>*/}
                    {/*        <option value="CREATED">CREATED</option>*/}
                    {/*        <option value="PENDING">PENDING</option>*/}
                    {/*        <option value="PARTIAL_COMPLETED">PARTIAL_COMPLETED</option>*/}
                    {/*        <option value="INVOICE_UPLOAD_PENDING">INVOICE_UPLOAD_PENDING</option>*/}
                    {/*        <option value="COMPLETED">COMPLETED</option>*/}
                    {/*        <option value="CANCELLED">CANCELLED</option>*/}
                    {/*        <option value="PAYMENT_PENDING">PAYMENT_PENDING</option>*/}
                    {/*    </select>*/}
                    {/*</div>*/}
                    {/*<div className="col-span-12 sm:col-span-4">*/}
                    {/*    <label htmlFor="fromDate" className="block text-sm font-medium text-gray-700">*/}
                    {/*        From Date*/}
                    {/*    </label>*/}

                    {/*    <div>*/}
                    {/*        <Datetime*/}
                    {/*            timeFormat={""}*/}
                    {/*            dateFormat={"DD/MM/YYYY"}*/}
                    {/*            inputProps={{readOnly: true,placeholder: 'pick date', className: "focus:outline-none"}}*/}
                    {/*            className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4`}*/}
                    {/*            onChange={(event: any) => setFilter({*/}
                    {/*                ...Filter,*/}
                    {/*                fromDate: moment(new Date(event).toISOString()).format("MM/DD/YYYY"),*/}
                    {/*            })}*/}
                    {/*            value={moment(Filter.fromDate)}*/}
                    {/*            renderInput={(props) => {*/}
                    {/*                return <input {...props} value={(Filter.fromDate) ? (Filter.fromDate) : ''}/>*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className="col-span-12 sm:col-span-4">*/}
                    {/*    <label htmlFor="toDate" className="block text-sm font-medium text-gray-700">*/}
                    {/*        To Date*/}
                    {/*    </label>*/}

                    {/*    <div>*/}
                    {/*        <Datetime*/}
                    {/*            timeFormat={""}*/}
                    {/*            dateFormat={"DD/MM/YYYY"}*/}
                    {/*            inputProps={{readOnly: true,placeholder: 'pick date', className: "focus:outline-none"}}*/}
                    {/*            className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4`}*/}
                    {/*            onChange={(event: any) => setFilter({*/}
                    {/*                ...Filter,*/}
                    {/*                toDate: moment(new Date(event).toISOString()).format("MM/DD/YYYY"),*/}
                    {/*            })}*/}
                    {/*            value={moment(Filter.toDate)}*/}
                    {/*            renderInput={(props) => {*/}
                    {/*                return <input {...props} value={(Filter.toDate) ? (Filter.toDate) : ''}/>*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="col-span-12 sm:col-span-12 flex justify-end">

                        <button
                            className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                            type="button"
                            onClick={() => handleSubmit(0)}
                        >
                            Filter
                        </button>
                        <button
                            className="ml-2 text-white border border-red-300 rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                            type="button"
                            onClick={(e) => resetData(e)}
                        >
                            Reset
                        </button>
                    </div>
                    <div className="col-span-12 sm:col-span-12">
                        <hr/>
                    </div>

                    <div className="col-span-12 sm:col-span-12">
                        {
                            Loading ? (<Loader/>) : (
                                <div className="border bg-white rounded border-gray-200 shadow">
                                    <table
                                        className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                                        <thead>
                                        <tr className="text-left border-b border-gray-300">
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800">Order Ref</th>
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800">Quote Ref</th>
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800">Created At</th>
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800">Customer ID</th>
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800">Account Id</th>
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800">Quotation Total</th>
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800">Currency</th>
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800">Type</th>
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800">Status</th>
                                            {
                                                HasAnyAccess(['manual.refund.view']) && (
                                            <th className="sticky top-0 px-3 py-2 bg-gray-800">Actions</th>)}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {(0 !== Orders.length) ?
                                            Orders.map((order: any, index: number) => {
                                                return (
                                                    <tr key={index}
                                                        className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                        <td className="py-3 px-3 text-left relative">
                                                            {order.ebOrderRef}
                                                        </td>
                                                        <td className="py-3 px-3 text-left relative">
                                                            {order.ebQuoteRef}
                                                        </td>
                                                        <td className="py-3 px-3 text-left relative">
                                                            {moment(order.createdAt).format("DD-MM-YYYY HH:mm")}
                                                        </td>
                                                        <td className="py-3 px-3 text-left relative">
                                                            {order.customerId}
                                                        </td>
                                                        <td className="py-3 px-3 text-left relative">
                                                            {order.accountId}
                                                        </td>
                                                        <td className="py-3 px-3 text-right relative">
                                                            {parseFloat(order.quotationTotal).toFixed(2)}
                                                        </td>
                                                        <td className="py-3 px-3 text-left relative">
                                                            {order.currency}
                                                        </td>
                                                        <td className="py-3 px-3 text-left relative">
                                                            {order.type}
                                                        </td>
                                                        <td className="py-3 px-3 text-left relative">
                                                            {order?.status?.replaceAll('_',' ')}
                                                        </td>
                                                        {
                                                            HasAnyAccess(['manual.refund.view']) && (
                                                        <td className="py-3 px-3 text-left relative">

                                                            <button
                                                                className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                                type="button"
                                                                onClick={() => {
                                                                    console.log(order.items);
                                                                    setModalStatus(true);
                                                                    setItemList(order.orderItem)
                                                                    setSelectedRecords([]);
                                                                }}
                                                            >
                                                                View
                                                            </button>
                                                        </td>)}
                                                    </tr>
                                                )
                                            }) :
                                            (
                                                <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                                    <td colSpan={11}
                                                        className="text-center py-3 px-3 text-left relative">No data to
                                                        display
                                                    </td>
                                                </tr>)
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            )
                        }
                    </div>
                    <div className="col-span-12 sm:col-span-12">
                        {/* Pagination Bar */}
                        <Pagination
                            meta={MetaData}
                            handlePageChange={(number: number) => handleSubmit(number)}
                        />
                    </div>

                </div>
                <Modal size={'large'} show={show} handleClose={hideModal} children={children}/>
            </div>
        </Layout>
    );
};

export default ManualRefund;
