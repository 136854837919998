import {makeQuery} from "../../../Common/util.helper";
import Http from "../../../library/http.library";
import {httpMethods} from "../../../library/enum";

export const getPartnerSettlements = async (tokens: any, currentPage: any, data: any) => {
    var status = ''
    data?.settlementStatus?.map((value: any) => {
        status = status + '&status=' + value.status
    })

    var query = {
        isPaginated: true,
        pageSize: 10,
        ...(data.status ? {isDailySettlmentValid: data.status} : {}),
        ...(data.partner ? {partnerId: data.partner} : {}),
        // ...(data.fromDate ? {date: data.fromDate} : {}),
        ...(data.fromDate ? {fromDate: data.fromDate} : {}),
        ...(data.toDate ? {toDate: data.toDate} : {}),
    }
    const params = makeQuery(query) + status;
    return await Http.setMethod(httpMethods.get).setResponseType('application/json').setUrl(`/settlement/partner?page=${currentPage}${params}`).setToken(tokens.idToken.jwtToken).request();
};

export const getPartnerSettlementsDetails = async (tokens: any, currentPage: any, data: any, id: any) => {
    var status = ''
    data?.selectedStatus?.map((value: any) => {
        status = status + '&status=' + value.status
    })

    var query = {
        pageSize: 10,
        ...(data.fileUploaded ? {fileUploaded: data.fileUploaded} : {}),
        ...(data.fileLocation ? {fileLocation: data.fileLocation} : {}),
    }
    const params = makeQuery(query) + status;
    return await Http.setMethod(httpMethods.get).setResponseType('application/json').setUrl(`/settlement/partner/details/${id}?page=${currentPage}${params}`).setToken(tokens.idToken.jwtToken).request();

};

export const reportDownload = async (tokens: any, batch: any) => {
    return await Http.setMethod(httpMethods.get).setResponseType('blob').setUrl(`/settlement/partner/download/${batch}`).setToken(tokens.idToken.jwtToken).request();

};


const PartnerSettlementReportService = {
    getPartnerSettlements,
    getPartnerSettlementsDetails,
    reportDownload
};

export default PartnerSettlementReportService;
