import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";
import {
  BarList,
  InsertDailyBarList,
  UpdateMetalLockUnLock,
  GetAllReferences,
} from "./Metal.Service";
import ErrorToolTip from "./ErrorToolTip";
import { SetConfirmAlert } from "../Util/util.component";
import { ToastContainer, toast } from "react-toastify";
import { ExportToExcel } from "../Util/ExcelExport";
import { Pagination } from "../../Components-ui/Pagination";
import { jsPDF, jsPDFOptions } from "jspdf";
import autoTable from "jspdf-autotable";
import Datetime from "react-datetime";
import { Tag } from "../../Components-ui/Tag";
import { useAuthStore } from "../../Store/AuthStore";
import axios from "axios";
import Loader from "../../Components-ui/Loader";

const DailyBarList: React.FC = () => {
  const initFilters = () => {
    return FieldsList.reduce((o, c) => ({ ...o, [c]: "" }), {});
  };

  let history = useHistory();
  const [data, setData] = useState<any>([]);
  const [filteredData, setfilteredData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [metaData, setMetaData] = useState<any>({});

  const [filterActive, setFilterActive] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>(initFilters());
  const [brandNames, setBrandNames] = useState<any>([]);
  const [InitLoading, setInitLoading] = useState<boolean>(true);
  const [inCreateMode, setInCreateMode] = useState<boolean>(false);
  const [ActiveColCreate, setActiveColCreate] = useState<string>("");
  const [CreateRowSubmitCount, setCreateRowSubmitCount] = useState<number>(0);
  const [CreateRowErrors, setCreateRowErrors] = useState<any>({});
  const [inEditMode, setInEditMode] = useState({
    status: false,
    row: -1,
  });

  const [Vault_Id, setVaultId] = useState<string>("V001");
  // const [supplier_reference, setSupplierReference] = useState<string>("");
  const [Item_Code, setItemCode] = useState<string>("");
  const [Metal_code, setMetalCode] = useState<string>("Ag");
  const [Item_Name, setItemName] = useState<string>("");
  const [Bar_No, setBarNo] = useState<string>("");
  const [Brand_code, setBrandCode] = useState<string>("");
  // const [Trn_Date, setTrnDate] = useState<string>("");
  const [Inout, setInout] = useState<string>("IN");
  const [Weight, setWeight] = useState<string>("");
  const [Fineness, setFineness] = useState<string>("");
  const [Assay, setAssay] = useState<string>("0");

  //filter by date state
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [dateRangeMode, setdateRangeMode] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<any>({});
  const { tokens, warehouses, permissions } = useAuthStore();
  useEffect(() => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${tokens.accessToken.jwtToken}`;
    fetchdata(currentPage);
  }, []);

  const fetchdata = async (page?: number, params?: any) => {
    console.log(page, params);
    setLoading(true);
    BarList(page, params, 10, permissions, warehouses)
      .then((data: any) => {
        console.log("Initially feched data ", data.data);

        if (data.data.data && data.data.data.length > 0) {
          let mapData = data.data.data.map((row: any) => {
            return {
              ...row,
              customerSUM: customerSUM(row),
              ebullionSUM: ebullionSUM(row),
            };
          });
          setData(mapData);
          setfilteredData(mapData);
          setMetaData(data.data.meta);
        } else {
          setData([]);
          setfilteredData([]);
          setMetaData({});
        }
        setLoading(false);
        return GetAllReferences(tokens.idToken.jwtToken, false);
      })
      .then((brandNames: any) => {
        console.log("brandNamesbrandNamesbrandNames ", brandNames.data.data);

        const val = brandNames?.data?.data.filter((data: any) => {
          return data.codeName === "Brand";
        });
        setBrandNames(val);
        if (val.length > 0) {
          setBrandCode(val.codeValue);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const roundNo = (amount: string) => {
    return Number(amount).toFixed(1);
  };

  const roundToFour = (amount: string) => {
    return Number(amount).toFixed(4);
  };

  const filter = (page: number = 0, forcedfilter: any = null) => {
    let params = {};
    if (forcedfilter != null ? forcedfilter : filterActive) {
      const otherfilters = FieldsList.reduce((ff: any, current: string) => {
        if (filters[current] != null && filters[current].trim().length > 0) {
          ff[current] = filters[current].trim();
        }
        return ff;
      }, {});
      params = { ...params, ...otherfilters };
    }
    if (startDate && endDate) {
      const dates = {
        startDate: new Date(startDate).toISOString(),
        endDate: moment(endDate).endOf("day").toISOString(),
      };
      params = { ...params, ...dates };
      setdateRangeMode(true);
    }
    setCurrentPage(page);
    fetchdata(page, params);
    setFilterParams(params);
  };

  const customerSUM = (bar: any) => {
    let total =
      parseFloat(bar.equivalentWeight) -
      parseFloat(bar.warehousePendingReceipt) -
      parseFloat(bar.warehouseReceipted);
    return bar.pool == "CUSTOMER" ? total.toFixed(4) : (0.0).toFixed(4);
  };

  const ebullionSUM = (bar: any) => {
    return bar.pool == "EBL"
      ? Number(bar.equivalentWeight).toFixed(4)
      : (0.0).toFixed(4);
  };

  const clearFilters = () => {
    setFilterActive(false);
    setFilters(initFilters());
    filter(0, false);
  };

  const onAddNewDailyBar = () => {
    setInitLoading(true);
    setInCreateMode(!inCreateMode);
    clearDataInForm();
    setCreateRowSubmitCount(0);
    setCreateRowErrors({});
    setInEditMode({ status: false, row: -1 });
  };

  const onCreateMetalBasisClicked = (e: any) => {
    e.preventDefault();
    console.log("Save metal!");
    setCreateRowSubmitCount(1);
    console.log("Save metal! 2");
    if (validateDailyBarListCreate({})) {
      console.log("Save metal! 3");
      setInCreateMode(false);
      InsertDailyBarList({
        Service_ID: 1111,
        metalCode: Metal_code.trim(),
        itemCode: Item_Code.trim(),
        itemName: Item_Name,
        brandCode: Brand_code.trim(),
        barNo: Bar_No.trim(),
        vaultId: Vault_Id,
        uomCode: "GMS",
        weight: Weight.trim(),
        fineness: Fineness.trim(),
        assay: Assay.trim(),
        inout: Inout.trim(),
      })
        .then((data) => {
          setCreateRowSubmitCount(0);
          toast.success("Successfully Added!");
          clearDataInForm();
          fetchdata(currentPage);
        })
        .catch((err) => {
          setInCreateMode(true);
          toast.error("Something went wrong!");
        });
    }
  };

  const latest_state = (data: any) => {
    return {
      Vault_Id,
      Item_Code,
      Metal_code,
      Item_Name,
      Bar_No,
      Brand_code,
      Inout,
      Weight,
      Fineness,
      Assay,
      ...data,
    };
  };

  const validateDailyBarListCreate = (data: any) => {
    let result = true;
    let errors: any = {};
    let state = latest_state(data);
    console.log(state);

    // if(state.Vault_Id.length == 0){
    //   result = false;
    //   errors.Vault_Id = "Vault Id can't be empty!"
    // }else{
    //   errors.Vault_Id = ""
    // }

    // if(state.supplier_reference.length == 0){
    //   result = false;
    //   errors.supplier_reference = "Supplier reference can't be empty!"
    // }else{
    //   errors.supplier_reference = ""
    // }
    if (state.Item_Code.length == 0) {
      result = false;
      errors.Item_Code = "Item code can't be empty!";
    } else {
      errors.Item_Code = "";
    }
    // if(state.Metal_code.length == 0){
    //   result = false;
    //   errors.Metal_code = "Metal code can't be empty!"
    // }else{
    //   errors.Metal_code = ""
    // }
    if (state.Item_Name.length == 0) {
      result = false;
      errors.Item_Name = "Item Name can't be empty!";
    } else {
      errors.Item_Name = "";
    }

    if (state.Bar_No.length == 0) {
      result = false;
      errors.Bar_No = "Bar No can't be empty!";
    } else {
      errors.Bar_No = "";
    }
    if (state.Brand_code.length == 0) {
      result = false;
      errors.Brand_code = "Brand code can't be empty!";
    } else {
      errors.Brand_code = "";
    }

    // if(state.Inout.length == 0){
    //   result = false;
    //   errors.Inout = "In Out can't be empty!"
    // }else{
    //   errors.Inout = ""
    // }

    if (state.Weight.length == 0) {
      result = false;
      errors.Weight = "Weight can't be empty!";
    } else if (isNaN(state.Weight)) {
      result = false;
      errors.Weight = "Weight must be a number!";
    } else {
      errors.Weight = "";
    }

    if (state.Fineness.length == 0) {
      result = false;
      errors.Fineness = "Fineness can't be empty!";
    } else if (isNaN(state.Fineness)) {
      result = false;
      errors.Fineness = "Fineness must be a number!";
    } else if (state.Fineness <= 0) {
      result = false;
      errors.Fineness = "Fineness can't be less than zero!";
    } else {
      errors.Fineness = "";
    }
    console.log("Errorss ", errors);

    setCreateRowErrors(errors);
    return result;
  };

  const clearDataInForm = () => {
    setVaultId("V001");
    setItemCode("");
    setMetalCode("Ag");
    setItemName("");
    setBarNo("");
    setBrandCode(brandNames.length > 0 ? brandNames[0].codeValue : "");
    setInout("IN");
    setWeight("");
    setFineness("");
    setAssay("0");
  };

  const createNewRow = () => {
    return (
      <tr key={0} className="border-b border-gray-200 bg-white">
        <td colSpan={19}>
          <div className="flex">
            {/* Vault ID */}
            {/* <div className="py-3 px-2 text-left relative">
            <input
             onBlur={(e) => setActiveColCreate("")}
             onFocus={(e) => setActiveColCreate("VaultId")}
              className={`appearance-none rounded  block w-full p-1 border border-gray-300 placeholder-gray-500 text-gray-900
             focus:outline-none text-sm
             ${ CreateRowSubmitCount > 0 && CreateRowErrors.Vault_Id && "border-red-600 focus:border-red-600"}`}
              value={Vault_Id}
              placeholder="Vault ID"
              onChange={(e) => {
                validateDailyBarListCreate({Vault_Id:e.target.value});
                setVaultId(e.target.value) }}
            />
            <ErrorToolTip
              error={CreateRowErrors.Vault_Id}
              count={CreateRowSubmitCount}
              type={"VaultId"}
              active={ActiveColCreate}
              />
          </div> */}

            <div className="py-1 px-1 text-left relative">
              <select
                className={` rounded w-20  block w-half p-1 border border-gray-300 placeholder-gray-500 text-gray-900
         focus:outline-none text-sm`}
                value={Vault_Id}
                onChange={(e) => setVaultId(e.target.value)}
              >
                <option value="V001">V001</option>
                <option value="V002">V002</option>
              </select>
            </div>

            {/* Vault Name */}

            {/* <td className="py-1 px-1 text-left relative">
            <input
             onBlur={(e) => setActiveColCreate("")}
             onFocus={(e) => setActiveColCreate("startDate")}
               className={`appearance-none rounded  block w-full px-2
              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
             focus:outline-none text-sm
             ${ CreateRowSubmitCount > 0 && CreateRowErrors.startDate && "border-red-600 focus:border-red-600"}`}
              value={startDate}
              type="date"
              onChange={(e) => {
                validateDailyBarListCreate({startDate:e.target.value});
                setstartDate(e.target.value) }}
            />
            <ErrorToolTip
              error={CreateRowErrors.startDate}
              count={CreateRowSubmitCount}
              type={"startDate"}
              active={ActiveColCreate}
              />
          </td> */}

            {/* Suplier Reference */}
            {/* <div className="py-1 px-1 text-left relative ">
            <input
             onBlur={(e) => setActiveColCreate("")}
             onFocus={(e) => setActiveColCreate("SuplierReference")}
               className={`appearance-none rounded  block w-full px-2
              py-1 border border-gray-300 placeholder-gray-500 text-gray-900
             focus:outline-none text-sm
             ${ CreateRowSubmitCount > 0 && CreateRowErrors.supplier_reference && "border-red-600 focus:border-red-600"}`}
              value={supplier_reference}
              placeholder="Suplier Reference"
              type="Suplier Reference"
              onChange={(e) => {
                // validateDailyBarListCreate({supplier_reference:e.target.value});
                setSupplierReference(e.target.value) }}
            />
            <ErrorToolTip
              error={CreateRowErrors.supplier_reference}
              count={CreateRowSubmitCount}
              type={"SuplierReference"}
              active={ActiveColCreate}
              />
           </div> */}

            {/* Item */}
            <div className="py-1 px-1 text-left relative ">
              <input
                onBlur={(e) => setActiveColCreate("")}
                onFocus={(e) => setActiveColCreate("Item")}
                className={`appearance-none rounded  block w-full p-1 border border-gray-300 placeholder-gray-500 text-gray-900
             focus:outline-none text-sm
             ${
               CreateRowSubmitCount > 0 &&
               CreateRowErrors.Item_Code &&
               "border-red-600 focus:border-red-600"
             }`}
                value={Item_Code}
                // value={}
                placeholder="Item"
                onChange={(e) => {
                  validateDailyBarListCreate({ Item_Code: e.target.value });
                  setItemCode(e.target.value);
                }}
              />
              <ErrorToolTip
                error={CreateRowErrors.Item_Code}
                count={CreateRowSubmitCount}
                type={"Item"}
                active={ActiveColCreate}
              />
            </div>

            {/* Metal Code	 */}
            {/* <div className="py-1 px-1 text-left relative ">
            <input
             onBlur={(e) => setActiveColCreate("")}
             onFocus={(e) => setActiveColCreate("MetalCode")}
               className={`appearance-none rounded  block w-full p-1 border border-gray-300 placeholder-gray-500 text-gray-900
             focus:outline-none text-sm
             ${ CreateRowSubmitCount > 0 && CreateRowErrors.Metal_code && "border-red-600 focus:border-red-600"}`}
              value={Metal_code}
              placeholder="Metal Code"
              onChange={(e) => {
                validateDailyBarListCreate({Metal_code:e.target.value});
                setMetalCode(e.target.value) }}
            />
            <ErrorToolTip
              error={CreateRowErrors.Metal_code}
              count={CreateRowSubmitCount}
              type={"MetalCode"}
              active={ActiveColCreate}
              />
           </div> */}

            <div className="py-1 px-1 text-left relative">
              <select
                className={` rounded  block w-half px-5
          py-1 border border-gray-300 placeholder-gray-500 text-gray-900
         focus:outline-none text-sm`}
                value={Metal_code}
                onChange={(e) => setMetalCode(e.target.value)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <option value="Ag">AG</option>
                <option value="Pt">PT</option>
                <option value="Au">AU</option>
                <option value="Pd">PD</option>
              </select>
            </div>

            {/* Item Name */}
            <div className="py-1 px-1 text-left relative">
              <input
                onBlur={(e) => setActiveColCreate("")}
                onFocus={(e) => setActiveColCreate("ItemName")}
                className={`appearance-none rounded  block w-full p-1 border border-gray-300 placeholder-gray-500 text-gray-900
             focus:outline-none text-sm
             ${
               CreateRowSubmitCount > 0 &&
               CreateRowErrors.Item_Name &&
               "border-red-600 focus:border-red-600"
             }`}
                value={Item_Name}
                placeholder="Item Name"
                onChange={(e) => {
                  validateDailyBarListCreate({ Item_Name: e.target.value });
                  setItemName(e.target.value);
                }}
              />
              <ErrorToolTip
                error={CreateRowErrors.Item_Name}
                count={CreateRowSubmitCount}
                type={"ItemName"}
                active={ActiveColCreate}
              />
            </div>

            {/*Bar No */}
            <div className="py-1 px-1 text-left relative">
              <input
                onBlur={(e) => setActiveColCreate("")}
                onFocus={(e) => setActiveColCreate("BarNo")}
                className={`appearance-none rounded  block w-full p-1 border border-gray-300 placeholder-gray-500 text-gray-900
             focus:outline-none text-sm
             ${
               CreateRowSubmitCount > 0 &&
               CreateRowErrors.Bar_No &&
               "border-red-600 focus:border-red-600"
             }`}
                value={Bar_No}
                placeholder="Bar No"
                onChange={(e) => {
                  validateDailyBarListCreate({ Bar_No: e.target.value });
                  setBarNo(e.target.value);
                }}
              />
              <ErrorToolTip
                error={CreateRowErrors.Bar_No}
                count={CreateRowSubmitCount}
                type={"BarNo"}
                active={ActiveColCreate}
              />
            </div>

            {/*Brand */}
            <div className="py-1 px-1 text-left relative">
              <select
                className={` rounded  block w-half px-5
            py-1 border border-gray-300 placeholder-gray-500 text-gray-900
          focus:outline-none text-sm`}
                value={Metal_code}
                onChange={(e) => setBrandCode(e.target.value)}
              >
                {brandNames?.length &&
                  brandNames.map((b: any) => {
                    return (
                      <option value={b.codeValue}>{b.valueDescription}</option>
                    );
                  })}
              </select>
            </div>

            {/* Date */}
            {/* <div className="py-1 px-1 text-left relative">
            <input
             onBlur={(e) => setActiveColCreate("")}
             onFocus={(e) => setActiveColCreate("Date")}
               className={`appearance-none rounded  block w-full p-1 border border-gray-300 placeholder-gray-500 text-gray-900
             focus:outline-none text-sm
             ${ CreateRowSubmitCount > 0 && CreateRowErrors.Trn_Date && "border-red-600 focus:border-red-600"}`}
              value={Trn_Date}
              type="date"
              placeholder=""
              onChange={(e) => {
                validateDailyBarListCreate({Trn_Date:e.target.value});
                setTrnDate(e.target.value) }}
            />
            <ErrorToolTip
              error={CreateRowErrors.Trn_Date}
              count={CreateRowSubmitCount}
              type={"Date"}
              active={ActiveColCreate}
              />
            </div> */}

            {/*In/Out*/}
            {/* <div className="py-1 px-1 text-left relative">
            <input
             onBlur={(e) => setActiveColCreate("")}
             onFocus={(e) => setActiveColCreate("Inout")}
               className={`appearance-none rounded  block w-full p-1 border border-gray-300 placeholder-gray-500 text-gray-900
             focus:outline-none text-sm
             ${ CreateRowSubmitCount > 0 && CreateRowErrors.Inout && "border-red-600 focus:border-red-600"}`}
              value={Inout}
              placeholder="In/Out"
              onChange={(e) => {
                validateDailyBarListCreate({Inout:e.target.value});
                setInout(e.target.value) }}
            />
            <ErrorToolTip
              error={CreateRowErrors.Inout}
              count={CreateRowSubmitCount}
              type={"Inout"}
              active={ActiveColCreate}
              />
            </div> */}

            <div className="py-1 px-1 text-left relative">
              <select
                className={` rounded  block w-half p-1 border border-gray-300 placeholder-gray-500 text-gray-900
         focus:outline-none text-sm`}
                value={Inout}
                onChange={(e) => setInout(e.target.value)}
              >
                <option value="IN">IN</option>
                <option value="OUT">OUT</option>
              </select>
            </div>

            {/*Weight in Gms*/}
            <div className="py-1 px-1 text-left relative">
              <input
                onBlur={(e) => setActiveColCreate("")}
                onFocus={(e) => setActiveColCreate("Weight")}
                className={`appearance-none rounded  block w-full p-1 border border-gray-300 placeholder-gray-500 text-gray-900
             focus:outline-none text-sm
             ${
               CreateRowSubmitCount > 0 &&
               CreateRowErrors.Weight &&
               "border-red-600 focus:border-red-600"
             }`}
                value={Weight}
                placeholder="Weight"
                onChange={(e) => {
                  validateDailyBarListCreate({ Weight: e.target.value });
                  setWeight(e.target.value);
                }}
              />
              <ErrorToolTip
                error={CreateRowErrors.Weight}
                count={CreateRowSubmitCount}
                type={"Weight"}
                active={ActiveColCreate}
              />
            </div>

            {/* Fineness */}

            <div className="py-1 px-1 text-left relative">
              <input
                onBlur={(e) => setActiveColCreate("")}
                onFocus={(e) => setActiveColCreate("Fineness")}
                className={`appearance-none rounded  block w-full p-1 border border-gray-300 placeholder-gray-500 text-gray-900
             focus:outline-none text-sm
             ${
               CreateRowSubmitCount > 0 &&
               CreateRowErrors.Fineness &&
               "border-red-600 focus:border-red-600"
             }`}
                value={Fineness}
                placeholder="Fineness"
                onChange={(e) => {
                  validateDailyBarListCreate({ Fineness: e.target.value });
                  setFineness(e.target.value);
                }}
              />
              <ErrorToolTip
                error={CreateRowErrors.Fineness}
                count={CreateRowSubmitCount}
                type={"Fineness"}
                active={ActiveColCreate}
              />
            </div>

            {/* ASSAY */}
            <div className="py-1 px-1 text-left relative">
              <select
                className={` rounded  block w-half px-5
          py-1 border border-gray-300 placeholder-gray-500 text-gray-900
         focus:outline-none text-sm`}
                value={Assay}
                onChange={(e) => setAssay(e.target.value)}
              >
                <option value="0">0</option>
                <option value="1">1</option>
              </select>
            </div>
          </div>
        </td>
        <td className="py-2 px-1 text-left flex">
          <div
            id="save-daily-barlist"
            onClick={(e) => onCreateMetalBasisClicked(e)}
            className="cursor-pointer font-medium text-xs py-1 px-1 rounded bg-gray-100 text-gray-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
            </svg>
          </div>
          <div
            onClick={(e) => {
              clearDataInForm();
              onAddNewDailyBar();
            }}
            className={`cursor-pointer px-2 py-0 rounded bg-gray-100 text-gray-500 ml-1`}
          >
            <span className={`text-xs font-bold h-4 w-4`}>X</span>
          </div>
        </td>
      </tr>
    );
  };
  const onClickLock = (item: any) => {
    console.log("item", item, "barno : ", item.barNo);

    SetConfirmAlert(
      "Confirm Alert",
      `Are you sure you want to lock item : ${item.itemName} - ${item.itemCode}?`,
      () => {
        UpdateStateLockUnlock(item.barNo, true);
      },
      () => {}
    );
  };
  const onClickUnlock = (item: any) => {
    SetConfirmAlert(
      "Confirm Alert",
      `Are you sure you want to unlock item : ${item.itemName} - ${item.itemCode}?`,
      () => {
        UpdateStateLockUnlock(item.barNo, false);
      },
      () => {}
    );
  };

  const UpdateStateLockUnlock = (barNo: any, trxLock: any) => {
    UpdateMetalLockUnLock(barNo, { trxLock: trxLock })
      .then((result: any) => {
        fetchdata(currentPage);
        toast.success("Update Successful!");
      })
      .catch((err: any) => {
        console.log(err.response);
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const collectAllData = async () => {
    const data: any = await BarList(0, filterParams, 999999);
    console.log("Fetched Data", data.data.data);

    if (data.data.data && data.data.data.length > 0) {
      let mapData = data.data.data.map((row: any) => {
        return {
          ...row,
          customerSUM: customerSUM(row),
          ebullionSUM: ebullionSUM(row),
        };
      });
      return mapData;
    }
    return [];
  };

  const ExportExcel = async () => {
    let exceldata = await collectAllData();

    exceldata = exceldata.map((barlist: any) => {
      console.log(
        "barlist.customerSUM",
        barlist.customerSUM,
        "barlist.ebullionSUM",
        barlist.ebullionSUM
      );

      return {
        [ColList[0]]: barlist.vaultId,
        [ColList[1]]: barlist.vaultName,
        [ColList[2]]: barlist.metalCode,
        [ColList[3]]: barlist.itemCode,
        [ColList[4]]: barlist.itemName,
        [ColList[5]]: barlist.barNo,
        [ColList[6]]: barlist.brandName,
        [ColList[7]]: roundNo(barlist.weight),
        [ColList[8]]: roundNo(barlist.fineness),
        [ColList[9]]: barlist.assay,
        [ColList[10]]: barlist.equivalentWeight.toFixed(4),
        [ColList[11]]: barlist.inout,
        [ColList[12]]: moment(barlist.trnDate).format("DD-MM-YYYY"),
        //  [ColList[13]] : barlist.pool,
        //  [ColList[14]] : roundNo(barlist.warehouseReceipted),
        //  [ColList[15]] : roundNo(barlist.warehousePendingReceipt),
        //  [ColList[16]] : roundNo(barlist.customerSUM),
        //  [ColList[17]] : roundNo(barlist.ebullionSUM),
        //  [ColList[18]] : barlist.segregationReference,
      };
    });
    ExportToExcel(exceldata, "daily_barlist", ColTypes);
  };

  const exportDataForPDF = async () => {
    const data = await collectAllData();
    return data.map((barlist: any) => {
      return {
        itemName: barlist.itemName,
        barNo: barlist.barNo,
        brandName: barlist.brandName,
        weight: Math.abs(parseFloat(roundNo(barlist.weight))).toFixed(4),
        fineness: roundNo(barlist.fineness),
        metalWeight: roundNo(barlist.weight),
        metalCode: barlist.metalCode,
      };
    });
  };

  const SaveAsPDF = async (e: any) => {
    e.preventDefault();
    const finaldata = await exportDataForPDF();
    const doc = new jsPDF();

    let filteredDataset = finaldata.map((i: any) => {
      return i.barNo;
    });
    let unique = filteredDataset.filter(
      (item: any, i: any, ar: any) => ar.indexOf(item) === i
    );

    let all = unique.map((el: any) => {
      return finaldata.find((i: any) => i.barNo == el);
    });

    var dataset = all.map(function (obj: any) {
      return Object.keys(obj).map(function (key) {
        return obj[key];
      });
    });

    let total = all.reduce((a: any, b: any) => {
      a += Math.abs(parseFloat(b.weight));
      return a;
    }, 0.0);
    dataset.push(["", "Total", "", total.toFixed(4)]);

    let filteredMetalList = finaldata.map((i: any) => {
      return i.metalCode;
    });
    let uniqueMetals = filteredMetalList.filter(
      (item: any, i: any, ar: any) => ar.indexOf(item) === i
    );

    let selMetal =
      uniqueMetals.length === 1
        ? ` - ${GetMetalFromCode(uniqueMetals[0])}`
        : "";

    let counter = 0;

    const columns =
      selMetal.length > 0
        ? [
            [
              "Item Name",
              "Bar No",
              "Brand",
              "Weight",
              "Fineness",
              GetMetalFromCode(uniqueMetals[0]),
            ],
          ]
        : [["Item Name", "Bar No", "Brand", "Weight", "Fineness"]];

    autoTable(doc, {
      head: columns,
      body: dataset,
      styles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
      tableLineColor: [189, 195, 199],
      tableLineWidth: 0.2,
      didDrawPage: (dataArg) => {
        doc.setFontSize(14);
        doc.setTextColor(40);
        doc.text(
          `Daily Bar List ${selMetal}`,
          dataArg.settings.margin.left,
          10
        );
        doc.setFontSize(10);
        doc.text(
          moment().format("DD-MM-YYYY hh:mm:ss"),
          dataArg.settings.margin.left,
          15
        );
      },
      didParseCell: (dataArg) => {
        var rows = dataArg.table.body;

        // if(dataArg.row.index === 0 && counter <= 4) {
        if (dataArg.row.index === 0 && counter <= 5 && selMetal.length > 0) {
          counter++;
          dataArg.cell.styles.fillColor = [235, 235, 235];
          dataArg.cell.styles.lineColor = [189, 195, 199];
          dataArg.cell.styles.lineWidth = 0.2;
        } else if (dataArg.row.index === 0 && counter <= 4) {
          counter++;
          dataArg.cell.styles.fillColor = [235, 235, 235];
          dataArg.cell.styles.lineColor = [189, 195, 199];
          dataArg.cell.styles.lineWidth = 0.2;
        } else if (dataArg.row.index === rows.length - 1) {
          dataArg.cell.styles.fillColor = [235, 235, 235];
        } else {
          dataArg.cell.styles.fillColor = [255, 255, 255];
          dataArg.cell.styles.lineColor = [189, 195, 199];
          dataArg.cell.styles.lineWidth = 0.2;
        }
      },
      margin: { top: 20, bottom: 15, horizontal: 8 },
    });

    const pageCount = doc.internal.pages.length;

    for (let i = 1; i <= pageCount - 1; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(
        "Page " + String(i) + " of " + String(pageCount - 1),
        210 - 30,
        297 - 10
      );
    }

    let datePrint = moment().format("DD-MM-YYYY");

    doc.save(`Report_daily_bar_list_${datePrint}.pdf`);
  };

  const GetMetalFromCode = (code: string) => {
    let metal = "";
    switch (code) {
      case "Ag":
        metal = "Silver";
        break;
      case "Au":
        metal = "Gold";
        break;
      case "Pt":
        metal = "Platinum";
        break;
      case "Pd":
        metal = "Palladium";
        break;
    }
    return metal;
  };

  const onSearchClicked = (e: any) => {
    filter();
  };

  const onClearClicked = (e: any) => {
    setdateRangeMode(false);
    setStartDate(null);
    setEndDate(null);
    setFilterActive(false);
    setFilters(initFilters());
    fetchdata(0);
    setCurrentPage(0);
    setFilterParams({});
  };

  return (
    <Layout type={"Reports"}>
      <ToastContainer />
      <div className="flex justify-between content-bottom">
        <div className="py-3 text-right flex mb-3 items-center justify-between w-full">
          {/* <button onClick={() =>  history.push(`/barlist/summary`)}
      className="inline-flex justify-center p-1 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
       <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
       <path fillRule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
       </svg>
      </button> */}
          <h5 className=" font-bold tracking-tight text-gray-600 sm:text-2xl">
            Daily Bar List &nbsp;&nbsp;
          </h5>
          <span
            onClick={(e) => onAddNewDailyBar()}
            className="inline-flex justify-center px-4 items-center py-2 border border-transparent text-md font-semibold rounded text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-offset-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
            Add New Bar
          </span>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* filter by date */}
          <div className="flex justify-between bg-white border-gray-300 border px-2 rounded">
            <div className="flex">
              <div className="py-2 text-left flex">
                {/* <label
            className="block text-sm font-medium text-gray-700font-bold"
            for="startPicker"
          >
            Start
          </label> */}
                <div id="startPicker" className="">
                  <Datetime
                    inputProps={{
                      readOnly: true,
                      className:
                        "border text-sm px-3 border-gray-300 rounded py-2",
                      placeholder: "Start Date",
                    }}
                    value={startDate}
                    timeFormat={false}
                    dateFormat={"DD/MM/YYYY"}
                    onChange={(e: any) => {
                      setStartDate(e);
                      setEndDate(e);
                    }}
                    renderInput={(props) => {
                      return (
                        <input
                          {...props}
                          value={startDate ? props.value : ""}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div className="py-2 text-left flex">
                {/* <label
            className="block text-sm font-medium text-gray-700font-bold"
            for="endPicker"
          >
            End
          </label> */}
                <div id="endPicker" className="pl-2">
                  <Datetime
                    inputProps={{
                      readOnly: true,
                      className:
                        "border text-sm px-3 border-gray-300 rounded py-2 focus:outline-none",
                      placeholder: "End Date",
                    }}
                    value={endDate}
                    timeFormat={false}
                    dateFormat={"DD/MM/YYYY"}
                    onChange={(e: any) => {
                      setEndDate(e);
                    }}
                    renderInput={(props) => {
                      return (
                        <input {...props} value={endDate ? props.value : ""} />
                      );
                    }}
                  />
                </div>
              </div>
              <div className="flex items-center text-left  px-2">
                <button
                  onClick={onSearchClicked}
                  className="bg-purple-800 focus:ring-0 text-white px-3 py-2 hover:opacity-70 font-semibold rounded text-sm"
                >
                  Search
                </button>
              </div>
              {dateRangeMode && (
                <div className="py-3 text-left flex">
                  <button
                    onClick={onClearClicked}
                    className="bg-purple-500 focus:ring-0 text-white px-3 py-2 hover:opacity-70 font-semibold rounded text-sm"
                  >
                    Clear
                  </button>
                </div>
              )}
            </div>
            <div className="py-2 text-right flex">
              <Link to={"/barlist/summary/reports"}>
                <button
                  type="submit"
                  className="inline-flex justify-center px-3 py-2 border border-purple-300 text-sm font-semibold rounded text-purple-800
          bg-white hover:bg-purple-100 focus:outline-none mr-2"
                >
                  Summary Reports
                </button>
              </Link>
              <Link to={"/warehouse-stock/movement"}>
                <button
                  type="submit"
                  className="inline-flex justify-center px-3 py-2 border border-purple-300 text-sm font-semibold rounded text-purple-800
          bg-white hover:bg-purple-100 focus:outline-none"
                >
                  Warehouse Stock Movement
                </button>
              </Link>
              {/* <button type="button" onClick={(e) => {
                        ExportExcel()
                    }}
                            className="ml-2 inline-flex justify-center px-3 py-2 border border-transparent text-sm font-semibold rounded text-purple-800
          bg-purple-200 hover:bg-purple-300 focus:outline-none">
                        Download Report
                    </button>
                    <button type="button" onClick={(e) => {
                        SaveAsPDF(e)
                    }}
                            className="ml-2 inline-flex justify-center  px-3 py-2 border border-transparent text-sm font-semibold rounded text-purple-800
          bg-purple-200 hover:bg-purple-300 focus:outline-none">
                        Download PDF
                    </button> */}
            </div>
          </div>
          {/* end filter by date */}
          <div className="overflow-x-auto">
            <div className="w-full">
              <div className=" mt-3 mb-6">
                <div className="px-1 bg-white">
                  <div className=" flex flex-wrap ">
                    {Object.keys(filterParams).map((key, index) => {
                      let key_value = key.replace(/_/g, " ").toUpperCase();
                      let value =
                        key == "startDate" || key == "endDate"
                          ? filterParams[key].substring(0, 10)
                          : filterParams[key];
                      let final_value = `${key_value} : ${value}`;
                      return <Tag value={final_value} />;
                    })}
                  </div>
                </div>
                <table
                  className="w-full table-auto rounded text-xs border border-gray-300"
                  id="tabledata"
                >
                  <thead>
                    {/* first row */}
                    <tr className="sticky top-1 px-2 py-2 text-gray-900 bg-gray-300">
                      <th className="align-top py-2 px-2 text-left">Vault</th>
                      <th className="align-top py-2 px-2 text-left">Vault</th>
                      <th className="align-top py-2 px-2 text-left">Metal</th>
                      <th className="align-top py-2 px-2 text-left">Item</th>
                      <th className="align-top py-2 px-2 text-left">Item</th>

                      <th className="align-top py-2 px-1 text-left">Bar</th>
                      <th className="align-top py-2 px-1 text-left">Brand</th>
                      <th className="align-top py-2 px-1 text-right">Weight</th>
                      <th className="align-top py-2 px-1 text-right">
                        Fineness
                      </th>
                      <th className="align-top py-2 px-1 text-right">ASSAY</th>
                      <th className="align-top py-2 px-1 text-right">
                        Equ weight
                      </th>

                      {/*<th className="align-top py-2 px-1 text-left">Pool</th>*/}
                      {/*<th className="align-top py-2 px-1 text-right">Customer Pool</th>*/}
                      {/*<th className="align-top py-2 px-1 text-right">Pending Warehouse Receipt</th>*/}
                      {/*<th className="align-top py-2 px-1 text-right">Customer Pool Unsold</th>*/}
                      {/*<th className="align-top py-2 px-1 text-right">EBPL Pool</th>*/}
                      {/*<th className="align-top py-2 px-1 text-left">Segregation</th>*/}
                      <th className="align-top py-2 px-1 text-left">Type</th>
                      <th className="align-top py-2 px-1 text-left">
                        Date/Time
                      </th>
                      <th className="align-top py-2 px-1 text-left"></th>
                    </tr>
                    {/* second row */}
                    <tr className="sticky top-1 px-1 py-2 text-gray-900 bg-gray-100 border-b border-gray-300">
                      <th className="align-top py-2 px-1 text-left">ID</th>
                      <th className="align-top py-2 px-1 text-left">Name</th>
                      <th className="align-top py-2 px-1 text-left">Code</th>
                      <th className="align-top py-2 px-1 text-left">Code</th>
                      <th className="align-top py-2 px-1 text-left">Name</th>

                      <th className="align-top py-2 px-1 text-left">Number</th>
                      <th className="align-top py-2 px-1 text-left"></th>

                      <th className="align-top py-2 px-1 text-right">Grams</th>
                      <th className="align-top py-2 px-1 text-right">PP1000</th>
                      <th className="align-top py-2 px-1 text-right">
                        Boolean
                      </th>
                      <th className="align-top py-2 px-1 text-right">Grams</th>
                      <th className="align-top py-2 px-1 text-left"></th>
                      <th className="align-top py-2 px-1 text-right">Grams</th>
                      {/*<th className="align-top py-2 px-1 text-right">Grams</th>*/}
                      {/*<th className="align-top py-2 px-1 text-right">Grams</th>*/}
                      {/*<th className="align-top py-2 px-1 text-right">Grams</th>*/}
                      {/*<th className="align-top py-2 px-1 text-left">Reference</th>*/}
                      {/*<th className="align-top py-2 px-1 text-left">Type</th>*/}
                      {/*<th className="align-top py-2 px-1 text-left">Stamp</th>*/}
                      <th className="align-top py-2 px-1 text-left"></th>
                    </tr>

                    {/* --------------Filter----------------- */}

                    <tr className="border-b border-gray-200 bg-white">
                      {FieldsList.map((name: string, i: number) => (
                        <td key={i} className="py-2 px-1 text-left">
                          <input
                            value={filters[name]}
                            disabled={[
                              "trnDate",
                              "customerSUM",
                              "ebullionSUM",
                            ].includes(name)}
                            onChange={(e) =>
                              setFilters((f: any) => ({
                                ...f,
                                [name]: e.target.value,
                              }))
                            }
                            className="border rounded-sm w-full p-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type={"text"}
                            placeholder=""
                          />
                        </td>
                      ))}

                      <td className="py-2 px-1 text-left flex">
                        <div
                          id="daily-barlist-filter-btn"
                          onClick={() => {
                            setFilterActive(true);
                            filter(0, true);
                          }}
                          className="cursor-pointer font-medium text-xs py-1 px-1 rounded bg-gray-100 text-gray-500"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-3 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <div
                          onClick={clearFilters}
                          className={`cursor-pointer px-2 py-1 rounded bg-gray-100 text-gray-500 ml-1`}
                        >
                          <span className={`text-xs font-bold h-4 w-4`}>X</span>
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody className="text-gray-800 text-sm ">
                    {inCreateMode ? createNewRow() : ""}
                    {filteredData.map((barlist: any, i: number) => (
                      <tr className="border-b border-gray-200 bg-white  hover:bg-gray-50">
                        <td className="py-2 px-1 text-left">
                          <span className="font-medium text-xs">
                            {barlist.vaultId}
                          </span>
                        </td>

                        <td className="py-2 px-1 text-left w-3/12">
                          <span className="font-medium text-xs">
                            {barlist.vaultName}
                          </span>
                        </td>

                        {/* <td className="py-2 px-1 text-left">
                {
                  barlist.supplier_reference
                  ? <span className="font-medium text-xs">{barlist.supplier_reference}</span>
                  : <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">Empty</span>
                }
                </td> */}
                        <td className="py-2 px-1 text-left">
                          <span className="font-medium text-xs">
                            {barlist.metalCode}
                          </span>
                        </td>

                        <td className="py-2 px-1 text-left">
                          <span className="font-medium text-xs">
                            {barlist.itemCode}
                          </span>
                        </td>

                        <td className="py-2 px-1 text-left w-3/12">
                          <span className="font-medium text-xs">
                            {barlist.itemName}
                          </span>
                        </td>

                        <td className="py-2 px-1 text-left">
                          <span className="font-medium text-xs">
                            {barlist.barNo}
                          </span>
                        </td>

                        <td className="py-2 px-1 text-left w-1/12">
                          <span className="font-medium text-xs">
                            {barlist.brandName}
                          </span>
                        </td>

                        <td className="py-2 px-1 text-right">
                          <span className="font-medium text-xs">
                            {roundNo(barlist.weight)}
                          </span>
                        </td>

                        <td className="py-2 px-1 text-right">
                          <span className="font-medium text-xs">
                            {roundToFour(barlist.fineness)}
                          </span>
                        </td>

                        <td className="py-2 px-1 text-right">
                          <span className="font-medium text-xs">
                            {barlist.assay}
                          </span>
                        </td>

                        <td className="py-2 px-1 text-right">
                          <span className="font-medium text-xs">
                            {roundToFour(barlist.equivalentWeight)}
                          </span>
                        </td>

                        {/*<td className="py-2 px-1 text-left">*/}
                        {/*    <span className="font-medium text-xs">{barlist.pool }</span>*/}
                        {/*</td>*/}

                        {/*<td className="py-2 px-1 text-right">*/}
                        {/*    <span className="font-medium text-xs">{roundToFour(barlist.warehouseReceipted) }</span>*/}
                        {/*</td>*/}

                        {/*<td className="py-2 px-1 text-right">*/}
                        {/*    <span className="font-medium text-xs">{roundToFour(barlist.warehousePendingReceipt)  }</span>*/}
                        {/*</td>*/}

                        {/*<td className="py-2 px-1 text-right">*/}
                        {/*    <span className="font-medium text-xs">{barlist.customerSUM}</span>*/}
                        {/*</td>*/}
                        {/*<td className="py-2 px-1 text-right">*/}
                        {/*    <span className="font-medium text-xs">{barlist.ebullionSUM}</span>*/}
                        {/*</td>*/}
                        {/*<td className="py-2 px-1 text-left">*/}
                        {/*{*/}
                        {/*  barlist.segregationReference*/}
                        {/*  ? <span className="font-medium text-xs">{barlist.segregationReference}</span>*/}
                        {/*  : <span className="font-medium text-xs px-1 rounded bg-gray-100 text-gray-500">Empty</span>*/}
                        {/*}*/}
                        {/*</td>*/}

                        <td className="py-2 px-1 text-left">
                          <span className="font-medium text-xs">
                            {barlist.inout}
                          </span>
                        </td>

                        <td className="py-3 px-1 text-left w-2/12">
                          <span className="font-medium text-xs">
                            {moment(barlist.trnDate).format("DD-MM-YYYY")}
                          </span>
                        </td>

                        {barlist.pool == "CUSTOMER" && (
                          <td className="cursor-pointer p-0 text-center bg-purple-300">
                            <Link
                              to={`/metal-allocation/view/${barlist.segregationReference}`}
                            >
                              <span className="font-semibold text-gray-900">
                                {">>"}
                              </span>
                            </Link>
                          </td>
                        )}

                        {(barlist.inout == "TRANSIT" ||
                          barlist.inout == "OUT") && (
                          <td className="p-0 text-center bg-red-200">
                            <span className="font-medium text-gray-900 text-xs">
                              {"MT"}
                            </span>
                          </td>
                        )}

                        {/* Previous logic for locking */}
                        {/* { barlist.inout != "TRANSIT" && barlist.inout != "OUT" && barlist.lockind == "N" &&
                  <td className="cursor-pointer p-0 text-center bg-purple-100">
                    <span
                    onClick={() => {onClickLock(barlist)}}
                    className="font-medium text-gray-900 text-xs">{'LOCK'}</span>
                  </td>} */}

                        {/*{barlist.inout == "IN" && barlist.allocatedWeight == 0 && !barlist.trxLock &&*/}
                        {/*  <td className="cursor-pointer p-0 text-center bg-purple-100">*/}
                        {/*    <span*/}
                        {/*      onClick={() => { onClickLock(barlist) }}*/}
                        {/*      className="font-medium text-gray-900 text-xs">{'LOCK'}</span>*/}
                        {/*  </td>}*/}

                        {/* { barlist.inout != "TRANSIT" && barlist.inout != "OUT" && barlist.lockind == "Y" &&
                 <td className="cursor-pointer p-0 text-center bg-purple-400">
                 <span
                  onClick={() => {onClickUnlock(barlist)}}
                 className="font-medium text-gray-900 text-xs">{'LOCKED'}</span>
               </td>}

                  { barlist.inout == "TRANSIT" && barlist.inout != "OUT" && barlist.lockind == "Y" &&
                  <td className="cursor-pointer p-0 text-center bg-purple-400">
                    <span
                     onClick={() => {onClickUnlock(barlist)}}
                    className="font-medium text-gray-900 text-xs">{'LOCKED'}</span>
                  </td>} */}

                        {/*{barlist.inout == "IN" &&  barlist.trxLock &&*/}
                        {/*  <td className="cursor-pointer p-0 text-center bg-purple-400">*/}
                        {/*    <span*/}
                        {/*      onClick={() => { onClickUnlock(barlist) }}*/}
                        {/*      className="font-medium text-gray-900 text-xs">{'LOCKED'}</span>*/}
                        {/*  </td>}*/}

                        <td></td>
                      </tr>
                    ))}
                    {filteredData.length == 0 && (
                      <tr>
                        <td
                          className="py-5 px-1 text-center bg-white font-semibold"
                          colSpan={21}
                        >
                          No Records Found !
                        </td>
                      </tr>
                    )}

                    <div className=""></div>
                  </tbody>
                </table>

                {/* Pagination Bar */}
                <Pagination
                  meta={metaData}
                  handlePageChange={(number: number) => {
                    filter(number);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

const FieldsList = [
  "vaultId",
  "vaultName",
  "metalCode",
  "itemCode",
  "itemName",
  "barNo",
  "brandName",
  "weight",
  "fineness",
  "assay",
  "equivalentWeight",
  "inout",
  "trnDate",
];

const ColList = [
  "Vault ID", // 0
  "Vault Name", // 1
  "Metal Code", // 2
  "Item", // 3
  "Item Name", // 4
  "Bar No", // 5
  "Brand", // 6
  "Weight in Gms", // 7
  "Fineness pp 1000", // 8
  "ASSAY", // 9
  "Equ weight", // 10
  "In/Out", // 11
  "Date", // 12
  // "Pool",// 13
  // "CusPool :Warehouse Rcptd",// 14
  // "CusPool :Warehouse Pen Rcptd",// 15
  // "CusPool :unsold",// 16
  // "EBL Pool",// 17
  // "Segregation Reference",// 18
];

const ColTypes = [
  { index: 7, type: "n" },
  { index: 8, type: "n" },
  { index: 9, type: "n" },
  { index: 10, type: "n" },
  // { index : 12 , type : 'n'},
  // { index : 13 , type : 'n'},
  // { index : 14 , type : 'n'},
  // { index : 15 , type : 'n'},
];

export default DailyBarList;
