import React, { useState, useEffect } from "react";
import Testing from "../Services/test.service";
import { toast } from "react-toastify";
import moment from "moment";

interface PropsType {
  // askPrice: number;
  // bitPrice: number;
}

const Main: React.FC<PropsType> = () => {
  const [metal, setMetal] = useState<any>([]);
  const [owner, setOwner] = useState<any>([]);
  const [selectedMetal, setSelectedMetal] = useState<string>("");
  const [selectOwner, setSelectedOwner] = useState<string>("");
  const [quantity, setQuantity] = useState<number>(1);
  const [metalAsk, setMetalAsk] = useState<any>();
  const [metalBid, setMetalBid] = useState<any>();
  const [ask, setAsk] = useState<any>();
  const [bid, setBid] = useState<any>();
  const [feedAsk, setFeedAsk] = useState<any>();
  const [feedBid, setFeedBid] = useState<any>();
  const [counter, setCounter] = useState<number>(1);
  const [uom, setUom] = useState<any>();
  const [currency, setCurrency] = useState<any>("usd");
  const [feederCurrencyAsk, setFeederCurrencyAsk] = useState<any>();
  const [feederCurrencyBid, setFeederCurrencyBid] = useState<any>();
  const [finalAskRule, setFinalAskRule] = useState<any>();
  const [finalBidRule, setFinalBidRule] = useState<any>();
  const [ruleNo, setRuleNo] = useState<any>();
  const [lastRefreshed, setLastRefreshed] = useState<any>();

  useEffect(() => {
    fetchAuxiliaryData();
  }, []);

  //   const onMetalChange = () => {
  //     return 1;
  //   };

  const qtyChanged = (e: any) => {
    let quantity = e.target.value;
    setQuantity(quantity);
  };

  const uomChanged = (e: any) => {
    let uom = e.target.value;
    setUom(uom);
  };

  const currancyChanged = (e: any) => {
    let currency = e.target.value;
    setCurrency(currency);
  };

  const askmusdaChange = (e: any) => {
    let metalAsk = e.target.value;
    setMetalAsk(metalAsk);
  };

  const bitmusdChange = (e: any) => {
    let metalBid = e.target.value;
    setMetalBid(metalBid);
  };

  const onOwnerChange = (e: any) => {
    //let selectOwner = ;
    setSelectedOwner(e.target.value);
  };

  const onMetalChange = (e: any) => {
    //let selectedMetal = ;
    setSelectedMetal(e.target.value);
  };

  const fetchAuxiliaryData = async () => {
    let data: any = await Testing.getAuxilaryData();
    if (data && data.data.success) {
      setOwner(data.data.data.partners);
      setMetal(data.data.data.metals);

      //default values
      setSelectedMetal(data.data.data.metals[0].metalCode);
      setSelectedOwner(data.data.data.partners[0].id);
    }
  };

  const fetchPrices = async () => {
    try {
      let data: any = await Testing.getPricingApi(
        parseInt(selectOwner),
        selectedMetal,
        quantity,
        currency
      );
      if (data && data.data.success) {
        setAsk(data.data.data.ask);
        setBid(data?.data?.data?.bid ? data?.data?.data?.bid : 0);
        setFeedAsk(data.data.data.feederPrice.ask);
        setFeedBid(data.data.data.feederPrice.bid);
        setFeederCurrencyAsk(data.data.data.feederCurrencyPrice.ask);
        setFeederCurrencyBid(data.data.data.feederCurrencyPrice.bid);
        setRuleNo(
          data?.data?.data?.rule?.ruleNo
            ? data?.data?.data?.rule?.ruleNo
            : "No matching rules found"
        );
        setFinalAskRule(
          data?.data?.data?.rule?.finalAskRule
            ? data?.data?.data?.rule?.finalAskRule
            : "No matching rules found"
        );
        setFinalBidRule(
          data?.data?.data?.rule?.finalBidRule
            ? data?.data?.data?.rule?.finalBidRule
            : "No matching rules found"
        );
      }

      console.log("Fetched data: ", data);
    } catch (error) {}
  };

  const onSubmitClick = (e: any) => {
    e.preventDefault();

    setAsk("");
    setBid("");
    setFeedAsk("");
    setFeedBid("");
    setFeederCurrencyAsk("");
    setFeederCurrencyBid("");
    setRuleNo("");
    setFinalAskRule("");
    setFinalBidRule("");
    fetchPrices();
  };

  useEffect(() => {
    try {
      const price = setInterval(fetchPrices, 5000);
      return () => {
        clearInterval(price);
      };
    } catch (ex) {
      toast.error(ex.message || "Failed to get Price data.", {
        autoClose: false,
      });
    }
  });

  return (
    <div className="max-w-full">
      <div className="flex bg-white">
        <div className="flex-1 border">
          <div className="p-5 border-b">
            <h3 className="text-xl font-bold mb-3 text-indigo-600">Request</h3>
            <h4 className="text-sm font-bold mb-3 text-indigo-800">{moment().toISOString()}</h4>
            <div className="mb-3">
              <label
                className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                for="grid-state"
              >
                Metal
              </label>
              <div className="relative">
                <select
                  onChange={onMetalChange}
                  className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-1 px-4"
                  id="grid-state"
                  value={selectedMetal}
                >
                  {metal.length > 0 &&
                    metal.map((metal: any) => {
                      return (
                        <option value={metal.metalCode}>
                          {metal.metalCode}
                        </option>
                      );
                    })}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
              </div>
            </div>

            <div className="mb-3">
              <label
                className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                for="grid-state"
              >
                Source
              </label>
              <div className="relative">
                <select
                  onChange={onOwnerChange}
                  className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                  id="grid-state"
                  value={selectOwner}
                >
                  {owner.length > 0 &&
                    owner.map((owner: any) => {
                      return <option value={owner.id}>{owner.name}</option>;
                    })}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
              </div>
            </div>

            <div>
              <label
                className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                for="grid-state"
              >
                Quantity
              </label>
              <input
                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                id="grid-city"
                name="bid"
                type="text"
                value={quantity}
                placeholder=""
                onChange={(e) => qtyChanged(e)}
              />
            </div>

            <button
              className=" uppercase rounded mt-3 w-full bg-purple-950 text-white px-4 py-3 font-bold"
              onClick={(e) => onSubmitClick(e)}
            >
              Submit
            </button>
          </div>

          <div className="p-5 bg-blue-50 m-1">
            <h3 className="text-xl font-bold mb-3 text-indigo-600">Response</h3>
            <div className="mb-3">
              <label
                className="block text-grey-darker text-sm font-bold mb-2"
                for="grid-state"
              >
                Rule No
              </label>
              <input
                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                id="grid-city"
                name="bid"
                type="text"
                value={ruleNo}
                placeholder=""
                readOnly={true}
              />
            </div>
            <div className="mb-3">
              <label
                className="block text-grey-darker text-sm font-bold mb-2"
                for="grid-state"
              >
                Final Ask Rule
              </label>
              <input
                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                id="grid-city"
                name="bid"
                type="text"
                value={finalAskRule}
                placeholder=""
                readOnly={true}
              />
            </div>
            <div>
              <label
                className="block text-grey-darker text-sm font-bold mb-2"
                for="grid-state"
              >
                Final Bid Rule
              </label>
              <input
                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                id="grid-city"
                name="bid"
                type="text"
                value={finalBidRule}
                placeholder=""
                readOnly={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white p-1 mt-2">
        <div className="bg-gray-200 border border-gray-300">
          <div className="p-5 px-3 border-b border-gray-400">
            <p className="text-lg font-bold mb-0 text-indigo-600">
              Calculated Price{" "}
              <small className="text-xs">(INR/g - if rule is applied)</small>
            </p>
            <div className="flex flex-row-reverse">
              <div className="mr-3 w-full">
                <label className="text-sm font-bold">Ask Price</label>
                <input
                  className="appearance-none text-right block w-full bg-grey-lighter text-black border border-grey-lighter rounded py-2 px-4"
                  id="grid-city"
                  name="ask/m"
                  type="text"
                  placeholder=""
                  value={ask}
                  onChange={(e) => askmusdaChange(e)}
                />
              </div>

              <div className="w-full mr-3">
                <label className="text-sm font-bold mr-3">Bid Price</label>
                <input
                  className="appearance-none text-right block w-full bg-grey-lighter text-black border border-grey-lighter rounded py-2 px-4"
                  id="grid-city"
                  name="bid/m"
                  type="text"
                  placeholder=""
                  value={bid}
                  onChange={(e) => bitmusdChange(e)}
                />
              </div>
            </div>
          </div>

          <div className="p-5 border-b border-gray-400">
            <p className="text-lg font-bold mb-0 text-indigo-600">
              CDF <small className="text-sm">(USD/Ozt)</small>
            </p>
            <div className="flex flex-row-reverse">
              <div className="w-full mr-3">
                <label className="text-sm font-bold">Ask Price</label>
                <input
                  className="appearance-none text-right block w-full bg-grey-lighter text-black border border-grey-lighter rounded py-2 px-4"
                  id="grid-city"
                  name="ask/m"
                  type="text"
                  placeholder=""
                  value={feedAsk}
                  readOnly={true}
                />
              </div>

              <div className="w-full mr-3">
                <label className="text-sm font-bold mr-3">Bid Price</label>
                <input
                  className="appearance-none text-right block w-full bg-grey-lighter text-black border border-grey-lighter rounded py-2 px-4"
                  id="grid-city"
                  name="bid/m"
                  type="text"
                  placeholder=""
                  value={feedBid}
                  readOnly={true}
                />
              </div>
            </div>
          </div>

          <div className="p-5 border-b border-gray-400">
            <p className="text-lg font-bold mb-0 text-indigo-600">USD/INR</p>
            <div className="flex flex-row-reverse">
              <div className="w-full mr-3">
                <label className="text-sm font-bold">Ask Price</label>
                <input
                  className="appearance-none text-right block w-full bg-grey-lighter text-black border border-grey-lighter rounded py-2 px-4"
                  id="grid-city"
                  name="ask/m"
                  type="text"
                  placeholder=""
                  value={feederCurrencyAsk}
                  readOnly={true}
                />
              </div>

              <div className="w-full mr-3">
                <label className="text-sm font-bold mr-3">Bid Price</label>
                <input
                  className="appearance-none text-right block w-full bg-grey-lighter text-black border border-grey-lighter rounded py-2 px-4"
                  id="grid-city"
                  name="bid/m"
                  type="text"
                  placeholder=""
                  value={feederCurrencyBid}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
