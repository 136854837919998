import axios from "axios";
import Http from "../../library/http.library";
import { httpMethods } from "../../library/enum";

export const getInvoiceList = async (
  tokens: any,
  currentPage: any,
  data: any,
  isPaginated: any = true
) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/invoice`;
    try {
      let result = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          Authorization: `Bearer ${tokens.idToken.jwtToken}`,
        },
        params: {
          page: currentPage,
          ...(data.ebInvoiceRef ? { ebInvoiceRef: data.ebInvoiceRef } : {}),
          ...(data.gstInvoiceRef ? { gstInvoiceRef: data.gstInvoiceRef } : {}),
          ...(data.partnerId ? { partnerId: data.partnerId } : {}),
          ...(data.ebOrderRef ? { ebOrderRef: data.ebOrderRef } : {}),
          ...(data.type ? { orderType: data.type } : {}),
          ...(data.accountId ? { accountId: data.accountId } : {}),
          ...(data.customerId ? { customerId: data.customerId } : {}),
          ...(data.currency ? { currency: data.currency } : {}),
          ...(data.orderBy ? { orderBy: data.orderBy } : {}),
          ...(data.order ? { order: data.order } : {}),
          ...(data.status ? { invStatus: data.status } : {}),
          ...(data.warehouseId ? { warehouseId: data.warehouseId } : {}),
          ...(data.supplierInvoiceDate
            ? { supplierInvoiceDate: data.supplierInvoiceDate }
            : {}),
          ...(data.metalCode ? { metal: data.metalCode } : {}),
          ...(data.toDate ? { toDate: data.toDate } : {}),
          ...(data.fromDate ? { fromDate: data.fromDate } : {}),
          // // ...(data.toDate ? {toDate: data.toDate} : {}),
          // // ...(data.orderBy ? {orderBy: data.orderBy} : {}),
        },
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};
export const downloadInvoices = async (
  tokens: any,
  currentPage: any,
  data: any,
  isPaginated: any = true
) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/invoice/download`;
    try {
      let result = await axios.post(apiUrl, {
        // headers: {
        //   "Content-Type": "application/json",
        //   "Access-Control-Allow-Origin": "*",
        //   "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        //   Authorization: `Bearer ${tokens.idToken.jwtToken}`,
        // },
        // params: {
        isPaginated: isPaginated,
        page: currentPage,
        ...(data.ebInvoiceRef ? { ebInvoiceRef: data.ebInvoiceRef } : {}),
        ...(data.partnerId ? { partnerId: data.partnerId } : {}),
        ...(data.ebOrderRef ? { ebOrderRef: data.ebOrderRef } : {}),
        ...(data.type ? { orderType: data.type } : {}),
        ...(data.accountId ? { accountId: data.accountId } : {}),
        ...(data.customerId ? { customerId: data.customerId } : {}),
        ...(data.currency ? { currency: data.currency } : {}),
        ...(data.orderBy ? { orderBy: data.orderBy } : {}),
        ...(data.order ? { order: data.order } : {}),
        ...(data.status ? { invStatus: data.status } : {}),
        ...(data.warehouseId ? { warehouseId: data.warehouseId } : {}),
        ...(data.supplierInvoiceDate
          ? { supplierInvoiceDate: data.supplierInvoiceDate }
          : {}),
        ...(data.metalCode ? { metal: data.metalCode } : {}),
        ...(data.toDate ? { toDate: data.toDate } : {}),
        ...(data.fromDate ? { fromDate: data.fromDate } : {}),
        // // ...(data.toDate ? {toDate: data.toDate} : {}),
        // // ...(data.orderBy ? {orderBy: data.orderBy} : {}),
        // },
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const postSelectRefundList = async (tokens: any, data: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/refund-list`;
    try {
      let result = await axios.post(apiUrl, data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          Authorization: `Bearer ${tokens.idToken.jwtToken}`,
        },
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const getRefundApprove = async (
  currentPage: number,
  tokens: any,
  data: any
) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/refund-approve`;
    try {
      let result = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          Authorization: `Bearer ${tokens.idToken.jwtToken}`,
        },
        params: {
          isPaginated: true,
          page: currentPage,
          ...(data.orderRef ? { orderRef: data.orderRef } : {}),
          ...(data.orderItemRef ? { orderItemRef: data.orderItemRef } : {}),
          ...(data.status ? { status: data.status } : {}),
        },
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const refundApprove = async (tokens: any, data: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/refund-approve`;
    try {
      let result = await axios.patch(apiUrl, data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          Authorization: `Bearer ${tokens.idToken.jwtToken}`,
        },
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const getPartnerList = async (tokens: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/partners`;
    try {
      let result = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          Authorization: `Bearer ${tokens.idToken.jwtToken}`,
        },
        params: {
          isPaginated: false,
        },
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const reportDownload = async (tokens: any, id: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/invoice/pdf/${id}`;
    try {
      let result = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokens.idToken.jwtToken}`,
        },
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const getMetals = async (tokens: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/metal-master?isPaginated=false`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

const InvoiceService = {
  getInvoiceList,
  downloadInvoices,
  postSelectRefundList,
  getRefundApprove,
  refundApprove,
  getPartnerList,
  reportDownload,
  getMetals,
};

export default InvoiceService;
