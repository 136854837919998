import React, { useState } from "react";
import OrderService from "../Services/order.service";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import Modal from "../widget/modal";
import { useAuthStore } from "../../../Store/AuthStore";

interface PropsType {
  batch?: any;
  order: any;
  salesPdfs?: any;
  refreshPage: () => void;
  prev: boolean;
}

const ActionTypes = {
  ACCEPT: "ACCEPT",
  REJECT: "REJECT",
  REJECT_AND_RESEND: "REJECT_AND_RESEND",
};

const InvoiceBatch: React.FC<PropsType> = ({
  batch,
  order,
  refreshPage,
  prev,
  salesPdfs,
}) => {
  const [Loading, setLoading] = useState<any>(false);
  const [viewModal, setViewModal] = useState<any>(false);
  const { tokens } = useAuthStore();

  const onActionEvent = async (id: string, action: string, reason: any) => {
    setLoading(true);
    try {
      let body = {
        reason: reason,
      };
      let data: any = await OrderService.orderInvoicesActions(id, action, body);
      if (data.status === 200 && data.data) {
        setLoading(false);
        let responseData = data.data.data;
        // console.log(responseData, "responseData");
        if (responseData.status === ActionTypes.REJECT_AND_RESEND) {
          toast.success(
            `Invoice link has been resent to ${responseData.data.link.email}`
          );
        } else if (responseData.status === "REJECTED") {
          toast.success(`Successfully rejected`);
        } else {
          toast.success(`Successfully approved`);
        }
        refreshPage();
      } else {
        setLoading(false);
        // console.log("Error ", data);
        toast.error(`Error: Somethings wrong`); // change to received error message
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const downloadUploads = async (key: string, isSales: boolean) => {
    if (key) {
      try {
        setLoading(true);
        if (isSales) {
          const rawData = await OrderService.orderSalesInvoicesDownload(
            key,
            tokens
          );
          setLoading(false);
          if (rawData?.data?.data?.fileUrl)
            window.open(rawData?.data?.data?.fileUrl, "_blank");
        } else {
          const res = await OrderService.orderInvoicesDownload(key, tokens);
          setLoading(false);

          if (res.status === 200 && res.data) {
            const href = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", "invoice.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        }
      } catch (error) {
        console.log("ERROR ", error);
        setLoading(false);
      }
    } else {
      toast.error(`No Object Key found!`);
    }
  };

  const rejectAndResend = (reason: any) => {
    console.log("Reason ", reason);

    onActionEvent(order.ebOrderRef, ActionTypes.REJECT_AND_RESEND, reason);
  };

  return (
    <>
      <ToastContainer />
      {Loading ? (
        <Loader />
      ) : (
        <>
          {batch?.docs?.length > 0 && (
            <>
              {batch.status === "PENDING" && !prev && (
                <>
                  <div className="py-4 flex justify-between">
                    <h4 className="text-sm font-medium text-gray-700 border-b">
                      Batch {`(${batch.batchId})`}
                    </h4>
                    {order.status === "INVOICE_APPROVAL_PENDING" &&
                      batch.status === "PENDING" && (
                        <div>
                          <button
                            type="button"
                            className="mr-2 px-2 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            onClick={() =>
                              onActionEvent(
                                order.ebOrderRef,
                                ActionTypes.ACCEPT,
                                null
                              )
                            }
                          >
                            Approve
                          </button>
                          <button
                            type="button"
                            className="mr-2 px-2 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
                            onClick={() =>
                              onActionEvent(
                                order.ebOrderRef,
                                ActionTypes.REJECT,
                                null
                              )
                            }
                          >
                            Reject and cancel
                          </button>
                          <button
                            type="button"
                            className="mr-2 px-2 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out"
                            onClick={() => setViewModal(true)}
                            // onClick={() => onActionEvent(order.ebOrderRef, ActionTypes.REJECT_AND_RESEND,"reason")}
                          >
                            Reject and resend
                          </button>
                        </div>
                      )}
                  </div>
                  <div className="grid grid-cols-6 gap-4">
                    {batch.docs.map((doc: any, key: number) => {
                      return (
                        <div key={key}>
                          <div
                            className="block rounded-lg shadow-lg bg-gray-200 max-w-sm text-center cursor-pointer"
                            onClick={() => {
                              downloadUploads(doc.objKey, false);
                            }}
                          >
                            <div className="py-3 px-6 border-b border-gray-300">
                              {doc.fileSize}
                            </div>
                            <div className="p-6 flex justify-center">
                              {doc.fileName.split(".")[
                                doc.fileName.split(".").length - 1
                              ] === "pdf" ? (
                                <svg
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="file-pdf"
                                  className="svg-inline--fa fa-file-pdf text-gray-700 w-1/4"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M181.9 256.1c-5-16-4.9-46.9-2-46.9 8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7 18.3-7 39-17.2 62.9-21.9-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8 4.5-18.5 11.6-46.6 6.2-64.2-4.7-29.4-42.4-26.5-47.8-6.8-5 18.3-.4 44.1 8.1 77-11.6 27.6-28.7 64.6-40.8 85.8-.1 0-.1.1-.2.1-27.1 13.9-73.6 44.5-54.5 68 5.6 6.9 16 10 21.5 10 17.9 0 35.7-18 61.1-61.8 25.8-8.5 54.1-19.1 79-23.2 21.7 11.8 47.1 19.5 64 19.5 29.2 0 31.2-32 19.7-43.4-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9 37.1 15.8 42.8 9 42.8 9z"
                                  ></path>
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="file-image"
                                  className="svg-inline--fa fa-file-image fa-w-12 text-gray-700 w-1/4"
                                  role="img"
                                  viewBox="0 0 384 512"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z"
                                  />
                                </svg>
                              )}
                            </div>
                            <div className="py-2 px-2 border-t border-gray-300 flex justify-between"></div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              {batch.status !== "PENDING" && prev && (
                <>
                  <div className="py-4 flex justify-between">
                    <h4 className="text-sm font-medium text-gray-700 border-b">
                      Batch {`(${batch.batchId})`}
                    </h4>
                  </div>
                  <div className="grid grid-cols-6 gap-4">
                    {batch.docs.map((doc: any, key: number) => {
                      return (
                        <div key={key}>
                          <div
                            className="block rounded-lg shadow-lg bg-gray-200 max-w-sm text-center cursor-pointer"
                            onClick={() => {
                              downloadUploads(doc.objKey, false);
                            }}
                          >
                            <div className="py-3 px-6 border-b border-gray-300">
                              {doc.fileSize}
                            </div>
                            <div className="p-6 flex justify-center">
                              {doc.fileName.split(".")[
                                doc.fileName.split(".").length - 1
                              ] === "pdf" ? (
                                <svg
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="file-pdf"
                                  className="svg-inline--fa fa-file-pdf text-gray-700 w-1/4"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M181.9 256.1c-5-16-4.9-46.9-2-46.9 8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7 18.3-7 39-17.2 62.9-21.9-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8 4.5-18.5 11.6-46.6 6.2-64.2-4.7-29.4-42.4-26.5-47.8-6.8-5 18.3-.4 44.1 8.1 77-11.6 27.6-28.7 64.6-40.8 85.8-.1 0-.1.1-.2.1-27.1 13.9-73.6 44.5-54.5 68 5.6 6.9 16 10 21.5 10 17.9 0 35.7-18 61.1-61.8 25.8-8.5 54.1-19.1 79-23.2 21.7 11.8 47.1 19.5 64 19.5 29.2 0 31.2-32 19.7-43.4-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9 37.1 15.8 42.8 9 42.8 9z"
                                  ></path>
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="file-image"
                                  className="svg-inline--fa fa-file-image fa-w-12 text-gray-700 w-1/4"
                                  role="img"
                                  viewBox="0 0 384 512"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z"
                                  />
                                </svg>
                              )}
                            </div>
                            <div className="py-2 px-2 border-t border-gray-300 flex justify-between"></div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </>
          )}
          {salesPdfs && (
            <div className="grid grid-cols-6 gap-4">
              {
                <div key={salesPdfs}>
                  <div
                    className="block rounded-lg shadow-lg bg-gray-200 max-w-sm text-center cursor-pointer"
                    onClick={() => {
                      downloadUploads(salesPdfs, true);
                    }}
                  >
                    <div className="py-3 px-6 border-b border-gray-300">
                      {salesPdfs}
                    </div>
                    <div className="p-6 flex justify-center">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="file-pdf"
                        className="svg-inline--fa fa-file-pdf text-gray-700 w-1/4"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path
                          fill="currentColor"
                          d="M181.9 256.1c-5-16-4.9-46.9-2-46.9 8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7 18.3-7 39-17.2 62.9-21.9-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8 4.5-18.5 11.6-46.6 6.2-64.2-4.7-29.4-42.4-26.5-47.8-6.8-5 18.3-.4 44.1 8.1 77-11.6 27.6-28.7 64.6-40.8 85.8-.1 0-.1.1-.2.1-27.1 13.9-73.6 44.5-54.5 68 5.6 6.9 16 10 21.5 10 17.9 0 35.7-18 61.1-61.8 25.8-8.5 54.1-19.1 79-23.2 21.7 11.8 47.1 19.5 64 19.5 29.2 0 31.2-32 19.7-43.4-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9 37.1 15.8 42.8 9 42.8 9z"
                        ></path>
                      </svg>
                    </div>
                    <div className="py-2 px-2 border-t border-gray-300 flex justify-between"></div>
                  </div>
                </div>
              }
            </div>
          )}
        </>
      )}

      <Modal
        viewModal={viewModal}
        setViewModal={setViewModal}
        rejectAndResend={rejectAndResend}
      />
    </>
  );
};

export default InvoiceBatch;
