import axios from "axios";
import { toast } from "react-toastify";
import Http from "../../library/http.library";
import {httpMethods} from "../../library/enum";

export const GetAllCountryCode = async () => {
    return new Promise(async (resolve, reject) => {
      let apiUrl = process.env.REACT_APP_URL + ``;
      resolve(await axios.get(apiUrl));
    });
  };

  export const GetAllStateCode = async () => {
    return new Promise(async (resolve, reject) => {
      let apiUrl = process.env.REACT_APP_URL + ``;
      resolve(await axios.get(apiUrl));
    });
  };

  export const GetAllReferenceMaster = async () => {
    // return new Promise(async (resolve, reject) => {
    //   let apiUrl = process.env.REACT_APP_URL + `/ref-master`;
    //   resolve(await axios.get(apiUrl));
    // });

    return new Promise(async (resolve, reject) => {
      let apiUrl = process.env.REACT_APP_URL + `/ref-master`;
      try {
        let result = await axios.get(apiUrl).then(response => {
          resolve(response);
        }).catch(err => {
          if( err.response ){
            console.log(err.response);
            toast.error(err.response.data)
        }else{
          console.error(err);
        }
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  export const GetReferenceMaster = async (id: number) => {
    // return new Promise(async (resolve, reject) => {
    //   let apiUrl = process.env.REACT_APP_URL + `/ref-master/${id}`;
    //   resolve(await axios.get(apiUrl));
    // });

    return new Promise(async (resolve, reject) => {
      let apiUrl = process.env.REACT_APP_URL + `/ref-master/${id}`;
      try {
        let result = await axios.get(apiUrl).then(response => {
          console.log(response);
          resolve(response);
        }).catch(err => {
          if( err.response ){
            console.log(err.response);
            toast.error(err.response.data)
        }else{
          console.error(err);
        }
        });
        
       
      } catch (error) {
        reject(error);
      }
    });
  };
  

  export const CreateReferenceMaster = async (data: any) => {
    return new Promise(async (resolve, reject) => {
      let apiUrl = process.env.REACT_APP_URL + `/ref-master`;
      try {
        let result = await axios.post(apiUrl, data).then(response => {
          toast.success("Successfuly inserted");
          console.log(response);
          resolve(response);
          
        }).catch(err => {
          if( err.response ){
            console.log(err.response);
            toast.error(err.response.data)
        }else{
          console.error(err);
        }
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  export const UpdateReferenceMaster = async (id: number, data: any) => {
    return new Promise(async (resolve, reject) => {
      let apiUrl = process.env.REACT_APP_URL + `/ref-master/${id}`;
      try {
        let result = await axios.patch(apiUrl, data).then(response => {
          toast.success("Successfuly updated");
          console.log(response);
          resolve(response);
        }).catch(err => {
          if( err.response ){
            console.log(err.response);
            toast.error(err.response.data)
        }else{
          console.error(err);
        }
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  export const GetReferenceDeatils = async (id: number) => {
    return new Promise(async (resolve, reject) => {
      let apiUrl = process.env.REACT_APP_URL + `/ref-details/master/${id}`;
      try {
        let result = await axios.get(apiUrl).then(response => {
          console.log(response); 
          resolve(response);
        }).catch(err => {
          if( err.response ){
            console.log(err.response);
        }else{
          console.error(err);
        }
        });
        
       
      } catch (error) {
        reject(error);
      }
    });
  };



  export const CreateReferenceDetails = async (data: any) => {
    return new Promise(async (resolve, reject) => {
      let apiUrl = process.env.REACT_APP_URL + `/ref-details`;
      try {
        let result = await axios.post(apiUrl, data).then(response => {
          toast.success("Reference details created successfully");
          console.log(response);
          resolve(response);
          
        }).catch(err => {
          if( err.response ){
            console.log(err.response);
            toast.error(err.response.data)
        }else{
          console.error(err);
        }
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  export const UpdateReferenceDetails = async (id: any, data: any) => {
    return new Promise(async (resolve, reject) => {
      let apiUrl = process.env.REACT_APP_URL + `/ref-details/${id}`;
      try {
        let result = await axios.patch(apiUrl, data).then(response => {
          toast.success("Successfuly updated");
          console.log(response);
          resolve(response);
        }).catch(err => {
          if( err.response ){
            console.log(err.response);
            toast.error(err.response.data)
        }else{
          console.error(err);
        }
        });
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };


  export const GetErpTableData = async (table:string,date:any) => {
    // return new Promise(async (resolve, reject) => {
    //   let apiUrl = process.env.REACT_APP_URL + `/ref-master`;
    //   resolve(await axios.get(apiUrl));
    // });

    return new Promise(async (resolve, reject) => {
      let apiUrl = process.env.REACT_APP_URL + `/erp-masters/sync-masters?table=${table}&date=${date}`;
      try {
        let result = await axios.get(apiUrl).then(response => {
          resolve(response);
        }).catch(err => {
          if( err.response ){
            console.log(err.response);
            toast.error(err.response.data)
        }else{
          console.error(err);
        }
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  export const GetErpSyncDetail = async (table:string,tokens:any) => {
    // return new Promise(async (resolve, reject) => {
    //   let apiUrl = process.env.REACT_APP_URL + `/ref-master`;
    //   resolve(await axios.get(apiUrl));
    // });

    return new Promise(async (resolve, reject) => {
      // let result1 = await Http.setMethod(httpMethods.get).setUrl(`/erp-sync-detail?table=${table}`).setToken(tokens.idToken.jwtToken).request();

      // let apiUrl = process.env.REACT_APP_URL + `/erp-sync-detail?table=${table}`;
      try {
        let result = await Http.setMethod(httpMethods.get).setUrl(`/erp-sync-detail?table=${table}`).setToken(tokens.idToken.jwtToken).request().then(response => {
          resolve(response);
        }).catch(err => {
          if( err.response ){
            console.log(err.response);
            toast.error(err.response.data)
        }else{
          console.error(err);
        }
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  export const syncMasterTables = async (table:string,data: any) => {
    return new Promise(async (resolve, reject) => {
      let apiUrl = process.env.REACT_APP_URL + `/erp-masters/sync-masters?table=${table}`;
      try {
        let result = await axios.patch(apiUrl, {"primaryKeys":data}).then(response => {
          toast.success("Successfuly updated");
          console.log(response);
          resolve(response);
        }).catch(err => {
          if( err.response ){
            console.log(err.response);
            toast.error(err.response.data)
        }else{
          console.error(err);
        }
        });
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
