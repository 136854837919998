import { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Layout from "../Layout/Layout";
import AskFareWeightBreak from "./New-Components/AsKFareWeightBreak";
import AskPriceSection from "./New-Components/AskPriceSection";
import BidPriceSection from "./New-Components/BidPriceSection";
import PricingMaster from "./New-Components/PricingMaster";
import PricingService from "./Services/pricing.service";
import PricingMasterStatus from "./New-Components/PricingMasterStatus";
import Collapsible from "react-collapsible";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Types } from "../Pricing/constants";
import "../Pricing/Spinner.css";
import { useStore } from "../../Store/PricingStore";
import customFunctions from "./Services/customFunctions";
import { useAuthStore } from "../../Store/AuthStore";
import { makeQuery } from "../../Common/util.helper";


interface PropsType {
  //   data_set: any;
  // askPrice: number;
  // bitPrice: number;
}

const Pricing: React.FC<PropsType> = () => {
  let { id } = useParams<any>();
  const [quantity, setQuantity] = useState<number>(1);
  const [price, setPrice] = useState<any>();
  const [bidRules, setBidRules] = useState<any>();
  const [askRules, setAskRules] = useState<any>();
  const [bidRefresh, setBidRefresh] = useState<number>(0);
  const [askRefresh, setAskRefresh] = useState<number>(0);
  const [askWeightBreak, setAskWeightBreak] = useState<any>([]);
  const [bidWeightBreak, setBidWeightBreak] = useState<any>([]);
  const [variables, setVariables] = useState<any>();
  const [selectedMetal, setSelectedMetal] = useState<any>("");
  const [defaultRule, setDefaultRule] = useState<boolean>(false);
  const [selectedBenchMark, setSelectedBenchMark] = useState<string>("XAG");
  const [variableData, setVariableData] = useState<any>();
  const [refreshPricingMaster, setRefreshPricingMaster] = useState<any>();
  const [calculatedAskPrice, setCalculatedAskPrice] = useState<number>(0);
  const [benckMarkSingle, setBenckMarkSingle] = useState<any>();
  const [auxilary, setAuxilary] = useState<any>();
  const [selectFeeder, setSelectedFeeder] = useState<string>("");
  const [loading, setLoading] = useState<any>(true);

  const { logout, permissions, userData, tokens } = useAuthStore();

  const [
    nsvAskZus,
    nsvBidZus,
    spreadDefaultBid,
    setNsvDefaultAsk,
    setNsvDefaultBid,
    setSpreadDefaultBid,
    uom,
    currency,
    uomConversions,
    setUOMConversions,
  ] = useStore((state: any) => [
    state.nsvDefaultAsk,
    state.nsvDefaultBid,
    state.spreadDefaultBid,
    state.setNsvDefaultAsk,
    state.setNsvDefaultBid,
    state.setSpreadDefaultBid,
    state.uom,
    state.currency,
    state.uomConversions,
    state.setUOMConversions,
  ]);

  let history = useHistory();

  useEffect((): any => {
    getWeightBreakData();
    // getCustomerVariable();
  }, []);

  useEffect((): any => {
    getWeightBreakData();
  }, [price]);



  // ==== (1) ====
  const [aux, setAux] = useState<any>([]);
  const [benVal, setBenVal] = useState<any>([]);
  const [benValAll, setBenValAll] = useState<any>([]);
  const [benFeedVal, setBenFeedVal] = useState<any>([]);
  const [feeds, setFeeds] = useState<any>([]);
  const [metVal, setMetVal] = useState<any>([]);
  const [partnerVal, setPartnerVal] = useState<any>([]);
  const [uomVal, setUOMVal] = useState<any>([]);

  // ==== (2) ====
  // const getFeed = (name: any) => {
  //   switch (name) {
  //     case "1":
  //       return "morning star";
  //     case "2":
  //       return "cdf";

  //     default:
  //       return "";
  //   }
  // };

  useEffect(() => {
    console.log("benFeedValbenFeedValbenFeedValbenFeedVal ", benFeedVal);

  }, [benFeedVal])


  let usdInr = aux.find((element: any) => element?.conversion === "USD/INR");

  let usdI = aux.filter((element: any) => element?.conversion === "USD/INR");



  // ==== (3) ====
  // const benchMarkInd =
  //   !!selectedMetal && !!selectFeeder && Array.isArray(aux)
  //     ? aux.find(
  //         (data: any) =>
  //           data?.metal === selectedMetal &&
  //           data?.feed === getFeed(selectFeeder)
  //       )
  //     : [];

  // const benchMarkInd1 =
  //   !!selectedMetal && Array.isArray(aux)
  //     ? aux.find(
  //       (data: any) =>
  //         data?.metal === selectedMetal

  //     )
  //     : [];

  const benchMarkInd =
    !!selectedBenchMark && Array.isArray(aux)
      ? aux.find(
        (data: any) =>
          data?.conversion.split("/")[0] === selectedBenchMark

        )
      : [];


  // ==== (5) ====
  const fetchAuxiliaryData = async (operation?: any) => {
    try {

      if (operation === "refresh") {
        const allVal: any = await Promise.allSettled([
          PricingService.getBenchmarkFeedData(),
        ]);
        const auxData: any = await PricingService.getAuxilaryData(
          allVal?.[0]?.value?.data?.data[0].feedCode || "CDF"
          // allVal?.[0]?.value?.data?.data[0].feedCode || "MORNING_STAR"
        );
        setAux(auxData?.data?.data);
        return;
      }

      let params = {
        isPaginated: false,
      };
      const query = makeQuery(params);

      const allVal: any = await Promise.allSettled([
        PricingService.getMetalMasterData(tokens, query),
        PricingService.getBenchData(tokens, 0, false),
        PricingService.getAuxilaryData(selectFeeder || "CDF"),
        // PricingService.getAuxilaryData(selectFeeder || "MORNING_STAR"),
        PricingService.getPartnerMasterData(tokens, query),
        PricingService.getUOMMasterData(),
        PricingService.getFeedMaster(),
        PricingService.getBenchmarkFeedData(),
      ]);

      // console.log("ALL SETTLED Metal", allVal?.[0], "Ben", allVal?.[1], "AUXXXXX", allVal?.[2], allVal?.[3], " UOM ", allVal?.[4]);
      console.log("alll", allVal);
      setAux(allVal?.[2]?.value?.data?.data);
      // setBenVal(allVal?.[1]?.value?.data?.data);
      setBenchMarkList(allVal?.[1]?.value?.data?.data);
      setMetVal(allVal?.[0]?.value?.data?.data);
      setPartnerVal(allVal?.[3]?.value?.data?.data);
      setUOMVal(allVal?.[4]?.value?.data?.data);
      setFeeds(allVal?.[5]?.value?.data?.data);
      setBenFeedVal(allVal?.[6]?.value?.data?.data);
      if (operation === "create") {
        handleChangeMetal(allVal?.[0]?.value?.data?.data[0].metalCode);
        handleBenchmark(allVal?.[1]?.value?.data?.data[0].basisCode);
        handleFeeder(allVal?.[6]?.value?.data?.data[0].feed);
        const auxData: any = await PricingService.getAuxilaryData(
          allVal?.[6]?.value?.data?.data[0].feed || "CDF"
          // allVal?.[6]?.value?.data?.data[0].feed || "MORNING_STAR"
        );
        setAux(auxData?.data?.data);
      }
    } catch (error) {
      console.error(error);
      toast.error("Something Went Wrong!");
    }
  };

  const setBenchMarkList = (list: any) => {
    // console.log("BenchMark List ", list);
    setBenValAll(list);
    const ids = list.map((o: any) => o.basisCode);
    const filtered = list.filter(
      ({ basisCode }: any, index: any) => !ids.includes(basisCode, index + 1)
    );
    // console.log(filtered);
    setBenVal(filtered);
  };

  // ==== (Metal table onchage) ====
  const handleValuePrices = (data: any, id: any, type: any) => {
    let value = [...aux];
    if (type === Types.METAL_ASK) {
      let index = value.findIndex((data) => data.id === id);
      value[index] = { ...value[index], ask: data };
      setAux(value);
    }
    if (type === Types.METAL_BID) {
      let index = value.findIndex((data) => data.id === id);
      value[index] = { ...value[index], bid: data };
      setAux(value);
    }
    if (type === Types.USD_ASK) {
      let value = [...usdI];
      value[0] = { ...value[0], ask: data };
      setAux(value);
    }
    if (type === Types.USD_BID) {
      let value = [...usdI];
      value[0] = { ...value[0], bid: data };
      setAux(value);
    }
  };

  const callConvertUom = async (from: string, to: string) => {
    let uomConLocal: [any] = uomConversions;
    if (from && to) {
      const isFound = uomConLocal.some((el: any) => el.des === `${from}_${to}`);
      if (!isFound) {
        const result: any = await customFunctions.convertUOM(from, to);
        uomConLocal.push({
          des: `${from}_${to}`,
          from: from,
          to: to,
          value: result.toString(),
        });
        setUOMConversions(uomConLocal);
      }
    }
    let obj = uomConLocal.find((o: any) => o.des === `${from}_${to}`);
    return obj.value ?? 0;
  };

  const getWeightBreakData = async () => {
    try {
      let data: any = await PricingService.getWeightBreakDataByID(id);
      setLoading(true);
      await Promise.all([
        callConvertUom("g", "g"),
        callConvertUom("g", "kg"),
        callConvertUom("kg", "g"),
        callConvertUom("ozt", "g"),
      ]);

      if (!!data?.data?.data) {
        let responseData = data.data.data;
        const finalRes =
          !!responseData?.length &&
          responseData.filter((bid: any) => bid.askOrBid === "BID");
        const finalAsk =
          !!responseData?.length &&
          responseData.filter((bid: any) => bid.askOrBid === "ASK");
        if (!!finalRes?.length) {
          setBidWeightBreak(finalRes);
        }
        if (!!finalAsk?.length) {
          setAskWeightBreak(finalAsk);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getCustomerVariable = async () => {
    let data: any = await PricingService.getVariableData();
    if (data.data.success && data.data.data) {
      let responseData = data.data.data;
      setVariables(responseData);
    }
  };

  const handleQuantityChange = (quantity: any) => {
    setQuantity(quantity);
  };

  const handleSingleBench = (bench: any, aux: any) => {
    setBenckMarkSingle(bench);
    setAuxilary(aux);
  };

  /////////////////////////////////////GET PRICE\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

  const handlePrice = (price: any) => {
    setPrice(price);
  };

  // ====== newImp ([1]) ======
  const handleFeeder = async (feeder: any) => {
    console.log("my feeder ", feeder);

    setSelectedFeeder(feeder);
    const auxData: any = await PricingService.getAuxilaryData(
      feeder || "CDF"
      // feeder || "MORNING_STAR"
    );
    // console.log("my feeder data", auxData?.data?.data);
    setAux(auxData?.data?.data);
  };

  const handleBidRules = (rules: any) => {
    setBidRules(rules);
  };

  const handleRrefreshPricingMaster = (e: any) => {
    setRefreshPricingMaster(e);
  };

  const handleAskRules = (rules: any) => {
    setAskRules(rules);
  };

  const handleRefreshBid = (refresh: number) => {
    let tempRefresh = bidRefresh + 1;
    setBidRefresh(tempRefresh);
  };

  const handleRefreshAsk = (refresh: number) => {
    let tempRefresh = askRefresh + 1;
    setAskRefresh(tempRefresh);
  };

  const handleChangeMetal = (metal: string) => {
    setSelectedMetal(metal);

    if (benValAll.length > 0) {
      const newBenchMark = benValAll?.filter(
        (data: any) => data.metalCode === metal
      )[0];
      console.log("newbench", newBenchMark);
      if (newBenchMark) {
        handleBenchmark(newBenchMark.basisCode);
      } else {
        toast.error("No benchMark found for given metal code");
      }
    }
  };

  const handleBenchmark = async (bench: string) => {
    console.log("00", bench);
    console.log("01", benFeedVal.length);
    setSelectedBenchMark(bench);
    if (benFeedVal.length > 0) {
      const newBenchMarkFeed = benFeedVal?.filter(
        (data: any) => data.basisCode === bench
      )[0];
      console.log("11", newBenchMarkFeed);
      handleFeeder(newBenchMarkFeed.feed);
    }
  };

  const handleVariableData = (data: any) => {
    setVariableData(data);
  };

  const handleDefaultRuleChange = (defaultset: any) => {
    setDefaultRule(defaultset);
  };

  const setLoadingView = (data: any) => {
    console.log("set loading view ", data);
    setLoading(data);
  };

  const saveAllData = () => {
    console.log("saveAllData !!!");
    childRefPriceHeader.current.saveAllDataHeader();
    childRefPriceAskSection.current.saveAllDataAsk();
    childRefPriceBidSection.current.saveAllDataBid();
    childRefAskFareWeightBreak.current.saveAllAskFareWeight();
    childRefBidFareWeightBreak.current.saveAllAskFareWeight();
  };

  const fetchPricingDataInMasterStatusWhenDataSaved = () => {
    console.log("fetch pricing master again !!!");
    childRefPriceMasterStatusSection.current.fetchPricingRulesDataWhenSaved();
  };

  const childRefPriceHeader: any = useRef();
  const childRefPriceAskSection: any = useRef();
  const childRefPriceBidSection: any = useRef();
  const childRefPriceMasterStatusSection: any = useRef();
  const childRefAskFareWeightBreak: any = useRef();
  const childRefBidFareWeightBreak: any = useRef();
  return (
    <Layout type={"PricingModule"}>
      <div className="">
        <button
          onClick={() => {
            history.goBack();
          }}
          className="-mr-2 flex items-center rounded text-white font-bold text-xs uppercase"
        >
          <svg
            className="w-7"
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fill-rule="evenodd"
            clip-rule="evenodd"
          >
            <path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" />
          </svg>{" "}
          {/* <span className="text-black">Back</span> */}
        </button>
        <div className="title flex items-center mb-5">
          {/* <svg
            className="w-14 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            />
          </svg> */}
          <div className="text">
            <h1 className="text-3xl font-bold flex items-center tracking-tight">
              {" "}
              Add Pricing Rules
            </h1>
            <p className="">
              Define your Pricing rules for the ask and bid prices
            </p>
          </div>
        </div>

        <PricingMaster
          refreshPricing={refreshPricingMaster}
          getChangedData={handleVariableData}
          askRulesSave={askRules}
          bidRulesSave={bidRules}
          getPrice={handlePrice}
          getQuantity={handleQuantityChange}
          getRefreshBid={handleRefreshBid}
          getRefreshAsk={handleRefreshAsk}
          handleSingleBench={handleSingleBench}
          aux={aux}
          usdInr={usdInr}
          benVal={benVal}
          benValAll={benValAll}
          benFeedVal={benFeedVal}
          metVal={metVal}
          feeds={feeds}
          partnerVal={partnerVal}
          benchMarkInd={benchMarkInd}
          fetchAuxiliaryData={fetchAuxiliaryData}
          handleValuePrices={handleValuePrices}
          handleFeeder={handleFeeder}
          handleChangeMetal={handleChangeMetal}
          // handleDisable={handleDisable}
          selectedMetal={selectedMetal}
          selectFeeder={selectFeeder}
          handleBenchmark={handleBenchmark}
          selectedBenchMark={selectedBenchMark}
          uomVal={uomVal}
          ref={childRefPriceHeader}
          setLoading={setLoadingView}
          fetchPricingDataInMasterStatusWhenDataSaved={
            fetchPricingDataInMasterStatusWhenDataSaved
          }
        />
        {benFeedVal.length > 0 && (
          <>
            <Collapsible trigger="Ask Price Section" className="mt-4 shadow-sm">
              {uomConversions.length > 0 && (
                <div className="p-3 pb-0 border font-bold uppercase bg-white">
                  {nsvAskZus != -1 && (
                    <AskPriceSection
                      onChangeCalculatedAskPrice={(value: number) =>
                        setCalculatedAskPrice(value)
                      }
                      variableData={variableData}
                      selectedMetal={selectedMetal}
                      customVariables={variables}
                      weightBreakData={askWeightBreak}
                      getAskRules={handleAskRules}
                      quantity={quantity}
                      price={price}
                      refreshPage={askRefresh}
                      benckMarkSingle={benckMarkSingle}
                      auxilary={auxilary}
                      benchMarkInd={benchMarkInd}
                      aux={aux}
                      usdInr={usdInr}
                      ref={childRefPriceAskSection}
                    />
                  )}

                  <AskFareWeightBreak
                    onNewWeightAdded={getWeightBreakData}
                    data={askWeightBreak}
                    uomVal={uomVal}
                    title={"Ask-Fare / Weight Break"}
                    type="ASK"
                    ref={childRefAskFareWeightBreak}
                  />
                </div>
              )}
            </Collapsible>

            <Collapsible trigger="Bid Price Section" className="mt-4 shadow-sm">
              {uomConversions.length > 0 && (
                <div className="p-3 pb-0 border font-bold uppercase bg-white">
                  {spreadDefaultBid != -1 && nsvBidZus != -1 && (
                    <BidPriceSection
                      variableData={variableData}
                      selectedMetal={selectedMetal}
                      customVariables={variables}
                      weightBreakData={bidWeightBreak}
                      getBidRules={handleBidRules}
                      quantity={quantity}
                      price={price}
                      refreshPage={bidRefresh}
                      benckMarkSingle={benckMarkSingle}
                      auxilary={auxilary}
                      finalAskPrice={calculatedAskPrice}
                      benchMarkInd={benchMarkInd}
                      aux={aux}
                      usdInr={usdInr}
                      ref={childRefPriceBidSection}
                    />
                  )}
                  <AskFareWeightBreak
                    onNewWeightAdded={getWeightBreakData}
                    uomVal={uomVal}
                    data={bidWeightBreak}
                    title={"Bid-Fare / Weight Break"}
                    type="BID"
                    ref={childRefBidFareWeightBreak}
                  />
                </div>
              )}
            </Collapsible>
            <div>
              <PricingMasterStatus
                refreshPricingMaster={handleRrefreshPricingMaster}
                saveAllData={saveAllData}
                ref={childRefPriceMasterStatusSection}
              />
            </div>
          </>
        )}
      </div>
      {loading ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h2 className="text-center text-white text-xl font-semibold">
            Loading...
          </h2>
          <p className="w-1/3 text-center text-white">
            This may take a few seconds, please don't close this page.
          </p>
        </div>
      ) : (
        ""
      )}
    </Layout>
  );
};

export default Pricing;
