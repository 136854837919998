import { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-datetime/css/react-datetime.css";
import AddNewMaster from "./shared/AddNewMaster";
import InpData from "./shared/InpData";
import { DATA_TYPE, HEAD_CELLS as headCells } from "./shared/utils";
import Label from "./shared/DisplayRuleMasterDetails";
import {
  CreateTrade,
  DeleteTradeRuleMaster,
  GetAllTradeRule,
  EditTradeRuleMaster,
} from "../Services/Trade.Service";
import LoadDelayCircle from "./shared/LoadDelayCircle";

const tempData = {
  Effect_From_Date: new Date().toISOString(),
  Effect_To_Date: new Date().toISOString(),
  metal: "Ag",
};

const TradeRuleMasterHome: React.FC = () => {
  const history = useHistory();
  const [rules, setRules] = useState<any>([]);
  const [update, setUpdate] = useState(false);
  const [createData, setCreateData] = useState<any>(tempData);
  const [selectedId, setSelectedId] = useState<any>();
  const [openDialog, setOpenDialog] = useState(false);
  const [validator, setValdator] = useState(false);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    localStorage.removeItem("editId");
    fetchTrade();
  }, []);

  useEffect(() => {
    if (!!update) {
      fetchTrade();
      setUpdate(false);
    }
  }, [update]);

  const fetchTrade = async () => {
    try {
      let allRef: any = await GetAllTradeRule();
      setRules(allRef?.data?.data);
      setLoading(false);
    } catch (error) {
      toast.error(error);
    }

  };



  const onChange = (e: any, type: any) => {

    
    if (type === DATA_TYPE.START_DATE) {
      if (
        moment(createData?.Effect_To_Date).valueOf() >=
        moment(new Date(e)).valueOf()
      ) {
        setValdator(false);
        return setCreateData({
          ...createData,
          Effect_From_Date: new Date(e).toISOString(),
        });
      }
      return setValdator(true);
    }
    if (type === DATA_TYPE.END_DATE) {
      if (
        moment(createData?.Effect_From_Date).valueOf() <=
        moment(new Date(e)).valueOf()
      ) {
        setValdator(false);
        return setCreateData({
          ...createData,
          Effect_To_Date: new Date(e).toISOString(),
        });
      }

      return setValdator(true);
    }
    if (e.target.name === "priority" || e.target.name==="source") {
      return setCreateData({
        ...createData,
        [e.target.name]: parseInt(e.target.value),
      });
    }
    setCreateData({
      ...createData,
      [e.target.name]: e.target.value,
    });
  };

  const onCreateRule = async () => {
    let data = [...rules];
   
    data.push({ ...createData });
    try {
      await CreateTrade({ ...createData , Effect_From_Date:moment(createData.Effect_From_Date).format("YYYY-MM-DD HH:mm:ss"),
      Effect_To_Date: moment(createData.Effect_To_Date).format("YYYY-MM-DD HH:mm:ss"),});
      setRules(data);
      setCreateData({});
      setUpdate(true);
      toast.success("Added Successfully.");
    } catch (error) {
      toast.error("Invalid input");
      console.error(error);
    } finally {
      setOpenDialog(false);
    }
  };

  const handleDelRule = async (index: any) => {
    try {
      let delData = [...rules];
      const id = parseInt(delData[index].Rule_ref);
      await DeleteTradeRuleMaster(id);
      delData.splice(index, 1);
      setRules(delData);
    } catch (ex) {
      toast.error(ex);
    }
  };

  const onEdit = (id: any, data: any) => {
    setSelectedId(id);
    setCreateData(data);
    
    
  };
  const addNewRule = () => setOpenDialog(true);
  const handleClosed = () => {
    setOpenDialog(false);
    setCreateData(tempData);
  };

  const ruleDialog = () => {
    return (
      <AddNewMaster
        handleChange={onChange}
        handleCreateRule={onCreateRule}
        onClose={handleClosed}
        createData={createData}
        validate={validator}
        rules={rules}
      />
    );
  };

  const UpdateHandler = async (idx: any) => {
    try {
   
      let data = [...rules];
      data[idx] = { ...createData };
    
      await EditTradeRuleMaster(parseInt(data[idx].Rule_ref), {
        ...createData,
        Effect_From_Date:moment(createData.Effect_From_Date).format("YYYY-MM-DD HH:mm:ss"),
        Effect_To_Date: moment(createData.Effect_To_Date).format("YYYY-MM-DD HH:mm:ss"),
      });
      toast.success("Successfully Updated");
      setRules(data);
      setSelectedId(null);
      setCreateData({});
    } catch (err) {
      toast.error(err);
    }
  };

  const viewClick = (id: any) => {
    localStorage.setItem("editId", JSON.stringify(id))
    history.push(`/trade/${id}`);
  };

  const handleEditClosed = () => {
    setSelectedId(null);
  };
  return (
    
    <Layout>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
      {loading && <LoadDelayCircle/>}
        <h1 className="text-2xl font-bold tracking-tight">
          {"Trading Rule Master"}
        </h1>
        <button
          className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
          onClick={addNewRule}
          type="button"
        >
          + Add Trading Rules
        </button>
      </div>
      <div className="border bg-white rounded border-gray-200 shadow">
        <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-sm table-auto">
          <thead>
            <tr className="text-left border-b border-gray-300 text-xs">
              {headCells.map((header) => {
                return <th className="px-2 py-3">{header.label}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {rules &&
              rules.map((ebl: any, index: any) => {
                return (
                  <>
                    {ebl.Rule_ref === selectedId ? (
                      <InpData
                        selectedIndex={index}
                        handleUpdate={UpdateHandler}
                        ebl={createData}
                        closeEdit={handleEditClosed}
                        handleChange={onChange}
                      />
                    ) : (
                      <Label
                        ebl={ebl}
                        selectedIndex={index}
                        viewClick={viewClick}
                        delRule={handleDelRule}
                        hanldleEdit={onEdit}
                      />
                    )}
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
      {!!openDialog && ruleDialog()}
    </Layout>
  );
};

export default TradeRuleMasterHome;
