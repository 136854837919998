import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuthStore } from "../../Store/AuthStore";
import Layout from "../Layout/Layout";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../Components-ui/Loader";
import FundManagementService from "./Services/FundManagementService";
import ReactJson from "react-json-view";
import { Pagination } from "../../Components-ui/Pagination";
import Modal from "../../Components-ui/Modal/Modal";
import UserListService from "../Userlist/Services/UserList.Service";
import moment from "moment";
import HasAnyAccess from "../../Components-ui/hasAnyAccess";

export const SingleFundManagementView: React.FC = () => {
  let id: any = useParams();
  const [show, setModalStatus] = useState<any>(false);
  const [children, setChildren] = useState<any>();
  const [selectedRecord, setSelectedRecord] = useState<any>({});
  const [payLoad, setPayload] = useState<any>({});
  const [apiLogsData, setApiLogsData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [meta, setMetaData] = useState<any>({});
  const { tokens } = useAuthStore();
  const history = useHistory();
  const [Loading, setLoading] = useState<any>(true);
  const [partnerList, setPartnerList] = useState<any>([]);

  const getFundManagementData = async () => {
    await FundManagementService.getTransactionSingleRecord(tokens, id)
      .then((response: any) => {
        setSelectedRecord(response.data.data.result[0]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };
  const updateTxnStatus = async (status: string) => {
    if (selectedRecord?.transactionMasterId && selectedRecord?.id) {
      setLoading(true);
      let data = {
        transactionId: selectedRecord?.transactionMasterId ?? "",
        transactionLogId: selectedRecord?.id ?? "",
        status,
      };
      await FundManagementService.updateTxnStatus(tokens, data)
        .then((response: any) => {
          get_partner_list();
          getFundManagementData();
          getApiLogsData(currentPage);
          toast.success("Transaction status updated");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    }
  };

  const getApiLogsData = async (page: number) => {
    await FundManagementService.getApiLogsData(tokens, page, id)
      .then((response: any) => {
        setApiLogsData(response.data.data.result);
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPage(response.data.meta.page);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const get_partner_list = async () => {
    await UserListService.getPartnerList(tokens)
      .then((response: any) => {
        setPartnerList(response.data.data);
      })
      .catch((err) => {
        // toast.error(err.response.data.errors[0].title);
      });
  };

  useEffect(() => {
    setLoading(false);
    get_partner_list();
    getFundManagementData();
    getApiLogsData(currentPage);
  }, []);

  const paginationFilter = async (page: number) => {
    setCurrentPage(page);
    getApiLogsData(page);
  };

  const labelColor = (label: any) => {
    switch (label) {
      case "CREATED":
        return "bg-blue-200 text-blue-600";
      case "PENDING":
        return "bg-yellow-200 text-yellow-600";
      case "ACCEPTED":
        return "text-green-600 bg-green-200";
      case "REJECTED":
        return "bg-red-400 text-red-900";
      default:
        return "bg-gray-200";
    }
  };

  const childrenElements = () => {
    setChildren(
      <div className="w-full">
        <h1 className="font-bold">Transaction Api Log ID : {payLoad.id}</h1>
        <div className="flex mb-3 bg-white border-purple-900 border-t-4 card p-2 pb-1 pr-3.5 pt-4 rounded shadow-2xl">
          {payLoad?.request && (
            <div className="flex md:w-1/2 flex-wrap -mx-3 mb-2">
              <div className="pb-3 w-full px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="user-id"
                >
                  Request
                </label>
                <pre>{JSON.stringify(payLoad?.request, undefined, 8)}</pre>
                {/*<ReactJson collapsed={true}*/}
                {/*           src={JSON.parse(payLoad.payload.replace('\'', ''))}/>*/}
              </div>{" "}
            </div>
          )}
          {payLoad?.response && (
            <div className="flex md:w-1/2 flex-wrap -mx-3 mb-2">
              {" "}
              <div className="pb-3 w-full px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="user-id"
                >
                  Response
                </label>
                <pre>{JSON.stringify(payLoad?.response, undefined, 8)}</pre>
                {/*<ReactJson collapsed={true}*/}
                {/*           src={JSON.parse(payLoad.payload.replace('\'', ''))}/>*/}
              </div>{" "}
            </div>
          )}
        </div>
        {!payLoad?.request && !payLoad?.response && (
          <div className="mb-10 font-mono text-center text-gray-800 w-full">
            No data to preview
          </div>
        )}
      </div>
    );
  };

  const viewModalElements = async (data: any) => {
    setPayload(data);
    childrenElements();
    setModalStatus(true);
  };

  useEffect(() => {
    childrenElements();
  }, [payLoad]);

  const hideModal = () => {
    setModalStatus(false);
  };

  return (
    <Layout type={"PaymentModule"}>
      <ToastContainer />
      <div>
        <button
          onClick={() => {
            history.goBack();
          }}
          className="-mr-2 flex items-center rounded text-white font-bold text-xs uppercase"
        >
          <svg
            className="w-7"
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fill-rule="evenodd"
            clip-rule="evenodd"
          >
            <path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" />
          </svg>{" "}
        </button>
      </div>
      {Loading ? (
        <Loader />
      ) : (
        <div className="w-full">
          <div className="mb-3 bg-white border-purple-900 border-t-4 card p-2 pb-1 pr-3.5 pt-4 rounded shadow-2xl">
            <fieldset className="border border-gray-300 flex min-w-0 mt-3 p-3 p-1 pb-4 rounded w-full">
              <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
                {"Transaction Logs Details"}
              </legend>
              <div className="w-full">
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="pb-3 w-full md:w-1/6 px-3 mb-6 md:mb-0">
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="user-id"
                    >
                      Transaction master ID
                    </label>
                    <input
                      className="bg-gray-100 mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="name"
                      type="text"
                      placeholder="Transaction master ID"
                      disabled={true}
                      readOnly={true}
                      value={selectedRecord?.transactionMasterId}
                      autoComplete="off"
                    />
                  </div>
                  <div className="pb-3 w-full md:w-1/6 px-3 mb-6 md:mb-0">
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="user-id"
                    >
                      Transaction ID
                    </label>
                    <input
                      className="bg-gray-100 mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="name"
                      type="text"
                      placeholder="Transaction ID"
                      disabled={true}
                      readOnly={true}
                      value={selectedRecord?.id}
                      autoComplete="off"
                    />
                  </div>
                  <div className="pb-3 w-full md:w-1/6 px-3 mb-6 md:mb-0">
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="user-id"
                    >
                      Datetime
                    </label>
                    <input
                      className="bg-gray-100 mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="name"
                      type="text"
                      placeholder="Date"
                      disabled={true}
                      readOnly={true}
                      value={selectedRecord?.date}
                      autoComplete="off"
                    />
                  </div>
                  <div className="pb-3 w-full md:w-1/6 px-3 mb-6 md:mb-0">
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="user-id"
                    >
                      Amount
                    </label>
                    <input
                      className="bg-gray-100 mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="name"
                      type="text"
                      placeholder="Amount"
                      disabled={true}
                      readOnly={true}
                      value={selectedRecord?.amount}
                      autoComplete="off"
                    />
                  </div>
                  <div className="pb-3 w-full md:w-1/6 px-3 mb-6 md:mb-0">
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="user-id"
                    >
                      Type
                    </label>
                    <input
                      className="bg-gray-100 mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="name"
                      type="text"
                      placeholder="Type"
                      disabled={true}
                      readOnly={true}
                      value={selectedRecord?.type}
                      autoComplete="off"
                    />
                  </div>

                  <div className="pb-3 w-full md:w-1/6 px-3 mb-6 md:mb-0">
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="user-id"
                    >
                      Method
                    </label>
                    <input
                      className="bg-gray-100 bg-gray-100 mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="name"
                      type="text"
                      placeholder="Method"
                      disabled={true}
                      readOnly={true}
                      value={selectedRecord?.method}
                      autoComplete="off"
                    />
                  </div>

                  {selectedRecord?.status === "TRANSACTION_APPROVAL_PENDING" &&
                  HasAnyAccess(["transaction.approve"]) ? (
                    <div className="p-3 w-full md:w-6/6 px-3 mb-6 md:mb-0 flex justify-end">
                      <button
                        className=" border border-green-300 rounded-md bg-green-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => updateTxnStatus("APPROVED")}
                      >
                        Approve
                      </button>
                      <button
                        className=" border border-red-300 rounded-md bg-red-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => updateTxnStatus("REJECTED")}
                      >
                        Reject
                      </button>
                    </div>
                  ) : null}
                  {selectedRecord?.status ===
                    "TRANSACTION_VERIFICATION_PENDING" &&
                  HasAnyAccess(["transaction.verify"]) ? (
                    <div className="p-3 w-full md:w-6/6 px-3 mb-6 md:mb-0 flex justify-end">
                      <button
                        className=" border border-green-300 rounded-md bg-green-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => updateTxnStatus("VERIFICATION_APPROVED")}
                      >
                        Verify
                      </button>
                      <button
                        className=" border border-red-300 rounded-md bg-red-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => updateTxnStatus("REJECTED")}
                      >
                        Reject
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </fieldset>
            <div className="">
              <fieldset className="bg-gray-100 p-3 min-w-0 border rounded p-1 border-gray-300 pb-4 flex mt-3">
                <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
                  {"Transaction Api Logs"}
                </legend>
                <div className="w-full">
                  <div className="overflow-x-auto">
                    <table className="whitespace-nowrap rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs ">
                      <thead>
                        <tr className="text-left border-b border-gray-300">
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            ID
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Time
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Event
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Provider Status
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            EBL Status
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Status
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            payload
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {0 !== apiLogsData.length ? (
                          apiLogsData.map((log: any, index: number) => {
                            return (
                              <tr
                                key={index}
                                className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                              >
                                <td className="py-3 px-3 text-left relative">
                                  {log.id}
                                </td>
                                <td className="py-3 px-3 text-left relative">
                                  {moment(log.apiTime).format(
                                    "DD-MM-YYYY HH:mm:ss"
                                  ) || "N/A"}
                                </td>
                                <td className="py-3 px-3 text-left relative">
                                  {log.event}
                                </td>
                                <td className="py-3 px-3 text-left relative">
                                  {log.providerStatus}
                                </td>
                                <td className="py-3 px-3 text-left relative">
                                  <span
                                    className={`mt-1.5 text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${labelColor(
                                      log.eblStatus
                                    )}`}
                                  >
                                    {log.eblStatus}
                                  </span>
                                </td>
                                <td className="py-3 px-3 text-left relative">
                                  <span
                                    className={`mt-1.5 text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${labelColor(
                                      log.status
                                    )}`}
                                  >
                                    {log.status}
                                  </span>
                                </td>
                                <td className="py-3 px-3 text-left relative">
                                  <button
                                    className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => {
                                      viewModalElements(log);
                                    }}
                                  >
                                    View
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                            <td
                              colSpan={8}
                              className="text-center py-3 px-3 text-left relative"
                            >
                              No data to display
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    meta={meta}
                    handlePageChange={(number: number) => {
                      paginationFilter(number);
                    }}
                  />
                </div>
              </fieldset>
            </div>
          </div>
          <Modal
            size={"large"}
            show={show}
            handleClose={hideModal}
            children={children}
          />
        </div>
      )}
    </Layout>
  );
};

export default SingleFundManagementView;
