import React from "react";
import "./table.css";
import moment from "moment";
import { formatAmount, formatMoney, formatQuantity } from "../utils";
import ReactTooltip from "react-tooltip";
import { hasAccess } from "../../../Components-ui/hasAnyAccess";
type Props = {
  data: any;
  metalCode?: any;
  metalName?: any;
  fromDate?: any;
  toDate: any;
  downloadReport: any;
};

const TradeSummaryReport = ({
  data,
  metalCode,
  metalName,
  fromDate,
  toDate,
  downloadReport,
}: Props) => {
  const isColumnHidden = hasAccess(["management.report.view"]);
  return (
    <div className={"border-t"}>
      <div className="flex justify-between px-3 py-2 ">
        <p className="text-lg font-bold">
          {metalName}{" "}
          <span className="text-sm font-normal">
            ({moment(new Date(fromDate)).format("DD-MM-YYYY hh:mm A")} To{" "}
            {moment(new Date(toDate)).format("DD-MM-YYYY hh:mm A")})
          </span>
        </p>
        {data?.cumulativeTable?.data?.length ? (
          <button
            className="text-white rounded-md bg-green-400 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
            type="button"
            onClick={downloadReport}
          >
            Download
          </button>
        ) : (
          ""
        )}
      </div>
      <div className="overflow-x-auto">
        <table className="rounded w-full mx-auto  text-gray-200 text-xs table-auto ">
          <thead>
            <tr className="text-left border-b border-gray-300 bg-gray-800">
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Txn Type
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Transaction Count
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Order Status
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                US$/₹
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Metal Rate US$/Ozt
              </th>
              {isColumnHidden ? null : (
                <>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                    Loco Premium (Discount) US$/Ozt
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                    <a data-tip data-for="omr_tooltip" className="z-50">
                      Order Metal Rate ₹/g
                    </a>
                    <div className="z-50">
                      <ReactTooltip
                        id="omr_tooltip"
                        place="top"
                        type="dark"
                        effect="solid"
                        backgroundColor="#000"
                        className="z-50"
                      >
                        <span>
                          Fx Rate x (Metal Rate Ozt + loco premium) in grams
                        </span>
                      </ReactTooltip>
                    </div>
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                    Landed Cost Base ₹/g
                  </th>
                </>
              )}

              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Customer Rate ₹/g
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Customer Quantity Total in g
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Customer Amount (₹)
              </th>
              {isColumnHidden ? null : (
                <>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                    Landed Cost (₹)
                  </th>
                </>
              )}

              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                NSV Amount
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                NSV Per Gram
              </th>
              {isColumnHidden ? null : (
                <>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                    NSV After Discount
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                    NSV After Discount Per Gram
                  </th>
                </>
              )}

              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Quantity in Ozt
              </th>
              {isColumnHidden ? null : (
                <>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                    Customer Amount (US$)
                  </th>
                </>
              )}
              <th className="sticky top-0 px-3 py-2 bg-gray-800 td-sr">
                Price offset ₹ per base UOM
              </th>
              {/* <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Status</th>
            <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Action</th> */}
            </tr>
          </thead>
          <tbody>
            {data?.cumulativeTable?.data?.length ? (
              <>
                {data?.cumulativeTable?.data?.map((item: any, index: any) =>
                  item.status !== "PAYMENT_PENDING" ? (
                    <tr
                      key={index}
                      className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                    >
                      <td className="py-3 px-3 text-left relative capitalize">
                        {item?.transactionType?.toLowerCase()}
                      </td>
                      <td className="py-3 px-3 text-left relative">
                        {item?.transactionCount}
                      </td>
                      <td className="py-3 px-3 text-left relative capitalize">
                        {item?.status?.toLowerCase()}
                      </td>
                      <td className="py-3 px-3 text-right relative">
                        ₹{item?.usdInr?.toFixed(4)}
                      </td>
                      <td className="py-3 px-3 text-right relative">
                        ${formatAmount(item?.metalRateUsdOzt, metalCode)}
                      </td>
                      {isColumnHidden ? null : (
                        <>
                          <td className="py-3 px-3 text-right ">
                            {formatMoney(
                              formatAmount(
                                item?.locoPremiumDiscount,
                                metalCode
                              ),
                              "US$"
                            )}
                          </td>
                          <td className="py-3 px-3 text-right relative">
                            ₹
                            {formatAmount(item?.orderMetalRateInrGm, metalCode)}
                          </td>
                          <td className="py-3 px-3 text-right relative">
                            ₹{item?.landedCostBaseInrGm.toFixed(2)}
                          </td>
                        </>
                      )}

                      <td className="py-3 px-3 text-right relative">
                        ₹{item?.customerRateInrGm.toFixed(2)}
                      </td>
                      <td className="py-3 px-3 text-right relative">
                        {item?.customerQtyTotalInGm.toFixed(4)}g
                      </td>
                      <td className="py-3 px-3 text-right relative">
                        ₹{item?.customerAmtInInr.toFixed(0)}
                      </td>
                      {isColumnHidden ? null : (
                        <>
                          <td className="py-3 px-3 text-right relative">
                            ₹{item?.landedCostAmtInr.toFixed(2)}
                          </td>
                        </>
                      )}

                      <td className="py-3 px-3 text-right relative">
                        ₹{item?.nsvAmount.toFixed(0)}
                      </td>
                      <td className="py-3 px-3 text-right relative">
                        ₹{item?.nsvPerGm.toFixed(2)}
                      </td>
                      {isColumnHidden ? null : (
                        <>
                          <td className="py-3 px-3 text-right relative">
                            ₹{item?.nsvAfterDiscount.toFixed(2)}
                          </td>
                          <td className="py-3 px-3 text-right relative">
                            ₹{item?.nsvPerGmAfterDiscount.toFixed(2)}
                          </td>
                        </>
                      )}

                      <td className="py-3 px-3 text-right relative">
                        {item?.qtyInOzt.toFixed(4)}
                      </td>
                      {isColumnHidden ? null : (
                        <>
                          <td className="py-3 px-3 text-right relative">
                            ${item?.customerAmtInUsd.toFixed(4)}
                          </td>
                        </>
                      )}

                      <td className="py-3 px-3 text-right relative">
                        ₹{item?.hpPrice.toFixed(2)}
                      </td>
                      {/* <td className="py-3 px-3 text-left relative"></td>
                  <td className="py-3 px-3 text-left relative"></td> */}
                    </tr>
                  ) : null
                )}
              </>
            ) : (
              <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                <td colSpan={10} className="text-center py-3 px-3">
                  No data to display
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TradeSummaryReport;
