import React from "react";

interface PropsType {

}

const Loader: React.FC<PropsType> = () => {
    return (
        <>
            <div className="z-50 fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-10000 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                <h2 className="text-center text-white text-xl font-semibold">Loading...</h2>
                <p className="w-1/3 text-center text-white">This may take a few seconds, please don't close this page.</p>
            </div>
        </>
    )
}

export default Loader;
