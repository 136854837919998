import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import MetalInterchange from "./BarToBar/BarToBar";
import MetalInterchangeQuantityToBar from "./QuantityToBar/QuantityToBar";
import MetalInterchangeApprove from './Approve/Approve';

const MetalInterchangeRoutes:React.FC = () => {
    return (
        <Fragment>
            <Route path="/bar-to-bar" exact component={MetalInterchange}/>
            <Route path="/quantity-to-bar" exact component={MetalInterchangeQuantityToBar}/>
            <Route path="/metal-interchange-approve" exact component={MetalInterchangeApprove}/>
        </Fragment>
    )
};

export default MetalInterchangeRoutes;
