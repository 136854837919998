import Http from "../../library/http.library";
import {httpMethods} from "../../library/enum";
import {makeQuery} from "../../Common/util.helper";

export const getDocumentList = async (tokens: any, currentPage: any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/customer/document/type?isPaginated=true&page=${currentPage}`).setToken(tokens.idToken.jwtToken).request();
};

export const filterDocs = async (data: any, currentPage: number, tokens: any) => {
    var query = {
        isPaginated: true,
        ...(data.customerType ? {customerType: data.customerType} : {}),
        ...(data.title ? {title: data.title} : {}),
        ...(data.description ? {description: data.description} : {}),
        ...(data.fromDate ? {fromDate: data.fromDate} : {}),
        ...(data.toDate ? {toDate: data.toDate} : {}),
        ...(data.optional ? {optional: data.optional} : {}),
        ...(data.validDuration ? {validDuration: data.validDuration} : {}),
        ...(data.defaultVerified ? {defaultVerified: data.defaultVerified} : {}),
    }
    const params = makeQuery(query);

    return await Http.setMethod(httpMethods.get).setUrl(`/customer/document/type?page=${currentPage}${params}`).setToken(tokens.idToken.jwtToken).request();

};

export const getSingleDoc = async (id: any, tokens: any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/customer/document/type/${id}`).setToken(tokens.idToken.jwtToken).request();

};

export const updateDoc = async (data: any, tokens: any) => {
    return Http.setMethod(httpMethods.patch).setUrl(`/customer/document/type/${data.docTypeId}`).setData(data).setToken(tokens.idToken.jwtToken).request();
};

export const createDoc = async (data: any, tokens: any) => {
    return await Http.setMethod(httpMethods.post).setUrl(`/customer/document/type`).setData(data).setToken(tokens.idToken.jwtToken).request();
};


const DocumentTypeService = {
    getDocumentList,
    filterDocs,
    getSingleDoc,
    updateDoc,
    createDoc
};

export default DocumentTypeService;
