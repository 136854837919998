
export const getName = (name: any) => {
    switch (name) {
        case "Ag":
            return "Silver";
        case "Au":
            return "Gold";
        case "Pt":
            return "Platinum";
        case "Pd":
            return "Palladium";
        default:
            return "";
    }
}