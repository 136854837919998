import React, { useState, useEffect, createRef, forwardRef, useImperativeHandle } from "react";
import CustomFunction from "../Services/customFunctions";
import { useParams } from "react-router-dom";
import pricingService from "../Services/pricing.service";
import DropownListCustom from "./List";
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isConvertUOMFunction, isCustomDutyFunction } from "../MathHelper";
import { useClearBrowserCache } from "react-clear-browser-cache";
import * as math from 'mathjs'
import customFunctions from "../Services/customFunctions";
import { useStore } from "../../../Store/PricingStore";
const maths: any = math.create(math.all);
// maths.config({
//     number: "BigNumber",
//   });

const parser = maths.parser();
parser.set("midPrice", CustomFunction.midPrice);
parser.set("average", CustomFunction.average);
// parser.set("convertUOM", CustomFunction.convertUOM);

let variables = [
  {
    variableId: "NSV",
    name: "NSV",
    value: 0,
    userDefined:false,
  },
  {
    variableId: "Spread",
    name: "Spread",
    value: 0,
  },
  {
    variableId: "FeederMetalAsk",
    name: "FeederMetalAsk",
    value: 0,
  },
  {
    variableId: "FeederMetalBid",
    name: "FeederMetalBid",
    value: 0,
  },
  {
    variableId: "FeederMetalAskConv",
    name: "FeederMetalAskConv",
    value: 0,
  },
  {
    variableId: "FeederMetalBidConv",
    name: "FeederMetalBidConv",
    value: 0,
  },
  {
    variableId: "FeederCurrencyAsk",
    name: "FeederCurrencyAsk",
    value: 0,
  },
  {
    variableId: "FeederCurrencyBid",
    name: "FeederCurrencyBid",
    value: 0,
  },
  {
    variableId: "CustomReqQty",
    name: "CustomReqQty",
    value: 1,
  },
  {
    variableId: "FeederUOM",
    name: "FeederUOM",
    value: "troy",
  },
  {
    variableId: "CustomerUOM",
    name: "CustomerUOM",
    value: "g",
  },
  {
    variableId: "Feeder",
    name: "Feeder",
    value: "CDF",
  },
  {
    variableId: "FeederCurrency",
    name: "FeederCurrency",
    value: "USD",
  },
  {
    variableId: "FinalAskPrice",
    name: "FinalAskPrice",
    value: 0,
  },
  {
    variableId: "Metal",
    name: "Metal",
    value: "Ag",
  },
  {
    variableId: "Benchmark",
    name: "Benchmark",
    value: "XAG",
  },
];

let customFunction = [
  { ruleName: "round()" },
  { ruleName: "sqrt()" },
  { ruleName: "pow()" },
  { ruleName: "sum()" },
  { ruleName: "median()" },
  { ruleName: "midPrice()" },
  { ruleName: "average()" },
  { ruleName: "convertUOM()" },
  { ruleName: "customDuty()" },
];

let stdFunctions = ["round", "sqrt"];
let currentIndex = 0;
interface PropsType {
  quantity: any;
  price: any;
  getBidRules: any;
  refreshPage: number;
  weightBreakData: any;
  customVariables: any;
  selectedMetal: string;
  variableData: any;
  finalAskPrice: number;
  benckMarkSingle: any;
  auxilary: any;
  benchMarkInd: any;
  aux: any;
  usdInr: any;
}
let selectionStart = 0;
let selectionEnd = 0;


const BidPriceSection = forwardRef(({
  quantity,
  price,
  getBidRules,
  refreshPage,
  weightBreakData,
  customVariables,
  selectedMetal,
  variableData,
  finalAskPrice,
  benckMarkSingle,
  auxilary,
  benchMarkInd,
  aux,
  usdInr
}: PropsType, ref) => {

  const contextValue = useClearBrowserCache();

  let { id } = useParams<any>();
  const [rules, setRules] = useState<any>([]);
  const [radio, setRadio] = useState<any>([]);
  const [selIndex, setSelIndex] = useState<number>(-1);
  const [subRuleTip, setsubRuleTip] = useState<number>(0);
  const [loadingIndex, setloadingIndex] = useState<number>(0);
  const [suggestionRules, setSuggestionRules] = useState<any>([]);
  const [elRefs, setElRefs] = React.useState<any>([]);

  const [totalQ, setTotalQ] = useState<any>();
  const [totalA, setTotalA] = useState<any>();

  const [nsvAskZus, nsvBidZus, spreadDefaultBid, setNsvDefaultAsk, setNsvDefaultBid, setSpreadDefaultBid, uom, currency, uomConversions, setUOMConversions, weightOrAmount, setWeightOrAmount, isruleExpired] = useStore((state: any) =>
    [state.nsvDefaultAsk, state.nsvDefaultBid, state.spreadDefaultBid, state.setNsvDefaultAsk, state.setNsvDefaultBid, state.setSpreadDefaultBid, state.uom, state.currency, state.uomConversions, state.setUOMConversions, state.weightOrAmount, state.setWeightOrAmount, state.isruleExpired]);

  // const [] = useState<any>([])
  useEffect((): any => {
    setVariablesToMathJs();
    // fetchBidRules();
    setSuggestionRules([
      ...JSON.parse(JSON.stringify(addVariablesToSuggestion())),
      ...customFunction,
    ]);
  }, []);

  // useEffect(() => {
  //   // console.log("benchMarkInd ", benchMarkInd);
  //   if (benchMarkInd && benchMarkInd.ask && benchMarkInd.bid) {
  //     calculateNsv(true);
  //     // fetchBidRules();
  //   }

  // }, [benchMarkInd])

  // useEffect(() => {
  //   // console.log("nsvAskZus,nsvBidZus,spreadDefaultBid ", nsvAskZus, nsvBidZus, spreadDefaultBid);
  //   calculateNsv();
  //   fetchBidRules();
  // }, [nsvAskZus, nsvBidZus, spreadDefaultBid]);



  const addVariablesToSuggestion = () => {
    let data: any = [];

    variables.map((variable: any) => {
      data.push({
        ruleName: variable.name,
      });
    });

    if (rules && rules.length > 0) {
      let index = data.findIndex((variable: any) => {
        return variable?.ruleName == rules[currentIndex]?.ruleName
      });
      if (index != -1) {
        data.splice(index, 1);
      }
    }

    return data;
  };
  // const [] = useState<any>([])
  // useEffect((): any => {
  //   fetchBidRules();
  // }, []);

  useEffect((): any => {
    let data: any = [];
    rules.map((rule: any, index: number) => {
      data.push(elRefs[index] || createRef());

      if (rule.isFinalExpression) {
        setRadio(Number(rule.value).toFixed(2));
      }
    });
    setElRefs(data);
  }, [rules]);

  useEffect((): any => {
    if (finalAskPrice) {
      parser.set("FinalAskPrice", finalAskPrice);
      //   calculateNsv();
      // fetchBidRules();
      // setValues();
    }
  }, [finalAskPrice]);

  useEffect((): any => {
    setVariableData();
  }, [variableData, selectedMetal, finalAskPrice]);

  useEffect(() => {


    // console.log("nsvAskZus,nsvBidZus,spreadDefaultBid ", nsvAskZus);
    if (nsvBidZus > -1 && (benchMarkInd && benchMarkInd.ask && benchMarkInd.bid)) {
      console.log("wijeeeeeeee bid", nsvAskZus, nsvBidZus, spreadDefaultBid);
      calculateNsv(true);
    }

    // fetchAskRules();
  }, [nsvAskZus, nsvBidZus, spreadDefaultBid, benchMarkInd ,aux, auxilary,refreshPage]);

  // useEffect((): any => {
  //   calculateNsv();
  //   fetchBidRules(); // when refreshing the askbid price table
  // }, [aux, auxilary]);

  // useEffect((): any => {
  //   calculateNsv();
  //   fetchBidRules();
  // }, [refreshPage]);

  useEffect((): any => {
    setValues();
  }, [price]);

  useEffect(() => {

    if (radio.length > 0) {
      onQuantityChanges();
    }

  }, [radio, weightOrAmount, quantity, uom, currency]);

  const onQuantityChanges = async () => {
    let totalQuantity: any = 0;
    let totalAmount: any = 0;
    if (weightOrAmount == "weight") {
      const result: any = await callConvertUom("g", uom.toString()); //await customFunctions.convertUOM("g", uom.toString());
      const val = result.toString();

      const newQuantity = quantity * val;
      totalQuantity = newQuantity;
      totalAmount = radio * newQuantity;
    } else {
      totalQuantity = (quantity / radio).toFixed(4);
      totalAmount = totalQuantity * radio;
    }

    setTotalA(totalAmount);
    setTotalQ(totalQuantity);
  }

  const callConvertUom = async (from: string, to: string) => {
    let uomConLocal: [any] = uomConversions;
    if (from && to) {
      const isFound = uomConLocal.some((el: any) => el.des === `${from}_${to}`);
      if (!isFound) {
        const result: any = await customFunctions.convertUOM(from, to);
        uomConLocal.push({ des: `${from}_${to}`, from: from, to: to, value: result.toString() })
        setUOMConversions(uomConLocal);
      }
    }
    let obj = uomConLocal.find((o: any) => o.des === `${from}_${to}`);
    return obj.value ?? 0;
  }

  const setValues = () => {
    calculateNsv();

    let tempRules = rules;
    let counter = 0;
    let promise: any = [];
    tempRules.map(async (ruleData: any, index: number) => {
      promise.push(setUpCustomDuty(ruleData.ruleExpression, index));
      promise.push(setUpConvertUom(ruleData.ruleExpression, index));
    });

    Promise.all(promise).then((data: any) => {
      tempRules.map(async (ruleData: any, index: number) => {
        let exp = data[index];
        ruleData.isValid = isValidExpression(exp, ruleData);
        if (ruleData.isValid) {
          ruleData.value = maths.format(calculateValue(exp), { precision: 14 });

          parser.set(ruleData.ruleName, ruleData.value);
        }

        counter++;
        if (tempRules.length == counter) {
          setTimeout(() => {
            setRules(JSON.parse(JSON.stringify(tempRules)));
          }, 1000);
        }
      });
    });
    // rules.map((ruleData: any, index: number) => {
    //   ruleData.isValid = isValidExpression(ruleData.ruleExpression, ruleData);
    //   if (ruleData.isValid) {
    //     ruleData.value = calculateValue(ruleData.ruleExpression);

    //     parser.set(ruleData.ruleName, ruleData.value);
    //   }
    // });
    // setRules(JSON.parse(JSON.stringify(rules)));
  };

  // useEffect((): any => {
  //   calculateNsv();
  //   rules.map((ruleData: any, index: number) => {
  //     ruleData.isValid = isValidExpression(ruleData.ruleExpression, ruleData);
  //     if (ruleData.isValid) {
  //       ruleData.value = calculateValue(ruleData.ruleExpression);
  //     }
  //   });
  //   setRules(JSON.parse(JSON.stringify(rules)));
  // }, [price]);

  useEffect((): any => {
    if (quantity && weightBreakData && nsvBidZus > -1) {
      setVariableData();
      calculateNsv(true);
      // fetchBidRules();
      let updatedRules = rules.map((rule: any) => {
        return {
          ...rule,
          value: calculateValue(rule.ruleExpression),
        };
      });
      setRules(updatedRules);
    }
  }, [quantity, weightBreakData, finalAskPrice, uom, weightOrAmount]);

  const setVariablesToMathJs = () => {
    parser.set("NSV", 0);
    parser.set("FeederMetalAsk", 0);
    parser.set("FeederMetalBid", 0);
    parser.set("FeederCurrencyAsk", 0);
    parser.set("FeederCurrencyBid", 0);
    parser.set("CustomReqQty", 1);
    parser.set("FeederUOM", "troy");
    parser.set("CustomerUOM", "g");
    parser.set("Feeder", "CDF");
    parser.set("FeederCurrency", "USD");
    parser.set("Metal", "Ag");
    parser.set("Benchmark", "XAG");
    parser.set("Spread", 0);
    parser.set("FinalAskPrice", finalAskPrice);
  };

  const setVariableData = () => {
    variables.map((variable: any) => {
      if (
        variable.variableId == "Feeder" &&
        variableData &&
        variableData.feed
      ) {
        variable.value = variableData.feed;
        parser.set("Feeder", variable.value);
      }
      if (
        variable.variableId == "Benchmark" &&
        variableData &&
        variableData.benchmark
      ) {
        variable.value = variableData.benchmark.basisCode;
        parser.set("Benchmark", variable.value);
      }
      if (variable.variableId == "Metal" && selectedMetal) {
        variable.value = selectedMetal;
        parser.set("Metal", variable.value);
      }
      if (variable.variableId == "FinalAskPrice") {
        variable.value = finalAskPrice;
        parser.set("FinalAskPrice", finalAskPrice);
      }
    });
  };

  const fetchBidRules = async () => {
    let data: any = await pricingService.getPricingSubRulesByMasterId(id);
    if (!!data?.data?.data) {
      let finalRes = !!data?.data?.data?.length ? data.data.data.filter((bid: any) => bid.askBidType === "BID") : [];
      let bidRules = finalRes;

      let newRules = rules.filter((rule: any) => rule.isNewRule);
      if (newRules.length > 0) {
        newRules.forEach((rule: any) => {
          bidRules.push(rule);
        });
      }


      // bidRules.map((bidRule: any, index: number) => {
      //   // expressionCheck(bidRule.ruleExpression, index);
      //   let expression = bidRule.ruleExpression;
      //   if (expression.length > 0) {
      //     bidRule.ruleExpression = expression;

      //     bidRule.isValid = isValidExpression(expression, bidRule);
      //     if (bidRule.isValid) {
      //       bidRule.value = calculateValue(expression);
      //     }

      //     setRules(JSON.parse(JSON.stringify(rules)));
      //     return;
      //   }
      //   bidRule.ruleExpression = expression;

      //   bidRule.isValid = false;
      // });

      let dataNew: any = [];
      rules.splice(0, rules.length);
      bidRules.map((rule: any, index: number) => {
        dataNew.push(elRefs[index] || createRef());
        rules.push(rule);

        if (rule.isFinalExpression) {
          setRadio(Number(rule.value).toFixed(2));
          // onChangeCalculatedBidPrice(parseFloat(rule.value));
        }
      });
      setElRefs(dataNew);

      await Promise.all(bidRules.map(async (bidRule: any, index: number) => {
        // debugger


        let expression = bidRule.ruleExpression;
        if (expression.length > 0) {
          bidRule.ruleExpression = expression;
          // convert uom
          expression = await setUpConvertUom(expression, index);
          expression = await setUpCustomDuty(expression, index);
          bidRule.isValid = isValidExpression(expression, bidRule);

          if (bidRule.isValid) {
            bidRule.value = calculateValue(expression);
          }
          setRules(JSON.parse(JSON.stringify(bidRules)));
          return;
        }

        bidRule.ruleExpression = expression;
        bidRule.isValid = false;
      }));

      if (price) {
        setValues();
        return;
      }
      setRules(JSON.parse(JSON.stringify(bidRules)));
    }
  };

  const isValidExpression = (
    expression: string,
    rule: any,
    showError = false
  ) => {
    let tempExpression: string = expression;

    variables.map((variable, index) => {
      let data = tempExpression.indexOf(variable.name);
      if (data != -1 && variable.name) {
        // let found = checkForAnotherMatch(index + 1, tempExpression, variable.name);

        // if (!found) {
        findAndReplaceVariableValue(variable.name, tempExpression, showError);
        // }
      }
    });

    try {
      tempExpression = parser.evaluate(tempExpression);
      let data = calcRules(tempExpression);

      //  const exp = (" " + tempExpression + " ")
      // .replace(/([a-z]{2,})/gi, "Math.$1")
      // .replace(/([^a-z])[a-z]([^a-z])/gi, "$11$2")
      // .replace(/([^a-z])[a-z]([^a-z])/gi, "$11$2");

      // checkAndCreateRulesAsVariable(tempExpression, rule);
      //   if (checkIfVariableValid(expression, rule)) {
      //     return false;
      //   }
      if (calcRules(tempExpression) !== false) {
        checkAndCreateRulesAsVariable(tempExpression, rule);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
    // calcRules(tempExpression);

    // const exp = (" " + tempExpression + " ")
    //   .replace(/([a-z]{2,})/gi, "Math.$1")
    //   .replace(/([^a-z])[a-z]([^a-z])/gi, "$11$2")
    //   .replace(/([^a-z])[a-z]([^a-z])/gi, "$11$2");

    // try {
    // checkAndCreateRulesAsVariable(tempExpression, rule);
    //   if (checkIfVariableValid(expression, rule)) {
    //     return false;
    //   }
    //   return !isNaN(eval(exp));
    // } catch (e) {
    //   return false;
    // }
  };

  const calculateValue = (expression: string) => {
    let tempExpression: string = expression;
    variables.map((variable, index) => {
      let data = tempExpression.indexOf(variable.name);
      if (data !== -1 && variable.name) {
        // let found = checkForAnotherMatch(index + 1, tempExpression, variable.name);

        // if (!found) {
        findAndReplaceVariableValue(variable.name, tempExpression);
        // }
      }
    });

    try {
      tempExpression = parser.evaluate(tempExpression);
      // console.log(eval(tempExpression));
      return calcRules(tempExpression);
    } catch (error) {
      return calcRules(tempExpression);
    }
  };

  const checkAndCreateRulesAsVariable = (expression: string, rule: any) => {
    let found: any = variables.find((variable) => {
      return variable.name === rule.ruleName;
    });


    if (!found) {
      let ex = calcRules(expression);
      variables.push({
        variableId: (variables.length + 1).toString(),
        name: rule.ruleName,
        value: ex,
        userDefined:true
      });
      parser.set(rule.ruleName, ex);
      return;
    }

    found.value = calcRules(expression);
    parser.set(rule.ruleName, found.value);

  };

  const findAndReplaceVariableValue = (
    name: string,
    expression: string,
    showError = false
  ) => {
    let foundData: any = variables.find((variable: any) => {
      return variable.name === name;
    });

    if (name == "NSV" && showError) {
      // let nsv: any = variables.find((data) => nsv.name == 'NSV');
      if (!foundData.value) {
        toast.error("Please add a default value for NSV");
      } else if (!quantity) {
        toast.error("Please add customer quantity");
      }
    }

    if (name == "Spread" && showError) {
      // let nsv: any = variables.find((data) => nsv.name == 'NSV');
      if (!foundData.value) {
        toast.error("Please add a default value for Spread");
      } else if (!quantity) {
        toast.error("Please add customer quantity");
      }
    }

    if (foundData && typeof foundData.value == "string") {
      parser.set(name, `"${foundData.value}"`);
      return expression.replaceAll(name, `"${foundData.value}"`);
    } else {
      parser.set(name, foundData.value);
      return expression.replaceAll(name, foundData.value);
    }
  };

  // determine height of the subrule formula texarea
  const setNoOfRows = (length: number) => {
    // console.log("setNoOfRows ", length);
    let rows = 1;
    if (length / 36 >= 1) {
      rows = length / 36 + 1
    }
    return rows;
  }

  const expressionChanged = async (e: any, index: number) => {
    currentIndex = index;
    rules[index].isDropOpen = true;

    selectionStart = e.target.selectionStart;
    selectionEnd = e.target.selectionEnd;

    calculateNsv();
    let exp = e.target.value;
    let expression = e.target.value;


    //customDuty
    exp = await setUpCustomDuty(e.target.value, index);

    // convert uom
    exp = await setUpConvertUom(exp, index);

    expressionCheck(e.target.value, index);
    setSuggestedRules(e.target.value);

    rules[index].ruleExpression = expression;

    rules[index].isValid = isValidExpression(exp, rules[index]);
    if (rules[index].isValid) {
      rules[index].value = maths.format(calculateValue(exp), { precision: 14 });
    }
    setRules(JSON.parse(JSON.stringify(rules)));

    if (elRefs[index] && elRefs[index].current) {
      let tempRefs = [...elRefs];
      tempRefs[index].current.selectionStart = selectionStart;
      tempRefs[index].current.selectionEnd = selectionEnd;
      setElRefs([...tempRefs]);
    }
    return;
  };

  const setUpCustomDuty = async (exp: string, index: number) => {
    return new Promise(async (resolve, reject) => {
      let tempexp: any = exp;

      const customDutyResult = isCustomDutyFunction(tempexp);

      if (customDutyResult.success) {
        setloadingIndex(rules[index].ruleSequence);

        try {
          const result: any = await pricingService.getCustomDuty({
            rules: customDutyResult.values,
          });
          const resultArray = result.data.data;

          resultArray.forEach((r: any) => {
            tempexp = tempexp.replaceAll(r.rule, parseFloat(r.value));

          });
          setloadingIndex(0);
        } catch (err) {

          setloadingIndex(0);
          customDutyResult.values.forEach((r) => {
            tempexp = tempexp.replaceAll(r.rule, 0.0);
          });
          resolve(tempexp);
        }
      } else {
        setloadingIndex(0);
      }

      resolve(tempexp);
    });
  };

  const setUpConvertUom = async (exp: string, index: number): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      let tempexp: any = exp;

      const convertUOMResult = isConvertUOMFunction(tempexp);
      if (convertUOMResult.success) {

        setloadingIndex(rules[index].ruleSequence);

        try {

          for await (const r of convertUOMResult.values) {
            const result: any = await callConvertUom(r.from.toString(), r.to.toString()) // await customFunctions.convertUOM(r.from.toString(), r.to.toString());
            const val = result.toString();
            tempexp = tempexp.replaceAll(r.rule, parseFloat(val));
            setloadingIndex(0);
          }


        } catch (err) {

          setloadingIndex(0);
          convertUOMResult.values.forEach((r) => {
            tempexp = tempexp.replaceAll(r.rule, 0.0);
          });
          resolve(tempexp);
        }
      } else {
        setloadingIndex(0);
      }

      resolve(tempexp);
    });
  };

  const setSuggestedRules = (value: string) => {
    const regex = /[a-zA-Z]+/gm;
    const str = value;
    let m;
    let word = "";

    while ((m = regex.exec(str)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      // The result can be accessed through the `m`-variable.
      m.forEach((match, groupIndex) => {
        word = match;
      });
    }

    let arrayRules = addVariablesToSuggestion();
    let suggestions = arrayRules.filter((rule: any) => {
      return rule && rule.ruleName
        ? rule.ruleName.toLowerCase().indexOf(word.toLowerCase()) != -1
        : false;
    });

    let suggestions_functions = customFunction.filter((rule: any) => {
      return rule && rule.ruleName
        ? rule.ruleName.toLowerCase().indexOf(word.toLowerCase()) != -1
        : false;
    });

    let onlyUserDefinedVars:any = [];
    variables.map((variable: any) => {
      if (variable.userDefined) {
        onlyUserDefinedVars.push({
          ruleName: variable.name,
        });
      }
    });
    // check if rule name exists in suggested list
    onlyUserDefinedVars.forEach((element: any) => {
      const index = rules.findIndex(function (data: any) {
        return data.ruleName == element.ruleName;
      });
      // if not remove from suggested list
      if (index < 0) {
        suggestions = suggestions.filter(function (el: any) { return el.ruleName != element.ruleName; });

        const indexVar = variables.findIndex(function (dataVar: any) {
          return dataVar.name == element.ruleName;
        });

        if (indexVar > -1) {
          variables.splice(indexVar,1);
          parser.remove(element.ruleName);
        }
      }
    });

    setSuggestionRules([
      ...JSON.parse(JSON.stringify(suggestions)),
      ...suggestions_functions,
    ]);
  };

  const expressionCheck = (e: string, index: number) => {
    // debugger
    let expression = e;
    if (expression.length > 0) {
      rules[index].ruleExpression = expression;

      rules[index].isValid = isValidExpression(expression, rules[index], true);
      if (rules[index].isValid) {
        rules[index].value = calculateValue(expression);
      }
      setRules(JSON.parse(JSON.stringify(rules)));
      return;
    }
    rules[index].ruleExpression = expression;

    rules[index].isValid = false;
    setRules(JSON.parse(JSON.stringify(rules)));
  };

  const nameChanged = (e: any, index: number) => {
    let name = e.target.value.replace(/\s/g, "");
    let result = validateRuleName(name, index);

    rules[index].isNameInvalid = result.isNameInvalid;
    rules[index].nameError = result.error;
    rules[index].ruleName = name;

    setRules(JSON.parse(JSON.stringify(rules)));

    let ex = calcRules(rules[index].ruleExpression);
    variables.push({
      variableId: (variables.length + 1).toString(),
      name: rules[index].ruleName,
      value: ex,
      userDefined:true
    });
    parser.set(rules[index].ruleName, ex);
  };

  const validateRuleName = (name: string, index: number) => {
    let result = {
      isNameInvalid: false,
      error: "",
    };

    if (name.length == 0) {
      result.isNameInvalid = true;
      result.error = "Sub-Rule name can not be empty !";
    }

    let rejected = [
      ...rules
        .filter((r: any, i: number) => i != index)
        .map((r: any) => r.ruleName.toUpperCase()),
      ...customFunction.map((r: any) => r.ruleName.slice(0, -2).toUpperCase()),
      ...validateVariables,
    ];

    if (rejected.indexOf(name.toUpperCase()) > -1) {
      result.isNameInvalid = true;
      result.error =
        "Sub-Rule name can not be a default variable, function or an another sub-rule name !";
    }

    return result;
  };

  //Radio Button
  const onValueChange = (e: any, index: number) => {
    // e.preventDefault();
    let radio = e.target.value;
    let tempRules = rules;

    tempRules.map((rule: any, indexRule: number) => {
      if (indexRule == index) {
        rule.isFinalExpression = true;
      } else {
        rule.isFinalExpression = false;
      }
    });

    setRules(JSON.parse(JSON.stringify(tempRules)));

    setRadio(Number(rules[index].value).toFixed(2));
    setSelIndex(index);
    // onChangeCalculatedAskPrice(parseFloat(rules[index].value));
    console.log(radio, "RADEO");
  };

  // const checkIfVariableValid = (expression: string, rule: any) => {
  //   let found = expression.indexOf(rule.name);
  //   return found !== -1;
  // };

  // const bidPrice = rules.map(()=>{
  //   return()
  // })

  const addNewRule = () => {
    let tempRule = {
      ruleSequence: rules.length + 1,
      askBidType: "BID",
      ruleExpression: "",
      isValid: false,
      ruleName: "",
      isFinalExpression: false,
      value: 0,
      isNameInvalid: false,
      nameError: "",
      pricingMasterId: id,
      isNewRule: true,
    };

    setRules([...rules, tempRule]);
  };

  const delRule = async (index: any) => {
    let delData = [...rules];
    let ruleOnDelete = delData[index];

    // if (delData[index].id) {
    //   let reponse = await pricingService.deleteSubRule(delData[index].id);
    // }
    delData.splice(index, 1);
    variables = variables.filter((v) => ruleOnDelete.ruleName != v.name);

    delData.map((data, index) => {
      data.ruleSequence = index + 1;
    });

    let mathjsData = parser.getAll();
    parser.clear();

    delete mathjsData[ruleOnDelete.ruleName];

    for (const property in mathjsData) {
      parser.set(property, mathjsData[property]);
    }

    setRules(delData);
    setSuggestedRules("");

    if (index == selIndex) {
      setSelIndex(-1);
      setRadio(0);
    }
    if (index < selIndex) {
      let inde = selIndex - 1;
      setSelIndex(inde);
    }
  };

  const calculateNsv = async (callAskRule?: boolean) => {
    let ns5 = 0.05;
    let ns3 = 0.03;
    let tempValue = 0;
    let tempValueSpread = 0;

    let data: any = localStorage.getItem("weightDataBid");
    if (data != "undefined") {
      let weightBreakLocalData: any = JSON.parse(data);
      if (weightBreakLocalData && weightBreakLocalData.length > 0) {

        for await (const weightBreak of weightBreakLocalData) {


          if (weightOrAmount == "weight") {
            const result: any = await callConvertUom("g", uom.toString()); //await customFunctions.convertUOM("g", uom.toString());
            const val = result.toString();
            const newQuantity = quantity * val;

            if (
              weightBreak.lowerBound <= newQuantity &&
              weightBreak.upperBound >= newQuantity && weightBreak.weightOrAmount == "W"
            ) {
              if (!tempValue) {
                tempValue = weightBreak.nsv;
              }
              if (!tempValueSpread) {
                tempValueSpread = weightBreak.bidSpread;
              }
            }
          } else {
            if (
              weightBreak.lowerBound <= quantity &&
              weightBreak.upperBound >= quantity && weightBreak.weightOrAmount == "A"
            ) {
              if (!tempValue) {
                tempValue = weightBreak.nsv;
              }
              if (!tempValueSpread) {
                tempValueSpread = weightBreak.bidSpread;
              }
            }
          }
        }

        // weightBreakLocalData.map((weightBreak: any) => {
        //   if (
        //     weightBreak.lowerBound <= quantity &&
        //     weightBreak.upperBound >= quantity && weightBreak.weightOrAmount == "W"
        //   ) {
        //     if (!tempValue) {
        //       tempValue = weightBreak.nsv;
        //     }
        //     if (!tempValueSpread) {
        //       tempValueSpread = weightBreak.bidSpread;
        //     }
        //   }
        // });
      }
    }

    if (!tempValueSpread) {
      let valueData: any = localStorage.getItem("spreadDefaultBid");
      tempValueSpread = parseFloat(valueData);
      if (!tempValueSpread) {
        tempValueSpread = 0;
      }
    }

    if (!tempValue) {
      let valueData: any = localStorage.getItem("nsvDefaultBid");
      tempValue = parseFloat(valueData);
      if (!tempValue) {
        tempValue = 0;
      }
    }

    const feederMetalAsk = benchMarkInd?.ask || 0;
    const feederMetalBid = benchMarkInd?.bid || 0;
    const feederCurrencyAsk = usdInr?.ask || 0;
    const feederCurrencyBid = usdInr?.bid || 0;
    const feederMetalAskConv = benchMarkInd && benchMarkInd.uom ? feederMetalAsk / await callConvertUom(benchMarkInd.uom, uom) : 0
    const feederMetalBidConv = benchMarkInd && benchMarkInd.uom ? feederMetalBid / await callConvertUom(benchMarkInd.uom, uom) : 0
    // console.log(feederMetalAsk,"feederMetalAsk");
    // console.log(feederMetalBid,"feederMetalBid");
    // console.log(feederCurrencyAsk,"feederCurrencyAsk");
    // console.log(feederCurrencyBid,"feederCurrencyBid");

    variables.map((data: any) => {
      if (data.variableId == "FeederMetalAsk") {
        data.value = feederMetalAsk;
        parser.set("FeederMetalAsk", parseFloat(data.value));
      }
      if (data.variableId == "FeederMetalBid") {
        data.value = feederMetalBid;
        parser.set("FeederMetalBid", parseFloat(data.value));
      }
      if (data.variableId == "FeederMetalAskConv") {
        data.value = feederMetalAskConv;
        parser.set("FeederMetalAskConv", parseFloat(data.value));
      }
      if (data.variableId == "FeederMetalBidConv") {
        data.value = feederMetalBidConv;
        parser.set("FeederMetalBidConv", parseFloat(data.value));
      }
      if (data.variableId == "FeederCurrencyAsk") {
        data.value = feederCurrencyAsk;
        parser.set("FeederCurrencyAsk", parseFloat(data.value));
      }
      if (data.variableId == "FeederCurrencyBid") {
        data.value = feederCurrencyBid;
        parser.set("FeederCurrencyBid", parseFloat(data.value));
      }
      if (data.variableId == "CustomReqQty") {
        data.value = quantity ? parseFloat(quantity) : 1;
        parser.set("CustomReqQty", data.value);
      }
      if (data.variableId == "NSV") {
        data.value = tempValue;
        data.value = Math.round(data.value / 100 * Math.pow(10, 4)) / Math.pow(10, 4);
        parser.set("NSV", data.value);
      }
      if (data.variableId == "Spread") {
        data.value = tempValueSpread;
        parser.set("Spread", data.value);
      }
      if (data.variableId == "FinalAskPrice") {
        data.value = finalAskPrice;
        parser.set("FinalAskPrice", finalAskPrice);
      }
    });
    if (callAskRule) {
      fetchBidRules();
    }
    // console.log("variabled", variables);
  };

  const calcRules = (expression: string): any => {
    try {
      const value = parseFloat(parser.evaluate(expression));
      if (typeof value == "number") {
        return value;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }

    // return math.evaluate(rules[index].expression);
    // let x = 2.8888888;
    // let y = 2;
    // if (rules[index].expression && rules[index].expression.split("(")[1]) {
    //   let z = rules[index].expression.split("(")[1].split(",")[0];

    //   let w = rules[index].expression.split(",")[1];
    //   w = w.split(")")[0];

    //   let formulaName = rules[index].expression
    //     ? rules[index].expression.split("(")[0].toLowerCase()
    //     : "";

    //   console.log(formulaName, "Hello", index);

    //   switch (formulaName) {
    //     case "round":
    //       let round = MathC.round(parseFloat(z), w ? w : 1);
    //       console.log("roundedValue", round);
    //       return round;
    //       break;
    //     case "sqrt":
    //       let sqrt = PreFunction.sqrt(z);
    //       return sqrt;
    //       break;
    //   }
    // }
  };

  //   const Pma = () => {
  //       let x =
  //   }

  const submitpricing = async () => {
    getBidRules(JSON.parse(JSON.stringify(rules)));
  };

  const onSubmit = () => {
    if (validateOnSubmit()) {
      submitpricing();
    }
  };

  useImperativeHandle(ref, () => ({
    
    
    saveAllDataBid() {
      console.log("Save Bid Price Section");
      onSubmit();
    }

  }));

  const validateOnSubmit = () => {
    if (rules && rules.length > 0) {
      for (let i = 0; i < rules.length; i++) {
        const rule = rules[i];
        const result = validateRuleName(rule.ruleName, i);

        const valid = rule.isValid;

        delete rules[i].isNewRule;

        if (result.isNameInvalid) {
          rules[i].isNameInvalid = result.isNameInvalid;
          rules[i].nameError = result.error;
          setsubRuleTip(rule.ruleSequence);
          setRules(rules);
          return false;
        }

        if (!valid) {
          toast.error("Please enter valid rules before save");
          return;
        }
      }
    }
    return true;
  };

  const handleRuleSelect = async (ruleName: string, index: number) => {
    let tempRules = rules;

    const regex = /[a-zA-Z]+/gm;
    const str = tempRules[index].ruleExpression;
    let m;
    let word = "";

    while ((m = regex.exec(str)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      // The result can be accessed through the `m`-variable.
      m.forEach((match, groupIndex) => {
        word = match;
      });
    }

    let expressionTemp = tempRules[index].ruleExpression.toString();

    expressionTemp = expressionTemp.substring(0, selectionEnd - word.length) + ruleName + expressionTemp.substring(selectionEnd);
    tempRules[index].ruleExpression = expressionTemp;

    tempRules[index].isDropOpen = false;

    calculateNsv();
    let exp = tempRules[index].ruleExpression;
    //customDuty
    exp = await setUpCustomDuty(exp, index);

    // convert uom
    exp = await setUpConvertUom(exp, index);

    expressionCheck(tempRules[index].ruleExpression, index);
    let expression = tempRules[index].ruleExpression;
    setSuggestedRules(expression);
    if (expression.length > 1) {
      tempRules[index].ruleExpression = expression;

      tempRules[index].isValid = isValidExpression(exp, tempRules[index]);
      if (tempRules[index].isValid) {
        tempRules[index].value = calculateValue(exp);
      }
      setRules(JSON.parse(JSON.stringify(tempRules)));
      return;
    }
    tempRules[index].ruleExpression = expression;

    tempRules[index].isValid = false;
    setRules(JSON.parse(JSON.stringify(tempRules)));
  };

  const closeDropDown = (indexCurrent: number) => {
    let tempRules = rules;
    tempRules.map((rule: any, index: number) => {
      if (indexCurrent == index) {
        rule.isDropOpen = true;
      } else {
        rule.isDropOpen = false;
      }
    });
    setRules(JSON.parse(JSON.stringify(tempRules)));
  };

  const closeDropDownBlur = () => {
    let tempRules = rules;
    tempRules.map((rule: any, index: number) => {
      rule.isDropOpen = false;
    });

    setTimeout(() => {
      setRules(JSON.parse(JSON.stringify(tempRules)));
    }, 1000);
  };

  return (
    <div className="mt-0 bg-white shadow-sm  border border-gray-300 rounded mb-0">
      <div className="flex items-center justify-between p-2 px-3 pl-5 bg-gray-50 border-b-2">
        <h2 className="font-bold">
          Calculated Bid Price:
          <span className="border p-2 bg-white ml-3">{radio} {currency == "INR" ? "₹" : "$"}</span>
        </h2>

        <div className="flex items-center justify-between pl-5">
          <p className="font-bold mr-3 text-xs">
            Total Quantity
            <span className="border p-2 bg-white ml-3">{totalQ} &nbsp; {uom}</span>
          </p>
          <p className="font-bold mr-3 text-xs">
            Total Amount
            <span className="border p-2 bg-white ml-3">{totalA} &nbsp; {currency}</span>
          </p>
          {/* <button
            id="bid-price-save-btn"
            className={`bg-purple-950 uppercase text-white px-4 py-2 rounded font-bold ${isruleExpired ? 'opacity-50' : ''}`}
            onClick={onSubmit}
            disabled={isruleExpired}
            type="button"
          >
            Save
          </button> */}
        </div>

      </div>
      <div className="flex p-5 mb-4">
        <div className="flex flex-1 mr-2 flex-col">
          <table className="w-full mr-2 border-gray-300 border divide-y divide-gray-300 table-fixed">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="w-12 px-6 py-1 text-left text-xs text-gray-500 uppercase tracking-wider"
                >
                  SEQ
                </th>
                <th
                  scope="col"
                  className=" w-1/6 px-6 py-1 text-left text-xs text-gray-500 uppercase tracking-wider"
                >
                  Sub-Rule
                </th>
                <th
                  scope="col"
                  className="w-2/6 px-6 py-1 text-left text-xs text-gray-500 uppercase tracking-wider"
                >
                  Formula
                </th>
                <th
                  scope="col"
                  className="w-1/6 px-6 py-1 text-right text-xs text-gray-500 uppercase tracking-wider"
                >
                  Final Value
                </th>
                <th
                  scope="col"
                  className="w-24 px-6 py-1 text-right text-xs text-gray-500 uppercase tracking-wider"
                >
                  <button
                    id="add-bid-rule-btn"
                    type="button"
                    className={`uppercase inline-flex justify-center py-1 m-1 px-2 border border-transparent shadow-sm text-sx rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isruleExpired ? 'opacity-50' : ''}`}
                    onClick={addNewRule}
                    disabled={isruleExpired}
                  >
                    + Rule
                  </button>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {rules.map((rule: any, index: number) => {
                return (
                  <tr className="p-0">
                    <React.Fragment>
                      <td className="px-2 py-2 w-100">
                        <div className="flex items-center">
                          <div className="ml-4">
                            <div className="text-sm text-gray-900">
                              <div className="p-1 px-2 border mb-1 font-bold h-8">
                                <label key={"l" + index}>
                                  {rule.ruleSequence}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-2 py-2">
                        <div className="text-sm text-gray-900">
                          <div className="mb-1 relative">
                            <input
                              disabled={isruleExpired}
                              onBlur={(e) => setsubRuleTip(0)}
                              onFocus={(e) => setsubRuleTip(rule.ruleSequence)}
                              className={`w-full p-1 px-2 font-bold border h-8 focus:outline-none ${rule.isNameInvalid &&
                                "border-red-600 focus:border-red-600"
                                }`}
                              id={rule.id}
                              type="text"
                              placeholder="Name"
                              value={rule.ruleName}
                              key={rule.id}
                              onChange={(e) => nameChanged(e, index)}
                            />
                            {subRuleTip == rule.ruleSequence &&
                              rule.isNameInvalid && (
                                <ErrorToolTip error={rule.nameError} />
                              )}
                          </div>
                        </div>
                      </td>
                      <td className="px-2 py-2">
                        <div className="text-sm text-gray-900">
                          <div>
                            <div className="relative">
                              <textarea
                                ref={elRefs[index]}
                                className="p-1 px-2 pr-20 border mb-1 w-full font-bold combobox combobox-list"
                                id={rule.id}
                                // type="text"
                                placeholder="Expression."
                                disabled={rule.isNameInvalid || isruleExpired}
                                value={rule.ruleExpression}
                                key={rule.id}
                                rows={rule.ruleExpression.length > 36 ? setNoOfRows(rule.ruleExpression.length) : 1}
                                onFocus={(e) => closeDropDown(index)}
                                onBlur={(e) => {
                                  closeDropDownBlur();
                                }}
                                onChange={(e) => expressionChanged(e, index)}
                              />

                              {rule.isDropOpen && (
                                <div className="bg-gray-50 absolute shadow-sm font-bold py-3 z-20 w-full px-2 bg-white border cursor-pointer">
                                  <DropownListCustom
                                    currentIndex={index}
                                    propsValues={suggestionRules}
                                    getRules={handleRuleSelect}
                                  />
                                </div>
                              )}
                              {loadingIndex != rule.ruleSequence &&
                                !rule.isValid && (
                                  <div className="flex-none">
                                    <small className="uppercase font-bold rounded text-red-600 absolute right-2 top-1.5 bg-red-100 px-2">
                                      Invalid
                                    </small>
                                  </div>
                                )}
                              {loadingIndex == rule.ruleSequence && (
                                <div className="flex-none">
                                  <small className="uppercase font-bold rounded text-gray-600 absolute right-2 top-1.5 bg-gray-100 px-2">
                                    Loading..
                                  </small>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-2 py-2 text-sm text-gray-500">
                        <div className="text-sm text-gray-900">
                          <div>
                            <div className="p-1 px-2 mb-1 font-bold text-right flex items-center justify-end h-8">
                              <div className="overflow-x-auto">{rule.isValid ? parseFloat(rule.value).toFixed(4) : ""}</div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-2 py-2 text-sm text-gray-500">
                        <div className="text-sm text-gray-900 flex items-center">
                          <div>
                            <div className="p-1 px-2 mb-1 text-center  h-8 flex items-center">
                              <input
                                id={rule.id}
                                type="checkbox"
                                className="form-radio"
                                name="FinalValue"
                                // value={rule.isFinalExpression}
                                checked={rule.isFinalExpression}
                                disabled={isruleExpired}
                                onChange={(e) => onValueChange(e, index)}
                              />
                            </div>
                          </div>

                          <div>
                            <div className="p-1 px-2 mb-1 text-center h-8 flex items-center">
                              <button
                                id="bid-price-delete-btn"
                                type="button"
                                className={`px-2 cursor-pointer ${isruleExpired ? 'opacity-50' : ''}`}
                                disabled={isruleExpired}
                                onClick={() => {
                                  return delRule(index);
                                }}
                              >
                                <svg
                                  className="w-4 text-red-500"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                    </React.Fragment>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex flex-col flex-none w-1/4 border border-gray-300 rounded p-2 shadow-sm">
          <div className="flex-1 rounded">
            <div className="w-full text-center bg-gray-800 text-white rounded ">
              <h2 className="p-2 font-bold uppercase flex text-center text-sm items-center">
                <svg
                  className="w-6 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8"
                  />
                </svg>{" "}
                Variables
              </h2>
            </div>
            <div className="flex flex-row flex-wrap text-xs font-bold text-purple-900 p-2">
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                Metal
              </div>
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                Benchmark
              </div>
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                FeederCurrency
              </div>
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                FeederUOM
              </div>
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                FeederMetalAsk
              </div>
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                FeederMetalBid
              </div>
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                FeederCurrencyBid
              </div>
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                FeederCurrencyAsk
              </div>
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                NSV
              </div>
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                Spread
              </div>
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                FinalAskPrice
              </div>
            </div>
          </div>

          <div className="flex-1">
            <div className="w-full text-center bg-gray-800 rounded">
              <h2 className="p-2 font-bold uppercase flex text-center text-white text-sm items-center">
                <svg
                  className="w-6 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
                  />
                </svg>
                Customer Inputs
              </h2>
            </div>
            <div className="flex flex-row flex-wrap text-xs font-bold text-purple-900 p-2">
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                CustomerReqQty
              </div>
            </div>
          </div>

          <ReactTooltip />

          <div className="flex-1">
            <div className="w-full text-center bg-gray-800 rounded">
              <h2 className="p-2 font-bold uppercase flex text-center text-white text-sm items-center">
                (f) Standard Functions
              </h2>
            </div>
            <div className="flex flex-row flex-wrap text-xs font-bold text-purple-900 p-2">
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                <p data-tip="Round to nearest number eg: round((formula),decimal)">
                  round
                </p>
              </div>
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                <p data-tip="Calculate the square root of a value eg: sqrt(x)">
                  sqrt
                </p>
              </div>
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                <p data-tip="Calculate the power of x to y eg: pow(x, y)">
                  pow
                </p>
              </div>
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                <p data-tip="Calculate the sum of numbers eg: sum(x, y, z)">
                  sum
                </p>
              </div>
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                <p data-tip="Calculate the median of numbers eg: median(x, y, z)">
                  median
                </p>
              </div>
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                <p data-tip="Calculate the mid price of numbers eg: midPrice(x, y)">
                  midPrice
                </p>
              </div>
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                <p data-tip="Calculate the average of numbers eg: average(x, y, z)">
                  average
                </p>
              </div>
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                <p data-tip="Convert to UOM eg: convertUOM(x, y)">convertUOM</p>
              </div>
              <div className="flex-1 p-2 text-center border bg-purple-100 border-purple-300 rounded m-1">
                <p data-tip='get the customDuty eg: customDuty( "MetalCode", Date:"yyyy-MM-dd", "HSN" , "ROUND")'>
                  customDuty
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const ErrorToolTip = ({ error }: any) => (
  <div
    className="absolute top-9 text-xs
  font-medium z-10 px-2 py-1 border border-red-500 bg-red-50 text-red-500 shadow-sm rounded-lg"
  >
    {error}
  </div>
);

const validateVariables = [
  "NSV",
  "FEEDERMETALASK",
  "FEEDERMETALBID",
  "FEEDERMETALASKCONV",
  "FEEDERMETALBIDCONV",
  "FEEDERCURRENCYASK",
  "FEEDERCURRENCYBID",
  "CUSTOMDUTY",
  "CUSTOMREQQTY",
  "UOM",
  "FEEDERUOM",
  "CUSTOMERUOM",
  "FEEDER",
  "FEEDERCURRENCY",
  "METAL",
  "BENCHMARK",
  "FINALASKPRICE",
];
export default BidPriceSection;
