import React from "react";
import SimpleReactValidator from "simple-react-validator";

interface PropsType {
  handleChange: any;
  data: any;
  addNewRule: any;
  edit: boolean;
  subAllDetail: any;
  selctedItemDetails: any;
}

const TradeExeRuleInput: React.FC<PropsType> = ({
  handleChange,
  data,
  addNewRule,
  edit,
  subAllDetail,
  selctedItemDetails,
}) => {
  // const simpleValidator = new SimpleReactValidator({
  //   locale: "en",
  //   validators: {},
  // });
  // const validationRef = React.useRef(simpleValidator);
  // const validator = validationRef.current;

  // const getValidatorError = (nameOfStateProp: any) => {
  //   const allErrorMessages = validator.getErrorMessages();
  //   return allErrorMessages[nameOfStateProp];
  // };
  // const handleSave = () => {
  //   if ( !validator.allValid()) {
  //     return toast.error("Please make sure all the inputs are valid.");
  //   }
  //   handleCreateRule();
  // };


  const option_apply_rate = [
    { label: "1", name: "Actual" },
    { label: "2", name: "On Previous Amount" },
    { label: "3", name: "On Net Total" },
    { label: "4", name: "On Invoice Total" },
  ];

  return (
    <>
      <div className="flex flex-row">
        <div className="flex-1 border bg-white shadow-sm sm:rounded-md ">
          <div className="flex p-4 bg-purple-100 m-1 mb-0">
            <div className="flex-1">
              <div className="px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  for="grid-state"
                >
                  Item Code
                </label>
                <div className="relative">
                  <select
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    name="item_code"
                    onChange={handleChange}
                    value={data?.item_code}
                  >
                    {subAllDetail?.item?.length &&
                      subAllDetail?.item.map((data: any) => {
                        return (
                          <option value={data?.Item_code}>
                            {data?.Item_code}
                          </option>
                        );
                      })}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                </div>
              </div>
            </div>


            <div className="flex-1">
              <div className="px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  for="grid-state"
                >
                  is maintain stock
                </label>
                <div className="relative">
                  <select
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    name="is_Maintain_Stock"
                    onChange={handleChange}
                    value={data?.is_Maintain_Stock}
                  >

                    <option value="true">Yes</option>

                    <option value="false">No</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  for="grid-state"
                >
                  allocate vault
                </label>
                <div className="relative">
                  <select
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    name="allocate_Vault"
                    onChange={handleChange}

                    value={data?.allocate_Vault ?? false}

                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  for="grid-state"
                >
                  Pricing Rule Status
                </label>
                <div className="relative">
                  <select
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    name="pricing_enabled"
                    onChange={handleChange}
                    value={data?.pricing_enabled ?? "false"}
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  for="grid-state"
                >
                  Pricing Rule
                </label>
                <div className="relative">
                  <select
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    name="pricing_rule"
                    onChange={handleChange}
                    value={data?.pricing_rule}
                  >
                    {subAllDetail?.pricing?.length &&
                      subAllDetail?.pricing.map((data: any) => {

                        return <option value={data?.id}>{data?.id}</option>;
                      })}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  for="grid-state"
                >
                  UOM
                </label>
                <div className="relative">
                  <select
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    name="UOM"
                    onChange={handleChange}
                    value={data?.UOM}
                  >
                    <option value="grams">grams</option>
                    <option value="ounce">ounce</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                </div>
              </div>
            </div>
            </div>
            <div className='flex p-4 border-b mb-4 bg-purple-100 m-1 mt-0'>
            <div className="flex-1">
              <div className="px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  for="grid-state"
                >
                  Currency
                </label>
                <div className="relative">
                  <select
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    name="currency"
                    onChange={handleChange}
                    value={data?.Curr_Code}
                  >
                    {subAllDetail?.currencies?.length &&
                      subAllDetail?.currencies.map((data: any) => {
                        return (
                          <option value={data?.Curr_Code}>
                            {data?.Curr_Code}
                          </option>
                        );
                      })}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  for="grid-state"
                >
                  Apply Rate On
                </label>
                <div className="relative">
                  <select
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    name="apply_rate_on"
                    onChange={handleChange}

                    value={data?.apply_rate_on ?? "1"}
                  >
                    {!!option_apply_rate?.length &&
                      option_apply_rate.map((data: any, idx: any) => {
                        return (
                          <option key={idx} value={data?.label}>
                            {data?.name}
                          </option>
                        );
                      })}

                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  for="grid-state"
                >
                  Amount Rate
                </label>
                <div className="relative">
                  <select
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    name="amount_rate"
                    onChange={handleChange}
                    value={data?.amount_rate}
                  >
                    <option value="AMOUNT">Amount</option>
                    <option value="RATE">Rate</option>
                    <option value=""></option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  for="grid-state"
                >
                  Discount Amount/Rate
                </label>
                <div className="relative">
                  <select
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    name="discountAmount_rate"
                    onChange={handleChange}
                    value={data?.discountAmount_rate}
                  >
                    <option value="AMOUNT">Amount</option>
                    <option value="RATE">Rate</option>
                    <option value=""></option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                </div>
              </div>
            </div>

            <div className="flex-1 ">
              <div className="px-3">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  for="grid-state"
                >
                  Invoice Net Total (+/-)
                </label>
                <div className="relative">
                  <select
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="grid-state"
                    name="add_ded"
                    onChange={handleChange}
                    value={data?.add_ded}
                  >

                    <option value="true">Add</option>
                    <option value="false">Deduct</option>
                  </select>


                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                </div>
              </div>
            </div>
          </div>
          <div className='p-3'>
          <div className='flex'>
          <div className="flex-1">

            <div className="px-3">
              <label
                className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                for="grid-state"
              >
                Item Name
              </label>
              <div className="relative">
                <input
                  className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                  id="grid-state"
                  name="itemName"
                  value={selctedItemDetails ?? ""}
                  readOnly
                ></input>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="px-3">
              <label
                className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                for="grid-state"
              >
                Metal Code
              </label>
              <div className="relative">
                <input
                  className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                  id="grid-city"
                  name="description"
                  type="text"
                  value={data?.metal_code}
                  readOnly
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
              </div>
            </div>
          </div>
          </div>

         
          <div className="flex-initial mt-3">
            <div className="w-full px-3 mb-6 md:mb-0">
              <label
                className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                for="grid-city"
              >
                Description
              </label>
              <input
                className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                id="grid-city"
                name="description"
                type="text"
                onChange={handleChange}
                value={data?.description}
              />
            </div>
          </div>
          <div className="flex mt-3">
          <div className="flex-1">
            <div className="px-3">
              <label
                className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                for="grid-state"
              >
                Discount Rate
              </label>
              <div className="relative">
                <input
                  className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                  id="grid-city"
                  name="discountRate"
                  type="number"
                  onChange={handleChange}
                  value={data?.discountRate}
                />

                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="w-full px-3 mb-6 md:mb-0">
              <label
                className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                for="grid-city"
              >
                Value.
              </label>
              <input
                className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                id="grid-city"
                name="value"
                type="number"
                min="0"
                placeholder=""
                onChange={handleChange}
                value={data?.value}
              />
            </div>
          </div>
          </div>
          </div>
            <div className='flex p-5 justify-end bg-gray-50'>
          <button
            className="px-4 py-2 bg-purple-950 text-white text-sm font-bold text-blue uppercase bg-gray-950 rounded"
            onClick={addNewRule}
            disabled={!data}
            type="button"
          >
            {!!edit ? "Update" : "Add New Rule"}
          </button>
          </div>
        </div>
      </div>
    </>
  );
};


export default TradeExeRuleInput;

