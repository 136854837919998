import axios from "axios";

//==========================================  Master Rule  ============================================//

export const GetAllTradeRule = async () => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/rulesmaster/all`;
    resolve(await axios.get(apiUrl));
  });
};

export const CreateTrade = async (data: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/rulesmaster/insert`;
    try {
      let result = await axios.post(apiUrl, data);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const GetTradeRuleById = async (id: number) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/rulesmaster/one/${id}`;
    try {
      let result = await axios.get(apiUrl);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const EditTradeRuleMaster = async (id: number, data: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/rulesmaster/update/${id}`;
    try {
      let result = await axios.patch(apiUrl, data);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteTradeRuleMaster = async (id: number) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/rulesmaster/delete/${id}`;
    try {
      let result = await axios.delete(apiUrl);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

//============================================  Sub Rule  =============================================//

export const CreateTradeSub = async (data: any) => { 
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/ruledetails/insert/bulk`;
    try {
      let result = await axios.post(apiUrl, data);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const EditTradeSub = async (id: number, data: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/ruledetails/update/${id}`;
    try {
      let result = await axios.put(apiUrl, data);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteTradeRule = async (id: number) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/ruledetails/delete/${id}`;
    try {
      let result = await axios.delete(apiUrl);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

//======================================  Get All For Sub Rule  =======================================

export const GetAllById = async (id: number) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/trading/all/${id}`;
    try {
      let result = await axios.get(apiUrl);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

//==================================== Get Sub =======================================================

export const GetAllSubRules = async () => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/ruledetails/all`;
    try {
      let result = await axios.get(apiUrl);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteSubTradeRule = async (id: number) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/ruledetails/delete/${id}`;
    try {
      let result = await axios.delete(apiUrl);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const EditTradeSubRule = async (id: number, data: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/ruledetails/update/${id}`;
    try {
      let result = await axios.put(apiUrl, data);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};