import Layout from "../../Layout/Layout";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Loader from "../../../Components-ui/Loader";
import { toast, ToastContainer } from "react-toastify";
import CustomDutyForm from "./CustomDutyForm";
import { useAuthStore } from "../../../Store/AuthStore";
import { Pagination } from "../../../Components-ui/Pagination";
import { makeQuery } from "../../../Common/util.helper";
import CustomsDutyService from "../Services/customs-duty.service";
import TaxDetailService from "../../TaxDetails/Services/tax-details.service";
import { DATA_TYPE } from "../../TaxDetails/Utils/enum";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";
import Datetime from "react-datetime";

interface PropsType {}

const CustomDutyTable: React.FC<PropsType> = () => {
  const { logout, permissions, userData, tokens } = useAuthStore();

  const [Loading, setLoading] = useState<any>(false);
  const [CustomDuties, setCustomDuties] = useState<any>([]);
  const [Metals, setMetals] = useState<any>([]);
  const [HSN, setHSN] = useState<any>([]);
  const [MetaData, setMetaData] = useState<any>({});
  const [CurrentPage, setCurrentPage] = useState<number>(0);
  const [OpenModal, setOpenModal] = useState(false);
  const [Validator, setValidator] = useState(false);
  const [CustomsDuty, setCustomsDuty] = useState<any>({
    metalCode: "",
    hsn: "",
    dutyOnNotifiedRates: false,
    codeDuty: "",
    customsRate: "",
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    dutyFct: "",
    active: "ENABLED",
  });

  const [FilterData, setFilterData] = useState<any>({
    metalCode: "",
    hsn: "",
    codeDuty: "",
    active: "",
    startDate: "",
    endDate: "",
  });

  useEffect((): any => {
    getAllCustomDuties(CurrentPage);
    getAllHSNs();
    getAllMetals();
    // eslint-disable-next-line
  }, []);

  /**
   * Handle filters change event
   * @param e
   */
  const handleChangeFilter = (e: any) => {
    const { name, value } = e.target;
    setFilterData({
      ...FilterData,
      [name]: value,
    });
  };

  /**
   * Get all custom duties
   * @param page
   * @param isReset
   */
  const getAllCustomDuties = async (page: number, isReset: boolean = false) => {
    setLoading(true);
    let cloneFilterData = !isReset
      ? { ...FilterData }
      : {
          metalCode: "",
          hsn: "",
          codeDuty: "",
          active: "",
          startDate: "",
          endDate: "",
        };
    const query = makeQuery(cloneFilterData);
    let data: any = await CustomsDutyService.getAllCustomDuties(
      page,
      tokens,
      query
    );
    if (data.status === 200 && data.data.data) {
      setCurrentPage(page);
      setMetaData({
        current: data.data.meta.page,
        pages: data.data.meta.totalPages,
      });
      setCustomDuties(data ? data.data.data.result : []);
    }
    setLoading(false);
  };

  /**
   * Get all metals
   */
  const getAllMetals = async () => {
    let data: any = await CustomsDutyService.getAllMetals(0, tokens, "", false);
    if (data.status === 200 && data.data.data) {
      let responseData = data.data.data;
      setMetals(responseData);
      setLoading(false);
    }
  };

  /**
   * Get all hsn
   */
  const getAllHSNs = async () => {
    let data: any = await TaxDetailService.getAllHSN(tokens, false);
    if (data.status === 200 && data.data.data) {
      let responseData = data.data.data;
      setHSN(responseData);
      setLoading(false);
    }
  };

  /**
   * Validate customs duty
   * @param e
   * @param type
   */
  const onChangeCustomDuty = (e: any, type: any) => {
    switch (type) {
      case DATA_TYPE.START_DATE:
        if (
          moment(CustomsDuty?.endDate).valueOf() >=
          moment(new Date(e)).valueOf()
        ) {
          setValidator(true);
          return setCustomsDuty({
            ...CustomsDuty,
            startDate: new Date(e).toISOString(),
          });
        }
        if (
          moment(CustomsDuty?.endDate).valueOf() < moment(new Date(e)).valueOf()
        ) {
          setValidator(true);
          return setCustomsDuty({
            ...CustomsDuty,
            endDate: new Date(e).toISOString(),
            startDate: new Date(e).toISOString(),
          });
        }

        return setValidator(false);
      case DATA_TYPE.END_DATE:
        if (
          moment(CustomsDuty?.startDate).valueOf() <=
          moment(new Date(e)).valueOf()
        ) {
          setValidator(true);
          return setCustomsDuty({
            ...CustomsDuty,
            endDate: new Date(e).toISOString(),
          });
        }

        return setValidator(false);
      case DATA_TYPE.NORMAL:
        setCustomsDuty({
          ...CustomsDuty,
          [e.target.name]:
            e.target.name === "dutyOnNotifiedRates"
              ? e.target.checked
              : e.target.value,
        });

        return setValidator(true);
      default:
        return "";
    }
  };

  /**
   * Reset filters to initial state
   * @param e
   */
  const resetFilterData = async (e: any) => {
    e.preventDefault();
    setFilterData({
      metalCode: "",
      hsn: "",
      codeDuty: "",
      active: "",
      startDate: "",
      endDate: "",
    });

    setCurrentPage(0);
    await getAllCustomDuties(0, true);
  };

  /**
   * Close modal
   */
  const closeModal = () => {
    setOpenModal(false);
  };

  /**
   * Open customs duty modal
   */
  const onAddCustomsDuty = () => {
    setInitialState();
    setOpenModal(true);
  };

  /**
   * Get custom duty details
   * @param id
   */
  const getCustomsDuty = (id: any) => {
    let country: any = CustomDuties.filter(
      (customDuty: any) => customDuty.id === id
    );
    if (country.length > 0) {
      setCustomsDuty(country[0]);
      setOpenModal(true);
    }
  };

  /**
   * Enable or disable custom duty
   * @param e
   * @param data
   */
  const enableDisableCustomDuty = async (e: any, data: any) => {
    setLoading(true);
    let cloneData = { ...data };

    cloneData.active = e.target.checked ? "ENABLED" : "DISABLED";
    await CustomsDutyService.updateCustomsDuty(cloneData, tokens);
    await getAllCustomDuties(CurrentPage);
    setLoading(false);
    return toast.success("Customs duty was successfully updated");
  };

  /**
   * Set country details to initial state
   */
  const setInitialState = () => {
    setCustomsDuty({
      metalCode: "",
      hsn: "",
      dutyOnNotifiedRates: false,
      codeDuty: "",
      customsRate: "",
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      dutyFct: "",
      active: "ENABLED",
    });
  };

  var valid = function (current: any) {
    return current.isAfter(moment(FilterData.startDate).subtract(1, "day"));
  };

  return (
    <Layout type={"MasterModule"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">
          {"Customs Duties"}
        </h1>
        {HasAnyAccess(["customs.duty.master.create"]) && (
          <button
            className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
            type="button"
            onClick={onAddCustomsDuty}
          >
            + Add Customs Duty
          </button>
        )}
      </div>

      <div className="w-full rounded border-gray-200 overflow-hidden shadow-lg dark:border-gray-700 bg-white">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Filter Custom Duties
          </h3>
        </div>
        <hr />
        <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="codeDuty"
              className="block text-sm font-medium text-gray-700"
            >
              Code Duty
            </label>
            <input
              type="text"
              name="codeDuty"
              id="codeDuty"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={FilterData.codeDuty}
            />
          </div>
          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="metalCode"
              className="block text-sm font-medium text-gray-700"
            >
              Metal Code
            </label>
            <select
              id="metalCode"
              name="metalCode"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={FilterData.metalCode}
            >
              <option value="">All</option>
              {Metals.map((metal: any, index: number) => {
                return (
                  <option key={index} value={metal.metalCode}>
                    {metal.metalCode}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="hsn"
              className="block text-sm font-medium text-gray-700"
            >
              HSN
            </label>
            <select
              id="hsn"
              name="hsn"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={FilterData.hsn}
            >
              <option value="">All</option>
              {HSN.map((hsn: any, index: number) => {
                return (
                  <option key={index} value={hsn.id}>
                    {hsn.hsnCode}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="active"
              className="block text-sm font-medium text-gray-700"
            >
              Status
            </label>
            <select
              id="active"
              name="active"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={FilterData.active}
            >
              <option value="">All</option>
              <option value="ENABLED">ENABLED</option>
              <option value="DISABLED">DISABLED</option>
            </select>
          </div>

          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="startDate"
              className="block text-sm font-medium text-gray-700"
            >
              Start Date
            </label>

            <div>
              <Datetime
                timeFormat={""}
                dateFormat={"DD/MM/YYYY"}
                inputProps={{
                  placeholder: "pick date",
                  className: "focus:outline-none",
                }}
                className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4`}
                onChange={(event: any) =>
                  setFilterData({
                    ...FilterData,
                    startDate: moment(new Date(event).toISOString()).format(
                      "MM/DD/YYYY"
                    ),
                    endDate:
                     ( FilterData.endDate == ""
                        ? moment(new Date(event).toISOString()).format(
                            "MM/DD/YYYY"
                          )
                        : FilterData. endDate),
                  })
                }
                value={moment(FilterData.startDate)}
                renderInput={(props) => {
                  return (
                    <input
                      style={{width:'100%'}}
                      {...props}
                      value={FilterData.startDate ? FilterData.startDate : ""}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="endDate"
              className="block text-sm font-medium text-gray-700"
            >
              End Date
            </label>
            {console.log("FilterData", FilterData)}
            <div>
              <Datetime
                isValidDate={valid}
                timeFormat={""}
                dateFormat={"DD/MM/YYYY"}
                inputProps={{
                  placeholder: "pick date",
                  className: "focus:outline-none",
                }}
                className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4`}
                onChange={(event: any) =>
                  setFilterData({
                    ...FilterData,
                    endDate: moment(new Date(event).toISOString()).format(
                      "MM/DD/YYYY"
                    ),
                  })
                }
                value={moment(FilterData.endDate)}
                renderInput={(props) => {
                  return (
                    <input
                      {...props}
                      style={{width:'100%'}}
                      value={FilterData.endDate ? FilterData.endDate : ""}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="col-span-12 sm:col-span-12 flex justify-end">
            <button
              className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
              type="button"
              onClick={() => getAllCustomDuties(0)}
            >
              Filter
            </button>
            <button
              className="text-white bg-red-600 border border-red-300 rounded-md background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
              type="button"
              onClick={(e) => resetFilterData(e)}
            >
              Reset
            </button>
          </div>
          <div className="col-span-12 sm:col-span-12">
            {" "}
            <hr />
          </div>
          <div className="col-span-12 sm:col-span-12">
            {Loading ? (
              <Loader />
            ) : (
              <div className="border bg-white rounded border-gray-200 shadow">
                <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                  <thead>
                    <tr className="text-left border-b border-gray-300">
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Code
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Metal Code
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        HSN
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Customs Rate
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Start Date
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        End Date
                      </th>
                      {HasAnyAccess(["customs.duty.master.edit"]) && (
                        <>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800">
                            Status
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800">
                            Actions
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {CustomDuties.length > 0 ? (
                      CustomDuties.map((customsDuty: any, index: number) => {
                        return (
                          <tr
                            key={index}
                            className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                          >
                            <td className="py-3 px-3 text-left relative">
                              {customsDuty.codeDuty}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {customsDuty.metalCode}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              <span>
                                {
                                  HSN?.find(
                                    (i: any) =>
                                      i.id === parseInt(customsDuty?.hsn)
                                  )?.hsnCode
                                }
                              </span>
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {customsDuty.customsRate}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {moment(customsDuty.startDate).format(
                                "DD-MM-YYYY HH:mm"
                              )}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {moment(customsDuty.endDate).format(
                                "DD-MM-YYYY HH:mm"
                              )}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {HasAnyAccess(["customs.duty.master.edit"]) && (
                                <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                  <input
                                    type="checkbox"
                                    name="toggle"
                                    id={`${customsDuty.id}toggle`}
                                    checked={customsDuty.active === "ENABLED"}
                                    className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                                    onChange={(e) =>
                                      enableDisableCustomDuty(e, customsDuty)
                                    }
                                  />
                                  <label
                                    htmlFor={`${customsDuty.id}toggle`}
                                    className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                                  />
                                </div>
                              )}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {HasAnyAccess(["customs.duty.master.edit"]) && (
                                <button
                                  className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                  type="button"
                                  onClick={() => getCustomsDuty(customsDuty.id)}
                                >
                                  Edit
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                        <td
                          className="py-3 px-3 text-center relative"
                          colSpan={8}
                        >
                          No Data To Display
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        <div className="col-span-12 sm:col-span-12 px-4 pt-2">
          {/*Pagination Bar*/}
          {!Loading && (
            <Pagination
              meta={MetaData}
              handlePageChange={(number: number) => getAllCustomDuties(number)}
            />
          )}
        </div>
      </div>
      {OpenModal && (
        <CustomDutyForm
          onCloseModal={closeModal}
          customsDuty={CustomsDuty}
          onChangeCustomDuty={onChangeCustomDuty}
          getAllCustomDuties={getAllCustomDuties}
          validatorRef={Validator}
          page={CurrentPage}
          metals={Metals}
          hsn={HSN}
        />
      )}
    </Layout>
  );
};

export default CustomDutyTable;
