import axios from "axios";
import Http from "../../../library/http.library";
import {httpMethods} from "../../../library/enum";

/**
 * Get all valuts
 * @param tokens
 */
export const getValuts = async (tokens: any) => {
  return await Http.setMethod(httpMethods.get).setUrl(`/warehouse-master?isPaginated=false`).setToken(tokens.idToken.jwtToken).request();

};

/**
 * Get all metals
 * @param tokens
 */
export const getMetals = async (tokens: any) => {
  return await Http.setMethod(httpMethods.get).setUrl(`/metal-master?isPaginated=false`).setToken(tokens.idToken.jwtToken).request();

  // return new Promise(async (resolve, reject) => {
  //
  //   let apiUrl = `${process.env.REACT_APP_URL}/metal-master?isPaginated=false`;
  //   try {
  //     resolve(
  //       await axios.get(apiUrl, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "Access-Control-Allow-Origin": "*",
  //           "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
  //           Authorization: `Bearer ${tokens.idToken.jwtToken}`,
  //         },
  //       })
  //     );
  //   } catch (e) {
  //     reject(e);
  //   }
  // });
};

export const createCalendar = async (data: any, tokens: any) => {
  return await Http.setMethod(httpMethods.post).setUrl(`/vso/calendar`).setData(data).setToken(tokens.idToken.jwtToken).request();

  // return new Promise(async (resolve, reject) => {
  //   try {
  //     let apiUrl = process.env.REACT_APP_URL + `/vso/calendar`;
  //
  //     resolve(
  //       await axios.post(apiUrl, data, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "Access-Control-Allow-Origin": "*",
  //           "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
  //           Authorization: `Bearer ${tokens.idToken.jwtToken}`,
  //         },
  //       })
  //     );
  //   } catch (error) {
  //     reject(error);
  //   }
  // });
};

//New Endpoint
export const getVSOReceiptDetails = async (
  calendarId: string,
  vaultId: string,
  metalCode: any,
  tokens: any,
  page: any
) => {
  let apiUrl = "";
  if (metalCode == "")
    apiUrl =
        `/vso/receipt?vaultId=${vaultId}&calendarId=${calendarId ? calendarId:''}&page=${page}`;
  else
    apiUrl =
        `/vso/receipt?vaultId=${vaultId}&calendarId=${calendarId}&metalCode=${metalCode}&page=${page}`;

  return await Http.setMethod(httpMethods.get).setUrl(`${apiUrl}`).setToken(tokens.idToken.jwtToken).request();

  // return new Promise(async (resolve, reject) => {
  //   let apiUrl = "";
  //   if (metalCode == "")
  //     apiUrl =
  //       process.env.REACT_APP_URL +
  //       `/vso/receipt?vaultId=${vaultId}&calendarId=${calendarId}`;
  //   else
  //     apiUrl =
  //       process.env.REACT_APP_URL +
  //       `/vso/receipt?vaultId=${vaultId}&calendarId=${calendarId}&metalCode=${metalCode}`;
  //
  //   try {
  //     let result = await axios.get(apiUrl, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Origin": "*",
  //         "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
  //         Authorization: `Bearer ${tokens.idToken.jwtToken}`,
  //       },
  //     });
  //     resolve(result);
  //   } catch (error) {
  //     reject(error);
  //   }
  // });
};

// Update time
export const EditTime = async (data: any, tokens: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/vso/calendar/update`;
    try {
      let result = await axios.patch(apiUrl, data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          Authorization: `Bearer ${tokens.idToken.jwtToken}`,
        },
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const initiateVSOReceiptingProcess = async (
  date: string,
  vaultId: string,
  vso: string,
  calendarId: string,
  receiptIds: any,
  metal: any,
  tokens: any
) => {
  // return new Promise(async (resolve, reject) => {
    let payload: any = { vaultId, date, calendarId, vso, receiptIds, metal };
    if (metal === "")
      payload = { vaultId, date, calendarId, vso, receiptIds };
    return await Http.setMethod(httpMethods.post).setUrl(`/vso/receipt/process`).setData(payload).setToken(tokens.idToken.jwtToken).request();

  //   let apiUrl = process.env.REACT_APP_URL + `/vso/receipt/process`;
  //   try {
  //     let payload: any = { vaultId, date, calendarId, vso, receiptIds, metal };
  //     if (metal === "")
  //       payload = { vaultId, date, calendarId, vso, receiptIds };
  //
  //     let result = await axios.post(apiUrl, payload, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Origin": "*",
  //         "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
  //         Authorization: `Bearer ${tokens.idToken.jwtToken}`,
  //       },
  //     });
  //     resolve(result);
  //   } catch (error) {
  //     reject(error);
  //   }
  // });
};

export const getCalender = async (
  date: string,
  vaultId: string,
  tokens: any
) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = `${process.env.REACT_APP_URL}/vso/calendar?startDate=${date}&endDate=${date}&vaultId=${vaultId}&orderBy=date&page=0&pageSize=10`;
    try {
      resolve(
        await axios.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
            Authorization: `Bearer ${tokens.idToken.jwtToken}`,
          },
        })
      );
    } catch (e) {
      reject(e);
    }
  });
};

export const getPendingCalenders = async (
  startDate: string,
  vaultId: string,
  tokens: any
) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = `${process.env.REACT_APP_URL}/vso/calendar?vaultId=${vaultId}&orderBy=date&isPaginated=false`;
    try {
      resolve(
        await axios.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
            Authorization: `Bearer ${tokens.idToken.jwtToken}`,
          },
        })
      );
    } catch (e) {
      reject(e);
    }
  });
};
