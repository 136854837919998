import axios from "axios";
import Http from "../../library/http.library";
import { httpMethods } from "../../library/enum";

export const getGiftLog = async (
  tokens: any,
  currentPage: any,
  data: any,
  isPaginated: any = true
) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/gift/log/data`;
    try {
      let result = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          Authorization: `Bearer ${tokens.idToken.jwtToken}`,
        },
        params: {
          page: currentPage,
          isPaginated:true,
          ...(data.ebOrderRef ? { ebOrderRef: data.ebOrderRef } : {}),
          ...(data.id ? { giftId: data.id } : {}),
          ...(data.installmentAmount ? { installmentAmount: data.installmentAmount } : {}),
          ...(data.receiverMobileno ? { receiverMobileNo: data.receiverMobileno } : {}),
          ...(data.orderId ? { orderType: data.orderId } : {}),
          ...(data.accountId ? { accountId: data.accountId } : {}),
          ...(data.customerId ? { customerId: data.customerId } : {}),
          ...(data.partnerId ? { partnerId: data.partnerId } : {}),
        //   ...(data.currency ? { currency: data.currency } : {}),
        //   ...(data.orderBy ? { orderBy: data.orderBy } : {}),
        //   ...(data.order ? { order: data.order } : {}),
          ...(data.status ? { status: data.status } : {}),
        //   ...(data.warehouseId ? { warehouseId: data.warehouseId } : {}),
        //   ...(data.supplierInvoiceDate
        //     ? { supplierInvoiceDate: data.supplierInvoiceDate }
        //     : {}),
          ...(data.metal ? { metal: data.metal } : {}),
          ...(data.endDate ? { endDate: data.endDate } : {}),
          ...(data.startDate ? { startDate: data.startDate } : {}),
          // // ...(data.toDate ? {toDate: data.toDate} : {}),
          // // ...(data.orderBy ? {orderBy: data.orderBy} : {}),
        },
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};
export const downloadInvoices = async (
  tokens: any,
  currentPage: any,
  data: any,
  isPaginated: any = true
) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/get-sipData/download`;
    try {
      let result = await axios.post(apiUrl, {
        // headers: {
        //   "Content-Type": "application/json",
        //   "Access-Control-Allow-Origin": "*",
        //   "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        //   Authorization: `Bearer ${tokens.idToken.jwtToken}`,
        // },
        // params: {
        isPaginated: isPaginated,
        page: currentPage,
        ...(data.ebInvoiceRef ? { ebInvoiceRef: data.ebInvoiceRef } : {}),
        ...(data.partnerId ? { partnerId: data.partnerId } : {}),
        ...(data.ebOrderRef ? { ebOrderRef: data.ebOrderRef } : {}),
        ...(data.type ? { orderType: data.type } : {}),
        ...(data.accountId ? { accountId: data.accountId } : {}),
        ...(data.customerId ? { customerId: data.customerId } : {}),
        ...(data.currency ? { currency: data.currency } : {}),
        ...(data.orderBy ? { orderBy: data.orderBy } : {}),
        ...(data.order ? { order: data.order } : {}),
        ...(data.status ? { invStatus: data.status } : {}),
        ...(data.warehouseId ? { warehouseId: data.warehouseId } : {}),
        ...(data.supplierInvoiceDate
          ? { supplierInvoiceDate: data.supplierInvoiceDate }
          : {}),
        ...(data.metalCode ? { metal: data.metalCode } : {}),
        ...(data.toDate ? { toDate: data.toDate } : {}),
        ...(data.fromDate ? { fromDate: data.fromDate } : {}),
        // // ...(data.toDate ? {toDate: data.toDate} : {}),
        // // ...(data.orderBy ? {orderBy: data.orderBy} : {}),
        // },
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};


//     tokens: any,
   
   
//     isPaginated: any = true
//   ) => {
//     return new Promise(async (resolve, reject) => {
//       let apiUrl = process.env.REACT_APP_URL + `/order/download`;
//       try {
//         let result = await axios.get(apiUrl, {
//           headers: {
//             "Content-Type": "application/json",
//             "Access-Control-Allow-Origin": "*",
//             "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
//             Authorization: `Bearer ${tokens.idToken.jwtToken}`,
//           },
//           params: {
           
//             ...(data.paymentId ? { paymentId: data.paymentId } : {}),
//             ...(data.sipId ? { sipId: data.sipId } : {}),
//             ...(data.installmentAmount ? { installmentAmount: data.installmentAmount } : {}),
//             ...(data.razorpayOrderId ? { razorpayOrderId: data.razorpayOrderId } : {}),
//             ...(data.orderId ? { orderType: data.orderId } : {}),
//             ...(data.accountId ? { accountId: data.accountId } : {}),
//             ...(data.customerId ? { customerId: data.customerId } : {}),
//             ...(data.partnerId ? { partnerId: data.partnerId } : {}),
//           //   ...(data.currency ? { currency: data.currency } : {}),
//           //   ...(data.orderBy ? { orderBy: data.orderBy } : {}),
//           //   ...(data.order ? { order: data.order } : {}),
//             ...(data.status ? { status: data.status } : {}),
//           //   ...(data.warehouseId ? { warehouseId: data.warehouseId } : {}),
//           //   ...(data.supplierInvoiceDate
//           //     ? { supplierInvoiceDate: data.supplierInvoiceDate }
//           //     : {}),
//           //   ...(data.metalCode ? { metal: data.metalCode } : {}),
//             ...(data.toDate ? { toDate: data.toDate } : {}),
//             ...(data.fromDate ? { fromDate: data.fromDate } : {}),
//             // // ...(data.toDate ? {toDate: data.toDate} : {}),
//             // // ...(data.orderBy ? {orderBy: data.orderBy} : {}),
//           },
//         });
//         resolve(result);
//       } catch (error) {
//         reject(error);
//       }
//     });
//   };
export const downloadSipReport = async(data: any, tokens: any) =>{
    return Http.setMethod(httpMethods.get)
      .unsetData()
      .setUrl(`/gift/log/download?${data}`)
      .setToken(tokens.idToken.jwtToken)
      .request();
  }

export const postSelectRefundList = async (tokens: any, data: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/refund-list`;
    try {
      let result = await axios.post(apiUrl, data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          Authorization: `Bearer ${tokens.idToken.jwtToken}`,
        },
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const getRefundApprove = async (
  currentPage: number,
  tokens: any,
  data: any
) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/refund-approve`;
    try {
      let result = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          Authorization: `Bearer ${tokens.idToken.jwtToken}`,
        },
        params: {
          isPaginated: true,
          page: currentPage,
          ...(data.orderRef ? { orderRef: data.orderRef } : {}),
          ...(data.orderItemRef ? { orderItemRef: data.orderItemRef } : {}),
          ...(data.status ? { status: data.status } : {}),
        },
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const refundApprove = async (tokens: any, data: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/refund-approve`;
    try {
      let result = await axios.patch(apiUrl, data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          Authorization: `Bearer ${tokens.idToken.jwtToken}`,
        },
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const getPartnerList = async (tokens: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/partners`;
    try {
      let result = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          Authorization: `Bearer ${tokens.idToken.jwtToken}`,
        },
        params: {
          isPaginated: false,
        },
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const reportDownload = async (tokens: any, id: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/invoice/pdf/${id}`;
    try {
      let result = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokens.idToken.jwtToken}`,
        },
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const getMetals = async (tokens: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/metal-master?isPaginated=false`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

const SipService = {
  getGiftLog,
  downloadInvoices,
  postSelectRefundList,
  getRefundApprove,
  refundApprove,
  getPartnerList,
  reportDownload,
  getMetals,
};

export default SipService;
