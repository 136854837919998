import React, { useContext, useEffect } from "react";
import socketIOClient from "socket.io-client";

import { MetalContext } from "../../../Context/metalContext";
import Layout from "../../Layout/Layout";
import { Table } from "../../../Components-ui/Table";
require("dotenv").config("../../../.env");
const Metals = () => {
  type END_POINT = {
    END_POINT: string;
  };
  const { state, dispatch } = useContext(MetalContext);

  useEffect((): any => {
    const socket = socketIOClient("wss://dev-api.ebullion.tk/metal-pricing");

    let dataJson;
    socket.on("updated-price", (data: any) => {
      dataJson = JSON.parse(data);
      console.log(dataJson.data);
    });

    return () => socket.disconnect();
  });

  interface columns {
    title: string;
    name: string;
  }

  const columns = [
    { title: "Metal Name", name: "item_name" },
    { title: "Price", name: "unit_price" },
    { title: "Unit", name: "unit" },
  ];

  return (
    <div>
      <Layout type={"Other"}>
        <Table columns={columns} data={state.metals} />
        {/* <button onClick={() => dispatch({ type: "UPDATE_METAL" })} type="button" className="bg-grey-light hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center">Add Metal</button> */}
      </Layout>
    </div>
  );
};

export default Metals;
