import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { useAuthStore } from "../../Store/AuthStore";
import Loader from "../../Components-ui/Loader";
import SimpleReactValidator from "simple-react-validator";
import Service from "../CustomerGroup/CustomerGroup.Service";
import { ToastContainer, toast } from "react-toastify";
import PushNotificationService from "./PushNotification.service";
import TradeExecutionService from "../TradeExecution/Services/trade-execution.service";

interface PropsType {
  onCloseModal: any;
  data: {
    title: string,
    message: string,
    imageUrl: string,
  }
}

const PushNotiModal: React.FC<PropsType> = ({ onCloseModal, data }) => {

  const [userType, setUserType] = useState<string>("");
  const [partnerId, setPartnerId] = useState<string>("");
  const [partners, setPartners] = useState<any>([]);
  const [category, setCategory] = useState("");
  const [customerGroup, setCustomerGroup] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const [notification, setNotification] = useState<any>({
    title: data.title,
    message: data.message,
    user_id: "",
    imageUrl: data.imageUrl,
    partnerId: "",
    customerGrpId: "",
  });
  const { logout, permissions, userData, tokens } = useAuthStore();

  const simpleValidator = new SimpleReactValidator({
    locale: "en",
    validators: {},
  });
  const validationRef = React.useRef(simpleValidator);
  const validator = validationRef.current;

  const discountCategories = [
    { label: "B2B", value: "B2B" },
    { label: "B2C", value: "B2C" },
    { label: "B2B & B2C", value: "BOTH" },
    // { label: "PARTNER", value: "PARTNER_API_USER" },
  ];


  // const downloadSample = async () => {
  //   setLoading(true);
  //   await BulkUploadService.downloadBulkSample(tokens)
  //     .then((response: any) => {
  //       const href = window.URL.createObjectURL(
  //         new Blob([response.data.data.payload.data])
  //       );
  //       const link = document.createElement("a");
  //       link.href = href;
  //       link.setAttribute("download", "sample-data.xlsx"); //or any other extension
  //       document.body.appendChild(link);
  //       link.click();
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //     });
  // };
  const getPartners = async () => {
    let response: any = await TradeExecutionService.getPartners(tokens);
    if (response.status === 200 && response.data.data) {
      let responseData = response.data.data;
      setPartners(responseData);
    }
  };

  const getCustomerGroup = async () => {
    let response: any = await TradeExecutionService.getCustomerGroup(
      category,
      tokens
    );
    if (response.status === 200 && response.data.data) {
      let responseData = response.data.data;

      setCustomerGroup(responseData);
    }
  };
  const sendNoti = async () => {
    try {
      // const data = {
      //   user_id: "bbfd7b5a-7e01-447d-a735-095ef8a475a6",
      //   title: "eBullion",
      //   message: "Think Precious Metal Think eBullion!",
      //   imageUrl:
      //     "https://ebullion-assets.s3.ap-south-1.amazonaws.com/logo-icon.png",
      // };
      if (
        notification?.title == "" ||
        notification?.message == "" ||
        // notification?.partnerId == "" ||
        userType == "" ||
        (userType == "one" && notification?.partnerId == "" && notification?.user_id == "") ||
        (userType == "many" && notification?.partnerId == "") ||
        (userType == "customer-group" && category == "" && notification.customerGroupId == "")
      ) {
        return toast.error("All fields are required");
      } else {
        setLoading(true);
        let res: any;
        if (userType == "one") {
          res = await PushNotificationService.sendToOne(notification, tokens);
        } else {
          res = await PushNotificationService.sendToMany(notification, tokens);
        }
        if (res) {
          toast.success("Notification sent successfully");
          setNotification({
            title: "",
            message: "",
            user_id: "",
            imageUrl: "",
            partnerId: "",
            customerGrpId: "",
          });
          setUserType("");
          setCategory("");
          setCustomerGroup([]);
          onCloseModal();
        }
      }
    } catch (err) {
      toast.error("Something went wrong");

      console.log(err);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPartners();
  }, []);

  useEffect(() => {
    getCustomerGroup();
  }, [category]);


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className=" w-full bg-gray-800 bg-opacity-50 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-4xl">
            <div className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
              <div className="items-start justify-between px-6 py-4 border-b border-solid border-blue bg-gray-100 rounded-t">
                <div className="flex justify-between items-center mb-5">
                  <h1 className="text-2xl font-bold tracking-tight">
                    {"Push Notification"}
                  </h1>
                  {/* <button
            onClick={() => downloadSample()}
            className="text-white  bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
            type="button"
          >
            Download sample
          </button> */}
                </div>
                <div className="border bg-white rounded border-gray-200 shadow">
                  <div className="flex-1 p-5">
                    <div className="col-span-12 sm:col-span-12">
                      <form
                        onSubmit={(e) => {
                          sendNoti();
                          e.preventDefault();
                        }}
                      >
                        <div className="flex flex-wrap -mx-3 mb-2">
                          <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <div className="relative">
                              <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="description"
                              >
                                Notification Title
                              </label>
                              <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="title"
                                name="title"
                                type="text"
                                placeholder="Notification Title"
                                maxLength={80}
                                onChange={(e: any) => {
                                  setNotification({
                                    ...notification,
                                    title: e.target.value,
                                  });
                                }}
                                value={notification.title}
                              // onChange={(event) =>
                              //   setSelectedRecord({
                              //     ...selectedRecord,
                              //     description: event.target.value,
                              //   })
                              // }
                              // value={selectedRecord.description}
                              />
                            </div>
                          </div>
                          <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <div className="relative">
                              <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="description"
                              >
                                Message
                              </label>
                              <textarea
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="message"
                                name="message"
                                // type="textarea"
                                placeholder="Message"
                                onChange={(e: any) => {
                                  setNotification({
                                    ...notification,
                                    message: e.target.value,
                                  });
                                }}
                                value={notification.message}
                              // onChange={(event) =>
                              //   setSelectedRecord({
                              //     ...selectedRecord,
                              //     description: event.target.value,
                              //   })
                              // }
                              // value={selectedRecord.description}
                              />
                            </div>
                          </div>
                          {/* <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label
                              htmlFor="metals"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Partner
                            </label>
                            <select
                              id="partnerIds"
                              name="partnerIds"
                              autoComplete="partnerIds"
                              onChange={(e: any) => {
                                setNotification({
                                  ...notification,
                                  partnerId: e.target.value,
                                });
                                setPartnerId(e.target.value);
                              }}
                              value={partnerId}
                              // onChange=(())
                              //   disabled={isEdit}
                              className={`mt-1 block w-full py-2 px-3 border border-gray-300  rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                            >
                              <option value="" disabled>
                                Select Partner
                              </option>
                              {partners[0] &&
                                partners?.map(
                                  (category: any, index: number) => {
                                    return (
                                      <option
                                        key={index}
                                        value={category.partnerId}
                                      >
                                        {category.name}
                                      </option>
                                    );
                                  }
                                )}
                            </select>
                          </div> */}
                          <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <div className="relative">
                              <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="description"
                              >
                                Image Url (Optional)
                              </label>
                              <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="imageUrl"
                                name="imageUrl"
                                // type="textarea"
                                placeholder="Image Url"
                                onChange={(e: any) => {
                                  setNotification({
                                    ...notification,
                                    imageUrl: e.target.value,
                                  });
                                }}
                                value={notification.imageUrl}
                              // onChange={(event) =>
                              //   setSelectedRecord({
                              //     ...selectedRecord,
                              //     description: event.target.value,
                              //   })
                              // }
                              // value={selectedRecord.description}
                              />
                            </div>
                          </div>
                          <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label
                              className="block text-sm font-medium text-gray-700"
                              htmlFor="description"
                            >
                              User Type
                            </label>

                            <select
                              id="metalType"
                              name="metalType"
                              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              onChange={(e: any) => {
                                setUserType(e.target.value);
                              }}
                              value={userType}
                            >
                              <option value="" disabled>Select</option>
                              <option value="one">One</option>
                              <option value="many">All</option>
                              <option value="customer-group">Customer Group</option>
                            </select>
                          </div>
                          {(userType == "one" || userType == "many") && (
                            <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                              <label
                                htmlFor="metals"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Partner
                              </label>
                              <select
                                id="partnerIds"
                                name="partnerIds"
                                autoComplete="partnerIds"
                                onChange={(e: any) => {
                                  setNotification({
                                    ...notification,
                                    partnerId: e.target.value,
                                  });
                                  setPartnerId(e.target.value);
                                }}
                                value={partnerId}

                                className={`mt-1 block w-full py-2 px-3 border border-gray-300  rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                              >
                                <option value="" disabled>
                                  Select Partner
                                </option>
                                {partners[0] &&
                                  partners?.map(
                                    (category: any, index: number) => {
                                      return (
                                        <option
                                          key={index}
                                          value={category.partnerId}
                                        >
                                          {category.name}
                                        </option>
                                      );
                                    }
                                  )}
                              </select>
                            </div>
                          )}

                          {userType == "one" && partnerId && (
                            <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                              <div className="relative">
                                <label
                                  className="block text-sm font-medium text-gray-700"
                                  htmlFor="description"
                                >
                                  User ID
                                </label>
                                <input
                                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  id="user_id"
                                  name="user_id"
                                  type="text"
                                  placeholder="User ID"
                                  onChange={(e: any) => {
                                    setNotification({
                                      ...notification,
                                      user_id: e.target.value,
                                    });
                                  }}
                                  value={notification.user_id}

                                />
                              </div>
                            </div>
                          )}
                          {userType == "customer-group" && (
                            <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                              <label
                                htmlFor="metals"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Category
                              </label>
                              <select
                                id="discountCategory"
                                name="discountCategory"
                                autoComplete="discountCategory"

                                value={category}

                                onChange={(e: any) => {
                                  setCategory(e.target.value);
                                }}
                                className={`mt-1 block w-full py-2 px-3 border border-gray-300  rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                              >
                                <option value="" disabled>
                                  Select Category
                                </option>
                                {discountCategories.map((category: any, index: number) => {
                                  return (
                                    <option key={index} value={category.value}>
                                      {category.label}
                                    </option>
                                  );
                                })}

                              </select>
                            </div>
                          )}


                          {userType == "customer-group" && category.length > 0 && (
                            <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                              <label
                                htmlFor="metals"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Customer Group
                              </label>
                              <select
                                id="customerGroupIds"
                                name="customerGroupIds"
                                autoComplete="customerGroupIds"
                                value={notification.customerGroupId}
                                onChange={(e: any) => {

                                  setNotification({
                                    ...notification,
                                    customerGrpId: e.target.value,
                                  })
                                }}

                                className={`mt-1 block w-full py-2 px-3 border border-gray-300  rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                              >
                                <option value="" disabled>
                                  Select Customer Group
                                </option>
                                {customerGroup[0] &&
                                  customerGroup?.map((category: any, index: number) => {
                                    return (
                                      <option key={index} value={category.id}>
                                        {category.name}
                                      </option>

                                    );
                                  })}
                              </select>
                            </div>
                          )}


                          <div className="flex w-full justify-end px-3 mb-6 md:mb-0">
                            <div className="my-3">
                              <input
                                className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                                type="submit"
                                value="Send"
                              // onClick={() => handleSubmit()}
                              />
                            </div>
                            <div className="my-3">
                              <input
                                className=" bg-grey background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                                type="button"
                                value="Cancel"
                                onClick={() => onCloseModal()}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PushNotiModal;
