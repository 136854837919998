import axios from "axios";

function formatParams(advanced:any) {
  

    let params = {customer_id:"",Entity:"",order_id:"",pge:"",partner:"EBL",fund_trn_id:"",Sourcemodule:"",paymentStatus:"",transaction_from:"",transaction_to:"",settlementdate_from:"",settlementdate_to:""};

    if (!!advanced.customer_id) {
      params.customer_id = `${advanced?.customer_id}`;
    }
    if (!!advanced?.Entity?.length) {
      params.Entity = `${advanced?.Entity}`;
    }
    if (!!advanced?.order_id?.length) {
      params.order_id = `${advanced?.order_id}`;
    }
    if (!!advanced?.pge?.length) {
      params.pge = `${advanced?.pge}`;
    }
    if (!!advanced?.partner?.length) {
      params.partner = `${advanced?.partner}`;
    }
    if (!!advanced?.fund_trn_id?.length) {
      params.fund_trn_id = `${advanced?.fund_trn_id}`;
    }
    if (!!advanced?.Sourcemodule?.length) {
      params.Sourcemodule = `${advanced?.Sourcemodule}`;
    }
    if (!!advanced?.paymentStatus?.length) {
        params.paymentStatus = `${advanced?.paymentStatus}`;
      }
      if (!!advanced?.transaction_from?.length) {
        params.transaction_from = `${advanced?.transaction_from}`;
      }
      if (!!advanced?.transaction_to?.length) {
        params.transaction_to = `${advanced?.transaction_to}`;
      }
      if (!!advanced?.settlementdate_from?.length) {
        params.settlementdate_from = `${advanced?.settlementdate_from}`;
      }
      if (!!advanced?.settlementdate_to?.length) {
        params.settlementdate_to = `${advanced?.settlementdate_to}`;
      }
    return params;
  }
  
  export function getEventByCustId(advanced:any) {
    const params = formatParams(advanced);
    let apiUrl = process.env.REACT_APP_URL + `/api/v1/funds/fundmgtdetails`;
    return axios.get(apiUrl, { params });
  }




  export const GetFundById = async (id: number) => {
    return new Promise(async (resolve, reject) => {
      let apiUrl = process.env.REACT_APP_URL + `/api/v1/funds/fundmgtdetails/${id}`;
      try {
        let result = await axios.get(apiUrl);
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };