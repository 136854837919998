import React, { Fragment } from "react";
import { Route } from "react-router-dom";

import GroupCreation from "./GroupCreation";
import CustomerGroupList from "./CustomerGroupList";
import EditCustomerGroup from "./EditCustomerGroup";
import AddCustomer from "./AddCustomer";

const CustomerGroupRoutes: React.FC = () => {
  return (
    <Fragment>
      <Route path="/group-creation" exact component={GroupCreation} />
      <Route path="/existing-group-creation/:id" exact component={AddCustomer} />
      <Route path="/group-list" exact component={CustomerGroupList} />
      <Route
        path="/customer-group/edit/:id"
        exact
        component={EditCustomerGroup}
      />
    </Fragment>
  );
};

export default CustomerGroupRoutes;
