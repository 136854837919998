import axios from "axios";

class Testing {
  async getAuxilaryData() {
    return new Promise(async (resolve, reject) => {
      let apiUrl = process.env.REACT_APP_URL + "/api/v1/price/rule/auxiliary";
      resolve(await axios.get(apiUrl));
    });
  }

  async getPricingMaster() {
    return new Promise(async (resolve, reject) => {
      let apiUrl = process.env.REACT_APP_URL + "/api/v1/price/rule/master";
      resolve(await axios.get(apiUrl));
    });
  }

  async getPricingApi(
    partnerId: number,
    metalCode: string,
    quantity: number,
    currency: string
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        let apiUrl =
          process.env.REACT_APP_URL +
          `/api/v1/price/price-engine?partnerId=${partnerId}&metalCode=${metalCode}&quantity=${quantity}&currency=${currency}`;
        let data = await axios.get(apiUrl);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new Testing();
