import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import UOMConversion from "./Components/UOMConversion";
import AddUpdateModal from "../../Components-ui/Modal/Modal";

const UOMConversionRoutes:React.FC = () => {
    return (
        <Fragment>
            <Route path="/uom-conversion" exact component={UOMConversion}/>
        </Fragment>
    )
};

export default UOMConversionRoutes;