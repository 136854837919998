import React, { useState, useEffect } from "react";
import { useAuthStore } from "../../../Store/AuthStore";
import Layout from "../../Layout/Layout";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import MetalInterchangeService from "../MetalInterchangeService";
import Modal from "../../../Components-ui/Modal/Modal";
import SimpleReactValidator from "simple-react-validator";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";

const MetalInterchange: React.FC = () => {
  const [Loading, setLoading] = useState<any>(true);
  const [show, setModalStatus] = useState<any>(false);
  const [children, setChildren] = useState<any>();
  const [status, setStatus] = useState<String>("FROM");
  const [record, setRecord] = useState<any>();
  const [selectedData, setSelectedData] = useState<any>([]);
  const [selectedDataTarget, setSelectedDataTarget] = useState<any>([]);
  const [fromBarList, setFromBarList] = useState<any>([]); //main table data
  const [targetBarList, setTargetBarList] = useState<any>([]); //main table data
  const [barList, setBarList] = useState<any>([]); //modal
  const [barListTargetM, setBarListTargetM] = useState<any>([]); //modal
  const [metalList, setMetalList] = useState<any>([]);
  const [vaultList, setVaultList] = useState<any>([]);
  const [itemList, setItemList] = useState<any>([]);
  const [array, setArray] = useState<any>([]);
  let [remove, setRemoveStatus] = useState<boolean>(false);
  let [isExchange, setIsExchange] = useState<boolean>(false);
  let [exchangeChildren, setExchangeChildren] = useState<any>();
  const [filters, setFilters] = useState<any>({
    vaultId: "",
    pool: "Customer",
    metalCode: "",
    weight: "",
    type: "BAR_TO_BAR",
  });
  const { logout, permissions, userData, tokens } = useAuthStore();
  const [validatorStatus, setValidator] = useState<boolean>(false);

  const simpleValidator = new SimpleReactValidator({
    locale: "en",
    validators: {},
  });
  const validationRef = React.useRef(simpleValidator);
  const validator = validationRef.current;
  const hideModal = () => {
    setModalStatus(false);
    setIsExchange(false);
  };
  const getValidatorError = (nameOfStateProp: any) => {
    const allErrorMessages = validator.getErrorMessages();
    return allErrorMessages[nameOfStateProp];
  };

  const getBarList = async (type: any) => {
    if (record) {
      let bars;
      if ("FROM" === type) {
        bars = "frombars";
      } else {
        bars = "targetbars";
      }
      //@ts-ignore
      MetalInterchangeService.getBarList(tokens, record.id, filters, bars)
        .then((response: any) => {
          setBarList(response.data.data.fromBars);
          setBarListTargetM(response.data.data.targetBars);
          setLoading(false);
          "FROM" === type
            ? removeItems(response.data.data.fromBars)
            : removeTargetItems(response.data.data.targetBars);
        })
        .catch((err) => {
          setLoading(false);
          console.log("errr", err);
          toast.error(err.data.errors[0].title);
        });
    }
  };

  const getMetals = async () => {
    setLoading(true);
    await MetalInterchangeService.getAllMetals(tokens)
      .then((response: any) => {
        setMetalList(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.errors[0].title);
      });
  };

  useEffect(() => {
    childrenElements();
  }, [array]);

  const changeStatus = async (data: any, i: number) => {
    if ("FROM" === status) {
      let newArr = [...barList];
      newArr[i].selected = !data.selected;
      setBarList(newArr);
    } else {
      let newArr = [...barListTargetM];
      newArr[i].selected = !data.selected;
      setBarListTargetM(newArr);
    }

    if (0 !== array.length) {
      if (array.some((item: any) => item.id === data.id)) {
        array.forEach((element: any, index: number) => {
          if (data.id === element.id) {
            array.splice(index, 1);
          }
          setArray(array);
        });
      } else {
        setArray(array.concat(data));
      }
    } else {
      setArray(array.concat(data));
    }
  };

  useEffect(() => {
    childrenElements();
  }, [remove, barListTargetM]);

  useEffect(() => {
    childrenElements();
  }, [barListTargetM]);

  useEffect(() => {
    childrenElements();
  }, [barList, show]);

  useEffect(() => {
    childrenElements();
  }, [remove, barList]);

  useEffect(() => {
    childrenElements();
  }, [barListTargetM, show]);

  const addBars = async (data: any) => {
    var arr: { barId: any; exchangeId: any; type: string }[] = [];
    data.map((item: any, index: number) => {
      if (undefined !== item.id) {
        var data = {
          barId: item.id,
          exchangeId: record.id,
          type: "FROM" === status ? "FROM" : "TO",
        };
        arr.push(data);
      }
    });
    await MetalInterchangeService.addBarList(tokens, arr)
      .then((response: any) => {
        toast.success("Record Added Successfully!");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.errors[0].title);
      });
    setChildren("");
  };

  const addModalElements = (type: String) => {
    setArray([]);
    setRemoveStatus(false);
    getBarList(type);
    setStatus(type);
    setModalStatus(true);
  };

  const childrenElements = () => {
    setChildren(
      <div className="w-full">
        <h1 className="font-bold">
          {"FROM" === status ? "Select Bars" : "Select Target Bars"}
        </h1>
        <form className="bg-white rounded pt-6 pb-8 mb-4">
          <div className="mb-4">
            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
              <thead>
                <tr className="text-left border-b border-gray-300">
                  <th className="sticky top-0 px-3 py-2 bg-gray-800"></th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800">Bar No</th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800">
                    Bar Description
                  </th>
                  {/* <th className="sticky top-0 px-3 py-2 bg-gray-800">
                    Seg ref
                  </th> */}
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Allocated Weight
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Availabe Weight
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Total Weight
                  </th>
                </tr>
              </thead>
              <tbody>
                {"FROM" === status ? (
                  0 !== barList.length ? (
                    barList.map((partner: any, index: number) => {
                      return (
                        <tr
                          key={index}
                          className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                        >
                          <td className="py-3 px-3 text-left relative">
                            <input
                              type="checkbox"
                              value={partner.selected}
                              checked={partner.selected}
                              onClick={() => {
                                changeStatus(partner, index);
                              }}
                            />
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {partner.barNo}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {partner.brandName}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {partner.allocatedWeight}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {partner.availbleWeight}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {partner.weight}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                      <td
                        colSpan={7}
                        className="text-center py-3 px-3 text-left relative"
                      >
                        No data to display
                      </td>
                    </tr>
                  )
                ) : 0 !== barListTargetM.length ? (
                  barListTargetM.map((partner: any, index: number) => {
                    return (
                      <tr
                        key={index}
                        className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                      >
                        <td className="py-3 px-3 text-left relative">
                          <input
                            type="checkbox"
                            value={partner.selected}
                            checked={partner.selected}
                            onClick={() => {
                              changeStatus(partner, index);
                            }}
                          />
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {partner.barNo}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {partner.brandName}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {partner.allocatedWeight}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {partner.availbleWeight}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {partner.weight}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                    <td
                      colSpan={7}
                      className="text-center py-3 px-3 text-left relative"
                    >
                      No data to display
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="flex items-center justify-between flex-row-reverse">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={() => {
                Save();
              }}
            >
              {"SAVE"}
            </button>
          </div>
        </form>
      </div>
    );
  };

  useEffect(() => {
    getItemData();
  }, [filters.vaultId, filters.metalCode]);

  const getItemData = async () => {
    if (!(filters && filters.vaultId && filters.metalCode)) return;
    try {
      await MetalInterchangeService.getItemList(tokens, filters)
        .then((response: any) => {
          setItemList(response.data.data.items);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          // toast.error(err.response.data.errors[0].title);
        });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const Save = async () => {
    if (0 !== array.length) {
      if ("FROM" === status) {
        let array1 = [...array];
        setSelectedData(
          0 !== selectedData.length ? selectedData.concat(array1) : array1
        );
        setFromBarList(
          0 !== fromBarList.length ? fromBarList.concat(array1) : array1
        );
        setStatus("");
        addBars(array1);
      } else {
        let array2 = [...array];
        setSelectedDataTarget(
          0 !== selectedDataTarget.length
            ? selectedDataTarget.concat(array2)
            : array2
        );
        setTargetBarList(
          0 !== targetBarList.length ? targetBarList.concat(array2) : array2
        );
        setStatus("");
        addBars(array2);
      }
    }
    setModalStatus(false);
  };

  const removeItems = (data: any) => {
    var allSelectedData = selectedData.concat(selectedDataTarget);
    var arr = data;
    for (var i = arr.length - 1; i >= 0; i--) {
      for (var j = 0; j < allSelectedData.length; j++) {
        if (arr[i] && arr[i].id === allSelectedData[j].id) {
          arr.splice(i, 1);
        }
      }
      setBarList(arr);
      setRemoveStatus(true);
    }
    if (remove) {
      setBarList(arr);
    }
  };

  const removeTargetItems = (data: any) => {
    var allSelectedData = selectedData.concat(selectedDataTarget);
    var arr = data;
    for (var i = arr.length - 1; i >= 0; i--) {
      for (var j = 0; j < allSelectedData.length; j++) {
        if (arr[i] && arr[i].id === allSelectedData[j].id) {
          arr.splice(i, 1);
        }
      }
      setBarListTargetM(arr);
      setRemoveStatus(true);
    }
    if (remove) {
      setBarListTargetM(arr);
    }
  };

  const filter = async () => {
    setValidator(true);
    if (validator.allValid()) {
      setLoading(true);
      Object.keys(filters).forEach((key) => {
        if (filters[key] === "") {
          delete filters[key];
          //  return toast.error("All fields are required")
        }
      });
      await MetalInterchangeService.filterData(tokens, filters)
        .then((response: any) => {
          setRecord(response.data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error(err.data.errors[0].detail);
        });
      setValidator(false);
    }
  };

  useEffect(() => {
    setRecord("");
    setSelectedData([]);
    setSelectedDataTarget([]);
    setBarList([]);
    setBarListTargetM([]);
    setFromBarList([]);
    setTargetBarList([]);
  }, [filters.vaultId, filters.metalCode]);

  const approveBars = () => {
    if (record) {
      var data = {
        approve: true,
        type: record.type,
      };
      MetalInterchangeService.approveBars(tokens, data, record.id)
        .then((response: any) => {
          toast.success("Bars Approved Successfully");
          setFilters({
            vaultId: "",
            pool: "Customer",
            metalCode: "",
            weight: "",
            type: "BAR_TO_BAR",
          });
          setItemList([]);
          setRecord("");
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
        });
    }
  };

  const resetForm = async () => {
    setValidator(false);
    setFilters({
      vaultId: "",
      pool: "Customer",
      metalCode: "",
      weight: "",
      type: "BAR_TO_BAR",
    });
    setItemList([]);
    setRecord("");
  };

  const closeExchangeModal = () => {
    setIsExchange(false);
    resetForm();
  };
  const exchangeModal = () => {
    setExchangeChildren(
      <div className="w-full m-5 p-5 text-center">
        <p>Are you sure you want to exchange these bars?</p>
        <div className="d-flex justify-center  ">
          <button
            className="text-white rounded-md bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
            type="button"
            onClick={() => {
              exchangeBars();
            }}
          >
            Yes
          </button>
          <button
            className="text-white m-3 rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
            type="button"
            onClick={() => {
              closeExchangeModal();
            }}
          >
            No
          </button>
        </div>
      </div>
    );
  };

  const exchangeBars = () => {
    if (record) {
      console.log("record", record);
      setLoading(true);
      const frombarsTotal = fromBarList.reduce((acc: any, bar: any) => {
        console.log("eqwt", bar.equivalentWeight);
        return acc + parseFloat(bar.equivalentWeight);
      }, 0);
      const targetBarsTotalEqWeight = targetBarList.reduce(
        (acc: any, bar: any) => {
          return acc + parseFloat(bar.equivalentWeight);
        },
        0
      );
      const targetBarsTotalAWeight = targetBarList.reduce(
        (acc: any, bar: any) => {
          return acc + parseFloat(bar.allocatedWeight);
        },
        0
      );

      MetalInterchangeService.exchangeBars(
        tokens,
        record.id,
        frombarsTotal,
        targetBarsTotalEqWeight,
        targetBarsTotalAWeight
      )
        .then((response: any) => {
          setLoading(false);
          toast.success("Bars Exchanged Successfully");
          closeExchangeModal()

        })
        .catch((err) => {
          setLoading(false);
          closeExchangeModal()
          toast.error(err.data.errors[0].title);
        });
    }
  };

  const loadVaults = async (): Promise<void> => {
    setLoading(true);
    return new Promise(async (resolve) => {
      MetalInterchangeService.getVaults(tokens)
        .then((response: any) => {
          setVaultList(response.data.data);
          setLoading(false);
          resolve();
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Something Went Wrong!");
          resolve();
        });
    });
  };

  const exchangeBtnFunc = async () => {
    // console.log("fromBarlist", fromBarList);
    if (fromBarList?.length > 0 && targetBarList?.length > 0 ) {
      setIsExchange(true);
    }else{
      toast.error("Select Bars to exchange")
    }
  };
  useEffect(() => {
    console.log("pppp", isExchange);
    exchangeModal();
  }, [isExchange]);

  useEffect(() => {
    loadVaults();
    getMetals();
  }, []);

  return (
    <Layout type={"MetalInterchangeModule"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">{"Bar To Bar"}</h1>
      </div>
      <div className="border bg-white rounded border-gray-200 shadow">
        <div className="flex-1 p-2">
          <form
            onSubmit={(e) => {
              filter();
              e.preventDefault();
            }}
          >
            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="partner-name"
                >
                  Vault ID
                </label>
                <select
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="user-type"
                  name="vault-Id"
                  onChange={(event) => {
                    setFilters({
                      ...filters,
                      vaultId: event.target.value,
                    });
                  }}
                  value={filters.vaultId}
                >
                  <option value="">Select a Vault</option>
                  {vaultList.map((vault: any) => (
                    <option value={vault.id}>{vault.warehouseId}</option>
                  ))}
                </select>
                {validator.message("vault-Id", filters.vaultId, "required")}
                {validatorStatus ? (
                  <span className="text-xs text-red-700" id="name">
                    {getValidatorError("vault-Id")}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="partner-name"
                >
                  Metal
                </label>
                <select
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="user-type"
                  name="metal-code"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      metalCode: event.target.value,
                    })
                  }
                  value={filters.metalCode}
                >
                  <option value="">Select a Metal</option>
                  {metalList.length > 0 &&
                    metalList.map((b: any) => (
                      <option value={b.metalCode}>{b.description}</option>
                    ))}
                </select>
                {validator.message("metal-code", filters.metalCode, "required")}
                {validatorStatus ? (
                  <span className="text-xs text-red-700" id="name">
                    {getValidatorError("metal-code")}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="partner-name"
                >
                  From Pool
                </label>
                <select
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="user-type"
                  onChange={(event) => {
                    setFilters({
                      ...filters,
                      pool: event.target.value,
                    });
                  }}
                  value={filters.pool}
                >
                  <option value="Customer">Customer Pool</option>
                </select>
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="partner-name"
                >
                  Weight
                </label>
                <select
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="user-type"
                  name="bar-weight"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      weight: event.target.value,
                      fineness: event.target.selectedOptions[0].getAttribute(
                        "data-fineness"
                      ),
                    })
                  }
                  value={filters.weight}
                >
                  <option value="">Select a Weight</option>
                  {itemList.map((b: any) => (
                    <option
                      value={b.weight}
                      data-fineness={b.fineness}
                    >
                      {b.itemName}
                    </option>
                  ))}
                </select>
                {validator.message("bar-weight", filters.weight, "required")}
                {validatorStatus ? (
                  <span className="text-xs text-red-700" id="name">
                    {getValidatorError("bar-weight")}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="w-full px-3 mb-6 md:mb-0">
                <div className="flex justify-end">
                  {/* <button
                                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                                    type="button" onClick={() => {
                                    filter()
                                }}>
                                    Filter
                                </button> */}
                  <input
                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                    type="submit"
                    value="Filter"
                  />
                  <button
                    className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      resetForm();
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="m-2 rounded">
          {Loading ? (
            <Loader />
          ) : (
            <div>
              <fieldset className="bg-gray-100 border border-gray-300 flex min-w-0 mt-3 p-3 p-1 pb-4 rounded w-full">
                <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
                  {"From Bars"}
                </legend>
                <div className="w-full">
                  <button
                    className="bg-purple-950 float-right font-bold mb-1.5 px-4 py-2 rounded text-sm text-white"
                    type="button"
                    onClick={() => {
                      addModalElements("FROM");
                    }}
                  >
                    + Add Bar
                  </button>
                  <div className="">
                    <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                      <thead>
                        <tr className="text-left border-b border-gray-300">
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Bar No
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Bar Description
                          </th>

                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Allocated Weight
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Available Weight
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Total Weight
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {0 !== fromBarList.length ? (
                          fromBarList.map((partner: any, index: number) => {
                            return (
                              <>
                                {undefined !== partner.barNo ? (
                                  <tr
                                    key={index}
                                    className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                                  >
                                    <td className="py-3 px-3 text-left relative">
                                      {partner.barNo}
                                    </td>
                                    <td className="py-3 px-3 text-left relative">
                                      {partner.brandName || "N/A"}
                                    </td>
                                    {/* <td className="py-3 px-3 text-left relative">
                                      {partner.name || "N/A"}
                                    </td> */}
                                    <td className="py-3 px-3 text-left relative">
                                      {partner?.allocatedWeight}
                                    </td>
                                    <td className="py-3 px-3 text-left relative">
                                      {partner?.availbleWeight}
                                    </td>
                                    <td className="py-3 px-3 text-left relative">
                                      {partner?.weight}
                                    </td>
                                  </tr>
                                ) : (
                                  <div></div>
                                )}
                              </>
                            );
                          })
                        ) : (
                          <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                            <td
                              colSpan={5}
                              className="text-center py-3 px-3 text-left relative"
                            >
                              No data to display
                            </td>
                          </tr>
                        )}
                        {0 !== fromBarList.length ? (
                          <tr className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                            <td
                              className="py-3 px-3 text-left relative"
                              colSpan={2}
                            >
                              Total
                            </td>
                            <td
                              className="py-3 px-3 text-left relative"
                              colSpan={3}
                            >
                              {0 !== fromBarList.length
                                ? fromBarList
                                    .map((a: any) => Number(a.allocatedWeight))
                                    .reduce(function (a: any, b: any) {
                                      return a + b;
                                    })
                                : ""}
                            </td>
                          </tr>
                        ) : (
                          <div></div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </fieldset>
              <fieldset className="bg-gray-100 border border-gray-300 flex min-w-0 mt-3 p-3 p-1 pb-4 rounded w-full">
                <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
                  {"Target Bars"}
                </legend>
                <div className="w-full">
                  <button
                    className="bg-purple-950 float-right font-bold mb-1.5 px-4 py-2 rounded text-sm text-white"
                    type="button"
                    onClick={() => {
                      addModalElements("TARGET");
                    }}
                  >
                    + Add Bar
                  </button>
                  <div className="">
                    <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                      <thead>
                        <tr className="text-left border-b border-gray-300">
                          {/* <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Target Pool
                          </th> */}
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Bar No
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Bar Description
                          </th>

                          {/* <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Seg ref
                          </th> */}
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Allocated Weight
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Available Weight
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                            Total Weight
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {0 !== targetBarList.length ? (
                          targetBarList.map((partner: any, index: number) => {
                            return (
                              <>
                                {undefined !== partner.barNo ? (
                                  <tr
                                    key={index}
                                    className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                                  >
                                    {/* <td className="py-3 px-3 text-left relative">
                                      {partner.name || "N/A"}
                                    </td> */}
                                    <td className="py-3 px-3 text-left relative">
                                      {partner.barNo}
                                    </td>
                                    <td className="py-3 px-3 text-left relative">
                                      {partner.brandName || "N/A"}
                                    </td>
                                    {/* <td className="py-3 px-3 text-left relative">
                                      {partner.name || "N/A"}
                                    </td> */}
                                    <td className="py-3 px-3 text-left relative">
                                      {partner.allocatedWeight || 0}
                                    </td>
                                    <td className="py-3 px-3 text-left relative">
                                      {partner.availbleWeight || 0}
                                    </td>
                                    <td className="py-3 px-3 text-left relative">
                                      {partner.weight || 0}
                                    </td>
                                    {/* <td className="py-3 px-3 text-left relative">
                                      {partner.weight || 0}
                                    </td> */}
                                  </tr>
                                ) : (
                                  <div></div>
                                )}
                              </>
                            );
                          })
                        ) : (
                          <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                            <td
                              colSpan={7}
                              className="text-center py-3 px-3 text-left relative"
                            >
                              No data to display
                            </td>
                          </tr>
                        )}
                        {0 !== targetBarList.length ? (
                          <tr className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                            <td
                              className="py-3 px-3 text-left relative"
                              colSpan={2}
                            >
                              Total
                            </td>
                            <td
                              className="py-3 px-3 text-left relative"
                              colSpan={3}
                            >
                              {0 !== targetBarList.length
                                ? targetBarList
                                    .map((a: any) =>
                                      parseFloat(a.allocatedWeight)
                                    )
                                    .reduce(function (a: any, b: any) {
                                      return a + b;
                                    })
                                : ""}
                            </td>
                            {/* <td className="py-3 px-3 text-left relative">
                              {0 !== targetBarList.length
                                ? targetBarList
                                    .map((a: any) => parseFloat(a.weight))
                                    .reduce(function (a: any, b: any) {
                                      return a + b;
                                    })
                                : ""}
                            </td> */}
                          </tr>
                        ) : (
                          <div></div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </fieldset>
              <div className="flex items-center justify-end sm:py-3  sm:px-6 rounded-b">
                <div>
                  {HasAnyAccess(["bar.to.bar.exchange"]) && (
                    <button
                      className="text-white bg-purple-950 background-transparent font-bold px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"
                      type="button"
                      onClick={() => exchangeBtnFunc()}
                    >
                      Exchange
                    </button>
                  )}
                  {/*{*/}
                  {/*    permissions.filter((permission: any) => (permission.permissionCode === 'bar.to.bar.approve')).length > 0 && (*/}
                  {/*<button*/}
                  {/*    className="ml-1.5 text-white bg-purple-950 background-transparent font-bold px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md"*/}
                  {/*    type="button" onClick={approveBars}>Approve*/}
                  {/*</button>)}*/}
                </div>
              </div>
              <Modal
                size={"large"}
                show={show}
                handleClose={hideModal}
                children={children}
              />
              <Modal
                size={"large"}
                show={isExchange}
                handleClose={hideModal}
                children={exchangeChildren}
              />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default MetalInterchange;