import React from "react";
import { Route } from "react-router-dom";

import QueryExpand from "../Components/shared/QueryExpand";

import TradeExeRuleMain from "../Components/TradeExeRuleMain";
import TradeRuleMasterHome from "../Components/TradeRuleMasterHome";
import TransQuery from "../Components/TransQuery";


export const TradeExeRoutes: React.FC = () => {
  return (
    <>
      <Route path="/master" exact component={TradeRuleMasterHome} />

      <Route path="/trade/:name" exact component={TradeExeRuleMain} />
      <Route path="/tq" exact component={TransQuery} />
      <Route path="/tqr" exact component={QueryExpand} />
      
    </>
  );
};