import Http from "../../../library/http.library";
import {httpMethods} from "../../../library/enum";
import {makeQuery} from "../../../Common/util.helper";

export const getBarReconciliationReport = async (tokens: any,currentPage: any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/bar-rec-report?isPaginated=true&page=${currentPage}`).setToken(tokens.idToken.jwtToken).request();
};

export const getCumulativeInventoryReport = async (tokens: any,currentPage: any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/bar-cumu-inventory?isPaginated=false&page=${currentPage}`).setToken(tokens.idToken.jwtToken).request();

};

export const getMetals = async (tokens: any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/metal-master?isPaginated=false`).setToken(tokens.idToken.jwtToken).request();
    // return new Promise(async (resolve, reject) => {
    //   let apiUrl = process.env.REACT_APP_URL + `/api/v1/metalcodemaster/all/with-sync`;
    //   resolve(await axios.get(apiUrl));
    // });
};

export const getVaults = async (tokens: any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/warehouse-master?isPaginated=false`).setToken(tokens.idToken.jwtToken).request();

};

export const filterBarReconciliationReport = async (data: any, currentPage: number, tokens: any) => {
    var query = {
        isPaginated: data.isPaginated,
        ...(data.metalCode ? {metalCode: data.metalCode} : {}),
        ...(data.partner ? {partner: data.partner} : {}),
        ...(data.date ? {date: data.date} : {}),
        ...(data.vaultId ? {vaultId: data.vaultId} : {}),
    }
    const params = makeQuery(query);
    return await Http.setMethod(httpMethods.get).setUrl(`/bar-rec-report?page=${currentPage}${params}`).setToken(tokens.idToken.jwtToken).request();
};

export const filterBarReconciliationCumalativeReport = async (data: any, currentPage: number, tokens: any) => {
    var query = {
        isPaginated: data.isPaginated,
        ...(data.metalCode ? {metalCode: data.metalCode} : {}),
        ...(data.currentDate ? {fromDate: data.currentDate} : {}),
        ...(data.partner ? {partner: data.partner} : {}),
        ...(data.fromDate ? {fromDate: data.fromDate} : {}),
        ...(data.toDate ? {toDate: data.toDate} : {}),
        ...(data.vaultId ? {vaultId: data.vaultId} : {}),
    }
    const params = makeQuery(query);
    return await Http.setMethod(httpMethods.get).setUrl(`/bar-cumu-inventory?page=${currentPage}${params}`).setToken(tokens.idToken.jwtToken).request();
};

export const getSegregationDetails = async (tokens: any, currentPage: any, data: any,id?:any) => {
    var query = {
        pageSize: 10,
        isPaginated: data.isPaginated,
        ...(data.vaultId ? { warehouseId: data.vaultId} : {}),
        ...(data.metalCode ? { metalCode: data. metalCode} : {}),
        ...(data.partnerId ? {partnerId: data.partnerId} : {}),
        ...(data.accountId ? {accountId: data.accountId} : {}),
        ...(data.reference ? {ref: data.reference} : {}),
        ...(data.fromDate ? {fromDate: data.fromDate} : {}),
        ...(data.toDate ? {toDate: data.toDate} : {}),
        ...(data.customerId ? {customerId: data.customerId} : {}),
        ...(data.allocationStatus ? {allocationStatus: data.allocationStatus} : {}),
        ...(data.isExchange ? {isExchange: data.isExchange} : {}),
        ...(data.orderReference ? {reference: data.orderReference} : {}),
        ...(id ? {ref: id} : {}),
    }
    const params = makeQuery(query);
    return await Http.setMethod(httpMethods.get).setUrl(`/segregation?page=${currentPage}${params}`).setToken(tokens.idToken.jwtToken).request();

};

export const getCustomerTotalStock = async (tokens: any, currentPage: any, data: any) => {
    var query = {
        pageSize: 10,
        isPaginated: data.isPaginated,
        ...(data.vaultId ? { warehouseId: data.vaultId} : {}),
        ...(data.metalCode ? { metalCode: data. metalCode} : {}),
        ...(data.partnerId ? {partnerId: data.partnerId} : {}),
        ...(data.fromDate ? {fromDate: data.fromDate} : {}),
        ...(data.toDate ? {toDate: data.toDate} : {}),
        ...(data.customerId ? {customerId: data.customerId} : {}),
        ...(data.allocationStatus ? {allocationStatus: data.allocationStatus} : {}),
        ...(data.isDownload ? {isDownload: data.isDownload} : {}),
    }
    const params = makeQuery(query);
    return await Http.setMethod(httpMethods.get).setUrl(`/total/stock/per/user?page=${currentPage}${params}`).setToken(tokens.idToken.jwtToken).request();
};
const BarReconciliationReportService = {
    getBarReconciliationReport,
    filterBarReconciliationReport,
    filterBarReconciliationCumalativeReport,
    getCumulativeInventoryReport,
    getMetals,
    getVaults,
    getSegregationDetails,
    getCustomerTotalStock
};

export default BarReconciliationReportService;
