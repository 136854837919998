import {makeQuery} from "../../../Common/util.helper";
import Http from "../../../library/http.library";
import {httpMethods} from "../../../library/enum";
import moment from "moment";

export const getSettlements = async (tokens: any, currentPage: any, data: any) => {
    var query = {
        isPaginated: true,
        pageSize: 10,
        ...(data.provider ? {provider: data.provider} : {}),
        ...(data.fromDate ? {fromDate: moment(data.fromDate).format("YYYY/MM/DD")} : {}),
        ...(data.toDate ? {toDate: moment(data.toDate).format("YYYY/MM/DD")} : {}),
    }
    const params = makeQuery(query);
    return await Http.setMethod(httpMethods.get).setUrl(`/settlement/summary?page=${currentPage}${params}`).setResponseType('application/json').setToken(tokens.idToken.jwtToken).request();

};

export const getSettlementsDetails = async (tokens: any, currentPage: any, data: any, id: any) => {
    var query = {
        pageSize: 10,
        ...(data.provider ? {provider: data.provider} : {}),
        ...(data.date ? {date: moment(data.date).format("YYYY-MM-DD")} : {}),
    }
    const params = makeQuery(query);
    return await Http.setMethod(httpMethods.get).setUrl(`/settlement/summary/details?page=${currentPage}${params}`).setResponseType('application/json').setToken(tokens.idToken.jwtToken).request();

};

export const getApiSettlementsLogsData = async (tokens: any, currentPage: any, data: any) => {
    var query = {
        pageSize: 10,
        ...(data.provider ? {provider: data.provider} : {}),
        ...(data.createdAtTo ? {toDate: data.createdAtTo} : {}),
        ...(data.createdAtFrom ? {fromDate: data.createdAtFrom} : {}),
    }
    const params = makeQuery(query);
    return await Http.setMethod(httpMethods.get).setUrl(`/settlement/log?page=${currentPage}${params}`).setResponseType('application/json').setToken(tokens.idToken.jwtToken).request();

};

export const settlementReportDownload = async (tokens: any,data:any) => {
    var query = {
        pageSize: 10,
        ...(data.provider ? {provider: data.provider} : {}),
        ...(data.date ? {date: moment(data.date).format("YYYY-MM-DD")} : {}),
    }
    const params = makeQuery(query);
    return await Http.setMethod(httpMethods.get).setResponseType('blob').setUrl(`/settlement/summary/download?${params}`).setToken(tokens.idToken.jwtToken).request();

};
export const logReportDownload = async (tokens: any,data:any) => {
    // var query = {
        // ...(data.partnerId ? {partnerId: data.partnerId} : {}),
        // ...(data.provider ? {provider: data.provider} : {}),
        // ...(data.transactionType ? {transactionType: data.transactionType} : {}),
        // ...(data.date ? {date: moment(data.date).format("YYYY/MM/DD")} : {}),
    // }
    // const params = makeQuery(query);
    return await Http.setMethod(httpMethods.get).setResponseType('blob').setUrl(`/settlement/log/download/${data}`).setToken(tokens.idToken.jwtToken).request();
};

export const getSettlementsLogsDetails = async (tokens: any, id: any, page: any) => {
    return await Http.setMethod(httpMethods.get).setUrl(`/settlement/log/details/${id}?page=${page}`).setResponseType('application/json').setToken(tokens.idToken.jwtToken).request();

};

const SettlementReportService = {
    getSettlements,
    getSettlementsDetails,
    getApiSettlementsLogsData,
    logReportDownload,
    settlementReportDownload,
    getSettlementsLogsDetails
};

export default SettlementReportService;
