import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import Datetime from "react-datetime";
import FundDataTable from "./FundDataTable";
import { getEventByCustId } from "../FundManagement/Services/Fund.Service";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import { DATA_TYPE } from "./utils";
import './FundMgtHead.css'

import { ExportToExcel } from "../Util/ExcelExport";

import moment from "moment";

const FundMgtHead = () => {
  const history = useHistory();
  const [fund, setFund] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [range, setRange] = useState<any>([]);
  const [paraFund, setParaFund] = useState();
  const [validator, setValdator] = useState(false);

  // const fetchFund = async (customer_id:any, advanced:any) => {
  //   try {
  //     let allRef: any = await getEventByCustId(customer_id,advanced);
  //     setFund(allRef?.data?.data);
  //     setLoading(false);
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  useEffect(() => {
    fetchQuery();
  }, [])

  const displayData = !!range?.length ? range : [];

  console.log(displayData, "DATA DISPLAY");

  const fetchQuery = async () => {
    try {
      console.log("LOG: ", Date.now());

      if(fund?.settlementdate_from > fund?.settlementdate_to) {
        toast.error("Settlement to date should be larger than settlement from date")
        return;
      }
      if(fund?.transaction_from > fund?.transaction_to) {
        toast.error("Transaction to date should be larger than transaction from date")
        return;
      }
      
      let allRef: any = await getEventByCustId(fund);
      setRange(allRef?.data?.data?.rows);
      setLoading(false);
    } catch (er) {
      setLoading(false);
      toast.error(er);
    }
  };

  console.log(fund, "HJHJHJHH");

  const onChangeFund = (e: any, type: any) => {
    if (
      e?.target?.id === "customer_id" ||
      e?.target?.id === "fund_trn_id" ||
      e?.target?.id === "pge" ||
      e?.target?.id === "Entity" ||
      e?.target?.id === "partner" ||
      e?.target?.id === "Sourcemodule" ||
      e?.target?.id === "paymentStatus" ||
      e?.target?.id === "settlementStatus"
    ) {
      return setFund({
        ...fund,
        [e?.target?.id]: e.target.value,
      });
    }

    if (type === DATA_TYPE.START_DATE) {
      const value = new Date(e).toISOString();
      return setFund({
        ...fund,
        transaction_from: value.replace("T", " ").replace("Z", ""),
      });
    }
    if (type === DATA_TYPE.END_DATE) {
      const value = new Date(e).toISOString();
      return setFund({
        ...fund,
        transaction_to: value.replace("T", " ").replace("Z", ""),
      });
    }
    if (type === DATA_TYPE.SETTLE_START_DATE) {
      const value = new Date(e).toISOString();
      return setFund({
        ...fund,
        settlementdate_from: value.replace("T", " ").replace("Z", ""),
      });
    }
    if (type === DATA_TYPE.SETTLE_END_DATE) {
      const value = new Date(e).toISOString();
      return setFund({
        ...fund,
        settlementdate_to: value.replace("T", " ").replace("Z", ""),
      });
    }
  };

  const viewClick = (id: any) => {
    // localStorage.setItem("Id", JSON.stringify(id));
    history.push(`/fund/${id}`);
  };

  const ColList = [
    "Fund_trn_id",
    "pge",
    "Entity",
    "method",
    "Partner",
    "Customer Id",
    "Source Module",
    "Order Id",
    "Amount",
    "Currency",
    "Request Time",
    "Response Time",
    "Response Id",
    "payment Status",
    "Error code",
    "Description",
    "feild",
    "responsesource",
    "step",
    "reason",
    "payment_id",
    "source",
    "source ref",
    "target",
    "target ref",
    "settlement status",
    "settlement date",
    "settlement ref",
    "request",
    "response",
  ]

  const exportData = () => {
    return displayData.map((fund:any) => {
       return{
         [ColList[0]] : fund.fund_trn_id,
         [ColList[1]] : fund.pge,
         [ColList[2]] : fund.Entity,
         [ColList[3]] : fund.method,
         [ColList[4]] : fund.partner,
         [ColList[5]] : fund.customer_id,
         [ColList[6]] : fund.Sourcemodule,
         [ColList[7]] : fund.order_id,
         [ColList[8]] : fund.Amount,
         [ColList[9]] : fund.currency,
         [ColList[10]] : fund.Request_time ? moment(fund.Request_time).format("YYYY-MM-DD hh:mm:ss") : "",
         [ColList[11]] : fund.Response_time ? moment(fund.Response_time).format("YYYY-MM-DD hh:mm:ss") : "",
         [ColList[12]] : fund.Response_Id,
         [ColList[13]] : fund.paymentStatus,
         [ColList[14]] : fund.Error_code,
         [ColList[15]] : fund.description,
         [ColList[16]] : fund.field,
         [ColList[17]] : fund.responsesource,
         [ColList[18]] : fund.step,
         [ColList[19]] : fund.reason,
         [ColList[20]] : fund.payment_id,
         [ColList[21]] : fund.source,
         [ColList[22]] : fund.sourceref,
         [ColList[23]] : fund.target,
         [ColList[24]] : fund.targetref,
         [ColList[25]] : fund.settlementStatus,
         [ColList[26]] : fund.settlementdate ? moment(fund.settlementdate).format("YYYY-MM-DD hh:mm:ss") : "",
         [ColList[27]] : fund.settlementref,
         [ColList[28]] : JSON.stringify(fund.request),
         [ColList[29]] : JSON.stringify(fund.response),
       }
     })
  }

  return (
    <>
      <Layout type={"PaymentModule"}>
        <ToastContainer></ToastContainer>
        <div className="flex flex-row">
          <div className="flex-1 border bg-white shadow-sm sm:rounded-md w-full ">
            <div className="flex p-4">
              <div className="flex-initial">
                <div className="px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-city"
                  >
                    Customer ID
                  </label>
                  <input
                    className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                    id="customer_id"
                    type="text"
                    placeholder="customerID"
                    // value={ruleName}
                    onChange={(e: any) => {
                      onChangeFund(e, DATA_TYPE.NORMAL);
                    }}
                  />
                </div>
              </div>
              <div className="flex-grow-0 w-1/2">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-city"
                  >
                    Transaction ID
                  </label>
                  <input
                    className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                    id="fund_trn_id"
                    name="bid"
                    type="text"
                    placeholder="Enter a Transaction ID"
                    // value={ruleName}
                    onChange={(e: any) => {
                      onChangeFund(e, DATA_TYPE.NORMAL);
                    }}
                  />
                </div>
              </div>
              <div className="flex-grow-0 w-1/2">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-city"
                  >
                    PGE
                  </label>
                  <select
                    onChange={(e: any) => {
                      onChangeFund(e, DATA_TYPE.NORMAL);
                    }}
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="pge"
                    // value={selectedMetal}
                  >
                    <option value="">Select an Option</option>
                    <option value="RPY">RPY</option>
                    <option value="DBS">DBS</option>
                  </select>
                </div>
              </div>
              <div className="flex-grow-0 w-1/2">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-city"
                  >
                    Entity
                  </label>
                  <select
                    onChange={(e: any) => {
                      onChangeFund(e, DATA_TYPE.NORMAL);
                    }}
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="Entity"
                    // value={selectedMetal}
                  >
                    <option value="">Select an Option</option>
                    <option value="UPI">UPI</option>
                    <option value="ACT">ACT</option>
                    <option value="Net Banking">Net Banking</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="flex bg-gray-200 p-4 py-6">
              <div className="flex-1">
                <div className="px-3">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-state"
                  >
                    Partner
                  </label>
                  <div className="relative">
                    <select
                      onChange={(e: any) => {
                        onChangeFund(e, DATA_TYPE.NORMAL);
                      }}
                      className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                      id="partner"
                      // value={selectedMetal}
                    >
                      <option value="">Select an Option</option>
                      <option value="EBL">EBL</option>
                      <option value="Bajaj">Bajaj</option>
                    </select>
                    <div className=" pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                  </div>
                </div>
              </div>
              <div className="flex-grow-0 w-1/2">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-city"
                  >
                    Source Module
                  </label>
                  <select
                    onChange={(e: any) => {
                      onChangeFund(e, DATA_TYPE.NORMAL);
                    }}
                    className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                    id="Sourcemodule"
                    // value={selectedMetal}
                  >
                    <option value="">Select an Option</option>
                    <option value="FM">FM</option>
                  </select>
                </div>
              </div>
              <div className="flex-1">
                <div className="px-3">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-state"
                  >
                    Payment Status
                  </label>
                  <div className="relative">
                    <select
                      onChange={(e: any) => {
                        onChangeFund(e, DATA_TYPE.NORMAL);
                      }}
                      className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                      id="paymentStatus"
                      // value={selectedMetal}
                    >
                      <option value="">Select an Option</option>
                      <option value="ACCP">ACCP</option>
                      <option value="ACTC">ACTC</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <div className="px-3">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-state"
                  >
                    Settlement Status
                  </label>
                  <div className="relative">
                    <select
                      onChange={(e: any) => {
                        onChangeFund(e, DATA_TYPE.NORMAL);
                      }}
                      className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                      id="settlementStatus"
                      // value={selectedMetal}
                    >
                      <option value="">Select an Option</option>
                      <option value="enable">Enable</option>
                      <option value="disable">Disable</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex p-4 pt-5  border-gray-200 flex-wrap">
              <div className="flex flex-1">
                <div className="px-3 w-full">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-state"
                  >
                    Transaction from Time
                  </label>
                  <div className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-1 px-4 border shadow-sm">
                    <Datetime
                      value={fund.transaction_from ? moment(fund?.transaction_from) : ""}
                      timeFormat={"HH:mm"}
                      dateFormat={"DD/MM/YYYY"}
                      inputProps={{placeholder: 'pick date time'}}
                      className={`appearance-none rounded  block w-full px-2
                              py-1 border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm
                           `}
                      onChange={(e: any) => {
                        onChangeFund(e, DATA_TYPE.START_DATE);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-1">
                <div className="px-3 w-full">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-state"
                  >
                    Transaction to Time
                  </label>
                  <div className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-1 px-4 border shadow-sm">
                    <Datetime
                      value={fund.transaction_to ? moment(fund?.transaction_to) : ""}
                      timeFormat={"HH:mm"}
                      dateFormat={"DD/MM/YYYY"}
                      inputProps={{placeholder: 'pick date time'}}
                      className={`appearance-none rounded  block w-full px-2
                              py-1  border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm
                           `}
                      onChange={(e: any) => {
                        onChangeFund(e, DATA_TYPE.END_DATE);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-1">
                <div className="px-3 w-full">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-state"
                  >
                    Settlement From Time
                  </label>
                  <div className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-1 px-4 border shadow-sm">
                    <Datetime
                      value={fund.settlementdate_from ? moment(fund?.settlementdate_from) : ""}
                      timeFormat={"HH:mm"}
                      dateFormat={"DD/MM/YYYY"}
                      inputProps={{placeholder: 'pick date time'}}
                      className={`appearance-none rounded  block w-full px-2
                py-1 border-gray-300 placeholder-gray-500 text-gray-900
              focus:outline-none text-sm
             `}
                      onChange={(e: any) => {
                        onChangeFund(e, DATA_TYPE.SETTLE_START_DATE);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-1">
                <div className="px-3 w-full">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                    for="grid-state"
                  >
                    Settlement to Time
                  </label>
                  <div className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-1 px-4 border shadow-sm">
                    <Datetime
                      value={fund.settlementdate_to ? moment(fund?.settlementdate_to) : ""}
                      timeFormat={"HH:mm"}
                      dateFormat={"DD/MM/YYYY"}
                      inputProps={{placeholder: 'pick date time'}}
                      className={`appearance-none rounded  block w-full px-2
                py-1 border-gray-300 placeholder-gray-500 text-gray-900
              focus:outline-none text-sm
             `}
                      onChange={(e: any) => {
                        onChangeFund(e, DATA_TYPE.SETTLE_END_DATE);
                      }}
                    />
                  </div>
                </div>
              </div>

              
            </div>
            <div className='flex justify-end mb-5 pr-4 mt-3'>
            <div className="flex  justify-end ">

                <button
                  className="mt-0 bg-indigo-500 hover:bg-indigo-400 text-white px-4 py-2 rounded font-bold"
                  onClick={() => {
                    setLoading(true);
                    fetchQuery();
                  }}
                >
                  Query
                </button>
              </div>

              <div className="flex  justify-end px-3">

                <button
                  className="mt-0 bg-green-500 hover:bg-green-400 text-white px-4 py-2 rounded font-bold"
                  onClick={(e)=> {ExportToExcel(exportData() , `fund_management_report_${moment().format("YYYY-MM-DD")}`)}}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
        {loading ? <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h2 className="text-center text-white text-xl font-semibold">Loading...</h2>
          <p className="w-1/3 text-center text-white">This may take a few seconds, please don't close this page.</p>
        </div> : <FundDataTable viewClick={viewClick} displayData={displayData} />}
        {/* <FundDataTable viewClick={viewClick} displayData={displayData} /> */}
      </Layout>
    </>
  );
};

export default FundMgtHead;
