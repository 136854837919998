import React from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { DATA_TYPE, getName } from './utils';
interface PropsType {
  closeEdit: any;
  ebl: any;
  handleChange:any;
  selectedIndex:any;
  handleUpdate:any;
}

const InpData: React.FC<PropsType> = ({ closeEdit, ebl, handleChange, selectedIndex , handleUpdate}) => {
  return (
    <>
      <tr className="border-b border-gray-200 bg-gray-100">
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto py-4 w-full flex-wrap items-stretch ">
            <label className="text-black">{ebl.Rule_ref}</label>
          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto py-4 w-full flex-wrap items-stretch ">
            <input
              type="text"
              placeholder="Description"
              className="px-10 py-2 placeholder-blueGray-300 pr-4 text-black relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full pr-10"
              name="description"
              onChange={(e: any) => {
                handleChange(e, DATA_TYPE.NORMAL);
              }}
              value={ebl?.description ?? ""}
            />
            <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
              <i className="fas fa-user"></i>
            </span>
          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto py-4 w-full flex-wrap items-stretch ">
            <select
              className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-black border-grey-lighter rounded py-2 px-4"
              id="grid-state"
              name="type"
              onChange={(e: any) => {
                handleChange(e, DATA_TYPE.NORMAL);
              }}
              value={ebl?.type ?? ""}
            >
              <option value="Sell">Add</option>
              <option value="Buyback">Buyback</option>
            </select>
            <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
              <i className="fas fa-user"></i>
            </span>
          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto py-4 w-full flex-wrap items-stretch ">
            <select
              className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-black border-grey-lighter rounded py-2 px-4"
              id="grid-state"
              name="metal"
              onChange={(e: any) => {
                handleChange(e, DATA_TYPE.NORMAL);
              }}
              value={ebl?.metal ?? ""}
            >
              <option value="Au">Au</option>
              <option value="Ag">Ag</option>
              <option value="Pd">Pd</option>
              <option value="Pt">Pt</option>
            </select>
            <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
              <i className="fas fa-user"></i>
            </span>
          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto py-4 w-full flex-wrap items-stretch ">
            <label className="text-black">{getName(ebl?.metal)}</label>
          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto py-4 w-full flex-wrap items-stretch ">
            <Datetime
              value={moment(ebl?.Effect_From_Date)}
              timeFormat={"HH:mm"}
              dateFormat={"DD/MM/YYYY"}
              className={`appearance-none rounded  block w-full px-2
                              py-2 bg-white border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm
                           `}
              onChange={(e: any) => {
                handleChange(e, DATA_TYPE.START_DATE);
              }}
            />
          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto py-4 w-full flex-wrap items-stretch ">
            <Datetime
              value={moment(ebl?.Effect_To_Date)}
              timeFormat={"HH:mm"}
              dateFormat={"DD/MM/YYYY"}
              className={`appearance-none rounded  block w-full px-2
              py-2 bg-white border border-gray-300 placeholder-gray-500 text-gray-900
                            focus:outline-none text-sm
                           `}
              onChange={(e: any) => {
                handleChange(e, DATA_TYPE.END_DATE);
              }}
            />
          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto py-4 w-full flex-wrap items-stretch ">
            <select
              className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-black border-grey-lighter rounded py-2 px-4"
              id="grid-state"
              name="source"
              onChange={(e: any) => {
                handleChange(e, DATA_TYPE.NORMAL);
              }}
              value={ebl?.source ?? ""}
            >
              <option value={1}>EBL</option>
              <option value={2}>BAJFINSERV</option>
            </select>
            <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
              <i className="fas fa-user"></i>
            </span>
          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto py-4 w-full flex-wrap items-stretch ">
            {/* <select
              className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-black border-grey-lighter rounded py-2 px-4"
              id="grid-state"
              name="Customer_Group"
              onChange={(e: any) => {
                handleChange(e, DATA_TYPE.NORMAL);
              }}
              value={ebl?.Customer_Group ?? ""}
            >
              <option value="g1">Gr 1</option>
              <option value="g2">Gr 2</option>
              <option value="g3">Gr 3</option>
              <option value="g4">Gr 4</option>
            </select> */}
            <span className="z-10 h-full leading-snug font-normal text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
              <i className="fas fa-user"></i>
            </span>
          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto py-4 w-full flex-wrap items-stretch ">
            {/* <input
              type="text"
              name="customer_Id"
              value={ebl?.customer_Id ?? ""}
              onChange={(e: any) => {
                handleChange(e, DATA_TYPE.NORMAL);
              }}
              
              className="px-3 py-3 placeholder-blueGray-300 text-black relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full pr-10"
            /> */}
            <span className="z-10 h-full leading-snug font-normal  text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
              <i className="fas fa-user"></i>
            </span>
          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="relative flex-auto py-4 w-full flex-wrap items-stretch ">
            <input
              className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-black border border-grey-lighter rounded py-2 px-4"
              id="grid-city"
              name="priority"
              type="number"
              min="0"
              onChange={(e: any) => {
                handleChange(e, DATA_TYPE.NORMAL);
              }}
              value={ebl?.priority ?? ""}
            />
          </div>
        </td>
        <td className="px-3 py-2 text-left">
          <div className="flex item-center justify-center">
            <div
              className="w-4 mr-2 transform hover:text-blue-500 hover:scale-110"
              onClick={closeEdit}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="gray"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </svg>
            </div>
            <div className="w-4 mr-2 transform hover:text-blue-500 hover:scale-110" onClick={()=>{handleUpdate(selectedIndex)}}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="gray"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default InpData;