import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import ListOfCustomers from './Components/ListOfCustomers';
import SingleCustomer from './Components/SingleCustomerDetails';

const CustomerRoutes:React.FC = () => {
    return (
        <Fragment>
            <Route path="/customers" exact component={ListOfCustomers} />
            <Route path="/customers/:id"  exact component={SingleCustomer} />
        </Fragment>
    )
};

export default CustomerRoutes;