import React, { useState, useEffect } from "react";
import { useAuthStore } from "../../../Store/AuthStore";
import Layout from "../../Layout/Layout";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import moment from "moment";
import { Pagination } from "../../../Components-ui/Pagination";
import "./BarReconciliation.css";
import BarReconciliationReportService from "./BarReconciliationReportService";
import { getValuts } from "../../Metal/vsoCalendar/VSO.Service";
import Datetime from "react-datetime";
import Modal from "../../../Components-ui/Modal/Modal";
import customerService from "../../Customer/Services/Customer.service";
import MetalInterchangeService from "../../MetalInterchange/MetalInterchangeService";
import XLSX from "xlsx";

const BarReconciliation: React.FC = () => {
  const { tokens, warehouses, permissions } = useAuthStore();
  const width = 100;
  const [date, setDate] = useState<any>(new Date().toISOString());
  const [children, setChildren] = useState<any>();
  const [show, setModalStatus] = useState<any>(false);
  const [metaSegregationDetails, setMetaSegregationDetails] = useState<any>({});
  const [segregationDetails, setSegregationDetails] = useState<any>([]);
  const [partnerList, setPartnerList] = useState<any>([]);
  const [selectedSettlementId, setSelectedSettlementId] = useState<any>();
  const [Loading, setLoading] = useState<any>(false);
  const [customerList, setCustomerList] = useState<any>([]);
  const [
    barReconciliationReportDataList,
    setBarReconciliationReportDataList,
  ] = useState<any>([]);
  const [
    cumulativeInventoryReportDataList,
    setCumulativeInventoryReportDataList,
  ] = useState<any>([]);
  const [summaryDetails, setSummaryDetails] = useState<any>({});
  const [metalList, setMetalList] = useState<any>([]);
  const [vaultList, setVaultList] = useState<any>([]);
  const [meta, setMetaData] = useState<any>({});
  const [reportDate, setReportDate] = useState<any>({});
  const [filters, setFilters] = useState<any>({
    metalCode: "",
    vaultId: "",
    date: "",
    partner: "",
  });
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [metaCumalative, setMetaDataCumalative] = useState<any>({});
  const [currentPageCumalative, setCurrentPageCumalative] = useState<number>(0);
  const [cumalativeReportDate, setCumalativeReportDate] = useState<any>({});
  const [modalFilters, setModalFilters] = useState<any>({
    partnerId: "",
    customerId: "",
    ref: "",
    accountId: "",
    allocationStatus: "",
    isExchange: "",
    fromDate: "",
    toDate: "",
  });

  useEffect(() => {
    childrenElements();
  }, [segregationDetails, show, modalFilters, metaSegregationDetails]);

  const getCustomers = async (): Promise<void> => {
    return new Promise(async (resolve) => {
      MetalInterchangeService.getCustomerList(tokens)
        .then((response: any) => {
          setCustomerList(response.data.data);
          resolve();
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
          resolve();
        });
    });
  };

  const getPartners = () => {
    customerService
      .getPartnerList(tokens)
      .then((response: any) => {
        setPartnerList(response.data.data);
      })
      .catch((err) => {
        toast.error("Something Went Wrong!");
        setLoading(false);
      });
  };

  const childrenElements = () => {
    setChildren(
      <div className="w-full">
        <h1 className="font-bold">{"Segregation Details"}</h1>
        <div className="mb-3 bg-white p-2 pb-1 pr-3.5 pt-4 rounded shadow-2xl">
          <div className="flex-1 p-2">
            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="user-type"
                >
                  Partner ID
                </label>
                <div className="relative">
                  <select
                    className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                    id="user-type"
                    onChange={(event) =>
                      setModalFilters({
                        ...modalFilters,
                        partnerId: event.target.value,
                      })
                    }
                    value={modalFilters.partnerId}
                  >
                    <option value="">Select a Partner</option>
                    {partnerList.map((partner: any) => (
                      <option value={partner.partnerId}>{partner.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="user-type"
                >
                  Segregation Reference
                </label>
                <div className="relative">
                  <input
                    className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                    id="reference"
                    type="text"
                    placeholder="Segregation Reference"
                    autoComplete="none"
                    onChange={(event) =>
                      setModalFilters({
                        ...modalFilters,
                        ref: event.target.value,
                      })
                    }
                    readOnly
                    value={modalFilters.reference}
                  />
                </div>
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="user-type"
                >
                  Customer
                </label>
                <div className="relative">
                  <input
                    className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                    id="description"
                    type="number"
                    placeholder="Customer ID"
                    autoComplete="none"
                    onChange={(event) =>
                      setModalFilters({
                        ...modalFilters,
                        customerId: event.target.value,
                      })
                    }
                    value={modalFilters.customerId}
                  />
                </div>
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="user-type"
                >
                  Account ID
                </label>
                <div className="relative">
                  <input
                    className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                    id="description"
                    type="text"
                    placeholder=" Account ID"
                    autoComplete="none"
                    onChange={(event) =>
                      setModalFilters({
                        ...modalFilters,
                        accountId: event.target.value,
                      })
                    }
                    value={modalFilters.accountId}
                  />
                </div>
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="user-type"
                >
                  Allocation Status
                </label>
                <div className="relative">
                  <select
                    className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                    id="user-type"
                    onChange={(event) =>
                      setModalFilters({
                        ...modalFilters,
                        allocationStatus: event.target.value,
                      })
                    }
                    value={modalFilters.allocationStatus}
                  >
                    <option value="">Select a Status</option>
                    <option value="PERMANENT">PERMANENT</option>
                    <option value="TEMPORARY">TEMPORARY</option>
                    <option value="REVERSED">REVERSED</option>
                  </select>
                </div>
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="user-type"
                >
                  Exchange Status
                </label>
                <div className="relative">
                  <select
                    className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                    id="user-type"
                    onChange={(event) =>
                      setModalFilters({
                        ...modalFilters,
                        isExchange: event.target.value,
                      })
                    }
                    value={modalFilters.isExchange}
                  >
                    <option value="">Select a Status</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  for="grid-state"
                >
                  From Date
                </label>
                <div className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border shadow-sm">
                  <Datetime
                    className="efect-to"
                    inputProps={{
                      readOnly: true,
                      placeholder: "pick date",
                      className: "focus:outline-none",
                    }}
                    // value={
                    //   modalFilters.fromDate ? moment(modalFilters.fromDate) : ""
                    // }
                    value={moment(modalFilters.fromDate)}
                    renderInput={(props) => {
                      return (
                        <input
                          {...props}
                          value={modalFilters.fromDate ? modalFilters.fromDate : ""}
                        />
                      );
                    }}
                    timeFormat={""}
                    dateFormat={"DD/MM/YYYY"}
                    onChange={(e: any) => {
                      setModalFilters({
                        ...modalFilters,
                        fromDate:  moment(new Date(e).toISOString()).format(
                          "MM/DD/YYYY"
                        ),
                      });
                    }}
                  />
                </div>
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                  for="grid-state"
                >
                  To Date
                </label>
                <div className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border shadow-sm">
                  <Datetime
                    className="efect-to"
                    inputProps={{
                      readOnly: true,
                      placeholder: "pick date",
                      className: "focus:outline-none",
                    }}
                   // value={
                    //   modalFilters.toDate ? moment(modalFilters.toDate) : ""
                    // }
                    value={moment(modalFilters.toDate)}
                renderInput={(props) => {
                  return (
                    <input
                      {...props}
                      value={modalFilters.toDate ? modalFilters.toDate : ""}
                    />
                  );
                }}
                    timeFormat={""}
                    dateFormat={"DD/MM/YYYY"}
                    onChange={(e: any) => {
                      setModalFilters({
                        ...modalFilters,
                        toDate: moment(new Date(e).toISOString()).format(
                          "MM/DD/YYYY"
                        ),
                      });
                    }}
                  />
                </div>
              </div>
              <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <div className="my-6">
                  <button
                    className="px-4 py-2.5 font-bold text-white text-sm uppercase bg-purple-950 rounded"
                    type="button"
                    onClick={() =>
                      segregationDetailsView(selectedSettlementId, modalFilters, 0)
                    }
                  >
                    Filter
                  </button>
                  <button
                    className="ml-4 px-4 py-2.5 font-bold text-white text-sm uppercase bg-red-600 rounded"
                    type="button"
                    onClick={(e) => ResetModalForm()}
                  >
                    Reset
                  </button>
                </div>
              </div>
              <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <div className="my-6">
                  <button
                    className="px-4 py-2.5 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                    type="button"
                    onClick={() =>
                      downloadReport(
                        "seg-details",
                        modalFilters,
                        selectedSettlementId
                      )
                    }
                  >
                    Download Report
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-wrap mb-2">
            {Loading ? (
              <Loader />
            ) : (
              <div>
                <div className="overflow-x-auto">
                  <table className="whitespace-nowrap  rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs ">
                    <thead>
                      <tr className="text-left border-b border-gray-300">
                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                          Customer ID
                        </th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                          Partner
                        </th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                          Bar No
                        </th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                          Account ID
                        </th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                          Reference
                        </th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                          Reference Type
                        </th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                          Weight
                        </th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                          Allocation Status
                        </th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                          Metal Ledger ID
                        </th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                          Status
                        </th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                          Exchange Status
                        </th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                          Prv Allocation ID
                        </th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                          Exchange ID
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {0 !== segregationDetails.length ? (
                        segregationDetails.map((data: any, index: number) => {
                          return (
                            <tr
                              key={index}
                              className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                            >
                              <td className="py-3 px-3 text-left relative">
                                {data.customerId || "N/A"}
                              </td>
                              <td className="py-3 px-3 text-left relative">
                                <span>
                                  {
                                    partnerList?.find(
                                      (i: any) =>
                                        i.partnerId === parseInt(data.partnerId)
                                    ).name // partnerList.map((x: any) =>  x.partnerId).includes(parseInt(data.partnerId)).name
                                  }
                                </span>
                              </td>
                              <td className="py-3 px-3 text-left relative">
                                {data.barNo}
                              </td>
                              <td className="py-3 px-3 text-left relative">
                                {data.accountId}
                              </td>
                              <td className="py-3 px-3 text-left relative">
                                {data.reference}
                              </td>
                              <td className="py-3 px-3 text-left relative">
                                {data.referenceType}
                              </td>
                              <td className="py-3 px-3 text-left relative">
                                {data.weight}
                              </td>
                              <td className="py-3 px-3 text-left relative">
                                {data.allocationStatus}
                              </td>
                              <td className="py-3 px-3 text-left relative">
                                {data.metalLedgerId}
                              </td>
                              <td className="py-3 px-3 text-left relative">
                                <div className="flex justify-between items-center">
                                  <div
                                    className={`w-14 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out ${
                                      "ENABLED" === data.isActive
                                        ? "bg-green-400"
                                        : ""
                                    }`}
                                  >
                                    <div
                                      className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${
                                        "ENABLED" === data.isActive
                                          ? "translate-x-6"
                                          : ""
                                      }`}
                                    ></div>
                                  </div>
                                </div>
                              </td>
                              <td className="py-3 px-3 text-left relative">
                                <span
                                  className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${
                                    data.isExchange
                                      ? "text-green-600 bg-green-200"
                                      : "text-black-600 bg-gray-400"
                                  }`}
                                >
                                  {data.isExchange ? "Yes" : "No"}
                                </span>
                              </td>
                              <td className="py-3 px-3 text-left relative">
                                {data.prvAllocationId}
                              </td>
                              <td className="py-3 px-3 text-left relative">
                                {data.exchangeId}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                          <td
                            colSpan={13}
                            className="text-center py-3 px-3 text-left relative"
                          >
                            No data to display
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  meta={metaSegregationDetails}
                  handlePageChange={(number: number) => {
                    modalPaginationFilter(number);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const modalPaginationFilter = async (page: number) => {
    segregationDetailsView(selectedSettlementId, modalFilters, page);
  };

  const hideModal = () => {
    setModalStatus(false);
  };

  const ResetModalForm = async () => {
    var date = new Date();
    setModalFilters({
      ref:selectedSettlementId,
      partnerId: "",
      customerId: "",
      accountId: "",
      allocationStatus: "",
      isExchange: "",
      // fromDate: new Date(date.getFullYear(), date.getMonth(), 1).toISOString(),
      // toDate: new Date().toISOString(),
      fromDate: "",
      toDate: "",
    });
    segregationDetailsView(selectedSettlementId, {}, 0);
  };

  const loadMetals = async (): Promise<void> => {
    return new Promise(async (resolve) => {
      BarReconciliationReportService.getMetals(tokens)
        .then((response: any) => {
          setMetalList(response.data.data);
          resolve();
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
          resolve();
        });
    });
  };

  const loadVaults = async (): Promise<void> => {
    return new Promise(async (resolve) => {
      getValuts(tokens, warehouses, permissions)
        .then((response: any) => {
          setVaultList(response.data.data);
          resolve();
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
          resolve();
        });
    });
  };

  useEffect(() => {
    setLoading(true);
    loadMetals();
    loadVaults();
    getPartners();
    getCustomers();
  }, []);

  useEffect(() => {
    setInitFilters();
  }, [vaultList]);

  useEffect(() => {
    setInitFilters();
  }, [metalList]);

  useEffect(() => {
    filter(currentPage, currentPageCumalative);
  }, [filters]);

  const setInitFilters = async () => {
    if (vaultList.length > 0 && metalList.length > 0) {
      setFilters({
        metalCode: metalList[0].metalCode,
        vaultId: vaultList[0].id,
        date: date,
        partner: "",
      });
    } else {
      setFilters({
        metalCode: "",
        vaultId: "",
        date: "",
        partner: "",
      });
    }
  };

  const ResetForm = async () => {
    setCurrentPage(0);
    setCurrentPageCumalative(0);
    setInitFilters();
    setDate(new Date().toISOString());
    setReportDate(new Date(date).toISOString())
  };

  const filter = async (
    currentPageInv: number,
    currentPageCumalative: number
  ) => {
    if (!(filters && filters.vaultId)) return;
    try {
      setLoading(true);
      const jobs: Promise<any>[] = [];
      jobs.push(inventoryGet(currentPageInv));
      jobs.push(inventoryCumalativeGet(currentPageCumalative));
      await Promise.all(jobs);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const inventoryGet = async (page: number) => {
    filters.isPaginated = true;
    await BarReconciliationReportService.filterBarReconciliationReport(
      filters,
      page,
      tokens
    )
      .then((response: any) => {
        setBarReconciliationReportDataList(response.data.data.result);
        setSummaryDetails(response.data.data.summary);
        var ts = response.data.data.details.ts[0]+"T"+response.data.data.details.ts[1];
        setReportDate(ts);
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPage(response.data.meta.page);
      })
      .catch((err) => {
        toast.error(err.response.data.errors[0].title);
      });
  };

  const inventoryCumalativeGet = async (page: number) => {
    await BarReconciliationReportService.filterBarReconciliationCumalativeReport(
      filters,
      page,
      tokens
    )
      .then((response: any) => {
        setCumulativeInventoryReportDataList(response.data.data.result);
        setCumalativeReportDate(response.data.data.details);
        setMetaDataCumalative({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPageCumalative(response.data.meta.page);
      })
      .catch((err) => {
        toast.error(err.response.data.errors[0].title);
      });
  };

  const segregationDetailsView = async (segNo: any, data: any, page: any) => {
    let segRef = "";
    if (!segNo) segRef = data.reference;
    else {
      segRef = segNo;
      setSelectedSettlementId(segNo);
    }

    data.isPaginated = true;
    await BarReconciliationReportService.getSegregationDetails(
      tokens,
      page,
      data,
      segRef
    )
      .then((response: any) => {
        setSegregationDetails(response.data.data.result);
        setMetaSegregationDetails({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setLoading(false);
        setModalStatus(true);
      })
      .catch((err) => {
        toast.error(err.response.data.errors[0].title);
      });
  };

  const downloadReport = async (type: string, filters: any, segNo?: string) => {
    filters.isPaginated = false;
    if (type === "cumulative") {
      await BarReconciliationReportService.filterBarReconciliationCumalativeReport(
        filters,
        0,
        tokens
      )
        .then((response: any) => {
          const worksheet = XLSX.utils.json_to_sheet(response.data.data.result);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, type);
          XLSX.writeFile(workbook, `cumulative-report-${filters.date}.xlsx`);
        })
        .catch((err) => {
          toast.error(err.response.data.errors[0].title);
        });
    } else if (type === "bar-reco") {
      await BarReconciliationReportService.filterBarReconciliationReport(
        filters,
        0,
        tokens
      )
        .then((response: any) => {
          const worksheet = XLSX.utils.json_to_sheet(response.data.data.result);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, type);
          XLSX.writeFile(workbook, `bar-reco-${filters.date}.xlsx`);
        })
        .catch((err) => {
          toast.error(err.response.data.errors[0].title);
        });
    } else if (type === "seg-details") {
      console.log(filters);

      await BarReconciliationReportService.getSegregationDetails(
        tokens,
        0,
        filters,
        segNo
      ).then((response: any) => {
        console.log(response.data.data);

        const worksheet = XLSX.utils.json_to_sheet(response.data.data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, type);
        XLSX.writeFile(workbook, `segregation-details-${segNo}.xlsx`);
      });
    }
  };

  return (
    <Layout type={"Reports"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">
          {"Bar Reconciliation Report"}
        </h1>
      </div>
      <div className="border bg-white rounded border-gray-200 shadow">
        <div className="flex-1 p-2 pb-0">
          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="pb-3 w-full md:w-2/4 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="vault-id"
              >
                Vault Id
              </label>
              <select
                className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                name="vault-id"
                id="vault-id"
                placeholder="Vault ID"
                onChange={(event) =>
                  setFilters({
                    ...filters,
                    vaultId: event.target.value,
                    date:filters.date
                  })
                }
                value={filters.vaultId}
              >
                {vaultList.map((vault: any) => (
                  <option value={vault.id}>{vault.warehouseId}</option>
                ))}
              </select>
            </div>
            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="user-id"
              >
                Metal Code
              </label>
              <select
                className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                name="user-id"
                id="user-id"
                placeholder="Metal Code"
                onChange={(event) =>
                  setFilters({
                    ...filters,
                    metalCode: event.target.value,
                  })
                }
                value={filters.metalCode}
              >
                {metalList.map((metal: any) => (
                  <option value={metal.metalCode}>{metal.metalCode}</option>
                ))}
              </select>
            </div>

            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="partner"
              >
                Partner
              </label>
              <div className="relative">
                <select
                  className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                  id="partner"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      partner: event.target.value,
                    })
                  }
                  value={filters.partner}
                >
                  <option value="">Select a Partner</option>
                  {partnerList.map((partner: any) => (
                    <option value={partner.partnerId}>{partner.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block text-sm font-medium text-gray-700 mb-1 font-bold"
                for="grid-state"
              >
                Date
              </label>
              <div className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border shadow-sm">
                <Datetime
                  className="efect-to"
                  inputProps={{
                    readOnly: true,
                    className: "focus:outline-none",
                  }}
                  value={moment(date)}
                  timeFormat={"HH:mm:ss"}
                  dateFormat={"DD/MM/YYYY"}
                  onChange={(e: any) => {
                    setDate(new Date(e).toISOString());
                    setFilters({
                      ...filters,
                      date: new Date(e).toISOString(),
                    });
                  }}
                />
              </div>
            </div>
            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <div className="my-6">
                <button
                  className="float-right ml-4 px-4 py-2.5 font-bold text-white text-sm uppercase bg-red-600 rounded"
                  type="button"
                  onClick={(e) => ResetForm()}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white rounded border-gray-200 shadow m-2">
          {Loading ? (
            <Loader />
          ) : (
            <div>
              <fieldset className="bg-gray-100 border border-gray-300 flex min-w-0 mt-3 p-3 p-1 pb-4 rounded w-full">
                <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
                  {"Bar Reconciliation Report"}
                </legend>
                <div className="w-full">
                  <div
                    className="font-bold mb-1 ml-3 pb-2"
                    style={{ fontSize: "13px" }}
                  >
                    Report as at{" "}
                    <span className="bg-green-500  py-1 px-2 rounded text-white text-xs">
                      {moment(reportDate).format("DD-MM-YYYY HH:mm")}
                    </span>
                    <button
                      className={`border ${
                        barReconciliationReportDataList.length > 0
                          ? "border-purple-300 bg-purple-100 hover:bg-purple-950 hover:text-white font-bold"
                          : "border-gray-300 bg-gray-100 cursor-default"
                      } rounded-md background-transparent  
                        uppercase px-3 py-1 text-sm outline-none 
                        focus:outline-none m-2  ease-linear transition-all 
                        duration-150  `}
                      type="button"
                      disabled={
                        barReconciliationReportDataList.length !== 0
                          ? false
                          : true
                      }
                      onClick={() => downloadReport("bar-reco", filters)}
                    >
                      Download Report
                    </button>
                  </div>
                  <div className="overflow-x-auto">
                    <table className="whitespace-nowrap  rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs ">
                      <thead>
                        <tr className="text-left border-b border-gray-300">
                          {/*<th style={{minWidth: width + "px", left: 0}}*/}
                          {/*    className="sticky-col sticky top-0 px-3 py-2 bg-gray-800 z-10">Vault*/}
                          {/*    ID*/}
                          {/*</th>*/}
                          {/* <th
                            style={{ minWidth: width + "px", left: 0 + "px" }}
                            className="sticky-col  sticky top-0 px-3 py-2 bg-gray-800 z-10"
                          >
                            Vault
                          </th> */}
                          <th
                            style={{
                              minWidth: width + "px",
                              left: width * 0 + "px",
                            }}
                            className="sticky-col  sticky top-0 px-3 py-2 bg-gray-800 z-10"
                          >
                            Metal Code
                          </th>
                          {/* <th
                            style={{
                              minWidth: width + "px",
                              left: width * 2 + "px",
                            }}
                            className="sticky-col  sticky top-0 px-3 py-2 bg-gray-800 z-10"
                          >
                            Item Code
                          </th> */}
                          <th
                            style={{
                              minWidth: width + "px",
                              left: width * 1 + "px",
                            }}
                            className="sticky-col  sticky top-0 px-3 py-2 bg-gray-800 z-10"
                          >
                            Item Name
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800">
                            Bar Number
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800">
                            Brand
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800">
                            Weight Grams
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800">
                            Fineness
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 ">
                            Equivalent Metal
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 ">
                            Pool
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 ">
                            Customer Pool Receipted
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 ">
                            Pending Warehouse Receipt
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 ">
                            Pool A-Unsold
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 ">
                            Total Customer Pool
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 ">
                            EBPL Pool
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 ">
                            Segregation Reference
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 ">
                            Segregation Time
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {0 !== barReconciliationReportDataList.length ? (
                          barReconciliationReportDataList.map(
                            (user: any, index: number) => {
                              return (
                                <tr
                                  key={index}
                                  className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                                >
                                  {/*<td style={{minWidth: width + "px", left: 0}}*/}
                                  {/*    className="bg-white z-10 sticky-col   py-3 px-3 text-left relative">*/}
                                  {/*    {user.vaultId}*/}
                                  {/*</td>*/}
                                  {/* <td
                                    style={{
                                      minWidth: width + "px",
                                      left: 0 + "px",
                                    }}
                                    className="bg-white z-10 sticky-col  py-3 px-3 text-left relative"
                                  >
                                    {user.vault}
                                  </td> */}
                                  <td
                                    style={{
                                      minWidth: width + "px",
                                      left: width * 0 + "px",
                                    }}
                                    className="bg-white z-10 sticky-col   py-3 px-3 text-left relative"
                                  >
                                    {user.metalCode}
                                  </td>
                                  {/* <td
                                    style={{
                                      minWidth: width + "px",
                                      left: width * 2 + "px",
                                    }}
                                    className="bg-white z-10 sticky-col  py-3 px-3 text-left relative"
                                  >
                                    {user.itemCode}
                                  </td> */}
                                  <td
                                    style={{
                                      minWidth: width + "px",
                                      left: width * 1 + "px",
                                    }}
                                    className="bg-white z-10 sticky-col  py-3 px-3 text-left relative"
                                  >
                                    {user.itemName}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {user.barNumber}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {user.brandName}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {Number(user.weight).toFixed(1)}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {Number(user.fineness).toFixed(1)}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {Number(user.equivalentWeight).toFixed(4)}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {user.pool}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {Number(user.poolA).toFixed(4)}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {Number(user.pending).toFixed(4)}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {Number(user.unsold).toFixed(4)}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {Number(user.totalCustomerPool).toFixed(4)}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {Number(user.ebplPool).toFixed(4)}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {user.totalCustomerPool > 0 ? (
                                      <a
                                        className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                        href="#"
                                        onClick={() => {
                                          setSelectedSettlementId(user.segregationReference)
                                          setModalFilters({
                                            partnerId: "",
                                            customerId: "",
                                            accountId: "",
                                            allocationStatus: "",
                                            isExchange: "",
                                            fromDate: "",
                                            toDate: "",
                                            reference:
                                              user.segregationReference,
                                          });
                                          segregationDetailsView(
                                            user.segregationReference,
                                            {},
                                            0
                                          );
                                        }}
                                      >
                                        {user.segregationReference}
                                      </a>
                                   ) : (
                                      ""
                                    )} 
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {/* {moment(user.segregationTimestamp).format(
                                      "DD-MM-YYYY HH:mm:ss"
                                    )} */}
                                    {user.segregationTimestamp}
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                            <td
                              colSpan={22}
                              className="text-center py-3 px-3 text-left relative"
                            >
                              No data to display
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    meta={meta}
                    handlePageChange={(number: number) => {
                      inventoryGet(number);
                    }}
                  />
                  <div className="w-full">
                    <div className="bg-white w-full border-purple-950 border-t-4 p-3 mt-4">
                      <h1 className="text-gray-900 flex font-bold text-xl leading-8 my-1">
                        Summary Details
                      </h1>
                      <div className="md:-mx-2 md:flex no-wrap">
                        <div className="md:w-6/12 p-3 pt-0">
                          <ul className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                            <li className="flex items-center py-3">
                              <span>Total Weight</span>
                              <span className="ml-auto">
                                {" "}
                                {Number(summaryDetails.totalWeight).toFixed(4)}
                              </span>
                            </li>
                            <li className="flex items-center py-3">
                              <span>Total Equivalent Weight</span>
                              <span className="ml-auto">
                                {Number(
                                  summaryDetails.totalEquivalentWeight
                                ).toFixed(4)}
                              </span>
                            </li>
                            <li className="flex items-center py-3">
                              <span>Total EBPL Pool</span>
                              <span className="ml-auto">
                                {Number(summaryDetails.totalEbplPool).toFixed(
                                  4
                                )}
                              </span>
                            </li>
                            <li className="flex items-center py-3">
                              <span>Total Customer Pool</span>
                              <span className="ml-auto">
                                {Number(
                                  summaryDetails.totalCustomerPool
                                ).toFixed(4)}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="bg-white md:w-6/12 p-3 pt-0">
                          <ul className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                            <li className="flex items-center py-3">
                              <span>Total Customer Allocated Pool</span>
                              <span className="ml-auto">
                                {Number(
                                  summaryDetails.totalCxAllocated
                                ).toFixed(4)}
                              </span>
                            </li>
                            <li className="flex items-center py-3">
                              <span>
                                Total Customer metal pending vault receipt{" "}
                              </span>
                              <span className="ml-auto">
                                {Number(summaryDetails.totalCxPending).toFixed(
                                  4
                                )}
                              </span>
                            </li>
                            <li className="flex items-center py-3">
                              <span> Total Customer Pool Unsold</span>
                              <span className="ml-auto">
                                {Number(summaryDetails.totalCxUnsold).toFixed(
                                  4
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset className="bg-gray-100 p-3 min-w-0 border rounded p-1 border-gray-300 pb-4 flex mt-3">
                <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
                  {"Cumulative Inventory"}
                </legend>
                <div className="w-full">
                  <div
                    className="font-bold mb-1 ml-3 pb-2"
                    style={{ fontSize: "13px" }}
                  >
                    Report as at{" "}
                    <span className="bg-green-500  py-1 px-2 rounded text-white text-xs">
                      {moment(cumalativeReportDate.ts).format(
                        "DD-MM-YYYY HH:mm"
                      )}
                    </span>
                    <button
                      className={`border ${
                        cumulativeInventoryReportDataList.length > 0
                          ? "border-purple-300 bg-purple-100 hover:bg-purple-950 hover:text-white font-bold"
                          : "border-gray-300 bg-gray-100 cursor-default"
                      } rounded-md background-transparent  
                      uppercase px-3 py-1 text-sm outline-none 
                      focus:outline-none m-2  ease-linear transition-all 
                      duration-150  `}
                      type="button"
                      onClick={() => downloadReport("cumulative", filters)}
                      disabled={
                        cumulativeInventoryReportDataList.length !== 0
                          ? false
                          : true
                      }
                    >
                      Download Report
                    </button>
                  </div>
                  <div className="overflow-x-auto">
                    <table className="whitespace-nowrap rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs ">
                      <thead>
                        <tr className="text-left border-b border-gray-300">
                          <th
                            style={{
                              minWidth: width + 30 + "px",
                              left: 0 + "px",
                            }}
                            className="sticky-col sticky top-0 z-10 px-3 py-2 bg-gray-800"
                          >
                            Reconciliation Date
                          </th>
                          <th
                            style={{
                              minWidth: width + 30 + "px",
                              left: width + 30 + "px",
                            }}
                            className="sticky top-0 z-10 px-3 py-2 bg-gray-800"
                          >
                            Settlement Cut Off Time
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800">
                            Cumulative Receipts - Customer Pool
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800">
                            Pending receipts - Customer Pool
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 ">
                            Cumulative - receipts - EBPL
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 ">
                            Pending receipts - EBPL
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 ">
                            Net Customer Pool
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 ">
                            Net Pending Receipts
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 ">
                            Customer Pool Unsold
                          </th>
                          <th className="sticky top-0 px-3 py-2 bg-gray-800 ">
                            EBPL Pool
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {0 !== cumulativeInventoryReportDataList.length ? (
                          cumulativeInventoryReportDataList.map(
                            (user: any, index: number) => {
                              return (
                                <tr
                                  key={index}
                                  className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                                >
                                  <td
                                    style={{
                                      minWidth: width + 30 + "px",
                                      left: 0 + "px",
                                    }}
                                    className="bg-white z-10 sticky-col py-3 px-3 text-left relative"
                                  >
                                    {moment(user.reconciliationDate).format(
                                      "DD-MM-YYYY hh:mm"
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      minWidth: width + 30 + "px",
                                      left: width + 30 + "px",
                                    }}
                                    className="bg-white z-10 sticky-col py-3 px-3 text-left relative"
                                  >
                                    {user.settlementCutoffTime}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {Number(user.cumCustPool).toFixed(4)}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {Number(user.pendingCustPool).toFixed(4)}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {Number(user.cumEbplPool).toFixed(4)}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {Number(user.pendingEbplPool).toFixed(4)}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {Number(user.netCusPool).toFixed(4)}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {Number(user.netPendingReceipts).toFixed(4)}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {currentPageCumalative == 0 && index == 0
                                      ? Number(user.custPoolUnsold).toFixed(4)
                                      : ""}
                                  </td>
                                  <td className="py-3 px-3 text-left relative">
                                    {currentPageCumalative == 0 && index == 0
                                      ? Number(user.eblPool).toFixed(4)
                                      : ""}
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                            <td
                              colSpan={10}
                              className="text-center py-3 px-3 text-left relative"
                            >
                              No data to display
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    meta={metaCumalative}
                    handlePageChange={(number: number) => {
                      inventoryCumalativeGet(number);
                    }}
                  />
                </div>
              </fieldset>
              <Modal
                size={"large"}
                show={show}
                handleClose={hideModal}
                children={children}
              />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};
export default BarReconciliation;
