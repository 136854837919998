import { confirmAlert } from "react-confirm-alert";

export const SetDeleteConfirmAlert = (
  title: string,
  message: string,
  confirm: any,
  cancel: any
) => {
  confirmAlert({
    title: title,
    message: message,
    buttons: [
      {
        label: "Yes",
        onClick: () => confirm(),
      },
      {
        label: "No",
        onClick: () => cancel(),
      },
    ],
  });
};

export const SetConfirmAlert = (
  title: string,
  message: string,
  confirm: any,
  cancel: any
) => {
  confirmAlert({
    title: title,
    message: message,
    buttons: [
      {
        label: "Yes",
        onClick: () => confirm(),
      },
      {
        label: "No",
        onClick: () => cancel(),
      },
    ],
  });
};

export const SetPopupAlert = (title: string, message: string) => {
  confirmAlert({
    title: title,
    message: message,
    buttons: [
      {
        label: "Ok",
        onClick: () => {},
      },
    ],
  });
};
