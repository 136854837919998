import React, { useEffect, useState } from "react";
import {useAuthStore} from "../../../Store/AuthStore";
import Layout from "../../Layout/Layout";
import {
  getValuts,
  createCalendar,
  getCalenderDetails,
  EditTime
} from "./VSO.Service";

import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Components-ui/Loader";
import { Pagination } from "../../../Components-ui/Pagination";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";
const dayjs = require('dayjs');

const VsoCalendar: React.FC = () => {
  const [selectedVault, setSelectedVault] = useState<any>("");
  const [vaults, setVaults] = useState<any>([]);
  const [sdate, setSDate] = useState<any>(new Date().toISOString());
  const [edate, setEDate] = useState<any>(new Date().toISOString());
  const [dateError, setdateError] = useState<boolean>(false);
  const [detail, setDetail] = useState<any>([]);
  const [Loading, setLoading] = useState<any>(true);
  const {tokens, warehouses, permissions} = useAuthStore();
  const [metaData , setMetaData] = useState<any>({});

  const [hrs, setHrs] = useState<any>([]);
  const [mins, setMins] = useState<any>([]);

  const [editMode, setEditMode] = useState<any>({
    state: false,
    index: -1,
    row: {},
  });
  const [editMins, setEditMins] = useState<number>(-1);
  const [editHrs, setEditHrs] = useState<number>(-1);
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    // fetchVaultReference();
    fetchVaults();
    loadHours();
    loadMins();
  }, []);

  useEffect(() => {
    if (moment(sdate).valueOf() <= moment(edate).valueOf()) {
      setdateError(false);
    } else {
      setdateError(true);
    }
  }, [sdate, edate]);

  const onVaultChange = (e: any) => {
    setSelectedVault(e.target.value);
  };

  const createMetalCalendar = async (data: any) => {
    try {
      let responseData: any = await createCalendar(data, tokens);
      setLoading(false);
      if (responseData && responseData?.data?.data) {
        setDetail(responseData.data.data);
      }
      else {
        setLoading(false);
        setDetail([]);
      }
    } catch (error: any) {

      toast.error(error.data.errors[0].title);
      throw new error(error);
    }
  };

  const fetchVaults = async () => {
    try {

      let allRef: any = await getValuts(tokens, warehouses, permissions);
      setVaults(allRef?.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Something Went Wrong!");
    }
  };

  const fetchMasterCalendarDetails = async (
    sdate: any,
    edate: any,
    vaults: any,
    page:number
  ) => {
    setLoading(true);
    try {
      let details: any = await getCalenderDetails(sdate, edate, vaults,tokens,page);

      if (details && details?.data?.data?.length === 0) {
        setDetail([]);
        setMetaData({});
        toast.error("No Results Found");
      } else {
        console.log(details.data);
        setMetaData({
          current: details.data.meta.page,
          pages: details.data.meta.totalPages,
        });
        setCurrentPage(details.data.meta.page);
        setDetail(details.data.data.result);
        // setMetaData(details.data.meta);

      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setDetail([]);
      setMetaData({});
      setLoading(false);
      toast.error("No records found!");
    }
  };

  const fetchMasterCalendarDetailsRefresh = async (
    sdate: any,
    edate: any,
    vaults: any
  ) => {
    try {
      let details: any = await getCalenderDetails(sdate, edate, vaults, tokens,0);

      if (details && details?.data?.data?.length == 0) {
        setDetail([]);
        setMetaData({});
        // toast.error("No Results Found");
      } else {
        setDetail(details.data.data.result);
        setMetaData({
          current: details.data.meta.page,
          pages: details.data.meta.totalPages,
        });
        // toast.success("Results Found");
      }
    } catch (err) {
      console.error(err);
      setDetail([]);
      setMetaData({});
      toast.error("No records found!");
    }
  };


  const onSubmitClick = async () => {
    try {
      if (!selectedVault) {
        toast.error("Please select a Vault ID");
        return;
      }
      if (!sdate) {
        toast.error("Please select Start Date");
        return;
      }
      if (!valid(moment(sdate))) {
        toast.error("Cannot process for an elpased time!");
        return;
      }
      if (!edate) {
        toast.error("Please select End Date");
        return;
      }
      if (dateError) {
        toast.error("Please Select Valid Date Range");
        return;
      }

      if (sdate === edate) {
        toast.error("You have selected same date!");
        return;
      }
      const data = {
        startDate: moment(sdate).format("YYYY-MM-DD"),
        endDate: moment(edate).format("YYYY-MM-DD"),
        vaultId: selectedVault,
      };
      setLoading(true);

      try {
        await createMetalCalendar(data);
        setLoading(false);
        toast.success("Added Sucessfully.");
      } catch (err:any) {
        setLoading(false);
        console.error(err);
      }
    } catch (error:any) {
      setLoading(false);
      console.error(error);
    }
  };

  const onQueryClick = async (e: any) => {
    try {
      e.preventDefault();

      if (selectedVault == "") {
        toast.error("Please select a vault first");
        return;
      }

      const data = {
        startDate: moment(sdate).format("YYYY-MM-DD"),
        endDate: moment(edate).format("YYYY-MM-DD"),
        vaultId: selectedVault,
      };
      fetchMasterCalendarDetails(data.startDate, data.endDate, data.vaultId, 0);
    } catch (error) {
      toast.error("Invaid input");
      console.error(error);
    }
  };

  const filter = async (page:number, forcedfilter:any = null) => {
    console.log(page);

    const data = {
      startDate: moment(sdate).format("YYYY-MM-DD"),
      endDate: moment(edate).format("YYYY-MM-DD"),
      vaultId: selectedVault,
    };
    setCurrentPage(page);
    fetchMasterCalendarDetails(data.startDate, data.endDate, data.vaultId, page);
}

  const onSaveClicked = (e: any, row: any, index: number) => {
    e.preventDefault();
console.log(row);

    if (!canEdit(dayjs(row.date).format("YYYY-MM-DD")+' '+editHrs + ":" + editMins)) {
      toast.error("Cannot update for an elpased time!");
      return;
    }

    if (editHrs == -1 || editMins == -1) {
      toast.error("Please Enter Valid Dates");
      return;
    }

    const updateData = {
      date: dayjs(row.date).format("YYYY-MM-DD"),
      time: editHrs + ":" + editMins,
      vaultId: selectedVault
    };

    EditTime(row.id, updateData,tokens).then(data => {
      // console.log(data);
      toast.success("Time successfully updated");

      const sendData = {
        startDate: dayjs(sdate).format("YYYY-MM-DD"),
        endDate: dayjs(edate).format("YYYY-MM-DD"),
        vaultId: selectedVault,
      };

      fetchMasterCalendarDetailsRefresh(
        sendData.startDate,
        sendData.endDate,
        sendData.vaultId
      ).then(d => {}).catch(err => {})

      setEditMode({
        state: false,
        index: -1,
        row: {},
      });

      setEditMins(-1);
      setEditHrs(-1);

    }).catch(err => {
      toast.error(err.response.data.errors[0].title);
      console.log(err);
    })

    // EditTime(row.id, updateData)
    //   .then((data) => {
    //     toast.success("Time successfully updated");
    //     const sendData = {
    //       startDate: dayjs(sdate).format("YYYY-MM-DD"),
    //       endDate: dayjs(edate).format("YYYY-MM-DD"),
    //       vaultId: selectedVault,
    //     };
    //     fetchMasterCalendarDetailsRefresh(
    //       sendData.startDate,
    //       sendData.endDate,
    //       sendData.vaultId
    //     );
    //     // createMasterCalendar(sendData.startDate, sendData.endDate, sendData.vaultId);
    //     // let altered = detail;
    //     // altered[index].time = moment(altered[index].date).format("YYYY-MM-DD") + " " + editHrs + ":" + editMins + ":00";
    //     // setDetail(altered);

    //     setEditMode({
    //       state: false,
    //       index: -1,
    //       row: {},
    //     });

    //     setEditMins(-1);
    //     setEditHrs(-1);
    //   })
    //   .catch((err) => {
    //     toast.error("Update Failed");
    //   });
  };

  const valid = function( current : any ){
      const yesterday = moment().subtract( 1, 'day' );
      return current.isAfter( yesterday );
  };

  const onEditClicked = (e: any, index: number, val: any) => {
    e.preventDefault();

    let fullDate = new Date(val.time);

    setEditMode({
      state: true,
      index: index,
      row: val,
    });



    setEditHrs(fullDate.getHours());
    setEditMins(fullDate.getMinutes());
  };

  const loadHours = () => {
    let hrs = [];
    for (let i = 0; i < 24; i++) {
      hrs.push(i);
    }

    let hrsSorted = hrs.map((i) => {
      return <option value={i}>i</option>;
    });

    setHrs(hrs);
  };

  const loadMins = () => {
    let min = [];
    for (let i = 0; i < 60; i++) {
      min.push(i);
    }

    let minSorted = min.map((i) => {
      return <option value={i}>i</option>;
    });

    setMins(min);
  };

  const getUtcHours = (time: any) => {
    let d = new Date(time);
    return d.getUTCHours();
  };

  const getUtcMins = (time: any) => {
    let d = new Date(time);
    return d.getUTCMinutes();
  };

  const getFullTime = (time: any) => {

    let date = new Date(time);
    let hours = date.getHours() <= 9 ? "0" + date.getHours() : date.getHours();
    let mins =
      date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes();
    return `${hours}:${mins}`;
  };

  const canEdit = (date:any) => {
    const now = new Date();
    const given = new Date(date);
    return now.getTime() < given.getTime();
  }

  const onCancelClicked = (e: any) => {
    e.preventDefault();

    setEditMode({
      state: false,
      index: -1,
      row: {},
    });

    setEditMins(-1);
    setEditHrs(-1);
  };


  return (
    <Layout type={"VSOModule"}>
      <ToastContainer/>
      <div className="flex justify-between items-center mb-5">
          <h1 className="text-2xl font-bold tracking-tight">
              {"Calendar"}
          </h1>
      </div>
      {Loading ? (<Loader/>) : (
      <><div className="shadow sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6">
                <label htmlFor="grid-state" className="block text-sm font-medium text-gray-700">Valut</label>
                <select
                  onChange={onVaultChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="grid-state"
                  value={selectedVault}
                >
                  <option value="">Select one</option>
                  {vaults.map((bench: any) => {
                    return (
                      <option value={bench.id}>
                        {bench.warehouseName} ({bench.warehouseId})
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="effect-from" className="block text-sm font-medium text-gray-700">Effected From</label>
                <div className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border shadow-sm">
                  <Datetime
                    className="effect-from"
                    input={false}
                    inputProps={{readOnly: true, className: "focus:outline-none" }}
                    value={moment(sdate)}
                    timeFormat={false}
                    dateFormat={"DD/MM/YYYY"}
                    // isValidDate={ valid }
                    onChange={(e: any) => {
                      // setdateError(false);
                      // if (
                      //   moment(edate).valueOf() >= moment(new Date(e)).valueOf()
                      // ) {
                      setSDate(new Date(e).toISOString());
                      // } else {
                      //   setdateError(true);
                      // }
                    } } />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="efect-to" className="block text-sm font-medium text-gray-700">Effected To</label>
                <div className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border shadow-sm">
                  <Datetime
                    className="efect-to"
                    input={false}
                    inputProps={{ readOnly: true,className: "focus:outline-none" }}
                    value={moment(edate)}
                    timeFormat={false}
                    dateFormat={"DD/MM/YYYY"}
                    onChange={(e: any) => {
                      // setdateError(false);
                      // if (
                      //   moment(sdate).valueOf() <= moment(new Date(e)).valueOf()
                      // ) {
                      setEDate(new Date(e).toISOString());
                      // } else {
                      //   setdateError(true);
                      // }
                    } } />
                </div>
              </div>

            </div>
            {dateError && (
              <div className="mt-5">
                <span className="text-xs font-semibold bg-red-50 border border-red-500 text-red-500 px-4 py-2 rounded">
                  From date must be less than To Date
                </span>
              </div>
            )}
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            {
              HasAnyAccess(['vso.calendar.create']) && (
            <button
              className="bg-purple-950 text-white px-4 py-2 rounded font-bold mr-3"
              onClick={() => onSubmitClick()}
            >
              Create Calendar
            </button>
            )}
            {
              HasAnyAccess(['vso.calendar.query']) && (
            <button
              className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
              onClick={(e) => onQueryClick(e)}
            >
              Query
            </button>
            )}
          </div>
        </div><div className="shadow sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6 mt-5 border rounded border-gray-200 shadow">
              {/* <h3>{JSON.stringify(editHrs)}</h3>
  <h3>{JSON.stringify(editMins)}</h3> */}
              <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                <thead>
                  <tr className="text-left border-b border-gray-300">
                    <th className="py-3 px-6 text-center">Date</th>
                    <th className="py-3 px-6 text-center">Time</th>
                    <th className="py-3 px-6 text-center z-10">Receipting Status</th>
                    <th className="py-3 px-6 text-center">VSO</th>
                    <th className="py-3 px-6 text-center">Receipt Time</th>
                    {
                      HasAnyAccess(['vso.calendar.edit']) && (
                    <th className="py-3 px-6 text-center z-10">Actions</th>
                      )}
                  </tr>
                </thead>
                <tbody className="text-gray-800 text-m font-dark">
                  {detail.length > 0 ?
                    detail.map((val: any, index: any) => {
                      return (
                        <tr
                          key={index}
                          className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                        >
                          <td className="py-3 px-6 text-center">
                            {dayjs(val.date).format("YYYY-MM-DD")}
                          </td>
                          <td className="py-3 px-6 text-center">
                            {/* {moment(val.time).format("YYYY-MM-DD")} */}
                            {/* <br /> */}
                            {/* {getUtcHours(val.time)} : {getUtcMins(val.time)} */}

                            {editMode.state && editMode.index == index ? (
                              <>
                                <select
                                  value={editHrs}
                                  onChange={(e) => setEditHrs(parseInt(e.target.value))}
                                  className="text-black border border-gray-300 rounded-md px-1 py-1 mx-1"
                                >
                                  {hrs.map((i: any) => {
                                    return <option value={i}>{i}</option>;
                                  })}
                                </select>
                                <select
                                  value={editMins}
                                  onChange={(e) => setEditMins(parseInt(e.target.value))}
                                  className="text-black border border-gray-300 rounded-md px-1 py-1 mx-1"
                                >
                                  {mins.map((i: any) => {
                                    return <option value={i}>{i}</option>;
                                  })}
                                </select>
                              </>
                            ) : (
                              getFullTime(val.time)
                            )}

                            {/* <button onClick={(e) => handleSumit(e, index)}>Update</button> */}
                            {/* <button onClick={editAccess} disabled={!!!editRow}>
                    Edit
                  </button> */}
                          </td>
                          <td className="py-3 px-6 text-center">
                            {/* {" "} */}
                            {val.receiptingStatus ? (
                              <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 text-green-600 bg-green-200">CHECKED</span>
                            ) : (
                              <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 text-red-600 bg-red-200">NOT CHECKED</span>
                            )}
                            {/* {
                      !val.receiptingStatus && val.pending == 0 ?
                        <span className="mx-3 bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-full text-s">
                          Not Receipted
                        </span>
                        :
                        ""
                    } */}
                          </td>
                          <td className="py-3 px-6 text-center"> {val.vso ? val.vso : 'N/A'}</td>
                          <td className="py-3 px-6 text-center">
                            {" "}
                            {val.receiptedTime ? getFullTime(val.receiptedTime) : "N/A"}
                          </td>
                          <td className="py-3 px-6 text-center">
                            {/* <span className="mx-3 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full text-xs cursor-pointer"
                    onClick={(e) => {onEditClicked(e, index, val)}}
                  >
                    Edit
                  </span> */}
                            {
                              HasAnyAccess(['vso.calendar.edit']) && (
                                  <>
                            {editMode.state && editMode.index == index ? (
                              <div>
                                <span
                                  className="mx-3 font-bold py-2 px-4 rounded-full text-xs cursor-pointer bg-purple-950 text-white"
                                  onClick={(e) => onSaveClicked(e, val, index)}
                                >
                                  Save
                                </span>
                                <span
                                  className="mx-3 bg-red-600 text-white font-bold py-2 px-4 rounded-full text-xs cursor-pointer"
                                  onClick={(e) => {
                                    onCancelClicked(e);
                                  } }
                                >
                                  Cancel
                                </span>
                              </div>
                            ) : !val.receiptingStatus && canEdit(dayjs(val.date).format("YYYY-MM-DD") +' '+getFullTime(val.time)) ? (
                              <span
                                className="mx-3 bg-blue-500 hover:bg-purple-950 text-white font-bold py-2 px-4 rounded-full text-xs cursor-pointer"
                                onClick={(e) => {
                                  onEditClicked(e, index, val);
                                } }
                              >
                                Edit
                              </span>
                            ) : (
                              <span
                                className="mx-3 bg-gray-500 text-white font-bold py-2 px-4 rounded-full text-xs cursor-not-allowed" title="Edit not allowed"

                              >
                                Edit
                              </span>
                            )}</>
                              )}
                          </td>
                        </tr>
                      );
                    }) : (<tr
                      className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                      <td className="py-3 px-3 text-center relative" colSpan={6}>
                        No Data
                      </td>
                    </tr>)}

                </tbody>
              </table>
              <Pagination
                meta={metaData}
                handlePageChange={(number: number) => {
                  filter(number);
                } } />
            </div>
          </div></>
      )}
    </Layout>
  );
};

export default VsoCalendar;
