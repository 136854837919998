import React, { Fragment } from "react";
import { Route } from "react-router-dom";
// import ListOfCustomers from "../Customer/Components/ListOfCustomers";
import CreateInvestor from "./Components/CreateInvestor";
import ListOfCustomers from "./Components/ListOfInvCustomers";
import ListOfOrders from "./Components/ListOfInvOrders";

// import ListOfUsers from './Components/ListOfUsers';
// import SingleUser from './Components/SingleUserDetails';
// import { CreateUser } from './Components/CreateUser';
import ListOfInvestors from "./Components/ListOfInvestors";
import SingleInvestor from "./Components/SingleInvestor";

const InvestorsListRoutes: React.FC = () => {
  return (
    <Fragment>
      {" "}
      <Route path="/investor-customer-list" exact component={ListOfCustomers} />
      <Route path="/investor-order-list" exact component={ListOfOrders} />
      <Route path="/investors" exact component={ListOfInvestors} />
      <Route path="/investor/create" exact component={CreateInvestor} />
      <Route path="/updateInvestor/:id" exact component={SingleInvestor} />
    </Fragment>
  );
};

export default InvestorsListRoutes;
