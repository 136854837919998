/**
 * Generate query parameters from an object
 * @param data
 */
export const makeQuery = (data: any) => {
  let query = "";
  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        query += `&${key}=${item}`;
      });
    } else {
      query += value === "" ? "" : `&${key}=${value}`;
    }
  });
  return query;
};

/**
 * Generete Query Params from Object
 * @param obj Object that needs to be converted to query string
 * @returns Query string for get api
 */
export const genereteQuery = (obj: any) => {
  let queryString = "";
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key])
        queryString += `${encodeURIComponent(key)}=${encodeURIComponent(
          obj[key]
        )}&`;
    }
  }
  return queryString.slice(0, -1);
};

export const createQuery = (obj: any) => {
  const params = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key]) {
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(obj[key]);
        // const decodedValue = decodeURIComponent(encodedValue);

        // params.push(`${encodedKey}=${encodedValue}`);
        params.push(`${key}=${obj[key]}`);
      }
    }
  }
  return params.length > 0 ? `?${params.join("&")}` : "";
};
