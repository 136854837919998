import React, { memo, useEffect, useState } from "react";
import { useStore } from "../../../Store/PricingStore";
import { Types } from "../constants";

interface PropsType {
  getQuantity: any;
  getPrice: any;
  benchmark: any;
  benchmarks: any;
  selectedMetalName: any;
  benchMarkInd: any;
  aux: any;
  usdInr: any;
  handleValuePrices: any;
  fetchAuxiliaryData: any;
}

let metalCode = "Ag";
const validateNum = /^(?!(?:^[-+]?[0.]+(?:[Ee]|$)))(?!(?:^-))(?:(?:[+-]?)(?=[0123456789.])(?:(?:(?:[0123456789]+)(?:(?:[.])(?:[0123456789]*))?|(?:(?:[.])(?:[0123456789]+))))(?:(?:[Ee])(?:(?:[+-]?)(?:[0123456789]+))|))$/;

const AskBidPriceTable: React.FC<PropsType> = ({
  getQuantity,
  getPrice,
  benchmark,
  benchmarks,
  selectedMetalName,
  benchMarkInd,
  aux,
  usdInr,
  handleValuePrices,
  fetchAuxiliaryData
}) => {
  const [quantity, setQuantity] = useState<any>(1);
  const [weightOrAmount, currency, setWeightOrAmount, isruleExpired] = useStore((state: any) =>
    [state.weightOrAmount, state.currency, state.setWeightOrAmount, state.isruleExpired]);

  const qtyChanged = (e: any) => {
    const value = e.target.value;
    let qty = 0;
    if (value == "") {
      qty = 0;
      getQuantity(0.0);
    } else {
      qty = validateNum.test(value) ? parseFloat(value) : quantity;
      getQuantity(qty);
    }
    console.log("qty ", qty);

    setQuantity(qty);
  };

  const askmusdaChange = (e: any, id: any, type: any) => {
    // debugger;
    const value = e.target.value.substring(2).slice(0, -4);
    let musa = validateNum.test(value)
      ? value
      : value.length == 0 || parseFloat(value) == 0
        ? "0.00"
        : benchMarkInd.ask;

    handleValuePrices(musa, id, type);

    // setMusda(parseFloat(musa));
    let data = {
      usd: {
        ask: parseFloat(musa),
        bid: parseFloat(benchMarkInd.bid),
      },
      inr: {
        ask: parseFloat(usdInr?.ask),
        bid: parseFloat(usdInr?.bid),
      },
    };

    getPrice(data);
  };

  const bitmusdChange = (e: any, id: any, type: any) => {
    const value = e.target.value.substring(2).slice(0, -4);

    let musb = validateNum.test(value)
      ? value
      : value.length == 0 || parseFloat(value) == 0
        ? "0.00"
        : benchMarkInd.bid;
    handleValuePrices(musb, id, type);
    let data = {
      usd: {
        ask: parseFloat(benchMarkInd.ask),
        bid: parseFloat(musb),
      },
      inr: {
        ask: parseFloat(usdInr?.ask),
        bid: parseFloat(usdInr?.bid),
      },
    };
    getPrice(data);
  };

  const askmusdinrChange = (e: any, id: any, type: any) => {
    const value = e.target.value.substring(2);
    let cusa = validateNum.test(value)
      ? value
      : value.length == 0 || parseFloat(value) == 0
        ? "0.00"
        : usdInr?.ask;
    handleValuePrices(cusa, id, type);

    let data = {
      usd: {
        ask: parseFloat(benchMarkInd.ask),
        bid: parseFloat(benchMarkInd.bid),
      },
      inr: {
        ask: parseFloat(cusa),
        bid: parseFloat(usdInr?.bid),
      },
    };
    getPrice(data);
  };

  const bitmusdinrChange = (e: any, id: any, type: any) => {
    const value = e.target.value.substring(2);
    let cusb = validateNum.test(value)
      ? value
      : value.length == 0 || parseFloat(value) == 0
        ? "0.00"
        : usdInr?.bid;
    handleValuePrices(cusb, id, type);

    let data = {
      usd: {
        ask: parseFloat(benchMarkInd.ask),
        bid: parseFloat(benchMarkInd.bid),
      },
      inr: {
        ask: parseFloat(usdInr?.ask),
        bid: parseFloat(cusb),
      },
    };
    getPrice(data);
  };

  const refreshFeedValues = () => {
    fetchAuxiliaryData("refresh");
  }


  return (
    <div className="p-3">
      <div className="flex justify-between mb-3 items-center">
        <h1 className="font-bold text-xl uppercase"> </h1>
        <button
          onClick={refreshFeedValues}
          disabled={isruleExpired}
          className={`bg-green-600 flex items-center rounded text-white px-2 py-1 font-bold text-xs uppercase ${isruleExpired ? 'opacity-50' : ''}`}
        >
          <svg
            className="w-3 mr-1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>{" "}
          Refresh
        </button>
      </div>

      <fieldset className="border bg-gray-200 rounded p-1 border-gray-300 pb-4 flex mt-3">
        <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
          {benchMarkInd?.conversion ?? "XAG"}
        </legend>
        {/* <span className="font-bold px-2 absolute -top-2.5" style={{background:'#DDD6FE', transform:'translateY(-15px)' , left : '50%'}}>
       
          {" "}
          {!!metalName?.description && metalName.description}/Oz
        </span> */}
        <div className="flex">
          <div className="w-full px-3 mb-6 md:mb-0">
            <label
              className="block  text-grey-darker text-xs font-bold mb-1"
              for="grid-city"
            >
              Bid Price (USD)
            </label>
            <input
              className=" border border-gray-300 text-right appearance-none block w-full bg-grey-lighter text-black border border-grey-lighter rounded py-1 px-2"
              id="grid-city"
              name="bid/m"
              type="text"
              placeholder=""
              disabled={isruleExpired}
              value={`$ ${benchMarkInd?.bid ? benchMarkInd?.bid : "0.00"} /Oz`}
              onChange={(e) =>
                bitmusdChange(e, benchMarkInd.id, Types.METAL_BID)
              }
            />
          </div>
          <div className="w-full px-3 mb-6 md:mb-0">
            <label
              className="block  text-grey-darker text-xs font-bold mb-1"
              for="grid-city"
            >
              Ask Price (USD)
            </label>
            <input
              className="border border-gray-300 appearance-none block w-full bg-grey-lighter text-black border border-grey-lighter rounded py-1 px-2 text-right"
              id="grid-city"
              name="ask/m"
              type="text"
              placeholder=""
              disabled={isruleExpired}
              value={`$ ${benchMarkInd?.ask ? benchMarkInd?.ask : "0.00"} /Oz`}
              onChange={(e) =>
                askmusdaChange(e, benchMarkInd.id, Types.METAL_ASK)
              }
            />
          </div>
        </div>
      </fieldset>

      <fieldset className="border bg-gray-200 rounded p-1 border-gray-300 pb-4 flex mt-2">
        <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
          USD/INR
        </legend>
        <div className="w-full px-3 mb-6 md:mb-0">
          <label
            className="block  text-grey-darker text-xs font-bold mb-1"
            for="grid-city"
          >
            Bid Price (INR)
          </label>
          <input
            className=" border border-gray-300 text-right appearance-none block w-full bg-grey-lighter text-black border border-grey-lighter rounded py-1 px-2"
            id="grid-city"
            name="/c"
            type="text"
            placeholder=""
            disabled={isruleExpired}
            value={`₹ ${usdInr?.bid ? usdInr?.bid : "0.00"}`}
            onChange={(e) => bitmusdinrChange(e, 0, Types.USD_BID)}
          />
        </div>
        <div className="w-full px-3 mb-6 md:mb-0">
          <label
            className="block  text-grey-darker text-xs font-bold mb-1"
            for="grid-city"
          >
            Ask Price (INR)
          </label>
          <input
            className="border border-gray-300 block w-full bg-grey-lighter text-black border border-grey-lighter rounded py-1 px-2 text-right"
            id="grid-city"
            name="ask/c"
            type="text"
            placeholder=""
            disabled={isruleExpired}
            value={`₹ ${usdInr?.ask ? usdInr?.ask : "0.00"}`}
            onChange={(e) => askmusdinrChange(e, 0, Types.USD_ASK)}
          />
        </div>
      </fieldset>
      <div className="mt-3 flex flex-row justify-evenly">
        {["weight", "amount"].map((data: any) => {
          return (
            <span className="">
              <label className="text-grey-darker text-xs font-bold mb-1 mr-2 capitalize">
                {data}
              </label>
              <input
                // className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                id="weight-amount"
                name="bid"
                type="radio"
                disabled={isruleExpired}
                value={data}
                checked={weightOrAmount == data}
                onChange={(e) => { setWeightOrAmount(data) }}
              />
            </span>
          )
        })

        }

      </div>
      <div className="mt-3">
        <label className="block  text-grey-darker text-xs font-bold mb-1">
          {weightOrAmount == "weight" ? "Customer Requested quantity (Grams)" : `Customer Requested amount (${currency})`}
        </label>
        <input
          className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
          id="grid-city"
          name="bid"
          type="text"
          disabled={isruleExpired}
          value={quantity}
          placeholder=""
          onChange={(e) => qtyChanged(e)}
        />
      </div>
    </div>
  );
};

// export default AskBidPriceTable;
export default memo(AskBidPriceTable);




