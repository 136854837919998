import Layout from "../../Layout/Layout";
import React, {useEffect, useState} from "react";
import moment from "moment";
import CityService from "../Services/city.service"
import Loader from"../../../Components-ui/Loader"
import {toast, ToastContainer} from "react-toastify";
import {useAuthStore} from "../../../Store/AuthStore";
import {Pagination} from "../../../Components-ui/Pagination";
import CityForm from "./CityForm";
import {makeQuery} from "../../../Common/util.helper";
import StateService from "../../State/Services/state.service";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";

interface PropsType {
}

const CityTable: React.FC<PropsType> = () => {

    const { logout, permissions, userData , tokens } = useAuthStore();

    const [Loading, setLoading] = useState<any>(false);

    const [MetaData , setMetaData] = useState<any>({});
    const [CurrentPage , setCurrentPage] = useState<number>(0);
    const [Cities, setCities] = useState<any>([]);
    const [States, setStates] = useState<any>([]);
    const [OpenModal, setOpenModal] = useState(false);
    const [Validator, setValidator] = useState(false);
    const [City, setCity] = useState<any>({
        city: "",
        state: "",
        active: "ENABLED",
    })

    const [FilterData, setFilterData] = useState<any>({
        city: "",
        state: "",
        active: "",
    });


    useEffect((): any => {
        getAllCities(CurrentPage)
        getAllStates();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
    }, []);


    /**
     * Get all states
     */
    const getAllStates = async () => {
        let data: any = await StateService.getAllStates(0, tokens, '', false);
        if (data.status === 200 && data.data.data) {
            console.log(data.data.data)
            setStates(data.data.data);
        }
    }


    /**
     * Validate city details
     * @param e
     */
    const onChangeCity = (e: any) => {
        setCity({
            ...City,
            [e.target.name]: e.target.value,
        });

        return setValidator(true);
    };


    /**
     * Get all cities
     */
    const getAllCities = async (page: number, isReset: boolean = false) => {
        setLoading(true)
        let cloneFilterData = !isReset ? {...FilterData} : {
            city: "",
            state: "",
            active: "",
        }
        const query = makeQuery(cloneFilterData);
        let data: any = await CityService.getAllCities(page, tokens, query);
        if (data.status === 200 && data.data.data) {
            setCurrentPage(page)
            setMetaData({
                current: data.data.meta.page,
                pages: data.data.meta.totalPages,
            })
            setCities(data ? data.data.data.result : []);
        }
        setLoading(false)

    };


    /**
     * Handle filters change event
     * @param e
     */
    const handleChangeFilter = (e: any) => {
        const {name, value} = e.target
        setFilterData({
            ...FilterData,
            [name]: value
        });
    }

    /**
     * Reset filters to initial state
     * @param e
     */
    const resetFilterData = async (e: any) => {
        e.preventDefault()
        setFilterData({
            city: "",
            state: "",
            active: "",
        })

        setCurrentPage(0)
        await getAllCities(0, true)
    }

    /**
     * Close modal
     */
    const closeModal = () => {
        setOpenModal(false)
    }

    /**
     * Open city modal
     */
    const onAddCity = () => {
        setInitialState()
        setOpenModal(true)
    }


    /**
     * Get city details
     * @param id
     */
    const getCity = (id: any) => {
        let city: any = Cities.filter((city:any) => city.id === id);
        if (city.length > 0) {
            setCity(city[0])
            setOpenModal(true)
        }
    }


    /**
     * Set city details to initial state
     */
    const setInitialState = () => {
        setCity({
            city: "",
            state: "",
            active: "ENABLED",
        })
    }

    /**
     * Enable or disable city
     * @param e
     * @param data
     */
    const enableDisableCity = async (e: any, data:any) => {
        setLoading(true)
        let cloneData = {...data}

        cloneData.active = e.target.checked ? 'ENABLED' : 'DISABLED';
        await CityService.updateCity(cloneData, tokens);
        await getAllCities(CurrentPage);
        setLoading(false)
        return toast.success("City was successfully updated");
    }

    return (
        <Layout type={"LocationModule"}>
            <ToastContainer />
            <div className="flex justify-between items-center mb-5">
                <h1 className="text-2xl font-bold tracking-tight">
                    {"City"}
                </h1>
                {
                    HasAnyAccess(['city.master.create']) && (
                        <button
                            className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
                            type="button"
                            onClick={onAddCity}
                        >
                            + Add City
                        </button>
                    )
                }
            </div>


            <div
                className="w-full rounded border-gray-200 overflow-hidden shadow-lg dark:border-gray-700 bg-white">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Filter Tax Details</h3>
                </div>
                <hr/>
                <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
                    <div className="col-span-12 sm:col-span-4">
                        <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                            City
                        </label>
                        <input
                            type="text"
                            name="city"
                            id="city"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e: any) => {
                                handleChangeFilter(e)
                            }}
                            value={FilterData.city}
                        />
                    </div>
                    <div className="col-span-12 sm:col-span-4">
                        <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                            State
                        </label>
                        <select
                            id="state"
                            name="state"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e: any) => {
                                handleChangeFilter(e)
                            }}
                            value={FilterData.state}
                        >
                            <option value="">All</option>
                            {
                                States.map((state:any) => {
                                    return (
                                        <option value={state.stateName}>{state.stateName}</option>
                                    )
                                })
                            }

                        </select>
                    </div>
                    <div className="col-span-12 sm:col-span-4">
                        <label htmlFor="active" className="block text-sm font-medium text-gray-700">
                            Status
                        </label>
                        <select
                            id="active"
                            name="active"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            onChange={(e: any) => {
                                handleChangeFilter(e)
                            }}
                            value={FilterData.active}
                        >
                            <option value="">All</option>
                            <option value="ENABLED">ENABLED</option>
                            <option value="DISABLED">DISABLED</option>

                        </select>
                    </div>
                    <div className="col-span-12 sm:col-span-12 flex justify-end">
                        <button
                            className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                            type="button"
                            onClick={() => getAllCities(0)}
                        >
                            Filter
                        </button>
                        <button
                            className="text-white bg-red-600 border border-red-300 rounded-md background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                            type="button"
                            onClick={(e) => resetFilterData(e)}
                        >
                            Reset
                        </button>

                    </div>
                    <div className="col-span-12 sm:col-span-12"> <hr/></div>
                    <div className="col-span-12 sm:col-span-12">
                        {
                            Loading ? (<Loader/>) : (
                                    <div className="border bg-white rounded border-gray-200 shadow">

                                        <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                                            <thead>
                                            <tr className="text-left border-b border-gray-300">
                                                <th className="sticky top-0 px-3 py-2 bg-gray-800">City</th>
                                                <th className="sticky top-0 px-3 py-2 bg-gray-800">State</th>
                                                <th className="sticky top-0 px-3 py-2 bg-gray-800">Date</th>
                                                {
                                                    HasAnyAccess(['city.master.edit']) && (
                                               <>
                                                   <th className="sticky top-0 px-3 py-2 bg-gray-800">Status</th>
                                                   <th className="sticky top-0 px-3 py-2 bg-gray-800">Actions</th>
                                               </>
                                                    )}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {   Cities.length > 0 ?
                                                Cities.map((city:any, index:number) => {
                                                    return (
                                                        <tr key={index} className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                            <td className="py-3 px-3 text-left relative">
                                                                {city.city}
                                                            </td>
                                                            <td className="py-3 px-3 text-left relative">
                                                                {city.state}
                                                            </td>
                                                            <td className="py-3 px-3 text-left relative">
                                                                {moment(city.createdAt).format("DD-MM-YYYY HH:mm")}
                                                            </td>
                                                                {
                                                                    HasAnyAccess(['city.master.edit']) && (
                                                                        <td className="py-3 px-3 text-left relative">
                                                                            <div
                                                                    className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="toggle"
                                                                        id={`${city.id}toggle`}
                                                                        checked={city.active === 'ENABLED'}
                                                                        className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                                                                        onChange={(e) => enableDisableCity(e, city)}
                                                                    />
                                                                    <label htmlFor={`${city.id}toggle`}
                                                                           className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"/>
                                                                </div>
                                                            </td>)}
                                                                {
                                                                    HasAnyAccess(['city.master.edit']) && (
                                                                        <td className="py-3 px-3 text-left relative">
                                                                        <button
                                                                            className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                                            type="button"
                                                                            onClick={() => getCity(city.id)}
                                                                        >
                                                                            Edit
                                                                        </button>

                                                            </td>)
                                                                }
                                                        </tr>
                                                    )
                                                }) : (<tr
                                                className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                <td className="py-3 px-3 text-center relative" colSpan={11}>
                                                No Data To Display
                                                </td>
                                                </tr>)
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                )
                        }
                    </div>
                </div>
                <div className="col-span-12 sm:col-span-12 px-4 pt-2">
                    {/*Pagination Bar*/}
                    {
                        !Loading && (
                            <Pagination
                                meta={MetaData}
                                handlePageChange={(number:number) => getAllCities(number)}
                            />
                        )
                    }

                </div>
            </div>
            {
                OpenModal && (
                    <CityForm
                        onCloseModal={closeModal}
                        city={City}
                        onChangeCity={onChangeCity}
                        getAllCities={getAllCities}
                        validatorRef={Validator}
                        states={States}
                        page={CurrentPage}
                    />
                )
            }
        </Layout>
    );
};

export default CityTable;
