import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import logo from "../../../Images/logo2.svg";
import {toast, ToastContainer} from 'react-toastify';
import SimpleReactValidator from "simple-react-validator";
import {CognitoUser} from "amazon-cognito-identity-js";
import {UserPool} from "../../../Services/UserPool";
import OtpInput from "react-otp-input";
import Modal from "../../../Components-ui/Modal/Modal";

const ResetPassword:React.FC = () => {
  const [email, setEmail] = useState('');
  const [formShow, setFormShow] = useState<boolean>(false);
  const [password, setPassword] = useState('');
  const [children, setChildren] = useState<any>();
  const [show, setShow] = useState(false);
  const [resend, setResend] = useState(false);
  const [otp, setOtp] = useState<any>('');
  const history = useHistory();
  const [validatorStatus, setValidator] = useState<boolean>(false);
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);
  const [resendStatus, setResendStatus] = useState<boolean>(false);
  const [canVerfy, setCanVerfy] = useState<boolean>(false);
  const hideModal = () => {
    setShow(false);
  };
  const handleChange = (data: any) => {
    setOtp(data);
    data.length === 6 ? setCanVerfy(true) : setCanVerfy(false);
  }

  useEffect(() => {
    childrenElements();
  }, [email]);

  useEffect(() => {
    childrenElements();
  }, [otp]);

  useEffect(() => {
    childrenElements();
  }, [resend]);

  useEffect(() => {
    childrenElements();
  }, [seconds]);

  useEffect(() => {
    childrenElements();
  }, [resendStatus]);

  useEffect(() => {
    childrenElements();
  }, [canVerfy]);

  useEffect(() => {
    if(resendStatus){
      const myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
            setResend(false);
            setResendStatus(false);
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }

  }, [resendStatus,seconds]);

  useEffect(() => {
    setMinutes(2);
    setSeconds(0);
  }, [resendStatus]);

  const otpVerification = async () => {
    const user: CognitoUser = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
     user.forgotPassword({
      onSuccess: function(data) {
        console.log(data);
      },
      onFailure: function(err) {
        toast.error(err.message || JSON.stringify(err));
      },
    });
  }

  const resendOtp = async () => {
    setResend(false);
    setResendStatus(true);
    const user: CognitoUser = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    user.resendConfirmationCode(function (err, result) {
      if (err) {
        toast.error(err.message || JSON.stringify(err));
        setResendStatus(false);
        return;
      }
      setResend(true);
    });
  }

  const sendOtp = async () => {
    console.log('Sending',email);
    const user: CognitoUser = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    user.forgotPassword({
      onSuccess(data) {
      // successfully initiated reset password request
        console.log(`CodeDeliveryData from forgotPassword: ${data}`);
        // resolve(true);
      },
      onFailure(err) {
        console.log(err);
        // reject(err);
      },
    });
  }

  const confirmPassword =()=>{
    const user: CognitoUser = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    user.confirmPassword(otp, password, {
      onSuccess() {
        toast.success('Password has been changed!');
        setTimeout(function (){
          setShow(false);
          history.push('/login');
        }, 1000);
      },
      onFailure(err) {
        toast.error(err.message || JSON.stringify(err))
      },
    });
  }

  const childrenElements = () => {
    setChildren(<div className="w-full">
      <form className="bg-white rounded pt-6 pb-8 mb-4">
        <div>
          <svg data-name="Layer 1" width="" height="200" viewBox="0 0 505.46625 596.94537"
               xmlns="http://www.w3.org/1999/xlink" className="rounded-b-full shadow-lg w-full">
            <path
                d="M706.73312,652.47268l5,96S500.239,547.178,473.89063,488.28153,497.13921,283.694,497.13921,283.694l110.04332,60.44633Z"
                transform="translate(-347.26688 -151.52732)" fill="#9f616a"/>
            <path d="M382.44618,243.39641l-32.548,3.09981s-17.049,37.19774,35.64783,40.29756Z"
                  transform="translate(-347.26688 -151.52732)" fill="#9f616a"/>
            <path d="M382.44618,300.74293l-32.548,3.09981s-17.049,37.19774,35.64783,40.29755Z"
                  transform="translate(-347.26688 -151.52732)" fill="#9f616a"/>
            <path d="M382.44618,362.73916l-32.548,3.09981s-17.049,37.19774,35.64783,40.29756Z"
                  transform="translate(-347.26688 -151.52732)" fill="#9f616a"/>
            <path d="M391.74562,424.7354l-32.548,3.09981s-17.049,37.19774,35.64784,40.29755Z"
                  transform="translate(-347.26688 -151.52732)" fill="#9f616a"/>
            <path
                d="M366.60044,258.3401h2.46966V190.68455a39.15718,39.15718,0,0,1,39.15715-39.15723H551.56477a39.15718,39.15718,0,0,1,39.15726,39.1571V561.84816a39.15719,39.15719,0,0,1-39.15715,39.15723H408.22744a39.1572,39.1572,0,0,1-39.15731-39.15708V306.49848h-2.46969Z"
                transform="translate(-347.26688 -151.52732)" fill="#3f3d56"/>
            <path
                d="M406.64739,161.71467h18.71028a13.8929,13.8929,0,0,0,12.86292,19.13985h82.1162a13.89286,13.89286,0,0,0,12.86291-19.13987h17.47545a29.24215,29.24215,0,0,1,29.24218,29.24211V561.57589a29.24216,29.24216,0,0,1-29.24214,29.24218H406.64739a29.24216,29.24216,0,0,1-29.24218-29.24214h0V190.95679A29.24214,29.24214,0,0,1,406.64739,161.71467Z"
                transform="translate(-347.26688 -151.52732)" fill="#fff"/>
            <rect x="100.53673" y="114.01642" width="61.71533" height="14.89247" fill="#6c63ff"
            />
            <rect x="97.59857" y="178.00873" width="67.59164" height="14.89247" fill="#e5e5e5"/>
            <rect x="55.96534" y="209.9996" width="150.85812" height="14.89247" fill="#e5e5e5"/>
            <rect x="55.96534" y="241.99047" width="150.85812" height="14.89247" fill="#e5e5e5"/>
            <path
                d="M852.73312,702.47268l-173.48-197.91713-6.19962-127.09228-65.096-108.49341-18.59887-46.49718s-43.39736,4.64972-7.74953,92.99435l14.72411,47.27213,0,0a216.67408,216.67408,0,0,0-20.14876,91.24394v108.3237c0,25.50678,121.55,164.44287,135.69866,185.6658l0,0Z"
                transform="translate(-347.26688 -151.52732)" fill="#9f616a"/>
            <polygon
                points="247.195 126.636 260.136 120.276 259.695 119.378 247.738 125.255 229.688 77.932 228.754 78.289 247.195 126.636"
                opacity="0.2"/>
            <rect x="358.98404" y="239.81058" width="1.00012" height="20.43016"
                  transform="translate(-346.65612 349.39987) rotate(-69.7779)" opacity="0.2"/>
            <rect x="358.98404" y="297.81058" width="1.00012" height="20.43016"
                  transform="translate(-401.08099 387.35158) rotate(-69.7779)" opacity="0.2"/>
            <rect x="358.98404" y="359.81058" width="1.00012" height="20.43016"
                  transform="translate(-459.25929 427.92066) rotate(-69.7779)" opacity="0.2"/>
            <rect x="364.06574" y="425.55799" width="0.99981" height="10.35283"
                  transform="translate(-528.7721 421.49849) rotate(-64.36101)" opacity="0.2"/>
            <circle cx="131.47081" cy="342.17535" r="43.22999" fill="#6c63ff"/>
            <polygon
                points="127.351 360.424 114.413 343.787 121.937 337.935 128.063 345.812 148.76 323.964 155.681 330.521 127.351 360.424"
                fill="#fff"/>
          </svg>
        </div>
        <h1 className="text-center font-bold mt-4">
          {'OTP Verification '}
        </h1>
        <div className="mb-4">
          <OtpInput
              inputStyle={{
                width: '2.7rem',
                height: '2.7rem',
                margin: '10px 0.4rem',
                fontSize: '2rem',
                borderRadius: 4,
                border: '2px solid rgba(0,0,0,0.3)',
              }}
              isInputNum={true}
              value={ otp }
              onChange={handleChange}
              numInputs={6}
              shouldAutoFocus
          />
        </div>
        <div className="flex justify-evenly text-center mb-2" style={{fontSize: '12px'}}>
          <div>
            <span>Didn't you receive the OTP? </span>
            <button disabled={resendStatus} className="outline-none focus:outline-none text-red-400" type="button"
                    style={{fontSize: '12px', cursor: "pointer"}} onClick={() => resendOtp()}>Resend
              OTP
            </button>
          </div>
          { !resendStatus
              ? null
              : (
                  <h5 className="float-end">
                    {' '}
                    {minutes}
                    :
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </h5>
              )}
        </div>
        {
          resend ? (<div className="mb-2 text-center" style={{fontSize: '10px'}}><span className="text-green">Successfully send the OTP to </span><span
                  className="text-red-600">{email}</span></div>
          ) : ('')
        }
        <div className="flex items-center justify-between flex-row-reverse">
          <button disabled={!canVerfy}
              className={`w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${canVerfy ? 'bg-purple-950' : 'bg-purple-800 cursor-not-allowed'}`}
              // className="w-full bg-purple-950 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button" onClick={() => {
            confirmPassword();
          }}
          >
            {'Verify'}

          </button>
        </div>
      </form>
    </div>);
  }

  const reset = async () => {
    setValidator(true);
    if (validator.allValid()) {
      //otpVerification();
      sendOtp();
      setShow(true);
      setValidator(false);
    }
  };

  const simpleValidator = new SimpleReactValidator({
    locale: "en",
    validators: {},
  });
  const validationRef = React.useRef(simpleValidator);
  const validator = validationRef.current;

  const getValidatorError = (nameOfStateProp: any) => {
    const allErrorMessages = validator.getErrorMessages();
    return allErrorMessages[nameOfStateProp];
  };
  const save = async () => {
    setValidator(true);
    if (validator.allValid()) {
      setFormShow(true);
      setValidator(false);
    }
  }
  return (
      <div className="mt-12 h-full flex items-center justify-center  py-12 px-4 sm:px-6 lg:px-8">
        <ToastContainer/>
        <div className="max-w-md w-full space-y-8">
          <div>
            <img className="mx-auto h-12 w-auto" src={logo} alt="Workflow"/>
            <h2 className="mt-6 text-center text-xl font-bold text-gray-900">
              Reset Your eBullion Admin Account Password
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">

              <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">

              </a>
            </p>
          </div>
          {
            !formShow ? (<form className="mt-8 space-y-6" >
                  <div className="rounded-md shadow-sm -space-y-px">
                    <div>
                      <label htmlFor="email-address" className="sr-only">Email address</label>
                      {/*<label htmlFor="email-address" className="text-gray-700">Email address</label>*/}
                      <input id="email-address" value={email} onChange={event => setEmail(event.target.value)}
                             name="email" type="email" autoComplete="email"
                             className="mt-2.5 appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                             placeholder="Email address"/>
                    </div>
                    {validator.message(
                        "email",
                        email,
                        "email|required"
                    )}
                    {
                      validatorStatus ? (<span className="text-xs text-red-700"
                                               id="name">{getValidatorError("email")}</span>) : ''
                    }
                  </div>
                  <div>
                    <button type="button" onClick={save}
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span className="absolute left-0 inset-y-0 flex items-center pl-3">

            <svg className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clip-rule="evenodd"/>
            </svg>
          </span>
                      Next
                    </button>
                  </div>
                </form>
            ) :(<form className="mt-8 space-y-6" >
                  <div className="rounded-md shadow-sm -space-y-px">
                    <div>
                      <label htmlFor="email-address" className="sr-only">Password</label>
                      {/*<label htmlFor="email-address" className="text-gray-700">New Password</label>*/}
                      <input id="password" value={password} onChange={event => setPassword(event.target.value)}
                             name="password" type="password" autoComplete="current-password" required
                             className="mt-2.5 appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                             placeholder="New Password"/>
                    </div>
                    {validator.message(
                        "password",
                        password,
                        ['required',{regex: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{4,}$'}]
                    )}
                    {
                      validatorStatus ? (<span className="text-xs text-red-700"
                                               id="password">{getValidatorError("password")}</span>) : ''
                    }
                  </div>
                  <div>
                    <button type="button" onClick={reset}
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span className="absolute left-0 inset-y-0 flex items-center pl-3">

            <svg className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clip-rule="evenodd"/>
            </svg>
          </span>
                      Reset
                    </button>
                  </div>
                </form>
            )
          }
        </div>
        <Modal size={''} show={show} handleClose={hideModal} children={children}/>
      </div>
  );
};

export default ResetPassword;
