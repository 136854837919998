import create, { SetState, GetState } from "zustand";
import { persist } from "zustand/middleware";

export type AuthStore = {
  isAuthenticated: boolean;
  tokens: {
    accessToken: any;
    idToken: any;
  };
  userData: any;
  permissions: [];
  roles: [];
  warehouses: [];
  partner: [];
  partnerAdminId: "";
  isPartnerAdmin: boolean;
  logout: () => void;
  setLoginData: (data: any) => void;
};

export const useAuthStore = create<AuthStore>(
  persist(
    (set: SetState<AuthStore>, get: GetState<AuthStore>) => ({
      isAuthenticated: false,
      tokens: { accessToken: null, idToken: null },
      userData: {},
      permissions: [],
      roles: [],
      warehouses: [],
      partner: [],
      partnerAdminId: "",
      isPartnerAdmin: false,
      logout: () => {
        set({
          userData: {},
          tokens: { accessToken: null, idToken: null },
          isAuthenticated: false,
          permissions: [],
          roles: [],
          warehouses: [],
          partner: [],
          partnerAdminId: "",
          isPartnerAdmin: false,
        });
      },
      setLoginData: (data): void => {
        // const { isAuthenticated ,tokens ,userData } = get();
        set({ isAuthenticated: true });
        set({ tokens: data.tokens });
        set({ userData: data.userData });
        set({ permissions: data.permissions });
        set({ roles: data.roles });
        set({ warehouses: data.warehouses });
        set({ partner: data.partner });
        set({ partnerAdminId: data.partnerAdminId });
        set({ isPartnerAdmin: data.isPartnerAdmin });
      },
    }),
    {
      name: "auth-storage", // unique name
    }
  )
);
