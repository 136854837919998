import React from 'react';

const LoginLayout:React.FC = (props : any) => {
    return (
        <>
           
            <main className='min-h-screen flex items-center'>
                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    <div className="px-4 py-6 sm:px-0">
                        {props.children}
                    </div>
                </div>
            </main>
        </>
    );
}

export default LoginLayout;