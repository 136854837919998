import React from "react";
import { Route } from "react-router-dom";

import TradeExecutionTable from "../Components/TradeExecutionTable";
import AddNewTradeExecutionRule from "../Components/AddNewTradeExecutionRule";
import EditTradeExecution from "../Components/EditTradeExecution";
import Invoice from "../../Invoice/Invoice";
import Gift from "../../gift/gift";

export const TradeExecutionRoutes: React.FC = () => {
  return (
    <>
      <Route path="/trade-execution" exact component={TradeExecutionTable} />
      <Route
        path="/trade-execution/add"
        exact
        component={AddNewTradeExecutionRule}
      />
      <Route
        path="/trade-execution/update/:id"
        exact
        component={EditTradeExecution}
      />
      <Route path="/invoice-list" exact component={Invoice} />
      <Route path="/gift-list" exact component={Gift} />
    </>
  );
};
