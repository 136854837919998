import React, {useEffect, useState} from "react";
import Layout from "../../Layout/Layout";
import {ToastContainer, toast} from "react-toastify";
import "./react-confirm-alert.css";
import Loader from "../../../Components-ui/Loader";
import {Pagination} from "../../../Components-ui/Pagination";
import Modal from "../../../Components-ui/Modal/Modal";
import UOMService from "../UOMService";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import { useAuthStore } from "../../../Store/AuthStore";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";

const UOM: React.FC = () => {
    const [Loading, setLoading] = useState<any>(true);
    const [uomList, setUOMList] = useState<any>([]);
    const [show, setModalStatus] = useState<any>(false);
    const [updateStatus, setUpdateStatus] = useState<any>('');
    const [status, setStatus] = useState<any>('ADD');
    const [children, setChildren] = useState<any>();
    const [selectedRecord, setSelectedRecord] = useState<any>({
        active: "ENABLED",
        name: "",
        code: "",
        erpUom: "",
    });
    const { logout, permissions, userData , tokens } = useAuthStore();
    const [filters, setFilters] = useState<any>({
        code: '',
        name: '',
        active: '',
    });
    const [validatorStatus, setValidator] = useState<boolean>(false);
    const [meta, setMetaData] = useState<any>({});
    const [currentPage, setCurrentPage] = useState<number>(0);

    const simpleValidator = new SimpleReactValidator({
        locale: "en",
        validators: {},
    });
    const validationRef = React.useRef(simpleValidator);
    const validator = validationRef.current;

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        childrenElements();
    }, [selectedRecord, status]);

    useEffect(() => {
        // if (selectedRecord && 'STATUS' === updateStatus) {
        //     updateRecord();
        // }
    }, [updateStatus, status, selectedRecord]);

    useEffect(() => {
        childrenElements();
    }, [validatorStatus]);

    const loadData = async () => {
        setLoading(true)
        await UOMService.getUOMList(tokens).then((response: any) => {
            setUOMList(response.data.data.result);
            setMetaData({
                current: response.data.meta.page,
                pages: response.data.meta.totalPages,
            });
            setCurrentPage(response.data.meta.page);
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            toast.error(err.data.errors[0].title);
        });
    };

    const changeStatus = async (status: any, key: any, id: any) => {
        setLoading(true);
        setUpdateStatus('STATUS');
        var data = {
            id:id,
            active: ('ENABLED' !== status) ? 'ENABLED' : 'DISABLED'
        };
        await UOMService.updateUOM(data,tokens).then((response: any) => {
            setLoading(false);
            setUpdateStatus('');
            paginationFilter(currentPage);
            toast.success("Updated Successfully");
        }).catch(err => {
            setLoading(false)
            toast.error(err.data.errors[0].title);
            loadData();
        });

    }

    const paginationFilter = async (page: number) => {
        setCurrentPage(page);
        await filter(page);
    }

    const childrenElements = () => {
        setChildren(<div className="w-full max-w-xs">
            <h1 className="font-bold">
                {('ADD' === status) ? 'Add New UOM' : 'Edit UOM'}
            </h1>
            <form className="bg-white rounded pt-6 mb-4" onSubmit={handleFormSubmit}>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="code">
                        Code
                    </label>
                    <input
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        id="code" name="code" type="text" placeholder="Code"
                        onChange={(event) => setSelectedRecord({
                            ...selectedRecord,
                            code: event.target.value
                        })} value={selectedRecord.code || ''} autoComplete="off"/>
                    {validator.message(
                        "code",
                        selectedRecord.code,
                        "required|max:4"
                    )}
                    {
                        validatorStatus ? (<span className="text-xs text-red-700"
                                                 id="code">{getValidatorError("code")}</span>) : ''
                    }
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="name">
                        Name
                    </label>
                    <input
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        id="name" name="name" type="text" placeholder="Name" onChange={(event) => setSelectedRecord({
                        ...selectedRecord,
                        name: event.target.value
                    })} value={selectedRecord.name || ''} autoComplete="off"/>
                    {validator.message(
                        "name",
                        selectedRecord.name,
                        "required"
                    )}
                    {
                        validatorStatus ? (<span className="text-xs text-red-700"
                                                 id="name">{getValidatorError("name")}</span>) : ''
                    }
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="name">
                        ERP uom
                    </label>
                    <input
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        id="name" name="erp-uom" type="text" placeholder="Name" onChange={(event) => setSelectedRecord({
                        ...selectedRecord,
                        erpUom: event.target.value
                    })} value={selectedRecord.erpUom || ''} autoComplete="off"/>
                    {validator.message(
                        "erp-uom",
                        selectedRecord.erpUom,
                        "required"
                    )}
                    {
                        validatorStatus ? (<span className="text-xs text-red-700"
                                                 id="name">{getValidatorError("erp-uom")}</span>) : ''
                    }
                </div>

                <div className="">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="username">
                        Status
                    </label>
                    <div className="flex justify-between items-center" onClick={(event) => setSelectedRecord({
                        ...selectedRecord,
                        active: ('ENABLED' !== selectedRecord.active) ? 'ENABLED' : 'DISABLED'
                    })}>
                        <div
                            className={`w-14 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out ${("ENABLED" === selectedRecord.active) ? "bg-green-400" : ""}`}>
                            <div
                                className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${("ENABLED" === selectedRecord.active) ? "translate-x-6" : ""}`}>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-between flex-row-reverse">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button" onClick={() => {
                        if ('ADD' === status) {
                            addNew()
                        } else {
                            updateRecord()
                        }
                    }}>
                        {('ADD' === status) ? 'SAVE' : 'UPDATE'}
                    </button>
                </div>
            </form>
        </div>);
    }

    const addNew = async () => {
        setValidator(true);
        var data = [
            {
                active: selectedRecord.active || 'DISABLED',
                uomName: selectedRecord.name || '',
                uomCode: selectedRecord.code || '',
                erpUom: selectedRecord.erpUom || '',
                createdBy: selectedRecord.createdBy || '',
            }
        ];
        if (validator.allValid()) {
            setLoading(true);
            UOMService.addNewUOM(data, tokens).then(() => {
                loadData();
                setLoading(false)
                toast.success("Record Added Successfully");
                setModalStatus(false);
            }).catch(err => {
                setLoading(false)
                setModalStatus(false);
                toast.error(err.data.errors[0].title);
            });
            setValidator(false);
        }
    }

    const updateRecord = async () => {
        setValidator(true);
        if (validator.allValid()) {
            setLoading(true);
            var data = {
                    id:selectedRecord.id,
                    active: selectedRecord.active,
                    uomName: selectedRecord.name,
                    uomCode: selectedRecord.code,
                };
            await UOMService.updateUOM(data,tokens).then((response: any) => {
                setLoading(false);
                setModalStatus(false);
                setUpdateStatus('');
                loadData();
                toast.success("Updated Successfully");
            }).catch(err => {
                setLoading(false)
                toast.error(err.data.errors[0].title);
                loadData();
            });
            setValidator(false);

        }
    }

    const editModalElements = (data: any) => {
        setUpdateStatus('EDIT');
        setStatus('EDIT');
        childrenElements();
        setSelectedRecord({...data,code:data.uomCode,name:data.uomName});
        setModalStatus(true);
    }

    const addModalElements = () => {
        setValidator(false)
        setSelectedRecord({
            active: "ENABLED",
            name: "",
            code: "",
            erpUom: "",
        });
        setStatus('ADD');
        childrenElements();
        setModalStatus(true);
    }

    // TODO integrate the endpoint
    const filter = async (current : number = 0) => {
        setLoading(true);
        await UOMService.filterUOM(filters, current, tokens).then((response: any) => {
            setUOMList(response.data.data.result);
            setMetaData({
                current: response.data.meta.page,
                pages: response.data.meta.totalPages,
            });
            setCurrentPage(response.data.meta.page);
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            toast.error(err.data.errors[0].title);
        });
    }

    const ResetForm = async () => {
        setFilters({
            code: '',
            name: '',
            active: '',
        });
        loadData();
    }

    const handleFormSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (validator.allValid()) {
        } else {
            setValidator(true)
            validator.showMessages();
        }

    };

    const getValidatorError = (nameOfStateProp: any) => {
        const allErrorMessages = validator.getErrorMessages();
        return allErrorMessages[nameOfStateProp];
    };

    const hideModal = () => {
        setModalStatus(false);
    };

    return (
        <Layout type={"UOMModule"}>
            <ToastContainer/>
            <div className="flex justify-between items-center mb-5">
                <h1 className="text-2xl font-bold tracking-tight">
                    {"UOMs"}
                </h1>
                {
                    HasAnyAccess(['uom.master.create']) && (
                        <button
                            className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
                            type="button"
                            onClick={() => {
                                addModalElements()
                            }}
                        >
                            + Create New
                        </button>
                    )
                }
            </div>
            <div className="border bg-white rounded border-gray-200 shadow">
                <div className="flex-1 p-2">
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="code">
                                Code
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                name="code" id="code" type="text" placeholder="Code"
                                autoComplete="none" onChange={event => setFilters({
                                ...filters,
                                code: event.target.value
                            })} value={filters.code}/>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="description">
                                Name
                            </label>
                            <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="description" type="text" placeholder="Name" autoComplete="none"
                                onChange={event => setFilters({
                                    ...filters,
                                    name: event.target.value
                                })} value={filters.name}/>
                        </div>
                        <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="status">
                                Status
                            </label>
                            <div className="relative">
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="status" onChange={event => setFilters({
                                    ...filters,
                                    active: event.target.value
                                })} value={filters.active}>
                                    <option value="">Select a Status</option>
                                    <option value="ENABLED">ENABLED</option>
                                    <option value="DISABLED">DISABLED</option>
                                </select>
                            </div>
                        </div>
                        <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                            <div className="my-6">
                                <button
                                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                                    type="button" onClick={() => filter()}>
                                    Filter
                                </button>
                                <button
                                    className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                                    type="button" onClick={() => ResetForm()}>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border bg-white rounded border-gray-200 shadow m-2">
                    {Loading ? (<Loader/>) : (
                        <div>
                            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                                <thead>
                                <tr className="text-left border-b border-gray-300">
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800">Code</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800">Name</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800">ERP uom</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800">Created By</th>
                                    <th className="sticky top-0 px-3 py-2 bg-gray-800">Updated Date</th>
                                    {
                                        HasAnyAccess(['uom.master.edit']) && (
                                    <>
                                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Status</th>
                                        {/*<th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">Actions</th>*/}
                                    </>
                                        )}
                                </tr>
                                </thead>
                                <tbody>
                                {(0 !== uomList.length) ?
                                    uomList.map((uom: any, index: number) => {
                                        return (
                                            <tr key={index}
                                                className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100">
                                                <td className="py-3 px-3 text-left relative">
                                                    {uom.uomCode}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {uom.uomName}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {uom.erpUom}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {uom.createdBy}
                                                </td>
                                                <td className="py-3 px-3 text-left relative">
                                                    {moment(uom.updatedAt).format("DD-MM-YYYY HH:mm")}
                                                </td>
                                                    {
                                                        HasAnyAccess(['uom.master.edit']) && (
                                                            <td className="py-3 px-3 text-left relative">

                                                            <div className="flex justify-between items-center" onClick={() => {
                                                        changeStatus(uom.active, index, uom.id)
                                                    }}>
                                                        <div
                                                            className={`w-14 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out ${("ENABLED" === uom.active) ? "bg-green-400" : ""}`}>
                                                            <div
                                                                className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${("ENABLED" === uom.active) ? "translate-x-6" : ""}`}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>)}
                                                {/*    {*/}
                                                {/*        permissions.filter((permission: any) => permission.permissionCode === 'uom.master.edit').length > 0 && (*/}
                                                {/*            <td className="py-3 px-3 text-left relative">*/}

                                                {/*            <button*/}
                                                {/*                className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"*/}
                                                {/*                type="button"*/}
                                                {/*                onClick={() => {*/}
                                                {/*                    editModalElements(uom)*/}
                                                {/*                }}*/}
                                                {/*            >*/}
                                                {/*                Edit*/}
                                                {/*            </button>*/}

                                                {/*</td>)*/}
                                                {/*    }*/}
                                            </tr>
                                        )
                                    })
                                    : (<tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                                        <td colSpan={7} className="text-center py-3 px-3 text-left relative">No data to
                                            display
                                        </td>
                                    </tr>)
                                }
                                </tbody>
                            </table>
                        </div>
                    )}
                    <Pagination
                        meta={meta}
                        handlePageChange={(number: number) => {
                        paginationFilter(number)
                        }}
                    />
                </div>
                <Modal show={show} handleClose={hideModal} children={children}/>
            </div>
        </Layout>
    );
};

export default UOM;
