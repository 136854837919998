import React, { useState, useEffect } from "react";
import { useAuthStore } from "../../../Store/AuthStore";
import Layout from "../../Layout/Layout";
import { toast, ToastContainer } from "react-toastify";
import Datetime from "react-datetime";
import moment from "moment";
import { useHistory } from "react-router-dom";
import MultipleSelect from "../../../Components-ui/MultipleSelect";
import MetalInterchangeService from "../../MetalInterchange/MetalInterchangeService";
import TradeTables from "./TradeTables";
import { CalendarIcon, FilterIcon } from "@heroicons/react/outline";
import CustomerService from "../../Customer/Services/Customer.service";

// interface PropsType {
//   flow?: any;
// }
const Metals = [
  {
    metalCode: "Ag",
    metalName: "Silver",
  },
  {
    metalCode: "Pt",
    metalName: "Platinum",
  },
  {
    metalCode: "Pd",
    metalName: "Palladium",
  },
  {
    metalCode: "Au",
    metalName: "Gold",
  },
];

const transactionStatus = [
  {
    id: "COMPLETED",
    status: "COMPLETED",
  },
  {
    id: "PENDING",
    status: "PENDING",
  },
  {
    id: "PAYMENT_PENDING",
    status: "PAYMENT_PENDING",
  },
  {
    id: "INVOICE_APPROVAL_PENDING",
    status: "INVOICE_APPROVAL_PENDING",
  },
  {
    id: "INVOICE_UPLOAD_PENDING",
    status: "INVOICE_UPLOAD_PENDING",
  },
  {
    id: "PARTIAL_COMPLETED",
    status: "PARTIAL_COMPLETED",
  },
];

const isCoveredStatus = [
  {
    id: "Uncovered",
    status: "Uncovered",
  },
  {
    id: "All",
    status: "All",
  },
  {
    id: "Covered",
    status: "Covered",
  },
];

const initialFilterValues = () => {
  let fromDate = new Date(moment().startOf("day").format("YYYY-MM-DD hh:mm A"));
  let toDate = new Date();
  return {
    fromDate,
    toDate,
    itemCode: "",
    vault: "",
    CuGroup: "",
    isMetalCovered: "Uncovered",
    isFxCovered: "Uncovered",
    transactionStatus: [
      { id: "COMPLETED", status: "COMPLETED" },
      { id: "PENDING", status: "PENDING" },
      { id: "INVOICE_APPROVAL_PENDING", status: "INVOICE_APPROVAL_PENDING" },
      { id: "INVOICE_UPLOAD_PENDING", status: "INVOICE_UPLOAD_PENDING" },
    ],
  };
};

const ReportDashboard: React.FC = () => {
  const history = useHistory();
  const [filters, setFilters] = useState<any>(initialFilterValues());
  const [selectedFilters, setSelectedFilters] = useState<any>(
    initialFilterValues()
  );
  const [vaults, setVaults] = useState<any>([]);
  const [CustomerGroups, setCustomerGroups] = useState<any>([]);
  const [selectedMetal, setSelectedMetal] = useState(Metals[0]);
  const { logout, permissions, userData, tokens } = useAuthStore();
  const [expanded, setExpanded] = useState(false);
  const [page, setPage] = useState(0);

  const loadVaults = async (): Promise<void> => {
    return new Promise(async (resolve) => {
      MetalInterchangeService.getVaults(tokens)
        .then((response: any) => {
          setVaults(response.data.data);
          resolve();
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
          resolve();
        });
    });
  };

  // const getCustomerGroups = async () => {
  //   let data: any = await TradeExecutionService.getCustomerGroups(tokens);
  //   if (data.status === 200 && data.data.data) {
  //     let responseData = data.data.data;
  //     setCustomerGroups(responseData);
  //   }
  // };

  const getCustomerGroups = () => {
    CustomerService.getPartnerList(tokens)
      .then((response: any) => {
        setCustomerGroups([
          { partnerId: null, name: "All Partners" },
          ...response.data.data,
        ]);
      })
      .catch((err) => {
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    loadVaults();
    getCustomerGroups();
    // getReportData();
  }, []);

  const resetForm = async () => {
    setFilters(initialFilterValues());
    setSelectedFilters(initialFilterValues());
    // getPartnerList(0);
  };

  interface PropsType {
    metal: any;
    index: any;
  }

  const Tab: React.FC<PropsType> = ({ metal, index }) => {
    return (
      <button
        style={{ pointerEvents: "unset" }}
        // className={`border border-purple-300 rounded-md background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150 ${!data.fileUploaded ? "cursor-not-allowed bg-gray-300" : "bg-purple-100 hover:bg-purple-950 hover:text-white"}`}
        className={`${tabClasses} ${
          selectedMetal?.metalCode == metal?.metalCode
            ? "bg-purple-950 text-white"
            : ""
        }
        `}
        type="button"
        // onClick={() => data.fileUploaded && downloadReport(data.activePartnerSettlementProcessId)}
        onClick={() => {
          setPage(0);
          setSelectedMetal(metal);
          setFilters(initialFilterValues());
          setSelectedFilters(initialFilterValues());
        }}
      >
        {metal?.metalName}
      </button>
    );
  };

  const tabClasses =
    "nav-link block border-r border-l  font-medium text-xs leading-tight uppercase border-collapse px-6 py-3  hover:border-transparent hover:bg-gray-100 focus:border-transparent ";

  const validDateFromDate = function (current: any) {
    return (
      current.isBefore(moment()) && current.isSameOrAfter(moment("01-04-2020"))
    );
  };

  const validateToDate = function (current: any) {
    return current.isSameOrAfter(filters?.fromDate, "day");
  };

  return (
    <Layout>
      <ToastContainer />

      <div className="flex items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">Summary Report</h1>
      </div>
      <div className="border bg-white rounded border-gray-200 shadow">
        <div className="flex justify-between w-full border-b flex-wrap">
          <div className="flex">
            {Metals?.map((metal, index) => {
              return <Tab metal={metal} index={index} />;
            })}
          </div>
          <button
            // style={{ pointerEvents: "unset" }}
            // className={`border border-purple-300 rounded-md background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150 ${!data.fileUploaded ? "cursor-not-allowed bg-gray-300" : "bg-purple-100 hover:bg-purple-950 hover:text-white"}`}
            className={`${tabClasses} w-fit outline-none`}
            type="button"
            // onClick={() => data.fileUploaded && downloadReport(data.activePartnerSettlementProcessId)}
            onClick={() => setExpanded(!expanded)}
          >
            <FilterIcon className="h-6" />
          </button>
        </div>

        <div className="flex p-2 w-full">
          {expanded && (
            <div className="flex flex-wrap  mb-2 p-4 ">
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-state"
                >
                  From Date
                </label>
                <div className="flex justify-between rounded-md appearance-none block w-full rounded py-2 px-4 border border-grey-darker">
                  <Datetime
                    // timeFormat={"hh:mm A"}
                    dateFormat={"DD-MM-YYYY"}
                    inputProps={{
                      readOnly: true,
                      placeholder: "dd/mm/yyyy",
                      className: "focus:outline-none",
                    }}
                    onChange={(date: any) => {
                      setFilters({
                        ...filters,
                        fromDate: date,
                        toDate:
                          date.diff(filters.toDate) > 0
                            ? moment()
                            : filters.toDate,
                      });
                    }}
                    isValidDate={validDateFromDate}
                    renderInput={(props) => {
                      return (
                        <input
                          {...props}
                          value={
                            filters.fromDate
                              ? moment(new Date(filters.fromDate)).format(
                                  "DD-MM-YYYY hh:mm A"
                                )
                              : ""
                          }
                        />
                      );
                    }}
                  />
                  <CalendarIcon
                    className="h-6"
                    style={{
                      color: "rgb(204,204,204)",
                    }}
                  />
                </div>
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-state"
                >
                  To Date
                </label>
                <div className="flex justify-between rounded-md appearance-none block w-full rounded py-2 px-4 border border-grey-darker">
                  <Datetime
                    // isValidDate={valid}
                    timeFormat={"hh:mm A"}
                    dateFormat={"DD-MM-YYYY"}
                    inputProps={{
                      readOnly: true,
                      placeholder: "dd/mm/yyyy",
                      className: "focus:outline-none",
                    }}
                    onChange={(date: any) => {
                      if (date.diff(filters.fromDate) > 0)
                        setFilters({
                          ...filters,
                          toDate: date,
                        });
                    }}
                    isValidDate={validateToDate}
                    // value={moment(filters.toDate)}
                    renderInput={(props) => {
                      return (
                        <input
                          {...props}
                          value={
                            filters.toDate
                              ? moment(new Date(filters.toDate)).format(
                                  "DD-MM-YYYY hh:mm A"
                                )
                              : ""
                          }
                        />
                      );
                    }}
                  />
                  <CalendarIcon
                    className="h-6 text-grey-darker"
                    style={{
                      color: "rgb(204,204,204)",
                    }}
                  />
                </div>
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="partner-name"
                >
                  Item Code
                </label>
                <input
                  className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                  name="partner-name"
                  id="partner-name"
                  type="text"
                  placeholder="Item Code"
                  autoComplete="none"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      itemCode: event.target.value,
                    })
                  }
                  value={filters.itemCode}
                />
              </div>
              {/* <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="partner-name"
                >
                  Vault
                </label>

                <MultipleSelect
                  optionList={vaults}
                  onChangeItem={(value: any) => {
                    setFilters({
                      ...filters,
                      vault: value,
                    });
                  }}
                  selectedValue={filters?.vault}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  getOptionLabel={(option: any) => `${option.warehouseId}`}
                  getOptionValue={(option: any) => `${option.id}`}
                />
              </div> */}
              {/* <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="partner-name"
              >
                Customer ID
              </label>
              <input
                className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                name="customerID"
                id="customerID"
                type="text"
                placeholder="Customer ID"
                autoComplete="none"
                onChange={(event: any) =>
                  setFilters({
                    ...filters,
                    [event.target.name]: event.target.value,
                  })
                }
                value={filters.customerID}
              />
            </div> */}
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="partner-name"
                >
                  Partner Code
                </label>
                <MultipleSelect
                  optionList={CustomerGroups}
                  onChangeItem={(value: any) => {
                    setFilters({
                      ...filters,
                      CuGroup: value,
                    });
                  }}
                  selectedValue={filters?.CuGroup}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  getOptionLabel={(option: any) => `${option.name}`}
                  getOptionValue={(option: any) => `${option.partnerId}`}
                />
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="partner-name"
                >
                  Status
                </label>
                <MultipleSelect
                  optionList={transactionStatus}
                  onChangeItem={(values: any) => {
                    setFilters({
                      ...filters,
                      transactionStatus: values,
                    });
                  }}
                  selectedValue={filters?.transactionStatus}
                  closeMenuOnSelect={false}
                  getOptionLabel={(option: any) => `${option.status}`}
                  getOptionValue={(option: any) => `${option.id}`}
                />
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="partner-name"
                >
                  Metal Covered
                </label>
                <select
                  name="coveredStatusHP"
                  id="coveredStatusHP"
                  className="form-select appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-label="Default select example"
                  onChange={(event: any) => {
                    let isMetalCovered = event.target.value;
                    setFilters({
                      ...filters,
                      isMetalCovered,
                    });
                  }}
                  value={filters?.isMetalCovered}
                >
                  {isCoveredStatus?.map((item) => {
                    return (
                      <option value={item.id} key={item.id}>
                        {item.status}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="partner-name"
                >
                  Fx Covered
                </label>
                <select
                  name="coveredStatusHP"
                  id="coveredStatusHP"
                  className="form-select appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-label="Default select example"
                  onChange={(event: any) => {
                    let isFxCovered = event.target.value;
                    setFilters({
                      ...filters,
                      isFxCovered,
                    });
                  }}
                  value={filters?.isFxCovered}
                >
                  {isCoveredStatus?.map((item) => {
                    return (
                      <option value={item.id} key={item.id}>
                        {item.status}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="w-full px-3 mb-6 md:mb-0">
                <div className="md:w-1/3" />
                <div className="md:w-2/3 my-6">
                  <button
                    className="px-4 py-2.5 font-bold text-white text-sm uppercase bg-purple-800 rounded"
                    type="button"
                    onClick={() => {
                      setPage(0);
                      setSelectedFilters({ ...filters });
                    }}
                  >
                    Filter
                  </button>
                  <button
                    className="ml-4 px-4 py-2.5 font-bold text-white text-sm uppercase bg-red-600 rounded"
                    type="button"
                    onClick={() => {
                      setPage(0);
                      resetForm();
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className=" bg-white border-gray-200  ">
          <TradeTables
            filters={selectedFilters}
            metalCode={selectedMetal?.metalCode}
            metalName={selectedMetal?.metalName}
            page={page}
            setPage={setPage}
            flow="EB"
          />
        </div>
      </div>
    </Layout>
  );
};

export default ReportDashboard;
