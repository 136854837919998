import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import ConfigDoc from "./ConfigDocument";
import CreateConfigDoc from "./CreateConfigDocument";


const PricingRoutes: React.FC = () => {
  return (
    <Fragment>
      <Route path="/document-config" exact component={ConfigDoc} />
      <Route path="/document-config/create" exact component={CreateConfigDoc} />
      <Route path="/document-config/edit/:id" exact component={CreateConfigDoc} />
    </Fragment>
  );
};

export default PricingRoutes;
