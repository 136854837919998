import React, { useEffect, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import CryptoJS from "crypto-js";
import { ContactlessOutlined } from "@mui/icons-material";
import CustomerService from "../Services/Customer.service";
import { useAuthStore } from "../../../Store/AuthStore";
import axios from "axios";

interface PropsType {
  onCloseModal: any;
  bankDetails: any;
  customer: any;
  get_customer: any;
}

const EditbankModal: React.FC<PropsType> = ({
  onCloseModal,
  bankDetails,
  customer,
  get_customer,
}) => {
  const [Loading, setLoading] = useState<any>(false);
  const { logout, permissions, userData, tokens } = useAuthStore();

  const [userType, setUserType] = useState<string>("");
  const [isVerifyDisabled, setIsVerifyDisabled] = useState<any>(false);

  const [newbankDetails, setnewBankDetails] = useState<any>({
    accountNo: bankDetails?.accountNo,
    name: bankDetails?.name,
    ifsc: bankDetails?.ifsc,
    bank: bankDetails?.bank,
  });
  const [bankData, setBankData] = useState<any>({
    transactionType: "VERIFICATION",
    transactionMethod: "IMPS",
    referenceId: bankDetails?.referenceId,
    referenceType: bankDetails?.referenceType,
    customerAccount: {
      accountType: bankDetails?.type,
      name: bankDetails?.name,
      accountNo: bankDetails?.accountNo,
      bank: bankDetails?.bank,
      ifsc: bankDetails?.accountNo,
    },
    user_id: customer?.user?.userId,
    // accNo: bankDetails?.accountNo,
    // name: bankDetails?.name,
    // ifsc: bankDetails?.ifsc,
    // bankName: bankDetails?.bank,
  });
  //   const { logout, permissions, userData, tokens } = useAuthStore();

  const simpleValidator = new SimpleReactValidator({
    locale: "en",
    validators: {},
  });
  const validationRef = React.useRef(simpleValidator);
  const validator = validationRef.current;

  useEffect(() => {
    if (
      newbankDetails?.accountNo == bankDetails?.accountNo &&
      newbankDetails?.ifsc == bankDetails?.ifsc
    ) {
      setIsVerifyDisabled(true);
    } else {
      setIsVerifyDisabled(false);
    }
  }, [newbankDetails]);
  // const downloadSample = async () => {
  //   setLoading(true);
  //   await BulkUploadService.downloadBulkSample(tokens)
  //     .then((response: any) => {
  //       const href = window.URL.createObjectURL(
  //         new Blob([response.data.data.payload.data])
  //       );
  //       const link = document.createElement("a");
  //       link.href = href;
  //       link.setAttribute("download", "sample-data.xlsx"); //or any other extension
  //       document.body.appendChild(link);
  //       link.click();
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //     });
  // };

  const sendNoti = async () => {
    // const data = bankData

    // console.log("sent", encrypted);
    try {
      const encryptionKey: any = process.env.REACT_APP_VI_PARTNER_KEY;
      const newdata = {
        ...bankData,
        customerAccount: { accountType: bankDetails?.type, ...newbankDetails },
      };
      const objectString = JSON.stringify(newdata);
      // Encrypt
      const encrypted = CryptoJS.AES.encrypt(
        objectString,
        encryptionKey
      ).toString();
      const data = { bank_details: encrypted };
      const res = await CustomerService.updateBankDetails(data, tokens);

      if (res?.status == 200) {
        if (
          res?.data?.data ==
          "Customer Bank details update has reached its limit"
        ) {
          toast.error(res?.data?.data);
          onCloseModal();
        } else {
          get_customer();
          toast.success("Bank Details Updated Successfully");
          onCloseModal();
        }
      } else {
        toast.error("Something went wrong");
      }
    } catch (err: any) {
      console.log(err?.data?.errors[0]?.title);
      toast.error(err?.data?.errors[0]?.title);
    }
  };

  return (
    <>
      {Loading ? (
        <Loader />
      ) : (
        <div className=" w-full bg-gray-800 bg-opacity-50 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-4xl">
            <div className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
              <div className="items-start justify-between px-6 py-4 border-b border-solid border-blue bg-gray-100 rounded-t">
                <div className="flex justify-between items-center mb-5">
                  <h1 className="text-2xl font-bold tracking-tight">
                    {"Update Bank Details"}
                  </h1>
                  {/* <button
            onClick={() => downloadSample()}
            className="text-white  bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
            type="button"
          >
            Download sample
          </button> */}
                </div>
                <div className="border bg-white rounded border-gray-200 shadow">
                  <div className="flex-1 p-5">
                    <div className="col-span-12 sm:col-span-12">
                      <form
                        onSubmit={(e) => {
                          sendNoti();
                          e.preventDefault();
                        }}
                      >
                        <div className="flex flex-wrap -mx-3 mb-2">
                          <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <div className="relative">
                              <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="description"
                              >
                                Account Name
                              </label>
                              <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="title"
                                name="title"
                                type="text"
                                placeholder="Account Name"
                                maxLength={30}
                                onChange={(e: any) => {
                                  setnewBankDetails({
                                    ...newbankDetails,
                                    name: e.target.value,
                                  });
                                }}
                                value={newbankDetails.name}
                              />
                            </div>
                          </div>
                          <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <div className="relative">
                              <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="description"
                              >
                                Account Number
                              </label>
                              <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="title"
                                name="title"
                                type="text"
                                placeholder="Account Number"
                                maxLength={30}
                                onChange={(e: any) => {
                                  setnewBankDetails({
                                    ...newbankDetails,
                                    accountNo: e.target.value,
                                  });
                                }}
                                value={newbankDetails.accountNo}
                              />
                            </div>
                          </div>
                          <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <div className="relative">
                              <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="description"
                              >
                                IFSC Code
                              </label>
                              <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="title"
                                name="title"
                                type="text"
                                placeholder="IFSC Code"
                                maxLength={30}
                                onChange={(e: any) => {
                                  setnewBankDetails({
                                    ...newbankDetails,
                                    ifsc: e.target.value,
                                  });
                                }}
                                value={newbankDetails.ifsc}
                              />
                            </div>
                          </div>
                          <div className="pb-3 w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <div className="relative">
                              <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="description"
                              >
                                Bank Name
                              </label>
                              <input
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                id="title"
                                name="title"
                                type="text"
                                placeholder="Bank Name"
                                maxLength={30}
                                onChange={(e: any) => {
                                  setnewBankDetails({
                                    ...newbankDetails,
                                    bank: e.target.value,
                                  });
                                }}
                                value={newbankDetails.bank}
                              />
                            </div>
                          </div>

                          <div className="flex w-full justify-end px-3 mb-6 md:mb-0">
                            <div className="my-3">
                              <input
                                className={`${
                                  isVerifyDisabled
                                    ? `text-white bg-purple-300`
                                    : `text-white bg-purple-950`
                                } background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2`}
                                type="submit"
                                value="Send"
                                disabled={isVerifyDisabled}
                                // onClick={() => handleSubmit()}
                              />
                            </div>
                            <div className="my-3">
                              <input
                                className=" bg-grey background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                                type="button"
                                value="Cancel"
                                onClick={() => onCloseModal()}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditbankModal;
