import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Layout from "../Layout/Layout";
import { GetAllReports, DownloadReport, DownloadReportNew } from "./s3Bucket.Service";

import "../Metal/react-confirm-alert.css";
import {useAuthStore} from "../../Store/AuthStore";
import HasAnyAccess from "../../Components-ui/hasAnyAccess";
import Loader from "../../Components-ui/Loader";

const BarListReport: React.FC = () => {
  const [tableData, setTableData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const {warehouses, permissions} = useAuthStore();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      let data: any = await GetAllReports(warehouses, permissions);
      var filteredItems = data.data.data.filter(function (item: any) {
        return item.Size > 0;
      });
      setTableData(filteredItems);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Something Went Wrong!");
    }
  };

  const dowloadFile = async (Key: string) => {
    try {
      // let data: any = await DownloadReport(Key);

      let rawData: any = await DownloadReportNew(Key);
      const data = await rawData.blob();

      let url = window.URL.createObjectURL(data)
      window.open(url, '_blank');
      const link: any = document.createElement('a');
      link.href = url;

      link.setAttribute(
        'download',
        `${Key.split('/')[1]}`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {
      toast.error("Something Went Wrong!");
    }
  };

  return (
    <Layout type={"Reports"}>
      <ToastContainer />
      <div className="flex">
        <h5 className=" font-bold tracking-tight text-gray-600 sm:text-xl">
          Daily Barlist Reports
        </h5>
      </div>
      <br />
      <div className="overflow-x-auto">
        <div className="w-full">
          {loading ? (<Loader/>) : ( <div className=" shadow-md rounded-lg my-6">
            <table className="min-w-max w-full table-fixed rounded-lg">
              <thead>
                <tr className="sticky top-0 px-6 py-3 text-green-900 bg-purple-300">
                  <th className="py-3 px-6 text-left text-xs ">Report name</th>
                  <th className="py-3 px-6 text-left text-xs ">Vault Name</th>
                  <th className="py-3 px-6 text-left text-xs ">Metal Code</th>
                  <th className="py-3 px-6 text-left text-xs ">
                    Last Modified date
                  </th>
                  {
                    HasAnyAccess(['daily.barlist.report.download']) && (
                  <th className="py-3 px-6 text-left text-xs ">Actions</th>)}
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-light">
                {tableData &&
                  tableData.map((item: any) => {
                    return (
                      <tr
                        key={item.Key}
                        className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
                      >
                        <td className="py-3 px-6 text-left text-xs ">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Key.split("reports/")[1]}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs ">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Key.split("_")[2].replace('.pdf', "")}
                          </span>
                        </td>
                        <td className="py-3 px-6 text-left text-xs ">
                          <span className="font-semibold whitespace-normal break-all">
                            {item.Key.split("_")[1]}
                          </span>
                        </td>
                        <td className=" py-3 px-6 text-left text-xs ">
                          <span className="font-medium whitespace-normal break-all">
                            {new Date(item.LastModified).toUTCString()}
                          </span>
                        </td>
                        {
                          HasAnyAccess(['daily.barlist.report.download']) && (
                        <td>
                          <div className="py-3 px-6 text-left flex item-center">
                            <div
                              className="w-4 mr-2 transform hover:text-purple-500  cursor-pointer"
                              onClick={() => {
                                dowloadFile(item.Key);
                              }}
                            >
                              Download Report
                            </div>
                          </div>
                        </td>
                          )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>)}
        </div>
      </div>
    </Layout>
  );
};

export default BarListReport;
