import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import ErrorToolTip from "../../Metal/ErrorToolTip";

interface PropsType {
  subItems: any;
  item: any;
  onSubItemChange: any;
  onAddItems: any;
  onRemoveItem: any;
  subRuleErrors: any;
  subRuleErrorsEdit: any;
  onEditItem: any;
  onEditSubmitItem: any;
  isView: any;
  type: any;
}

const SubItemList: React.FC<PropsType> = ({
  subItems,
  item,
  onSubItemChange,
  onAddItems,
  onRemoveItem,
  subRuleErrors,
  subRuleErrorsEdit,
  onEditItem,
  onEditSubmitItem,
  isView,
  type,
}) => {
  const [ActiveColSubRule, setActiveColSubRule] = useState<string>("");

  /**
   * Create new roe for sub rules
   */
  const createNewRow = () => {
    return (
      <tr
        key={0}
        className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
      >
        <td className="py-3 px-3 text-left relative">
          <div>
            <input
              onMouseLeave={(e) => setActiveColSubRule("")}
              onMouseOver={(e) => setActiveColSubRule("lowerLimit")}
              type="number"
              name="lowerLimit"
              id="lowerLimit"
              min={
                subItems?.length > 0
                  ? subItems[subItems?.length - 1]?.upperLimit
                  : 0
              }
              value={item?.lowerLimit}
              disabled={
                subItems?.length > 0 &&
                subItems[subItems?.length - 1]?.upperLimit === ""
              }
              placeholder="Lower Limit"
              className={`mt-1 block w-full py-2 px-3 border border-gray-300 ${
                subItems?.length > 0 &&
                subItems[subItems?.length - 1]?.upperLimit === ""
                  ? "bg-gray-200"
                  : "bg-white"
              } rounded-md 
                            shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                            ${
                              subRuleErrors[type]?.lowerLimit &&
                              "border-red-600 focus:border-red-600"
                            }`}
              onChange={(e) => {
                onSubItemChange(e, type);
              }}
            />

            <ErrorToolTip
              error={subRuleErrors[type]?.lowerLimit}
              count={1}
              type="lowerLimit"
              active={ActiveColSubRule}
            />
          </div>
        </td>
        <td className="py-3 px-3 text-left relative">
          <div>
            <input
              onMouseLeave={(e) => setActiveColSubRule("")}
              onMouseOver={(e) => setActiveColSubRule("upperLimit")}
              type="number"
              name="upperLimit"
              id="upperLimit"
              min={(Number(item?.lowerLimit) + 1).toString()}
              value={item?.upperLimit}
              disabled={
                subItems?.length > 0 &&
                subItems[subItems?.length - 1]?.upperLimit === ""
              }
              placeholder="Upper Limit"
              className={`mt-1 block w-full py-2 px-3 border border-gray-300 ${
                subItems?.length > 0 &&
                subItems[subItems?.length - 1]?.upperLimit === ""
                  ? "bg-gray-200"
                  : "bg-white"
              } rounded-md 
                            shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                            ${
                              subRuleErrors[type]?.upperLimit &&
                              "border-red-600 focus:border-red-600"
                            }`}
              onChange={(e) => {
                onSubItemChange(e, type);
              }}
            />
            <ErrorToolTip
              error={subRuleErrors[type]?.upperLimit}
              count={1}
              type="upperLimit"
              active={ActiveColSubRule}
            />
          </div>
        </td>
        <td className="py-3 px-3 text-left relative">
          <div>
            <input
              onMouseLeave={(e) => setActiveColSubRule("")}
              onMouseOver={(e) => setActiveColSubRule("issueValue")}
              type="number"
              name="issueValue"
              id="issueValue"
              // min="0"
              min={item?.lowerLimit}
              max={item?.upperLimit}
              value={item?.issueValue}
              disabled={
                subItems?.length > 0 &&
                subItems[subItems?.length - 1]?.upperLimit === ""
              }
              placeholder="Issue Value"
              className={`mt-1 block w-full py-2 px-3 border border-gray-300 ${
                subItems?.length > 0 &&
                subItems[subItems?.length - 1]?.upperLimit === ""
                  ? "bg-gray-200"
                  : "bg-white"
              } rounded-md 
                            shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                            ${
                              subRuleErrors[type]?.issueValue &&
                              "border-red-600 focus:border-red-600"
                            }`}
              onChange={(e) => {
                onSubItemChange(e, type);
              }}
            />
            <ErrorToolTip
              error={subRuleErrors[type].issueValue}
              count={1}
              type="issueValue"
              active={ActiveColSubRule}
            />
          </div>
        </td>
        <td>
          <button
            className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
            type="button"
            onClick={() => onAddItems(type)}
            disabled={
              subItems?.length > 0 &&
              subItems[subItems?.length - 1]?.upperLimit === ""
            }
          >
            Add
          </button>
        </td>
      </tr>
    );
  };

  /**
   * Render added sub rules
   * @param itemDetails
   * @param index
   */
  const renderTableRow = (itemDetails: any, index: number) => {
    return (
      <tr
        key={index + 1}
        className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100"
      >
        <td className="py-3 px-3  relative">
          {/* {
                        itemDetails.isEdit ? (
                            <div>
                                <input
                                    onMouseLeave={(e) => setActiveColSubRule("")}
                                    onMouseOver={(e) => setActiveColSubRule("lowerLimitEdit")}
                                    type="number"
                                    name="lowerLimitEdit"
                                    id="lowerLimitEdit"
                                    min={0}
                                    value={itemDetails.lowerLimit}
                                    placeholder="Lower Limit"
                                    className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md
                            shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                            ${subRuleErrorsEdit.lowerLimit && "border-red-600 focus:border-red-600"}`}
                                    onChange={(e) => {
                                        onSubItemChange(e, itemDetails, index)
                                    }}
                                />
                                <ErrorToolTip
                                    error={subRuleErrorsEdit.lowerLimit}
                                    count={1}
                                    type={"lowerLimitEdit"}
                                    active={ActiveColSubRule}
                                />
                            </div>
                        ) : itemDetails.lowerLimit
                    }*/}
          {itemDetails?.lowerLimit}
        </td>
        <td className="py-3 px-3  relative">
          {itemDetails?.isEdit ? (
            <div>
              <input
                onMouseLeave={(e) => setActiveColSubRule("")}
                onMouseOver={(e) => setActiveColSubRule("upperLimitEdit")}
                type="number"
                name="upperLimitEdit"
                id="upperLimitEdit"
                min={(Number(itemDetails?.lowerLimit) + 1).toString()}
                value={
                  itemDetails?.upperLimit.toString() === "-1.0000"
                    ? ""
                    : itemDetails?.upperLimit
                }
                placeholder="Upper Limit"
                className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md 
                            shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                            ${
                              subRuleErrorsEdit[type]?.upperLimit &&
                              "border-red-600 focus:border-red-600"
                            }`}
                onChange={(e) => {
                  onSubItemChange(e, type, itemDetails, index);
                }}
              />
              <ErrorToolTip
                error={subRuleErrorsEdit[type]?.upperLimit}
                count={1}
                type="upperLimitEdit"
                active={ActiveColSubRule}
              />
            </div>
          ) : itemDetails?.upperLimit.toString() === "-1.0000" ? (
            ""
          ) : (
            itemDetails?.upperLimit
          )}
        </td>
        <td className="py-3 px-3  relative">
          {itemDetails.isEdit ? (
            <div>
              <input
                onMouseLeave={(e) => setActiveColSubRule("")}
                onMouseOver={(e) => setActiveColSubRule("issueValueEdit")}
                type="number"
                name="issueValueEdit"
                id="issueValueEdit"
                min="0"
                value={itemDetails.issueValue}
                placeholder="Issue Value"
                className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md 
                            shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                            ${
                              subRuleErrorsEdit[type].issueValue &&
                              "border-red-600 focus:border-red-600"
                            }`}
                onChange={(e) => {
                  onSubItemChange(e, type, itemDetails, index);
                }}
              />
              <ErrorToolTip
                error={subRuleErrorsEdit[type].issueValue}
                count={1}
                type="issueValueEdit"
                active={ActiveColSubRule}
              />
            </div>
          ) : (
            itemDetails.issueValue
          )}
        </td>
        {!isView && (
          <td>
            <div className="flex py-3 px-3">
              {!itemDetails.isEdit ? (
                <button
                  type="button"
                  onClick={() => onEditItem(index, item, type)}
                  className="h-8 w-8 text-purple-500 mr-3 focus:outline-none"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                  </svg>
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => onEditSubmitItem(index, item, type)}
                  className="h-8 w-8 text-purple-500 mr-3 focus:outline-none"
                >
                  <svg
                    className="h-7 w-7 text-green-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              )}
              <button
                type="button"
                onClick={() => onRemoveItem(index, type)}
                className="h-8 w-8 text-red-500 focus:outline-none"
              >
                <svg
                  className="h-6 w-6 text-red-500"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="12" cy="12" r="10" />
                  <line x1="15" y1="9" x2="9" y2="15" />
                  <line x1="9" y1="9" x2="15" y2="15" />
                </svg>
              </button>
            </div>
          </td>
        )}
      </tr>
    );
  };

  return (
    <>
      {/* Sub Rule List */}
      <div className="border rounded border-gray-200">
        <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
          <thead>
            <tr className="text-left border-b border-gray-300">
              <th className="sticky top-0 px-3 py-2 bg-gray-800">
                <div className="flex ">
                  Lower Limit
                  <a data-tip data-for="ll_tooltip" className="z-50">
                    <svg
                      className="bg-white-500 text-white-800 fill-current h-4 w-4 rounded-lg ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                    </svg>
                  </a>
                  <div className="z-50">
                    <ReactTooltip
                      id="ll_tooltip"
                      place="top"
                      type="dark"
                      effect="solid"
                      backgroundColor="#000"
                      className="z-50"
                    >
                      {type == "AMOUNT" || type == "RATE" ? (
                        <span>Lower limit must be in ₹</span>
                      ) : (
                        <span>Lower limit must be in grams</span>
                      )}
                    </ReactTooltip>
                  </div>
                </div>
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800">
                <div className="flex ">
                  Upper Limit
                  <a data-tip data-for="ul_tooltip" className="z-50">
                    <svg
                      className="bg-white-500 text-white-800 fill-current h-4 w-4 rounded-lg ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                    </svg>
                  </a>
                  <div className="z-50">
                    <ReactTooltip
                      id="ul_tooltip"
                      place="top"
                      type="dark"
                      effect="solid"
                      backgroundColor="#000"
                      className="z-50"
                    >
                      {type == "AMOUNT" || type == "RATE" ? (
                        <span>Upper limit must be in ₹</span>
                      ) : (
                        <span>Upper limit must be in grams</span>
                      )}
                    </ReactTooltip>
                  </div>
                </div>
              </th>
              <th className="sticky top-0 px-3 py-2 bg-gray-800">
                <div className="flex ">
                  Issue Value
                  <a data-tip data-for="is_tooltip" className="z-50">
                    <svg
                      className="bg-white-500 text-white-800 fill-current h-4 w-4 rounded-lg ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                    </svg>
                  </a>
                  <div className="z-50">
                    <ReactTooltip
                      id="is_tooltip"
                      place="top"
                      type="dark"
                      effect="solid"
                      backgroundColor="#000"
                      className="z-50"
                    >
                      {type == "AMOUNT" && (
                        <span>Issue value must be in ₹</span>
                      )}
                      {type == "PRODUCT_QTY" && (
                        <span>Issue value must be in grams.</span>
                      )}
                      {type == "RATE" && (
                        <span>Issue value must be in percent (0.1-100).</span>
                      )}
                    </ReactTooltip>
                  </div>
                </div>
              </th>
              {!isView && (
                <th className="sticky top-0 px-3 py-2 bg-gray-800">Actions</th>
              )}
              {/* <th></th> */}
            </tr>
          </thead>
          <tbody className="text-gray-800 text-sm font-light">
            {!isView && createNewRow()}
            {subItems?.map((item: any, index: number) =>
              renderTableRow(item, index)
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SubItemList;
