import Http from "../../../library/http.library";
import {httpMethods} from "../../../library/enum";

class PermissionService {

    /**
     * Get all permissions
     * @param page
     * @param tokens
     * @param isPaginated
     * @param query
     */
    async getAllPermissions(page:number, tokens: any, isPaginated: boolean = true, query = "") {
        return await Http.setMethod(httpMethods.get).setUrl(`/permission?page=${page}${query}${!isPaginated ? `&isPaginated=false` : ``}`).setToken(tokens.idToken.jwtToken).request();

    }

    /**
     * Add new permission
     * @param data
     * @param tokens
     */
    async addPermission(data: any, tokens: any) {
        return await Http.setMethod(httpMethods.post).setUrl(`/permission`).setData([data]).setToken(tokens.idToken.jwtToken).request();

    }

    /**
     * Update permission details
     * @param data
     * @param tokens
     */
    async updatePermission(data: any, tokens: any) {
        return Http.setMethod(httpMethods.patch).setUrl(`/permission/${data.id}`).setData(data).setToken(tokens.idToken.jwtToken).request();

    }

    /**
     * Remove permission
     * @param id
     * @param tokens
     */
    async removePermission(id: number, tokens: any) {
        return Http.setMethod(httpMethods.delete).setUrl(`/permission/${id}`).setToken(tokens.idToken.jwtToken).request();
    }

}

export default new PermissionService();
