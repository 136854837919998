import React, { useState, useEffect } from "react";
import { useAuthStore } from "../../../Store/AuthStore";
import Layout from "../../Layout/Layout";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import moment from "moment";
import { Pagination } from "../../../Components-ui/Pagination";
import { useHistory } from "react-router-dom";
import PartnerService from "../PartnerService";
import Modal from "../../../Components-ui/Modal/Modal";
import SimpleReactValidator from "simple-react-validator";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";

const Partner: React.FC = () => {
  const history = useHistory();
  const [meta, setMetaData] = useState<any>({});
  const [Loading, setLoading] = useState<any>(true);
  const [partners, setPartnerList] = useState<any>([]);
  const [apiKeys, setApiKeyList] = useState<any>([]);
  const [ftpAccounts, setFtpAccountList] = useState<any>([]);
  const [show, setModalStatus] = useState<any>(false);
  const [showApiKeyModal, setShowApiKeyModal] = useState<any>(false);
  const [showFtpModal, setShowFtpModal] = useState<any>(false);
  const [updateStatus, setUpdateStatus] = useState<any>("");
  const [children, setChildren] = useState<any>("");
  const [status, setStatus] = useState<any>("ADD");
  const [creditLimit, setCreditLimit] = useState<any>();
  const [creditLimitId, setCreditLimitId] = useState<any>(0);
  const [selectedPartner, setSelectedPartner] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<any>(0);
  const [checked, setChecked] = useState<any>(false);
  const [filters, setFilters] = useState<any>({
    isPaginated: true,
    name: "",
    status: "",
    email: "",
    panCard: "",
    mobile: "",
  });
  const { logout, permissions, userData, tokens } = useAuthStore();
  const [validatorStatus, setValidator] = useState<boolean>(false);

  const simpleValidator = new SimpleReactValidator({
    locale: "en",
    validators: {},
  });
  const validationRef = React.useRef(simpleValidator);
  const validator = validationRef.current;

  const hideModal = () => {
    setModalStatus(false);
    setShowApiKeyModal(false);
    setShowFtpModal(false);
    setChecked(false);
    setCreditLimit(0);
    setChildren("");
  };

  const getPartnerList = async (page: number) => {
    await PartnerService.getPartnerList(tokens, page)
      .then((response: any) => {
        setLoading(false);
        setPartnerList(response.data.data);
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPage(page);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.data.errors[0].title);
      });
  };

  const updatePartner = async () => {
    setValidator(true);
    if (validator.allValid() || "STATUS" === updateStatus) {
      await PartnerService.UpdatePartner(selectedPartner, tokens)
        .then((response: any) => {
          setLoading(false);
          setModalStatus(false);
          setUpdateStatus("");
          setCreditLimit(0);
          if ("STATUS" !== updateStatus) {
            updateCreditLimit();
          } else {
            getPartnerList(currentPage);
          }
          toast.success("Updated Successfully");
        })
        .catch((err) => {
          console.log("updatePartner // err", err);

          setLoading(false);
          toast.error(err.data.errors[0].title);
        });
      setValidator(false);
    }
  };

  const addCreditLimit = async (id: any) => {
    var data = {
      id: id,
      dailySellLimit: checked ? -1 : creditLimit,
    };
    await PartnerService.addCreditLimit(data, tokens)
      .then((response: any) => {
        setLoading(false);
        setModalStatus(false);
        setUpdateStatus("");
        setCreditLimit(0);
        getPartnerList(0);
        toast.success("Record Added Successfully");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.data.errors[0].title);
      });
  };

  const updateCreditLimit = async () => {
    if (creditLimitId) {
      var data = {
        id: creditLimitId,
        partnerId: selectedPartner.partnerId,
        dailySellLimit: checked ? -1 : creditLimit,
      };
      await PartnerService.updateCreditLimit(data, tokens)
        .then((response: any) => {
          setLoading(false);
          setModalStatus(false);
          setUpdateStatus("");
          getPartnerList(0);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.data.errors[0].title);
        });
    } else {
      addCreditLimit(selectedPartner.partnerId);
    }
  };

  const changeStatus = (status: any, key: any) => {
    setUpdateStatus("STATUS");
    let newArr = [...partners];
    newArr[key].status = "ENABLED" !== status ? "ENABLED" : "DISABLED";
    setPartnerList(newArr);
    setSelectedPartner(newArr[key]);
  };

  const changeStatusNew = async(status: string, key: any,id:any) => {
    
    if(status == "DISABLED"){
      status = "ENABLED"
    }else{
      status = "DISABLED"
    }
    const data = {status,id}
    await PartnerService.UpdatePartnerStatus(data, tokens)
      .then((response: any) => {
          if(response.data.data.success){
          toast.success(response.data.data.message);
           getPartnerList(currentPage)
          }else{
          toast.error(response.data.data.message);
        }
      })
      .catch((err) => {
        toast.error("Error");
      });
    
  };

  const changeApiStatus = (data: any, key: any) => {
    let newArr = [...apiKeys];
    newArr[key].status = "ENABLED" !== data.status ? "ENABLED" : "DISABLED";
    updateApiKey(newArr[key]);
    setApiKeyList(newArr);
  };

  const changeFtpStatus = async (data: any, key: any) => {
    let newArr = [...ftpAccounts];
    newArr[key].status = "ENABLED" !== data.status ? "ENABLED" : "DISABLED";
    await updateFtpAccount(newArr[key]);
    console.log(newArr);
    setFtpAccountList(newArr);
    childrenElementsApiModal();
  };

  useEffect(() => {
    childrenElements();
  }, [selectedPartner, status, creditLimit]);

  useEffect(() => {
    if (selectedPartner && "STATUS" === updateStatus) {
      updatePartner();
    }
  }, [updateStatus, status, selectedPartner]);

  useEffect(() => {
    childrenElements();
  }, [validatorStatus]);

  useEffect(() => {
    childrenElementsApiModal();
  }, [apiKeys]);

  useEffect(() => {
    childrenElementsFtpModal();
  }, [ftpAccounts]);

  useEffect(() => {
    childrenElements();
  }, [checked]);

  const getCreditLimit = async (id: any) => {
    await PartnerService.getCreditLimit(id, tokens)
      .then((response: any) => {
        setCreditLimit(response.data.data?.dailySellLimit);
        setCreditLimitId(response.data.data?.id);
        setChecked(-1 === Number(response.data.data?.dailySellLimit));
        selectedPartner.creditLimit = response.data.data?.dailySellLimit;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.data.errors[0].title);
      });
  };

  const editModalElements = (partner: any) => {
    setUpdateStatus("EDIT");
    setStatus("EDIT");
    getCreditLimit(partner.partnerId);
    childrenElements();
    setSelectedPartner(partner);
    setModalStatus(true);
  };

  const addModalElements = () => {
    setCreditLimit(0);
    setValidator(false);
    setChecked(false);
    setSelectedPartner({
      name: "",
      creditLimit: "",
      active: "ENABLED",
      email: "",
    });
    setStatus("ADD");
    childrenElements();
    setModalStatus(true);
  };

  const apiKeyGenerateModalElements = (partner: any) => {
    setLoading(true);
    getApiKeysById(partner.partnerId);
    childrenElementsApiModal();
    setShowApiKeyModal(true);
    setSelectedPartner(partner);
  };

  const ftpAccountModalElements = (partner: any) => {
    setLoading(true);
    getFtpAccountsByPartner(partner.partnerId);
    childrenElementsFtpModal();
    setShowFtpModal(true);
    setSelectedPartner(partner);
  };

  const getValidatorError = (nameOfStateProp: any) => {
    const allErrorMessages = validator.getErrorMessages();
    return allErrorMessages[nameOfStateProp];
  };

  const childrenElements = () => {
    setChildren(
      <div className="w-full max-w-xs">
        <h1 className="font-bold">
          {"ADD" === status ? "ADD Partner" : "EDIT Partner"}
        </h1>
        <form
          className="bg-white rounded pt-6 pb-8 mb-4"
          onSubmit={handleFormSubmit}
        >
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Name
            </label>
            <input
              className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
              id="name"
              name="name"
              type="text"
              placeholder="Name"
              onChange={(event) =>
                setSelectedPartner({
                  ...selectedPartner,
                  name: event.target.value,
                })
              }
              value={selectedPartner.name || ""}
              autoComplete="off"
            />
            {validator.message("name", selectedPartner.name, "required")}
            {validatorStatus ? (
              <span className="text-xs text-red-700" id="name">
                {getValidatorError("name")}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Contact Person
            </label>
            <input
              className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
              id="contactPerson"
              name="contactPerson"
              type="text"
              placeholder="Contact Person"
              onChange={(event) =>
                setSelectedPartner({
                  ...selectedPartner,
                  contactPerson: event.target.value,
                })
              }
              value={selectedPartner.contactPerson || ""}
              autoComplete="off"
            />
            {validator.message(
              "contactPerson",
              selectedPartner.contactPerson,
              "required"
            )}
            {validatorStatus ? (
              <span className="text-xs text-red-700" id="contactPerson">
                {getValidatorError("contactPerson")}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Company PAN
            </label>
            <input
              className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
              id="panCard"
              name="panCard"
              type="text"
              placeholder="Name"
              onChange={(event) =>
                setSelectedPartner({
                  ...selectedPartner,
                  panCard: event.target.value,
                })
              }
              value={selectedPartner.panCard || ""}
              autoComplete="off"
            />
            {validator.message("panCard", selectedPartner.panCard, "required")}
            {validatorStatus ? (
              <span className="text-xs text-red-700" id="panCard">
                {getValidatorError("panCard")}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Credit Limit
            </label>
            <div className="justify-between flex">
              <div className="md:w-1/2">
                <input
                  className="m-3"
                  type="checkbox"
                  onChange={(event) => {
                    setChecked(!checked);
                    setCreditLimit(0);
                  }}
                  value={!checked ? -1 : 0}
                  checked={checked}
                  id="not-expired"
                  name="credit-Limit"
                />
                <label htmlFor="not-expired"> Unlimited</label>
              </div>
              <div className="md:w-1/2">
                <input
                  disabled={checked}
                  className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                  id="name"
                  name="credit-Limit"
                  min={0}
                  type="number"
                  placeholder="Credit Limit"
                  onChange={(event) => setCreditLimit(event.target.value)}
                  value={!checked ? creditLimit : ""}
                  autoComplete="off"
                  onKeyPress={(e: any) => {
                    if (e.code === "Minus") {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            {validator.message(
              "credit-Limit",
              creditLimit,
              !checked ? "required|numeric|min:0,num" : "required"
            )}
            {validatorStatus ? (
              <span className="text-xs text-red-700" id="name">
                {getValidatorError("credit-Limit")}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Email
            </label>
            <input
              className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
              id="email"
              type="email"
              name="email"
              placeholder="Email"
              onChange={(event) =>
                setSelectedPartner({
                  ...selectedPartner,
                  email: event.target.value,
                })
              }
              value={selectedPartner.email || ""}
              autoComplete="off"
            />
            {validator.message(
              "email",
              selectedPartner.email,
              "required|email"
            )}
            {validatorStatus ? (
              <span className="text-xs text-red-700" id="name">
                {getValidatorError("email")}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Mobile
            </label>
            <input
              className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
              id="mobile"
              type="mobile"
              name="mobile"
              placeholder="Mobile"
              onChange={(event) =>
                setSelectedPartner({
                  ...selectedPartner,
                  mobile: event.target.value,
                })
              }
              value={selectedPartner.mobile || ""}
              autoComplete="off"
            />
            {validator.message("mobile", selectedPartner.mobile, "required")}
            {validatorStatus ? (
              <span className="text-xs text-red-700" id="name">
                {getValidatorError("mobile")}
              </span>
            ) : (
              ""
            )}
          </div>


          <div className="flex items-center justify-between flex-row-reverse">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={() => {
                if ("ADD" === status) {
                  addNew();
                } else {
                  updatePartner();
                }
              }}
            >
              {"ADD" === status ? "SAVE" : "UPDATE"}
            </button>
          </div>
        </form>
      </div>
    );
  };

  const childrenElementsApiModal = () => {
    setChildren(
      <div className="w-full">
        {/* {console.log("dddd", moment(apiKeys[0]?.expiredAt).diff(moment(new Date(2023,6,30)))/(1000 * 60 * 60 * 24))} */}
      {apiKeys.length<1 ||
      moment(apiKeys[0]?.expiredAt).diff(moment(new Date()))/(1000 * 60 * 60 * 24) <= 15
      ?
          <fieldset className="border rounded p-1 border-gray-300 pb-4 flex mt-3">
            <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
              {"Generate New Key"}
            </legend>
            <div className="w-full">
              <div className="border border-dashed border-gray-400 p-5 text-center m-5">
                <button
                  className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    generateKey();
                  }}
                >
                  Generate New Api Key
                </button>
              </div>
            </div>
      </fieldset>:null
      }
        <fieldset className="border rounded p-1 border-gray-300 pb-4 flex mt-3">
          <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
            {"Existing Api Keys"}
          </legend>
          <div className="w-full">
            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
              <thead>
                <tr className="text-left border-b border-gray-300 text-truncate">
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Id
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Api Key
                  </th>

                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Secret Key
                  </th>

                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Created Date
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Expiry Date
                  </th>
                  {permissions.filter(
                    (permission: any) =>
                      permission.permissionCode === "partner.list.edit"
                  ).length > 0 && (
                    <>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                        Status
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {0 !== apiKeys.length ? (
                  apiKeys.map((partner: any, index: number) => {
                    return (
                      <tr
                        key={index}
                        className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                      >
                        <td className="py-3 px-3 text-left relative">
                          {partner.id}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {partner.apiKey}
                        </td>

                        <td className="py-3 px-3 text-left relative">
                          {partner.secretKey}
                        </td>

                        <td className="py-3 px-3 text-left relative">
                          {moment(partner.createdAt).format("DD-MM-YYYY HH:mm")}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {moment(partner.expiredAt).format("DD-MM-YYYY HH:mm")}
                        </td>
                        {permissions.filter(
                          (permission: any) =>
                            permission.permissionCode === "partner.list.edit"
                        ).length > 0 && (
                          <td className="py-3 px-3 text-left relative">
                            <div
                              className="flex justify-between items-center"
                              onClick={() => {
                                changeApiStatus(partner, index);
                              }}
                            >
                              <div
                                className={`w-14 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out ${
                                  "ENABLED" === partner.active
                                    ? "bg-green-400"
                                    : ""
                                }`}
                              >
                                <div
                                  className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${
                                    "ENABLED" === partner.active
                                      ? "translate-x-6"
                                      : ""
                                  }`}
                                ></div>
                              </div>
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                    <td
                      colSpan={7}
                      className="text-center py-3 px-3 text-left relative"
                    >
                      No data to display
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </fieldset>
      </div>
    );
  };

  const childrenElementsFtpModal = () => {
    setChildren(
      <div className="w-full">
        <fieldset className="border rounded p-1 border-gray-300 pb-4 flex mt-3">
          <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
            {"Generate New FTP Account"}
          </legend>
          <div className="w-full">
            <div className="border border-dashed border-gray-400 p-5 text-center m-5">
              <button
                className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  generateFtpAccount();
                }}
              >
                Generate New FTP Account
              </button>
            </div>
          </div>
        </fieldset>
        <fieldset className="border rounded p-1 border-gray-300 pb-4 flex mt-3">
          <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
            {"Existing FTP Accounts"}
          </legend>
          <div className="w-full">
            <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
              <thead>
                <tr className="text-left border-b border-gray-300 text-truncate">
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Id
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Type
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    User Id
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    FTP Username
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    FTP Path
                  </th>
                  <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                    Created Date
                  </th>
                  {permissions.filter(
                    (permission: any) =>
                      permission.permissionCode === "partner.list.edit"
                  ).length > 0 && (
                    <>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                        Status
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {0 !== ftpAccounts.length ? (
                  ftpAccounts.map((partner: any, index: number) => {
                    return (
                      <tr
                        key={index}
                        className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                      >
                        <td className="py-3 px-3 text-left relative">
                          {partner.id}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {partner.type}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {partner.userId}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {partner.ftpUsername}
                        </td>
                        <td className="py-3 px-3 text-left relative">
                          {partner.ftpPath}
                        </td>

                        <td className="py-3 px-3 text-left relative">
                          {moment(partner.createdAt).format("DD-MM-YYYY HH:mm")}
                        </td>
                        {permissions.filter(
                          (permission: any) =>
                            permission.permissionCode === "partner.list.edit"
                        ).length > 0 && (
                          <td className="py-3 px-3 text-left relative">
                            <div
                              className="flex justify-between items-center"
                              onClick={() => {
                                changeFtpStatus(partner, index);
                              }}
                            >
                              <div
                                className={`w-14 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out ${
                                  "ENABLED" === partner.status
                                    ? "bg-green-400"
                                    : ""
                                }`}
                              >
                                <div
                                  className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${
                                    "ENABLED" === partner.status
                                      ? "translate-x-6"
                                      : ""
                                  }`}
                                ></div>
                              </div>
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                    <td
                      colSpan={7}
                      className="text-center py-3 px-3 text-left relative"
                    >
                      No data to display
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </fieldset>
      </div>
    );
  };

  const handleFormSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (validator.allValid()) {
    } else {
      setValidator(true);
      validator.showMessages();
    }
  };

  const addNew = async () => {
    setValidator(true);
    console.log(
      "addNew // selectedPartner",
      selectedPartner,
      validator.getErrorMessages()
    );

    if (validator.allValid()) {
      await PartnerService.addNewPartner(selectedPartner, tokens)

        .then((response: any) => {
          addCreditLimit(response.data.data.partnerId);
        })
        .catch((err) => {
          console.log("addNew // err", err);
          setModalStatus(false);

          toast.error(err.data.errors[0].title);
        });
      setValidator(false);
    }
  };

  const getApiKeysById = async (id: any) => {
    await PartnerService.getApiKeysById(id, tokens)
      .then((response: any) => {
        setApiKeyList(response.data.data.result);
        setLoading(false);
      })
      .catch((err) => {
        setModalStatus(false);
        toast.error(err.response.data.errors[0].title);
      });
  };

  const getFtpAccountsByPartner = async (id: any) => {
    await PartnerService.getFtpAccountsByPartner(id, tokens)
      .then((response: any) => {
        setFtpAccountList(response.data.data.result);
        setLoading(false);
      })
      .catch((err) => {
        setModalStatus(false);
        toast.error(err.response.data.errors[0].title);
      });
  };

  const generateKey = async () => {
    await PartnerService.generateApiKey(selectedPartner.partnerId, tokens)
      .then((response: any) => {
        getApiKeysById(selectedPartner.partnerId);
        toast.success("API Key has been generated!");
      })
      .catch((err) => {
        setModalStatus(false);
        toast.error(err.data.errors[0].title);
      });
  };

  const generateFtpAccount = async () => {
    await PartnerService.generateFtpAccoount(selectedPartner.partnerId, tokens)
      .then((response: any) => {
        getFtpAccountsByPartner(selectedPartner.partnerId);
        toast.success("FTP Account has been generated!");
      })
      .catch((err) => {
        setModalStatus(false);
        toast.error(err?.data?.errors[0]?.title);
      });
  };

  const updateApiKey = async (partner: any) => {
    await PartnerService.updateApiKey(partner, tokens)
      .then((response: any) => {
        getApiKeysById(partner.partnerId);
        toast.success("API Key has been Updated!");
      })
      .catch((err) => {
        setModalStatus(false);
        toast.error(err.response.data.errors[0].title);
      });
  };

  const updateFtpAccount = async (partner: any) => {
    await PartnerService.updateFtpAccount(partner, tokens)
      .then((response: any) => {
        getFtpAccountsByPartner(partner.partnerId);
        toast.success("FTP account has been Updated!");
      })
      .catch((err) => {
        setModalStatus(false);
        toast.error(err.response.data.errors[0].title);
      });
  };

  const filter = async (page: number) => {
    setLoading(true);
    await PartnerService.filterPartner(filters, page, tokens)
      .then((response: any) => {
        setPartnerList(response.data.data);
        setLoading(false);
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPage(page);
      })
      .catch((err) => {
        console.log("filter // err", err);

        setLoading(false);
        toast.error(err.response.data.errors[0].title);
      });
  };

  const resetForm = async () => {
    setFilters({
      isPaginated: true,
      name: "",
      status: "",
      email: "",
      panCard: "",
      mobile: "",
    });
    getPartnerList(0);
  };

  useEffect(() => {
    getPartnerList(0);
  }, []);

  const paginationFilter = async (page: number) => {
    setCurrentPage(page);
    await filter(page);
  };

  return (
    <Layout type={"UserModule"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">{"Partners"}</h1>
        {HasAnyAccess(["partner.list.create"]) && (
          <button
            className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
            type="button"
            onClick={() => {
              addModalElements();
            }}
          >
            + Add Partner
          </button>
        )}
      </div>

      <div className="border bg-white rounded border-gray-200 shadow">
        <div className="flex-1 p-2">
           <form
          onSubmit={(e) => {
            filter(0)
            e.preventDefault();
          }}
        >
          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="partner-name"
              >
                Partner Name
              </label>
              <input
                className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                name="partner-name"
                id="partner-name"
                type="text"
                placeholder="Partner Name"
                autoComplete="none"
                onChange={(event) =>
                  setFilters({
                    ...filters,
                    name: event.target.value,
                  })
                }
                value={filters.name}
              />
            </div>
            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="partner-name"
              >
                Partner PAN
              </label>
              <input
                className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                name="partner-pan"
                id="partner-pan"
                type="text"
                placeholder="Partner PAN"
                autoComplete="none"
                onChange={(event) =>
                  setFilters({
                    ...filters,
                    panCard: event.target.value,
                  })
                }
                value={filters.panCard}
              />
            </div>

            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="partner-name"
              >
                Mobile
              </label>
              <input
                className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                name="partner-mobile"
                id="partner-mobile"
                type="text"
                placeholder="Mobile Number"
                autoComplete="none"
                onChange={(event) =>
                  setFilters({
                    ...filters,
                    mobile: event.target.value,
                  })
                }
                value={filters.mobile}
              />
            </div>
            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="partner-name"
              >
                Email
              </label>
              <input
                className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                name="partner-email"
                id="partner-email"
                type="text"
                placeholder="Email"
                autoComplete="none"
                onChange={(event) =>
                  setFilters({
                    ...filters,
                    email: event.target.value,
                  })
                }
                value={filters.email}
              />
            </div>

            <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="partner-name"
              >
                Status
              </label>
              <select
                className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
                id="user-type"
                onChange={(event) =>
                  setFilters({
                    ...filters,
                    status: event.target.value,
                  })
                }
                value={filters.status}
              >
                <option value="">Select a Status</option>
                <option value="ENABLED">ENABLED</option>
                <option value="DISABLED">DISABLED</option>
              </select>
            </div>
            <div className="w-full md:w-2/4 px-3 mb-6 md:mb-0">
              <div className="md:w-1/3" />
              <div className="md:w-2/3 my-6">
                {/* <button
                  className="px-4 py-2.5 font-bold text-white text-sm uppercase bg-purple-950 rounded"
                  type="button"
                  onClick={() => {
                    filter(0);
                  }}
                >
                  Filter
                </button> */}
                 <input
                  className="px-4 py-2.5 font-bold text-white text-sm uppercase bg-purple-950 rounded"
                  type="submit"
                value="Filter"
              />
                <button
                  className="ml-4 px-4 py-2.5 font-bold text-white text-sm uppercase bg-red-600 rounded"
                  type="button"
                  onClick={() => resetForm()}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          </form>
        </div>
        <div className="border bg-white rounded border-gray-200 shadow m-2">
          {Loading ? (
            <Loader />
          ) : (
            <div>
              <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                <thead>
                  <tr className="text-left border-b border-gray-300">
                    <th className="sticky top-0 px-3 py-2 bg-gray-800">
                      Partner ID
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800">Name</th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800">
                      Email
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800">
                      Mobile
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800">PAN</th>
                    {/*<th className="sticky top-0 px-3 py-2 bg-gray-800">Credit Limit</th>*/}
                    {/* <th className="sticky top-0 px-3 py-2 bg-gray-800">
                      Created By
                    </th> */}
                    <th className="sticky top-0 px-3 py-2 bg-gray-800">
                      Created Date
                    </th>
                    {HasAnyAccess(["partner.list.edit"]) && (
                      <>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                          Status
                        </th>
                        <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                          Action
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {0 !== partners.length ? (
                    partners.map((partner: any, index: number) => {
                      return (
                        <tr
                          key={index}
                          className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                        >
                          <td className="py-3 px-3 text-left relative">
                            {partner.partnerId}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {partner.name}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {partner.email}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {partner.mobile}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {partner.panCard}
                          </td>
                          {/*<td className="py-3 px-3 text-left relative">*/}
                          {/*    {partner.name}*/}
                          {/*</td>*/}
                          {/* <td className="py-3 px-3 text-left relative">
                            {partner.createdBy}
                          </td> */}
                          <td className="py-3 px-3 text-left relative">
                            {moment(partner.createdAt).format(
                              "DD-MM-YYYY HH:mm"
                            )}
                          </td>
                          {HasAnyAccess(["partner.list.edit"]) && (
                            <td className="py-3 px-3 text-left relative">
                              {/* <div
                                className="flex justify-between items-center"
                                onClick={() => {
                                  changeStatus(partner.status, index);
                                }}
                              > */}
                              <div
                                className="flex justify-between items-center"
                                onClick={() => {
                                  changeStatusNew(partner.status, index,partner.partnerId);
                                }}
                              >
                                <div
                                  className={`w-14 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out ${
                                    "ENABLED" === partner.status
                                      ? "bg-green-400"
                                      : ""
                                  }`}
                                >
                                  <div
                                    className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${
                                      "ENABLED" === partner.status
                                        ? "translate-x-6"
                                        : ""
                                    }`}
                                  ></div>
                                </div>
                              </div>
                            </td>
                          )}
                          {/* {
                                                        HasAnyAccess(['partner.list.edit']) && (
                                                            <td className="py-3 px-3 text-left relative">
                                                            <button
                                                                className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                                type="button"
                                                                onClick={() => {
                                                                    editModalElements(partner)
                                                                }}
                                                            >
                                                                Edit
                                                            </button>
                                                            <button
                                                                className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                                                type="button"
                                                                onClick={() => {
                                                                    apiKeyGenerateModalElements(partner)
                                                                }}
                                                            >
                                                                API Key
                                                            </button>
                                                        </td>)} */}

                          <td className="py-3 px-3 text-left">
                            <div className="flex justify-center">
                              <div className="relative">
                                <button
                                  className="whitespace-nowrap items-center flex ease-in-out duration-150 transition active:bg-purple-800 active:shadow-lg active:text-white focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 hover:bg-purple-700 hover:shadow-lg shadow-md rounded dropdown-toggle px-0.5 py-0.5 bg-purple-950 text-white font-medium text-xs text-xs leading-tight"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    width="20px"
                                    fill="#FFFFFF"
                                  >
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                                  </svg>
                                </button>
                                <ul
                                  className="border-none bg-clip-padding m-0 hidden mt-1 shadow-lg rounded-lg text-left list-none py-2 float-left z-50 text-base bg-white hidden absolute dropdown-menu min-w-max"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  {" "}
                                  {HasAnyAccess(["partner.list.edit"]) && (
                                    <li>
                                      <button
                                        className="hover:bg-gray-100 text-gray-700 bg-transparent whitespace-nowrap w-full block font-normal px-4 py-2 text-sm dropdown-item"
                                        onClick={() =>
                                          editModalElements(partner)
                                        }
                                      >
                                        Edit
                                      </button>
                                    </li>
                                  )}
                                  {HasAnyAccess(["partner.list.edit"]) && (
                                    <li>
                                      <button
                                        className="hover:bg-gray-100 text-gray-700 bg-transparent whitespace-nowrap w-full block font-normal px-4 py-2 text-sm dropdown-item"
                                        onClick={() =>
                                          apiKeyGenerateModalElements(partner)
                                        }
                                      >
                                        API Key
                                      </button>
                                    </li>
                                  )}
                                  {HasAnyAccess(["partner.list.edit"]) && (
                                    <li>
                                      <button
                                        className="hover:bg-gray-100 text-gray-700 bg-transparent whitespace-nowrap w-full block font-normal px-4 py-2 text-sm dropdown-item"
                                        onClick={() =>
                                          ftpAccountModalElements(partner)
                                        }
                                      >
                                        FTP Account
                                      </button>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                      <td
                        colSpan={5}
                        className="text-center py-3 px-3 text-left relative"
                      >
                        No data to display
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Modal
                size={""}
                show={show}
                handleClose={hideModal}
                children={children}
              />
              <Modal
                size={"large"}
                show={showApiKeyModal}
                handleClose={hideModal}
                children={children}
              />
              <Modal
                size={"large"}
                show={showFtpModal}
                handleClose={hideModal}
                children={children}
              />
            </div>
          )}
          <Pagination
            meta={meta}
            handlePageChange={(number: number) => {
              paginationFilter(number);
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Partner;
