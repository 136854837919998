import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import ListOfUsers from './Components/ListOfUsers';
import SingleUser from './Components/SingleUserDetails';
import { CreateUser } from './Components/CreateUser';

const UserListRoutes:React.FC = () => {
    return (
        <Fragment>  <Route path="/users" exact component={ListOfUsers} />
            <Route path="/user/create"  exact component={CreateUser} />
            <Route path="/users/:id"  exact component={SingleUser} />
        </Fragment>
    )
};

export default UserListRoutes;