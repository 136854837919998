import React, { useState, useEffect } from "react";
import Testing from "../Services/test.service";
import { toast } from "react-toastify";
import moment from "moment";
import Layout from "../../Layout/Layout";
import { round } from "mathjs";
import { PriceFeedGetAll, PriceFeedGetAllEb } from "./pricingFeedService";
import result from "postcss/lib/result";
import { useAuthStore } from "../../../Store/AuthStore";

interface PropsType {
  // askPrice: number;
  // bitPrice: number;
}

// save previous status
const old_XAUData: any = ["val1", "val2"];
const old_XAGData: any = ["val1", "val2"];
const old_XPTData: any = ["val1", "val2"];
const old_XPDData: any = ["val1", "val2"];
const old_USDData: any = ["val1", "val2"];

// ebullion
const old_XAUDataEb: any = ["val1", "val2"];
const old_XAGDataEb: any = ["val1", "val2"];
const old_XPTDataEb: any = ["val1", "val2"];
const old_XPDDataEb: any = ["val1", "val2"];

const PricingFeed: React.FC<PropsType> = () => {
  const [XAUData, setXAUData] = useState<any>();
  const [XAGData, setXAGData] = useState<any>();
  const [XPTData, setXPTData] = useState<any>();
  const [XPDData, setXPDData] = useState<any>();
  const [USDData, setUSDData] = useState<any>();
  // ebullion
  const [XAUDataEb, setXAUDataEb] = useState<any>();
  const [XAGDataEb, setXAGDataEb] = useState<any>();
  const [XPTDataEb, setXPTDataEb] = useState<any>();
  const [XPDDataEb, setXPDDataEb] = useState<any>();

  const [syncInterval, setSyncInterval] = useState<any>(5000);
 const { logout, permissions, userData , tokens } = useAuthStore();

  // Comment websocket because we change the background

  useEffect(() => {
    let interval: any;
    let arr = ["Ag", "Au", "Pd", "Pt"];
    // ebullion
    async function getEbullionPrice() {
      for await (const metal of arr) {
        await PriceFeedGetAllEb(metal)
        // await PriceFeedGetAllEb(metal,tokens)
          .then((result: any) => {
            const fetchedData = result.data.data;
            const formatData = {
              bid: fetchedData.priceEngineData.bidPrice.rule,
              ask: fetchedData.priceEngineData.askPrice.rule,
            };
            if (metal == "Ag") {
              setXAGDataEb(formatData);
            } else if (metal == "Au") {
              setXAUDataEb(formatData);
            } else if (metal == "Pd") {
              setXPDDataEb(formatData);
            } else {
              // PT
              setXPTDataEb(formatData);
            }
          })
          .catch((err) => {
            console.log("Error! ", err);
          });
      }
    }

    async function fetchData() {
      return await new Promise((resolve) => {
        interval = setInterval(() => {
          console.log("Get feeder data!");

          PriceFeedGetAll()
            .then((result: any) => {
              console.log("Success! ", result.data.data);
              const AU = result.data.data.filter(
                (data: any) => data.metal == "Au"
              )[0];
              const AG = result.data.data.filter(
                (data: any) => data.metal == "Ag"
              )[0];
              const PD = result.data.data.filter(
                (data: any) => data.metal == "Pd"
              )[0];
              const PT = result.data.data.filter(
                (data: any) => data.metal == "Pt"
              )[0];
              const USD = result.data.data.filter(
                (data: any) => data.metal == "USD"
              )[0];
              console.log("AU: ", AU, "AG", AG, "PD", PD, "PT", PT, "USD", USD);
              fetchTableDetails(AU, AG, PT, PD, USD);
              // toast.success("Success!",result);
            })
            .catch((err) => {
              console.log("Error! ", err);
            });

          getEbullionPrice();
        }, syncInterval);
      });
    }

    fetchData();

    // try {
    //   const pricingFeedSocket: any = socketIOClient(
    //     `${process.env.REACT_APP_WEBSOCKET_URL}/admin-pricing-feed`
    //   );
    //   pricingFeedSocket.on("updated-pricing-feed", (data: any) => {
    //     data = JSON.parse(data);
    //     fetchTableDetails(data.Au, data.Ag, data.Pt, data.Pd);
    //   });
    // } catch (ex) {
    //   toast.error(ex.message || "Failed to get Price data.", {
    //     autoClose: false,
    //   });
    // }
    return () => clearInterval(interval);
  }, []);

  const fetchTableDetails = async (
    xauData: any,
    xagData: any,
    xptData: any,
    xpdData: any,
    usdData?: any
  ) => {
    // let xauData: any = await Testing.getPricingApi(1, "Au", 1, "inr"); // partnerid/metalcode/quantity/currency
    // let xagData: any = await Testing.getPricingApi(1, "Ag", 1, "inr");
    // let xptData: any = await Testing.getPricingApi(1, "Pt", 1, "inr");
    // let xpdData: any = await Testing.getPricingApi(1, "Pd", 1, "inr");

    setXAGData(xagData);
    old_XAGData.shift(); // remove element
    old_XAGData.push(xagData);

    setXAUData(xauData);
    old_XAUData.shift();
    old_XAUData.push(xauData);

    setXPTData(xptData);
    old_XPTData.shift();
    old_XPTData.push(xptData);

    setXPDData(xpdData);
    old_XPDData.shift();
    old_XPDData.push(xpdData);

    setUSDData(usdData);
    old_USDData.shift();
    old_USDData.push(usdData);
  };

  const calculateCDFSpread = (ask: number, bid: number) => {
    // return (ask - bid);
    if (!ask || !bid) {
      return "-";
    }
    let calc = round((ask - bid) * 100) / 100;
    return calc.toFixed(2);
  };

  const calcCurrencySpread = (ask: number, bid: number) => {
    // return (ask - bid);
    if (!ask || !bid) {
      return "-";
    }

    let calc = round((ask - bid) * 1000) / 1000;
    return calc.toFixed(3);
  };

  const calculateEbullionSpread = (ask: number, bid: number) => {
    if (!ask || !bid) {
      return "-";
    }
    let calc = ((ask - bid) / bid) * 100;
    let upCalc = round(calc * 100) / 100;
    return `${upCalc.toFixed(2)}%`;
  };

  const roundToTwoDecimals = (amount: number) => {
    if (!amount) {
      return "-";
    }
    let calc = round(amount * 100) / 100;
    return calc.toFixed(2);
  };

  const roundToThreeDecimals = (amount: number) => {
    if (!amount) {
      return "-";
    }
    let calc = round(amount * 1000) / 1000;
    return calc.toFixed(3);
  };

  const changeColor = (oldVal: any, newVal: any) => {
    if (parseFloat(oldVal) > parseFloat(newVal)) {
      return "red";
    }
    if (parseFloat(oldVal) == parseFloat(newVal)) {
      return "black";
    } else return "green";
  };

  return (
    <Layout type={"PricingModule"}>
      <h1 className="text-gray-600">Last Updated</h1>{" "}
      <h1 className="text-xl text-gray-800 font-bold">
        {moment().format("YYYY-MM-DD HH:mm:ss")}
      </h1>
      <div className="overflow-x-auto">
        <div className="w-full">
          <div className=" shadow-md rounded-lg my-6">
            <table className="min-w-max w-full  rounded-lg border border-gray-400">
              <thead>
                <tr className="sticky top-0 px-6 py-3  bg-indigo-300">
                  <th
                    className="py-3 px-6 text-center border-r border-indigo-400"
                    colSpan={4}
                  >
                    Price ($/Ozt)
                  </th>
                  <th className="py-3 px-6 text-center" colSpan={3}>
                    eBullion
                  </th>
                </tr>
                <tr className="sticky top-0 px-6 py-3 text-white bg-gray-800 border-gray-400">
                  <th className="py-3 px-6 text-left"></th>
                  <th className="py-3 px-6 text-right">Bid</th>
                  <th className="py-3 px-6 text-right">Ask</th>
                  <th className="py-3 px-6 text-right border-r border-gray-400">
                    Spread
                  </th>
                  <th className="py-3 px-6 text-right">Bid</th>
                  <th className="py-3 px-6 text-right">Ask</th>
                  <th className="py-3 px-6 text-right">Spread</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100 border-gray-400">
                  <td className="py-3 px-6 text-left font-bold">XAG</td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(old_XAGData?.bid, old_XAGData?.bid),
                    }}
                  >
                    {roundToTwoDecimals(XAGData?.bid)}
                  </td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(old_XAGData?.ask, old_XAGData?.ask),
                    }}
                  >
                    {roundToTwoDecimals(XAGData?.ask)}
                  </td>
                  <td
                    className="py-3 px-6 text-right border-r border-gray-400border-r border-gray-400"
                    style={{
                      color: changeColor(
                        calculateCDFSpread(
                          old_XAGData[0]?.ask,
                          old_XAGData[0]?.bid
                        ),
                        calculateCDFSpread(
                          old_XAGData[1]?.ask,
                          old_XAGData[1]?.bid
                        )
                      ),
                    }}
                  >
                    {calculateCDFSpread(XAGData?.ask, XAGData?.bid)}
                  </td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(
                        old_XAGDataEb[0].bid,
                        old_XAGDataEb[1].bid
                      ),
                    }}
                  >
                    {roundToTwoDecimals(XAGDataEb?.bid)}
                  </td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(
                        old_XAGDataEb[0].ask,
                        old_XAGDataEb[1].ask
                      ),
                    }}
                  >
                    {roundToTwoDecimals(XAGDataEb?.ask)}
                  </td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(
                        calculateEbullionSpread(
                          old_XAGDataEb[0].ask,
                          old_XAGDataEb[0].bid
                        ),
                        calculateEbullionSpread(
                          old_XAGDataEb[1].ask,
                          old_XAGDataEb[1].bid
                        )
                      ),
                    }}
                  >
                    {calculateEbullionSpread(XAGDataEb?.ask, XAGDataEb?.bid)}
                    {/* % */}
                  </td>
                </tr>
                <tr className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100 border-gray-400">
                  <td className="py-3 px-6 text-left font-bold">XAU</td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(old_XAUData?.bid, old_XAUData?.bid),
                    }}
                  >
                    {roundToTwoDecimals(XAUData?.bid)}
                  </td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(
                        old_XAUData[0]?.ask,
                        old_XAUData[1]?.ask
                      ),
                    }}
                  >
                    {roundToTwoDecimals(XAUData?.ask)}
                  </td>
                  <td
                    className="py-3 px-6 text-right border-r border-gray-400"
                    style={{
                      color: changeColor(
                        calculateCDFSpread(
                          old_XAUData[0]?.ask,
                          old_XAUData[0]?.bid
                        ),
                        calculateCDFSpread(
                          old_XAUData[1]?.ask,
                          old_XAUData[1]?.bid
                        )
                      ),
                    }}
                  >
                    {calculateCDFSpread(XAUData?.ask, XAUData?.bid)}
                  </td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(
                        old_XAUDataEb[0].bid,
                        old_XAUDataEb[1].bid
                      ),
                    }}
                  >
                    {roundToTwoDecimals(XAUDataEb?.bid)}
                  </td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(
                        old_XAUDataEb[0].ask,
                        old_XAUDataEb[1].ask
                      ),
                    }}
                  >
                    {roundToTwoDecimals(XAUDataEb?.ask)}
                  </td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(
                        calculateEbullionSpread(
                          old_XAUDataEb[0].ask,
                          old_XAUDataEb[0].bid
                        ),
                        calculateEbullionSpread(
                          old_XAUDataEb[1].ask,
                          old_XAUDataEb[1].bid
                        )
                      ),
                    }}
                  >
                    {calculateEbullionSpread(XAUDataEb?.ask, XAUDataEb?.bid)}
                    {/* % */}
                  </td>
                </tr>
                <tr className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100 border-gray-400">
                  <td className="py-3 px-6 text-left font-bold">XPT</td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(old_XPTData?.bid, old_XPTData?.bid),
                    }}
                  >
                    {roundToTwoDecimals(XPTData?.bid)}
                  </td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(old_XPTData?.ask, old_XPTData?.ask),
                    }}
                  >
                    {roundToTwoDecimals(XPTData?.ask)}
                  </td>
                  <td
                    className="py-3 px-6 text-right border-r border-gray-400"
                    style={{
                      color: changeColor(
                        calculateCDFSpread(old_XPTData?.ask, old_XPTData?.bid),
                        calculateCDFSpread(old_XPTData?.ask, old_XPTData?.bid)
                      ),
                    }}
                  >
                    {calculateCDFSpread(XPTData?.ask, XPTData?.bid)}
                  </td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(
                        old_XPTDataEb[0].bid,
                        old_XPTDataEb[1].bid
                      ),
                    }}
                  >
                    {roundToTwoDecimals(XPTDataEb?.bid)}
                  </td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(
                        old_XPTDataEb[0].ask,
                        old_XPTDataEb[1].ask
                      ),
                    }}
                  >
                    {roundToTwoDecimals(XPTDataEb?.ask)}
                  </td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(
                        calculateEbullionSpread(
                          old_XPTDataEb[0].ask,
                          old_XPTDataEb[0].bid
                        ),
                        calculateEbullionSpread(
                          old_XPTDataEb[1].ask,
                          old_XPTDataEb[1].bid
                        )
                      ),
                    }}
                  >
                    {calculateEbullionSpread(XPTDataEb?.ask, XPTDataEb?.bid)}
                    {/* % */}
                  </td>
                </tr>
                <tr className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100 border-gray-400">
                  <td className="py-3 px-6 text-left font-bold">XPD</td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(
                        old_XPDData[0]?.bid,
                        old_XPDData[1]?.bid
                      ),
                    }}
                  >
                    {roundToTwoDecimals(XPDData?.bid)}
                  </td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(
                        old_XPDData[0]?.ask,
                        old_XPDData[1]?.ask
                      ),
                    }}
                  >
                    {roundToTwoDecimals(XPDData?.ask)}
                  </td>
                  <td
                    className="py-3 px-6 text-right border-r border-gray-400"
                    style={{
                      color: changeColor(
                        calculateCDFSpread(
                          old_XPDData[0]?.ask,
                          old_XPDData[0]?.bid
                        ),
                        calculateCDFSpread(
                          old_XPDData[1]?.ask,
                          old_XPDData[1]?.bid
                        )
                      ),
                    }}
                  >
                    {calculateCDFSpread(XPDData?.ask, XPDData?.bid)}
                  </td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(
                        old_XPDDataEb[0].bid,
                        old_XPDDataEb[1].bid
                      ),
                    }}
                  >
                    {roundToTwoDecimals(XPDDataEb?.bid)}
                  </td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(
                        old_XPDDataEb[0].ask,
                        old_XPDDataEb[1].ask
                      ),
                    }}
                  >
                    {roundToTwoDecimals(XPDDataEb?.ask)}
                  </td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(
                        calculateEbullionSpread(
                          old_XPDDataEb[0].ask,
                          old_XPDDataEb[0].bid
                        ),
                        calculateEbullionSpread(
                          old_XPDDataEb[1].ask,
                          old_XPDDataEb[1].bid
                        )
                      ),
                    }}
                  >
                    {calculateEbullionSpread(XPDDataEb?.ask, XPDDataEb?.bid)}
                    {/* % */}
                  </td>
                </tr>
                <tr className="border-b border-gray-200 bg-gray-50  hover:bg-gray-100 border-gray-400">
                  <td className="py-3 px-6 text-left font-bold">USD/INR</td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(
                        old_USDData[0]?.bid,
                        old_USDData[0]?.bid
                      ),
                    }}
                  >
                    {roundToThreeDecimals(USDData?.bid)}
                  </td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(
                        old_USDData[0]?.ask,
                        old_USDData[0]?.ask
                      ),
                    }}
                  >
                    {roundToThreeDecimals(USDData?.ask)}
                  </td>
                  <td
                    className="py-3 px-6 text-right"
                    style={{
                      color: changeColor(
                        calcCurrencySpread(
                          old_USDData[0]?.ask,
                          old_USDData[0]?.bid
                        ),
                        calcCurrencySpread(
                          old_USDData[0]?.ask,
                          old_USDData[0]?.bid
                        )
                      ),
                    }}
                  >
                    {calcCurrencySpread(USDData?.ask, USDData?.bid)}
                  </td>
                  <td className="py-3 px-6 text-right"></td>
                  <td className="py-3 px-6 text-right"></td>
                  <td className="py-3 px-6 text-right"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PricingFeed;
