import React from "react";
import { Route } from "react-router-dom";

import DiscountTable from "../Components/DiscountTable";
import AddNewDiscount from "../Components/AddNewDiscount";
import EditDiscount from "../Components/EditDiscount";


export const DiscountRoutes: React.FC = () => {
  return (
    <>
      <Route path="/discount" exact component={DiscountTable} />
      <Route path="/discount/add" exact component={AddNewDiscount} />
      <Route path="/discount/update/:id" exact component={EditDiscount} />
      <Route path="/discount/update/:id/:view" exact component={EditDiscount} />
    </>
  );
};
