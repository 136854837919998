import React, {useEffect, useState} from "react";
import SimpleReactValidator from "simple-react-validator";
import {PASSWORD} from "../Consts/REGEX_CONST"
import logo from "../Images/logo2.svg";
import {useHistory} from "react-router-dom";
declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        // extends React's HTMLAttributes
        custom?: string;
        for?: string;
        autocomplete?: string
    }

}

interface PropsType {
    loginHandle: any;
    loginFaild: boolean;
    loginFailedMsg:any;
    loading:boolean;
}

export const Form: React.FC<PropsType> = ({loginHandle, loginFaild, loginFailedMsg,loading}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const history = useHistory();

    const simpleValidator = new SimpleReactValidator({
        locale: "en",
        validators: {
            text: {
                message:
                    "",
                rule: (val: any, params: any, validator: any) => {
                    return (
                        validator.helpers.testRegex(val, PASSWORD) &&
                        params.indexOf(val) === -1
                    );
                },
                messageReplace: (message: any, params: any) =>
                    message.replace(":values", validator.helpers.toSentence(params)),
            },
        },
    });

    const validationRef = React.useRef(simpleValidator);
    const validator = validationRef.current;

    const getValidatorError = (nameOfStateProp: any) => {
        const allErrorMessages = validator.getErrorMessages();
        return allErrorMessages[nameOfStateProp];
    };

    const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // setModalStatus(true);
        loginHandle(email,password);
    }

    return (
        <div className="h-full flex items-center justify-center  py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <img className="mx-auto h-12 w-auto" src={logo} alt="Workflow"/>
                    <h2 className="mt-6 text-center text-xl font-bold text-gray-900">
                        Sign In To Your eBullion Admin Account
                    </h2>
                    <p className="mt-2 text-center text-sm text-gray-600">

                        <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">

                        </a>
                    </p>
                </div>
                <form id="login-form" className="mt-8 space-y-6" onSubmit={handleLogin}>
                    <input type="hidden" name="remember" value="true"/>
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label for="email-address" className="sr-only">Email address</label>
                            <input id="email-address" value={email} onChange={event => setEmail(event.target.value)}
                                   name="email" type="email" autocomplete="email" required
                                   className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                   placeholder="Email address"/>
                        </div>
                        {validator.message(
                            "email",
                            email,
                            "email"
                        )}
                        <span>
                    {getValidatorError("email")}
                  </span>
                        <div>
                            <label for="password" className="sr-only">Password</label>
                            <input id="password" value={password} onChange={event => setPassword(event.target.value)}
                                   name="password" type="password" autocomplete="current-password" required
                                   className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                   placeholder="Password"/>
                        </div>
                        {validator.message(
                            "password",
                            password,
                            "required|text"
                        )}
                        <span>
                    {getValidatorError("password")}
                  </span>
                    </div>
                    {!!loginFaild &&
                    <span><p className='text-sm' style={{color: "red"}}>{loginFailedMsg}</p></span>}
                    <div className="flex items-center justify-end">
                        {/*<div className="flex items-center">*/}
                        {/*    <input id="remember_me" name="remember_me" type="checkbox"*/}
                        {/*           className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"/>*/}
                        {/*    <label for="remember_me" className="ml-2 block text-sm text-gray-900">*/}
                        {/*        Remember me*/}
                        {/*    </label>*/}
                        {/*</div>*/}

                        <div className="text-sm">
                            <a onClick={() =>  {
                                history.push(`/forget-password`)
                            }} href="" className="font-medium text-indigo-600 hover:text-indigo-500">
                                Forgot your password?
                            </a>
                        </div>
                    </div>

                    <div>
                        <button type="submit" disabled={loading}
                                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {loading ? (
                            <>
                                <svg role="status" className="inline w-4 h-4 mr-3 text-white animate-spin"
                                     viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="#E5E7EB"/>
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentColor"/>
                                </svg>
                            </>) : (
                                <>
                                     <span className="absolute left-0 inset-y-0 flex items-center pl-3">

            <svg className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clip-rule="evenodd"/>
            </svg>
          </span>              Sign in
                                </>
                            )
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
