import Http from "../../library/http.library";
import { httpMethods } from "../../library/enum";
import { genereteQuery, makeQuery } from "../../Common/util.helper";
import axios from "axios";

export const getPartnerList = async (tokens: any, currentPage: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/partners?isPaginated=true&page=${currentPage}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const UpdatePartner = async (data: any, tokens: any) => {
  return Http.setMethod(httpMethods.patch)
    .setUrl(`/partners`)
    .setData(data)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const UpdatePartnerStatus = async (data: any, tokens: any) => {
  return Http.setMethod(httpMethods.patch)
    .setUrl(`/partner/status`)
    .setData(data)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const filterPartner = async (
  data: any,
  currentPage: number,
  tokens: any
) => {
  let queryString = genereteQuery({ ...data, page: currentPage });
  console.log("queryString", queryString);

  var query = {
    ...(data.name ? { name: data.name } : {}),
    ...(data.active ? { active: data.active } : {}),
  };
  const params = makeQuery(query);
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/partners?${queryString}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const addNewPartner = async (data: any, tokens: any) => {
  return await Http.setMethod(httpMethods.post)
    .setUrl(`/partners`)
    .setData({
      partnerName: data.name,
      email: data.email,
      mobile: data.mobile,
      panCard: data.panCard,
      contactPerson: data.contactPerson,
    })
    .setToken(tokens.idToken.jwtToken)
    .request();
};

//credit limit
export const addCreditLimit = async (data: any, tokens: any) => {
  return await Http.setMethod(httpMethods.post)
    .setUrl(`/partner-credit`)
    .setData({
      partnerId: data.id,
      dailySellLimit: data.dailySellLimit,
    })
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const updateCreditLimit = async (data: any, tokens: any) => {
  return Http.setMethod(httpMethods.patch)
    .setUrl(`/partner-credit/${data.id}`)
    .setData({
      partnerId: data.partnerId,
      dailySellLimit: data.dailySellLimit,
    })
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getCreditLimit = async (id: any, tokens: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/partner-credit/${id}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getApiKeysById = async (id: any, tokens: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/partner/api-key?partnerId=${id}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getFtpAccountsByPartner = async (id: any, tokens: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/partner/ftp?partnerId=${id}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const generateApiKey = async (id: any, tokens: any) => {
  return await Http.setMethod(httpMethods.post)
    .setUrl(`/partner/api-key`)
    .setData({
      partnerId: id,
      active: "ENABLED",
    })
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const generateFtpAccoount = async (id: any, tokens: any) => {
  return await Http.setMethod(httpMethods.post)
    .setUrl(`/partner/ftp`)
    .setData({
      partnerId: id,
      type: "SETTLEMENT_FTP",
    })
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const updateApiKey = async (data: any, tokens: any) => {
  return await Http.setMethod(httpMethods.patch)
    .setUrl(`/partner/api-key/${data.id}`)
    .setData({
      active: data.active,
    })
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const updateFtpAccount = async (data: any, tokens: any) => {
  return await Http.setMethod(httpMethods.patch)
    .setUrl(`/partner/ftp/${data.id}`)
    .setData({
      active: data.active,
    })
    .setToken(tokens.idToken.jwtToken)
    .request();
};

const PartnerService = {
  getPartnerList,
  UpdatePartner,
  filterPartner,
  addNewPartner,
  addCreditLimit,
  updateCreditLimit,
  getCreditLimit,
  getApiKeysById,
  getFtpAccountsByPartner,
  generateApiKey,
  updateApiKey,
  updateFtpAccount,
  generateFtpAccoount,
  UpdatePartnerStatus
};

export default PartnerService;
