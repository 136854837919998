import moment from "moment";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import { useAuthStore } from "../../../Store/AuthStore";
import DashboardReportServiceHP from "../DashboardServiceHP";
import TradeSummaryReport from "./TradeSummaryReport";
import TreasurySummaryReport from "./TreasurySummaryReport";
import UsdInrTable from "./UsdInrTable";

interface PropsType {
  filters?: any;
  CustomerGroups?: any;
}

const Summary: React.FC<PropsType> = ({ filters, CustomerGroups }) => {
  const [loading, setLoading] = useState(false);
  const [isUsdInrLoading, setIsUsdInrLoading] = useState(false);
  const { tokens } = useAuthStore();
  let MetalsInitial = [
    {
      metalCode: "Ag",
      metalName: "Silver",
      tableData: [],
    },
    {
      metalCode: "Pt",
      metalName: "Platinum",
      tableData: [],
    },
    {
      metalCode: "Pd",
      metalName: "Palladium",
      tableData: [],
    },
    {
      metalCode: "Au",
      metalName: "Gold",
      tableData: [],
    },
  ];

  const [Metals, setMetals] = useState(MetalsInitial);

  useEffect(() => {
    getData();
  }, [filters]);

  const getData = () => {
    setLoading(true);

    const promises = Metals?.map((metal) => {
      const queryParams = {
        fromDate: moment.utc(filters?.fromDate).format("x"),
        toDate: moment.utc(filters?.toDate).format("x"),
        metalCode: metal?.metalCode,
        status: filters?.transactionStatus || [],
        itemCode: filters?.itemCode || "",
        vault: filters?.vault?.id || "",
        partnerId: filters?.CuGroup?.partnerId || "",
        isMetalCovered: filters?.isMetalCovered,
        orderBy: "created_at",
        order: "desc",
        page: 0,
        pagesize: 10,
      };

      return DashboardReportServiceHP.getReportSummary(tokens, queryParams);
    });

    Promise.all(promises)
      .then((values) => {
        values?.map((value, index) => {
          Metals[index].tableData = value?.data?.data;
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Something went wrong");
      });
  };

  return (
    <div>
      {loading || isUsdInrLoading ? (
        <Loader />
      ) : (
        Metals?.map((metal) => {
          return (
            <div className="pb-5">
              <TreasurySummaryReport
                data={metal?.tableData}
                metalCode={metal?.metalCode}
                metalName={metal?.metalName}
                fromDate={filters?.fromDate}
                toDate={filters?.toDate}
                downloadReport={() => {}}
                downloadBtn={false}
              />
            </div>
          );
        })
      )}
      <UsdInrTable
        CustomerGroups={CustomerGroups}
        setIsUsdInrLoading={setIsUsdInrLoading}
      />
    </div>
  );
};

export default Summary;
