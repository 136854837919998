import Layout from "../../Layout/Layout";
import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import { CURRENCY, MAIN_RULE_TYPES } from "../../TradeExecution/Utils/enum";
import { ORDER_BY, STATUS } from "../Utils/enum";
import { makeQuery } from "../../../Common/util.helper";
import OrderService from "../Services/order.service";
import Loader from "../../../Components-ui/Loader";
import MultipleSelect from "../../../Components-ui/MultipleSelect";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Pagination } from "../../../Components-ui/Pagination";
import { toast } from "react-toastify";
import { useAuthStore } from "../../../Store/AuthStore";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";
import UserListService from "../../Userlist/Services/UserList.Service";
interface PropsType { }

const OrderTable: React.FC<PropsType> = () => {
  const { logout, permissions, userData, tokens } = useAuthStore();

  const history = useHistory();

  const searchParams = new URLSearchParams(window.location.search);
  const status = searchParams.get("status");
  const interval = searchParams.get("interval");
  const iconKey = searchParams.get("iconkey");
  const decodedStatus = status ? decodeURIComponent(status) : undefined;
  const decodedInterval: any = interval
    ? decodeURIComponent(interval)
    : undefined;
  const { isPartnerAdmin, partnerAdminId } = useAuthStore();
  // @ts-ignore
  const newPartnerId = partnerAdminId == 0 ? "" : partnerAdminId;
  const [Filter, setFilter] = useState<any>({
    orderRef: "",
    metalCode: "",
    qType: "",
    status: [],
    accountId: "",
    partnerId: newPartnerId,
    customerId: "",
    currency: "",
    source: "",
    orderBy: "created_at/desc",
    order: "",
    orderByJoin: "",
    fromDate: "",
    toDate: "",
  });
  const [Loading, setLoading] = useState<any>(false);
  const [Orders, setOrders] = useState<any>([]);
  const [MetaData, setMetaData] = useState<any>({});
  const [CurrentPage, setCurrentPage] = useState<number>(0);
  const [metalList, setMetalList] = useState<any>([]);
  const [partnerList, setPartnerList] = useState<any>([]);
  const [downloadQuery, setDownloadQuery] = useState<any>("");

  const get_partner_list = async () => {
    await UserListService.getPartnerList(tokens)
      .then((response: any) => {
        setPartnerList(response.data.data);
        sessionStorage.setItem(
          "partnerList",
          JSON.stringify(response.data.data)
        );
      })
      .catch((err) => {
        // toast.error(err.response.data.errors[0].title);
      });
  };
  useEffect((): any => {
    let partnerList = sessionStorage.getItem("partnerList");
    handleSubmit(CurrentPage);
    loadMetals();
    // if (Object.keys(params).length > 0) {
    // filterFunction();
    // }
    if (searchParams.has("status")) {
      filterFunction();
    }
    if (!partnerList) get_partner_list();
    else setPartnerList(JSON.parse(partnerList));
    // eslint-disable-next-line
  }, []);

  const filterFunction = () => {
    setFilter({
      ...Filter,
      status:
        decodedStatus == "TOTAL" ||
          iconKey == "Ag" ||
          iconKey == "Au" ||
          iconKey == "Pt" ||
          iconKey == "Pd"
          ? []
          : [
            {
              label: decodedStatus,
              value: decodedStatus,
            },
          ],
      fromDate:
        decodedInterval < 1
          ? ""
          : moment().subtract(decodedInterval, "d").format("MM/DD/YYYY"),
      toDate: decodedInterval < 1 ? "" : moment().format("MM/DD/YYYY"),
      metalCode:
        iconKey == "Ag" || iconKey == "Au" || iconKey == "Pt" || iconKey == "Pd"
          ? iconKey
          : "",
    });
  };

  const loadMetals = async (): Promise<void> => {
    return new Promise(async (resolve) => {
      OrderService.getMetals(tokens)
        .then((response: any) => {
          setMetalList(response.data.data);
          resolve();
        })
        .catch((err) => {
          toast.error("Something Went Wrong in metal list load!");
          resolve();
        });
    });
  };

  /**
   * Handle multiple select change event
   * @param selected
   */
  const handleChangeItem = (selected: any) => {
    setFilter({
      ...Filter,
      status: selected,
    });
  };

  /**
   * Handle filters change event
   * @param e
   */
  const handleChangeFilter = (e: any) => {
    const { name, value } = e.target;
    setFilter({
      ...Filter,
      [name]: value,
    });
  };

  // /**
  //  * Get orders according to the filtration
  //  * @param page
  //  * @param isReset
  //  */
  // const handleSubmit = async (page: number, isReset: boolean = false) => {
  //   setLoading(true);
  //   let cloneFilterData = !isReset
  //     ? { ...Filter }
  //     : {
  //         orderRef: "",
  //         metalCode: "",
  //         qType: "",
  //         status: [],
  //         accountId: "",
  //         customerId: "",
  //         currency: "",
  //         orderBy: "created_at/desc",
  //         order: "",
  //         fromDate: "",
  //         toDate: "",
  //       };
  //   cloneFilterData.status = cloneFilterData.status.map((x: any) => x.value);
  //   if (cloneFilterData.orderBy !== "") {
  //     let orderByValues = cloneFilterData.orderBy.split("/");
  //     cloneFilterData.order = orderByValues[1];
  //     cloneFilterData.orderBy = orderByValues[0];
  //   }

  /**
   * Get orders according to the filtration
   * @param page
   * @param isReset
   */
  const handleSubmit = async (page: number, isReset: boolean = false) => {
    let cloneFilterData: any;
    setLoading(true);
    if (searchParams.has("status")) {
      cloneFilterData = {
        ...Filter,
        status:
          decodedStatus == "TOTAL" ||
            iconKey == "Ag" ||
            iconKey == "Au" ||
            iconKey == "Pt" ||
            iconKey == "Pd"
            ? []
            : [
              {
                label: decodedStatus,
                value: decodedStatus,
              },
            ],

        fromDate:
          decodedInterval < 1
            ? ""
            : moment().subtract(decodedInterval, "d").format("MM/DD/YYYY"),
        toDate: decodedInterval < 1 ? "" : moment().format("MM/DD/YYYY"),
        metalCode:
          iconKey == "Ag" ||
            iconKey == "Au" ||
            iconKey == "Pt" ||
            iconKey == "Pd"
            ? iconKey
            : "",
      };
    } else {
      cloneFilterData = !isReset
        ? { ...Filter }
        : {
          orderRef: "",
          metalCode: "",
          qType: "",
          status: [],
          accountId: "",

          customerId: "",
          partnerId: newPartnerId,
          currency: "",
          orderBy: "created_at/desc",
          order: "",
          fromDate: "",
          toDate: "",
        };
    }
    cloneFilterData.status = cloneFilterData.status.map((x: any) => x.value);
    if (cloneFilterData.orderBy !== "") {
      let orderByValues = cloneFilterData.orderBy.split("/");
      cloneFilterData.order = orderByValues[1];
      cloneFilterData.orderBy = orderByValues[0];
    }
    const query = makeQuery(cloneFilterData);
    setDownloadQuery(query);
    const data: any = await OrderService.getAllOrders(
      query,
      page,
      tokens,
      userData?.sub
    );

    if (data.status === 200 && data.data.data) {
      setMetaData({
        current: data.data.meta.page,
        pages: data.data.meta.totalPages,
      });
      setCurrentPage(page);
      setOrders(data ? data.data.data.result : []);
    }
    // console.log(data ? data.data.data.result : [])
    setLoading(false);
  };
  //   // console.log(data ? data.data.data.result : [])
  //   setLoading(false);
  // };

  /**
   * Reset filters to initial state
   * @param e
   */
  const resetData = async (e: any) => {
    e.preventDefault();
    setFilter({
      orderRef: "",
      metal: "",
      qType: "",
      status: [],
      accountId: "",
      customerId: "",

      currency: "",
      partnerId: newPartnerId,
      orderBy: "created_at/desc",
      order: "",
      fromDate: "",
      toDate: "",
    });

    setCurrentPage(0);
    await handleSubmit(0, true);
  };

  const downloadOrderReport = async () => {
    setLoading(true);
    await OrderService.downloadOrderReport(downloadQuery, tokens)
      .then((res: any) => {
        if (res.status === 200 && res.data) {
          setLoading(false);
          // saveAs(res.data.data.fileUrl, `order_log_report`);
          window.location.assign(res.data.data.fileUrl);
          // window.open(res.data.data.fileUrl);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Something Went Wrong!");
        setLoading(false);
      });

    setLoading(false);
  };



  return (
    <Layout type={"TradeExecution"}>
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">{"Orders"}</h1>
      </div>
      <div className="w-full rounded border-gray-200 overflow-hidden shadow-lg dark:border-gray-700 bg-white">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Filter Orders
          </h3>
        </div>
        <hr />
        <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="orderRef"
              className="block text-sm font-medium text-gray-700"
            >
              Order Ref
            </label>
            <input
              type="text"
              name="orderRef"
              id="orderRef"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={Filter.orderRef}
            />
          </div>

          <div className="col-span-12 sm:col-span-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="metal"
            >
              Metal Code
            </label>
            <select
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              name="metalCode"
              id="metal"
              placeholder="Metal Code"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={Filter.metalCode}
            >
              <option value="">All</option>
              {metalList.map((metal: any) => (
                <option value={metal.metalCode}>{metal.metalCode}</option>
              ))}
            </select>
          </div>
          {/* Added partner filter into order    */}

          {/* <div className="col-span-12 sm:col-span-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="partner"
            >
              Partner
            </label>
            <div className="relative">
              <select
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="partner"
                name="partnerId"
                onChange={(e: any) => {
                  handleChangeFilter(e);
                }}
                value={Filter.partnerId}
                disabled={isPartnerAdmin}
              >
                <option value="" disabled>
                  Select a Partner
                </option>
                {partnerList.map((partner: any) => (
                  <option value={partner.partnerId}>{partner.name}</option>
                ))}
              </select>
            </div>
          </div> */}

          {/* Added partner filter into order    */}

          <div className="col-span-12 sm:col-span-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="partner"
            >
              Partner
            </label>
            <div className="relative">
              <select
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="partner"
                name="partnerId"
                onChange={(e: any) => {
                  handleChangeFilter(e);
                }}
                value={Filter.partnerId}
                disabled={isPartnerAdmin}
              >
                <option value="" disabled>
                  Select a Partner
                </option>
                {partnerList.map((partner: any) => (
                  <option value={partner.partnerId}>{partner.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="qType"
              className="block text-sm font-medium text-gray-700"
            >
              Type
            </label>
            <select
              id="qType"
              name="qType"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={Filter.qType}
            >
              <option value="">All</option>
              {MAIN_RULE_TYPES.map((type: string, index: number) => {
                return (
                  <option key={index} value={type}>
                    {type}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="accountId"
              className="block text-sm font-medium text-gray-700"
            >
              Account Id
            </label>
            <input
              type="text"
              name="accountId"
              id="accountId"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={Filter.accountId}
            />
          </div>
          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="customerId"
              className="block text-sm font-medium text-gray-700"
            >
              Customer
            </label>
            <input
              type="text"
              name="customerId"
              id="customerId"
              min="0"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={Filter.customerId}
            />
          </div>

          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="currency"
              className="block text-sm font-medium text-gray-700"
            >
              Currency
            </label>
            <select
              id="currency"
              name="currency"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={Filter.currency}
            >
              <option value="">All</option>
              {CURRENCY.map((type: string, index: number) => {
                return (
                  <option key={index} value={type}>
                    {type}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="fromDate"
              className="block text-sm font-medium text-gray-700"
            >
              From Date
            </label>

            <div>
              <Datetime
                timeFormat={""}
                dateFormat={"DD/MM/YYYY"}
                inputProps={{
                  readOnly: true,
                  placeholder: "pick date",
                  className: "focus:outline-none",
                }}
                className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4`}
                onChange={(event: any) =>
                  setFilter({
                    ...Filter,
                    fromDate: moment(new Date(event).toISOString()).format(
                      "MM/DD/YYYY"
                    ),
                  })
                }
                value={moment(Filter.fromDate)}
                renderInput={(props) => {
                  return (
                    <input
                      {...props}
                      value={Filter.fromDate ? Filter.fromDate : ""}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="toDate"
              className="block text-sm font-medium text-gray-700"
            >
              To Date
            </label>

            <div>
              <Datetime
                timeFormat={""}
                dateFormat={"DD/MM/YYYY"}
                inputProps={{
                  readOnly: true,
                  placeholder: "pick date",
                  className: "focus:outline-none",
                }}
                className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4`}
                onChange={(event: any) =>
                  setFilter({
                    ...Filter,
                    toDate: moment(new Date(event).toISOString()).format(
                      "MM/DD/YYYY"
                    ),
                  })
                }
                value={moment(Filter.toDate)}
                renderInput={(props) => {
                  return (
                    <input
                      {...props}
                      value={Filter.toDate ? Filter.toDate : ""}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="type"
              className="block text-sm font-medium text-gray-700 pb-1"
            >
              Status
            </label>
            <MultipleSelect
              optionList={STATUS}
              onChangeItem={handleChangeItem}
              selectedValue={Filter.status}
              closeMenuOnSelect={false}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
            />
          </div>
          <div className="col-span-12 sm:col-span-4">
            <label
              htmlFor="orderBy"
              className="block text-sm font-medium text-gray-700"
            >
              Order By
            </label>
            <select
              id="orderBy"
              name="orderBy"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={Filter.orderBy}
            >
              <option value="" disabled>
                Select an order by
              </option>
              {ORDER_BY.map((item: any, index: number) => {
                return (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="col-span-12 sm:col-span-12 flex justify-end">
            <button
              className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
              type="button"
              onClick={() => handleSubmit(CurrentPage)}
            >
              Filter
            </button>
            <button
              className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
              type="button"
              onClick={(e) => resetData(e)}
            >
              Reset
            </button>
            <button
              className="px-4 py-1.5 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none ml-2  ease-linear transition-all duration-150"
              type="button"
              onClick={(e) => downloadOrderReport()}
            >
              Download
            </button>
          </div>
          <div className="col-span-12 sm:col-span-12">
            {" "}
            <hr />
          </div>

          <div className="col-span-12 sm:col-span-12">
            {Loading ? (
              <Loader />
            ) : (
              <div className="border bg-white rounded border-gray-200 shadow overflow-scroll" >
                <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                  <thead>
                    <tr className="text-left border-b border-gray-300">
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Order Ref
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Quote Ref
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Created At
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Customer
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Account Id
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Quotation Total
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Currency
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Metal Code
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Type
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Source
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        IsGift
                      </th>
                      <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Status
                      </th>
                      {HasAnyAccess([
                        "order.master.view",
                        "order.master.view.invoice",
                      ]) && (
                          <th className="sticky top-0 px-3 py-2 bg-gray-800">
                            Actions
                          </th>
                        )}
                      {/* <th className="sticky top-0 px-3 py-2 bg-gray-800">
                        Mail
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {Orders.length > 0 ? (
                      Orders.map((order: any, index: number) => {
                        return (
                          <tr
                            key={index}
                            className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                          >
                            <td className="py-3 px-3 text-left relative">
                              {order.ebOrderRef}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {order.ebQuoteRef}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {moment(order.createdAtUnix).format(
                                "DD-MM-YYYY HH:mm"
                              )}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {order.customerId}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {order.accountId}
                            </td>
                            <td className="py-3 px-3 text-left relative">

                              {parseFloat(order.order.quotationTotal).toFixed(2)}

                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {order.order.systemCurrency}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {order.items.length > 0 &&
                                order.items[0].itemBreakDown.length > 0
                                ? order.items[0].itemBreakDown[0].metalCode
                                : "N/A"}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {order.type}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {order?.order?.source}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {order.order.paymentMethod == "GIFT"
                                ? "YES"
                                : "NO"}
                            </td>
                            <td className="py-3 px-3 text-left relative">
                              {STATUS.filter(
                                (x: any) => x.value === order.status
                              ).length > 0
                                ? STATUS.filter(
                                  (x: any) => x.value === order.status
                                )[0].label
                                : order.status}
                            </td>
                            {HasAnyAccess([
                              "order.master.view",
                              "order.master.view.invoice",
                            ]) && (
                                <td className="flex py-3 px-3 text-left relative">
                                  {HasAnyAccess(["order.master.view"]) && (
                                    <button
                                      className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                      type="button"
                                      onClick={() =>
                                        history.push(`/order/${order.ebOrderRef}`)
                                      }
                                    >
                                      View
                                    </button>
                                  )}
                                  {HasAnyAccess(["order.master.view"]) &&
                                    order?.status ==
                                    "BANK_TRANSFER_APPROVAL_PENDING" && (
                                      <button
                                        className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() =>
                                          history.push(
                                            `/order/${order.ebOrderRef}/invoices`
                                          )
                                        }
                                      >
                                        Approve
                                      </button>
                                    )}
                                  {HasAnyAccess([
                                    "order.master.view.invoice",
                                  ]) && (
                                      <>
                                        {(order.type === "BUYBACK" ||
                                          order.type === "SELL") &&
                                          order.customer.customerCategory ===
                                          "REGISTERED" && (
                                            <button
                                              className="text-truncate text-white bg-purple-600 background-transparent rounded-md font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                              type="button"
                                              onClick={() =>
                                                history.push(
                                                  `/order/${order.ebOrderRef}/invoices`
                                                )
                                              }
                                            >
                                              View Invoices
                                            </button>
                                          )}
                                      </>
                                    )}
                                </td>
                              )}
                            {/* //new */}
                            {/* <td className="py-3 px-3 text-left relative">
                              {(STATUS.filter(
                                (x: any) => x.value === order.status
                              ).length > 0
                                ? STATUS.filter(
                                    (x: any) => x.value === order.status
                                  )[0].label
                                : order.status) === "COMPLETED" ? (
                                <button
                                  className="text-purple-900 border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                  type="button"
                                  // onClick={() =>
                                  //   history.push(`/order/${order.ebOrderRef}`)
                                  // }
                                >
                                  Send mail
                                </button>
                              ) : (
                                ""
                              )}
                            </td> */}
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                        <td
                          colSpan={10}
                          className="text-center py-3 px-3 text-left relative"
                        >
                          No data to display
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="col-span-12 sm:col-span-12">
            {/* Pagination Bar */}
            <Pagination
              meta={MetaData}
              handlePageChange={(number: number) => handleSubmit(number)}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OrderTable;
