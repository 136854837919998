import Http from "../../../library/http.library";
import {httpMethods} from "../../../library/enum";

class CityService {

    /**
     * Get all cities
     * @param page
     * @param tokens
     * @param query
     */
    async getAllCities(page: number, tokens:any, query:any) {
        return await Http.setMethod(httpMethods.get).setUrl(`/city-master?page=${page}${query}`).setToken(tokens.idToken.jwtToken).request();
    }

    /**
     * Add new city
     * @param data
     * @param tokens
     */
    async addCity(data: any, tokens: any) {
        return await Http.setMethod(httpMethods.post).setUrl(`/city-master`).setData([data]).setToken(tokens.idToken.jwtToken).request();
    }

    /**
     * Update city details
     * @param data
     * @param tokens
     */
    async updateCity(data: any, tokens: any) {
        return Http.setMethod(httpMethods.patch).setUrl(`/city-master/${data.id}`).setData(data).setToken(tokens.idToken.jwtToken).request();
    }
}

export default new CityService();
